import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComingSoonComponent } from '@core/components/coming-soon/coming-soon.component';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { newAccountDisabledFieldsIfEnhancedUser, newAccountRequiredFields, newRegistrationRequiredFields, newSubAccountDisabledFieldsByMop, newSubAccountRequiredFields, newSubAcocuntRequiredFieldsbyMop } from '@core/constants/registration-required-fields.const';
import {
  accountMap,
  cmNewSubAccount,
  newAccount,
  newArrounByMop,
  newRegistration,
  newSubAccount,
  newSubAccountByMop
} from '@core/constants/registration-route-types.const';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { AccountData, OMPAccountType } from '@core/models/account.model';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { programIdKey, StoreService } from '@core/store/store.service';
import { convertToYYYYMMDD } from '@core/utilities/utilities.constants';
import { environment } from '@env/environment';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { IAccountData, IDocument, IFacility, IFacilityRequest, IRegistrationStep } from '@module/registration/registration.model';
import { RegistrationService } from '@module/registration/registration.service';
import { UserManagementService } from '@module/users-management/services/user-management.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { RegistrationForm } from '@shared/models/registration-form.model';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { combineLatest, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil, switchMap, filter } from 'rxjs/operators';
import { FormService } from '../../services/form.service';
import {
  BUSINESS_STRUCTURE_IDS_BY_PROGRAM,
  COMPONENT_ID,
  KYC_DOWNLOAD_TEMPLATE_URI,
  REGISTRATION_TYPE
} from './stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {

  @Input('type') _type: string;
  @Input('accountId') _accountId: string;
  @Input('inApp') inApp = false;
  formFields: FormlyFieldConfig[];

  private _destroy$ = new Subject<any>();
  registrationSteps: IRegistrationStep[];
  accountFields: FormlyFieldConfig[];
  facilityNames: IApp.ILookup[];
  facilities: IFacility[] = [];
  files: IDocument[] = [];
  facilityError = [];
  stepErrors = [];
  documentUploadConfig: IApp.IDocumentUploadConfig = {
    comment: true,
    documentType: false,
    entityName: 'ACCOUNT',
    documentTypeValue: 2,
  };
  accountData = new IAccountData();
  nfmpForm = new FormGroup({});
  obpsForm = new FormGroup({});
  cmForm = new FormGroup({});
  masterAccountData = new AccountData;
  requiredFields: string[];
  disabledFields: string[] = [];
  facilityModel: any = {};
  registrationType: string;
  type: string;
  header: string;
  subHeader: string;
  facilityAlreadyAdded: boolean;
  facilityRequired: boolean;
  currentStep = 1;
  createNFMPAccountEnabled = false;
  createOffsetccountEnabled = false;
  cfsEnabled = false;

  facilityName = new FormControl('', Validators.required);
  ompAccountTypePurpose: OMPAccountType;
  public cmNewSubAccount = cmNewSubAccount;

  isOffset = false;
  isLoaded = false;
  showStepper = true;
  onestep = false;

  isEmailConfirmedOnce = false;

  constructor(
    private router: Router,
    private formService: FormService,
    private storeService: StoreService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private authService: AuthService,
    private userManagementService: UserManagementService,
    private accountManagementService: AccountManagementService,
    private helperService: HelperService,
    private translate: TranslateService,
    private uploadDocumentService: UploadDocumentService,
    private documentUploadInProgress: DocumentUploadInProgressService,
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
  ) {
    this.translate.onLangChange.pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      event => this.setupRegistrationSteps(this.type),
    );
  }

  ngOnInit() {
    combineLatest([
      this.storeService.getProperty('nfmp.enabled.date'),
      this.storeService.getProperty('cfs.enabled.date'),
      this.storeService.getProperty('enable.offset'),
    ]).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      ([nfmpProp, cfsProp, offsetProp]) => {
        if (nfmpProp.value && nfmpProp.value.toLowerCase() === 'true') {
          this.createNFMPAccountEnabled = true;
        }
        if (cfsProp.value && cfsProp.value.length === 0) {
          this.cfsEnabled = true;
        }

        if (offsetProp.value && offsetProp.value.toLowerCase() === 'true') {
          this.createOffsetccountEnabled = true;
        }
      });

    this.ompAccountTypePurpose = new OMPAccountType();
    this.nfmpForm['id'] = `${COMPONENT_ID}_NFMP`;
    this.obpsForm['id'] = `${COMPONENT_ID}_OBPS`;
    this.cmForm['id'] = `${COMPONENT_ID}_CM`;
    this.formFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-sm-12 hidden-label',
            type: 'input',
            key: 'facility',
            templateOptions: {
              label: 'REGISTRATION_PAGE.enterFacilitySection.facilityCertificateNumber',
              translateOptions: true,
              required: false,
              placeholder: 'REGISTRATION_PAGE.enterFacilitySection.facilityCertificateNumber',
              keydown: (field, event) => {
                // is there any better solution feel free to change it
                if (event.keyCode === 13) {
                  document.getElementById('button-addon').click();
                }
              },
            },
          },
        ],
      },
    ];

    this.registrationType = this.activatedRoute.snapshot.data['registrationType'] || this._type;
    this.facilityError = null;

    this.activatedRoute.params.subscribe(params => {

      this.isOffset = this.storeService.user.isOffset();

      switch (this.registrationType) {

        case newRegistration:
          this.requiredFields = newRegistrationRequiredFields;
          this.isLoaded = true;
          break;

        case newAccount:
          this.requiredFields = newAccountRequiredFields;

          if (this.storeService.user.hasPermission('USER_TYPE_ACCOUNT_HOLDER')) {
            this.disabledFields = newAccountDisabledFieldsIfEnhancedUser;
          }
          this.prepopulateForm(params);
          break;

        case newSubAccount:

          if (this.isOffset) { this.showStepper = false; }
          this.requiredFields = newSubAccountRequiredFields;
          // if(this.storeService.user.hasPermission('TYPE_ADMIN')){
          this.disabledFields = newAccountDisabledFieldsIfEnhancedUser;
          // }
          this.prepopulateForm(params);
          break;

        case newArrounByMop:
          this.isLoaded = true;
          this.requiredFields = newAccountRequiredFields;
          break;

        case newSubAccountByMop:
          if (this.isOffset) { this.showStepper = false; }
          this.isLoaded = true;
          this.getFacilityNames();
          this.requiredFields = newSubAcocuntRequiredFieldsbyMop;
          this.disabledFields = newSubAccountDisabledFieldsByMop;
          break;

        case cmNewSubAccount:
          const ompAccountTypePurpose = window.history.state.ompAccountTypePurpose;

          if (ompAccountTypePurpose) {
            this.accountData.accountSubType = ompAccountTypePurpose.accountSubType;
            this.accountData.registrationType = ompAccountTypePurpose.accountRegistrationType;
          }

          this.showStepper = false;
          this.requiredFields = newSubAccountRequiredFields;
          this.disabledFields = newAccountDisabledFieldsIfEnhancedUser;
          this.prepopulateForm(params);
          break;
      }

      this.setupRegistrationSteps();

      if (this.activatedRoute.snapshot.data['step']) {
        this.currentStep = this.activatedRoute.snapshot.data['step'];
        this.accountData.programType = this.activatedRoute.snapshot.data['programType'];
        this.registrationSteps[0].completed = true;
        this.nextStepPostCheck(this.accountData.programType);

        const facilityList = this.activatedRoute.snapshot.data['facilities'];
        if (this.activatedRoute.snapshot.data['facilities']) {
          this.facilities = facilityList.map(facility => ({
            id: facility.id,
            legalName: facility.legalName,
            certificateNumber: facility.certificateNumber,
            authorizedAccountRepresentativeId: facility.authorizedAccountRepresentativeId,
          }));
        }
      }

    });
    this.isEmailConfirmedOnce = false;

  }

  isOMPTypeOrg() {
    return this.ompAccountTypePurpose === 'org';
  }

  setupRegistrationSteps(type?: string) {
    this.registrationService.prepareSteps();
    if (this.isMasterAccountRegistration()) {
      if (type) {
        this.setNewRegistrationTypeSetUp(type);
      } else {
        this.setNewRegistrationTypeSetUp('default');
      }
    } else {
      this.newAccountSetUp();
    }
  }

  setNewRegistrationTypeSetUp(type) {
    this.type = type;
    let steps = this.registrationService.registrationSteps[this.type];
    steps = steps.map(step => ({...step, name: this.translateService.instant(step.name)}));
    steps[0].hidden = !this.cfsEnabled;
    this.registrationSteps = [...steps];
  }

  setNewAccountSteps(type) {
    this.type = type;
    const steps = this.registrationService.registrationSteps[this.type]
      .map(step => ({...step, name: this.translateService.instant(step.name)}));
    this.registrationSteps = [...steps];
  }

  prepopulateForm(params) {
    if (!this.isRegistrationSubAccountByMop()) {
      this.userManagementService.get(this.storeService.user.id).subscribe(data => {
        this.accountData.authorizedOfficial = {
          ...data,
          name: data.fullName,
          telephone: data.phoneNumber,
        };

      });
    }

    this.getMasterAccountDetails(params['id']);

    if (this.isEnhancedUserAndNewSubAccount()) {
      this.getAuthorizedOfficialByAccountId(params['id']);
    }
  }

  isMopNewSubAccount() {
    return this.registrationType === newSubAccountByMop;
  }

  isEnhancedUserAndNewSubAccount() {
    return this.storeService.user.roleName.indexOf('ACCOUNT_HOLDER') > -1 && this.registrationType === newSubAccount;
  }

  newAccountSetUp() {
    this.type = this.isOffset ? REGISTRATION_TYPE.OFFSET : REGISTRATION_TYPE.CM;
    this.registrationSteps = [...this.registrationService.newAccountSteps[this.type]];

    if (!this.showStepper) {
      this.onestep = true;
      this.registrationSteps = [...this.registrationService.newAccountSteps[`${this.type}HiddenSteps`]];
    }

    this.currentStep = this.registrationSteps[0].id;
  }

  getMasterAccountDetails(id) {
    this.accountManagementService.getAccountDetails(id)
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          this.masterAccountData = data;
          this.isLoaded = true;
        },
        () => { },
      );
  }

  getFacilityNames() {
    this.lookupService.getMasterAccounts().pipe(
      takeUntil(this._destroy$),
    ).subscribe(value => this.facilityNames = value);
  }

  isMasterAccountRegistration() {
    return this.registrationType === newRegistration;
  }

  isRegistrationSubAccount() {
    return this.registrationType === newSubAccount ||
      this.registrationType === cmNewSubAccount;
  }

  isRegistrationSubAccountByMop() {
    return this.registrationType === newSubAccountByMop;
  }

  isNewRegistration() {
    return this.registrationType === newRegistration;
  }

  processFormatDate(file) {
    file.lastModifiedDate = convertToYYYYMMDD(file.lastModifiedDate);
    return file.lastModifiedDate;
  }

  createForm(type = REGISTRATION_TYPE.INITIAL) {
    this.accountFields = [];
    const registrationForm = new RegistrationForm(
      this.registrationService,
      type,
      this.lookupService,
      this.requiredFields,
      this.disabledFields,
      null,
      this.ompAccountTypePurpose);

    this.accountFields = registrationForm.getRegistrationForm();

  }

  onFacilityNameChange(event) {
    this.masterAccountData = event;
    this.getAuthorizedOfficialByAccountId(event.id);
  }

  getAuthorizedOfficialByAccountId(id) {

    this.accountManagementService.getAuthorizedOfficial(id)
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        () => {
          if (!this.isOffset) {
            this.createForm();
          }
        },
        () => { },
      );
  }

  backStep() {
    if (this.currentStep === 3) {
      this.registrationService.registrationType.next(null);
    }

    if (!this.showStepper) {
      this.router.navigateByUrl('/');
    } else {
      sessionStorage.setItem(programIdKey, this.accountData.programType);
      this.authService.changeLang(this.translate.currentLang, true)
        .subscribe(() => {
          if (this.currentStep === 2) {
            this.router.navigateByUrl('/');
          } else {
            this.currentStep -= 1;

            if (this.accountData.programType === REGISTRATION_TYPE.CM && this.currentStep === 3) {
              this.setNewRegistrationTypeSetUp(this.accountData.programType);
            } else {
              this.setNewRegistrationTypeSetUp('default');
            }
          }
        });
    }
  }

  nextStepIntendUsage(type: string = null) {
    if (type) {
      this.accountData.programType = type;
    }
    if (this.checkAccountTypeEnabled(type, REGISTRATION_TYPE.CFS, 'cfs.enabled.date')) {
      this.nextStepPostCheck(type);
    }
  }

  nextStep(object: any) {
    if (object instanceof OMPAccountType) {
      this.ompAccountTypePurpose = object;
      this.accountData.accountSubType = this.ompAccountTypePurpose.accountSubType;
      this.accountData.registrationType = this.ompAccountTypePurpose.accountRegistrationType;

      this._setOMPAccountFormHeader();
      this.accountData.programType = REGISTRATION_TYPE.CM;
      sessionStorage.setItem(programIdKey, this.accountData.programType);
      this.nextStepPostCheck(REGISTRATION_TYPE.CM);
      this._businessStructureChangeSubscription();

      return;
    }

    const type = object;
    this.registrationService.registrationType.next(type);
    if (type === REGISTRATION_TYPE.OBPS) {
      if (this.currentStep === 2) {
        this.header = 'REGISTRATION_PAGE.accountTypeSection.obpsAccountRegistration';
      }

      if (this.currentStep === 3) {
        this.header = 'REGISTRATION_PAGE.accountTypeSection.obpsAccountRegistrationStep2';
      }
    } else if (type === REGISTRATION_TYPE.OFFSET) {
      if (this.currentStep === 3) {
        this.header = 'REGISTRATION_PAGE.accountTypeSection.offsetAccountRegistrationTitle';
      }
    }

    sessionStorage.setItem(programIdKey, type);
    this.authService.changeLang(this.translate.currentLang, true)
      .subscribe(() => this.nextStepPostCheck(type));
  }

  nextStepPostCheck(type: string = null) {
    this.type = type;

    if (this.registrationType === newRegistration) {

      const currentStep = this.registrationSteps.find(step => step.id === this.currentStep);
      currentStep.completed = true;
      this.currentStep += 1;


      if ((this.type === REGISTRATION_TYPE.CM || this.type === REGISTRATION_TYPE.OFFSET) && this.currentStep === 3) {
        this.setNewRegistrationTypeSetUp(type);
        this.registrationSteps[0].completed = true;
        this.registrationSteps[1].completed = true;
      }

      if ((this.type === 'obps') && this.currentStep ===3) {
        this.setNewRegistrationTypeSetUp(type);
       }

      if (this.type === REGISTRATION_TYPE.CM && this.currentStep === 4) {
        this.createForm(this.type);
      }
    } else {
      const currentStep = this.registrationSteps.find(step => step.id === this.currentStep);
      currentStep.completed = true;
      this.currentStep += 1;

      if (this.type === REGISTRATION_TYPE.CM && this.currentStep === 3) {
        this.setNewRegistrationTypeSetUp(type);
        this.registrationSteps[0].completed = true;
        this.registrationSteps[1].completed = true;
      }

      if (this.type === REGISTRATION_TYPE.CM && this.currentStep === 4) {
        this.createForm(this.type);
      }
    }

  }

  checkAccountTypeEnabled(type: string, registrationType: REGISTRATION_TYPE, propertyName: string) {
    if (type === registrationType) {
      this.registrationService.getProperty(propertyName)
        .subscribe(property => {
          if (property.value && property.value.length > 1) {
            this.modalService.open(ComingSoonComponent, {
              registration: registrationType,
              value: property.value,
            }).afterClosed().subscribe((closed?: any) => {
              if (closed) {
                return;
              }
            });
          } else {
            this.nextStepPostCheck(type);
          }
        });
    } else {
      return true;
    }
  }

  downloadKYCTemplate() {
    window.open(`${environment.apiUrl}${KYC_DOWNLOAD_TEMPLATE_URI}`, '_blank');
  }

  addFacility(facilityForm: FormGroup) {
    const facilityNo = this.facilityModel ? this.facilityModel.facility : null;
    const facilityExists = this.facilities.filter(facility => {
      return facility.certificateNumber === facilityNo;
    });

    if (facilityExists.length) {
      facilityForm.controls['facility'].setErrors({ 'server-side': this.translate.instant('facilityAlreadyAdded') });
      return;
    }
    if (!facilityNo || !facilityNo.trim()) {
      facilityForm.controls['facility'].markAsDirty();
      facilityForm.controls['facility'].setErrors({ 'server-side': this.translate.instant('missingFacilityCertificateNumber') });
      return;
    }

    let facilityNos = '';

    if (this.facilities.length === 0) {
      facilityNos = facilityNo;
    } else {
      facilityNos = this.facilities.map(facility => {
        return facility.certificateNumber;
      }).join(',');
      facilityNos = `${facilityNos},${facilityNo}`;
    }

    const facilityIds = facilityNos.split(',');
    const facilityRequest: IFacilityRequest = {
      docIds: this.files,
      certificateNumbers: facilityIds,
    };

    this.registrationService.checkFacility(facilityRequest)
      .subscribe(facilities => {
        this.facilityModel = {};
        this.facilities = [];
        facilityForm.reset();
        for (const facility of facilities) {
          this.facilities.push({
            id: facility.id,
            legalName: facility.legalName,
            certificateNumber: facility.certificateNumber,
            authorizedAccountRepresentativeId: facility.authorizedAccountRepresentativeId,
          });
        }
        this.facilities.sort((a, b) => a.certificateNumber.localeCompare(b.certificateNumber));
        this.facilityError = null;
      },
        (error: HttpErrorResponse) => {
          // this.facilityError = this.registrationService.handleErrors(error.error);
          this.formService.parseErrors(facilityForm, error.error);
        });
  }

  clearErrors() {
    this.facilityError = null;
  }

  removeFile(index: number) {

    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: 'deleteDocumentWarningMessage',
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.files.splice(index, 1);
      }
    });

  }

  removeFacility(index: number) {
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: 'deleteFacilityWarningMessage', // CHECK THE EN.JSON NEEDS A BETTER SOLUTION
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.facilities.splice(index, 1);
      }
    });
  }

  download(id: number) {
    this.uploadDocumentService.download(id);
  }

  uploadKycFiles() {
    if (this.type === 'obps') {
      const facilityIds = this.facilities.map(el => el.certificateNumber);
      const facilityRequest: IFacilityRequest = {
        docIds: this.files,
        certificateNumbers: facilityIds,
      };
      this.registrationService.submitObpsFacilityAccount(facilityRequest).subscribe(
        result => {
          this.storeService.setAccessKey(result.entity);
          this.nextStep(this.type);
        },
        (error: HttpErrorResponse) => {
          this.stepErrors = this.registrationService.handleErrors(error.error);
        },
      );
    } else if (this.type === REGISTRATION_TYPE.CM) {
      this.accountData.documents = this.files;
      this.registrationService.submitOMPAccount(this.accountData, null).subscribe(
        result => {
          this.storeService.setAccessKey(result.entity);
          this.nextStep(this.type);
        },
        (error: HttpErrorResponse) => {
          this.stepErrors = this.registrationService.handleErrors(error.error);
        },
      );
    } else if (this.type === REGISTRATION_TYPE.NFMP) {
      this.accountData.documents = this.files;
      this.registrationService.submitObpsNonFacilityAccount(this.accountData).subscribe(
        result => {
          this.storeService.setAccessKey(result.entity);
          this.nextStep(this.type);
        },
        (error: HttpErrorResponse) => {
          this.stepErrors = this.registrationService.handleErrors(error.error);
        },
      );
    }
  }

  isSubAccount() {
    return this.registrationType === newSubAccount ||
      this.registrationType === newSubAccountByMop ||
      this.registrationType === cmNewSubAccount;
  }

  submitObpsNonFacilityAccount(form: FormGroup) {
    if (this.isSubAccount() && !this.isMasterAccountValid) {
      return;
    }

    if (this.isSubAccount()) {
      this.accountData.masterAccount = this.masterAccountData;
    }

    let submitMethod = 'submitObpsNonFacilityAccount';
    let uri;

    if (this.type === REGISTRATION_TYPE.CM) {
      submitMethod = 'submitOMPAccount';

      if (this.storeService.currentProgram) {
        const registrationType = window.history.state.registrationType || newAccount;
        uri = accountMap[registrationType].offset;
      }
    }
    this._showEmailConfirmationModal().pipe(
      filter(result => !!result),
      switchMap(() => this.registrationService[submitMethod](this.accountData, uri))
    )
    .subscribe(
      (result:any) => {
        this.isEmailConfirmedOnce = true;
        this.storeService.setAccessKey(result.entity);
        this.nextStep(this.type);
      },
      (error: HttpErrorResponse) => {
        this.formService.parseErrors(form, error.error);
        this.stepErrors = this.registrationService.handleErrors(error.error);
      },
    );
  }

  saveObpsNonFacilityAccount(form: FormGroup) {

    if (this.isSubAccount() && !this.isMasterAccountValid) {
      return;
    }

    if (this.isSubAccount()) {
      this.accountData.masterAccount = this.masterAccountData;
    }

    if (form.valid) {
      this.registrationService.saveObpsNonFacilityAccount(this.accountData).subscribe(
        result => {
          this.accountData.id = result.entity.id;
          this.nextStep(this.type);
        },
        (error: HttpErrorResponse) => {
          this.formService.parseErrors(form, error.error);
          this.stepErrors = this.registrationService.handleErrors(error.error);
          // alert(this.stepErrors);
        },
      );
    }
  }

  updateStep(step: any) {
    this.currentStep = step;
  }

  processForm(form: any) {
    this.uploadKycFiles();
    // this.nextStep(this.type);
  }

  closeForm() {
    switch (this.registrationType) {

      case newRegistration:
        this.router.navigateByUrl('');
        break;

      case newAccount:
      case newSubAccount:
        this.router.navigate(this.helperService.getTranslatedPath('/account-management/summary-user'));
        break;

      case newArrounByMop:
      case newSubAccountByMop:
        this.router.navigate(this.helperService.getTranslatedPath('/account-management/summary-admin'));
        break;
    }
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.files[id][property] = editField;
  }

  openUploadPopup(event) {
    { }
    event && event.preventDefault();
    this.modalService.open(UploadDocumentComponent, this.documentUploadConfig).afterClosed().subscribe((result?: any) => {
      if (result) {
        if (!this.files) {
          this.files = [];
        }

        this.uploadDocumentService.checkUploadStatus(result.id, result.signedUrl)
          .pipe(
            takeUntil(this._destroy$),
          )
          .subscribe(
            res => {

              const idx = this.files.findIndex(d => d.id === res.id);
              if (idx > -1) {
                if (res.status === 'CLEAN') {
                  const doc = { ...this.files[idx], _uploadStatus: res.status };
                  this.files[idx] = doc;
                } else {
                  this.files.splice(idx, 1);

                  const messages: IDisplayMessages = {
                    messages: [{ message: 'infectedDocument' }],
                    type: SereviceMessageType.ERROR,
                  };
                  this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                    .afterClosed()
                    .subscribe();
                }
              }
            },
          );

        this.files.push({ ...result, _uploadStatus: 'IN_PROGRESS' });
      }
    });
  }

  get isMasterAccountValid() {
    if (!this.isRegistrationSubAccount) {
      if (!this.facilityName.valid) {
        this.facilityName.markAsDirty();
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  get filesValidated() {
    if (this.files && this.files.length > 0) {
      return this.files.filter(f => f['_uploadStatus'] === 'IN_PROGRESS').length > 0;
    } else {
      return false;
    }
  }

  submitFacilities(form) {
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: 'confirmAccountsSubmission',
        metaData: this.translateService.instant('submit'),
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.processForm(form);
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload(event) {
    if (this.documentUploadInProgress.isInProgress()) {
      event.preventDefault();
      event.returnValue = 'Documents are still processing';
      return event;
    }
    return true;
  }

  get translationLoading(): Observable<boolean> {
    return this.authService.translationLoading$;
  }

  ngOnDestroy() {
    if (this.registrationSteps && this.registrationSteps.length > 0) {
      this.registrationSteps.map(step => step.completed = false);
    }
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _setOMPAccountFormHeader() {
    if (this.accountData.accountSubType.name === 'ORGANIZATION') {
      this.header = 'REGISTRATION_PAGE.accountTypeSection.accountTypeRegistrationOrganization';
      this.subHeader = 'REGISTRATION_PAGE.accountTypeSection.accountTypeRegistrationOrganizationSubHeader';
    } else if (this.accountData.accountSubType.name === 'PUBLIC_GOVERNMENT') {
      this.header = 'REGISTRATION_PAGE.accountTypeSection.nfmpAccountRegistration';
      this.subHeader = 'REGISTRATION_PAGE.accountTypeSection.nfmpAccountRegistrationSubHeader';
    } else {
      this.header = 'REGISTRATION_PAGE.accountTypeSection.accountTypeRegistrationIndividual';
    }
  }

  private _showEmailConfirmationModal() {
    return this.modalService.open(ServiceMessageComponent, {
        messages: null,
        yesNoButton: true,
        message: 'confirmationMessageRegistrationEmail',
        metaDataList: [
          this.accountData.authorizedOfficial.email,
          this.accountData.legalName
        ],
        type: SereviceMessageType.WARNING,
      },
      true, DEFAULT_DIALOG_CONFIG).afterClosed();
  }

  private _businessStructureChangeSubscription() {
    this.formFactoryService.dictCallback
      .pipe(
        takeUntil(this._destroy$),
        filter(field => field.key === 'businessStructure.id'),
      )
      .subscribe(() => {
        if (this.accountData.businessStructure &&
          this.accountData.businessStructure.id === this._getBusinessStructureIdByProgramType()) {
          this.accountData.charitableFirm = true;
        } else {
          this.accountData.charitableFirm = false;
        }
      });
  }

  private _getBusinessStructureIdByProgramType() {
    return this.accountData.programType.toLowerCase() === REGISTRATION_TYPE.CM ?
      BUSINESS_STRUCTURE_IDS_BY_PROGRAM.CM : BUSINESS_STRUCTURE_IDS_BY_PROGRAM.OFFSET;
  }
}
