import { commonActionsLabelTranslationPrefix, commonFilterTranslationPrefix } from '@core/models/filter-types.model';
import { ActionOptionType, ITableFilterActionModel } from '@shared/models/table-filter.models';
import { IRawFormBase } from '@core/models/raw-form.types';
import {
  CCR_CHARGING_STATION_ADDRESS_POPUP,
  CCR_PREFIX
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';

export enum publicProjectRoutes {
  Issuance = 'issuance',
  ProjectDetails = 'details',
  ProjectDocuments = 'documents',
  SubProjects = 'sub-projects',
}

export const PublicProjectsTableFilter:  ITableFilterActionModel = {
  searchBox: true,
  dateRange: true,

  filterListsModel: [],

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PUBLIC_PROJECT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PUBLIC_PROJECT',
        },
      ],
    },
  ],

  options: {
    dateRange: {
      placeholder: 'PUBLIC_PROJECTS_MODULE.projectList.registrationDate',
    },
  },
};

export const PublicUnitsTableFilter:  ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [],

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PUBLIC_UNITS',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PUBLIC_UNITS',
        },
      ],
    },
  ],
};

export const UNIT_DETAILS_FORM: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-6',
        key: 'unitAccount.legalName',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.legalName',
        disabled: true,
        required: false,
        id: 'legalName',
      },
      {
        type: 'input',
        className: 'col-6',
        key: 'unitAccount.organizationEmail',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.organizationEmail',
        disabled: true,
        required: false,
        hideExpression: `!model['organizationEmail']`,
        id: 'organizationEmail',
      },
    ],
  },
];

export const UNIT_DETAILS_AO_CONTACT_FIELDS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'template',
        className: 'col-12 font-weight-bold mt-4 mb-n3 arial-font-14',
        parse: true,
        translate: true,
        label: 'PUBLIC_UNITS_MODULE.unitDetails.aoHeader',
      },
      {
        type: 'input',
        key: 'unitAccount.authorizedOfficialName',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.aoName',
        disabled: true,
        required: false,
        className: 'col-6',
        id: 'authorizedOfficialName',
      },
      {
        type: 'input',
        key: 'unitAccount.aoEmail',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.aoEmail',
        disabled: true,
        required: false,
        className: 'col-6',
        id: 'aoEmail',
      },
      {
        type: 'input',
        key: 'unitAccount.aoNumber',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.aoNumber',
        placeholder:'COMMON.messageSection.phonePlaceholder',
        disabled: true,
        required: false,
        className: 'col-6',
        id: 'aoNumber',
      },
    ],
  },
];

export const UNIT_DETAILS_REST_FIELDS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'readonly-input',
        key: 'projectIndegeniousLed',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.projectIndegeniousLed',
        disabled: true,
        required: false,
        className: 'col-6',
        translate: true,
        translatePrefix: 'COMMON.yesNoList',
        id: 'projectIndegeniousLed',
      },
      {
        type: 'input',
        key: 'benefecialOwner',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.beneficialOwner',
        disabled: true,
        required: false,
        className: 'col-6',
        hideExpression: `!model['benefecialOwner']`,
        translatePrefix: 'COMMON.yesNoList.',
        id: 'beneficialOwner',
      },
      {
        type: 'readonly-input',
        key: 'province',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.beneficialOwnerJurisdiction',
        disabled: true,
        required: false,
        className: 'col-6',
        hideExpression: `!model['province']`,
        translate: true,
        translatePrefix: 'COMMON.jurisdictionsList',
        id: 'beneficialOwnerJurisdiction',
      },
      {
        type: 'readonly-input',
        key: 'cancellationReason',
        label: 'PUBLIC_UNITS_MODULE.unitDetails.cancellationReason',
        disabled: true,
        required: false,
        className: 'col-12',
        translate: true,
        translatePrefix: 'COMMON.cancellationList',
        hideExpression: `!model['cancellationReason']`,
        id: 'cancellationReason',
      },
    ],
  },
];

export const UNIT_ORG_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: `PUBLIC_UNITS_MODULE.unitDetails.`,
  key: 'unitAccount.civicAddress',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true
  },
  hideLatLong: true,
  optional: true,
  disabled: true,
  className: 'col-12'
};

export const UNIT_AO_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: `PUBLIC_UNITS_MODULE.unitDetails.`,
  key: 'unitAccount.aoPostalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true,
  },
  hideLatLong: true,
  optional: true,
  disabled: true,
};

export const UNIT_AO_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: `PUBLIC_UNITS_MODULE.unitDetails.`,
  key: 'unitAccount.aoCivicAddress',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true
  },
  hideLatLong: true,
  optional: true,
  disabled: true,
};
