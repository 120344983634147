import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input, NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import IDocument = IApp.IDocument;
import { environment } from '@env/environment';
import {
  CreditAdjustmentReportViewAddressComponent
} from '@module/credit-adjustment-report/components/credit-adjustment-report-view-address/credit-adjustment-report-view-address.component';
import {
  ICreditCreationReport,
} from '@module/credit-creation-reports/credit-creation-reports.types';
import {
  CreditCreationReportsService,
} from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { Subject } from 'rxjs';
import { Dialog } from 'primeng';
import { CARApplyPrefix } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';

declare var ResizeObserver;

@Component({
  selector: 'app-add-details-adjustment-form',
  templateUrl: './add-details-adjustment-form.component.html',
  styleUrls: ['./add-details-adjustment-form.component.scss'],
})
export class AddDetailsAdjustmentFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() form = new FormGroup({});
  @Input() ccrForm = new FormGroup({});
  @Input() masterProject: any;
  @Output() modelIdUpdate = new EventEmitter();

  @ViewChild('leftCol', {static: true}) leftCol: ElementRef;
  @ViewChild('rightCol', {static: true}) rightCol: ElementRef;
  @ViewChild('dialog', { static: false }) dialog: Dialog;
  public report: ICreditCreationReport;

  height;
  observer;
  private initial = true;

  // Adjustment report model
  public model: any = {};
  public fields: FormlyFieldConfig[];

  // Original CCR model
  public ccrModel: any = {};
  public ccrFields: FormlyFieldConfig[];

  public needMoreInfoFields: FormlyFieldConfig[];
  public documents: IDocument[];
  public isDocumentUploadInProgress = false;

  public visible = true;
  private _destroy$ = new Subject<any>();

  public currentStepObj;
  public downloadSelected;
  public taskList = [];
  public documentUploadDisabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddDetailsAdjustmentFormComponent>,
    private service: CreditCreationReportsService,
    private uploadDocumentService: UploadDocumentService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private zone: NgZone,
  ) { }

  ngOnInit() {
    this.currentStepObj = this.data.currentStepObj;
    this.currentStepObj.onBeforeAddDetails();
    this.data.section && (this.currentStepObj.section = this.data.section);
    this.currentStepObj.dialogRef = this.dialogRef;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentStepObj.ccrForm.disable();
      this.currentStepObj.disableExtraSections();
    });
    this.initResizeObserver();   
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  public save() {
    if (!this.requiredNestedFields) {
      return;
    }
    if (this.currentStepObj.detailSectionDocumentUploadConfig &&
        !this.uploadDocumentService.checkMandatoryDocuments(
          this.currentStepObj.detailSectionDocumentUploadConfig,
          this.documents,
          this.currentStepObj.optionalDocsWithPlaceholderCodes,
        )) {
        return;
    }
    this.currentStepObj.save(this.dialogRef);
  }

  public close(): void {
    if (this.currentStepObj.form.touched) {
      const sub$ = this.service.openConfirmationModal('confirmOnClose').subscribe(result => {
        sub$.unsubscribe();
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  documentUploadHandler(documentResult: IDocument[]): void {
    this.documents = documentResult;
    this.isDocumentUploadInProgress = documentResult
      .filter(document => document._uploadStatus)
      .some(document => document._uploadStatus !== 'CLEAN');
  }

  public downloadTemplate(ev) {
    let uri;
    switch (ev.id) {
      case 'DOCUMENT_REQUIRED_FOR_INFROMATION_ON_PUBLIC_CHARGING_STATIONS':
        uri = '/account-service/document/downloadChTemplate/false';
        break;
      case 'DOCUMENT_REQUIRED_FOR_INFROMATION_ON_PRIVATE_CHARGING_STATIONS':
        uri = '/account-service/document/downloadChTemplate/true';
        break;
      default:
        return;
    }
    this.uploadDocumentService.downloadDocument(`${ environment.apiUrl }${uri}`);
    setTimeout(() => {
      this.downloadSelected = null;
      (document.activeElement as HTMLElement).blur();
      }, 0,
    );
  }

  private get requiredNestedFields(): boolean {
    const missingNestedFields = this.currentStepObj.validateRequiredNestedFields;
    if (Object.keys(missingNestedFields).length) {
      this.nestedFieldsRequired(missingNestedFields);
      return false;
    } else {
      return true;
    }
  }

  private nestedFieldsRequired(missingFields) {
    let msg = '';
    Object.keys(missingFields).forEach(key => msg += `<li>${ this.translateService.instant(missingFields[key]) }</li>`);
    const metaData = [`<ol type="a">${ msg }</ol>`];
    this.modalService.open(
      ServiceMessageComponent,
      {
        messages: [
          {
            message: 'mandatoryNestedFieldsRequired',
            metaData,
          },
        ],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    );
  }

  private _hasDocumentConfigs() {
    return this.currentStepObj.detailSectionDocumentUploadConfig &&
      this.currentStepObj.detailSectionDocumentUploadConfig.documentUploadConfigs;
  }

  private initResizeObserver() {
    this.observer = new ResizeObserver(entries => {
      this.zone.run(() => this.adjustRowHeight(entries[0].target));
    });
    const rightColRows = Array.from(this.rightCol.nativeElement.querySelector('formly-group').children);
    rightColRows.forEach((row: HTMLElement, idx: number) => {
        row.setAttribute('data-index', String(idx));
        this.observer.observe(row);
      });

    rightColRows.forEach(el => this.adjustRowHeight(el as HTMLElement, true));
  }

  private removeResizeObserver() {
    Array.from(this.rightCol.nativeElement.querySelector('formly-group').children)
      .forEach((row: HTMLElement, idx: number) => {
        row.setAttribute('data-index', String(idx));
        this.observer.observe(row);
      });
  }

  private adjustRowHeight(el: HTMLElement, initial: boolean = false): void {
    const leftColItems = this.leftCol.nativeElement.querySelector('formly-group').children;
    const rowIndex = Number(el.getAttribute('data-index'));
    const rowHeight = el.clientHeight;
    const leftRowHeight = leftColItems[rowIndex].clientHeight;
    if (initial && leftRowHeight >= rowHeight) {
      el.style.minHeight = `${leftRowHeight}px`;
      leftColItems[rowIndex].style.minHeight = `${leftRowHeight}px`;
    } else {
      leftColItems[rowIndex].style.height = `${rowHeight}px`;
    }
  }

  public viewFacilityDetails(event) {
    event && event.preventDefault();
    this.modalService.open(CreditAdjustmentReportViewAddressComponent, {
      facility: this.currentStepObj.ccrModel.facility,
      tooltip: this.getTooltip()
    }, true).afterClosed();
  }

  getTooltip(){
    let stepCode = this.currentStepObj.model.step.code;
    switch (stepCode) {
      case 'STEP-1':
        return CARApplyPrefix('step1_tooltip','.tooltip');
      case 'STEP-2':
        return CARApplyPrefix('step2_tooltip','.tooltip')
      case 'STEP-3':
        return CARApplyPrefix('step3_tooltip','.tooltip')
      case 'STEP-4':
        return CARApplyPrefix('step4_tooltip','.tooltip')
      case 'STEP-5':
        return CARApplyPrefix('step5_tooltip','.tooltip')
      case 'STEP-6':
        return CARApplyPrefix('step6_tooltip','.tooltip')
      case 'STEP-7':
        return CARApplyPrefix('step7_tooltip','.tooltip')
      case 'STEP-8':
        return CARApplyPrefix('step8_tooltip','.tooltip')

    }
  }

  ngOnDestroy() {
    this.initial = true;
    this._destroy$.next();
    this._destroy$.complete();
    this.removeResizeObserver();
  }
 
}
