/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./previous-discussion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i7 from "@fortawesome/angular-fontawesome";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i10 from "./previous-discussion.component";
var styles_PreviousDiscussionComponent = [i0.styles];
var RenderType_PreviousDiscussionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviousDiscussionComponent, data: {} });
export { RenderType_PreviousDiscussionComponent as RenderType_PreviousDiscussionComponent };
function View_PreviousDiscussionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" (", ": ", ") "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform((_co.translationPrefix + "timestamp"))); var currVal_1 = _v.parent.context.$implicit.commentTimestampStr; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PreviousDiscussionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "comments-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.commentTimestampStr; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform((_co.translationPrefix + "adminComments"))); _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit.comment; _ck(_v, 8, 0, currVal_2); }); }
function View_PreviousDiscussionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "offset-1 col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_2)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.discussions[0].comments; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PreviousDiscussionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "button", [["class", "btn btn-transparent"], ["ngbPanelToggle", ""], ["type", "button"]], [[8, "disabled", 0], [2, "collapsed", null], [1, "aria-expanded", 0], [1, "aria-controls", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).accordion.toggle(i1.ɵnov(_v, 1).panel.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgbPanelToggle, [i5.NgbAccordion, [2, i5.NgbPanel]], { ngbPanelToggle: [0, "ngbPanelToggle"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i6.View_FaIconComponent_0, i6.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i7.FaIconComponent, [i8.DomSanitizer, i7.FaConfig, i7.FaIconLibrary, [2, i7.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Toggle"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h2", [["class", "ml-2 d-inline"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_4 = ""; _ck(_v, 1, 0, currVal_4); var currVal_7 = _ck(_v, 4, 0, "fas", (_v.context.opened ? "chevron-down" : "chevron-up")); _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).panel.disabled; var currVal_1 = !i1.ɵnov(_v, 1).panel.isOpen; var currVal_2 = i1.ɵnov(_v, 1).panel.isOpen; var currVal_3 = i1.ɵnov(_v, 1).panel.id; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 3).title; var currVal_6 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform((_co.translationPrefix + "previousDiscussion"))); _ck(_v, 8, 0, currVal_8); }); }
function View_PreviousDiscussionComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "comments-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["(", ": ", ")"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform((_co.translationPrefix + "adminComments"))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform((_co.translationPrefix + "timestamp"))); var currVal_2 = _v.context.$implicit.commentTimestampStr; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.comment; _ck(_v, 9, 0, currVal_3); }); }
function View_PreviousDiscussionComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "comments-section section-start"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ":"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "small", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["(", ": ", ")"])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_9)), i1.ɵdid(12, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_4 = _v.parent.context.$implicit.comments; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform((_co.translationPrefix + "response"))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform((_co.translationPrefix + "timestamp"))); var currVal_2 = _v.parent.context.$implicit.userCommentTimestampStr; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _v.parent.context.$implicit.userComment; _ck(_v, 10, 0, currVal_3); }); }
function View_PreviousDiscussionComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_8)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.userComment; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PreviousDiscussionComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_PreviousDiscussionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "offset-1 col-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_7)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_10)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.discussions; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.customContent; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PreviousDiscussionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ngb-accordion", [["class", "accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], null, null, i9.View_NgbAccordion_0, i9.RenderType_NgbAccordion)), i1.ɵdid(1, 2146304, null, 1, i5.NgbAccordion, [i5.NgbAccordionConfig], { closeOtherPanels: [0, "closeOtherPanels"] }, null), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "ngb-panel", [], null, null, null, null, null)), i1.ɵdid(4, 2113536, [[1, 4]], 3, i5.NgbPanel, [], null, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { headerTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_PreviousDiscussionComponent_5)), i1.ɵdid(9, 16384, [[3, 4]], 0, i5.NgbPanelHeader, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_PreviousDiscussionComponent_6)), i1.ɵdid(11, 16384, [[4, 4]], 0, i5.NgbPanelContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_1 = true; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
export function View_PreviousDiscussionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousDiscussionComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasUnansweredComment; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.anyPreviousDiscussions; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_PreviousDiscussionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-previous-discussion", [], null, null, null, View_PreviousDiscussionComponent_0, RenderType_PreviousDiscussionComponent)), i1.ɵdid(1, 49152, null, 0, i10.PreviousDiscussionComponent, [], null, null)], null, null); }
var PreviousDiscussionComponentNgFactory = i1.ɵccf("app-previous-discussion", i10.PreviousDiscussionComponent, View_PreviousDiscussionComponent_Host_0, { discussions: "discussions", customContent: "customContent", translationPrefix: "translationPrefix" }, {}, ["*"]);
export { PreviousDiscussionComponentNgFactory as PreviousDiscussionComponentNgFactory };
