<ng-container *ngIf="accountId; else new">
  <div class="inner">
    <div class="panel">
      <div class="max-width mb-2">
        <ng-container *ngTemplateOutlet="form"></ng-container>
        <div class="mt-2 button-panel">
          <button type="button" class="btn btn-primary" (click)="save()" *ngIf="showSave">  {{ 'REGISTRATION_PAGE.offsetRegistrationForm.save' | translate }}</button>
          <button type="button" class="btn btn-secondary mr-2" (click)="close()">  {{ 'REGISTRATION_PAGE.offsetRegistrationForm.close' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #new>
  <ng-container *ngTemplateOutlet="form"></ng-container>
  <div class="mt-2">
    <button class="btn btn-secondary mr-2" (click)="previousStep()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>

    <button *ngIf="onestep; else nextStepLbl" id="offset-stp2-btn" class="btn btn-primary" (click)="nextStep()">
      {{ 'COMMON.actionsLabel.SUBMIT' | translate }}</button>
    <ng-template #nextStepLbl >
      <button
        (click)="nextStep()"
        class="btn btn-primary"
        id="offset-stp2-btn"
      >
        {{ 'REGISTRATION_PAGE.offsetRegistrationForm.nextStepButton' | translate }}
      </button>
    </ng-template>

  </div>
</ng-template>

<ng-template #form>
  <div id="offset-accunt-type">
    <div class="row">
      <div class="col-12">
        <h1 *ngIf="onestep; else titleSubacc">{{ isCM ?
          ('REGISTRATION_PAGE.accountTypeSection.cmAccountRegistrationTitleSUBACC' | translate) :
          ('REGISTRATION_PAGE.accountTypeSection.offsetAccountRegistrationTitleSUBACC' | translate) }}</h1>
        <ng-template #titleSubacc>
          <h1>{{ isCM ?
            ('REGISTRATION_PAGE.accountTypeSection.nfmpAccountRegistration' | translate) :
            ('REGISTRATION_PAGE.accountTypeSection.offsetAccountRegistrationTitle' | translate) }}</h1>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h2>{{ 'REGISTRATION_PAGE.accountTypeSection.offsetAccRegSubTitle' | translate }}</h2>
      </div>
    </div>
    <form [formGroup]="formGroup" #ngForm>
      <formly-form [form]="formGroup" [model]="model" [fields]="fields"></formly-form>
    </form>
  </div>
</ng-template>
