<div class="card mb-3">
  <div class="border-bottom">
    <div class="card-header d-flex justify-content-between">
      <div><strong>{{title}}</strong></div>
      <div class="d-flex">
        <!-- <span><fa-icon [icon]="clone"></fa-icon></span> -->
        <!-- <span class="ml-"><fa-icon [icon]="pencil"></fa-icon></span> -->
        <!-- <span class="mx-2"><fa-icon [icon]="trash"></fa-icon></span> -->
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-6">
        <span>{{ 'PROJECTS_MODULE.ProjectForms.province' | translate}}:</span>
      </div>
      <div class="col-6">{{activity.provinceNames}}</div>
      <div class="col-6">
        <span>{{ 'PROJECTS_MODULE.ProjectForms.ghgOffsetProtocols' | translate}}:</span>
      </div>
      <div class="col-6">{{activity.protocolName}}</div>
      <!-- <div class="col-6">
        <label for="crediting">Crediting Period:</label>
      </div>
      <div class="col-6">{{activity.creditingPeriodStartDate | date: 'yyyy-MM-dd'}} - {{activity.creditingPeriodEndDate | date: 'yyyy-MM-dd'}}</div> -->
      <div class="col-6">
        <span [innerHTML]="'PROJECTS_MODULE.ProjectForms.estimatedReductions' | translate">:</span>
      </div>
      <div class="col-6">{{activity.activity.estimatedScenarioGhg | localizedNumber: '1.'}}</div>
    </div>
  </div>
</div>

