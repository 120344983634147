<app-header></app-header>

<nav class="navbar navbar-toggleable-md  navbar-main navbar-expand-md position-relative">
  <button class="navbar-toggler navbar-toggler-right"
          type="button"
          (click)="isNavbarCollapsed = !isNavbarCollapsed"
          aria-controls="mainCollapsingMenuBar"
          aria-expanded="false"
          aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isNavbarCollapsed"
       class="collapse navbar-collapse"
       id="mainCollapsingMenuBar">
    <ul class="navbar-nav mr-auto"
        *ngIf="userData">

      <li class="nav-item" *ngFor="let menuItem of userData.menuItems">
        <a
          [id]="menuItem.name"
          class="nav-link"
          wcag-dummy-link-target
          [routerLink]="getTranslatedPath(menuItem.path)"
          [routerLinkActive]="['']"
          #rla="routerLinkActive"
          [ngClass]="rla.isActive || menuItem.isActive ? 'active' : ''"
        >{{ 'COMMON.menuSection.' + menuItem.translatedName | translate }}
        </a>
      </li>

    </ul>
  </div>
 <app-facility-account-chooser></app-facility-account-chooser>
</nav>


<div class="page-content" id="main-content" tabindex="-1">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
<p-toast></p-toast>
