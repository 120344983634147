import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'formly-field-chips',
  template: `
    <div [id]="elementId + '_chips'">
      <input type="hidden" [id]="elementId" class="wcag-visuallyhidden">
      <div class="mb-2">
        <ng-container *ngFor="let item of list; let i = index">
          <div class="badge badge-primary badge-tag simple-tag">
          {{ item }}
            <button type="button" class="close" aria-label="Close / Delete" (click)="remove(i)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="input-group mb-3 col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newItem"
                 [class.ng-invalid]="formControl.invalid"
                 [formlyAttributes]="field"
                 [placeholder]="placeholder"
                 [attr.maxLength]="maxLength ? maxLength : null">
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button" (click)="addItem()" aria-label="Add item">{{ buttonText }}</button>
            </div>
        </div>
      </div>
    </div>
  `,
})
export class FormlyFieldChips extends FieldType implements OnInit {

  public placeholder: string;
  public label: string;
  public translatePrefix;
  public newItem;
  public buttonText;
  public maxLength;

  private _list = [];

  constructor(
    private translateService: TranslateService,
  ) {
    super();
  }

  get list() {
    return this._list;
  }

  set list(val) {
    this._list = val;
    this.propagateChange(this._list);
  }

  propagateChange(val) {
    this.model[this.key] = val;
    if (this.to.change) {
      this.to.change(this.field, val);
    }
  }

  ngOnInit() {
    this.translatePrefix = this.to.translatePrefix ? `${ this.to.translatePrefix }.` : '';
    this.to.placeholder && (this.placeholder = this.translateService.instant(`${ this.translatePrefix }${ this.to.placeholder }`));
    this.to.label && (this.to.label = this.translateService.instant(`${ this.translatePrefix }${ this.to.label }`));
    this.to.buttonText && (this.buttonText = this.translateService.instant(`${ this.translatePrefix }${ this.to.buttonText }`));
    this.maxLength = this.to.maxLength ? this.to.maxLength : null;
    this.list = this.model[this.key] || [];
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.field.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  addItem() {
    if (this.newItem) {
      this.list = [...this.list, this.newItem];
      this.newItem = null;
    }
  }

  remove(index) {
    this.list.splice(index, 1);
    this.propagateChange(this.list);
  }

}
