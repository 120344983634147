<p-dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="true"
  [closable]="false"
  [header] = "'PUBLIC_UNITS_MODULE.unitDetails.title' | translate">



  <form [formGroup]="form" #ngForm>
    <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
  </form>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{'PUBLIC_UNITS_MODULE.unitDetails.close' | translate}}</button>
  </div>
</p-dialog>
