import { ViewChild, AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IApp } from '@core/models/app.interfaces';
import IDocument = IApp.IDocument;
import { Dialog } from 'primeng';

@Component({
  selector: 'app-credit-creation-additional-details',
  templateUrl: './compliance-report-additional-details.component.html',
  styleUrls: ['./compliance-report-additional-details.component.scss'],
})
export class ComplianceReportAdditionalDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('dialog', { static: false }) dialog: Dialog;

  public currentStepObj;
  public visible = true;
  public documents: IDocument[];
  public isDocumentUploadInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ComplianceReportAdditionalDetailsComponent>,
  ) { }

  ngOnInit() {
    this.currentStepObj = this.data.currentStepObj;
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentStepObj.additionalDetailsForm.disable();
    });
  }

  documentUploadHandler(documentResult: IDocument[]): void {
    this.documents = documentResult;
    this.isDocumentUploadInProgress = documentResult
      .filter(document => document._uploadStatus)
      .some(document => document._uploadStatus !== 'CLEAN');
  }

  public close(): void {
    this.dialogRef.close(false);
  }

}
