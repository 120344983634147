<div class="row justify-content-center">
  <div class="col-sm-8 col-md-6">

    <ng-container *ngIf="lang === 'en'">
      <h1>CYBER AUTHENTICATION FREQUENTLY ASKED QUESTIONS FOR USERS</h1>
      <div class="date">January 2024</div>

      <div>
        <div>
          <h2>TABLE OF CONTENTS</h2>
        </div>

        <div>
          <h3>In General</h3>
          <ol>
            <li><a href="{{ url }}#General1">Why is the Government of Canada offering users a choice of login credentials?</a></li>
            <li><a href="{{ url }}#General2">Are the Sign-In Partner credentials as secure as the ones issued by the Government of Canada?</a></li>
            <li><a href="{{ url }}#General3">I am already enrolled with one Government of Canada service. Why do I have to provide basic identity information again to enroll in services with other departments?</a></li>
          </ol>
        </div>
        <div>
          <h3>About using a Sign-In Partner</h3>
          <ol>
            <li><a href="{{ url }}#signin1">What is a Sign-In Partner?</a></li>
            <li><a href="{{ url }}#signin2">Who are the Sign-In Partners?</a></li>
            <li><a href="{{ url }}#signin3">What are the advantages of using my Sign-In Partner credential?</a></li>
            <li><a href="{{ url }}#signin4">Is any of my personal information shared with the government if I use a Sign-In Partner?</a></li>
            <li><a href="{{ url }}#signin5">The financial institution I do business with is not on the list of Sign-In Partners. What alternative do I have to access government services online?</a></li>
            <li><a href="{{ url }}#signin6">I bank with one of the Sign-In Partners on your list, but I’m not registered to bank online.  Am I still able to access Government of Canada services using my Sign-In Partner?</a></li>
            <li><a href="{{ url }}#signin7">My Sign-In Partner knows who I am so why do I still have to provide my basic identity information to enroll in the Government of Canada service?</a></li>
            <li><a href="{{ url }}#signin8">If my bank issues me a new bank card (with new numbers), can I still access my Government Online Services?</a></li>
          </ol>
        </div>
        <div>
          <h3>About GCKey</h3>
          <ol>
            <li><a href="{{ url }}#gckey1">What is GCKey?</a></li>
            <li><a href="{{ url }}#gckey2">Why has the Government of Canada introduced GCKey?</a></li>
            <li><a href="{{ url }}#gckey3">How do I get a GCKey?</a></li>
            <li><a href="{{ url }}#gckey4">Can I use the same GCKey to access several services?</a></li>
            <li><a href="{{ url }}#gckey5">Am I able to access all of the government’s online services with my GCKey?</a></li>
            <li><a href="{{ url }}#gckey6">Who has access to the information I submit when I register for a GCKey?</a></li>
          </ol>

        </div>
        <div>
          <h3>About the Canada Revenue Agency (CRA) Credential</h3>
          <ol>
            <li><a href="{{ url }}#cra1">I am registered with the Canada Revenue Agency (CRA) and use a CRA User ID and password to access the CRA’s login services.  Can I continue to use my CRA credential?</a></li>
            <li><a href="{{ url }}#cra2">Can I use a Sign-In Partner to access the Canada Revenue Agency (CRA) online services?</a></li>
            <li><a href="{{ url }}#cra3">Can I use a GCKey to access my Canada Revenue Agency (CRA) online services?</a></li>
            <li><a href="{{ url }}#cra4">Which credentials can I use to access online services at the Canada Revenue Agency (CRA)?</a>
            </li>
          </ol>
        </div>

      </div>
      <div>

        <div>
          <h3>In General</h3>
          <ol>

            <li id="General1">Why is the Government of Canada offering users a choice of login credentials?

              <p class="p-content">
                By offering a choice of credentials, the government is making its online services more convenient for clients to access. Many individuals regularly use their online credentials for banking or paying bills, so being able to use the same credential to access government services online means one less user name and password for clients to remember.
              </p>
            </li>

            <li id="General2">Are the Sign-In Partner credentials as secure as the ones issued by the Government of Canada?

              <p class="p-content">
                Yes. The same authentication (or log in) process and security requirements are used for the Sign-In Partner credentials and the government-branded credential called GCKey. Your privacy is respected in that no personally identifiable information such as name, birth date, etc. is exchanged during the authentication process.
              </p>
            </li>

            <li id="General3">I am already enrolled with one Government of Canada service. Why do I need to provide basic identity information again to enroll in services from other departments?

              <p class="p-content">
                Each government service requests your personal information based on their specific requirements and to determine eligibility or entitlement. The Government of Canada must adhere to the Privacy Act and other legislation that restrict the information that can be shared between departments and agencies.
              </p>
            </li>

          </ol>
        </div>

        <div>
          <h3>About using a Sign-In Partner</h3>
          <ol>
            <li id="signin1">What is a Sign-In Partner?

              <p class="p-content">
                Sign-In Partners are private sector companies and organizations that have partnered with Interac (Interac sign-in service) to enable their customers to use their online credentials (e.g. card numbers or user names and passwords) to access Government of Canada services.
                <br/><br/>

                Currently there are twenty one Sign-In Partners:<br/>
                Affinity Credit Union<br />
                Alberta Treasury Branches<br />
                BMO Financial Group<br />
                Caisse Alliance <br />
                CIBC Canadian Imperial Bank of Commerce<br />
                Coast Capital Savings<br />
                Conexus Credit Union<br />
                connectFirst<br />
                Desjardins Group (Caisses Populaires)<br />
                Libro Credit Union<br />
                Meridian Credit Union<br />
                National Bank of Canada<br />
                RBC Royal Bank <br />
                Scotiabank <br />
                Servus Credit Union<br />
                Simplii Financial<br />
                Tangerine <br />
                TD Bank Group<br />
                UNI<br />
                Vancity<br />
                Wealthsimple<br />
              </p>
            </li>


            <li id="signin2">Who are the Sign-In Partners?

              <p class="p-content">
                There are currently twenty one private sector organizations that have partnered with Interac.
                <br/>
                These are:<br/>
                Affinity Credit Union<br />
                Alberta Treasury Branches<br />
                BMO Financial Group<br />
                Caisse Alliance <br />
                CIBC Canadian Imperial Bank of Commerce<br />
                Coast Capital Savings<br />
                Conexus Credit Union<br />
                connectFirst<br />
                Desjardins Group (Caisses Populaires)<br />
                Libro Credit Union<br />
                Meridian Credit Union<br />
                National Bank of Canada<br />
                RBC Royal Bank <br />
                Scotiabank <br />
                Servus Credit Union<br />
                Simplii Financial<br />
                Tangerine <br />
                TD Bank Group<br />
                UNI<br />
                Vancity<br />
                Wealthsimple<br />
                <br/>

                It is anticipated that other Sign-In Partners will be added in the future, so keep checking the list of Sign-In Partners when you log in to access Government of Canada services.
              </p>
            </li>


            <li id="signin3">What are the advantages of using my Sign-In Partner credential?

              <p class="p-content">
                Your existing credentials, such as your online banking credential, are familiar as you may already use them on a regular basis. Using your credential from one of the Sign-In Partners to access government services is convenient as you don’t have to remember a different User ID and password.<br>
                The safeguarding of Canadians’ information online is a priority for the Government of Canada and measures have been put in place to ensure secure online access which respects privacy.<br>
                Remember that when you use a Sign-In Partner, none of your banking or personally identifiable information is communicated to the government service you are accessing and the Government of Canada does not know which Sign-In Partner you have used. Similarly, no information about the government service being accessed by the user is shared with the user's Sign-In Partner.
              </p>
            </li>


            <li id="signin4">Is any of my personal information shared with the government if I use a Sign-In Partner?

              <p class="p-content">
                No. Your personal information, such as name, address, etc. is not shared when you use a Sign-In Partner.<br>
                The technology is designed in such a way that the Government of Canada will not know which Sign-In Partner you have used. Similarly, the Sign-In Partner will not know which government service its customer has accessed. Also, the credential broker which facilitates this interaction, will not know the identity of the individual or business.
              </p>
            </li>


            <li id="signin5">The financial institution I do business with is not on the list of Sign-In Partners.  What alternative do I have to access government services online?

              <p class="p-content">
                Individuals who do not have a credential (e.g. username and password) with one of the Sign-In Partners can use the government-issued credential, GCKey.<br>
                To access the CRA’s services you will need to register for a CRA User ID and password.<br>
                It is expected that other Sign-In Partners will be added in the future, so keep checking the list of Sign-In Partners when you log in to access Government of Canada services.
              </p>
            </li>


            <li id="signin6">I bank with one of the Sign-In Partners on your list, but I’m not registered to bank online.  Am I still able to access Government of Canada services using my Sign-In Partner?

              <p class="p-content">
                To log in using a Sign-In Partner that is a financial institution or bank, you must be registered for online banking access.  Contact your bank for assistance.
              </p>
            </li>


            <li id="signin7">My Sign-In Partner knows who I am so why do I still have to provide my basic identity information to enroll in a Government of Canada service?

              <p class="p-content">
                The Sign-In Partner does not provide any identity information to the Government and simply validates your user name or card number and password.  Each government service requires your personal information based on their specific needs and to determine eligibility or entitlement.
              </p>
            </li>

            <li id="signin8">If my bank issues me a new bank card (with new numbers), can I still access my Government Online Services?

              <p class="p-content">
                After receiving your new bank card (with new numbers), you may be required to re-register with your Government Online Service using the new card number.
              </p>
            </li>
          </ol>

        </div>

        <div>
          <h3>About GCKey</h3>

          <ol>
            <li id="gckey1">What is GCKey?

              <p class="p-content">
                A GCKey is a unique electronic credential (username and password) that allows you to communicate
                securely with
                online government services. The Government of Canada, as the service provider, issues these credentials
                to
                individuals so they can access government online services.
              </p>
            </li>

            <li id="gckey2">Why has the Government of Canada introduced GCKey?

              <p class="p-content">
                GCKey is a Government of Canada service that issues credentials (username and password) that are used to
                access (or log in to) federal government services online. The GCKey service can be used by clients who
                do not
                have, or choose not to use a credential they may have with one of the Sign-In Partners.
              </p>
            </li>
            <li id="gckey3">How do I get a GCKey?
              <p class="p-content">
                To register or sign up for a GCKey, go to the Government of Canada website that you would like to access
                and
                click on the “Continue to GCKey” button when you are presented with the log in choice.
              </p>
            </li>

            <li id="gckey4">Can I use the same GCKey to access several services?

              <p class="p-content">
                Yes. You can use the same GCKey credential to access all available online services, with the exception
                of the
                CRA services. Using the same credential to access many services is convenient, as you don’t have to
                remember
                several username and passwords.
                To access the CRA’s login services, you must either use one of the Sign-In Partners or register for a
                CRA User
                ID and password.
              </p>

            </li>
            <li id="gckey5">Am I able to access all of the government’s online services with my GCKey?

              <p class="p-content">
                You are able to use your GCKey to access all available government online services with the exception of
                services offered by the Canada Revenue Agency. To access the CRA’s services, you must use one of the
                Sign-In
                Partners or register for a CRA User ID and password.
              </p>
            </li>
            <li id="gckey6">Who has access to the information I submit when I register for a GCKey?

              <p class="p-content">
                The GCKey service does not share your registration information with any third party. The information you
                provide is held by the Government of Canada and is protected under the Federal Privacy Act.
              </p>
            </li>
          </ol>
        </div>
        <div>
          <h3>About the Canada Revenue Agency (CRA) Credential</h3>
          <ol>
            <li id="cra1">I am registered with the Canada Revenue Agency (CRA) and use a CRA User ID and password to
              access
              the CRA’s login services. Can I continue to use my CRA credential?
              <p class="p-content">
                Yes. You can continue to use your CRA User ID and password to access the CRA’s My Account for
                Individuals, My
                Business Account and Represent a Client online services. The CRA will continue to offer its own
                credential
                service. For more information, go to CRA login services (link to <a
                href="http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html" target="_blank">http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html</a>.)
              </p>
            </li>
            <li id="cra2">Can I use a Sign-In Partner to access the Canada Revenue Agency (CRA) online services?
              <p class="p-content">Yes. You can use a Sign-In Partner to access the CRA’s My Account for Individuals, My
                Business Account and Represent a Client online services. For more information go to CRA login services
                (link
                to: <a href="http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html" target="_blank">http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html</a>).
                Those who prefer to use a CRA user ID and password can continue to do so.
              </p>
            </li>
            <li id="cra3">Can I use a GCKey to access my Canada Revenue Agency’s (CRA) online services?
              <p class="p-content">No. To access the CRA’s login services, you must either use one of the Sign-In
                Partners or
                register for a CRA User ID and password. For more information, go to CRA login services (link to: <a
                  href="http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html" target="_blank">http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html</a>).
              </p>
            </li>
            <li id="cra4">Which credentials can I use to access online services at the Canada Revenue Agency (CRA)?
              <p class="p-content">The CRA will continue to use its CRA User ID and password service; no change is
                required
                for existing users. You can also use one of the Sign-In Partners to access the CRA’s online services,
                instead
                of, or in addition to, the CRA User ID and password. For more information, go to CRA login services
                (link to: <a href="http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html" target="_blank">http://www.cra-arc.gc.ca/esrvc-srvce/tx/psssrvcs/menu-eng.html</a>).
              </p>
            </li>
          </ol>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="lang === 'fr'">
      <div>
        <h1>AUTHENTIFICATION ÉLECTRONIQUE FOIRE AUX QUESTIONS À L’INTENTION DES UTILISATEURS</h1>

        <div class="date">janvier 2024</div>

        <div>
          <div>
            <h2>TABLE DES MATIÈRES</h2>
          </div>

          <div>
            <h3>Généralités</h3>
            <ol>
              <li><a href="{{ url }}#General1">Pourquoi le gouvernement du Canada offre-t-il aux utilisateurs un choix de
                justificatifs d’identité?</a></li>
              <li><a href="{{ url }}#General2">Le justificatif d’identité du partenaire de connexion est-il aussi sécurisé que
                celui délivré par le gouvernement du Canada?</a></li>
              <li><a href="{{ url }}#General3">Je suis déjà inscrit(e) à un service du gouvernement du Canada. Pourquoi dois-je
                fournir de nouveau des renseignements d’identité de base pour m’inscrire à des services dans d’autres
                ministères?</a></li>
            </ol>
          </div>
          <div>
            <h3>Au sujet de l’utilisation d’un partenaire de connexion</h3>
            <ol>
              <li><a href="{{ url }}#signin1">Qu’est-ce qu’un partenaire de connexion?</a></li>
              <li><a href="{{ url }}#signin2">Qui sont les partenaires de connexion?</a></li>
              <li><a href="{{ url }}#signin3">Quels sont les avantages d’utiliser mon justificatif d’identité d’un partenaire de
                connexion?</a></li>
              <li><a href="{{ url }}#signin4">Mes renseignements personnels sont-ils communiqués au gouvernement si j’utilise un
                partenaire de connexion?</a></li>
              <li><a href="{{ url }}#signin5">L’institution financière avec laquelle je fais affaire ne figure pas sur la liste
                des partenaires de connexion. Quelles sont les méthodes d’accès aux services en ligne?</a></li>
              <li><a href="{{ url }}#signin6">Je fais affaire avec un des partenaires de connexion figurant sur la liste, mais je
                ne suis pas inscrit(e) aux services bancaires en ligne. Puis-je quand même accéder aux services du
                gouvernement du Canada au moyen de mon partenaire de connexion?</a></li>
              <li><a href="{{ url }}#signin7">Mon partenaire de connexion me connaît. Pourquoi dois-je tout de même fournir mes renseignements d’identité pour m’inscrire à un service du gouvernement du Canada?</a></li>
              <li><a href="{{ url }}#signin8">Si ma banque émet une nouvelle carte bancaire (avec des nouveaux numéros), puis-je accéder aux services gouvernementaux en ligne?</a></li>
            </ol>
          </div>
          <div>
            <h3>Au sujet de la CléGC</h3>
            <ol>
              <li><a href="{{ url }}#gckey1">Qu’est-ce qu’une CléGC?</a></li>
              <li><a href="{{ url }}#gckey2">Pourquoi le gouvernement du Canada a-t-il mis en place la CléGC? </a></li>
              <li><a href="{{ url }}#gckey3">Comment puis-je obtenir une CléGC?</a></li>
              <li><a href="{{ url }}#gckey4">Puis-je utiliser la même CléGC pour accéder à plusieurs services?</a></li>
              <li><a href="{{ url }}#gckey5">Puis-je accéder à tous les services en ligne du gouvernement avec la CléGC?</a></li>
              <li><a href="{{ url }}#gckey6">Qui a accès aux renseignements que je fournis lorsque je fais une demande
                d’obtention d’une CléGC? </a></li>
            </ol>

          </div>
          <div>
            <h3>Au sujet du justificatif d’identité de l’Agence du revenu du Canada (ARC)</h3>
            <ol>
              <li><a href="{{ url }}#cra1">Je suis inscrit(e) à l’Agence du revenu du Canada (ARC) et j’utilise un nom
                d’utilisateur et un mot de passe de l’ARC pour accéder aux services en ligne de l’ARC. Puis-je continuer
                à utiliser mon justificatif d’identité de l’ARC? </a></li>
              <li><a href="{{ url }}#cra2">Puis-je utiliser un partenaire de connexion pour accéder aux services en ligne de
                l’Agence du revenu du Canada (ARC)?</a></li>
              <li><a href="{{ url }}#cra3">Puis-je utiliser une CléGC pour accéder aux services en ligne de l’Agence du revenu du
                Canada (ARC)?</a></li>
              <li><a href="{{ url }}#cra4">Quels justificatifs d’identité puis-je utiliser pour accéder aux services en ligne de
                l’Agence du revenu du Canada (ARC)?</a></li>
            </ol>
          </div>

        </div>
        <div>

          <div>
            <h3>Généralités</h3>
            <ol>

              <li id="General1">Pourquoi le gouvernement du Canada offre-t-il aux utilisateurs un choix de justificatifs
                d’identité?

                <p class="p-content">
                  En offrant un choix de justificatifs d’identité, le gouvernement rend ses services en ligne plus
                  faciles d’accès pour ses clients. Bon nombre de personnes utilisent régulièrement leur justificatif
                  d’identité en ligne pour effectuer des transactions bancaires ou payer des factures; avoir la
                  possibilité d’utiliser le même justificatif d’identité pour accéder aux services en ligne du
                  gouvernement signifie que les clients ont un nom d’utilisateur et un mot de passe de moins à
                  mémoriser.
                </p>
              </li>

              <li id="General2">Le justificatif d’identité du partenaire de connexion est-il aussi sécurisé que celui
                délivré par le gouvernement du Canada?

                <p class="p-content">
                  Oui. Le même processus d’authentification (ou de connexion) et les mêmes exigences de sécurité sont
                  utilisés pour le justificatif d’identité du partenaire de connexion et l’authentifiant du
                  gouvernement, appelé CléGC. Vos renseignements personnels sont protégés puisqu’aucun renseignement
                  permettant d’identifier une personne, comme le nom ou la date de naissance, n’est communiqué durant le
                  processus d’authentification.
                </p>
              </li>

              <li id="General3">Je suis déjà inscrit(e) à un service du gouvernement du Canada. Pourquoi dois-je fournir
                de nouveau des renseignements d’identité de base pour m’inscrire à des services dans d’autres
                ministères?

                <p class="p-content">
                  Chaque service du gouvernement vous demande des renseignements personnels en fonction de ses exigences
                  particulières et pour déterminer votre admissibilité. Le gouvernement du Canada doit respecter la Loi
                  sur la protection des renseignements personnels et d’autres lois qui limitent les renseignements qui
                  peuvent être échangés entre ministères et organismes.
                </p>
              </li>

            </ol>
          </div>

          <div>
            <h3>Au sujet de l’utilisation d’un partenaire de connexion</h3>
            <ol>
              <li id="signin1">Qu’est-ce qu’un partenaire de connexion?

                <p class="p-content">
                  Les partenaires de connexion sont des entreprises et des organisations du secteur privé qui ont établi
                  un partenariat avec Interac (Service d’ouverture de session Interac) pour permettre à leurs clients d’utiliser leur justificatif
                  d’identité en ligne (p. ex., un numéro de carte de crédit ou un nom d’utilisateur et un mot de passe)
                  pour accéder aux services du gouvernement du Canada.
                  <br/><br/>

                  On compte actuellement vingt-et-un partenaires de connexion:<br />
                  Affinity Credit Union<br />
                  Alberta Treasury Branches<br />
                  BMO Groupe financier <br />
                  Banque Nationale du Canada<br />
                  Banque Scotia<br />
                  Caisse Alliance<br />
                  CIBC  Banque Canadienne Impériale de Commerce<br />
                  Coast Capital Savings<br />
                  Conexus Credit Union<br />
                  connectFirst<br />
                  FINANCIÈRE SIMPLII <br />
                  Groupe Desjardins (Caisse Populaire)<br />
                  Groupe Banque TD<br />
                  Libro Credit Union<br />
                  Meridian Credit Union<br />
                  RBC Banque Royale <br />
                  Servus Credit Union<br />
                  Tangerine <br />
                  UNI<br />
                  Vancity<br />
                  Wealthsimple<br />
                </p>
              </li>


              <li id="signin2">Qui sont les partenaires de connexion?

                <p class="p-content">
                  On compte actuellement vingt-et-un organisations du secteur privé qui ont établi un partenariat avec Interac.
                  <br/>

                  Les voici:<br/>
                  Affinity Credit Union<br />
                  Alberta Treasury Branches<br />
                  BMO Groupe financier <br />
                  Banque Nationale du Canada<br />
                  Banque Scotia<br />
                  Caisse Alliance<br />
                  CIBC  Banque Canadienne Impériale de Commerce<br />
                  Coast Capital Savings<br />
                  Conexus Credit Union<br />
                  connectFirst<br />
                  FINANCIÈRE SIMPLII <br />
                  Groupe Desjardins (Caisses Populaires)<br />
                  Groupe Banque TD<br />
                  Libro Credit Union<br />
                  Meridian Credit Union<br />
                  RBC Banque Royale <br />
                  Servus Credit Union<br />
                  Tangerine <br />
                  UNI<br />
                  Vancity<br />
                  Wealthsimple<br />
                  <br/>

                  On prévoit que d’autres partenaires de connexion s’ajouteront à cette liste; consultez-la lorsque vous vous branchez aux services du gouvernement du Canada.
                </p>
              </li>


              <li id="signin3">Quels sont les avantages d’utiliser mon justificatif d’identité d’un partenaire de
                connexion?

                <p class="p-content">
                  Vous connaissez bien vos justificatifs d’identité actuels, comme celui que vous utilisez pour vos
                  transactions bancaires en ligne, puisque vous les utilisez déjà régulièrement. En utilisant votre
                  justificatif d’identité d’un des partenaires de connexion pour accéder aux services du gouvernement,
                  vous n’avez pas à mémoriser un nouveau nom d’utilisateur et un mot de passe.
                  La protection des renseignements des Canadiens en ligne est une priorité pour le gouvernement du
                  Canada et des mesures ont été prises pour veiller à ce que l’accès en ligne soit sécurisé et respecte
                  les renseignements personnels.
                  N’oubliez pas que lorsque vous utilisez un partenaire de connexion, aucun renseignement pouvant servir
                  à vous identifier n’est communiqué au service du gouvernement auquel vous accédez et le gouvernement
                  du Canada ne sait pas quel partenaire vous avez utilisé. De même, aucun renseignement sur le service
                  du gouvernement auquel vous accédez n’est communiqué à votre partenaire de connexion.

                </p>
              </li>


              <li id="signin4">Mes renseignements personnels sont-ils communiqués au gouvernement si j’utilise un
                partenaire de connexion?

                <p class="p-content">
                  Non, vos renseignements personnels ne sont pas communiqués lorsque vous utilisez un partenaire de
                  connexion.
                  La technologie est conçue de manière à ce que le gouvernement du Canada ne sache pas quel partenaire
                  de connexion (ou institution bancaire) vous avez utilisé. De même, votre banque ne saura pas avec quel
                  ministère ou organisme gouvernemental vous avez communiqué. Le service de courtier de justificatifs
                  d’identité qui permet cette interaction ne connaîtra pas non plus l’identité de la personne ni de
                  l’entreprise. Les institutions financières et les ministères et organismes participants ne
                  communiqueront aucun renseignement qui permettrait d’identifier une personne, conformément aux lois
                  sur la protection des renseignements personnels.

                </p>
              </li>


              <li id="signin5">L’institution financière avec laquelle je fais affaire ne figure pas sur la liste des
                partenaires de connexion. Quelles sont les méthodes d’accès aux services en ligne?

                <p class="p-content">
                  Les personnes qui n’ont pas de justificatif d’identité (p. ex., nom d’utilisateur et mot de passe)
                  délivré par l’un des partenaires de connexion peuvent utiliser le justificatif d’identité fourni par
                  le gouvernement, la CléGC.
                  Pour accéder aux services de l’Agence du revenu du Canada (ARC), vous devrez vous inscrire pour
                  obtenir un nom d’utilisateur et un mot de passe de l’ARC.
                  On prévoit que d’autres partenaires de connexion s’ajouteront; consultez la liste lorsque vous vous
                  branchez aux services du gouvernement du Canada.
                </p>
              </li>


              <li id="signin6">Je fais affaire avec un des partenaires de connexion figurant sur la liste, mais je ne
                suis pas inscrit(e) aux services bancaires en ligne. Puis-je quand même accéder aux services du
                gouvernement du Canada au moyen de mon partenaire de connexion?

                <p class="p-content">
                  Pour vous brancher par l’intermédiaire d’une institution financière partenaire, vous devez être
                  inscrit(e) aux transactions bancaires en ligne. Communiquez avec votre banque pour obtenir de plus
                  amples renseignements.
                </p>
              </li>

              <li id="signin7">Mon partenaire de connexion me connaît. Pourquoi dois-je tout de même fournir mes
                renseignements d’identité pour m’inscrire à un service du gouvernement du Canada?

                <p class="p-content">
                  Le partenaire de connexion ne communique au gouvernement aucun renseignement permettant de vous
                  identifier et ne fait que valider votre nom d’utilisateur et votre mot de passe. Chaque service du
                  gouvernement exige des renseignements personnels en fonction de ses exigences particulières et pour
                  déterminer votre admissibilité.
                </p>
              </li>

              <li id="signin8">Si ma banque émet une nouvelle carte bancaire (avec des nouveaux numéros), puis-je accéder aux services gouvernementaux en ligne?

                <p class="p-content">
                  Après avoir reçu votre nouvelle carte bancaire (avec des nouveaux numéros), vous pourriez avoir à vous réenregistrer auprès de votre service gouvernemental en ligne, en utilisant votre nouveau numéro de carte
                </p>
              </li>

            </ol>

          </div>

          <div>
            <h3>Au sujet de la CléGC</h3>

            <ol>
              <li id="gckey1">Qu’est-ce qu’une CléGC?

                <p class="p-content">
                  Une CléGC est un justificatif d’identité électronique unique (nom d’utilisateur et mot de passe) qui
                  vous permet de communiquer en toute sécurité avec les services du gouvernement. Le gouvernement du
                  Canada, en tant que fournisseur de services, distribue ces justificatifs d’identité afin de permettre
                  aux gens d’accéder aux services en ligne du gouvernement.
                </p>
              </li>

              <li id="gckey2">Pourquoi le gouvernement du Canada a-t-il mis en place la CléGC?

                <p class="p-content">
                  La CléGC est un service du gouvernement du Canada qui fournit des justificatifs d’identité (nom
                  d’utilisateur et mot de passe) permettant d’accéder (ou de se brancher) aux services du gouvernement
                  fédéral en ligne. Le service de CléGC peut être utilisé par des clients qui n’ont pas de justificatif
                  d’identité d’un partenaire de connexion ou qui choisissent de ne pas utiliser ce justificatif.
                </p>
              </li>

              <li id="gckey3">Comment puis-je obtenir une CléGC?
                <p class="p-content">
                  Pour vous inscrire, rendez-vous au site Web du gouvernement du Canada auquel vous voulez avoir accès
                  et cliquez sur le bouton « Poursuivez vers la CléGC » lorsqu’on vous présente les choix pour vous
                  brancher.
                </p>
              </li>

              <li id="gckey4">Puis-je utiliser la même CléGC pour accéder à plusieurs services?

                <p class="p-content">
                  Oui. Vous pouvez utiliser le même justificatif d’identité pour accéder à tous les services en ligne, à
                  l’exception des services de l’ARC. Il est pratique de pouvoir utiliser le même justificatif d’identité
                  puisque cela vous évite d’avoir à mémoriser plusieurs noms d’utilisateur et mots de passe.
                  Pour accéder aux services en ligne de l’ARC, vous devez utiliser un des partenaires de connexion ou
                  obtenir un nom d’utilisateur et un mot de passe de l’ARC.
                </p>
              </li>

              <li id="gckey5">Puis-je accéder à tous les services en ligne du gouvernement avec la CléGC?

                <p class="p-content">
                  Vous pouvez utiliser votre CléGC pour accéder à tous les services en ligne du gouvernement, à
                  l’exception des services de l’Agence du revenu du Canada (ARC). Pour accéder aux services de l’ARC,
                  vous devez utiliser un des partenaires de connexion ou obtenir un nom d’utilisateur et un mot de passe
                  de l’ARC.
                </p>
              </li>
              <li id="gckey6">Qui a accès aux renseignements que je fournis lorsque je fais une demande de CléGC?

                <p class="p-content">
                  Le service de CléGC ne communique pas vos renseignements pour l’inscription à des tiers. Les
                  renseignements que vous donnez sont conservés par le gouvernement du Canada et sont protégés
                  conformément à la Loi sur la protection des renseignements personnels.
                </p>
              </li>
            </ol>
          </div>
          <div>
            <h3>Au sujet du justificatif d’identité de l’Agence du revenu du Canada (ARC)</h3>
            <ol>
              <li id="cra1">Je suis inscrit(e) à l’Agence du revenu du Canada (ARC) et j’utilise un nom d’utilisateur et
                un mot de passe de l’ARC pour accéder aux services en ligne de l’ARC. Puis-je continuer à utiliser mon
                justificatif d’identité de l’ARC?
                <p class="p-content">
                  Oui. Vous pouvez continuer d’utiliser votre nom d’utilisateur et votre mot de passe de l’ARC pour
                  accéder aux services « Mon dossier pour les particuliers », « Mon dossier d’entreprise » et «
                  Représenter un client » de l’ARC. L’ARC continuera d’offrir ses propres services de justificatif
                  d’identité. Pour obtenir de plus amples renseignements, consultez la page sur les services en ligne de
                  l’ARC (lien vers <a href="https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html">https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html</a>).
                </p>
              </li>
              <li id="cra2">Puis-je utiliser un partenaire de connexion pour accéder aux services en ligne de l’Agence
                du revenu du Canada (ARC)?
                <p class="p-content">
                  Oui. Vous pouvez utiliser un partenaire de connexion pour accéder aux services « Mon dossier pour les
                  particuliers », « Mon dossier d’entreprise » et « Représenter un client » de l’ARC. Pour obtenir de
                  plus amples renseignements, consultez la page sur les services en ligne de l’ARC (lien vers <a
                  href="https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html">https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html</a>).
                  Les gens qui préfèrent utiliser un nom d’utilisateur et un mot de passe de l’ARC peuvent continuer de
                  le faire.
                </p>
              </li>
              <li id="cra3">Puis-je utiliser ma CléGC pour accéder aux services en ligne de l’Agence du revenu du Canada
                (ARC)?
                <p class="p-content">
                  Non. Pour accéder aux services en ligne de l’ARC, vous devez utiliser un des partenaires de connexion
                  ou obtenir un nom d’utilisateur et un mot de passe de l’ARC. Pour obtenir de plus amples
                  renseignements, consultez la page sur les services en ligne de l’ARC (lien vers <a
                  href="https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html">https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html</a>).
                </p>
              </li>
              <li id="cra4">Quels justificatifs d’identité puis-je utiliser pour accéder aux services en ligne de
                l’Agence du revenu du Canada (ARC)?
                <p class="p-content">
                  L’ARC continuera d’utiliser son service de nom d’utilisateur et de mot de passe; les utilisateurs
                  existants n’ont aucun changement à faire. Vous pouvez également utiliser les partenaires de connexion
                  pour accéder aux services en ligne de l’ARC au lieu du nom d’utilisateur et du mot de passe de l’ARC
                  ou en plus de ces derniers. Pour obtenir de plus amples renseignements, consultez la page sur les
                  services en ligne de l’ARC (lien vers <a
                  href="https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html">https://www.canada.ca/fr/agence-revenu/services/services-electroniques/services-ouverture-session-arc.html</a>).
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
