import * as tslib_1 from "tslib";
import { OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { accountExportExcel, accountExportPdf, accountListTableFilters, newAccountAction, newSubAccountAction } from '@core/models/filter-types.model';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { ActionOptionType } from '@shared/models/table-filter.models';
import { AUTO_WIDTH_FOR_COLUMNS, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class AccountsListComponent {
    constructor(router, translate, lookupService, accountManagementService, helperService, store, oBPSAccountActionsService, cFRAccountActionsService, oFFSETAccountActionsService, cMAccountActionsService) {
        this.router = router;
        this.translate = translate;
        this.lookupService = lookupService;
        this.accountManagementService = accountManagementService;
        this.helperService = helperService;
        this.store = store;
        this.oBPSAccountActionsService = oBPSAccountActionsService;
        this.cFRAccountActionsService = cFRAccountActionsService;
        this.oFFSETAccountActionsService = oFFSETAccountActionsService;
        this.cMAccountActionsService = cMAccountActionsService;
        this.showFilters = true;
        this._destroy$ = new Subject();
        this._stopPrevious$ = new Subject();
        this.firstItemIndex = 0;
        this.filterState = {};
        this.pagination = {
            page: 0,
            size: ROWS_PER_PAGE,
        };
        this.accountListFilters = accountListTableFilters;
        this.createAccountEnabled = false;
        this.propertyLoaded = false;
    }
    ngOnInit() {
        this.user = this.store.user;
        this.store.getProperty('nfmp.enabled.date')
            .pipe(takeUntil(this._destroy$)).subscribe(property => {
            if (property.value && property.value.toLowerCase() === 'true') {
                this.createAccountEnabled = true;
            }
            if (this.user.isOBPS()) {
                this.createAccountEnabled = false;
            }
            if (this.user.isOffset()) {
                this.createAccountEnabled = true;
            }
            if (this.user.isCFR()) {
                this.createAccountEnabled = false;
            }
            this.setActions();
            this.lookupService.getTableConfigurations('ACCOUNT').subscribe(data => {
                this.accountListTableConfig = data;
                this.accountListFilters.filterListsModel = data.filters;
                const _a = this.accountListTableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
                const _columns = [
                    ...this.accountListTableConfig.columns,
                    {
                        header: 'view',
                        width: AUTO_WIDTH_FOR_COLUMNS,
                        templateRef: this.viewColTemplate,
                    }
                ];
                if (this.user.hasOneOfThePermission(['APPROVE_ACCOUNT', 'ACCOUNT_DUAL_CONTROL_APPROVAL'])) {
                    _columns.push({
                        header: 'actions',
                        width: '155px',
                        templateRef: this.actionTemplate,
                        className: 'overflow-visible',
                    });
                }
                this.tableConfig = Object.assign({}, config, { columns: _columns });
                this.propertyLoaded = true;
            });
        });
        this.translate.onLangChange
            .pipe(takeUntil(this._destroy$))
            .subscribe(() => this.loadAccounts(this.pagination, this.filterState));
    }
    setActions() {
        const options = [];
        if (this.user.hasPermission('ACCOUNT_WRITE') && this.createAccountEnabled) {
            if (this.user.isOffset()) {
                options.push(newSubAccountAction);
            }
            else {
                options.push(newAccountAction);
                options.push(newSubAccountAction);
            }
        }
        options.push(accountExportPdf);
        options.push(accountExportExcel);
        this.accountListFilters.actionModel[0].options = options;
    }
    loadAccounts(pagination, filterState = {}) {
        this._stopPrevious$.next();
        this.masterAccountId && (filterState = Object.assign({}, filterState, { masterAccountId: this.masterAccountId }));
        this.accountManagementService.getAll(pagination, filterState)
            .pipe(takeUntil(this._destroy$), takeUntil(this._stopPrevious$)).subscribe(data => this.accounts = data);
    }
    onPaginationChanged(pagination) {
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        this.loadAccounts(this.pagination, this.filterState);
    }
    onFilterChanged(filterData, loadData = true) {
        this.firstItemIndex = 0;
        this.pagination.page = this.firstItemIndex;
        this.filterState = filterData;
        loadData && this.loadAccounts(this.pagination, this.filterState);
    }
    viewAccount(event, account) {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        this.store.setHistoryUrl();
        if (this.user.isOffset() && this.masterAccountId) {
            this.router.navigate(this.helperService.getTranslatedPath(`/offset-registration/${account.id}/${account.masterAccountId}`));
        }
        else {
            this.router.navigate(this.helperService.getTranslatedPath(`/account-management/account-summary-user/${account.id}`));
        }
    }
    refreshData(event) {
        this.tableFilterComponent.searchServiceManager();
    }
    get lang() {
        return this.translate.currentLang;
    }
    actionSelectedController(event) {
        switch (event.type) {
            case ActionOptionType.URL:
                // this.router.navigateByUrl(this.lang + event.uri);
                this.router.navigate(this.helperService.getTranslatedPath(event.uri));
                break;
        }
    }
    get actionService() {
        return this[this.user.getServiceClass('AccountActionsService')];
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
