
    <!-- editor form -->
    <form *ngIf="editorIsVisible">
      <formly-form [fields]="editorFields" [form]="editorForm" [model]="editorModel" [options]="to.editorOptions"></formly-form>
    </form>

    <!-- controls form -->
    <form>
      <formly-form [fields]="controlsFields" [form]="controlsForm" [model]="controlsModel" [options]="to.controlsOptions"></formly-form>
    </form>

    <!-- table view -->
    <div class="table-area mb-5">
      <div class="mask" *ngIf="editMode"></div>
      <app-table-view
        [config]="to.config"
        [data]="data"
        [relative]="true"
        [tableContainerMinWidth]="to.tableContainerMinWidth"
        (paginationChanged)="onPaginationChanged($event)"
      ></app-table-view>
    </div>

    <!-- template for actions -->
    <ng-template #actionsTpl let-data>
      <ng-select
        #actionDropdown
        [clearable]="false"
        [inputAttrs]="{ 'aria-label': applyPrefix('actions') | translate }"
        [items]="data.actionList"
        [hidden]="data.isTotalRecord"
        [placeholder]="applyPrefix('actions') | translate"
        [searchWhileComposing]="false"
        [typeToSearchText]="false"
        [(ngModel)]="action"
        class="ng-dropdown-left"
        appendTo="body"
        [alignRight]="true"
        (change)="onAction($event, data)"
        bindLabel="label"
        bindValue="label"
        wcag-label
      ></ng-select>
    </ng-template>

    <ng-template #viewColTemplate let-data>
      <div *ngIf="enableDetails(data)">
        <span tabindex="0" class="link" role="button"
              (click)="viewAction( data)"
              (keyup)="viewAction( data)">
          {{ 'UNITS_MODULE.unitList.view' | translate}}
        </span>
      </div>
    </ng-template>

    <!-- data form -->
    <form>
      <formly-form [fields]="to.dataFields" [form]="form" [model]="model" [options]="to.dataOptions"></formly-form>
    </form>
  