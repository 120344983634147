import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectTasksComponent } from './components/project-tasks/project-tasks.component';

@NgModule({
  declarations: [ProjectTasksComponent],
  exports: [
    ProjectTasksComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
  ],
})
export class ProjectTasksModule { }
