<div id="message-service" class="alert-icon alert-dismissible fade show" [ngClass]="getClassByMessageType()" role="alert">
  <div class="icon" aria-hidden="true">
    <fa-icon [icon]="['fas', iconByMessageType]" style="font-size: 40px"></fa-icon>
  </div>
  <div class="message">
    <h3 id="message_type">{{ messageTypeHeader | translate}}</h3>

    <ng-container *ngIf="isWarning(); else IS_ERROR">

      <p [innerHTML]="messages.message | translate"></p>
     <div *ngIf="messages.yesNoButton">
        <button type="button" class="btn btn-warning mr-3" (click)="warningResult(true)">{{'COMMON.actionsLabel.YES' | translate}}</button>
        <button type="button" class="btn btn-secondary mr-3" (click)="warningResult(false)">{{'COMMON.actionsLabel.NO' | translate}}</button>

      </div>

      <div *ngIf="messages.continueOkButton">
        <button type="button" class="btn btn-warning mr-3" (click)="warningResult(true)">{{'COMMON.actionsLabel.CONTINUE' | translate}}</button>
        <button type="button" class="btn btn-secondary mr-3" (click)="warningResult(false)">{{'COMMON.actionsLabel.CANCEL' | translate}}</button>

      </div>

      <div *ngIf="!messages.yesNoButton && !messages.continueOkButton">
        <button type="button" class="btn btn-warning mr-3" (click)="warningResult(true)">{{'COMMON.actionsLabel.OK' | translate}}</button>
        <button type="button" class="btn btn-secondary mr-3" (click)="warningResult(false)">{{'COMMON.actionsLabel.CANCEL' | translate}}</button>
      </div>


    </ng-container>

    <ng-template #IS_ERROR>

      <ng-container *ngIf="messages.message; else REGULAR_ERROR">
        <p [innerHTML]="messages.message | translate"></p>
      </ng-container>

      <ng-template #REGULAR_ERROR>
        <div *ngFor="let msg of messages.messages">
          <p [innerHTML]="format(msg)"></p>
        </div>
      </ng-template>
    </ng-template>
  </div>
  <button id="popup-close" type="button" class="close" data-dismiss="alert" aria-label="Close" color="link" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
