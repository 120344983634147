
 <ng-container *ngIf="showTableDetails">
    <h2>{{ 'PROJECTS_MODULE.tableDetails.title' | translate }}</h2>

    <p>{{ 'PROJECTS_MODULE.tableDetails.message' | translate }}</p>
  </ng-container>
  <app-blurb [message]="'PROJECTS_MODULE.projectList.topMessage'" *ngIf="!showTableDetails && !isPublicView" class="stick-left"></app-blurb>
  <div class="filters" *ngIf="showFilters">
      <app-table-filter
          [fields]="filterFields"
          (filterChanged)="onFilterChanged($event)"
          (filterInit)="onFilterChanged($event)"
          (customButtonAction)="onCustomButtonAction($event)">
      </app-table-filter>
  </div>

  <div class="table-area">
    <app-table-view *ngIf="dataList"
      [config]="tableConfig"
      [data]="dataList"
      [relative]="isPublicView"
      (paginationChanged)="onPaginationChanged($event)"
     >
    </app-table-view>
  </div>
  <ng-container *ngIf="showTableDetails">
    <div class="float-right">
      <button class="btn btn-primary float-right" type="button" (click)="close()"  *ngIf="showNextStep">
        {{ 'COMMON.actionsLabel.PROCEEED_TO_NEXT_STEP' | translate }}
      </button>
      <button class="btn btn-secondary mr-3 float-right" type="button" (click)="close()">
        {{ 'COMMON.actionsLabel.BACK' | translate }}
      </button>
    </div>
  </ng-container>

<ng-template #viewColTemplate let-data>
  <ng-container *ngIf="showView(data)">
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewProject($event, data)"
          (keyup)="viewProject($event, data)">
      {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
    </span>
  </div>
</ng-container>
</ng-template>

<ng-template #actionTemplate let-data>
  <app-generic-action-control
    [rawData]="data"
    [appendTo]="'app-project-list .table-container'">
    </app-generic-action-control>
</ng-template>



<ng-template #expandProjectRowTemplate let-data let-index>
  <div style="position: relative" class="pl-5">
    <app-sub-project-list
      [data]="data.subProjects"
      [relative]="true"
      [tableConfigName] = "tableConfigName">
    </app-sub-project-list>
  </div>
</ng-template>

