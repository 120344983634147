/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-tasks.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./project-tasks.component";
import * as i5 from "../../../../../../services/tasks.service";
var styles_ProjectTasksComponent = [i0.styles];
var RenderType_ProjectTasksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectTasksComponent, data: {} });
export { RenderType_ProjectTasksComponent as RenderType_ProjectTasksComponent };
function View_ProjectTasksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "task mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-secondary btn-sm"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "task-desc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "p", [["class", "m-0"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.view")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.taskTypeTranslated; _ck(_v, 5, 0, currVal_1); }); }
function View_ProjectTasksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectTasksComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tasks; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProjectTasksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-center mb-3 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PROJECTS_MODULE.projectTasks.noPendingTask")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ProjectTasksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "mt-3 border-bottom d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "tasks"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectTasksComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_TASKS", 2]], null, 0, null, View_ProjectTasksComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.tasks && _co.tasks.length); var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.projectTasks.title")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ProjectTasksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-tasks", [], null, null, null, View_ProjectTasksComponent_0, RenderType_ProjectTasksComponent)), i1.ɵdid(1, 114688, null, 0, i4.ProjectTasksComponent, [i5.TasksService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectTasksComponentNgFactory = i1.ɵccf("app-project-tasks", i4.ProjectTasksComponent, View_ProjectTasksComponent_Host_0, { projectId: "projectId" }, {}, []);
export { ProjectTasksComponentNgFactory as ProjectTasksComponentNgFactory };
