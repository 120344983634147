import { ITableViewConfig } from "@shared/models/table-view.model";

export const FILTER_COLUMNS = [
  "accountName",
  "name",
  "aggregatedProjectId",
  "standaloneProjectId"
];

export const SUB_PROJECT_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: 'ACCOUNTS_MODULE.accountSummaryProject',
  columns: [
    {
      field: 'name',
      header: 'subProjectName',
      sortField: 'name'
    },
    // {
    //   field: 'aggregatedProjectId',
    //   header: 'aggregatedSubProjectId',
    //   sortField: 'aggregatedProjectId'
    // },
    {
      field: 'projectId',
      header: 'subProjectId',
      sortField: 'projectId'
    }
  ],
};

export const PROJECT_DETAILS = [
  {
    label: 'jurisdiction',
    field: 'provinceNames',
    default: 'N/A',
    translatePrefix: 'COMMON.jurisdictionsList.'
  },
  {
    label: 'protocol',
    field: 'offsetProtocolName',
    default: 'N/A'
  },
  {
    label: 'totalOffsetCreditIssued',
    field: 'totalOffsetCreditsIssued',
    default: 0,
    numericField: true
  },
  {
    label: 'currentHoldings',
    field: 'currentHoldings',
    default: 0,
    numericField: true
  },
  {
    label: 'totalPendingTransferIn',
    field: 'ghgTotalPendingTransfersIn',
    default: 0,
    numericField: true
  },
  {
    label: 'totalPendingTransferOut',
    field: 'ghgTotalPendingTransfersOut',
    default: 0,
    numericField: true
  },
  {
    label: 'outstandingObligations',
    field: 'outstandingObligation',
    default: 0,
    numericField: true
  },
  {
    label: 'compensationProvidedToDate',
    field: 'totalCompensationProvidedToDate',
    default: 0,
    numericField: true
  },
  {
    label: 'totalvoluntaryCancellation',
    field: 'totalVoluntaryCancellations',
    default: 0,
    numericField: true
  },
  {
    label: 'upcommingObligationDeadline',
    field: 'upcomingObligationDeadlineDate',
    default: 'N/A'
  }
]
