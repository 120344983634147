/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ci-path-prev-selected-doc.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/controls/document-actions/document-actions.component.ngfactory";
import * as i5 from "../../../../shared/controls/document-actions/document-actions.component";
import * as i6 from "../../../../shared/services/control-actions.service";
import * as i7 from "../../../../shared/services/modal.service";
import * as i8 from "../../../../shared/services/upload-document.service";
import * as i9 from "../../../../shared/services/document-in-progress.service";
import * as i10 from "../../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i11 from "primeng/dialog";
import * as i12 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i13 from "../../../../shared/components/table-view/table-view.component";
import * as i14 from "../../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i15 from "primeng/api";
import * as i16 from "./ci-path-prev-selected-doc.component";
import * as i17 from "@angular/material/dialog";
import * as i18 from "../../ci-pathway-report.service";
var styles_CiPathPrevSelectedDocComponent = [i0.styles];
var RenderType_CiPathPrevSelectedDocComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CiPathPrevSelectedDocComponent, data: {} });
export { RenderType_CiPathPrevSelectedDocComponent as RenderType_CiPathPrevSelectedDocComponent };
function View_CiPathPrevSelectedDocComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.messageSection.documentUploading")); _ck(_v, 1, 0, currVal_0); }); }
function View_CiPathPrevSelectedDocComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-document-actions", [], null, null, null, i4.View_DocumentActionsComponent_0, i4.RenderType_DocumentActionsComponent)), i1.ɵdid(3, 245760, null, 0, i5.DocumentActionsComponent, [i6.ControlActionsService, i7.ModalService, i8.UploadDocumentService, i9.DocumentUploadInProgressService], { document: [0, "document"], documentUploadConfig: [1, "documentUploadConfig"], appendTo: [2, "appendTo"] }, null), (_l()(), i1.ɵand(0, [["UPLOADING", 2]], null, 0, null, View_CiPathPrevSelectedDocComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.documentUploadConfig; var currVal_2 = ""; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CiPathPrevSelectedDocComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { accountDocumentActions: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 19, "p-dialog", [["id", "prev-selected"]], [[8, "className", 0]], [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.openUploadPopup = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_Dialog_0, i10.RenderType_Dialog)), i1.ɵdid(2, 180224, null, 2, i11.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], [null, "checkboxSelected"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("checkboxSelected" === en)) {
        var pd_2 = (_co.getSelections($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i12.View_TableViewComponent_0, i12.RenderType_TableViewComponent)), i1.ɵdid(9, 638976, null, 0, i13.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"], firstItemIndex: [2, "firstItemIndex"], selections: [3, "selections"] }, { paginationChanged: "paginationChanged", checkboxSelected: "checkboxSelected" }), (_l()(), i1.ɵand(0, [[1, 2], ["accountDocumentActions", 2]], 1, 0, null, View_CiPathPrevSelectedDocComponent_1)), (_l()(), i1.ɵeld(11, 0, null, 2, 9, "p-footer", [], null, null, null, i14.View_Footer_0, i14.RenderType_Footer)), i1.ɵdid(12, 49152, [[3, 4]], 0, i15.Footer, [], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 7, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(19, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform("ACCOUNTS_MODULE.uploadDocument.ci_pathway_report_upload_from_previous")); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = _co.openUploadPopup; var currVal_9 = _ck(_v, 6, 0, "800px"); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.tableDataList; var currVal_11 = _co.tableConfig; var currVal_12 = _co.firstItemIndex; var currVal_13 = _co.selectedRows; _ck(_v, 9, 0, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = "eccc"; _ck(_v, 1, 0, currVal_0); var currVal_14 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 16, 0, currVal_14); var currVal_15 = i1.ɵunv(_v, 19, 0, i1.ɵnov(_v, 20).transform("COMMON.actionsLabel.SAVE")); _ck(_v, 19, 0, currVal_15); }); }
export function View_CiPathPrevSelectedDocComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ci-path-prev-selected-doc", [], null, null, null, View_CiPathPrevSelectedDocComponent_0, RenderType_CiPathPrevSelectedDocComponent)), i1.ɵdid(1, 114688, null, 0, i16.CiPathPrevSelectedDocComponent, [i17.MAT_DIALOG_DATA, i17.MatDialogRef, i18.CiPathwayReportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CiPathPrevSelectedDocComponentNgFactory = i1.ɵccf("app-ci-path-prev-selected-doc", i16.CiPathPrevSelectedDocComponent, View_CiPathPrevSelectedDocComponent_Host_0, {}, {}, []);
export { CiPathPrevSelectedDocComponentNgFactory as CiPathPrevSelectedDocComponentNgFactory };
