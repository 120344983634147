import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

// A password containing at least 1 uppercase, 1 lowercase, 1 digit, 1 special character and have a length of at least of 8
const REGEX = /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/m;

@Directive({
  selector: '[passwordComplexityValidator]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: PasswordComplexityValidatorDirective, multi: true},
  ],
})
export class PasswordComplexityValidatorDirective implements Validator {

  constructor() { }

  validate(control: AbstractControl): ValidationErrors | null {
    if (REGEX.test(control.value)) {
      return null;
    } else {
      return { passwordComplexityValidator: true };
    }
  }

}
