<div class="inner">

  <app-back-btn></app-back-btn>

  <h2>{{'PROJECTS_MODULE.transferrequest.field_61a' | translate}}</h2>

  <app-simple-table-wrapper [initialFilterState]="filter" [tableDetails]="tableDetails" ></app-simple-table-wrapper>

  <formly-form [fields]="actionFields" [form]="actionBtnForm"></formly-form>

</div>
