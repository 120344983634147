import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TablenewrowService {
    constructor(http) {
        this.http = http;
    }
    updateEntry(request, service_uri) {
        return this.http.post(`${environment.apiUrl}${service_uri}`, request);
    }
}
TablenewrowService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TablenewrowService_Factory() { return new TablenewrowService(i0.ɵɵinject(i1.HttpClient)); }, token: TablenewrowService, providedIn: "root" });
