import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';
import {INotification} from '../models/notifications';
import { ModalService } from './modal.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  public isShown = new BehaviorSubject(false);
  public data = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private translate: TranslateService

  ) {
    
  }

  public toggle(): void {
    this.isShown.next(!this.isShown.getValue());
  }



  showMessage(type: any, message: string, metaData = null) {
    this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            }],
          message: message,
          metaData: metaData,
          type: type,
        },
        true,
        DEFAULT_DIALOG_CONFIG
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
         console.log(result);
        }
      });
  }


}
