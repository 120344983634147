<p-dialog
  [className]="'eccc'"
  [header]="'Coming Soon'"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  {{message}}

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="COMING_SOON_closeBtn" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>


