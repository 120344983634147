import { Component, OnInit } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { StoreService } from '@core/store/store.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { Step } from '@shared/components/steps/models/step';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { ModalService } from '@shared/services/modal.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-review-activities',
  templateUrl: './review-activities.component.html',
  styleUrls: ['./review-activities.component.scss'],
})
export class ReviewActivitiesComponent extends BaseStepperStepComponent {

  static key = 'ReviewActivitiesComponent';
  facilities: any[] = [];
  isOpa:boolean;
  headerDetails: any;

  constructor(
    private modalService: ModalService,
    private storeService: StoreService,
    public formService: FormService,
    public service: AgreementCreditsService,
    private translate: TranslateService,
    private submissionManagementService: SubmissionManagementService
  ) {
    super();
    this.translate.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => this.onLangChange(),
      );
  }

  ngOnInit(): void {
    this.service.hideSaveAsDraft = true;
    this.inputData = this.service.inputData;
    this.isOpa = this.model.opaDetail && !this.model.opaDetail.editable &&
    this.model.opaDetail.id === this.storeService.user.associationsList[0].accountId;
    const inputData = Array.isArray(this.inputData) ? this.inputData[0] : this.inputData;
    this.facilities = inputData.uniqueFacilities;// this.service.currentData.facilityList;

    //because this could of been done in the back end by order by
    this.facilities.forEach(facility => {
      facility.liquidFuelsList && facility.liquidFuelsList.sort();
      facility.gaseousFuelsList && facility.gaseousFuelsList.sort();
    });

    this.headerDetails = {
      type: inputData.uniqueFacilities[0].activity,
      legalName: inputData.uniqueFacilities[0].legalName || this.model.registrationAccount.legalName,
      subType: inputData.uniqueFacilities[0].subAccountType,
      accountId: inputData.uniqueFacilities[0].accountId || this.model.registrationAccountId,
      registrationAccountId: inputData.uniqueFacilities[0].registrationAccountId || this.model.registrationReportAccountId,
    };
  }

  onLangChange(): void {
    const payload = {
      id: this.service.currentData.id,
      complianceYear: this.model.complianceYear,
    };
    this.service.getAgreementOpaList(this.getRegistrationAccountId(), payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      response => {
        const facilities = response.flatMap(a => a.uniqueFacilities);
        const inputData = Array.isArray(this.inputData) ? this.inputData[0] : this.inputData;
        inputData.uniqueFacilities = inputData.uniqueFacilities.map(dst => {
          const src = facilities.find(f => f.id === dst.id);
          if (src && src.liquidFuelsList) {
            dst.liquidFuelsList = src.liquidFuelsList.sort();
          }
          return dst;
        });
        this.facilities = inputData.uniqueFacilities;
      });
  }

  showFuel(fuel:string){
    const tran = this.translate.instant(`COMMON.fuelList.${fuel}`);
    if(tran && tran.indexOf('COMMON.fuelList') === -1){
      return tran;
    }
    return fuel;
  }

  isActivityCodeERP(facility) {
    return this.checkFacility(facility, 'ERP');
  }

  isActivityCodeBPE(facility) {
    return this.checkFacility(facility, 'BPE');
  }


  isActivityCodePRODCI(facility) {
    return this.checkFacility(facility, 'PRODCI');
  }

  isActivityCodeFuelStation(facility) {
      const eUFS = this.checkFacility(facility, 'EUFS');
      const isTypeFS = facility.type === 'FuelingStation' ;

      return eUFS && isTypeFS;
    }

  isActivityCargingStation(facility) {
    const eUFS = this.checkFacility(facility, 'EUFS');
    const isTypeCS = (facility.type === 'NonResidential')  ;

    return eUFS && isTypeCS;
  }

  isActivityNRCargingStation(facility) {
    const eUFS = this.checkFacility(facility, 'EUFS');
    const isTypeCS =  (facility.type === 'Residential' || facility.type === 'CSH' || facility.type === 'GPC') ;

    return eUFS && isTypeCS;
  }
  checkFacility(facility, type: string) {
    const inputData = Array.isArray(this.inputData) ? this.inputData[0] : this.inputData;
    const facilityExist = inputData.uniqueFacilities.find(d => d.facilityId === facility.facilityId);
    if (facilityExist) {
      return facilityExist.activityCodeList && facilityExist.activityCodeList.find(element => element === type);
      }
    return false;
  }

  hasAddress(facility){
    return !this.isActivityCodeERP(facility) && !this.isActivityNRCargingStation(facility);
  }

  saveAndProceed(draft = false) {
    if(this.checkForDisableState() && this.service.currentData.opaDetail && !this.service.currentData.opaDetail.editable){
      this.modalService
      .open(
          ServiceMessageComponent,
          {
              messages: null,
              message: 'opaExistsMessage',
              type: SereviceMessageType.WARNING,
          },
          true,
          DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
          if (result) {
            this.service.nextStep();
          }
      });
    } else  {
      this.service.nextStep();
    }
  }

  private getRegistrationAccountId() {
    return this.service.currentData.registrationAccountId ? this.service.currentData.registrationAccountId : this.service.currentData.registrationAccount.id;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this.service.hideSaveAsDraft = false;
  }
}
