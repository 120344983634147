import { SOURCE_DATA_TYPE } from "@shared/components/stepper/stepper.const";


export const CREATION_AGREEMENT_COMPONENTS = {
    SELECT_PARTY: 'SelectPartyComponent',
    REVIEW_ACTIVITIES: 'ReviewActivitiesComponent',
    OTHER_PARTY_ORG_DETAILS: 'OtherPartyOrgDetailsComponent',
    AUTH_CONTACT_PERSON_INFO: 'AuthContactPersonInfoComponent',
    RCA_PARTY_ORG_DETAILS: 'RcaOrgDetailsComponent',
    RCA_AUTH_CONTACT_PERSON_INFO: 'RcaAuthContactPersonInfoComponent',
    GEN_AGREEMENT: 'GenAgreementComponent',
    SIGN_AGREEMENT: 'SignAgreementComponent',
    UPLOAD_DOCUMENT: 'UploadAgreementComponent'
}


export const AGREEMENT_CREATE_CREDITS_STEPS = {
   page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.mainStepTitle',


  steps: [
    {
      id: 1,
      name: 'SELECT_OTHER_PARTY_TO_AGREEMENT',
      completed: false,
      reloadOnPrevious: false,
      component: CREATION_AGREEMENT_COMPONENTS.SELECT_PARTY,
    },
    {
      id: 2,
      name: 'REVIEW_ACTIVITIES',
      completed: false,
      reloadOnPrevious: false,
      component:  CREATION_AGREEMENT_COMPONENTS.REVIEW_ACTIVITIES,
    },
    {
      id: 3,
      name: 'ENTER_OPA',
      completed: false,
      reloadOnPrevious: true,
      component: CREATION_AGREEMENT_COMPONENTS.OTHER_PARTY_ORG_DETAILS,
    },
    {
      id: 4,
      name: 'ENTER_OPA_AO_DETAILS',
      completed: false,
      component: CREATION_AGREEMENT_COMPONENTS.AUTH_CONTACT_PERSON_INFO,
      reloadOnPrevious: true,
      showSubmit: true,
    }/**,
    {
      id: 5,
      name: 'GENERATE_AGREEMENT',
      completed: false,
      component:  CREATION_AGREEMENT_COMPONENTS.GEN_AGREEMENT,
      onlyClose: true,
    }**/,
  ],

  buttons: {
    showSave: true,
    showSubmit: false,
    submitPrefix: 'submitRegistrationReportButton',
  },
};

export const AGREEMENT_REVIEW_CREDITS_STEPS = {
  page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.mainStepTitle',


 steps: [
   {
     id: 1,
     name: 'OTHER_PARTY_TO_AGREEMENT',
     completed: false,
     reloadOnPrevious: false,
     component: CREATION_AGREEMENT_COMPONENTS.SELECT_PARTY,
   },
   {
     id: 2,
     name: 'REVIEW_ACTIVITIES',
     completed: false,
     reloadOnPrevious: false,
     component:  CREATION_AGREEMENT_COMPONENTS.REVIEW_ACTIVITIES,
   },
   {
    id: 3,
    name: 'REVIEW_RCA',
    completed: false,
    reloadOnPrevious: false,
    component: CREATION_AGREEMENT_COMPONENTS.RCA_PARTY_ORG_DETAILS,
  },
  {
    id: 4,
    name: 'REVIEW_RCA_AO_DETAILS',
    completed: false,
    reloadOnPrevious: false,
    component: CREATION_AGREEMENT_COMPONENTS.RCA_AUTH_CONTACT_PERSON_INFO,
    showSubmit: false,
  },{
     id: 5,
     name: 'REVIEW_OPA',
     completed: false,
     reloadOnPrevious: false,
     component: CREATION_AGREEMENT_COMPONENTS.OTHER_PARTY_ORG_DETAILS,
   },
   {
     id: 6,
     name: 'REVIEW_OPA_AO_DETAILS',
     completed: false,
     reloadOnPrevious: false,
     component: CREATION_AGREEMENT_COMPONENTS.AUTH_CONTACT_PERSON_INFO,
     showSubmit: true,
   }/**,
   {
     id: 5,
     name: 'GENERATE_AGREEMENT',
     completed: false,
     component:  CREATION_AGREEMENT_COMPONENTS.GEN_AGREEMENT,
     onlyClose: true,
   }**/,
 ],

 buttons: {
   showSave: true,
   showSubmit: false,
   submitPrefix: 'submitRegistrationReportButton',
 },
};

export const AGREEMENT_DOCUMENT_STEPS = {
   page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.mainStepTitle',


  steps: [
    {
      id: 1,
      name: 'UPLOAD_DOCUMENT',
      completed: false,
      component: CREATION_AGREEMENT_COMPONENTS.UPLOAD_DOCUMENT,
      disableMessageOnClose: true,
    }
  ],

  buttons: {
    showSave: false,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },
};