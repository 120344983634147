/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-simple.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/primeng/table/primeng-table.ngfactory";
import * as i6 from "primeng/table";
import * as i7 from "primeng/api";
import * as i8 from "./table-simple.component";
var styles_TableSimpleComponent = [i0.styles];
var RenderType_TableSimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableSimpleComponent, data: {} });
export { RenderType_TableSimpleComponent as RenderType_TableSimpleComponent };
function View_TableSimpleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.header)); _ck(_v, 1, 0, currVal_0); }); }
function View_TableSimpleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.header; _ck(_v, 0, 0, currVal_0); }); }
function View_TableSimpleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "th", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSimpleComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_TRANSLATION", 2]], null, 0, null, View_TableSimpleComponent_4))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.translate; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TableSimpleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSimpleComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TableSimpleComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform((_v.parent.parent.context.$implicit.valueTranslationPrefix + _v.parent.parent.parent.context.$implicit[_v.parent.parent.context.$implicit.field]))); _ck(_v, 1, 0, currVal_0); }); }
function View_TableSimpleComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit[_v.parent.parent.context.$implicit.field]; _ck(_v, 0, 0, currVal_0); }); }
function View_TableSimpleComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSimpleComponent_8)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_TRANSLATION", 2]], null, 0, null, View_TableSimpleComponent_9))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.valueTranslationPrefix; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TableSimpleComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TableSimpleComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TableSimpleComponent_11)), i1.ɵdid(1, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(2, { $implicit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, _v.parent.parent.context.$implicit); var currVal_1 = _v.parent.context.$implicit.templateRef; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TableSimpleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSimpleComponent_7)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["CUSTOM_CONTENT", 2]], null, 0, null, View_TableSimpleComponent_10))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.templateRef; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TableSimpleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableSimpleComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.columns; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TableSimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "p-table", [], null, null, null, i5.View_Table_0, i5.RenderType_Table)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵprd(512, null, i6.TableService, i6.TableService, []), i1.ɵdid(4, 6012928, null, 1, i6.Table, [i1.ElementRef, i1.NgZone, i6.TableService, i1.ChangeDetectorRef], { value: [0, "value"], columns: [1, "columns"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_TableSimpleComponent_1)), i1.ɵdid(7, 16384, [[1, 4]], 0, i7.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_TableSimpleComponent_5)), i1.ɵdid(9, 16384, [[1, 4]], 0, i7.PrimeTemplate, [i1.TemplateRef], { name: [0, "name"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "eccc"; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tableData; var currVal_2 = _co.tableHeaders; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = "header"; _ck(_v, 7, 0, currVal_3); var currVal_4 = "body"; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_TableSimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-simple", [], null, null, null, View_TableSimpleComponent_0, RenderType_TableSimpleComponent)), i1.ɵdid(1, 114688, null, 0, i8.TableSimpleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableSimpleComponentNgFactory = i1.ɵccf("app-table-simple", i8.TableSimpleComponent, View_TableSimpleComponent_Host_0, { tableHeaders: "tableHeaders", tableData: "tableData" }, {}, []);
export { TableSimpleComponentNgFactory as TableSimpleComponentNgFactory };
