<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1000px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header] = "formHeader | translate"
  (onShow)="scrollToTop()">

    <div class="table-area">
        <app-table-view
        [config]="tableConfig"
        [data]="tableData"
        (paginationChanged)="onPaginationChanged($event)"
        >
      </app-table-view>
    </div>
  
    <div class="d-flex justify-content-end mt-2">
        <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
      </div>
</p-dialog>