import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { TWorkflowAction } from '@core/models/action.model';
import { IApp } from '@core/models/app.interfaces';
import { rawFormEditableTableControlsMode } from '@core/models/raw-form.constants';
import { IRawFormBase, IRawFormButton, TRawFormEditableTableControlsMode } from '@core/models/raw-form.types';
import { getButton } from '@core/utilities/raw-form.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { ITableViewColumn } from '@shared/models/table-view.model';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ModalService } from '@shared/services/modal.service';
import { NgSelectComponent } from '@shared/components/ng-select/ng-select.component';
import { Observable, Subject } from 'rxjs';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';

@UntilDestroy()
@Component({
  selector: 'formly-editable-table',
  template: `
    <!-- editor form -->
    <form *ngIf="editorIsVisible">
      <formly-form [fields]="editorFields" [form]="editorForm" [model]="editorModel" [options]="to.editorOptions"></formly-form>
    </form>

    <!-- controls form -->
    <form>
      <formly-form [fields]="controlsFields" [form]="controlsForm" [model]="controlsModel" [options]="to.controlsOptions"></formly-form>
    </form>

    <!-- table view -->
    <div class="table-area mb-5">
      <div class="mask" *ngIf="editMode"></div>
      <app-table-view
        [config]="to.config"
        [data]="data"
        [relative]="true"
        [tableContainerMinWidth]="to.tableContainerMinWidth"
        (paginationChanged)="onPaginationChanged($event)"
      ></app-table-view>
    </div>

    <!-- template for actions -->
    <ng-template #actionsTpl let-data>
      <ng-select
        #actionDropdown
        [clearable]="false"
        [inputAttrs]="{ 'aria-label': applyPrefix('actions') | translate }"
        [items]="data.actionList"
        [hidden]="data.isTotalRecord"
        [placeholder]="applyPrefix('actions') | translate"
        [searchWhileComposing]="false"
        [typeToSearchText]="false"
        [(ngModel)]="action"
        class="ng-dropdown-left"
        appendTo="body"
        [alignRight]="true"
        (change)="onAction($event, data)"
        bindLabel="label"
        bindValue="label"
        wcag-label
      ></ng-select>
    </ng-template>

    <ng-template #viewColTemplate let-data>
      <div *ngIf="enableDetails(data)">
        <span tabindex="0" class="link" role="button"
              (click)="viewAction( data)"
              (keyup)="viewAction( data)">
          {{ 'UNITS_MODULE.unitList.view' | translate}}
        </span>
      </div>
    </ng-template>

    <!-- data form -->
    <form>
      <formly-form [fields]="to.dataFields" [form]="form" [model]="model" [options]="to.dataOptions"></formly-form>
    </form>
  `,
  styles: [
    `
      ::ng-deep {
        .table-container {
          position: relative !important;
        }
      }

      .table-area {
        position: relative;
      }

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.5);
        z-index: 1;
      }
    `,
  ],
})
export class FormlyEditableTable extends FieldType implements OnInit {
  @ViewChild('actionsTpl', { static: true }) actionsTpl: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  @ViewChild('actionDropdown', { static: false }) actionDropdown: NgSelectComponent;

  action: IApp.IEntityAction;
  editorFields: FormlyFieldConfig[] = [];
  editorForm = new FormGroup({});
  editorModel: any;
  editMode: boolean;
  controlsFields: FormlyFieldConfig[] = [];
  controlsForm = new FormGroup({});
  controlsModel: any;

  get data() {
    const control = this.form.get(this.controlId);
    if (control && control.value !== null) {
      return [...control.value];
    }
    return [];
  }

  set data(val: any) {
    this.onTableUpdate(val);
    this.form.get(this.controlId).setValue(val);
  }

  get editorIsVisible(): boolean {
    const { editorIsVisible } = this.to;
    return typeof editorIsVisible === 'boolean' ? editorIsVisible : true;
  }

  private get controlId(): string {
    return this.to.controlId;
  }

  private entryId = 0;
  private actions: IApp.IEntityAction[];
  private selectedEntry: any;

  constructor(
    private translateSvc: TranslateService,
    private formFactorySvc: FormFactoryService,
    private modalSvc: ModalService,
    private cd: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit(): void {
    this.initEditorSub();
    this.initControlsSub();
    this.initColumnsSub();
    this.initActionsSub();
    this.initUpdateEditorModelSub();
    this.initApplyInitialDataSub();
    this.initUpdateDataSub();
    this.initRefreshEditorSub();
    this.onLanguageChange();
  }

  applyPrefix(label: string): string {
    return `COMMON.actionsLabel.${label}`;
  }

  getSortedActions(actionList?: IApp.IEntityAction[]): IApp.IEntityAction[] {
    return actionList instanceof Array ? actionList.sort((a, b) => a.label.localeCompare(b.label)) : actionList;
  }

  onAction(selectedAction: IApp.IEntityAction, data: any): void {
    if (!selectedAction) {
      return;
    }

    const { workflowAction, custom } = selectedAction;
    if (custom !== true && (workflowAction === 'EDIT' || workflowAction === 'DELETE')) {
      if (workflowAction === 'EDIT') {
        this.editEntry(data);
      }
      if (workflowAction === 'DELETE') {
        this.deleteEntry(data);
      }
    }
    this.resetSelection();
    this.triggerAction(selectedAction.workflowAction, data);
    this.cd.detectChanges();
  }

  resetSelection() {
    setTimeout(() => {
        this.action = null;
        this.actionDropdown.handleClearClick();
        this.actionDropdown.blur();
      });
  }

  private initEditorSub(): void {
    const editorFields: FormlyFieldConfig[] = this.to.editorFields || [];
    const updateEditableFields: Observable<FormlyFieldConfig[]> = this.to.updateEditableFields;

    this.editorFields = editorFields;

    if (!updateEditableFields) {
      return;
    }

    updateEditableFields.pipe(untilDestroyed(this)).subscribe(fields => {
      this.editorFields = fields;
    });
  }

  private initControlsSub(): void {
    const controlsFields: IRawFormBase[] = this.to.controlsFields || [];
    const updateControlsFields: Observable<IRawFormBase[]> = this.to.updateControlsFields;
    const actions = [this.getAddButton(), this.getUpdateButton(), this.getDiscardButton()];

    this.controlsFields = this.getConfiguredControls(this.mergeControls(controlsFields, actions));

    if (!updateControlsFields) {
      return;
    }

    updateControlsFields.pipe(untilDestroyed(this)).subscribe(fields => {
      this.controlsFields = this.getConfiguredControls(this.mergeControls(fields, actions));
    });
  }

  private mergeControls(controls: IRawFormBase[], actions: IRawFormBase[]): IRawFormBase[] {
    const controlsMode: TRawFormEditableTableControlsMode = this.to.controlsMode || rawFormEditableTableControlsMode.all;

    if (controlsMode === 'PREDEFINED') {
      return actions;
    }
    if (controlsMode === 'CUSTOM') {
      return controls;
    }
    return [...controls, ...actions];
  }

  private getConfiguredControls(controls: IRawFormBase[]): FormlyFieldConfig[] {
    return this.formFactorySvc.configureForm([{ fieldGroup: [...controls] }]);
  }

  private initColumnsSub(): void {
    const { columns, ...config } = this.to.config || { columns: {} };
    const _columns = [...this.to.config.columns];

    if(this.to.config.hasViewColumn){
        this.to.config.columns.push(
          {
            header: 'view',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          });
    }else if(!this.to.config.hideActionsColumn) {
      _columns.push({
        field: 'actions',
        header: 'actions',
        width: '150px',
        templateRef: this.actionsTpl,
      });
    }
    this.to.config = {
      ...config,
      ...{ columns: _columns },
    };
  }

  private getAction(type: TWorkflowAction): IApp.IEntityAction {
    return {
      label: this.translateSvc.instant(this.applyPrefix(type)),
      workflowAction: type,
    };
  }

  private initActionsSub(): void {
    const source: IApp.IEntityAction[] = this.to.actions || [];
    const edit = this.getAction('EDIT');
    const remove = this.getAction('DELETE');
    const predefined = this.to.config.hideRemoveAction ? [edit] : [edit, remove];
    const ids = new Set(source.map(action => action.workflowAction));
    const filtered = predefined.filter(action => !ids.has(action.workflowAction));
    const labeled = source.map(action => ({ ...action, custom: true }));
    const actions = [...filtered, ...labeled];

    this.actions = this.getSortedActions(actions);

    if(this.to.config.hasViewColumn){
      this.to.config.columns.push(
        {
          header: 'view',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.viewColTemplate,
        }
      );
      this.actions = [];
    }
  }

  private initUpdateEditorModelSub(): void {
    if (typeof this.to.updateEditorModel !== 'function') {
      return;
    }

    this.editorForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.editorModel = this.to.updateEditorModel(this.editorForm);
    });
  }

  private initApplyInitialDataSub(): void {
    const { initialData } = this.to;
    const source = initialData instanceof Array ? initialData : [];
    this.applyData(source);
  }

  private initUpdateDataSub(): void {
    const updateData: Subject<any[]> = this.to.updateData;

    if (!updateData) {
      return;
    }

    updateData.pipe(untilDestroyed(this)).subscribe(value => {
      this.applyData(value);
      this.cd.detectChanges();
    });
  }

  private initRefreshEditorSub(): void {
    const refreshEditor: Subject<void> = this.to.refreshEditor;

    if (!refreshEditor) {
      return;
    }

    refreshEditor.pipe(untilDestroyed(this)).subscribe(() => {
      this.editorForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    });
  }

  private applyData(data: any[]): void {
    const totalKey = this.editorFields[0].fieldGroup[0].key;
    const index = data.findIndex(t => t.isTotalRecord);
    const total: any = [];
    total.isTotalRecord = true;
    this.editorFields[0].fieldGroup.forEach(fg => (total[fg.key] = 0));
    if (index > -1) {
      data.splice(index, 1);
    }
    this.data = data.map(entry => {
      this.entryId++;
      const columns: ITableViewColumn[] = this.to.config.columns;
      columns
       // .filter(el => el.numeric)
        .forEach(fg => {
          if(fg.numeric){
            const subTotal = parseInt(total[fg.field]) + parseInt(entry[fg.field]) || 0;
            total[fg.field] = subTotal;
          }else{
            total[fg.field] = '';
          }
        });
      return {
        ...entry,
        id: this.entryId,
        actionList: this.getActionList(),
      };
    });

    if (this.to.config.hasTotalRecord) {
      total[totalKey] = this.translateSvc.instant(`${this.to.config.translationPrefix}.totalKey`);
      this.data = [...this.data, { ...total, id: ++this.entryId }];
    }
  }

  private getAddButton(): IRawFormButton {
    return getButton({
      id: 'addRecord',
      label: this.applyPrefix('addRecord'),
      hideExpression: () => this.editMode || this.to.config.hideRemoveAction,
      disabledExpression: () => {
        return this.editorForm.invalid;
      },
      customTemplateOptions: {
        onClick: () => {
          this.addEntry();
        },
      },
    });
  }

  private getUpdateButton(): IRawFormButton {
    return getButton({
      id: 'updateRecord',
      className: 'ml-3',
      label: this.applyPrefix('updateRecord'),
      hideExpression: () => !this.editMode,
      customTemplateOptions: {
        onClick: () => {
          this.updateEntry();
        },
      },
    });
  }

  private getDiscardButton(): IRawFormButton {
    return getButton({
      id: 'discard',
      className: 'ml-3',
      label: this.applyPrefix('discardRecord'),
      hideExpression: () => !this.editMode,
      customTemplateOptions: {
        onClick: () => {
          this.resetModel();
          this.triggerAfterAction('DISCARD');
        },
      },
    });
  }

  private getActionList(): IApp.IEntityAction[] {
    return [...this.actions];
  }

  private resetModel(): void {
    this.editMode = false;
    this.editorForm.reset();
    this.selectedEntry = null;
  }

  private onTableUpdate(value: any): void {
    const { onTableUpdate } = this.to;

    if (typeof onTableUpdate === 'function') {
      onTableUpdate(value);
    }
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    debugger;
  }

  private addEntry(): void {
    this.entryId++;
    const actionList = this.getActionList();
    const patch = { ...this.editorForm.value, id: this.entryId, actionList };
    this.data = [...this.data, patch];
    this.resetModel();

    this.triggerAfterAction('ADD');
  }

  private updateEntry(): void {
    const index = this.data.findIndex(item => item.id === this.selectedEntry.id);
    const entries = [...this.data];
    const actionList = this.getActionList();
    entries[index] = { ...this.editorModel, actionList };
    this.data = entries;
    this.resetModel();

    this.triggerAfterAction('EDIT');
  }

  private editEntry(data: any): void {
    this.editMode = true;
    this.editorModel = { ...data };
    this.selectedEntry = { ...data };
  }

  private deleteEntry(data: any): void {
    this.modalSvc
      .open(ServiceMessageComponent, {
        messages: null,
        message: 'deleteEntry',
        metaData: 'translate delete',
        type: SereviceMessageType.WARNING,
      })
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result === true) {
          const index = this.data.findIndex(item => item.id === data.id);
          const entries = [...this.data];
          entries.splice(index, 1);
          this.data = entries;
          this.cd.detectChanges();
          this.triggerAfterAction('DELETE');
        }
      });
  }

  private triggerAction(action: TWorkflowAction, data?: any): void {
    if (typeof this.to.onAction === 'function') {
      this.to.onAction(this.getAction(action), data || this.editorModel, this.formControl.value);
    }
  }

  private viewAction(data: any){
    this.to.onAction(this.getAction('EDIT'), data , this.formControl.value);
  }

  private triggerAfterAction(action: TWorkflowAction): void {
    if (typeof this.to.afterAction === 'function') {
      this.to.afterAction(this.getAction(action), this.editorModel, this.formControl.value);
    }
  }

  public enableDetails(data){
    return !data.isTotalRecord;
  }

  private onLanguageChange() {
    this.translateSvc.onLangChange.subscribe(() => {
      const data = this.data.map(item => {
        const actionList = item.actionList && item.actionList.map(action => {
          return {
            label: this.translateSvc.instant(this.applyPrefix(action.workflowAction)),
            workflowAction: action.workflowAction,
          };
        });
        return {
          ...item,
          actionList
        };
      });

      if (this.to.config.hasTotalRecord) {
        const total = this.data[this.data.length - 1];
        const totalKey = this.editorFields[0].fieldGroup[0].key;
        total[totalKey] = this.translateSvc.instant(`${this.to.config.translationPrefix}.totalKey`);
        data[data.length - 1] = total;
      }
      this.data = data;
    });
  }
}
