import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';


export interface ILinkData {
  url: string;
  linktext: string;
  linkDescription: string;
  additionalDescription: string;
  header: string
  actionButtonText?: string;
}

@Component({
  selector: 'app-execute-link-action-modal',
  templateUrl: './execute-link-action-modal.component.html',
  styleUrls: ['./execute-link-action-modal.component.scss']
})
export class ExecuteLinkActionModalComponent implements OnInit {

  visible = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public linkData: ILinkData,
    private dialogRef: MatDialogRef<any>,
    ) { }

  ngOnInit() {

  }

  executeAction() {
    this.close(true);
  }


  close(result) {
    this.visible = false;
    this.dialogRef.close(result);
  }

}


