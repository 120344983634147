/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/documents/documents.component.ngfactory";
import * as i2 from "../../components/documents/documents.component";
import * as i3 from "../../services/modal.service";
import * as i4 from "../../services/upload-document.service";
import * as i5 from "../../../core/store/store.service";
import * as i6 from "../../services/document-in-progress.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "./formly-documents.type";
var styles_FormlyDocumentsType = [];
var RenderType_FormlyDocumentsType = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyDocumentsType, data: {} });
export { RenderType_FormlyDocumentsType as RenderType_FormlyDocumentsType };
function View_FormlyDocumentsType_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-documents", [], null, [[null, "documentResult"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("documentResult" === en)) {
        var pd_1 = (_co.documentUploadHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_DocumentsComponent_0, i1.RenderType_DocumentsComponent)), i0.ɵdid(2, 770048, null, 0, i2.DocumentsComponent, [i3.ModalService, i4.UploadDocumentService, i5.StoreService, i6.DocumentUploadInProgressService, i7.TranslateService], { documentUploadConfig: [0, "documentUploadConfig"], disabled: [1, "disabled"], showPlaceholders: [2, "showPlaceholders"] }, { documentResult: "documentResult" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentUploadConfig; var currVal_1 = _co.fieldDisabled; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_FormlyDocumentsType_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyDocumentsType_1)), i0.ɵdid(1, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.documentUploadConfig && !_co.field.hide); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormlyDocumentsType_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-documents", [], null, null, null, View_FormlyDocumentsType_0, RenderType_FormlyDocumentsType)), i0.ɵdid(1, 114688, null, 0, i9.FormlyDocumentsType, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyDocumentsTypeNgFactory = i0.ɵccf("formly-documents", i9.FormlyDocumentsType, View_FormlyDocumentsType_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyDocumentsTypeNgFactory as FormlyDocumentsTypeNgFactory };
