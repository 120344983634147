import { APPLICATION_APPROVAL_CARBON_INTENSITY } from '@shared/components/steps/constants/application-approval-carbon-intensity.const';

export const APPLICATION_CO_PROCESSED_LOW_CI_FUEL = {
  page_title: 'Application Co-processed Low CI fuel',

  steps: [
    {
      id: 1,
      name: 'BASIC_INFO',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.BASIC_INFO,
    },
    {
      id: 2,
      name: 'FACILITY_OR_EQUIPMENT',
      completed: false,
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_OR_EQUIPMENT,
      inputData: {
        ciApplication: true,
        step: '2',
      },
    },
    {
      id: 3,
      name: 'FACILITY_CI_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.FACILITY_CI_INFO,
    },
    {
      id: 4,
      name: 'UPLOAD_CI_DOCUMENTS',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.UPLOAD_DOCUMNETS,
      inputData: {
        documentType: 'ci',
      },
    },
    {
      id: 5,
      name: 'CONTACT_PERSON_INFO',
      component: APPLICATION_APPROVAL_CARBON_INTENSITY.CONTACT_PERSON_INFO,
      inputData: {
        step: '100',
      },
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },
};
