import { IApp } from '@core/models/app.interfaces';

export class  FacilityAuthorizedOfficial implements IApp.IFacilityAuthorizedOfficial {
    email: string;
    name: string;
    telephone?: string;
    businessTelephone?: string;
    title?: string;
  }

export class Account implements IApp.IAccount {
    accountType?: IApp.IAccountType;
    accountId?: number;
    additionalLegalName?: string;
    address?: IApp.IAddress;
    authorizedAccountRepresenttive?: IApp.IUser;
    businessStructure?: IApp.IBusinessStructure;
    certificateNumber?: string;
    createdBy?: number;
    creationDate?: any;
    deleted?: boolean;
    externalId?: number;
    facilityId?: number;
    id?: number;
    jurisdiction?: string;
    lastModifiedBy?: number;
    lastModifiedDate?: any;
    legalName?: string;
    masterAccount?: IApp.IAccount;
    naics?: string;
    persons?: IApp.IPerson[];
    phone?: string;
    registrationNumber?: string;
    registrationType?: IApp.IRegistrationType;
    status?: IApp.NSAccount.StatusEnum;
    termsAndConditions?: boolean;
    transitionType?: IApp.NSAccount.TransitionTypeEnum;
    userType?: IApp.NSAccount.UserTypeEnum;
    version?: number;
    authorizedOfficial?: IApp.IAddress;
}

export class AccountData implements IApp.IAccountData {
    accountTypeName?: string;
    additionalLegalName?: string;
    address?: IApp.IAddress;
    certificateNumber?: string;
    documents?: IApp.IDocument[];
    entityActionList?: IApp.IEntityAction[];
    externalId?: number;
    facilityId?: number;
    id?: number;
    jurisdiction?: string;
    legalName?: string;
    naics?: string;
    obligationStatus?: string;
    phone?: string;
    registrationNumber?: string;
    registrationType?: IApp.IRegistrationType;
    status?: IApp.NSAccountData.StatusEnum;
    termsAndConditions?: boolean;
    totalHoldings?: string;
    user?: IApp.IUser;
    userType?: IApp.NSAccountData.UserTypeEnum;
    users?: IApp.ISaveUser[];
  }

  export class AccountSubType implements IApp.IAccountSubType{
    id?:number;
    name?:string;
  }

  export class AccountRegistrationType implements IApp.IAccountRegistrationType{
    id?:number;
    name?:string;
  }

  export class ReplaceMAO {
    id?: number;
    legalName?:string;
    email?:string;
    reason?:string;
    accountId?:number;
    registrationAcountId?: number;
    status?: string;
    type?:string;
    action?: string;
    submissionType?: string;
    submissionTypeCode?: string;
    entityActionList?:any;

    constructor(type:string){
      this.id = null;
      this.legalName = '';
      this.email = '';
      this.reason = '';
      this.accountId = null;
      this.type = type;
      this.action = null;
    }
  }

  export class RequestRegistrationCancellation extends Account{
    primarySupplier:boolean;
    registeredCreator:boolean;
    foreignSupplier:boolean;
    carbonIntensityContributor: boolean;
    acceptCancellation:boolean;
    remark:string;
    discloseInfo: boolean;

    constructor(){
      super();
      this.primarySupplier = false;
      this.registeredCreator = false;
      this.foreignSupplier = false;
      this.carbonIntensityContributor = false;
      this.acceptCancellation = null;
      this.remark = '';
      this.discloseInfo = false;
    }
  }

export class OMPAccountType implements IApp.OMPAccountType{
  accountSubType?:AccountSubType
  accountRegistrationType?:AccountRegistrationType;

  constructor(){
    this.accountSubType = null;
    this.accountRegistrationType = null;
  }
}

export class Address {
  id: any;
  streetAddress: any;
  municipality: any;
  province: any;
  country: any;
  postalCode: any;
  latitude: any;
  longitude: any;
  streetNumber: any;
  streetName: any;
  city: any;
  poBox: any;
  sameAsPostalAddress: any;
  sameAsOrganizationAddress: any;

  constructor() {
    this.id = null;
    this.streetAddress = null;
    this.municipality = null;
    this.province = null;
    this.country = null;
    this.postalCode = null;
    this.latitude = null;
    this.longitude = null;
    this.streetNumber = null;
    this.streetName = null;
    this.city = null;
    this.poBox = null;
    this.sameAsPostalAddress = null;
    this.sameAsOrganizationAddress = null;
  }
}

export class OPAAccount {
  legalName: string;
  authorizedOfficial : Address;
  postalAddress: Address;

  constructor() {
    this.legalName = '';
    this.authorizedOfficial = new Address();
    this.postalAddress  = new Address();
  }
}

export class Agreements {

  accountId?: any;
	action?: any;
	activityToOrganizationEntityDtos?: any;
	agreementInfoDto?: any;
	catsAccountOpa?: any;
	comment?: any;
	complianceYear?: any;
	configAction?: any;
	endDate?: any;
	entityActionList?: any;
	facilityList?:  any[];
	fuelDtoList?: any;
	id?: any;
	mainAuthorizedOfficial?: any;
	nameIdentifier?: any;
	opaAccount?: OPAAccount;
	opaDetail?: OPAAccount;
	opaActivityFacility?: any;
	opaId?: any;
	opaName?: any;
	previousStatus?: any;
	registrationAccount?: any;
	registrationAccountId?: any;
	signature?: any;
	startDate?: any;
	status?: any;
	submittedBy?: any;
	submittedByName?: any;
	submittedOn?: any;
	transitionType?: any;
	version?: any;

    constructor(){
      this.accountId = null;
      this.action = "SAVE";
      this.activityToOrganizationEntityDtos = null;
      this.agreementInfoDto = null;
      this.catsAccountOpa = null;
      this.comment = null;
      this.complianceYear = null;
      this.configAction = null;
      this.endDate = null;
      this.entityActionList = null;
      this.facilityList = [];
      this.fuelDtoList = null;
      this.id = null;
      this.mainAuthorizedOfficial = null;
      this.nameIdentifier = null;
      this.opaDetail = null;
      this.opaAccount = null;
      this.opaActivityFacility = null;
      this.opaId = null;
      this.opaName = null;
      this.previousStatus = null;
      this.registrationAccount = null;
      this.registrationAccountId = null;
      this.signature = null;
      this.startDate = null;
      this.status = null;
      this.submittedBy = null;
      this.submittedByName = null;
      this.submittedOn = null;
      this.transitionType = null;
      this.version = null;
    }
}
