<div id="generic-action-control-action" *ngIf="isAvailable()">
    <ng-select
        wcag-label
        [placeholder]="'COMMON.actionsLabel.actions' | translate"
        [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.actions' | translate}"
        [items]="items"
        class="ng-dropdown-left"
        bindLabel="label"
        bindValue="workflowAction"
        [(ngModel)]="dummyModel"
        (change)="triggerActionController($event)"
        [appendTo]="appendTo"
        [alignRight]="true"
        [readonly]="disabled">
    </ng-select>
</div>
