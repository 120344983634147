<p-dialog
  [className]="'eccc'"
  [visible]="true"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header]="header | translate">

  <div class="container">
    <app-table-view *ngIf="totalElements > 0; else NO_DATA"
      [data]="dataList"
      [config]="config"
      [selections]="selections"
      [firstItemIndex]="firstItemIndex"
      (paginationChanged)="onPaginationChanged($event)"
      (checkboxSelected)="selectionChanged($event)">
    </app-table-view>
    <ng-template #NO_DATA>
      <ng-container *ngIf="totalElements === 0; else LOADING">
        <div class="align-content-center">{{ 'COMMON.custom_messages.noItems' | translate }}</div>
      </ng-container>
      <ng-template #LOADING>
        <div class="align-content-center">{{ 'COMMON.custom_messages.pleaseWait' | translate }}</div>
      </ng-template>
    </ng-template>
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button type="button" class="btn btn-secondary mr-2" (click)="close()">
          {{ 'COMMON.actionsLabel.CLOSE' | translate }}
        </button>
        <ng-container *ngIf="editMode">
          <button type="button" class="btn btn-secondary mr-2" (click)="reject()">
            {{ 'COMMON.actionsLabel.vbRejectAccessRequest' | translate }}
          </button>
          <button type="button" class="btn btn-primary"
                  [disabled]="!selections.length"
                  (click)="approve()">
            {{ 'COMMON.actionsLabel.vbApproveAccessRequest' | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </p-footer>
</p-dialog>

<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="view($event, data)"
          (keyup)="view($event, data)">
      {{ 'COMMON.actionsLabel.view' | translate}}
    </span>
  </div>
</ng-template>
