import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const PROJECT_DETAILS = '/offset-service/project/getById';
export const PROJECT_TRANS_REQ = '/offset-service/projectTransferRequest/getById';
export const GETPROJECTTRANSFER = '/offset-service/projectTransferRequest/get';
export const PROJ_TRANS_REQ_SUBMIT = '/offset-service/projectTransferRequest/submit';
export const PROJ_TRANS_REQ_SUBMIT_PENDING = '/offset-service/projectTransferRequest/pendingSubmit';
export const PROJ_TRANS_REQ_NEED_INFO = '/offset-service/projectTransferRequest/needMoreInfo';
export const PROJ_TRANS_REQ_RE_SUBMIT = '/offset-service/projectTransferRequest/resubmit';
export const PROJ_TRANS_REQ_REJECT = '/offset-service/projectTransferRequest/reject';
export const PROJ_TRANS_REQ_APPROVE = '/offset-service/projectTransferRequest/approve';
export const PROJ_TRANS_REQ_CANCEL = '/offset-service/projectTransferRequest/cancel';
export const PROJ_TRANS_REQ_APPROVE_2 = '/offset-service/projectTransferRequest/approve2';
export const PROJ_TRANS_REQ_REJECT_2 = '/offset-service/projectTransferRequest/rejection/reject';
export const PROJ_TRANS_REQ_REJECTION_APPROVE_2 = '/offset-service/projectTransferRequest/rejection/approve';
export const PROJ_TRANS_REQ_SAVE = '/offset-service/projectTransferRequest/save';
export const PROJ_REJECT_TRANSFER = '/offset-service/projectTransferRequest/rejectTransfer';
export const PROJ_ACCEPT_TRANSFER = '/offset-service/projectTransferRequest/accept';
export const GET_PENDING_TRANSFER_PROJECT = '/offset-service/project/getPendingTransferProject';
export const SEND_PROPONENT_CONSENT = '/offset-service/project/sendConsent';
export const SEACH_DST_ACCOUNT = '/account-service/offsetAccount/loadById/';
export const SEARCH_ACC_PROJ = '/offset-service/project/searchProject';
export const GETUNITS = '/obps-service/unit/findAll?size=20';
export class ProjectTransferService extends BaseStepperService {
    constructor(http) {
        super();
        this.http = http;
    }
    getUnits(req) {
        return this.http.post(`${environment.apiUrl}${GETUNITS}`, req);
    }
    getProjectDetailsPromise(projectId) {
        return this.http.get(`${environment.apiUrl}${PROJECT_DETAILS}/${projectId}`).toPromise();
    }
    getProjectTransferDetailsPromise(projectId) {
        return this.http.get(`${environment.apiUrl}${PROJECT_TRANS_REQ}/${projectId}`).toPromise();
    }
    getPrevProjectTransferDetailsPromise(projectId) {
        return this.http.get(`${environment.apiUrl}${GETPROJECTTRANSFER}/${projectId}`).toPromise();
    }
    getPendingTransferProjects(projectId, projectPid, srcAccountId) {
        return this.http.get(`${environment.apiUrl}${GET_PENDING_TRANSFER_PROJECT}/${projectId}/${projectPid}/${srcAccountId}`).toPromise();
    }
    getDestinationAccount(id) {
        return this.http.get(`${environment.apiUrl}${SEACH_DST_ACCOUNT}${id}`);
    }
    submit(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_SUBMIT}`, payload);
    }
    pendingSubmit(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_SUBMIT_PENDING}`, payload);
    }
    needmoreinfo(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_NEED_INFO}`, payload);
    }
    resubmit(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_RE_SUBMIT}`, payload);
    }
    reject(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_REJECT}`, payload);
    }
    approve(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_APPROVE}`, payload);
    }
    cancelProjecTranser(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_CANCEL}`, payload);
    }
    approve2(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_APPROVE_2}`, payload);
    }
    reject2(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_REJECT_2}`, payload);
    }
    rejectionApprove(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_REJECTION_APPROVE_2}`, payload);
    }
    save(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_TRANS_REQ_SAVE}`, payload);
    }
    rejectTransfer(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_REJECT_TRANSFER}`, payload);
    }
    accpetTransfer(payload) {
        return this.http.post(`${environment.apiUrl}${PROJ_ACCEPT_TRANSFER}`, payload);
    }
    sendConsent(payload) {
        return this.http.post(`${environment.apiUrl}${SEND_PROPONENT_CONSENT}`, payload);
    }
    searchAccountProject(payload) {
        return this.http.post(`${environment.apiUrl}${SEARCH_ACC_PROJ}`, payload);
    }
}
ProjectTransferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectTransferService_Factory() { return new ProjectTransferService(i0.ɵɵinject(i1.HttpClient)); }, token: ProjectTransferService, providedIn: "root" });
