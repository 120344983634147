import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IResetPasswordPayload, IStatus } from '@core/models/auth.model';
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@shared/services/helper.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  @Input() username: string;
  @Input() standalone = false;
  resetPasswordPayload: IResetPasswordPayload = {};
  authErrors = [];
  status: IStatus = {};
  codeSentMessage: string;
  title:string ='LOGIN_PAGE.forgotPasswordSection.resetPassword';

  id: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      
      if (params.id) {
        this.id = params.id;
      }

      if (params.access) {
        this.title ='LOGIN_PAGE.forgotPasswordSection.setPassword';
        this.status = {
          deliveryMedium :'email'
        };
        this.authService.findByAccessToken(params.access).subscribe(
          result => {
            this.resetPasswordPayload.username = result.userName;
            this.sendRequest(null);
          },
          (error: HttpErrorResponse) => {
            this.router.navigate(this.helperService.getTranslatedPath('/login'));
          },
        );
      }
    });
  }

  ngOnInit() {
    if (this.username) {
      this.resetPasswordPayload.username = this.username;
    }
  }

  sendRequest(forgotPasswordForm: any) {
    this.authErrors = [];
    if (this.resetPasswordPayload.username) {
      let request;
      const username = this.resetPasswordPayload.username;
      if (this.resetPasswordPayload.confirmationCode) {
        request = this.authService.confirmResetPassword(this.resetPasswordPayload);
      } else {
        request = this.authService.resetPassword(this.resetPasswordPayload);
      }

      request.subscribe(
          result => {
           if(forgotPasswordForm) {
             forgotPasswordForm.resetForm();
           }
           this.resetPasswordPayload.username = username;
            if (result.errors) {
             // this.authErrors = this.authService.handleErrors(result.errors);
            } else {
              this.status = result;
              this.codeSentMessage = this.translateService.instant('LOGIN_PAGE.forgotPasswordSection.codeSentMessage'+ result.deliveryMedium);
			        this.codeSentMessage = this.codeSentMessage.replace("{{0}}",this.status.destination);
              if (!this.status.deliveryMedium) {
                this.router.navigate(this.helperService.getTranslatedPath('/login'));
              }
            }
          },
           (error: HttpErrorResponse) => {
            //this.authErrors = this.authService.handleErrors(error.error);
            },
        );
    }
  }

}
