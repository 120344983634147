import { IRawForm } from '@core/models/raw-form.types';
import { getCheckbox, getDictSelect } from '@core/utilities/raw-form.utils';
import {
  COMP_REP_ORGANIZATION_NAME_FLD,
  COMPLIANCE_REPORT_CONFIRM_FLD,
  COMPLIANCE_REPORT_CREATE_POPUP,
  COMPLIANCE_REPORT_PERIOD_FLD, COMPLIANCE_REPORT_PREFIX,
  complianceRepApplyPrefix,
} from '@module/compliance-report/compliance-report.const';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';

export const getComplianceReportPeriod = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: COMPLIANCE_REPORT_PERIOD_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.COMPLIANCE_REPORT_COMPLIANCE_YEARS,
    label: complianceRepApplyPrefix(COMPLIANCE_REPORT_PERIOD_FLD, COMPLIANCE_REPORT_CREATE_POPUP),
    onChange,
    required: true,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    skipInitialBlur: true,
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_CREATE_POPUP,
  });
};

export const getConfirmationCheckbox = (): IRawForm => {
  return getCheckbox({
    id: COMPLIANCE_REPORT_CONFIRM_FLD,
    className: 'col-12',
    label: complianceRepApplyPrefix(COMPLIANCE_REPORT_CONFIRM_FLD, COMPLIANCE_REPORT_CREATE_POPUP),
    required: true,
    requiredTrue: true,
    });
};

export const getOrganizationName = (onChange?: () => void, hideExpression?): IRawForm => {
  return getDictSelect({
    id: COMP_REP_ORGANIZATION_NAME_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.ACCOUNT_NAMES,
    label: complianceRepApplyPrefix(COMP_REP_ORGANIZATION_NAME_FLD, COMPLIANCE_REPORT_CREATE_POPUP),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_CREATE_POPUP,
    hideExpression,
  });
};
