import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { CurrentUser, IUserData } from '@core/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TABLE_FILTER_SETTINGS_KEY } from '../table-filter/table-filter.const';

@Component({
  selector: 'app-program-switcher',
  templateUrl: './program-switcher.component.html',
  styleUrls: ['./program-switcher.component.scss'],
})
export class ProgramSwitcherComponent implements OnInit, OnDestroy {

  public currentUser: CurrentUser;
  private _destroy$ = new Subject<any>();

  constructor(
    private authService: AuthService,
    private store: StoreService,
    private helperService: HelperService,
    private router: Router,
  ) {
    this.store.user$
      .pipe(takeUntil(this._destroy$))
      .subscribe(currentUser =>  this.currentUser = currentUser);
  }

  ngOnInit() {
  }

  public switchProgram(programId: number) {
    this.authService.changeProgram(programId).subscribe(result => {
      window.location.href = '/';
      sessionStorage.removeItem(TABLE_FILTER_SETTINGS_KEY)
    });
  }

  public get program(): string {
    return this.currentUser && this.currentUser.program;
  }

  public get programs(): IApp.ILookup[] {
    return this.currentUser ? this.currentUser.programs : [];
    // return this.currentUser.programs || [];
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
