import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { ACTION_TYPE, TableTemplateActionControl } from '@core/models/tableTemplateControls.model';
import { BaseActionsComponent } from '@shared/components/base-actions/base-actions.component';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-generic-action-control',
  templateUrl: './generic-action-control.component.html',
  styleUrls: ['./generic-action-control.component.scss'],
})
export class GenericActionControlComponent extends BaseActionsComponent implements OnInit {

  @Input() rawData: any;
  @Input() appendTo;
  @Input() actionListProperty = 'entityActionList';
  @Input() disabled: boolean;
  @Output() selectedAction = new EventEmitter<TableTemplateActionControl>();

  items: IApp.IEntityAction[];

  constructor(
    private router: Router,
    private helperService: HelperService,
    private modalService: ModalService,
    private tableTemplateControls: TableTemplateControls,
    ) {
    super();
    this.translateService.onLangChange
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(_ => {
        this.setItems();
      });
  }

    ngOnInit() {
      this.setItems();
    }

    setItems(): void {
      if (this.isAvailable()) {
        const items = [...this.rawItems];
        items.forEach(option => {
          option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`);
        });
        items.sort((a, b) => a.label.localeCompare(b.label));
        this.items = [...items];
      }
    }

    triggerActionController(type: IApp.IEntityAction): void {
      this.tableTemplateControls.nextAction({action: type, data: this.rawData} as TableTemplateActionControl);
      this.selectedAction.emit({action: type, data: this.rawData} as TableTemplateActionControl);
      this.resetSelection();
    }

    isAvailable() {
      return (this.rawItems && this.rawItems.length > 0);
    }

    get rawItems(): IApp.IEntityAction[] {
      return this.rawData[this.actionListProperty];
    }

}
