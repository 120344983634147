import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { IDocument } from '@module/registration/registration.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
import { VB_PROOF_ACCREDITATION } from '@shared/components/validation-workflow/constants/verification-validation-form.const';
import { StoreService } from '@core/store/store.service';

const DATE_FORMAT = 'YYYY-MM-DD';

const GET_AC_DOC_ALL = '/account-service/accreditationDocument/getDocuments';
@Component({
  selector: 'app-vb-proof-accreditation',
  templateUrl: './vb-proof-accreditation.component.html',
  styleUrls: ['./vb-proof-accreditation.component.scss'],
})
export class VbProofAccreditationComponent implements OnInit, OnDestroy {
  static key = 'VbProofAccreditationComponent';

  _destroy$ = new Subject<any>();

  form = new FormGroup({});
  @Input() data: any;
  @Input() parentComponent: any;
  @Output() updateProvince = new EventEmitter<any>();

  fields: FormlyFieldConfig[];
  documentUploadConfig: IApp.IDocumentUploadConfig;
  documentsPage: IApp.IDocumentsPage;
  disabled: boolean;

  private uploadedDocTypes: string[] = [];
  constructor(
    public service: CfrRegistrationService,
    private formFactoryService: FormFactoryService,
    public translate: TranslateService,
    public router: Router,
    public helperService: HelperService,
    private translateService: TranslateService,
    private store: StoreService
  ) {}

  ngOnInit() {
    this.parentComponent.detailsValidationRequest$.pipe(takeUntil(this._destroy$)).subscribe(() => this.onRequestValidation());
    // if(this.data && this.data.name){
    //    this.data.name = parseInt(this.data.name);
    // }
    this.disabled = this.service.disabled;
    const d = new Date();
    const randomId = d.getTime();
    const entityName = this.isSubContractorFlow()
      ? IApp.NSDocument.EntityTypeEnum.SUB_CONTRACTING_ORGANIZATION
      : IApp.NSDocument.EntityTypeEnum.ACCREDITATION_BODY;
    const id = this.data.id ? this.data.id : randomId;
    this.data.id = id;
    const metaData = { REPORT_ID: this.service.currentData.id, ACCOUNT_STATUS:  this.service.currentData.registrationAccountStatus};
    const documentUploadConfig = [
      {
        documentType: true,
        entityName,
        entityDetail: this.service.currentData.legalName,
        comment: true,
        documentTypeValue: 13,
        documentTypeCode: 'PROOF_OF_ACCREDITATION',
        id,
        disablePaginator: false,
        statusColumn: false,
        title: 'REGISTRATION_PAGE.cfsRegistrationForm.proofOfAcreds',
        pendingSubmissionStatus: true,
        metaData,
        listUri: GET_AC_DOC_ALL,
      }
    ];

    this.documentUploadConfig = {
      documentType: true,
      entityName,
      entityDetail: this.service.currentData.legalName,
      comment: true,
      // documentTypeValue: 13,
      documentTypeCodes: ['PROOF_OF_ACCREDITATION', 'SCOPE_OF_ACCREDITATION', 'OTHER'],
      id,
      statusColumn: true,
      disablePaginator: false,
      documentUploadConfigs: documentUploadConfig,
      extraUploadDocuments: [  {
        id,
        entityName,
        documentTypeValue: 30,
        documentTypeCode: 'SCOPE_OF_ACCREDITATION',
      },],
      title: 'REGISTRATION_PAGE.cfsRegistrationForm.proofOfAcreds',
      pendingSubmissionStatus: true,
      metaData,
      listUri: GET_AC_DOC_ALL,
    };

    this.fields = this.formFactoryService.configureForm(VB_PROOF_ACCREDITATION);
    this.service.disableFormIfApplicable(this.form);
  }

  getAccredetationLabelDetails() {
    if (this.isSubContractorFlow()) {
      return 'SCProofAccreditationSubTitle';
    }
    return 'vbRegRepSubmProofAccreditationSubTitle';
  }

  isSubContractorFlow() {
    return this.service.code === 'RRSC';
  }

  onRequestValidation() {
    this.formFactoryService.markFormGroupDirty(this.form);
    let formValid = this.form.valid;
    if (formValid) {
      const missingDocs = this.documentUploadConfig.documentUploadConfigs.map(d => d.documentTypeCode).filter(doc => !this.uploadedDocTypes.includes(doc));
      if (missingDocs && missingDocs.length) {
        formValid = false;
        this.service.closeServiceMessageDialogs();
        this.service.showMessage('mandatoryInformationRequiredForUploadDocs', this.generateMetaDataForErrorMsg(missingDocs));
        this.parentComponent.markInvalid();
      }
      if (this.data.fromDate) {
        this.data.fromDate = moment(this.data.fromDate).format(DATE_FORMAT).toString();
      }
      if (this.data.toDate) {
        this.data.toDate = moment(this.data.toDate).format(DATE_FORMAT).toString();
      }

      this.updateProvince.emit(this.data);
    } else {
      this.parentComponent.markInvalid();
    }
    this.parentComponent.detailsValidationResponse$.next(formValid);
    this.service.ovbAccreditationValid = formValid;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  updateDocumentDetails(documents: IDocument[]) {
    var documentIds: number[] = [];
    documents.map(doc => documentIds.push(doc.id));
    this.data.documentIds = documentIds.sort();
    this.uploadedDocTypes = documents.map(doc => doc.type);
  }

  private generateMetaDataForErrorMsg(missingDocs): string[] {
    let charCode = 97;
    let missingDocMsg = missingDocs.reduce((acc, doc, index) => {
      ++charCode;
      return index < missingDocs.length - 1
        ? acc + `${this.translateService.instant(`COMMON.documentList.${doc}`)}<br>${String.fromCharCode(charCode)}. `
        : acc + `${this.translateService.instant(`COMMON.documentList.${doc}`)}`;
    }, '');
    return [`${String.fromCharCode(97)}. ${missingDocMsg}`];
  }

  public get adminActionAllowed() {
    return this.store.user.isAdmin && this.service.currentData.registrationAccountStatus === 'PENDING_REVIEW_ADMIN';
  }
}
