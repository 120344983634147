<app-header></app-header>
<div class="content" id="main-content" tabindex="-1">
  <div class="container">
    <div class="row justify-content-center align-items-center" style="height: 80vh">
      <div class="col-sm-12 col-md-6 col-lg-4"
           [class.col-lg-6]="programConfirmation">
        <div class="card" *ngIf="pageVisible">
          <div class="card-body">
            <h1>
              <ng-container *ngIf="!provideMfaToken; else MFA_TOKEN_NEEDED_HEADER">{{ 'LOGIN_PAGE.loginSection.signin'| translate }}</ng-container>
              <ng-template #MFA_TOKEN_NEEDED_HEADER>
                <ng-container *ngIf="!migrationConfirmation; else MIGRATION_CONFIRMATION_NEEDED_HEADER">
                  {{ 'LOGIN_PAGE.loginSection.signin2fa'| translate }}
                </ng-container>
              </ng-template>
              <ng-template #MIGRATION_CONFIRMATION_NEEDED_HEADER>
              <ng-container *ngIf="!programConfirmation; else PROGRAM_CONFIRMATION_NEEDED_HEADER">
                {{ 'LOGIN_PAGE.loginSection.migrationConfirmationHeader'| translate }}
                </ng-container>
              </ng-template>
              <ng-template #PROGRAM_CONFIRMATION_NEEDED_HEADER>
                {{ 'LOGIN_PAGE.loginSection.programConfirmationHeader'| translate }}
           </ng-template>
            </h1>
            <hr />

            <section class="alert alert-danger alert-danger-banner text-center"
                     *ngIf="authErrors.length > 0">
                <span *ngFor="let errItem of authErrors" class="alert-link">{{ errItem }}</span>
            </section>

            <ng-container *ngIf="!provideNewPassword && !provideMfaToken; else NEW_PASSWORD_NEEDED">
              <form #loginForm1="ngForm"
                    autocomplete="off"
                    (ngSubmit)="loginForm1.form.valid && signIn(loginForm1)"
                    novalidate>
                <div class="form-group">
                  <label for="loginUsername">{{ 'LOGIN_PAGE.loginSection.username'| translate }}</label>
                  <input type="text"
                         [(ngModel)]="loginPayload.username"
                         class="form-control"
                         name="username"
                         #username="ngModel"
                         [ngClass]="{ 'is-invalid': loginForm1.submitted && username.invalid }"
                         id="loginUsername"
                         required>

                  <small *ngIf="loginForm1.submitted && username.invalid"
                         id="login-error-username-required"
                         class="invalid-feedback">
                    {{ 'LOGIN_PAGE.loginSection.provideUsernameError'| translate }}
                  </small>

                </div>
                <div class="form-group">
                  <label for="loginPassword">{{ 'LOGIN_PAGE.loginSection.password'| translate }}</label>
                  <input type="password"
                         [(ngModel)]="loginPayload.password"
                         class="form-control"
                         name="password"
                         #password="ngModel"
                         [ngClass]="{ 'is-invalid': loginForm1.submitted && password.invalid }"
                         id="loginPassword"
                         required>

                  <small *ngIf="loginForm1.submitted &&  password.invalid"
                         id="login-error-password-required"
                         class="invalid-feedback">
                      {{ 'LOGIN_PAGE.loginSection.providePasswordError'| translate }}
                  </small>

                </div>
                <button type="submit"
                        id="loginSignInBtn"
                        class="btn btn-primary">
                        <span>
                  {{ 'LOGIN_PAGE.loginSection.signinButton'| translate }}</span>
                </button>
                <div class="form-group">
                  <hr/>
                  <a id="forgotPassword" href= "" [appRouterLink]="['reset-password' | localize]" class="text-center d-block">{{ 'LOGIN_PAGE.loginSection.forgotPassword'| translate }}</a>
                  <a id="forgotUserName" href= "" [appRouterLink]="['forgot-username'| localize]" class="text-center d-block">{{ 'LOGIN_PAGE.loginSection.forgotUsername'| translate }}</a>
                </div>
              </form>
            </ng-container>

            <ng-template #NEW_PASSWORD_NEEDED>
                 <ng-container *ngIf="!provideMfaToken; else MFA_TOKEN_NEEDED">

                    <p> Please set a password</p>
              <form #loginForm2="ngForm"
                    autocomplete="off"
                    (ngSubmit)="loginForm2.form.valid && signIn()"
                    novalidate>
                <div class="form-group">
                  <label for="loginNewPassword">{{ 'LOGIN_PAGE.loginSection.newPassword'| translate  }}</label>
                  <input type="password"
                         [(ngModel)]="loginPayload.newPassword"
                         class="form-control"
                         #newPassword="ngModel"
                         name="password1"
                         [ngClass]="{ 'is-invalid': loginForm2.submitted && newPassword.invalid }"
                         id="loginNewPassword"
                         passwordComplexityValidator
                         required>

                  <small *ngIf="newPassword.invalid"
                           id="login-error-new-password-required"
                           class="invalid-feedback">
                      <span *ngIf="newPassword.errors.required; else COMPLEXITY">{{ 'LOGIN_PAGE.loginSection.providePasswordError'| translate }}</span>
                      <ng-template #COMPLEXITY>
                        <span *ngIf="newPassword.errors.passwordComplexityValidator">{{ 'COMMON.messageSection.PasswordTooSimple'| translate }}</span>
                      </ng-template>
                  </small>

                </div>

                <div class="form-group">
                    <label for="loginConfirmPassword">{{ 'LOGIN_PAGE.loginSection.confirmPassword'| translate  }}</label>
                    <input type="password"
                           [(ngModel)]="loginPayload.password"
                           class="form-control"
                           #password="ngModel"
                           name="password2"
                           [ngClass]="{ 'is-invalid': loginForm2.submitted && password.invalid }"
                           id="loginConfirmPassword"
                           passwordComplexityValidator
                           required>

                    <small *ngIf="password.invalid"
                             id="login-error-new-password-required"
                             class="invalid-feedback">
                        <span *ngIf="password.errors.required; else COMPLEXITY">{{ 'LOGIN_PAGE.loginSection.providePasswordError'| translate }}</span>
                        <ng-template #COMPLEXITY>
                          <span *ngIf="password.errors.passwordComplexityValidator">{{ 'COMMON.messageSection.PasswordTooSimple'| translate }}</span>
                        </ng-template>
                    </small>

                  </div>
                <button type="submit"
                        id="loginUpdatePasswordBtn"
                        class="btn btn-primary">
                  {{ 'LOGIN_PAGE.loginSection.setPasswordButton'| translate }}
                </button>
              </form>
              </ng-container>
            </ng-template>

            <ng-template #MFA_TOKEN_NEEDED>
              <ng-container *ngIf="!migrationConfirmation; else MIGRATION_CONFIRMATION_NEEDED">

              <!--  <p>{{ 'LOGIN_PAGE.loginSection.enterOTP'| translate }}</p>-->
              <p> {{ codeSentMessage }}</p>

             <form #mfaTokenForm="ngForm"
                        autocomplete="off"
                        (ngSubmit)="mfaTokenForm.form.valid && signIn()"
                        novalidate>
                        <div class="form-group">
                            <app-confirmation-code
                              [(ngModel)]="loginPayload.mfaCode"
                              name="mfaCode"
                              #mfaCode="ngModel"
                              [isInvalid]="mfaTokenForm.submitted && mfaCode.invalid">
                            </app-confirmation-code>

                            <small *ngIf="mfaTokenForm.submitted && mfaCode.invalid"
                                   style="display: block"
                                     id="mfaTokenForm-error-mfaCode-required"
                                     class="invalid-feedback">
                                {{ 'LOGIN_PAGE.forgotPasswordSection.provideConfirmationCode'| translate }}
                            </small>
                       </div>

                        <div class="float-left">
                          <button type="button" id="mfaTokenFormResendCodeBtn" class="btn btn-secondary" (click)="resendCode(mfaTokenForm)">
                            {{ 'LOGIN_PAGE.loginSection.resendCode'| translate }}
                          </button>
                        </div>
                        <div class="float-right">
                          <button type="submit"
                                  id="mfaTokenFormSignInBtn"
                                  class="btn btn-primary">
                            {{ 'LOGIN_PAGE.loginSection.signin'| translate }}
                          </button>
                        </div>
              </form>
              </ng-container>
            </ng-template>


            <ng-template #MIGRATION_CONFIRMATION_NEEDED>
              <ng-container *ngIf="!programConfirmation; else PROGRAM_CONFIRMATION_NEEDED">

              <p [innerHTML]="'LOGIN_PAGE.loginSection.migrateCredentialsDescription' | translate"></p>

                      <div class="float-left">
<!--                        <p>  {{ 'LOGIN_PAGE.loginSection.migrateToNewCredentials'| translate }}</p>-->
                        <button type="button" id="mfaTokenFormResendCodeBtn" class="btn btn-secondary" (click)="migrateCredentials()">
                            {{ 'LOGIN_PAGE.loginSection.migrate'| translate }}
                          </button>
                        </div>
                        <div class="float-right">
<!--                          <p>  {{ 'LOGIN_PAGE.loginSection.loginWithExistingCredentials'| translate }}</p>-->
                          <button (click)="loginWithExistingCredentials()"
                                  id="mfaTokenFormSignInBtn"
                                  class="btn btn-primary">
                            {{ 'LOGIN_PAGE.loginSection.login'| translate }}
                          </button>
                        </div>
                </ng-container>
            </ng-template>

            <ng-template #PROGRAM_CONFIRMATION_NEEDED>
              <p>  {{ 'LOGIN_PAGE.loginSection.programConfirmationDescription'| translate }}</p>

              <div class="row">
                <div class="col-xs-12 col-md-6 mb-4" *ngFor="let program of programs">
                  <div class="card h-100 card--flex">
                    <div class="card-body">
                      <h2 class="card-title flex-full">
                      {{ 'COMMON.programTypeList.' + program.name | translate }}
                      </h2>
                      <div class="card-footer text-center">
                        <button class="btn btn-secondary btm-sm"
                        (click)="choseProgram(program.id)">{{ 'COMMON.labels.continue' | translate }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </ng-template>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
