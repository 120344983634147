import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { SelectItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { enLocales, frLocales } from './date-range.locales';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@core/services/auth.service';

export interface IDateRange {
  dateFrom: Date;
  dateTo: Date;
}

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements OnInit, OnDestroy, OnChanges {

  public locales = {
    en: enLocales,
    fr: frLocales,
  };

  @Output() datesChanged = new EventEmitter<IDateRange>();

  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() lang;
  @Input() placeholder: string;

  dateFromUTC: UTCDate;
  dateToUTC: UTCDate;

  private _destroy$ = new Subject<any>();

  dateRangeLabel: string;
  calendarOptionsOpened = false;
  isDateChanged = false;
  dateOptions: SelectItem[];
  selectedDateOption: any;

  constructor(
    public datePipe: DatePipe,
    private eRef: ElementRef,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef,
    private auth: AuthService
  ) {
    this.auth.translationLoading$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.setDateRangeLabel();
        this.setDateOptions();
      });

    if (!this.lang) {
      this.translateService.onLangChange
        .pipe(
          takeUntil(this._destroy$),
        )
        .subscribe(
        () => {
          this.lang = this.translateService.currentLang;
          this.setDateRangeLabel();
          this.setDateOptions();
        },
      );
    }

  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if ( ! this.eRef.nativeElement.contains(event.target)) {
      this.calendarOptionsOpened = false;
    }
  }

  ngOnInit() {
    this.dateFromUTC = new UTCDate(this.dateFrom ? moment.utc(this.dateFrom).toDate() : new Date(), true);
    this.dateToUTC = new UTCDate(this.dateTo ? moment.utc(this.dateTo).toDate() : new Date(), false);
    this.lang = this.lang || this.translateService.currentLang;
    this.setDateRangeLabel();
  }

  toggleCalendar() {
    this.calendarOptionsOpened = !this.calendarOptionsOpened;
    if (this.isDateChanged) {
      this.updateDatesInDashboardContext();
    }
  }

  setDateRangeLabel() {
    moment.locale(this.translateService.currentLang);
    if (this.dateFrom && this.dateTo) {
      this.dateRangeLabel =
        `${moment(this.dateFrom).format('DD MMM YYYY')}
      - ${moment(this.dateTo).format('DD MMM YYYY')}`;
    } else {
      this.dateRangeLabel = this.translateService.instant(this.placeholder || 'COMMON.calendar.dateRange');
    }
    this.cd.markForCheck();
  }

  onChangedDateOption() {
    if (DateOption.THIS_MONTH === this.selectedDateOption) {
      this.setDatesOnThisMonth();
    } else if (DateOption.THIS_YEAR === this.selectedDateOption) {
      this.setDatesOnThisYear();
    } else if (DateOption.LAST_MONTH === this.selectedDateOption) {
      this.setDatesOnLastMonth();
    } else if (DateOption.LAST_YEAR === this.selectedDateOption) {
      this.setDatesOnLastYear();
    }
    this.customCalendarDatesChanged();
    this.isDateChanged = true;
  }

  customCalendarDatesChanged() {
    this.dateFrom = new Date(moment(this.dateFromUTC.boundDate).format('YYYY-MM-DDT00:00:00'));
    this.dateTo = new Date(moment(this.dateToUTC.boundDate).format('YYYY-MM-DDT23:59:59'));
    this.setDateRangeLabel();
    this.isDateChanged = true;

    this.updateDatesInDashboardContext();
  }

  updateDatesInDashboardContext(): void {
    if (this.dateFrom && this.dateTo) {
      this.datesChanged.emit({
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
      });
      this.isDateChanged = false;
    }
  }

  private toUTC(date: Date): Date {
    return moment.utc(moment(date).format('YYYY-MM-DD')).toDate();
  }

  setDatesOnThisMonth() {
    this.dateFromUTC = new UTCDate(moment.utc().startOf('month').toDate());
    this.dateToUTC = new UTCDate(moment.utc().endOf('month').toDate());
  }

  setDatesOnThisYear() {
    this.dateFromUTC = new UTCDate(moment.utc().startOf('year').toDate());
    this.dateToUTC = new UTCDate(moment.utc().endOf('year').startOf('day').toDate());
  }

  setDatesOnLastMonth() {
    this.dateFromUTC = new UTCDate(moment.utc().subtract(1, 'months').startOf('month').toDate());
    this.dateToUTC = new UTCDate(moment.utc().subtract(1, 'months').endOf('month').toDate());
  }

  setDatesOnLastYear() {
    this.dateFromUTC = new UTCDate(moment.utc().subtract(1, 'years').startOf('year').toDate());
    this.dateToUTC = new UTCDate(moment.utc().subtract(1, 'years').endOf('year').toDate());
  }

  setDateOptions() {
    this.dateOptions = [
      {
        label: this.translateService.instant('COMMON.calendar.thisMonthText'),
        value: DateOption.THIS_MONTH,
      },
      {
        label: this.translateService.instant('COMMON.calendar.thisYearText'),
        value: DateOption.THIS_YEAR,
      },
      {
        label: this.translateService.instant('COMMON.calendar.lastMonthText'),
        value: DateOption.LAST_MONTH,
      },
      {
        label: this.translateService.instant('COMMON.calendar.lastYearText'),
        value: DateOption.LAST_YEAR,
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setDateRangeLabel();
  }

  keyPressed(event, method: string, ignoredKeys: string[] = []): void {
    if (!this.calendarOptionsOpened) {
      if (!(event.key && ignoredKeys.includes(event.key))) {
        this[method]();
      }
    }
  }

  get minDate() {
    return typeof this.dateFrom === 'string' ? new Date(this.dateFrom) : this.dateFrom;
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.calendarOptionsOpened) {
      if (event.key === 'Escape') {
        this.toggleCalendar();
        (document.getElementsByClassName('hotspot-area')[0] as HTMLElement).focus();
      }
    }

  }

  private toDate(d: Moment): Date {
    return new Date(d.year(), d.month(), d.date(), d.hours(), d.minutes(), d.seconds());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}

enum DateOption {
  THIS_MONTH,
  THIS_YEAR,
  LAST_MONTH,
  LAST_YEAR,
}

class UTCDate {
  private boundDateBacker: Date;
  constructor(
    private dateInstance: Date,
    localToUtc = false,
  ) {
    this.boundDateBacker = localToUtc ? this.localToUtc(dateInstance) : this.utcToLocal(dateInstance);
  }

  public get boundDate(): Date {
    return this.boundDateBacker;
  }
  public set boundDate(value: Date) {
    if (value) {
      this.dateInstance.setTime(this.localToUtc(value).getTime());
      const newTime = value.getTime();
      if (newTime !== this.boundDateBacker.getTime()) {
        this.boundDateBacker.setTime(newTime);
      }
    }
  }

  private localToUtc(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(),  date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  private utcToLocal(date: Date): Date {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),  date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
  }
}
