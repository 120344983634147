import { Component, OnInit } from '@angular/core';
import { SessionData } from '@core/models/auth.model';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { CfsRegistration, CFS_REGISTRATION_STEP_TYPE } from '../../constants/registration-types.const';
import { Step } from '../../models/step';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cfs-secure-sign-in',
  templateUrl: './cfs-secure-sign-in.component.html',
  styleUrls: ['./cfs-secure-sign-in.component.scss']
})
export class CfsSecureSignInComponent extends Step implements OnInit {

  static key = 'CfsSecureSignInComponent';

  public session: SessionData;
  public message: string;
  headerTitle: string;
  sub_header: string;

  constructor(
    private translate: TranslateService,
    private store: StoreService,
    private activatedRoute: ActivatedRoute
  ) { super(); }

  ngOnInit() {
    this.session = this.store.getSession();
    const token = this.activatedRoute.snapshot.params['token'];
    this.message = token ? 'REGISTRATION_PAGE.stepperSection.cfsSecureSignIn1' : 'REGISTRATION_PAGE.authAccountSelection.placeHolderText';

    if(this.inputData === 'ORG') {
      this.headerTitle = 'REGISTRATION_PAGE.stepperSection.orgTitleFinalStep';
    } else {
      this.headerTitle = 'REGISTRATION_PAGE.stepperSection.vbTitleFinalStep';
    }
  }

}
