/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sub-projects-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../table-view/table-view.component.ngfactory";
import * as i3 from "../../table-view/table-view.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../controls/generic-action-control/generic-action-control.component.ngfactory";
import * as i6 from "../../../controls/generic-action-control/generic-action-control.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../services/helper.service";
import * as i9 from "../../../services/modal.service";
import * as i10 from "../../../services/table-template-controls.service";
import * as i11 from "../../../pipes/custom-translate.pipe";
import * as i12 from "@angular/common";
import * as i13 from "./sub-projects-table.component";
import * as i14 from "../project-builder.service";
import * as i15 from "../../../../core/services/lookup.service";
var styles_SubProjectsTableComponent = [i0.styles];
var RenderType_SubProjectsTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubProjectsTableComponent, data: {} });
export { RenderType_SubProjectsTableComponent as RenderType_SubProjectsTableComponent };
function View_SubProjectsTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "table-area sub-projects"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TableViewComponent_0, i2.RenderType_TableViewComponent)), i1.ɵdid(2, 638976, null, 0, i3.TableViewComponent, [i1.ElementRef, i4.TranslateService], { data: [0, "data"], config: [1, "config"], firstItemIndex: [2, "firstItemIndex"], relative: [3, "relative"] }, { paginationChanged: "paginationChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableData; var currVal_1 = _co.tableConfig; var currVal_2 = _co.firstItemIndex; var currVal_3 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SubProjectsTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-action-control", [], null, [[null, "selectedAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedAction" === en)) {
        var pd_0 = (_co.onActionSelected($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_GenericActionControlComponent_0, i5.RenderType_GenericActionControlComponent)), i1.ɵdid(1, 245760, null, 0, i6.GenericActionControlComponent, [i7.Router, i8.HelperService, i9.ModalService, i10.TableTemplateControls], { rawData: [0, "rawData"], appendTo: [1, "appendTo"] }, { selectedAction: "selectedAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; var currVal_1 = ".sub-projects .ui-table-wrapper table"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SubProjectsTableComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateSubProject(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.updateSubProject(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i11.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("USERS_MODULE.usersList.view")); _ck(_v, 2, 0, currVal_0); }); }
export function View_SubProjectsTableComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { actionTemplate: 0 }), i1.ɵqud(402653184, 2, { viewColTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubProjectsTableComponent_1)), i1.ɵdid(3, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["actionTemplate", 2]], null, 0, null, View_SubProjectsTableComponent_2)), (_l()(), i1.ɵand(0, [[2, 2], ["viewColTemplate", 2]], null, 0, null, View_SubProjectsTableComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tableData == null) ? null : _co.tableData.length); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SubProjectsTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sub-projects-table", [], null, null, null, View_SubProjectsTableComponent_0, RenderType_SubProjectsTableComponent)), i1.ɵdid(1, 245760, null, 0, i13.SubProjectsTableComponent, [i9.ModalService, i14.ProjectBuilderService, i15.LookupService, i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubProjectsTableComponentNgFactory = i1.ɵccf("app-sub-projects-table", i13.SubProjectsTableComponent, View_SubProjectsTableComponent_Host_0, { showStatus: "showStatus" }, {}, []);
export { SubProjectsTableComponentNgFactory as SubProjectsTableComponentNgFactory };
