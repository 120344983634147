import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class RegReptListComponent {
    constructor(router, accountManagementService, cfrRegService, translateService, lookupService, helperService, storeService) {
        this.router = router;
        this.accountManagementService = accountManagementService;
        this.cfrRegService = cfrRegService;
        this.translateService = translateService;
        this.lookupService = lookupService;
        this.helperService = helperService;
        this.storeService = storeService;
        this._destroy$ = new Subject();
        this._stopPrevious$ = new Subject();
        this.pagination = {
            page: 0,
            size: 5,
        };
        this.firstItemIndex = 0;
        this.filterState = {};
    }
    ngOnInit() {
        console.log(this.accountId);
        this.lookupService.getTableConfigurations('REGISTRATION').subscribe(data => {
            this.tableConfig = data;
            const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
            const _columns = [
                ...this.tableConfig.columns,
                {
                    header: 'view',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.viewColTemplate,
                },
                {
                    header: 'actions',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.actionsTemplate,
                }
            ];
            this.tableConfig = Object.assign({}, config, { columns: _columns });
        });
    }
    loadData(pagination, filterState = {}) {
        this._stopPrevious$.next();
        filterState.accountId = this.accountId;
        this.accountManagementService.getRegistrationDetails(pagination, filterState)
            .pipe(takeUntil(this._destroy$), takeUntil(this._stopPrevious$)).subscribe(data => {
            this.regDetailsList = data;
        });
    }
    onPaginationChanged(pagination) {
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        this.loadData(this.pagination, this.filterState);
    }
    refreshData(event) {
        this.loadData(this.pagination, this.filterState);
    }
    viewAccount(event, data) {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        const code = data.accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB ?
            IApp.NSAccountSubType.StatusEnum.VB : IApp.NSAccountSubType.StatusEnum.PS;
        this.storeService.setHistoryUrl();
        this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/RR${code}/${this.accountId}/${data.id}`));
    }
    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
