import { Component, OnInit, Input } from '@angular/core';
import { SessionData } from '@core/models/auth.model';
import { StoreService } from '@core/store/store.service';

@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.scss'],
})
export class AccountSelectionComponent implements OnInit {
  public session: SessionData;
  
 // header = 'REGISTRATION_PAGE.accountTypeSection.obpsAccountRegistration';
  @Input('header') header: string;
  @Input('thankyoumessage') thankyoumessage: string;
  message :string;
  createNewAccount = false;

  @Input()sub_header :string;

  constructor( private store: StoreService,) {
   }

  ngOnInit() {
    this.message = this.thankyoumessage? this.thankyoumessage : 'REGISTRATION_PAGE.authAccountSelection.placeHolderText';
    this.session = this.store.getSession();
   
  }

}
