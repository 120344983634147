import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { CIVIC_ADDRESS_FORM_SECTION, POSTAL_ADDRESS_FORM_SECTION, SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { Step } from '@shared/components/steps/models/step';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { takeUntil } from 'rxjs/operators';
import { STEP3_RCA_ORG_DETAILS } from '../constant/AgreementCreateCredits.const';

@Component({
  selector: 'app-rca-org-details',
  templateUrl: './rca-org-details.component.html',
  styleUrls: ['./rca-org-details.component.scss']
})
export class RcaOrgDetailsComponent extends BaseStepperStepComponent {

  static key = 'RcaOrgDetailsComponent';

  formGroup = new FormGroup({});
  fields: FormlyFieldConfig[];
  model: any;
  existInMarkit: boolean;

  constructor(
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
    public formService: FormService,
    public service: AgreementCreditsService,
    private cfrRegistrationService: CfrRegistrationService) { super(); }

  ngOnInit() {
    this.model = this.service.currentData;
    this.fields = this.formFactoryService.configureForm(STEP3_RCA_ORG_DETAILS);
 
    // this.model.registrationAccount.civicAddress = result.civicAddress;

    if (this.model.opaDetail && this.model.opaDetail.editable) {
      this.existInMarkit = false;
    } else {
      this.existInMarkit = true;
      setTimeout(() => this.formGroup.disable());
    }

    POSTAL_ADDRESS_FORM_SECTION.key = 'registrationAccount.postalAddress';
    CIVIC_ADDRESS_FORM_SECTION.key = 'registrationAccount.civicAddress';
    CIVIC_ADDRESS_FORM_SECTION.sameAsPostal.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.postalAddress\', \'opaDetail.civicAddress\')',
      POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = true;
    this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(POSTAL_ADDRESS_FORM_SECTION, CIVIC_ADDRESS_FORM_SECTION)[0]);
    this.service.disableFormIfApplicable(this.formGroup);
 
  }

  saveAsDraft() {
    this.saveAndProceed(true);
  }

  saveAndProceed(draft: boolean = false) {
    this.service.nextStep();
  }

}
