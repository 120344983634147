import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TermsConditionsComponent } from '@core/components/terms-conditions/terms-conditions.component';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IRawFormBase } from '@core/models/raw-form.types';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { applicationTypeConfig } from '@module/submission-management/const/submission-management.const';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { combineLatest, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { PENDING_REG_KEY, PROJECT_TYPES } from '../consts/project-builder.const';

export const PROJECT_TYPE = {
  NORMAL: 'offset',
  MASTER: 'aggregated',
  SUB: 'sub',
  MASTER_RECEIVER: 'MASTER_RECEIVER'
};
@Component({
  selector: 'app-create-new-project',
  templateUrl: './create-new-project.component.html',
  styleUrls: ['./create-new-project.component.scss'],
})
export class CreateNewProjectComponent implements OnInit {
  private _destroy$ = new Subject<any>();

  visible = true;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  data: any = {};

  accountsList$ = new BehaviorSubject<IApp.IFacilityNamesLookup[]>([]);

  private previousAccountId;
  private masterProjects = [];

  private pendingReg = false;

  constructor(
    private formFactoryService: FormFactoryService,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<TermsConditionsComponent>,
    private router: Router,
    private store: StoreService,
    private translate: TranslateService,
    private helperService: HelperService,
    private submissionManagementService: SubmissionManagementService,
    private lookupService: LookupService,
    private notificationService: NotificationsService,
  ) {}

  ngOnInit() {
    this.pendingReg = !!sessionStorage.getItem(PENDING_REG_KEY);
    this.getAccountsList(this.inputData.registrationAccountId);
    this.fields = this.formFactoryService.configureForm(this.getFormFields());
    this.submissionManagementService.resetModel();
    this.formFactoryService.dictCallback
      .pipe(
        filter(field => {
          if ((field.key === 'submissionType' || field.key === 'accountId') && this.data.accountId && this.data.submissionType === 'SUB') {
            if (this.previousAccountId !== this.data.accountId) {
              this.previousAccountId = this.data.accountId;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        }),
        switchMap(() => this.lookupService.getOffsetProjectNames(this.data.accountId, { name: 'type', value: 'MASTER' })),
      )
      .subscribe(result => {
        this.masterProjects = result.filter(project => project.status === 'ACTIVE');
        this.fields = this.formFactoryService.configureForm(this.getFormFields());
        // if (field.key === 'submissionType' && this.data.submissionType !== 'CI_APPLICATION') {
        //   this.data.activityType = null;
        //   const control = this.form.get('activityType');
        //   if (control) {
        //     control.markAsPristine();
        //   }
        // }
      });
  }

  close() {
    this.visible = false;
    this.dialogRef.close();
  }

  createApplication() {
    console.log(this.data.submissionType);
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    if (this.form.valid) {
      const submissionType = `APPLICATION_${this.data.activityType || this.data.submissionType}`;
      combineLatest(this.lookupService.getFacilityNames())
        .pipe(first())
        .subscribe(([accounts]) => {
          // todo this account name should be provided in inputData
          let account = accounts.find(f => f.id === this.data.accountId);
          if (!account) {
            account = this.store.user.associationsList[0].accountDto;
          }
          this.lookupService.validatePerformAction(this.data.accountId).subscribe(t => {
            if (!t) {
              this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnPendingProjectTransferAccount');
            } else if (account.status === 'LOCKED') {
              this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnLockedAccount');
            } else if (!this.store.user.hasPermissionForAccount('CREATE_PROJECT', account.id, account.masterAccountId)) {
              this.notificationService.showMessage(SereviceMessageType.ERROR, 'accessDenied');
            } else {
              this.visible = false;
              this.dialogRef.close();
              console.log(this.data.submissionType);
              // inputData = { ...inputData, legalName: inputData.legalName };
              const projectType = PROJECT_TYPE[this.data.submissionType];
              const accountId = projectType === PROJECT_TYPES.SUB ? this.data.masterProjectId : this.data.accountId;
              const data = { ...applicationTypeConfig[submissionType], account }; // TODO create data as required by project builder
              this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/${projectType}/${accountId}`), { state: { data } });
            }
        });
      });
    }
  }

  checkRole(accountSubTypeNames, role) {
    return accountSubTypeNames && accountSubTypeNames.indexOf(role) > -1;
  }

  getFormFields(): IRawFormBase[] {
    const isCustomer = this.store.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER') && this.inputData.registrationAccountId;
    if (isCustomer) {
      this.data.accountId = this.inputData.registrationAccountId;
    }
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          {
            type: 'dict-select',
            className: 'col-6',
            key: 'accountId',
            source: this.accountsList$,
            label: 'PROJECTS_MODULE.createApplication.account',
            disabled: false,
            hideExpression: isCustomer && !this.pendingReg,
            required: true,
            placeholder: 'COMMON.submissionTypeList.selectItem',
            ignoreDropdownAppend: true,
          },
          {
            type: 'dict-select',
            className: 'col-6',
            key: 'submissionType',
            source: SOURCE_DATA_TYPE.PROJECT_TYPE,
            dataType: this.inputData.sourceDataType,
            label: 'PROJECTS_MODULE.createApplication.projectApplicationType',
            disabled: false,
            required: true,
            translatePrefix: 'COMMON.projectApplicationTypes',
            placeholder: 'selectItem',
            ignoreDropdownAppend: true,
            sort: false,
          },
          {
            type: 'dict-select',
            className: 'col-6',
            key: 'masterProjectId',
            // source: SOURCE_DATA_TYPE.APPLICATION_SUBMISSION_ACTIVITY_TYPE,
            dataType: { applicationType: 'CI_APPLICATION', foreignSupplier: this.store.user.accountSubTypes === 'FS' },
            label: 'PROJECTS_MODULE.createApplication.aggregatedProjects',
            hideExpression: `!model.submissionType || model.submissionType != 'SUB'`,
            items: this.masterProjects,
            disabled: false,
            dataProvided: true,
            required: true,
            placeholder: 'COMMON.projectApplicationTypes.selectItem',
            ignoreDropdownAppend: true,
          },
        ],
      },
    ];
  }

  private getAccountsList(accountId: number) {
    this.lookupService.getFacilityNames()
      .pipe(map(accounts => {
        if (accountId && this.pendingReg) {
          this.accountsList$.next(accounts.filter(account => Number(account.id) === Number(accountId)));
        } else {
          this.accountsList$.next(accounts.filter(account => account.status === 'ACTIVE'));
        }
      }))
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
