/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./agreement-credits-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/components/table-filter/table-filter.component.ngfactory";
import * as i5 from "../../../../shared/components/table-filter/table-filter.component";
import * as i6 from "../../../../shared/services/modal.service";
import * as i7 from "../../../../core/services/lookup.service";
import * as i8 from "../../../../core/store/store.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../../shared/services/helper.service";
import * as i11 from "../../../../shared/services/upload-document.service";
import * as i12 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i13 from "../../../../shared/components/table-view/table-view.component";
import * as i14 from "../../../../shared/controls/generic-action-control/generic-action-control.component.ngfactory";
import * as i15 from "../../../../shared/controls/generic-action-control/generic-action-control.component";
import * as i16 from "../../../../shared/services/table-template-controls.service";
import * as i17 from "@angular/common";
import * as i18 from "./agreement-credits-list.component";
import * as i19 from "../../agreement-credits.service";
var styles_AgreementCreditsListComponent = [i0.styles];
var RenderType_AgreementCreditsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgreementCreditsListComponent, data: {} });
export { RenderType_AgreementCreditsListComponent as RenderType_AgreementCreditsListComponent };
function View_AgreementCreditsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("AGREEMENT_CREDITS.tableDetails.title")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("AGREEMENT_CREDITS.tableDetails.subTitle")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("AGREEMENT_CREDITS.tableDetails.message")); _ck(_v, 8, 0, currVal_2); }); }
function View_AgreementCreditsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "filters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "app-table-filter", [], null, [[null, "filterChanged"], [null, "filterInit"], [null, "customButtonAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChanged" === en)) {
        var pd_0 = (_co.onFilterChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterInit" === en)) {
        var pd_1 = (_co.onFilterChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("customButtonAction" === en)) {
        var pd_2 = (_co.onCustomButtonAction($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_TableFilterComponent_0, i4.RenderType_TableFilterComponent)), i1.ɵdid(2, 770048, null, 0, i5.TableFilterComponent, [i1.ViewContainerRef, i6.ModalService, i7.LookupService, i3.TranslateService, i8.StoreService, i9.Router, i10.HelperService, i11.UploadDocumentService, i9.ActivatedRoute], { fields: [0, "fields"] }, { filterChanged: "filterChanged", filterInit: "filterInit", customButtonAction: "customButtonAction" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterFields; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AgreementCreditsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_TableViewComponent_0, i12.RenderType_TableViewComponent)), i1.ɵdid(1, 638976, null, 0, i13.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"] }, { paginationChanged: "paginationChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataList; var currVal_1 = _co.tableConfig; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AgreementCreditsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewAgreement($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.viewAgreement($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 2, 0, currVal_0); }); }
function View_AgreementCreditsListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-action-control", [], null, null, null, i14.View_GenericActionControlComponent_0, i14.RenderType_GenericActionControlComponent)), i1.ɵdid(1, 245760, null, 0, i15.GenericActionControlComponent, [i9.Router, i10.HelperService, i6.ModalService, i16.TableTemplateControls], { rawData: [0, "rawData"], appendTo: [1, "appendTo"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "app-agreement-credits-list"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AgreementCreditsListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-secondary mb-5 float-right"], ["style", "width: 80px;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 3, 0, currVal_0); }); }
export function View_AgreementCreditsListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { actionTemplate: 0 }), i1.ɵqud(402653184, 2, { viewColTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgreementCreditsListComponent_1)), i1.ɵdid(3, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgreementCreditsListComponent_2)), i1.ɵdid(5, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "table-area"], ["id", "agreement"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgreementCreditsListComponent_3)), i1.ɵdid(8, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["viewColTemplate", 2]], null, 0, null, View_AgreementCreditsListComponent_4)), (_l()(), i1.ɵand(0, [[1, 2], ["actionTemplate", 2]], null, 0, null, View_AgreementCreditsListComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AgreementCreditsListComponent_6)), i1.ɵdid(12, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTableDetails; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showTableDetails; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.dataList; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.showTableDetails; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_AgreementCreditsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-agreement-credits-list", [], null, null, null, View_AgreementCreditsListComponent_0, RenderType_AgreementCreditsListComponent)), i1.ɵdid(1, 245760, null, 0, i18.AgreementCreditsListComponent, [i9.ActivatedRoute, i19.AgreementCreditsService, i16.TableTemplateControls, i3.TranslateService, i7.LookupService, i10.HelperService, i17.Location, i9.Router, i8.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgreementCreditsListComponentNgFactory = i1.ɵccf("app-agreement-credits-list", i18.AgreementCreditsListComponent, View_AgreementCreditsListComponent_Host_0, { showTableDetails: "showTableDetails", registrationAccountId: "registrationAccountId" }, {}, []);
export { AgreementCreditsListComponentNgFactory as AgreementCreditsListComponentNgFactory };
