import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { rawFormEditableTableControlsMode } from '@core/models/raw-form.constants';
import { getButton } from '@core/utilities/raw-form.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
let FormlyEditableTable = class FormlyEditableTable extends FieldType {
    constructor(translateSvc, formFactorySvc, modalSvc, cd) {
        super();
        this.translateSvc = translateSvc;
        this.formFactorySvc = formFactorySvc;
        this.modalSvc = modalSvc;
        this.cd = cd;
        this.editorFields = [];
        this.editorForm = new FormGroup({});
        this.controlsFields = [];
        this.controlsForm = new FormGroup({});
        this.entryId = 0;
    }
    get data() {
        const control = this.form.get(this.controlId);
        if (control && control.value !== null) {
            return [...control.value];
        }
        return [];
    }
    set data(val) {
        this.onTableUpdate(val);
        this.form.get(this.controlId).setValue(val);
    }
    get editorIsVisible() {
        const { editorIsVisible } = this.to;
        return typeof editorIsVisible === 'boolean' ? editorIsVisible : true;
    }
    get controlId() {
        return this.to.controlId;
    }
    ngOnInit() {
        this.initEditorSub();
        this.initControlsSub();
        this.initColumnsSub();
        this.initActionsSub();
        this.initUpdateEditorModelSub();
        this.initApplyInitialDataSub();
        this.initUpdateDataSub();
        this.initRefreshEditorSub();
        this.onLanguageChange();
    }
    applyPrefix(label) {
        return `COMMON.actionsLabel.${label}`;
    }
    getSortedActions(actionList) {
        return actionList instanceof Array ? actionList.sort((a, b) => a.label.localeCompare(b.label)) : actionList;
    }
    onAction(selectedAction, data) {
        if (!selectedAction) {
            return;
        }
        const { workflowAction, custom } = selectedAction;
        if (custom !== true && (workflowAction === 'EDIT' || workflowAction === 'DELETE')) {
            if (workflowAction === 'EDIT') {
                this.editEntry(data);
            }
            if (workflowAction === 'DELETE') {
                this.deleteEntry(data);
            }
        }
        this.resetSelection();
        this.triggerAction(selectedAction.workflowAction, data);
        this.cd.detectChanges();
    }
    resetSelection() {
        setTimeout(() => {
            this.action = null;
            this.actionDropdown.handleClearClick();
            this.actionDropdown.blur();
        });
    }
    initEditorSub() {
        const editorFields = this.to.editorFields || [];
        const updateEditableFields = this.to.updateEditableFields;
        this.editorFields = editorFields;
        if (!updateEditableFields) {
            return;
        }
        updateEditableFields.pipe(untilDestroyed(this)).subscribe(fields => {
            this.editorFields = fields;
        });
    }
    initControlsSub() {
        const controlsFields = this.to.controlsFields || [];
        const updateControlsFields = this.to.updateControlsFields;
        const actions = [this.getAddButton(), this.getUpdateButton(), this.getDiscardButton()];
        this.controlsFields = this.getConfiguredControls(this.mergeControls(controlsFields, actions));
        if (!updateControlsFields) {
            return;
        }
        updateControlsFields.pipe(untilDestroyed(this)).subscribe(fields => {
            this.controlsFields = this.getConfiguredControls(this.mergeControls(fields, actions));
        });
    }
    mergeControls(controls, actions) {
        const controlsMode = this.to.controlsMode || rawFormEditableTableControlsMode.all;
        if (controlsMode === 'PREDEFINED') {
            return actions;
        }
        if (controlsMode === 'CUSTOM') {
            return controls;
        }
        return [...controls, ...actions];
    }
    getConfiguredControls(controls) {
        return this.formFactorySvc.configureForm([{ fieldGroup: [...controls] }]);
    }
    initColumnsSub() {
        const _a = this.to.config || { columns: {} }, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
        const _columns = [...this.to.config.columns];
        if (this.to.config.hasViewColumn) {
            this.to.config.columns.push({
                header: 'view',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.viewColTemplate,
            });
        }
        else if (!this.to.config.hideActionsColumn) {
            _columns.push({
                field: 'actions',
                header: 'actions',
                width: '150px',
                templateRef: this.actionsTpl,
            });
        }
        this.to.config = Object.assign({}, config, { columns: _columns });
    }
    getAction(type) {
        return {
            label: this.translateSvc.instant(this.applyPrefix(type)),
            workflowAction: type,
        };
    }
    initActionsSub() {
        const source = this.to.actions || [];
        const edit = this.getAction('EDIT');
        const remove = this.getAction('DELETE');
        const predefined = this.to.config.hideRemoveAction ? [edit] : [edit, remove];
        const ids = new Set(source.map(action => action.workflowAction));
        const filtered = predefined.filter(action => !ids.has(action.workflowAction));
        const labeled = source.map(action => (Object.assign({}, action, { custom: true })));
        const actions = [...filtered, ...labeled];
        this.actions = this.getSortedActions(actions);
        if (this.to.config.hasViewColumn) {
            this.to.config.columns.push({
                header: 'view',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.viewColTemplate,
            });
            this.actions = [];
        }
    }
    initUpdateEditorModelSub() {
        if (typeof this.to.updateEditorModel !== 'function') {
            return;
        }
        this.editorForm.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.editorModel = this.to.updateEditorModel(this.editorForm);
        });
    }
    initApplyInitialDataSub() {
        const { initialData } = this.to;
        const source = initialData instanceof Array ? initialData : [];
        this.applyData(source);
    }
    initUpdateDataSub() {
        const updateData = this.to.updateData;
        if (!updateData) {
            return;
        }
        updateData.pipe(untilDestroyed(this)).subscribe(value => {
            this.applyData(value);
            this.cd.detectChanges();
        });
    }
    initRefreshEditorSub() {
        const refreshEditor = this.to.refreshEditor;
        if (!refreshEditor) {
            return;
        }
        refreshEditor.pipe(untilDestroyed(this)).subscribe(() => {
            this.editorForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        });
    }
    applyData(data) {
        const totalKey = this.editorFields[0].fieldGroup[0].key;
        const index = data.findIndex(t => t.isTotalRecord);
        const total = [];
        total.isTotalRecord = true;
        this.editorFields[0].fieldGroup.forEach(fg => (total[fg.key] = 0));
        if (index > -1) {
            data.splice(index, 1);
        }
        this.data = data.map(entry => {
            this.entryId++;
            const columns = this.to.config.columns;
            columns
                // .filter(el => el.numeric)
                .forEach(fg => {
                if (fg.numeric) {
                    const subTotal = parseInt(total[fg.field]) + parseInt(entry[fg.field]) || 0;
                    total[fg.field] = subTotal;
                }
                else {
                    total[fg.field] = '';
                }
            });
            return Object.assign({}, entry, { id: this.entryId, actionList: this.getActionList() });
        });
        if (this.to.config.hasTotalRecord) {
            total[totalKey] = this.translateSvc.instant(`${this.to.config.translationPrefix}.totalKey`);
            this.data = [...this.data, Object.assign({}, total, { id: ++this.entryId })];
        }
    }
    getAddButton() {
        return getButton({
            id: 'addRecord',
            label: this.applyPrefix('addRecord'),
            hideExpression: () => this.editMode || this.to.config.hideRemoveAction,
            disabledExpression: () => {
                return this.editorForm.invalid;
            },
            customTemplateOptions: {
                onClick: () => {
                    this.addEntry();
                },
            },
        });
    }
    getUpdateButton() {
        return getButton({
            id: 'updateRecord',
            className: 'ml-3',
            label: this.applyPrefix('updateRecord'),
            hideExpression: () => !this.editMode,
            customTemplateOptions: {
                onClick: () => {
                    this.updateEntry();
                },
            },
        });
    }
    getDiscardButton() {
        return getButton({
            id: 'discard',
            className: 'ml-3',
            label: this.applyPrefix('discardRecord'),
            hideExpression: () => !this.editMode,
            customTemplateOptions: {
                onClick: () => {
                    this.resetModel();
                    this.triggerAfterAction('DISCARD');
                },
            },
        });
    }
    getActionList() {
        return [...this.actions];
    }
    resetModel() {
        this.editMode = false;
        this.editorForm.reset();
        this.selectedEntry = null;
    }
    onTableUpdate(value) {
        const { onTableUpdate } = this.to;
        if (typeof onTableUpdate === 'function') {
            onTableUpdate(value);
        }
    }
    onPaginationChanged(pagination) {
        debugger;
    }
    addEntry() {
        this.entryId++;
        const actionList = this.getActionList();
        const patch = Object.assign({}, this.editorForm.value, { id: this.entryId, actionList });
        this.data = [...this.data, patch];
        this.resetModel();
        this.triggerAfterAction('ADD');
    }
    updateEntry() {
        const index = this.data.findIndex(item => item.id === this.selectedEntry.id);
        const entries = [...this.data];
        const actionList = this.getActionList();
        entries[index] = Object.assign({}, this.editorModel, { actionList });
        this.data = entries;
        this.resetModel();
        this.triggerAfterAction('EDIT');
    }
    editEntry(data) {
        this.editMode = true;
        this.editorModel = Object.assign({}, data);
        this.selectedEntry = Object.assign({}, data);
    }
    deleteEntry(data) {
        this.modalSvc
            .open(ServiceMessageComponent, {
            messages: null,
            message: 'deleteEntry',
            metaData: 'translate delete',
            type: "WARNING" /* WARNING */,
        })
            .afterClosed()
            .subscribe((result) => {
            if (result === true) {
                const index = this.data.findIndex(item => item.id === data.id);
                const entries = [...this.data];
                entries.splice(index, 1);
                this.data = entries;
                this.cd.detectChanges();
                this.triggerAfterAction('DELETE');
            }
        });
    }
    triggerAction(action, data) {
        if (typeof this.to.onAction === 'function') {
            this.to.onAction(this.getAction(action), data || this.editorModel, this.formControl.value);
        }
    }
    viewAction(data) {
        this.to.onAction(this.getAction('EDIT'), data, this.formControl.value);
    }
    triggerAfterAction(action) {
        if (typeof this.to.afterAction === 'function') {
            this.to.afterAction(this.getAction(action), this.editorModel, this.formControl.value);
        }
    }
    enableDetails(data) {
        return !data.isTotalRecord;
    }
    onLanguageChange() {
        this.translateSvc.onLangChange.subscribe(() => {
            const data = this.data.map(item => {
                const actionList = item.actionList && item.actionList.map(action => {
                    return {
                        label: this.translateSvc.instant(this.applyPrefix(action.workflowAction)),
                        workflowAction: action.workflowAction,
                    };
                });
                return Object.assign({}, item, { actionList });
            });
            if (this.to.config.hasTotalRecord) {
                const total = this.data[this.data.length - 1];
                const totalKey = this.editorFields[0].fieldGroup[0].key;
                total[totalKey] = this.translateSvc.instant(`${this.to.config.translationPrefix}.totalKey`);
                data[data.length - 1] = total;
            }
            this.data = data;
        });
    }
};
FormlyEditableTable = tslib_1.__decorate([
    UntilDestroy()
], FormlyEditableTable);
export { FormlyEditableTable };
