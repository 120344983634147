<ng-container *ngIf="popUpView; else NORMAL_VIEW">
  <p-dialog
    [className]="'eccc'"
    [(visible)]="visible"
    (visibleChange)="changed($event)"
    [modal]="true"
    [responsive]="true"
    [style]="{width: '800px'}"
    [minY]="100"
    [maximizable]="false"
    [autoZIndex]="false"
    [header] = "'PROJECTS_MODULE.ProjectBuilder.subProjectDetailForm' | translate">
    <form [formGroup]="form">
      <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
    </form>
    <app-blurb *ngIf='this.needMoreInfoFields && this.needMoreInfoFields.length > 0' [message]="'PROJECTS_MODULE.ProjectForms.adminRemarksGuidanceTest'"></app-blurb>
    <formly-form [form]="form" [model]="model" [fields]="needMoreInfoFields"></formly-form>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{'PROJECTS_MODULE.ProjectBuilder.close' | translate}}</button>
      <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!model.disabled">{{'PROJECTS_MODULE.ProjectBuilder.save' | translate}}</button>
      <ng-container *ngIf="formDisabled">
        <button type="button"
                class="btn btn-primary"
                *hasPermission="'EDIT_PROJECT_PUBLIC_VISIBILITY'"
                (click)="save(true)">
          {{'PROJECTS_MODULE.ProjectBuilder.save' | translate}}
        </button>
      </ng-container>
    </div>
  </p-dialog>
</ng-container>

<ng-template #NORMAL_VIEW>
  <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
</ng-template>
<ng-container>
  <app-blurb *ngIf='this.needMoreInfoFields && this.needMoreInfoFields.length > 0' [message]="'PROJECTS_MODULE.ProjectForms.adminRemarksGuidanceTest'"></app-blurb>
  <formly-form [form]="form" [model]="model" [fields]="needMoreInfoFields"></formly-form>
</ng-container>


