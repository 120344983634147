<div class="inner">

  <nav class="navbar navbar-toggleable-md  navbar-main navbar-expand-md">
    <button class="navbar-toggler navbar-toggler-right"
            type="button"
            (click)="isNavbarCollapsed = !isNavbarCollapsed"
            aria-controls="mainCollapsingMenuBar"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse"
          id="mainCollapsingMenuBar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *ngFor="let menuItem of menuItems">
          <a
            id="{{menuItem.id}}"
            [ngClass]="{'active' : menuItem.selected}"
            class="nav-link"
            wcag-dummy-link-target
            (click)="updatePageWith(menuItem.id); setTabActive(menuItem.id)"
            >{{ menuItem.name | translate}}
          </a>
        </li>

      </ul>
    </div>
  </nav>


  <div *ngIf="loaded">
    <div class="col-sm-12 col-md-9 col-6 mb-5 d-inline-block" *ngIf="accountLoaded">

      <h1>{{ 'REGISTRATION_PAGE.cfrPendingRegistration.orgRegProgressTitle' + getSubTypeActivity | translate }}</h1>

      <h2 class="mt-3">{{ 'REGISTRATION_PAGE.cfrPendingRegistration.orgName' + getSubTypeActivity | translate }}
        <span *ngIf="facilities[menuIndex]">{{ facilities[menuIndex].legalName }}</span>
      </h2>

      <div class="row">
        <div class="col-1">
          <fa-icon class="float-left back-icon" [ngStyle]="statusIconStyle" [icon]="statusIconIcon"></fa-icon>
        </div>
        <div class="col-11">
          <p [innerHTML]="actionDesc"></p>
        </div>
        <div class="offset-1 col-8" *ngIf="showComments">
          <form [formGroup]="form">
            <formly-form [form]="form" [model]="data" [fields]="fields">
            </formly-form>
          </form>
        </div>
      </div>

      <app-previous-discussion *ngIf="discussions" [discussions]="discussions"></app-previous-discussion>

      <h2 class="mt-5">{{ 'REGISTRATION_PAGE.cfrPendingRegistration.regRequirementsTitle' | translate }}:</h2>

      <div *ngFor="let entity of entityActionList; let i = index;">
        <div class="row">
          <div class="col-1">
            <fa-icon class="float-left back-icon"
                     [ngStyle]="statusIcon[entity.status]?.style"
                     [icon]="statusIcon[entity.status]?.icon">
            </fa-icon>
          </div>
          <div class="col-11">
            {{ 'REGISTRATION_PAGE.cfrPendingRegistration.Requirement' | translate }} {{i + 1}}:
            {{ 'REGISTRATION_PAGE.cfrPendingRegistration.' + entity.workflowAction | translate }}
          </div>
        </div>

        <div class="row bottom-spacing">
          <div class="col-1"></div>
          <div class="col-11">
            <button type="button" class="btn btn-secondary mr-2" (click)="cfrRegistration(entity.code)">
              {{ 'REGISTRATION_PAGE.cfrPendingRegistration.' + entity.message | translate }}</button>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 sticky-action-button sticky-down" *ngIf="editable">
        <button
          [disabled]="docMissing || (!agreementComplete && !agreementSkipAllowed)|| !registrationComplete"
          id="stp4-btn"
          class="btn btn-primary float-right"
          type="submit"
          disableOnUpload
          (click)="submitRegistrationReport()">{{ 'REGISTRATION_PAGE.cfrPendingRegistration.submitReg' | translate }}
        </button>
      </div>
    </div>

    <div class="col-sm-12 col-md-9 col-6 mb-5 d-inline-block" *ngIf="userLoaded">
      <app-upload-pending-documents
        [pendingDocumentMetaData]="pendingDocumentMetaData"
        (documentResult)="updateDocumentDetails($event)">
      </app-upload-pending-documents>

      <div class="col-12 p-0" *ngIf="editable">
        <button
          id="stp4-btn"
          class="btn btn-primary float-right"
          type="submit"
          disableOnUpload
          (click)="submitKycFiles()">{{ 'REGISTRATION_PAGE.cfrPendingRegistration.submitReg' | translate }}
        </button>
      </div>
    </div>
  </div>

</div>
