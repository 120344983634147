import { Injectable } from '@angular/core';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import {
  CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY,
  CREDIT_BALANCE_REPORT_DATA_KEY,
  CREDIT_BALANCE_REPORT_PREFIX,
  CREDIT_BALANCE_REPORT_STEP_PREFIX,
  CREDIT_BALANCE_STEP_COMPONENT_MAP
} from '@module/credit-balance/credit-balance-report.const';
import { Observable } from 'rxjs/internal/Observable';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { StoreService } from '@core/store/store.service';

export const CREATE_CREDIT_BALANCE_REPORT = '/cfr-service/creditBalanceReport/create';
export const GET_REPORT_BY_ID = '/cfr-service/creditBalanceReport/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/creditBalanceReport/getReportToResubmit/';
export const CB_REP_SAVE = '/cfr-service/creditBalanceReport/save';
export const CB_REP_DISCARD = '/cfr-service/creditBalanceReport/discard';
export const CB_REP_PROPOSE = '/cfr-service/creditBalanceReport/proposeCreditBalanceReport';
export const CB_REP_CHECK_CALCULATION = '/cfr-service/creditBalanceReport/isCreditCalculationCorrect';

export const CB_REP_DOWNLOAD_REPORT = '/cfr-service/creditBalanceReport/download/report/';

@Injectable({
  providedIn: 'root',
})
export class CreditBalanceReportService extends BaseStepperService {
  public creditBalanceReportsDetail: any;
  public report: any;
  public stepRoutineMap: any = {};
  public currentStepInvalid = false;
  public updateSummary$ = new Subject();
  public translationPrefix = `${CREDIT_BALANCE_REPORT_PREFIX}${CREDIT_BALANCE_REPORT_STEP_PREFIX}`;
  public registrationReportsDetail: any;

  public updateSteps$ = new Subject();

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private uploadDocumentService: UploadDocumentService,
    private store: StoreService
  ) {
    super();
  }

  storeCurrentData(): void {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(CREDIT_BALANCE_REPORT_DATA_KEY, data);
    const step = JSON.stringify({...this.currentStep});
    sessionStorage.setItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY, step);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(CREDIT_BALANCE_REPORT_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(CREDIT_BALANCE_REPORT_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  restoreLastStep(): any {
    const currentStep = sessionStorage.getItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
    if (currentStep) {
      sessionStorage.removeItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
      return JSON.parse(currentStep);
    } else {
      return null;
    }
  }

  public createCreditBalanceReport(payload) {
    payload.id = null;
    return this.http.post<any>(`${ environment.apiUrl }${ CREATE_CREDIT_BALANCE_REPORT }`, payload);
  }

  public setReportSteps(omitStep = false) {
    let associatedSteps = [];
    if (omitStep) {
      associatedSteps = this.currentData.associatedSteps.filter(step => step.code !== 'STEP-2');
    } else {
      associatedSteps = this.currentData.associatedSteps.slice();
    }
    const steps = associatedSteps.map((step, index) => {
      return this.getStepContent(step, index);
    });


    this.steps = [...steps];
    return [...steps];
  }

  private getStepContent(step, index) {
    let component = CREDIT_BALANCE_STEP_COMPONENT_MAP[step.code];

    const _step = {
      ...step,
      name: `.${ step.code }_Name`,
      completed: false,
      id: index + 1,
      component,
    };
    _step.id = index + 1;
    return _step;
  }

  getReportById(reportId: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID }${ reportId }`);
  }

  getReportByIdToReSubmit(reportId: number) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_TO_RESUBMIT }${ reportId }`);
  }

  saveReport(payload): Observable<any>  {
    return this.http.post(`${ environment.apiUrl }${ CB_REP_SAVE }`, {...payload, action: 'SAVE', saveAndProceed: false});
  }

  saveAndProceed(payload): Observable<any>  {
    return this.http.post(`${ environment.apiUrl }${ CB_REP_SAVE }`, {...payload, action: 'SAVE', saveAndProceed: true})
  }

  checkCalculations(payload): Observable<any>  {
    return this.http.post(`${ environment.apiUrl }${ CB_REP_CHECK_CALCULATION }`, {...payload, action: 'SAVE', saveAndProceed: true})
  }

  submitReport(payload, action = 'SUBMIT', propose = false): Observable<any> {
    if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
      return this.http.post(`${ environment.apiUrl }${ CB_REP_PROPOSE }`, {...payload, action: 'PROPOSE'});
    } else {
      return this.http.post(`${ environment.apiUrl }${ CB_REP_SAVE }`, {...payload, action});
    }
  }

  discard(payload) {
    return this.http.post<any>(`${environment.apiUrl}${CB_REP_DISCARD}`, {...payload, action: 'DISCARD'});
  }

  public openConfirmationModal(msg, metaDataList?) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          metaDataList,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public showErrorMessage(message: string, metaDataList = []) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            { message },
          ],
          message,
          metaDataList,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public downloadDocument(documentId) {
    const uri = environment.serviceUrl + CB_REP_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json',})
      .subscribe(
        (response: any) => {
          downloadBlob(response)
        },
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  triggerAction(actionData) {
    if(actionData.action.workflowAction === 'DOWNLOAD_CREDIT_BALANCE_REPORT') {
      this.downloadDocument(actionData.data.id);
    }
  }
}
