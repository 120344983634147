import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IProject } from '@shared/services/project.service.types';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ProjectDiscussionService } from '../../service/project-discussion.service';
import { IProjectDiscussionGetComments, IProjectDiscussionGetCommentsResponse } from '../../service/project-discussion.service.types';

@Component({
  selector: 'app-project-discussion',
  templateUrl: './project-discussion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiscussionComponent implements OnInit, OnDestroy {
  @Input() project: IProject;
  @Input() hideMaps = false;

  comments: IProjectDiscussionGetCommentsResponse;

  private destroyed$ = new Subject<any>();

  constructor(
    private projectDiscussionSvc: ProjectDiscussionService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.initGetDiscussionSub();
    this.initLoadCommentsSub();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initLoadCommentsSub(): void {
    this.projectDiscussionSvc.loadComments
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.initGetDiscussionSub());
  }

  private initGetDiscussionSub(): void {
    const body: IProjectDiscussionGetComments = {
      entityId: this.project.id.toString(),
      entityType: this.project.entityName,
      entityStatus: this.project.status,
    };
    this.projectDiscussionSvc.getComments$(body).pipe(
      take(1),
    ).subscribe(data => {
      this.comments = data;
      this.cd.detectChanges();
    });
  }
}
