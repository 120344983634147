<p-dialog
[className]="'eccc'"
[closable]="false"
[header]="'CREDIT_CREATION_REPORT.createReport.title' | translate"
[(visible)]="visible"
[modal]="true"
[responsive]="true"
[style]="{width: '600px'}"
[minY]="100"
[maximizable]="false"
[autoZIndex]="false">

<div class="container">
    <form [formGroup]="form" >
        <formly-form [form]="form" [model]="data" [fields]="fields"></formly-form>
    </form>
</div>


<p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="close_btn" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
        <button id="submit_btn" type="button" class="btn btn-primary" (click)="create()">
            {{'CREDIT_CREATION_REPORT.createReport.createReport' | translate}}
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
