import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../store/store.service";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../services/auth.service";
import * as i5 from "@gilsdav/ngx-translate-router";
export class UnauthenticatedGuard {
    constructor(store, router, translate, authService, localizeService) {
        this.store = store;
        this.router = router;
        this.translate = translate;
        this.authService = authService;
        this.localizeService = localizeService;
    }
    canActivate(next, state) {
        return this.canBeActivated(next);
    }
    canBeActivated(next) {
        const session = this.store.getSession();
        if (session) {
            // check token expiration TODO
            this.router.navigate(['']);
            return of(false);
        }
        else {
            return of(true);
        }
    }
}
UnauthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UnauthenticatedGuard_Factory() { return new UnauthenticatedGuard(i0.ɵɵinject(i1.StoreService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.LocalizeRouterService)); }, token: UnauthenticatedGuard, providedIn: "root" });
