<div class="row pl-2">
  <div class="col-6">
    <app-project-activity-card [cardInfo]="cardInfo" [selected]="true"></app-project-activity-card>
  </div>
</div>
<form [formGroup]="form" #ngForm>
  <formly-form [form]="projectBuilderService.projectForm" [model]="projectBuilderService.model.activity" [fields]="fields" (modelChange)="modelChanged($event)"></formly-form>
</form>
<ng-container *ngIf="projectBuilderService.projectType==='aggregated' && projectBuilderService.showRequiredDocs">
  <div class="pl-2 border-bottom">
    <h3>{{'PROJECTS_MODULE.ProjectBuilder.subProjectDetails' | translate}}</h3>
  </div>
  <app-blurb [message]="'COMMON.documentGuidanceTest.AGG_SUB_PROJECT' | translate" class="stick-left"></app-blurb>
  <div class="pl-4 mt-3">
    <div class="d-flex justify-content-between mb-3">
      <div #UPLOAD>
        <button type="button" class="btn btn-secondary mr-3" (click)="addSubProject()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.addNewSubProject' | translate}}</button>
        <button type="button" class="btn btn-secondary" (click)="uploadSubProjects()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.uploadSubProjects' | translate}}</button>
      </div>
      <div class="tools-selector">
        <ng-select
          wcag-label
          [placeholder]="'COMMON.actionsLabel.tools' | translate"
          [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.tools' | translate}"
          [items]="taskList"
          bindLabel="name"
          bindValue="name"
          class="ng-dropdown-left"
          [(ngModel)]="downloadSelected"
          (change)="downloadTemplate($event)"
          [disabled]="projectBuilderService.disabled">
        </ng-select>
      </div>
    </div>
    <app-sub-projects-table [showStatus]="false"></app-sub-projects-table>
  </div>
</ng-container>

<ng-container *ngIf="projectBuilderService.projectType==='MASTER_RECEIVER' && projectBuilderService.showRequiredDocs">
  <app-transfer-project-to-aggregation></app-transfer-project-to-aggregation>
</ng-container>

<ng-container *ngIf="projectBuilderService.projectType==='aggregated' || projectBuilderService.projectType==='MASTER_RECEIVER'">
  <formly-form [form]="projectBuilderService.projectForm" [model]="projectBuilderService.model.activity" [fields]="aggregatedAttnDeclarationfields"></formly-form>
</ng-container>

<ng-container>
  <app-blurb *ngIf='projectBuilderService.hasApproveAction()' [message]="'PROJECTS_MODULE.ProjectForms.adminRemarksGuidanceTest'"></app-blurb>
  <formly-form [form]="projectBuilderService.projectForm" [model]="projectBuilderService.model" [fields]="needMoreInfoFields"></formly-form>
</ng-container>

<ng-container *ngIf="projectBuilderService.projectType==='MASTER_RECEIVER'">
  <app-blurb *ngIf="true" [message]="'PROJECTS_MODULE.ProjectForms.guidanceDocumentsRegistrationNewAggregation'"></app-blurb>
</ng-container>

<ng-container *ngIf="projectBuilderService.showRequiredDocs">
  <div class="pl-2 mt-5 border-bottom">
    <h3>{{'PROJECTS_MODULE.ProjectBuilder.requiredDocument' | translate}}</h3>
  </div>
  <div class="pl-4 mt-3">
    <app-project-documents></app-project-documents>
  </div>
</ng-container>
<div class="d-flex justify-content-end mt-3 pb-3">
  <button type="button" class="btn btn-secondary"
    (click)="addActivity()"
    [disabled]="documentUploadInProgress.inProgress$ |async"
    *ngIf="projectBuilderService.showAddToSummary">{{'PROJECTS_MODULE.ProjectBuilder.addToProject' | translate}}</button>
</div>
