<div class="action-bar" *ngIf="!readonly">
  <form [formGroup]="linkedAccountsForm" >
      <formly-form
        [form]="linkedAccountsForm"
        [options]="options"
        [model]="linkedAccount"
        [fields]="linkedAccountsFields">
      </formly-form>
  </form>
</div>

<div class="table-area">
  <div class="mask" *ngIf="inEdit"></div>
  <app-table-view
    [data]="items"
    [config]="tableConfig"
    [relative]="readonly || relative">
  </app-table-view>
</div>

<ng-template #roleField let-data>
  {{ roleTranslationPrefix + (data.roleId | dict: viewRoles) | translate }}
</ng-template>

<ng-template #accountField let-data>
  {{ data.accountId | dict: accounts }}
</ng-template>

<ng-template #projectFieldTmpl let-data>
  <ng-container *ngIf="data.projectNames">
  <span
    *ngFor="let project of data.projectNames.split(',')"
    class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text ">{{ project }}</span>
  </ng-container>
</ng-template>

<ng-template #accountTypeField let-data>
  {{ 'COMMON.accountTypeList.' + (data.accountId | dict: accounts :'accountTypeName') | translate }}
</ng-template>

<ng-template #programField let-data>
  {{ 'COMMON.facilityTypeList.' + (data.programId | dict: programs) | translate }}
</ng-template>

<ng-template #linkedAccountsActions let-data>
  <ng-select
        wcag-label
        [placeholder]="'COMMON.actionsLabel.actions' | translate"
        [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.actions' | translate }"
        [items]="getSortedActions(data.actionList)"
        bindLabel="label"
        bindValue="label"
        (change)="onActionSelected($event, data)"
        [clearable]="false"
        [searchWhileComposing]="false"
        [typeToSearchText]="false"
        [appendTo]="'app-linked-accounts'"
        [(ngModel)]="dummy">
    </ng-select>
</ng-template>
