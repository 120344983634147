<div class="document-container">
  <app-documents
    [documentUploadConfig]="documentUploadConfig"
    [documentsPage]="documentsPage"
    [showPlaceholders]="(projectBuilderService.model.adminPermisson || projectBuilderService.model.editParticipant)"
    (documentResult)="updateUploadedDocuments($event)"
    [relative]="true"
    [disabled]="projectBuilderService.disabled">
  </app-documents>
</div>

