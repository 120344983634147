import { HttpParams } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { replaceParam } from '@core/utilities/utilities.constants';
import { environment } from '@env/environment';
import { CreateReportNotificationModalComponent } from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.component';
import { notificationType, reportType, } from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.constants';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { PROJECT_ACCOUNT_ACTIVE_STATUSES, PROJECT_ACCOUNT_INACTIVE_ACTIONS, PROJECT_ACTIONS } from '@shared/components/project/project.constants';
import { PROJECT_CANCEL_ACTIONS, PROJECT_TRANSFER_ACTIONS, SECONDARY_ACTIONS } from '@shared/shared.const';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { last } from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "./modal.service";
import * as i4 from "./helper.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../core/store/store.service";
import * as i7 from "./notifications.service";
export const AGREEMENT_OPA_LIST = '/account-service/agreement/getOpaList';
export const SAVE_OPA_ACCOUNT = '/account-service/agreement/saveOpaAccount';
export const UPDATE_STATUS = '/account-service/agreement/updateStatus';
export const PROJECT_LIST = '/offset-service/project/pageSearchByFilter?';
export const PROJECT_DETAILS = '/offset-service/project/getById';
export const PROJECT_DETAILS_BY_PROJECT_ID = '/offset-service/project/searchProject';
export const PROJECT_SUBMIT_CANCELLATION = '/offset-service/projectAction/submitCancellation';
export const PROJECT_SAVE_CANCELLATION = '/offset-service/projectAction/saveCancellation';
export const PROJECT_REVIEW_CANCELLATION = '/offset-service/projectAction/reviewCancellation';
export const PROJECT_REVIEW_2_CANCELLATION = '/offset-service/projectAction/review2Cancellation';
export const PROJECT_SEARCH_CANCEL_HISTORY = '/offset-service/projectAction/searchCancellationHistory';
export const GET_NEW_CANCELLATION_REQUEST_ID = '/offset-service/projectAction/getNewCancellationRequestId';
export const GET_NEW_TRANSFER_REQUEST_ID = '/offset-service/projectTransferRequest/getNewProjectTransferRequestId';
export const GET_BY_PENDING_PROJECT_TRANSFER_REQUEST_ID = '/offset-service/projectTransferRequest/get/';
export const PROJECT_GET_CANCELLATION_DETAILS = '/offset-service/projectAction/get';
export const NOTES_SEARCH = '/offset-service/notes/search';
export const AGREEMENT_LOAD = '/account-service/agreement/load';
export const DOWNLOAD_PDF = '/account-service/agreement/download';
export const OFFSET_PROJECT = '/offset-service/project/';
export const PROJECT_OFFSET_RECEIVER = '/offset-service/project/consent';
export const SAVE_PROJECT = '/offset-service/project/save';
export const SUBMIT_PROJECT = '/offset-service/project/submit';
export const GET_NEW_PROJECT_AND_ACTIVITYIDS = '/offset-service/project/getNewProjectAndActivityIds';
export const PROJECT_ACCOUNT_SEARCH = '/offset-service/project/search';
export const SUBMIT_PROJECT_ADDITIONS = '/offset-service/project/getByAdditionToAggregationProjectId';
export const UPLOAD_KML = '/account-service/document/uploadKmlFile';
export const DOWNLOAD_KML = '/account-service/document/downloadKml/';
export const GET_KML_INFO = '/account-service/document/getKml/';
export const DELETE_KML = '/account-service/document/doDeleteLocationFile';
export const UPLOAD_SUB_PROJECTS = '/offset-service/project/subProject/upload';
export const VALIDATE_TRANSFER_AGG_REQ = '/offset-service/projectTransferRequest/validateTransferAggregatedProject/';
const STEPS_TRANSLATION_PREFIX = 'AGREEMENT_CREDITS.wizard.';
export const PROJECT_DETAILS_URL_REGEX = /^\/[a-z]{2}\/project\/offset\/\d*\/\d*.*/;
export const DISCARD = '/offset-service/projectTransferRequest/discard';
export const PUBLIC_PROJECT_DETAILS = '/offset-service/public/project/getById';
export const PUBLIC_PROJECT_LIST = '/offset-service/public/project/pageSearchByFilter?';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const PROJECT_SUBMISSIONS = '/offset-service/submissions/searchSubmissions';
export const GET_PROJECT_BY_ACCOUNT_PROJECT = '/offset-service/project/getByProjectIdAccountId/';
export const DEFAULT_SUBMISSIONS_PAYLOAD = {
    projectIdList: [],
    submissionsTypeCode: reportType.projectReport,
    last: false,
};
export class ProjectService extends BaseStepperService {
    constructor(http, router, modalService, helperService, translateService, store, notificationService) {
        super();
        this.http = http;
        this.router = router;
        this.modalService = modalService;
        this.helperService = helperService;
        this.translateService = translateService;
        this.store = store;
        this.notificationService = notificationService;
        this.translationPrefix = STEPS_TRANSLATION_PREFIX;
        // private _destroy$ = new Subject<any>();
        this._refreshData$ = new Subject();
        this.refreshData$ = this._refreshData$.asObservable();
        this._onProjectUpdate$ = new Subject();
        this.onProjectUpdate$ = this._refreshData$.asObservable();
        this.currentTabUrl = this.router.url;
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
            this.currentTabUrl = this.router.url;
            if (!PROJECT_DETAILS_URL_REGEX.test(event.url)) {
                this._urlBeforeProjectPage = event.url;
            }
        });
    }
    createNewSubmission(data, url) {
        const currentTab = last((url).split('/'));
        if (currentTab === 'project-list') {
            data = Object.assign({}, data, { projectTab: true });
            this.modalService.open(CreateReportNotificationModalComponent, data);
        }
        else {
            this.notificationService.showMessage("ERROR" /* ERROR */, 'TransactionNotPermittedOnPendingProjectTransferAccount');
        }
    }
    get isPublicView() {
        return !this.store.getSession();
    }
    getProjectApplicationList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        const uri = `${environment.apiUrl}${this.isPublicView ? PUBLIC_PROJECT_LIST : PROJECT_LIST}`;
        return this.http.post(uri, filterState, { params });
    }
    redirectToProjectScreen(reportId, entityId = null, project, tabName = null, edit = false) {
        if (this.isPublicView) {
            this.router.navigate(this.helperService.getTranslatedPath(`/public/project/${entityId}`), { state: { data: project } });
        }
        else {
            let uri = entityId ? `${entityId}` : '';
            if (project) {
                if (project.type === 'MASTER') {
                    uri = 'aggregated';
                }
                else if (project.type === 'MASTER_RECEIVER') {
                    uri = 'MASTER_RECEIVER';
                }
                else {
                    uri = 'offset';
                }
                // uri = project.type === 'MASTER' ? 'aggregated' : 'offset';
                uri += `/${project.accountId}/${project.id}`;
            }
            const tab = tabName ? '/' + tabName : '';
            const reDirectToSubTab = !!tabName;
            if (project.status === 'IN_PROCESS' || (reportId && edit)) {
                this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/${uri}`), { state: { data: project, edit, reportId, } });
            }
            else {
                this.router.navigate(this.helperService.getTranslatedPath(`/project/offset/${project.accountId}/${entityId}${tab}`), { state: { data: project, edit, reportId, reDirectToSubTab } });
            }
        }
    }
    redirecToUrlBeforeProjectPage() {
        if (this._urlBeforeProjectPage) {
            this.router.navigate(this.helperService.getTranslatedPath(this._urlBeforeProjectPage));
        }
        else {
            this.redirecToProjectList();
        }
    }
    redirecToProjectList() {
        this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/project-list`));
    }
    redirectToSubProject(masterProjectId, entityId, project) {
        this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/sub/${masterProjectId}/${entityId}`), { state: { data: project } });
    }
    redirectToTransferProject(masterProjectId, entityId, project) {
        this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${masterProjectId}/VIEW_PROJECT_TRANSFER_INIT`), { state: { data: project, formType: '' } });
    }
    saveProject(payload) {
        this.configureProjectDates(payload);
        return this.http.post(`${environment.serviceUrl}${SAVE_PROJECT}`, payload);
    }
    submitProject(payload) {
        this.configureProjectDates(payload);
        return this.http.post(`${environment.serviceUrl}${SUBMIT_PROJECT}`, payload);
    }
    consentProjectsTransfer(payload) {
        return this.http.post(`${environment.apiUrl}${PROJECT_OFFSET_RECEIVER}`, payload);
    }
    getNewProjectAndActivityIds() {
        return this.http.get(`${environment.apiUrl}${GET_NEW_PROJECT_AND_ACTIVITYIDS}`);
    }
    searchProjectAccount(payload) {
        return this.http.post(`${environment.apiUrl}${PROJECT_ACCOUNT_SEARCH}`, payload);
    }
    getProjectDetails(projectId, queryParams = {}, reportId) {
        let params = new HttpParams();
        Object.keys(queryParams).forEach(key => params = params.append(key, queryParams[key]));
        let uri = `${environment.apiUrl}${PROJECT_DETAILS}/${projectId}`;
        if (reportId) {
            uri = `${uri}/${reportId}`;
        }
        return this.http.get(uri, { params });
    }
    getProjectDetailsByProjectId(data) {
        return this.http.post(`${environment.apiUrl}${PROJECT_DETAILS_BY_PROJECT_ID}`, data);
    }
    submitProjectCancellationRequest(payload) {
        return this.http.post(`${environment.apiUrl}${PROJECT_SUBMIT_CANCELLATION}`, payload);
    }
    getCancellationDetails(id) {
        return this.http.get(`${environment.apiUrl}${PROJECT_GET_CANCELLATION_DETAILS}/${id}`);
    }
    saveCancellation(options) {
        return this.http.post(`${environment.apiUrl}${PROJECT_SAVE_CANCELLATION}`, options);
    }
    reviewCancellationAction(action, payload = {}) {
        const options = Object.assign({ action }, payload);
        return this.http.post(`${environment.apiUrl}${PROJECT_REVIEW_CANCELLATION}`, options);
    }
    review2CancellationAction(action, payload = {}) {
        const options = Object.assign({ action }, payload);
        return this.http.post(`${environment.apiUrl}${PROJECT_REVIEW_2_CANCELLATION}`, options);
    }
    getCancellationHistory(id) {
        return this.http.post(`${environment.apiUrl}${PROJECT_SEARCH_CANCEL_HISTORY}`, { projectId: id });
    }
    getCancellationNotes(id, dir = 'DESC') {
        let params = new HttpParams();
        params = params.append('dir', dir);
        return this.http.post(`${environment.apiUrl}${NOTES_SEARCH}`, { entityId: id, entityType: 'PROJECT_CANCELLATION' }, { params });
    }
    getNewCancellationRequestId() {
        return this.http.get(`${environment.apiUrl}${GET_NEW_CANCELLATION_REQUEST_ID}`);
    }
    getNewTransferRequestId() {
        return this.http.get(`${environment.apiUrl}${GET_NEW_TRANSFER_REQUEST_ID}`);
    }
    valTransAggReq(id) {
        return this.http.get(`${environment.apiUrl}${VALIDATE_TRANSFER_AGG_REQ}${id}`);
    }
    getByPendingProjectTransferRequestId(id) {
        return this.http.get(`${environment.apiUrl}${GET_BY_PENDING_PROJECT_TRANSFER_REQUEST_ID}${id}`);
    }
    triggerActionController(metadata, back = false) {
        const value = metadata.action;
        const mapData = PROJECT_ACTIONS[value.workflowAction] || {};
        const payload = metadata.data;
        if (PROJECT_CANCEL_ACTIONS.includes(value.workflowAction)) {
            this.router.navigate(this.helperService.getTranslatedPath(`/program-cancellation/${payload.id}`));
        }
        else if (value.workflowAction === 'REVIEW_CANCELLATION_REQUEST' || value.workflowAction === 'VIEW_CANCELLATION_REQUEST') {
            this.router.navigate(this.helperService.getTranslatedPath(`/program-cancellation/${payload.id}/review/${payload.cancellationRequestId}`));
        }
        else if (value.workflowAction === 'SUBMIT_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT' ||
            value.workflowAction === 'REVIEW_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT') {
            this.submitProjectAdditions(payload, mapData);
        }
        else if (value.workflowAction === 'CREATE_NEW_SUBMISSION') {
            this.createNewSubmission(metadata.data, this.currentTabUrl);
        }
        else if (value.workflowAction === 'VIEW_HISTORY') {
            this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/history/${payload.id}`));
        }
        else if (SECONDARY_ACTIONS.includes(value.workflowAction)) {
            if (payload.type === 'SUB') {
                this.redirectToSubProject(payload.masterProjectId, payload.id);
            }
            else {
                this.redirectToProjectScreen(null, payload.id, payload);
            }
        }
        else if (mapData.uiUri) {
            let actionRoute = mapData.uiUri;
            mapData.params.forEach(param => {
                actionRoute = replaceParam(actionRoute, param, payload[param]);
            });
            this.router.navigate(this.helperService.getTranslatedPath(actionRoute));
        }
        else if (PROJECT_TRANSFER_ACTIONS.includes(value.workflowAction)) {
            if (value.workflowAction === 'TRANSFER_AGGREGATED_PROJECT') {
                this.valTransAggReq(payload.id).subscribe(response => {
                    if (response) {
                        this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/${value.workflowAction}`), { state: { data: payload, formType: value.workflowAction } });
                    }
                }, error => {
                });
            }
            else {
                this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/${value.workflowAction}`), { state: { data: payload, formType: value.workflowAction } });
            }
        }
        else if (value.workflowAction === 'VIEW_PREVIOUS_PROJECT_TRANSFER_REQUEST') {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request-list/${payload.id}`), { state: { data: payload, formType: value.workflowAction } });
        }
        else if (value.workflowAction === 'VIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {
            const type = payload.projectTransferStatus === 'RECEIVED_CONSENT' ? 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST' : 'VIEW_PROJECT_TRANSFER_INIT';
            let apiCallForAction = false;
            if (type === 'VIEW_PROJECT_TRANSFER_INIT') {
                apiCallForAction = true;
            }
            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/${type}`), { state: { data: payload, formType: '', extraActions: apiCallForAction } });
        }
        else if (value.workflowAction === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST`), { state: { data: payload, formType: '' } });
        }
        else if (value.workflowAction === 'DISCARD_PROJECT_TRANSFER') {
            this.discard(payload, back, mapData);
        }
        else if (value.workflowAction === 'VIEW_PREVIOUS_CANCELLATION_REQUEST') {
            this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/project-cancellation-history/${payload.id}`), { state: { data: payload, formType: value.workflowAction } });
        }
        else if (['ADMIN_APPROVE_CONSENT', 'ADMIN_REJECT_CONSENT', 'ADMIN_APPROVE_CONSENT_SECOND', 'ADMIN_REJECT_CONSENT_SECOND'].includes(value.workflowAction)) {
            this.store.resetHistoryUrl();
            if (payload.status === 'TRANSFERRED') {
                this.viewTransferedProject(payload.type, payload.id);
            }
            else {
                this.redirectToProjectScreen(null, payload.id, payload);
            }
        }
        else if (value.workflowAction === 'NOTICE_OF_INTENTION_TO_CANCEL_A_PROJECT_REGISTRATION') {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-notification/${notificationType.notificationOfCancelProjectRegistration}/${payload.accountId}/${payload.id}`), { state: { data: payload, formType: value.workflowAction } });
        }
        else if (value.workflowAction === 'REVIEW_NOTICE_OF_INTENT_TO_CANCEL_A_PROJECT_REGISTRATION' ||
            value.workflowAction === 'VIEW_NOTICE_OF_INTENT_TO_CANCEL_A_PROJECT_REGISTRATION') {
            // project-notification/:notificationType/:accountId/:projectId/:entityId
            this.router.navigate(this.helperService.getTranslatedPath(`/project-notification/${notificationType.notificationOfCancelProjectRegistration}/${payload.accountId}/${payload.id}/${payload.notIntCancelProjRegEntityId}`), { state: { data: payload, formType: value.workflowAction } });
        }
        else {
            payload.action = value.workflowAction;
            this.triggerBackendAction(payload, back, mapData);
        }
    }
    triggerBackendAction(payload, back, mapData = null) {
        const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
        const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
        console.log(payload.action);
        this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: confirmationMessage,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                // payload.name,
                payload.accountName,
            ],
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
            if (result) {
                this.modalService.closeAllMessageDialogs();
                this.projectWorkflowAction(payload, mapData.uri).pipe(takeUntil(this._destroy$)).subscribe(response => {
                    this._onProjectUpdate$.next(true);
                    if (back) {
                        // const rcaSign = payload.action  === 'SIGN_AGREEMENT';
                        //  if (rcaSign) {
                        //    this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/generate/${payload.id}/rca`));
                        //  } else {
                        //  this._location.back();
                        //  }
                    }
                    else {
                        this._refreshData$.next(true);
                    }
                });
            }
        });
    }
    discardApi(data) {
        return this.http.post(`${environment.apiUrl}${DISCARD}`, data);
    }
    discard(payload, back, mapData = null) {
        let request = { id: payload.pendingTransferRequestId };
        const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
        const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
        console.log(payload.action);
        this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: confirmationMessage,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                // payload.name,
                payload.accountName,
            ],
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
            if (result) {
                this.modalService.closeAllMessageDialogs();
                this.discardApi(request).pipe(takeUntil(this._destroy$)).subscribe(response => {
                    if (back) {
                        // const rcaSign = payload.action  === 'SIGN_AGREEMENT';
                        //  if (rcaSign) {
                        //    this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/generate/${payload.id}/rca`));
                        //  } else {
                        //  this._location.back();
                        //  }
                    }
                    else {
                        this._refreshData$.next(true);
                    }
                });
            }
        });
    }
    submitProjectAdditions(payload, mapData = null) {
        const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
        const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
        this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: confirmationMessage,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                payload.accountName,
            ],
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
            if (result) {
                this.modalService.closeAllMessageDialogs();
                const id = payload[mapData.id];
                this.http.get(`${environment.apiUrl}${SUBMIT_PROJECT_ADDITIONS}/${id}`)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(response => {
                    this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/sub/${response.id}`), { state: { masterProject: response } });
                });
            }
        });
    }
    projectWorkflowAction(payload, uri) {
        return this.http.post(`${environment.apiUrl}${OFFSET_PROJECT}${uri}`, payload);
    }
    uploadDocument(formData) {
        return this.http.post(`${environment.apiUrl}${UPLOAD_KML}`, formData);
    }
    deleteKmlDocument(payload) {
        return this.http.post(`${environment.apiUrl}${DELETE_KML}`, payload);
    }
    uploadSubProjects(formData) {
        return this.http.post(`${environment.apiUrl}${UPLOAD_SUB_PROJECTS}`, formData);
    }
    downloadKML(projectId) {
        return this.http.get(`${environment.apiUrl}${DOWNLOAD_KML}${projectId}`, { responseType: 'blob', observe: 'response' });
    }
    getKmlInfo(projectId) {
        return this.http.get(`${environment.apiUrl}${GET_KML_INFO}${projectId}`);
    }
    configureProjectDates(payload) {
        if (payload.activity.municipality) {
            payload.municipality = payload.activity.municipality;
        }
        payload.activity.activityStartDate && (payload.activity.activityStartDate = moment(payload.activity.activityStartDate).format(DATE_FORMAT).toString());
        payload.activity.creditingPeriodStartDate && (payload.activity.creditingPeriodStartDate = moment(payload.activity.creditingPeriodStartDate).format(DATE_FORMAT).toString());
        payload.activity.creditingPeriodEndDate && (payload.activity.creditingPeriodEndDate = moment(payload.activity.creditingPeriodEndDate).format(DATE_FORMAT).toString());
        if (payload.subProjects && payload.subProjects.length) {
            payload.subProjects.forEach(subProject => {
                subProject.activity.activityStartDate && (subProject.activity.activityStartDate = moment(subProject.activity.activityStartDate).format(DATE_FORMAT).toString());
                subProject.activity.creditingPeriodStartDate && (subProject.activity.creditingPeriodStartDate = moment(subProject.activity.creditingPeriodStartDate).format(DATE_FORMAT).toString());
                subProject.activity.creditingPeriodEndDate && (subProject.activity.creditingPeriodEndDate = moment(subProject.activity.creditingPeriodEndDate).format(DATE_FORMAT).toString());
            });
        }
    }
    getProjectReports$(payload) {
        const url = `${environment.apiUrl}${PROJECT_SUBMISSIONS}`;
        return this.http.post(url, payload);
    }
    viewTransferedProject(projectType, projectId) {
        let params = new HttpParams();
        params = params.append('size', '10');
        const sub$ = this.http.post(`${environment.apiUrl}${OFFSET_PROJECT}projectHistory?pageSearch`, { id: projectId }, { params })
            .subscribe((data) => {
            sub$.unsubscribe();
            const project = Object.assign({}, data.content[0], { historic: true });
            if (projectType === 'SUB') {
                this.redirectToSubProject(project.masterProjectId, project.id, project);
            }
            else {
                this.redirectToProjectScreen(null, project.id, project);
            }
        });
    }
    filterActiveActions(project, actionListProp = 'entityActionList') {
        const accountStatus = project.accountStatus || project.account.status;
        const accountActive = PROJECT_ACCOUNT_ACTIVE_STATUSES.includes(accountStatus);
        let actions = project[actionListProp];
        const isAdmin = this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS);
        if (!accountActive) {
            if (!isAdmin) {
                actions = actions.filter(entity => PROJECT_ACCOUNT_INACTIVE_ACTIONS.includes(entity.workflowAction));
            }
            else {
                actions = actions.filter(action => ![...PROJECT_TRANSFER_ACTIONS, ...PROJECT_CANCEL_ACTIONS].includes(action.workflowAction.toString()));
            }
        }
        return Object.assign({}, project, { [actionListProp]: actions });
    }
    searchProject(projectId, accountId) {
        return this.http.get(`${environment.apiUrl}${GET_PROJECT_BY_ACCOUNT_PROJECT}${projectId}/${accountId}`);
    }
}
ProjectService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectService_Factory() { return new ProjectService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.TranslateService), i0.ɵɵinject(i6.StoreService), i0.ɵɵinject(i7.NotificationsService)); }, token: ProjectService, providedIn: "root" });
