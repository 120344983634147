import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { getDictSelect } from '@core/utilities/raw-form.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { IDictCallback } from '@shared/services/form-factory.types';
import { HelperService } from '@shared/services/helper.service';
import { combineLatest, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import {
  CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION,
  CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION_TYPE,
  CREATE_REPORT_NOTIFICATION_MODAL_REPORT,
  CREATE_REPORT_NOTIFICATION_MODAL_REPORT_TYPE,
  CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT,
  CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_NOTIFICATION,
  CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_REPORT,
  PR_PREFIX,
} from './create-report-notification-modal.constants';
import { ICreateReportNotificationModal } from './create-report-notification-modal.types';

@Component({
  selector: 'app-create-report-notification-modal',
  templateUrl: './create-report-notification-modal.component.html',
  styleUrls: ['./create-report-notification-modal.component.scss'],
})
export class CreateReportNotificationModalComponent implements OnInit, OnDestroy {
  get submitDisabled() {
    const { accountNameId, notificationType, projectNameId, reportTypeId, submissionTypeId } = this.data;
    const staticFields = !!submissionTypeId && !!accountNameId && !!projectNameId;
    const dynamicFields = this.mode === CREATE_REPORT_NOTIFICATION_MODAL_REPORT ? !!reportTypeId : !!notificationType;
    return !(staticFields && dynamicFields);
  }

  visible = true;
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  data: ICreateReportNotificationModal = {};
  submitLabel = CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT;

  private mode = CREATE_REPORT_NOTIFICATION_MODAL_REPORT;
  private destroy$ = new Subject<void>();
  private accountName$ = new Subject<number>();
  private reportType$ = new Subject<number>();
  private notificationType$ = new Subject<number>();
  private projects = new BehaviorSubject<IApp.ILookup[]>([]);
  private _hasSingleLinkedAccount: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<CreateReportNotificationModalComponent>,
    private formFactorySvc: FormFactoryService,
    private translateSvc: TranslateService,
    private lookupSvc: LookupService,
    private cd: ChangeDetectorRef,
    private helperSvc: HelperService,
    private router: Router,
    private storeService: StoreService,
  ) {}

  ngOnInit(): void {
    if (this.inputData) {
      this.data.accountNameId = this.inputData.accountId;
      this.data.projectNameId = this.inputData.id;
    }

    this._checkNumberOfLinkedAccounts();
    this.initGetFieldsSub();
    this.initDictCallbackSub();
    this.initAccountNameSub();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(): void {
    this.close();
    this.router.navigate(this._getSubmitTargetPath(), { state: { data: this.data } });
  }

  close(): void {
    this.visible = false;
    this.dialogRef.close();
  }

  private _isReportType(): boolean {
    return this.submitLabel === CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_REPORT;
  }

  private _getSubmitTargetPath() {
    const { reportTypeId, accountNameId, projectNameId, notificationType } = this.data;
    const type = this._isReportType() ? reportTypeId : notificationType;
    const prefix = this._isReportType() ? 'project-report' : 'project-notification';
    return this.helperSvc.getTranslatedPath(`/${prefix}/${type}/${accountNameId}/${projectNameId}`);
  }

  private initGetFieldsSub(): void {
    this.fields = this.formFactorySvc.configureForm(this.getFields());
    this.cd.detectChanges();
  }

  private initDictCallbackSub(): void {
    this.formFactorySvc.dictCallback.pipe(takeUntil(this.destroy$)).subscribe(data => {
      this.dictCallback(data);
    });
  }

  private initAccountNameSub(): void {
    this.cd.detectChanges();
    combineLatest([this.accountName$, this.reportType$, this.notificationType$])
      .pipe(
        filter(([id, report, notification]) => typeof id === 'number' && (typeof report === 'number' || typeof notification === 'number')),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(([id]) => {
        this.lookupProjects(id);
      });
  }

  private dictCallback(data: IDictCallback): void {
    if (data.id === 'submissionTypeId' && typeof data.event.id === 'string') {
      const { id } = data.event;
      this.mode = id;
      this.generateSubmitLabel(id);
      this.reloadProjectsIfApplicable();
    } else if (data.id === CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION_TYPE) {
      const event = data.event;
      this.notificationType$.next(event && (event.id as number));
      this.reloadProjectsIfApplicable();
    } else if (data.id === CREATE_REPORT_NOTIFICATION_MODAL_REPORT_TYPE) {
      const event = data.event;
      this.reportType$.next(event && (event.id as number));
      this.reloadProjectsIfApplicable();
    }
  }

  private reloadProjectsIfApplicable() {
    if (this.data.accountNameId) {
      this.lookupProjects(this.data.accountNameId);
    }
  }
  private generateSubmitLabel(id: string): void {
    this.submitLabel =
      id === CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION
        ? CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_NOTIFICATION
        : CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_REPORT;
    this.cd.detectChanges();
  }

  private lookupProjects(id: number): void {
    let typeId;
    if (this.mode === CREATE_REPORT_NOTIFICATION_MODAL_REPORT) {
      typeId = CREATE_REPORT_NOTIFICATION_MODAL_REPORT_TYPE;
    } else if (this.mode === CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION) {
      typeId = CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION_TYPE;
    }
    const control = this.form.get(typeId);
    const type = control && control.value ? control.value : undefined;
    this.lookupSvc
      .getOffsetProjectNames(id, { name: typeId, value: type })
      .pipe(take(1))
      .subscribe(projects => {
        projects = projects.map(project => {
          if (!project.subProjects) {
            project.subProjects = [];
          } else {
            project.subProjects = project.subProjects.filter(project => project.status === 'ACTIVE');
          }

          return project;
        });
        if (this.inputData && this.inputData.id) {
          const index = projects.findIndex(el => el.id === this.inputData.id);
          index > -1 ? this.form.get('projectNameId').setValue(this.inputData.id) : this.form.get('projectNameId').reset();
        }
        this.projects.next(projects);
      });
  }

  private getFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          this.getSubmissionTypeDict(),
          this.getReportTypeDict(),
          this.getNotificationTypeDict(),
          this.getAccountNameDict(),
          this.getProjectNameDict(),
        ],
      },
    ];
  }

  private _checkNumberOfLinkedAccounts() {
    this.lookupSvc.getFacilityNames().subscribe(names => (this._hasSingleLinkedAccount = names.length === 1));
  }

  private getSubmissionTypeDict(): IRawForm {
    const report = this.translateSvc.instant('PROJECTS_MODULE.createReportNotification.itemLabelReport');
    const notification = this.translateSvc.instant('PROJECTS_MODULE.createReportNotification.itemLabelNotification');
    const defaultValue = this.inputData ? this.inputData.submissionTypeId : null;

    return getDictSelect({
      id: 'submissionTypeId',
      bindValue: 'value',
      className: 'col-3',
      dataProvided: true,
      defaultValue,
      ignoreTranslateOptions: true,
      translatePrefix: PR_PREFIX,
      items: [
        { id: CREATE_REPORT_NOTIFICATION_MODAL_REPORT, name: report, value: report },
        { id: CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION, name: notification, value: notification },
      ],
      label: 'PROJECTS_MODULE.createReportNotification.submissionType',
    });
  }

  private getReportTypeDict(): IRawForm {
    return getDictSelect({
      id: CREATE_REPORT_NOTIFICATION_MODAL_REPORT_TYPE,
      bindValue: 'code',
      className: 'col-3',
      ignoreTranslateOptions: true,
      translatePrefix: PR_PREFIX,
      hideExpression: () => this.mode !== CREATE_REPORT_NOTIFICATION_MODAL_REPORT,
      label: 'PROJECTS_MODULE.createReportNotification.reportType',
      source: SOURCE_DATA_TYPE.SUBMISSION_REPORT_TYPE_LIST,
    });
  }

  private getNotificationTypeDict(): IRawForm {
    return getDictSelect({
      id: CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION_TYPE,
      bindValue: 'code',
      className: 'col-3',
      ignoreTranslateOptions: true,
      translatePrefix: PR_PREFIX,
      hideExpression: () => this.mode !== CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION,
      label: 'PROJECTS_MODULE.createReportNotification.notificationType',
      source: SOURCE_DATA_TYPE.SUBMISSION_TYPES_LIST,
    });
  }

  private getAccountNameDict(): IRawForm {
    return getDictSelect({
      id: 'accountNameId',
      className: 'col-3',
      ignoreTranslateOptions: true,
      translatePrefix: PR_PREFIX,
      ignoreDropdownAppend: true,
      label: 'PROJECTS_MODULE.createReportNotification.accountName',
      hideExpression: () => this._hasSingleLinkedAccount,
      onChange: event => this.accountName$.next(event.event && (event.event.id as number)),
      source: SOURCE_DATA_TYPE.ACTIVE_ACCOUNT_NAMES,
    });
  }

  private getProjectNameDict(): IRawForm {
    return getDictSelect({
      id: 'projectNameId',
      className: 'col-3',
      ignoreDropdownAppend: true,
      // translate: false,
      ignoreTranslateOptions: true,
      translatePrefix: PR_PREFIX,
      source: this.projects,
      label: 'PROJECTS_MODULE.createReportNotification.projectName',
      groupBy: 'subProjects',
    });
  }
}
