import { ITableViewConfig, EXPAND_COLLAPSE_TYPE, ROWS_PER_PAGE } from '../../shared/models/table-view.model';

export const MAIN_MODULE_PATH = '/reports';

export const REPORTS_MODULE = 'REPORTS_MODULE'
export const COMPENSATION_REPORT = 'compensation_report';

export const COLUMN_WIDTH = 250;

export const insufficientCredits = {
  path: 'insufficient-credits-report',
  permission: 'REPORT_INSUFFICIENT_CREDITS_TO_CANCEL'
};

export const compensationReportListTableConfig: ITableViewConfig = {
  translationPrefix: `${REPORTS_MODULE}.${COMPENSATION_REPORT}`,
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  width: 3000,
  expandSectionType:EXPAND_COLLAPSE_TYPE.SIMPLE_TABLE,
  columns: [

    {
      field: 'certificateNumber',
      header: 'coveredFacilityCertificateNumber',
      width: '150px',
      sortField: 'certificateNumber',
    },

    {
      field: 'versionProvision',
      header: 'provisionAct',
      width: '150px'
    },

    {
      field: 'compliancePeriod',
      header: 'compliancePeriod',
      width: '150px',
      sortField: 'compliancePeriod',
    },

    {
      field: 'originalCompensation',
      header: 'originalCompensation',
      width: '150px',
    },

    {
      field: 'excessEmissionCharge',
      header: 'excessEmissionCharge',
      width: '150px',
      expandCollapse:true,
      expandSectionDataRoot:'accountRemittanceResponse',
      expandSectionData:'paymentConfirmationDto',
      expandSectionTableHeader: {
        translationPrefix: `${REPORTS_MODULE}.${COMPENSATION_REPORT}`,
        paginator: true,
        rowsPerPage: ROWS_PER_PAGE,
        columns: [
          {
            field: 'paymentAmount',
            header: 'Excess Emission Charge (EEC) Payment amount',
            width: '150px',
            sortField: 'paymentAmount',
          },
          {
            field: 'paymentAmountInTonnes',
            header: 'Payment Amount in tonnes',
            width: '150px',
            sortField: 'paymentAmountInTonnes',
          },
          {
            field: 'applicableRate',
            header: 'EEC Applicable Rate',
            width: '150px',
            sortField: 'applicableRate',
          },
          {
            field: 'paymentDateFormatted',
            header: 'EEC date of payment',
            width: '150px',
            sortField: 'paymentDateFormatted',
          }
        ]
      }
    },

    {
      field: 'scrRemittanceAmount',
      header: 'scrRemittanceAmount',
      width: '150px',
      expandCollapse:true,
      expandSectionDataRoot:'accountRemittanceResponse',
      expandSectionData:'scrRemittanceUnits',
      expandSectionTableHeader: {
        translationPrefix: `${REPORTS_MODULE}.${COMPENSATION_REPORT}`,
        paginator: true,
        rowsPerPage: ROWS_PER_PAGE,
        columns: [
          {
            field: 'quantity',
            header: 'Surplus (or offset) Credit remittance Quantity',
            width: '150px',
            sortField: 'quantity',
          },
          {
            field: 'date',
            header: 'Surplus (or offset) Credit remittance Date',
            width: '150px',
            sortField: 'date',
          },
          {
            field: 'transactionNumber',
            header: 'Surplus (or offset) Credit remittance Transaction number',
            width: '150px',
            sortField: 'transactionNumber',
          },
          {
            field: 'serialNumber',
            header: 'Surplus (or offset) Credit remittance Serial Numbers',
            width: '150px',
            sortField: 'serialNumber',
          },
          {
            field: 'issuanceDate',
            header: 'Surplus (or offset) Credit Issuance date(s)',
            width: '150px',
            sortField: 'issuanceDate',
          }
        ]
      }
    },

    {
      field: 'ruRemittanceAmount',
      header: 'ruRemittanceAmount',
      width: '150px',
      expandCollapse:true,
      expandSectionDataRoot:'accountRemittanceResponse',
      expandSectionData:'ruRemittanceUnits',
      expandSectionTableHeader: {
        translationPrefix: `${REPORTS_MODULE}.${COMPENSATION_REPORT}`,
        paginator: true,
        rowsPerPage: ROWS_PER_PAGE,
        columns: [
          {
            field: 'remittanceQuantity',
            header: 'Recognized Unit (RU) remittance quantity',
            width: '150px',
            sortField: 'remittanceQuantity',
          },
          {
            field: 'jurisdiction',
            header: 'RU Jurisdiction of issuance',
            width: '150px',
            sortField: 'jurisdiction',
          },
          {
            field: 'serialNumber',
            header: 'RU Serial Numbers',
            width: '150px',
            sortField: 'serialNumber',
          },
          {
            field: 'projectStartDate',
            header: 'RU Project start date',
            width: '150px',
            sortField: 'projectStartDate',
          },
          {
            field: 'ghgReductionDate',
            header: 'RU Year GHG reductions occurred',
            width: '150px',
            sortField: 'ghgReductionDate',
          },
          {
            field: 'offsetProtocol',
            header: 'RU Offset protocol and version for project',
            width: '150px',
            sortField: 'offsetProtocol',
          },
          {
            field: 'accreditedVerifierName',
            header: 'RU name of accredited verifier',
            width: '150px',
            sortField: 'accreditedVerifierName',
          }
        ]
      }
    }
  ],
};
