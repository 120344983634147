import { Component, Input, OnInit } from '@angular/core';
import { Message } from 'primeng';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-blurb',
  templateUrl: './blurb.component.html',
  styleUrls: ['./blurb.component.scss'],
})
export class BlurbComponent implements OnInit {

  @Input() message: string;
  @Input() severity = 'info';
  _messages: Message[];

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this._messages = [
      {
        severity: this.severity,
        detail: null,
      },
    ];

    this.translate.onLangChange.subscribe(() => {
      setTimeout(() => this.getMessages());
    });
  }

  getMessages(): Message[] {
    if (this._messages.length && this.message) {
      this._messages[0].detail = this.getTranslation(this.message);
    }
    return this._messages;
  }

  set messages(val: Message[]) {
    this._messages = val;
  }

  private getTranslation(key: string): string {
    return  key && this.translate.instant(key) !== key ? this.translate.instant(key) : '';
  }

}
