
<div id="auth-contact-person-info">
    <h2>{{'AGREEMENT_CREDITS.Step.main_title' | translate }}</h2>
    <formly-form [form]="prevFormGroup" [model]="model" [fields]="prevFields" ></formly-form>

    <h2>{{'AGREEMENT_CREDITS.Step_4.otherPartyAuthOffInfo' | translate }}</h2>
    <form [formGroup]="formGroup" #ngForm>
      <formly-form #ngForm [form]="formGroup" [model]="model" [fields]="fields"></formly-form>
    </form>

  <!--  <h4 class="mt-3">{{'AGREEMENT_CREDITS.Step_4.otherPartyContactPersonInfo' | translate }}</h4>

    <formly-form [form]="formGroupOtherParty" [model]="model" [fields]="fieldsOtherParty"></formly-form>-->

</div>
