import { ITableViewConfig, ROWS_PER_PAGE } from "@shared/models/table-view.model";

export const ACCOUNT_NOTES_CONFIG: ITableViewConfig = {
  translationPrefix: 'COMMON.custom_messages',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/account-service/account/notes',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false
    }, {
      field: 'notes',
      header: 'notes',
      sortField: 'respond',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12'
    }

  ],
};

export const ACCOUNT_ACTION_CONFIRMATION_CONFIG: ITableViewConfig = {
  translationPrefix: 'COMMON.custom_messages',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  // editService: '/account-service/account/notes',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false
    }, {
      field: 'reason',
      header: 'comment',
      sortField: 'respond',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12'
    }

  ],
};

export const ACCOUNT_ACTION_EDIT_SERVICE = {
  ON_HOLD: '/account-service/offsetAccount/onHold',
  RELEASE_HOLD: '/account-service/offsetAccount/releaseHold',
  CANCEL: '/account-service/offsetAccount/cancel',
  DISCARD: '/account-service/account/discard',
  LOCK: '/account-service/account/lock',
  UNLOCK: '/account-service/account/unlock',
  REOPEN: '/account-service/account/reOpen'
}


export const ACCOUNT_CLOSUER_CONFIRMATION_CONFIG: ITableViewConfig = {
  translationPrefix: 'COMMON.custom_messages',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/account-service/account/close',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false
    },
    {
      field: 'closureReasonId',
      header: 'closeReason',
      width: '200px',
      editable: false,
      fieldType: 'dict-select',
      source: 'getAccountClosureReasons',
      inputType: 'string',
      editRowField: true,
      // className: 'col-12'
    },
    {
      field: 'reason',
      header: 'comment',
      sortField: 'respond',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
      hideExpression: '!model.closureReasonId || model.closureReasonId != 6'
    }
  ],
};


