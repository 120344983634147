<div>
  <h2 class="float-left">{{ 'ACCOUNTS_MODULE.upcomingDeadlines.title' | translate }} - {{ 'ACCOUNTS_MODULE.upcomingDeadlines.allAccounts' | translate }}
    <span *ngIf="upcomingCompensationDeadlines">({{ upcomingCompensationDeadlines.length }})</span>
  </h2>
  <div class="form-group float-right">
    <ng-select
      wcag-label
      [placeholder]="'ACCOUNTS_MODULE.upcomingDeadlines.selectPeriod' | translate"
      [inputAttrs]="{'aria-label': 'ACCOUNTS_MODULE.upcomingDeadlines.selectPeriod' | translate}"
      [items]="periods"
      class="ng-dropdown-left"
      [ngModel]="currentPeriod.id"
      [clearable]="false"
      (change)="changePeriod($event)"
      bindLabel="name"
      bindValue="id">
    </ng-select>
  </div>
</div>

<div class="table-area">
  <app-table-view
    *ngIf="totalElements > 0; else NO_DATA"
    [config]="upcomingCompensationDeadlinesTableConfig"
    [data]="upcomingCompensationDeadlines"
    [relative]="true">
  </app-table-view>
  <ng-template #NO_DATA>
    <ng-container *ngIf="totalElements === 0; else LOADING">
      <div>{{ 'COMMON.custom_messages.noItems' | translate }}</div>
    </ng-container>
    <ng-template #LOADING>
      <div>{{ 'COMMON.custom_messages.pleaseWait' | translate }}</div>
    </ng-template>
  </ng-template>
</div>

<div *ngIf="totalElements > 0">
  <p class="text-center mt-4" *ngIf="upcomingCompensationDeadlines">
  <span
   [translate]="'ACCOUNTS_MODULE.dashboard.recordsInPanel'"
   [translateParams]="{
      current: upcomingCompensationDeadlines.length,
      total: totalElements
   }"></span>&nbsp;

    <ng-container *ngIf="upcomingCompensationDeadlines.length < totalElements; else ALL_ITEMS">
      <a href="#" (click)="viewNumberOfItems($event, totalElements)">{{ 'COMMON.custom_messages.viewAll' | translate }}</a>
    </ng-container>
    <ng-template #ALL_ITEMS>
      <a href="#" (click)="viewNumberOfItems($event)">{{ 'COMMON.custom_messages.viewLess' | translate }}</a>
    </ng-template>

</p>
</div>

<ng-template #deadlineTypeColTemplate let-data>
  <ng-container *ngIf="data.regular; else NOT_REGULAR">{{ 'ACCOUNTS_MODULE.upcomingDeadlines.REGULAR' | translate }}</ng-container>
  <ng-template #NOT_REGULAR>{{ 'ACCOUNTS_MODULE.upcomingDeadlines.INCREASED' | translate }}</ng-template>
</ng-template>
