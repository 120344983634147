import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-input-switch',
 template: `
 <div class="d-flex align-items-center">
  <p-inputSwitch [formControl]="formControl" [formlyAttributes]="field"></p-inputSwitch>
  <label class="ml-3">{{to.label|translate}}</label>
 </div>

  `,
})
export class FormlyInputSwitch extends FieldType {}
