import { ITableViewConfig } from '@shared/models/table-view.model';

export const subProjectApplicationTableConfig: ITableViewConfig = {
  translationPrefix: 'PROJECTS_MODULE.subProjectList',
  columns: [
    {
      field: 'name',
      header: 'projectName',
      sortField: 'name',
      width: '200px',
    },
    {
      field: 'provinceNames',
      header: 'jurisdiction',
      sortField: 'provinceNames',
      width: '200px',
      translate: true,
      fieldType: 'chips',
      translationPrefix: 'COMMON.jurisdictionsList',
    },
    {
      field: 'activityStartDate',
      header: 'startdateStr',
      sortField: 'activityStartDate',
      width: '200px',
    },
    {
      field: 'reductionorRemovalGenerated',
      header: 'reductionorRemovalGenerated',
      sortField: 'reductionorRemovalGenerated',
      width: '200px',
    },
    {
      field: 'statusStr',
      header: 'status',
      sortField: 'status',
      width: '200px',
    },
  ],
};

export const subProjectTableConfig: ITableViewConfig = {
  translationPrefix: 'PROJECTS_MODULE.subProjectList',
  columns: [
    {
      field: 'name',
      header: 'projectName',
      sortField: 'name',
      width: '200px',
    },
    {
      field: 'projectId',
      header: 'projectId',
      sortField: 'projectId',
      width: '200px',
    },
    {
      field: 'provinceNames',
      header: 'jurisdiction',
      sortField: 'provinceNames',
      width: '200px',
      translate: true,
      fieldType: 'chips',
      translationPrefix: 'COMMON.jurisdictionsList',
    },
    {
      field: 'activityStartDate',
      header: 'startdateStr',
      sortField: 'activityStartDate',
      width: '200px',
    },
    {
      field: 'creditingPeriodStr',
      header: 'dateOfApplication',
      sortField: 'creditingPeriodStr',
      width: '200px',
    },
    {
      field: 'ghgOffsetCreditsIssued',
      header: 'offsetCreditsIssued',
      sortField: 'ghgOffsetCreditsIssued',
      numeric: true,
      width: '200px',
    },
    {
      field: 'directFinancialIncentivesReceivedStr',
      header: 'directFinancialIncentivesReceived',
      sortField: 'directFinancialIncentivesReceived',
      width: '200px',
      translate: true,
      translationPrefix: 'COMMON.yesNoList',
    },
    {
      field: 'conservationCreditReceivedStr',
      header: 'conservationCreditReceived',
      sortField: 'conservationCreditReceived',
      width: '200px',
      translate: true,
      translationPrefix: 'COMMON.yesNoList',
    },
    {
      field: 'indigenousLedBusinessStr',
      header: 'indigenousLedBusiness',
      sortField: 'indigenousLedBusiness',
      width: '200px',
      translate: true,
      translationPrefix: 'COMMON.yesNoList',
    },
    {
      field: 'statusStr',
      header: 'status',
      sortField: 'status',
      width: '200px',
    },
  ],
};

export const subProjectTableConfigMap ={
  PROJECT_APPLICATION: subProjectApplicationTableConfig,
  PROJECT: subProjectTableConfig,
};
