import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { Observable } from 'rxjs/internal/Observable';
import { CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY, CREDIT_REVENUE_REPORT_DATA_KEY, CREDIT_REVENUE_REPORT_STEP_PREFIX } from './credit-revenue-report.const';
import { ModalService } from '@shared/services/modal.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { Subject } from 'rxjs';
import { StoreService } from '@core/store/store.service';
import { AdminTakeActionModalComponent } from '@shared/components/admin-take-action-modal/admin-take-action-modal.component';
import { COCR_REVIEW_UPDATE } from '@shared/components/stepper/stepper.const';
import { IApp } from '@core/models/app.interfaces';

export const CREATE_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/getNewComplianceCreditRevReport';
export const SAVE_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/saveComplianceCreditRevenueReport';
export const SUBMIT_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/submitComplianceCreditRevenueReport';
export const RESUBMIT_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/reSubmitCCRReport';
export const PROPOSE_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/proposeReport';
export const GET_CRR_ACTIVITIES = '/cfr-service/lookup/findActivityforCCRR';
export const GET_BY_ID = '/cfr-service/complianceCreditRevReport/getReportById/';

export const SAVE_AND_NEXT_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/saveAndNextCreditRevenueReport';
export const DISCARD_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/discard';
export const CR_REP_DOWNLOAD_REPORT = '/cfr-service/complianceCreditRevReport/download/report/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/complianceCreditRevReport/getReportToResubmit/';

export const COMP_REV_REPORT_REVIEW = '/cfr-service/complianceCreditRevReport/review';


export const LC = 'http://localhost:8089/api';


@Injectable({
  providedIn: 'root'
})
export class CreditRevenueReportService extends BaseStepperService {

  public translationPrefix = `${CREDIT_REVENUE_REPORT_STEP_PREFIX}`;
  public registrationReportsDetail: any;
  currentStepInvalid = false;

  public validateActiveCrditBlockReq$ = new Subject();
  public validateActivePanelReq$ = new Subject();
  public validateActivePanelRes$ = new Subject();

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private store: StoreService
  ) {
    super();
  }

  storeCurrentData(): void {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(CREDIT_REVENUE_REPORT_DATA_KEY, data);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(CREDIT_REVENUE_REPORT_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(CREDIT_REVENUE_REPORT_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  restoreLastStep(): any {
    const currentStep = sessionStorage.getItem(CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY);
    if (currentStep) {
      sessionStorage.removeItem(CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY);
      return JSON.parse(currentStep);
    } else {
      return null;
    }
  }

  public createCreditRevenueReport(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ CREATE_CREDIT_REVENUE_REPORT }`, payload);
  }
  getReportById(reportId: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_BY_ID }${ reportId }`);
  }

  public executeAction(event) {
    if (event&& event.action.workflowAction === 'DOWNLOAD_COMPLIANCE_CREDIT_REVENUE_REPORT') {
      this.downloadDocument(event.data.id);
    } else if (['NEED_MORE_INFO', 'CREDIT_SUSPENDED', 'RESOLVED'].includes(event.action.workflowAction)) {
      this._showReviewCCRR(event.action);
    }
  }

  public triggerAction () {}

  public saveAsDraft(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ SAVE_CREDIT_REVENUE_REPORT }`, payload);
  }

  public saveAndProceed(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ SAVE_AND_NEXT_CREDIT_REVENUE_REPORT }`, payload);
  }

  public submit(payload, action) {
    let uri = SUBMIT_CREDIT_REVENUE_REPORT;
    if (action === 'RESUBMIT') {
      uri = RESUBMIT_CREDIT_REVENUE_REPORT;
    } else if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
      if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
        uri = PROPOSE_CREDIT_REVENUE_REPORT;
      }
    }
    return this.http.post<any>(`${ environment.apiUrl }${ uri }`, payload);
  }

  public getCRRActivities() {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_CRR_ACTIVITIES }`)
  }

  public openConfirmationModal(msg, metaDataList?, yesNoButton = false) {
    return this.modalService
    .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          yesNoButton,
          metaDataList
        },
        true,
        DEFAULT_DIALOG_CONFIG,
        )
        .afterClosed()
      }

  public getTranslatedLabel(prefix, label, compliancePeriod, otherPeriod = null) {
    let translated = this.translateService.instant(prefix + '.' + label);
    translated = translated.replace(`{{0}}`, `${compliancePeriod}`);
    if (otherPeriod) {
      translated = translated.replace(`{{1}}`, otherPeriod);
    }
    return translated;
  }

  public discard(id) {
    return this.http.post<any>(`${environment.apiUrl}${DISCARD_REVENUE_REPORT}`, {id});
  }

  public downloadDocument(documentId) {
    const uri = environment.apiUrl + CR_REP_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json',})
      .subscribe(
        (response: any) => {
          downloadBlob(response)
        },
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  public getReportByIdToReSubmit(reportId: number) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_TO_RESUBMIT }${ reportId }`);
  }

  public showErrorMessage(message: string) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            },
          ],
          message: message,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }

  private _showReviewCCRR(action) {
    const sub$ = this.modalService.open(
      AdminTakeActionModalComponent,
      {
        model: { id: this.currentData.id, accountId: this.currentData.accountId, adminRemark: '' } as IApp.IAdminTakeAction,
        formConfig: COCR_REVIEW_UPDATE,
        title: 'TASK_TO_DO.complianceCreditRevenueReport.title',
        primaryActionBtn: 'TASK_TO_DO.complianceCreditRevenueReport.save'
      } as IApp.IModalData,
      ).afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          result.model.action = action.workflowAction;
          this.comRevReportReviewUpdate(result.model).subscribe(() => {
            this.resetStepper();
            this.store.back();
          })
        }
        sub$.unsubscribe();
      });
  }

  public comRevReportReviewUpdate(payload: IApp.IAdminTakeAction) {
    return this.http.post<IApp.IAdminTakeAction>(`${ environment.apiUrl }${ COMP_REV_REPORT_REVIEW }`, payload);
  }
}
