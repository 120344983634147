/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/primeng/inputswitch/primeng-inputswitch.ngfactory";
import * as i2 from "primeng/inputswitch";
import * as i3 from "@angular/forms";
import * as i4 from "@ngx-formly/core";
import * as i5 from "@angular/common";
import * as i6 from "../../pipes/custom-translate.pipe";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./input-switch.type";
var styles_FormlyInputSwitch = [];
var RenderType_FormlyInputSwitch = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyInputSwitch, data: {} });
export { RenderType_FormlyInputSwitch as RenderType_FormlyInputSwitch };
export function View_FormlyInputSwitch_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "p-inputSwitch", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "blur"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onBlur($event) !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).onChange($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_InputSwitch_0, i1.RenderType_InputSwitch)), i0.ɵdid(2, 49152, null, 0, i2.InputSwitch, [i0.ChangeDetectorRef], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.InputSwitch]), i0.ɵdid(4, 540672, null, 0, i3.FormControlDirective, [[8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlDirective]), i0.ɵdid(6, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), i0.ɵdid(7, 933888, null, 0, i4.FormlyAttributes, [i0.Renderer2, i0.ElementRef, i5.DOCUMENT], { field: [0, "field"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 2, "label", [["class", "ml-3"]], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i6.CustomTranslatePipe, [i7.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.formControl; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.field; _ck(_v, 7, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 6).ngClassValid; var currVal_5 = i0.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.to.label)); _ck(_v, 9, 0, currVal_9); }); }
export function View_FormlyInputSwitch_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-input-switch", [], null, null, null, View_FormlyInputSwitch_0, RenderType_FormlyInputSwitch)), i0.ɵdid(1, 49152, null, 0, i8.FormlyInputSwitch, [], null, null)], null, null); }
var FormlyInputSwitchNgFactory = i0.ɵccf("formly-input-switch", i8.FormlyInputSwitch, View_FormlyInputSwitch_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyInputSwitchNgFactory as FormlyInputSwitchNgFactory };
