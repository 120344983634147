import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { AppInjector } from '@shared/services/app-injector.service';
import { Observable } from 'rxjs';

export const ACCOUNT_APPROVE = '/account-service/account/approve';
export const ACCOUNT_REJECT = '/account-service/account/reject';
export const ACCOUNT_CLOSE = '/account-service/account/close';
export const ACCOUNT_OPEN = '/account-service/account/reOpen';
export const ACCOUNT_DISCARD = '/account-service/account/discard';
export const ACCOUNT_LOCK = '/account-service/account/lock';
export const ACCOUNT_UNLOCK = '/account-service/account/unlock';
export const ACCOUNT_APPROVE_ALL_LINKED_ACCOUNTS = '/account-service/account/approveAllLinkedAccounts';
const ACCOUNT_APPROVE2 = '/account-service/account/dualControlApprove';

@Injectable({
  providedIn: 'root',
})
export class BaseAccountActionsService {

  public http: HttpClient;
  customConfirmations = {};

  constructor(
  ) {
    const injector = AppInjector.getInjector();
    this.http = injector.get(HttpClient);
  }

  getConfirmationMsg(key) {
    return this.customConfirmations[key] || 'confirmationMessage';
  }

  approveAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_APPROVE }`, account);
  }


  approve2Account(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_APPROVE2 }`, account);
  }

  approveAllLinkedAccountsAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_APPROVE_ALL_LINKED_ACCOUNTS }`, account);
  }

  lockAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_LOCK }`, account);
  }

  unlockAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_UNLOCK }`, account);
  }

  closeAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_CLOSE }`, account);
  }

  openAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_OPEN }`, account);
  }

  discardAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_DISCARD }`, account);
  }

  rejectAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_REJECT }`, account);
  }

  showConfirmationMessageForApproval(){
    return true;
  }

  showConfirmationMessageForRejection(){
    return true;
  }

  view(account: IApp.IAccount){
  }
}
