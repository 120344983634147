import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';

@Injectable({
  providedIn: 'root',
})
export class ActionPanelService {

  public broadcastAccountId$ = new BehaviorSubject<number>(null);
  public broadcastSubAccountSelected$ = new BehaviorSubject<boolean>(null);
  public withActionPanel$ = new BehaviorSubject<boolean>(false);
  public panelVisible$ = new BehaviorSubject<boolean>(true);
  private _account: IApp.IAccountData;

  constructor() { }

  broadcastAccountId(id) {
    this.broadcastAccountId$.next(id);
  }

  broadcastSubAccountSelected(value){
    this.broadcastSubAccountSelected$.next(value);
  }

  get accountId() {
    return this.broadcastAccountId$.getValue();
  }

  get subAccountSelected() {
    return this.broadcastSubAccountSelected$.getValue();
  }

  get account() {
    return this._account;
  }

  set account(data) {
    this._account = data;
  }

  init(event) {
    this.withActionPanel$.next(event.snapshot.data.actionPanel || false);
  }

  toggle() {
    this.panelVisible$.next(!this.panelVisible$.getValue());
  }

}
