import { IRawForm, IRawFormAppFormlyDatepicker } from '@core/models/raw-form.types';
import { getAppFormlyDatepicker, getDictSelect, getInput } from '@core/utilities/raw-form.utils';
import {
  CCR_ACTIVITY_CODE_FLD,
  CCR_PREFIX,
  CCRApplyPrefix,
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import * as moment from 'moment-timezone';

export const CCR_PROJECT_DETAILS = '.projectDetails';
export const CCR_PROJECT_CONTACT_PERSON = 'contactPerson';
export const CCR_PROJECT_DETAILS_PROJECT_ID = 'id';
export const CCR_PROJECT_DETAILS_PROJECT_ID_FLD = 'applicationId';
export const CCR_PROJECT_DETAILS_PROJECT_NAME = 'projectName';
export const CCR_PROJECT_DETAILS_PROJECT_TYPE = 'projectType';
export const CCR_PROJECT_DETAILS_QUANTIFICATION_METHOD = 'quantificationMethod';
export const CCR_PROJECT_DETAILS_FUEL_IDS = 'fuelIds';
export const CCR_PROJECT_DETAILS_CARBON_INTENSITY_FUEL = 'carbonIntensityFuel';
export const CCR_PROJECT_ANTICIPATED_START_DATE = 'startDate';
export const CCR_PROJECT_ANTICIPATED_END_DATE = 'endDate';
export const CCR_PROJECT_CREDITING_START_DATE = 'creditingPeriodStartDate';
export const CCR_PROJECT_DURATION = 'creditingPeriodDuration';

export const getProjectId = (disabledExpression?: () => boolean): IRawForm => {
  return getInput({
    id: CCR_PROJECT_DETAILS_PROJECT_ID_FLD,
    className: 'col-6',
    disabled: true,
    label: CCRApplyPrefix(CCR_PROJECT_DETAILS_PROJECT_ID, CCR_PROJECT_DETAILS),
    translatePrefix: CCR_PREFIX + CCR_PROJECT_DETAILS,
  });
};

export const getProjectName = (disabledExpression?: () => boolean): IRawForm => {
  return getInput({
    id: CCR_PROJECT_DETAILS_PROJECT_NAME,
    className: 'col-6',
    disabled: true,
    required: false,
    label: CCRApplyPrefix(CCR_PROJECT_DETAILS_PROJECT_NAME, CCR_PROJECT_DETAILS),
    translatePrefix: CCR_PREFIX + CCR_PROJECT_DETAILS,
  });
};

export const getProjectType = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CCR_PROJECT_DETAILS_PROJECT_TYPE,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.ERP_PROJECT_TYPE,
    label: CCRApplyPrefix(CCR_PROJECT_DETAILS_PROJECT_TYPE, CCR_PROJECT_DETAILS),
    onChange,
    disabled: true,
    translate: true,
    translatePrefix: 'COMMON.erpProjectTypeList',
  });
};

export const getQuantificationMethod = (source: BehaviorSubject<any>, disabledExpression?: () => boolean): IRawForm => {
  return getDictSelect({
    id: CCR_PROJECT_DETAILS_QUANTIFICATION_METHOD,
    className: 'col-6',
    disabled: true,
    source,
    label: CCRApplyPrefix(CCR_PROJECT_DETAILS_QUANTIFICATION_METHOD, CCR_PROJECT_DETAILS),
    ignoreTranslateOptions: true,
    translatePrefix: CCR_PREFIX + CCR_PROJECT_DETAILS,
  });
};

export const getFuelType = (source: Observable<any>, reload$?: Observable<any>): IRawForm => {
  return {
    type: 'multi-checkbox',
    key: CCR_PROJECT_DETAILS_FUEL_IDS,
    label: CCRApplyPrefix(CCR_PROJECT_DETAILS_FUEL_IDS, CCR_PROJECT_DETAILS),
    disabled: true,
    required: true,
    source,
    className: 'col-6',
    id: CCR_ACTIVITY_CODE_FLD,
    reload$,
  };
};

export const getCarbonIntensity = (disabledExpression?: () => boolean): IRawForm => {
  return getInput({
    id: CCR_PROJECT_DETAILS_CARBON_INTENSITY_FUEL,
    className: 'col-6',
    disabled: true,
    label: CCRApplyPrefix(CCR_PROJECT_DETAILS_CARBON_INTENSITY_FUEL, CCR_PROJECT_DETAILS),
    translatePrefix: CCR_PREFIX + CCR_PROJECT_DETAILS,
  });
};

export const getAnticipatedStartDate = (onChange: () => void): IRawFormAppFormlyDatepicker => {
  const value = CCRApplyPrefix(CCR_PROJECT_ANTICIPATED_START_DATE, CCR_PROJECT_DETAILS);
  return getAppFormlyDatepicker({
    id: CCR_PROJECT_ANTICIPATED_START_DATE,
    label: value,
    onChange,
    placeholder: value,
    required: true,
    expressionProperties: { 'templateOptions.maxDate': `model.${ CCR_PROJECT_CREDITING_START_DATE }` },
  });
};

export const getAnticipatedEndDate = (onChange: () => void): IRawFormAppFormlyDatepicker => {
  const value = CCRApplyPrefix(CCR_PROJECT_ANTICIPATED_END_DATE, CCR_PROJECT_DETAILS);
  return getAppFormlyDatepicker({
    id: CCR_PROJECT_ANTICIPATED_END_DATE,
    label: value,
    onChange,
    placeholder: value,
    required: false,
    expressionProperties: { 'templateOptions.minDate': `model.${ CCR_PROJECT_ANTICIPATED_START_DATE }` },
  });
};

export const getProjectDuration = (): IRawForm => {
  return getInput({
    id: CCR_PROJECT_DURATION,
    className: 'col-6',
    disabled: true,
    required: false,
    label: CCRApplyPrefix(CCR_PROJECT_DURATION, CCR_PROJECT_DETAILS),
    translatePrefix: CCR_PREFIX + CCR_PROJECT_DETAILS,
  });
};

export const getProjectCreditingEndDate = (): IRawFormAppFormlyDatepicker => {
  const value = CCRApplyPrefix(CCR_PROJECT_CREDITING_START_DATE, CCR_PROJECT_DETAILS);
  return getAppFormlyDatepicker({
    id: CCR_PROJECT_CREDITING_START_DATE,
    label: value,
    placeholder: value,
    required: false,
    templateOptions: {
      minDate: moment().local().toDate(),
    },
  });
};

export const getRegulatoryRegime = (disabled = false): IRawForm[] => {
  return [
  {
    arrayField: true,
    key: 'applicationRegulatoryRegimes',
    type: 'form-array',
    removeText: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.remove',
    formRowClass: 'col-sm-12 col-md-10',
    buttonClass: 'col-sm-12 col-md-2',
    removeButtons: true,
    disabled,
    fieldGroup: [
      {
        key: 'entityName',
        type: 'dict-select',
        className: 'col-sm-12 col-md-6',
        required: false,
        source: SOURCE_DATA_TYPE.REGULATORY_REGIME,
        translatePrefix: 'COMMON.regulatoryRegimeList',
        placeholder: 'selectItem',
       },
      {
        type: 'input',
        key: 'name',
        className: 'col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.description',
        dummyLabel: true,
        disabled: false,
        required: false,
      },
    ],
  },
  ];
};

export const getEntityByProp = (objects: BehaviorSubject<any>, value: any, prop: string = 'id') => {
  return objects.getValue().find(el => el[prop] === value);
};
