import { Component, Input, OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TasksService } from '@shared/services/tasks.service';

@UntilDestroy()
@Component({
  selector: 'app-project-tasks',
  templateUrl: './project-tasks.component.html',
  styleUrls: ['./project-tasks.component.scss'],
})
export class ProjectTasksComponent implements OnInit {

  @Input() projectId: number;

  tasks: IApp.ITask[];

  constructor(
    private service: TasksService,
  ) {}

  ngOnInit() {
    this.service.getProjectTasks(this.projectId)
      .pipe(untilDestroyed(this))
      .subscribe(data => this.tasks = data);
  }

  doAction(task: IApp.ITask): void {
    this.service.doAction(task);
  }

}
