import { Component, OnInit, Input, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { usersListTableConfig } from '@module/account-management/account-management.const';
import { IApp } from '@core/models/app.interfaces';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { StoreService } from '@core/store/store.service';
import { CurrentUser } from '@core/models/user.model';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HelperService } from "@shared/services/helper.service";
import { UserService } from '@shared/services/user.service';
import { LookupService } from '@core/services/lookup.service';

export const PERMISSION_APPROVE_NEW_USER = 'APPROVE_NEW_USER';

@Component({
  selector: 'app-assigned-users',
  templateUrl: './assigned-users.component.html',
  styleUrls: ['./assigned-users.component.scss'],
})
export class AssignedUsersComponent implements OnInit {

  @Input() accountId : number;
  @Input() userList : IApp.IUser[];
  @Output() actionSuccesfull = new EventEmitter<boolean>();
  @ViewChild('accountUserActions', {static: true}) public accountUserActions: TemplateRef<any>;
  @ViewChild('viewColTemplate', {static: true}) public viewColTemplate: TemplateRef<any>;
  @ViewChild('expandUserRowTemplate', {static: true}) public expandUserRowTemplate: TemplateRef<any>;
  @ViewChild('roleColumnTemplate', {static: true}) public roleColumnTemplate: TemplateRef<any>;

  roles: IApp.ILookup[];
  private _destroy$ = new Subject<any>();
  user: CurrentUser;
  tableConfig: ITableViewConfig;
  roleTranslationPrefix: string;

  constructor(
    private store: StoreService,
    private accountManagementService: AccountManagementService,
    private router: Router,
    private helperService: HelperService,
    private userService: UserService,
    private lookupService: LookupService
  ) { }

  ngOnInit() {

    this.user = this.store.user;
    this.roleTranslationPrefix =  `COMMON.${this.store.user.program.toLowerCase()}RoleList.`;


    const { columns, ...config } = usersListTableConfig;
    const _columns = [
      ...usersListTableConfig.columns,
    ];

    this.getRoles();

    _columns.push( {
      header: 'view',
      width: AUTO_WIDTH_FOR_COLUMNS,
      templateRef: this.viewColTemplate,
    });

    _columns[4].templateRef = this.roleColumnTemplate;
    config.expandSectionTemplate = this.expandUserRowTemplate;

   /** if(this.user.hasPermission(PERMISSION_APPROVE_NEW_USER)){
      _columns.push( {
        field: 'actions',
        header: 'actions',
        width: AUTO_WIDTH_FOR_COLUMNS,
        sortField: 'actions',
        templateRef: this.accountUserActions,
        className: 'overflow-visible',
      });
    } */
    this.tableConfig = {
      ...config,
      ...{columns: _columns},
    };

  }

  refreshData(event) {
    this.accountManagementService.searchUsersByAccountId(this.accountId).pipe(
     takeUntil(this._destroy$),
   ).subscribe(
     data => {
       this.userList = data;
     });
   }

   getRoles() {
    this.accountManagementService.getAllRoles()
      .pipe(
        takeUntil(this._destroy$),
        filter(d => d !== null),
      ).subscribe(
        data => this.roles = data,
    );
  }

   viewUser(event, userId) {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    this.router.navigate(this.helperService.getTranslatedPath(`/user/list/${ userId }`));
  }

}

