import { Component } from '@angular/core';
import { LookupService } from '@core/services/lookup.service';
import { FieldType } from '@ngx-formly/core';
import { SEARCHABLE_ACCOUNT_ID_VALID } from '@module/unit-management/components/credit-transfer-form/credit-transfer-form.const';

@Component({
  selector: 'formly-field-account-id-search',
  template: `
    <div class="form-group" [class.has-error]="!isValid">
      <div class="input-group">
        <input type="text"
               [id]="elementId"
               class="form-control"
               [(ngModel)]="accountId"
               [class.is-invalid]="!isValid"
               [placeholder]="to.placeholder"
               (blur)="updateAccountId()"
               (keyup)="clearErrors()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary"
                  type="button"
                  (click)="search()">{{'COMMON.filters.filterSearch' | translate}}</button>
        </div>
        <div *ngIf="accountFound"
             class="invalid-feedback"
             style="color: green"
             role="alert"
             [class.d-block]="accountFound">{{ 'COMMON.filters.accountFound' | translate }}: {{_accountName}}</div>
      </div>
    </div>
  `,
})

export class FormlyFieldAccoundIdSearch extends FieldType {

  isValid = true;
  submitted = false;
  accountId: string = null;
  _accountFound = false;
  _accountName:string;
  fieldControl: any;

  constructor(private lookupservice: LookupService) {
    super();
  }

  get accountFound() {
    this.fieldControl = this.form.get(this.field.key);
    return this.fieldControl && this.fieldControl.touched && this._accountFound;
  }

  clearErrors() {
    this.formControl.setErrors(null);
    this._accountFound = false;
    this.model[SEARCHABLE_ACCOUNT_ID_VALID] = true;
  }

  updateAccountId() {
    this.accountId = this.accountId && this.accountId.trim();
    if (this.model[this.field.key] !== this.accountId) {
      this._accountFound = false;
      this.search();
    } else {
      this.fieldControl.setValue(this.accountId);
      this.fieldControl.markAsDirty();
    }
    this.model[this.field.key] = this.accountId === '' ? null : this.accountId;
    if (!this.accountId) {
      this.fieldControl.markAsDirty();
      this.fieldControl.setErrors({'required': 'required'});
    }
  }

  get elementId(): string {
    return `${this.form['id'] ? this.form['id'] + '_' : ''}${this.key.replace('.', '_')}`;
  }

  search() {
    this.fieldControl = this.form.get(this.field.key);
    if (this.accountId) {
      const uri = this.to.uri;
      this.submitted = true;
      this.fieldControl.markAsTouched();
      this.lookupservice.findAccount(`${uri}`, this.accountId)
      .subscribe(data => {
        if (data === 'false') {
          this.fieldControl.markAsDirty();
          this.fieldControl.setErrors({ 'server-side': 'destinationAccountInvalid' });
          this.model[SEARCHABLE_ACCOUNT_ID_VALID] = false;
        } else {
          this.formControl.setValue(null);
          this.formControl.setValue(this.accountId);
          this.fieldControl.markAsDirty();
          if (this.fieldControl.valid) {
            this._accountFound = true;
            this.model[SEARCHABLE_ACCOUNT_ID_VALID] = true;
            this._accountName = data;
          }
        }
      },
        err => {
          const parsed = JSON.parse(err.error);
          const msg = parsed.messages && parsed.messages[0] ? parsed.messages[0] : 'destinationAccountInvalid';
          this.fieldControl.markAsDirty();
          this.fieldControl.setErrors({ 'server-side': msg });
          this.model[SEARCHABLE_ACCOUNT_ID_VALID] = false;
          },
        );
    } else {
      this.fieldControl.markAsDirty();
      this.fieldControl.setErrors({'required': 'required'});
      this.model[SEARCHABLE_ACCOUNT_ID_VALID] = false;
    }
  }

}
