/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-summary-projects.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i3 from "../../../../shared/components/table-view/table-view.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../shared/pipes/localized-decimal.pipe";
import * as i6 from "@angular/common";
import * as i7 from "../../../../shared/pipes/custom-translate.pipe";
import * as i8 from "../../../../shared/components/table-filter/table-filter.component.ngfactory";
import * as i9 from "../../../../shared/components/table-filter/table-filter.component";
import * as i10 from "../../../../shared/services/modal.service";
import * as i11 from "../../../../core/services/lookup.service";
import * as i12 from "../../../../core/store/store.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../../shared/services/helper.service";
import * as i15 from "../../../../shared/services/upload-document.service";
import * as i16 from "./account-summary-projects.component";
import * as i17 from "../../../../shared/services/navigation.service";
import * as i18 from "../../services/account-management.service";
var styles_AccountSummaryProjectsComponent = [i0.styles];
var RenderType_AccountSummaryProjectsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountSummaryProjectsComponent, data: {} });
export { RenderType_AccountSummaryProjectsComponent as RenderType_AccountSummaryProjectsComponent };
function View_AccountSummaryProjectsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TableViewComponent_0, i2.RenderType_TableViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.TableViewComponent, [i1.ElementRef, i4.TranslateService], { data: [0, "data"], config: [1, "config"], highlightRowId: [2, "highlightRowId"] }, { paginationChanged: "paginationChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableData; var currVal_1 = _co.tableConfig; var currVal_2 = _co.highlightRowId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AccountSummaryProjectsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedProject[_v.parent.parent.context.$implicit.field] ? _co.selectedProject[_v.parent.parent.context.$implicit.field] : _v.parent.parent.context.$implicit.default); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountSummaryProjectsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(0, i5.LocalizedDecimalPipe, [i4.TranslateService, i6.DecimalPipe])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedProject[_v.parent.parent.context.$implicit.field] ? i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.selectedProject[_v.parent.parent.context.$implicit.field], "1.")) : _v.parent.parent.context.$implicit.default); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountSummaryProjectsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSummaryProjectsComponent_5)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NUMERIC", 2]], null, 0, null, View_AccountSummaryProjectsComponent_6))], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit.numericField; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AccountSummaryProjectsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i7.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedProject[_v.parent.context.$implicit.field] ? i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform((_v.parent.context.$implicit.translatePrefix + _co.selectedProject[_v.parent.context.$implicit.field]))) : _v.parent.context.$implicit.default); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountSummaryProjectsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "col-3 my-3 label"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i7.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "col-3 my-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSummaryProjectsComponent_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["TRANSLATE", 2]], null, 0, null, View_AccountSummaryProjectsComponent_7))], function (_ck, _v) { var currVal_1 = !_v.context.$implicit.translatePrefix; var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("ACCOUNTS_MODULE.accountSummaryProject." + _v.context.$implicit.label))); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountSummaryProjectsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "table-details d-flex flex-column overflow-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "table-details-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "border-bottom d-flex justify-content-between p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "table-details-content overflow-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "row m-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSummaryProjectsComponent_3)), i1.ɵdid(10, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.projectDetails; _ck(_v, 10, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.selectedProject.name; _ck(_v, 4, 0, currVal_0); }); }
function View_AccountSummaryProjectsComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewProject($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.viewProject($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i7.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 2, 0, currVal_0); }); }
export function View_AccountSummaryProjectsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewColTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "header", [["class", "border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i7.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 11, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "table-filters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 16777216, null, null, 1, "app-table-filter", [], null, [[null, "filterChanged"], [null, "filterInit"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChanged" === en)) {
        var pd_0 = (_co.onFilterChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterInit" === en)) {
        var pd_1 = (_co.onFilterChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_TableFilterComponent_0, i8.RenderType_TableFilterComponent)), i1.ɵdid(10, 770048, null, 0, i9.TableFilterComponent, [i1.ViewContainerRef, i10.ModalService, i11.LookupService, i4.TranslateService, i12.StoreService, i13.Router, i14.HelperService, i15.UploadDocumentService, i13.ActivatedRoute], { fields: [0, "fields"] }, { filterChanged: "filterChanged", filterInit: "filterInit" }), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "data-container mt-2 overflow-hidden h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "table overflow-hidden h-100 position-relative"]], null, null, null, null, null)), i1.ɵprd(512, null, i6.ɵNgClassImpl, i6.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i6.NgClass, [i6.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSummaryProjectsComponent_1)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSummaryProjectsComponent_2)), i1.ɵdid(18, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 4, "footer", [["class", "border-top pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "content d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(22, null, [" ", " "])), i1.ɵpid(131072, i7.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [[1, 2], ["viewColTemplate", 2]], null, 0, null, View_AccountSummaryProjectsComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.filterFields; _ck(_v, 10, 0, currVal_1); var currVal_2 = "table overflow-hidden h-100 position-relative"; var currVal_3 = (_co.showDetails ? "show-details" : "hide-details"); _ck(_v, 14, 0, currVal_2, currVal_3); var currVal_4 = _co.tableData; _ck(_v, 16, 0, currVal_4); var currVal_5 = _co.showTableDetails; _ck(_v, 18, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("ACCOUNTS_MODULE.accountSummaryProject.projectSummary")); _ck(_v, 5, 0, currVal_0); var currVal_6 = i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("ACCOUNTS_MODULE.accountSummaryProject.close")); _ck(_v, 22, 0, currVal_6); }); }
export function View_AccountSummaryProjectsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-summary-projects", [], null, null, null, View_AccountSummaryProjectsComponent_0, RenderType_AccountSummaryProjectsComponent)), i1.ɵdid(1, 114688, null, 0, i16.AccountSummaryProjectsComponent, [i17.NavigationService, i11.LookupService, i18.AccountManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountSummaryProjectsComponentNgFactory = i1.ɵccf("app-account-summary-projects", i16.AccountSummaryProjectsComponent, View_AccountSummaryProjectsComponent_Host_0, {}, {}, []);
export { AccountSummaryProjectsComponentNgFactory as AccountSummaryProjectsComponentNgFactory };
