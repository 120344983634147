/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/platform-browser";
import * as i4 from "primeng/tooltip";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/formly/formly-custom-types/formly-custom-validation-message.component.ngfactory";
import * as i8 from "../../../shared/formly/formly-custom-types/formly-custom-validation-message.component";
import * as i9 from "@ngx-formly/core";
import * as i10 from "./form-field-wrapper.component";
var styles_FormlyWrapperFormField = [];
var RenderType_FormlyWrapperFormField = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyWrapperFormField, data: {} });
export { RenderType_FormlyWrapperFormField as RenderType_FormlyWrapperFormField };
function View_FormlyWrapperFormField_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Required field, "]))], null, null); }
function View_FormlyWrapperFormField_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["*"]))], null, null); }
function View_FormlyWrapperFormField_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "fa-icon", [["class", "ng-fa-icon"], ["tooltipStyleClass", "tooltip-min-width"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(1, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaConfig, i2.FaIconLibrary, [2, i2.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i0.ɵpad(2, 2), i0.ɵdid(3, 4341760, null, 0, i4.Tooltip, [i0.ElementRef, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipStyleClass: [1, "tooltipStyleClass"], escape: [2, "escape"], text: [3, "text"] }, null), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.to.icon == null) ? null : _co.to.icon.iconProp) ? _co.to.icon.iconProp : _ck(_v, 2, 0, "fas", "info-circle")); _ck(_v, 1, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "", (((_co.to.icon == null) ? null : _co.to.icon.tooltipPosition) ? ((_co.to.icon == null) ? null : _co.to.icon.tooltipPosition) : "top"), ""); var currVal_4 = "tooltip-min-width"; var currVal_5 = false; var currVal_6 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 3, i0.ɵnov(_v, 4).transform(((_co.to.icon == null) ? null : _co.to.icon.text))), ""); _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).title; var currVal_1 = i0.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FormlyWrapperFormField_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "label", [], [[1, "for", 0]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_2)), i0.ɵdid(2, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_3)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [": "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_4)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.to.required && (_co.to.hideRequiredMarker !== true)); _ck(_v, 2, 0, currVal_1); var currVal_3 = (_co.to.required && (_co.to.hideRequiredMarker !== true)); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.to.icon; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.itemLabelPrefix + _co.elementId); _ck(_v, 0, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.to.label)); _ck(_v, 3, 0, currVal_2); }); }
function View_FormlyWrapperFormField_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "wcag-visuallyhidden"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.itemLabelPrefix + _co.elementId); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.to.label; _ck(_v, 1, 0, currVal_1); }); }
function View_FormlyWrapperFormField_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "input", [["class", "wcag-visuallyhidden"], ["tabindex", "-1"]], [[8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.itemLabelPrefix + _co.elementId); _ck(_v, 0, 0, currVal_0); }); }
function View_FormlyWrapperFormField_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_FormlyWrapperFormField_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "invalid-feedback"]], [[4, "display", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "formly-custom-validation-message", [], null, null, null, i7.View_FormlyCustomValidationMessage_0, i7.RenderType_FormlyCustomValidationMessage)), i0.ɵdid(2, 573440, null, 0, i8.FormlyCustomValidationMessage, [i9.FormlyConfig], { field: [0, "field"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.field; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = "block"; _ck(_v, 0, 0, currVal_0); }); }
function View_FormlyWrapperFormField_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "small", [["class", "form-text text-muted"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.description; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormlyWrapperFormField_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { fieldComponent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "form-group"]], [[2, "has-error", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_5)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_6)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, [[1, 3], ["fieldComponent", 2]], null, 0, null, View_FormlyWrapperFormField_7)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_8)), i0.ɵdid(10, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyWrapperFormField_9)), i0.ɵdid(12, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.to.label && (_co.to.hideLabel !== true)) && (_co.to.dummyLabel !== true)); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.to.label && (_co.to.dummyLabel === true)); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.to.dummyInputForLabel; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.showError; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.to.description; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showError; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormlyWrapperFormField_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-wrapper-form-field", [], null, null, null, View_FormlyWrapperFormField_0, RenderType_FormlyWrapperFormField)), i0.ɵdid(1, 49152, null, 0, i10.FormlyWrapperFormField, [], null, null)], null, null); }
var FormlyWrapperFormFieldNgFactory = i0.ɵccf("formly-wrapper-form-field", i10.FormlyWrapperFormField, View_FormlyWrapperFormField_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyWrapperFormFieldNgFactory as FormlyWrapperFormFieldNgFactory };
