import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-form-array-table',
  templateUrl: './form-array-table.component.html',
  styleUrls: ['./form-array-table.component.scss']
})
export class FormArrayTableComponent implements OnInit {

  @Input() headers: any;
  @Input() form: FormGroup;
  @Input() model: any;
  @Input() fields: FormlyFieldConfig[];
  @Input() addButtonText: string;
  @Input() disabled: boolean

  @Output() addItem = new EventEmitter()

  constructor() { }

  ngOnInit() {
  }

  public add() {
    this.addItem.next();
  }

}
