import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { SessionData } from '@core/models/auth.model';
import { CurrentUser } from '@core/models/user.model';
import { environment } from '@env/environment';
import { AutoResume, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionEndComponent } from '@shared/components/session-end/session-end.component';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CATSKeepAlive } from '@core/utilities/cats-keep-alive';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../store/store.service";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@ng-idle/core";
import * as i6 from "primeng/api";
import * as i7 from "../../shared/services/helper.service";
import * as i8 from "../../shared/services/modal.service";
import * as i9 from "@angular/material/dialog";
import * as i10 from "./lookup.service";
import * as i11 from "ngx-cookie-service";
import * as i12 from "@gilsdav/ngx-translate-router";
import * as i13 from "../../shared/services/user.service";
export const AUTH_SERVICE = '/auth-server';
// export const AUTH_SERVICE = 'http://localhost:8081/api/eccc-auth-server';
export const AUTH_LOGIN_URL = '/auth/uilogin';
export const AUTH_REFRESH_TOKEN = '/auth/refreshToken';
export const AUTH_TOKEN_URL = '/auth/samlLogin';
export const AUTH_SIGNUP_URL = '/auth/SignUp';
export const AUTH_SIGNOUT_URL = '/auth/SignOut';
export const AUTH_RESET_PASSWORD_URL = '/auth/ResetPassword';
export const AUTH_CONFIRM_RESET_PASSWORD_URL = '/auth/ConfirmResetPassword';
export const CHECK_VALID_PASSWORD_RESET_LINK = '/account-service/user/checkValidPasswordResetLink';
export const MIGRATE_ACCOUNT = '/account-service/private/user/migrateAccount';
export const UPDATE_USERNAME = '/account-service/user/recoverAccount';
export const AUTH_SAML_PROVIDERS = '/auth/samlProviders';
export const VALIDATE_GCCF_LINK = '/account-service/user/validateGccfLink/';
export const AUTH_CHANGE_PRORGAM = '/auth/changeProgram';
const INACTIVITY_PERIOD = 900; // 15min
const INACTIVITY_TIMEOUT_PERIOD = 60;
const SESSION_INACTIVITY_TIME_PROP = 'session.inactive.validity.time';
const BACKEND_PING_INTERVAL = 5 * 60; // 5min
const REFRESH_TOKEN_INTERVAL = 15 * 60; // 50min => changing it to 15 minute to handle inactive browser situation
const COOKIE_LANG_VALUE_MAPPING = {
    en: 'eng',
    fr: 'fra',
};
const COOKIE_LANG_NAME = '_gc_lang';
export class AuthService {
    constructor(http, store, router, translateService, idle, messageService, helperService, modalService, dialogRef, lookupService, cookieService, localizeService, userService) {
        this.http = http;
        this.store = store;
        this.router = router;
        this.translateService = translateService;
        this.idle = idle;
        this.messageService = messageService;
        this.helperService = helperService;
        this.modalService = modalService;
        this.dialogRef = dialogRef;
        this.lookupService = lookupService;
        this.cookieService = cookieService;
        this.localizeService = localizeService;
        this.userService = userService;
        this.idleReady = false;
        this._samlProviders$ = new BehaviorSubject(null);
        this.samlProviders$ = this._samlProviders$.asObservable();
        this._translationLoading$ = new BehaviorSubject(false);
        this.translationLoading$ = this._translationLoading$.asObservable();
        this.backendKeepAlive = new CATSKeepAlive(BACKEND_PING_INTERVAL, () => this.lookupService.getStandards().subscribe());
        this.refreshTokenScheduler = new CATSKeepAlive(REFRESH_TOKEN_INTERVAL, () => this.reAuthenticateUser());
        let sessionTimeout = INACTIVITY_PERIOD;
        const debugSessionTimeout = parseInt(sessionStorage.getItem('INACTIVITY_PERIOD'), 10);
        this.store.getProperty(SESSION_INACTIVITY_TIME_PROP)
            .subscribe(prop => {
            sessionTimeout = debugSessionTimeout || parseInt(prop.value, 10);
            if (isNaN(sessionTimeout) || sessionTimeout <= 0) {
                sessionTimeout = INACTIVITY_PERIOD;
            }
            console.log('sessionTimeout ', sessionTimeout);
            idle.setAutoResume(AutoResume.notIdle);
            idle.setIdle(sessionTimeout);
            idle.setTimeout(INACTIVITY_TIMEOUT_PERIOD);
            idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
            idle.onInterrupt.subscribe(() => {
            });
            idle.onIdleStart.subscribe(() => {
                idle.stop();
                console.log('Standards call before terminate popup ', new Date());
                this.lookupService.getStandards().subscribe();
                this.modalService.open(SessionEndComponent, {});
            });
            this.idleReady = true;
            this.resetInactivity();
            this.startCounters();
        });
        // sets the ping interval to 50 minutes
        // keepalive.interval(50 * 60); // seconds
        // keepalive.onPing.subscribe(() => this.reAuthenticateUser() );
        this.fetchSAMLProviders();
    }
    changeProgram(programId) {
        const currentProgram = this.store.user.programId;
        const payload = {
            username: this.store.user.userName,
            accessToken: this.store.getSession().accessToken,
            programId,
        };
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_CHANGE_PRORGAM}`, payload).pipe(tap(response => {
            if (response.userData) {
                response.userData.validLoginDateStr = this.store.user.validLoginDateStr;
                this.store.user = new CurrentUser(response.userData);
                this.store.accountFacilities = null;
                if (currentProgram !== programId) {
                    this.changeLang(this.currentLang, true).subscribe();
                }
            }
        }));
    }
    /**
     * This method authenticates user
     * @param {ILoginPayload} payload
     * @returns {Observable<ILoginResponse>}
     */
    authenticateUser(payload) {
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_LOGIN_URL}`, payload).pipe(tap(response => {
            if (response.sessionToken && response.message !== 'MFATokenMissing') {
                if (response.userData) {
                    this.store.user = new CurrentUser(response.userData);
                }
                const { userData, errors } = response, sessionData = tslib_1.__rest(response, ["userData", "errors"]);
                this.store.session = new SessionData(sessionData);
            }
        }));
    }
    validateToken(token) {
        return this.http.get(`${environment.apiUrl}${VALIDATE_GCCF_LINK}${token}`);
    }
    regenerateToken() {
        const payload = { programId: this.store.user.programId };
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_REFRESH_TOKEN}?quiet=1`, payload).pipe(tap(response => {
            if (response.accessToken) {
                const session = this.store.getSession();
                const accessToken = response.accessToken;
                this.store.session = Object.assign({}, session, { accessToken });
            }
        }));
    }
    authenticateUserWithToken(payload) {
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_TOKEN_URL}`, payload).pipe(tap(response => {
            const isError = response.message && response.message.indexOf('SAML') > -1;
            if (response.accessToken && !isError) {
                this.store.user = new CurrentUser(response.userData);
                const { userData, errors } = response, sessionData = tslib_1.__rest(response, ["userData", "errors"]);
                this.store.session = new SessionData(sessionData);
                this.store.setAccessKey('SAML');
            }
        }));
    }
    samlSignOut(accessToken, errorResponse, nativeSignout = true) {
        this.idle.stop();
        this._samlProviders$.subscribe(samlProviders => {
            const ep = nativeSignout ?
                this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_SIGNOUT_URL}`, null) : of({});
            ep.subscribe(data => {
                this.clearDataOnLogout(accessToken, errorResponse, samlProviders);
            });
        });
    }
    onSignOut(accessToken, errorResponse) {
        this._samlProviders$.subscribe(samlProviders => {
            this.clearDataOnLogout(accessToken, errorResponse, samlProviders);
        });
    }
    clearDataOnLogout(accessToken, errorResponse, samlProviders) {
        this.backendKeepAlive.stop();
        this.refreshTokenScheduler.stop();
        const idToken = accessToken;
        this.store.destroy();
        this.lookupService.clearCache();
        this.userService.clearCache();
        const spProvider = this.getSP(samlProviders);
        const logoutUrl = errorResponse ? this.localizeService.translateRoute(`/register/chooser-page`) : this.localizeService.translateRoute(`/welcome`);
        const href = `${spProvider.url}${spProvider.logoutUrl}${logoutUrl}&id_token_hint=${idToken}`;
        const baseURL = spProvider.logoutUrl.split('=')[1];
        this.store.setAccessKey(errorResponse);
        this.http.post(`${environment.apiUrl}${AUTH_SERVICE}/auth/saml/logout`, { idHint: idToken, redirectUrl: `${baseURL}${logoutUrl}` }).subscribe(() => {
            const logoutUrl = errorResponse ? this.helperService.getTranslatedPath(`/register/chooser-page`) : this.helperService.getTranslatedPath(`/welcome`);
            this.router.navigate(logoutUrl);
        });
        // window.location.href = href;
    }
    reAuthenticateUser() {
        console.log('reAuthenticateUser ', new Date());
        const payload = {};
        const session = this.store.getSession();
        const user = this.store.user;
        if (this.store.getAccessKey() === 'SAML') {
            this.regenerateToken().subscribe(result => {
                console.log('regenerateToken ', new Date());
            }, () => this.modalService.open(SessionEndComponent, { terminated: true }));
        }
        else {
            if (session && session.accessToken) {
                payload.refreshToken = session.accessToken;
                payload.username = session.username;
                this.authenticateUser(payload).subscribe(result => {
                    this.store.user = user;
                    this.store.getSession().username = payload.username;
                    const sess = this.store.getSession();
                    const accessToken = result.accessToken;
                    this.store.session = Object.assign({}, sess, { accessToken });
                });
            }
        }
    }
    findByAccessToken(accessToken) {
        let user = {};
        user.accessToken = accessToken;
        return this.http.post(`${environment.apiUrl}${CHECK_VALID_PASSWORD_RESET_LINK}`, user).pipe(tap(response => {
            if (response.errors) {
                this.router.navigate(this.helperService.getTranslatedPath('/register/chooser-page'));
            }
        }));
    }
    migrateAccount() {
        return this.http.get(`${environment.apiUrl}${MIGRATE_ACCOUNT}`);
    }
    signOut(reload = true, newAccessToken = null, nativeLogout = true) {
        return new Observable(subscriber => {
            const session = this.store.getSession();
            if (!session || !session.accessToken) {
                subscriber.next(true);
            }
            if (this.store.getAccessKey() === 'SAML') {
                this.samlSignOut(session.accessToken, newAccessToken, nativeLogout);
                subscriber.next(true);
            }
            else {
                this.signOutRegular(reload).subscribe(() => subscriber.next(true), () => subscriber.next(true));
            }
        });
    }
    signOutRegular(reload = true) {
        this.idle.stop();
        this.store.setAccessKey(null);
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_SIGNOUT_URL}`, null).pipe(tap(response => {
            //if (!response.errors) {
            this.backendKeepAlive.stop();
            this.refreshTokenScheduler.stop();
            this.resetSession(reload);
            // }
        }));
    }
    fetchSAMLProviders() {
        this.http.get(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_SAML_PROVIDERS}`)
            .subscribe(data => this._samlProviders$.next(data));
    }
    get SAMLProviders() {
        return this.samlProviders$
            .pipe(filter(d => d !== null));
    }
    resetSession(reload = true) {
        this.store.destroy();
        this.backendKeepAlive.stop();
        this.refreshTokenScheduler.stop();
        this.lookupService.clearCache();
        this.userService.clearCache();
        this.dialogRef.closeAll();
        this.changeLang(this.currentLang, true).subscribe(() => {
            if (reload) {
                this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
            }
        });
    }
    resetPassword(payload) {
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_RESET_PASSWORD_URL}`, payload);
    }
    confirmResetPassword(payload) {
        return this.http.post(`${environment.apiUrl}${AUTH_SERVICE}${AUTH_CONFIRM_RESET_PASSWORD_URL}`, payload);
    }
    handleErrors(errors) {
        const errorResponse = [];
        return errors.messages.map(err => {
            const errMsg = this.translateService.instant(`COMMON.messageSection.${err}`);
            errorResponse.push(errMsg);
            return errorResponse;
        });
    }
    resetInactivity(force = false) {
        const session = this.store.getSession();
        if (session && session.accessToken) {
            if (this.idleReady && (!this.idle.isRunning() || force)) {
                this.idle.watch();
            }
        }
    }
    startCounters() {
        this.refreshTokenScheduler.start();
        this.backendKeepAlive.start();
    }
    stopCounters() {
        this.backendKeepAlive.stop();
        this.refreshTokenScheduler.stop();
    }
    getUserName(email) {
        return this.http.post(`${environment.apiUrl}${UPDATE_USERNAME}`, { email });
    }
    getSAMLProvider(sAMLProviders, name) {
        return sAMLProviders && sAMLProviders.find(o => o.name === name);
    }
    getSP(sAMLProviders) {
        const name = 'SP'; //Local
        return sAMLProviders && sAMLProviders.find(o => o.name === name);
    }
    setupLangCookie(domain) {
        if (!domain) {
            return;
        }
        // domain = '.fjgc-gccf.gc.ca';
        const lang = this.currentLang;
        //  this.deleteLangCookie(domain);
        //  this.cookieService.set('test', COOKIE_LANG_VALUE_MAPPING[lang], 1, '/', '',true);
        // this.cookieService.set(COOKIE_LANG_NAME, COOKIE_LANG_VALUE_MAPPING[lang], 1, '/', domain,true);
        return COOKIE_LANG_VALUE_MAPPING[lang];
    }
    get currentLang() {
        return this.translateService.currentLang;
    }
    deleteLangCookie(domain) {
        this.cookieService.delete(COOKIE_LANG_NAME, '/', domain);
    }
    changeLang(lang, reload = false) {
        console.log('trans');
        this.lookupService.clearCache();
        if (reload) {
            return this.reloadLang(lang)
                .pipe(tap(() => this.localizeService.changeLanguage(lang)));
        }
        else {
            this.translateService.use(lang);
            this.localizeService.changeLanguage(lang);
            return of(true);
        }
    }
    reloadLang(lang) {
        return new Observable(subscriber => {
            this._translationLoading$.next(true);
            this.translateService.reloadLang(lang)
                .subscribe(() => {
                this._translationLoading$.next(false);
                subscriber.next();
                subscriber.complete();
            });
        });
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StoreService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.TranslateService), i0.ɵɵinject(i5.Idle), i0.ɵɵinject(i6.MessageService), i0.ɵɵinject(i7.HelperService), i0.ɵɵinject(i8.ModalService), i0.ɵɵinject(i9.MatDialog), i0.ɵɵinject(i10.LookupService), i0.ɵɵinject(i11.CookieService), i0.ɵɵinject(i12.LocalizeRouterService), i0.ɵɵinject(i13.UserService)); }, token: AuthService, providedIn: "root" });
