import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { last } from 'lodash';
import { LOCALES } from '../../app.const';

export const PROJECT_DETAILS_URL_REGEX = /^\/[a-z]{2}\/project\/offset\/\d*\/\d*\/.*/;
export const PROJECT_DETAILS_FR_URL_REGEX = /^\/[a-z]{2}\/projet\/offset\/\d*\/\d*\/.*/;

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private navigationHistory: string[] = [];
  private storedUrl: string;
  private skipAddToHistory = false;

  private regArray = [PROJECT_DETAILS_URL_REGEX, PROJECT_DETAILS_FR_URL_REGEX];

  constructor(
    private router: Router,
    private locRouterSvc: LocalizeRouterService,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.handleHistory(event.urlAfterRedirects);
      }
    });
  }

  private handleHistory(url: string) {
    if (this.skipURLTracking(url)) {
      return;
    }

    if (!this.skipAddToHistory) {
      if (!this.navigationHistory.length) {
        this.navigationHistory.push(url);
        this.storedUrl = null;
      } else if (this.isSameLang(url) && last(this.navigationHistory) !== url) {
        this.navigationHistory.push(url);
        this.storedUrl = null;
      } else if (!this.isSameLang(url)) {
        if (this.storedUrl && this.storedUrl !== url) {
          this.navigationHistory.push(url);
          this.storedUrl = null;
        } else {
          this.storedUrl = url;
        }
      }
    } else {
      this.navigationHistory[this.navigationHistory.length - 1] = url;
    }
    this.skipAddToHistory = false;
  }

  back(customFallbackPath = null): void {
    this.navigationHistory.pop();
    this.skipAddToHistory = true;
    if (this.navigationHistory.length > 0) {
      this.router.navigateByUrl(this.replaceLangPrefix(last(this.navigationHistory)));
    } else if (customFallbackPath) {
      this.router.navigate(customFallbackPath);
    } else {
      this.router.navigateByUrl('/');
    }
  }

  addUrl(url: string): void {
    this.navigationHistory.push(url);
  }

  removeLast(): void {
    this.navigationHistory.pop();
  }

  removeSameSectionLocation(): void {
    const historyLength = this.navigationHistory.length;

    if (historyLength >= 2) {
      const prevIdx = historyLength - 2;
      const prev = this.navigationHistory[prevIdx];
      let last = this.navigationHistory[historyLength - 1];
      last = last.split('/').slice(0, -1).join('/');

      if (last === prev) {
        this.navigationHistory.splice(prevIdx, 1);
      }
    }
  }

  isPageReloaded(): boolean{
    return (this.navigationHistory.length > 1) ? false : true;
  }

  private isSameLang(url: string): boolean {
    return last(this.navigationHistory).split('/')[1] === url.split('/')[1];
  }

  private replaceLangPrefix(path): string {
    const fragments = path.split('/');
    if (LOCALES.includes(fragments[1])) {
      fragments.splice(1, 1);
    }
    return this.locRouterSvc.translateRoute(`${fragments.join('/')}`) as string;
  }

  /**
   * Skip child tab routes that interfare with tha navigation flow of back button Eg: Project details sub tab routes.
   * @param url
   * @returns
   */
  private skipURLTracking(url) {
    return this.regArray.some(regExp => regExp.test(url)) && !history.state.reDirectToSubTab;
  }
}
