<p-dialog
  [className]="'eccc'"
  [closable]="false"
  [header]="inputData.title"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '98%'}"
  [minY]="500"
  [maximizable]="false"
  [autoZIndex]="false">

  <div class="container">
    <app-table-view
      [data]="units"
      [config]="tableConfig"
      [firstItemIndex]="firstItemIndex"
      [filterState]= "filterState"
      (paginationChanged)="onPaginationChanged($event)"
      (checkboxSelected)="onRowSelect($event)"
      [selections]="inputData?.selections"
    >
    </app-table-view>
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button type="button" class="btn btn-secondary" (click)="close()">
          {{'COMMON.actionsLabel.CLOSE' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="save()" [disabled]="disabled">
          {{'COMMON.actionsLabel.SAVE' | translate}}
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>

<ng-template #enterRemittanceQuantity let-data let-rowIndex="rowIndex">
  <input
    [class.hide-arrows]="true"
    (input)="onRemiQtyChange($event.target.value, data, rowIndex)"
    [class.has-error]="selectedUnitIds.includes(data.id)"
    class="form-control"
    type="number"
    [value]="getSelectedUnitQty(data)"
  />
  <div class="invalid-feedback d-block"
       *ngIf="data.hasError">
    <span>{{ 'COMMON.messageSection.creditQuantityIsMoreThanAvailableCredit' | translate }}</span>
  </div>
</ng-template>

<ng-template #viewColTemplate let-data>
  <span tabindex="0" class="link" role="button"
        (click)="viewUnitDetails(data)">
    {{ 'UNITS_MODULE.unitList.view' | translate}}
  </span>
</ng-template>
