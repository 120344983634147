import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { getAllParams, getNestedProp, replaceParam } from '@core/utilities/utilities.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
let DynamicFormlyTemplateType = class DynamicFormlyTemplateType extends FieldType {
    constructor(translateService, sanitizer) {
        super();
        this.translateService = translateService;
        this.sanitizer = sanitizer;
        this.innerHtml = { content: null, template: null };
    }
    ngOnInit() {
        this.parseTemplate(this.to.template);
        this.initContentSub();
    }
    getFieldValue(prop) {
        return getNestedProp(this.field.model, prop) || '';
    }
    parseTemplate(template) {
        if (template) {
            let source = template;
            if (this.to.translate) {
                source = this.translateService.instant(template);
            }
            const params = getAllParams(source);
            params.forEach(param => {
                console.log(param, '=>', this.form.get(param));
                source = replaceParam(source, param, this.getFieldValue(param));
            });
            this.innerHtml = {
                template: source,
                content: this.to.safeHtml ? this.sanitizer.bypassSecurityTrustHtml(source) : source,
            };
        }
        this.template = this.innerHtml.content;
    }
    initContentSub() {
        const updateContent = this.to.updateContent;
        if (!updateContent) {
            return;
        }
        updateContent.pipe(untilDestroyed(this)).subscribe(value => {
            this.parseTemplate(value);
        });
    }
};
DynamicFormlyTemplateType = tslib_1.__decorate([
    UntilDestroy()
], DynamicFormlyTemplateType);
export { DynamicFormlyTemplateType };
