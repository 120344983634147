import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
//Document Actions
export const DOCUMENT_DO_ACTION = '/account-service/document/do';
export const CRA_PAYMENT_APPROVE = '/obps-service/transaction/approvePayment';
export const CRA_PAYMENT_REJECT = '/obps-service/transaction/rejectPayment';
export const CRA_PAYMENT_SAVE = '/obps-service/transaction/save';
export const DOWNLOAD = '/account-service/cfrAccount/registration/download';
export class ControlActionsService {
    constructor(http) {
        this.http = http;
    }
    getFileNameFromHeaderOrDefult(response) {
        const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
        if (response.headers.get('content-disposition') !== null) {
            return regex.exec(response.headers.get('content-disposition'))[1];
        }
        return "file";
    }
    download(payload) {
        let fileName;
        this.http.get(DOWNLOAD, payload).subscribe((response) => {
            const dataType = response.body.type;
            const binaryData = [];
            const filename = fileName ? fileName : this.getFileNameFromHeaderOrDefult(response);
            binaryData.push(response.body);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            if (filename) {
                downloadLink.setAttribute('download', filename);
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
        }, () => {
            // repeat request to get JSON response
            this.http.get(DOWNLOAD, { observe: 'response' }).subscribe();
        });
    }
    documentDoAction(payload, action) {
        return this.http.post(`${environment.apiUrl}${DOCUMENT_DO_ACTION}${action}`, payload).pipe(tap(response => {
        }));
    }
    craPaymentApprove(request) {
        return this.http.post(`${environment.apiUrl}${CRA_PAYMENT_APPROVE}`, request).pipe(tap(response => {
        }));
    }
    craPaymentReject(request) {
        return this.http.post(`${environment.apiUrl}${CRA_PAYMENT_REJECT}`, request).pipe(tap(response => {
        }));
    }
    craPaymentSave(request) {
        return this.http.post(`${environment.apiUrl}${CRA_PAYMENT_SAVE}`, request).pipe(tap(response => {
        }));
    }
}
ControlActionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ControlActionsService_Factory() { return new ControlActionsService(i0.ɵɵinject(i1.HttpClient)); }, token: ControlActionsService, providedIn: "root" });
