<app-header></app-header>
<div class="content" id="main-content" tabindex="-1">
  <div class="container">
    <div class="row justify-content-center align-items-center" style="height: 80vh">
      <div class="col-sm-12 col-md-6 col-lg-4">
        <app-retrieve-username></app-retrieve-username>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
