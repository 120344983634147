import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel } from '@core/models/filter.model';
import { LookupService } from '@core/services/lookup.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account-activities-list',
  templateUrl: './account-activities-list.component.html',
  styleUrls: ['./account-activities-list.component.scss']
})
export class AccountActivitiesListComponent implements OnInit, OnDestroy {

  @Input() accountId;
  private pagination = {
    page: 0,
    size: 5,
  } as IApp.IPagination;
  firstItemIndex = 0;
  filterState: FilterModel = {};
  tableConfig: ITableViewConfig;
  activities = {
    content: []
  };
  result;
  subscription$: Subscription = new Subscription();

  @ViewChild('activityDetailTemplate', { static: true }) public activityDetailTemplate: TemplateRef<any>;

  constructor(
    private lookupService: LookupService,
    private accountManagementService: AccountManagementService,
    public translateService: TranslateService
  ) { }

  ngOnInit() {
    this.translateService.currentLang
    this.filterState.accountId = this.accountId;
    this.lookupService.getTableConfigurations('ACTIVITY_DETAILS').subscribe(
      data => {
        data.columns[2] = {
          header: 'activityDetail',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.activityDetailTemplate,
        };
        data.columns[0].header = 'reportVersion';
        data.columns.forEach(col => col.className = 'v-align');
        this.tableConfig = data;
      },
    );
  }

  private loadData(pagination: IApp.IPagination, filterState: FilterModel = {}) {
    filterState.accountId = this.accountId;
    this.subscription$.add(this.accountManagementService.getActivitiesDetailList(pagination, filterState)
      .pipe(
      ).subscribe(data => {
          this.result = data;
          this.formatData();
        }));
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadData(this.pagination, this.filterState);
  }

  formatData() {
    this.result.content.forEach(rowData => {
      rowData.accountSubTypeDtoList.forEach(subType => {
        subType.activities.forEach(activity => {
          if (activity.code === 'PRODCI' || activity.code === 'IMPLCI') {
            activity.facilities.forEach(facility => {
              facility.liquidFuels = facility.fuelDtoList && facility.fuelDtoList.filter(fuel => !fuel.fuelClass || fuel.fuelClass.code ==="LIQUID");
              facility.gasFuels = facility.fuelDtoList && facility.fuelDtoList.filter(fuel => fuel.fuelClass && fuel.fuelClass.code ==="GAS");
            })
          }
        });
      });
    });
    this.activities = this.result;
  }

  toggleColExpand(data) {
    data.expanded = !data.expanded;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

}
