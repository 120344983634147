<app-header></app-header>

<div class="content" id="main-content" tabindex="-1" *ngIf="loaded">
  <div class="container">

    <div class="row">
      <div class="col-12 mt-4 mb-3">
        <div class="card master">
          <div class="card-body">
            <div *ngIf="isNonProd" class="alert alert-info" [innerHTML]="'COMMON.custom_messages.nonProdInfo' | translate"></div>
            <h1 class="card-title">{{ 'LANDING_PAGE.catsDescription.title' | translate }}</h1>
            <p class="card-text"
               *ngIf="showCfr"
               [innerHTML]="'LANDING_PAGE.catsDescription.description' | translate"></p>
            <p class="card-text"
               *ngIf="!showCfr"
               [innerHTML]="'LANDING_PAGE.catsDescription.obpsDescription' | translate"></p>

            <div class="float-left"  *ngIf="!showCfr && !showOffset">

              <p>&nbsp;</p>
              <a *ngIf="showButtons | async" id="register" href="#" wcag-dummy-link-target class="btn btn-inverted btn-lg" [routerLink]="registrationRoute">{{ 'LANDING_PAGE.catsDescription.registerNowButton' | translate }}</a>
              <br/><br/>
              <a href="#" class="btn btn-inverted btn-lg" (click)="contactUs($event)">{{ 'LANDING_PAGE.cfsDescription.contactUsButton' | translate }}</a>
              <br/><br/>
            </div>


            <div class="float-right" *ngIf="showButtons | async">
              <p>{{ 'LANDING_PAGE.catsDescription.alreadyHaveAnAccount' | translate }}</p><a id="login" href="#" wcag-dummy-link-target class="btn btn-inverted btn-lg w-100" [routerLink]="loginRoute">{{ 'LANDING_PAGE.catsDescription.loginButton' | translate }}</a>
            </div>

          </div>
        </div>
      </div>
    </div>

   <div class="row d-lg-flex align-content-stretch" *ngIf="showCfr || showOffset">

    <div class="col-12 mb-3" *ngIf="!showCfr && showOffset">
      <div class="card h-100 card--flex">
        <div class="card-body">
          <h2 class="card-title"
              [innerHTML]="'LANDING_PAGE.obpsDescription.title' | translate">
          </h2>
          <p class="card-text"
             [innerHTML]="'LANDING_PAGE.obpsDescription.description' | translate">
          </p>
          <div class="card-footer d-flex align-items-baseline">
            <div class="flex-column">
              <a href="#" class="btn btn-secondary btm-sm mr-2 mb-2 float-left btn-size-fix" [routerLink]="aboutUsRoute">{{ 'LANDING_PAGE.obpsDescription.aboutUsButton' | translate }}</a>
              <a href="#" class="btn btn-secondary btm-sm mr-2 mb-2 float-left clear-both" (click)="contactUs($event, 'OBPS')">{{ 'LANDING_PAGE.obpsDescription.contactUsButton' | translate }}</a>
            </div>
            <div class="flex-column flex-grow-1">
              <a *ngIf="showButtons | async" id="register" href="#" wcag-dummy-link-target class="btn btn-secondary btm-sm mb-2 float-right btn-cfs white-space-normal" (click)="openRegistrationObps($event)">{{ 'LANDING_PAGE.obpsDescription.registerNowButton' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-lg-6 mb-3" *ngIf="showCfr">
      <div class="card h-100 card--flex">
        <div class="card-body">
          <h2 class="card-title"
              [innerHTML]="'LANDING_PAGE.obpsDescription.title' | translate">
          </h2>
          <p class="card-text" style="min-height:300px;"
             [innerHTML]="'LANDING_PAGE.obpsDescription.description' | translate">
          </p>
          <div class="card-footer d-flex align-items-baseline">
            <div class="flex-column">
              <a href="#" class="btn btn-secondary btm-sm mr-2 mb-2 float-left btn-size-fix" [routerLink]="aboutUsRoute">{{ 'LANDING_PAGE.obpsDescription.aboutUsButton' | translate }}</a>
              <a href="#" class="btn btn-secondary btm-sm mr-2 mb-2 float-left clear-both" (click)="contactUs($event, 'CM')">{{ 'LANDING_PAGE.obpsDescription.contactUsButton' | translate }}</a>
            </div>
            <div class="flex-column flex-grow-1">
              <a *ngIf="showButtons | async" id="register" href="#" wcag-dummy-link-target class="btn btn-secondary btm-sm mb-2 float-right btn-cfs white-space-normal" (click)="openRegistrationObps($event)">{{ 'LANDING_PAGE.obpsDescription.registerNowButton' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="col-xs-12 col-lg-6 mb-3" *ngIf="showCfr" >
        <div class="card h-100 card--flex">
          <div class="card-body">
            <h2 class="card-title"
                [innerHTML]="'LANDING_PAGE.cfsDescription.title' | translate">
            </h2>
            <p class="card-text" style="min-height: 312px;"
               [innerHTML]="'LANDING_PAGE.cfsDescription.description' | translate">
            </p>
            <div class="card-footer d-flex align-items-baseline">
              <div class="flex-column">
                <a href="#" class="btn btn-secondary btm-sm mr-2 mb-2 float-left" (click)="contactUs($event, 'CFR')">{{ 'LANDING_PAGE.cfsDescription.contactUsButton' | translate }}</a>
              </div>
              <div class="flex-column flex-grow-1">
                <a *ngIf="showButtons | async" id="register" href="#" wcag-dummy-link-target class="btn btn-secondary btm-sm mb-2 float-right btn-cfs white-space-normal" (click)="openRegistrationCfs($event)">{{ 'LANDING_PAGE.cfsDescription.registerNowButton' | translate }}</a>
              </div>
              <!--<a href="#" class="btn btn-secondary btm-sm mb-2 float-right">{{ 'LANDING_PAGE.cfsDescription.cfsMoreButton' | translate }}</a>-->
            </div>
          </div>
        </div>
      </div>
      </div>


<!--      <div class="col-xs-12 col-lg-12  mb-1">-->
<!--          <div class="card">-->
<!--            <div class="card-body">-->
<!--              <p class="card-text">-->
<!--                 <label class="mt-2 float-right">Date modified: 2019-12-14</label>-->
<!--                 <button type="button" class="btn btn-secondary float-left btm-sm">Report a problem on this page</button>-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->



  </div>
</div>

<app-footer></app-footer>
