import { HttpParams } from '@angular/common/http';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { environment } from '@env/environment';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { map, switchMap, tap } from 'rxjs/operators';
import { CREATE_REPORT_NOTIFICATION_MODAL_REPORT } from '../components/create-report-notification-modal/create-report-notification-modal.constants';
import { APP_TYPE, APPLICATION_CURRENT_STEP_KEY, APPLICATION_DATA_KEY, } from '../const/submission-management.const';
import { ONLY_REGISTRY_ADMIN } from '../../../app.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../core/store/store.service";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/services/helper.service";
import * as i5 from "../../../shared/services/modal.service";
import * as i6 from "../../project-report/service/project-report.service";
import * as i7 from "../../../shared/services/project.service";
import * as i8 from "../../../shared/services/form.service";
export const SUBMISSION_MANAGEMENT = '/account-service/submission/pageSearchByFilter';
export const CI_APPLICATION_SEARCH = '/account-service/application/search';
export const CARBON_INETNSITY_SEARCH = '/cfr-service/application/pageSearch';
export const ERP_SEARCH = '/cfr-service/application/erpPageSearch';
export const CI_APPLICATION_GET = '/account-service/application/get/';
export const SUBMISSION_BY_ID = '/account-service/submission/';
export const CI_APPLICATION_SAVE_PRODUCTION_LOW_FUELS = '/account-service/application/save';
export const CI_APPLICATION_SAVE_AND_PROCEED = ' account-service.url/application/saveAndProceed';
export const PERMISSION_PROPOSE_TRANSACTION = 'PROPOSE_TRANSACTION';
// common methods for all the applications
export const ACCOUNT_SERVICE_URL = '/account-service/';
export const COMMON_SAVE_ACTION = '/save';
export const COMMON_SUBMIT_ACTION = '/submit';
export const COMMON_PROPOSE_ACTION = '/propose';
export const COMMON_REVIEW_ACTION = '/review';
export const COMMON_GET_ACTION = '/get';
export const APPLICATION = 'application';
export const GET_REPLACE_REQUEST = 'cfrAccount/maoReplaceRequest/find/';
export const VERIFICATION_BODY = '/account-service/cfrAccount/verificationBody/pageSearch?';
export const REGISTRATION_REPORT = '/account-service/cfrAccount/cfrDetail';
export const VERIFICATION_REVIEW = '/cfr-service/verification/review';
export const VERIFICATION_UPDATE_REVIEW = '/cfr-service/verification/updateReview';
export const TEAM_LEAD_AND_INDEPENDENT_REVIEWER = '/cfr-service/verification/assignTeamLeadAndIndependentReviewer';
export const CHANGE_TEAM_LEAD_AND_INDEPENDENT_REVIEWER = '/cfr-service/verification/changeTeamLeadAndIndependentReviewer';
export const ASSIGN_TEAM_MEMBER = '/cfr-service/verification/assignTeamMembers';
export const EDIT_TEAM_MEMBER = '/cfr-service/verification/editTeamMembers';
export const GET_ACCESS_PROVIDED_ENTITIES_BY_VERIFICATION_ID = '/cfr-service/verification/get/';
export const GET_ENTITIES_BY_VERIFICATION_ID = '/cfr-service/verification/pageVbEntity?sort=entityId&dir=ASC';
export const PROCESS_ENTITY_ACCESS = '/cfr-service/verification/vbProcessEntityAccess';
export const DOWNLOAD_APPLICATION_PDF = '/cfr-service/application/download/report';
export const GET_CI_REQUEST_CHANGE_DATA_API = '/account-service/lookup/getCarbonIntensityStatusChangeRequest/';
export const LOOKUP_APPLICATION_FACILITIES = 'account-service/lookup/getFacilityListForApplication';
const STEPS_TRANSLATION_PREFIX = 'SUBMISSION_MANAGEMENT_MODULE.wizard.';
export class SubmissionManagementService extends BaseStepperService {
    constructor(http, storeService, router, helperService, modalService, helperSvc, projectReportSvc, projectService, formService) {
        super();
        this.http = http;
        this.storeService = storeService;
        this.router = router;
        this.helperService = helperService;
        this.modalService = modalService;
        this.helperSvc = helperSvc;
        this.projectReportSvc = projectReportSvc;
        this.projectService = projectService;
        this.formService = formService;
        this._stepModelCache = {};
        this._applicationApprovalModel = new BehaviorSubject({});
        this.applicationApprovalModel$ = this._applicationApprovalModel.asObservable();
        this.translationPrefix = STEPS_TRANSLATION_PREFIX;
        this.selectedIndependentReviewer = {};
        this.selectedTeamLead = {};
        this.selectedVB = {};
        this.stepperStepUpdate$ = new Subject();
        this.hideSaveAsDraft = false;
        this._refreshData$ = new Subject();
        this.refreshData$ = this._refreshData$.asObservable();
    }
    get applicationApprovalModel() {
        return this.currentData;
    }
    set applicationApprovalModel(data) {
        this.currentData = data;
    }
    resetModel() {
        this._applicationApprovalModel.next({});
    }
    // get scrollEl() {
    //   return this.scrollContainerDir;
    // }
    //
    // set scrollEl(el) {
    //   this.scrollContainerDir = el;
    // }
    saveAsDraft() {
        return this.http.post(`${environment.apiUrl}${CI_APPLICATION_SAVE_PRODUCTION_LOW_FUELS}`, this.applicationApprovalModel.data);
    }
    saveAndProceed() {
        return this.http.post(`${environment.apiUrl}${CI_APPLICATION_SAVE_AND_PROCEED}`, this.applicationApprovalModel);
    }
    getReportList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${SUBMISSION_MANAGEMENT}`, filterState, { params });
    }
    getCarbonIntensityById(id) {
        let params = new HttpParams();
        params = this.addPagination(params, {});
        return this.http.post(`${environment.apiUrl}${CARBON_INETNSITY_SEARCH}`, { id }, { params });
    }
    getApplicationById(id, type = APP_TYPE.CI) {
        let params = new HttpParams();
        params = this.addPagination(params, {});
        const uri = type === APP_TYPE.CI ? CARBON_INETNSITY_SEARCH : ERP_SEARCH;
        return this.http.post(`${environment.apiUrl}${uri}`, { id }, { params });
    }
    getCarbonIntensity(payload = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, {});
        return this.http.post(`${environment.apiUrl}${CARBON_INETNSITY_SEARCH}`, payload, { params });
    }
    getCIApplication(payload) {
        return this.http.post(`${environment.apiUrl}${CI_APPLICATION_SEARCH}`, payload)
            .pipe(tap(response => this.registrationReportData = response), tap(response => this.registrationReportsDetail = response ? response.account : null));
    }
    getApplication(id) {
        return this.http.get(`${environment.apiUrl}${CI_APPLICATION_GET}${id}`);
    }
    getApplicationBySubmissionReportId(id) {
        return this.http.get(`${environment.apiUrl}${SUBMISSION_BY_ID}APPLICATION${id}`);
    }
    getRegistrationReportDetail(id = null, accountId) {
        const payload = {
            id,
            accountId,
            discriminatorType: 'REGISTRATION_ACCOUNT',
        };
        return this.http.post(`${environment.apiUrl}${REGISTRATION_REPORT}`, payload).
            pipe(tap(response => this.registrationReportsDetail = response));
    }
    getApplicationByType(type, id) {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${APPLICATION}${COMMON_GET_ACTION}/${id}`);
    }
    saveApplication(draft, payload, quiet = false) {
        const type = draft ? '' : 'AndProceed';
        payload.fullJson = null;
        if (parseFloat(this.stepNum) !== parseFloat(payload.step) && parseFloat(payload.step) > this.lastSavedStep) {
            payload.step = this.lastSavedStep.toString();
        }
        if (!this.isDirty && !draft) {
            return of({ entity: this.currentData })
                .pipe(tap(() => this.adminCanUpdate.next(false)));
        }
        else {
            return this.http.post(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${APPLICATION}${COMMON_SAVE_ACTION}${type}${quiet ? '?quiet=1' : ''}`, payload)
                .pipe(tap(() => this.adminCanUpdate.next(false)), tap(data => data.entity && data.entity.step && (this.lastSavedStep = data.entity.step)));
        }
    }
    proposeApplication(payload) {
        payload.fullJson = null;
        return this.http.post(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${APPLICATION}${COMMON_PROPOSE_ACTION}`, payload);
    }
    submitApplication(type, payload, form) {
        payload.fullJson = null;
        const isTransactionProposer = this.storeService.user.checkIfTransactionProposer(payload.accountId);
        const message = isTransactionProposer ? 'proposeApplicationConfirmation' : 'confirmationMessageApplication';
        let userType = payload.type === 'CI_PATHWAY' ? 'ecccTeam' : 'validationBody';
        let appType = payload.type;
        if (payload.ciMethod === 'DEFAULT') {
            userType = 'verificationBody';
        }
        if (payload.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS'
            || payload.type === 'CI_FUELING_STATIONS'
            || payload.type === 'CI_CHARGING_STATIONS') {
            appType = 'CI_APPLICATION';
        }
        this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.submit`),
                this.translateService.instant(`COMMON.submissionTypeList.${appType}`),
                payload.legalName,
                this.translateService.instant(`COMMON.actionsLabel.${userType}`),
            ],
            type,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                this.submit(payload, isTransactionProposer)
                    .subscribe(resp => {
                    this.returnPreviousLocation();
                }, (error) => {
                    if (form) {
                        this.formService.parseErrors(form, error.error);
                    }
                });
            }
        });
    }
    returnPreviousLocation() {
        const prevPage = this.storeService.lastVisitedUrl || this.storeService.getHistoryUrl();
        if (prevPage) {
            this.router.navigate(this.helperService.getTranslatedPath(prevPage));
        }
        else {
            this.storeService.back();
        }
    }
    submit(payload, isTransactionProposer) {
        if (isTransactionProposer) {
            return this.proposeApplication(payload);
        }
        return this.http.post(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${APPLICATION}${COMMON_SUBMIT_ACTION}`, payload);
    }
    doAction(uri, payload) {
        payload.fullJson = null;
        return this.http.post(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${APPLICATION}${uri}`, payload);
    }
    getReplaceRequest(id) {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${GET_REPLACE_REQUEST}${id}`);
    }
    getCiRequestChangeData(id) {
        return this.http.get(`${environment.apiUrl}${GET_CI_REQUEST_CHANGE_DATA_API}${id}`);
    }
    getVerificationBodyList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${VERIFICATION_BODY}`, filterState, { params });
    }
    saveVerificationReview(payload) {
        return this.http.post(`${environment.apiUrl}${VERIFICATION_REVIEW}`, payload);
    }
    saveTeamLeadAndIndependentReviewer(payload) {
        if (this.inputData.showReasonForm) {
            return this.http.post(`${environment.apiUrl}${CHANGE_TEAM_LEAD_AND_INDEPENDENT_REVIEWER}`, payload);
        }
        return this.http.post(`${environment.apiUrl}${TEAM_LEAD_AND_INDEPENDENT_REVIEWER}`, payload);
    }
    saveTeamMembers(payload, status) {
        if (status === 'PENDING_VERIFICATION_TEAM_SELECTION') {
            return this.http.post(`${environment.apiUrl}${ASSIGN_TEAM_MEMBER}`, payload);
        }
        else {
            return this.http.post(`${environment.apiUrl}${EDIT_TEAM_MEMBER}`, payload);
        }
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.inputData, this.currentData));
        sessionStorage.setItem(APPLICATION_DATA_KEY, data);
        const currentStep = JSON.stringify(this.currentStep);
        sessionStorage.setItem(APPLICATION_CURRENT_STEP_KEY, currentStep);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(APPLICATION_DATA_KEY);
        if (data) {
            !skipRemove && sessionStorage.removeItem(APPLICATION_DATA_KEY);
            let restoredData = JSON.parse(data);
            return this.getCIApplication({
                accountId: restoredData.accountId,
                type: restoredData.type,
            }).pipe(tap(appData => {
                restoredData = Object.assign({}, restoredData, { fuelDtoList: appData.fuelDtoList });
            }), switchMap(() => of(restoredData)));
        }
        else {
            return of(null);
        }
    }
    restoreLastStep() {
        const currentStep = sessionStorage.getItem(APPLICATION_CURRENT_STEP_KEY);
        if (currentStep) {
            sessionStorage.removeItem(APPLICATION_CURRENT_STEP_KEY);
            return JSON.parse(currentStep);
        }
        else {
            return null;
        }
    }
    getSubmissionsList$(pagination, filterState = {}, uri) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${uri}`, filterState, { params });
    }
    triggerBackendAction(payload, back, mapData = null) {
        const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
        switch (actionLabel) {
            case 'DISCARD':
                this._requestConfirmation(payload, mapData, 'discard');
                break;
            case 'VIEW_HISTORY':
                this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/submissions-history/${payload.id}`));
                break;
            case 'VIEW_PROJECT_REPORT':
                this.router.navigate(this.helperService.getTranslatedPath(`/project-report/PROJECT_REPORT/${payload.accountId}/${payload.projectId}/${payload.notErrOmiProjectReportId}`));
                break;
            case 'APPROVE':
            case 'REJECT':
            case 'APPROVE_SECOND':
            case 'REJECT_SECOND':
            case 'SUBMIT':
            case 'EDIT_CUST':
            case 'NEED_REVIEW':
            case 'NEED_MORE_INFO':
            case 'RESUBMIT':
                this._redirectToReport(payload);
                break;
            case 'VIEW_PROJECT':
                this.redirectToProjects(payload);
                break;
            default:
                console.log('Unhandled action', actionLabel);
                this._redirectToReport(payload);
                break;
        }
    }
    _requestConfirmation(payload, mapData, uri = 'review') {
        const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
        const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
        const actionPayload = {
            id: payload.id,
            action: actionLabel,
            reportTypeCode: payload.reportType,
            type: payload.submissionType.toUpperCase()
        };
        this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message: confirmationMessage,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                payload.accountName,
            ],
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed().subscribe((result) => {
            if (result) {
                this.modalService.closeAllMessageDialogs();
                this.projectReportSvc.reportWorkflowAction(actionPayload, uri).subscribe(() => {
                    this._refreshData$.next(true);
                });
            }
        });
    }
    _redirectToReport(payload) {
        const { accountId, projectId, id, submissionType } = payload;
        const reportType = this.updatedReportTypeForGHG(payload.status, payload.reportType);
        const path = submissionType.toUpperCase() === CREATE_REPORT_NOTIFICATION_MODAL_REPORT ? 'project-report' : 'project-notification';
        const route = ['', path, reportType, accountId, projectId, id].join('/');
        this.router.navigate(this.helperSvc.getTranslatedPath(route));
        return;
    }
    updatedReportTypeForGHG(status, reprotType) {
        if (reprotType && reprotType === 'NOT_GHG_REVERSAL' && ['SUSPECTED_UNDER_REVIEW_2', 'SUSPECTED_APPROVED', 'SUSPECTED_UNDER_REVIEW'].includes(status)) {
            return 'NOT_SUSPECTED_GHG_REVERSAL';
        }
        return reprotType;
    }
    get isAdmin() {
        return this.storeService.user.hasPermission(ONLY_REGISTRY_ADMIN);
    }
    redirectToProjects(payload) {
        if (payload.projectType === 'SUB') {
            this.projectService.redirectToSubProject(payload.masterProjectId, payload.projectId);
        }
        else {
            this.projectService.redirectToProjectScreen(null, payload.projectId, payload);
        }
        return;
    }
    getDownloadApplicationUrl(id) {
        return `${environment.serviceUrl}${DOWNLOAD_APPLICATION_PDF}/${id}`;
    }
    getApplicationFacilities(accountId) {
        return this.http.get(`${environment.apiUrl}/${LOOKUP_APPLICATION_FACILITIES}/${accountId}`);
    }
    getAccessProvidedEntitiesByVerificationId(pagination, id) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.get(`${environment.apiUrl}${GET_ACCESS_PROVIDED_ENTITIES_BY_VERIFICATION_ID}${id}`, { params })
            .pipe(map(response => {
            response.content = response.vbAccessProvidedEntities;
            return response;
        }));
    }
    getEntitiesByVerificationId(pagination, id) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${GET_ENTITIES_BY_VERIFICATION_ID}`, { verificationId: id }, { params });
    }
    processEntityAccess(payload) {
        return this.http.post(`${environment.apiUrl}${PROCESS_ENTITY_ACCESS}`, payload);
    }
    showError(message) {
        const messages = {
            messages: [{ message }],
            type: "ERROR" /* ERROR */,
        };
        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe();
    }
}
SubmissionManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SubmissionManagementService_Factory() { return new SubmissionManagementService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StoreService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.ModalService), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i6.ProjectReportService), i0.ɵɵinject(i7.ProjectService), i0.ɵɵinject(i8.FormService)); }, token: SubmissionManagementService, providedIn: "root" });
