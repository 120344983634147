<div class="pt-3 pr-4 pl-4 pb-5 activity-panel">
  <!-- <h2>Activity</h2> -->
  <div class="pl-2 mt-3 border-bottom d-flex justify-content-between">
    <span><h3>{{'PROJECTS_MODULE.ProjectBuilder.projectType' | translate}}</h3></span>
    <span *ngIf="editMode && inProcess"
          (click)="showActivityList()"
          (keypress)="showActivityList()"
          tabindex="0"
          role="button"
          class="link">
      {{'PROJECTS_MODULE.ProjectBuilder.changeType' | translate}}
    </span>
  </div>
  <div *ngIf="editMode; else listView">
    <app-project-activity-form></app-project-activity-form>
  </div>
</div>

<ng-template #listView>
  <div class="pl-2">
    <app-project-activity-list></app-project-activity-list>
  </div>
</ng-template>
