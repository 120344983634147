import { ComponentType } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatDialogConfig } from '@angular/material/dialog';
import { MODAL_TYPES } from '@core/constants/modals.const';

@Injectable()
export class ModalService {

  constructor(
    private dialog: MatDialog,
  ) {}

  public open(
    component: ComponentType<any>,
    data: any,
    disableClose = false,
    extraConfig: MatDialogConfig = {},
  ) {
    const closeOnNavigation = false;
    return this.dialog.open(component, { data, disableClose, closeOnNavigation, ...extraConfig, id: this.getDialogId() });
  }

  public closeAllMessageDialogs() {
    const serviceMessageDialog = this.dialog.getDialogById(MODAL_TYPES.SERVICE_MESSAGE);
    if (serviceMessageDialog !== undefined) {
       serviceMessageDialog.close();
    }
  }

  public closeServiceMessageDialogs() {
    this.openDialogs.forEach(dialog => {
      if (dialog.id.includes(MODAL_TYPES.SERVICE_MESSAGE)) {
        dialog.close();
      }
    });
  }

  public get openDialogs(): any {
    return this.dialog.openDialogs;
  }

  public toggleModalByOccurrence(id: number, hide = true) {
    const dialog = this.openDialogs[id];
    if (dialog) {
      if (hide) {
        dialog['_overlayRef']['_pane'].classList.add('hidden');
      } else {
        dialog['_overlayRef']['_pane'].classList.remove('hidden');
      }
    }
  }

  private get randomId() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }

  private getDialogId() {
    return `${MODAL_TYPES.SERVICE_MESSAGE}-${this.randomId}`;
  }

  public isTCDialogIsNotOpen() {
    return !(this.dialog.getDialogById(MODAL_TYPES.TERMS_AND_CONDITIONS) !== undefined);
  }

}
