<a href="#"
class="arrow-back"
wcag-dummy-link-target
role="button"
aria-label="Back"
(click)="back($event)"
(keypress)="back($event)">
<fa-icon class="float-left back-icon"  [icon]="['fas', 'chevron-left']"></fa-icon>
<span style="display: none">back</span>
</a>
