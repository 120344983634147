/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/primeng/fieldset/primeng-fieldset.ngfactory";
import * as i4 from "primeng/fieldset";
import * as i5 from "@angular/common";
import * as i6 from "./address-view.component";
var styles_AddressViewComponent = [i0.styles];
var RenderType_AddressViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressViewComponent, data: {} });
export { RenderType_AddressViewComponent as RenderType_AddressViewComponent };
function View_AddressViewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("COMMON.countryList." + _co.address.country))); _ck(_v, 1, 0, currVal_0); }); }
function View_AddressViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "p-fieldset", [], null, null, null, i3.View_Fieldset_0, i3.RenderType_Fieldset)), i1.ɵdid(1, 49152, null, 0, i4.Fieldset, [i1.ElementRef], { legend: [0, "legend"] }, null), (_l()(), i1.ɵeld(2, 0, null, 1, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressViewComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.legend; _ck(_v, 1, 0, currVal_0); var currVal_4 = _co.address.country; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.streetNumberAndName; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.cityAndProvince; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.poBoxAndPostalCode; _ck(_v, 8, 0, currVal_3); }); }
export function View_AddressViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressViewComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.address; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AddressViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-address-view", [], null, null, null, View_AddressViewComponent_0, RenderType_AddressViewComponent)), i1.ɵdid(1, 638976, null, 0, i6.AddressViewComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressViewComponentNgFactory = i1.ɵccf("app-address-view", i6.AddressViewComponent, View_AddressViewComponent_Host_0, { legend: "legend", address: "address" }, {}, []);
export { AddressViewComponentNgFactory as AddressViewComponentNgFactory };
