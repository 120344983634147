import { Observable } from 'rxjs/internal/Observable';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class FormService {
    constructor(translateService) {
        this.translateService = translateService;
    }
    parseErrors(form, error) {
        if (error.fieldMessages) {
            error.fieldMessages.forEach(fieldError => {
                const fieldControl = form.get(fieldError.field);
                if (fieldControl) {
                    fieldControl.markAsDirty();
                    fieldControl.setErrors({ 'server-side': fieldError.message });
                }
            });
        }
    }
    parseErrorsDirect(form, error) {
        if (error.fieldMessages) {
            error.fieldMessages.forEach(fieldError => {
                const fieldControl = form.get(fieldError.field);
                if (fieldControl) {
                    fieldControl.markAsDirty();
                    fieldControl.setErrors({ 'direct': fieldError.message });
                }
            });
        }
    }
    scrollToFirstInvalidControl(form = null, containerEl) {
        const el = form;
        if (!el) {
            return;
        }
        this.getFirstInvalidControl(el).subscribe(firstInvalidControl => {
            containerEl.scroll({
                top: this.getTopOffset(firstInvalidControl, containerEl),
                left: 0,
                behavior: 'smooth',
            });
            fromEvent(containerEl, 'scroll')
                .pipe(debounceTime(100), take(1))
                .subscribe(() => firstInvalidControl.focus());
        });
    }
    getTopOffset(controlEl, containerEl = null) {
        const labelOffset = 50;
        const controlElTop = controlEl.getBoundingClientRect().top;
        if (containerEl) {
            const containerTop = containerEl.getBoundingClientRect().top;
            const absoluteControlElTop = controlElTop + containerEl.scrollTop;
            return absoluteControlElTop - containerTop - labelOffset;
        }
        else {
            const absoluteControlElTop = controlElTop + window.scrollY;
            return absoluteControlElTop - labelOffset;
        }
    }
    getFirstInvalidControl(parentEl) {
        const elList = parentEl.nativeElement.querySelectorAll('.ng-invalid:not(form)');
        let _found = false;
        return new Observable(subscriber => {
            for (const el of elList) {
                if (!_found) {
                    if (el.offsetParent) {
                        _found = true;
                        subscriber.next(el);
                        subscriber.complete();
                    }
                }
            }
        });
    }
}
FormService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormService_Factory() { return new FormService(i0.ɵɵinject(i1.TranslateService)); }, token: FormService, providedIn: "root" });
