import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { unitManagementTableConfig } from '@module/unit-management/unit-management.const';
import { UnitDetailsComponent } from '@module/project-report/components/unit-details/unit-details.component';
import { filterUniqueById } from '@module/project-report/routines/common.routine';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { ENVIRONMENT_INTEGRITY_ACCOUNT } from '@module/environmental-integrity-account/components/environmental-integrity-account/constant/environmental-integrity-account.const';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
export class SelectUnitsComponent {
    constructor(inputData, dialogRef, modalService, unitManagementService, lookupService) {
        this.inputData = inputData;
        this.dialogRef = dialogRef;
        this.modalService = modalService;
        this.unitManagementService = unitManagementService;
        this.lookupService = lookupService;
        this.tableConfig = unitManagementTableConfig;
        this.selectedUnits = [];
        this.updatedUnits = [];
        this.selectedUnitIds = [];
        this.visible = true;
        this.disabled = true;
        this.pagination = {
            page: 0,
            size: 20,
        };
        this.firstItemIndex = 0;
        this.filterState = {
            facilityNameList: [this.inputData.accountId],
            unitStatusList: this.inputData.unitStatusList,
            unitIdNotIn: this.inputData.unitIdNotIn,
            projectIdList: this.inputData.projectIdList,
        };
    }
    ngOnInit() {
        this._tableConstructor();
        this._loadData(this.pagination, this.filterState);
    }
    _loadData(pagination, filterState = {}) {
        const isEIA = this.inputData['isEIA'] ? this.inputData['isEIA'] : false;
        this.inputData['unitStatusList'] && (this.filterState['unitStatusList'] = this.inputData['unitStatusList']);
        if (this.inputData['projectIdList']) {
            this.filterState['projectIdList'] = this.inputData['projectIdList'];
        }
        if (this.inputData['transitionTypeList']) {
            this.filterState['transitionTypeList'] = this.inputData['transitionTypeList'];
        }
        if (isEIA) {
            delete filterState['unitIdNotIn'];
            delete filterState['projectIdList'];
            delete filterState['facilityNameList'];
            delete filterState['transitionTypeList'];
        }
        if (this.inputData['isGhg']) {
            delete filterState['unitIdNotIn'];
        }
        const uri = isEIA ? ENVIRONMENT_INTEGRITY_ACCOUNT.tableListApiUri : '';
        this.unitManagementService
            .getAll(pagination, filterState, uri)
            .subscribe(data => {
            this.units = data;
            this.disabled = !this.haveSelectedUnits();
        });
    }
    haveSelectedUnits() {
        const selectedUnitWithQty = this.inputData['selectedUnitWithQty'] || [];
        const availableUnits = this.units.content;
        let selectedUnitsAvailable = false; // will be used to enable or disable save button
        selectedUnitWithQty.forEach(selectedUnit => availableUnits.forEach(availableUnit => {
            if (availableUnit.id === selectedUnit.unitId) {
                this.onRemiQtyChange(selectedUnit.qty, availableUnit); // updating the updatedUnits array for already selected units
                selectedUnitsAvailable = true;
            }
        }));
        return selectedUnitsAvailable;
    }
    _tableConstructor() {
        this.lookupService.getTableConfigurations('UNIT').subscribe(data => {
            const { columns } = data, config = tslib_1.__rest(data, ["columns"]);
            const _columns = [
                {
                    checkboxSelect: true,
                    width: '3em',
                },
                ...data.columns,
                {
                    header: 'enterRemiQty',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.enterRemittanceQuantity,
                },
                {
                    header: 'details',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.viewColTemplate,
                },
            ];
            this.tableConfig = Object.assign({}, config, { columns: _columns });
        });
    }
    getSelectedUnitQty(data) {
        const selectedUnitWithQty = this.inputData['selectedUnitWithQty'] || [];
        const unit = selectedUnitWithQty.find(unit => unit.unitId === data.id);
        return unit ? unit.qty : null;
    }
    onRemiQtyChange(value, data, rowIndex) {
        setTimeout(() => {
            const idx = this.updatedUnits.findIndex(item => item.id === data.id);
            if (idx === -1) {
                const unit = Object.assign({}, data);
                unit['quantity'] = parseInt(value, 10);
                unit.account = null;
                unit.project = null;
                unit.projectType = null;
                this.updatedUnits.push(unit);
            }
            else {
                if (!value) {
                    this.updatedUnits.splice(idx, 1);
                }
                else {
                    this.updatedUnits[idx]['quantity'] = parseInt(value, 10);
                }
            }
            this.inputData.selections = [...this.updatedUnits];
            this.disabled = false;
            if (this.updatedUnits) { // disabling the save button if none units were selected
                this.updatedUnits = this.updatedUnits.filter(unit => !isNaN(unit.quantity));
                this.disabled = (this.updatedUnits.length === 0) ? true : false;
            }
        });
    }
    viewUnitDetails(unit) {
        this.modalService
            .open(UnitDetailsComponent, {
            title: 'UNITS_MODULE.creditTransferForm.unitsLabel',
            unit,
        });
    }
    openModal(message, metaData = [], type = "ERROR" /* ERROR */) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message,
                    metaData,
                },
            ],
            type,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    onRowSelect(data) {
        this.selectedUnits = data;
        this.disabled = false;
    }
    onPaginationChanged(pagination) {
        if ((this.pagination.page === pagination.page) && !pagination.sort && !pagination.dir)
            return; // added to avoid duplicate calls when a popup opens
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        this._loadData(this.pagination, this.filterState);
    }
    areSelectedUnitsQtyUnchanged() {
        let selectedUnitWithQty = this.inputData['selectedUnitWithQty'] || [];
        if (selectedUnitWithQty.length === this.updatedUnits.length) {
            const areSelectedUnitsQtyUnchanged = this.updatedUnits.every(updatedUnit => selectedUnitWithQty.some(selectedUnit => updatedUnit.id === selectedUnit.unitId && updatedUnit.quantity === selectedUnit.qty));
            return areSelectedUnitsQtyUnchanged;
        }
        return false;
    }
    save() {
        this.selectedUnitIds = filterUniqueById(this.selectedUnits, this.updatedUnits)
            .map(data => data.id);
        if (!this.updatedUnits || !this.updatedUnits.length) {
            this.close();
            return;
        }
        if (this.areSelectedUnitsQtyUnchanged()) { // there is not change in selected units
            this.close();
            return;
        }
        let exceedingMaximumQtyErrorFlag = false;
        let userEnteredNegativeValue = false;
        this.updatedUnits.forEach(updatedUnit => {
            const unit = this.units.content.find(u => u.id === updatedUnit.id);
            if (unit && (updatedUnit.quantity > unit.quantity)) {
                exceedingMaximumQtyErrorFlag = true;
            }
            if (unit && (updatedUnit.quantity < 0)) {
                userEnteredNegativeValue = true;
            }
        });
        if (userEnteredNegativeValue) {
            const sub$ = this.openModal('valueCannotBeLessThanZero').subscribe(() => sub$.unsubscribe());
            return;
        }
        if (exceedingMaximumQtyErrorFlag) {
            const sub$ = this.openModal('quantityExceedsMaxErrorMessage').subscribe(() => sub$.unsubscribe());
            return;
        }
        if (this.inputData['isGhg']) {
            this.updatedUnits = this.updatedUnits.map((unit) => {
                // not required in payload
                delete unit.creditCreationReportDetailDto;
                delete unit.creditActionDto;
                delete unit.project;
                delete unit['submission'];
                return unit;
            });
            this.unitManagementService.updateUnitData({ creditBlockList: this.updatedUnits })
                .subscribe((result) => {
                this.visible = false;
                let alteredResult = result.map((unit) => {
                    (unit.creditCreationReportDetailDto) && delete unit.creditCreationReportDetailDto;
                    (unit.submission) && delete unit.submission;
                    return unit;
                });
                this.dialogRef.close(alteredResult);
            }, () => this.close());
        }
        else {
            this.unitManagementService.updateUnitData({ creditBlockList: this.updatedUnits })
                .subscribe((result) => {
                this.visible = false;
                this.dialogRef.close(result);
            }, () => this.close());
        }
    }
    close() {
        this.visible = false;
        this.dialogRef.close();
    }
}
