import { TemplateRef } from '@angular/core';
import { IFilterModel } from './table-filter.models';
import { IApp } from '@core/models/app.interfaces';
export const ROWS_PER_PAGE = 20;
export const AUTO_WIDTH_FOR_COLUMNS = 'auto';

export const PAGINATION = { page: 0, size: ROWS_PER_PAGE } as IApp.IPagination;

export interface ITableViewConfig {
  id?:any;
  tableViewName?:any;
  dataKey?:any;
  uri?: string;
  translationPrefix?: string;
  paginator?: boolean;
  rowsPerPage?: number;
  extraHeader?: [ITableExtraHeader[]];
  filters?: IFilterModel[];
  className?: string;
  editService?: string;
  editTitle?: string;
  validateService?: string;
  updatePage?: string;
  expandSectionType?:EXPAND_COLLAPSE_TYPE;
  width?: string | number;
  columns: ITableViewColumn[];
  fileName?:string;
  expandSectionTemplate?: TemplateRef<any>;
  autoWidth?: boolean;
  singleSelect?: boolean;
  clazz?:any;
  entityClazz?:AnalyserNode;
  hasTotalRecord?: boolean;
  hideRemoveAction?: boolean;
  hasViewColumn?: boolean;
  hideActionsColumn?: boolean;
  selectDisabled?: boolean;
}

export interface ITableViewColumn {
  expandIcon?: boolean;
  checkboxSelect?: boolean;
  checkboxDisabled?: boolean;
  expandCollapse?:boolean;
  expandSectionDataRoot?:any;
  expandSectionData?:any;
  expandSectionTableHeader?:any;
  expandSectionAllRecords?: boolean;
  required?:boolean;
  field?: string;
  header?: string;
  width?: string;
  sortField?: string;
  id?:string;
  maxWidth?: string;
  templateRef?: any;
  translate?: boolean;
  translationPrefix?: string;
  dateTime?: boolean;
  editable?: boolean;
  key?:string;
  className?: string;
  toolTip?: { [key: string]: any };
  defaultValue?: any;
  fieldType?:string;
  inputType?:any;
  invalidMessage?: string;
  editRowField?:boolean;
  newRowField?:boolean;
  source?: string;
  placeholder?: string;
  items?: any;
  bindLabel?: string;
  bindValue?: string;
  dataProvided?: boolean;
  defaultDropDownValue?: string;
  formField?:string;
  numeric?: boolean;
  onChange?: IColumnOnChangeProp;
  arg0?:string;
  isHTML?:boolean;
  hideExpression?:any;
  columnOrder?:any;
  format?:any;
  link?:any;
  fileName?:any;
  nestedField?:any;
  createNewRow?:any;
  columnGroup?:any;
  repeateColumn?:any;
  rowNumber?:any;
  colspan?:any;
  permissionName?:any;
  readonly?: boolean;
  toolTipText?: string;
  icon?: any;
  ignoreTruncate?: boolean;
  customTemplateOptions?: any;
  multiple?: boolean;
  validators?: any;
  inlineTooltip?: boolean
}

export interface ITableExtraHeader {
  header?: string;
  colspan?: number;
  rowspan?: number;
  toolTip?: { [key: string]: any };
}

export enum EXPAND_COLLAPSE_TYPE {
  SIMPLE_TABLE
}

export interface IColumnOnChangeProp {
  callback?: string;
  clearField?: string[];
  additionalField?: any[];
}

export interface ITableFilterParams {
  [key: string]: any;
}
