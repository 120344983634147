import { ActionOptionType, IActionOptionsModel, ITableFilterActionModel } from '@shared/models/table-filter.models';
const unitActionTranslationPrefix = 'UNITS_MODULE.actionList.';
const CREDIT_TRANSFER = '/credit-transfer';
const CREDIT_REMITTANCE = '/credit-remittance';
export const CREDIT_CANCELLATION = '/credit-cancellation';
const CREDIT_RETIREMENT = '/credit-retirement';
const ISSUE_CREDIT = '/issue-credit';
const CREDIT_SUSPENSION = '/credit-suspension';
const CREDIT_REVOKE = '/credit-revocation';
const CREDIT_ACTIVE = '/credit-active';
const CREDIT_ON_HOLD = '/credit-on-hold';

export const transferAction: IActionOptionsModel = {
  id: 'creditTransfers',
  label: unitActionTranslationPrefix + 'creditTransfers',
  type: ActionOptionType.SERVICE,
  uri: CREDIT_TRANSFER,
};

export const revocationAction: IActionOptionsModel = {
  id: 'creditRevocation',
  label: unitActionTranslationPrefix + 'creditRevocation',
  type: ActionOptionType.SERVICE,
  uri: '/credit-revocation',
};

export const issueSurplusCreditsAction: IActionOptionsModel = {
  id: 'creditIssuance',
  label: unitActionTranslationPrefix + 'creditIssuance',
  type: ActionOptionType.SERVICE,
  uri: ISSUE_CREDIT,
};

export const sendNoticeOfInsufficientCredit: IActionOptionsModel = {
  id: 'sendNoticeOfInsufficientCredit',
  label: unitActionTranslationPrefix + 'sendNoticeOfInsufficientCredit',
  type: ActionOptionType.SERVICE,
  uri: '/notice-of-insufficient-credits',
};

export const listCreditsAction: IActionOptionsModel = {
  id: 'creditListing',
  label: unitActionTranslationPrefix + 'listCreditOnBulletinBoard',
  type: ActionOptionType.SERVICE,
  uri: '/list-credit',
};

export const unlistCreditsAction: IActionOptionsModel = {
  id: 'creditUnListing',
  label: unitActionTranslationPrefix + 'unlistCredit',
  type: ActionOptionType.APPROVE,
  uri: '/list-credit',
};

export const retirementAction: IActionOptionsModel = {
  id: 'creditRetirement',
  label: unitActionTranslationPrefix + 'creditRetirement',
  type: ActionOptionType.SERVICE,
  uri: CREDIT_RETIREMENT,
};

export const suspensionAction: IActionOptionsModel = {
  id: 'creditSuspension',
  label: unitActionTranslationPrefix + 'creditSuspension',
  type: ActionOptionType.SERVICE,
  uri: '/credit-suspension',
};

export const cancellationAction: IActionOptionsModel = {
  id: 'voluntaryCancellation',
  label: unitActionTranslationPrefix + 'voluntaryCancellation',
  type: ActionOptionType.SERVICE,
  uri: CREDIT_CANCELLATION,
};

export const remittanceAction: IActionOptionsModel = {
  id: 'credit-remittance',
  label: unitActionTranslationPrefix + 'surplusCreditRemittance',
  type: ActionOptionType.SERVICE,
  uri: CREDIT_REMITTANCE,
};

export const ruRemittanceAction: IActionOptionsModel = {
  id: 'recognizedUnitRemittance',
  label: unitActionTranslationPrefix + 'recognizedUnitRemittance',
  type: ActionOptionType.SERVICE,
  uri: '/ru-credit-remittance',
};

export const unSuspendAction: IActionOptionsModel = {
  id: 'unSuspend',
  label: unitActionTranslationPrefix + 'unSuspendCredit',
  type: ActionOptionType.SERVICE,
  uri: '/doCreditDeSuspension',
};

export const statusUriMap: any = {
  SCR_CREDIT_LIST: '/list-credit',
  SCR_REMITTED : CREDIT_REMITTANCE,
  SCR_PROPOSED_REMITTANCE: CREDIT_REMITTANCE,
  SCR_REMIT_PENDING_SECOND_APPROVER: CREDIT_REMITTANCE,
  SCR_REMIT_REJECT_SECOND: CREDIT_REMITTANCE,
  SCR_PENDING_ISSUANCE_ADMIN : ISSUE_CREDIT,
  SCR_PENDING_ISSUANCE_ADMIN1 : ISSUE_CREDIT,
  SCR_REJECTED : ISSUE_CREDIT,
  SCR_PENDING_REMIT_REVIEW_ADMIN: CREDIT_REMITTANCE,
  SCR_PENDING_REMIT_REVIEW_ADMIN2: CREDIT_REMITTANCE,
  RU_RETIRED : '/ru-credit-remittance',
  SCR_SUSPENDED : CREDIT_SUSPENSION,
  RU_REMITTED: '/ru-credit-remittance',
  SCR_RETIRED: CREDIT_RETIREMENT,
  SCR_PENDING_RETIREMENT_REVIEW_ADMIN: CREDIT_RETIREMENT,
  SCR_VOLUNTARILY_CANCELLED: CREDIT_CANCELLATION,
  SCR_PROPOSED_CANCELLATION: CREDIT_CANCELLATION,
  SCR_PENDING_CANCELLATION_REVIEW_ADMIN: CREDIT_CANCELLATION,
  SCR_PENDING_CANCELLATION_REVIEW_ADMIN2: CREDIT_CANCELLATION,
  SCR_CANCELLATION_PENDING_SECOND_APPROVER: CREDIT_CANCELLATION,
  SCR_CANCELLATION_REJECT_SECOND: CREDIT_CANCELLATION,
  SCR_REVOKED : CREDIT_REVOKE,
  SCR_PENDING_REVOKE_REVIEW_ADMIN : CREDIT_REVOKE,
  SCR_PENDING_REVOKE_REVIEW_ADMIN2 : CREDIT_REVOKE,
  SCR_PENDING_TRANSFER_ADMIN: CREDIT_TRANSFER,
  SCR_PENDING_TRANSFER_ADMIN2: CREDIT_TRANSFER,
  SCR_PENDING_TRANSFER_BUYER: CREDIT_TRANSFER,
  SCR_PROPOSED_TRANSFER: CREDIT_TRANSFER,
  SCR_TRANSFER_PENDING_SECOND_APPROVER: CREDIT_TRANSFER,
  SCR_TRANSFER_REJECT_SECOND: CREDIT_TRANSFER,
  SCR_PENDING_REMIT_REVIEW_REFUND_RETIRED : CREDIT_RETIREMENT,
  SCR_PENDING_REMIT_REVIEW_REFUND_REMITTED : CREDIT_REMITTANCE,
  SCR_REFUND_REQUESTED_RETIRED : CREDIT_RETIREMENT,
  SCR_REFUND_REQUESTED_REMITTED : CREDIT_REMITTANCE,
  SCR_PENDING_REMIT_REVIEW_REFUND : CREDIT_REMITTANCE,
  SURPLUS_CREDITS_REFUND_REQUESTED_REMITTED: '/refund/credit-remittance',
  SURPLUS_CREDITS_REFUND_REQUESTED_RETIRED : '/refund/credit-retirement',
  SURPLUS_CREDITS_REFUND_REQUESTED_REMIT_PENDING_SECOND_APPROVER : CREDIT_REMITTANCE,
  SURPLUS_CREDITS_PENDING_REMIT_REVIEW_REFUND_REMIT_PENDING_SECOND_APPROVER : CREDIT_REMITTANCE,
  SURPLUS_CREDITS_PENDING_REMIT_REVIEW_REFUND_REMITTED : CREDIT_REMITTANCE,
  SURPLUS_CREDITS_PENDING_REMIT_REVIEW_REFUND_RETIRED : CREDIT_RETIREMENT,
  SURPLUS_CREDITS_PENDING_REMIT_REVIEW_REFUND1_RETIRED: CREDIT_RETIREMENT,
  SCR_PENDING_REMIT_REVIEW_REFUND1_RETIRED: CREDIT_RETIREMENT,
  SURPLUS_CREDITS_PENDING_REMIT_REVIEW_REFUND1_REMIT_REJECT_SECOND: CREDIT_REMITTANCE,
  SCR_REFUND_REQUESTED_REMIT_PENDING_SECOND_APPROVER : CREDIT_REMITTANCE,
  SCR_PENDING_REMIT_REVIEW_REFUND_REMIT_PENDING_SECOND_APPROVER: CREDIT_REMITTANCE,
  SCR_PENDING_REMIT_REVIEW_REFUND1_REMIT_REJECT_SECOND: CREDIT_REMITTANCE,

  OCR_ACTIVE : CREDIT_ACTIVE,
  SCR_ACTIVE : CREDIT_ACTIVE,
  OCR_PENDING_PROJECT_TRANSFER: CREDIT_ACTIVE,
  OCR_PENDING_ISSUANCE_ADMIN : ISSUE_CREDIT,
  OCR_PENDING_ISSUANCE_ADMIN1 : ISSUE_CREDIT,
  OCR_VOLUNTARILY_CANCELLED: CREDIT_CANCELLATION,
  OCR_PROPOSED_CANCELLATION: CREDIT_CANCELLATION,
  OCR_PENDING_CANCELLATION_REVIEW_ADMIN: CREDIT_CANCELLATION,
  OCR_PENDING_CANCELLATION_REVIEW_ADMIN2: CREDIT_CANCELLATION,
  OCR_CANCELLATION_PENDING_SECOND_APPROVER: CREDIT_CANCELLATION,
  OCR_CANCELLATION_REJECT_SECOND: CREDIT_CANCELLATION,
  OCR_REJECTED : ISSUE_CREDIT,
  OCR_PENDING_TRANSFER_ADMIN: CREDIT_TRANSFER,
  OCR_PENDING_TRANSFER_ADMIN2: CREDIT_TRANSFER,
  OCR_PENDING_TRANSFER_BUYER: CREDIT_TRANSFER,
  OCR_PROPOSED_TRANSFER: CREDIT_TRANSFER,
  OCR_TRANSFER_PENDING_SECOND_APPROVER: CREDIT_TRANSFER,
  OCR_TRANSFER_REJECT_SECOND: CREDIT_TRANSFER,
  OCR_SUSPENDED : CREDIT_SUSPENSION,
  OCR_REVOKED : CREDIT_REVOKE,
  OCR_PENDING_REVOKE_REVIEW_ADMIN : CREDIT_REVOKE,
  OCR_PENDING_REVOKE_REVIEW_ADMIN2 : CREDIT_REVOKE,
  OCR_REMITTED : CREDIT_REMITTANCE,
  OCR_PENDING_SUSPENSION_NOTIFICATION_OF_GHG_REVERSAL: CREDIT_SUSPENSION,
  OCR_PENDING_SUSPENSION_NOTIFICATION_OF_SUSPECTED_GHG_REVERSAL: CREDIT_SUSPENSION,
  OCR_CREDIT_GHG_REVERSAL_PENDING_UNSUSPENDED: CREDIT_SUSPENSION,
  OCR_GHG_CREDIT_CANCELLED: CREDIT_CANCELLATION,
  OCR_PENDING_REVOCATION_GHG_REVERSAL: CREDIT_REVOKE,

  //CFR
  CFR_ACTIVE : CREDIT_ACTIVE,
  CFR_PLEDGED : CREDIT_ACTIVE,
  CFR_PENDING_PROJECT_TRANSFER: CREDIT_ACTIVE,
  CFR_CCM_ON_HOLD: CREDIT_ACTIVE,
  CFR_PENDING_ISSUANCE_ADMIN : ISSUE_CREDIT,
  CFR_PENDING_ISSUANCE_ADMIN1 : ISSUE_CREDIT,
  CFR_VOLUNTARILY_CANCELLED: CREDIT_CANCELLATION,
  CFR_PROPOSED_CANCELLATION: CREDIT_CANCELLATION,
  CFR_PENDING_CANCELLATION_REVIEW_ADMIN: CREDIT_CANCELLATION,
  CFR_PENDING_CANCELLATION_REVIEW_ADMIN2: CREDIT_CANCELLATION,
  CFR_CANCELLATION_PENDING_SECOND_APPROVER: CREDIT_CANCELLATION,
  CFR_CANCELLATION_REJECT_SECOND: CREDIT_CANCELLATION,
  CFR_PENDING_REGISTRATION_CANCELLATION: CREDIT_CANCELLATION,
  CFR_PENDING_CANCELLATION_ERFP: CREDIT_CANCELLATION,
  CFR_CANCELLED_USED_FOR_REDUCTION: CREDIT_CANCELLATION,
  CFR_TO_BE_CANCELLED: CREDIT_CANCELLATION,
  CFR_REJECTED : ISSUE_CREDIT,
  CFR_PENDING_TRANSFER_ADMIN: CREDIT_TRANSFER,
  CFR_PENDING_TRANSFER_ADMIN2: CREDIT_TRANSFER,
  CFR_PENDING_TRANSFER_BUYER: CREDIT_TRANSFER,
  CFR_PROPOSED_TRANSFER: CREDIT_TRANSFER,
  CFR_TRANSFER_PENDING_SECOND_APPROVER: CREDIT_TRANSFER,
  CFR_TRANSFER_REJECT_SECOND: CREDIT_TRANSFER,
  CFR_SUSPENDED : CREDIT_SUSPENSION,
  CFR_TEMPORARY_ON_HOLD: CREDIT_ON_HOLD,
  CFR_UNIT_ON_HOLD: CREDIT_ON_HOLD,
};

export const PERMISSION_DISCLOSE_COMMENTS = 'CREDIT_DISCLOSE_REMARKS';
