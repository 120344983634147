import { Injectable } from '@angular/core';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FilterModel, UploadOBPSResultsFilterModel } from '@core/models/filter.model';
import { Observable } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';

export const OBPS_SERVICE  = '/obps-service';
export const CREDITS_SEARCH_FACILITY  = '/credits/searchFacility';
export const TRANSACTION_GET_CRA_PAYMENT_RESULTS= '/transaction/getCraPaymentResults';
export const APPROVE_RESULT_LIST = '/credits/approveResultList';
export const REJECT_RESULT_LIST = '/credits/rejectResultList';
export const TRANSACTION_MANUAL_REFUND = '/transaction/manualRefund';
export const SAVE_PAYMENT = '/transaction/save';
export const VALIDATE_PAYMENT= '/transaction/validateManualReviewRecord';
export const SET_ISSUANCE_DATE = '/unit/activate/scheduler/startScheduler';
export const FIND_RESULT_IMPACT  = '/credits/findResultImpact/';
export const UPLOAD_OBPS_RESULTS_TABLE = '/obps-service/fileUpload/obpsResultsUploadSearchFilter'
export const REJECT_OBPS_RESULT_UPLOAD = '/obps-service/fileUpload/rejectObpsResultsUpload';
export const APPROVE_OBPS_RESULTS_UPLOAD = '/obps-service/fileUpload/approveObpsResultsUpload';
export const RESUBMIT_OPBPS_RESULTS = '/obps-service/fileUpload/reSubmitObpsResultsUpload';
export const OBPS_RESULTS_VIEW = '/obps-service/fileUpload/viewObpsResultsUpload';
export const GET_SCHEDULED_TIME = '/obps-service/unit/activate/scheduler/getScheduledTime';
@Injectable({
  providedIn: 'root'
})
export class ProgramManagementService extends AbstractPaginationService {

  constructor(private http: HttpClient) { 
    super();
  }
  
  getScheduledTime() {
    
    return this.http.get(`${ environment.apiUrl }${ GET_SCHEDULED_TIME }`, {responseType: 'text'});
  }

  getCreditsSearchFacility(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<IApp.IPageResponseFacilityResultVersion> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IPageResponseFacilityResultVersion>(`${ environment.apiUrl }${ OBPS_SERVICE }${ CREDITS_SEARCH_FACILITY }?pageSearch`, filterState, { params });
  }

  getCraPaymentResults(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<IApp.IPageResponsePaymentInformation> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IPageResponsePaymentInformation>(`${ environment.apiUrl }${ OBPS_SERVICE }${ TRANSACTION_GET_CRA_PAYMENT_RESULTS }?pageSearch`, filterState, { params });
  }

  approveResultList(request : number[]){
    return this.http.post<any[]>(`${ environment.apiUrl }${ OBPS_SERVICE }${ APPROVE_RESULT_LIST }`,request);
  }

  rejectResultList(request : number[]){
    return this.http.post<any[]>(`${ environment.apiUrl }${ OBPS_SERVICE }${ REJECT_RESULT_LIST }`,request);
  }

  transactionManualRefund(request : any){//any until object is defined needs to be updated once service api is concluded
    return this.http.post<any>(`${ environment.apiUrl }${ OBPS_SERVICE }${ TRANSACTION_MANUAL_REFUND }`,request);
  }

  savePayment(request: any){//any until object is defined needs to be updated once service api is concluded
    return this.http.post<any>(`${ environment.apiUrl }${ OBPS_SERVICE }${ SAVE_PAYMENT }`, request);
  }

  setIssuanceDate(request: any){
    let params = new HttpParams().set('startScheduler', request);
    return this.http.get<any>(`${ environment.apiUrl }${ OBPS_SERVICE }${ SET_ISSUANCE_DATE }`, { params: params });
  }

  findResultImpact(id: number): Observable<IApp.IResultDetail> {
     return this.http.get<IApp.IResultDetail>(`${ environment.apiUrl }${ OBPS_SERVICE }${ FIND_RESULT_IMPACT }${id}`);
   //  return this.http.get<IApp.IResultDetail>(`http://localhost:8088/api${ OBPS_SERVICE }${ FIND_RESULT_IMPACT }${id}`);
  }

  getUploadOBPSResultTable(pagination: IApp.IPagination, filterState: UploadOBPSResultsFilterModel = {}): Observable<IApp.IUploadOBPSResults> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IUploadOBPSResults>(`${ environment.apiUrl }${ UPLOAD_OBPS_RESULTS_TABLE }`, filterState, { params });
  }

  rejectObpsResultsUpload(id){
    return this.http.post<any>(`${ environment.apiUrl }${ REJECT_OBPS_RESULT_UPLOAD }?id=`+id, {});
  }

  approveObpsResultsUpload(id){
    return this.http.post<any>(`${ environment.apiUrl }${ APPROVE_OBPS_RESULTS_UPLOAD }?id=`+id, {});
  }

  reSubmitObpsResultsUpload(id){
    return this.http.post<any>(`${ environment.apiUrl }${ RESUBMIT_OPBPS_RESULTS }?id=`+id, {});
  }

  getViewObpsResultsUpload(id){
    let params = new HttpParams().set('id', id);
    return this.http.post<any>(`${ environment.apiUrl }${ OBPS_RESULTS_VIEW }?id=`+id, {});  
  }

}
