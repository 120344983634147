import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TermsConditionsService } from '@core/services/terms-conditions.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-read-only-privacy',
  templateUrl: './read-only-privacy.component.html',
  styleUrls: ['./read-only-privacy.component.scss']
})
export class ReadOnlyPrivacyComponent implements OnInit {
  public visible = true;
  public content: string;

  private programIdMap = {
    'obps': 1,
    'cfr': 2,
    'offset': 3
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ReadOnlyPrivacyComponent>,
    private termsConditionsService: TermsConditionsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.getContent();
  }

  close() {
    this.dialogRef.close();
  }

  getContent() {
    this.termsConditionsService.getRegistrationPrivacyPolicy(this.programIdMap[this.data.type]).subscribe(data => {
      if (data) {
        this.content = this.translateService.currentLang === 'en' ? data.contentEn : data.contentFr;
      } else {
        this.content = '';
      }
    })
  }

}
