import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

const authorizationKey = 'Authorization';
const authorizationbackupKey = 'authorizationbackup';
const languageKey = 'Language';
const skipAuthTokenUrls = [
  '/api/registry-server/property/get?key=enable.verification.flow'
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
      private store: StoreService,
      private translate: TranslateService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};
    const session = this.store && this.store.getSession();

    const apiCall = request.url.indexOf('/api') > -1 || request.url.indexOf('/service') > -1;
    if (session && session.accessToken && apiCall && !skipAuthTokenUrls.includes(request.url)) {
      headers[authorizationKey] = `Bearer ${ session.accessToken }`;
      headers[authorizationbackupKey] = `Bearer ${ session.accessToken }`;
    }

    if (apiCall && this.translate.currentLang !== undefined) {
      headers[languageKey] = this.translate.currentLang;
    }
    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }

}
