<p-dialog
  [className]="'eccc'"
  [closable]="false"
  [header]="inputData.title | translate"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="500"
  [maximizable]="false"
  [autoZIndex]="false">

  <div class="container">
    <form [formGroup]="form">
      <formly-form [model]="inputData.unit" [fields]="formFields" [options]="options" [form]="form"></formly-form>
    </form>
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button type="button" class="btn btn-secondary" (click)="close()">
          {{'COMMON.actionsLabel.CLOSE' | translate}}
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
