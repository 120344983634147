import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { ILoginPayload, ILoginResponse, IResetPasswordPayload, IStatus, SessionData } from '@core/models/auth.model';
import { CurrentUser } from '@core/models/user.model';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SessionEndComponent } from '@shared/components/session-end/session-end.component';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, interval, Observable , of} from 'rxjs';
import { filter, share, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { LookupService } from '@core/services/lookup.service';
import { CookieService } from 'ngx-cookie-service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { UserService } from '@shared/services/user.service';
import { Subject } from 'rxjs/internal/Subject';
import { CATSKeepAlive } from '@core/utilities/cats-keep-alive';

export const AUTH_SERVICE = '/auth-server';
// export const AUTH_SERVICE = 'http://localhost:8081/api/eccc-auth-server';
export const AUTH_LOGIN_URL = '/auth/uilogin';
export const AUTH_REFRESH_TOKEN = '/auth/refreshToken';
export const AUTH_TOKEN_URL = '/auth/samlLogin';
export const AUTH_SIGNUP_URL = '/auth/SignUp';
export const AUTH_SIGNOUT_URL = '/auth/SignOut';
export const AUTH_RESET_PASSWORD_URL = '/auth/ResetPassword';
export const AUTH_CONFIRM_RESET_PASSWORD_URL = '/auth/ConfirmResetPassword';
export const CHECK_VALID_PASSWORD_RESET_LINK = '/account-service/user/checkValidPasswordResetLink';
export const MIGRATE_ACCOUNT = '/account-service/private/user/migrateAccount';
export const UPDATE_USERNAME = '/account-service/user/recoverAccount';
export const AUTH_SAML_PROVIDERS = '/auth/samlProviders';
export const VALIDATE_GCCF_LINK = '/account-service/user/validateGccfLink/';
export const AUTH_CHANGE_PRORGAM = '/auth/changeProgram';

const INACTIVITY_PERIOD = 900; // 15min
const INACTIVITY_TIMEOUT_PERIOD = 60;
const SESSION_INACTIVITY_TIME_PROP = 'session.inactive.validity.time';
const BACKEND_PING_INTERVAL = 5 * 60; // 5min
const REFRESH_TOKEN_INTERVAL = 15 * 60; // 50min => changing it to 15 minute to handle inactive browser situation

const COOKIE_LANG_VALUE_MAPPING = {
  en: 'eng',
  fr: 'fra',
};

const COOKIE_LANG_NAME = '_gc_lang';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private idleReady = false;

  private readonly _samlProviders$ = new BehaviorSubject<IApp.IAuthSAMLProvider[]>(null);
  private samlProviders$ = this._samlProviders$.asObservable();
  private backendKeepAlive;
  private refreshTokenScheduler;

  private _translationLoading$ = new BehaviorSubject<boolean>(false);
  public translationLoading$: Observable<boolean> = this._translationLoading$.asObservable();

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private router: Router,
    private translateService: TranslateService,
    private idle: Idle,
    private messageService: MessageService,
    private helperService: HelperService,
    private modalService: ModalService,
    private dialogRef: MatDialog,
    private lookupService: LookupService,
    private cookieService: CookieService,
    private localizeService: LocalizeRouterService,
    private userService: UserService,
  ) {

    this.backendKeepAlive = new CATSKeepAlive(
      BACKEND_PING_INTERVAL,
      () => this.lookupService.getStandards().subscribe(),
    );

    this.refreshTokenScheduler = new CATSKeepAlive(
      REFRESH_TOKEN_INTERVAL,
      () => this.reAuthenticateUser(),
    );

    let sessionTimeout = INACTIVITY_PERIOD;
    const debugSessionTimeout = parseInt(sessionStorage.getItem('INACTIVITY_PERIOD'), 10);
    this.store.getProperty(SESSION_INACTIVITY_TIME_PROP)
      .subscribe(
        prop => {
          sessionTimeout = debugSessionTimeout || parseInt(prop.value, 10);
          if (isNaN(sessionTimeout) || sessionTimeout <= 0) {
            sessionTimeout = INACTIVITY_PERIOD;
          }
          console.log('sessionTimeout ', sessionTimeout);
          idle.setAutoResume(AutoResume.notIdle);
          idle.setIdle(sessionTimeout);
          idle.setTimeout(INACTIVITY_TIMEOUT_PERIOD);
          idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

          idle.onInterrupt.subscribe(() => {

          });
          idle.onIdleStart.subscribe(
            () => {
              idle.stop();
              console.log('Standards call before terminate popup ', new Date());
              this.lookupService.getStandards().subscribe();
              this.modalService.open(SessionEndComponent, {});
            },
          );

          this.idleReady = true;
          this.resetInactivity();
          this.startCounters();

        },
      );

    // sets the ping interval to 50 minutes
    // keepalive.interval(50 * 60); // seconds
    // keepalive.onPing.subscribe(() => this.reAuthenticateUser() );
    this.fetchSAMLProviders();
  }


  changeProgram(programId: number) {
    const currentProgram = this.store.user.programId;
    const payload = {
      username : this.store.user.userName,
      accessToken :  this.store.getSession().accessToken,
      programId,
    }
    return this.http.post<ILoginResponse>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_CHANGE_PRORGAM }`, payload).pipe(
      tap(response => {
        if (response.userData) {
          response.userData.validLoginDateStr = this.store.user.validLoginDateStr;
          this.store.user = new CurrentUser(response.userData);
          this.store.accountFacilities = null;
          if(currentProgram !== programId){
            this.changeLang(this.currentLang, true).subscribe();
          }
        }
      }),
    );
   }

  /**
   * This method authenticates user
   * @param {ILoginPayload} payload
   * @returns {Observable<ILoginResponse>}
   */
  authenticateUser(payload: ILoginPayload): Observable<ILoginResponse> {
    return this.http.post<ILoginResponse>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_LOGIN_URL }`, payload).pipe(
      tap(response => {
        if (response.sessionToken &&  response.message !== 'MFATokenMissing') {
          if (response.userData) {
            this.store.user = new CurrentUser(response.userData);
          }
          const { userData, errors, ...sessionData } = response;
          this.store.session = new SessionData(sessionData);
        }
      }),
    );
  }

  validateToken(token: string){
    return this.http.get<any>(`${ environment.apiUrl }${ VALIDATE_GCCF_LINK }${token}`);
  }

  regenerateToken(): Observable<ILoginResponse> {
    const payload = {programId : this.store.user.programId};
    return this.http.post<ILoginResponse>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_REFRESH_TOKEN }?quiet=1`, payload).pipe(
      tap(response => {
        if (response.accessToken) {
          const session = this.store.getSession();
          const accessToken = response.accessToken;
          this.store.session = {...session, accessToken};
        }
      }),
    );
  }

  authenticateUserWithToken(payload: ILoginPayload): Observable<ILoginResponse> {
     return this.http.post<ILoginResponse>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_TOKEN_URL }`, payload).pipe(
      tap(response => {
        const isError = response.message && response.message.indexOf('SAML') > -1;
        if (response.accessToken && !isError ) {
          this.store.user = new CurrentUser(response.userData);
          const { userData, errors, ...sessionData } = response;
          this.store.session = new SessionData(sessionData);
          this.store.setAccessKey('SAML');
         }
      }),
    );
  }

  samlSignOut(accessToken, errorResponse: string, nativeSignout =true) {
    this.idle.stop();
    this._samlProviders$.subscribe(samlProviders => {
      const ep = nativeSignout ?
      this.http.post<any>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_SIGNOUT_URL }`, null): <Observable<any>>of({});
      ep.subscribe(data =>{
        this.clearDataOnLogout(accessToken, errorResponse, samlProviders);
      });
    });
 }

 onSignOut(accessToken, errorResponse) {
  this._samlProviders$.subscribe(samlProviders => {
      this.clearDataOnLogout(accessToken, errorResponse, samlProviders);
  })
 }

 clearDataOnLogout(accessToken, errorResponse, samlProviders) {
    this.backendKeepAlive.stop();
    this.refreshTokenScheduler.stop();
    const idToken = accessToken;
    this.store.destroy();
    this.lookupService.clearCache();
    this.userService.clearCache();
    const spProvider = this.getSP(samlProviders);
    const logoutUrl = errorResponse ? this.localizeService.translateRoute(`/register/chooser-page`) : this.localizeService.translateRoute(`/welcome`);
    const href =`${spProvider.url}${spProvider.logoutUrl}${logoutUrl}&id_token_hint=${idToken}`;
    const baseURL = spProvider.logoutUrl.split('=')[1];
    this.store.setAccessKey(errorResponse);
    this.http.post(`${ environment.apiUrl }${ AUTH_SERVICE }/auth/saml/logout`, {idHint: idToken, redirectUrl: `${baseURL}${logoutUrl}`}).subscribe(() => {
      const logoutUrl = errorResponse ? this.helperService.getTranslatedPath(`/register/chooser-page`) : this.helperService.getTranslatedPath(`/welcome`);
      this.router.navigate(logoutUrl);
    });
    // window.location.href = href;
 }



  reAuthenticateUser(): void {
    console.log('reAuthenticateUser ', new Date());
    const payload: ILoginPayload = {};
    const session = this.store.getSession();
    const user = this.store.user;
    if (this.store.getAccessKey() === 'SAML') {
      this.regenerateToken().subscribe(
        result => {
          console.log('regenerateToken ', new Date());
        },
        () => this.modalService.open(SessionEndComponent, {terminated: true}));
    } else {
      if (session && session.accessToken) {
        payload.refreshToken = session.accessToken;
        payload.username = session.username;
        this.authenticateUser(payload).subscribe(
          result => {
            this.store.user = user;
            this.store.getSession().username = payload.username;
            const sess = this.store.getSession();
            const accessToken = result.accessToken;
            this.store.session = {...sess, accessToken};
          });
      }
    }
  }

  findByAccessToken(accessToken: string): Observable<any> {
    let user: IApp.ISaveUser = {};
    user.accessToken = accessToken;
    return this.http.post<any>(`${ environment.apiUrl }${ CHECK_VALID_PASSWORD_RESET_LINK }`, user).pipe(
      tap(response => {
        if (response.errors) {
          this.router.navigate(this.helperService.getTranslatedPath('/register/chooser-page'));
        }
      }),
    );
  }

  migrateAccount(): Observable<any>  {
    return this.http.get<any>(`${ environment.apiUrl }${ MIGRATE_ACCOUNT }`);
  }

  signOut(reload = true, newAccessToken=null, nativeLogout = true ): Observable<any> {
    return new Observable<any>(subscriber => {
      const session = this.store.getSession();
      if (!session || !session.accessToken) {
        subscriber.next(true);
      }
      if (this.store.getAccessKey() === 'SAML') {
        this.samlSignOut(session.accessToken, newAccessToken, nativeLogout);
        subscriber.next(true);
      } else {
        this.signOutRegular(reload).subscribe(
          () => subscriber.next(true),
          () => subscriber.next(true),
        );
      }
    });
  }

  signOutRegular(reload = true): Observable<any> {
    this.idle.stop();
    this.store.setAccessKey(null);
    return this.http.post<any>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_SIGNOUT_URL }`, null).pipe(
      tap(response => {
        //if (!response.errors) {
        this.backendKeepAlive.stop();
        this.refreshTokenScheduler.stop();
        this.resetSession(reload);
       // }
      }),
    );
  }

  fetchSAMLProviders() {
    this.http.get<IApp.IAuthSAMLProvider[]>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_SAML_PROVIDERS }`)
      .subscribe(
        data => this._samlProviders$.next(data),
      );
  }

  get SAMLProviders(): Observable<IApp.IAuthSAMLProvider[]> {
    return this.samlProviders$
      .pipe(
        filter(d => d !== null),
      );
  }

  resetSession(reload = true) {
    this.store.destroy();
    this.backendKeepAlive.stop();
    this.refreshTokenScheduler.stop();
    this.lookupService.clearCache();
    this.userService.clearCache();
    this.dialogRef.closeAll();
    this.changeLang(this.currentLang, true).subscribe(() => {
      if (reload) {
        this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
      }
    });
  }

  resetPassword(payload: IResetPasswordPayload): Observable<IStatus> {
    return this.http.post<IStatus>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_RESET_PASSWORD_URL }`, payload);
  }

  confirmResetPassword(payload: IResetPasswordPayload): Observable<IStatus> {
    return this.http.post<IStatus>(`${ environment.apiUrl }${ AUTH_SERVICE }${ AUTH_CONFIRM_RESET_PASSWORD_URL }`, payload);
  }

  handleErrors(errors: IApp.IError): any[] {
    const errorResponse = [];
    return errors.messages.map(err => {
      const errMsg = this.translateService.instant(`COMMON.messageSection.${ err }`);
      errorResponse.push(errMsg);
      return errorResponse;
    });
  }

  resetInactivity(force = false) {
    const session = this.store.getSession();
    if (session && session.accessToken) {
      if (this.idleReady && (!this.idle.isRunning() || force)) {
        this.idle.watch();
      }
    }
  }

  public startCounters() {
    this.refreshTokenScheduler.start();
    this.backendKeepAlive.start();
  }

  public stopCounters() {
    this.backendKeepAlive.stop();
    this.refreshTokenScheduler.stop();
  }

  getUserName(email: string) {
    return this.http.post<IApp.IAccountPage>(`${ environment.apiUrl }${UPDATE_USERNAME}` , {email});
  }

  getSAMLProvider(sAMLProviders : IApp.IAuthSAMLProvider[], name: string) {
    return sAMLProviders && sAMLProviders.find(o => o.name === name);
  }

  getSP(sAMLProviders : IApp.IAuthSAMLProvider[]) {
    const name = 'SP'; //Local
    return sAMLProviders && sAMLProviders.find(o => o.name === name);
  }

  setupLangCookie(domain: string) {
    if (!domain) {
      return ;
    }
   // domain = '.fjgc-gccf.gc.ca';
    const lang = this.currentLang;
  //  this.deleteLangCookie(domain);
  //  this.cookieService.set('test', COOKIE_LANG_VALUE_MAPPING[lang], 1, '/', '',true);
   // this.cookieService.set(COOKIE_LANG_NAME, COOKIE_LANG_VALUE_MAPPING[lang], 1, '/', domain,true);
    return COOKIE_LANG_VALUE_MAPPING[lang];
  }

  get currentLang(): string {
    return this.translateService.currentLang;
  }

  deleteLangCookie(domain) {
    this.cookieService.delete(COOKIE_LANG_NAME, '/', domain);
  }

  changeLang(lang: string, reload = false): Observable<any> {
    console.log('trans');
    this.lookupService.clearCache();
    if (reload) {
      return this.reloadLang(lang)
        .pipe(tap(() => this.localizeService.changeLanguage(lang)));
    } else {
      this.translateService.use(lang);
      this.localizeService.changeLanguage(lang);
      return of(true);
    }
  }

  reloadLang(lang: string) {
    return new Observable(subscriber => {
      this._translationLoading$.next(true);
      this.translateService.reloadLang(lang)
        .subscribe(() => {
          this._translationLoading$.next(false);
          subscriber.next();
          subscriber.complete();
        });
    });
  }
}
