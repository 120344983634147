import { FieldArrayType } from "@ngx-formly/core";
export class AddFacilityAccordionTypeComponent extends FieldArrayType {
    constructor() {
        super(...arguments);
        this.openPanel = false;
    }
    onPanelToggle(event) {
        this.openPanel = !this.openPanel;
    }
}
