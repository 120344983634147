import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Component({
  selector: 'app-unit-details',
  templateUrl: './unit-details.component.html',
})
export class UnitDetailsComponent implements OnInit {
  public visible = true;
  public form = new FormGroup({});
  public options: FormlyFormOptions = {};
  public formFields: FormlyFieldConfig[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<UnitDetailsComponent>,) {
  }

  ngOnInit() {
    this.formFields = [
      {
        fieldGroup: [
          {
            fieldGroupClassName: 'row with-checkbox',
            fieldGroup: [
              {
                className: "col-md-6 col-xl-6",
                type: "readonly-input",
                key: 'facilityName',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.accountNameLabel',
                },
              },

              {
                className: 'col-md-6 col-xl-4',
                type: 'readonly-input',
                key: 'unitClassCode',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.unitTypeLabel',
                },
              },
              {
                className: 'col-md-4 col-xl-2',
                type: 'readonly-input',
                key: 'quantity',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.unitQtyLabel',
                },
              },
              {
                className: "col-md-8 col-xl-12",
                type: "readonly-input",
                key: 'protocol',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.protocol',
                },
              },
              {
                className: 'col-md-12 col-xl-12',
                type: 'readonly-input',
                key: 'serialNumber',
                templateOptions: {
                  label: 'UNITS_MODULE.creditTransferForm.serialNoLabel',
                },
              },
              {
                className: 'col-md-6 col-xl-4',
                type: 'readonly-input',
                key: 'issuanceDateStr',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.issuanceDate',
                },
              },
              {
                className: "col-md-6 col-xl-4",
                type: "readonly-input",
                key: 'projectType',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.projectType',
                },
              },
              {
                className: 'col-md-6 col-xl-4',
                type: 'readonly-input',
                key: 'complianceYear',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.vintageYear',
                },
              },
              {
                className: 'col-md-6 col-xl-4',
                type: 'readonly-input',
                wrappers: ['form-field'],
                key: 'jurisdiction',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.jurisdiction'
                },
              },
              {
                className: "col-md-6 col-xl-4",
                type: "readonly-input",
                key: 'projectId',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.projectID',
                },
              },
              {
                className: "col-md-6 col-xl-4",
                type: "readonly-input",
                key: 'projectName',
                templateOptions: {
                  label: 'UNITS_MODULE.creditForm.projectNameLabel',
                },
              },
            ],
          },
        ],
      },
    ];
  }

  close() {
    this.visible = false;
    this.dialogRef.close();
  }
}
