/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@angular/common";
import * as i3 from "./formly-custom-validation-message.component";
import * as i4 from "@ngx-formly/core";
var styles_FormlyCustomValidationMessage = [];
var RenderType_FormlyCustomValidationMessage = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyCustomValidationMessage, data: {} });
export { RenderType_FormlyCustomValidationMessage as RenderType_FormlyCustomValidationMessage };
function View_FormlyCustomValidationMessage_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "wcag-visuallyhidden"], ["role", "alert"]], [[1, "aria-label", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorLabel; _ck(_v, 0, 0, currVal_0); }); }
export function View_FormlyCustomValidationMessage_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FormlyCustomValidationMessage_1)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.errorMessage$)); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_FormlyCustomValidationMessage_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-custom-validation-message", [], null, null, null, View_FormlyCustomValidationMessage_0, RenderType_FormlyCustomValidationMessage)), i0.ɵdid(1, 573440, null, 0, i3.FormlyCustomValidationMessage, [i4.FormlyConfig], null, null)], null, null); }
var FormlyCustomValidationMessageNgFactory = i0.ɵccf("formly-custom-validation-message", i3.FormlyCustomValidationMessage, View_FormlyCustomValidationMessage_Host_0, { field: "field" }, {}, []);
export { FormlyCustomValidationMessageNgFactory as FormlyCustomValidationMessageNgFactory };
