
  <ng-container *ngIf="to.tooltip">
    <fa-icon
      pTooltip="{{ to.tooltip | translate }}"
      tooltipPosition="top"
      tooltipStyleClass="tooltip-min-width"
      [icon]="['fas', 'info-circle']">
    </fa-icon>
  </ng-container>
    <p-calendar
      [appendTo]="to.appendTo"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [id]="id + '_calendar'"
      [inputId]="elementId"
      [inputStyleClass]="'col-12'"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [placeholder]="to.placeholder"
      [styleClass]="'col-12 p-0'"
      (keydown)="keydown($event)"
      (onClose)="onClose($event)"
      (onFocus)="onFocus($event)"
      #calendar
      dateFormat="yy-mm-dd"
    >
    </p-calendar>
  