<app-project-discussion-wrapper>
  <app-project-discussion-controls
    [comments]="comments"
    [project]="project"
    class="mb-3"
  ></app-project-discussion-controls>
  <app-project-discussion-chat
    [comments]="comments"
    [project]="project"
    class="mb-3"
  ></app-project-discussion-chat>
  <app-project-discussion-map *ngIf="!hideMaps"
    [project]="project"
  ></app-project-discussion-map>
</app-project-discussion-wrapper>
