<div class="table-area sub-projects" *ngIf="tableData?.length">
  <app-table-view
    [data]="tableData"
    [config]="tableConfig"
    [firstItemIndex]="firstItemIndex"
    (paginationChanged)="onPaginationChanged($event)"
    [relative]="true"
  >
  </app-table-view>
</div>
<ng-template #actionTemplate let-data let-index = "rowIndex">
  <app-generic-action-control
    [rawData]="actions"
    [appendTo]="'.sub-projects .ui-table-wrapper table'"
    (selectedAction)="onActionSelected($event, data)">
  </app-generic-action-control>
</ng-template>

<ng-template #viewColTemplate let-data let-index = "rowIndex">
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="updateSubProject(data)"
          (keyup)="updateSubProject(data)">
      {{ 'USERS_MODULE.usersList.view' | translate}}
    </span>
  </div>
</ng-template>
