/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i4 from "@fortawesome/angular-fontawesome";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/router";
import * as i7 from "./account-page.component";
var styles_AccountPageComponent = [i0.styles];
var RenderType_AccountPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountPageComponent, data: {} });
export { RenderType_AccountPageComponent as RenderType_AccountPageComponent };
function View_AccountPageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AccountPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "section", [["class", "action-panel"]], [[2, "visible", null]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountPageComponent_2)), i1.ɵdid(3, 671744, null, 0, i2.NgComponentOutlet, [i1.ViewContainerRef], { ngComponentOutlet: [0, "ngComponentOutlet"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "fa-icon", [["aria-label", "Toggle"], ["class", "toggle-icon fa-chevron-left ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleActionPanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(5, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaConfig, i4.FaIconLibrary, [2, i4.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 2, "fa-icon", [["aria-label", "Toggle"], ["class", "toggle-icon fa-chevron-right ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleActionPanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FaIconComponent_0, i3.RenderType_FaIconComponent)), i1.ɵdid(8, 573440, null, 0, i4.FaIconComponent, [i5.DomSanitizer, i4.FaConfig, i4.FaIconLibrary, [2, i4.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(9, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.sidebarComponent; _ck(_v, 3, 0, currVal_1); var currVal_4 = _ck(_v, 6, 0, "fas", "chevron-left"); _ck(_v, 5, 0, currVal_4); var currVal_7 = _ck(_v, 9, 0, "fas", "chevron-right"); _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.actionPanelVisible)); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 5).title; var currVal_3 = i1.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 8).title; var currVal_6 = i1.ɵnov(_v, 8).renderedIconHTML; _ck(_v, 7, 0, currVal_5, currVal_6); }); }
export function View_AccountPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountPageComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.withActionPanel; _ck(_v, 1, 0, currVal_0); _ck(_v, 4, 0); }, null); }
export function View_AccountPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-page", [], null, null, null, View_AccountPageComponent_0, RenderType_AccountPageComponent)), i1.ɵdid(1, 245760, null, 0, i7.AccountPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountPageComponentNgFactory = i1.ɵccf("app-account-page", i7.AccountPageComponent, View_AccountPageComponent_Host_0, {}, {}, []);
export { AccountPageComponentNgFactory as AccountPageComponentNgFactory };
