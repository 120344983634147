import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { PROJECT_DETAILS_TRANSFER_FORM } from '@shared/components/project-builder/consts/project-builder.const';
import { SUB_PROJECT_FORM } from '@shared/components/project-builder/consts/sub-projects.const';
import { ProjectBuilderService } from '@shared/components/project-builder/project-builder.service';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-project-transfer-details',
  templateUrl: './project-transfer-details.component.html',
  styleUrls: ['./project-transfer-details.component.scss'],
})
export class ProjectTransferDetailsComponent implements OnInit {

  @Input() popUpView = true;
  @Input() form = new FormGroup({});
  @Input() model: any = {};
  public visible = true;
  public fields: FormlyFieldConfig[];

  constructor(
    private formFactoryService: FormFactoryService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ProjectTransferDetailsComponent>,
    private projectBuilderService: ProjectBuilderService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.model =  {
      ...this.model,
      ...this.data.projectData,
      account: this.projectBuilderService.model.account,
    };
    if (this.model.account.address) {
      this.model.account['organizationAddressCompiled'] = `${this.getStringValue(this.model.account.address.streetNumber, false)}${this.getStringValue(this.model.account.address.streetName)}${this.getStringValue(this.model.account.address.city)}${this.getStringValue(this.model.account.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(this.model.account.address.poBox)}${this.getStringValue(this.model.account.address.postalCode)}${this.getStringValue(this.model.account.address.country, false, 'COMMON.countryList.')}`;
    }
    this.setFields();
  }

  private setFields() {
    const subProjectForm = cloneDeep(PROJECT_DETAILS_TRANSFER_FORM);
    this.fields = this.formFactoryService.configureForm(subProjectForm);
  }

  public close() {
    this.dialogRef.close(false);
  }

  public changed(ev) {
    this.dialogRef.close(false);
  }

  getStringValue(value, comma = true, translationPrefix = null) {
    let response = '';
    if (value) {
      response = value;
      if (translationPrefix) {
        response = this.translateService.instant(`${translationPrefix}${value}`);
      }
      if (comma) {
        response = `${response}, `;
      } else {
        response = `${response} `;
      }
    }
    return response;
  }
}
