<div class="row">
  <div class="col-4 mb-2">
    {{'PROJECTS_MODULE.DISCUSSION.discussionFilterBy' | translate}}:
    <ng-select
      [(ngModel)]="filterByModel"
      [inputAttrs]="{'aria-label': 'PROJECTS_MODULE.DISCUSSION.discussionFilterBy' | translate}"
      [items]="filterByItems"
      [multiple]="true"
      [placeholder]="'PROJECTS_MODULE.DISCUSSION.discussionFilterBy' | translate"
      (add)="onAdd($event)"
      (remove)="onRemove()"
      #filterBy
      class="ng-dropdown-left"
      wcag-label
    >
      <ng-template ng-label-tmp let-item="item">
        {{item.label | translate}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{item.label | translate}}
      </ng-template>
    </ng-select>
  </div>
  <div class="col-4 mb-2">
    {{'PROJECTS_MODULE.DISCUSSION.discussionSortBy' | translate}}:
    <ng-select
      [(ngModel)]="sortByModel"
      [inputAttrs]="{'aria-label': 'PROJECTS_MODULE.DISCUSSION.discussionSortBy' | translate}"
      [items]="sortByItems"
      [placeholder]="'PROJECTS_MODULE.DISCUSSION.discussionSortBy' | translate"
      (change)="onSortBy($event)"
      class="ng-dropdown-left"
      wcag-label
    >
      <ng-template ng-label-tmp let-item="item">
        {{item.label | translate}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        {{item.label | translate}}
      </ng-template>
    </ng-select>
  </div>
  <div class="d-flex justify-content-end align-items-end comment-btn mb-2">
    <button
      (click)="onAddComment()"
      class="btn btn-secondary"
      type="button"
    >
      {{'PROJECTS_MODULE.DISCUSSION.discussionAddCommentButton' | translate}}
    </button>
  </div>
</div>
