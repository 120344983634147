import { HttpClient, HttpParams } from '@angular/common/http';
import { OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { EventEmitter, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { Pgination } from '@core/models/pagination.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { AUTO_WIDTH_FOR_COLUMNS, ITableFilterParams, ITableViewConfig } from '@shared/models/table-view.model';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { ModalService } from '@shared/services/modal.service';
import { isEqual } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';
import { TableNewRowComponent } from '../table-new-row/table-new-row.component';
import {
  TABLE_FILTER_SETTINGS_KEY
} from '@shared/components/table-filter/table-filter.const';


@Component({
  selector: 'app-simple-table-wrapper',
  templateUrl: './simple-table-wrapper.component.html',
  styleUrls: ['./simple-table-wrapper.component.scss'],
})
export class SimpleTableWrapperComponent extends AbstractPaginationService implements OnInit, OnDestroy {

  @Input() tableDetails: any;
  @Input() filterParams: ITableFilterParams;
  @Input() initialFilterState: any = {};
  @Input() selections: any[] = [];
  @Input() refreshData: Observable<any>;
  @Output() exportTableData = new EventEmitter<any>();
  @Output() actionSelected = new EventEmitter<any>();
  @Output() selectedCheckboxes = new EventEmitter<any>();
  @Output() customButtonAction = new EventEmitter<any>();

  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild('editActionTemplate', { static: true }) public editActionTemplate: TemplateRef<any>;

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();

  // Filter variables
  filterFields: any = {};
  firstItemIndex = 0;
  pagination = new Pgination(0, 20);
  filterState: any = {};
  _filterId: number;
  parent = '';

  // Table variables
  tableData: IApp.ITableList;
  tableConfig: ITableViewConfig;

  constructor(
    private http: HttpClient,
    private store: StoreService,
    private lookupService: LookupService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private viewContainerRef: ViewContainerRef) {
    super();
  }

  ngOnInit() {
      this.activatedRoute.data.subscribe(data => {
        if (data['offsetType']) {
           this.initialFilterState = {offsetType : data['offsetType']};
        }
      });

      if (this.refreshData) {
        const storedFilter = sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY)? JSON.parse(sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY)): {};
        this.filterState = {...this.filterState, ...(storedFilter && storedFilter[this.parent])? storedFilter[this.parent]: storedFilter};
        this.refreshData
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.loadData(this.pagination, this.filterState));
      }

      this.activatedRoute.params.subscribe(params => {
        if (params.applicationId) {
          this.initialFilterState = {applicationId : params.applicationId};
        }
        if (params.accountId) {
          this.initialFilterState = {id : params.accountId};
        }
        if (params.projectId) {
          this.initialFilterState = {id : params.projectId};
        }
        if (params.submissionsId) {
          this.initialFilterState = {id : params.submissionsId};
        }
        if (params.projHistId) {
          this.initialFilterState = {projectId : params.projHistId};
        }
        this.filterState = {...this.initialFilterState};
        this.filterFields = this.tableDetails.tableFilter;
        this.getTableHeader();
        this.translateService.onLangChange
          .pipe(takeUntil(this._destroy$))
          .subscribe(() => this.loadData(this.pagination, this.filterState));
    });
  }

  private setupCustomColumnTemplates(): void {
    if (this.tableDetails.customColumnTemplates) {
      const fields = Object.keys(this.tableDetails.customColumnTemplates);
      fields.forEach(field => this.setTemplate(this.tableDetails.customColumnTemplates[field], null, field));
    }
  }

  public get summaryTemplateRef(): TemplateRef<any> {
    return this.tableDetails.summaryTemplateRef;
  }

  getTableHeader() {
    this.lookupService.getTableConfigurations(this.tableDetails.tableHeaderType).subscribe(data => {

      this.tableConfig = data;
      this.parent = this.viewContainerRef['_data'].componentView.component.constructor.name || '';

      if (this.tableDetails.filtersFromConfig) {
        this.filterFields = {...this.filterFields, filterListsModel: data.filters};
      }

      if (!this.isAdmin && this.tableDetails.adminFilters && this.tableDetails.adminFilters.length) {
        this.filterFields = {
          ...this.filterFields,
          filterListsModel: this.filterFields.filterListsModel.filter(filter => !this.tableDetails.adminFilters.includes(filter.itemsType)),
        };
      }

      if (this.tableDetails.hasViewTemple) {
        this.setTemplate(this.viewColTemplate, 'view');
      }

      if (this.tableDetails.hasActionTemple) {
        this.setTemplate(this.actionTemplate, 'actions');
      }

      if (this.tableDetails.hasEditActionTemple && this.store.user.hasPermission(this.tableDetails.hasEditActionTemple.permission)) {
        this.setTemplate(this.editActionTemplate, 'editAction'); // Todo add editAction to translation
      }

      if (this.tableDetails.adminProtocol) {
        this.adminProtocolManagementTableDetailsConfig();
      }

      if (this.tableDetails.projectTypeManagement) {
        this.adminProjectTypeManagementTableDetailsConfig();
      }

      this.setupCustomColumnTemplates();

      if (this.tableDetails.checkbox) {
        this.tableConfig.columns.unshift(
          {
            checkboxSelect: true,
            width: '3em',
          });

      }

      if (!data.paginator) {
        this.loadData(null);
      }
    });
  }

  setTemplate(templateType, headerTitle, field = null) {
    const { columns, ...config } = this.tableConfig;

    let _columns: any = [
      ...this.tableConfig.columns,
    ];

    if (field) {
      const columnIdx = this.tableConfig.columns.findIndex(col => col.field === field);
      if (columnIdx > -1) {
        _columns[columnIdx].templateRef = templateType;
      }
    } else {
      _columns = [
        ..._columns,
        {
          header: headerTitle,
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: templateType,
        },
      ];
    }

    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };
  }

  onFilterChanged(filterData: any, loadData = true) {
    this.firstItemIndex = 0;
    this.pagination.page = this.firstItemIndex;
    this.filterState = {...this.filterState, ...filterData};
    loadData && this.loadData(this.pagination, this.filterState);
  }

  onFilterCleared() {
    this.filterState = {...this.initialFilterState};
    this._filterId = null;
  }

  onPaginationChanged(pagination: Pgination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    const storedFilter = sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY)? JSON.parse(sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY)): {};
    this.filterState = {...this.filterState, ...(storedFilter && storedFilter[this.parent])? storedFilter[this.parent]: storedFilter};
    this.loadData(this.pagination, this.filterState);
  }

  private loadData(pagination: Pgination, filterState: any = {}) {
    this._stopPrevious$.next();

    this.getTableListData(pagination, filterState)
    .pipe(
      takeUntil(this._destroy$),
      takeUntil(this._stopPrevious$),
      ).subscribe(
        data => {
          this.tableData = data;
          if (this.tableDetails.needsTableData) {
            this.exportTableData.emit(data);
          }
        },
        );
  }

  get actionService(): any {
    return this.tableDetails.actionService;
  }

  public onActionSelected(actionData): void {
    this.actionService.triggerAction(actionData);
  }

  openEditPopUp(rowData) {
    // tslint:disable-next-line: max-line-length
    this.modalService.open(TableNewRowComponent, { form: this.tableConfig, defaultValues: rowData, actionType: TABLE_NEW_ROW_ACTION.EDIT } as TableNewRow).afterClosed().subscribe((result?: any) => {

      this.newEntryAdded(result);
    });
  }

  newEntryAdded(result) {
    if (result) {
      this.loadData(this.pagination, this.filterState);
    }
  }

  getTableListData(pagination: IApp.IPagination, filterState: any = {}): Observable<IApp.ITableList> {
    const params = this.addPagination(new HttpParams(), pagination);
    return this.http.post<IApp.ITableList>(`${environment.apiUrl}${this.tableDetailsUri}`, filterState, { params });
  }

  get tableDetailsUri(): string {
    return this.tableDetails.tableListApiUri || this.tableConfig.uri;
  }

  private adminProtocolManagementTableDetailsConfig() {
    this.tableConfig.editService = '/account-service/offsetProtocol/saveGHG';
    this.tableConfig.columns.map(obj => {
      obj.sortField = obj.field;
      if (obj.field === 'protocolPublishedDate') {
        return obj.fieldType = 'app-formly-datepicker';
      } else {
        return obj.fieldType = 'input';
      }
    });

    this.tableConfig.columns.map(obj => {
      obj.sortField = obj.field;
      if (obj.field === 'status' ) {
        obj['source'] = 'getOffsetProtocolStatuses';
        obj.toolTip =  {text : 'ADMIN_MODULE.offsetProtocolManagement.statusMessage'};

        return obj.fieldType = 'dict-select';
      }
    });
  }

  onCheckboxActionSelected(selectedRows) {
    this.actionSelected.emit(selectedRows);
  }

  onCustomButtonAction(event) {
    this.customButtonAction.emit(event);
  }

  onRowSelect(check) {
    if (this.actionService) {
      const actionModel = this.actionService.addCheckBoxAction(check, this.tableDetails);
      this.filterFields = {...this.filterFields, actionModel};
      this.selectedCheckboxes.emit(check);
    }
  }

  private get isAdmin(): boolean {
    return this.store.user.isAdmin;
  }

  private adminProjectTypeManagementTableDetailsConfig() {
    this.tableConfig.editService = '/offset-service/projectActivityType/save';
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
