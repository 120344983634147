/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../project-activity-summary-card/project-activity-summary-card.component.ngfactory";
import * as i3 from "../project-activity-summary-card/project-activity-summary-card.component";
import * as i4 from "../../../../core/services/lookup.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../services/form-factory.service";
import * as i7 from "../project-builder.service";
import * as i8 from "../../../pipes/custom-translate.pipe";
import * as i9 from "@angular/common";
import * as i10 from "./project-summary.component";
var styles_ProjectSummaryComponent = [i0.styles];
var RenderType_ProjectSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectSummaryComponent, data: {} });
export { RenderType_ProjectSummaryComponent as RenderType_ProjectSummaryComponent };
function View_ProjectSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-project-activity-summary-card", [], null, null, null, i2.View_ProjectActivitySummaryCardComponent_0, i2.RenderType_ProjectActivitySummaryCardComponent)), i1.ɵdid(2, 245760, null, 0, i3.ProjectActivitySummaryCardComponent, [i4.LookupService, i5.TranslateService, i6.FormFactoryService, i7.ProjectBuilderService], { activity: [0, "activity"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProjectSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i8.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "pl-2 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ProjectSummaryComponent_1)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.activities$)); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.ProjectBuilder.summary")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ProjectSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-summary", [], null, null, null, View_ProjectSummaryComponent_0, RenderType_ProjectSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProjectSummaryComponent, [i7.ProjectBuilderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectSummaryComponentNgFactory = i1.ɵccf("app-project-summary", i10.ProjectSummaryComponent, View_ProjectSummaryComponent_Host_0, {}, {}, []);
export { ProjectSummaryComponentNgFactory as ProjectSummaryComponentNgFactory };
