import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-form-field',
  template: `
    <div class="form-group" [class.has-error]="showError">
      <label *ngIf="to.label && to.hideLabel !== true && to.dummyLabel !== true" [attr.for]="itemLabelPrefix + elementId">
        <span *ngIf="to.required && to.hideRequiredMarker !== true" class="wcag-visuallyhidden">Required field, </span>
        <span [innerHTML]="to.label | translate"></span>
        <span *ngIf="to.required && to.hideRequiredMarker !== true" aria-hidden="true">*</span>:
        <fa-icon
          *ngIf="to.icon"
          pTooltip="{{to.icon?.text | translate}}"
          tooltipStyleClass="tooltip-min-width"
          [escape]="false"
          tooltipPosition="{{to.icon?.tooltipPosition ? to.icon?.tooltipPosition : 'top'}}"
          [icon]="to.icon?.iconProp ? to.icon.iconProp : ['fas', 'info-circle']">
        </fa-icon>
      </label>
      <label *ngIf="to.label && to.dummyLabel === true" [attr.for]="itemLabelPrefix + elementId" class="wcag-visuallyhidden">
        <span [innerHTML]="to.label"></span>
      </label>
      <input *ngIf="to.dummyInputForLabel" [id]="itemLabelPrefix + elementId" class="wcag-visuallyhidden" tabindex="-1">
      <ng-template #fieldComponent></ng-template>
      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-custom-validation-message [field]="field"></formly-custom-validation-message>
      </div>
      <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
    </div>
  `,
})
export class FormlyWrapperFormField extends FieldWrapper {

  get itemLabelPrefix(): string {
    return this.to.itemLabelPrefix ? `${this.to.itemLabelPrefix}_` : '';
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      if (!prefix && this.field.parent.parent && this.field.parent.parent.type === 'form-array') {
        prefix = this.field.parent['id'];
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

}
