/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-array-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i6 from "@ngx-formly/core";
import * as i7 from "@angular/forms";
import * as i8 from "./form-array-table.component";
var styles_FormArrayTableComponent = [i0.styles];
var RenderType_FormArrayTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormArrayTableComponent, data: {} });
export { RenderType_FormArrayTableComponent as RenderType_FormArrayTableComponent };
function View_FormArrayTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[1, "class", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.class; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_v.context.$implicit.text)); _ck(_v, 2, 0, currVal_1); }); }
function View_FormArrayTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row p-2 border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-primary float-right"], ["id", "add"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.add() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.addButtonText)); _ck(_v, 3, 0, currVal_1); }); }
export function View_FormArrayTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container border m-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row border-bottom align-items-end"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormArrayTableComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "addStageContainer pt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "formly-form", [], null, null, null, i5.View_FormlyForm_0, i5.RenderType_FormlyForm)), i1.ɵprd(512, null, i6.FormlyFormBuilder, i6.FormlyFormBuilder, [i6.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(7, 966656, null, 0, i6.FormlyForm, [i6.FormlyFormBuilder, i6.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i7.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormArrayTableComponent_2)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headers; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.form; var currVal_2 = _co.model; var currVal_3 = _co.fields; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.addButtonText; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_FormArrayTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-form-array-table", [], null, null, null, View_FormArrayTableComponent_0, RenderType_FormArrayTableComponent)), i1.ɵdid(1, 114688, null, 0, i8.FormArrayTableComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormArrayTableComponentNgFactory = i1.ɵccf("app-form-array-table", i8.FormArrayTableComponent, View_FormArrayTableComponent_Host_0, { headers: "headers", form: "form", model: "model", fields: "fields", addButtonText: "addButtonText", disabled: "disabled" }, { addItem: "addItem" }, []);
export { FormArrayTableComponentNgFactory as FormArrayTableComponentNgFactory };
