/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./button.type.component";
var styles_FormlyFieldButton = [];
var RenderType_FormlyFieldButton = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldButton, data: {} });
export { RenderType_FormlyFieldButton as RenderType_FormlyFieldButton };
export function View_FormlyFieldButton_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "button", [["type", "button"]], [[8, "disabled", 0], [8, "tabIndex", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = ((("btn btn-" + _co.to.btnType) + " ") + _co.to.className); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.disabled; var currVal_1 = _co.tabIndex; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.to.text)); _ck(_v, 4, 0, currVal_3); }); }
export function View_FormlyFieldButton_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-field-button", [], null, null, null, View_FormlyFieldButton_0, RenderType_FormlyFieldButton)), i0.ɵdid(1, 49152, null, 0, i4.FormlyFieldButton, [], null, null)], null, null); }
var FormlyFieldButtonNgFactory = i0.ɵccf("formly-field-button", i4.FormlyFieldButton, View_FormlyFieldButton_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldButtonNgFactory as FormlyFieldButtonNgFactory };
