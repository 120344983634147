import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dict',
})
export class DictPipe implements PipeTransform {

  transform(value, dictArray, key = 'name'): any {
    if (value && dictArray && Object.keys(dictArray).length > 0) {
      let d = dictArray;
      if (isNaN(parseInt(Object.keys(dictArray)[0], 10))) {
        // support Flux source
        d = dictArray[Object.keys(dictArray)[0]];
      }
      const obj = d.find(o => o.id === value);
      return obj ? obj[key] : null;
    } else {
      return '';
    }
  }

}
