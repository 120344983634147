
    <div class="form-group" [class.has-error]="showError">
      <label *ngIf="to.label && to.hideLabel !== true && to.dummyLabel !== true" [attr.for]="itemLabelPrefix + elementId">
        <span *ngIf="to.required && to.hideRequiredMarker !== true" class="wcag-visuallyhidden">Required field, </span>
        <span [innerHTML]="to.label | translate"></span>
        <span *ngIf="to.required && to.hideRequiredMarker !== true" aria-hidden="true">*</span>:
        <fa-icon
          *ngIf="to.icon"
          pTooltip="{{to.icon?.text | translate}}"
          tooltipStyleClass="tooltip-min-width"
          [escape]="false"
          tooltipPosition="{{to.icon?.tooltipPosition ? to.icon?.tooltipPosition : 'top'}}"
          [icon]="to.icon?.iconProp ? to.icon.iconProp : ['fas', 'info-circle']">
        </fa-icon>
      </label>
      <label *ngIf="to.label && to.dummyLabel === true" [attr.for]="itemLabelPrefix + elementId" class="wcag-visuallyhidden">
        <span [innerHTML]="to.label"></span>
      </label>
      <input *ngIf="to.dummyInputForLabel" [id]="itemLabelPrefix + elementId" class="wcag-visuallyhidden" tabindex="-1">
      <ng-template #fieldComponent></ng-template>
      <div *ngIf="showError" class="invalid-feedback" [style.display]="'block'">
        <formly-custom-validation-message [field]="field"></formly-custom-validation-message>
      </div>
      <small *ngIf="to.description" class="form-text text-muted">{{ to.description }}</small>
    </div>
  