import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { CIVIC_ADDRESS_FORM_SECTION, POSTAL_ADDRESS_FORM_SECTION } from '@shared/components/stepper/stepper.const';
import { Step } from '@shared/components/steps/models/step';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { takeUntil } from 'rxjs/operators';
import { STEP4_RC_AUTH_OFFICIAL_INFO, STEP4_CONTACT_PERSON_INFO } from '../constant/AgreementCreateCredits.const';

@Component({
  selector: 'app-rca-auth-contact-person-info',
  templateUrl: './rca-auth-contact-person-info.component.html',
  styleUrls: ['./rca-auth-contact-person-info.component.scss']
})
export class RcaAuthContactPersonInfoComponent extends BaseStepperStepComponent {

  static key = 'RcaAuthContactPersonInfoComponent';

  model: any = {};

  formGroup = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  formGroupOtherParty = new FormGroup({});
  fieldsOtherParty: FormlyFieldConfig[] = [];

  existInMarkit: boolean;
  opaDetail: any;
  constructor(
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
    private helperService: HelperService,
    private router: Router,
    private modalService: ModalService,
    public service: AgreementCreditsService) { super(); }

  ngOnInit() {

    this.model = this.service.currentData;

    if (this.service.currentData && this.service.currentData.oldAag && this.model.registrationAccount) {
      this.model.registrationAccount.authorizedOfficial = this.service.currentData.oldAag
      this.model.registrationAccount.authorizedOfficial.name = this.service.currentData.oldAag.fullName
    }

    if (this.model.opaDetail && this.model.opaDetail.editable) {
      this.existInMarkit = false;
    } else {
      this.existInMarkit = true;
      setTimeout(() => {
        this.formGroup.disable();
        this.formGroupOtherParty.disable();
      });
    }

    // opaDetail.authorizedOfficial.
    const FORM_FIELDS = this.service.getContactPersonInfo('registrationAccount.authorizedOfficial');
    this.fields = this.formFactoryService.configureForm(FORM_FIELDS);
    // this will have to change based on the type as for the 2nd user the title will be different
    POSTAL_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Auth_Address.';
    POSTAL_ADDRESS_FORM_SECTION.key = 'registrationAccount.authorizedOfficial.postalAddress';
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = false;
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.postalAddress\', \'opaDetail.authorizedOfficial.postalAddress\')';

    CIVIC_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Auth_Address.';
    CIVIC_ADDRESS_FORM_SECTION.key = 'registrationAccount.authorizedOfficial.civicAddress';
    CIVIC_ADDRESS_FORM_SECTION.sameAsPostal.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.authorizedOfficial.postalAddress\', \'opaDetail.authorizedOfficial.civicAddress\')';

    this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(POSTAL_ADDRESS_FORM_SECTION, CIVIC_ADDRESS_FORM_SECTION, 'opaDetail.postalAddress')[0]);

    const fieldsOtherParty =this.service.getContactPersonInfo('registrationAccount.contactPerson');
    this.fieldsOtherParty = this.formFactoryService.configureForm(fieldsOtherParty);
    // this will have to change based on the type as for the 2nd user the title will be different
    POSTAL_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Contact_Person.';
    POSTAL_ADDRESS_FORM_SECTION.key = 'registrationAccount.contactPerson.postalAddress';
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = false;
    POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.postalAddress\', \'opaDetail.contactPerson.postalAddress\')';

    CIVIC_ADDRESS_FORM_SECTION.prefix = 'AGREEMENT_CREDITS.Step_4_Contact_Person.';
    CIVIC_ADDRESS_FORM_SECTION.key = 'registrationAccount.contactPerson.civicAddress';

    CIVIC_ADDRESS_FORM_SECTION.sameAsPostal.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.contactPerson.postalAddress\', \'opaDetail.contactPerson.civicAddress\')',

      this.fieldsOtherParty.push(this.formFactoryService.configurFormPostWithCivicAddress(POSTAL_ADDRESS_FORM_SECTION, CIVIC_ADDRESS_FORM_SECTION, 'opaDetail.postalAddress')[0]);
    this.service.disableFormIfApplicable(this.formGroup);
    this.service.disableFormIfApplicable(this.formGroupOtherParty);
  }



  saveAsDraft() {
    this.saveAndProceed(true);
  }

  saveAndProceed(draft: boolean = false) {
    this.service.nextStep();
  }

}
