import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { accountSummaryNFMPRequiredFields, accountSummaryOBPSRequiredFields } from '@core/constants/registration-required-fields.const';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { OMPAccountType } from '@core/models/account.model';
import { IApp } from '@core/models/app.interfaces';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AccountType } from '@module/account-management/account-management.const';
import {
  AccountManagementService,
  DOCUMENT_UPLOAD_REGISTRATION_VB,
} from '@module/account-management/services/account-management.service';
import { CfrAccountActionsService } from '@module/account-management/services/cfr-account-actions.service';
import { OBPSAccountActionsService } from '@module/account-management/services/obps-account-actions.service';
import { OffsetAccountActionsService } from '@module/account-management/services/offset-account-actions.service';
import { CMAccountActionsService } from '@module/account-management/services/cm-account-actions.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { RegistrationService } from '@module/registration/registration.service';
import { UserManagementService } from '@module/users-management/services/user-management.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
  CONTACT_PERSON_MAILING_ADDRESS,
  CONTACT_PERSON_PHYSICAL_ADDRESS,
  OFFSET_REGISTRATION_FORM,
  OFFSET_REGISTRATION_FORM_WITHOUT_ARP,
  OFFSET_SUB_REGISTRATION_FORM,
  SAPCUSTOMERNUMBER,
} from '@shared/components/offset-account-type/offset-account-type.const';
import {
  CFS_REGISTRATION_ACCOUNT_SUMMARY,
  REGISTRATION_TYPE
} from '@shared/components/stepper/stepper.const';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { RegistrationForm } from '@shared/models/registration-form.model';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { AgreeementAccSumComponent } from '../agreeement-acc-sum/agreeement-acc-sum.component';

export const COMPONENT_ID = 'ACCOUNT_SUMMARY';

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
})
export class AccountSummaryComponent implements OnInit, OnDestroy {

  // Active variables
  private _destroy$ = new Subject<any>();
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[];
  account: IApp.IAccountData = {};
  accountId: number;
  isEditEnabled = false;
  accountStatus: string;
  public creditSummary: IApp.ICreditSummary = {};
  documentsPage: IApp.IDocumentsPage;
  accountTypeName: string;
  accountSubTypes: string;
  documentUploadConfig: IApp.IDocumentUploadConfig = {
    documentType: true,
    entityName: 'ACCOUNT',
    comment: true,
    title: 'ACCOUNTS_MODULE.uploadDocument.title',
  };
  cfrAccountTypeName: string;
  isRC: boolean;
  history: boolean;
  users: IApp.ISaveUser[];

  constructor(
    private elem: ElementRef,
    private router: Router,
    private _location: Location,
    private storeService: StoreService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private registrationService: RegistrationService,
    private uploadDocumentService: UploadDocumentService,
    private accountManagementService: AccountManagementService,
    private userManagementService: UserManagementService,
    private helperService: HelperService,
    private formFactoryService: FormFactoryService,
    private oBPSAccountActionsService: OBPSAccountActionsService,
    private cFRAccountActionsService: CfrAccountActionsService,
    private oFFSETAccountActionsService: OffsetAccountActionsService,
    private cMAccountActionsService: CMAccountActionsService,
    private agreementCreditsService: AgreementCreditsService,
    private notificationService: NotificationsService,
    private navigation: NavigationService,
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        if (params.id === 'History') {
          if (history.state.data) {
          this.account = history.state.data.data;
          this.accountId =  this.account.id;
          this.history = true;
          this.createForm();
          } else {
            this.navigation.back();
          }
        } else {
          this.accountId = params.id;
          this.documentUploadConfig.id = this.accountId;
       }
      } else {
        this.navigation.back();
      }
    });
    setTimeout(() => { this.form.disable(); });
    if (!this.history) {
      this.getAccountDetails();
    }
    this.form['id'] = COMPONENT_ID;
    this.translateService.onLangChange
    .pipe(
      takeUntil(this._destroy$),
      ).subscribe((event: LangChangeEvent) => {
        this.getAuthorizedOfficialByAccountId();
      });
  }

  getAuthorizedOfficialByAccountId() {
    const filterState = { facilityNameList: [this.accountId] };
    this.userManagementService.getAll(null, filterState)

      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          const users = data.content.filter(user => user.associationsList.length > 0);
          users.map(user => {
            if (user.id === this.account.authorizedUserId && this.isOBPS()) {
              user.email = user.email + `<br/> (${this.translateService.instant('ACCOUNTS_MODULE.accountSummary.authorizedOfficial')})`;
            }
          });
          this.users = [...users];
        },
        () => { },
      );
  }

  get actionService() {
    return this[this.storeService.user.getServiceClass('AccountActionsService')];
  }

  getAccountDetails() {
    combineLatest([
    this.accountManagementService.getAccountDetails(this.accountId, true),
    this.accountManagementService.getAll({}, {accountId: this.accountId}),
   ]).pipe(takeUntil(this._destroy$))
      .subscribe(([data,  regResponse]) => {
          this.account = data;
          this.account.authorizedOfficial = {
            ...data.authorizedOfficial,
            name: data.authorizedOfficial.fullName,
            telephone: data.authorizedOfficial.phoneNumber,
          };
          this.isRC = this.account.accountTypeName === 'PARTICIPANT' && this.account.accountSubTypeNames !== 'FS';
          this.documentUploadConfig.entityDetail = data.legalName;
          // this.account.address.country = this.account.address.country ? this.account.address.country : 'CA';
          // if (this.account.accountTypeName === 'VB') {
          if (this.isCFS())  {
            this.documentUploadConfig.uri = DOCUMENT_UPLOAD_REGISTRATION_VB;
          }
          this.accountStatus = `COMMON.statusList.${data.status}`;
          this.accountTypeName = `COMMON.accountTypeList.${data.accountTypeName}`;
          this.accountSubTypes = `COMMON.accountSubTypeList.${data.accountSubTypes}`;
          if (this.account.entityActionList) {
            this.account.entityActionList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`));
          }

          this.account.jurisdiction = this.translateService.instant(`COMMON.jurisdictionsList.${this.account.jurisdiction}`);

          if (this.isOBPS()) {
            if (this.account.address !== null) {
              // this.account.address.province =  this.translateService.instant(`COMMON.jurisdictionsList.${ this.account.address.province }`);
            }
          }

          this.getAuthorizedOfficialByAccountId();

          this.createForm();

          this.accountManagementService.getOBPSCreditSummary(this.account.id).pipe(
            takeUntil(this._destroy$),
          ).subscribe(
            data => {
              this.creditSummary = data;
              this.accountManagementService.formatCreditSummary(this.creditSummary);
            });

          this.documentUploadConfig.id = this.accountId;
          this.uploadDocumentService.getDocumentsByEntityIdAndType(
            this.documentUploadConfig.id, this.documentUploadConfig.entityName)
            .subscribe(
              documents => {
                this.documentsPage = documents;
                this.form.disable();
              });
        },
        err => {
          this.router.navigate(this.helperService.getTranslatedPath('/account-management'));
        },
      );
  }

  isCFS() {
    return this.storeService.user.program === 'CFR';
  }

  get isOffset(): boolean {
    return this.storeService.user.isOffset();
  }

  get isCM(): boolean {
    return this.storeService.user.isCM();
  }

  get isMasterAccount(): boolean {
    return !this.account.masterAccountId;
  }

  showSubOrganization() {
    return this.account.accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB
      && this.storeService.user.hasPermission('ACCOUNT_WRITE');
  }

  createForm() {

    const ompAccountTypePurpose = new OMPAccountType();

    ompAccountTypePurpose.accountSubType = this.account.accountSubType;

    if (this.storeService.user.isOffset()) {
      this.setAoProfilePublicView();

      if (!this.storeService.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER') ) {
        if (OFFSET_REGISTRATION_FORM[0].fieldGroup.length === 9) {
          OFFSET_REGISTRATION_FORM[0].fieldGroup.push(SAPCUSTOMERNUMBER);
        }
      }

      if (this.account.masterAccountName) {
        this.registrationService.getOffsetAccountById(this.accountId).subscribe(result => {

          if (result.subAccount && (this.isCM || this.isOffset)) {
            result.legalName = result.masterAccount.legalName;
          }

          this.account.masterAccount = result;
          this.account.subAccountReasonId = result.subAccountReasonId;

          if (!this.storeService.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER') ) {
            if (OFFSET_REGISTRATION_FORM[0].fieldGroup.length === 8) {
              OFFSET_REGISTRATION_FORM[0].fieldGroup.push(SAPCUSTOMERNUMBER);
            }
          }
          this.fields = this.formFactoryService.configureForm(OFFSET_SUB_REGISTRATION_FORM);
          OFFSET_REGISTRATION_FORM[0].fieldGroup.splice(-1);
          if (!this.storeService.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER') ) {
            if (OFFSET_REGISTRATION_FORM_WITHOUT_ARP[0].fieldGroup.length === 8) {
              OFFSET_REGISTRATION_FORM_WITHOUT_ARP[0].fieldGroup.push(SAPCUSTOMERNUMBER);
            }
            this.formFactoryService.configureForm(OFFSET_REGISTRATION_FORM_WITHOUT_ARP).forEach(f => {
              f.key = f.key ? `masterAccount.${f.key}` : `masterAccount`;
              f.fieldGroup.forEach(fg => fg.templateOptions.disabled = true);
              this.fields.push(f);
            });
            OFFSET_REGISTRATION_FORM_WITHOUT_ARP[0].fieldGroup.splice(-1);
          }
          this.formFactoryService.addPrincipalContactPerson(this.fields);
          setTimeout(() => this.form.disable());
        });
      } else {

        this.fields = this.formFactoryService.configureForm(OFFSET_REGISTRATION_FORM);

        const contactPersonForm = this.formFactoryService.configureForm([this.formFactoryService.contactPersonJobTitleRequiredExpression(this.form)])[0];
        this.fields.push(contactPersonForm);
        const contactPersonAddress = this.formFactoryService.configurFormPostWithCivicAddress(CONTACT_PERSON_PHYSICAL_ADDRESS, CONTACT_PERSON_MAILING_ADDRESS)[0];
        contactPersonAddress.wrappers = ['app-formly-fieldset'];
        contactPersonAddress.templateOptions = {label: this.translateService.instant('REGISTRATION_PAGE.offsetRegistrationForm.contactPersonAddress')};
        this.fields.push(contactPersonAddress);
        this._businessStructureChangeSubscription();
      }

      return;
    }

    if (this.isCFS()) {
      this.fields = this.formFactoryService.configureForm(CFS_REGISTRATION_ACCOUNT_SUMMARY);
      return;
    }

    if (this.isOBPS()) {
      const registrationForm = new RegistrationForm(
        this.registrationService,
        REGISTRATION_TYPE.OBPS,
        this.lookupService,
        accountSummaryOBPSRequiredFields,
        null,
        this.account.persons ? this.account.persons .length : 0,
        ompAccountTypePurpose);
      this.fields = registrationForm.getRegistrationForm();
      if (this.storeService.user.hasPermission('USER_TYPE_ACCOUNT_HOLDER')) {
        this.fields.forEach(e => {
          e.fieldGroup.map(fg => {
            if (
              fg.key === 'facilityId' ||
              fg.key.indexOf('organizationId') > -1 ||
              fg.key === 'sapCustomerNumber'
            ) {
              fg.hide = true;
            }
          });
        });
      }
    } else {
      // Carbon Market program, Other Market Participant
      const registrationForm = new RegistrationForm(
        this.registrationService,
        REGISTRATION_TYPE.NFMP,
        this.lookupService,
        accountSummaryNFMPRequiredFields,
        null,
        null,
        ompAccountTypePurpose);

      if (this.account.masterAccountName) {
        this.registrationService.getOffsetAccountById(this.accountId).subscribe(result => {
          const subRegForm = OFFSET_SUB_REGISTRATION_FORM;
          subRegForm[0].fieldGroup.forEach(f => f.disabled = true);

          this.account.masterAccount = result;
          this.account.subAccountReasonId = result.subAccountReasonId;

          this.fields = registrationForm.getRegistrationForm();
          this.fields.splice(1, 0, ...this.formFactoryService.configureForm(subRegForm));
          setTimeout(() => this.form.disable());
        });
      } else {
        this.fields = registrationForm.getRegistrationForm();
      }

      this._businessStructureChangeSubscription();
    }
  }

  openCreationAgreement() {
    if (this.account.status === 'LOCKED') {
      this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnLockedAccount');
    } else {
    this.agreementCreditsService.getAgreementOpaList(this.account.id).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      response => {
        this.createNewAgreementForEnhancedUser(response);
      },
    );
    }
  }

  createNewAgreementForEnhancedUser(response) {
    if (response.length === 0) {
      // if no response open message saying no facility found
      this.modalService.open(AgreeementAccSumComponent, { showNoFacilityFound: true, data: this.account.id });
    } else {
      this.agreementCreditsService.saveHistoryAndRedirectToAgreementScreen(response);
    }
  }

  isNFMA() { return this.account.accountTypeName === AccountType.NFMPA; }

  isOBPS() { return this.account.accountTypeName === AccountType.OBPS ||
    ( this.account.certificateNumber && this.account.certificateNumber.length > 0); }

  isAllowedToEdit() {
    if (this.isCFS() || this.history || this.account.masterAccountName) {
      return false;
    }
    const permission = this.isOBPS() ? 'OBPS_ACCOUNT_WRITE' : 'NFMP_ACCOUNT_WRITE';
    return this.storeService.user.hasPermissionForAccount(permission, this.accountId);
  }

  editGeneralInformation() {
    this.isEditEnabled = true;
    if (this.account.masterAccountName && this.storeService.user.isOffset()) {
      this.form.get('subAccountReasonId').enable();
    } else {
     this.form.enable();
     if ( this.form.get('legalName')) {
       this.form.get('legalName').disable();
     }
    }
  }

  cancelEditGeneralInformation() {
    this.isEditEnabled = false;
    this.form.disable();
    this.options.resetModel();
  }

  saveGeneralInformation() {
    if (this.form.valid) {
      this.account.users = null;
      if (this.account.authorizedOfficial && this.account.authorizedOfficial.postalAddress && this.account.authorizedOfficial.postalAddress.sameAsOrganizationAddress) {
        const {sameAsOrganizationAddress, sameAsPostalAddress, id} = this.account.authorizedOfficial.postalAddress;
        this.account.authorizedOfficial.postalAddress = {
          ...this.account.authorizedOfficial.postalAddress,
          ...this.account.address,
          sameAsOrganizationAddress,
          sameAsPostalAddress,
          id
        };
      }


      let updateService$: Observable<IApp.OkResponseAccountDto>;

      if (this.storeService.user.isOffset()) {
        updateService$ = this.registrationService.saveOffsetAccount(this.account);
      } else if(this.storeService.user.isCM()) {
        updateService$ = this.accountManagementService.updateCM(this.account);
      } else {
        updateService$ = this.accountManagementService.updateOBPS_NFMP(this.account);
      }

      updateService$.pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          this.isEditEnabled = false;
          this.getAccountDetails();
        },
        err => {
        },
      );
    }
  }

  getEditLabel() {
    if (this.form.disabled === false) {
      return 'COMMON.actionsLabel.CANCEL';
    } else {
      return 'COMMON.actionsLabel.EDIT';
    }
  }

  returnPreviousLocation(event) {
    event && event.preventDefault();
    this.navigation.back();
  }

  back(event) {
    event && event.preventDefault();
    this.navigation.back();
  }

  openUploadPopup(event) {
    event && event.preventDefault();
    this.modalService.open(UploadDocumentComponent, { id: this.account.id, uploadTo: 'account', documentType: true, comment: true }).afterClosed().subscribe((result?: boolean) => {
      if (result) { this.getAccountDetails(); }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _businessStructureChangeSubscription() {
    this.formFactoryService.dictCallback.pipe(
      takeUntil(this._destroy$),
      filter(field => field.key === 'businessStructure.id'),
      switchMap(() => this.lookupService.getBusinessStructure(this.storeService.getAccountTypeIdByProgramType())),
    ).subscribe(result => {
      if (this.account.businessStructure) {
        const businessStructure = result.filter(data => data.id === this.account.businessStructure.id)[0];
        const id = businessStructure ? businessStructure.id : 0;
        this.account.charitableFirm = id === this.storeService.getBusinessStructureIdByProgramType();
        this.account.registrationNumber = null;
      }
    });
  }

  private setAoProfilePublicView() {
    if (!this.account.authorizedOfficial) {
      this.account.authorizedOfficial = { displayAoProfilePublicView: this.account.displayAoProfilePublicView };
    } else {
      this.account.authorizedOfficial.displayAoProfilePublicView = this.account.displayAoProfilePublicView;
    }
  }

}
