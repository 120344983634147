import { Component, Input } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';

@Component({
  selector: 'app-previous-discussion',
  templateUrl: './previous-discussion.component.html',
  styleUrls: ['./previous-discussion.component.scss'],
})
export class PreviousDiscussionComponent {
  @Input() discussions: IApp.IRegistrationDiscussion[];
  @Input() customContent: boolean;
  @Input() translationPrefix = 'REGISTRATION_PAGE.cfrPendingRegistration.';

  constructor() { }

  get anyPreviousDiscussions(): boolean {
    return (this.discussions && this.discussions.filter(d => d.userComment).length > 0) || this.customContent;
  }

  get hasUnansweredComment(): boolean {
    return this.discussions && this.discussions.length > 0 && !this.discussions[0].userComment;
  }
}
