import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
export class FormlyFieldCheckboxExtra extends FieldType {
    constructor() {
        super(...arguments);
        this.defaultOptions = {
            templateOptions: {
                indeterminate: false,
                hideLabel: true,
                dummyLabel: false,
                formCheck: 'custom',
            },
        };
    }
    ngOnInit() {
        if (this.field.defaultValue === 'undefined') {
            this.field.defaultValue = false;
        }
    }
    ngAfterViewInit() {
        if (!this.to.skipFirstChange && this.to.change) {
            setTimeout(() => {
                if (!this.form.disabled) {
                    const _event = new Event('change');
                    _event['initial'] = true;
                    this.checkboxEl.nativeElement.dispatchEvent(_event);
                }
            });
        }
    }
    get elementId() {
        if (this.field.id && this.field.id.includes('formly_')) {
            let prefix = this.form['id'];
            // check if in repeat section
            if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
                const cnt = this.field.parent.parent.key;
                prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
            }
            return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
        }
        else {
            return this.field.id;
        }
    }
    get tabIndex() {
        return this.to.tabindex || this.field.hide ? -1 : 0;
    }
}
