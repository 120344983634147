import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  UNIT_AO_CIVIC_ADDRESS_FORM_SECTION,
  UNIT_AO_POSTAL_ADDRESS_FORM_SECTION, UNIT_DETAILS_AO_CONTACT_FIELDS,
  UNIT_DETAILS_FORM, UNIT_DETAILS_REST_FIELDS, UNIT_ORG_CIVIC_ADDRESS_FORM_SECTION,
} from '@module/offset-public-registry/offset-public-registry.const';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-unit-details-popup',
  templateUrl: './unit-details-popup.component.html',
  styleUrls: ['./unit-details-popup.component.scss'],
})
export class UnitDetailsPopupComponent implements OnInit, OnDestroy {

  public _destroy$ = new Subject<any>();
  visible = true;

  model;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<UnitDetailsPopupComponent>,
    private formFactoryService: FormFactoryService,
  ) { }

  ngOnInit() {
    this.model = this.data.model;
    if (this.model.accountType === 'OBPS' && this.model.unitAccount && this.model.unitAccount.civicAddress) {
      this.model.unitAccount.civicAddress.streetNumber = this.model.unitAccount.civicAddress.streetNumber ? this.model.unitAccount.civicAddress.streetNumber : this.model.unitAccount.civicAddress.streetAddress;
      this.model.unitAccount.civicAddress.city = this.model.unitAccount.civicAddress.city ? this.model.unitAccount.civicAddress.city : this.model.unitAccount.civicAddress.municipality;
    }
    this.fields = this.formFactoryService.configureForm(UNIT_DETAILS_FORM);

    if (this.model['unitAccount']['civicAddress']) {
      this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(
        null,
        UNIT_ORG_CIVIC_ADDRESS_FORM_SECTION,
      )[0]);
    }

    if (this.model['unitAccount']['displayAoProfilePublicView']) {
      this.fields.push(...this.formFactoryService.configureForm(UNIT_DETAILS_AO_CONTACT_FIELDS));
    }
    this.fields.push(this.formFactoryService.configureForm(UNIT_DETAILS_REST_FIELDS)[0]);

    setTimeout(() => this.form.disable());
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
