import { Component, OnInit } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@shared/services/modal.service';
import { Observable } from 'rxjs';
import { CustomPopupComponent } from '../custom-popup/custom-popup.component';
import { TermsConditionsComponent } from '@core/components/terms-conditions/terms-conditions.component';
import { IUserData } from '@core/models/user.model';
import { MODAL_TYPES } from '@core/constants/modals.const';
import { ISessionData } from '@core/models/auth.model';
import { ContactSupportModalComponent } from '../contact-support-modal/contact-support-modal.component';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';
import { RegistrationService } from '@module/registration/registration.service';
import { ReadOnlyPrivacyComponent } from '../read-only-privacy/read-only-privacy.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  version = '0.xxx';

  public publicPage = false;

  public registrationType: string;

  constructor(
    private store: StoreService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private router: Router,
    private helperService: HelperService,
    private registrationService: RegistrationService
  ) { }

  ngOnInit() {
    this.registrationService.registrationType.subscribe(type => {
      this.registrationType = type;
    });
    this.publicPage = this.router.url.includes('public');
  }

  get lang() {
    return this.translateService.currentLang;
  }

  get revisedDate(): Observable<any> {
    return this.store.revisedDate$;
  }

  get currentUser() {
    return this.store.user;
  }

  openPopup(event, header_title, key) {
    event.preventDefault();
    this.modalService.open(CustomPopupComponent, { key, header_title } );
  }

  contactUs(event) {
    event && event.preventDefault();
    this.modalService.open(ContactSupportModalComponent,this.store.user.getProgramPrefix());
  }

  openTnC(event) {
    event.preventDefault();
    this.modalService.open(TermsConditionsComponent, { currentUser: this.currentUser as IUserData, readOnly: true }, false, { id: MODAL_TYPES.SERVICE_MESSAGE });
  }

  openPrivacy(event) {
    event.preventDefault();
    this.modalService.open(TermsConditionsComponent, { currentUser: this.currentUser as IUserData, readOnly: true, privacy: true }, false, { id: MODAL_TYPES.SERVICE_MESSAGE });
  }

  openRegistrationPrivacy(event) {
    event.preventDefault();
    this.modalService.open(ReadOnlyPrivacyComponent, {title: 'privacyPolicy', type: this.registrationType});
  }

  get isLoggedIn(): boolean {
    return !!this.store.getSession();
  }

  openPublicPagePrivacy(event) {
    event.preventDefault();
    if (this.lang === 'en') {
      window.open("https://www.canada.ca/en/transparency/privacy.html", '_blank');
    } else {
      window.open("https://www.canada.ca/fr/transparence/confidentialite.html", '_blank');
    }
  }

  public get isOffsetPublicPage(): boolean {
    return this.store.isOffsetPublicPage;
  }

  openPublicPageDisclaimer(event) {
    event.preventDefault();
    this.router.navigate(this.helperService.getTranslatedPath('/public-registry/disclaimer'));
  }
}
