import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';

@Component({
  selector: 'app-agreeement-acc-sum',
  templateUrl: './agreeement-acc-sum.component.html',
  styleUrls: ['./agreeement-acc-sum.component.scss']
})
export class AgreeementAccSumComponent implements OnInit {

  data: any = {};
  visible = true;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<any>,    
    private router: Router,
    private helperService: HelperService) { }

  ngOnInit() {

  }

  clickRegistrationReport(data) {
    this.close();
    this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/RRPS/${data}`));
  }


  close() {
    this.visible = false;
    this.dialogRef.close();
  }

}
