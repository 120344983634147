import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { LookupService } from '@core/services/lookup.service';
import { TranslateService } from '@ngx-translate/core';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { ModalService } from '@shared/services/modal.service';
import { ProjectBuilderService } from '../project-builder.service';
import { SubProjectFormComponent } from '../sub-project-form/sub-project-form.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sub-projects-table',
  templateUrl: './sub-projects-table.component.html',
  styleUrls: ['./sub-projects-table.component.scss']
})
export class SubProjectsTableComponent implements OnInit, OnDestroy {

  @Input() showStatus = true;

  public actions = {
    entityActionList: [
    {
      id: 1,
      workflowAction: 'discard'
    }]
  };
  public tableConfig: ITableViewConfig;
  public firstItemIndex = 0;
  private _destroy$ = new Subject<any>();

  public tableData = [];

  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  constructor(
    private modalService: ModalService,
    public projectBuilderService: ProjectBuilderService,
    private lookupService: LookupService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.setTableConfig();
    this.translateService.onLangChange.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.setTableConfig();
    });

    this.projectBuilderService.subProjectAdded$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.setTableData();
    })
  }

  onPaginationChanged(ev) {

  }

  onActionSelected(ev, data) {
    const index = this.projectBuilderService.model.subProjects.findIndex(project => project.id === data.id);
    if (index === -1) {
      return;
    }
    if(ev.action.workflowAction === "discard") {
      const sub$ = this.projectBuilderService.openConfirmationModal('discard').subscribe(result => {
        if (result) {
          const discardedSubProject = this.projectBuilderService.model.subProjects.splice(index, 1)[0];
          if (discardedSubProject.version >= 0) {
            if (!this.projectBuilderService.model.discardSubProjects) {
              this.projectBuilderService.model.discardSubProjects = [];
            }
            this.projectBuilderService.model.discardSubProjects.push(discardedSubProject.id);
          }
          this.updateMaster();
          this.setTableData();
          sub$.unsubscribe();
        }
      });
    } else {
      this.updateSubProject(ev.data);
    }
  }

  private updateSubProject(data) {
    const index = this.projectBuilderService.model.subProjects.findIndex(project => project.id === data.id);
    if (index === -1) {
      return;
    }
    const dialogRef = this.modalService.open(SubProjectFormComponent, {
      model: {
        ...this.projectBuilderService.model.subProjects[index],
        disabled: this.projectBuilderService.disabled
      }
    }).afterClosed().subscribe((result) => {
      if (result) {
        delete result.activity.location.file;
        result.activityStartDate = result.activity.activityStartDate;
        result.estimatedScenarioGhg = result.activity.estimatedScenarioGhg;
        this.projectBuilderService.model.subProjects[index] = result;
        this.projectBuilderService.model.subProjects = [... this.projectBuilderService.model.subProjects];
        this.setTableData();
        this.updateMaster();
        dialogRef.unsubscribe();
      };
    })
  }

  private updateMaster() {
    this.projectBuilderService.model.noOfSubProjects =  this.projectBuilderService.model.subProjects.length;
    this.projectBuilderService.setAggregatedStartDate();
    this.projectBuilderService.setAggregatedEmmissionsDetails();
    this.projectBuilderService.setSubprojectPreviouslyRegistered();
    this.projectBuilderService.model = { ...this.projectBuilderService.model };
  }

  private setTableConfig() {
    this.lookupService.getTableConfigurations('SUB_PROJECT').subscribe(data => {
      if (!this.showStatus) {
        data.columns.pop();
      }
      data.columns = [
        ...data.columns,
        {
          templateRef: this.viewColTemplate,
          header: 'details',
        },
        ...(this.projectBuilderService.model.disabled || this.projectBuilderService.model.projectsInAggregationStatus === "SUBMITTED"? [] : [{
          templateRef: this.actionTemplate,
          header: 'actions',
        }])
      ];
      data.columns[3].dateTime = true;
      if (this.translateService.currentLang === 'fr') {
        data.columns[0].field = 'name2';
        data.columns[1].field = 'description2';
      }
      this.tableConfig = {
        ...data,
        paginator: false
      };

      this.setTableData();
    });
  }

  private setTableData() {
    this.tableData = this.projectBuilderService.model.subProjects.filter(subProject => !subProject.isSubMasterReceiver);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
