<p-calendar 
    (onSelect)="customCalendarDatesChanged()"
    [(ngModel)]="dateSelected"
    [showTime]="false"
    [yearNavigator]="true"
    view="date"
    yearRange="2000:2030"
    [formControl]="formControl"
    [ngStyle]="{'width': '100%'}">
</p-calendar>
       