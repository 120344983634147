import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { IDocument } from '@module/registration/registration.model';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PROJECT_QUANTIFICATION_DOCUMENT } from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';

@Component({
  selector: 'app-sub-project-documents',
  templateUrl: './sub-project-documents.component.html',
  styleUrls: ['./sub-project-documents.component.scss']
})
export class SubProjectDocumentsComponent implements OnInit, OnDestroy {

  @Input() model:any = {};
  @Input() masterProject:any = {};

  @Output() validateDocuments = new EventEmitter();

  public documentUploadConfig: IApp.IDocumentUploadConfig;
  public documentsPage: IApp.IDocumentsPage;

  private uploadedDocuments: IDocument[];
  private requiredDocumentsIds = [71, 73];

  private reqDocs: IApp.IDocumentUploadConfig[];

  private proofOfCancellationDoc: IApp.IDocumentUploadConfig;

  private riskManagementPlan: IApp.IDocumentUploadConfig;

  private requestForConfidentialityDoc: IApp.IDocumentUploadConfig;

  private projectQuantificationDoc:  IApp.IDocumentUploadConfig;

  private updatedProjectRegistrationAnnexDoc:  IApp.IDocumentUploadConfig;
  private geospatialDoc:  IApp.IDocumentUploadConfig;

  public disabled;
  private _destroy$ = new Subject<any>();

  constructor(
    public projectBuilderService: ProjectBuilderService,
    private formFactoryService: FormFactoryService,
    private store: StoreService
  ) { }

  ngOnInit() {
    this.initializeDocumentTypes();
    this.documentUploadConfig = {
      documentType: true,
      id: this.model.id,
      entityName: 'PROJECT_OFFSET',
      comment: true,
      entityDetail: '',
      statusColumn: true,
      pendingSubmissionStatus: true,
      disablePaginator: false,
      documentUploadConfigs: this.reqDocs,
      publicVisibility: true,
      extraUploadDocuments: [
      {
        documentTypeValue: 68,
        documentTypeCode: 'PROJECT_QUANTIFICATION',
        id: this.model.id,
        entityName: 'PROJECT_OFFSET',
      },
      this.geospatialDoc,
      {
        documentTypeValue: 73,
        documentTypeCode: 'UPDATED_DECLARATION_MEMBER_PARTIES',
        id: this.model.id,
        entityName: 'PROJECT_OFFSET',
      }
    ]
    };
    this.updateDocuments();
    this.validateRequiredDocs();
  }

  public updateUploadedDocuments(documents: IDocument[]) {
    this.uploadedDocuments = documents;
  }

  private updateDocuments() {

    this.addRiskManagementPlan();
    this.addRequestForConfidentialityDoc();
    this.addProjectQuantificationDoc();
    this.addUpdatedRegistrationApplicationAnnexDoc();
  }

  private updateDocumentTableView() {
    this.documentUploadConfig = { ...this.documentUploadConfig };
  }

  private addProofOfCancellationDoc(field?) {
    const validDoc = field ? field.formControl.value : this.model.activity.projectPreviouslyRegistered;
    this.updateRequiredDocs(validDoc, this.proofOfCancellationDoc);
  }

  private addRequestForConfidentialityDoc() {
    this.updateNonRequiredDocs(this.masterProject.activity.confidentialBusiness, this.requestForConfidentialityDoc);
    this.updateRequiredDocs(this.masterProject.activity.confidentialBusiness, this.requestForConfidentialityDoc);
  }

  private addUpdatedRegistrationApplicationAnnexDoc() {
    this.updateNonRequiredDocs(this.masterProject.activity.confidentialBusiness, this.updatedProjectRegistrationAnnexDoc);
    this.updateRequiredDocs(this.masterProject.activity.confidentialBusiness, this.updatedProjectRegistrationAnnexDoc);
  }

  private updateRequiredDocs(fieldValue, docType) {
    if (fieldValue) {
      if (!this.documentUploadConfig.documentUploadConfigs.some(doc => doc.documentTypeValue === docType.documentTypeValue)) {
        this.documentUploadConfig.documentUploadConfigs.push(docType);
        this.requiredDocumentsIds.push(docType.documentTypeValue);
        this.updateDocumentTableView();
      }
    } else {
      this.removeDocumentFromList(docType.documentTypeValue);
      const index = this.requiredDocumentsIds.findIndex(id => id === docType.documentTypeValue )
      if (index > -1) {
        this.requiredDocumentsIds.splice(index, 1);
      }
    }
  }

  private updateNonRequiredDocs(fieldValue, docType) {
    if (!fieldValue) {
      if (!this.documentUploadConfig.extraUploadDocuments.some(doc => doc.documentTypeValue === docType.documentTypeValue)) {
        this.documentUploadConfig.extraUploadDocuments.push(docType);
      }
    } else {
      const index = this.documentUploadConfig.extraUploadDocuments.findIndex(doc => doc.documentTypeValue === docType.documentTypeValue )
      if (index > -1) {
        this.documentUploadConfig.extraUploadDocuments.splice(index, 1);
      }
    }
  }

  private addRiskManagementPlan() {
    if (this.masterProject.activity.quantificationMethod &&
          ['HYBRID_TONNE_YEAR', 'TONNE_TONNE'].includes(this.masterProject.activity.quantificationMethod) &&
            this.masterProject.activity.sequestrationProject !== 'NOT_APPLICABLE') {
      if (!this.documentUploadConfig.documentUploadConfigs.some(doc => doc.documentTypeValue === this.riskManagementPlan.documentTypeValue)) {
        this.documentUploadConfig.documentUploadConfigs.push(this.riskManagementPlan);
        this.requiredDocumentsIds.push(this.riskManagementPlan.documentTypeValue);
        this.updateDocumentTableView();
      }
    } else {
      this.removeDocumentFromList(this.riskManagementPlan.documentTypeValue);
      const index = this.requiredDocumentsIds.findIndex(id => id === this.riskManagementPlan.documentTypeValue )
      if (index > -1) {
        this.requiredDocumentsIds.splice(index, 1);
      }
    }
  }

  private removeDocumentFromList(documentTypeValue) {
    const index = this.documentUploadConfig.documentUploadConfigs.findIndex(doc => doc.documentTypeValue === documentTypeValue );
    if (index > -1) {
      this.documentUploadConfig.documentUploadConfigs.splice(index, 1);
      this.updateDocumentTableView();
    }

  }

  private validateRequiredDocs() {
    this.projectBuilderService.validateSubProjectDocsReq$
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() =>{
          const missingDocs = this.uploadedDocuments.filter(doc => !doc.id && this.requiredDocumentsIds.includes(doc.documentType.documentTypeValue));
          if (missingDocs.length) {
            this.projectBuilderService.validateRequiredDocsRes$.next(false);
            return this.projectBuilderService.checkForRequiredDocs(missingDocs);
          } else {
            this.projectBuilderService.validateRequiredDocsRes$.next(true);
            return of({})
          }

        })
      )
      .subscribe(() => {
      });
  }

  private initializeDocumentTypes() {
    this.reqDocs = [
      {
        documentTypeValue: 71,
        documentTypeCode: 'UPDATED_PROJECT_REGISTRATION_APPLICATION',
        id: this.model.id,
        entityName: 'PROJECT_OFFSET',
      }
    ] as IApp.IDocumentUploadConfig[];

    this.proofOfCancellationDoc = {
      id: this.model.id,
      entityName: 'PROJECT_OFFSET',
      documentType: true,
      documentTypeValue: 43,
      documentTypeCode: 'PROOF_OF_CANCELLATION_IN_THE_OTHER_PROGRAM',
    } as IApp.IDocumentUploadConfig;

    this.riskManagementPlan = {
      id: this.model.id,
      entityName: 'PROJECT_OFFSET',
      documentType: true,
      documentTypeValue: 74,
      documentTypeCode: 'UPDATED_RISK_MANAGEMENT_PLAN',
    } as IApp.IDocumentUploadConfig;

    this.requestForConfidentialityDoc = {
      id: this.model.id,
      entityName: 'PROJECT_OFFSET',
      documentType: true,
      documentTypeValue: 69,
      documentTypeCode: 'REQUEST_FOR_CONFIDENTIALITY',
    } as IApp.IDocumentUploadConfig;

    this.projectQuantificationDoc =  {
      documentTypeValue: 68,
      documentTypeCode: 'PROJECT_QUANTIFICATION',
      id: this.model.id,
      entityName: 'PROJECT_OFFSET',
    } as IApp.IDocumentUploadConfig;

    this.updatedProjectRegistrationAnnexDoc =  {
      documentTypeValue: 97,
      documentTypeCode: 'UPDATED_PROJECT_REGISTRATION_APPLICATION_ANNEX',
      id: this.model.id,
      entityName: 'PROJECT_OFFSET',
    } as IApp.IDocumentUploadConfig;

    this.geospatialDoc =  {
      documentTypeValue: 96,
      documentTypeCode: 'PROJECT_OFFSET_GEOSPTIAL_FILE',
      id: this.projectBuilderService.model.id,
      entityName: 'PROJECT_OFFSET',
    } as IApp.IDocumentUploadConfig;
  }

  private addProjectQuantificationDoc() {
    this.store.getProperty(PROJECT_QUANTIFICATION_DOCUMENT).subscribe(data => {
      if (data.value === 'true') {
        this.documentUploadConfig.documentUploadConfigs.push(this.projectQuantificationDoc);
        this.requiredDocumentsIds.push(this.projectQuantificationDoc.documentTypeValue);
      } else {
        this.documentUploadConfig.extraUploadDocuments.push(this.projectQuantificationDoc);
      }
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
