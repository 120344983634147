<p-dialog
  [className]="'eccc'"
  [(visible)]="visible"
  (visibleChange)="changed($event)"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [header] = "data.header">
  <formly-form [form]="data.form" [model]="data.model" [fields]="data.fields"></formly-form>

  <p-footer *ngIf="data.closeBtn">
    <div class="d-flex justify-content-end">
      <button (click)="close()" class="btn btn-secondary" type="button">
        {{ 'COMMON.labels.close' | translate }}
      </button>
    </div>
  </p-footer>
</p-dialog>
