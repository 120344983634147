import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { ProjectDiscussionWrapperComponent } from './components/project-discussion-wrapper/project-discussion-wrapper.component';
import { ProjectDiscussionComponent } from './components/project-discussion/project-discussion.component';
import { ProjectDiscussionChatModule } from './project-discussion-chat/project-discussion-chat.module';
import { ProjectDiscussionControlsModule } from './project-discussion-controls/project-discussion-controls.module';
import { ProjectDiscussionMapModule } from './project-discussion-map/project-discussion-map.module';
import { ProjectDiscussionService } from './service/project-discussion.service';

@NgModule({
  declarations: [
    ProjectDiscussionWrapperComponent,
    ProjectDiscussionComponent,
  ],
  exports: [
    ProjectDiscussionWrapperComponent,
    ProjectDiscussionComponent,
  ],
  imports: [
    CommonModule,
    ProjectDiscussionChatModule,
    ProjectDiscussionControlsModule,
    ProjectDiscussionMapModule,
    ReactiveFormsModule,
    SharedPipesModule,
  ],
  providers: [
    ProjectDiscussionService,
  ],
})
export class ProjectDiscussionModule { }
