<div id="cfs-org-role">

    <div>
        <p-card>
            <div class="cfs-container-opt" [ngClass]="{'cfs-container-selected' : selectedCfsOpt === 'cfsOpt1'}">
                <div class="row mb-4">

                    <div class="col-lg">
                        <h3>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt1Tile1' | translate }}</h3>
                        <p [innerHTML] ="'REGISTRATION_PAGE.cfsOrgRole.primarySupplierDesc' | translate"></p>
                    </div>

                    <div class="col-md-12 col-lg-1">
                        <div class="top-line">&nbsp;</div>
                        <p class="or-divider">{{ 'REGISTRATION_PAGE.cfsOrgRole.or' | translate }}</p>
                        <div class="bottom-line">&nbsp;</div>
                    </div>

                    <div class="col-lg">
                        <h3>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt1Tile2' | translate }}</h3>

                        <p [innerHTML]="'REGISTRATION_PAGE.cfsOrgRole.opt1body2' | translate"></p>
                    </div>

                    <div class="col-md-12 col-lg-1">
                        <div class="top-line">&nbsp;</div>
                        <p class="or-divider">{{ 'REGISTRATION_PAGE.cfsOrgRole.or' | translate }}</p>
                        <div class="bottom-line">&nbsp;</div>
                    </div>

                    <div class="col-lg">
                        <h3>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt1Tile3' | translate }}</h3>

                        <p>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt1body3' | translate }}</p>
                    </div>

                  <div class="col-md-12 col-lg-1">
                    <div class="top-line">&nbsp;</div>
                    <p class="or-divider">{{ 'REGISTRATION_PAGE.cfsOrgRole.or' | translate }}</p>
                    <div class="bottom-line">&nbsp;</div>
                  </div>

                  <div class="col-lg">
                    <h3>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt1Tile5' | translate }}</h3>

                    <p>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt1body5' | translate }}</p>
                  </div>
                </div>

               <div class="row float-right">
                      <div class="col-2">
                        <button type="button" class="btn btn-secondary cfs-select-btn" (click)="onSelection('CfsOrgAccRegComponent')">{{'COMMON.actionsLabel.select' | translate}}</button>
                    </div>
                </div>
            </div>
        </p-card>
    </div>

    <div style="margin-top: 10px;">
        <p-card>
            <div class="cfs-container-opt" [ngClass]="{'cfs-container-selected' : selectedCfsOpt === 'cfsOpt2'}">
                <div class="row">

                    <div class="col-lg">
                        <h3>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt2Tile' | translate }}</h3>
                        <p [innerHTML] ="'REGISTRATION_PAGE.cfsOrgRole.opt2partA' | translate"></p>
                    </div>

                    <div class="col-2">
                        <button type="button" class="btn btn-secondary cfs-select-btn" (click)="onSelection('CfsVbAccRegComponent')">{{'COMMON.actionsLabel.select' | translate}}</button>
                    </div>

                </div>
                <!--<div class="row">
                    <div class="col align-self-end">
                        <button id="cfs-org-role-next" type="submit" class="btn btn-primary">{{ 'COMMON.actionsLabel.select' | translate }}</button>
                    </div>
                </div> -->
            </div>
        </p-card>
    </div>
<!--
    <div style="margin-top: 10px;">
        <p-card>
            <div class="container cfs-container-opt" [ngClass]="{'cfs-container-selected' : selectedCfsOpt === 'cfsOpt3'}">
                <div class="row">

                    <div class="col-lg">
                        <h3>{{ 'REGISTRATION_PAGE.cfsOrgRole.opt3Tile' | translate }}</h3>
                        <p [innerHTML] ="'REGISTRATION_PAGE.cfsOrgRole.opt3partA' | translate"></p>

                    </div>

                    <div class="col-2">
                        <button type="button" class="btn btn-secondary cfs-select-btn" (click)="onSelection('')">{{'COMMON.actionsLabel.select' | translate}}</button>
                    </div>

                </div>
                <div class="row">
                    <div class="col align-self-end">
                        <button id="cfs-org-role-next" type="submit" class="btn btn-primary">{{ 'COMMON.actionsLabel.select' | translate }}</button>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
-->
</div>
