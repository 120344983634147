import { IApp } from '@core/models/app.interfaces';
import { IUserData } from '@core/models/user.model';

export interface ILoginPayload {
  username?: string;
  password?: string;
  newPassword ?: string;
  session?: string;
  accessToken?: string;
  refreshToken?: string;
  mfaCode?: string;
  samlCode?: string;
  migrate?: boolean;
  programId?: number;
}

export interface ILoginResponse {
  accessToken ?: string;
  actualDate ?: string;
  expirationDate ?: string;
  expiresIn ?: string;
  message ?: string;
  sessionToken ?: string;
  destination ?: string;
  userData: IUserData;
  deliveryMedium?: string;
  username ?: string;
  errors ?: IApp.IError[];
  programs?:IApp.ILookup[]
}

export interface IResetPasswordPayload {
  confirmationCode ?: number;
  password ?: string;
  newPassword ?: string;
  username ?: string;
}

export interface ISessionData {
  accessToken?: string;
  actualDate?: string;
  expirationDate?: string;
  expiresIn?: string;
  message?: string;
  sessionToken?: string;
  username?: string;
  refreshToken?: string;
}

export class SessionData {
  accessToken ?: string;
  actualDate ?: string;
  expirationDate ?: string;
  expiresIn ?: string;
  sessionToken ?: string;
  refreshToken?: string;
  username ?: string;
  tcAccepted?:boolean;

  constructor(sessionData: ISessionData) {
    this.accessToken = sessionData.accessToken;
    this.actualDate = sessionData.actualDate;
    this.expirationDate = sessionData.expirationDate;
    this.expiresIn = sessionData.expiresIn;
    this.sessionToken = sessionData.sessionToken;
    this.username = sessionData.username;
    this.refreshToken = sessionData.refreshToken;
  }

}

export interface IStatus {
 deliveryMedium ?: string;
 destination ?: string;
 message ?: string;
 username ?: string;
 errors ?: IApp.IError[];
}
