/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/address-view/address-view.component.ngfactory";
import * as i2 from "../../components/address-view/address-view.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../pipes/custom-translate.pipe";
import * as i5 from "./address-view.type";
var styles_FormlyAddressViewTypeComponent = [];
var RenderType_FormlyAddressViewTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyAddressViewTypeComponent, data: {} });
export { RenderType_FormlyAddressViewTypeComponent as RenderType_FormlyAddressViewTypeComponent };
export function View_FormlyAddressViewTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-address-view", [], null, null, null, i1.View_AddressViewComponent_0, i1.RenderType_AddressViewComponent)), i0.ɵdid(1, 638976, null, 0, i2.AddressViewComponent, [i3.TranslateService], { legend: [0, "legend"], address: [1, "address"] }, null), i0.ɵpid(131072, i4.CustomTranslatePipe, [i3.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.to.legend)); var currVal_1 = _co.model[_co.to.addressKey]; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_FormlyAddressViewTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "address-view", [], null, null, null, View_FormlyAddressViewTypeComponent_0, RenderType_FormlyAddressViewTypeComponent)), i0.ɵdid(1, 49152, null, 0, i5.FormlyAddressViewTypeComponent, [], null, null)], null, null); }
var FormlyAddressViewTypeComponentNgFactory = i0.ɵccf("address-view", i5.FormlyAddressViewTypeComponent, View_FormlyAddressViewTypeComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyAddressViewTypeComponentNgFactory as FormlyAddressViewTypeComponentNgFactory };
