/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../project-discussion-map/components/project-discussion-map/project-discussion-map.component.ngfactory";
import * as i2 from "../../project-discussion-map/components/project-discussion-map/project-discussion-map.component";
import * as i3 from "@angular/common/http";
import * as i4 from "../../service/project-discussion.service";
import * as i5 from "../project-discussion-wrapper/project-discussion-wrapper.component.ngfactory";
import * as i6 from "../project-discussion-wrapper/project-discussion-wrapper.component";
import * as i7 from "../../project-discussion-controls/components/project-discussion-controls/project-discussion-controls.component.ngfactory";
import * as i8 from "../../project-discussion-controls/components/project-discussion-controls/project-discussion-controls.component";
import * as i9 from "../../../../services/modal.service";
import * as i10 from "../../project-discussion-chat/components/project-discussion-chat/project-discussion-chat.component.ngfactory";
import * as i11 from "../../project-discussion-chat/components/project-discussion-chat/project-discussion-chat.component";
import * as i12 from "@angular/common";
import * as i13 from "./project-discussion.component";
var styles_ProjectDiscussionComponent = [];
var RenderType_ProjectDiscussionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectDiscussionComponent, data: {} });
export { RenderType_ProjectDiscussionComponent as RenderType_ProjectDiscussionComponent };
function View_ProjectDiscussionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-discussion-map", [], null, null, null, i1.View_ProjectDiscussionMapComponent_0, i1.RenderType_ProjectDiscussionMapComponent)), i0.ɵdid(1, 114688, null, 0, i2.ProjectDiscussionMapComponent, [i3.HttpClient, i4.ProjectDiscussionService, i0.ChangeDetectorRef], { project: [0, "project"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.project; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProjectDiscussionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-project-discussion-wrapper", [], null, null, null, i5.View_ProjectDiscussionWrapperComponent_0, i5.RenderType_ProjectDiscussionWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i6.ProjectDiscussionWrapperComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "app-project-discussion-controls", [["class", "mb-3"]], null, null, null, i7.View_ProjectDiscussionControlsComponent_0, i7.RenderType_ProjectDiscussionControlsComponent)), i0.ɵdid(3, 573440, null, 0, i8.ProjectDiscussionControlsComponent, [i9.ModalService, i4.ProjectDiscussionService], { comments: [0, "comments"], project: [1, "project"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "app-project-discussion-chat", [["class", "mb-3"]], null, null, null, i10.View_ProjectDiscussionChatComponent_0, i10.RenderType_ProjectDiscussionChatComponent)), i0.ɵdid(5, 49152, null, 0, i11.ProjectDiscussionChatComponent, [], { comments: [0, "comments"], project: [1, "project"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ProjectDiscussionComponent_1)), i0.ɵdid(7, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comments; var currVal_1 = _co.project; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.comments; var currVal_3 = _co.project; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = !_co.hideMaps; _ck(_v, 7, 0, currVal_4); }, null); }
export function View_ProjectDiscussionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-discussion", [], null, null, null, View_ProjectDiscussionComponent_0, RenderType_ProjectDiscussionComponent)), i0.ɵdid(1, 245760, null, 0, i13.ProjectDiscussionComponent, [i4.ProjectDiscussionService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectDiscussionComponentNgFactory = i0.ɵccf("app-project-discussion", i13.ProjectDiscussionComponent, View_ProjectDiscussionComponent_Host_0, { project: "project", hideMaps: "hideMaps" }, {}, []);
export { ProjectDiscussionComponentNgFactory as ProjectDiscussionComponentNgFactory };
