<p-dialog
  [className]="'eccc'"
  [visible]="true"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '820px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header] = "title | translate">
  <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
  <div class="d-flex justify-content-end mt-2">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">
      {{ 'TASK_TO_DO.adminTakeActionModal.close' | translate }}
    </button>
    <button *ngIf="data['customActionBtn']"
            type="button" class="btn btn-secondary mr-2"
            (click)="customActionHandler()">
      {{ data['customActionBtn'] | translate }}
    </button>
    <button *ngIf="data['additionalActionBtn']"
            type="button" class="btn btn-primary mr-2" 
            (click)="additionalActionHandler()">
      {{ data['additionalActionBtn'] | translate }}
    </button>
    <button type="button" class="btn btn-primary" (click)="save()">
      {{
        (
          data['primaryActionBtn'] ? data['primaryActionBtn'] :
          'TASK_TO_DO.adminTakeActionModal.markTaskAsCompleted'
        ) | translate
      }}
    </button>
  </div>
</p-dialog>
