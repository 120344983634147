<p-dialog
  [className]="'eccc'"
  [header]="title"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '500px'}"
  [minY]="500"
  [maximizable]="false"
  [autoZIndex]="false">

  <div>
       <span [innerHTML]="message"></span>
      {{translatedMessage}}
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="closeBtn" type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
