import { commonActionsLabelTranslationPrefix } from "@core/models/filter-types.model";
import { IRawForm } from "@core/models/raw-form.types";
import { getDictSelect } from "@core/utilities/raw-form.utils";
import { SOURCE_DATA_TYPE } from "@shared/components/stepper/stepper.const";
import { ActionOptionType, ITableFilterActionModel } from "@shared/models/table-filter.models";
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from "@shared/models/table-view.model";

export const CREDIT_REVENUE_REPORT_PERIOD_FLD = 'compliancePeriod';
export const CREDIT_REVENUE_REPORT_PREFIX = 'COMPLIANCE_CREDIT_REVENUE_REPORT';
export const CREDIT_REVENUE_REPORT_DETAILS_PREFIX = 'COMPLIANCE_CREDIT_REVENUE_REPORT.reportDetails';
export const CREDIT_REVENUE_REPORT_STEP_PREFIX = 'COMPLIANCE_CREDIT_REVENUE_REPORT.reportSteps';
export const CREDIT_REVENUE_REPORT_SUMMARY_PREFIX = 'COMPLIANCE_CREDIT_REVENUE_REPORT.summaryStep';
export const CREDIT_REVENUE_REPORT_CREATE_POPUP = 'createReportPopUpLabel';
export const CREDIT_REVENUE_REPORT_TABLE_PREFIX = 'COMPLIANCE_CREDIT_REVENUE_REPORT.table';

export const CREDIT_REVENUE_REPORT_ID_KEY = 'CREDIT_REVENUE_REPORT_ID_KEY';
export const CREDIT_REVENUE_REPORT_DATA_KEY = 'CREDIT_REVENUE_REPORT_DATA_KEY';
export const CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY = 'CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY';
export const CRR_INVOLVED_IN_ACTIVITIES = 'revenueUsedForCompPeriod';

export const SCHEDULE_16_SECTION_2_TOOLTIP = 'SCHEDULE_16_SECTION_2_TOOLTIP';
export const SCHEDULE_16_SECTION_3_TOOLTIP = 'SCHEDULE_16_SECTION_3_TOOLTIP';
export const SCHEDULE_16_SECTION_4_TOOLTIP = 'SCHEDULE_16_SECTION_4_TOOLTIP';
export const SCHEDULE_16_SECTION_7_TOOLTIP = 'SCHEDULE_16_SECTION_7_TOOLTIP';
export const SCHEDULE_16_SECTION_5_TOOLTIP = 'SCHEDULE_16_SECTION_5_TOOLTIP';



export const CR_REPORT_LIST_TABLE_FILTER: ITableFilterActionModel = {
  filterListsModel: [],
  searchBox: true,
  searchButtons: false,
  dateRange: true,
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'COMPLIANCE_CREDIT_REVENUE_REPORT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'COMPLIANCE_CREDIT_REVENUE_REPORT',
        },
      ],
    },
  ],
  options: {
    dateRange: {
      placeholder: 'COMPLIANCE_REPORT.table.submittedOn',
    },
  },
};

export const CREDIT_REVENUE_REPORT_LIST_TABLE_CONFIG = {
  tableHeaderType: 'COMPLIANCE_CREDIT_REVENUE_REPORT',
  tableFilter: CR_REPORT_LIST_TABLE_FILTER,
  filtersFromConfig: true,
  hasViewTemple: true,
  hasActionTemple: true,
  adminFilters: ['facilityNames'],
};

export const CREDIT_REVENUE_STEP_COMPONENT_MAP = {
  "STEP-1": 'RevenueInfoComponent',
  "STEP-2": 'RevenueDetailsComponent',
  "STEP-3": 'CreditRevenueContactPersonComponent',
  "STEP-4": 'CreditRevenueSummaryComponent'
};

export const REVENUE_INFO_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: CREDIT_REVENUE_REPORT_TABLE_PREFIX,
  paginator: false,
  hasTotalRecord: false,
  columns: [
    {
      field: 'compliancePeriod',
      header: 'compliancePeriod',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: false,
    },
    {
      field: 'totalRevenueReceived',
      header: 'totalRevenueReceived',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
    },
    {
      field: 'totalRevenueAvailable',
      header: 'currentYearUsedRevenue',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
    },
    {
      field: 'deadlineToUse',
      header: 'deadlineToUse',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: false
    }
  ],
};

export const REVENUE_USAGE_TABLE_CONFIG: ITableViewConfig = {
  paginator: false,
  hasTotalRecord: false,
  columns: [
    {
      field: 'compliancePeriod',
      header: 'compliancePeriod',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: false,
    },
    {
      field: 'totalTransferCredits',
      header: 'totalTransferCredits',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      toolTip: {
        text: `${CREDIT_REVENUE_REPORT_TABLE_PREFIX}.${SCHEDULE_16_SECTION_2_TOOLTIP}`
      }
    },
    {
      field: 'totalRevenueReceived',
      header: 'totalRevenueReceivedStep2',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      toolTip: {
        text: `${CREDIT_REVENUE_REPORT_TABLE_PREFIX}.${SCHEDULE_16_SECTION_3_TOOLTIP}`
      }
    },
    {
      field: 'revenueUsedInSecondLastYear',
      header: 'futureYearUsedRevenue',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      toolTip: {
        text: `${CREDIT_REVENUE_REPORT_TABLE_PREFIX}.${SCHEDULE_16_SECTION_4_TOOLTIP}`
      }
    },
    {
      field: 'revenueUsedInLastYear',
      header: 'currentYearUsedRevenueStep2',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      toolTip: {
        text: `${CREDIT_REVENUE_REPORT_TABLE_PREFIX}.${SCHEDULE_16_SECTION_4_TOOLTIP}`
      }
    }

  ],
};

export const REVENUE_ADDITIONAL_TABLE_CONFIG: ITableViewConfig = {
  paginator: true,
  hasTotalRecord: false,
  rowsPerPage: 5,
  columns: [
    {
      field: 'amountOfRevUsedByRegisteredCreator',
      header: 'amountOfRevUsedByRegisteredCreator',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'amountOfRevUsedByRegisteredCreator',
      numeric: true,
      toolTip: {
        text: `${CREDIT_REVENUE_REPORT_TABLE_PREFIX}.${SCHEDULE_16_SECTION_4_TOOLTIP}`
      }
    },
    {
      field: 'activityCode',
      header: 'activityCode',
      width: AUTO_WIDTH_FOR_COLUMNS,
      sortField: 'activityCode',
      numeric: false,
      translate: true,
      translationPrefix: 'COMMON.complianceRevenueReportAcivityList',
      toolTip: {
        text: `${CREDIT_REVENUE_REPORT_TABLE_PREFIX}.${SCHEDULE_16_SECTION_4_TOOLTIP}`
      }
    },
  ],
};

export const getCreditRevenueReportPeriod = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CREDIT_REVENUE_REPORT_PERIOD_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.CREDIT_REVENUE_REPORT_COMPLIANCE_YEARS,
    label: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.${CREDIT_REVENUE_REPORT_CREATE_POPUP}`,
    onChange,
    required: true,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    skipInitialBlur: true,
    translatePrefix: CREDIT_REVENUE_REPORT_DETAILS_PREFIX,
  });
};

