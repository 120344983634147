import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { BaseAccountActionsService } from '@module/account-management/services/base-account-actions.service';
import { Observable } from 'rxjs';

export const ACCOUNT_APPROVE_ALL_LINKED_ACCOUNTS = '/account-service/account/approveAllLinkedAccounts';
export const ACCOUNT_CLOSE = '/account-service/account/close';
export const ACCOUNT_NEED_MORE_INFO = '/account-service/account/needMoreInformation';
export const ACCOUNT_PROCESS_ADDITIONAL_REVIEW = '/account-service//account/processAdditionalReview';
export const ACCOUNT_SAVE_OBPS_NON_FACILITY = '/account-service/account/saveObpsNonFacilityAccount';
export const ACCOUNT_SUBMIT_OBPS_FACILITY_ACCOUNT = '/account-service/account/submitObpsFacilityAccount';
export const ACCOUNT_SUBMIT_OBPS_NON_FACILITY_ACCOUNT = '/account-service/account/submitObpsNonFacilityAccount';
export const ACCOUNT_UNLOCK = '/account-service/account/unlock';

@Injectable({
  providedIn: 'root',
})
export class OBPSAccountActionsService extends BaseAccountActionsService {

  customConfirmations = {
    APPROVE_ALL_LINKED_ACCOUNTS: 'APPROVE_ALL_LINKED_ACCOUNTS',
  };

  constructor() {
    super();
  }

  approveAllLinkedAccountsAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_APPROVE_ALL_LINKED_ACCOUNTS }`, account);
  }

  needMoreInformationAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_NEED_MORE_INFO }`, account);
  }

  processAdditionalReviewAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_PROCESS_ADDITIONAL_REVIEW }`, account);
  }

  saveObpsFacilityAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_SAVE_OBPS_NON_FACILITY }`, account);
  }

  submitObpsFacilityAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_SUBMIT_OBPS_FACILITY_ACCOUNT }`, account);
  }

  submitObpsNonFacilityAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_SUBMIT_OBPS_NON_FACILITY_ACCOUNT }`, account);
  }

  unLockAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_UNLOCK }`, account);
  }

}
