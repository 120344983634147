
export const baseRegistrationRoute = '/register/wizard/';
export const OFFSET_ACC_MASTER_SUBMIT = '/account-service/offsetAccount/masterAccount/submit';
export const OFFSET_ACC_SUB_MASTER_SUBMIT = '/account-service/offsetAccount/subAccount/submit';
export const OFFSET_REGISTRATION = '/account-service/offsetAccount/submit';
export const CM_MASTER_ACC_SUBMIT = '/account-service/ompAccount/masterAccount/submit';
export const CM_SUB_ACC_SUBMIT = '/account-service/ompAccount/subAccount/submit';


/**************************************************************************/

export const newRegistration = 'new-registration';
export const newAccount = 'new-account';
export const newSubAccount = 'new-sub-account';
export const newArrounByMop = "new-account-mop";
export const newSubAccountByMop = 'new-sub-account-mop';
export const cmNewAccount = 'cm-new-account';
export const cmNewSubAccount = 'cm-new-sub-account';

export const accountMap = {
    'new-registration': { account: false, subAccount: false, registration: true , offset: OFFSET_REGISTRATION },
    'new-account': { account: true, subAccount: false, registration: false, offset: OFFSET_ACC_MASTER_SUBMIT },
    'new-sub-account': { account: false, subAccount: true, registration: false, offset: OFFSET_ACC_SUB_MASTER_SUBMIT, onestep: true },
    'new-account-mop': { account: true, subAccount: false, registration: false, offset: OFFSET_ACC_MASTER_SUBMIT, admin: true },
    'new-sub-account-mop': { account: false, subAccount: true, registration: false, offset: OFFSET_ACC_SUB_MASTER_SUBMIT, admin: true, onestep: true },
    'cm-new-account': { account: true, subAccount: false, registration: false, offset: CM_MASTER_ACC_SUBMIT },
    'cm-new-sub-account': { account: false, subAccount: true, registration: false, offset: CM_SUB_ACC_SUBMIT, onestep: true },
}

/**************************************************************************/

export const newRegistrationRoute = baseRegistrationRoute + newRegistration;

export const newOBPSRegistrationRoute = `${ baseRegistrationRoute}${ newRegistration }/obps`;

export const newAccountRoute = baseRegistrationRoute + newAccount;

export const newSubAccountRoute = baseRegistrationRoute + newSubAccount;

/**************************************************************************/

export const loginRoute = '/register/chooser-page';
