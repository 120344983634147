import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldDictionarySelect } from '@shared/formly/formly-custom-types/dictionary-select.type.component';
import { IApp } from '@core/models/app.interfaces';

const ALL_ID = 'All';

@Component({
  selector: 'formly-field-multi-select',
  template: `
    <input type="hidden" [id]="elementId" class="wcag-visuallyhidden">
    <ng-select
            wcag-label
            [items]="items"
            [id]="elementId + '_dropdown'"
            [placeholder]="placeholder"
            [inputAttrs]="{'aria-label': label}"
            [formControl]="formControl"
            [multiple]="multiple"
            [closeOnSelect]="false"
            [bindLabel]="to.bindLabel || 'name'"
            [bindValue]="to.bindValue || 'id'"
            [loading]="isLoading"
            [notFoundText]="notFoundText"
            (add)="onAdd($event)">
    </ng-select>
  `,
})
export class FormlyFieldMultiSelect extends FormlyFieldDictionarySelect implements OnInit, OnDestroy {

  multiple = true;

  onAdd(item) {
    if (item.id === ALL_ID) {
      if (this.model[this.field.key][0] && this.model[this.field.key][0] !== ALL_ID) {
        this.ngSelectComponent.clearModel();
        setTimeout(() => {
          const option = this.ngSelectComponent.itemsList.items.find(opt => opt.value['id'] === item.id);
          this.ngSelectComponent.select(option);
        });
      }
    } else {
      const firstItem = this.model[this.field.key][0];
      if (firstItem && firstItem === ALL_ID) {
        this.ngSelectComponent.clearModel();
        setTimeout(() => {
          const option = this.ngSelectComponent.itemsList.items.find(opt => opt.value['id'] === item.id);
          this.ngSelectComponent.select(option);
        });
      }
    }
  }

}
