import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { IRawFormBase } from '@core/models/raw-form.types';
import { LookupService } from '@core/services/lookup.service';
import { ICreditCreationReportPayload } from '@module/credit-creation-reports/credit-creation-reports.types';
import {
  CAR_COMPLIANCE_PERIOD_FLD,
  CCR_COMPLIANCE_PERIOD_FLD, CCR_QUARTER_FLD,
  CCR_TOTAL_QTY_BIOGAS,
  CCR_TYPE_FLD,
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import {
  activityTypeMap,
  getCreditAdjustmentReportCompliancePeriod,
  getCreditCreationReportActivityType,
  getCreditCreationReportCompliancePeriod,
  getCreditCreationReportQuarters,
  getCreditCreationReportType,
  getOrganizationName,
} from '@module/credit-creation-reports/routines/create-credit-popup.routine';
import { CreditCreationReportsService } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { takeUntil } from 'rxjs/operators';
import { CCR_TYPE } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import { StoreService } from '@core/store/store.service';
import { MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER } from 'app/app.const';
import { NotificationsService } from '@shared/services/notifications.service';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';

@Component({
  selector: 'app-create-new-credit-creation-report',
  templateUrl: './create-new-credit-creation-report.component.html',
  styleUrls: ['./create-new-credit-creation-report.component.scss'],
})
export class CreateNewCreditCreationReportComponent implements OnInit {

  private _destroy$ = new Subject<any>();

  creditCreationReportActivityTypes$ = new BehaviorSubject([]);

  visible = true;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  data: ICreditCreationReportPayload = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<CreateNewCreditCreationReportComponent>,
    private formFactoryService: FormFactoryService,
    private lookupService: LookupService,
    private service: CreditCreationReportsService,
    private router: Router,
    private helperService: HelperService,
    private store: StoreService,
    private notificationService: NotificationsService
  ) { }

  ngOnInit() {
    this.fields = this.formFactoryService.configureForm(this.getFormFields());
  }

  create(): void {
    if (this.data[CCR_TYPE_FLD] === CCR_TYPE.QUARTERLY && this.data[CCR_COMPLIANCE_PERIOD_FLD] === 2022) {
      this.data[CCR_QUARTER_FLD] = 'Q3/Q4';
    }
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    if (this.data[CCR_TYPE_FLD] !== CCR_TYPE.CREDIT_ADJUSTMENT && !this.store.user.hasPermission('SUBMIT_CREDIT_CREATION_REPORT')) {
      this.service.showErrorMessage('permissionToCreateReport');
    }
    else {
      if (this.form.valid) {
        if (this.data[CCR_TYPE_FLD] === CCR_TYPE.CREDIT_ADJUSTMENT) {
          this.data[CCR_COMPLIANCE_PERIOD_FLD] = this.data[CAR_COMPLIANCE_PERIOD_FLD];
        }
        this.service.getNewCreditCreationReport(this.data)
          .pipe(takeUntil(this._destroy$))
          .subscribe(resp => {
            if (this.data[CCR_TYPE_FLD] === CCR_TYPE.ANNUAL) {
              delete resp.quarterName;
            }
            this.close();
            if (this.data[CCR_TYPE_FLD] === CCR_TYPE.CREDIT_ADJUSTMENT) {
              this.router.navigate(this.helperService.getTranslatedPath('/credit-adjustment-reports/report'), { state: { data: resp } });
            } else {
              this.router.navigate(this.helperService.getTranslatedPath('/credit-creation-reports/report'), { state: { data: resp } });
            }
          });
      }
    }
  }

  private get hideOrgDropdown(): boolean {
    const isCustomer = this.store.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER');
    if (this.store.user.associationsList && this.store.user.associationsList.length === 1 && isCustomer) {
      this.data.accountId = this.store.user.associationsList[0].accountId;
      return true;
    } else {
      return false;
    }
  }

  getFormFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getOrganizationName(null, this.hideOrgDropdown),
          getCreditCreationReportType(() => this.onReportTypeChange()),
          getCreditCreationReportCompliancePeriod(),
          getCreditAdjustmentReportCompliancePeriod(),
          ...getCreditCreationReportQuarters(null, this.quarterFieldDisabled),
        ],
      }];
  }

  private get quarterFieldDisabled(): boolean {
    return this.data[CCR_TYPE_FLD] === CCR_TYPE.ANNUAL;
  }

  onReportTypeChange(): void {
    if (this.data[CCR_TYPE_FLD]) {
      this.data.activityCodes = activityTypeMap[this.data[CCR_TYPE_FLD]];
      this.lookupService.getCCRActivityType(this.data[CCR_TYPE_FLD])
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          data = data.map(el => {
            return {
              ...el,
              id: el.code
            }
          })
          this.creditCreationReportActivityTypes$.next(data);
        });
    }
  }

  close() {
    this.visible = false;
    this._destroy$.next();
    this._destroy$.complete();
    this.dialogRef.close();
  }

}
