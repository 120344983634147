import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'localizedNumber',
  pure: false,
})
export class LocalizedDecimalPipe implements PipeTransform {

  constructor(
    private translateService: TranslateService,
    private decimalPipe: DecimalPipe,
  ) {}

  transform(value: any, digitsInfo?: string, locale?: string): string | null {
    value = isNaN(value) ? 0 : +value;
    return this.decimalPipe.transform(value, digitsInfo, this.translateService.currentLang);
  }

}
