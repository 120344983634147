import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DEFAULT_DIALOG_CONFIG,
  GENERIC_EXCEPTION_MSG,
  SereviceMessageType
} from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { ILoginPayload } from '@core/models/auth.model';
import { IDisplayMessages, IMessages } from '@core/models/serviceMessage.model';
import { IUserData } from '@core/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { StoreService } from '@core/store/store.service';
import { isTodayAfterEffectiveDate } from '@core/utilities/utilities.constants';
import { FormlyFieldConfig, FormlyFormOptions} from '@ngx-formly/core';
import { LangChangeEvent , TranslateService} from '@ngx-translate/core';
import { HelperService } from "@shared/services/helper.service";
import { ModalService } from '@shared/services/modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RequiredInformationComponent } from '../required-information/required-information.component';
import { ServiceMessageComponent } from '../service-message/service-message.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {

  loginPayload: ILoginPayload = {
    username: '',
    password: '',
  };
  codeSentMessage: string;
  destination: string;
  missingTokenType: string;
  pageVisible = false;

  authErrors = [];
  id: string;

  provideNewPassword = false;
  migrationConfirmation = false;
  programConfirmation = false;
  programs: IApp.ILookup[];
  provideMfaToken  = false;
  mfaTokenForm = new FormGroup({});
  private _destroy$ = new Subject<any>();

  mfaFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'input',
          key: 'mfaCode',
          templateOptions: {
            label: 'LOGIN_PAGE.loginSection.enterOTP',
            required: true,
          },
        },
      ],
    },
  ];

  constructor(
    private router: Router,
    private store: StoreService,
    private authService: AuthService,
    private modalService: ModalService,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    ) {
      this.activatedRoute.params.subscribe(params => {
        if (params.id) {
          this.router.navigate(this.helperService.getTranslatedPath('/login'));
        }
      });

    }

  reset() {
    this.provideNewPassword = false;
    this.loginPayload = {
      username: '',
      password: '',
    };
  }

  ngOnInit() {
    // this.reset();
    this.translateService.onLangChange
    .pipe(
      takeUntil(this._destroy$),
    )
    .subscribe((event: LangChangeEvent) => {
      this.translateCode('codeSentMessageSMS');
    });

    this.activatedRoute.queryParamMap.subscribe(queryParams => {
     const idToken = queryParams.get('id_token');
     const auth_error = queryParams.get('auth_error');
     if (auth_error) {
       console.log(auth_error);
       this.showAuthErrorMessage();
     } else if (idToken) {
        this.loginPayload.samlCode = idToken;
        this.loginPayload.accessToken = this.store.getAccessKey();
        this.samlSignIn();
      } else {
       this.pageVisible = true;
     }
    });
  }

  showAuthErrorMessage() {
    this.router.navigate(this.helperService.getTranslatedPath('/register/chooser-page'));
    this.store.getProperty('email.group.programAdmin').subscribe(prop => {
        const messageList: IMessages[] = [{
          message : 'loginErrorMessage',
          metaData: [prop.value],
        }];

        const messages: IDisplayMessages = {
          messages: messageList,
          type: SereviceMessageType.ERROR,
        };

        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
        .afterClosed()
        .subscribe((result?: boolean) => {
        });

      });
  }

  translateCode(msg: string) {
    this.codeSentMessage = this.translateService.instant(`LOGIN_PAGE.forgotPasswordSection.${msg}`);
    this.codeSentMessage = this.codeSentMessage.replace('{{0}}', this.destination);
  }

  resendCode(form: NgForm) {
    form.resetForm();
    this.loginPayload.mfaCode = null;
    this.signIn(null);
  }

  migrateCredentials() {
    this.loginPayload.migrate = true;
    this.signIn(null);
  }

  choseProgram(programId: number) {
      this.loginPayload.programId = programId;
      this.signIn(null);

  }

  loginWithExistingCredentials() {
    this.authService.onSignOut(this.loginPayload.samlCode, this.store.getAccessKey());
  }

  samlSignIn() {
  this.authService.authenticateUserWithToken(this.loginPayload)
    .subscribe(result => {
       // loginForm.resetForm();
    if (result.message) {
      if (result.message  === 'SAMLFirstLoginExistingUser') {
        // password change needed
        this.pageVisible = true;
        this.provideMfaToken = true;
        this.provideNewPassword = true;
        this.migrationConfirmation = true;
        this.programConfirmation = false;
        this.missingTokenType = result.message;
      } else  if (result.message  === 'SAMLFirstUserAssociatedWithMultiplePrograms') {
        // password change needed
        this.pageVisible = true;
        this.provideMfaToken = true;
        this.provideNewPassword = true;
        this.migrationConfirmation = true;
        this.programConfirmation = true;
        this.programs = result.programs;
        this.missingTokenType = result.message;
      } else if (result.message  === 'SAMLFirstLoginTokenMissing') {
        // password change needed
        this.pageVisible = true;
        this.missingTokenType = result.message;
        this.provideMfaToken = true;
        this.migrationConfirmation = false;
        this.programConfirmation = false;
        this.provideNewPassword = true;
        this.loginPayload.session = result.sessionToken;
        this.destination = result.destination;
        this.translateCode(`codeSentMessage${result.deliveryMedium}`);
      } else {
        this.reset();
        // this.authErrors = this.authService.handleErrors(result.errors);
      }
    } else {
      this.authService.resetInactivity();
      this.authService.startCounters();
      this.authService.reloadLang(this.authService.currentLang).subscribe(() => {
        this.router.navigate(this.helperService.getTranslatedPath(''));
      });
    }

    // if(result.userData.phoneNumber === null){
     // this.modalService.open(RequiredInformationComponent, result.userData as IUserData)
    // }

    this.openTermsAndConditionsIfAvailable(result);
  },
  (err: HttpErrorResponse) => {
     if (err.error && err.error.messages && err.error.messages.length > 0) {
        const message = err.error.messages[0];

        if (message === 'CodeMismatchException' || message === 'CodeExpiredException') {

          if (this.migrationConfirmation) {
            this.loginPayload.mfaCode = null;
            this.signIn(null);
          }

          const messageList: IMessages[] = err.error.messages.map(message => ({
          message,
        }));

          const messages: IDisplayMessages = {
          messages: messageList,
          type: SereviceMessageType.ERROR,
        };

          this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
          .afterClosed()
          .subscribe((result?: boolean) => {

          });
        } else {
          const errorResponse = (message && message !== GENERIC_EXCEPTION_MSG) ? `SAML_LOGIN_ERROR|${ message }` : 'SAML_LOGIN_ERROR';
          this.authService.samlSignOut(this.loginPayload.samlCode, errorResponse, false);
     }
      } else {
         this.authService.samlSignOut(this.loginPayload.samlCode, 'SAML_LOGIN_ERROR', false);
    }
  });
  }

  signIn(loginForm: any) {
    this.authErrors = [];
    if (this.missingTokenType && this.missingTokenType.indexOf('SAML') > -1) {
        this.samlSignIn();
    } else if ((this.loginPayload.username && this.loginPayload.password) || this.loginPayload.accessToken) {
      this.authService.authenticateUser(this.loginPayload).subscribe(
        result => {
          this.authService.reloadLang(this.authService.currentLang).subscribe(
            () => {
              if (result.message) {
                if (result.message  === 'MFATokenMissing') {
                  // password change needed
                  this.provideMfaToken = true;
                  this.provideNewPassword = true;
                  this.loginPayload.session = result.sessionToken;
                  this.destination = result.destination;
                  this.translateCode('codeSentMessageSMS');
                } else  if (result.message  === 'SAMLFirstUserAssociatedWithMultiplePrograms') {
                  // password change needed
                  this.pageVisible = true;
                  this.provideMfaToken = true;
                  this.provideNewPassword = true;
                  this.migrationConfirmation = true;
                  this.programConfirmation = true;
                  this.programs = result.programs;
                  this.missingTokenType = null;
                } else {
                  this.reset();
                  // this.authErrors = this.authService.handleErrors(result.errors);
                }
              } else {
                this.authService.resetInactivity();
                this.authService.startCounters();
                this.router.navigate(this.helperService.getTranslatedPath(''));
              }
            },
          );

          // if(result.userData.phoneNumber === null){
           // this.modalService.open(RequiredInformationComponent, result.userData as IUserData)
          // }

          this.openTermsAndConditionsIfAvailable(result);

        },
        (error: HttpErrorResponse) => {
          if (error.error.messages.findIndex(err => err === 'UserMustChangePasswordException') > -1) {
              // password change needed
              this.provideNewPassword = true;
          } else {
              // this.reset();
              // this.authErrors = this.authService.handleErrors(error.error);
          }
      });
    }
  }

  openTermsAndConditionsIfAvailable(result) {
    if ( result && result.userData && result.userData.tandcContents && result.userData.tandcContents.length > 0 ) {
      this.store.setTcAccept('false');
    } else {
      this.store.setTcAccept('true');
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
