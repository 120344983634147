import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProjectDiscussionService } from '@shared/components/project-discussion/service/project-discussion.service';
import { IProjectDiscussionAddComment, IProjectDiscussionCommentContent, IProjectDiscussionReplyComment } from '@shared/components/project-discussion/service/project-discussion.service.types';
import { Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { ProjectDiscussionAddComment } from './project-discussion-add-comment.constants';
import { IProjectDiscussionAddCommentComponent } from './project-discussion-add-comment.types';

@Component({
  selector: 'app-project-discussion-add-comment',
  templateUrl: './project-discussion-add-comment.component.html',
})
export class ProjectDiscussionAddCommentComponent {
  visible = true;
  comment = '';

  constructor(
    private dialogRef: MatDialogRef<ProjectDiscussionAddCommentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IProjectDiscussionAddCommentComponent,
    private projectDiscussionSvc: ProjectDiscussionService,
  ) {
    this.comment = data.mode === ProjectDiscussionAddComment.Update && data.entry ? data.entry.comment : '';
  }

  close(result = false): void {
    this.visible = false;
    this.dialogRef.close(result);
  }

  submit(): void {
    if (this.data.mode === ProjectDiscussionAddComment.Comment) {
      this.add();
    }
    if (this.data.mode === ProjectDiscussionAddComment.Update) {
      this.update();
    }
    if (this.data.mode === ProjectDiscussionAddComment.Reply) {
      this.reply();
    }
  }

  private add(): void {
    const body: IProjectDiscussionAddComment = {
      comment: this.comment,
      entityId: this.data.project.id,
      type: 'COMMENT',
      entityType: this.data.project.entityName
    };

    this.action(this.projectDiscussionSvc.addComment$(body));
  }

  private update(): void {
    const body: IProjectDiscussionCommentContent = {
      ...this.data.entry,
      comment: this.comment,
    };

    this.action(this.projectDiscussionSvc.updateComment$(body));
  }

  private reply(): void {
    const body: IProjectDiscussionReplyComment = {
      comment: this.comment,
      entityId: this.data.project.id,
      type: 'COMMENT',
      repliedOnId: this.data.entry.id,
      entityType: this.data.project.entityName
    };

    this.action(this.projectDiscussionSvc.replyComment$(body));
  }

  private action(action$: Observable<unknown>): void {
    action$.pipe(
      take(1),
      catchError(err => {
        this.close();
        throw err;
      }),
    ).subscribe(() => this.close(true));
  }
}
