<ng-container *ngIf="to.label; else NO_LABEL">
  <div class="border-bottom mb-2" [innerHTML]="to.label | translate"></div>
  <div class="pl-4">
    <ng-container #fieldComponent></ng-container>
  </div>
</ng-container>

<ng-template #NO_LABEL>
  <div class="border-bottom mb-2">
    <div class="pl-4">
      <ng-container #fieldComponent></ng-container>
    </div>
  </div>
</ng-template>
