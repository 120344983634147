import { ITableViewColumn, ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { AbstractControl } from '@angular/forms';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';

export const PR_PREFIX = 'SUBMISSIONS.projectReportDetails';
export const PR_CHANGES_ID = 'changes';
export const PR_AGREEMENT1_ID = 'proponentHasExclusiveEntitlement';
export const PR_AGREEMENT2_ID = 'proponentHasNecessaryAuthorization';
export const PR_AGREEMENT3_ID = 'proponentIsInCompliance';
export const PR_AGREEMENT4_ID = 'proponentHaveBeenConvicted';
export const PR_AGREEMENT5_ID = 'projectCarriedInAccordance';
export const PR_AGREEMENT6_ID = 'noCreditsWereAttributed';
export const PR_AGREEMENT7_ID = 'informationIsAccurateAndComplete';
//export const PR_AGREEMENT8_ID = 'iCertify';

export const AGGREGATED_PR_AGREEMENT1_ID = 'proponentHasExclusiveEntitlementAggregated';
export const AGGREGATED_PR_AGREEMENT2_ID = 'proponentHasNecessaryAuthorizationAggregated';
export const AGGREGATED_PR_AGREEMENT3_ID = 'proponentIsInComplianceAggregated';
export const AGGREGATED_PR_AGREEMENT4_ID = 'proponentHaveBeenConvictedAggregated';
export const AGGREGATED_PR_AGREEMENT5_ID = 'projectCarriedInAccordanceAggregated';
export const AGGREGATED_PR_AGREEMENT6_ID = 'noCreditsWereAttributedAggregated';
export const AGGREGATED_PR_AGREEMENT7_ID = 'informationIsAccurateAndCompleteAggregated';
//export const AGGREGATED_PR_AGREEMENT8_ID = 'iCertifyAggregated';

export const REPORT_DATA_KEY = 'Reportdata';

export const PR_TCO2E = 'tCO2e';

export const PR_TABLE_MIN_WIDTH = '1000px';

export const PRApplyPrefix = (value: string): string => `${PR_PREFIX}.${value}`;

export const PR_YES_NO_ITEMS = [
  { id: 'yes', name: 'prYes', value: true },
  { id: 'no', name: 'prNo', value: false },
];

export const PR_OVER_UNDER_ISSUANCE = [
  { id: 'OVER_ISSUANCE', name: 'Over-issuance', value: 'OVER_ISSUANCE' },
  { id: 'UNDER_ISSUANCE', name: 'Under-issuance', value: 'UNDER_ISSUANCE' },
];

export const HEADING_CLASS = 'col-12 h5';
export const HEADING_CLASS_EXTRA_MARGIN_TOP = ' mt-4';
export const PR_REPORTING_PERIOD_START_DATE = 'reportingPeriodStartDate';
export const PR_REPORTING_PERIOD_END_DATE = 'reportingPeriodEndDate';
export const PR_CONFIDENTIAL_BUSINESS_INFO_INCLUDED = 'confidentialBusinessInfoIncluded';
export const PR_REQUESTING_CREDITS = 'requestingCredits';
export const PR_CALENDER_YEAR = 'calenderYear';
export const PR_SUB_PROJECT_IDENTIFIER = 'subProjectIdentifier';
export const PR_SUB_PROJECT_START_DATE = 'subProjectStartDate';

export const GHG_REVERSAL_CREDIT_TYPE = 'ghgCreditType';
export const GHG_REVERSAL_QUANTITY = 'ghgQuantity';
export const GHG_REVERSAL_SERIAL_NO = 'ghgSerialNo';
export const GHG_REVERSAL_VINTAGE_YEAR = 'ghgVintageYear';
export const GHG_REVERSAL_UNIT_STATUS = 'ghgUnitStatus';

export const CR_OVER_ISSUANCE_QUANTITY = 'overIssuanceQuantity';
export const PR_OVER_ISSUANCE = 'OVER_ISSUANCE';
export const PR_UNDER_ISSUANCE = 'UNDER_ISSUANCE';
export const CR_TOTAL_CREDITS_TO_BE_ISSUED = 'totalCreditsToBeIssued';
export const CR_OVER_ISSUANCE_NOT_YET_COMPENSATED_QUANTITY = 'overIssuanceQtyNotYetCompensated';
export const CR_EIA_PERCENTAGE = 'crEiaPercentage';
export const CR_EIA_QUANTITY = 'crEiaQuantity';
export const CR_TOATL_CREDITS_REMITTED = 'totalCreditsRemitted';
export const CR_OVER_ISSUANCE_COMPENSATED_QUANTITY = 'overIssuanceQtyCompensated';
export const CR_ASSIGN_COMPENSATION_OBLIGATION = 'obligation';
export const CR_COMEPNSATION_DEADLINE = 'compensationDeadline';

export const PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT = 'ghgReductionsGeneratedByTheProject';
export const PR_EIA_DEPOSIT = 'eiaDepositPercentage';
export const PR_FOREGONE_CREDITS = 'foregoneCredits';
export const PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS = 'netIncreaseOfGhgsCarriedOverFromPreviousYears';
export const PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT = 'totalNetQuantityGhgReductionsGeneratedByTheProject';
export const PR_REPORT_SUBMISSIONS = 'reportSubmissions';
export const CR_REPORT_SUBMISSIONS_DIFFERENCE = 'reportSubmissionsDifference';
export const PR_IS_TOTAL_RECORD = 'isTotalRecord';
export const CR_DETAILS_INITIAL_STATE = 'reportDetailsInitialState'; // second grid should be reportSubmissions
export const PR_CORRECTED_REPORT_SELECT_UNITS = 'selectUnits';
export const PR_CORRECTED_REPORT_REMITTANCE_GRID = 'remittanceSection';
export const NOE_CORRECTED_REPORT_SUSPENSION_GRID = 'suspensionSection';
export const PR_CORRECTED_REPORT_EIA_REMITTANCE_GRID = 'eiaRemittanceSection';
export const PR_ADMIN_REMARK = 'adminRemark';
export const PR_DISCLOSE_COMMENT = 'discloseComment';
export const PR_PARTICIPANT_RESPONSE = 'participantResponse';
export const PR_SELECT_SUB_PROJECT = 'selectSubProject';
export const PR_SELECT_SUB_PROJECT_IDS = 'subProjectIds';
export const PR_NEXT_PLANNED_REPORTING_PERIOD_START_DATE = 'nextPlannedReportingPeriodStartDate';
export const PR_NEXT_PLANNED_REPORTING_PERIOD_END_DATE = 'nextPlannedReportingPeriodEndDate';

export const PR_ADMIN_REMARKS = 'adminRemarks';
export const PR_DISCLOSE_COMMENTS = 'discloseComments';
export const PR_INTERNAL_ADMIN_REMARKS = 'internalAdminRemark';
export const PR_SELECT_UNITS_BUTTON = 'selectUnits';
export const PR_INTERNAL_PARTICIPANT_COMMENT = 'internalComment';
export const PR_SUBMISSION_DEADLINE = 'correctedReportSubmissionDeadline';
export const PR_DISPLAY_REMARKS = 'displayRemarks';
export const PR_ADMIN_FIELDS = [
  CR_ASSIGN_COMPENSATION_OBLIGATION,
  CR_COMEPNSATION_DEADLINE,
  PR_NEXT_PLANNED_REPORTING_PERIOD_START_DATE,
  PR_NEXT_PLANNED_REPORTING_PERIOD_END_DATE,
  PR_ADMIN_REMARK,
  PR_DISCLOSE_COMMENT,
  PR_INTERNAL_ADMIN_REMARKS,
];

export const PR_ADDITIONAL_INFO_FIELDS =[
  PR_REQUESTING_CREDITS,
  PR_CONFIDENTIAL_BUSINESS_INFO_INCLUDED
]
export const PR_TOTAL_QUANTITY_AGGREGATED_BY_YEAR = 'TotalQuantityAggregatedByYear';

export const PR_ADD_SUB_PROJECT_INFORMATION = 'addSubProjectInformation';
export const PR_DOWNLOAD_TEMPLATE = 'downloadTemplate';
export const PR_UPLOAD_SUB_PROJECT_INFORMATION = 'uploadSubProjectInformation';

export const UNDER_REVIEW = 'UNDER_REVIEW';
export const UNDER_REVIEW_2 = 'UNDER_REVIEW_2';
export const SUBMITTED = 'SUBMITTED';

export const ON_ADMIN_FIELDS = [
  PR_ADMIN_REMARK,
  PR_INTERNAL_ADMIN_REMARKS,
  PR_DISCLOSE_COMMENT,
  PR_INTERNAL_PARTICIPANT_COMMENT,
  PR_SELECT_UNITS_BUTTON,
];

const PR_BASE_TABLE_CONFIG: ITableViewColumn[] = [
  {
    field: PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
    header: PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
    width: AUTO_WIDTH_FOR_COLUMNS,
    numeric: true,
  },
  {
    field: PR_FOREGONE_CREDITS,
    header: PR_FOREGONE_CREDITS,
    width: AUTO_WIDTH_FOR_COLUMNS,
    numeric: true,
  },
  {
    field: PR_EIA_DEPOSIT,
    header: PR_EIA_DEPOSIT,
    width: AUTO_WIDTH_FOR_COLUMNS,
    numeric: true,
  },
  {
    field: PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS,
    header: PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS,
    width: AUTO_WIDTH_FOR_COLUMNS,
    numeric: true,
  },
  {
    field: PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
    header: PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
    width: AUTO_WIDTH_FOR_COLUMNS,
    numeric: true,
  },
];

export const PR_NORMAL_TABLE_CONFIG = (hideRemoveAction = false, hideActionsColumn = false): ITableViewConfig => {
  return {
    translationPrefix: PR_PREFIX,
    hasTotalRecord: true,
    paginator: false,
    hideRemoveAction,
    hideActionsColumn,
    columns: [
      {
        field: PR_CALENDER_YEAR,
        header: PR_CALENDER_YEAR,
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      ...PR_BASE_TABLE_CONFIG,
    ],
  };
};

export const PR_GHG_REVERSAL_TABLE_CONFIG = (hideRemoveAction = false, hideActionsColumn = false): ITableViewConfig => {
  return {
    translationPrefix: PR_PREFIX,
    hasTotalRecord: true,
    paginator: false,
    hideRemoveAction,
    hideActionsColumn,
    columns: [
      {
        field: GHG_REVERSAL_CREDIT_TYPE,
        header: GHG_REVERSAL_CREDIT_TYPE,
        width: AUTO_WIDTH_FOR_COLUMNS
      },
      {
        field: GHG_REVERSAL_QUANTITY,
        header: GHG_REVERSAL_QUANTITY,
        width: AUTO_WIDTH_FOR_COLUMNS
      },
      {
        field: GHG_REVERSAL_SERIAL_NO,
        header: GHG_REVERSAL_SERIAL_NO,
        width: AUTO_WIDTH_FOR_COLUMNS
      },
      {
        field: GHG_REVERSAL_VINTAGE_YEAR,
        header: GHG_REVERSAL_VINTAGE_YEAR,
        width: AUTO_WIDTH_FOR_COLUMNS
      },
      {
        field: GHG_REVERSAL_UNIT_STATUS,
        header: GHG_REVERSAL_UNIT_STATUS,
        width: AUTO_WIDTH_FOR_COLUMNS
      },
    ],
  };
};

export const PR_AGGREGATED_TABLE_CONFIG = (hideRemoveAction = false, hasViewColumn = false): ITableViewConfig => {
  return {
    translationPrefix: PR_PREFIX,
    paginator: false,
    hasTotalRecord: true,
    hideRemoveAction,
    hasViewColumn,
    columns: [
      {
        field: PR_SUB_PROJECT_IDENTIFIER,
        header: PR_SUB_PROJECT_IDENTIFIER,
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      {
        field: PR_SUB_PROJECT_START_DATE,
        header: PR_SUB_PROJECT_START_DATE,
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      ...PR_BASE_TABLE_CONFIG,
    ],
  };
};

export const PR_ATTESTATION_IDS = [
  PR_AGREEMENT1_ID,
  PR_AGREEMENT2_ID,
  PR_AGREEMENT3_ID,
  PR_AGREEMENT4_ID,
  PR_AGREEMENT6_ID,
  PR_AGREEMENT5_ID,
  PR_AGREEMENT7_ID
];

export const AGGREGATED_PR_ATTESTATION_IDS = [
  AGGREGATED_PR_AGREEMENT1_ID,
  AGGREGATED_PR_AGREEMENT2_ID,
  AGGREGATED_PR_AGREEMENT3_ID,
  AGGREGATED_PR_AGREEMENT4_ID,
  AGGREGATED_PR_AGREEMENT5_ID,
  AGGREGATED_PR_AGREEMENT6_ID,
  AGGREGATED_PR_AGREEMENT7_ID
];

export const PR_REMITTANCE_GRID: ITableViewConfig = {
  translationPrefix: 'UNITS_MODULE.unitList',
  paginator: false,
  hasTotalRecord: true,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: 'unitClassCode',
      header: 'creditType',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.unitClassList',
    },
    {
      field: 'quantity',
      header: 'quantity',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
    },
    {
      field: 'serialNumber',
      header: 'serialNumber',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'complianceYear',
      header: 'vintageYear',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'status',
      header: 'unitStatus',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.statusList',
    },
  ],
};

export const NOE_SUSPENSION_GRID: ITableViewConfig = {
  translationPrefix: 'UNITS_MODULE.unitList',
  paginator: false,
  hasTotalRecord: true,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: 'unitClassCode',
      header: 'creditType',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.unitClassList',
    },
    {
      field: 'quantity',
      header: 'quantity',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
    },
    {
      field: 'serialNumber',
      header: 'serialNumber',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'complianceYear',
      header: 'vintageYear',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'status',
      header: 'unitStatus',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMMON.statusList',
    },
  ],
};

export const CR_SUMMARY_TABLE_FIELDS: ITableViewColumn[] = [
  {
    field: 'complianceYear',
    header: 'complianceYear',
    sortField: 'complianceYear',
  },
  {
    field: 'quantity',
    header: 'quantity',
    sortField: 'quantity',
  },
  {
    field: 'eiaDepositPercentage',
    header: 'eiaDepositPercentage',
    sortField: 'eiaDepositPercentage',
  },
  {
    field: 'remittedQuantity',
    header: 'remittedQuantity',
    sortField: 'remittedQuantity',
  },
  {
    field: 'obligationAssigned',
    header: 'obligationAssigned',
    sortField: 'obligationAssigned',
  },
];

export const CR_NORMAL_SUMMARY_TABLE_CFG: ITableViewConfig = {
  translationPrefix: 'UNITS_MODULE.crSummaryTable',
  paginator: false,
  columns: [...CR_SUMMARY_TABLE_FIELDS],
};

export const CR_AGGREGATED_SUMMARY_TABLE_CFG: ITableViewConfig = {
  translationPrefix: 'UNITS_MODULE.crAggregatedSummaryTable',
  paginator: false,
  columns: [
    {
      field: 'subProjectId',
      header: 'subProjectId',
      sortField: 'subProjectId',
    },
    ...CR_SUMMARY_TABLE_FIELDS,
  ],
};

export const PR_TOTAL_QUANTITY_AGGREGATED_BY_YEAR_TABLE_CFG: ITableViewConfig = {
  translationPrefix: PR_PREFIX,
  paginator: false,
  columns: [
    {
      field: PR_CALENDER_YEAR,
      header: PR_CALENDER_YEAR,
    },
    {
      field: PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
      header: PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
      numeric: true,
    },
    {
      field: PR_FOREGONE_CREDITS,
      header: PR_FOREGONE_CREDITS,
      numeric: true,
    },
    {
      field: PR_EIA_DEPOSIT,
      header: PR_EIA_DEPOSIT,
      numeric: true,
    },
  ],
};

export const PR_EDITABLE_TABLE_VALIDATION_ERR = { arrayIsEmpty: true };

export const prEditableTableValidator = (control: AbstractControl) => {
  return control.value && control.value.length > 1 ? null : PR_EDITABLE_TABLE_VALIDATION_ERR;
};
