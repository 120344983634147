/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i2 from "@ngx-formly/core";
import * as i3 from "@angular/common";
import * as i4 from "./repeat-section.type";
var styles_RepeatTypeComponent = [];
var RenderType_RepeatTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RepeatTypeComponent, data: {} });
export { RenderType_RepeatTypeComponent as RenderType_RepeatTypeComponent };
function View_RepeatTypeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row item-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "formly-field", [["class", "col"]], [[4, "display", null], [8, "className", 0]], null, null, i1.View_FormlyField_0, i1.RenderType_FormlyField)), i0.ɵdid(2, 16760832, null, 0, i2.FormlyField, [i2.FormlyConfig, i0.ComponentFactoryResolver, i0.Injector, [8, null]], { field: [0, "field"], className: [1, "className"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit; var currVal_3 = "col"; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 2).field.hide ? "none" : ""); var currVal_1 = (i0.ɵnov(_v, 2).field.className ? i0.ɵnov(_v, 2).field.className : i0.ɵnov(_v, 2).className); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_RepeatTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RepeatTypeComponent_1)), i0.ɵdid(1, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldGroup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RepeatTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-repeat-section", [], null, null, null, View_RepeatTypeComponent_0, RenderType_RepeatTypeComponent)), i0.ɵdid(1, 49152, null, 0, i4.RepeatTypeComponent, [[2, i2.FORMLY_CONFIG]], null, null)], null, null); }
var RepeatTypeComponentNgFactory = i0.ɵccf("formly-repeat-section", i4.RepeatTypeComponent, View_RepeatTypeComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { RepeatTypeComponentNgFactory as RepeatTypeComponentNgFactory };
