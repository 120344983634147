import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { IRawFormBase } from '@core/models/raw-form.types';
import { StoreService } from '@core/store/store.service';
import { 
  COMPLIMENTARY_COMPLIANCE_REPORT_PERIOD_FLD,
  COMPLIMENTARY_COMPLIANCE_REPORT_TYPE
} from '@module/complimentary-compliance-report/complimentary-compliance-report.const';
import { ComplimentaryComplianceReportService } from '@module/complimentary-compliance-report/complimentary-compliance-report.service';
import { ICreateComplimentaryComplianceReportPayload } from '@module/complimentary-compliance-report/complimentary-compliance-report.types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { getOrganizationName, getComplianceReportPeriod } from '@module/complimentary-compliance-report/routines/create-complimentary-compliance-report.routine';

@Component({
  selector: 'app-create-complimentary-compliance-report',
  templateUrl: './create-complimentary-compliance-report.component.html',
  styleUrls: ['./create-complimentary-compliance-report.component.scss']
})
export class CreateComplimentaryComplianceReportComponent implements OnInit {

  private _destroy$ = new Subject<any>();

  visible = true;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  data: ICreateComplimentaryComplianceReportPayload = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<CreateComplimentaryComplianceReportComponent>,
    private formFactoryService: FormFactoryService,
    private service: ComplimentaryComplianceReportService,
    private store: StoreService,
    private router: Router,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.fields = this.formFactoryService.configureForm(this.getFormFields());
  }

  getFormFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getOrganizationName(null, this.hideOrgDropdown),
          getComplianceReportPeriod(),
        ],
      }];
  }

  private get hideOrgDropdown(): boolean {
    const isCustomer = this.store.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER');
    if (this.store.user.associationsList.length === 1 && isCustomer) {
      this.data.accountId = this.store.user.associationsList[0].accountId;
      return true;
    } else {
      return false;
    }
  }

  get payload(): ICreateComplimentaryComplianceReportPayload {
    return {
      accountId: this.hideOrgDropdown ? this.store.user.accountId : this.data.accountId,
      compliancePeriod: this.data[COMPLIMENTARY_COMPLIANCE_REPORT_PERIOD_FLD],
      complianceReportType: COMPLIMENTARY_COMPLIANCE_REPORT_TYPE
    };
  }

  create(): void {
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    if (this.form.valid) {
      this.service.createComplianceReport(this.payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(resp => {
          this.close();
          this.router.navigate(this.helperService.getTranslatedPath('/complimentary-compliance-report/report'), {state: {data: resp}});
        });
    }
  }

  close() {
    this.visible = false;
    this._destroy$.next();
    this._destroy$.complete();
    this.dialogRef.close();
  }

}
