import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { IDocument } from '@module/registration/registration.model';
import { FormFactoryService } from '@shared/services/form-factory.service';
import {  of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PROJECT_QUANTIFICATION_DOCUMENT, PROJECT_TYPES } from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';
import { EDIT_DOCUMENT_PUBLIC_VISIBILITY } from '@core/models/permission.const';
import EntityTypeEnum = IApp.NSDocument.EntityTypeEnum;
@Component({
  selector: 'app-project-documents',
  templateUrl: './project-documents.component.html',
  styleUrls: ['./project-documents.component.scss'],
})
export class ProjectDocumentsComponent implements OnInit, OnDestroy {

  @Input() entityName: EntityTypeEnum = 'PROJECT_OFFSET';
  @Input() entityId: number = this.projectBuilderService.model.id;

  public documentUploadConfig: IApp.IDocumentUploadConfig;
  public documentsPage: IApp.IDocumentsPage;

  private uploadedDocuments: IDocument[];
  private requiredDocumentsIds = [];

  private reqDocs = [] as IApp.IDocumentUploadConfig[];

  private declarationMemberPartyDoc = {
    documentTypeValue: 61,
    documentTypeCode: 'DECLARATION_MEMBER_PARTIES',
    documentType: true,
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private aggregatedProjectRegisrationApplicationDoc = {
    documentTypeValue: 70,
    documentTypeCode: 'AGGREGATED_PROJECT_REGISTRATION_APPLICATION',
    documentType: true,
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private aggregationProjectRegisrationApplicationDoc = {
    documentTypeValue: 101000,
    documentTypeCode: 'AGGREGATION_PROJECT_REGISTRATION_APPLICATION',
    documentType: true,
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private projectRegisrationApplicationDoc = {
    documentTypeValue: 62,
    documentTypeCode: 'PROJECT_REGISTRATION_APPLICATION',
    documentType: true,
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private proofOfCancellationDoc = {
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
    documentType: true,
    documentTypeValue: 43,
    documentTypeCode: 'PROOF_OF_CANCELLATION_IN_THE_OTHER_PROGRAM',
  } as IApp.IDocumentUploadConfig;

  private riskManagementPlan = {
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
    documentType: true,
    documentTypeValue: 46,
    documentTypeCode: 'RISK_MANAGEMENT_PLAN',
  } as IApp.IDocumentUploadConfig;

  private requestForConfidentialityDoc = {
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
    documentType: true,
    documentTypeValue: 69,
    documentTypeCode: 'REQUEST_FOR_CONFIDENTIALITY',
  } as IApp.IDocumentUploadConfig;

  private landownerDeclarationDoc =    {
    documentTypeValue: 65,
    documentTypeCode: 'DECLARATION_LANDOWNER',
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private projectQuantificationDoc =  {
    documentTypeValue: 68,
    documentTypeCode: 'PROJECT_QUANTIFICATION',
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private projectRegistrationAnnexDoc =  {
    documentTypeValue: 84,
    documentTypeCode: 'PROJECT_REGISTRATION_APPLICATION_ANNEX',
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private aggregatedProjectRegistrationAnnexDoc =  {
    documentTypeValue: 98,
    documentTypeCode: 'AGGREGATED_PROJECT_REGISTRATION_APPLICATION_ANNEX',
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  private geospatialDoc =  {
    documentTypeValue: 96,
    documentTypeCode: 'PROJECT_OFFSET_GEOSPTIAL_FILE',
    id: this.projectBuilderService.model.id,
    entityName: this.entityName,
  } as IApp.IDocumentUploadConfig;

  public disabled;
  private _destroy$ = new Subject<any>();

  constructor(
    public projectBuilderService: ProjectBuilderService,
    private formFactoryService: FormFactoryService,
    private store: StoreService
  ) { }

  ngOnInit() {

    this.documentUploadConfig = {
      documentType: true,
      ...this.entityConfig,
      comment: true,
      entityDetail: '',
      statusColumn: true,
      pendingSubmissionStatus: true,
      disablePaginator: false,
      documentUploadConfigs: this.reqDocs,
      publicVisibility: true,
      publicVisibilityEnabled: this.hasPublicVisibilityEnabled(),
      extraUploadDocuments: [{...this.geospatialDoc, ...this.entityConfig}],
      supportingDocumentFilter: [
        ...(this.projectBuilderService.model.associatedSubmissions ? this.projectBuilderService.model.associatedSubmissions: []),
        ...((this.projectBuilderService.model.projectsInAggregationId && this.projectBuilderService.model.projectsInAggregationStatus !== 'ACTIVE') ? [{id: this.projectBuilderService.model.projectsInAggregationId, entityName: 'PROJECTS_IN_AGGREGATION'}] : [])
      ]
    };

    this.updateDocumentConfigForReports();

    this.updateDocuments();
    this.validateRequiredDocs();
  }

  public updateUploadedDocuments(documents: IDocument[]) {
    this.uploadedDocuments = documents;
  }

  private updateDocuments() {
    this.documentUploadConfig.documentUploadConfigs = [...this.reqDocs];
    if (this.projectBuilderService.projectType === PROJECT_TYPES.AGGREGATED || this.projectBuilderService.projectType === PROJECT_TYPES.MASTER_RECEIVER) {
      if (this.projectBuilderService.projectType === PROJECT_TYPES.MASTER_RECEIVER) {
        this.documentUploadConfig.documentUploadConfigs.push({...this.aggregatedProjectRegisrationApplicationDoc, ...this.entityConfig});
        this.documentUploadConfig.extraUploadDocuments.push({...this.aggregationProjectRegisrationApplicationDoc, ...this.entityConfig});
        this.documentUploadConfig.metaData = {
          projectType: PROJECT_TYPES.MASTER_RECEIVER,
          status: this.projectBuilderService.model.status
        }
      } else {
        this.documentUploadConfig.documentUploadConfigs.push({...this.aggregatedProjectRegisrationApplicationDoc, ...this.entityConfig});
      }
      this.documentUploadConfig.extraUploadDocuments.push({...this.declarationMemberPartyDoc, ...this.entityConfig});
      this.requiredDocumentsIds.push(this.aggregatedProjectRegisrationApplicationDoc.documentTypeValue);
    } else {
      this.documentUploadConfig.documentUploadConfigs.push({...this.projectRegisrationApplicationDoc, ...this.entityConfig});
      this.requiredDocumentsIds.push(this.projectRegisrationApplicationDoc.documentTypeValue);
    }

    this.addRiskManagementPlan();
    this.addRequestForConfidentialityDoc();
    this.addProjectQuantificationDoc();
    this.addProjectRegistrationAnnexDoc();

    this.formFactoryService.event_callback
    .pipe(takeUntil(this._destroy$))
    .subscribe(field => {
     if (field.key === 'confidentialBusiness') {
        this.addRequestForConfidentialityDoc();
        this.addProjectRegistrationAnnexDoc();
     }
    });

    this.formFactoryService.RadioCheangeCallback
    .pipe(takeUntil(this._destroy$))
    .subscribe(field => {
      if (field.key === 'quantificationMethod' || field.key === 'sequestrationProject') {
        this.addRiskManagementPlan();
      }
    });
  }

  private updateDocumentTableView() {
    this.documentUploadConfig = { ...this.documentUploadConfig };
  }

  private addProofOfCancellationDoc() {
    const addDoc = (this.projectBuilderService.model.activity.projectPreviouslyRegistered ||
            this.projectBuilderService.model.subprojectPreviouslyRegistered) &&
            this.projectBuilderService.model.status === 'SUBMITTED';
    this.updateNonRequiredDocs(addDoc, this.proofOfCancellationDoc);
    this.updateRequiredDocs(addDoc, this.proofOfCancellationDoc);
  }

  private addRequestForConfidentialityDoc() {
    this.updateNonRequiredDocs(this.projectBuilderService.model.activity.confidentialBusiness, this.requestForConfidentialityDoc)
    this.updateRequiredDocs(this.projectBuilderService.model.activity.confidentialBusiness, this.requestForConfidentialityDoc);
  }

  private addProjectRegistrationAnnexDoc() {
    const doc = this.projectBuilderService.projectType === PROJECT_TYPES.AGGREGATED ? this.aggregatedProjectRegistrationAnnexDoc: this.projectRegistrationAnnexDoc;
    this.updateNonRequiredDocs(this.projectBuilderService.model.activity.confidentialBusiness, doc);
    this.updateRequiredDocs(this.projectBuilderService.model.activity.confidentialBusiness, doc);
  }

  private addLandOwnerDeclarationDoc() {
    const declarationRequired = !!(this.projectBuilderService.model.declarationLandownerRequired || this.projectBuilderService.model.activity.isLandPrivate || this.projectBuilderService.model.activity.isProponentOwner);
    this.updateNonRequiredDocs(declarationRequired, this.landownerDeclarationDoc)
    this.updateRequiredDocs(declarationRequired, this.landownerDeclarationDoc);
  }

  private updateRequiredDocs(fieldValue, docType) {
    if (fieldValue) {
      if (!this.documentUploadConfig.documentUploadConfigs.some(doc => doc.documentTypeValue === docType.documentTypeValue)) {
        this.documentUploadConfig.documentUploadConfigs.push({...docType, ...this.entityConfig});
        this.requiredDocumentsIds.push(docType.documentTypeValue);
        this.updateDocumentTableView();
      }
    } else {
      this.removeDocumentFromList(docType.documentTypeValue);
      const index = this.requiredDocumentsIds.findIndex(id => id === docType.documentTypeValue )
      if (index > -1) {
        this.requiredDocumentsIds.splice(index, 1);
      }
    }
  }

  private updateNonRequiredDocs(fieldValue, docType) {
    if (!fieldValue) {
      if (!this.documentUploadConfig.extraUploadDocuments.some(doc => doc.documentTypeValue === docType.documentTypeValue)) {
        this.documentUploadConfig.extraUploadDocuments.push({...docType, ...this.entityConfig});
      }
    } else {
      const index = this.documentUploadConfig.extraUploadDocuments.findIndex(doc => doc.documentTypeValue === docType.documentTypeValue )
      if (index > -1) {
        this.documentUploadConfig.extraUploadDocuments.splice(index, 1);
      }
    }
  }

  private addRiskManagementPlan() {
    if (this.projectBuilderService.model.activity.quantificationMethod &&
          ['HYBRID_TONNE_YEAR', 'TONNE_TONNE'].includes(this.projectBuilderService.model.activity.quantificationMethod) &&
            this.projectBuilderService.model.activity.sequestrationProject !== 'NOT_APPLICABLE') {
      if (!this.documentUploadConfig.documentUploadConfigs.some(doc => doc.documentTypeValue === this.riskManagementPlan.documentTypeValue)) {
        this.documentUploadConfig.documentUploadConfigs.push({...this.riskManagementPlan, ...this.entityConfig});
        this.requiredDocumentsIds.push(this.riskManagementPlan.documentTypeValue);
        this.updateDocumentTableView();
      }
    } else {
      this.removeDocumentFromList(this.riskManagementPlan.documentTypeValue);
      const index = this.requiredDocumentsIds.findIndex(id => id === this.riskManagementPlan.documentTypeValue )
      if (index > -1) {
        this.requiredDocumentsIds.splice(index, 1);
      }
    }
  }

  private removeDocumentFromList(documentTypeValue) {
    const index = this.documentUploadConfig.documentUploadConfigs.findIndex(doc => doc.documentTypeValue === documentTypeValue );
    if (index > -1) {
      this.documentUploadConfig.documentUploadConfigs.splice(index, 1);
      this.updateDocumentTableView();
    }

  }

  private validateRequiredDocs() {
    this.projectBuilderService.validateFormsReq$
      .pipe(
        takeUntil(this._destroy$),
        switchMap(() =>{
          const missingDocs = this.uploadedDocuments.filter(doc => !doc.id && this.requiredDocumentsIds.includes(doc.documentType.documentTypeValue));
          if (missingDocs.length) {
            this.projectBuilderService.validateRequiredDocsRes$.next(false);
            return this.projectBuilderService.checkForRequiredDocs(missingDocs);
          } else {
            this.projectBuilderService.validateRequiredDocsRes$.next(true);
            return of({})
          }

        })
      )
      .subscribe(() => {
      });
  }

  private subscribeSubProjectUpdates() {
    this.projectBuilderService.subProjectUpdate$.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      const proofOfCancellationDocExists = this.documentUploadConfig.documentUploadConfigs.some(doc => doc.documentTypeValue === this.proofOfCancellationDoc.documentTypeValue);
      if (this.projectBuilderService.model.subprojectPreviouslyRegistered && !proofOfCancellationDocExists) {
          this.documentUploadConfig.documentUploadConfigs.push(this.proofOfCancellationDoc);
          this.requiredDocumentsIds.push(this.proofOfCancellationDoc.documentTypeValue);
          this.updateDocumentTableView();
        } else if (!this.projectBuilderService.model.subprojectPreviouslyRegistered &&
           !this.projectBuilderService.model.activity.projectPreviouslyRegistered && proofOfCancellationDocExists) {
          this.removeDocumentFromList(this.proofOfCancellationDoc.documentTypeValue);
        }
    });
  }

  private addProjectQuantificationDoc() {
    this.store.getProperty(PROJECT_QUANTIFICATION_DOCUMENT).subscribe(data => {
      if (data.value === 'true') {
        this.documentUploadConfig.documentUploadConfigs.push({...this.projectQuantificationDoc, ...this.entityConfig});
        this.requiredDocumentsIds.push(this.projectQuantificationDoc.documentTypeValue);
      } else {
        this.documentUploadConfig.extraUploadDocuments.push({...this.projectQuantificationDoc, ...this.entityConfig});
      }
    });
  }

  private hasPublicVisibilityEnabled(): boolean {
    const hasPermission = this.store.user.hasPermission(EDIT_DOCUMENT_PUBLIC_VISIBILITY);
    const isProjectDisabled = this.projectBuilderService.checkForDisabledState();
    const isAdmin = this.projectBuilderService.hasApproveAction();
    return hasPermission ? ( isProjectDisabled ? isAdmin : true ) : false;
  }

  private updateDocumentConfigForReports() {
    if (this.projectBuilderService.model.submissionsId) {
      const docs = [
      'declarationMemberPartyDoc',
      'aggregatedProjectRegisrationApplicationDoc',
      'projectRegisrationApplicationDoc',
      'proofOfCancellationDoc',
      'riskManagementPlan',
      'requestForConfidentialityDoc',
      'landownerDeclarationDoc',
      'projectQuantificationDoc',
      'projectRegistrationAnnexDoc',
      'aggregatedProjectRegistrationAnnexDoc',
      'geospatialDoc'];

      this.documentUploadConfig.secondaryEntityId = this.documentUploadConfig.id;
      this.documentUploadConfig.secondaryEntityName = this.documentUploadConfig.entityName;
      this.documentUploadConfig.id = this.projectBuilderService.model.submissionsEditedProjectId;
      this.documentUploadConfig.entityName = this.projectBuilderService.model.submissionsEditedProjectEntityName;
      docs.forEach(doc => {
        this[doc].id = this.projectBuilderService.model.submissionsEditedProjectId;
        this[doc].entityName = this.projectBuilderService.model.submissionsEditedProjectEntityName;
      });
    }
  }

  private get entityConfig(): Partial<IApp.IDocumentUploadConfig> {
    return {
      entityName: this.entityName,
      id: this.entityId,
    };
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
