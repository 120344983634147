import { environment } from '@env/environment';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY, CREDIT_REVENUE_REPORT_DATA_KEY, CREDIT_REVENUE_REPORT_STEP_PREFIX } from './credit-revenue-report.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { Subject } from 'rxjs';
import { AdminTakeActionModalComponent } from '@shared/components/admin-take-action-modal/admin-take-action-modal.component';
import { COCR_REVIEW_UPDATE } from '@shared/components/stepper/stepper.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/modal.service";
import * as i3 from "../../core/store/store.service";
export const CREATE_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/getNewComplianceCreditRevReport';
export const SAVE_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/saveComplianceCreditRevenueReport';
export const SUBMIT_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/submitComplianceCreditRevenueReport';
export const RESUBMIT_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/reSubmitCCRReport';
export const PROPOSE_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/proposeReport';
export const GET_CRR_ACTIVITIES = '/cfr-service/lookup/findActivityforCCRR';
export const GET_BY_ID = '/cfr-service/complianceCreditRevReport/getReportById/';
export const SAVE_AND_NEXT_CREDIT_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/saveAndNextCreditRevenueReport';
export const DISCARD_REVENUE_REPORT = '/cfr-service/complianceCreditRevReport/discard';
export const CR_REP_DOWNLOAD_REPORT = '/cfr-service/complianceCreditRevReport/download/report/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/complianceCreditRevReport/getReportToResubmit/';
export const COMP_REV_REPORT_REVIEW = '/cfr-service/complianceCreditRevReport/review';
export const LC = 'http://localhost:8089/api';
export class CreditRevenueReportService extends BaseStepperService {
    constructor(http, modalService, store) {
        super();
        this.http = http;
        this.modalService = modalService;
        this.store = store;
        this.translationPrefix = `${CREDIT_REVENUE_REPORT_STEP_PREFIX}`;
        this.currentStepInvalid = false;
        this.validateActiveCrditBlockReq$ = new Subject();
        this.validateActivePanelReq$ = new Subject();
        this.validateActivePanelRes$ = new Subject();
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.currentData));
        sessionStorage.setItem(CREDIT_REVENUE_REPORT_DATA_KEY, data);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(CREDIT_REVENUE_REPORT_DATA_KEY);
        if (data) {
            !skipRemove && sessionStorage.removeItem(CREDIT_REVENUE_REPORT_DATA_KEY);
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    restoreLastStep() {
        const currentStep = sessionStorage.getItem(CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY);
        if (currentStep) {
            sessionStorage.removeItem(CREDIT_REVENUE_REPORT_CURRENT_STEP_KEY);
            return JSON.parse(currentStep);
        }
        else {
            return null;
        }
    }
    createCreditRevenueReport(payload) {
        return this.http.post(`${environment.apiUrl}${CREATE_CREDIT_REVENUE_REPORT}`, payload);
    }
    getReportById(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_BY_ID}${reportId}`);
    }
    executeAction(event) {
        if (event && event.action.workflowAction === 'DOWNLOAD_COMPLIANCE_CREDIT_REVENUE_REPORT') {
            this.downloadDocument(event.data.id);
        }
        else if (['NEED_MORE_INFO', 'CREDIT_SUSPENDED', 'RESOLVED'].includes(event.action.workflowAction)) {
            this._showReviewCCRR(event.action);
        }
    }
    triggerAction() { }
    saveAsDraft(payload) {
        return this.http.post(`${environment.apiUrl}${SAVE_CREDIT_REVENUE_REPORT}`, payload);
    }
    saveAndProceed(payload) {
        return this.http.post(`${environment.apiUrl}${SAVE_AND_NEXT_CREDIT_REVENUE_REPORT}`, payload);
    }
    submit(payload, action) {
        let uri = SUBMIT_CREDIT_REVENUE_REPORT;
        if (action === 'RESUBMIT') {
            uri = RESUBMIT_CREDIT_REVENUE_REPORT;
        }
        else if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
            if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
                uri = PROPOSE_CREDIT_REVENUE_REPORT;
            }
        }
        return this.http.post(`${environment.apiUrl}${uri}`, payload);
    }
    getCRRActivities() {
        return this.http.get(`${environment.apiUrl}${GET_CRR_ACTIVITIES}`);
    }
    openConfirmationModal(msg, metaDataList, yesNoButton = false) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message: msg,
            type: "WARNING" /* WARNING */,
            yesNoButton,
            metaDataList
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    getTranslatedLabel(prefix, label, compliancePeriod, otherPeriod = null) {
        let translated = this.translateService.instant(prefix + '.' + label);
        translated = translated.replace(`{{0}}`, `${compliancePeriod}`);
        if (otherPeriod) {
            translated = translated.replace(`{{1}}`, otherPeriod);
        }
        return translated;
    }
    discard(id) {
        return this.http.post(`${environment.apiUrl}${DISCARD_REVENUE_REPORT}`, { id });
    }
    downloadDocument(documentId) {
        const uri = environment.apiUrl + CR_REP_DOWNLOAD_REPORT + documentId;
        this.http.get(uri, { observe: 'response', responseType: 'blob', })
            .subscribe((response) => {
            downloadBlob(response);
        }, () => this.http.get(uri, { observe: 'response' }).subscribe());
    }
    getReportByIdToReSubmit(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID_TO_RESUBMIT}${reportId}`);
    }
    showErrorMessage(message) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                },
            ],
            message: message,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    _showReviewCCRR(action) {
        const sub$ = this.modalService.open(AdminTakeActionModalComponent, {
            model: { id: this.currentData.id, accountId: this.currentData.accountId, adminRemark: '' },
            formConfig: COCR_REVIEW_UPDATE,
            title: 'TASK_TO_DO.complianceCreditRevenueReport.title',
            primaryActionBtn: 'TASK_TO_DO.complianceCreditRevenueReport.save'
        }).afterClosed()
            .subscribe((result) => {
            if (result) {
                result.model.action = action.workflowAction;
                this.comRevReportReviewUpdate(result.model).subscribe(() => {
                    this.resetStepper();
                    this.store.back();
                });
            }
            sub$.unsubscribe();
        });
    }
    comRevReportReviewUpdate(payload) {
        return this.http.post(`${environment.apiUrl}${COMP_REV_REPORT_REVIEW}`, payload);
    }
}
CreditRevenueReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditRevenueReportService_Factory() { return new CreditRevenueReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.StoreService)); }, token: CreditRevenueReportService, providedIn: "root" });
