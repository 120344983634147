/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-history-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i7 from "@ngx-formly/core";
import * as i8 from "./account-history-view.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../../core/store/store.service";
import * as i11 from "../../services/account-management.service";
import * as i12 from "../../../users-management/services/user-management.service";
import * as i13 from "../../../registration/registration.service";
import * as i14 from "../../../../core/services/lookup.service";
import * as i15 from "../../../../shared/services/helper.service";
import * as i16 from "../../../../shared/services/navigation.service";
var styles_AccountHistoryViewComponent = [i0.styles];
var RenderType_AccountHistoryViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountHistoryViewComponent, data: {} });
export { RenderType_AccountHistoryViewComponent as RenderType_AccountHistoryViewComponent };
function View_AccountHistoryViewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "col-8 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "col-8 col-sm-6"]], null, null, null, null, null))], null, null); }
export function View_AccountHistoryViewComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "panel general-information"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "row justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "col-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountHistoryViewComponent_1)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 7, "form", [["id", "details-form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(11, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(13, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "formly-form", [], null, null, null, i6.View_FormlyForm_0, i6.RenderType_FormlyForm)), i1.ɵprd(512, null, i7.FormlyFormBuilder, i7.FormlyFormBuilder, [i7.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(16, 966656, null, 0, i7.FormlyForm, [i7.FormlyFormBuilder, i7.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i5.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"], options: [3, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.account; _ck(_v, 8, 0, currVal_1); var currVal_9 = _co.form; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.form; var currVal_11 = _co.account; var currVal_12 = _co.fields; var currVal_13 = _co.options; _ck(_v, 16, 0, currVal_10, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("ACCOUNTS_MODULE.accountSummary.generalInformationTitle")); _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 13).ngClassValid; var currVal_7 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_AccountHistoryViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-history-view", [], null, null, null, View_AccountHistoryViewComponent_0, RenderType_AccountHistoryViewComponent)), i1.ɵdid(1, 114688, null, 0, i8.AccountHistoryViewComponent, [i9.Router, i9.ActivatedRoute, i10.StoreService, i11.AccountManagementService, i4.Location, i3.TranslateService, i12.UserManagementService, i13.RegistrationService, i14.LookupService, i15.HelperService, i16.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountHistoryViewComponentNgFactory = i1.ɵccf("app-account-history-view", i8.AccountHistoryViewComponent, View_AccountHistoryViewComponent_Host_0, {}, {}, []);
export { AccountHistoryViewComponentNgFactory as AccountHistoryViewComponentNgFactory };
