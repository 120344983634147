import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faCheck} from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-project-activity-card',
  templateUrl: './project-activity-card.component.html',
  styleUrls: ['./project-activity-card.component.scss']
})
export class ProjectActivityCardComponent implements OnInit, OnDestroy {

  @Input() selected: boolean;
  @Input() cardInfo: any;
  @Output() selectionChange = new EventEmitter();

  public checkIcon = faCheck;
  public title: string;
  public description: string;

  private languageChangeSub$: Subscription;

  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.setCardInfo();
    this.languageChangeSub$ = this.translateService.onLangChange.subscribe(() => this.setCardInfo());
  }

  public selectCard() {
    if (!this.selected) {
      this.selectionChange.emit(this.cardInfo);
    }
  }

  private setCardInfo() {
    if (this.translateService.currentLang === 'en') {
      this.title = this.cardInfo.en;
      this.description = this.cardInfo.descriptionEn;
    } else {
      this.title = this.cardInfo.fr;
      this.description = this.cardInfo.descriptionFr;
    }
  }

  ngOnDestroy() {
    if (this.languageChangeSub$) {
      this.languageChangeSub$.unsubscribe();
    }
  }


}
