import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-blurb',
 template: `
   <app-blurb [message]="to.label"></app-blurb>
 `,
})
export class FormlyBlurb extends FieldType {
}
