import { IApp } from '@core/models/app.interfaces';
import { IRawForm, IRawFormDictSelect, IRawFormInput, IRawFormAppFormlyDatepicker } from '@core/models/raw-form.types';
import {
  getButton,
  getCheckbox,
  getDictSelect,
  getDocuments,
  getDummy,
  getDynamicLabel,
  getInput,
  getLabel,
  getRadio, getReadOnly,
  getTable,
  getTextArea,
  getAppFormlyDatepicker,
} from '@core/utilities/raw-form.utils';
import {
  COMP_REP_ALPHANUMERIC_CI_FLD,
  COMP_REP_ALPHANUMERIC_CI_TOOLTIP,
  COMP_REP_BLEND_FUEL_FLD,
  COMP_REP_BLEND_FUEL_TOOLTIP,
  COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_TOOLTIP,
  COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD,
  COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_TOOLTIP,
  COMP_REP_CI_ID,
  COMP_REP_CLEAR_BUTTON,
  COMP_REP_COMPLIANCE_CREDIT_ACCOUNT_FLD,
  COMP_REP_CONTRIBUTION_AMOUNT_FLD,
  COMP_REP_CONTRIBUTION_AMOUNT_TOOLTIP,
  COMP_REP_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_ID,
  COMP_REP_CREDITS_USED_TOWARD_REQUIREMENTS_TABLE_ID,
  COMP_REP_ENERGY_DENSITY_FLD,
  COMP_REP_ENTER_EXEMPTED_QUANTITY_LABEL,
  COMP_REP_ERFP_FLD,
  COMP_REP_ERFP_LABEL,
  COMP_REP_ERFP_NAMES_TOOLTIP,
  COMP_REP_ERFP_PRICE_MAPPING_FLD,
  COMP_REP_ERFP_PRICE_MAPPING_LABEL,
  COMP_REP_ERFP_PRICE_MAPPING_TOOLTIP,
  COMP_REP_EXEMPTIONS_FLD,
  COMP_REP_FACILITY_FLD,
  COMP_REP_FACILITY_ID_FLD,
  COMP_REP_FACILITY_TOOLTIP,
  COMP_REP_FUEL_BLEND_DETAILS_FLD,
  COMP_REP_FUEL_BLEND_DETAILS_TEXT_FLD,
  COMP_REP_FUEL_FLD,
  COMP_REP_FUEL_ID_FLD,
  COMP_REP_FUEL_QUANTITY_FLD,
  COMP_REP_FUEL_QUANTITY_TOOLTIP,
  COMP_REP_FUEL_TOOLTIP,
  COMP_REP_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD,
  COMP_REP_OVERRIDE_CALCULATED_VALUE_FLD,
  COMP_REP_PROVISIONAL_COMP_CREDIT_FLD,
  COMP_REP_PROVISIONAL_COMP_CREDIT_TOOLTIP,
  COMP_REP_RATIONAL_FOR_CHANGE_FLD,
  COMP_REP_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_SEARCH_BUTTON,
  COMP_REP_STEP_6_CREDITS_HIDE_EXPRESSION,
  COMP_REP_STEP_6_TABLE_TITLE,
  COMP_REP_STEP_7_TABLE_TITLE,
  COMP_REP_STEP_7_TABLE_TOOLTIP,
  COMP_REP_SUBTRACTIONS_POOL_FLD,
  COMP_REP_SUBTRACTIONS_POOL_TOOLTIP,
  COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_TOOLTIP,
  COMP_REP_TOTAL_CREDITS_TO_BE_CANCELLED_FLD,
  COMP_REP_TOTAL_EXEMPTED_QUANTITY_FLD,
  COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD,
  COMP_REP_TOTAL_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_TOTAL_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_TOTAL_SUBTRACTIONS_FROM_POOL_FLD,
  COMP_REP_TRANSPORT_METHOD_FLD,
  COMP_REP_TRANSPORT_METHOD_OTHER_FLD,
  COMP_REP_VOLUME_SUBJECT_TO_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_VOLUMETRIC_REQUIREMENT_TOOLTIP,
  COMP_REP_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD,
  COMPLIANCE_REPORT_AMOUNT_MASK,
  COMPLIANCE_REPORT_DETAILS_PREFIX,
  COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX,
  COMPLIANCE_REPORT_NUMBER_MASK,
  COMPLIANCE_REPORT_PREFIX,
  COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
  COMPLIANCE_REPORT_STEP_PREFIX,
  complianceRepApplyPrefix,
  COMP_REP_OUTSTANDING_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_RATIONAL_CHANGE_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_COMMENT_FLD,
  COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQUIREMENT_INPUT_FLD,
  COMP_REP_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDDEN_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDDEN_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDDEN_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_RATIONAL_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDDEN_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_REP_OVERRIDE_RATIONAL_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
  COMP_ERFP_DATE_OF_CONTRIBUTION,
  COMP_REP_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD,
  COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQ_DEF_INPUT_FLD,
  COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD,
} from '@module/compliance-report/compliance-report.const';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { ITableViewConfig } from '@shared/models/table-view.model';

export const getStaticText = (label: string, className: string = 'col-12', hideExpression: string = null,): IRawForm => {
  return getLabel({
    label,
    className,
    hideExpression,
  });
};

export const getComplianceReportFacility = (
  source: BehaviorSubject<any>,
  onChange?: (event) => void,
  label = COMP_REP_FACILITY_FLD,
  ignoreTranslateOptions = true,
  translatePrefix = COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
  bindValue = 'id',
  idKey = COMP_REP_FACILITY_ID_FLD,
  tooltip = COMP_REP_FACILITY_TOOLTIP,
): IRawForm => {
  return getDictSelect({
    id: idKey,
    className: 'col-6',
    source,
    label:  complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions,
    ignoreDropdownAppend: true,
    translatePrefix,
    bindValue,
    icon: {
      text: tooltip,
    },
  });
};

export const getERFPNames = (
  source: BehaviorSubject<any>,
  onChange?: (event) => void,
): IRawForm => {
  return getDictSelect({
    id: COMP_REP_ERFP_FLD,
    className: 'col-6',
    source,
    onChange,
    label:  complianceRepApplyPrefix(COMP_REP_ERFP_LABEL, COMPLIANCE_REPORT_STEP_PREFIX),
    ignoreTranslateOptions: false,
    ignoreDropdownAppend: true,
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    icon: {
      text: COMP_REP_ERFP_NAMES_TOOLTIP,
    },
  });
};

export const getFuel = (
  source: BehaviorSubject<any>,
  onChange?: (event) => void,
  disabledExpression?: () => boolean,
  label = COMP_REP_FUEL_FLD,
  required = true,
  tooltip = COMP_REP_FUEL_TOOLTIP,
): IRawForm => {
  const config = {
    id: COMP_REP_FUEL_ID_FLD,
    className: 'col-6',
    source,
    label: complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    required,
    disabledExpression,
  };
  if (tooltip) {
    config['icon'] = {
      text: tooltip,
    };
  }
  return getDictSelect(config);
};

export const getQuantityOfFuel = (
  onChange?: () => void,
  label = COMP_REP_FUEL_QUANTITY_FLD,
  tooltip: string = COMP_REP_FUEL_QUANTITY_TOOLTIP,
): IRawForm => {
  return getInput({
    id: COMP_REP_FUEL_QUANTITY_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      mask: COMPLIANCE_REPORT_NUMBER_MASK,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    label:  complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    validators: ['positiveOrZeroNumber'],
    icon: {
      text: tooltip,
    },
  });
};

export const getVolumeSubjectToReductionRequirement = (): IRawForm => {
  return getInput({
    id: COMP_REP_VOLUME_SUBJECT_TO_REDUCTION_REQUIREMENT_FLD,
    disabled: true,
    required: false,
    className: 'col-3',
    customTemplateOptions: {
      mask: COMPLIANCE_REPORT_NUMBER_MASK,
      allowNegativeNumbers: false,
    },
    label:  complianceRepApplyPrefix(COMP_REP_VOLUME_SUBJECT_TO_REDUCTION_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
  });
};

export const getSubtractionsPool = (
  onChange?: (field, event) => void,
  stepFuel?: string,
  tooltip = COMP_REP_SUBTRACTIONS_POOL_TOOLTIP,
): IRawForm[] => {
  const header2 = stepFuel ? `subtractionHeader2${stepFuel}` : 'subtractionHeader2';
  return [
    getLabel({
      label: complianceRepApplyPrefix(COMP_REP_SUBTRACTIONS_POOL_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      className: 'col-12 h4 mb-0',
      parse: true,
      icon: {
        text: tooltip
      },
    }),
    {
    type: 'key-value-pairs',
    id: COMP_REP_SUBTRACTIONS_POOL_FLD,
    onChange,
    keyValueConfig: {
      headers: ['subtractionHeader1', header2 ],
      keys: [{
        key: 'SOLD_TO_DELIVER',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'SOLD_TO_DELIVER_MARINE',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'SOLD_TO_NON_INDUSTRIAL',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'SOLD_TO_DELIVER_SPACE',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'SOLD_TO_DELIVER_ELECTRICITY',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }],
      summary: {
        label: 'subtractionFooterTitle',
        key: COMP_REP_TOTAL_SUBTRACTIONS_FROM_POOL_FLD,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      },
    },
    translatePrefix: `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_DETAILS_PREFIX }`,
  }];
};

export const getFuelTransportMethod = (source: BehaviorSubject<any>, reload$?: Observable<any>): IRawForm[] => {
  return [
    {
      type: 'multi-checkbox',
      key: `${COMP_REP_TRANSPORT_METHOD_FLD}`,
      source,
      label: complianceRepApplyPrefix(COMP_REP_TRANSPORT_METHOD_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      disabled: false,
      required: true,
      className: 'col-12',
      id: COMP_REP_TRANSPORT_METHOD_FLD,
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      translateOptions: true,
      skipSort: true,
      reload$,
    },
    getInput({
      id: COMP_REP_TRANSPORT_METHOD_OTHER_FLD,
      disabled: false,
      required: true,
      className: 'col-6',
      hideExpression: `!model.${ COMP_REP_TRANSPORT_METHOD_FLD }  || (model.${ COMP_REP_TRANSPORT_METHOD_FLD } && model.${ COMP_REP_TRANSPORT_METHOD_FLD }.indexOf(5) === -1)`,
      label:  complianceRepApplyPrefix(COMP_REP_TRANSPORT_METHOD_OTHER_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
    }),
    getDummy(),
  ];
};

export const getYesNoSelector = (key: string, label: string = null, required = true, onChange: () => void = null): IRawForm => {
  return {
    type: 'dict-select',
    key,
    className: 'col-sm-12 col-md-6',
    required,
    dataProvided: true,
    items: [
      {id: true, name: 'Y'},
      {id: false, name: 'N'},
    ],
    label: complianceRepApplyPrefix(label || key, COMPLIANCE_REPORT_STEP_PREFIX),
    placeholder: 'selectItem',
    translatePrefix: 'COMMON.yesNoList',
    onChange,
    icon: {
      text: COMP_REP_BLEND_FUEL_TOOLTIP,
    },
  };
};

export const getTextAreaField = (id: string, label: string = null, required = true): IRawForm => {
  return getTextArea({
    id,
    className: 'col-12',
    required,
    label: complianceRepApplyPrefix(label || id, COMPLIANCE_REPORT_STEP_PREFIX),
  });
};

export const getFuelBlendDetails = (
  onChange: () => void,
  documentUploadConfig: IApp.IDocumentUploadConfig,
  onDocumentsChange: (documentsState: any) => void,
): IRawForm[] => {
  return [
    getRadio({
      id: COMP_REP_FUEL_BLEND_DETAILS_FLD,
      className: 'inline col-12 mb-4',
      label: complianceRepApplyPrefix(COMP_REP_FUEL_BLEND_DETAILS_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      options: [{
        key: true,
        value: `${complianceRepApplyPrefix('textBlendDetails', COMPLIANCE_REPORT_STEP_PREFIX)}`,
      }, {
        key: false,
        value: `${complianceRepApplyPrefix('attachmentBlendDetails', COMPLIANCE_REPORT_STEP_PREFIX)}`,
      }],
      onChange,
      defaultValue: true,
      dummyInputForLabel: true,
      requiredExpression: `model.${ COMP_REP_BLEND_FUEL_FLD } === true`,
      hideExpression: `model.${ COMP_REP_BLEND_FUEL_FLD } !== true`,
    }),
    getTextArea({
      id: COMP_REP_FUEL_BLEND_DETAILS_TEXT_FLD,
      className: 'col-12',
      required: true,
      dummyLabel: true,
      requiredExpression: `model.${ COMP_REP_BLEND_FUEL_FLD } === true &&  model.${ COMP_REP_FUEL_BLEND_DETAILS_FLD } === true`,
      hideExpression: `model.${ COMP_REP_BLEND_FUEL_FLD } !== true || model.${ COMP_REP_FUEL_BLEND_DETAILS_FLD } === undefined || model.${ COMP_REP_FUEL_BLEND_DETAILS_FLD } === false`,
    }),
    getDocuments({
      documentUploadConfig,
      onDocumentsChange,
      hideExpression: `model.${ COMP_REP_BLEND_FUEL_FLD } !== true || model.${ COMP_REP_FUEL_BLEND_DETAILS_FLD } === undefined || model.${ COMP_REP_FUEL_BLEND_DETAILS_FLD } == true`,
    }),
  ];
};

export const getVolumetricSubjectToRequirement = (
  stepCode: string = null,
  onChange?: (field, event) => void,
  tooltip: string = COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_TOOLTIP + stepCode,
): IRawForm[] => {
  return [
    getInput({
      id: COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
        skipLengthValidation: true
      },
      min: 0,
      ignoreLocalFormDisabled: false,
      formDisabled: true,
      validators: ['positiveOrZeroNumber'],
      label:  complianceRepApplyPrefix(COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD + stepCode, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      icon: {
        text: tooltip,
      },
    }),
    getCheckbox({
      id: COMP_REP_OVERRIDE_CALCULATED_VALUE_FLD,
      className: 'col-6',
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDE_CALCULATED_VALUE_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      change: onChange,
      disabledExpression: `model._disabled || model.${ COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      id: COMP_REP_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        onChange,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      min: 0,
      validators: ['positiveOrZeroNumber'],
      label:  complianceRepApplyPrefix(COMP_REP_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD + stepCode, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `model.${ COMP_REP_OVERRIDE_CALCULATED_VALUE_FLD } !== true`,
      disabledExpression: 'model._disabled',
    }),
    getTextArea({
      id: COMP_REP_RATIONAL_FOR_CHANGE_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_RATIONAL_FOR_CHANGE_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `model.${ COMP_REP_OVERRIDE_CALCULATED_VALUE_FLD } !== true`,
      disabledExpression: 'model._disabled',
    }),
  ];
};

export const getFuelVolumetricRequirement = (
  fuelType: string,
  stepCode: string = null,
  tooltip: string = COMP_REP_VOLUMETRIC_REQUIREMENT_TOOLTIP + fuelType,
): IRawForm[] => {
  let overrideKey =  COMP_REP_OVERRIDE_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD;
  let overridenValueKey = COMP_REP_OVERRIDDEN_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD;
  let overrideRationalKey = COMP_REP_OVERRIDE_RATIONAL_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD;
  if (stepCode === 'STEP-2_2') {
    overrideKey =  COMP_REP_OVERRIDE_DIESEL_VOLUMETRIC_REQUIREMENT_FLD;
    overridenValueKey = COMP_REP_OVERRIDDEN_DIESEL_VOLUMETRIC_REQUIREMENT_FLD;
    overrideRationalKey = COMP_REP_OVERRIDE_RATIONAL_DIESEL_VOLUMETRIC_REQUIREMENT_FLD;
  }

  return [
     getInput({
      id: COMP_REP_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-6',
      formDisabled: true,
      validators: ['positiveOrZeroNumber'],
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
        skipLengthValidation: true
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_VOLUMETRIC_REQUIREMENT_FLD + fuelType, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      icon: {
        text: tooltip,
      },
    }),
    getCheckbox({
      id: overrideKey,
      className: 'col-6',
      label: complianceRepApplyPrefix(overrideKey, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_VOLUMETRIC_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      id: overridenValueKey,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      min: 0,
      validators: ['positiveOrZeroNumber'],
      label:  complianceRepApplyPrefix(overridenValueKey, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `model.${ overrideKey } !== true`,
      disabledExpression: 'model._disabled',
    }),
    getTextArea({
      id: overrideRationalKey,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(overrideRationalKey, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `model.${ overrideKey } !== true`,
      disabledExpression: 'model._disabled',
    }),
  ];
};

export const getEnergyDensity = (
  source: BehaviorSubject<any>,
  onChange?: () => void,
  label: string = COMP_REP_ENERGY_DENSITY_FLD,
  tooltip: string = null,
): IRawForm => {
  const fieldConfig: Partial<IRawFormDictSelect> = {
    id: COMP_REP_ENERGY_DENSITY_FLD,
    className: 'col-6',
    source,
    label: complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    onChange,
    bindValue: 'densityValue',
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    disabledExpression: 'model._disabled',
  };
  if (tooltip) {
    fieldConfig.icon = {
      text: tooltip,
    };
  }
  return getDictSelect(fieldConfig);
};

export const getReductionRequirement = (
  stepCode: string = null,
  tooltip: string = COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_TOOLTIP + stepCode,
): IRawForm[] => {
  return [
    getInput({
      id: COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_FLD,
      className: 'col-6',
      disabled: true,
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_FLD + stepCode, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      icon: {
        text: tooltip,
      },
    }),
    getCheckbox({
      id: COMP_REP_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD,
      className: 'col-6',
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      id: COMP_REP_REDUCTION_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_REDUCTION_REQUIREMENT_FLD + stepCode, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `model.${ COMP_REP_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD } !== true`,
      disabledExpression: 'model._disabled',
    }),
    getTextArea({
      id: COMP_REP_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_RATIONAL_FOR_CHANGE_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `model.${ COMP_REP_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD } !== true`,
      disabledExpression: 'model._disabled',
    }),
  ];
};

export const getTotalReductionRequirement = (): IRawForm[] => {
  return [
    getInput({
      id: COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD,
      className: 'col-6',
      disabled: true,
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: false,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      icon: {
        text: COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_TOOLTIP,
      },
    }),
    getCheckbox({
      id: COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD,
      className: 'col-6',
      label: complianceRepApplyPrefix(COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      id: COMP_REP_TOTAL_REDUCTION_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_TOTAL_REDUCTION_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `model.${ COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD } !== true`,
      disabledExpression: 'model._disabled',
    }),
    getTextArea({
      id: COMP_REP_TOTAL_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_TOTAL_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `model.${ COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD } !== true`,
      disabledExpression: 'model._disabled',
    }),
  ];
};

export const getGasolineExemptedQuantities = (onChange?: (field, event) => void): IRawForm[] => {
  return [
    getLabel({
      label: complianceRepApplyPrefix(COMP_REP_ENTER_EXEMPTED_QUANTITY_LABEL, COMPLIANCE_REPORT_STEP_PREFIX),
      className: 'col-12',
      parse: true,
    }),
    {
    type: 'key-value-pairs',
    id: COMP_REP_EXEMPTIONS_FLD,
    onChange,
    keyValueConfig: {
      headers: ['conditionExemptedQuantity', 'quantityOfFuel'],
      keys: [{
        key: 'AVIATION_GASOLINE',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'EXPORTED_FROM_CANADA',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'USED_FOR_RESEARCH',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      },{
        key: 'SOLD_TO_DELIVER_ENGINE',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      },],
      summary: {
        label: 'gasolineExemptionFooterTitle',
        key: COMP_REP_TOTAL_EXEMPTED_QUANTITY_FLD,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      },
    },
    translatePrefix: `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_DETAILS_PREFIX }`,
  }];
};
export const getDieselExemptedQuantities = (onChange?: (field, event) => void): IRawForm[] => {
  return [
    getLabel({
      label: complianceRepApplyPrefix(COMP_REP_ENTER_EXEMPTED_QUANTITY_LABEL, COMPLIANCE_REPORT_STEP_PREFIX),
      className: 'col-12',
      parse: true,
    }),
    {
    type: 'key-value-pairs',
    id: COMP_REP_EXEMPTIONS_FLD,
    onChange,
    keyValueConfig: {
      headers: ['conditionExemptedQuantity', 'quantityOfFuel'],
      keys: [{
        key: 'EXPORTED_FROM_CANADA',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'USED_FOR_RESEARCH',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }, {
        key: 'SOLD_TO_DELIVER_ENGINE',
        type: 'number',
        required: true,
        allowNegativeNumbers: false,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      }],
      summary: {
        label: 'dieselExemptionFooterTitle',
        key: COMP_REP_TOTAL_EXEMPTED_QUANTITY_FLD,
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
      },
    },
    translatePrefix: `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_DETAILS_PREFIX }`,
  }];
};

export const getContributionAmount = (
  onChange?: () => void,
  label = COMP_REP_CONTRIBUTION_AMOUNT_FLD,
  tooltip: string = COMP_REP_CONTRIBUTION_AMOUNT_TOOLTIP,
): IRawForm => {
  return getInput({
    id: COMP_REP_CONTRIBUTION_AMOUNT_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      mask: COMPLIANCE_REPORT_AMOUNT_MASK,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    required: true,
    label:  complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    icon: {
      text: tooltip,
    },
  });
};

export const getContributedCredits = (
  onChange?: () => void,
  label = COMP_REP_PROVISIONAL_COMP_CREDIT_FLD,
  tooltip: string = COMP_REP_PROVISIONAL_COMP_CREDIT_TOOLTIP,
): IRawForm => {
  return getInput({
    id: COMP_REP_PROVISIONAL_COMP_CREDIT_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      mask: COMPLIANCE_REPORT_AMOUNT_MASK,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    ignoreLocalFormDisabled: false,
    formDisabled: true,
    label:  complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    icon: {
      text: tooltip
    },
  });
};

export const getERFPPriceMapping = (
  onChange?: () => void,
  label = COMP_REP_ERFP_PRICE_MAPPING_LABEL,
  tooltip: string = COMP_REP_ERFP_PRICE_MAPPING_TOOLTIP,
): IRawForm => {
  return getInput({
    id: COMP_REP_ERFP_PRICE_MAPPING_FLD,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      mask: COMPLIANCE_REPORT_AMOUNT_MASK,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    ignoreLocalFormDisabled: false,
    formDisabled: true,
    label:  complianceRepApplyPrefix(label, COMPLIANCE_REPORT_STEP_PREFIX),
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    icon: {
      text: tooltip,
    },
  });
};

export const getTypeOfCredits = (): IRawForm => {
  return getReadOnly({
    id: COMP_REP_COMPLIANCE_CREDIT_ACCOUNT_FLD,
    disabled: true,
    translate: true,
    className: 'col-6',
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_DETAILS_PREFIX,
    label:  complianceRepApplyPrefix(COMP_REP_COMPLIANCE_CREDIT_ACCOUNT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
  });
};

export const getInputField = (
  idKey,
  disabled = false,
  tooltip: string = null,
  onChange?: () => void,
  hideExpression: string = null,
  disabledExpression: string = null,
): IRawForm => {
  const fieldConfig: Partial<IRawFormInput> = {
    id: idKey,
    required: true,
    className: 'col-6',
    customTemplateOptions: {
      onChange,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    label:  complianceRepApplyPrefix(idKey, COMPLIANCE_REPORT_STEP_PREFIX),
    formDisabled: disabled,
    validators: ['positiveOrZeroNumber'],
    hideExpression,
    disabledExpression,
  };
  if (tooltip) {
    fieldConfig.icon = {
      text: tooltip,
    };
  }
  return getInput(fieldConfig);
};

export const getComplianceCreditsTowardRequirementsBlocksTable = (
  config: ITableViewConfig,
  updateData: Subject<IApp.IPageable> = null,
  selections: any[] = null,
  onRowSelected: (data: any) => void = null,
  onPaginationChanged: (event: any) => void = null,
  requestSelected: Subject<any> = null,
  footerTemplate: TemplateRef<any> = null,
  disabled: boolean = false,
): IRawForm[] => {
  return [
    getLabel({
      label: COMP_REP_STEP_7_TABLE_TITLE,
      className: 'col-12',
      icon: {
        text: COMP_REP_STEP_7_TABLE_TOOLTIP,
      },
    }),
    getTable({
      id: COMP_REP_CREDITS_USED_TOWARD_REQUIREMENTS_TABLE_ID,
      className: 'col-12 mb-4 table-h-500',
      customTemplateOptions: {
        config,
        updateData,
        footerTemplate,
        onRowSelected,
        onPaginationChanged,
        requestSelected,
        selections,
        disabled,
      },
    }),
  ];
};

export const getComplianceCreditsBlocksToBeCancelledTable = (
  config: ITableViewConfig,
  updateData: Subject<IApp.IPageable> = null,
  selections: any[] = null,
  onRowSelected: (data: any) => void = null,
  onPaginationChanged: (event: any) => void = null,
  requestSelected: Subject<any> = null,
  footerTemplate: TemplateRef<any> = null,
  disabled: boolean = false,
): IRawForm[] => {
  return [
    getLabel({
      label: COMP_REP_STEP_6_TABLE_TITLE,
      className: 'col-12 h5',
      hideExpression: COMP_REP_STEP_6_CREDITS_HIDE_EXPRESSION,
    }),
    getTable({
      id: COMP_REP_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_ID,
      className: 'col-12 mb-4 table-h-500',
      customTemplateOptions: {
        config,
        updateData,
        footerTemplate,
        onRowSelected,
        onPaginationChanged,
        requestSelected,
        selections,
        disabled,
      },
      hideExpression: COMP_REP_STEP_6_CREDITS_HIDE_EXPRESSION,
    }),
  ];
};

export const getAlphanumericIDofCI = (source: BehaviorSubject<any>, onChange?: (event) => void, required = true): IRawForm => {
  return getDictSelect({
    id: COMP_REP_ALPHANUMERIC_CI_FLD,
    className: 'col-6',
    source,
    label: complianceRepApplyPrefix(COMP_REP_ALPHANUMERIC_CI_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    bindValue: 'name',
    required,
    icon: {
      text: COMP_REP_ALPHANUMERIC_CI_TOOLTIP,
    },
  });
};

export const getCISearchFields = (hideExpression: () => boolean, searchCallback: () => void, clearCallback: ()=> void ): IRawForm[] => {
  return [
    getInput({
      id: COMP_REP_CI_ID,
      className: 'col-6',
      hideExpression,
      label: complianceRepApplyPrefix(COMP_REP_CI_ID, COMPLIANCE_REPORT_STEP_PREFIX),
      placeholder: complianceRepApplyPrefix(COMP_REP_CI_ID, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
    }),
    getButton({
      id: COMP_REP_SEARCH_BUTTON,
      label: complianceRepApplyPrefix(COMP_REP_SEARCH_BUTTON, COMPLIANCE_REPORT_STEP_PREFIX),
      className: 'col-0 mx-3',
      hideExpression,
      customTemplateOptions: {
        onClick: searchCallback
      },
    }),
    getButton({
      id: COMP_REP_CLEAR_BUTTON,
      label: complianceRepApplyPrefix(COMP_REP_CLEAR_BUTTON, COMPLIANCE_REPORT_STEP_PREFIX),
      className: 'col-0',
      hideExpression,
      customTemplateOptions: {
        onClick: clearCallback,
      },
    }),
  ];
};

export const getCreditDynamicLabels = (): IRawForm[] => {
  return [
    getLabel({
      label: `${ COMPLIANCE_REPORT_PREFIX }.${COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX}applyCreditsTowardsDeficit`,
      className: 'col-12 height-2rem mb-2',
    }),
    getDynamicLabel({
      label: `${ COMPLIANCE_REPORT_PREFIX }.${COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX}creditToBeCanceled`,
      className: 'col-12',
      hideExpression:"model.reviewMode"
    }),
    getDynamicLabel({
      label: `${ COMPLIANCE_REPORT_PREFIX }.${COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX}appliedTowardsCancellation`,
      className: 'col-12',
      hideExpression:"model.reviewMode"
    }),
    getDynamicLabel({
      label: `${ COMPLIANCE_REPORT_PREFIX }.${COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX}needsToBeFullfilled`,
      className: 'col-12',
      hideExpression:"model.reviewMode"
    }),
  ];
};


export const getComplianceInsufficientCreditsToBeCancelledTable = (
  config: ITableViewConfig,
  updateData: Subject<IApp.IPageable> = null,
  selections: any[] = null,
  onRowSelected: (data: any) => void = null,
  onPaginationChanged: (event: any) => void = null,
  requestSelected: Subject<any> = null,
  footerTemplate: TemplateRef<any> = null,
  isCARCancelExcessForm: boolean = false,
): IRawForm[] => {
  return [
    getLabel({
      label: isCARCancelExcessForm? 'CREDIT_ADJUSTMENT_REPORT.cancelExcessCredits.creditsToBeCancelled': `${ COMPLIANCE_REPORT_PREFIX }.${COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX}creditBlocksForFulfillingAppliedTowardsDeficit`,
      className: 'col-12 h5',
    }),
    getTable({
      id: `creditBlocksForFulfillingAppliedTowardsDeficit`,
      className: 'col-12 mb-4 table-h-500',
      customTemplateOptions: {
        config,
        updateData,
        footerTemplate,
        onRowSelected,
        onPaginationChanged,
        requestSelected,
        selections,
      },
    }),
  ];
};

export const getTotalSelectedCreditsForCancellation = () => {
  return getInput({
    id: 'totalAvailableCredits',
    disabled: true,
    required: false,
    className: 'col-3',
    customTemplateOptions: {
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    hideExpression: "model.reviewMode",
    label: `${ COMPLIANCE_REPORT_PREFIX }.${COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX}totalSelectedCreditsForCancellation`,
  });
}

export const getOverrideOutstandingdReductionRequirements = (onChange?: (event) => void): IRawForm[] => {
  return [
    getInputField(COMP_REP_OUTSTANDING_REDUCTION_REQUIREMENT_FLD, true, null, null),
    getCheckbox({
      id: COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD,
      className: 'col-6',
      change: onChange,
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      id: COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQUIREMENT_INPUT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      disabled: true,
      label:  complianceRepApplyPrefix(COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQUIREMENT_INPUT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `!model.${ COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD }`,
    }),
    getTextArea({
      id: COMP_REP_RATIONAL_CHANGE_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_COMMENT_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_RATIONAL_CHANGE_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_COMMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `!model.${ COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD }`,
      disabledExpression: 'model._disabled',
    }),
  ];
};

export const getOverrideOutstandingdVolumetricRequirements = (): IRawForm[] => {
  return [
    getInput({
      id: COMP_REP_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      disabled: true
    }),
    getCheckbox({
      id: COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-6',
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      id: COMP_REP_OVERRIDDEN_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_OVERRIDDEN_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `!model.${ COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD }`,
      disabledExpression: 'model._disabled',
    }),
    getTextArea({
      id: COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `!model.${ COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD }`,
      disabledExpression: 'model._disabled',
    }),
    getInput({
      id: COMP_REP_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      label:  complianceRepApplyPrefix(COMP_REP_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      disabled: true
    }),
    getCheckbox({
      id: COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-6',
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD } === undefined`,
    }),
    getInput({
      className: 'col-6',
      id: COMP_REP_OVERRIDDEN_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      label:  complianceRepApplyPrefix(COMP_REP_OVERRIDDEN_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `!model.${ COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD }`,
      disabledExpression: 'model._disabled',
    }),
    getTextArea({
      id: COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `!model.${ COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD }`,
      disabledExpression: 'model._disabled',
    }),
  ];
};

export const getERFPDateOfContribution = (complianceYear, onChange: () => void): IRawFormAppFormlyDatepicker => {
  const value = complianceRepApplyPrefix(COMP_ERFP_DATE_OF_CONTRIBUTION, COMPLIANCE_REPORT_STEP_PREFIX);
  return getAppFormlyDatepicker({
    id: COMP_ERFP_DATE_OF_CONTRIBUTION,
    label: value,
    onChange,
    placeholder: value,
    required: true,
  });
};

export const getOverrideOutstandingdReductionRequirementsForDeferral = (onChange?: (event) => void): IRawForm[] => {
  return [
    getInputField(COMP_REP_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD, true, null, null),
    getCheckbox({
      id: COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD,
      className: 'col-6',
      change: onChange,
      label: complianceRepApplyPrefix(COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      required: false,
      disabledExpression: `model._disabled || model.${ COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD } === undefined`,
    }),
    getInput({
      id: COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQ_DEF_INPUT_FLD,
      className: 'col-6',
      customTemplateOptions: {
        mask: COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK,
        type: 'formatted-number',
        allowNegativeNumbers: true,
      },
      min: 0,
      disabled: true,
      label:  complianceRepApplyPrefix(COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQ_DEF_INPUT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      translatePrefix: COMPLIANCE_REPORT_PREFIX + COMPLIANCE_REPORT_STEP_PREFIX,
      hideExpression: `!model.${ COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD }`,
    }),
    getTextArea({
      id: COMP_REP_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD,
      className: 'col-12',
      required: true,
      label: complianceRepApplyPrefix(COMP_REP_RATIONAL_CHANGE_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_COMMENT_FLD, COMPLIANCE_REPORT_STEP_PREFIX),
      hideExpression: `!model.${ COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD }`,
      disabledExpression: 'model._disabled',
    }),
  ];
};
