/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../pipes/custom-translate.pipe";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i4 from "@ngx-formly/core";
import * as i5 from "@angular/common";
import * as i6 from "./form-array.type";
var styles_FormlyFormArrayTypeComponent = [];
var RenderType_FormlyFormArrayTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFormArrayTypeComponent, data: {} });
export { RenderType_FormlyFormArrayTypeComponent as RenderType_FormlyFormArrayTypeComponent };
function View_FormlyFormArrayTypeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], [[1, "class", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.buttonClass; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.to.disabled; _ck(_v, 2, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.to.removeText)); _ck(_v, 3, 0, currVal_2); }); }
function View_FormlyFormArrayTypeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [], [[1, "class", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "formly-field", [], [[4, "display", null], [8, "className", 0]], null, null, i3.View_FormlyField_0, i3.RenderType_FormlyField)), i0.ɵdid(3, 16760832, null, 0, i4.FormlyField, [i4.FormlyConfig, i0.ComponentFactoryResolver, i0.Injector, [8, null]], { field: [0, "field"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyFormArrayTypeComponent_2)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.context.$implicit; _ck(_v, 3, 0, currVal_3); var currVal_4 = _co.to.removeButtons; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.formRowClass; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i0.ɵnov(_v, 3).field.hide ? "none" : ""); var currVal_2 = (i0.ɵnov(_v, 3).field.className ? i0.ɵnov(_v, 3).field.className : i0.ɵnov(_v, 3).className); _ck(_v, 2, 0, currVal_1, currVal_2); }); }
export function View_FormlyFormArrayTypeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyFormArrayTypeComponent_1)), i0.ɵdid(1, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.fieldGroup; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormlyFormArrayTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-form-array", [], null, null, null, View_FormlyFormArrayTypeComponent_0, RenderType_FormlyFormArrayTypeComponent)), i0.ɵdid(1, 49152, null, 0, i6.FormlyFormArrayTypeComponent, [[2, i4.FORMLY_CONFIG]], null, null)], null, null); }
var FormlyFormArrayTypeComponentNgFactory = i0.ɵccf("formly-form-array", i6.FormlyFormArrayTypeComponent, View_FormlyFormArrayTypeComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFormArrayTypeComponentNgFactory as FormlyFormArrayTypeComponentNgFactory };
