/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vb-proof-accreditation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i4 from "@ngx-formly/core";
import * as i5 from "../../../../shared/components/documents/documents.component.ngfactory";
import * as i6 from "../../../../shared/components/documents/documents.component";
import * as i7 from "../../../../shared/services/modal.service";
import * as i8 from "../../../../shared/services/upload-document.service";
import * as i9 from "../../../../core/store/store.service";
import * as i10 from "../../../../shared/services/document-in-progress.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "./vb-proof-accreditation.component";
import * as i13 from "../../services/cfr-registration.service";
import * as i14 from "../../../../shared/services/form-factory.service";
import * as i15 from "@angular/router";
import * as i16 from "../../../../shared/services/helper.service";
var styles_VbProofAccreditationComponent = [i0.styles];
var RenderType_VbProofAccreditationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VbProofAccreditationComponent, data: {} });
export { RenderType_VbProofAccreditationComponent as RenderType_VbProofAccreditationComponent };
export function View_VbProofAccreditationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "formly-form", [], null, null, null, i3.View_FormlyForm_0, i3.RenderType_FormlyForm)), i1.ɵprd(512, null, i4.FormlyFormBuilder, i4.FormlyFormBuilder, [i4.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(7, 966656, null, 0, i4.FormlyForm, [i4.FormlyFormBuilder, i4.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i2.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-documents", [], null, [[null, "documentResult"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("documentResult" === en)) {
        var pd_1 = (_co.updateDocumentDetails($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DocumentsComponent_0, i5.RenderType_DocumentsComponent)), i1.ɵdid(9, 770048, null, 0, i6.DocumentsComponent, [i7.ModalService, i8.UploadDocumentService, i9.StoreService, i10.DocumentUploadInProgressService, i11.TranslateService], { documentsPage: [0, "documentsPage"], documentUploadConfig: [1, "documentUploadConfig"], disabled: [2, "disabled"], showPlaceholders: [3, "showPlaceholders"] }, { documentResult: "documentResult" })], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.data; var currVal_10 = _co.fields; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.documentsPage; var currVal_12 = _co.documentUploadConfig; var currVal_13 = (_co.disabled && !_co.adminActionAllowed); var currVal_14 = true; _ck(_v, 9, 0, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_VbProofAccreditationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vb-proof-accreditation", [], null, null, null, View_VbProofAccreditationComponent_0, RenderType_VbProofAccreditationComponent)), i1.ɵdid(1, 245760, null, 0, i12.VbProofAccreditationComponent, [i13.CfrRegistrationService, i14.FormFactoryService, i11.TranslateService, i15.Router, i16.HelperService, i11.TranslateService, i9.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VbProofAccreditationComponentNgFactory = i1.ɵccf("app-vb-proof-accreditation", i12.VbProofAccreditationComponent, View_VbProofAccreditationComponent_Host_0, { data: "data", parentComponent: "parentComponent" }, { updateProvince: "updateProvince" }, []);
export { VbProofAccreditationComponentNgFactory as VbProofAccreditationComponentNgFactory };
