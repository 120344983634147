import { take } from 'rxjs/operators';

export function validateStep() {
  // tslint:disable-next-line:only-arrow-functions
  return function(target: any, key: any, descriptor: any) {
    const oldFunc = descriptor.value;
    descriptor.value = function(this, ...args) {
      if (this._svc) {
        this._svc.validateData()
          .pipe(
            take(1),
          ).subscribe(isValid => {
          if (isValid) {
            return oldFunc.apply(this, args);
          }
        });
      } else {
        return oldFunc.apply(this, args);
      }
    };
    return descriptor;
  };
}
