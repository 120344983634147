import { Component } from "@angular/core";
import { FieldArrayType } from "@ngx-formly/core";

@Component({
  selector: "formly-add-facility",
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="row p-2">
      <formly-field class="col" [field]="field"></formly-field>
    </div>
  `
})
export class AddFacilityTypeComponent extends FieldArrayType {}
