import { OnDestroy, OnInit } from '@angular/core';
import { FormlyFieldDictionarySelect } from '@shared/formly/formly-custom-types/dictionary-select.type.component';
const ALL_ID = 'All';
export class FormlyFieldMultiSelect extends FormlyFieldDictionarySelect {
    constructor() {
        super(...arguments);
        this.multiple = true;
    }
    onAdd(item) {
        if (item.id === ALL_ID) {
            if (this.model[this.field.key][0] && this.model[this.field.key][0] !== ALL_ID) {
                this.ngSelectComponent.clearModel();
                setTimeout(() => {
                    const option = this.ngSelectComponent.itemsList.items.find(opt => opt.value['id'] === item.id);
                    this.ngSelectComponent.select(option);
                });
            }
        }
        else {
            const firstItem = this.model[this.field.key][0];
            if (firstItem && firstItem === ALL_ID) {
                this.ngSelectComponent.clearModel();
                setTimeout(() => {
                    const option = this.ngSelectComponent.itemsList.items.find(opt => opt.value['id'] === item.id);
                    this.ngSelectComponent.select(option);
                });
            }
        }
    }
}
