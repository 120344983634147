/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-document.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i4 from "@ngx-formly/core";
import * as i5 from "../../pipes/custom-translate.pipe";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i8 from "primeng/dialog";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i11 from "primeng/api";
import * as i12 from "./upload-document.component";
import * as i13 from "@angular/material/dialog";
import * as i14 from "../../../core/services/lookup.service";
import * as i15 from "../../services/upload-document.service";
import * as i16 from "../../services/modal.service";
import * as i17 from "../../../core/store/store.service";
import * as i18 from "../../services/form-factory.service";
var styles_UploadDocumentComponent = [i0.styles];
var RenderType_UploadDocumentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadDocumentComponent, data: {} });
export { RenderType_UploadDocumentComponent as RenderType_UploadDocumentComponent };
function View_UploadDocumentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "formly-form", [], null, null, null, i3.View_FormlyForm_0, i3.RenderType_FormlyForm)), i1.ɵprd(512, null, i4.FormlyFormBuilder, i4.FormlyFormBuilder, [i4.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(8, 966656, null, 0, i4.FormlyForm, [i4.FormlyFormBuilder, i4.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i2.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.form; var currVal_9 = _co.documentProps; var currVal_10 = _co.fields; _ck(_v, 8, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_UploadDocumentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [["for", "exampleFormControlTextarea1"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "textarea", [["class", "form-control rounded-0"], ["id", "exampleFormControlTextarea1"], ["rows", "10"]], [[8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.documentCommentsEntered($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("COMMON.actionsLabel.COMMENT")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.errorMessage; _ck(_v, 5, 0, currVal_1); }); }
export function View_UploadDocumentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { fileInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 37, "p-dialog", [["id", "upload-popup"]], [[8, "className", 0]], [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.openUploadPopup = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_Dialog_0, i7.RenderType_Dialog)), i1.ɵdid(2, 180224, null, 2, i8.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 21, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 15, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "label", [["for", "input-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 10, "div", [["class", "input-group input-file"], ["name", "Fichier1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 7, "span", [["class", "input-group-btn"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-primary btn-upload"], ["id", "select-document"]], [[8, "disabled", 0]], [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectFiles($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.selectFiles($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "label", [["class", "wcag-visuallyhidden"], ["for", "file"]], null, null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, [[1, 0], ["fileInput", 1]], null, 0, "input", [["class", "hidden"], ["data-cy", "file-input"], ["id", "file"], ["tabindex", "-1"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.addFile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 1, "input", [["class", "form-control"], ["id", "input-file"], ["readonly", ""], ["tabindex", "-1"], ["type", "text"]], [[8, "placeholder", 0], [8, "value", 0], [8, "disabled", 0]], null, null, null, null)), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDocumentComponent_1)), i1.ɵdid(26, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadDocumentComponent_2)), i1.ɵdid(28, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(29, 0, null, 2, 9, "p-footer", [], null, null, null, i10.View_Footer_0, i10.RenderType_Footer)), i1.ɵdid(30, 49152, [[3, 4]], 0, i11.Footer, [], null, null), (_l()(), i1.ɵeld(31, 0, null, 0, 7, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 6, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["id", "UPLOAD_DOCUMENT_closeBtn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(34, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(36, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "submit-upload-document"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(37, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform("COMMON.actionsLabel.uploadDocument")); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = _co.openUploadPopup; var currVal_9 = _ck(_v, 6, 0, "500px"); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_17 = _co.data.documentType; _ck(_v, 26, 0, currVal_17); var currVal_18 = _co.data.comment; _ck(_v, 28, 0, currVal_18); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "eccc"; _ck(_v, 1, 0, currVal_0); var currVal_10 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("COMMON.actionsLabel.document")); _ck(_v, 11, 0, currVal_10); var currVal_11 = _co.errorMessage; _ck(_v, 16, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("COMMON.actionsLabel.BROWSE")); _ck(_v, 17, 0, currVal_12); var currVal_13 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("COMMON.actionsLabel.chooseAFfile")); _ck(_v, 20, 0, currVal_13); var currVal_14 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("COMMON.actionsLabel.chooseAFfile")); var currVal_15 = i1.ɵinlineInterpolate(1, "", ((_co.file == null) ? null : _co.file.name), ""); var currVal_16 = _co.errorMessage; _ck(_v, 23, 0, currVal_14, currVal_15, currVal_16); var currVal_19 = _co.errorMessage; _ck(_v, 33, 0, currVal_19); var currVal_20 = i1.ɵunv(_v, 34, 0, i1.ɵnov(_v, 35).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 34, 0, currVal_20); var currVal_21 = _co.errorMessage; _ck(_v, 36, 0, currVal_21); var currVal_22 = i1.ɵunv(_v, 37, 0, i1.ɵnov(_v, 38).transform("COMMON.actionsLabel.upload")); _ck(_v, 37, 0, currVal_22); }); }
export function View_UploadDocumentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-document", [], null, null, null, View_UploadDocumentComponent_0, RenderType_UploadDocumentComponent)), i1.ɵdid(1, 49152, null, 0, i12.UploadDocumentComponent, [i13.MAT_DIALOG_DATA, i13.MatDialogRef, i14.LookupService, i15.UploadDocumentService, i6.TranslateService, i16.ModalService, i17.StoreService, i18.FormFactoryService], null, null)], null, null); }
var UploadDocumentComponentNgFactory = i1.ɵccf("app-upload-document", i12.UploadDocumentComponent, View_UploadDocumentComponent_Host_0, {}, {}, []);
export { UploadDocumentComponentNgFactory as UploadDocumentComponentNgFactory };
