import { IApp } from '@core/models/app.interfaces';
import IPageable = IApp.IPageable;
import { MENU_ITEMS_PATH_MAPPING } from '@core/constants/menu-items-mapping.const';
import { PERMISSION_DISCLOSE_COMMENTS } from '../../app.const';

export interface IUserData {
  needMoreInformation?: string;
  accessToken?: string;
  api?: boolean;
  authAction?: NSUserDto.AuthActionEnum;
  email?: string;
  fullName?: string;
  id?: number;
  invalidLoginDate?: number;
  loginSuccess?: number;
  menuItems ?: IApp.IMenuItem[];
  passwordUpdatedOn?: number;
  permissions?: Array<string>;
  reportTypes?:Array<string>;
  phoneNumber?: string;
  businessTelephone?: string;
  prefferedLanguage?: string;
  roleName?: string;
  status?: string;
  tandcContents?: any[];
  userName?: string;
  validLoginDate?: number;
  validLoginDateStr?:string;
  isNonObpsAccount?:boolean;
  title?:string;
  userCreateAllowed?: boolean;
  programId: number;
  program : string;
  standardIds? : number[];
  associationsList?: IApp.ILinkedAccount[];
  programs: IApp.ILookup[];
  civicAddress?: any;
  postalAddress?:any;
  accountSubTypes?: string;
}
export namespace NSUserDto {
  export type AuthActionEnum = 'LOGIN' | 'FIST_TIME_LOGIN' | 'PASSWORD_RESET' | 'CONFIRM_PASSWORD_RESET';
  export const AuthActionEnum = {
      LOGIN: 'LOGIN' as AuthActionEnum,
      FISTTIMELOGIN: 'FIST_TIME_LOGIN' as AuthActionEnum,
      PASSWORDRESET: 'PASSWORD_RESET' as AuthActionEnum,
      CONFIRMPASSWORDRESET: 'CONFIRM_PASSWORD_RESET' as AuthActionEnum
  };
}

export interface IUsersPage extends IPageable {
  content ?: IApp.ISaveUser[];
}

export class CurrentUser {
  needMoreInformation?: string;
  tandcContents: any;
  id: number;
  programId: number;
  program : string;
  standardIds? : number[];
  accessToken ?: string;
  email ?: string;
  fullName ?: string;
  invalidLoginDate ?: number;
  loginSuccess ?: number;
  passwordUpdatedOn ?: number;
  prefferedLanguage ?: string;
  status ?: string;
  userName ?: string;
  validLoginDate ?: number;
  validLoginDateStr?:string;
  permissions?: string[];
  reportTypes?:string[];
  menuItems ?: IApp.IMenuItem[];
  isNonObpsAccount?:boolean;
  phoneNumber?:string;
  businessTelephone?:string;
  title?:string;
  roleName?:string;
  userCreateAllowed?: boolean;
  associationsList?: IApp.ILinkedAccount[];
  programs: IApp.ILookup[];
  civicAddress?: any;
  postalAddress?:any;
  accountSubTypes?: string;

  constructor(userData: IUserData) {
    //console.log(userData);
    if (userData) {
      this.id = userData.id;
      this.accessToken = userData.accessToken;
      this.email = userData.email;
      this.fullName = userData.fullName;
      this.invalidLoginDate = userData.invalidLoginDate;
      this.loginSuccess = userData.loginSuccess;
      this.passwordUpdatedOn = userData.passwordUpdatedOn;
      this.prefferedLanguage = userData.prefferedLanguage;
      this.status = userData.status;
      this.userName = userData.userName;
      this.validLoginDate = userData.validLoginDate;
      this.validLoginDateStr = userData.validLoginDateStr
      this.permissions = userData.permissions;
      this.reportTypes = userData.reportTypes;
      this.isNonObpsAccount = userData.isNonObpsAccount;
      this.tandcContents = userData.tandcContents;
      this.phoneNumber = userData.phoneNumber;
      this.businessTelephone = userData.businessTelephone;
      this.title = userData.title;
      this.roleName = userData.roleName;
      this.userCreateAllowed = userData.userCreateAllowed;
      this.needMoreInformation = userData.needMoreInformation;
      this.associationsList = userData. associationsList;
      this.program = userData.program;
      this.programId = userData.programId;
      this.standardIds = userData.standardIds;
      this.programs = userData.programs;
      this.postalAddress = userData.postalAddress;
      this.civicAddress = userData.civicAddress;
      this.accountSubTypes = userData.accountSubTypes;
      this.menuItems = userData.menuItems && userData.menuItems
        .map(m => ({
          ...m,
          path: this.getPathFromName(m.name),
          translatedName : this.gettranslatedFromName(m.name),
          urlMappings: MENU_ITEMS_PATH_MAPPING[m.name] || null,
        }))
        .sort(m => m.sortOrder);
    }
  }
  private gettranslatedFromName(name: string): string {
    if(name === 'MENU_REGISTRATION_PENDING'){
      return `${this.program}_${name}`;
    }
    return name;
  }

  private getPathFromName(name: string): string {
    let decoratedName = name;
    if(name === 'MENU_REGISTRATION_PENDING_USER'){
      decoratedName = 'MENU_REGISTRATION_PENDING';
    }
    const parts = decoratedName.split('_');
    let output = `${ parts.slice(1, 3).join('-').toLowerCase() }`;
    if (parts.length > 3) {
      output = `${ output }/${ parts.slice(3).join('-').toLowerCase() }`;
    }
    return output;
  }

  public hasRole(role: string): boolean {
    return this.associationsList && this.associationsList.findIndex(m => m.role === role) > -1;
  }

  public hasPermission(permission:string) {
    const hasPermission =  this.permissions && this.permissions.findIndex(m => m === permission) > -1;
    if(!hasPermission){
      return this.hasReportPermission(permission);
    }
    return hasPermission;
  }

  public hasReportPermission(permission:string) {
    return this.reportTypes && this.reportTypes.findIndex(m => m === permission) > -1;
    //&& this.permissions.findIndex(m => m === permission) > -1;
  }


  public hasPermissionForAccount(permission:string, accountId: number, masterAccountId: number = null) {
    if(accountId){
      const index = this.associationsList.findIndex(m => m.accountId === accountId);
      if(index > -1){
        const permissions = this.associationsList[index].permissions;
        const hasPermission =  permissions && permissions.findIndex(m => m === permission) > -1;
        if (!hasPermission && masterAccountId){
          return this.hasPermissionForAccount(permission, masterAccountId);
         } else {
           return hasPermission;
         }
      }
    }
    return this.hasPermission(permission);
  }

  public hasPermissionForBothAccount(permission:string, accountId: number, masterAccountId: number = null) {
    if (accountId) {
      const index = this.associationsList.findIndex(m => m.accountId === accountId);
      if (index > -1) {
        const permissions = this.associationsList[index].permissions;
        const hasPermission =  permissions && permissions.findIndex(m => m === permission) > -1;
        if (masterAccountId) {
          return this.hasPermissionToSubAccount(masterAccountId, permission);
        } else {
          return hasPermission;
        }
      } else if (masterAccountId) {
        return this.hasPermissionToSubAccount(masterAccountId, permission);
      }
    }
    return this.hasPermission(permission);
  }

  private hasPermissionToSubAccount(masterAccountId: number, permission: any) {
    const idx = this.associationsList.findIndex(m => m.accountId === masterAccountId);
    if (idx > -1) {
      return this.hasPermissionForAccount(permission, masterAccountId);
    } else {
      return false;
    }
  }

  public hasOneOfThePermission(permissions:string[]) {
    let hasPermission = false;
    for(let i = 0; i < permissions.length; i++){
     hasPermission =  this.permissions && this.permissions.findIndex(m => m === permissions[i]) > -1;
      if(hasPermission){
        break;
      }
    }
    return hasPermission;
  }

  public checkIfTransactionProposer(accountId: number){
    //console.log('accountId' + accountId)
    const permName = 'PROPOSE_TRANSACTION';
    return this.hasPermissionForAccount(permName, accountId);
  }

  public getServiceClass(serviceName : string){
    let serviceClass = `${this.program}${serviceName}`;
    return serviceClass.charAt(0).toLowerCase() + serviceClass.slice(1);
  }

  public getProgramPrefix() {
    if(this.program === 'OBPS'){
      return '';
    }
    return this.program;
 }

  public isCFR() {
    return this.program === 'CFR';
  }

  public isOffset() {
    return this.program === 'OFFSET';
  }

 public isOBPS() {
    return this.program === 'OBPS';
  }

  public isCM() {
    return this.program === 'CM';
  }

  public checkIfVerificationBody(){
    const index = this.associationsList.findIndex(o => o.accountDto && o.accountDto.accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB);
    return index > -1;
  }

  public get isRC(): boolean {
    return this.accountSubTypes ? this.accountSubTypes.split(',').includes(IApp.NSAccountSubType.StatusEnum.RC) : true;
  }

  public checkIfCFRNonAdmin(){
    return this.program === 'CFR' && !this.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN');
  }

  public get isAdmin() {
    return this.hasPermission(PERMISSION_DISCLOSE_COMMENTS);
  }

  public get accountId(): number {
    return this.associationsList[0] && this.associationsList[0].accountId;
  }

  public getCfrAccountType(account: any){
    const accountType =  account.accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB ?
    'assignedVerificationBody' :  'cfrParticipantAccount';
    return `VERIFICATION_MANAGEMENT_MODULE.reportList.${accountType}`;
  }
}
