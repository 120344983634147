/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./complimentary-compliance-report-additional-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i4 from "@ngx-formly/core";
import * as i5 from "../../../../shared/components/documents/documents.component.ngfactory";
import * as i6 from "../../../../shared/components/documents/documents.component";
import * as i7 from "../../../../shared/services/modal.service";
import * as i8 from "../../../../shared/services/upload-document.service";
import * as i9 from "../../../../core/store/store.service";
import * as i10 from "../../../../shared/services/document-in-progress.service";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i13 from "primeng/dialog";
import * as i14 from "../../../../shared/pipes/custom-translate.pipe";
import * as i15 from "@angular/common";
import * as i16 from "./complimentary-compliance-report-additional-details.component";
import * as i17 from "@angular/material/dialog";
var styles_ComplimentaryComplianceReportAdditionalDetailsComponent = [i0.styles];
var RenderType_ComplimentaryComplianceReportAdditionalDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ComplimentaryComplianceReportAdditionalDetailsComponent, data: {} });
export { RenderType_ComplimentaryComplianceReportAdditionalDetailsComponent as RenderType_ComplimentaryComplianceReportAdditionalDetailsComponent };
function View_ComplimentaryComplianceReportAdditionalDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "formly-form", [], null, null, null, i3.View_FormlyForm_0, i3.RenderType_FormlyForm)), i1.ɵprd(512, null, i4.FormlyFormBuilder, i4.FormlyFormBuilder, [i4.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(7, 966656, null, 0, i4.FormlyForm, [i4.FormlyFormBuilder, i4.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i2.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.currentStepObj.additionalDetailsForm; _ck(_v, 2, 0, currVal_7); var currVal_8 = _co.currentStepObj.additionalDetailsForm; var currVal_9 = _co.currentStepObj.additionalDetailsModel; var currVal_10 = _co.currentStepObj.additionalDetailsFields; _ck(_v, 7, 0, currVal_8, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_ComplimentaryComplianceReportAdditionalDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "form-group mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-documents", [], null, [[null, "documentResult"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("documentResult" === en)) {
        var pd_1 = (_co.documentUploadHandler($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_DocumentsComponent_0, i5.RenderType_DocumentsComponent)), i1.ɵdid(2, 770048, null, 0, i6.DocumentsComponent, [i7.ModalService, i8.UploadDocumentService, i9.StoreService, i10.DocumentUploadInProgressService, i11.TranslateService], { documentUploadConfig: [0, "documentUploadConfig"], disabled: [1, "disabled"], showPlaceholders: [2, "showPlaceholders"] }, { documentResult: "documentResult" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentStepObj.detailSectionDocumentUploadConfig; var currVal_1 = true; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ComplimentaryComplianceReportAdditionalDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { dialog: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "p-dialog", [], [[8, "className", 0]], [[null, "visibleChange"], [null, "onShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.scrollToTop() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i12.View_Dialog_0, i12.RenderType_Dialog)), i1.ɵdid(2, 180224, [[1, 4], ["dialog", 4]], 2, i13.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { onShow: "onShow", visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), i1.ɵpid(131072, i14.CustomTranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_ComplimentaryComplianceReportAdditionalDetailsComponent_1)), i1.ɵdid(8, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 1, 1, null, View_ComplimentaryComplianceReportAdditionalDetailsComponent_2)), i1.ɵdid(10, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, 1, 3, "div", [["class", "d-flex justify-content-end mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i14.CustomTranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform("COMPLIMENTARY_COMPLIANCE_REPORT.details.additionalDetailsTitle")); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = _co.visible; var currVal_9 = _ck(_v, 6, 0, "1000px"); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.currentStepObj && _co.currentStepObj.additionalDetailsForm); _ck(_v, 8, 0, currVal_10); var currVal_11 = _co.currentStepObj.detailSectionDocumentUploadConfig; _ck(_v, 10, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = "eccc"; _ck(_v, 1, 0, currVal_0); var currVal_12 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 13, 0, currVal_12); }); }
export function View_ComplimentaryComplianceReportAdditionalDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-complimentary-compliance-report-additional-details", [], null, null, null, View_ComplimentaryComplianceReportAdditionalDetailsComponent_0, RenderType_ComplimentaryComplianceReportAdditionalDetailsComponent)), i1.ɵdid(1, 4308992, null, 0, i16.ComplimentaryComplianceReportAdditionalDetailsComponent, [i17.MAT_DIALOG_DATA, i17.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComplimentaryComplianceReportAdditionalDetailsComponentNgFactory = i1.ɵccf("app-complimentary-compliance-report-additional-details", i16.ComplimentaryComplianceReportAdditionalDetailsComponent, View_ComplimentaryComplianceReportAdditionalDetailsComponent_Host_0, {}, {}, []);
export { ComplimentaryComplianceReportAdditionalDetailsComponentNgFactory as ComplimentaryComplianceReportAdditionalDetailsComponentNgFactory };
