/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formly-custom-types.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/localized-decimal.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "primeng/tooltip";
import * as i6 from "./read-only-input.type.component";
var styles_FormlyFieldReadOnlyInput = [i0.styles];
var RenderType_FormlyFieldReadOnlyInput = i1.ɵcrt({ encapsulation: 0, styles: styles_FormlyFieldReadOnlyInput, data: {} });
export { RenderType_FormlyFieldReadOnlyInput as RenderType_FormlyFieldReadOnlyInput };
function View_FormlyFieldReadOnlyInput_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(0, i2.LocalizedDecimalPipe, [i3.TranslateService, i4.DecimalPipe])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.currValue, "1.0-0")); _ck(_v, 1, 0, currVal_0); }); }
function View_FormlyFieldReadOnlyInput_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-primary badge-tag simple-tag"], ["style", "margin-top: -2px;"]], [[2, "mb", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoHeight; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
function View_FormlyFieldReadOnlyInput_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormlyFieldReadOnlyInput_5)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chipsList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FormlyFieldReadOnlyInput_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["tooltipPosition", "top"], ["tooltipStyleClass", "tooltip-min-width"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(2, 4341760, null, 0, i5.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipStyleClass: [1, "tooltipStyleClass"], text: [2, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "top"; var currVal_2 = "tooltip-min-width"; var currVal_3 = _co.currValue; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currValue; _ck(_v, 1, 0, currVal_0); }); }
function View_FormlyFieldReadOnlyInput_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormlyFieldReadOnlyInput_7)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.dataPopup; var currVal_1 = i1.ɵnov(_v.parent, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FormlyFieldReadOnlyInput_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currValue; _ck(_v, 0, 0, currVal_0); }); }
function View_FormlyFieldReadOnlyInput_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormlyFieldReadOnlyInput_4)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NORMAL", 2]], null, 0, null, View_FormlyFieldReadOnlyInput_6)), (_l()(), i1.ɵand(0, [["NO_POPUP", 2]], null, 0, null, View_FormlyFieldReadOnlyInput_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chips; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FormlyFieldReadOnlyInput_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "form-control wrapped-text"], ["readonly", "readonly"]], [[2, "auto-size", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormlyFieldReadOnlyInput_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["REGULAR", 2]], null, 0, null, View_FormlyFieldReadOnlyInput_3))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.type && (_co.type === "formatted-number")); var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.autoHeight; _ck(_v, 1, 0, currVal_0); }); }
function View_FormlyFieldReadOnlyInput_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "form-control hide-overflow"], ["readonly", "readonly"], ["style", "color: #495057"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; _ck(_v, 1, 0, currVal_0); }); }
export function View_FormlyFieldReadOnlyInput_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormlyFieldReadOnlyInput_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["PLACEHOLDER", 2]], null, 0, null, View_FormlyFieldReadOnlyInput_9)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "wcag-visuallyhidden"], ["type", "hidden"]], [[8, "value", 0], [8, "id", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currValue !== null) && (_co.currValue !== undefined)); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.currValue; var currVal_3 = _co.elementId; _ck(_v, 3, 0, currVal_2, currVal_3); }); }
export function View_FormlyFieldReadOnlyInput_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "formly-field-readonly-input", [], null, null, null, View_FormlyFieldReadOnlyInput_0, RenderType_FormlyFieldReadOnlyInput)), i1.ɵdid(1, 114688, null, 0, i6.FormlyFieldReadOnlyInput, [i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldReadOnlyInputNgFactory = i1.ɵccf("formly-field-readonly-input", i6.FormlyFieldReadOnlyInput, View_FormlyFieldReadOnlyInput_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldReadOnlyInputNgFactory as FormlyFieldReadOnlyInputNgFactory };
