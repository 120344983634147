import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';
import { ACCOUNT_TYPE_IDS, SOURCE_DATA_TYPE } from '../stepper/stepper.const';
import { ACCOUNT_SUMMARY_PUBLIC_FIELD } from '@module/account-management/account-management.const';

export const CHECKBOX_IGNORED_FIELDS = ['sameAsOrganizationAddress', 'sameAsPostalAddress', 'sameAsPhysicalAddress'];

export const provinceStateLabel = 'REGISTRATION_PAGE.cfsRegistrationForm.provinceTerritotyState';
export const postalCodeLabel = 'REGISTRATION_PAGE.cfsRegistrationForm.postalZipForeignCode';
export const PHYSICAL_ADDRESS_FORM_SECTION = {
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.APRphysicalAddress',
  key: 'authorizedOfficial.postalAddress',
  changeExpr: "this.cloneField($event, field, 'authorizedOfficial.civicAddress', 'authorizedOfficial.civicAddress.sameAsPostalAddress'); this.cloneField($event, field, 'contactPerson.postalAddress', 'contactPerson.postalAddress.sameAsPostalAddress');",
  sameAsOrganization: {
    changeExpr: "this.disableForm($event, field);  this.cloneFormGroup($event, field, 'address', 'authorizedOfficial.postalAddress');",
  },
  hidePoBox: false,
  sameAsPostal: {
    hide: true,
  },
  hideLatLong: true,
  provinceAsFreeTextExpr: "!['US', 'CA'].includes(this.model.country)",
  provinceStateLabel,
  postalCodeLabel,
};

export const MAILING_ADDRESS_FORM_SECTION = {
  key: 'authorizedOfficial.civicAddress',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.APRmailingAddress',
  sameAsPostalAddressLabel: 'REGISTRATION_PAGE.offsetRegistrationForm.sameAsPhysicalAddress',
  sameAsOrganization: {
    hide: true,
  },
  hidePoBox: false,
  sameAsPostal: {
    hide: false,
    changeExpr:
      "this.disableForm($event, field);  this.cloneFormGroup($event, field, 'authorizedOfficial.postalAddress', 'authorizedOfficial.civicAddress'); ",
  },
  provinceAsFreeTextExpr: "!['US', 'CA'].includes(this.model.country)",
  provinceStateLabel,
  postalCodeLabel,
  hideLatLong: true,
};

export const CONTACT_PERSON_PHYSICAL_ADDRESS = {
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.contactPersonPhysicalAddress',
  key: 'contactPerson.postalAddress',
  changeExpr: "this.cloneField($event, field, 'contactPerson.civicAddress', 'contactPerson.civicAddress.sameAsPostalAddress');",
  sameAsPostalAddressLabel: 'REGISTRATION_PAGE.offsetRegistrationForm.sameAsAOAddress',
  sameAsOrganization: {
    hide: true,
    changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
    hide: false,
    changeExpr:
      "this.disableForm($event, field);  this.cloneFormGroup($event, field, 'authorizedOfficial.postalAddress', 'contactPerson.postalAddress');",
  },
  hideLatLong: true,
  provinceAsFreeTextExpr: "!['US', 'CA'].includes(this.model.country)",
  provinceStateLabel,
  postalCodeLabel,
};

export const PR_YES_NO_ITEMS = [
  { id: 'yes', name: 'Y', value: true },
  { id: 'no', name: 'N', value: false },
];

export const CONTACT_PERSON_MAILING_ADDRESS = {
  key: 'contactPerson.civicAddress',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.contactPersonMailingAddress',
  sameAsPostalAddressLabel: 'REGISTRATION_PAGE.offsetRegistrationForm.sameAsPhysicalAddress',
  sameAsOrganization: {
    hide: true,
  },
  hidePoBox: false,
  sameAsPostal: {
    hide: false,
    changeExpr:
      "this.disableForm($event, field);  this.cloneFormGroup($event, field, 'contactPerson.postalAddress', 'contactPerson.civicAddress');",
  },
  hideLatLong: true,
  provinceAsFreeTextExpr: "!['US', 'CA'].includes(this.model.country)",
  provinceStateLabel,
  postalCodeLabel,
};

export const AO_PUBLIC_FIELD: IRawForm = {
  type: 'dict-select',
  className: 'col-6',
  key: 'displayAoProfilePublicView',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.displayAoProfilePublicView',
  dataProvided: true,
  bindValue: 'value',
  disabled: false,
  required: true,
  defaultValue: true,
  items: PR_YES_NO_ITEMS,
  translatePrefix: 'COMMON.yesNoList',
  id: 'displayAoProfilePublicView',
};

export const APR: IRawFormBase = {
  groupFields: true,
  key: 'authorizedOfficial',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.authProjecRepresentativeProfile',
  fieldGroup: [
    {
      type: 'input',
      key: 'name',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.APRName',
      disabled: false,
      required: true,
      id: 'APRName',
      icon: {
        text: 'REGISTRATION_PAGE.offsetRegistrationForm.individualAO',
        tooltipPosition: 'right',
      },
    },
    {
      type: 'input',
      key: 'title',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.authProjecRepresentativeTitle',
      disabled: false,
      required: true,
      id: 'authProjecRepresentativeTitle',
    },
    {
      type: 'dict-select',
      key: 'langCorrespondence',
      source: SOURCE_DATA_TYPE.LANGUAGE_OF_CORRESPONDANCE,
      sort: false,
      sortByLang: true,
      translatePrefix: 'COMMON.languageList',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.languageOfCorrespondance',
      disabled: false,
      required: true,
      id: 'languageOfCorrespondance',
      placeholder: 'selectItem',
    },
    {
      type: 'input',
      key: 'email',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.email',
      disabled: false,
      required: true,
      id: 'APREmail',
      validators: ['email'],
    },
    {
      type: 'input',
      key: 'businessTelephone',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.telephone',
      placeholder: 'COMMON.messageSection.phonePlaceholder',
      disabled: false,
      required: true,
      id: 'APRTelephone',
    },
    {
      type: 'input',
      key: 'businessTelephoneExtension',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.telephoneExtension',
      disabled: false,
      required: false,
      id: 'APRTelephoneExtension',
    },
    {
      type: 'input',
      key: 'telephone',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.mobile',
      placeholder: 'COMMON.messageSection.phonePlaceholder',
      disabled: false,
      required: false,
      id: 'APRmobile',
    },
    AO_PUBLIC_FIELD,
  ],
};


export const CONTACT_PERSON: IRawFormBase = {
  groupFields: true,
  key: 'contactPerson',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.contactPersonProfile',
  fieldGroup: [
    /**  {
      type: 'checkbox',
      key: 'sameAsAuthorizedOfficial',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.sameAsAuthorizedOfficial',
      disabled: false,
      required:false,
      className: 'col-12',
      id:'sameAsAuthorizedOfficial'
    }, */

    {
      type: 'input',
      key: 'name',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.contactPersonName',
      disabled: false,
      required: true,
      id: 'contactPersonName',
    },
    {
      type: 'input',
      key: 'title',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.authProjecRepresentativeTitle',
      disabled: false,
      required: true,
      id: 'contactPersonTitle',
    },
    {
      type: 'dict-select',
      key: 'langCorrespondence',
      source: SOURCE_DATA_TYPE.LANGUAGE_OF_CORRESPONDANCE,
      translatePrefix: 'COMMON.languageList',
      sort: false,
      sortByLang: true,
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.languageOfCorrespondance',
      disabled: false,
      required: true,
      id: 'contactPersonLanguageOfCorrespondance',
      placeholder: 'selectItem',
    },
    {
      type: 'input',
      key: 'email',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.email',
      disabled: false,
      required: true,
      id: 'contactPersonEmail',
      validators: ['email'],
    },
    {
      type: 'input',
      key: 'businessTelephone',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.telephone',
      placeholder: 'COMMON.messageSection.phonePlaceholder',
      disabled: false,
      required: true,
      id: 'contactPersonTelephone',
    },
    {
      type: 'input',
      key: 'businessTelephoneExtension',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.telephoneExtension',
      disabled: false,
      required: false,
      id: 'contactPersonTelephoneExtension',
    },
    {
      type: 'input',
      key: 'phoneNumber',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.mobile',
      placeholder: 'COMMON.messageSection.phonePlaceholder',
      disabled: false,
      required: false,
      id: 'contactPersonMobile',
    },
  ],
};

export const SAPCUSTOMERNUMBER: any = {
  type: 'input',
  key: 'sapCustomerNumber',
  label: 'REGISTRATION_PAGE.nfmpAccountForm.sapCustomerNumber',
  disabled: false,
  required: false,
  id: 'sapCustomerNumber',
};

export const CM_ORG_PROFILE: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'template',
        parse: true,
        translate: true,
        className: 'col-12 mt-3',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.ompOrgRegNoteText',
      },
    ],
  },
  {
    groupFields: true,
    label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgProfileInfoSection',
    fieldGroup: [
      {
        type: 'input',
        key: 'legalName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.legalEntityName',
        disabled: false,
        required: true,
        id: 'legalname',
      },
      {
        type: 'input',
        key: 'dunsNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.dunsNumber',
        disabled: false,
        required: false,
        id: 'dunsNumber',
      },
      {
        type: 'input',
        key: 'englishTradeName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.engTradeName',
        disabled: false,
        required: false,
        id: 'englishTradeName',
      },
      {
        type: 'input',
        key: 'frenchTradeName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.frenchTradeName',
        disabled: false,
        required: false,
        id: 'frenchTradeName',
      },
      {
        type: 'input',
        key: 'registrationNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.busRegNum',
        disabled: false,
        minLength: 9,
        id: 'registrationNumber',
        requiredExpression: '!model.charitableFirm',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
          allowNegativeNumbers: false,
        },
      },
      {
        type: 'dict-select',
        key: 'businessStructure.id',
        source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
        dataType: ACCOUNT_TYPE_IDS.CM,
        translatePrefix: 'REGISTRATION_PAGE.offsetRegistrationForm',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.businessStructure',
        disabled: false,
        required: true,
        id: 'offsetBusinessStructure',
        placeholder: 'selectItem',
      },
      {
        type: 'input',
        key: 'registeredCharityNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.registeredCharityNumber',
        disabled: false,
        requiredExpression: 'model.charitableFirm && !model.registrationNumber',
        id: 'registeredCharityNumber',
      },
      {
        type: 'input',
        key: 'orgBusinessEmail',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgBusinessEmail',
        disabled: false,
        required: false,
        id: 'orgBusinessEmail',
      },
      ACCOUNT_SUMMARY_PUBLIC_FIELD,
    ],
  }
];

export const CM_PB_GOV_PROFILE: IRawFormBase = {
  groupFields: true,
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.publicBodyGovernmentProfileInfoSection',
  fieldGroup: [
    {
      type: 'input',
      key: 'legalName',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.governmentName',
      disabled: false,
      required: true,
      id: 'legalname',
    },
    {
      type: 'input',
      key: 'ministry',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.ministryName',
      disabled: false,
      required: false,
      id: 'ministry',
    },
    {
      type: 'input',
      key: 'orgBusinessEmail',
      label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgBusinessEmail',
      disabled: false,
      required: false,
      id: 'orgBusinessEmail',
    },
    ACCOUNT_SUMMARY_PUBLIC_FIELD,
  ],
};

export const OFFSET_ORG_PROFILE: IRawFormBase = {
    groupFields: true,
    label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgProfileInfoSection',
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'accountSubType.id',
        source: SOURCE_DATA_TYPE.ACCOUNT_TYPE,
        dataType: 3,
        translatePrefix: 'REGISTRATION_PAGE.accountTypeSection',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.accountType',
        disabled: false,
        required: true,
        id: 'offsetAccountType',
        placeholder: 'selectItem',
      },
      {
        type: 'dict-select',
        key: 'businessStructure.id',
        source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
        dataType: ACCOUNT_TYPE_IDS.OFFSET,
        translatePrefix: 'REGISTRATION_PAGE.offsetRegistrationForm',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.businessStructure',
        disabled: false,
        required: true,
        id: 'offsetBusinessStructure',
        placeholder: 'selectItem',
      },
      {
        type: 'input',
        key: 'legalName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.legalEntityName',
        disabled: false,
        required: true,
        id: 'legalname',
      },
      {
        type: 'input',
        key: 'registrationNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.busRegNum',
        disabled: false,
        // required: true,
        id: 'registrationNumber',
        requiredExpression: '!model.charitableFirm',
        customTemplateOptions: {
          hideArrows: true,
          type: 'number',
          allowNegativeNumbers: false,
        },
      },
      {
        type: 'input',
        key: 'englishTradeName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.engTradeName',
        disabled: false,
        required: false,
        id: 'englishTradeName',
      },
      {
        type: 'input',
        key: 'frenchTradeName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.frenchTradeName',
        disabled: false,
        required: false,
        id: 'frenchTradeName',
      },

      {
        type: 'input',
        key: 'dunsNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.dunsNumber',
        disabled: false,
        required: false,
        id: 'dunsNumber',
      },
      {
        type: 'input',
        key: 'registeredCharityNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.registeredCharityNumber',
        disabled: false,
        requiredExpression: 'model.charitableFirm && !model.registrationNumber',
        id: 'registeredCharityNumber',
      },
      {
        type: 'input',
        key: 'orgBusinessEmail',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgBusinessEmail',
        disabled: false,
        id: 'orgBusinessEmail',
        validators: ['notRequiredEmail'],
      },
    ],
  };

export const OFFSET_REGISTRATION_FORM: IRawFormBase[] = [
  OFFSET_ORG_PROFILE,
  {
    groupFields: true,
    key: 'address',
    label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgAddress',
    changeExpr: `this.cloneField($event, field, 'authorizedOfficial.postalAddress', 'authorizedOfficial.postalAddress.sameAsOrganizationAddress');
                 this.cloneField($event, field, 'contactPerson.postalAddress', 'contactPerson.postalAddress.sameAsPostalAddress, authorizedOfficial.postalAddress.sameAsOrganizationAddress');`,
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'country',
        source: SOURCE_DATA_TYPE.COUNTRY,
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.country',
        disabled: true,
        required: true,
        defaultValue: 'CA',
        translatePrefix: 'COMMON.countryList',
        id: 'offsetOrgAddressCountry',
        sort: false,
      },

      {
        type: 'input',
        key: 'streetNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.streetNum',
        disabled: false,
        required: true,
        id: 'POstreetNumber',
      },

      {
        type: 'input',
        key: 'streetName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.streetName',
        disabled: false,
        required: true,
        id: 'POstreetName',
      },
      {
        type: 'input',
        key: 'city',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
        disabled: false,
        required: true,
        id: 'orgAddressCity',
      },
      // {
      //   type: 'input',
      //   key: 'municipality',
      //   label: 'REGISTRATION_PAGE.offsetRegistrationForm.municipality',
      //   disabled: false,
      //   required: true

      // },
      {
        type: 'dict-select',
        key: 'province',
        source: SOURCE_DATA_TYPE.ALL_PROVINCE,
        translatePrefix: 'COMMON.jurisdictionsList',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.provinceTerritoty',
        disabled: false,
        requiredExpression: "model && ['CA', 'US'].includes(model.country)",
        freeTextExpr: "!['US', 'CA'].includes(this.model.country)",
        filter: { field: 'country', prop: 'code' },
        id: `province`,
      },
      {
        type: 'input',
        key: 'poBox',
        // hideExpression: addressConfig.hidePoBox,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
        disabled: false,
        required: false,
        className: 'col-6',
        id: `orgAddressPoBox`,
      },
      {
        type: 'input',
        key: 'postalCode',
        label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
        disabled: false,
        requiredExpression: "model && ['CA', 'US'].includes(model.country)",
      },
    ],
  },
  APR,
];

export const OFFSET_REGISTRATION_FORM_WITHOUT_ARP: IRawFormBase[] = [
  {
    groupFields: true,
    label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgProfileInfoSection',
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'accountSubType.id',
        source: SOURCE_DATA_TYPE.ACCOUNT_TYPE,
        dataType: 3,
        translatePrefix: 'REGISTRATION_PAGE.accountTypeSection',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.accountType',
        disabled: false,
        required: true,
        id: 'offsetAccountType',
        placeholder: 'selectItem',
      },
      {
        type: 'dict-select',
        key: 'businessStructure.id',
        source: SOURCE_DATA_TYPE.BUSSINES_STRUCTURE,
        dataType: ACCOUNT_TYPE_IDS.OFFSET,
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.businessStructure',
        disabled: false,
        required: true,
        id: 'offsetBusinessStructure',
        placeholder: 'REGISTRATION_PAGE.offsetRegistrationForm.selectItem',
      },
      {
        type: 'input',
        key: 'legalName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.legalEntityName',
        disabled: false,
        required: true,
        id: 'legalname',
      },
      {
        type: 'input',
        key: 'registrationNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.busRegNum',
        disabled: false,
        // required: true,
        id: 'registrationNumber',
        requiredExpression: '!model.charitableFirm',
      },
      {
        type: 'input',
        key: 'englishTradeName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.engTradeName',
        disabled: false,
        required: false,
        id: 'englishTradeName',
      },
      {
        type: 'input',
        key: 'frenchTradeName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.frenchTradeName',
        disabled: false,
        required: false,
        id: 'frenchTradeName',
      },

      {
        type: 'input',
        key: 'dunsNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.dunsNumber',
        disabled: false,
        required: false,
        id: 'dunsNumber',
      },
      // {
      //   type: 'template',
      //   label: '',
      //   className: 'col-12'
      // },
      // {
      //   key: 'requireBusinessNumber',
      //   type: 'radio',
      //   className:'col-12',
      //   required: true,
      //   options: [
      //       { value: 'Yes', key: true },
      //       { value: 'No', key: false }
      //   ]
      // },
      {
        type: 'input',
        key: 'registeredCharityNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.registeredCharityNumber',
        disabled: false,
        requiredExpression: 'model.charitableFirm && !model.registrationNumber',
        id: 'registeredCharityNumber',
      },
      {
        type: 'input',
        key: 'orgBusinessEmail',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgBusinessEmail2',
        disabled: false,
        id: 'orgBusinessEmail',
        validators: ['notRequiredEmail'],
      },
    ],
  },
  {
    groupFields: true,
    key: 'address',
    label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgAddress',
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'country',
        source: SOURCE_DATA_TYPE.COUNTRY,
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.country',
        disabled: true,
        required: true,
        defaultValue: 'CA',
        translatePrefix: 'COMMON.countryList',
        id: 'offsetOrgAddressCountry',
        sort: false,
      },

      {
        type: 'input',
        key: 'streetNumber',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.streetNum',
        disabled: false,
        required: true,
        id: 'POstreetNumber',
      },

      {
        type: 'input',
        key: 'streetName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.streetName',
        disabled: false,
        required: true,
        id: 'POstreetName',
      },
      {
        type: 'input',
        key: 'city',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
        disabled: false,
        required: true,
        id: 'orgAddressCity',
      },
      // {
      //   type: 'input',
      //   key: 'municipality',
      //   label: 'REGISTRATION_PAGE.offsetRegistrationForm.municipality',
      //   disabled: false,
      //   required: true

      // },
      {
        type: 'dict-select',
        key: 'province',
        source: SOURCE_DATA_TYPE.ALL_PROVINCE,
        translatePrefix: 'COMMON.jurisdictionsList',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.provinceTerritoty',
        disabled: false,
        requiredExpression: "model && ['CA', 'US'].includes(model.country)",
        freeTextExpr: "!['US', 'CA'].includes(this.model.country)",
        filter: { field: 'country', prop: 'code' },
        id: `province`,
      },
      {
        type: 'input',
        key: 'poBox',
        // hideExpression: addressConfig.hidePoBox,
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
        disabled: false,
        required: false,
        className: 'col-6',
        id: `orgAddressPoBox`,
      },
      {
        type: 'input',
        key: 'postalCode',
        label: 'REGISTRATION_PAGE.nfmpAccountForm.postalCode',
        disabled: false,
        requiredExpression: "model && ['CA', 'US'].includes(model.country)",
      },
    ],
  },
];

export const SUB_ACCOUNT_REASON: IRawForm = {
  type: 'dict-select',
  key: 'subAccountReasonId',
  source: SOURCE_DATA_TYPE.SUB_ACCOUNT_REASON,
  //  translatePrefix: 'REGISTRATION_PAGE.subAccountReason',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.subAccountReason',
  disabled: false,
  required: true,
  id: 'subAccountReason',
  placeholder: 'REGISTRATION_PAGE.offsetRegistrationForm.selectItem',
};

export const SUB_ACCOUNT_REASON_OTHER: IRawForm = {
  className: 'col-12 mt-3',
  type: 'textarea',
  key: 'reason',
  label: 'REGISTRATION_PAGE.offsetRegistrationForm.reason',
  disabled: false,
  required: true,
  hideExpression: '(!model.subAccountReasonId || (model.subAccountReasonId) != 8)',
  id: 'reason',
};

export const SUB_ACCOUNT_TYPE: IRawForm = {
  type: 'input',
  hidden: true,
  key: 'accountSubType',
  id: 'accountSubType',
  required: false,
};

export const OFFSET_SUB_REGISTRATION_FORM_ADMIN: IRawFormBase[] = [
  {
    groupFields: false,
    // label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgProfileInfoSection',
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'masterAccount.id',
        source: SOURCE_DATA_TYPE.MASTER_ACCOUNT,
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.masterAccountName',
        disabled: false,
        required: true,
        id: 'masterAccountName',
        placeholder: 'REGISTRATION_PAGE.offsetRegistrationForm.selectItem',
      },
      SUB_ACCOUNT_REASON,
      {
        type: 'input',
        key: 'legalName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.subAccName',
        disabled: false,
        required: true,
        id: 'sublegalName',
      },
      SUB_ACCOUNT_REASON_OTHER,
    ],
  },
];

export const OFFSET_SUB_REGISTRATION_FORM: IRawFormBase[] = [
  {
    groupFields: false,
    // label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgProfileInfoSection',
    fieldGroup: [
      {
        type: 'input',
        key: 'masterAccountName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.masterAccountName',
        disabled: true,
        required: false,
        id: 'masterAccountName',
      },

      SUB_ACCOUNT_REASON,
      {
        type: 'input',
        key: 'legalName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.subAccName',
        disabled: false,
        required: true,
        id: 'legalname',
      },
      SUB_ACCOUNT_REASON_OTHER,
      SUB_ACCOUNT_TYPE,
    ],
  },
];

export const NFMP_PUBLIC_GOV_REGISTRATION_FORM: IRawFormBase[] = [
  {
    groupFields: true,
    label: 'REGISTRATION_PAGE.offsetRegistrationForm.publicBodyGovernmentProfileInfoSection',
    fieldGroup: [
      {
        type: 'input',
        key: 'governmentName',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.governmentName',
        disabled: false,
        required: true,
        id: 'governmentName',
      },
      {
        type: 'input',
        key: 'ministry',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.ministryName',
        disabled: false,
        required: false,
        id: 'ministry',
      },
      {
        type: 'input',
        key: 'orgBusinessEmail',
        label: 'REGISTRATION_PAGE.offsetRegistrationForm.orgBusinessEmail3',
        disabled: false,
        id: 'orgBusinessEmail',
        validators: ['notRequiredEmail'],
      },
    ],
  }
];
