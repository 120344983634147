import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { ProjectDiscussionMapComponent } from './components/project-discussion-map/project-discussion-map.component';

@NgModule({
  declarations: [
    ProjectDiscussionMapComponent,
  ],
  exports: [
    ProjectDiscussionMapComponent,
  ],
  imports: [
    CommonModule,
    GoogleMapsModule,
    HttpClientJsonpModule,
  ],
})
export class ProjectDiscussionMapModule { }
