import * as tslib_1 from "tslib";
import { Component, ComponentFactoryResolver, OnDestroy, OnInit } from '@angular/core';
import { validateStep } from '@shared/decorators/validate-step.decorator';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BaseStepperComponent, } from '@shared/components/base-stepper/base-stepper.component';
import { StepAnchorDirective } from '@shared/components/steps/directive/step-anchor.directive';
import { IApp } from '@core/models/app.interfaces';
import { ACTION_LIST, FOOTER_BUTTONS, REGISTRATION_REPORT_ROLES, REGISTRATION_STEPS, } from '@module/cfr-registration/cfr-registration.const';
import { cloneDeep } from 'lodash';
export const RECENT_ENTITY_ID = 'recentEntityId';
export const RECENT_STEP = 'recentStep';
export const RECENT_STEP_CODE = 'recentStepCode';
const DEFAULT_CI_APPLICATION_URL = '/submission-management/wizard/application';
const DEFAULT_AGREEMENT_URL = '/agreement-credits/wizard/agreement';
const DEFAULT_COMPONENT_MAP = 'RRPS';
const AAG_INFO_VISIBLE = 'AAG_info_visible';
export class MainPageComponent extends BaseStepperComponent {
    constructor(activatedRoute, componentFactoryResolver, loaderService, accountService) {
        super(activatedRoute);
        this.activatedRoute = activatedRoute;
        this.componentFactoryResolver = componentFactoryResolver;
        this.loaderService = loaderService;
        this.accountService = accountService;
        this.isLoading = this.loaderService.isLoading;
        this.code = DEFAULT_COMPONENT_MAP;
        this.footerButtonConfig = {};
        this.lastPageProfile = false;
    }
    set content(content) {
        if (content) {
            this.appStepAnchor = content;
        }
    }
    ngOnInit() {
        this.activatedRoute.params
            .pipe(takeUntil(this._destroy$)).subscribe(params => {
            params.code && (this.code = params.code);
            this.steps = cloneDeep(REGISTRATION_STEPS[this.code]);
            const showAAGInfo = sessionStorage.getItem(AAG_INFO_VISIBLE);
            if (this.code === 'RRVB' && showAAGInfo && showAAGInfo === 'false') {
                this.steps.pop();
            }
            this.initStepper();
            this.activatedRoute.snapshot.queryParams.profile && (this.lastPageProfile = true);
            this.service.stepCode = this.code;
            this.service.id = params.id;
            this.service.accountId = params.accountId;
            this.discriminatorType = this.service.stepCode === 'RRSC' ? 'SUB_ORGANIZATION_ACCOUNT' : 'REGISTRATION_ACCOUNT';
            this.footerButtonConfig = Object.assign({}, this.footerButtonConfig, FOOTER_BUTTONS[this.service.stepCode]);
            if (!this.footerButtonConfig.closePrefix) {
                if (this.isAppStoredData) {
                    this.footerButtonConfig.closePrefix = 'closeAndBackToApp';
                    this.footerButtonConfig.onCloseRedirectUrl = DEFAULT_CI_APPLICATION_URL;
                }
                else if (this.isAgreementStoredData) {
                    this.footerButtonConfig.closePrefix = 'closeAndBackToAgreement';
                    this.navigationService.removeLast();
                    this.footerButtonConfig.onCloseRedirectUrl = DEFAULT_AGREEMENT_URL;
                }
                else if (this.lastPageProfile) {
                    this.footerButtonConfig.closePrefix = 'closeAndBackToUserPage';
                }
                else {
                    this.footerButtonConfig.closePrefix = 'close';
                }
            }
            if (this.service.stepCode === 'DUPS' && (this.storeService.user.isOffset() || this.storeService.user.isCM())) {
                this.footerButtonConfig.hideActions = true;
            }
            const recentEntityId = sessionStorage.getItem(RECENT_ENTITY_ID);
            const recentStepCode = sessionStorage.getItem(RECENT_STEP_CODE);
            if (recentEntityId && this.service.stepCode === recentStepCode) {
                this.service.id = recentEntityId;
            }
            if (this.service.stepCode === 'RRSC' && this.service.isNewAccount()) {
                this.createNewSubcontractingOrganization();
            }
            else {
                const payload = {
                    id: this.service.id,
                    accountId: this.service.accountId,
                    discriminatorType: this.discriminatorType
                };
                this.accountService.getAccountDetails(this.service.accountId).pipe(tap(accountDetail => {
                    if (accountDetail.status === 'ACTIVE') {
                        this.service.activeAccountSubTypeCodes = accountDetail.accountSubTypeNames ? accountDetail.accountSubTypeNames.split(',') : [];
                    }
                    this.service.userId = accountDetail.authorizedOfficial.id;
                }), switchMap(() => this.getRegistrationDetails(payload, true))).subscribe();
            }
        });
        this.actionsList = ACTION_LIST;
    }
    get isAppStoredData() {
        return this.service.isAppStoredData;
    }
    get isAgreementStoredData() {
        return this.service.isAgreementStoredData;
    }
    createNewSubcontractingOrganization() {
        this.service.currentData = {
            discriminatorType: 'SUB_ORGANIZATION_ACCOUNT',
            accountId: this.service.accountId,
            authorizedOfficial: null,
            accountSubTypeDtoList: [{
                    code: 'VB',
                    activities: [{
                            code: 'VFB',
                            facilities: []
                        }]
                }]
        };
        this.setCurrentComponent();
    }
    saveAsDraft(draft = false) {
        this.service.save('SAVE', draft).subscribe(result => {
            console.log(result);
            this.service.id = result.entity;
            const payload = {
                id: this.service.id,
                accountId: this.service.accountId,
                discriminatorType: this.discriminatorType,
            };
            this.getRegistrationDetails(payload, false, draft).subscribe(() => {
                this.service.wasModified = true;
                draft ? this.service.nextStep() : this.currentStepComponent.ngOnInit();
            });
        }, (error) => {
            this.formService.parseErrors(this.service.formData, error.error);
        });
    }
    nextStep() {
        if (!this.service.disabled && this.service.isDirty) {
            this.saveAsDraft(true);
        }
        else {
            this.service.nextStep();
        }
    }
    submit() {
        if (!this.service.currentData.accountSubTypeDtoList || this.service.currentData.accountSubTypeDtoList.length === 0) {
            this.service.showMessage('atLeast1OrganizationRoleShouldBeSelected');
            return;
        }
        this.service.save('SUBMIT').subscribe(result => {
            console.log(result);
            this.service.resetStepper();
            this.returnPreviousLocation(null);
        }, (error) => {
            console.log(error);
        });
    }
    update() {
        if (this.footerButtonConfig.updateAction) {
            this.service.openConfirmationModal('MAIN_PAGE_ACTION_' + this.footerButtonConfig.updateAction)
                .subscribe(result => {
                if (result) {
                    this._makeSaveRequest(this.footerButtonConfig.updateAction);
                    this.service.wasModified = false;
                }
            });
        }
        else {
            this._makeSaveRequest();
        }
    }
    _makeSaveRequest(action = 'SUBMIT') {
        this.service.save(action, false, true).subscribe(result => {
            const payload = {
                id: result.entity,
                accountId: this.service.accountId,
                discriminatorType: this.discriminatorType
            };
            this.getRegistrationDetails(payload, true).subscribe();
        }, (error) => {
            this.formService.parseErrors(this.service.formData, error.error);
        });
    }
    getRegistrationDetails(payload, setcomponent = false, wasModified = false) {
        const req = this.storeService.user.isOffset()
            ? this.service.getOffsetRegistrationDetails(payload.accountId)
            : this.service.getRegistrationDetails(payload);
        return req.pipe(tap((result) => {
            console.log(result);
            this.service.currentData = result;
            this.service.setEntityActionListForAdditionalActions();
            this.service.setInitialTypes();
            this.service.currentData.address = null;
            this.service.currentData.accountId = this.service.accountId;
            if (this.service.currentData.authorizedOfficial) {
                this.service.currentData.authorizedOfficial.name = this.service.currentData.authorizedOfficial.fullName;
            }
            this.service.currentData.discriminatorType = payload.discriminatorType;
            this.service.code = result.accountSubTypes === 'VB' ? result.accountSubTypes : 'PS';
            const notLatestReport = this.service.currentData.id && this.service.stepCode.indexOf('RR') > -1 &&
                this.service.currentData.id !== result.latestRegistrationId;
            const readOnlyState = this._readOnlyState();
            const hasPermission = this.storeService.user.hasPermission('CREATE_REGISTRATION_REPORT');
            const hasPermissionForAccount = this.storeService.user.hasPermissionForAccount('CREATE_REGISTRATION_REPORT', Number(this.service.accountId));
            if (this.service.currentData.authorizedOfficial) {
                this.service.currentData.authorizedOfficial.telephone = this.service.currentData.authorizedOfficial.phoneNumber;
            }
            this.setInitialRoleProperties();
            if (notLatestReport || readOnlyState || !(hasPermission || hasPermissionForAccount)) {
                const regAccountStatus = this.service.currentData.registrationAccountStatus;
                this.service.disable();
                this.footerButtonConfig.showSave = false;
                this.footerButtonConfig.showSubmit = false;
                if (!notLatestReport && (regAccountStatus === 'ACTIVE' || regAccountStatus === 'IN_PROGRESS') && this._hasProposeRegistrationRole()) {
                    this.footerButtonConfig.updatePrefix = 'PROPOSE_REGISTRATION_REPORT';
                    this.footerButtonConfig.updateAction = 'PROPOSE';
                    this.footerButtonConfig.showSave = true;
                    this.service.enable();
                }
                if (hasPermission && regAccountStatus === 'PROPOSED') {
                    this.service.currentData.entityActionList = [
                        { workflowAction: "APPROVE_PROPOSED" },
                        { workflowAction: "REJECT_PROPOSED" }
                    ];
                }
            }
            else {
                this.service.enable();
                this.footerButtonConfig = Object.assign({}, this.footerButtonConfig, FOOTER_BUTTONS[this.service.stepCode]);
                if (this.service.stepCode === 'DUPS' && (this.storeService.user.isOffset() || this.storeService.user.isCM())) {
                    this.footerButtonConfig.hideActions = true;
                }
            }
            if ((this.service.currentData.updateInd || this.service.participationRequested()) && !this.service.disabled && this.service.stepCode.indexOf('RR') > -1) {
                this.footerButtonConfig.showUpdate = true;
                this.footerButtonConfig.showSubmit = false;
                this.footerButtonConfig.updatePrefix = this._hasProposeRegistrationRole() ? 'PROPOSE_REGISTRATION_REPORT' : 'UPDATE_REGISTRATION_REPORT';
                if (this.service.currentData.registrationAccountStatus === 'NEED_MORE_INFO') {
                    this.footerButtonConfig.updatePrefix = 'SUBMIT_RR_TO_ECCC';
                }
            }
            if (setcomponent) {
                this.setCurrentComponent();
            }
            this.service.prevStepData = cloneDeep(this.service.currentData);
            this.initModelChangeTracking(this.service.currentData, null, wasModified);
            const recentStep = JSON.parse(sessionStorage.getItem(RECENT_STEP));
            const recentStepCode = sessionStorage.getItem(RECENT_STEP_CODE);
            if (this.service.stepCode === recentStepCode && recentStep) {
                this.service.currentStep = { id: recentStep.id, subId: recentStep.subId, data: this.service.currentData };
                sessionStorage.removeItem(RECENT_STEP);
                sessionStorage.removeItem(RECENT_STEP_CODE);
                sessionStorage.removeItem(RECENT_ENTITY_ID);
            }
        }, (error) => {
            console.log(error);
        }));
    }
    setInitialRoleProperties() {
        const allRoles = IApp.NSAccountSubType.StatusEnum;
        for (const roleCode in allRoles) {
            const element = this.service.getElement(this.service.currentData.accountSubTypeDtoList, roleCode);
            const roleIdx = this.getRoleDTOIndex(roleCode);
            if (element) {
                this.service.currentData[REGISTRATION_REPORT_ROLES[roleCode]] = element.selected;
                if (!element.selected) {
                    this.service.currentData.accountSubTypeDtoList[roleIdx].canadaLocation = null;
                    this.service.currentData.accountSubTypeDtoList[roleIdx].civicAddress = {};
                }
            }
        }
    }
    getRoleDTOIndex(roleCode) {
        return this.service.getElementIndex(this.service.currentData.accountSubTypeDtoList, roleCode);
    }
    get submitButtonEnabled() {
        if (this.service.currentData && !this.service.disabled && this.service.isDirty) {
            return true;
        }
        else {
            return this.service.currentData &&
                this.service.currentData.registrationAccountStatus === 'IN_PROGRESS';
        }
    }
    get inProgress() {
        return this.service.currentData.registrationAccountStatus === 'IN_PROGRESS';
    }
    get wasModified() {
        return this.service.wasModified;
    }
    _hasProposeRegistrationRole() {
        return this.storeService.user.hasPermissionForAccount('PROPOSE_REGISTRATION_REPORT', Number(this.service.accountId));
    }
    _readOnlyState() {
        return this.service.currentData.registrationAccountStatus &&
            (this.service.currentData.registrationAccountStatus.indexOf('PENDING') > -1 ||
                this.service.currentData.registrationAccountStatus === 'PROPOSED' || this.storeService.getAAGInfoVisibility() === 'false');
    }
}
tslib_1.__decorate([
    validateStep()
], MainPageComponent.prototype, "saveAsDraft", null);
tslib_1.__decorate([
    validateStep()
], MainPageComponent.prototype, "nextStep", null);
tslib_1.__decorate([
    validateStep()
], MainPageComponent.prototype, "submit", null);
tslib_1.__decorate([
    validateStep()
], MainPageComponent.prototype, "update", null);
