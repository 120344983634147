<div class="account-title">

  <a href="#"
     class="arrow-back"
     wcag-dummy-link-target
     role="button"
     aria-label="Back"
     (click)="back($event)"
     (keypress)="back($event)">
    <fa-icon class="float-left back-icon"  [icon]="['fas', 'chevron-left']"></fa-icon>
    <span style="display: none">back</span>
  </a>

  <h2 class="float-left">{{account?.legalName}}</h2>
  <div class="text-right"  *ngIf ="!history" >
    <div class="d-inline-block" >
      <app-account-actions *ngIf="account" [account_id]="account.id" class="actions"
      [actionService]="actionService"
      [accountData]="account"
      [actionsList]="account.entityActionList" (actionSuccessfull)="getAccountDetails()">
    </app-account-actions>
    </div>
  </div>
</div>

<div *ngIf ="!history">
<div class="row justify-content-md-center mb-3" *ngIf="!isCFS()">
  <div class="col-lg-5">
    <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.accountStatus' | translate }}</div>
      <div class="col-lg-6" *ngIf="accountStatus">{{accountStatus | translate}}</div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.accountType' | translate }}</div>
      <div class="col-lg-6" *ngIf="accountTypeName">{{ accountTypeName | translate}}</div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.accountID' | translate }}</div>
      <div class="col-lg-6">{{account?.id}}</div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">
        {{ 'ACCOUNTS_MODULE.accountSummary.accountHistory' | translate }}:&nbsp;
      </div>
      <div class="col-lg-6">
        <a [routerLink]="['/account-management/history/' + account.id | localize]">{{ 'COMMON.actionsLabel.view' | translate
          }}
        </a>
      </div>
    </div>

    <div class="row mb-2" *ngIf="account.accountSubType">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.accountSubTypeName' | translate }}:</div>
      <div class="col-lg-6 mrG-CamelCase">{{'REGISTRATION_PAGE.accountTypeSection.'+ account.accountSubType.name | translate }}</div>
    </div>

    <div class="row mb-2" *ngIf="account.registrationType">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.accountPurpose' | translate }}</div>
      <div class="col-lg-6">{{ 'REGISTRATION_PAGE.accountTypeSection.'+ account?.registrationType.name | translate }}</div>
    </div>

  </div>
  <div class="col-lg-5" >
    <div class="row mb-2" *ngIf="account.masterAccountName">
      <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.masterAccountName' | translate }} :</div>
      <div class="col-lg-7">{{account?.masterAccountName}}</div>
    </div>

    <ng-container *ngIf="isOffset; else NOT_OFFSET">
      <div class="row mb-2">
        <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.totalIssuedCredits' | translate }}:</div>
        <div class="col-lg-7" [innerHTML]="creditSummary.totalOffsetCreditIssuedStr"></div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.activeOffsetCredits' | translate }}:</div>
        <div class="col-lg-7" [innerHTML]="creditSummary.totalActiveOffsetCreditsStr"></div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.activeSurplusCredits' | translate }}:</div>
        <div class="col-lg-7" [innerHTML]="creditSummary.totalActiveSurplusCreditsStr"></div>
      </div>
    </ng-container>

    <ng-template #NOT_OFFSET>
      <ng-container *ngIf="isCM; else DEFAULT">
        <div class="row mb-2">
          <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.holdings' | translate }}:</div>
          <div class="col-lg-7" [innerHTML]="creditSummary.currentHoldingsStr"></div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.activeOffsetCredits' | translate }}:</div>
          <div class="col-lg-7" [innerHTML]="creditSummary.totalActiveOffsetCreditsStr"></div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.activeSurplusCredits' | translate }}:</div>
          <div class="col-lg-7" [innerHTML]="creditSummary.totalActiveSurplusCreditsStr"></div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #DEFAULT>
      <div class="row mb-2">
        <div class="col-lg-5 text-right text-muted overflow-hidden default">{{ 'ACCOUNTS_MODULE.accountSummary.totalIssuedCredits' | translate }}:</div>
        <div class="col-lg-7" [innerHTML]="creditSummary.totalIssuedStr"></div>
      </div>
      <div class="row mb-2">
        <div class="col-lg-5 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.holdings' | translate }}:</div>
        <div class="col-lg-7" [innerHTML]="creditSummary.currentHoldingsStr"></div>
      </div>
    </ng-template>

  </div>
</div>

<div class="row justify-content-md-center mb-3"  *ngIf="isCFS()">
  <div class="col-lg-5">
     <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.accountTypeCFR' | translate }}</div>
      <div class="col-lg-6">{{  accountTypeName | translate}}</div>
    </div>

    <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.OrganizationRoles' | translate }}:</div>
      <div class="col-lg-6">{{  'COMMON.accountSubTypeList.'+ account.accountSubTypeNames | translate}}</div>
    </div>

  </div>
   <div class="col-lg-5">
   <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.accountSummary.orgID' | translate }}</div>
      <div class="col-lg-6">{{account?.id}}</div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-6 text-right text-muted overflow-hidden">
        {{ 'ACCOUNTS_MODULE.accountSummary.accountHistory' | translate }}:&nbsp;
      </div>
      <div class="col-lg-6">
        <a [routerLink]="['/account-management/history/' + account.id | localize]">{{ 'COMMON.actionsLabel.view' | translate
          }}
        </a>
      </div>
    </div>
  </div>
</div>

<div class="panel" *ngIf="isCFS()">
  <app-reg-rept-list [accountId]="accountId" [accountStatus]="account.status"></app-reg-rept-list>
</div>
<div class="panel min-height" *ngIf="isCFS() && account.accountTypeName && account.accountTypeName !== 'VB'">
  <app-account-activities-list [accountId]="accountId"></app-account-activities-list>
</div>

<div class="panel" *ngIf="isOBPS()">
  <app-compensation-obligation-history [accountId]="accountId"></app-compensation-obligation-history>
</div>

<div class="panel mt-4" *ngIf="isOffset">
    <h2>{{ 'PROJECTS_MODULE.tableDetails.accountDetailsTitle' | translate }}</h2>
    <app-project-list
      [showTableDetails]="false"
      [registrationAccountId]="accountId"
      [showFilters]="false"
      [ignoreLayout]="true">
    </app-project-list>
</div>


<div class="panel mt-4" *ngIf="isOffset && isMasterAccount">
    <h2>{{ 'ACCOUNTS_MODULE.accountSummary.subAccounts' | translate }}</h2>
    <div class="d-flex flex-column flex-fill h-100 ">
      <app-accounts-list
        [showFilters]="false"
        [masterAccountId]="accountId">
      </app-accounts-list>
    </div>
</div>


<div class="panel">
  <app-assigned-users *ngIf="account" [accountId]="accountId" [userList]="users"></app-assigned-users>
</div>
</div>
<div class="panel general-information">
    <div class="row justify-content-between">
        <div class="col-3">
          <h2>{{ 'ACCOUNTS_MODULE.accountSummary.generalInformationTitle' | translate }}</h2>
        </div>
        <div class="col-3">
          <div *ngIf="account" class="row">
            <div class="col-8 col-sm-6">
              <button *ngIf="isEditEnabled" (click)="saveGeneralInformation()" disableOnUpload form="details-form" type="submit" id="accountSummarySave" class="btn btn-primary mr-2">{{ 'COMMON.actionsLabel.SAVE' | translate }}</button>
              <button *ngIf="isEditEnabled" disableOnUpload type="button" class="btn btn-secondary mr-2" id="accountSummaryEdit" (click)="cancelEditGeneralInformation()">{{ 'COMMON.actionsLabel.CANCEL'| translate }}</button>
              <button *ngIf="!isEditEnabled && isAllowedToEdit()" disableOnUpload type="button" class="btn btn-primary" id="accountSummaryEdit" (click)="editGeneralInformation()">{{ 'COMMON.actionsLabel.EDIT' | translate }}</button>
            </div>
          </div>
        </div>
    </div>
  <form [formGroup]="form" id="details-form" >
    <formly-form [model]="account" [fields]="fields" [options]="options" [form]="form"></formly-form>
  </form>
</div>
<div class="panel" *ngIf = "isRC">
  <button *hasPermission="CREATE_AGREEMENT" class="btn btn-secondary mb-2" style="width: 250px;" (click)="openCreationAgreement();" >{{'COMMON.filters.createNewAgreement' | translate }}</button>
  <app-agreement-credits-list [showTableDetails]="false" [registrationAccountId]="accountId"></app-agreement-credits-list>
</div>

<!--<div class="panel" *ngIf="showSubOrganization()">
  <app-sub-contracting-organization [accountId]="accountId" [documentsPage]="documentsPage" [documentUploadConfig]="documentUploadConfig" ></app-sub-contracting-organization>
</div> -->

<div class="panel-no-background" *ngIf ="!history">
  <app-documents [documentsPage]="documentsPage"
                 [documentUploadConfig]="documentUploadConfig"
                 [skipFirstLoad]="true"></app-documents>
</div>

