/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datepicker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/calendar/primeng-calendar.ngfactory";
import * as i3 from "primeng/calendar";
import * as i4 from "../../pipes/custom-translate.pipe";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/forms";
import * as i7 from "./datepicker.component";
var styles_DatepickerComponent = [i0.styles];
var RenderType_DatepickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DatepickerComponent, data: {} });
export { RenderType_DatepickerComponent as RenderType_DatepickerComponent };
export function View_DatepickerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-calendar", [["dateFormat", "yy-mm-dd"]], [[8, "id", 0], [2, "ui-inputwrapper-filled", null], [2, "ui-inputwrapper-focus", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Calendar_0, i2.RenderType_Calendar)), i1.ɵdid(1, 1294336, [["calendar", 4]], 1, i3.Calendar, [i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef, i1.NgZone], { styleClass: [0, "styleClass"], inputId: [1, "inputId"], inputStyleClass: [2, "inputStyleClass"], placeholder: [3, "placeholder"], dateFormat: [4, "dateFormat"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.Calendar]), i1.ɵdid(5, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(7, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "col-12 p-0"; var currVal_11 = "date-input"; var currVal_12 = "col-12"; var currVal_13 = i1.ɵunv(_v, 1, 3, i1.ɵnov(_v, 3).transform(_co.placeholder)); var currVal_14 = "yy-mm-dd"; _ck(_v, 1, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_15 = _co.currentValue; _ck(_v, 5, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = i1.ɵnov(_v, 1).filled; var currVal_2 = i1.ɵnov(_v, 1).focus; var currVal_3 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 7).ngClassValid; var currVal_8 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_DatepickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-datepicker", [], null, null, null, View_DatepickerComponent_0, RenderType_DatepickerComponent)), i1.ɵdid(1, 49152, null, 0, i7.DatepickerComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var DatepickerComponentNgFactory = i1.ɵccf("app-datepicker", i7.DatepickerComponent, View_DatepickerComponent_Host_0, { value: "value", placeholder: "placeholder", appendTo: "appendTo", id: "id" }, { dateChanged: "dateChanged" }, []);
export { DatepickerComponentNgFactory as DatepickerComponentNgFactory };
