/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reg-rept-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/controls/reg-rept-actions/reg-rept-actions.component.ngfactory";
import * as i5 from "../../../../shared/controls/reg-rept-actions/reg-rept-actions.component";
import * as i6 from "../../../../shared/services/helper.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../../shared/services/modal.service";
import * as i9 from "../../../cfr-registration/services/cfr-registration.service";
import * as i10 from "../../../../shared/services/upload-document.service";
import * as i11 from "../../../../core/store/store.service";
import * as i12 from "../../services/account-management.service";
import * as i13 from "../../../../shared/services/notifications.service";
import * as i14 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i15 from "../../../../shared/components/table-view/table-view.component";
import * as i16 from "./reg-rept-list.component";
import * as i17 from "../../../../core/services/lookup.service";
var styles_RegReptListComponent = [i0.styles];
var RenderType_RegReptListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegReptListComponent, data: {} });
export { RenderType_RegReptListComponent as RenderType_RegReptListComponent };
function View_RegReptListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewAccount($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.viewAccount($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 2, 0, currVal_0); }); }
function View_RegReptListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reg-rept-actions", [], null, [[null, "actionSuccessfull"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionSuccessfull" === en)) {
        var pd_0 = (_co.refreshData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RegReptActionsComponent_0, i4.RenderType_RegReptActionsComponent)), i1.ɵdid(1, 245760, null, 0, i5.RegReptActionsComponent, [i6.HelperService, i7.Router, i8.ModalService, i9.CfrRegistrationService, i10.UploadDocumentService, i11.StoreService, i12.AccountManagementService, i13.NotificationsService], { actionsList: [0, "actionsList"], data: [1, "data"], accountId: [2, "accountId"], appendTo: [3, "appendTo"], accountStatus: [4, "accountStatus"] }, { actionSuccessfull: "actionSuccessfull" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.entityActionList; var currVal_1 = _v.context.$implicit; var currVal_2 = _co.accountId; var currVal_3 = "app-reg-rept-list"; var currVal_4 = _co.accountStatus; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_RegReptListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { actionsTemplate: 0 }), i1.ɵqud(402653184, 2, { viewColTemplate: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i14.View_TableViewComponent_0, i14.RenderType_TableViewComponent)), i1.ɵdid(7, 638976, null, 0, i15.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"] }, { paginationChanged: "paginationChanged" }), (_l()(), i1.ɵand(0, [[2, 2], ["viewColTemplate", 2]], null, 0, null, View_RegReptListComponent_1)), (_l()(), i1.ɵand(0, [[1, 2], ["actionsTemplate", 2]], null, 0, null, View_RegReptListComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.regDetailsList; var currVal_2 = _co.tableConfig; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ACCOUNTS_MODULE.accountList.reportDetails")); _ck(_v, 3, 0, currVal_0); }); }
export function View_RegReptListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reg-rept-list", [], null, null, null, View_RegReptListComponent_0, RenderType_RegReptListComponent)), i1.ɵdid(1, 245760, null, 0, i16.RegReptListComponent, [i7.Router, i12.AccountManagementService, i9.CfrRegistrationService, i3.TranslateService, i17.LookupService, i6.HelperService, i11.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegReptListComponentNgFactory = i1.ɵccf("app-reg-rept-list", i16.RegReptListComponent, View_RegReptListComponent_Host_0, { accountId: "accountId", accountStatus: "accountStatus" }, {}, []);
export { RegReptListComponentNgFactory as RegReptListComponentNgFactory };
