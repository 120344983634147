import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { APPROVE_CANCELLATION, APPROVE_CANCELLATION2, LOAD_CANCELLATION, REJECT_CANCELLATION, REJECT_CANCELLATION2, SUBMIT_REGISTRATION_CANCELLATION } from '@module/cfr-registration/services/cfr-registration.service';
import { PERMISSION_APPROVE_CFR_ACCOUNT, PERMISSION_ACCOUNT_DUAL_CONTROL_APPROVAL } from '@module/submission-management/components/cancel-application/cancel-application.component';
import { ACCEPT_CANCELLATION, ACCEPT_CANCELLATION_VB, CARBON_INTENSITY_CONTRIBUTOR, FOREIGN_SUPPLIER, PRIMARY_SUPPLIER, REGISTERED_CREATOR, REQUEST_REGISTRATION_CANCELLATION_REMARKS } from '@shared/components/stepper/stepper.const';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { getLabel, getTable } from '@core/utilities/raw-form.utils';
import { PERMISSION_ADD_REMARKS, PERMISSION_DISCLOSE_REMARKS, PERMISSION_VIEW_DISCLOSE_REMARKS } from 'app/app.const';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { TASK_STATE } from '@module/unit-management/unit-management.const';
const TABLE_NAME = 'UNIT_PENDING_REGISTRATION_CANCELLATION';
export class ReqRegCancellationComponent {
    constructor(router, navigationService, store, translate, modalService, helperService, formFactoryService, cfrRegistrationService, lookupService, unitManagementService) {
        this.router = router;
        this.navigationService = navigationService;
        this.store = store;
        this.translate = translate;
        this.modalService = modalService;
        this.helperService = helperService;
        this.formFactoryService = formFactoryService;
        this.cfrRegistrationService = cfrRegistrationService;
        this.lookupService = lookupService;
        this.unitManagementService = unitManagementService;
        this._destroy$ = new Subject();
        this.formGroup = new FormGroup({});
        this.model = {};
        this.apiURL = new Map([
            ['SUBMIT', SUBMIT_REGISTRATION_CANCELLATION],
            ['APPROVE', APPROVE_CANCELLATION],
            ['REJECT', REJECT_CANCELLATION],
            ['APPROVE2', APPROVE_CANCELLATION2],
            ['REJECT2', REJECT_CANCELLATION2],
            ['LOAD', LOAD_CANCELLATION],
        ]);
    }
    ngOnInit() {
        this.state = window.history.state && window.history.state.data ? window.history.state : this.storedTaskState;
        if (this.state.data) {
            this.model = this.state.data.rawData;
            this.type = this.state.data.actionType;
            this.isVb = this.model.accountSubTypes === 'VB';
            if (this.isViewType()) {
                this.registrationCancellation('LOAD');
            }
            else {
                this.createForm(this.setGlobalFields());
                setTimeout(() => this.formGroup.enable());
            }
        }
        else {
            this.close();
        }
    }
    get storedTaskState() {
        const content = sessionStorage.getItem(TASK_STATE);
        sessionStorage.removeItem(TASK_STATE);
        return content ? JSON.parse(content) : null;
    }
    isViewType() {
        return this.type === 'VIEW_CANCELLATION' ||
            this.type === 'APPROVE_CANCELLATION' ||
            this.type === 'REJECT_CANCELLATION' ||
            this.type === 'APPROVE_CANCELLATION2' ||
            this.type === 'REJECT_CANCELLATION2';
    }
    showApproveReject() {
        return this.model.registrationAccountStatus === 'PENDING_REGISTRATION_CANCELLATION'
            && this.store.user.hasPermission(PERMISSION_APPROVE_CFR_ACCOUNT);
    }
    showApproveReject2() {
        return this.model.registrationAccountStatus === 'PENDING_REGISTRATION_CANCELLATION2'
            && this.store.user.hasPermission(PERMISSION_ACCOUNT_DUAL_CONTROL_APPROVAL);
    }
    createForm(fields) {
        this.fields = this.formFactoryService.configureForm([
            {
                groupFields: false,
                fieldGroup: fields,
            },
        ]);
    }
    setGlobalFields() {
        const fields = [];
        const roleMap = {
            [IApp.NSAccountSubType.StatusEnum.PS]: { present: false, selected: false },
            [IApp.NSAccountSubType.StatusEnum.RC]: { present: false, selected: false },
            [IApp.NSAccountSubType.StatusEnum.FS]: { present: false, selected: false },
            [IApp.NSAccountSubType.StatusEnum.CIC]: { present: false, selected: false },
        };
        if (this.model.accountSubTypes) {
            this.model.accountSubTypes.split(',').forEach(element => {
                if (roleMap[element]) {
                    roleMap[element] = { present: true, selected: false };
                }
            });
        }
        if (this.model.accountSubTypeDtoList) {
            this.model.orgRoles = '';
            this.model.accountSubTypeDtoList.forEach(element => {
                if (roleMap[element.code]) {
                    roleMap[element.code] = { present: true, selected: true };
                    this.appendOrgRoleCon(element.code);
                }
            });
        }
        if (roleMap[IApp.NSAccountSubType.StatusEnum.PS].present) {
            fields.push(PRIMARY_SUPPLIER);
            this.model.primarySupplier = roleMap[IApp.NSAccountSubType.StatusEnum.PS].selected;
        }
        if (roleMap[IApp.NSAccountSubType.StatusEnum.RC].present) {
            fields.push(REGISTERED_CREATOR);
            this.model.registeredCreator = roleMap[IApp.NSAccountSubType.StatusEnum.RC].selected;
        }
        if (roleMap[IApp.NSAccountSubType.StatusEnum.FS].present) {
            fields.push(FOREIGN_SUPPLIER);
            this.model.foreignSupplier = roleMap[IApp.NSAccountSubType.StatusEnum.FS].selected;
        }
        if (roleMap[IApp.NSAccountSubType.StatusEnum.CIC].present) {
            fields.push(CARBON_INTENSITY_CONTRIBUTOR);
            this.model.carbonIntensityContributor = roleMap[IApp.NSAccountSubType.StatusEnum.CIC].selected;
        }
        if (this.isVb) {
            fields.push(ACCEPT_CANCELLATION_VB);
        }
        else {
            fields.push(ACCEPT_CANCELLATION);
        }
        return fields;
    }
    setAdminFields(fields, showUnitsTable = false) {
        fields.forEach(field => {
            field['disabled'] = true;
        });
        if (showUnitsTable) {
            fields.push(...[
                getLabel({
                    label: 'ACCOUNTS_MODULE.reqRegCancellation.unitsTableDesc',
                    className: 'col-12',
                }),
                getTable({
                    id: '_dummy',
                    className: 'col-12 mb-3',
                    customTemplateOptions: {
                        config: this.tableConfig,
                        initialData: this.units.content,
                        footerTemplate: this.unitsTableFooterTemplate,
                        updateData: new Subject(),
                    },
                }),
            ]);
        }
        // admin remark
        if (this.store.user.hasPermission(PERMISSION_ADD_REMARKS)) {
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].disabled = false;
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].hideExpression = false;
        }
        else if (this.store.user.hasPermission(PERMISSION_VIEW_DISCLOSE_REMARKS) && this.model.discloseInfo) {
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].disabled = true;
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].hideExpression = false;
        }
        else {
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].hideExpression = true;
        }
        // disclose
        if (this.store.user.hasPermission(PERMISSION_DISCLOSE_REMARKS)) {
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[1].disabled = false;
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[1].hideExpression = false;
        }
        else {
            REQUEST_REGISTRATION_CANCELLATION_REMARKS[1].hideExpression = true;
        }
        REQUEST_REGISTRATION_CANCELLATION_REMARKS.forEach(field => {
            fields.push(field);
        });
        return fields;
    }
    registrationCancellation(type) {
        if (type === 'SUBMIT') {
            // vanilla can be enhanced
            this.buildOrgRole();
            this.doAction(type);
        }
        else if (type === 'LOAD') {
            this.lookupService.getTableConfigurations(TABLE_NAME)
                .pipe(takeUntil(this._destroy$), tap(config => this.setTableConfiguration(config)), switchMap(() => this.cfrRegistrationService.registrationCancellation(this.model, this.apiURL.get(type)))).subscribe(data => {
                this.model = data;
                this.model.acceptCancellation = true;
                if (this.model.reviewUnit) {
                    this.cfrRegistrationService.getPendingRegistrationCancellationUnits(this.model.accountId)
                        .pipe(takeUntil(this._destroy$), tap(units => this.units = units))
                        .subscribe(units => {
                        const unitsAvailable = units.content.length;
                        this.createForm(this.setAdminFields(this.setGlobalFields(), unitsAvailable));
                    });
                }
                else {
                    this.createForm(this.setAdminFields(this.setGlobalFields()));
                }
            });
        }
        else {
            this.doAction(type);
        }
    }
    setTableConfiguration(tableConfig) {
        this.tableConfig = tableConfig;
        const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
        const _columns = [
            ...this.tableConfig.columns,
            {
                header: 'view',
                width: AUTO_WIDTH_FOR_COLUMNS,
                templateRef: this.viewColTemplate,
            },
        ];
        this.tableConfig = Object.assign({}, config, { paginator: false }, { columns: _columns });
    }
    isValidForSubmitting() {
        const roleIsSelected = this.model.primarySupplier ||
            this.model.registeredCreator ||
            this.model.foreignSupplier ||
            this.model.carbonIntensityContributor ||
            this.isVb;
        return this.model.acceptCancellation && roleIsSelected;
    }
    doAction(type) {
        this.formFactoryService.markFormGroupDirty(this.formGroup);
        if (this.formGroup.valid) {
            this.modalService
                .open(ServiceMessageComponent, {
                message: this._getConfirmationMessage(type),
                metaDataList: [
                    this.translate.instant(`COMMON.actionsLabel.${type}`),
                    this.model.orgRoles,
                    this.model.legalName
                ],
                type: "WARNING" /* WARNING */,
            }, true, DEFAULT_DIALOG_CONFIG)
                .afterClosed()
                .subscribe((result) => {
                if (result) {
                    this.cfrRegistrationService.registrationCancellation(this.model, this.apiURL.get(type)).pipe(takeUntil(this._destroy$)).subscribe(data => {
                        this.router.navigate(this.helperService.getTranslatedPath(`/account-management/summary-admin/${this.model.accountId}`));
                    });
                }
            });
        }
    }
    buildOrgRole() {
        this.model.accountSubTypeDtoList = [];
        this.model.orgRoles = '';
        if (this.model.primarySupplier) {
            this.appendOrgRole(IApp.NSAccountSubType.StatusEnum.PS);
        }
        if (this.model.registeredCreator) {
            this.appendOrgRole(IApp.NSAccountSubType.StatusEnum.RC);
        }
        if (this.model.foreignSupplier) {
            this.appendOrgRole(IApp.NSAccountSubType.StatusEnum.FS);
        }
        if (this.model.carbonIntensityContributor) {
            this.appendOrgRole(IApp.NSAccountSubType.StatusEnum.CIC);
        }
    }
    appendOrgRoleCon(type) {
        if (this.model.orgRoles.length === 0) {
            this.model.orgRoles = this.translate.instant(`COMMON.accountSubTypeList.${type}`);
        }
        else {
            this.model.orgRoles = this.model.orgRoles + ',' + this.translate.instant(`COMMON.accountSubTypeList.${type}`);
        }
    }
    appendOrgRole(type) {
        if (this.model.accountSubTypeDtoList.length === 0) {
            this.model.accountSubTypeDtoList = [{
                    code: type,
                }];
        }
        else {
            this.model.accountSubTypeDtoList.push({
                code: type,
            });
        }
        this.appendOrgRoleCon(type);
    }
    viewUnitDetails(event, unit) {
        if (event.key && event.key !== 'Enter') {
            return;
        }
        sessionStorage.setItem(TASK_STATE, JSON.stringify(this.state));
        this.unitManagementService.viewUnitDetails(unit.unitClassCode, unit, false, 'ACTIVE');
    }
    close() {
        this.navigationService.back();
    }
    _getConfirmationMessage(action) {
        if (action === 'REJECT') {
            return 'confirmationMessageRejectRegistrationCancellation';
        }
        return this.isVb ? 'confirmationMessageRegistrationCancellationVb' :
            'confirmationMessageRegistrationCancellation';
    }
}
