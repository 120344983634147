import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@core/services/auth.service';
import { Idle } from '@ng-idle/core';
import { ModalService } from '@shared/services/modal.service';
import { interval, Subject } from 'rxjs';
import { map, share, takeUntil } from 'rxjs/operators';
import { isNull } from 'util';

export const SESSION_CHECK_INTERVAL_TIME = 1000;
export const INACTIVITY_TIMEOUT_PERIOD = 60;

@Component({
  selector: 'app-session-end',
  templateUrl: './session-end.component.html',
  styleUrls: ['./session-end.component.scss'],
})
export class SessionEndComponent implements OnInit, OnDestroy {

  public visible = true;
  public countdown = INACTIVITY_TIMEOUT_PERIOD;
  public sessionTerminated = false;

  private _destroy$ = new Subject<any>();
  private timeout$ = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SessionEndComponent>,
    private idle: Idle,
    private authService: AuthService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    if (this.data && this.data.terminated) {
      this.sessionTerminated = true;
    }
    this.dialogRef.disableClose = true;

    if (!this.sessionTerminated) {
      interval(SESSION_CHECK_INTERVAL_TIME).pipe(
        takeUntil(this._destroy$),
        takeUntil(this.timeout$),
        map(time => {
          if (isNull(time)) {
            return null;
          }

          this.countdown = INACTIVITY_TIMEOUT_PERIOD - time - 1;
          if (this.countdown <= 0) {
            this.timeout$.next();
            this.terminateSession();
          }
        }),
        share(),
      ).subscribe();
    } else {
      this.authService.stopCounters();
    }

  }

  private terminateSession() {
    this.authService.signOut().subscribe(
      () => {
        this.modalService.open(SessionEndComponent, {terminated: true});
        },
      () => {
        this.dialogRef.close();
        this.authService.resetSession();
      },
    );
  }

  reset(): void {
    this.authService.resetInactivity(true);
    this.dialogRef.close();
  }

  logout(): void {
    this.authService.signOut().subscribe(
      () => this.dialogRef.close(),
      () => this.dialogRef.close(),
    );
  }

  resetSession(): void {
    this.authService.resetSession();
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
