/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-registration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/directives/router-link.directive";
import * as i3 from "@angular/router";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@gilsdav/ngx-translate-router";
import * as i6 from "../../../../shared/directives/wcag-dummy-link-target.directive";
import * as i7 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i8 from "@fortawesome/angular-fontawesome";
import * as i9 from "@angular/platform-browser";
import * as i10 from "../../../../shared/components/stepper/stepper.component.ngfactory";
import * as i11 from "../../../../shared/components/stepper/stepper.component";
import * as i12 from "../../../../shared/services/form.service";
import * as i13 from "../../../../core/store/store.service";
import * as i14 from "../../../../shared/services/modal.service";
import * as i15 from "../../../../core/services/lookup.service";
import * as i16 from "../../../registration/registration.service";
import * as i17 from "../../../../core/services/auth.service";
import * as i18 from "../../../users-management/services/user-management.service";
import * as i19 from "../../services/account-management.service";
import * as i20 from "../../../../shared/services/helper.service";
import * as i21 from "../../../../shared/services/upload-document.service";
import * as i22 from "../../../../shared/services/document-in-progress.service";
import * as i23 from "../../../../shared/services/form-factory.service";
import * as i24 from "./account-registration.component";
var styles_AccountRegistrationComponent = [i0.styles];
var RenderType_AccountRegistrationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountRegistrationComponent, data: {} });
export { RenderType_AccountRegistrationComponent as RenderType_AccountRegistrationComponent };
export function View_AccountRegistrationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "account-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "a", [["class", "arrow-back"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterLinkDirective, [i1.Renderer2, i1.ElementRef, i3.Router, i4.TranslateService], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i5.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(4, 212992, null, 0, i6.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i4.TranslateService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "fa-icon", [["class", "float-left back-icon ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i7.View_FaIconComponent_0, i7.RenderType_FaIconComponent)), i1.ɵdid(6, 573440, null, 0, i8.FaIconComponent, [i9.DomSanitizer, i8.FaConfig, i8.FaIconLibrary, [2, i8.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(7, 2), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["style", "display: none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["back"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-stepper", [], null, [["window", "beforeunload"]], function (_v, en, $event) { var ad = true; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_StepperComponent_0, i10.RenderType_StepperComponent)), i1.ɵdid(11, 245760, null, 0, i11.StepperComponent, [i3.Router, i12.FormService, i13.StoreService, i14.ModalService, i15.LookupService, i3.ActivatedRoute, i16.RegistrationService, i17.AuthService, i18.UserManagementService, i19.AccountManagementService, i20.HelperService, i4.TranslateService, i21.UploadDocumentService, i22.DocumentUploadInProgressService, i4.TranslateService, i23.FormFactoryService], { _type: [0, "_type"], _accountId: [1, "_accountId"], inApp: [2, "inApp"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("/account-management/summary-user")); _ck(_v, 2, 0, currVal_0); _ck(_v, 4, 0); var currVal_3 = _ck(_v, 7, 0, "fas", "chevron-left"); _ck(_v, 6, 0, currVal_3); var currVal_4 = _co.type; var currVal_5 = _co.accountId; var currVal_6 = true; _ck(_v, 11, 0, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 6).title; var currVal_2 = i1.ɵnov(_v, 6).renderedIconHTML; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_AccountRegistrationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-registration", [], null, null, null, View_AccountRegistrationComponent_0, RenderType_AccountRegistrationComponent)), i1.ɵdid(1, 114688, null, 0, i24.AccountRegistrationComponent, [i4.TranslateService, i3.ActivatedRoute, i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountRegistrationComponentNgFactory = i1.ɵccf("app-account-registration", i24.AccountRegistrationComponent, View_AccountRegistrationComponent_Host_0, {}, {}, []);
export { AccountRegistrationComponentNgFactory as AccountRegistrationComponentNgFactory };
