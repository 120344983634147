import { Component, Inject,OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact-support-modal',
  templateUrl: './contact-support-modal.component.html',
  styleUrls: ['./contact-support-modal.component.scss'],
})
export class ContactSupportModalComponent  implements OnInit{

  public visible = true;
  title :string;
  message :any;
  labelPrefix: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ContactSupportModalComponent>,
    private translate :TranslateService,
    private storeService: StoreService
  ) { }

  ngOnInit() {
    this.labelPrefix = this.data ? this.data : this.storeService.user.getProgramPrefix();
    this.storeService.gerProgramAdminEmail().subscribe(
      prop => {
        this.message= this.translate.instant(`COMMON.actionsLabel.contactMessageEmail`);
        this.message=  this.message.replaceAll(`{{paEmail}}`, prop.value);
       
      }
    );
  }


  close(): void {
    this.dialogRef.close();
  }

}
