import { ServiceMessageComponent } from "@core/components/service-message/service-message.component";
import { DEFAULT_DIALOG_CONFIG } from "@core/constants/serviceMessage.const";
import { environment } from "@env/environment";
import { BaseStepperService } from "@shared/components/base-stepper/base-stepper.service";
import { BehaviorSubject, from } from "rxjs";
import { switchMap, filter, tap } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../shared/services/upload-document.service";
import * as i3 from "../../../../shared/services/modal.service";
export const ACCOUNT_DETAILS = '/account-service/account/detail/';
export const TRANSFEREE_DETAILS = '/account-service/agreementTuc/searchTransferee/';
export const SAVE_AGREEMENT = '/account-service/agreementTuc/save';
export const SAVE_AND_PROCEED_AGREEMENT = '/account-service/agreementTuc/saveAndProceed';
export const UPDATE_AGREEMENT_STATUS = '/account-service/agreementTuc/updateStatus';
export const LOAD_AGREEMENT_BY_ID = '/account-service/agreementTuc/load';
export const GET_REGISTRATION_REPORT = '/account-service/cfrAccount/getRegistrationReport/';
export const VALIDATE_AGREEMENT_REQUEST = '/account-service/agreementTuc/validate/';
export const AGREEMENT_TUC_DATA_KEY = 'agreement_tuc_data';
export const AGREEMENT_TUC_CURRENT_STEP_KEY = 'agreement_tuc_step';
const STEPS_TRANSLATION_PREFIX = 'SUBMISSION_MANAGEMENT_MODULE.AGREEMENT_TO_CREDIT.';
export class AgreementToTransferCreditService extends BaseStepperService {
    constructor(http, uploadDocumentService, modalService) {
        super();
        this.http = http;
        this.uploadDocumentService = uploadDocumentService;
        this.modalService = modalService;
        this.translationPrefix = STEPS_TRANSLATION_PREFIX;
        this.stepperTitle = 'SUBMISSION_MANAGEMENT_MODULE.AGREEMENT_TO_CREDIT.pageTitleCreateForm';
        this.carbonIntensityIds$ = new BehaviorSubject([]);
        this.footerButtonConfig = {};
        this.fuelList = [];
    }
    getAccountByID(id) {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_DETAILS}${id}`).pipe(tap(data => {
            this.currentData.transferorAccount = Object.assign({}, data, { accountId: data.id });
        }));
    }
    getTransfereeDetails(id) {
        return this.http.get(`${environment.apiUrl}${TRANSFEREE_DETAILS}${id}`).pipe(tap(data => {
            this.currentData.transfereeAccount = Object.assign({}, data, { accountId: data.id });
        }));
    }
    save(payload) {
        payload = Object.assign({}, payload, { action: 'SAVE' });
        return this.http.post(`${environment.apiUrl}${SAVE_AND_PROCEED_AGREEMENT}`, payload);
    }
    saveDraft(payload) {
        payload = Object.assign({}, payload, { action: 'SAVE' });
        return this.http.post(`${environment.apiUrl}${SAVE_AGREEMENT}`, payload);
    }
    updateStatus(payload) {
        return this.http.post(`${environment.apiUrl}${UPDATE_AGREEMENT_STATUS}`, payload);
    }
    submit() {
        return this.http.post(`${environment.apiUrl}${SAVE_AGREEMENT}`, Object.assign({}, this.currentData, { action: 'SUBMIT' }));
    }
    getAgreementByID(id) {
        return this.http.get(`${environment.apiUrl}${LOAD_AGREEMENT_BY_ID}/${id}`);
    }
    getRegistrationReport(accountId) {
        return this.http.get(`${environment.apiUrl}${GET_REGISTRATION_REPORT}${accountId}`);
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.currentData));
        sessionStorage.setItem(AGREEMENT_TUC_DATA_KEY, data);
        const currentStep = JSON.stringify(this.currentStep);
        sessionStorage.setItem(AGREEMENT_TUC_CURRENT_STEP_KEY, currentStep);
    }
    checkForStoredData() {
        const currentData = sessionStorage.getItem(AGREEMENT_TUC_DATA_KEY);
        const currentStep = sessionStorage.getItem(AGREEMENT_TUC_CURRENT_STEP_KEY);
        if (currentData && currentStep) {
            this.currentData = JSON.parse(currentData);
            this.currentStep = JSON.parse(currentStep);
            sessionStorage.removeItem(AGREEMENT_TUC_DATA_KEY);
            sessionStorage.removeItem(AGREEMENT_TUC_CURRENT_STEP_KEY);
            return true;
        }
        return false;
    }
    checkIfSaveRequired() {
        return !(this.currentData.transferorConsent || this.currentData.transfereeConsent);
    }
    downloadAgreement(id, signed) {
        let uri = `${environment.serviceUrl}/account-service/agreementTuc/download/${id}`;
        if (signed) {
            uri = uri + '?signed=true';
        }
        this.uploadDocumentService.downloadDocument(uri);
    }
    openWarningPopUp(message, metaDataList = []) {
        return this.modalService.open(ServiceMessageComponent, {
            messages: null,
            message,
            metaDataList,
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    validateAgreementRequest(accountId) {
        return this.http.get(`${environment.apiUrl}${VALIDATE_AGREEMENT_REQUEST}${accountId}`).pipe(switchMap(result => {
            if (result) {
                return from([true]);
            }
            else {
                return this.showErrorMessage('applicationNotAllowedForActivity');
            }
        }), filter(result => !!result));
    }
    showErrorMessage(message) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                },
            ],
            message: message,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
}
AgreementToTransferCreditService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgreementToTransferCreditService_Factory() { return new AgreementToTransferCreditService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UploadDocumentService), i0.ɵɵinject(i3.ModalService)); }, token: AgreementToTransferCreditService, providedIn: "root" });
