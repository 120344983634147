import { IApp } from '@core/models/app.interfaces';
import { TABLE_FILTER_SETTINGS_KEY } from '@shared/components/table-filter/table-filter.const';
import { ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
export const PROCESS_REPORT = '/processReport';
export const REPORT_VERIFICATION_COMPONENTS = {
  ISSUE_STATEMENT_COMPONENT: 'IssueStatementComponent',
  PATHWAY_INFORMATION: 'ReportDocumentComponent',
};

export const ALL= 'ALL' ;
export const STATUS_CONFIG_MAP = {
  UNDER_VERIFICATION: {
    irDetails: { show: false, userTypes: ALL },
    irComments: { show: true, userTypes: 'TYPE_VB_LV' },
    pvbLvDetails:  { show: false, userTypes: ALL },
    pvbIrDetails:  { show: false, userTypes: ALL },
  },
  UNDER_VERIFICATION_IR_REVIEW : {
    irDetails: { show: true, userTypes: 'TYPE_VB_IR' , enable: true},
    irComments: { show: true, userTypes: 'TYPE_VB_IR', enable: true },
    pvbLvDetails:  { show: false, userTypes: ALL },
    pvbIrDetails:  { show: false, userTypes: ALL },
  },
  UNDER_VERIFICATION_PVB_LV_REVIEW: {
    irDetails: { show: true, userTypes: ALL, enable: false },
    pvbLvDetails:  { show: true, userTypes: 'TYPE_PVB_LV', enable: true },
    pvbIrDetails:  { show: false, userTypes: ALL },
  },
  UNDER_VERIFICATION_PVB_IR_REVIEW: {
    irDetails: { show: true, userTypes: ALL, enable: false },
    pvbLvDetails:  { show: true, userTypes: ALL , enable: false},
    pvbIrDetails:  { show: true, userTypes: 'TYPE_PVB_IR' , enable: true},
  },
  VERIFICATION_COMPLETE: {
    irDetails: { show: true, userTypes: ALL , enable: false},
    pvbLvDetails:  { show: true, userTypes: ALL , enable: false},
    pvbIrDetails:  { show: true, userTypes: ALL , enable: false},
  }
};

export const REVIEW = '/review';

export const CANCELLATION = {
  action: 'CANCEL',
  uiUri: '/submission-management/cancel-application',
  uri: '/reviewCancellation',
  type: 'CI_PATHWAY_CANCELLATION',
  id: 'cancellationId',
  status: 'cancellationStatus',
  prefix: 'cancel',
};

export const REVIEW_RELEASE = {
  action: 'SUBMIT',
  uiUri: '/submission-management/release-application',
  type: 'CI_PATHWAY_RELEASE',
  uri: '/reviewRelease',
  id: 'releaseId',
  status: 'releaseStatus',
  prefix: 'release',
};

export const RELEASE = {
  action: 'RELEASE',
  uiUri: '/submission-management/release-application',
  type: 'CI_PATHWAY_RELEASE',
  uri: '/reviewRelease',
 // id: 'releaseId',
 // status: 'releaseStatus',
  prefix: 'release',
};

export const STATEMENT = {
  uiUri: '/submission-management/wizard/statement/${vbIdentifier}/${id}',
  params: ['vbIdentifier', 'id'],
};

export const VIEW_VERIFICATION_ASSIGNMENT = {
  uiUri: '/submission-management/assignment/${vbIdentifier}/${id}',
  params: ['vbIdentifier', 'id'],
};

export const VIEW_TL_IR ={
  uiUri: '/submission-management/team-assignment/${vbIdentifier}/${id}',
  params: ['vbIdentifier', 'id'],
};

export const VERIFICATION_MANAGEMENT_ACTIONS = {
  VIEW_VERIFICATION_ASSIGNMENT,
  REVIEW_VERIFICATION_ASSIGNMENT: VIEW_VERIFICATION_ASSIGNMENT,
  IR_TL_ASSIGN: VIEW_TL_IR,
  VIEW_TL_IR,
  REVIEW_TL_IR: VIEW_TL_IR,
  APPROVE: {
    action: 'APPROVE',
    uri: REVIEW,
  },

  NEED_MORE_INFORMATION: {
    action: 'NEED_MORE_INFO',
    uri: REVIEW,
  },
  START_VERIFICATION_PASS: {
    action: 'START_VERIFICATION',
    uri: PROCESS_REPORT,
    actionLabel: 'startTheVerificationFor',
  },
  ISSUE_VFB_STATEMENT: STATEMENT,
  ISSUE_VLB_STATEMENT: STATEMENT,
  REVIEW_VFB_STATEMENT: STATEMENT,
  REVIEW_VLB_STATEMENT: STATEMENT,
  VIEW_VFB_STATEMENT: STATEMENT,
  VIEW_VLB_STATEMENT: STATEMENT,
  IR_APPROVE_CVB: {
    action: 'IR_APPROVE_CVB',
    uri: PROCESS_REPORT,
    actionLabel: 'submitToCfrParticipantFor',
    nextStep: true,
  },
  IR_APPROVE_PVB: {
    action: 'IR_APPROVE_PVB',
    uri: PROCESS_REPORT,
    actionLabel: 'submitToCfrParticipantFor'
  },
  IR_REJECT: {
    action: 'IR_REJECT',
    uri: PROCESS_REPORT,
    actionLabel: 'reject',
  },
  PVB_LV_APPROVE: {
    action: 'PVB_LV_APPROVE',
    uri: PROCESS_REPORT,
    actionLabel: 'submitToCfrParticipantFor'
  },
  MODIFY_VFB_STATEMENT_PASS:{
    action: 'REQUEST_MODIFICATION',
    uri: PROCESS_REPORT,
    confirmationMessage: 'vbModificationConfirmationMessage'
  },
  NEED_REVIEW: {
    action: 'NEED_REVIEW',
    uri: PROCESS_REPORT,
    confirmationMessage :'confirmationMessageVbReportClarification'
  },
  SAVE_COMMENT: {
    action: 'SAVE_COMMENT',
    uri: PROCESS_REPORT,
    confirmationMessage : 'confirmationMessageVbReportSaveRemark',
  },
  RESPOND: {
    action: 'RESPOND',
    uri: PROCESS_REPORT,
    confirmationMessage :'confirmationMessageVbReportClarificationResponse'
  },
  VIEW_APPLICATION: {
    action: 'CANCEL',
    uiUri: '/submission-management/wizard/application/',
    type: 'CI_PATHWAY_CANCELLATION',
    uri: '/cancel',
    id: 'entityId',
    status: null,
    prefix: 'cancel',
  },
};

export const TEST_DATA = {
 status: 'UNDER_VERIFICATION',
 showReview: false,
 legalName: 'PD PS 4 Mar',
 applicationId: 'CFR-ERPApp-20210406--NEW-G274T',
 submissionType: 'CO2E_EMISSION_REDUCTION',
 complianceYear: 2021,
 responsiblePerson: 'Papul Das',
 summary: 'Lorem Ipsum',
 verificationBody: { // read only
   legalName: 'PD VB 7 MAR',
   location: 'CN',
   leadVerifierName: 'PD Lead Verifier',
   independentReviewer: 'PD Independent Reviewer',

   teamMembers: {
    expert: 'Team Member 1,Team Member 2',
    verifier :  'Team Member 3',
  },
   outsourcedTeamMembers: {
    expert: 'OS Team Member 1, OS Team Member 2',
    verifier :  'OS Team Member 3',
  },
 },
  associatedMethodology: true,
  scopeId: 1,
  siteVisitLocation: 'Ontario',
  siteVisitDate: '2021-01-01',
  previousSiteVisitDate: '2020-01-01',
  vbStandards: [3, 4],
  description: 'some more lorem ipsum',
  opinionId: 6,
  threatId: 10,
  opinionReason: 'The standards were not met',
  scopeLimitation: 'Again the standards were not met',
  disagreementCriteria: 'scopeLimitation Again the standards were not met',
  ministerInformedDate: '2020-01-01',
  actvitiesContinuedDate: '2020-01-01',
  declarations: [
    {
      type: 'TL',
      agreed: true,
      signature: 'PD Lead Verifier',
      signedDate: '2021-07-04',
    },
    {
      type: 'IR',
      agreed: true,
      signature: 'PD Independent Reviewer',
      signedDate: '2021-07-04',
      opinion: 'AGREE',
      opinionId: 12,
      description: 'I see discrepancies',
    },

  ],

};

export const VERIFICATION_WORKFLOW_ACTIONS = {
    ASSIGN_VERIFICATION: 'ASSIGN_VB',
    VIEW_VERIFICATION_ASSIGNMENT: 'VIEW_VERIFICATION_ASSIGNMENT',
    VIEW_VERIFICATION: 'VIEW_ASSIGNED_VB',
    REVIEW_VERIFICATION: 'REVIEW_VERIFICATION_ASSIGNMENT',
    TEAM_LEAD_ASSIGN: 'TEAM_LEAD_ASSIGN',
    CREATE_NOTICE_OF_COI: 'CREATE_COI_NOTICE',
    CREATE_COI_CHECKLIST: 'CREATE_COI_CHECKLIST',
    TEAM_LEAD_REPLACE: 'REVIEW_TL_IR',
    VIEW_ASSOCIATED_COI_NOTICES: 'VIEW_ASSOCIATED_COI_NOTICES',
    REVIEW_COI_CHECKLIST: 'REVIEW_COI_CHECKLIST',
    REPLACE_VB: 'REPLACE_VB',
    REQUEST_ACCESS_PREVIOUS_APPLICATIONS: 'REQUEST_ACCESS_PREVIOUS_APPLICATIONS',
    VIEW_PREVIOUS_APPLICATIONS: 'VIEW_PREVIOUS_APPLICATIONS',
};

export const VERIFICATION_ASSIGNMENT_TYPE = {
  INDEPENDENT_REVIEWER: 'INDEPENDENT_REVIEWER',
  VERIFICATION_TEAM_MEMBER: 'VERIFICATION_TEAM_MEMBER',
  OUTSOURCED_TEAM_MEMBER: 'OUTSOURCED_TEAM_MEMBER',
}

export const TEAM_MEMBER_OBJECT = 'teamMemberObj';

export const REPLACE_VB_CONFIG: ITableViewConfig = {
  translationPrefix: 'SUBMISSION_MANAGEMENT_MODULE.verificationBodyStep',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/cfr-service/verification/replaceVB',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    }, {
      field: 'replaceReason',
      header: 'replaceVBHeader',
      sortField: 'replaceReason',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
    },
  ],
};

export const VB_REQUEST_ENTITY_ACCESS_CONFIG: ITableViewConfig = {
  translationPrefix: 'SUBMISSION_MANAGEMENT_MODULE.verificationBodyStep',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  editService: '/cfr-service/verification/vbRequestEntityAccess',
  columns: [
    {
      field: 'id',
      header: 'id',
      sortField: 'id',
      width: '200px',
      editable: false,
      fieldType: 'readonly-input',
      inputType: 'number',
      editRowField: false,
    }, {
      field: 'vbAccessRequestedEntities',
      header: 'vbAccessRequestedEntities',
      sortField: 'vbAccessRequestedEntities',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
    },
  ],
};

export function resetSelectUserTableFilter() {
  try {
    let filterSettings = sessionStorage.getItem(TABLE_FILTER_SETTINGS_KEY) || '{}';
    filterSettings = filterSettings ? JSON.parse(filterSettings) : {};
    if (filterSettings['SelectUserTableComponent']) {
      filterSettings['SelectUserTableComponent']['professionalQualifications'] = [];
      filterSettings['SelectUserTableComponent']['freeText'] = '';
    }
    if (filterSettings['ct']) {
      filterSettings['ct']['professionalQualifications'] = [];
      filterSettings['ct']['freeText'] = '';
    }
    sessionStorage.setItem(TABLE_FILTER_SETTINGS_KEY, JSON.stringify(filterSettings));
  } catch (error) {
    console.error('An error occurred while resetting table filter:', error);
  }
}
