<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1000px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header] = "translationPrefix + '.addDetailsTitle' | translate"
  (onShow)="scrollToTop()">

  <form [formGroup]="form" *ngIf="!data.viewMode">
    <formly-form [form]="form" [model]="model" [fields]="searchField"></formly-form>
  </form>

  <div *ngIf="organizationDetail">
    <div class="row mb-3">
      <div class="col-12">
        <p id="org-name">{{organizationDetail.legalName}} ({{organizationDetail.accountId}})</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        {{'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.orgAddressLabel' | translate }}:
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6" *ngIf="organizationDetail.postalAddress && organizationDetail.postalAddress.country">
        <app-address-view
          [legend]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.postalAddress' | translate"
          [address]="organizationDetail.postalAddress" >
        </app-address-view>
      </div>
      <div class="col-6" *ngIf="organizationDetail.civicAddress && organizationDetail.civicAddress.country">
        <app-address-view
          [legend]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.civicAddress' | translate"
          [address]="organizationDetail.civicAddress">
        </app-address-view>
      </div>
    </div>

    <form [formGroup]="form" >
      <formly-form [form]="form" [model]="model" [fields]="addressFields" (change)="stepModified = true"></formly-form>
    </form>

    <form [formGroup]="form">
      <formly-form [form]="form" [model]="model" [fields]="quantityFields" (change)="stepModified = true"></formly-form>
    </form>

  </div>
  <div *ngIf="isSearched && !organizationDetail" id="org-status">
    <p>{{ translationPrefix + '.' + orgErrorMsg | translate}}</p>
  </div>


  <div class="d-flex justify-content-end mt-2">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{ translationPrefix + '.close' | translate}}</button>
    <button type="button"
            *ngIf="!data.viewMode"
            disableOnUpload
            class="btn btn-primary"
            (click)="save()">
      {{ translationPrefix + '.save' | translate}}
    </button>
  </div>
</p-dialog>
