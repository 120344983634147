/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entity-history-list-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../table-view/table-view.component.ngfactory";
import * as i3 from "../table-view/table-view.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../pipes/custom-translate.pipe";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i8 from "primeng/dialog";
import * as i9 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i10 from "primeng/api";
import * as i11 from "./entity-history-list-modal.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "@angular/router";
import * as i14 from "../../services/helper.service";
import * as i15 from "../../../modules/submission-management/services/submission-management.service";
var styles_EntityHistoryListModalComponent = [i0.styles];
var RenderType_EntityHistoryListModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EntityHistoryListModalComponent, data: {} });
export { RenderType_EntityHistoryListModalComponent as RenderType_EntityHistoryListModalComponent };
function View_EntityHistoryListModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], [null, "checkboxSelected"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("checkboxSelected" === en)) {
        var pd_2 = (_co.selectionChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_TableViewComponent_0, i2.RenderType_TableViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.TableViewComponent, [i1.ElementRef, i4.TranslateService], { data: [0, "data"], config: [1, "config"], firstItemIndex: [2, "firstItemIndex"], selections: [3, "selections"] }, { paginationChanged: "paginationChanged", checkboxSelected: "checkboxSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataList; var currVal_1 = _co.config; var currVal_2 = _co.firstItemIndex; var currVal_3 = _co.selections; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_EntityHistoryListModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "align-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.custom_messages.noItems")); _ck(_v, 2, 0, currVal_0); }); }
function View_EntityHistoryListModalComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "align-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.custom_messages.pleaseWait")); _ck(_v, 1, 0, currVal_0); }); }
function View_EntityHistoryListModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntityHistoryListModalComponent_3)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["LOADING", 2]], null, 0, null, View_EntityHistoryListModalComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalElements === 0); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EntityHistoryListModalComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.approve() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.vbRejectAccessRequest")); _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.selections.length; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("COMMON.actionsLabel.vbApproveAccessRequest")); _ck(_v, 5, 0, currVal_2); }); }
function View_EntityHistoryListModalComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.view($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.view($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.view")); _ck(_v, 2, 0, currVal_0); }); }
export function View_EntityHistoryListModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewColTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "p-dialog", [], [[8, "className", 0]], null, null, i7.View_Dialog_0, i7.RenderType_Dialog)), i1.ɵdid(2, 180224, null, 2, i8.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, null), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntityHistoryListModalComponent_1)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_DATA", 2]], null, 0, null, View_EntityHistoryListModalComponent_2)), (_l()(), i1.ɵeld(11, 0, null, 2, 8, "p-footer", [], null, null, null, i9.View_Footer_0, i9.RenderType_Footer)), i1.ɵdid(12, 49152, [[3, 4]], 0, i10.Footer, [], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 6, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 5, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntityHistoryListModalComponent_5)), i1.ɵdid(19, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[1, 2], ["viewColTemplate", 2]], null, 0, null, View_EntityHistoryListModalComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform(_co.header)); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = true; var currVal_9 = _ck(_v, 6, 0, "800px"); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = (_co.totalElements > 0); var currVal_11 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_10, currVal_11); var currVal_13 = _co.editMode; _ck(_v, 19, 0, currVal_13); }, function (_ck, _v) { var currVal_0 = "eccc"; _ck(_v, 1, 0, currVal_0); var currVal_12 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 16, 0, currVal_12); }); }
export function View_EntityHistoryListModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-entity-history-list-modal", [], null, null, null, View_EntityHistoryListModalComponent_0, RenderType_EntityHistoryListModalComponent)), i1.ɵdid(1, 114688, null, 0, i11.EntityHistoryListModalComponent, [i12.MAT_DIALOG_DATA, i12.MatDialogRef, i13.Router, i14.HelperService, i15.SubmissionManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntityHistoryListModalComponentNgFactory = i1.ɵccf("app-entity-history-list-modal", i11.EntityHistoryListModalComponent, View_EntityHistoryListModalComponent_Host_0, {}, {}, []);
export { EntityHistoryListModalComponentNgFactory as EntityHistoryListModalComponentNgFactory };
