import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { TranslateService } from '@ngx-translate/core';
import { subProjectTableConfigMap } from '@shared/components/project-builder/sub-project-list/sub-project-list.const';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

const PROJECT_RELATED_ACTION_LABELS = [
  'TRANSFER_PROJECT',
  'CANCEL_PROJECT',
  'REQUEST_CANCELLATION'
];

@Component({
  selector: 'app-sub-project-list',
  templateUrl: './sub-project-list.component.html',
  styleUrls: ['./sub-project-list.component.scss'],
})
export class SubProjectListComponent implements OnInit, OnDestroy {

  @Input() data: any; // TODO this needs to be specified
  @Input() tableConfigName: any;
  @Input() relative = false;

  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;

  private _destroy$ = new Subject<any>();
  tableConfig: ITableViewConfig;

  constructor(
    private agreementCreditsService: AgreementCreditsService,
    private projectService: ProjectService,
    private tableTemplateControls: TableTemplateControls,
    private translateService: TranslateService,
    private lookupService: LookupService,
    public helperService: HelperService,
    public modalService: ModalService,
    private _location: Location,
    private store: StoreService
  ) { }

  ngOnInit() {
    this._alterSubProjectActionLabels();
    this.tableConfiguration();
  }

  executeActions() {
    this.tableTemplateControls._action.pipe(
      take(1),
    ).subscribe(metadata => {
      // TODO add logic
    });
  }

  tableConfiguration() {
    this.tableConfig = subProjectTableConfigMap[this.tableConfigName];

    const { columns, ...config } = this.tableConfig;

    const _columns: any = [
       ...this.tableConfig.columns,
      {
        header: 'details',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.viewColTemplate,
      }, {
        header: 'actions',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.actionTemplate,
      },
    ];

    this.tableConfig = {
      ...config,
      ...{ columns: _columns },
    };
  }

  view($event, data) {
    /*if(data.pendingTransferRequestId){
      this.projectService.redirectToTransferProject(data.masterProjectId, data.id, data);
    }else{
      this.projectService.redirectToSubProject(data.masterProjectId, data.id);

    }*/
    this.projectService.redirectToSubProject(data.masterProjectId, data.id);

  }

  showView(data){
    var show = true;

    if(this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) return true;

    var found = false;
    this.store.user.associationsList.forEach(acc =>{
      if (acc.accountId === data.accountId) {
        found = true;
      }
    })

    if(found) {
      show = true
    } else {
      show =false
    }

    if(data.entityActionList === null) {
      show = false;
    }

    return show
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _alterSubProjectActionLabels() {
    this.data.forEach(s => {
      if(s.entityActionList) {
        s.entityActionList.forEach(a => {
          if (PROJECT_RELATED_ACTION_LABELS.includes(a.workflowAction)) {
            // set a suffix so Project becomes Sub-Project corresponding to translation config
            a.workflowAction = a.workflowAction + '_SUB';
          }
        });
      }
    });
  }

}
