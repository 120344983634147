import { Component, OnDestroy, OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { randomStr } from '@core/utilities/utilities.constants';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'formly-field-multi-checkbox',
  template: `
    <style>
      .inline {
        display: flex;
        flex-wrap: wrap;
      }
      .inline .p-field-checkbox {
        display: flex;
        flex: 0 0 20%;
        padding-right: 10px;
        padding-bottom: 15px;
      }
      .inline .p-field-checkbox label {
        right: 0;
        left: 20px;
        margin-top: 1px;
      }
    </style>
    <label *ngIf="to.dummyLabel" class="wcag-visuallyhidden" [for]="itemLabelPrefix + elementId">Label</label>
    <input type="text" class="wcag-visuallyhidden" [id]="itemLabelPrefix + elementId" tabindex="-1">
    <div [class.inline]="inline">
      <fieldset>
        <legend class="wcag-visuallyhidden">{{ itemLabelPrefix + elementId }}</legend>
      <div class="p-field-checkbox"
           *ngFor="let item of items">
        <p-checkbox [name]="elementId + '_checkbox_group'"
                    [value]="item.id"
                    [disabled]="item.disabled"
                    [formControl]="formControl"
                    [class.inline-checkbox]="inline"
                    [inputId]="itemLabelPrefix + elementId + '_item_' + item.id"
                    [label]="item.name"
                    (onChange)="change(field, $event)">
        </p-checkbox>
          <ng-container *ngIf="item.value">
            <fa-icon
              pTooltip="{{ item.value }}"
              tooltipPosition="top"
              [escape]="false"
              [icon]="['fas', 'info-circle']">
            </fa-icon>
          </ng-container>
      </div>
      </fieldset>
    </div>
  `,
})
export class FormlyFieldMultiCheckbox extends FieldType implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  isLoading = false;
  items = [];
  source: Observable<IApp.IDict[]>;
  fluxSource: Observable<IApp.IFluxDict>;
  dataProvided: boolean;
  clearable: boolean;
  skipSort: boolean;
  public notFoundText: string;
  public placeholder: string;

  public translatePrefix;

  inline = false;

  constructor(
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.translatePrefix = this.to.translatePrefix ? `${ this.to.translatePrefix }.` : '';
    this.source = this.to.source;
    this.fluxSource = this.to.fluxSource;
    this.dataProvided = this.to.dataProvided;
    this.inline = this.to.inline;
    this.skipSort = this.to.skipSort || false;

    this.loadSource();
    this.translateService.onLangChange
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.loadSource());

    if (this.to.reload$) {
      this.to.reload$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.loadSource());
    }
  }

  private loadSource() {
    if (this.fluxSource) {
      this.loadFluxData();
    } else if (this.dataProvided) {
      this.loadProvidedData();
    } else {
      this.loadData();
    }
  }

  get disabled() {
    return this.to.disabled;
  }

  get itemLabelPrefix(): string {
    return this.to.itemLabelPrefix ? `${this.to.itemLabelPrefix}_${randomStr}_` : `${randomStr}_`;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.field.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  private loadData() {
    this.isLoading = true;
    this.source
    .pipe(
      takeUntil(this.onDestroy$),
      first(),
      map((data: IApp.IDict[]) => {
        if (this.to.translateOptions) {
          const translated = data.map(el => ({
            ...el,
            name: this.translateService.instant(`${this.translatePrefix}${el[this.to.bindLabel || 'name']}`),
          }));
          return this.skipSort ? translated : translated.sort((a,b) => a.name.localeCompare(b.name));
        } else {
          return data;
        }
      }),
    )
      .subscribe(data => {
        console.log(data);
        this.items = [...data];
          if (this.field.key === 'accountId') {
            this.to.change && this.to.change(this.field, { id: this.model[this.field.key],
              'certificateNumber': this.model['certificateNumber'],
              initial: true });
          } else {
            this.to.change && this.to.change(this.field, { id: this.model[this.field.key], initial: true });
          }
        this.isLoading = false;
      },
        err => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        });
  }

  private loadFluxData() {
    this.isLoading = true;
    this.fluxSource
      .pipe(
        map((data: IApp.IFluxDict) => {
          if (this.to.translateOptions) {
            return data.roleDtos.map(el => ({
              ...el,
              name: this.translateService.instant(`${this.translatePrefix}${el[this.to.bindLabel || 'name']}`),
            }));
          } else {
            return data.roleDtos;
          }
        }),
      )
      .subscribe(data => {
      this.items = data;
      this.isLoading = false;
    });
  }

  private loadProvidedData() {
    this.items = this.to.items;
  }

  get label() {
    let label = '';
    if (this.to.required) {
      label = label + 'Required field, ';
    }
    label += this.to.placeholder === 'selectItem' ? this.to.label : this.placeholder;
    return label;
  }

  change(field, data) {
    this.to.change && this.to.change(this.field, this.model[this.field.key]);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
