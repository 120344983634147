import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { environment } from '@env/environment';
import { CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY, CREDIT_BALANCE_REPORT_DATA_KEY, CREDIT_BALANCE_REPORT_PREFIX, CREDIT_BALANCE_REPORT_STEP_PREFIX, CREDIT_BALANCE_STEP_COMPONENT_MAP } from '@module/credit-balance/credit-balance-report.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { Subject } from 'rxjs';
import { downloadBlob } from '@core/utilities/utilities.constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/modal.service";
import * as i3 from "../../shared/services/upload-document.service";
import * as i4 from "../../core/store/store.service";
export const CREATE_CREDIT_BALANCE_REPORT = '/cfr-service/creditBalanceReport/create';
export const GET_REPORT_BY_ID = '/cfr-service/creditBalanceReport/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/creditBalanceReport/getReportToResubmit/';
export const CB_REP_SAVE = '/cfr-service/creditBalanceReport/save';
export const CB_REP_DISCARD = '/cfr-service/creditBalanceReport/discard';
export const CB_REP_PROPOSE = '/cfr-service/creditBalanceReport/proposeCreditBalanceReport';
export const CB_REP_CHECK_CALCULATION = '/cfr-service/creditBalanceReport/isCreditCalculationCorrect';
export const CB_REP_DOWNLOAD_REPORT = '/cfr-service/creditBalanceReport/download/report/';
export class CreditBalanceReportService extends BaseStepperService {
    constructor(http, modalService, uploadDocumentService, store) {
        super();
        this.http = http;
        this.modalService = modalService;
        this.uploadDocumentService = uploadDocumentService;
        this.store = store;
        this.stepRoutineMap = {};
        this.currentStepInvalid = false;
        this.updateSummary$ = new Subject();
        this.translationPrefix = `${CREDIT_BALANCE_REPORT_PREFIX}${CREDIT_BALANCE_REPORT_STEP_PREFIX}`;
        this.updateSteps$ = new Subject();
    }
    storeCurrentData() {
        const data = JSON.stringify(Object.assign({}, this.currentData));
        sessionStorage.setItem(CREDIT_BALANCE_REPORT_DATA_KEY, data);
        const step = JSON.stringify(Object.assign({}, this.currentStep));
        sessionStorage.setItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY, step);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(CREDIT_BALANCE_REPORT_DATA_KEY);
        if (data) {
            !skipRemove && sessionStorage.removeItem(CREDIT_BALANCE_REPORT_DATA_KEY);
            return JSON.parse(data);
        }
        else {
            return null;
        }
    }
    restoreLastStep() {
        const currentStep = sessionStorage.getItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
        if (currentStep) {
            sessionStorage.removeItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
            return JSON.parse(currentStep);
        }
        else {
            return null;
        }
    }
    createCreditBalanceReport(payload) {
        payload.id = null;
        return this.http.post(`${environment.apiUrl}${CREATE_CREDIT_BALANCE_REPORT}`, payload);
    }
    setReportSteps(omitStep = false) {
        let associatedSteps = [];
        if (omitStep) {
            associatedSteps = this.currentData.associatedSteps.filter(step => step.code !== 'STEP-2');
        }
        else {
            associatedSteps = this.currentData.associatedSteps.slice();
        }
        const steps = associatedSteps.map((step, index) => {
            return this.getStepContent(step, index);
        });
        this.steps = [...steps];
        return [...steps];
    }
    getStepContent(step, index) {
        let component = CREDIT_BALANCE_STEP_COMPONENT_MAP[step.code];
        const _step = Object.assign({}, step, { name: `.${step.code}_Name`, completed: false, id: index + 1, component });
        _step.id = index + 1;
        return _step;
    }
    getReportById(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID}${reportId}`);
    }
    getReportByIdToReSubmit(reportId) {
        return this.http.get(`${environment.apiUrl}${GET_REPORT_BY_ID_TO_RESUBMIT}${reportId}`);
    }
    saveReport(payload) {
        return this.http.post(`${environment.apiUrl}${CB_REP_SAVE}`, Object.assign({}, payload, { action: 'SAVE', saveAndProceed: false }));
    }
    saveAndProceed(payload) {
        return this.http.post(`${environment.apiUrl}${CB_REP_SAVE}`, Object.assign({}, payload, { action: 'SAVE', saveAndProceed: true }));
    }
    checkCalculations(payload) {
        return this.http.post(`${environment.apiUrl}${CB_REP_CHECK_CALCULATION}`, Object.assign({}, payload, { action: 'SAVE', saveAndProceed: true }));
    }
    submitReport(payload, action = 'SUBMIT', propose = false) {
        if (this.store.user.roleName === 'ACCOUNT_HOLDER_TRANSACTION_PROPOSER') {
            return this.http.post(`${environment.apiUrl}${CB_REP_PROPOSE}`, Object.assign({}, payload, { action: 'PROPOSE' }));
        }
        else {
            return this.http.post(`${environment.apiUrl}${CB_REP_SAVE}`, Object.assign({}, payload, { action }));
        }
    }
    discard(payload) {
        return this.http.post(`${environment.apiUrl}${CB_REP_DISCARD}`, Object.assign({}, payload, { action: 'DISCARD' }));
    }
    openConfirmationModal(msg, metaDataList) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message: msg,
            type: "WARNING" /* WARNING */,
            metaDataList,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    showErrorMessage(message, metaDataList = []) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                { message },
            ],
            message,
            metaDataList,
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    downloadDocument(documentId) {
        const uri = environment.serviceUrl + CB_REP_DOWNLOAD_REPORT + documentId;
        this.http.get(uri, { observe: 'response', responseType: 'blob', })
            .subscribe((response) => {
            downloadBlob(response);
        }, () => this.http.get(uri, { observe: 'response' }).subscribe());
    }
    triggerAction(actionData) {
        if (actionData.action.workflowAction === 'DOWNLOAD_CREDIT_BALANCE_REPORT') {
            this.downloadDocument(actionData.data.id);
        }
    }
}
CreditBalanceReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditBalanceReportService_Factory() { return new CreditBalanceReportService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.UploadDocumentService), i0.ɵɵinject(i4.StoreService)); }, token: CreditBalanceReportService, providedIn: "root" });
