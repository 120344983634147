import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IEntityActionList, TWorkflowAction } from '@core/models/action.model';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import {
  ACT_SUBMIT_PARTICIPANT_RESPONSE,
  PROJECT_RN_PREFIX, REPORT_ACTIONS,
  SUBMISSION_ACTIONS,
} from '@module/project-report/components/project-report/project-report.constants';
import { IProjectRNRoutine } from '@module/project-report/components/project-report/project-report.types';
import { NotStartDateNotificationRoutine } from '@module/project-report/routines/not-start-date-notification.routine';
import { TranslateService } from '@ngx-translate/core';
import { ProjectTransferService } from '@shared/components/project-builder/project-transfer-request/project-transfer.service';
import {
  TRANSFER_AGGREGATION_DESC,
  TRANSFER_AGGREGATION_TITLE,
} from '@shared/components/project-builder/transfer-aggregation-request/transfer-aggregation-request.constants';
import {
  TransferAggregationRequestRoutine,
} from '@shared/components/project-builder/transfer-aggregation-request/transfer-aggregation-request.routine';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import { IProject } from '@shared/services/project.service.types';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-transfer-aggregation-request',
  templateUrl: './transfer-aggregation-request.component.html',
  styleUrls: ['./transfer-aggregation-request.component.scss'],
})
export class TransferAggregationRequestComponent implements OnInit {

  public title: string;
  public detailsTitle: string;
  transferDetails: any;
  projectId: string;
  projectPid: string;
  srcAccountId: string;
  public reverse = true;
  public source: any = null;
  projectListText = '';
  public cancellationForm = new FormGroup({});

  public documentUploadConfig: IApp.IDocumentUploadConfig;

  constructor(
    private projectTransferService: ProjectTransferService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private formFactorySvc: FormFactoryService,
    private translateService: TranslateService,
    private store: StoreService,
    private modalService: ModalService,
    private projectService: ProjectService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      if (!isNaN(params.id) && !isNaN(params.projectPid) && !isNaN(params.srcAccountId)) {
        this.projectId = params.id;
        this.srcAccountId = params.srcAccountId;
        this.projectPid = params.projectPid;

        this.loadPendingTransferProjects(this.projectId, this.projectPid, this.srcAccountId);
      } else {
        this.router.navigate(this.helperService.getTranslatedPath(`/account-management/summary-user`));
      }
      // this.translateService.onLangChange.subscribe(() => {
      //   this.onInit();
      // });
    });
  }

  loadPendingTransferProjects(projectId: string, projectPid: string, srcAccountId: string) {
    this.projectTransferService.getPendingTransferProjects(projectId, projectPid, srcAccountId).then(
      (response: any) => {
        response.destAccount['organizationAddressCompiled'] = `${this.getStringValue(response.destAccount.address.streetNumber, false)}${this.getStringValue(response.destAccount.address.streetName)}${this.getStringValue(response.destAccount.address.city)}${this.getStringValue(response.destAccount.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response.destAccount.address.poBox)}${this.getStringValue(response.destAccount.address.postalCode)}${this.getStringValue(response.destAccount.address.country, false, 'COMMON.countryList.')}`;
        response.account['organizationAddressCompiled'] = `${this.getStringValue(response.account.address.streetNumber, false)}${this.getStringValue(response.account.address.streetName)}${this.getStringValue(response.account.address.city)}${this.getStringValue(response.account.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response.account.address.poBox)}${this.getStringValue(response.account.address.postalCode)}${this.getStringValue(response.account.address.country, false, 'COMMON.countryList.')}`;
        response.entityActionList = [
          {
            workflowAction: 'AGREE',
            enabled: false,
            id: null,
          },
          {
            workflowAction: 'DO_NOT_AGREE',
            enabled: false,
            id: null,
          },
        ];
        this.transferDetails = response;
        this.source = new TransferAggregationRequestRoutine(
          this.formFactorySvc,
          this.transferDetails,
          this.projectId,
          this.store,
          this.translateService,
        );
        this.setTitle(response.subProjects);
        this.setDocConfig(this.projectPid);
      },
    );
  }

  private setTitle(subProjects: IProject[]): void {
    this.projectListText = '';
    const projectNames = subProjects.map(project => `${project.name} (${project.projectId})`);
    this.projectListText = projectNames.join(', ');
    this.title = TRANSFER_AGGREGATION_TITLE;
    this.detailsTitle = TRANSFER_AGGREGATION_DESC;
  }

  getStringValue(value, comma = true, translationPrefix = null) {
    let response = '';
    if (value) {
      response = value;
      if (translationPrefix) {
        response = this.translateService.instant(`${translationPrefix}${value}`);
      }
      if (comma) {
        response = `${response}, `;
      } else {
        response = `${response} `;
      }
    }
    return response;
  }

  back() {
      this.store.back();
  }

  public openConfirmationModal(actionLabel: TWorkflowAction, message = 'ProjectsTransferConsent'): Observable<any> {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message,
          ...(actionLabel && {
            metaDataList: [
              this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
              // this.translateService.instant(`SUBMISSIONS.projectReportDetails.${this.type}Title`),
              this.source.model.name,
            ],
          }),
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public triggerAction(action: IEntityActionList): void {
    // const model = this.source.getPayload(this.projectDetails);
    // const payload: any = {
    //   ...model,
    //   id: this.source.model.id,
    //   action: action.workflowAction,
    //   adminRemark: this.source.model.adminRemark,
    //   discloseComment: this.source.model.discloseComment,
    //   internalAdminRemarks: this.source.model.internalAdminRemarks,
    //   reportTypeCode: this.type,
    //   respondToNotice: this.source.model.respondToNotice,
    //   type: this.entityType,
    //   nextPlannedReportingPeriodEndDate: this.getFormattedDate(this.source.model.nextPlannedReportingPeriodEndDate),
    //   nextPlannedReportingPeriodStartDate: this.getFormattedDate(this.source.model.nextPlannedReportingPeriodStartDate),
    //   compensationDeadline: this.getFormattedDate(this.source.model.compensationDeadline),
    //   obligation: this.source.model.obligation,
    // };
    // let uri = 'review';

    if (action.workflowAction === 'DO_NOT_AGREE') {
      const payload = {
        type: 'MASTER_RECEIVER',
        action: 'CONSENT_NOT_AGREE',
        srcAccountId: this.srcAccountId,
        srcProjectId: this.projectId,
        id: this.projectPid,
      };
      const sub$ = this.openConfirmationModal(action.workflowAction, 'ProjectsTransferNoConsent')
        .pipe(
          filter(result => result),
          switchMap(() => this.projectService.consentProjectsTransfer(payload)),
        )
        .subscribe(() => {
          sub$.unsubscribe();
          this.back();
        });
    } else if (action.workflowAction === 'AGREE') {
      const payload = {
        type: 'MASTER_RECEIVER',
        action: 'CONSENT_AGREE',
        srcAccountId: this.srcAccountId,
        srcProjectId: this.projectId,
        id: this.projectPid,
      };

      const sub$ = this.openConfirmationModal(action.workflowAction, 'ProjectsTransferConsent')
        .pipe(
          filter(result => result),
          switchMap(() => this.projectService.consentProjectsTransfer(payload)),
        )
        .subscribe(() => {
          sub$.unsubscribe();
          this.back();
        });
    }
  }

  public setDocConfig(id) {
    this.documentUploadConfig = {
      documentType: true,
      entityName: 'PROJECT_OFFSET_RECEIVER',
      id,
      comment: true,
      entityDetail: '',
      statusColumn: true,
      pendingSubmissionStatus: true,
      disablePaginator: false,
      documentUploadConfigs: [],
      extraUploadDocuments: [{
        documentTypeValue: 101001,
        documentTypeCode: 'TRANSFEREE_PROJECT_TRANSFER_CONSENT',
        id,
        entityName: 'PROJECT_OFFSET_RECEIVER',
      }],
    };
  }

}
