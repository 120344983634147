import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { TableTemplateActionControl } from '@core/models/tableTemplateControls.model';
import { BaseActionsComponent } from '@shared/components/base-actions/base-actions.component';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';

@Component({
  selector: 'app-generic-view-control',
  templateUrl: './generic-view-control.component.html',
  styleUrls: ['./generic-view-control.component.scss']
})
export class GenericViewControlComponent extends BaseActionsComponent implements OnInit {
  
  //we can also manage the label of view, passing it to simple table wrapper first then down here to this comp
  @Input() rawData: any;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private modalService: ModalService,
    private tableTemplateControls:TableTemplateControls
    ) { super(); }

  ngOnInit() {
  }
  
  triggerViewAction(){
    this.tableTemplateControls.nextAction( {

      action: { workflowAction: 'VIEW' } as IApp.IEntityAction, 
      data: this.rawData

    } as TableTemplateActionControl);
  }

}
