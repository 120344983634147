<ng-container *ngIf="popUpView; else NORMAL_VIEW">
  <p-dialog
    [className]="'eccc'"
    [(visible)]="visible"
    (visibleChange)="changed($event)"
    [modal]="true"
    [responsive]="true"
    [style]="{width: '800px'}"
    [minY]="100"
    [maximizable]="false"
    [closable]="false"
    [autoZIndex]="false"
    [dragging]="false"
    [header] = "'PROJECTS_MODULE.transferrequest.projectDetails' | translate">
    <form [formGroup]="form">
      <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
    </form>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-primary mr-2" (click)="close()">{{'PROJECTS_MODULE.ProjectBuilder.close' | translate}}</button>
    </div>
  </p-dialog>
</ng-container>

<ng-template #NORMAL_VIEW>
  <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
</ng-template>


