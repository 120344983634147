
  <div class="wrapper">
    <header class="border-bottom">
      <div class="title">
        <h2 class="m-0">{{'ACCOUNTS_MODULE.accountSummaryProject.projectSummary' | translate}}</h2>
      </div>
    </header>
    <section>
      <div class="table-filters">
        <app-table-filter
            [fields]="filterFields"
            (filterChanged)="onFilterChanged($event)"
            (filterInit)="onFilterChanged($event)"
            >
        </app-table-filter>
      </div>

      <div class="data-container mt-2 overflow-hidden h-100">
        <div class="table overflow-hidden h-100 position-relative" [ngClass]="showDetails ? 'show-details' : 'hide-details'">
          <app-table-view *ngIf="tableData"
          [config]="tableConfig"
          [data]="tableData"
          (paginationChanged)="onPaginationChanged($event)"
          [highlightRowId]="highlightRowId"
          >
          </app-table-view>
        </div>
        <div class="table-details d-flex flex-column overflow-hidden" *ngIf="showTableDetails">
          <div class="table-details-header">
            <div class="border-bottom d-flex justify-content-between p-3">
              <h2 class="m-0">{{selectedProject.name}}</h2>
              <span (click)="close()" class="close">&times;</span>
            </div>
          </div>
          <div class="table-details-content overflow-auto">
            <div class="row m-0">
              <ng-container *ngFor="let detail of projectDetails">
                <div class="col-3 my-3 label" [innerHTML]="'ACCOUNTS_MODULE.accountSummaryProject.'+detail.label | translate"></div>
                <div class="col-3 my-3">
                  <ng-container *ngIf="!detail.translatePrefix; else TRANSLATE">
                    <span *ngIf="!detail.numericField; else NUMERIC">{{selectedProject[detail.field] ? selectedProject[detail.field] : detail.default}}</span>
                    <ng-template #NUMERIC>
                      <span>{{selectedProject[detail.field] ? (selectedProject[detail.field] | localizedNumber: '1.') : detail.default}}</span>
                    </ng-template>
                  </ng-container>
                  <ng-template #TRANSLATE>
                    <span>{{selectedProject[detail.field] ? (detail.translatePrefix+selectedProject[detail.field] | translate) : detail.default}}</span>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

    </section>
    <footer class="border-top pt-2">
      <div class="content d-flex justify-content-end">
        <button (click)="back()" class="btn btn-primary">
          {{'ACCOUNTS_MODULE.accountSummaryProject.close' | translate}}
        </button>
      </div>
    </footer>
  </div>

<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewProject($event, data)"
          (keyup)="viewProject($event, data)">
      {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
    </span>
  </div>
</ng-template>


