import { IRawForm } from "@core/models/raw-form.types";
import { SOURCE_DATA_TYPE } from "@shared/components/stepper/stepper.const";

export const COMPLIANCE_REPORT_PERIOD_FLD = 'compliancePeriod';
export const CCM_PREFIX = 'CREDIT_CLEARANCE_MECHANISM';
export const DETAILS_FORM_PREFIX = '.DETAILS_FORM';

import { getAppFormlyDatepicker, getDictSelect, getInput, getLabel, getRadio, getTextArea } from "@core/utilities/raw-form.utils";
import { COMPLIANCE_REPORT_AMOUNT_MASK } from "@module/compliance-report/compliance-report.const";


export const CCM_FORM_PREFIX = 'CREDIT_CLEARANCE_MECHANISM.DETAILS_FORM'

export const getStaticText = (label: string, className: string = 'col-12', hideExpression: string = null,): IRawForm => {
  return getLabel({
    label: `${CCM_FORM_PREFIX}.${label}`,
    className,
    hideExpression,
  });
};

export const getReadonlyInput = (id, hideExpression?): IRawForm => {
  return getInput({
    id,
    type: 'readonly-input',
    className: 'col-6',
    label: `${CCM_FORM_PREFIX}.${id}`,
    hideExpression,
    required: false
  });
};

export const getIsCCMRequired = () => {
  return getRadio({
    id: 'isCCMRequired',
    key: 'ccmRequired',
    className: 'col-12',
    label: `${CCM_FORM_PREFIX}.isCCMRequired`,
    options: [{
      key: true,
      value: `${CCM_FORM_PREFIX}.yes`,
    }, {
      key: false,
      value: `${CCM_FORM_PREFIX}.no`,
    }],
    dummyInputForLabel: true,
    required: true,
  })
};

export const getDatePicker = (id, onChange?:  ()=> void): IRawForm => {
  return getAppFormlyDatepicker({
    id,
    className: 'col-sm-12 col-md-6',
    label: `${CCM_FORM_PREFIX}.${id}`,
    hideExpression: "!model.ccmRequired",
    onChange
  })
};

export const getEditableInput = (
  id: string
): IRawForm => {
  return getInput({
    id,
    className: 'col-6',
    customTemplateOptions: {
      mask: COMPLIANCE_REPORT_AMOUNT_MASK,
      type: 'formatted-number',
      allowNegativeNumbers: false,
    },
    min: 0,
    required: true,
    label:  `${CCM_FORM_PREFIX}.${id}`,
    hideExpression:"!model.ccmRequired"
  });
};

export const getAdminComments = (id): IRawForm => {
  return getTextArea({
    id,
    required: false,
    className: 'col-12',
    label:  `${CCM_FORM_PREFIX}.${id}`
  })
};


export const getComplianceReportPeriod = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: COMPLIANCE_REPORT_PERIOD_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.CCM_COMPLAINCE_PERIOD,
    label: `${CCM_FORM_PREFIX}.${COMPLIANCE_REPORT_PERIOD_FLD}`,
    onChange,
    required: true,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    skipInitialBlur: true,
    translatePrefix: CCM_PREFIX + DETAILS_FORM_PREFIX,
  });
};
