<div class="row" *ngIf="hasUnansweredComment">
  <div  class="offset-1 col-8">
    <div class="comments-section"
         *ngFor="let comment of discussions[0].comments">
      <span>{{ translationPrefix + 'adminComments' | translate }}:<br>
        <small *ngIf="comment.commentTimestampStr">
          ({{ translationPrefix + 'timestamp' | translate }}: {{ comment.commentTimestampStr }})
        </small>
      </span>
      <span>
        {{ comment.comment}}
      </span>
    </div>
  </div>
</div>

<ngb-accordion [closeOthers]="true" *ngIf="anyPreviousDiscussions">
  <ngb-panel>
    <ng-template ngbPanelHeader let-opened="opened">
      <button ngbPanelToggle class="btn btn-transparent">
        <fa-icon [icon]="['fas', opened ? 'chevron-down' : 'chevron-up']"></fa-icon>
        <span class="wcag-visuallyhidden">Toggle</span>
      </button>
      <h2 class="ml-2 d-inline">{{ translationPrefix + 'previousDiscussion' | translate }}</h2>
    </ng-template>
    <ng-template ngbPanelContent>
      <div  class="offset-1 col-8">
        <ng-container *ngFor="let discussion of discussions">
          <ng-container *ngIf="discussion.userComment">
            <div class="comments-section section-start">
              <span>{{ translationPrefix + 'response' | translate }}:<br>
                <small>({{ translationPrefix + 'timestamp' | translate }}: {{ discussion.userCommentTimestampStr }})</small>
              </span>
              <span>
                {{ discussion.userComment }}
              </span>
            </div>
            <div class="comments-section"
                 *ngFor="let comment of discussion.comments">
              <span>{{ translationPrefix + 'adminComments' | translate }}:<br>
                <small>({{ translationPrefix + 'timestamp' | translate }}: {{ comment.commentTimestampStr }})</small>
              </span>
              <span>
                {{ comment.comment}}
              </span>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="customContent">
          <ng-content></ng-content>
        </ng-container>
      </div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>

