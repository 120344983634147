<h2>{{ 'ACCOUNTS_MODULE.assignedUsers.title' | translate }}</h2>
<div class="table-area">
  <app-table-view
    [config]="tableConfig"
    [data]="userList">
  </app-table-view>
</div>


<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewUser($event, data.id)"
          (keyup)="viewUser($event, data.id)">
      {{ 'USERS_MODULE.usersList.view' | translate}}
    </span>
  </div>
</ng-template>

<ng-template #accountUserActions let-data>
  <div><app-user-actions
    [user]="data"
    [actionsList]="data.entityActionList"
    (actionSuccessfull)="refreshData($event)"
    [appendTo]="'.ui-table-scrollable-view'">
  </app-user-actions></div>
</ng-template>
<ng-template #roleColumnTemplate let-data>
  <ng-container *ngIf="data.associationsList && data.associationsList.length > 0; else NO_ASSOC_LIST">
    <span *ngIf="(data.associationsList[0].roleId | dict: roles)">{{ roleTranslationPrefix + (data.associationsList[0].roleId | dict: roles) | translate }}</span>
    <span *ngIf="data.associationsList.length > 1"><br>...</span>
  </ng-container>
  <ng-template #NO_ASSOC_LIST>
    <span><br>-</span>
  </ng-template>
</ng-template>

<ng-template #expandUserRowTemplate let-data let-index>
  <div style="position: relative">
    <app-linked-accounts
      [items]="data.associationsList"
      [readonly]="true">
    </app-linked-accounts>
  </div>
</ng-template>
