import { CurrentUser } from '@core/models/user.model';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';
import { ACCOUNT_TYPE_IDS, BUSINESS_STRUCTURE_IDS_BY_PROGRAM } from '@shared/components/stepper/stepper.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/services/helper.service";
import * as i5 from "../../shared/services/navigation.service";
const userDataKey = 'userData';
const sessionDataKey = 'sessionData';
const lastVisitedUrlKey = 'last_visited_url';
const obligationId = 'obligationId';
const tcAccepted = 'tcAccepted';
const uiVersion = 'uiVersion';
export const ignoreTranslationPrefix = 'ignoreTranslationPrefix';
export const PROPERTY = '/account-service/property/get?key=';
const REGISTRY_SERVER_PROPERTY = '/registry-server/property/get?key=';
const accessKey = 'accessKey';
const historyUrl = 'historyUrl';
const accountStorageKey = 'global_account';
export const programIdKey = 'program_id';
export const defaultAccountSelected = -1;
const DISPLAY_ACTION_BUTTONS_PROP = 'display.action.buttons';
export const ENABLE_VERIFICATION_FLOW = 'enable.verification.flow';
const SESSION_INACTIVITY_TIME_PROP = 'session.inactive.validity.time';
const CFS_ENABLED_DATE_PROP = 'cfs.enabled.date';
const UI_DATE_REVISED_PROP = 'ui.date.revised';
const ENABLE_OFFSET_PROP = 'enable.offset';
const NEW_NOE_KEY = 'cached_new_notice_of_error';
const AAG_INFO_VISIBLE = 'AAG_info_visible';
export const ENABLE_VERIFICATION_FLOW_CI_APP_START_DATE = 'enable.verification.flow.ci.application.start.date';
export const TRANSLATION_PREFIX_IGNORE_PROPERTY = 'application.ignoreTranslationPrefix';
export class StoreService {
    constructor(http, router, _location, helperService, navigatioService) {
        this.http = http;
        this.router = router;
        this._location = _location;
        this.helperService = helperService;
        this.navigatioService = navigatioService;
        this._user$ = new BehaviorSubject(null);
        this.user$ = this._user$.asObservable();
        this._session$ = new BehaviorSubject(null);
        this._revisedDate$ = new BehaviorSubject(null);
        this.revisedDate$ = this._revisedDate$.asObservable();
        this._ignoreTranslationPrefix$ = new BehaviorSubject(null);
        this.ignoreTranslationPrefix$ = this._ignoreTranslationPrefix$.asObservable();
        this._accountFacilities$ = new BehaviorSubject([defaultAccountSelected]);
        this.accountFacilities$ = this._accountFacilities$.asObservable();
        this._loginAvailable$ = new BehaviorSubject(false);
        this.loginAvailable$ = this._loginAvailable$.asObservable();
        this._accountSubTypes$ = new BehaviorSubject(null);
        this.accountSubTypes$ = this._accountSubTypes$.asObservable();
        this.getActionButtonsState();
        // Get userData and sessionData from sessionStorage if available
        this._user$.next(this.load(userDataKey));
        this._session$.next(this.load(sessionDataKey));
        this.getRevisedDate().subscribe(data => {
            this._revisedDate$.next(data.value);
            sessionStorage.setItem(uiVersion, data.component);
        });
        this.getIgnoreTranslationPrefix().subscribe(data => {
            this._ignoreTranslationPrefix$.next(data.value);
            sessionStorage.setItem(ignoreTranslationPrefix, data.value);
        });
        const selectedAccounts = sessionStorage.getItem(accountStorageKey);
        try {
            if (selectedAccounts) {
                this._accountFacilities$.next(JSON.parse(selectedAccounts));
            }
        }
        catch (e) {
            this.accountFacilities = [defaultAccountSelected];
        }
    }
    setTcAccept(value) {
        sessionStorage.setItem(tcAccepted, value);
    }
    getTcAccept() {
        return sessionStorage.getItem(tcAccepted);
    }
    setAccessKey(value) {
        sessionStorage.setItem(accessKey, value);
    }
    removeAcceptedTc(id) {
        const user = this.user;
        user.tandcContents = user.tandcContents.filter(el => el.id !== id);
        this.user = user;
    }
    getAccessKey() {
        const key = sessionStorage.getItem(accessKey);
        return key === 'null' ? null : key;
    }
    setHistoryUrl() {
        sessionStorage.setItem(historyUrl, this.router.url);
    }
    resetHistoryUrl() {
        sessionStorage.removeItem(historyUrl);
    }
    getHistoryUrl() {
        const key = sessionStorage.getItem(historyUrl);
        return key === 'null' ? null : key;
    }
    back() {
        const url = this.getHistoryUrl();
        if (url) {
            this.resetHistoryUrl();
            this.router.navigate(this.helperService.getTranslatedPath(url));
        }
        else {
            this.navigatioService.back();
        }
    }
    removeTcAccept() {
        sessionStorage.removeItem(tcAccepted);
    }
    set user(user) {
        this._user$.next(user);
        if (user) {
            this.save(userDataKey, user);
            if (user.program) {
                sessionStorage.setItem(programIdKey, user.program.toLowerCase());
            }
        }
    }
    get user() {
        return new CurrentUser(this._user$.getValue());
    }
    checkTCAcceptance() {
        const user = this.user;
        if (user && user.tandcContents && user.tandcContents.length > 0) {
            this.setTcAccept('false');
        }
        else {
            this.setTcAccept('true');
        }
    }
    get currentProgram() {
        return this.user.program;
    }
    isProgram(code) {
        return this.currentProgram.toLowerCase() === code.toLowerCase();
    }
    set session(session) {
        this._session$.next(session);
        if (session) {
            this.save(sessionDataKey, session);
        }
    }
    getSession() {
        return this._session$.getValue();
    }
    save(itemKey, value) {
        sessionStorage.setItem(itemKey, JSON.stringify(value));
    }
    load(itemKey) {
        const userData = sessionStorage.getItem(itemKey);
        if (userData) {
            try {
                return JSON.parse(userData);
            }
            catch (_a) {
                return null;
            }
        }
    }
    getActionButtonsState() {
        this.getProperty(DISPLAY_ACTION_BUTTONS_PROP).subscribe(data => {
            this._loginAvailable$.next(data.value === 'true');
        });
    }
    getIgnoreTranslationPrefix() {
        return this.getProperty(TRANSLATION_PREFIX_IGNORE_PROPERTY);
    }
    getRevisedDate() {
        return this.getProperty('ui.date.revised');
    }
    gerProgramAdminEmail() {
        return this.getProperty('email.group.programAdmin');
    }
    getAllowedDocTypes() {
        return this.getProperty('doc.allowed.file.types');
    }
    getOffsetMinStartDate() {
        return this.getProperty('project.offset.startDate.minimumValue');
    }
    getProperty(propertyName) {
        let propertyUrl = PROPERTY;
        if (propertyName === SESSION_INACTIVITY_TIME_PROP || propertyName === DISPLAY_ACTION_BUTTONS_PROP
            || propertyName === CFS_ENABLED_DATE_PROP || propertyName === UI_DATE_REVISED_PROP ||
            propertyName === ENABLE_OFFSET_PROP || propertyName === ENABLE_VERIFICATION_FLOW) {
            propertyUrl = REGISTRY_SERVER_PROPERTY;
        }
        return this.http.get(`${environment.apiUrl}${propertyUrl}${propertyName}`)
            .pipe(map(data => {
            if (propertyName === ENABLE_VERIFICATION_FLOW) {
                data.value = data.value ? data.value.toLowerCase() : data.value;
            }
            return data;
        }));
    }
    set accountFacilities(ids) {
        if (ids) {
            sessionStorage.setItem(accountStorageKey, JSON.stringify(ids));
        }
        else {
            sessionStorage.removeItem(accountStorageKey);
        }
        this._accountFacilities$.next(ids);
    }
    get accountFacilities() {
        return this._accountFacilities$.getValue() || [-1];
    }
    set lastVisitedUrl(url) {
        sessionStorage.setItem(lastVisitedUrlKey, JSON.stringify(url));
    }
    get lastVisitedUrl() {
        const url = sessionStorage.getItem(lastVisitedUrlKey);
        return url && JSON.parse(url);
    }
    set obligationId(url) {
        sessionStorage.setItem(obligationId, url);
    }
    get obligationId() {
        return sessionStorage.getItem(obligationId);
    }
    clearLastVisitedUrl() {
        sessionStorage.removeItem(lastVisitedUrlKey);
    }
    storeObligationId(id) {
        this.obligationId = id;
    }
    clearObligationId() {
        sessionStorage.removeItem(obligationId);
    }
    storeCurrentUrl() {
        this.lastVisitedUrl = window.location.pathname;
    }
    get isOffsetPublicPage() {
        return this.router.url.includes('public');
    }
    set activeNOE(data) {
        sessionStorage.setItem(NEW_NOE_KEY, JSON.stringify(data));
    }
    get activeNOE() {
        const result = sessionStorage.getItem(NEW_NOE_KEY);
        return result && JSON.parse(result);
    }
    clearActiveNOE() {
        sessionStorage.removeItem(NEW_NOE_KEY);
    }
    destroy() {
        sessionStorage.clear();
        this.accountFacilities = null;
        this.user = null;
        this.session = null;
    }
    getAccountTypeIdByProgramType() {
        return this.user.isCM() ? ACCOUNT_TYPE_IDS.CM : ACCOUNT_TYPE_IDS.OFFSET;
    }
    getBusinessStructureIdByProgramType() {
        return this.user.isCM() ? BUSINESS_STRUCTURE_IDS_BY_PROGRAM.CM : BUSINESS_STRUCTURE_IDS_BY_PROGRAM.OFFSET;
    }
    setAAGInfoVisibility(visibility) {
        sessionStorage.setItem(AAG_INFO_VISIBLE, visibility);
    }
    getAAGInfoVisibility() {
        return sessionStorage.getItem(AAG_INFO_VISIBLE);
    }
    setCOIDataObject(key, object) {
        sessionStorage.setItem(key, object);
    }
    getCOIDataObject(key) {
        return sessionStorage.getItem(key);
    }
    removeCOIDataObject(key) {
        sessionStorage.removeItem(key);
    }
    setAccountSubTypes(subTypes) {
        this._accountSubTypes$.next(subTypes);
    }
}
StoreService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoreService_Factory() { return new StoreService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.NavigationService)); }, token: StoreService, providedIn: "root" });
