<ul>
  <li *ngIf="isCollapsed && collapsedReplies.length">
    <div>
      <a
        (click)="onExpandReplies($event)"
        href="#"
      >
        {{collapsedReplies.length}} {{'PROJECTS_MODULE.DISCUSSION.discussionPreviousReplies' | translate}}
      </a>
    </div>
  </li>

  <li *ngFor="let reply of visibleReplies">
    <div>
      <app-project-discussion-chat-reply [reply]="reply"></app-project-discussion-chat-reply>
    </div>
  </li>
</ul>