import { Component, OnInit } from '@angular/core';
import { IUserData } from '@core/models/user.model';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {

  public currentUser: IUserData;

  constructor(
    private helperService: HelperService,
    private store: StoreService,
  ) {
    this.store.user$.subscribe(
      currentUser =>  this.currentUser = currentUser,
      );
  }

  ngOnInit() {
  }

}
