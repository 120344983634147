/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./omp-account-types.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/radiobutton/primeng-radiobutton.ngfactory";
import * as i3 from "primeng/radiobutton";
import * as i4 from "../../pipes/custom-translate.pipe";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/forms";
import * as i7 from "../../directives/wcag-label.directive";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../node_modules/primeng/fieldset/primeng-fieldset.ngfactory";
import * as i10 from "primeng/fieldset";
import * as i11 from "../blurb/blurb.component.ngfactory";
import * as i12 from "../blurb/blurb.component";
import * as i13 from "./omp-account-types.component";
import * as i14 from "../../../modules/registration/registration.service";
import * as i15 from "../../services/modal.service";
var styles_OmpAccountTypesComponent = [i0.styles];
var RenderType_OmpAccountTypesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OmpAccountTypesComponent, data: {} });
export { RenderType_OmpAccountTypesComponent as RenderType_OmpAccountTypesComponent };
function View_OmpAccountTypesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-radioButton", [["name", "group1"], ["wcag-label", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectionAccountType = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RadioButton_0, i2.RenderType_RadioButton)), i1.ɵdid(1, 49152, null, 0, i3.RadioButton, [i1.ChangeDetectorRef], { value: [0, "value"], name: [1, "name"], label: [2, "label"] }, null), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioButton]), i1.ɵdid(4, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(6, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(7, 212992, null, 0, i7.WCAGLabelDirective, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _v.parent.context.$implicit; var currVal_8 = "group1"; var currVal_9 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 2, i1.ɵnov(_v, 2).transform(("REGISTRATION_PAGE.accountTypeSection." + _v.parent.context.$implicit.name))), ""); _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = "group1"; var currVal_11 = _co.selectionAccountType; _ck(_v, 4, 0, currVal_10, currVal_11); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_OmpAccountTypesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "p-col-12 mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "legend", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmpAccountTypesComponent_2)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.hideOption(_v.context.$implicit.id); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("REGISTRATION_PAGE.accountTypeSection.ompAccountType")); _ck(_v, 3, 0, currVal_0); }); }
function View_OmpAccountTypesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "p-col-12 m-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "fieldset", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "legend", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 7, "p-radioButton", [["name", "group2"], ["wcag-label", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selectionAccountRegistraionType = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RadioButton_0, i2.RenderType_RadioButton)), i1.ɵdid(6, 49152, null, 0, i3.RadioButton, [i1.ChangeDetectorRef], { value: [0, "value"], name: [1, "name"], label: [2, "label"] }, null), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.RadioButton]), i1.ɵdid(9, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(11, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(12, 212992, null, 0, i7.WCAGLabelDirective, [i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _v.context.$implicit; var currVal_9 = "group2"; var currVal_10 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 2, i1.ɵnov(_v, 7).transform(("REGISTRATION_PAGE.accountTypeSection." + _v.context.$implicit.name))), ""); _ck(_v, 6, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = "group2"; var currVal_12 = _co.selectionAccountRegistraionType; _ck(_v, 9, 0, currVal_11, currVal_12); _ck(_v, 12, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("REGISTRATION_PAGE.accountTypeSection.purposeOfAccount")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_OmpAccountTypesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p-fieldset", [], null, null, null, i9.View_Fieldset_0, i9.RenderType_Fieldset)), i1.ɵdid(1, 49152, null, 0, i10.Fieldset, [i1.ElementRef], { legend: [0, "legend"] }, null), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, 1, 1, "app-blurb", [], null, null, null, i11.View_BlurbComponent_0, i11.RenderType_BlurbComponent)), i1.ɵdid(4, 114688, null, 0, i12.BlurbComponent, [i5.TranslateService], { message: [0, "message"] }, null), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmpAccountTypesComponent_4)), i1.ɵdid(7, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REGISTRATION_PAGE.accountTypeSection.purposeOfAccount")), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = "REGISTRATION_PAGE.accountTypeSection.accountOpeningReason"; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.registrationType; _ck(_v, 7, 0, currVal_2); }, null); }
function View_OmpAccountTypesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REGISTRATION_PAGE.stepperSection.back")); _ck(_v, 1, 0, currVal_0); }); }
export function View_OmpAccountTypesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["id", "omp-account-types"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 14, "div", [["class", "d-md-flex mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 13, "div", [["class", "card mb-2 "]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "p-grid mt-5 mb-5 "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmpAccountTypesComponent_1)), i1.ɵdid(9, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "p-grid mb-5 "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmpAccountTypesComponent_3)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OmpAccountTypesComponent_5)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "nfmp-stp2-btn"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.accoutTypes; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.selectionAccountType; _ck(_v, 12, 0, currVal_2); var currVal_3 = (!_co.isSubaccount && _co.showBackButton); _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("REGISTRATION_PAGE.accountTypeSection.ompAccountType")); _ck(_v, 2, 0, currVal_0); var currVal_4 = _co.onlyIfNoselection(); _ck(_v, 16, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("REGISTRATION_PAGE.accountTypeSection.select")); _ck(_v, 17, 0, currVal_5); }); }
export function View_OmpAccountTypesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-omp-account-types", [], null, null, null, View_OmpAccountTypesComponent_0, RenderType_OmpAccountTypesComponent)), i1.ɵdid(1, 114688, null, 0, i13.OmpAccountTypesComponent, [i14.RegistrationService, i15.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OmpAccountTypesComponentNgFactory = i1.ɵccf("app-omp-account-types", i13.OmpAccountTypesComponent, View_OmpAccountTypesComponent_Host_0, { isSubaccount: "isSubaccount", showBackButton: "showBackButton" }, { back: "back", next: "next" }, []);
export { OmpAccountTypesComponentNgFactory as OmpAccountTypesComponentNgFactory };
