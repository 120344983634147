import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { StoreService } from '@core/store/store.service';
import { validateDecimalPlace } from '@core/utilities/utilities.constants';
import { TranslateService } from '@ngx-translate/core';
import {
  ProjectTransferDetailsComponent
} from '@shared/components/project-builder/project-transfer-details/project-transfer-details.component';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubProjectFormComponent } from './sub-project-form/sub-project-form.component';
import { Observable } from 'rxjs/internal/Observable';
import { HttpResponse } from '@angular/common/http';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { SubProjectsAdditionComponent } from '@shared/components/project-builder/sub-projects-addition/sub-projects-addition.component';
import { EDIT_PROJECT_PUBLIC_VISIBILITY } from '@core/models/permission.const';
import { PROJECT_ACCOUNT_ACTIVE_STATUSES } from '@shared/components/project/project.constants';

const ADMIN_PERMISSION = 'ACCOUNT_ADMIN_ACTION';

@Injectable({
  providedIn: 'root',
})
export class ProjectBuilderService {

  public changeActivityView = new BehaviorSubject<boolean>(false);
  public projectType: string;
  public model: any = {
    subProjects: [],
    discardSubProjects: [],
    activity: {
      activityType: null,
      location: {},
    }
  };
  public historicData = false;
  public disabled = false;
  public showAddToSummary = true;
  public showRequiredDocs = true;
  public validateFormsReq$ = new Subject<void>();
  public validateDescriptionFormsRes$ = new BehaviorSubject<boolean>(false);
  public validateActivityFormsRes$ = new BehaviorSubject<boolean>(false);
  public validateRequiredDocsRes$ = new BehaviorSubject<boolean>(false);
  public validateIncorrectValuesReq$ = new Subject();
  public validateIncorrectValuesRes$ = new Subject();
  public updateProject = false;
  public reportId = null;
  public isProjectPublicExistingValue = null;

  public validateSubProjectDocsReq$ = new Subject();
  public subProjectUpdate$ = new Subject();
  subProjectAdded$ = new Subject();

  public subscriptions$: Subscription;

  public projectForm: FormGroup;

  private _selectedCard: any;
  private _activities: any = [];
  private activityList$ = new BehaviorSubject([]);

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private store: StoreService,
    private projectService: ProjectService) { }

  public get selectedCard() {
    return this._selectedCard;
  }

  public set selectedCard(value) {
    this._selectedCard = value;
    this.changeActivityView.next(true);
  }

  public addActivity(activity) {
    this._activities = [activity];
    this.activityList$.next([...this._activities]);
  }

  public getActivities() {
    return this.activityList$;
  }

  public setAggregatedEmmissionsDetails() {
    this.model.activity.estimatedScenarioGhg = this.model.subProjects.reduce((acc, subProject) => {
      return Number(acc) + Number(subProject.activity.estimatedScenarioGhg);
    }, 0);
    this.model.activity = { ...this.model.activity };
  }

  public validateDecimalLatLong(control: FormControl, value: number) {
    if (isNaN(value)) {
      control.setErrors({ 'server-side': this.translateService.instant('valueMustBeNumber') });
      return false;
    } else {
      if (control && value) {
        if (!validateDecimalPlace(value, 5)) {
          control.markAsDirty();
          control.setErrors({ 'server-side': this.translateService.instant('accurateToFourDecimals') });
          return false;
        } else {
          control.setValue((+value).toFixed(5))
          return true;
        }
      }
      return true;
    }
  }

  public setAggregatedStartDate() {
    const startDates = this.model.subProjects.map(subProject => moment(subProject.activity.activityStartDate));
    this.model.activity.activityStartDate = moment.min(startDates).toDate();
  }

  public addSubProject() {
    this.modalService.open(SubProjectFormComponent, {
      model: {
        activity: {
          location: {},
        },
        accountId: this.model.accountId,
      },
    }, true).afterClosed()
      .subscribe(result => {
        if (result) {
          delete result.activity.location.file;
          result.activityStartDate = result.activity.activityStartDate;
          result.estimatedScenarioGhg = result.activity.estimatedScenarioGhg;
          result.masterProjectId = this.model.activity.projectId;
          result.activity.activityType = {...this.model.activity.activityType};
          this.model.subProjects.push(result);
          this.model.noOfSubProjects = this.model.subProjects.length;
          this.setAggregatedStartDate();
          this.setAggregatedEmmissionsDetails();
          this.setSubprojectPreviouslyRegistered();
          this.model.subProjects = [...this.model.subProjects];
          this.model = { ...this.model };
          this.subProjectAdded$.next();
        }
      });
  }

  public openConfirmationModal(actionLabel, accountName = this.model.account.legalName, message = 'confirmationMessageProject'): Observable<any> {
    return this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message,
        ...(actionLabel && {metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
          accountName,
        ]}),
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed();
  }

  public openProjectToConsentConfirmationModal(actionLabel, accountName = this.model.account.legalName, message = 'confirmationMessageSendProjectToConsent'): Observable<any> {
    return this.modalService.open(ServiceMessageComponent,
      {
        message,
        metaData: accountName,
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed();
  }

  public openProjectTransferDetailsModal(projectData): Observable<any> {
    return this.modalService.open(ProjectTransferDetailsComponent,
      {
        projectData,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed();
  }

  public addPermission(actions, status = this.model.status) {
    const adminPermisson = (actions && actions.some(action => action.workflowAction === 'EDIT')) || (this.hasApproveAction() && (!status || status === 'IN_PROCESS' || this.updateProject));
    this.model.adminPermisson = adminPermisson;
    this.model.activity.adminPermisson = adminPermisson;
  }

  public hasApproveAction(discloseComment = this.model.discloseComment) {
    return this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS) || discloseComment;
  }

  public hasEditProjectPublicVisibilityAction() {
    return this.store.user.hasPermission(EDIT_PROJECT_PUBLIC_VISIBILITY);
  }

  public checkForDisabledState(actions = this.model.entityActionList, status = this.model.status) {
    const editParticipant = this.updateProject || (actions && actions.some(action => action.workflowAction === 'EDIT_CUST') || !status);
    this.disabled = !editParticipant || this.historicData;
    this.model = { ...this.model, disabled: this.disabled, editParticipant, activity: {...this.model.activity, editParticipant, disabled: this.disabled} };
    return this.disabled;

  }

  public uploadSubProjects(uploadContainer, renderer) {
    const input = renderer.createElement('input');
    renderer.setAttribute(input, 'type', 'file');
    renderer.setStyle(input, 'display', 'none');
    renderer.listen(input, 'change', event => {
      const file = event.target.files[0];
      const formData: FormData = new FormData();
      const action = (!this.model.status || this.model.status === 'IN_PROCESS' ||
        this.model.entityActionList && this.model.entityActionList.some(action => action.workflowAction === 'RESUBMIT')) ? 'SAVE' : 'SUBMIT';
      formData.append('file', file);
      formData.append('project', JSON.stringify({ id: this.model.id, action}));
      const sub$ = this.projectService.saveProject({...this.model, hideMessageInResponse : true})
        .pipe(switchMap(() => this.projectService.uploadSubProjects(formData)))
        .subscribe(result => {
          this.updateModel(result.entity);
          sub$.unsubscribe();
        });
    });
    renderer.appendChild(uploadContainer.nativeElement, input);
    input.click();
  }

  public getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('content-disposition');
      filename = contentDisposition.split('=')[1].replace(/"/g, '');

    }
    catch (e) {
      filename = 'location.kml'
    }
    return filename
  }

  public renderKmlFileUpload(model, file, field, mapFileId, downloadLinkId) {
    if (field.id === mapFileId) {
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('project', JSON.stringify(model));
      this.projectService.uploadDocument(formData)
        .subscribe(result => {
          model.activity.location.kmlFileExists = true;
          file.arrayBuffer().then((arrayBuffer) => {
            const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob]));
            downloadLink.setAttribute('class', 'link');
            downloadLink.setAttribute('download', file.name);
            downloadLink.innerText = file.name;
            const linkDiv = document.createElement('div');
            linkDiv.appendChild(downloadLink);
            const doc = document.getElementById(downloadLinkId);
            const closeButton = document.createElement('div');
            closeButton.innerHTML = '&times';
            closeButton.setAttribute('id', 'close-button');

            if (doc) {
              closeButton.addEventListener("click",() => {
                doc.innerHTML = '';
                document.getElementById(mapFileId)['value'] = null;
                this.projectService.deleteKmlDocument(result).subscribe();
              });

              doc.innerHTML = '';
              doc.appendChild(linkDiv);
              doc.appendChild(closeButton);
            }
          });
        });
    }
  }

  public renderDownloadKmlControl(model, mapFileId, downloadLinkId) {
    this.projectService.downloadKML(model.id).subscribe((response:HttpResponse<Blob>) => {
      if (response.body && response.body.size) {
        model.activity.location.kmlFileExists = true;
        let filename: string = this.getFileName(response);
        let binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
        downloadLink.setAttribute('download', filename);
        downloadLink.innerText = filename;
        const doc = document.getElementById(downloadLinkId);
        const closeButton = document.createElement('div');
        closeButton.innerHTML = '&times';
        closeButton.setAttribute('id', 'close-button');

        if (doc) {
          closeButton.addEventListener('click',() => {
            doc.innerHTML = '';
            document.getElementById(mapFileId)['value'] = null;
            this.projectService.getKmlInfo(model.id).subscribe(fileInfo => {
              this.projectService.deleteKmlDocument(fileInfo).subscribe();
            });
          });

          doc.innerHTML = '';
          doc.appendChild(downloadLink);
          doc.appendChild(closeButton);
        }
      }
    });
  }

  private updateModel(result) {
    this.model = {...this.model, ...result};
    this.model.subProjects = result.subProjects || [];
    this.model.noOfSubProjects = this.model.subProjects.length;
    this.model.discardSubProjects = [];
    this.setAggregatedStartDate();
    this.setAggregatedEmmissionsDetails();
    this.setSubprojectPreviouslyRegistered();
    this.subProjectAdded$.next();
  }

  public validateGHGReductions(form: FormGroup, model: any) {
    const control = form.get('activity.estimatedScenarioGhg');
      if (model.activity.estimatedScenarioGhg <= 0) {
        control.markAsDirty();
        control.setErrors({ 'server-side': this.translateService.instant('cannotBeLessThenZero') });
      }
  }

  public validateIncorrectValues(form: FormGroup, valid) {
    (<any>Object).values(form.controls).forEach((control) => {
      if (control.controls) {
        valid = this.validateIncorrectValues(control, valid);
      }
      const error = control.errors ? Object.keys(control.errors)[0] : null;
      if (control.status === "INVALID" && ['max','min', 'server-side'].includes(error)) {
        valid = false;
      }
    });
    return valid;
  }

  public checkForRequiredDocs(missingDocs) {
    let charCode = 97;
    let missingDocMsg = missingDocs.reduce((acc, doc, index) => {
      ++charCode;
      return (index < missingDocs.length - 1) ?
        acc + `${this.translateService.instant(`COMMON.documentList.${doc.type}`)}<br>${String.fromCharCode(charCode)}. ` :
        acc + `${this.translateService.instant(`COMMON.documentList.${doc.type}`)}`;
    }, '');
    const metaData = [`${String.fromCharCode(97)}. ${missingDocMsg}`];
    return this.modalService.open(ServiceMessageComponent,
      {
        messages: [{
          message: 'mandatoryInformationRequiredForUploadDocs',
          metaData
        }],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG
    ).afterClosed();
  }

  public setSubprojectPreviouslyRegistered() {
    if(this.model.subProjects){
      this.model.subprojectPreviouslyRegistered = this.model.subProjects.some(sub => sub.activity.projectPreviouslyRegistered);
      this.model.declarationLandownerRequired = this.model.subProjects.some(sub => sub.activity.isLandPrivate || sub.activity.isProponentOwner);
    }
  }

  public updateControlsValueAndValidity() {
    if (this.translateService.currentLang === 'en') {
      this.projectForm.get('name2').clearValidators();
      this.projectForm.get('name2').reset(this.model.name2);
      this.projectForm.get('name2').updateValueAndValidity();
      this.projectForm.get('description2').clearValidators();
      this.projectForm.get('description2').reset(this.model.description2);
      this.projectForm.get('description2').updateValueAndValidity();
    } else {
      this.projectForm.get('name').clearValidators();
      this.projectForm.get('name').reset(this.model.name);
      this.projectForm.get('name').updateValueAndValidity();
      this.projectForm.get('description').clearValidators();
      this.projectForm.get('description').reset(this.model.description);
      this.projectForm.get('description').updateValueAndValidity();
    }
  }

  public clearData() {
    this._activities = [];
    this.activityList$.next([]);
    this.model = {
      subProjects: [],
      activity: {
        activityType: null,
      }
    };
    this.projectType = null;
    this.disabled = false;
    this.updateProject = false;
    this.changeActivityView.next(false);
    this.showAddToSummary = true;
    this.showRequiredDocs = true;
    this.historicData = false;
    this.disabled = false;
    this.isProjectPublicExistingValue = null;
    this.projectForm = null;
    this.reportId = null;
    if (this.subscriptions$) {
      this.subscriptions$.unsubscribe();
    }
  }

  public get isProjectAccountActive(): boolean {
    return PROJECT_ACCOUNT_ACTIVE_STATUSES.includes(this.model.account.status);
  }

}
