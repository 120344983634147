<div class="wrapper">
  <ng-container
    *ngIf="comments && comments.content.length; else noData"
  >
    <div
      *ngFor="let entry of comments.content"
      class="comment py-3 px-4 mb-2 mr-3"
    >
      <div class="comment-triangle"></div>

      <div
        *ngIf="entry.type === projectDiscussionCommentContentTypeNeedMoreInfo"
        class="guidance"
      >
        {{'PROJECTS_MODULE.DISCUSSION.discussionGuidanceLabel' | translate}}
      </div>

      <app-project-discussion-chat-header
        [entry]="entry"
      ></app-project-discussion-chat-header>

      <div class="comment-body">
        {{entry.comment}}

        <app-project-discussion-chat-controls
          [entry]="entry"
          [project]="project"
        ></app-project-discussion-chat-controls>

        <app-project-discussion-chat-replies
          *ngIf="entry.replyList"
          [replies]="entry.replyList"
        ></app-project-discussion-chat-replies>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #noData>
  <div>
    {{'PROJECTS_MODULE.DISCUSSION.discussionNoData' | translate}}
  </div>
</ng-template>
