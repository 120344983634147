<p-dialog
  [className]="'eccc'"
  [header]="'ADMIN_MODULE.menuList.' + title | translate"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '600px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <label *ngIf="!readOnlyView" class="title">{{'ADMIN_MODULE.termsAndConditions.termsAndConditionTileAcceptance' | translate }}:</label>
  <div class="col-lg-12" [innerHTML]="tcContent"></div>


  <p-footer>
    <div class="footer-buttons">
      <ng-container *ngIf="!readOnlyView; else READ_ONLY">
        <div class="float-right">
          <button id="TNC_appeptBtn" type="button" class="btn btn-primary" id="tncAccept" (click)="accept()">{{'COMMON.actionsLabel.ACCEPT' | translate}}</button>
        </div>
        <div class="float-right">
          <button id="TNC_logoutBtn" type="button" class="btn btn-secondary" id="tncLogout" (click)="signOut()">{{'COMMON.actionsLabel.LOGOUT' | translate}}</button>
        </div>
      </ng-container>
      <ng-template #READ_ONLY>
        <div class="float-right">
          <button id="TNC_close" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
        </div>
      </ng-template>
    </div>
  </p-footer>

</p-dialog>
