import { DatePipe } from '@angular/common';
import {
     Component,
     ElementRef,
     EventEmitter,
     HostListener,
     OnInit,
     Output } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as moment from 'moment';

@Component({
    selector: 'app-formly-calendar-input',
    templateUrl: './calendar.type.component.html',
    styleUrls: ['./calendar.type.component.scss'],
    providers: [DatePipe],
})
export class FormlyCalendarInputComponent extends FieldType
    implements OnInit {

    public translatePrefix;

    calendarOptionsOpened = false;
    isDateChanged = false;
    dateRangeLabel: string;
    dateSelected: Date;

    @Output() dateChanged = new EventEmitter();
    constructor(
        public datePipe: DatePipe,
        private eRef: ElementRef,
    ) {
        super();
    }

    ngOnInit() {
    }

    toggleCalendar(event) {
        this.calendarOptionsOpened = !this.calendarOptionsOpened;
    }

    setDateRangeLabel() {
        this.dateRangeLabel =
            `${this.datePipe.transform(this.dateSelected, 'dd MMM yyyy')}`;
    }

    customCalendarDatesChanged() {
        this.calendarOptionsOpened = !this.calendarOptionsOpened;
        this.setDateRangeLabel();
        this.isDateChanged = true;
        this.updateDatesInDashboardContext();
    }

    updateDatesInDashboardContext(): void {
        this.dateChanged.emit({
            dateSelected: this.dateSelected,
        });
        this.isDateChanged = false;
      }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.calendarOptionsOpened = false;
        }
    }

}
