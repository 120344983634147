import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { ITableFilterParams } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';
import {
  ACCOUNT_HISTORY_ADMIN_TABLE_DETAILS,
  ACCOUNT_HISTORY_USER_TABLE_DETAILS,
} from './constant/account-history-list.const';
import { NavigationService } from '@shared/services/navigation.service';

const PERMISSION_ACCOUNT_ADMIN_ACTION = 'ACCOUNT_ADMIN_ACTION';

@Component({
  selector: 'app-account-history',
  templateUrl: './account-history.component.html',
  styleUrls: ['./account-history.component.scss'],
})
export class AccountHistoryComponent implements OnInit {
  tableDetails: any;
  filterParams: ITableFilterParams;
  title:string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tableTemplateControls: TableTemplateControls,
    private helperService: HelperService,
    private store: StoreService,
    private _location: Location,
    private navigation: NavigationService,
  ) {}

  ngOnInit() {
    const user = this.store.user;
    if (user.hasPermission(PERMISSION_ACCOUNT_ADMIN_ACTION)) {
      this.tableDetails = ACCOUNT_HISTORY_ADMIN_TABLE_DETAILS;
    } else {
      this.tableDetails = ACCOUNT_HISTORY_USER_TABLE_DETAILS;
    }

    const id = this.activatedRoute.snapshot.params.accountId;


    this.filterParams = {
      type: id,
      action: id,
    };

    this.tableTemplateControls._action.subscribe(metadata => {
      if (metadata.action.workflowAction === 'VIEW') {
        this.router.navigate(
          this.helperService.getTranslatedPath(
            `/account-management/account-details/history`,
          ),
          { state: { data: metadata } },
        );
      }
    });

    this.activatedRoute.data.subscribe(data => {
      this.title = data['title'] + ACCOUNT_HISTORY_ADMIN_TABLE_DETAILS.title;
    });
  }


  returnPreviousLocation(event) {
    event && event.preventDefault();
    this.navigation.back();
  }
}
