import {
  Directive,
  ElementRef,
  Input, OnChanges,
  OnInit, SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[disable-with-validation]',
})
export class DisableWithValidationDirective implements OnInit, OnChanges {

  observer: any;
  el: any;
  @Input('disable-with-validation') disabled = false;

  constructor(
    private elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.el = this.elRef.nativeElement;
    this.updateContainerWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.el && this.updateContainerWidth();
    }
  }

  updateContainerWidth() {
    if (this.disabled) {
      this.el.setAttribute('disabled', 'disabled');
    } else {
      this.el.removeAttribute('disabled');
    }
  }

}
