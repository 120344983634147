import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-textarea',
  template: `
    <label *ngIf="to.dummyLabel === true" [attr.for]="elementId" class="wcag-visuallyhidden">
      <span [innerHTML]="elementId"></span>
    </label>
    <textarea [formControl]="formControl" [cols]="to.cols"
      [rows]="to.rows" class="form-control" [class.is-invalid]="showError"
      [id]="elementId"
      [readOnly]="disabledField"
      [formlyAttributes]="field"
      [tabIndex]="tabIndex"
      title="">
    </textarea>
  `,
})
export class FormlyFieldTextAreaExtra extends FieldType implements OnInit {
  disabledField = false;

  defaultOptions = {
    templateOptions: {
      cols: 1,
      rows: 1,
    },
  };

  ngOnInit() {
    this.disabledField = this.to.disabled;
    this.initDefaultValueSub();
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }
      if (!prefix && this.field.parent.parent && this.field.parent.parent.type === 'form-array') {
        prefix = this.field.parent['id'];
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  private initDefaultValueSub(): void {
    const { defaultValue } = this.to;

    if (typeof defaultValue !== 'string' && typeof defaultValue !== 'number') {
      return;
    }

    const value = typeof defaultValue === 'number' ? defaultValue.toString() : defaultValue;

    this.formControl.setValue(value);
  }

  public get tabIndex(): number {
    return this.to.tabindex || this.field.hide ? -1 : 0;
  }
}
