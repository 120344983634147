import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from '@core/store/store.service';

@Component({
  selector: 'app-custom-popup',
  templateUrl: './custom-popup.component.html',
  styleUrls: ['./custom-popup.component.scss']
})
export class CustomPopupComponent implements OnInit {

  private _destroy$ = new Subject<any>();
  public visible:boolean = false;
  public message:any;
  public translatedMessage: string;
  public title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CustomPopupComponent>,
    private translateService: TranslateService,
    private storeService: StoreService
  ) {  }

  ngOnInit() {
    this.visible = true;
    this.title = this.translateService.instant('COMMON.custom_messages.' + this.data.header_title ) ;
    if (this.data.key) {
      this.message = this.translateService.instant('COMMON.custom_messages.' + this.data.key);
    } else if (this.data.message) {
      this.message = this.data.message;
    }
    this.storeService.gerProgramAdminEmail().subscribe(
      prop => {
        this.message = this.message.replaceAll(`{{paEmail}}`, prop.value);
      },
    );
    this.translatedMessage = this.data.translatedMessage;
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
