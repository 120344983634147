import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../store/store.service";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../services/auth.service";
import * as i5 from "@gilsdav/ngx-translate-router";
import * as i6 from "../../shared/services/helper.service";
import * as i7 from "../../shared/services/notifications.service";
const USER_LIST_URLS = ['/user/list/', '/utilisateur/liste'];
export class AuthenticatedGuard {
    constructor(store, router, translate, authService, localizeService, helperService, serviceMessageComponent) {
        this.store = store;
        this.router = router;
        this.translate = translate;
        this.authService = authService;
        this.localizeService = localizeService;
        this.helperService = helperService;
        this.serviceMessageComponent = serviceMessageComponent;
    }
    canActivate(next, state) {
        if (['/en', '/fr'].includes(state.url)) {
            if (this.store.user.menuItems) {
                const initialPath = this.store.user.menuItems[0].path;
                this.router.navigate(this.helperService.getTranslatedPath(`/${initialPath}`));
                return of(false);
            }
            else {
                this.router.navigate([this.localizeService.translateRoute('/welcome')]);
                return of(false);
            }
        }
        else {
            return this.canBeActivated(next, state.url);
        }
    }
    canActivateChild(next, state) {
        return this.canBeActivated(next, state.url);
    }
    canBeActivated(next, stateUrl) {
        const session = this.store.getSession();
        if (!session) {
            this.router.navigate([this.localizeService.translateRoute('/welcome')]);
            return of(false);
        }
        else {
            if (this.checkUserdetailPresent() || USER_LIST_URLS.map(url => this.localizeService.translateRoute(url)).some(a => stateUrl.includes(a))) {
                this.authService.resetInactivity();
                return of(typeof session !== 'undefined');
            }
            else {
                if (this.store.getTcAccept() === 'loaded') {
                    this.serviceMessageComponent.showMessage("ERROR" /* ERROR */, 'pleaseCompleteUserProfile');
                }
                else if (this.store.getTcAccept() === 'true') {
                    this.store.setTcAccept('loaded');
                }
                this.router.navigate(this.helperService.getTranslatedPath(`/user/list/${this.store.user.id}`));
                return of(typeof session !== 'undefined');
            }
        }
    }
    checkUserdetailPresent() {
        const userDetailsPresent = this.store.user.businessTelephone; // && this.store.user.title;
        if (this.store.user.isCFR()) {
            if (this.store.user.hasRole('ACCOUNT_HOLDER_ENHANCED_USER') && this.store.user.associationsList[0].accountDto &&
                this.store.user.associationsList[0].accountDto.status !== 'ACTIVE') {
                return true;
            }
            return userDetailsPresent && this.store.user.civicAddress;
        }
        return true;
    }
}
AuthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticatedGuard_Factory() { return new AuthenticatedGuard(i0.ɵɵinject(i1.StoreService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.AuthService), i0.ɵɵinject(i5.LocalizeRouterService), i0.ɵɵinject(i6.HelperService), i0.ɵɵinject(i7.NotificationsService)); }, token: AuthenticatedGuard, providedIn: "root" });
