import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestInterceptorService } from '@core/interceptors/request-interceptor.service';
import { RespondInterceptorService } from '@core/interceptors/respond-interceptor.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { AddressViewModule } from '@shared/components/address-view/address-view.module';
import { NgSelectModule } from '@shared/components/ng-select/ng-select.module';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { ModalService } from '@shared/services/modal.service';
import { SharedModule } from '@shared/shared.module';
import { AccordionModule, DialogModule, FieldsetModule, MessageService } from 'primeng';
import { AgreementCreditRoutingModule } from './agreement-credits.routing';
import { AgreementCreateCreditsComponent } from './components/agreement-create-credits/agreement-create-credits.component';
import { AgreementCreditsListComponent } from './components/agreement-credits-list/agreement-credits-list.component';
import { AuthContactPersonInfoComponent } from './components/auth-contact-person-info/auth-contact-person-info.component';
import { GenAgreementComponent } from './components/gen-agreement/gen-agreement.component';
import { OtherPartyOrgDetailsComponent } from './components/other-party-org-details/other-party-org-details.component';
import { RcaAuthContactPersonInfoComponent } from './components/rca-auth-contact-person-info/rca-auth-contact-person-info.component';
import { RcaOrgDetailsComponent } from './components/rca-org-details/rca-org-details.component';
import { ReviewActivitiesComponent } from './components/review-activities/review-activities.component';
import { SelectPartyComponent } from './components/select-party/select-party.component';
import { SignAgreementComponent } from './components/sign-agreement/sign-agreement.component';
import { StepHeaderComponent } from './components/step-header/step-header.component';
import { UploadAgreementComponent } from './components/upload-agreement/upload-agreement.component';
import { AgreementStepperComponent } from './containers/agreement-stepper/agreement-stepper.component';
import { MainPageComponent } from './containers/main-page/main-page.component';

@NgModule({
  declarations: [
    MainPageComponent,
    AgreementCreditsListComponent,
    AgreementCreateCreditsComponent,
    SelectPartyComponent,
    ReviewActivitiesComponent,
    OtherPartyOrgDetailsComponent,
    AuthContactPersonInfoComponent,
    GenAgreementComponent,
    SignAgreementComponent,
    StepHeaderComponent,
    AgreementStepperComponent,
    UploadAgreementComponent,
    RcaOrgDetailsComponent,
    RcaAuthContactPersonInfoComponent,
  ],
  imports: [
    CommonModule,
    AgreementCreditRoutingModule,
    SharedModule,
    LocalizeRouterModule,
    NgSelectModule,
    FontAwesomeModule,
    FormsModule,
    DialogModule,
    ReactiveFormsModule,
    AccordionModule,
    FieldsetModule,
    AddressViewModule,
    SharedPipesModule,
  ],
  exports: [
    SelectPartyComponent,
    ReviewActivitiesComponent,
    OtherPartyOrgDetailsComponent,
    AuthContactPersonInfoComponent,
    GenAgreementComponent,
    SignAgreementComponent,
    AgreementCreditsListComponent,
  ],
  entryComponents: [
    SelectPartyComponent,
    ReviewActivitiesComponent,
    OtherPartyOrgDetailsComponent,
    AuthContactPersonInfoComponent,
    GenAgreementComponent,
    SignAgreementComponent,
    AgreementCreditsListComponent,
    UploadAgreementComponent,
    RcaOrgDetailsComponent,
    RcaAuthContactPersonInfoComponent,
  ],
  providers: [
    MessageService,
    ModalService,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AgreementCreditsModule { }
