import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil } from 'rxjs/operators';
export class FormlyFieldTypeahead extends FieldType {
    constructor() {
        super(...arguments);
        this.onDestroy$ = new Subject();
        this.search$ = new EventEmitter();
    }
    ngOnInit() {
        this.options$ = this.search$.pipe(takeUntil(this.onDestroy$), startWith(''), filter(v => v !== null), debounceTime(200), distinctUntilChanged(), switchMap(this.to.search$));
        this.options$.subscribe();
    }
    get elementId() {
        if (this.field.id && this.field.id.includes('formly_')) {
            let prefix = this.form['id'];
            // check if in repeat section
            if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
                const cnt = this.field.parent.parent.key;
                prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
            }
            return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
        }
        else {
            return this.field.id;
        }
    }
    ngOnDestroy() {
        this.onDestroy$.complete();
    }
}
