/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../directives/wcag-dummy-link-target.directive";
import * as i6 from "./footer.component";
import * as i7 from "../../../core/store/store.service";
import * as i8 from "../../services/modal.service";
import * as i9 from "@angular/router";
import * as i10 from "../../services/helper.service";
import * as i11 from "../../../modules/registration/registration.service";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
function View_FooterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ": "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.dateRevised")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.revisedDate)); _ck(_v, 5, 0, currVal_1); }); }
function View_FooterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.contactUs($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.reportProblemLink")); _ck(_v, 2, 0, currVal_0); }); }
function View_FooterComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "ml-4"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openTnC($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.terms")); _ck(_v, 2, 0, currVal_0); }); }
function View_FooterComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "ml-4"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPrivacy($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.privacy")); _ck(_v, 2, 0, currVal_0); }); }
function View_FooterComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "ml-4"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openRegistrationPrivacy($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.privacy")); _ck(_v, 2, 0, currVal_0); }); }
function View_FooterComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "ml-4"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPublicPagePrivacy($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.privacy")); _ck(_v, 2, 0, currVal_0); }); }
function View_FooterComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "ml-4"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPublicPageDisclaimer($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_FOOTER.footerSection.disclaimer")); _ck(_v, 2, 0, currVal_0); }); }
function View_FooterComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("APP_FOOTER.footerSection.aboutCATSLink")); _ck(_v, 1, 0, currVal_0); }); }
function View_FooterComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("APP_FOOTER.footerSection.supportedBrowsersLink")); _ck(_v, 0, 0, currVal_0); }); }
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 17, "p", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_6)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_7)), i1.ɵdid(14, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 4, "a", [["class", "ml-4"], ["href", "#"], ["wcag-dummy-link-target", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPopup($event, (!_co.isOffsetPublicPage ? "aboutCATSTitle" : "supportedBrowsersTitle"), "supportedBrowsers") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 212992, null, 0, i5.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FooterComponent_8)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["OFFSET_PUBLIC_PAGE", 2]], null, 0, null, View_FooterComponent_9)), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "ca-logo"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isOffsetPublicPage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isLoggedIn; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.isLoggedIn; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.isLoggedIn; _ck(_v, 8, 0, currVal_3); var currVal_4 = (!_co.isLoggedIn && _co.registrationType); _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.publicPage; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.publicPage; _ck(_v, 14, 0, currVal_6); _ck(_v, 16, 0); var currVal_7 = !_co.isOffsetPublicPage; var currVal_8 = i1.ɵnov(_v, 19); _ck(_v, 18, 0, currVal_7, currVal_8); }, null); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i6.FooterComponent, [i7.StoreService, i8.ModalService, i3.TranslateService, i9.Router, i10.HelperService, i11.RegistrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("app-footer", i6.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
