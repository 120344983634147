import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
let FormlyTable = class FormlyTable extends FieldType {
    constructor(cd) {
        super();
        this.cd = cd;
        this.currentSelection = [];
        this.selection = [];
        this.entryId = 0;
    }
    get data() {
        const control = this.form.get(this.controlId);
        if (control && control.value && control.value !== null) {
            return this.to.config.paginator ? control.value : [...control.value];
        }
        return this.to.config.paginator ? { content: [] } : [];
    }
    set data(val) {
        this.onTableUpdate(val);
        if (this.form.get(this.controlId)) {
            this.form.get(this.controlId).setValue(val);
        }
    }
    get controlId() {
        return this.to.controlId;
    }
    ngOnInit() {
        this.initApplyInitialDataSub();
        this.initUpdateDataSub();
        this.initRefreshSelectedSub();
        this.disableTableIfApplicable();
        if (this.to.selections) {
            this.selection = [...this.to.selections];
        }
    }
    onRowSelected(data, background = false) {
        const { onRowSelected } = this.to;
        if (typeof onRowSelected === 'function') {
            this.currentSelection = data;
            onRowSelected(data, background);
        }
    }
    onPaginationChanged($event) {
        const { onPaginationChanged } = this.to;
        if (typeof onPaginationChanged === 'function') {
            onPaginationChanged($event);
        }
    }
    disableTableIfApplicable() {
        if (this.to.disabled) {
            this.to.config.selectDisabled = true;
        }
    }
    initApplyInitialDataSub() {
        const { initialData } = this.to;
        const source = initialData instanceof Array ? initialData : [];
        this.applyData(source);
    }
    initRefreshSelectedSub() {
        const requestSelected = this.to.requestSelected;
        if (!requestSelected) {
            return;
        }
        requestSelected.pipe(untilDestroyed(this)).subscribe(value => {
            this.currentSelection = value;
            this.selection = [...value];
            this.onRowSelected(this.currentSelection, true);
        });
    }
    initUpdateDataSub() {
        const updateData = this.to.updateData;
        if (!updateData) {
            return;
        }
        updateData.pipe(untilDestroyed(this)).subscribe(value => {
            this.applyData(value);
        });
    }
    applyData(data) {
        if (this.to.config.paginator) {
            this.data = data;
        }
        else {
            this.data = data.map(entry => {
                this.entryId++;
                return Object.assign({}, entry, { id: entry.id ? entry.id : this.entryId });
            });
        }
        this.cd.detectChanges();
    }
    onTableUpdate(value) {
        const { onTableUpdate } = this.to;
        if (typeof onTableUpdate === 'function') {
            onTableUpdate(value);
        }
    }
};
FormlyTable = tslib_1.__decorate([
    UntilDestroy()
], FormlyTable);
export { FormlyTable };
