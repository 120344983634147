import { FieldType } from '@ngx-formly/core';
export class FormlyFieldRadioExtra extends FieldType {
    constructor() {
        super(...arguments);
        this.defaultOptions = {
            templateOptions: {
                options: [],
                formCheck: 'custom',
            },
        };
    }
    isData(option) {
        return option.data;
    }
    get itemLabelPrefix() {
        return this.to.itemLabelPrefix ? `${this.to.itemLabelPrefix}_` : '';
    }
    get elementId() {
        if (this.field.id && this.field.id.includes('formly_')) {
            let prefix = this.form['id'] || (this.formState && this.formState['id']);
            // check if in repeat section
            if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
                const cnt = this.field.parent.parent.key;
                prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
            }
            return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
        }
        else {
            return this.field.id;
        }
    }
    getTooltipClass(index) {
        return this.to.options[index].tooltipClass || '';
    }
    get tabIndex() {
        return this.to.tabindex || this.field.hide ? -1 : 0;
    }
}
