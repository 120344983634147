import * as tslib_1 from "tslib";
import { OnInit, TemplateRef } from '@angular/core';
import { AccountSummaryProjectsTableFilter } from '@core/models/filter-types.model';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { FILTER_COLUMNS, PROJECT_DETAILS } from './account-summary-projects.const';
import { isEqual } from 'lodash';
export class AccountSummaryProjectsComponent {
    constructor(navigation, lookupService, accountManagementService) {
        this.navigation = navigation;
        this.lookupService = lookupService;
        this.accountManagementService = accountManagementService;
        this.showDetails = false;
        this.showTableDetails = false;
        this.filterFields = AccountSummaryProjectsTableFilter;
        this.pagination = {
            page: 0,
            size: 20,
            dir: undefined,
            sort: undefined
        };
        this.firstItemIndex = 0;
        this.filterState = {};
        this.projectDetails = PROJECT_DETAILS;
    }
    ngOnInit() {
        this.accountId = history.state.accountId;
        this.setTableConfigs();
    }
    viewProject(event, data) {
        if (!data) {
            return;
        }
        this.highlightRowId = data.id;
        this.selectedProject = data;
        this.showDetails = true;
        setTimeout(() => {
            this.showTableDetails = true;
        }, 300);
    }
    close() {
        this.showDetails = false;
        this.showTableDetails = false;
    }
    onFilterChanged(filterData) {
        if (!filterData.facilityNameList || !filterData.facilityNameList.length && this.accountId) {
            filterData.facilityNameList = [this.accountId];
        }
        if (isEqual(filterData, this.filterState)) {
            return;
        }
        this.firstItemIndex = 0;
        this.filterState = Object.assign({}, filterData);
        this.loadData(this.pagination, this.filterState);
    }
    onPaginationChanged(pagination) {
        if (isEqual(pagination, this.pagination)) {
            return;
        }
        this.pagination = pagination;
        this.firstItemIndex = (this.pagination.page * this.pagination.size);
        this.loadData(this.pagination, this.filterState);
    }
    loadData(pagination, filterState = {}) {
        this.showDetails = false;
        this.showTableDetails = false;
        this.accountManagementService.getAccountProjects(pagination, filterState).subscribe(data => {
            this.tableData = data;
            this.viewProject(null, data.content[0]);
        });
    }
    back() {
        this.navigation.back();
    }
    setTableConfigs() {
        this.lookupService.getTableConfigurations('OFFSET_DASHBOARD_PROJECTS').subscribe(data => {
            this.tableConfig = data;
            const _a = this.tableConfig, { columns } = _a, config = tslib_1.__rest(_a, ["columns"]);
            const _columns = [
                ...this.tableConfig.columns.filter(column => FILTER_COLUMNS.includes(column.field)),
                {
                    header: 'details',
                    width: AUTO_WIDTH_FOR_COLUMNS,
                    templateRef: this.viewColTemplate,
                }
            ];
            this.filterFields = Object.assign({}, this.filterFields, { filterListsModel: data.filters });
            this.tableConfig = Object.assign({}, config, { columns: _columns });
        });
    }
}
