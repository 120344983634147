import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { validateDecimalPlace } from '@core/utilities/utilities.constants';
import { ProjectTransferDetailsComponent } from '@shared/components/project-builder/project-transfer-details/project-transfer-details.component';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubProjectFormComponent } from './sub-project-form/sub-project-form.component';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { EDIT_PROJECT_PUBLIC_VISIBILITY } from '@core/models/permission.const';
import { PROJECT_ACCOUNT_ACTIVE_STATUSES } from '@shared/components/project/project.constants';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "../../services/modal.service";
import * as i3 from "../../../core/store/store.service";
import * as i4 from "../../services/project.service";
const ADMIN_PERMISSION = 'ACCOUNT_ADMIN_ACTION';
export class ProjectBuilderService {
    constructor(translateService, modalService, store, projectService) {
        this.translateService = translateService;
        this.modalService = modalService;
        this.store = store;
        this.projectService = projectService;
        this.changeActivityView = new BehaviorSubject(false);
        this.model = {
            subProjects: [],
            discardSubProjects: [],
            activity: {
                activityType: null,
                location: {},
            }
        };
        this.historicData = false;
        this.disabled = false;
        this.showAddToSummary = true;
        this.showRequiredDocs = true;
        this.validateFormsReq$ = new Subject();
        this.validateDescriptionFormsRes$ = new BehaviorSubject(false);
        this.validateActivityFormsRes$ = new BehaviorSubject(false);
        this.validateRequiredDocsRes$ = new BehaviorSubject(false);
        this.validateIncorrectValuesReq$ = new Subject();
        this.validateIncorrectValuesRes$ = new Subject();
        this.updateProject = false;
        this.reportId = null;
        this.isProjectPublicExistingValue = null;
        this.validateSubProjectDocsReq$ = new Subject();
        this.subProjectUpdate$ = new Subject();
        this.subProjectAdded$ = new Subject();
        this._activities = [];
        this.activityList$ = new BehaviorSubject([]);
    }
    get selectedCard() {
        return this._selectedCard;
    }
    set selectedCard(value) {
        this._selectedCard = value;
        this.changeActivityView.next(true);
    }
    addActivity(activity) {
        this._activities = [activity];
        this.activityList$.next([...this._activities]);
    }
    getActivities() {
        return this.activityList$;
    }
    setAggregatedEmmissionsDetails() {
        this.model.activity.estimatedScenarioGhg = this.model.subProjects.reduce((acc, subProject) => {
            return Number(acc) + Number(subProject.activity.estimatedScenarioGhg);
        }, 0);
        this.model.activity = Object.assign({}, this.model.activity);
    }
    validateDecimalLatLong(control, value) {
        if (isNaN(value)) {
            control.setErrors({ 'server-side': this.translateService.instant('valueMustBeNumber') });
            return false;
        }
        else {
            if (control && value) {
                if (!validateDecimalPlace(value, 5)) {
                    control.markAsDirty();
                    control.setErrors({ 'server-side': this.translateService.instant('accurateToFourDecimals') });
                    return false;
                }
                else {
                    control.setValue((+value).toFixed(5));
                    return true;
                }
            }
            return true;
        }
    }
    setAggregatedStartDate() {
        const startDates = this.model.subProjects.map(subProject => moment(subProject.activity.activityStartDate));
        this.model.activity.activityStartDate = moment.min(startDates).toDate();
    }
    addSubProject() {
        this.modalService.open(SubProjectFormComponent, {
            model: {
                activity: {
                    location: {},
                },
                accountId: this.model.accountId,
            },
        }, true).afterClosed()
            .subscribe(result => {
            if (result) {
                delete result.activity.location.file;
                result.activityStartDate = result.activity.activityStartDate;
                result.estimatedScenarioGhg = result.activity.estimatedScenarioGhg;
                result.masterProjectId = this.model.activity.projectId;
                result.activity.activityType = Object.assign({}, this.model.activity.activityType);
                this.model.subProjects.push(result);
                this.model.noOfSubProjects = this.model.subProjects.length;
                this.setAggregatedStartDate();
                this.setAggregatedEmmissionsDetails();
                this.setSubprojectPreviouslyRegistered();
                this.model.subProjects = [...this.model.subProjects];
                this.model = Object.assign({}, this.model);
                this.subProjectAdded$.next();
            }
        });
    }
    openConfirmationModal(actionLabel, accountName = this.model.account.legalName, message = 'confirmationMessageProject') {
        return this.modalService.open(ServiceMessageComponent, Object.assign({ messages: null, message }, (actionLabel && { metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                accountName,
            ] }), { type: "WARNING" /* WARNING */ }), true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    openProjectToConsentConfirmationModal(actionLabel, accountName = this.model.account.legalName, message = 'confirmationMessageSendProjectToConsent') {
        return this.modalService.open(ServiceMessageComponent, {
            message,
            metaData: accountName,
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    openProjectTransferDetailsModal(projectData) {
        return this.modalService.open(ProjectTransferDetailsComponent, {
            projectData,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    addPermission(actions, status = this.model.status) {
        const adminPermisson = (actions && actions.some(action => action.workflowAction === 'EDIT')) || (this.hasApproveAction() && (!status || status === 'IN_PROCESS' || this.updateProject));
        this.model.adminPermisson = adminPermisson;
        this.model.activity.adminPermisson = adminPermisson;
    }
    hasApproveAction(discloseComment = this.model.discloseComment) {
        return this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS) || discloseComment;
    }
    hasEditProjectPublicVisibilityAction() {
        return this.store.user.hasPermission(EDIT_PROJECT_PUBLIC_VISIBILITY);
    }
    checkForDisabledState(actions = this.model.entityActionList, status = this.model.status) {
        const editParticipant = this.updateProject || (actions && actions.some(action => action.workflowAction === 'EDIT_CUST') || !status);
        this.disabled = !editParticipant || this.historicData;
        this.model = Object.assign({}, this.model, { disabled: this.disabled, editParticipant, activity: Object.assign({}, this.model.activity, { editParticipant, disabled: this.disabled }) });
        return this.disabled;
    }
    uploadSubProjects(uploadContainer, renderer) {
        const input = renderer.createElement('input');
        renderer.setAttribute(input, 'type', 'file');
        renderer.setStyle(input, 'display', 'none');
        renderer.listen(input, 'change', event => {
            const file = event.target.files[0];
            const formData = new FormData();
            const action = (!this.model.status || this.model.status === 'IN_PROCESS' ||
                this.model.entityActionList && this.model.entityActionList.some(action => action.workflowAction === 'RESUBMIT')) ? 'SAVE' : 'SUBMIT';
            formData.append('file', file);
            formData.append('project', JSON.stringify({ id: this.model.id, action }));
            const sub$ = this.projectService.saveProject(Object.assign({}, this.model, { hideMessageInResponse: true }))
                .pipe(switchMap(() => this.projectService.uploadSubProjects(formData)))
                .subscribe(result => {
                this.updateModel(result.entity);
                sub$.unsubscribe();
            });
        });
        renderer.appendChild(uploadContainer.nativeElement, input);
        input.click();
    }
    getFileName(response) {
        let filename;
        try {
            const contentDisposition = response.headers.get('content-disposition');
            filename = contentDisposition.split('=')[1].replace(/"/g, '');
        }
        catch (e) {
            filename = 'location.kml';
        }
        return filename;
    }
    renderKmlFileUpload(model, file, field, mapFileId, downloadLinkId) {
        if (field.id === mapFileId) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('project', JSON.stringify(model));
            this.projectService.uploadDocument(formData)
                .subscribe(result => {
                model.activity.location.kmlFileExists = true;
                file.arrayBuffer().then((arrayBuffer) => {
                    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(new Blob([blob]));
                    downloadLink.setAttribute('class', 'link');
                    downloadLink.setAttribute('download', file.name);
                    downloadLink.innerText = file.name;
                    const linkDiv = document.createElement('div');
                    linkDiv.appendChild(downloadLink);
                    const doc = document.getElementById(downloadLinkId);
                    const closeButton = document.createElement('div');
                    closeButton.innerHTML = '&times';
                    closeButton.setAttribute('id', 'close-button');
                    if (doc) {
                        closeButton.addEventListener("click", () => {
                            doc.innerHTML = '';
                            document.getElementById(mapFileId)['value'] = null;
                            this.projectService.deleteKmlDocument(result).subscribe();
                        });
                        doc.innerHTML = '';
                        doc.appendChild(linkDiv);
                        doc.appendChild(closeButton);
                    }
                });
            });
        }
    }
    renderDownloadKmlControl(model, mapFileId, downloadLinkId) {
        this.projectService.downloadKML(model.id).subscribe((response) => {
            if (response.body && response.body.size) {
                model.activity.location.kmlFileExists = true;
                let filename = this.getFileName(response);
                let binaryData = [];
                binaryData.push(response.body);
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
                downloadLink.setAttribute('download', filename);
                downloadLink.innerText = filename;
                const doc = document.getElementById(downloadLinkId);
                const closeButton = document.createElement('div');
                closeButton.innerHTML = '&times';
                closeButton.setAttribute('id', 'close-button');
                if (doc) {
                    closeButton.addEventListener('click', () => {
                        doc.innerHTML = '';
                        document.getElementById(mapFileId)['value'] = null;
                        this.projectService.getKmlInfo(model.id).subscribe(fileInfo => {
                            this.projectService.deleteKmlDocument(fileInfo).subscribe();
                        });
                    });
                    doc.innerHTML = '';
                    doc.appendChild(downloadLink);
                    doc.appendChild(closeButton);
                }
            }
        });
    }
    updateModel(result) {
        this.model = Object.assign({}, this.model, result);
        this.model.subProjects = result.subProjects || [];
        this.model.noOfSubProjects = this.model.subProjects.length;
        this.model.discardSubProjects = [];
        this.setAggregatedStartDate();
        this.setAggregatedEmmissionsDetails();
        this.setSubprojectPreviouslyRegistered();
        this.subProjectAdded$.next();
    }
    validateGHGReductions(form, model) {
        const control = form.get('activity.estimatedScenarioGhg');
        if (model.activity.estimatedScenarioGhg <= 0) {
            control.markAsDirty();
            control.setErrors({ 'server-side': this.translateService.instant('cannotBeLessThenZero') });
        }
    }
    validateIncorrectValues(form, valid) {
        Object.values(form.controls).forEach((control) => {
            if (control.controls) {
                valid = this.validateIncorrectValues(control, valid);
            }
            const error = control.errors ? Object.keys(control.errors)[0] : null;
            if (control.status === "INVALID" && ['max', 'min', 'server-side'].includes(error)) {
                valid = false;
            }
        });
        return valid;
    }
    checkForRequiredDocs(missingDocs) {
        let charCode = 97;
        let missingDocMsg = missingDocs.reduce((acc, doc, index) => {
            ++charCode;
            return (index < missingDocs.length - 1) ?
                acc + `${this.translateService.instant(`COMMON.documentList.${doc.type}`)}<br>${String.fromCharCode(charCode)}. ` :
                acc + `${this.translateService.instant(`COMMON.documentList.${doc.type}`)}`;
        }, '');
        const metaData = [`${String.fromCharCode(97)}. ${missingDocMsg}`];
        return this.modalService.open(ServiceMessageComponent, {
            messages: [{
                    message: 'mandatoryInformationRequiredForUploadDocs',
                    metaData
                }],
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG).afterClosed();
    }
    setSubprojectPreviouslyRegistered() {
        if (this.model.subProjects) {
            this.model.subprojectPreviouslyRegistered = this.model.subProjects.some(sub => sub.activity.projectPreviouslyRegistered);
            this.model.declarationLandownerRequired = this.model.subProjects.some(sub => sub.activity.isLandPrivate || sub.activity.isProponentOwner);
        }
    }
    updateControlsValueAndValidity() {
        if (this.translateService.currentLang === 'en') {
            this.projectForm.get('name2').clearValidators();
            this.projectForm.get('name2').reset(this.model.name2);
            this.projectForm.get('name2').updateValueAndValidity();
            this.projectForm.get('description2').clearValidators();
            this.projectForm.get('description2').reset(this.model.description2);
            this.projectForm.get('description2').updateValueAndValidity();
        }
        else {
            this.projectForm.get('name').clearValidators();
            this.projectForm.get('name').reset(this.model.name);
            this.projectForm.get('name').updateValueAndValidity();
            this.projectForm.get('description').clearValidators();
            this.projectForm.get('description').reset(this.model.description);
            this.projectForm.get('description').updateValueAndValidity();
        }
    }
    clearData() {
        this._activities = [];
        this.activityList$.next([]);
        this.model = {
            subProjects: [],
            activity: {
                activityType: null,
            }
        };
        this.projectType = null;
        this.disabled = false;
        this.updateProject = false;
        this.changeActivityView.next(false);
        this.showAddToSummary = true;
        this.showRequiredDocs = true;
        this.historicData = false;
        this.disabled = false;
        this.isProjectPublicExistingValue = null;
        this.projectForm = null;
        this.reportId = null;
        if (this.subscriptions$) {
            this.subscriptions$.unsubscribe();
        }
    }
    get isProjectAccountActive() {
        return PROJECT_ACCOUNT_ACTIVE_STATUSES.includes(this.model.account.status);
    }
}
ProjectBuilderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectBuilderService_Factory() { return new ProjectBuilderService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.StoreService), i0.ɵɵinject(i4.ProjectService)); }, token: ProjectBuilderService, providedIn: "root" });
