import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ServiceMessageModel } from '@core/models/serviceMessage.model';
import { ModalService } from '@shared/services/modal.service';
import { Observable, throwError ,  EMPTY ,  NEVER } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor( private modalService: ModalService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }

}
