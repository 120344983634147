/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-transactions.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i5 from "../../../../shared/components/table-view/table-view.component";
import * as i6 from "./top-transactions.component";
import * as i7 from "../../services/account-management.service";
import * as i8 from "../../../../core/store/store.service";
var styles_TopTransactionsComponent = [i0.styles];
var RenderType_TopTransactionsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopTransactionsComponent, data: {} });
export { RenderType_TopTransactionsComponent as RenderType_TopTransactionsComponent };
export function View_TopTransactionsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-table-view", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_TableViewComponent_0, i4.RenderType_TableViewComponent)), i1.ɵdid(5, 638976, null, 0, i5.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.topTransactions; var currVal_2 = _co.topTransactionsTableConfig; _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("COMMON.titles.viewLastFiveTransactions" + _co.labelPrefix))); _ck(_v, 1, 0, currVal_0); }); }
export function View_TopTransactionsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-transactions", [], null, null, null, View_TopTransactionsComponent_0, RenderType_TopTransactionsComponent)), i1.ɵdid(1, 245760, null, 0, i6.TopTransactionsComponent, [i7.AccountManagementService, i8.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopTransactionsComponentNgFactory = i1.ɵccf("app-top-transactions", i6.TopTransactionsComponent, View_TopTransactionsComponent_Host_0, { topTransactions: "topTransactions" }, {}, []);
export { TopTransactionsComponentNgFactory as TopTransactionsComponentNgFactory };
