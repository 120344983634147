import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { FormlyConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isObject } from 'util';

@Component({
  selector: 'formly-custom-validation-message',
  template: `<span [translate]=errorMessage></span>
        <span *ngIf="errorMessage$ | async" class="wcag-visuallyhidden" role="alert" [attr.aria-label]="errorLabel"></span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyCustomValidationMessage implements OnChanges {
  @Input() field: FormlyFieldConfig;
  errorMessage$: Observable<string>;

  constructor(private formlyConfig: FormlyConfig) {}

  get errorLabel() {
    const fieldForm = this.field.formControl;
    if (fieldForm.errors['required']) {
      return `${this.field.templateOptions.label} is required!`;
    } else {
      return this.errorMessage;
    }
  }

  ngOnChanges() {
    this.errorMessage$ = this.field.formControl.statusChanges.pipe(
      startWith(null),
      map(() => this.errorMessage),
    );
  }

  get errorMessage(): string {
    const fieldForm = this.field.formControl;
    for (const error in fieldForm.errors) {
      if (fieldForm.errors.hasOwnProperty(error)) {
        let message: string | Function = this.formlyConfig.getValidatorMessage(error);

        if (isObject(fieldForm.errors[error])) {
          if (fieldForm.errors[error].errorPath) {
            return;
          }

          if (fieldForm.errors[error].message) {
            message = fieldForm.errors[error].message;
          }
        }

        if (this.field.validation && this.field.validation.messages && this.field.validation.messages[error]) {
          message = this.field.validation.messages[error];
        }

        if (this.field.validators && this.field.validators[error] && this.field.validators[error].message) {
          message = this.field.validators[error].message;
        }

        if (this.field.asyncValidators && this.field.asyncValidators[error] && this.field.asyncValidators[error].message) {
          message = this.field.asyncValidators[error].message;
        }

        if (typeof message === 'function') {
          return message(fieldForm.errors[error], this.field);
        }

        return message;
      }
    }
  }
}
