import { HttpResponse } from '@angular/common/http';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, IGNORE_ERROR_URIS, ORGANIZATION_NAME_REQUIRED_TYPES } from '@core/constants/serviceMessage.const';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../shared/services/modal.service";
export class RespondInterceptorService {
    constructor(router, modalService) {
        this.router = router;
        this.modalService = modalService;
    }
    isQuiet(event) {
        const url = new URL(event.url);
        const searchParams = new URLSearchParams(url.search);
        return searchParams.has('quiet');
    }
    intercept(req, next) {
        return next.handle(req).pipe(tap((event) => {
            if (event instanceof HttpResponse && event.status == 200 && event.body && event.body.message) {
                if (event.body.message !== 'SUCCESS' && event.body.message !== 'MFATokenMissing'
                    && event.body.message.indexOf('SAMLFirst') == -1) {
                    const messages = {
                        messages: [Object.assign({ message: event.body.message }, this._addMetaDataIfNeeded(event.body))],
                        type: "SUCCESS" /* SUCCESS */,
                    };
                    !this.isQuiet(event) && this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                        .afterClosed()
                        .subscribe((result) => { });
                }
            }
        }, (err) => {
            let skipMessage = false;
            if (err.url) {
                IGNORE_ERROR_URIS.forEach(uri => {
                    if (err.url.indexOf(uri) > -1) {
                        skipMessage = true;
                    }
                });
            }
            if (!skipMessage) {
                if (err.error && err.error.messages && err.error.messages.length > 0) {
                    const messageList = err.error.messages.map(message => ({
                        message,
                    }));
                    const messages = {
                        messages: messageList,
                        type: "ERROR" /* ERROR */,
                    };
                    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                        .afterClosed()
                        .subscribe((result) => {
                    });
                }
                else if (err.error && err.error.dynamicFieldMessages && err.error.dynamicFieldMessages.length > 0) {
                    const messages = {
                        messages: err.error.dynamicFieldMessages,
                        type: "ERROR" /* ERROR */,
                    };
                    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                        .afterClosed()
                        .subscribe((result) => {
                    });
                }
                else if (err.error && err.error.code === 401) {
                    const messageList = [{ message: 'accessDenied' }];
                    const messages = {
                        messages: messageList,
                        type: "ERROR" /* ERROR */,
                    };
                    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                        .afterClosed()
                        .subscribe((result) => {
                    });
                }
            }
        }));
    }
    _addMetaDataIfNeeded(data) {
        if (ORGANIZATION_NAME_REQUIRED_TYPES.includes(data.message)) {
            return { metaData: [data.entity.organizationName] };
        }
        return {};
    }
}
RespondInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RespondInterceptorService_Factory() { return new RespondInterceptorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.ModalService)); }, token: RespondInterceptorService, providedIn: "root" });
