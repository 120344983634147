/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./view-reconciliation-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i3 from "primeng/dialog";
import * as i4 from "../../../shared/pipes/custom-translate.pipe";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../shared/components/table-view/table-view.component.ngfactory";
import * as i7 from "../../../shared/components/table-view/table-view.component";
import * as i8 from "./view-reconciliation-details.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../core/services/lookup.service";
import * as i11 from "../material-balance-report.service";
var styles_ViewReconciliationDetailsComponent = [i0.styles];
var RenderType_ViewReconciliationDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ViewReconciliationDetailsComponent, data: {} });
export { RenderType_ViewReconciliationDetailsComponent as RenderType_ViewReconciliationDetailsComponent };
export function View_ViewReconciliationDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { dialog: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "p-dialog", [], [[8, "className", 0]], [[null, "visibleChange"], [null, "onShow"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onShow" === en)) {
        var pd_1 = (_co.scrollToTop() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Dialog_0, i2.RenderType_Dialog)), i1.ɵdid(2, 180224, [[1, 4], ["dialog", 4]], 2, i3.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { onShow: "onShow", visibleChange: "visibleChange" }), i1.ɵqud(603979776, 2, { headerFacet: 1 }), i1.ɵqud(603979776, 3, { footerFacet: 1 }), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_TableViewComponent_0, i6.RenderType_TableViewComponent)), i1.ɵdid(9, 638976, null, 0, i7.TableViewComponent, [i1.ElementRef, i5.TranslateService], { data: [0, "data"], config: [1, "config"] }, { paginationChanged: "paginationChanged" }), (_l()(), i1.ɵeld(10, 0, null, 1, 3, "div", [["class", "d-flex justify-content-end mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform(_co.formHeader)); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = _co.visible; var currVal_9 = _ck(_v, 6, 0, "1000px"); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.tableData; var currVal_11 = _co.tableConfig; _ck(_v, 9, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = "eccc"; _ck(_v, 1, 0, currVal_0); var currVal_12 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 12, 0, currVal_12); }); }
export function View_ViewReconciliationDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-view-reconciliation-details", [], null, null, null, View_ViewReconciliationDetailsComponent_0, RenderType_ViewReconciliationDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i8.ViewReconciliationDetailsComponent, [i9.MAT_DIALOG_DATA, i9.MatDialogRef, i10.LookupService, i11.MaterialBalanceReportService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewReconciliationDetailsComponentNgFactory = i1.ɵccf("app-view-reconciliation-details", i8.ViewReconciliationDetailsComponent, View_ViewReconciliationDetailsComponent_Host_0, {}, {}, []);
export { ViewReconciliationDetailsComponentNgFactory as ViewReconciliationDetailsComponentNgFactory };
