import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { IUserData } from '@core/models/user.model';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-app-wrapper',
  templateUrl: './app-wrapper.component.html',
  styleUrls: ['./app-wrapper.component.scss'],
})
export class AppWrapperComponent implements OnInit {
  isNavbarCollapsed = true;
  userData: IUserData;

  constructor(
    private store: StoreService,
    private helperService: HelperService,
    private router: Router,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((routeData: RouterEvent) => {
      const activeMenuItemFound = this.setActiveMenuItem(routeData.url);

      if (!activeMenuItemFound && this.router.getCurrentNavigation().previousNavigation) {
        const previousUrl = this.router.getCurrentNavigation()
          .previousNavigation.finalUrl.toString();
        this.setActiveMenuItem(previousUrl);
      }

      setTimeout(() => {
        const mainEl = document.getElementById('main-content');
        if (mainEl) {
          const innerEl = mainEl.getElementsByClassName('inner')[0];
          if (innerEl) {
            innerEl.scrollTo(0, 0);
          }
        }
      });
    });
  }

  ngOnInit() {
    this.store.user$.subscribe(userData => {
      this.userData = userData;
      this.setActiveMenuItem(this.router.url);

      if(this.store.user.isOffset()){
        this.userData.menuItems.find( item => {
          if (item.name === 'MENU_PROGRAM_MANAGEMENT') {
            item.path = 'program-management/offsets-results'
          }
        });
      }
    });
  }

  getTranslatedPath(path) {
    return this.helperService.getTranslatedPath(path).join('/');
  }

  setActiveMenuItem(url) {
    let activeItemFound = false;

    if (this.userData) {
      url = this.helperService.getTranslatedPath(url.split('/')[2])[0];

      this.userData.menuItems.forEach(item => {
        const path = this.helperService
          .getTranslatedPath(item.path.split('/')[0])[0];
        var decodedStr = decodeURIComponent(url);
        item.isActive = path === url || path === decodedStr;
        if (!item.isActive && item.urlMappings) {
          item.isActive = item.urlMappings.includes(url);
        }

        if (item.isActive) {
          activeItemFound = true;
        }
      });
    }

    return activeItemFound;
  }
}
