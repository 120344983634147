import { Input } from '@angular/core';
import { TemplateRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel } from '@core/models/filter.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { regReportTableConfig } from '@module/account-management/account-management.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reg-rept-list',
  templateUrl: './reg-rept-list.component.html',
  styleUrls: ['./reg-rept-list.component.scss']
})
export class RegReptListComponent implements OnInit {

  @Input() accountId : number;
  @Input() accountStatus : string;
  @ViewChild('actionsTemplate', {static: true}) public actionsTemplate: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();

  private pagination = {
    page: 0,
    size: 5,
  } as IApp.IPagination;
  firstItemIndex = 0;
  filterState: FilterModel = {};

  tableConfig: ITableViewConfig;
  regDetailsList: any;

  constructor(
    private router: Router,
    private accountManagementService: AccountManagementService,
    private cfrRegService: CfrRegistrationService,
    private translateService : TranslateService,
    private lookupService: LookupService,
    public helperService: HelperService,
    private storeService: StoreService) { }

  ngOnInit() {

    console.log(this.accountId);
    this.lookupService.getTableConfigurations('REGISTRATION').subscribe(
      data => {
         this.tableConfig = data;
         const { columns, ...config } = this.tableConfig;

         const _columns: any = [
           ...this.tableConfig.columns,
           {
            header: 'view',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          },
          {
            header: 'actions',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.actionsTemplate,
          }];

         this.tableConfig = {
           ...config,
           ...{columns: _columns},
         };
      },
    );

  }

  private loadData(pagination: IApp.IPagination, filterState: FilterModel = {}) {
    this._stopPrevious$.next();
    filterState.accountId = this.accountId;
    this.accountManagementService.getRegistrationDetails(pagination, filterState)
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(data => {
          this.regDetailsList = data;
        });
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadData(this.pagination, this.filterState);
  }

  refreshData(event) {
    this.loadData(this.pagination, this.filterState);
  }

  viewAccount(event, data) {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    const code = data.accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB ?
    IApp.NSAccountSubType.StatusEnum.VB :  IApp.NSAccountSubType.StatusEnum.PS;
    this.storeService.setHistoryUrl();
    this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/RR${code}/${this.accountId}/${data.id}`));
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
