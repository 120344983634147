/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step-title.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./step-title.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../services/helper.service";
import * as i8 from "../../../../modules/submission-management/services/submission-management.service";
import * as i9 from "../../../../core/store/store.service";
var styles_StepTitleComponent = [i0.styles];
var RenderType_StepTitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepTitleComponent, data: {} });
export { RenderType_StepTitleComponent as RenderType_StepTitleComponent };
function View_StepTitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "h2", [["class", "mb-5"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.instrumentIdText; _ck(_v, 0, 0, currVal_0); }); }
function View_StepTitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-secondary float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToRegistrationReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.VIEW_REGISTRATION_REPORT")); _ck(_v, 2, 0, currVal_0); }); }
export function View_StepTitleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "section", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "title"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "h2", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepTitleComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepTitleComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.instrumentIdText; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.hasPermission; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(("COMMON.submissionTypeList.APPLICATION_" + _co.data.type))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.legalName; _ck(_v, 4, 0, currVal_1); }); }
export function View_StepTitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-step-title", [], null, null, null, View_StepTitleComponent_0, RenderType_StepTitleComponent)), i1.ɵdid(1, 114688, null, 0, i5.StepTitleComponent, [i6.Router, i7.HelperService, i3.TranslateService, i8.SubmissionManagementService, i9.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepTitleComponentNgFactory = i1.ɵccf("app-step-title", i5.StepTitleComponent, View_StepTitleComponent_Host_0, { data: "data", type: "type", parentComponent: "parentComponent" }, {}, []);
export { StepTitleComponentNgFactory as StepTitleComponentNgFactory };
