import { environment } from '@env/environment';
import { BaseAccountActionsService } from '@module/account-management/services/base-account-actions.service';
import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../shared/services/helper.service";
const ACCOUNT_REJECT2 = '/account-service/account/dualControlReject';
export class OffsetAccountActionsService extends BaseAccountActionsService {
    constructor(router, helperService) {
        super();
        this.router = router;
        this.helperService = helperService;
        this.customConfirmations = {
            APPROVE_ALL_LINKED_ACCOUNTS: 'APPROVE_ALL_LINKED_ACCOUNTS',
        };
    }
    getConfirmationMsg(key) {
        return this.customConfirmations[key] || 'confirmationMessageAccount';
    }
    approveAccount(account) {
        this.router.navigate(this.helperService.getTranslatedPath(`/account-management/approve-account/${account.id}`));
        return of({});
    }
    reject2Account(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_REJECT2}`, account);
    }
    approve2Account(account) {
        this.router.navigate(this.helperService.getTranslatedPath(`/account-management/approve-account/${account.id}`));
        return this.http.post(`${environment.apiUrl}`, account);
    }
    approvesubAccount(account) {
        return super.approveAccount(account);
    }
    approve2SubAccount(account) {
        return super.approve2Account(account);
    }
    showConfirmationMessageForApproval() {
        return false;
    }
    showConfirmationMessageForRejection() {
        return false;
    }
}
OffsetAccountActionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OffsetAccountActionsService_Factory() { return new OffsetAccountActionsService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.HelperService)); }, token: OffsetAccountActionsService, providedIn: "root" });
