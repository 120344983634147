<p-dialog
  [className]="'eccc'"
  [header]="'Required Information'"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <div>

    <formly-form [form]="infoForm" [model]="data" [fields]="fields"></formly-form>

  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="REQUIRED_INFORMATION_continueBtn" type="button" class="btn btn-secondary" type="submit" (click)="continue()">{{'COMMON.actionsLabel.CONTINUE' | translate}}</button>
      </div>
      <div class="float-right">
        <button id="REQUIRED_INFORMATION_logoutBtn" type="button" class="btn btn-secondary" (click)="signOut()">{{'COMMON.actionsLabel.LOGOUT' | translate}}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
