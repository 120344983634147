import { Routes } from '@angular/router';
import { MOPGuard } from '@core/guards/mop.guard';
import { AccountSummaryComponent } from '@module/account-management/components/account-summary/account-summary.component';
import { AccountsListComponent } from '@module/account-management/components/accounts-list/accounts-list.component';
import { EnhancedUserDashboardComponent } from '@module/account-management/components/enhanced-user-dashboard/enhanced-user-dashboard.component';
import { EnhancedUserSidebarComponent } from '@module/account-management/components/enhanced-user-sidebar/enhanced-user-sidebar.component';
import { ManageAccountComponent } from '@module/account-management/components/manage-account/manage-account.component';
import { AccountPageComponent } from '@module/account-management/containers/account-page/account-page.component';
import { AddAdditionalUsersComponent } from "@shared/components/add-additional-users/add-additional-users.component";
import { AccountRegistrationComponent } from './components/account-registration/account-registration.component';
import { ChangePasswordComponent } from "@module/account-management/components/change-password/change-password.component";
import { UserDetailsCanDeactivateGuard } from "@core/guards/documentUploadCheckCanDeactivate.guard";
import { AccountApprovalComponent } from './components/account-approval/account-approval.component';
import { ReplacingMaoComponent } from './components/replacing-mao/replacing-mao.component';
import { AuthenticatedGuard } from '@core/guards/authenticated.guard';
import { ReqRegCancellationComponent } from './components/req-reg-cancellation/req-reg-cancellation.component';
import { AccountHistoryComponent } from './components/account-history/account-history.component';
import { AccountHistoryViewComponent } from './components/account-history-view/account-history-view.component';
import { AccountSummaryProjectsComponent } from './components/account-summary-projects/account-summary-projects.component';
const ɵ0 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission: 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' }, ɵ1 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle' }, ɵ2 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle' }, ɵ3 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle' }, ɵ4 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission: 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' }, ɵ5 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission: 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' }, ɵ6 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission: 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' }, ɵ7 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission: 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' }, ɵ8 = {
    title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
    actionPanel: true,
    actionPanelComponent: EnhancedUserSidebarComponent,
}, ɵ9 = {
    title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
    actionPanel: false
}, ɵ10 = {
    title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
    actionPanel: true,
    actionPanelComponent: EnhancedUserSidebarComponent,
}, ɵ11 = { title: 'ACCOUNTS_MODULE.accountList.pageTitle' }, ɵ12 = {
    title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
    actionPanel: true,
    actionPanelComponent: EnhancedUserSidebarComponent,
}, ɵ13 = {
    title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
    actionPanel: true,
    actionPanelComponent: EnhancedUserSidebarComponent,
}, ɵ14 = {
    actionPanel: true,
    actionPanelComponent: EnhancedUserSidebarComponent,
}, ɵ15 = {
    title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
    actionPanel: true,
    actionPanelComponent: EnhancedUserSidebarComponent,
}, ɵ16 = {
    title: 'ACCOUNTS_MODULE.pageTitle',
};
const routes = [
    {
        path: '',
        component: AccountPageComponent,
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'summary-admin',
            },
            {
                // MOP section
                path: 'summary-admin',
                canActivate: [MOPGuard],
                data: ɵ0,
                component: AccountsListComponent,
            },
            {
                path: 'replace-mao',
                //canActivate: [MOPGuard],
                data: ɵ1,
                component: ReplacingMaoComponent,
            },
            {
                path: 'replace-mao/:id',
                //canActivate: [MOPGuard],
                data: ɵ2,
                component: ReplacingMaoComponent,
            },
            {
                path: 'request-registration-cancellation',
                // canActivate: [MOPGuard],
                data: ɵ3,
                component: ReqRegCancellationComponent,
            },
            {
                // MOP section
                path: 'approve-account/:id',
                canActivate: [MOPGuard],
                data: ɵ4,
                component: AccountApprovalComponent,
            },
            {
                // MOP section
                path: 'approve-account/:id/:userId',
                canActivate: [MOPGuard],
                data: ɵ5,
                component: AccountApprovalComponent,
            },
            {
                // MOP section
                path: 'summary-admin/:id',
                canActivate: [MOPGuard],
                canDeactivate: ['CanDeactivateAccountSummaryComponent'],
                component: AccountSummaryComponent,
                data: ɵ6,
            },
            {
                path: 'account-details/:id',
                canDeactivate: ['CanDeactivateAccountSummaryComponent'],
                component: AccountSummaryComponent,
                data: ɵ7,
            },
            {
                // Enhanced User section
                path: 'summary-user',
                component: EnhancedUserDashboardComponent,
                data: ɵ8,
            },
            {
                // Account Summary Project section
                path: 'summary-projects',
                component: AccountSummaryProjectsComponent,
            },
            {
                // MOP registration
                path: 'mop-register-account/:type',
                component: AccountRegistrationComponent,
                data: ɵ9,
            },
            {
                // Enhanced User section
                path: 'register-account/:type',
                component: AccountRegistrationComponent,
                data: ɵ10,
            },
            {
                // Enhanced User section
                path: 'account-summary-user/:id',
                component: AccountSummaryComponent,
                data: ɵ11,
            },
            {
                // Enhanced User section
                path: 'register-account/:type/:id/:offset',
                component: AccountRegistrationComponent,
                data: ɵ12,
            },
            {
                path: 'manage-account',
                component: ManageAccountComponent,
                data: ɵ13,
            },
            {
                path: 'add-users',
                component: AddAdditionalUsersComponent,
                canDeactivate: ['CanDeactivateAddAdditionalUsersComponent'],
                data: ɵ14,
            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
                data: ɵ15,
            },
            {
                path: 'history/:accountId',
                component: AccountHistoryComponent,
                data: ɵ16,
            },
            {
                path: 'history/view/:id',
                component: AccountHistoryViewComponent,
            }
        ],
    },
];
const ɵ17 = () => new UserDetailsCanDeactivateGuard(), ɵ18 = () => new UserDetailsCanDeactivateGuard();
export class AccountManagementRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
