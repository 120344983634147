import { ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Input } from '@angular/core';
import { EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AppInjector } from '@shared/services/app-injector.service';
import { fromEvent, Subject, Subscription, Observable } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';

export class Step implements OnDestroy {

  @Input() inputData: any;
  @Input() executeLastEvent: Observable<void>;
  @Input() onSaveAsDraft: Observable<void>;
  @Input() onSubmit: Observable<any>;
  @Input() onSaveAndProceed: Observable<any>;
  @Output() stepComplete = new EventEmitter<any>();
  eventsSubscription: Subscription;
  saveDraftSubscription: Subscription;
  public _destroy$ = new Subject<any>();
  private _svc: any;
  private containerEl: any;
  private readonly scrollContainerDir: ElementRef;
  @ViewChild('ngForm', {static: true}) el: ElementRef;

  formGroup = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[];

  constructor() {
    const injector = AppInjector.getInjector();
    this._svc = injector.get(SubmissionManagementService);
    this._svc.stepValidationRequest$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(
        () => this.onRequestValidation(),
      );
    this.scrollContainerDir = this._svc.scrollEl;

    setTimeout(() => {
      this.onSaveAsDraft && this.onSaveAsDraft
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.saveAsDraft());

      this.onSubmit && this.onSubmit
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.submit());

      this.onSaveAndProceed && this.onSaveAndProceed
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => this.saveAndProceed());
    });

    this._svc.applicationApprovalModel$
      .pipe(takeUntil(this._destroy$))
      .subscribe(data => this.model = {...data, ...this.model});
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  saveAsDraft() {
    console.log('saveAsDraft executed');
  }

  submit() {
    console.log('submit executed');
  }

  saveAndProceed() {
    console.log('submit saveAndProceed');
    this.stepCompleted();
  }

  markFormAsDirty(form: FormGroup) {
    (Object).values(form.controls).forEach((control: any) => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormAsDirty(control as FormGroup);
      }
    });
  }

  onRequestValidation() {
    this._svc.stepValidationResponse = true;
  }

  validateForm(form: FormGroup) {
    if (this._svc.disabled) {
      return true;
    }
    form.markAllAsTouched();
    this.markFormAsDirty(form);
    this._svc.stepValidationResponse = form.valid;
    if (!form.valid) {
      this.scrollToFirstInvalidControl();
    }
    return form.valid;
  }

  markInvalid() {
    this._svc.stepValidationResponse = false;
  }

  stepCompleted() {
    this.stepComplete.emit(true);
  }

  private scrollToFirstInvalidControl() {
    if (!this.el) {
      return;
    }
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('.ng-invalid:not(form)');
    if (this._svc.scrollEl) {
      this.containerEl = this._svc.scrollEl.nativeElement;
      this.containerEl.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });

      fromEvent(this.containerEl, 'scroll')
        .pipe(
          debounceTime(100),
          take(1),
        )
        .subscribe(() => firstInvalidControl.focus());
    }
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    const controlElTop = controlEl.getBoundingClientRect().top;

    if (this.scrollContainerDir) {
      const containerTop = this.containerEl.getBoundingClientRect().top;
      const absoluteControlElTop = controlElTop + this.containerEl.scrollTop;

      return absoluteControlElTop - containerTop - labelOffset;
    } else {
      const absoluteControlElTop = controlElTop + window.scrollY;

      return absoluteControlElTop - labelOffset;
    }
  }

  public scrollToBottom() {
    this.containerEl = this._svc.scrollEl.nativeElement;
    if (this.containerEl.children[0]) {
      this.containerEl.scroll({
        top: this.containerEl.children[0].offsetHeight,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getCurrentStep() {
    return this._svc.currentStep;
  }

}
