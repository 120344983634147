<p-dialog
  [className]="'eccc'"
  [header]="'COMMON.actionsLabel.setIssuances' | translate"
  [closable]="false"
  [(visible)]="openUploadPopup"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <div class="container">
    <div class="row">
        <div class="col-12 text-center mt-3 mb-3">
          {{'COMMON.labels.issuanceDate' | translate }}
          <br>
          {{scheduledTime}}
        </div>
      <div class="col-12 text-center mt-3 mb-3">
        <p-calendar
          [showTime]="true"
          [inline]="true"
          [minDate]="minDate"
          [(ngModel)]="value"
          [locale]="locales[lang]">
        </p-calendar>
             </div>
    </div>
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="ISSUANCE_DATES_submitBtn" type="button" class="btn btn-secondary" (click)="onSelectDate()">{{'COMMON.labels.submit' | translate }}</button>
        <button id="ISSUANCE_DATES_closeBtn" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.labels.close' | translate }}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
