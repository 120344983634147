<app-table-view
  *ngIf="data"
  [config]="tableConfig"
  [relative]="relative"
  [data]="data">
</app-table-view>

<ng-template #viewColTemplate let-data>
  <ng-container *ngIf="showView(data)">
    <div>
      <span tabindex="0" class="link" role="button"
            (click)="view($event, data)"
            (keyup)="view($event, data)">
        {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
      </span>
    </div>
  </ng-container>
</ng-template>

<ng-template #actionTemplate let-data>
  <app-generic-action-control
    [rawData]="data"
    [appendTo]="'app-project-list .table-container'">
  </app-generic-action-control>
</ng-template>
