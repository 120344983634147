import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { IApp } from '@core/models/app.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';
import { IMessages, IDisplayMessages } from '@core/models/serviceMessage.model';
import { SereviceMessageType, DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

const SAML_LOGIN_ERROR = 'SAML_LOGIN_ERROR';
@Component({
  selector: 'app-chooser-page',
  templateUrl: './chooser-page.component.html',
  styleUrls: ['./chooser-page.component.scss'],
})
export class ChooserPageComponent implements OnInit {

  private _SAMLProviders: IApp.IAuthSAMLProvider[] = null;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private store: StoreService,
    private router: Router,
    private helperService: HelperService,
    private modalService: ModalService,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
    this.authService.SAMLProviders
      .subscribe(
        data => this._SAMLProviders = data,
      );

        this.activatedRoute.params.subscribe((params) => {
        if (this.store.getAccessKey()) {
          const accessKey = this.store.getAccessKey().split('|');
          if (accessKey[0] === SAML_LOGIN_ERROR) {
            let extraErrorMessage = null;
            if (accessKey[1]) {
              extraErrorMessage = accessKey[1];
            }
            this.store.setAccessKey(null);
            this.showAuthErrorMessage('loginErrorMessage', extraErrorMessage);
          }
        }
        if (params.code && params.code !== 'undefined') {
          this.authService.validateToken(params.code).subscribe(userdto => {
              this.store.setAccessKey(params.code);
          },
          (error: HttpErrorResponse) => {
            this.store.setAccessKey(null);
            this.router.navigate(this.helperService.getTranslatedPath('/'));
          });
       }
      });

      this.activatedRoute.queryParamMap.subscribe((queryParams) => {
        const auth_error=  queryParams.get('logout_error');
        if(auth_error){
          console.log(auth_error);
          this.showAuthErrorMessage('logoutErrorMessage');
        }
       });
  }

  showAuthErrorMessage(errorMessage: string, extraErrorMessage: string = null) {
    this.router.navigate(this.helperService.getTranslatedPath('/register/chooser-page'));
    this.store.getProperty('email.group.programAdmin').subscribe(prop => {
        const messageList: IMessages[] = [{
          message: errorMessage,
          metaData: [prop.value],
        }];

        if (extraErrorMessage) {
          messageList.push({
            message: extraErrorMessage,
          });
        }

        const messages: IDisplayMessages = {
          messages: messageList,
          type: SereviceMessageType.ERROR
        };

        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
        .afterClosed()
        .subscribe((result?: boolean) => {
        });
      })
  }


  getSAMLProvider(name: string): IApp.IAuthSAMLProvider {
    return this.authService.getSAMLProvider(this._SAMLProviders , name);
  }

  openLoginPage(idpName: string){
  if(this.store.getAccessKey() === SAML_LOGIN_ERROR){
    this.store.setAccessKey(null);
  }
   const spProvider = this.authService.getSP(this._SAMLProviders);
   const idpProvider = this.authService.getSAMLProvider(this._SAMLProviders, idpName);
   const lang = this.authService.setupLangCookie(idpProvider.cookieDomain);
   const uri = `${spProvider.url}/oauth2/authorize?redirect_uri=${spProvider.callbackUrl}&identity_provider=${idpProvider.url}&response_type=token&client_id=${spProvider.clientId}&scope=email,openid&lang=${lang}`;
   window.open(uri ,'_self');

  }

  get lang(): string {
    return this.translate.currentLang;
  }

}
