import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Step } from '@shared/components/steps/models/step';
import { StoreService } from '@core/store/store.service';
import { RegistrationService } from '@module/registration/registration.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { VB_ORG_INFO } from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { CfsRegistration, CFS_REGISTRATION_STEP_TYPE } from '../../constants/registration-types.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';

@Component({
  selector: 'app-cfs-vb-acc-reg',
  templateUrl: './cfs-vb-acc-reg.component.html',
  styleUrls: ['./cfs-vb-acc-reg.component.scss']
})
export class CfsVbAccRegComponent extends Step implements OnInit {

  static key = 'CfsVbAccRegComponent';

  formGroup = new FormGroup({});
  model: any;
  fields: FormlyFieldConfig[];
  private isEmailConfirmedOnce = false;

  constructor(
    private formFactoryService: FormFactoryService,
    private formService: FormService,
    private storeService: StoreService,
    private registrationService: RegistrationService,
    private modalService: ModalService,
  ) { super(); }

  ngOnInit() {
    this.configureForm();
  }

  saveAndProceed() {
    this.markFormAsDirty(this.formGroup);

    if (this.formGroup.valid) {
      if (this.getCurrentStep()['id'] === 1 && !this.isEmailConfirmedOnce) {
        this._showEmailConfirmationModal();
      } else {
        this._proceedToNextStep();
      }
    }
  }

  private configureForm() {
    this.fields = this.formFactoryService.configureForm(VB_ORG_INFO);

    if(this.inputData) {
      this.model = this.inputData;
      setTimeout(() => {
        this.formGroup.disable();
        this.formGroup.get('authorizedOfficial').enable();
        this.formGroup.get('authorizedOfficial.email').disable();
      });
    }

    setTimeout(() => {
      this.formFactoryService.addPostalAddressValidator(this.formGroup, 'postalAddress.');
      this.formFactoryService.addPostalAddressValidator(this.formGroup, 'authorizedOfficial.postalAddress.');
    });
  }

  private _showEmailConfirmationModal() {
    this.modalService.open(ServiceMessageComponent, {
        messages: null,
        yesNoButton: true,
        message: 'confirmationMessageRegistrationEmail',
        metaDataList: [
          this.model.authorizedOfficial.email,
          this.model.legalName
        ],
        type: SereviceMessageType.WARNING,
      },
      true, DEFAULT_DIALOG_CONFIG).afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.isEmailConfirmedOnce = true;
          this._proceedToNextStep();
        }
      });
  }

  private _proceedToNextStep() {
    const payload = this.model ? this.model : this.formGroup.value;
    this.registrationService.submitVBRegistration(payload).subscribe(
      response => {
        this.storeService.setAccessKey(response.entity);
        const steps = [...CfsRegistration.steps].filter(step => step.type !== CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG);
        steps[2].inputData = 'VB';
        this.stepComplete.emit(steps);
      },
      (error: HttpErrorResponse) => {
        this.isEmailConfirmedOnce = false;
        this.formService.parseErrors(this.formGroup, error.error);
      },
    );
  }
}
