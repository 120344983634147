/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./task-details.component";
import * as i6 from "../../../core/store/store.service";
import * as i7 from "../../services/tasks.service";
import * as i8 from "../../../core/services/lookup.service";
import * as i9 from "../../../modules/obligation-management/services/obligation-management.service";
import * as i10 from "../../services/modal.service";
import * as i11 from "../../services/helper.service";
import * as i12 from "@angular/router";
var styles_TaskDetailsComponent = [i0.styles];
var RenderType_TaskDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskDetailsComponent, data: {} });
export { RenderType_TaskDetailsComponent as RenderType_TaskDetailsComponent };
function View_TaskDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-lg-6 text-right overflow-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [" :"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "col-lg-6"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("TASK_TO_DO.task." + _v.parent.context.$implicit))); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.task[_v.parent.context.$implicit]; _ck(_v, 6, 0, currVal_1); }); }
function View_TaskDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskDetailsComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task[_v.context.$implicit]; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TaskDetailsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-secondary btm-sm float-right ml-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerActionController(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_TaskDetailsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskDetailsComponent_4)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task.entityActionList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TaskDetailsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-secondary btm-sm float-right ml-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doAction(_v.context.$implicit.workflowAction) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_TaskDetailsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskDetailsComponent_6)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task.entityActionList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TaskDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-lg-8"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskDetailsComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row mt-2 float-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskDetailsComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "row mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaskDetailsComponent_5)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldList; _ck(_v, 3, 0, currVal_0); var currVal_1 = !_co.showAction; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showAction; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_TaskDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-details", [], null, null, null, View_TaskDetailsComponent_0, RenderType_TaskDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i5.TaskDetailsComponent, [i6.StoreService, i3.TranslateService, i7.TasksService, i8.LookupService, i9.ObligationManagementService, i10.ModalService, i11.HelperService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskDetailsComponentNgFactory = i1.ɵccf("app-task-details", i5.TaskDetailsComponent, View_TaskDetailsComponent_Host_0, { task: "task", showAction: "showAction" }, { successfullAction: "successfullAction" }, []);
export { TaskDetailsComponentNgFactory as TaskDetailsComponentNgFactory };
