/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./generic-view-control.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./generic-view-control.component";
import * as i5 from "@angular/router";
import * as i6 from "../../services/helper.service";
import * as i7 from "../../services/modal.service";
import * as i8 from "../../services/table-template-controls.service";
var styles_GenericViewControlComponent = [i0.styles];
var RenderType_GenericViewControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenericViewControlComponent, data: {} });
export { RenderType_GenericViewControlComponent as RenderType_GenericViewControlComponent };
export function View_GenericViewControlComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["id", "generic-action-control-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["style", "margin:auto"], ["tabindex", "0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerViewAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 2, 0, currVal_0); }); }
export function View_GenericViewControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-view-control", [], null, null, null, View_GenericViewControlComponent_0, RenderType_GenericViewControlComponent)), i1.ɵdid(1, 245760, null, 0, i4.GenericViewControlComponent, [i5.Router, i6.HelperService, i7.ModalService, i8.TableTemplateControls], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenericViewControlComponentNgFactory = i1.ɵccf("app-generic-view-control", i4.GenericViewControlComponent, View_GenericViewControlComponent_Host_0, { actionsList: "actionsList", rawData: "rawData" }, {}, []);
export { GenericViewControlComponentNgFactory as GenericViewControlComponentNgFactory };
