import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const TANDC_ACCEPT = '/account-service/tandc/accept';
export const TANDC_SAVE = '/account-service/tandc/save';
export const TANDC_UNACCEPTED_BY_USER_ID = '/account-service/tandc/unacceptedByUser/';
export const TANDC_ACCEPTED_BY_USER = '/account-service/tandc/acceptedByUser';
export const PRIVACY_POLICY = '/account-service/tandc/privacyPolicy';
export const REGISTRATION_PRIVACY_POLICY = '/account-service/lookup/privacyPolicy';
export class TermsConditionsService {
    constructor(http) {
        this.http = http;
    }
    acceptTermsAndConditions(userTAndC) {
        return this.http.post(`${environment.apiUrl}${TANDC_ACCEPT}`, userTAndC);
    }
    saveTandC(newTandC) {
        return this.http.post(`${environment.apiUrl}${TANDC_SAVE}`, newTandC);
    }
    getTandCUnacceptedByUserId(id) {
        return this.http.get(`${environment.apiUrl}${TANDC_UNACCEPTED_BY_USER_ID}${id}`);
    }
    getTandAcceptedByUser() {
        return this.http.get(`${environment.apiUrl}${TANDC_ACCEPTED_BY_USER}`);
    }
    getPrivacyPolicy() {
        return this.http.get(`${environment.apiUrl}${PRIVACY_POLICY}`);
    }
    getRegistrationPrivacyPolicy(programId) {
        let params = new HttpParams();
        params = params.append('programId', `${programId}`);
        return this.http.get(`${environment.apiUrl}${REGISTRATION_PRIVACY_POLICY}`, { params });
    }
}
TermsConditionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TermsConditionsService_Factory() { return new TermsConditionsService(i0.ɵɵinject(i1.HttpClient)); }, token: TermsConditionsService, providedIn: "root" });
