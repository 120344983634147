<div id="account-user-actions" *ngIf="actionsList?.length > 0">
    <ng-select
        wcag-label
        [placeholder]="'COMMON.actionsLabel.actions' | translate"
        [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.actions' | translate}"
        [items]="actionsList"
        bindLabel="label"
        bindValue="label"
        [(ngModel)]="dummyModel"
        (change)="triggerActionController($event)"
        [appendTo]="appendTo">
    </ng-select>
</div>

<!-- needs to fix append to body, so far for user is causing a slight shift i think the calculation is setting it off working on
this later no time to spend on this if someone can see this and pick this up-->
