import { ChangeDetectorRef, ElementRef, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { ComponentFactoryResolver } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { IRegistrationStep } from '@module/registration/registration.model';
import { RegistrationService } from '@module/registration/registration.service';
import { HelperService } from '@shared/services/helper.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CFS_REGISTRATION_STEP_TYPE } from '../constants/registration-types.const';
import { StepAnchorDirective } from '../directive/step-anchor.directive';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { validateStep } from '@shared/decorators/validate-step.decorator';
import { SubmissionManagementService, PERMISSION_PROPOSE_TRANSACTION  } from '@module/submission-management/services/submission-management.service';
import { StoreService } from '@core/store/store.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/services/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { NavigationService } from '@shared/services/navigation.service';

export const programIdKey = 'program_id';
@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();
  currentStepLastEvent: Subject<void> = new Subject<void>();
  saveAsDraftEvent: Subject<void> = new Subject<void>();
  saveAndProceedEvent: Subject<void> = new Subject<void>();
  submitEvent: Subject<void> = new Subject<void>();
  submitButtonLabel = 'submit';

  applicationId: number;
  applicationType: string;

  @HostBinding('class.wizard-layout')
  public useLayout = false;

  progressBar: IRegistrationStep[];
  currentStep: number;
  configurations:any;
  entryComponentsMap: any;
  applicationData: any;

  page_title:string;
  step_title:string;
  page_sub_title: string;

  showBeforeNextButton: boolean = false;
  showNextButton: boolean = true;
  showBackButton: boolean = false;
  showCloseButton: boolean = false;
  showSaveAsDraftButton: boolean = false;

  /* components to be injected */
  private appStepAnchor: StepAnchorDirective;
  @ViewChild(StepAnchorDirective, {static: false}) set content(content: StepAnchorDirective) {
    if (content) {
      this.appStepAnchor = content;
    }
    this.cdRef.detectChanges();
  }

   private formContainer: ElementRef;
   @ViewChild('formContainer', {static: false}) set content1(content1: ElementRef) {
     if (content1) {
       this.formContainer = content1;
     }
     this.cdRef.detectChanges();
   }

  constructor(
    private router: Router,
    private helperService: HelperService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    private registrationService: RegistrationService,
    private storeService: StoreService,
    private service: SubmissionManagementService, // todo
    private agreementService: AgreementCreditsService,
    private _location: Location,
    private cdRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private authService : AuthService,
    private navigationService: NavigationService,
  ) {
    this.activatedRoute.params.subscribe(params => {
      this.activatedRoute.data
        .pipe(takeUntil(this._destroy$))
        .subscribe(data => {
          if (data) {
            data.entryComponentsMap && (this.entryComponentsMap = data.entryComponentsMap)
            this.useLayout = data.useLayout;
            this.applicationType = data.type;
          }
        });

      if (params.token) {
        this.registrationService.getCFRAccountDetails(params.token)
          .pipe(takeUntil(this._destroy$))
          .subscribe(
            response => {
              sessionStorage.setItem(programIdKey, 'cfr');
               this.authService.changeLang(this.translateService.currentLang, true)
                 .subscribe(() => {
                   this.initStepper(1);
                   if (response.accountSubTypeIds
                     && response.accountSubTypeIds.length > 0
                     && response.accountSubTypeIds[0] === 7) {
                     //vb
                     this.removeStepByType(CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG);
                   } else {
                     //ps
                     this.removeStepByType(CFS_REGISTRATION_STEP_TYPE.CFS_VB_ACC_REG);
                   }
                   this.getCurrentStep().inputData = response;
                   this.loadStep();
                 });
            },
            (error: HttpErrorResponse) => {
              this.router.navigate(this.helperService.getTranslatedPath('/'));
            }
          );
      }

      if (params.id) {
        this.applicationId = params.id;
        this.service.getApplicationByType(this.applicationType, this.applicationId)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            this.service.applicationApprovalModel = data;
          });
      }

      if (params.agreementId) {
        this.applicationId = params.agreementId;
        this.agreementService.getAgreementById(this.applicationId)
          .pipe(takeUntil(this._destroy$))
          .subscribe(data => {
            this.service.applicationApprovalModel = data;
            this.ngOnInit();
          });
      }
    });
  }

  ngOnInit() {
    if( this.getStepConfigFromRouter() ) {
      if(history.state.data) {
        this.initStepper(0);
        this.loadStep();
      }
    }
    else {
      this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
    }

    if(this.storeService.user.hasPermission(PERMISSION_PROPOSE_TRANSACTION )){
      this.submitButtonLabel = 'propose';
    }else{
      this.submitButtonLabel = 'submit';
    }
    if (this.applicationData) {
      this.submitButtonLabel = this.applicationData.type === 'CI_PATHWAY' ?
        `${this.submitButtonLabel}ButtonPathway` : `${this.submitButtonLabel}ButtonCI`;
    }

    this.registrationService.registrationType.next('cfr');

  }

  removeStepByType(type){
   //vanilla
   let indexToRemove;
   this.configurations.steps.forEach( (step, index) => {
     if(step.type === type) {
      indexToRemove = index;
      return false;
     }
   });

   this.configurations.steps.splice(indexToRemove,1);
  }

  getStepConfigFromRouter (){
    return history.state.data || this.activatedRoute.snapshot.data.stepperConfig;
  }

  setInputData() {
     if (history.state.data && history.state.data.inputData) {
       if (!this.applicationData) {
         this.applicationData = history.state.data.inputData;
       }
       this.getCurrentStep().inputData = history.state.data.inputData;
     }
  }

  initStepper(fromStepIndex){
    this.configurations = this.getStepConfigFromRouter();
    this.setProgressBar();
    this.resetProgressBarOnLangChange();
    this.step_title = this.configurations.step_title;
    this.page_title = this.configurations.page_title;
    this.currentStep = this.configurations.steps[fromStepIndex].id;
    this.updatePageSubTitle();
    this.updateBackNextButtons();
    this.setInputData();
  }

  loadStep() {
     this.translationLoading
       .pipe(takeUntil(this._destroy$))
       .subscribe(() => {

         const factories = Array.from(this.componentFactoryResolver['_factories'].values());
         const factoryClass = <any>factories.find((component: any) => component.componentType.key === this.getCurrentStep().type);

         const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.entryComponentsMap[factoryClass.componentType.key]);

         setTimeout(() => {

           this.service.scrollEl = this.formContainer;

           const viewContainerRef = this.appStepAnchor.viewContainerRef;
           viewContainerRef.clear();

           const componentRef = viewContainerRef.createComponent<any>(componentFactory);

           if (this.getCurrentStep().before_next) {
             componentRef.instance.onSaveAndProceed = this.saveAndProceedEvent.asObservable();
           }

           if (this.getCurrentStep().saveAsDraft) {
             componentRef.instance.onSaveAsDraft = this.saveAsDraftEvent.asObservable();
           }
           componentRef.instance.onSubmit = this.submitEvent.asObservable();


           if (this.getCurrentStep().inputData) {
             componentRef.instance.inputData = /*Object.create(*/this.getCurrentStep().inputData;//);
           }

           componentRef.instance.stepComplete.subscribe(event => {
             if (this.getCurrentStep().updateStep) {
               this.updateStep(event);
             }
             this.nextStep();
           });
         });
       });
  }

  getComponent() {
    this.loadStep();
  }

  back() {
    if(this.currentStep <= 1 ){
      this.close();
    } else {
      this.progressBar[this.currentStep].completed = false;
      this.currentStep--;
      this.progressBar[this.currentStep].completed = false;
      this.loadStep();
      this.updatePageSubTitle();
      this.updateBackNextButtons();
    }
  }

  @validateStep()
  beforeNextStep() {
    this.saveAndProceedEvent.next();
  }

  nextStep() {
    this.progressBar[this.currentStepListId].completed = true;
    if (!this.isLastStep) {
      this.currentStep++;
      this.loadStep();
      this.updatePageSubTitle();
      this.updateBackNextButtons();
    } else {
      this._location.back();
    }
  }

  get isLastStep(): boolean {
     if (this.configurations) {
       return this.currentStepListId + 1 > this.configurations.steps.length - 1;
     } else {
       return false;
     }
  }

  get currentStepListId(): number {
     return this.configurations.steps.findIndex(step => step.id === this.currentStep);
  }

  updateStep(event) {
    this.configurations.steps = event;
  }

  goToStep($event){
    this.currentStep = $event;
    this.loadStep();
    this.updatePageSubTitle();
    this.updateBackNextButtons();
  }

  updatePageSubTitle() {
    const currentStep = this.getCurrentStep();
    this.step_title = currentStep.step_title;
    this.page_sub_title = currentStep.type === CFS_REGISTRATION_STEP_TYPE.CFS_SECURE_SIGN_IN ? null : currentStep.page_sub_title;
  }

  updateBackNextButtons(){
    this.showBeforeNextButton = this.getCurrentStep().before_next;
    this.showNextButton = this.getCurrentStep().next;
    this.showBackButton = this.getCurrentStep().back;
    this.showCloseButton = this.getCurrentStep().close;
    this.showSaveAsDraftButton = this.getCurrentStep().saveAsDraft;
  }

  getCurrentStep() {
    return this.configurations.steps.find(step => step.id === this.currentStep);
  }

  close(){
    this.navigationService.back();
  }

  @validateStep()
  saveAsDraft() {
    this.saveAsDraftEvent.next();
  }

  @validateStep()
  submit() {
    this.submitEvent.next();
  }

  private resetProgressBarOnLangChange(){
    this.translateService.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
      this.setProgressBar();
    });
  }

  private setProgressBar() {
    this.progressBar = this.configurations.progress_bar_config.map(config => {
      return {
        ...config,
        name: this.translateService.instant(`REGISTRATION_PAGE.stepperSection.${config.name}`)
      }
    });
  }

  get translationLoading(): Observable<boolean> {
    return this.authService.translationLoading$;
  }

  ngOnDestroy() {
     this.service.resetModel();
     this._destroy$.next();
     this._destroy$.complete();
  }

}
