import { NgModule } from "@angular/core";
import { Routes } from "@angular/router";
import { RouterModule } from "@angular/router";
import { AuthenticatedGuard } from "@core/guards/authenticated.guard";
import { MOPGuard } from "@core/guards/mop.guard";
import { LocalizeRouterModule } from "@gilsdav/ngx-translate-router";
import { StepsComponent } from "@shared/components/steps/steps/steps.component";
import { AgreementCreditsListComponent } from "./components/agreement-credits-list/agreement-credits-list.component";
import { MainPageComponent } from "./containers/main-page/main-page.component";
import { SelectPartyComponent } from '@module/agreement-credits/components/select-party/select-party.component';
import { ReviewActivitiesComponent } from '@module/agreement-credits/components/review-activities/review-activities.component';
import { OtherPartyOrgDetailsComponent } from '@module/agreement-credits/components/other-party-org-details/other-party-org-details.component';
import { AuthContactPersonInfoComponent } from '@module/agreement-credits/components/auth-contact-person-info/auth-contact-person-info.component';
import { GenAgreementComponent } from '@module/agreement-credits/components/gen-agreement/gen-agreement.component';
import { BaseStepperComponent } from "@shared/components/base-stepper/base-stepper.component";
import { AgreementCreditsService } from "./agreement-credits.service";
import { AgreementStepperComponent } from "./containers/agreement-stepper/agreement-stepper.component";
import { SignAgreementComponent } from '@module/agreement-credits/components/sign-agreement/sign-agreement.component';
import { UploadAgreementComponent } from '@module/agreement-credits/components/upload-agreement/upload-agreement.component';
import { RcaOrgDetailsComponent } from '@module/agreement-credits/components/rca-org-details/rca-org-details.component';
import { RcaAuthContactPersonInfoComponent } from '@module/agreement-credits/components/rca-auth-contact-person-info/rca-auth-contact-person-info.component';
import { AgreementGuard } from '@module/agreement-credits/agreement.guard';

const CreateAgreementMap = {
    SelectPartyComponent,
    ReviewActivitiesComponent,
    OtherPartyOrgDetailsComponent,
    AuthContactPersonInfoComponent,
    GenAgreementComponent,
    SignAgreementComponent,
    UploadAgreementComponent,
    RcaOrgDetailsComponent,
    RcaAuthContactPersonInfoComponent,
}

const routes: Routes = [
    {
        path: '',
        component: MainPageComponent,
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: AgreementCreditsListComponent,
                data: {title: 'SUBMISSION_MANAGEMENT_MODULE.pageTitle', permission : 'MENU_SUBMISSION_MANAGEMENT', navbar: true},
                canActivate: [MOPGuard],
            },
            {
                path: 'list/:id',
                component: AgreementCreditsListComponent,
                data: {title: 'SUBMISSION_MANAGEMENT_MODULE.pageTitle', permission : 'MENU_SUBMISSION_MANAGEMENT', navbar: true},
                canActivate: [MOPGuard],
            },
            {
                path: 'generate/:id/:existInMarkit',
                component: GenAgreementComponent,
                data: {title: 'SUBMISSION_MANAGEMENT_MODULE.pageTitle', permission : 'MENU_SUBMISSION_MANAGEMENT', navbar: true},
                canActivate: [MOPGuard],
            },
        ]
    },
    {
        path: 'wizard',
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: 'create-agreement',
                component: AgreementStepperComponent,
                data: { service: AgreementCreditsService, entryComponentsMap: CreateAgreementMap},
            },
            {
                path: 'agreement/:id',
                component: AgreementStepperComponent,
                data: { service: AgreementCreditsService, entryComponentsMap: CreateAgreementMap},
            },
            {
                path: 'agreement/:type/:id',
                component: AgreementStepperComponent,
                data: { service: AgreementCreditsService, entryComponentsMap: CreateAgreementMap},
            },
            {
                path: 'agreement',
                component: AgreementStepperComponent,
                canActivate: [AgreementGuard],
            },
        ],
    },
];

@NgModule({
    imports: [
      RouterModule.forChild(routes),
      LocalizeRouterModule.forChild(routes),
    ],
    exports: [RouterModule],
})

export class AgreementCreditRoutingModule{

}
