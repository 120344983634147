import * as tslib_1 from "tslib";
import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { ADMIN_WORKFLOW_APPROVAL_TYPES } from '@module/admin/services/admin-management.service';
import { OTHER_AREA_OF_EXPERTISE_ID } from '@shared/shared.const';
import { BehaviorSubject, of } from 'rxjs';
import { filter, finalize, map, shareReplay, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../store/store.service";
import * as i3 from "@ngx-translate/core";
export const LOOKUP_ACCOUNT_TYPES = '/account-service/lookup/accountTypes';
export const LOOKUP_ACCOUNT_SUB_TYPES = '/account-service/lookup/accountSubTypes';
export const LOOKUP_FACILITY_STATUS = '/account-service/lookup/facilityStatus';
export const LOOKUP_FACILITY_NAMES = '/account-service/lookup/facilityNames';
export const LOOKUP_ACTIVE_ACCOUNTS = '/account-service/lookup/activeAccountNames';
export const LOOKUP_CROSS_FACILITY_NAMES = '/account-service/lookup/crossReportsFacilityNames';
export const LOOKUP_PENDING_FACILITY_NAMES = '/account-service/private/user/userPendingAccount';
export const LOOKUP_VALIDATE_PERFORM_ACTION = '/account-service/account/validatePerformAction/';
export const LOOKUP_FACILITY_CERTIFICATE_NOS = '/account-service/lookup/facilityCertificateNos';
export const LOOKUP_RESPONSIBLE_PERSONS = '/account-service/lookup/responsiblePersons';
export const LOOKUP_OBLICATION_STATUS = '/account-service/lookup/obligationStatus';
export const LOOKUP_DOCUMENT_TYPE = '/account-service/lookup/getDocumentTypes';
export const LOOKUP_SUSPENSION_REASONS = '/account-service/lookup/credit/suspensionReasons';
export const LOOKUP_CREDIT_REPORT_TYPES = '/account-service/lookup/credit/reportTypes';
export const LOOKUP_CREDIT_SUBSECTIONS = '/account-service/lookup/credit/subsections';
export const LOOKUP_COMPLIANCE_PERIODS = '/account-service/lookup/compliancePeriods';
export const LOOKUP_CROSS_COMPLIANCE_PERIODS = '/account-service/lookup/crossReportsCompliancePeriods';
export const LOOKUP_RESULT_COMPLIANCE_PERIODS = '/account-service/lookup/resultCompliancePeriods';
export const LOOKUP_CREDIT_STATUS = '/account-service/lookup/credit/status';
export const LOOKUP_RU_CREDIT_STATUS = '/account-service/lookup/recognizedUnit/status';
export const LOOKUP_JURISDICTIONS_URI = '/account-service/lookup/jurisdictions';
export const LOOKUP_ALL_PROVINCES_URI = '/registry-server/lookup/allProvinces';
export const LOOKUP_FUELS = '/account-service/lookup/getFuels';
export const LOOKUP_FUELS_BY_TYPE = '/account-service/lookup/getFuelsByType';
export const LOOKUP_FUELS_FOR_ACTIVITY = '/registry-server/lookup/getFuelsForActivity';
export const LOOKUP_ALL_FUELS = '/account-service/lookup/getAllFuels';
export const GET_ALL_FUELS = '/cfr-service/lookup/getApplicationFuelList';
export const LOOKUP_ENERGY_DENSITY_FUELS = '/account-service/energyDensity/getAllFuels';
export const LOOKUP_FUEL_TYPES = '/account-service/lookup/fuelTypes';
export const LOOKUP_ACCEPTED_JURISDICTIONS_URI = '/account-service/lookup/allJurisdictions';
export const LOOKUP_FACILITY_VERSIONS = '/account-service/lookup/facilityVersions';
export const LOOKUP_OBPS_REPORT_TYPES = '/account-service/lookup/getObpsReportTypes';
export const LOOKUP_BUSINESS_STRUCTURE = '/account-service/lookup/businessStructure';
export const LOOKUP_RESULT_VERSION_STATUS = '/account-service/lookup/resultVersionStatus';
export const LOOKUP_COUNTRIES = '/registry-server/lookup/getCountries';
export const LOOKUP_ACTIVITY_COUNTRIES = '/account-service/lookup/getActivityCountryList';
export const EXPORT = '/account-service/export';
export const TABLE_DETAILS = '/registry-server/export/findTableDetails/';
export const LOOKUP_DESTINATION_ACCOUNTS = '/account-service/lookup/findLastTransferedAccounts/';
export const LOOKUP_PAYMENT_STATUS = '/account-service/lookup/paymentStatus';
export const LOOKUP_TRANSACTION_TYPES = '/account-service/lookup/transactionType';
export const LOOKUP_MASTER_ACCOUNTS = '/account-service/lookup/masterFacilityNames';
export const LOOKUP_STANDARDS = '/account-service/lookup/standards';
export const LOOKUP_PAYMENT_REFUND_STATUS = '/account-service/lookup/paymentRefundStatus';
export const LOOKUP_PAYMENT_REFUND_MODE = '/account-service/lookup/paymentRefundMode';
export const LOOKUP_OBLIGATION_IDS = '/account-service/lookup/getObligationIds/';
export const LOOKUP_REFUND_OPTIONS = '/account-service/lookup/refundPaymentTypes';
export const LOOKUP_UNIT_CLASS_LISTING = '/account-service/lookup/getUnitClassForListing';
export const LOOKUP_FILEUPLOAD_STATUS = '/account-service/lookup/fileUploadStatus';
export const LOOKUP_DELIVERY_TYPES = '/account-service/lookup/deliveryMediums';
export const LOOKUP_REMITTANCE_TYPES = '/account-service/lookup/remittanceTypes';
export const LOOKUP_TASK_TYPE = '/registry-server/lookup/getTaskTypes';
export const LOOKUP_ASSIGNED_TO = '/registry-server/lookup/getAssignedTo';
export const LOOKUP_AREA_OF_EXPERTISE = '/account-service/lookup/getAreaOfExpertise/';
export const LOOKUP_AREA_OF_EXPERTISE_BY_ID = '/account-service/lookup/getAreaOfExpertiseById/';
export const LOOKUP_PROFESSIONAL_QUALIFICATIONS = '/account-service/lookup/getProfessionalQualifications';
export const LOOKUP_VB_ORG_TYPES = '/account-service/lookup/getAccreditationBodyTypes';
export const LOOKUP_ACCREDITATION_BODY = '/account-service/lookup/getAccreditationBody';
export const LOOKUP_ADDRESS_LIST = '/account-service/account/getAddressList/';
export const LOOKUP_REGISTRATION_FACILITIES = '/account-service/lookup/getFacilityList';
export const LOOKUP_REGISTRATION_FACILITIES_WITHOUT_AGREEMENT = '/account-service/lookup/getNonAgreementFacilityList';
export const LOOKUP_SUBORGANIZATION_LIST = '/account-service/cfrAccount/getSubOrganizationsLookup/';
export const LOOKUP_ORG_ENTITY_TYPE = '/account-service/lookup/organizationEntityTypes';
export const LOOKUP_SUBMISSION_TYPE = '/account-service/submission/types';
export const LOOKUP_SUBMISSIONS_TYPE = '/offset-service/lookup/submissionsType';
export const LOOKUP_REPORTING_PERIODS = '/offset-service/submissions/reportingPeriod';
export const LOOKUP_TYPE_CODES = '/offset-service/submissions/submissionsTypeCodes';
export const LOOKUP_REPORTING_PERIOD_VERSION = '/offset-service/lookup/searchProjectReportingPeriodVersions/';
export const LOOKUP_VB_SUBMISSION_TYPE = '/cfr-service/lookup/vbSubmissionTypes';
export const LOOKUP_VB_TYPE = '/cfr-service/lookup/vbTypes';
export const LOOKUP_VB_STATUS = '/cfr-service/lookup/verificationStatuses';
export const LOOKUP_COI_NOTICE_STATUS = '/cfr-service/lookup/getCoiNoticeStatusList';
export const LOOKUP_ACCOUNT_LEVELS = '/account-service/lookup/accountLevel';
export const LOOKUP_AGREEMENT_STATUS = '/account-service/agreement/lookupCurrentStatus';
export const LOOKUP_COI_STATUS = '/cfr-service/lookup/coiCheckListStatuses';
export const LOOKUP_AGREEMENT_ACTIVITY = '/account-service/agreement/lookupActivity';
export const LOOKUP_OPA_NAME = '/account-service/agreement/lookupOpaName';
export const LOOKUP_RCA_NAME = '/account-service/agreement/lookupRcaName';
export const LOOKUP_AGREEMENT_ISSUE_YEAR = '/account-service/agreement/lookupIssueYear';
export const LOOKUP_SUBMISSION_SUB_TYPE = '/account-service/submission/subTypes';
export const LOOKUP_APPLICATION_SUBMISSION_TYPE = '/account-service/submission/applicationTypes';
export const LOOKUP_ASSIGNED_VB = '/account-service/submission/assignedVbs';
export const LOOKUP_CFR_COMPLAINCE_PERIOD = '/account-service/submission/compliancePeriods';
export const LOOKUP_SUBMISSION_STATUS = '/account-service/submission/statusList';
export const LOOKUP_ALLOWED_APPLICATION_TYPES = '/account-service/submission/allowedApplicationTypes';
export const LOOKUP_CI_METHOD = '/account-service/lookup/ciMethod';
export const CI_APPLICATION_TYPE = '/account-service/lookup/staticData/CI_APPLICATION_TYPE';
export const LOOKUP_STATIC_DATA = '/account-service/lookup/staticData/';
export const LOOKUP_FUEL_SUBJECT = '/account-service/lookup/staticData/FUEL_SUBJECT';
export const LOOKUP_PATHWAY = '/account-service/lookup/pathwayType';
export const LOOKUP_APPLICATION_MONTHS_DATA = '/account-service/lookup/applicationMonthsData';
export const LOOKUP_APPLICATION_CI_STATUS = '/account-service/lookup/applicationCiStatus';
export const LOOKUP_ELECTRIC_ENERGY_SOURCE = '/account-service/lookup/electricEnergySource';
export const LOOKUP_FEED_STOCK = '/account-service/lookup/feedStock';
export const LOOKUP_MODE_ACTIVITY_SUBJECT_TO_APPLICATION = '/account-service/lookup/activitySubjectToApplication';
export const LOOKUP_MODE_THERMAL_ELECTRICAL_COMPRESSION = '/account-service/lookup/thermalElectricalCompression';
export const LOOKUP_MODE_STAGE_OF_PRODUCTION = '/account-service/lookup/stageOfProduction';
export const LOOKUP_QUANTIFICATION_METHOD = '/account-service/lookup/quantificationMethod';
export const LOOKUP_REGULATORY_REGIME = '/account-service/lookup/regulatoryRegime';
export const LOOKUP_APPLICATION_SUB_TYPE = '/account-service/submission/applicationSubTypes';
export const LOOKUP_LIFECYCLE_FEEDSTOCK = '/account-service/lookup/staticData/LIFECYCLE_FEEDSTOCK';
export const LOOKUP_MODE_STAGE_OF_PRODUCTION_TRANSPORTATION = '/account-service/lookup/stageProductionTransportation';
export const LOOKUP_STAGE_COMPRESSION_LIQUE_FACTION = '/account-service/lookup/stageCompressionOrLiquefaction';
export const LOOKUP_STAGE_ELECTRICITY = '/account-service/lookup/stageElectricity';
export const LOOKUP_STAGE_ADDITIONAL_TRANSPORTATION = '/account-service/lookup/stageAdditionalTransportation';
export const LOOKUP_STAGE_COMBUSTION = '/account-service/lookup/stageCombustion';
export const LOOKUP_TYPE_OF_ENERGY = '/account-service/lookup/staticData/TYPE_OF_ENERGY_SOURCE';
export const LOOKUP_FACILITIES_BY_PROVINCES = '/cfr-service/application/lookupFacilitiesProvinces';
export const LOOKUP_TASK_FAMILY = '/registry-server/lookup/getTaskFamily';
export const LOOKUP_FAMILY_TASK_TYPES = '/account-service/lookup/taskTypes';
export const LOOKUP_ALL_USER_ROLES = '/account-service/private/user/roles';
export const LOOKUP_ALL_USER_STATUSES = '/account-service/lookup/allUserStatus';
export const LOOKUP_ALL_USER_ROLES_LIST = '/account-service/private/user/roleList';
export const LOOKUP_ALL_ACTION_PERFORMED_BY = '/registry-server/activityLog/actionPerformedByList';
export const LOOKUP_ACTIVITY_LOG_ORGNAMES = '/registry-server/activityLog/organizationNameList';
export const LOOKUP_ACTIVITY_LOG_ORGROLES = '/registry-server/activityLog/organizationRoleList';
export const ELECTRICITY_FROM_PROVINCIAL_GRID = '/account-service/lookup/staticData/ELECTRICITY_FROM_PROVINCIAL_GRID';
export const LANGUAGE_OF_CORRESPONDANCE = '/registry-server/lookup/languages';
export const ACCOUNT_CLOSURE_REASON = '/account-service/lookup/closureReason';
export const LOOKUP_ACCOUNT_HISTORY_ACTION_BY_USER = '/account-service/lookup/accountHistory/users/';
export const LOOKUP_ACCOUNT_HISTORY_ACTION_BY_TYPE = '/account-service/lookup/accountHistory/activityTypes/';
export const LOOKUP_OFFSET_GREEN_HOUSE_GAS = '/offset-service/lookup/greenhouseGas';
export const LOOKUP_OFFSET_PROJECT_ACTIVITY_TYPE = '/offset-service/lookup/projectActivityType';
export const LOOKUP_OFFSET_QUANTIFICATION_METHOD_TYPE = '/offset-service/lookup/quantificationMethodType';
export const LOOKUP_OFFSET_SEQUESTRATION_PROJECT_TYPE = '/offset-service/lookup/sequestrationProjectType';
export const LOOKUP_OFFSET_PROJECT_STATUS = '/offset-service/lookup/projectStatus';
export const LOOKUP_OFFSET_SUBMISSIONS_STATUS = '/offset-service/lookup/submisionsStatus';
export const LOOKUP_OFFSET_PROJECT_IDNAMES = '/offset-service/lookup/searchOffsetProjectIdNames';
export const LOOKUP_SEARCH_REPORT_OFFSET_REGISTERED_PROJECT_NAMES = '/offset-service/lookup/searchReportOffsetProjectNames/REPORT_REGISTERED_PROJECTS';
export const LOOKUP_SEARCH_REPORT_OFFSET_REJECTED_PROJECT_NAMES = '/offset-service/lookup/searchReportOffsetProjectNames/REPORT_REJECTED_PROJECT';
export const LOOKUP_OFFSET_GFG_PROTOCOLS = '/account-service/lookup/ghgOffset';
export const LOOKUP_PROVINCE_BY_COUNTRY = '/account-service/jurisdiction/getAll/';
export const LOOKUP_PROVINCE_BY_CANADA = '/account-service/jurisdiction/getAll/CA';
export const LOOKUP_OFFSET_PROJECT_NAMES = '/offset-service/lookup/searchOffsetProjectNames';
export const LOOKUP_OFFSET_DEADLINE_TYPE = '/offset-service/lookup/deadLineType';
export const LOOKUP_SEARCH_PROJECT_NAMES_BY_FILTER = '/offset-service/lookup/searchProjectNamesByFilter';
export const LOOKUP_PROJECT_TYPE = '/offset-service/lookup/project';
export const LOOKUP_SUB_ACCOUNT_CREATION_REASONS = '/account-service/lookup/subAccountCreationReason';
export const LOOKUP_PROJECT_HISTORY_ACTION_BY_USER = '/offset-service/lookup/projectHistory/users/';
export const LOOKUP_PROJECT_HISTORY_ACTION_BY_TYPE = '/offset-service/lookup/projectHistory/activityTypes/';
export const LOOKUP_PROJECT_CANCELLATION_REASON = '/offset-service/lookup/staticData/PROJECT_CANCELLATION_REASON';
export const SUBMISSIONS_NOTIFICATION_TYPE = '/offset-service/lookup/staticData/SUBMISSIONS_NOTIFICATION_TYPE';
export const SUBMISSIONS_REPORT_TYPE = '/offset-service/lookup/staticData/SUBMISSIONS_REPORT_TYPE';
export const OBLIGATION_REASON_TYPE = '/offset-service/lookup/obligationReasons';
export const LOOKUP_VINTAGE_YEARS = '/offset-service/lookup/searchVintageYears';
export const LOOKUP_OFFSET_PROTOCOL_STATUS = '/offset-service/lookup/offsetProtocolStatus';
export const LOOKUP_OFFSET_STATIC_DATA = '/offset-service/lookup/staticData/';
export const LOOKUP_SUBMISSIONS_HISTORY_ACTION_BY_USER = '/offset-service/lookup/submissionsHistory/users/';
export const LOOKUP_SUBMISSIONS_HISTORY_ACTION_BY_TYPE = '/offset-service/lookup/submissionsHistory/activityTypes/';
export const LOOKUP_PUBLIC_OFFSET_PROJECT_STATUS = '/offset-service/public/project/publicProjectStatus';
export const LOOKUP_PUBLIC_OFFSET_PROJECT_PROPONENTS = '/offset-service/public/project/publicProjectProponents';
export const LOOKUP_PUBLIC_OFFSET_PROJECT_NAMES = '/offset-service/public/project/publicProjectNames';
export const LOOKUP_PUBLIC_OFFSET_PROJECT_TYPE = '/offset-service/public/project/projectActivityType';
export const LOOKUP_PUBLIC_OFFSET_PROJECT_ID = '/offset-service/public/project/publicProjectIds';
export const LOOKUP_PUBLIC_OFFSET_UNIT_STATUSES = '/obps-service/public/unit/publicUnitStatusLookup';
export const LOOKUP_PUBLIC_OFFSET_UNIT_PROPONENTS = '/obps-service/public/unit/publicUnitProponentsLookup';
export const LOOKUP_PUBLIC_OFFSET_UNIT_ISSUANCE_YEAR = '/obps-service/public/unit/publicUnitIssuanceYearLookup';
export const LOOKUP_PUBLIC_OFFSET_UNIT_PROTOCOL = '/obps-service/public/unit/publicUnitProtocolLookup';
export const LOOKUP_PUBLIC_OFFSET_UNIT_VINTAGE = '/obps-service/public/unit/publicUnitVintageLookup';
export const LOOKUP_PUBLIC_OFFSET_ACCOUNT_TYPE = '/obps-service/public/unit/publicUnitAccountTypeLookup';
export const LOOKUP_PUBLIC_OFFSET_VERIFICATION_BODY = '/obps-service/public/unit/publicUnitVBLookup';
export const LOOKUP_UNIT_VINTAGE_YEAR = '/account-service/lookup/unitVintageYears';
export const LOOKUP_ERP_PROJECT_TYPE = '/registry-server/lookup/erpProjectType';
export const LOOKUP_ERP_QUANTIFICATION_METHODS = '/registry-server/lookup/getQuantificationMethods';
export const LOOKUP_ERP_APPROVAL_TEST_METHOD = '/registry-server/lookup/appStaticData/APPROVAL_TEST_METHOD';
export const LOOKUP_ERP_CARBON_INTENSITY_IDS = '/cfr-service/application/lookupCarbonIntensityIDs';
export const LOOKUP_ERP_FACILITY_LIST = '/cfr-service/lookup/erpFacilityNameList';
export const LOOKUP_ERP_PROJECT_NAME_LIST = '/cfr-service/lookup/erpProjectNameList';
export const LOOKUP_ERP_PROJECT_STATUS_LIST = '/cfr-service/lookup/erpProjectStatusList';
export const LOOKUP_ERP_FUEL_LIST = '/registry-server/lookup/erpFuels';
export const LOOKUP_ENERGY_DENSITY_UNITS = '/account-service/lookup/staticData/ENERGY_DENSITY_UNITS';
export const LOOKUP_QM_STATUS = '/registry-server/lookup/quantificationMethodStatus';
export const LOOKUP_QM_FUEL_TYPES = '/registry-server/lookup/erpFuels';
export const LOOKUP_CREDIT_CREATION_REPORT_TYPES = '/cfr-service/lookup/creditCreateReportTypes';
export const LOOKUP_CREDIT_CREATION_REPORT_ACTIVITY_TYPE = '/cfr-service/lookup/activityTypesForCreditCreateReport';
export const LOOKUP_CREDIT_CREATION_REPORT_COMPLIANCE_YEARS = '/cfr-service/lookup/complianceYearsForCreditCreateReport';
export const LOOKUP_CREDIT_CREATION_REPORT_QUARTERS = '/cfr-service/lookup/getAllQuarters';
export const LOOKUP_AGREEMENT_TRANSFER_CREDIT_FUELS = '/account-service/agreementTuc/lookupFuels';
export const LOOKUP_CREDIT_GROUP_LIST = '/cfr-service/lookup/getCreditGroupList';
export const LOOKUP_CREDIT_CLASS_LIST = '/cfr-service/lookup/getCreditClass';
export const LOOKUP_CREDIT_COMPLIANCE_YEAR_LIST = '/cfr-service/lookup/getUnitComplianceYearList';
export const LOOKUP_COMPLIANCE_REPORT_COMPLIANCE_YEAR_LIST = '/cfr-service/lookup/getComplianceYearListForCR';
export const LOOKUP_REPORT_INSUFFICIENT_CREDITS_TO_CANCEL_YEAR_LIST = '/cfr-service/lookup/getInsufficientCreditComplianceYearList';
export const LOOKUP_CREDIT_STATUS_LIST = '/cfr-service/lookup/getUnitStatusList';
export const LOOKUP_UNIT_FUEL_LIST = '/cfr-service/lookup/getUnitFuelList';
export const LOOKUP_CREDIT_GROUP_COMPLETE_LIST = '/cfr-service/lookup/getCreditGroupCompleteList';
export const LOOKUP_CREDIT_CLASS_COMPLETE_LIST = '/cfr-service/lookup/getCreditClassCompleteList';
export const LOOKUP_CFR_ACCOUNTS = '/account-service/lookup/cfrAccountNames';
export const LOOKUP_ALL_FULE_TYPE = '/account-service/lookup/getAllFuelType';
export const LOOKUP_TUC_FUEL_TYPE = '/account-service/agreementTuc/lookupFuelType';
export const LOOKUP_TUC_COMPLIANCE_PERIOD = '/account-service/agreementTuc/lookupCompliancePeriod';
export const LOOKUP_TUC_TRANSFEROR_ORGANIZATION_NAME = '/account-service/agreementTuc/lookupTransferorOrganizationName';
export const LOOKUP_TUC_TRANSFEREE_ORGANIZATION_NAME = '/account-service/agreementTuc/lookupTransfereeOrganizationName';
export const LOOKUP_TUC_CURRENT_STATUS = '/account-service/agreementTuc/lookupCurrentStatus';
export const LOOKUP_CSR_COMPLIANCE_YEAR = '/registry-server/reportLookup/getCreditStatusReportComplianceYear';
export const LOOKUP_CSR_JURISDICTION = '/registry-server/reportLookup/getCreditStatusReportJurisdiction';
export const LOOKUP_CSR_CREDIT_CLASS = '/registry-server/reportLookup/getCreditStatusReportCreditClass';
export const LOOKUP_CSR_CREDIT_CATEGORY = '/registry-server/reportLookup/getCreditStatusReportCreditCategory';
export const LOOKUP_CSR_ACTIVITY_TYPE = '/registry-server/reportLookup/getCreditStatusReportActivityType';
export const LOOKUP_CSR_FUEL_TYPE = '/registry-server/reportLookup/getCreditStatusReportFuelType';
export const LOOKUP_CSR_QUANTIFICATION_METHOD = '/registry-server/reportLookup/getCreditStatusReportQuantificationMethod';
export const LOOKUP_CSR_STATUS = '/registry-server/reportLookup/getCreditStatusReportStatus';
export const LOOKUP_CHR_COMPLIANCE_PERIOD = '/registry-server/reportLookup/getCreditHistoryCfrReportComplianceYear';
export const LOOKUP_CHR_TRANSACTION_TYPE = '/registry-server/reportLookup/getCreditHistoryCfrReportTransactionType';
export const LOOKUP_CHR_CREDIT_CLASS = '/registry-server/reportLookup/getCreditHistoryCfrReportCreditClass';
export const LOOKUP_CHR_CREDIT_CATEGORY = '/registry-server/reportLookup/getCreditHistoryCfrReportCreditCategory';
export const LOOKUP_SECTION_FOR_CREDIT_CREATION = '/cfr-service/lookup/sectionsForCreditCreation';
export const LOOKUP_STATUS_FOR_CREDIT_CREATION = '/cfr-service/lookup/getCreditReportStatusList';
export const LOOKUP_ENERGY_EFFICIENCY_FOR_ANNUAL_STEP = '/cfr-service/creditCreationReport/getEnergyEffieciency';
export const LOOKUP_CREDIT_COMPLIANCE_YEAR_CCR = '/cfr-service/lookup/getComplianceYearList';
export const LOOKUP_CREDIT_COMPLIANCE_YEAR_CCM = '/registry-server/lookup/getCcmCompliancePeriod';
export const LOOKUP_CREDIT_COMPLIANCE_YEAR_CRR = '/cfr-service/lookup/complianceYearsForCreditRevenueReport';
export const LOOKUP_REPORT_TYPE_CCR = '/cfr-service/lookup/getCreditReportTypeList';
export const CF = 'http://localhost:8089/api';
export const FUEL_ENERGY_SOURCE = 'FUEL_ENERGY_SOURCE';
export const ENERGY_DENSITY = 'ENERGY_DENSITY';
export const LOOKUP_NOE_NATURE_OF_ERRORS = '/registry-server/lookup/getNatureOfErrors';
export const LOOKUP_NOE_TYPE_OF_ACCOUNTS_FOR_NOTICE = '/registry-server/lookup/getTypeOfAccountsForNotice';
export const LOOKUP_VB_AREA_OF_EXPERTISE = '/account-service/lookup/getAreaOfExpertise/VB_USER';
export const LOOKUP_NOE_INSUFFICIENT_CREDIT_STATUS_LIST = '/cfr-service/lookup/getInsufficientCreditStatusList';
export const LOOKUP_COMPLIANCE_REPORT_COMPLIANCE_YEARS = '/cfr-service/lookup/complianceYearsForComplianceReport';
export const LOOKUP_COMP_COMPLIANCE_REPORT_COMPLIANCE_YEARS = '/cfr-service/lookup/complianceYearsForComplianceReport/COMPLIMENTRY_COMPLIANCE_REPORT';
export const LOOKUP_COMPLIANCE_REPORT_STATUS_LIST = '/cfr-service/lookup/getComplianceReportStatusList';
export const LOOKUP_COMPLIANCE_REPORT_FUEL_TRANSPORT_METHODS = '/registry-server/lookup/getFuelTransportMethods';
export const LOOKUP_COMPLIANCE_REPORT_EMISSION_REDUCTION_FUNDING_PROGRAMS = '/registry-server/lookup/getEmissionReductionFundingPrograms';
export const ERFP_PRICE_MANAGEMENT = 'ERFP_PRICE_MANAGEMENT';
export const ERFP_COMPLIANCE_PERIOD = '/cfr-service/lookup/complianceYearsForERFP';
export const LOOKUP_ERFP_NAMES = '/registry-server/lookup/getEmissionReductionFundingPrograms';
export const LOOKUP_VB_FACILITY_NAMES = '/account-service/lookup/vb/facilityNames';
export const LOOKUP_VERIFICATION_SUBMISSION_ID = '/cfr-service/lookup/applicationIdList';
export const LOOKUP_VERIFICATION_OPINION_TYPES = '/cfr-service/lookup/vbReportOpinionTypes';
export const LOOKUP_VERIFICATION_REPORT_STATUS = '/cfr-service/lookup/verificationReportStatusList';
export const LOOKUP_COI_CHECKLIST_SUBMISSION_TYPES = '/cfr-service/lookup/checklist/submissionTypes';
export const LOOKUP_COI_CHECKLIST_ACCOUNT_NAMES = '/cfr-service/lookup/checklist/accountNames';
export const LOOKUP_GET_CCM_STATUS = '/registry-server/lookup/getCreditClearanceStatus';
export const LOOKUP_GET_CB_STATUS = '/cfr-service/creditBalanceReport/getCreditBalanceReportStatusList';
export const REPORT_TYPE = 'CREDIT_ADJUSTMENT';
export const LOOKUP_CREDIT_BALANCE_REPORT_YEARS = '/cfr-service/creditBalanceReport/complianceYearsForCreditBalanceReport';
export const LOOKUP_CREDIT_BALANCE_COMPLIANCE_YEARS = '/cfr-service/lookup/getComplianceYearListForCB';
export const LOOKUP_CREDIT_REVENUE_COMPLIANCE_YEARS = '/cfr-service/lookup/getComplianceYearListForCCRR';
export const LOOKUP_CREDIT_REVENUE_STATUS_LIST = '/cfr-service/lookup/getCCRRStatusList';
export const GET_MB_STATUS_LIST = '/cfr-service/materialBalanceReport/getSearchFilterStatusList';
export const GET_MB_COMPLIANCE_YEAR = '/cfr-service/materialBalanceReport/getSearchFilterComplianceYearList';
export const LOOKUP_CI_PATHWAY_REPORT_COMPLIANCE_YEARS_LIST = '/cfr-service/lookup/getComplianceYearListForCIPathway';
export const LOOKUP_CI_PATHWAY_REPORT_STATUS_LIST = '/cfr-service/lookup/getCIPathwayReportStatusList';
export const LOOKUP_CI_PATHWAY_FUEL_FILTER_LIST = '/account-service/lookup/getAllFuelType?reportType=/ciPathwayReport';
export const LOOKUP_CI_STATUS_LIST_API = '/account-service/lookup/ciStatus';
export const LOOKUP_CI_INVALID_REASONS_API = '/registry-server/lookup/appStaticData/CI_INVALID_REASON';
export class LookupService {
    constructor(http, storeService, translate) {
        this.http = http;
        this.storeService = storeService;
        this.translate = translate;
        this._facilityNamesCachedLookup$ = new BehaviorSubject(null);
        this._facilityNamesRequestInProgress$$ = new BehaviorSubject(false);
        this._crossFacilityNamesCachedLookup$ = new BehaviorSubject(null);
        this._obligationIds$ = new BehaviorSubject(null);
        this._cacheMap = {};
        this.basicInfoLookupCache = {};
        this.energyDensityName$ = new BehaviorSubject([]);
    }
    clearCache() {
        this._facilityNamesCachedLookup$.next(null);
        this._crossFacilityNamesCachedLookup$.next(null);
        this._cacheMap = {};
    }
    getLookUpData(uri, translate = true, keepTranslationPrefix = false, bindValue, bindLabel = 'name') {
        if (this.basicInfoLookupCache[uri]) {
            return of(this.basicInfoLookupCache[uri]);
        }
        return this.http.get(`${environment.apiUrl}${uri}`).pipe(map(elements => {
            return elements.map(element => ({
                key: bindValue ? element[bindValue] : element.id,
                value: translate
                    ? this.translate.instant(`COMMON.lookupFormData.${element[bindLabel]}`)
                    : keepTranslationPrefix ? `COMMON.lookupFormData.${element[bindLabel]}` : element[bindLabel],
            }));
        }), tap(data => this.basicInfoLookupCache[uri] = data));
    }
    getStaticData(type) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STATIC_DATA}${type}`);
    }
    getThermalElectricalCompression() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_MODE_THERMAL_ELECTRICAL_COMPRESSION}`);
    }
    getStageOfProduction() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_MODE_STAGE_OF_PRODUCTION}`);
    }
    getFeedStock() {
        if (!this._feedstocks) {
            return this.http.get(`${environment.apiUrl}${LOOKUP_FEED_STOCK}`)
                .pipe(tap(feedstocks => this._feedstocks = feedstocks));
        }
        else {
            return of(this._feedstocks);
        }
    }
    getFeedStockFilters(additionalQueryParams) {
        const uri = `${LOOKUP_FEED_STOCK}?isFilter=true${additionalQueryParams}`;
        return this.http.get(`${environment.apiUrl}${uri}`);
    }
    getAssignedTo() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ASSIGNED_TO}`).pipe(map(data => data.sort((a, b) => a.name.localeCompare(b.name))));
    }
    getTaskType(taskFamily) {
        if (!taskFamily) {
            return this.http.get(`${environment.apiUrl}${LOOKUP_TASK_TYPE}`);
        }
        else {
            return this.http.get(`${environment.apiUrl}${LOOKUP_FAMILY_TASK_TYPES}/${taskFamily}`);
        }
    }
    getDeliveryTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_DELIVERY_TYPES}`);
    }
    getRemittanceTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REMITTANCE_TYPES}`);
    }
    getMasterAccounts() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_MASTER_ACCOUNTS}`);
    }
    getCountries(forceReload = false) {
        return this.getCachedLookup(LOOKUP_COUNTRIES, forceReload);
    }
    getActivityCountries(path) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACTIVITY_COUNTRIES}/${path}`);
    }
    getAccountTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACCOUNT_TYPES}`);
    }
    getAccountSubTypes(standardId) {
        const uri = standardId ? `${LOOKUP_ACCOUNT_SUB_TYPES}/${standardId}` : LOOKUP_ACCOUNT_SUB_TYPES;
        return this.http.get(`${environment.apiUrl}${uri}`);
    }
    getFacilityStatus(forceReload = false) {
        return this.getCachedLookup(LOOKUP_FACILITY_STATUS, forceReload);
    }
    getOrgEntityType() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ORG_ENTITY_TYPE}`);
    }
    getFacilityNames(ignoreFilter = false, forceReload = false) {
        let observable$;
        const hasCache = this.facilityNamesCachedLookup$ && this._facilityNamesCachedLookup$.getValue() !== null;
        const isInProgress = this._facilityNamesRequestInProgress$$.getValue();
        if (!forceReload && (hasCache || isInProgress)) {
            observable$ = this.facilityNamesCachedLookup$;
        }
        else {
            this._facilityNamesRequestInProgress$$.next(true);
            this.facilityNamesCachedLookup$ = this._facilityNamesCachedLookup$.asObservable();
            observable$ = this.http.get(`${environment.apiUrl}${LOOKUP_FACILITY_NAMES}`)
                .pipe(tap(data => this._facilityNamesCachedLookup$.next(data)), finalize(() => this._facilityNamesRequestInProgress$$.next(false)));
        }
        return observable$
            .pipe(filter(d => d !== null), map((results) => results.filter(f => ignoreFilter
            || (this.storeService.accountFacilities
                ? (this.storeService.accountFacilities[0] === -1
                    || this.storeService.accountFacilities.includes(f.id)) : true))));
    }
    getCrossFacilityNames(ignoreFilter = false, forceReload = false) {
        let observable$;
        if (!forceReload && this.crossFacilityNamesCachedLookup$) {
            observable$ = this.crossFacilityNamesCachedLookup$;
        }
        else {
            this.crossFacilityNamesCachedLookup$ = this._crossFacilityNamesCachedLookup$.asObservable();
            observable$ = this.http.get(`${environment.apiUrl}${LOOKUP_CROSS_FACILITY_NAMES}`)
                .pipe(tap(data => this._crossFacilityNamesCachedLookup$.next(data)));
        }
        return observable$
            .pipe(filter(d => d !== null), map((results) => results.filter(f => ignoreFilter
            || (this.storeService.accountFacilities
                ? (this.storeService.accountFacilities[0] === -1
                    || this.storeService.accountFacilities.includes(f.id)) : true))));
    }
    validatePerformAction(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_VALIDATE_PERFORM_ACTION}${accountId}`);
    }
    getPendingFacilityNames() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PENDING_FACILITY_NAMES}`);
    }
    getRegistrationFacilities(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REGISTRATION_FACILITIES}/${accountId}`);
    }
    getRegistrationFacilitiesWithoutagreement(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REGISTRATION_FACILITIES_WITHOUT_AGREEMENT}/${accountId}`);
    }
    getSuspensionReasons() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUSPENSION_REASONS}`);
    }
    getCreditReportTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_REPORT_TYPES}`);
    }
    getCreditSubsections() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_SUBSECTIONS}`);
    }
    getRefundOptions() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REFUND_OPTIONS}`);
    }
    getFacilityCertificateNos() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_FACILITY_CERTIFICATE_NOS}`);
    }
    getResponsiblePersons() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_RESPONSIBLE_PERSONS}`);
    }
    getObligationStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OBLICATION_STATUS}`);
    }
    // TODO: the same implementation as 'getDocumentTypes()'
    getOBPSDocumentType(entityName) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_DOCUMENT_TYPE}/${this.storeService.user.programId}/${entityName}`);
    }
    // TODO: the same implementation as 'getOBPSDocumentType()'
    getDocumentTypes(entityName) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_DOCUMENT_TYPE}/${this.storeService.user.programId}/${entityName}`);
    }
    getAllUnitStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_STATUS}`);
    }
    getPublicUnitStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PUBLIC_OFFSET_UNIT_STATUSES}`);
    }
    getRuUnitStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_RU_CREDIT_STATUS}`);
    }
    getCompliancePeriods() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_COMPLIANCE_PERIODS}`);
    }
    geElectricEnergySource() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ELECTRIC_ENERGY_SOURCE}`);
    }
    getActivitySubjectToApplication() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_MODE_ACTIVITY_SUBJECT_TO_APPLICATION}`);
    }
    getResultCompliancePeriods() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_RESULT_COMPLIANCE_PERIODS}`);
    }
    getFacilityVersions() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_FACILITY_VERSIONS}`);
    }
    getObpsReportTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OBPS_REPORT_TYPES}`);
    }
    getJurisdictions(forceReload = false) {
        return this.getCachedLookup(LOOKUP_JURISDICTIONS_URI, forceReload);
    }
    getAllProvince(forceReload = false) {
        return this.getCachedLookup(LOOKUP_ALL_PROVINCES_URI, forceReload);
    }
    getVerificationFilters() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_VB_FACILITY_NAMES}`);
    }
    getOutsideCAProvince() {
        const output = [{ id: 'OC', name: 'OC' }];
        return of(output);
    }
    getFuels(path, byType = false) {
        return this.http.get(`${environment.apiUrl}${byType ? LOOKUP_FUELS_BY_TYPE : LOOKUP_FUELS}/${path}`);
    }
    getFuelTypes(forceReload = false) {
        return this.getCachedLookup(`${LOOKUP_FUEL_TYPES}`, forceReload);
    }
    getFuelsForActivity(activityCode) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_FUELS_FOR_ACTIVITY}/${activityCode}`)
            .pipe(map(data => data.map(item => (Object.assign({}, item, { id: parseInt(item.id, 10) })))));
    }
    getCachedLookup(path, forceReload = false) {
        let observable$;
        const request = () => {
            return this.http.get(`${environment.apiUrl}${path}`);
        };
        if (forceReload || !this._cacheMap[path]) {
            observable$ = request().pipe(shareReplay(1));
            this._cacheMap[path] = observable$;
        }
        else {
            observable$ = this._cacheMap[path];
        }
        return observable$;
    }
    getAreaOfExpertise(type) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_AREA_OF_EXPERTISE}${type}`);
    }
    getAreaOfExpertiseById(type) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_AREA_OF_EXPERTISE_BY_ID}${type}`);
    }
    getProfessionalQualifications() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PROFESSIONAL_QUALIFICATIONS}`);
    }
    getAcceptedJurisdictions() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACCEPTED_JURISDICTIONS_URI}`);
    }
    getBusinessStructure(accountTypeId, forceReload = false) {
        const uri = accountTypeId ? `${LOOKUP_BUSINESS_STRUCTURE}/${accountTypeId}` : LOOKUP_BUSINESS_STRUCTURE;
        return this.getCachedLookup(uri, forceReload);
    }
    getUnitClassForListing() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_UNIT_CLASS_LISTING}`);
    }
    getVersionStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_RESULT_VERSION_STATUS}`);
    }
    getStandards() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STANDARDS}`);
    }
    getPaymentRefundStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PAYMENT_REFUND_STATUS}`);
    }
    getPaymentRefundMode() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PAYMENT_REFUND_MODE}`);
    }
    getTransactionType(filterState) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_TRANSACTION_TYPES}`, filterState);
    }
    getPaymentStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PAYMENT_STATUS}`);
    }
    getFileUploadStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_FILEUPLOAD_STATUS}`);
    }
    getTableConfigurations(name) {
        return this.http.get(`${environment.apiUrl}${TABLE_DETAILS}${name}`);
    }
    getUnderSectionTableConfig(name) {
        return this.http.get(`${environment.serviceUrl}${TABLE_DETAILS}${name}`);
    }
    getEnergyDensityConfig() {
        this.updateDensity(FUEL_ENERGY_SOURCE);
        return this.http.get(`${environment.apiUrl}${TABLE_DETAILS}${ENERGY_DENSITY}`);
    }
    export(payload) {
        return this.http.post(`${environment.serviceUrl}${EXPORT}`, payload, {
            observe: 'response',
            responseType: 'blob',
        });
    }
    downloadTemplate(uri) {
        return this.http.get(`${environment.apiUrl}${uri}`, {
            observe: 'response',
            responseType: 'blob',
        });
    }
    findAccount(uri, accountId) {
        return this.http.post(`${environment.apiUrl}${uri}`, accountId, { responseType: 'text' });
    }
    getSubmissionTypes() {
        return this.http.post(`${environment.apiUrl}${LOOKUP_SUBMISSION_TYPE}`, {});
    }
    getSubmissionsType$() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUBMISSIONS_TYPE}`);
    }
    getReportingPeriod$() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REPORTING_PERIODS}`);
    }
    getTypeCodes$() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_TYPE_CODES}`);
    }
    getLookup(uri) {
        return this.http.get(`${environment.apiUrl}${uri}`)
            .pipe(map(data => Array.isArray(data) ? data : data[Object.keys(data)[0]]));
    }
    postLookup(uri, serviceUrl = false) {
        let baseUrl = environment.apiUrl;
        if (serviceUrl) {
            baseUrl = environment.serviceUrl;
        }
        return this.http.post(`${baseUrl}${uri}`, {});
    }
    getSubmissionSubTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUBMISSION_SUB_TYPE}`);
    }
    getApplicationSubmissionTypes(searchFilter = {}) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_APPLICATION_SUBMISSION_TYPE}`, searchFilter);
    }
    getAssignedVB() {
        return this.http.post(`${environment.apiUrl}${LOOKUP_ASSIGNED_VB}`, {});
    }
    getCfrCompliancePeriod() {
        return this.http.post(`${environment.apiUrl}${LOOKUP_CFR_COMPLAINCE_PERIOD}`, {});
    }
    getCompRepCompliancePeriod() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_COMPLIANCE_REPORT_COMPLIANCE_YEAR_LIST}`, {});
    }
    getSubmissionReportStatus() {
        return this.http.post(`${environment.apiUrl}${LOOKUP_SUBMISSION_STATUS}`, {});
    }
    getAccounts(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_DESTINATION_ACCOUNTS}${accountId}`);
    }
    getWorkflowApprovalTypes() {
        return this.http.get(`${environment.apiUrl}${ADMIN_WORKFLOW_APPROVAL_TYPES}`);
    }
    getObligationIds(accountId) {
        if (!accountId) {
            this._obligationIds$.next([]);
        }
        else {
            return this.http.get(`${environment.apiUrl}${LOOKUP_OBLIGATION_IDS}${accountId}`)
                .pipe(map(data => data.map(el => (Object.assign({}, el, { name: el.id })))))
                .subscribe(data => this._obligationIds$.next(data));
        }
    }
    getVbOrgTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_VB_ORG_TYPES}`);
    }
    getAccreditationTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACCREDITATION_BODY}`);
    }
    getAddressList(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ADDRESS_LIST}${accountId}`);
    }
    getSubOrganizations(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUBORGANIZATION_LIST}${accountId}`);
    }
    getQuantificationMethods(langChange) {
        if (!this._quantificationMethods || langChange) {
            return this.http.get(`${environment.apiUrl}${LOOKUP_QUANTIFICATION_METHOD}`)
                .pipe(tap(quantificationMethods => this._quantificationMethods = quantificationMethods));
        }
        else {
            return of(this._quantificationMethods);
        }
    }
    getRegulatoryRegimes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REGULATORY_REGIME}`);
    }
    getActivities() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACCOUNT_SUB_TYPES}`);
    }
    getApplicationSubTypes(searchFilter) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_APPLICATION_SUB_TYPE}`, searchFilter);
    }
    searchPreviousOpas(currentData) {
        const lookup = [];
        currentData.accountSubTypeDtoList.forEach(subType => {
            subType.activities.forEach(activity => {
                activity.facilities.forEach(facility => {
                    const opa = facility.opa;
                    const opaId = facility.opaId;
                    if (opa && opaId && lookup.indexOf(t => t.id === opaId) === -1) {
                        lookup.push({ id: opaId, name: opa });
                    }
                });
            });
        });
        return of(lookup);
    }
    getLifecycleFeedstock() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_LIFECYCLE_FEEDSTOCK}`);
    }
    getStageProductionTransportation() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_MODE_STAGE_OF_PRODUCTION_TRANSPORTATION}`);
    }
    getStageCompressionLiquid() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STAGE_COMPRESSION_LIQUE_FACTION}`);
    }
    getStageElectricity() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STAGE_ELECTRICITY}`);
    }
    getStageAdditionalTransportation() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STAGE_ADDITIONAL_TRANSPORTATION}`);
    }
    getStageCombustion() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STAGE_COMBUSTION}`);
    }
    getTypeOfEnergy() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_TYPE_OF_ENERGY}`);
    }
    getFacilitiesByProvinces(organizationIds = [], flatten = false) {
        const payload = { ids: organizationIds };
        return this.http.post(`${environment.apiUrl}${LOOKUP_FACILITIES_BY_PROVINCES}`, payload)
            .pipe(map(response => {
            if (flatten) {
                let resp = [];
                Object.keys(response).map(key => resp = [
                    ...resp,
                    ...response[key].map(data => (Object.assign({}, data, { value: this.translate.instant(`COMMON.jurisdictionsList.${data.value}`) }))),
                ]);
                return resp;
            }
            else {
                return response;
            }
        }));
    }
    getTaskFamilyList() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_TASK_FAMILY}`);
    }
    getElectricityFromProvincialGrid() {
        return this.http.get(`${environment.apiUrl}${ELECTRICITY_FROM_PROVINCIAL_GRID}`);
    }
    getLanguageOfCorrespondance() {
        return this.http.get(`${environment.apiUrl}${LANGUAGE_OF_CORRESPONDANCE}`)
            .pipe(map(data => data.map(el => (Object.assign({}, el, { lang: el.id })))));
    }
    getAccountClosureReasons() {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_CLOSURE_REASON}`);
    }
    getAccountHistoryActionPerformedByUserById(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACCOUNT_HISTORY_ACTION_BY_USER}${accountId}`);
    }
    getAccountHistoryActionByTypeById(accountId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACCOUNT_HISTORY_ACTION_BY_TYPE}${accountId}`);
    }
    getOffsetGreenHousGases() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OFFSET_GREEN_HOUSE_GAS}`);
    }
    getOffsetProjectActivityType() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OFFSET_PROJECT_ACTIVITY_TYPE}`);
    }
    getOffsetProtocols() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OFFSET_GFG_PROTOCOLS}`);
    }
    getProvinceByCountry(countryCode = 'CA') {
        const uri = `${LOOKUP_PROVINCE_BY_COUNTRY}${countryCode}`;
        return this.getCachedLookup(uri);
    }
    getProjectNamesByFilter(ids) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_SEARCH_PROJECT_NAMES_BY_FILTER}`, { ids });
    }
    getPojectType(type = 'Type') {
        const uri = `${LOOKUP_PROJECT_TYPE}${type}`;
        return this.getCachedLookup(uri);
    }
    getSubAccountCreationReasons() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUB_ACCOUNT_CREATION_REASONS}`);
    }
    getOffsetProjectNames(id, type) {
        let uri = `${environment.apiUrl}${LOOKUP_OFFSET_PROJECT_NAMES}`;
        let params = new HttpParams();
        if (id !== undefined) {
            uri = `${uri}/${id}`;
        }
        if (type && type.name !== undefined && type.value !== undefined) {
            params = params.append(type.name, type.value);
        }
        return this.http.get(uri, { params });
    }
    getReportingPeriodVersions(projectId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_REPORTING_PERIOD_VERSION}${projectId}`);
    }
    getProjectHistoryActionPerformedByUserById(projectId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PROJECT_HISTORY_ACTION_BY_USER}${projectId}`);
    }
    getProjectHistoryActionByTypeById(projectId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PROJECT_HISTORY_ACTION_BY_TYPE}${projectId}`);
    }
    getSubmissionsHistoryActivityTypes(submissionsId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUBMISSIONS_HISTORY_ACTION_BY_TYPE}${submissionsId}`);
    }
    getSubmissionsHistoryUsers(projectId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SUBMISSIONS_HISTORY_ACTION_BY_USER}${projectId}`);
    }
    getProjectCancellationReason() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_PROJECT_CANCELLATION_REASON}`);
    }
    getSubmissionTypesList$() {
        return this.http.get(`${environment.apiUrl}${SUBMISSIONS_NOTIFICATION_TYPE}`);
    }
    getSubmissionReportTypesList$() {
        return this.http.get(`${environment.apiUrl}${SUBMISSIONS_REPORT_TYPE}`);
    }
    getOffsetProtocolStatuses() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OFFSET_PROTOCOL_STATUS}`);
    }
    getObligationReasonType() {
        return this.http.get(`${environment.apiUrl}${OBLIGATION_REASON_TYPE}`);
    }
    getCancellationReasonList(type) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_OFFSET_STATIC_DATA}${type}`).pipe(map(data => data.map(el => (Object.assign({}, el, { id: el.code, name: el.code })))));
    }
    getERPProjectType() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ERP_PROJECT_TYPE}`);
    }
    getERPQuantificationMethods(projectType) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ERP_QUANTIFICATION_METHODS}/${projectType}`);
    }
    getERPApprovalTestMethods() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ERP_APPROVAL_TEST_METHOD}`);
    }
    getERPCarbonIntensityIDs(payload) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_ERP_CARBON_INTENSITY_IDS}`, payload);
    }
    geEnergyDensityUnits() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ENERGY_DENSITY_UNITS}`);
    }
    getEnergyDensityFuels() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ENERGY_DENSITY_FUELS}`);
    }
    updateDensity(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (id) {
                if (id === FUEL_ENERGY_SOURCE) {
                    const fuels = yield this.getEnergyDensityFuels().toPromise();
                    this.energyDensityName$.next(fuels);
                }
                else {
                    const province = yield this.getJurisdictions().toPromise();
                    province.forEach(item => item.name = this.translate.instant(`COMMON.jurisdictionsList.${item.name}`));
                    this.energyDensityName$.next(province);
                }
            }
        });
    }
    getQMStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_QM_STATUS}`);
    }
    getQMFuelTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_QM_FUEL_TYPES}`);
    }
    getCCRTypes() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_CREATION_REPORT_TYPES}`);
    }
    getCCRComplianceYears() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_CREATION_REPORT_COMPLIANCE_YEARS}`)
            .pipe(map((years) => years.map(year => ({ id: year, name: year }))));
    }
    getCARComplianceYears() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_CREATION_REPORT_COMPLIANCE_YEARS}/${REPORT_TYPE}`)
            .pipe(map((years) => years.map(year => ({ id: year, name: year }))));
    }
    getCCRActivityType(reportType) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_CREATION_REPORT_ACTIVITY_TYPE}/${reportType}`);
    }
    getCCRQuarters(complianceYear) {
        const uri = complianceYear
            ? `${LOOKUP_CREDIT_CREATION_REPORT_QUARTERS}/${complianceYear}`
            : `${LOOKUP_CREDIT_CREATION_REPORT_QUARTERS}`;
        return this.http.get(`${environment.apiUrl}${uri}`);
    }
    getAgreementTransferCreditFuels(accountId) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_AGREEMENT_TRANSFER_CREDIT_FUELS}`, { id: accountId });
    }
    getCiApplicationTypes() {
        return this.http.get(`${environment.apiUrl}${CI_APPLICATION_TYPE}`);
    }
    getCreditGroupList(flag = false) {
        let url = `${environment.apiUrl}${LOOKUP_CREDIT_GROUP_COMPLETE_LIST}`;
        if (flag) {
            url = url + '/true';
        }
        return this.http.get(url)
            .pipe(map(data => data.map(item => (Object.assign({}, item, { id: parseInt(item['id'], 10) })))));
    }
    getCreditClassList() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_CLASS_COMPLETE_LIST}`)
            .pipe(map(data => data.map(item => (Object.assign({}, item, { id: parseInt(item['id'], 10) })))));
    }
    getCfrAccounts() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CFR_ACCOUNTS}`);
    }
    getAllFuelType() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ALL_FULE_TYPE}`);
    }
    getSectionForCreditCreation() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_SECTION_FOR_CREDIT_CREATION}`);
    }
    getEnergyEfficiencyForAnnualStep(stepCode) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ENERGY_EFFICIENCY_FOR_ANNUAL_STEP}/${stepCode}`);
    }
    getComplianceYearCcr() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_COMPLIANCE_YEAR_CCR}`);
    }
    getComplianceYearCCM() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_COMPLIANCE_YEAR_CCM}`);
    }
    getCCRReportYears() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_COMPLIANCE_YEAR_CRR}`)
            .pipe(map((years) => years.map(year => ({ id: year, name: year }))));
    }
    getCreditCreationReportStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_STATUS_FOR_CREDIT_CREATION}`);
    }
    getComplianceReportStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_COMPLIANCE_REPORT_STATUS_LIST}`);
    }
    getNOENatureOfErrors() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_NOE_NATURE_OF_ERRORS}`)
            .pipe(map((data) => data.map(item => (Object.assign({}, item, { name: item.code })))));
    }
    getNOETypeOfAccountsForNotice() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_NOE_TYPE_OF_ACCOUNTS_FOR_NOTICE}`)
            .pipe(map((data) => data.map(item => (Object.assign({}, item, { name: item.code })))));
    }
    getAreaOfExpertiseForVB() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_VB_AREA_OF_EXPERTISE}`).pipe(map(data => {
            const otherOption = data.filter(item => item.id === OTHER_AREA_OF_EXPERTISE_ID);
            const remainingOptions = data.filter(item => item.id !== OTHER_AREA_OF_EXPERTISE_ID).sort(((a, b) => a.name.localeCompare(b.name)));
            return [...remainingOptions, ...otherOption];
        }));
    }
    getErfpCompliancePeriod() {
        return this.http.get(`${environment.apiUrl}${ERFP_COMPLIANCE_PERIOD}`);
    }
    getComplianceReportComplianceYears() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_COMPLIANCE_REPORT_COMPLIANCE_YEARS}`)
            .pipe(map((years) => years.map(year => ({ id: year, name: year }))));
    }
    getCompComplianceReportComplianceYears() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_COMP_COMPLIANCE_REPORT_COMPLIANCE_YEARS}`)
            .pipe(map((years) => years.map(year => ({ id: year, name: year }))));
    }
    getCreditBalanceReportYears() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CREDIT_BALANCE_REPORT_YEARS}`)
            .pipe(map((years) => years.map(year => ({ id: year, name: year }))));
    }
    getComplianceReportFuelTransportMethods() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_COMPLIANCE_REPORT_FUEL_TRANSPORT_METHODS}`)
            .pipe(map((data) => data.map(item => (Object.assign({}, item, { name: item.code })))));
    }
    getERFPNames() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ERFP_NAMES}`);
    }
    getSubmissionIds() {
        return this.http.get(`${environment.serviceUrl}${LOOKUP_VERIFICATION_SUBMISSION_ID}`);
    }
    getChecklistSubmissionTypes() {
        return this.http.get(`${environment.serviceUrl}${LOOKUP_COI_CHECKLIST_SUBMISSION_TYPES}`);
    }
    getAllowedApplicationTypes(searchFilter = {}) {
        return this.http.post(`${environment.apiUrl}${LOOKUP_ALLOWED_APPLICATION_TYPES}`, searchFilter);
    }
    getActiveAccounts() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACTIVE_ACCOUNTS}`);
    }
    getCIStatus() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CI_STATUS_LIST_API}`);
    }
    getInvalidReasons() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_CI_INVALID_REASONS_API}`);
    }
}
LookupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LookupService_Factory() { return new LookupService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StoreService), i0.ɵɵinject(i3.TranslateService)); }, token: LookupService, providedIn: "root" });
