import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormlyCustomSelectOptionsPipe } from './custom-select-options.pipe';
import { CustomTranslatePipe } from './custom-translate.pipe';
import { DictPipe } from './dict.pipe';
import { CustomShortenTextPipe } from './custom-shorten-text.pipe';
import { LocalizedDecimalPipe } from '@shared/pipes/localized-decimal.pipe';
import { PeriodDurationPipe } from '@shared/pipes/period-duration.pipe';

@NgModule({
  declarations: [
    CustomTranslatePipe,
    DictPipe,
    FormlyCustomSelectOptionsPipe,
    CustomShortenTextPipe,
    LocalizedDecimalPipe,
    PeriodDurationPipe,
  ],
  exports: [
    CustomTranslatePipe,
    DictPipe,
    FormlyCustomSelectOptionsPipe,
    CustomShortenTextPipe,
    LocalizedDecimalPipe,
    PeriodDurationPipe,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    DecimalPipe,
    LocalizedDecimalPipe
  ],
})
export class SharedPipesModule { }
