import { Injectable } from '@angular/core';
import { NavigationService } from '@shared/services/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class BackbtnserviceService {


  constructor( private navigation: NavigationService) { }

  public backPreviousPage() {
    this.navigation.back();
  }
}
