import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { cmNewSubAccount, newSubAccount } from '@core/constants/registration-route-types.const';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactSupportModalComponent } from '@shared/components/contact-support-modal/contact-support-modal.component';
import { ActionPanelService } from '@shared/services/action-panel.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { isArray } from 'util';

@Component({
  selector: 'app-enhanced-user-sidebar',
  templateUrl: './enhanced-user-sidebar.component.html',
  styleUrls: ['./enhanced-user-sidebar.component.scss'],
})
export class EnhancedUserSidebarComponent implements OnInit, OnDestroy {

  @ViewChild('facilityNameNgSelect', {static: true}) facilityNameNgSelect;

  public groupByField = 'subAccounts';

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();
  public facilityNames: IApp.IFacilityNamesLookup[];
  public createAccountEnabled = false;
  public labelPrefix: any = '';
  private selectedAccount: IApp.IFacilityNamesLookup;

  // TODO: check it and remove if useless
  test;

  public isOffset: string;

  constructor(
    private actionPanelService: ActionPanelService,
    private translate: TranslateService,
    private helperService: HelperService,
    private modalService: ModalService,
    private store: StoreService,
    private lookupService: LookupService,
    private accountManagementService: AccountManagementService,
    private uploadDocumentService: UploadDocumentService,
    public router: Router,
  ) {}

  ngOnInit() {
    this.labelPrefix = this.store.user.getProgramPrefix();
    this.actionPanelService.broadcastAccountId$.next(null);
    this.actionPanelService.broadcastSubAccountSelected$.next(null);
    this.isOffset = this.store.user.isOffset() ? 'offset' : '';
    this.store.accountFacilities$.pipe(
        takeUntil(this._destroy$),
        filter(facilities => !!facilities && isArray(facilities)),
        distinctUntilChanged((a, b) => a.every((v, i) => v === b[i])),
      ).subscribe(
        () => {
          this.getFacilityNames();
        },
      );

    this.store.getProperty('nfmp.enabled.date')
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        property => {
          if (property.value  && property.value.toLowerCase() === 'true') {
            this.createAccountEnabled = true;
          } else {
            this.createAccountEnabled = this.store.user.isOffset();
          }
        });

      if (!this.accountId) {
        this.getFacilityNames();
      }
  }

  getFacilityNames() {
    this.lookupService.getFacilityNames().pipe(
        takeUntil(this._destroy$),
        map(value => value.filter(facility => facility.status === 'ACTIVE'))
      ).subscribe(value => {
        this.facilityNames = this.mapSubAccounts(value);
        this.actionPanelService.broadcastAccountId(this.facilityNames[0].id);
        setTimeout(() => this.facilityNameNgSelect.select(this.facilityNameNgSelect.itemsList.items[0]));
      });
  }

  private mapSubAccounts(data) {
    let _data = data;
    const subAccounts = {};
    _data.filter(item => !!item.masterAccountId).forEach(item => {
      if (!subAccounts[item.masterAccountId]) {
        subAccounts[item.masterAccountId] = [];
      }
      subAccounts[item.masterAccountId].push(item);
    });
    Object.keys(subAccounts).forEach(masterAccountId => {
      const masterAccountIdx = _data.findIndex(item => Number(item.id) === Number(masterAccountId));
      if (masterAccountIdx > -1) {
        _data[masterAccountIdx] = {..._data[masterAccountIdx], subAccounts: subAccounts[masterAccountId]};
        _data = _data.filter(item => !subAccounts[masterAccountId].map(i => i.id).includes(item.id));
      }
    });
    _data.map(item => !item.subAccounts && (item.subAccounts = []));
    return _data;
  }

  get accountId(): number {
    return this.actionPanelService.accountId;
  }

  get isSubAccountDisabled(): boolean {
    return this.actionPanelService.subAccountSelected;
  }

  userCreateAllowed() {
    let allowed =  this.store.user.userCreateAllowed;
    const hasPermission = this.store.user.hasPermission('REQUEST_NEW_USERS');
    if (allowed && this.store.user.isOffset()) {
        allowed = false;
        this.store.user.associationsList.forEach(asst => {
          if (this.accountId === asst.accountId && (!asst.accountDto || !asst.accountDto.masterAccount)) {
            allowed = true;
          }
        });
    }
    return allowed && hasPermission;
  }

  subAccountCreateAllowed() {
    return this.accountId && this.store.user.hasPermissionForAccount('ACCOUNT_WRITE', this.accountId) &&
    this.createAccountEnabled && (this.store.user.isCM() || this.store.user.isOffset());
  }

  redirectToSubAccount(accountId) {
    if ( this.actionPanelService.account.status === 'LOCKED') {
      this.showError('TransactionNotPermittedOnLockedAccount');
      return;
    }

    let path = '/account-management/register-account/new-sub-account/';
    let state: any = { registrationType: newSubAccount };

    if (this.store.user.isCM()) {
      path = '/account-management/register-account/cm-new-sub-account/';
      state = {
        registrationType: cmNewSubAccount,
        ompAccountTypePurpose: {
          accountRegistrationType: this.actionPanelService.account.registrationType,
          accountSubType: this.actionPanelService.account.accountSubType,
        },
      };
    }

    this.router.navigate(this.helperService.getTranslatedPath(path + accountId + '/' + this.isOffset), { state });
}

  get lang(): string {
    return this.translate.currentLang;
  }

  getTranslatedPath(path) {
    return this.helperService.getTranslatedPath(path);
  }

  contactUs(event) {
    event && event.preventDefault();
    this.modalService.open(ContactSupportModalComponent, null);
  }

  showUserGuide(event) {
    event && event.preventDefault();
    if (this.store.user.getProgramPrefix().length > 0) {
      this.uploadDocumentService.downloadLatestDocument(
        `${this.store.user.getProgramPrefix()}_USER_GUIDE_${this.translate.currentLang.toUpperCase()}`,
      );
    } else {
      this.uploadDocumentService.downloadLatestDocument('USER_GUIDE');
    }
  }

  getAccount(account: any) {
    if (this.selectedAccount && this.selectedAccount.id === account.id) {
      return;
    }
    this.selectedAccount = account;
    this._stopPrevious$.next();
    this.accountManagementService.getAccountDetails(account.id ? account.id : account).pipe(
      filter(data => !!data),
      takeUntil(this._destroy$),
      takeUntil(this._stopPrevious$),
    ).subscribe(
      data => {
        if (data.masterAccountId !== null) {
          this.actionPanelService.broadcastSubAccountSelected(true);
        } else {
          this.actionPanelService.broadcastSubAccountSelected(false);
        }
        this.actionPanelService.account = data;
        this.actionPanelService.broadcastAccountId(data.id);
      },
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private showError(message) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      type: SereviceMessageType.ERROR,
    };
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe();
  }
}
