import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CreditCreationReportsService } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import {cloneDeep} from 'lodash';
import { Subject } from 'rxjs';
import {
  CCR_CIVIC_ADDRESS_FORM_SECTION,
  CCR_POSTAL_ADDRESS_FORM_SECTION,
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';

@Component({
  selector: 'app-charging-station-address-form',
  templateUrl: './charging-station-address-form.component.html',
  styleUrls: ['./charging-station-address-form.component.scss'],
})
export class ChargingStationAddressFormComponent implements OnInit, OnDestroy {

  public _destroy$ = new Subject<any>();

  public visible = true;

  public currentStepObj;

  public authorizedOfficial;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  model;
  public isDisabled = false;
  forceReadOnly = false;

  POSTAL_ADDRESS_FORM_SECTION = cloneDeep(CCR_POSTAL_ADDRESS_FORM_SECTION);
  CIVIC_ADDRESS_FORM_SECTION = cloneDeep(CCR_CIVIC_ADDRESS_FORM_SECTION);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ChargingStationAddressFormComponent>,
    private service: CreditCreationReportsService,
    private formFactoryService: FormFactoryService,
  ) {
  }

  ngOnInit() {
    this.currentStepObj = this.data.currentStepObj;
    this.forceReadOnly = this.data.forceReadOnly;
    if (this.data.model) {
      this.model = cloneDeep(this.data.model.chargingStation);
    } else {
      this.model = cloneDeep(this.currentStepObj.model.chargingStation);
    }
    this.fields = this.configureFields();
    this.disableIfApplicable();
  }

  public configureFields() {
    const fields = [];
    this.CIVIC_ADDRESS_FORM_SECTION.hideLatLong = false;
    this.CIVIC_ADDRESS_FORM_SECTION.requiredLatLong = true;
    this.CIVIC_ADDRESS_FORM_SECTION['label'] = `SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.hostChargingCivicAddress`;
    this.CIVIC_ADDRESS_FORM_SECTION.sameAsPostalAddressDisabled = () => this.isSameAsPostalAddressDisabled();
    this.POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = true;
    this.POSTAL_ADDRESS_FORM_SECTION['label'] = `SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.hostChargingPostalAddress`;
    fields.push(this.formFactoryService.configurFormPostWithCivicAddress(this.POSTAL_ADDRESS_FORM_SECTION, this.CIVIC_ADDRESS_FORM_SECTION)[0]);
    setTimeout(() =>{
      this.formFactoryService.addPostalAddressValidator(this.form, 'postalAddress.', false);
      this.formFactoryService.addPostalAddressValidator(this.form, 'civicAddress.', false);
    });
    return fields;
  }

  private isSameAsPostalAddressDisabled(): boolean {
    // TODO : Needs to be verified by Primo before removing it permanently
    // const isDisabled = this.model['postalAddress'] && this.model['postalAddress']['province'];
    // const stateChecked = this.model['civicAddress'] && this.model['civicAddress']['sameAsPostalAddress'];
    // if (isDisabled && stateChecked) {
      // this.form.get('civicAddress').enable();
      // this.form.get('civicAddress').get('sameAsPostalAddress').setValue(false);
    // }
    setTimeout(() => this.disableCountryFields());
    return this.forceReadOnly ? true : false;
  }

  private disableIfApplicable(): void {
    setTimeout(() => {
      if (this.model.id || this.forceReadOnly) {
        this.form.disable();
        this.isDisabled = true;
      } else {
        this.disableCountryFields();
      }
    });
  }

  private disableCountryFields(): void {
    this.form.get('postalAddress').get('country').disable();
    this.form.get('civicAddress').get('country').disable();
  }

  public save(): void {
    this.formFactoryService.markFormGroupDirty(this.form);
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.service.clearCallMethod();
    this._destroy$.next();
    this._destroy$.complete();
  }

}
