import { ViewChild, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  ICreditCreationReport,
} from '@module/credit-creation-reports/credit-creation-reports.types';
import {
  CreditCreationReportsService,
} from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';
import IDocument = IApp.IDocument;
import { environment } from '@env/environment';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { TranslateService } from '@ngx-translate/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ModalService } from '@shared/services/modal.service';
import { Dialog } from 'primeng';
@Component({
  selector: 'app-add-details-form',
  templateUrl: './add-details-form.component.html',
  styleUrls: ['./add-details-form.component.scss'],
})
export class AddDetailsFormComponent implements OnInit, OnDestroy {

  @ViewChild('dialog', { static: false }) dialog: Dialog;

  @Input() form = new FormGroup({});
  @Input() masterProject: any;
  @Output() modelIdUpdate = new EventEmitter();

  public report: ICreditCreationReport;
  public model: any = {};
  public fields: FormlyFieldConfig[];
  public needMoreInfoFields: FormlyFieldConfig[];
  public documents: IDocument[] = [];
  public isDocumentUploadInProgress = false;

  public visible = true;
  private _destroy$ = new Subject<any>();

  public currentStepObj;
  public downloadSelected;
  public taskList = [];
  public documentUploadDisabled = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddDetailsFormComponent>,
    private service: CreditCreationReportsService,
    private uploadDocumentService: UploadDocumentService,
    private translateService: TranslateService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.currentStepObj = this.data.currentStepObj;
    this.currentStepObj.onBeforeAddDetails();
    this.data.section && (this.currentStepObj.section = this.data.section);
    this.currentStepObj.dialogRef = this.dialogRef;
    this.setTaskList();
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  public save() {
    if (!this.requiredNestedFields) {
      return;
    }
    if (this.currentStepObj.detailSectionDocumentUploadConfig &&
        !this.uploadDocumentService.checkMandatoryDocuments(
          this.currentStepObj.detailSectionDocumentUploadConfig,
          this.documents,
          this.currentStepObj.optionalDocsWithPlaceholderCodes
        )) {
        return;
    }
    this.currentStepObj.save(this.dialogRef);
  }

  public close(): void {
    if (this.currentStepObj.form.touched) {
      const sub$ = this.service.openConfirmationModal('confirmOnClose').subscribe(result => {
        sub$.unsubscribe();
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  documentUploadHandler(documentResult: IDocument[]): void {
    this.documents = documentResult;
    this.isDocumentUploadInProgress = documentResult
      .filter(document => document._uploadStatus)
      .some(document => document._uploadStatus !== 'CLEAN');
  }

  public downloadTemplate(ev) {
    let uri;
    switch(ev.id) {
      case 'DOCUMENT_REQUIRED_FOR_INFROMATION_ON_PUBLIC_CHARGING_STATIONS':
        uri = '/account-service/document/downloadChTemplate/false';
        break;
      case 'DOCUMENT_REQUIRED_FOR_INFROMATION_ON_PRIVATE_CHARGING_STATIONS':
        uri = '/account-service/document/downloadChTemplate/true';
        break;
      default:
        return;
    }
    this.uploadDocumentService.downloadDocument(`${ environment.apiUrl }${uri}`);
    setTimeout(() => {
      this.downloadSelected = null;
      (document.activeElement as HTMLElement).blur();
      }, 0,
    );
  }

  private setTaskList() {
    if (this._hasDocumentConfigs() && this.service.currentStep.code === 'STEP-4'){
          this.taskList = this.currentStepObj.detailSectionDocumentUploadConfig.documentUploadConfigs.map(entity => {
            return {
              id: entity.documentTypeCode,
              name: this.translateService.instant(`COMMON.documentList.${ entity.documentTypeCode}`)
            }
          });
        }
  }

  private get requiredNestedFields(): boolean {
    const missingNestedFields = this.currentStepObj.validateRequiredNestedFields;
    if (Object.keys(missingNestedFields).length) {
      this.nestedFieldsRequired(missingNestedFields);
      return false;
    } else {
      return true;
    }
  }

  private nestedFieldsRequired(missingFields) {
    let msg = '';
    Object.keys(missingFields).forEach(key => msg += `<li>${ this.translateService.instant(missingFields[key]) }</li>`);
    const metaData = [`<ol type="a">${ msg }</ol>`];
    this.modalService.open(
      ServiceMessageComponent,
      {
        messages: [
          {
            message: 'mandatoryNestedFieldsRequired',
            metaData,
          },
        ],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this.currentStepObj.fuelEnergyDensity$.next([]);
  }

  private _hasDocumentConfigs() {
    return this.currentStepObj.detailSectionDocumentUploadConfig &&
      this.currentStepObj.detailSectionDocumentUploadConfig.documentUploadConfigs;
  }

}
