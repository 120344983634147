import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';

export const COMPLIMENTARY_COMPLIANCE_REPORT_ID_KEY = 'COMP_COMPLIANCE_REP_ID';
export const COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX = 'COMPLIMENTARY_COMPLIANCE_REPORT';
export const COMPLIMENTARY_COMPLIANCE_REPORT_TYPE = 'COMPLIMENTRY_COMPLIANCE_REPORT';
export const COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX = '.reportStep';
export const COMPLIMENTARY_COMPLIANCE_REPORT_CURRENT_STEP_KEY = 'COMP_COMPLIANCE_REP_STEP_KEY';
export const COMPLIMENTARY_COMPLIANCE_REPORT_DATA_KEY = 'COMP_COMPLIANCE_REP_DATA';
export const COMPLIMENTARY_COMPLIANCE_REPORT_STEP_COMPONENTS = {
    'STEP-1': 'ComplimentaryComplianceReportOutstandingRequirementDetailsComponent',
    'STEP-2_1': 'CreditTransferBlockComponent',
    'STEP-2_2': 'CreditsTransferTowardsDeferralsComponent',
    'STEP-2_3': 'CreditTransferSummaryComponent',
    'STEP-3_1': 'ComplimentaryComplianceReportStepComponent',
    'STEP-3_2': 'CcpUseOfErfpCreditsComponent',
    'STEP-3_3': 'CreditsTransferTowardsDeferralsComponent',
    'STEP-3_4': 'CreditTransferSummaryComponent',
    'STEP-4_1': 'CcpDeferralsDetailsComponent',
    'STEP-4_2': 'CcpDeferralsDetailsComponent',
    'STEP-5': 'ComplimentaryComplianceReportDocumentsComponent',
    'STEP-6': 'CcpReportContactPersonComponent',
    'STEP-7': 'ComplimentaryComplianceReportSummaryComponent',
};
export const COMPLIMENTARY_COMPLIANCE_REPORT_ORGANIZATION_NAME_FLD = 'accountId';
export const complimentaryComplianceReportApplyPrefix = (value: string, subPrefix: string = ''): string => `${COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX}${subPrefix}.${value}`;
export const COMPLIMENTARY_COMPLIANCE_REPORT_CREATE_POPUP = '.createReport';
export const COMPLIMENTARY_COMPLIANCE_REPORT_PERIOD_FLD = 'compliancePeriod';

export const COMP_COMPLIANCE_REPORT_NUMBER_MASK = 'separator.5';
export const COMP_REP_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'gasolineVolumetricRequirement';
export const COMP_REP_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'disealVolumetricRequirement';
export const COMP_REP_REDUCTION_REQUIREMENT_FLD = 'reductionRequirement';
export const COMP_REP_CURRENT_DISEAL_REDUCTION_REQUIREMENT_FLD = 'currentYearDisealReductionRequirement';
export const COMP_REP_CURRENT_GASOLINE_REDUCTION_REQUIREMENT_FLD = 'currentYearGasolineReductionRequirement';
export const COMP_REP_GASOLINE_VOL_OVERRIDEN_REQ_FLD = 'overrideGasolineVolumetricRequirement';
export const COMP_REP_DIESEL_VOL_OVERRIDEN_REQ_FLD = 'overrideDisealVolumetricRequirement';
export const COMP_REP_DIESEL_VOL_OVERRIDEN_TOOLTIP_REQ_FLD = 'overrideDisealVolReqTooltip';
export const COMP_REP_RED_OVERRIDEN_REQ_FLD = 'overrideReductionRequirement';
export const COMP_REP_CURRENT_DISEAL_OVERRIDEN_RED_REQ_FLD = 'overrideCurrentYearDisealReductionRequirement';
export const COMP_REP_CURRENT_GASOLINE_OVERRIDEN_RED_REQ_FLD = 'overrideCurrentYearGasolineReductionRequirement';
export const COMP_UNSATISFIED_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'outstandingGasolineVolumetricRequirement';
export const COMP_UNSATISFIED_GASOLINE_VOLUMETRIC_REQUIREMENT_TOOLTIP_FLD = 'outstandingGasolineVolumetricRequirementTooltip';
export const COMP_UNSATISFIED_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'outstandingDisealVolumetricRequirement';
export const COMP_UNSATISFIED_DIESEL_VOLUMETRIC_REQUIREMENT_TOOTLTIP_FLD = 'outstandingDisealVolumetricRequirementTooltip';
export const COMP_UNSATISFIED_CURRENT_REDUCTION_REQUIREMENT_FLD = 'outstandingReductionRequirement';
export const COMP_UNSATISFIED_CURRENT_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'outstandingDisealReductionRequirement';
export const COMP_UNSATISFIED_CURRENT_DIESEL_VOLUMETRIC_REQUIREMENT_TOOLTIP_FLD = 'outstandingDisealReductionRequirementTooltip';
export const COMP_UNSATISFIED_CURRENT_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'outstandingGasolineReductionRequirement';
export const COMP_UNSATISFIED_CURRENT_GASOLINE_VOLUMETRIC_REQUIREMENT_TOOLTIP_FLD = 'outstandingGasolineReductionRequirementTooltip';
export const COMP_UNSATISFIED_GASOLINE_VOL_OVERRIDEN_REQ_FLD = 'overrideOutstandingGasolineVolumetricRequirement';
export const COMP_UNSATISFIED_GASOLINE_VOL_OVERRIDEN_REQ_TOOLTIP = 'overrideOutstandingGasVolReqTooltip';
export const COMP_UNSATISFIED_DIESEL_VOL_OVERRIDEN_REQ_FLD = 'overrideOutstandingDisealVolumetricRequirement';
export const COMP_UNSATISFIED_DIESEL_VOL_OVERRIDEN_REQ_TOOLTIP = 'overrideOutstandingDisealVolReqTooltip';
export const COMP_UNSATISFIED_CURRENT_RED_OVERRIDEN_REQ_FLD = 'overrideOutstandingReductionRequirement';
export const COMP_UNSATISFIED_CURRENT_DIESEL_RED_OVERRIDEN_REQ_FLD = 'overrideOutstandingDisealReductionRequirement';
export const COMP_UNSATISFIED_CURRENT_DIESEL_RED_OVERRIDEN_REQ_TOOLTIP = 'overrideOutstandingDisealRedReqTooltip';
export const COMP_UNSATISFIED_CURRENT_GASOLINE_RED_OVERRIDEN_REQ_FLD = 'overrideOutstandingGasolineReductionRequirement';
export const COMP_UNSATISFIED_CURRENT_GASOLINE_RED_OVERRIDEN_REQ_TOOLTIP = 'overrideOutstandingGasolineRedReqTooltip';
export const COMP_TOTAL_REDUCTION_REQUIREMENT_FLD = 'calculatedTotalReductionRequirement';
export const COMP_TOTAL_UNSATISFIED_REDUCTION_REQUIREMENT_FLD = 'calculatedTotalOutstandingReductionRequirement';
export const COMP_TOTAL_OVERRIDDEN_RED_REQ_FLD = 'overrideCalculatedTotalReductionRequirement';
export const COMP_TOTAL_UNSATISFIED_OVERRIDDEN_RED_REQ_FLD = 'overrideCalculatedTotalOutstandingReductionRequirement';
export const COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX = `${ COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX }.table`;
export const COMP_REP_STEP_2_1_SECTION_TITLE = 'section2_1_Title';
export const COMP_REP_STEP_2_1_GASOLINE_TABLE_TITLE = 'section2_1_Table__Title_G';
export const COMP_REP_STEP_2_1_DIESEL_TABLE_TITLE = 'section2_1_Table_Title_D';
export const COMP_REP_STEP_2_1_GASOLINE_TABLE_TITLE_INFO = 'section2_1_Table_Title_Info_G';
export const COMP_REP_STEP_2_1_DIESEL_TABLE_TITLE_INFO = 'section2_1_Table_Title_Info_G';
export const COMP_REP_CREDITS_USED_GASOLINE_TOWARD_REQUIREMENTS_TABLE_ID = 'creditsUsedTowardRequirementsTableG';
export const COMP_REP_CREDITS_USED_DIESEL_TOWARD_REQUIREMENTS_TABLE_ID = 'creditsUsedTowardRequirementsTableD';
export const COMP_REP_CREDIT_USED_FOR_GASOLINE_COMPLIANCE_CREDIT_FLD = 'gasolineQtyCCMSatisfied';
export const COMP_REP_CREDIT_USED_FOR_DIESEL_COMPLIANCE_CREDIT_FLD = 'dieselQtyCCMSatisfied';
export const COMP_REP_CREDIT_USE_SUMMARY_TITLE = 'creditUseSummaryTitle';
export const COMP_REP_CREDIT_USE_SUMMARY_SUBTITLE = 'creditUseSummarySubTitle';
export const COMP_REP_CREDITS_USED_SATISFY_CURRENT_RED_REQ = 'creditUseSummaryCurrentRedReq';
export const COMP_REP_CREDITS_USED_SATISFY_RED_REQ = 'creditUseSummaryRedReq';
export const COMP_REP_CCM_SUMMARY_GASOLINE_VOL = 'ccmSummaryGasolineVol';
export const COMP_REP_CCM_SUMMARY_DIESEL_VOL = 'ccmSummaryDieselVol';
export const COMP_REP_SERIAL_NUMBER_FLD = 'serialNumber';
export const COMP_REP_COMPLIANCE_PERIOD_FLD = 'complianceYear';
export const COMP_REP_TYPE_OF_CREDITS_FLD = 'creditClass';
export const COMP_REP_CREDIT_GROUP_FLD = 'creditGroupName';
export const COMP_REP_CREDIT_TYPE_CODE_FLD = 'creditTypeCode';
export const COMP_REP_FUEL_TYPE_FLD = 'fuelType';
export const COMP_REP_QUANTITY_OF_CREDITS_FLD = 'quantity';
export const COMP_REP_STATUS_FLD = 'status';
export const COMP_REP_ASSOCIATED_VOLUME_FLD = 'associatedVolume';
export const COMP_REP_ASSOCIATED_VOLUME_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX }.associatedVolumeTooltip`;
export const COMP_REP_CARBON_INTENSITY_VALUE = 'finalCi';
export const COMP_REP_CARBON_INTENSITY_FLD = 'carbonIntensityFuel';
export const COMP_REP_CARBON_INTENSITY_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX }.carbonIntensityTooltip`;
export const COMP_REP_CI_ALPHA_ID_FLD = 'alphanumericId';
export const COMP_REP_SERIAL_NUMBER_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX }.serialNumberTooltip`;
export const COMP_REP_COMP_PERIOD_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX }.compliancePeriodTooltip`;
export const COMP_REP_QUANTITY_TO_SATISFY = 'quantityToSatisfy';
export const COMPLIANCE_REPORT_PREFIX = 'COMPLIANCE_REPORT';
export const COMPLIANCE_REPORT_TABLE_PREFIX = `${ COMPLIANCE_REPORT_PREFIX }.table`;
export const COMP_REP_CREDIT_GROUP_ERPF_FLD = 'creditGroupName';
export const COMP_REP_TYPE_OF_CREDITS_ERPF_FLD = 'creditClass';
export const COMP_REP_QUANTITY_OF_CREDITS_ERPF_FLD = 'numberOfCompCreditsProvisionallyCreated';
export const COMP_CURRENT_YEAR_UNSATISFIED_DIESEL_REQ = 'outstandingDiselReductionRequirement';
export const COMP_MAX_CURRENT_CREDITS_DEFERRED = 'maxCurrentCreditsDeferred';
export const COMP_GASOLINE_CURRENT_CREDITS_DEFERRED = 'deferredOutstandingGasolineReductionRequirement';
export const COMP_DIESEL_CURRENT_CREDITS_DEFERRED = 'deferredOutstandingDieselReductionRequirement';
export const COMP_GASOLINE_CURRENT_CREDITS_DEFERRED_TOOLTIP = 'deferredOutstandingGasolineReductionRequirementTooltip';
export const COMP_DIESEL_CURRENT_CREDITS_DEFERRED_TOOLTIP = 'deferredOutstandingDieselReductionRequirementTooltip';
export const COMP_CURRENT_YEAR_DEFERRED_PORTION = 'currentYearDeferredPortion';
export const COMP_OUTSTANDING_OVER_TEN_PERCENT_PORTION = 'outstandingOverTenPercent';
export const COMP_OUTSTANDING_GASOLINE_OVER_TEN_PERCENT_PORTION = 'outstandingGasolineOverTenPercent';
export const COMP_OUTSTANDING_DIESEL_OVER_TEN_PERCENT_PORTION = 'outstandingDieselOverTenPercent';
export const COMP_OUTSTANDING_FROM_SIXTH_CY = 'outstandingFromSixthCY';
export const COMP_OUTSTANDING_GASOLINE_FROM_SIXTH_CY = 'outstandingGasolineFromSixthCY';
export const COMP_OUTSTANDING_DIESEL_FROM_SIXTH_CY = 'outstandingDieselFromSixthCY';
export const COMP_REP_MAX_TEN_PERCENT_CREDITS = 'maxNumberOfComplianceCreditsCreatedByContributorComplimentaryRep';

// STEP table row data
export const COMP_REP_INVOLVED_IN_ACTIVITIES = 'involvedInActivities';
export const COMP_REP_ENGAGE_IN_ACTIVITIES = 'engageInActivities';
export const COMP_REP_CONTRIBUTION_AMOUNT_FLD = 'contributionAmount';
export const COMP_REP_FUEL_QUANTITY_FLD = 'quantityOfFuel';
export const COMP_REP_PROVISIONAL_COMP_CREDIT_FLD = 'numberOfCompCreditsProvisionallyCreated';
export const COMP_REP_STEP_3_1_DOC_LABEL = 'step_3_1_doc_Label';
export const COMP_REP_STEP_3_1_DOC_TOOLTIP = 'step_3_1_doc_tooltip';
export const COMP_REP_TOTAL_ERFP = 'totalERFP';
export const COMP_REP_TOTAL_ERFP_USED_BY_JULY = 'totalCreditsToCancelForExportStep';
export const COMP_REP_TOTAL_ERFP_CURRENT_YEAR = 'totalERFPCurrentYear';
export const COMP_REP_TOTAL_SUBTRACTIONS_FROM_POOL_FLD = 'totalSubtractionsFromPool';
export const COMP_REP_PROVISIONAL_COMP_CREDIT_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX }${ COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX }.numberOfCompCreditsProvisionallyCreatedTooltip`;
export const COMPLIANCE_REPORT_AMOUNT_MASK = 'separator.2';
export const COMP_REP_CONTRIBUTION_AMOUNT_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX }${ COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX }.contributionAmountTooltip`;
export const COMP_REP_ERFP_FLD = 'erf.id';
export const COMP_REP_ERFP_NAMES_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX }${ COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX }.erfpNameTooltip`;
export const COMP_REP_ERFP_LABEL = 'erfpName';
export const COMP_REP_ERFP_PRICE_MAPPING_LABEL = 'erfPriceMapping';
export const COMP_REP_ERFP_PRICE_MAPPING_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX }${ COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX }.erfPriceMappingTooltip`;
export const COMP_REP_ERFP_PRICE_MAPPING_FLD = 'erfPriceMapping.cadConversion';
export const COMP_REP_STEP_REQUIRED_UNITS_FLD = 'requiredUnits';
export const COMP_ERFP_DATE_OF_CONTRIBUTION = 'contributionDate';
export const COMP_REP_ERFP_SUMMARY_TITLE = 'erfpSummaryTitle';
export const COMP_REP_ERFP_SUMMARY_SUBTITLE = 'erfpSummarySubTitle';
export const COMP_REP_ERFP_DEFERRAL_SUMMARY_TITLE = 'erfpDeferralSummary';
export const COMP_REP_ERFP_SUMMARY_CREDITS_USED_SATISFY_RED_REQ = 'erfpCreditsSatifyCurrentReq';
export const COMP_REP_ERFP_SUMMARY_CREDITS_USED_SATISFY_CURRENT_RED_REQ = 'erfpCreditsSatisfyReq';
export const COMP_REP_UNSATISFIED_GASOLINE_DEFERRAL = 'calcOutstandingGasolineReductionRequirement';
export const COMP_REP_UNSATISFIED_DIESEL_DEFERRAL = 'calcOutstandingDieselReductionRequirement';
export const COMP_REP_UNSATISFIED_GASOLINE_DEFERRAL_LABEL = 'STEP-4_1_outstandingGasolineRedReq';
export const COMP_REP_UNSATISFIED_DIESEL_DEFERRAL_LABEL = 'STEP-4_1_outstandingDieselRedReq';
export const COMP_INITIAL_RED_REQ_DEFERRAL_TOOLTIP = `${ COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX }.initialReductionRequirementDeferralTooltip`;

export const deferralDetailsTableConfig: ITableViewConfig = {
    translationPrefix: `${COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX}${COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX}`,
    paginator: false,
    columns: [
      {
        field: 'complianceYear',
        header: 'complianceYear',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      {
        field: 'initialReductionRequirement',
        header: 'deferredPortion',
        width: AUTO_WIDTH_FOR_COLUMNS,
        toolTip: {
          text: COMP_INITIAL_RED_REQ_DEFERRAL_TOOLTIP,
          class: 'tooltip-class'
        }
      },
      {
        field: 'initialOutstandingReductionRequirement',
        header: 'unsatisfiedDeferredPortion',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
    ],
  };

  export const expandedDeferralDetailsTableConfig: ITableViewConfig = {
    translationPrefix: `${COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX}${COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX}`,
    paginator: false,
    columns: [
      {
        field: 'fuelComplianceYear',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      {
        field: 'reductionRequirement',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      {
        field: 'outstandingReductionRequirement',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
    ],
  };

  export const overrideDeferralDetailsTableConfig: ITableViewConfig = {
    translationPrefix: `${COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX}${COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX}`,
    paginator: false,
    columns: [
      {
        field: 'complianceYear',
        header: 'complianceYear',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      {
        field: 'overrideReductionRequirement',
        header: 'deferredPortion',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
      {
        field: 'overrideOutstandingReductionRequirement',
        header: 'unsatisfiedDeferredPortion',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
    ],
  };

  export const expandedOverrideDeferralDetailsTableConfig: ITableViewConfig = {
    translationPrefix: `${COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX}${COMPLIMENTARY_COMPLIANCE_REPORT_STEP_PREFIX}`,
    paginator: false,
    columns: [
      {
        field: 'fuelComplianceYear',
        width: AUTO_WIDTH_FOR_COLUMNS,
      },
    ],
  };

  export const COMP_REP_CREDITS_USED_TOWARD_REQUIREMENTS_TABLE_CONFIG: ITableViewConfig = {
    translationPrefix: COMPLIANCE_REPORT_TABLE_PREFIX,
    paginator: true,
    rowsPerPage: 10,
    columns: [
      {
        checkboxSelect: true,
        width: '3em',
      },
      {
        field: COMP_REP_SERIAL_NUMBER_FLD,
        sortField: COMP_REP_SERIAL_NUMBER_FLD,
        header: COMP_REP_SERIAL_NUMBER_FLD,
        toolTip: {
          text: COMP_REP_SERIAL_NUMBER_TOOLTIP,
        },
      },
      {
        field: COMP_REP_COMPLIANCE_PERIOD_FLD,
        sortField: COMP_REP_COMPLIANCE_PERIOD_FLD,
        header: COMP_REP_COMPLIANCE_PERIOD_FLD,
        toolTip: {
          text: COMP_REP_COMP_PERIOD_TOOLTIP,
        },
      },
      
      {
        field: COMP_REP_CREDIT_TYPE_CODE_FLD,
        sortField: COMP_REP_TYPE_OF_CREDITS_FLD,
        header: COMP_REP_TYPE_OF_CREDITS_FLD,
        translate: true,
        translationPrefix: 'COMMON.creditClassList',
      },
      {
        field: COMP_REP_CREDIT_GROUP_FLD,
        sortField: COMP_REP_CREDIT_GROUP_FLD,
        header: COMP_REP_CREDIT_GROUP_FLD,
        translate: true,
        translationPrefix: 'COMMON.creditGroupList',
      },
      {
        field: COMP_REP_FUEL_TYPE_FLD,
        sortField: COMP_REP_FUEL_TYPE_FLD,
        header: COMP_REP_FUEL_TYPE_FLD,
      },
      {
        field: COMP_REP_QUANTITY_OF_CREDITS_FLD,
        sortField: COMP_REP_QUANTITY_OF_CREDITS_FLD,
        header: COMP_REP_QUANTITY_OF_CREDITS_FLD,
        numeric: true,
      },
      {
        field: COMP_REP_STATUS_FLD,
        sortField: COMP_REP_STATUS_FLD,
        header: COMP_REP_STATUS_FLD,
        translate: true,
        translationPrefix: 'COMMON.statusList',
      },
      {
        field: COMP_REP_ASSOCIATED_VOLUME_FLD,
        sortField: COMP_REP_ASSOCIATED_VOLUME_FLD,
        header: COMP_REP_ASSOCIATED_VOLUME_FLD,
        numeric: true,
        toolTip: {
          text: COMP_REP_ASSOCIATED_VOLUME_TOOLTIP,
        },
      },
      {
        field: COMP_REP_CARBON_INTENSITY_VALUE,
        sortField: COMP_REP_CARBON_INTENSITY_FLD,
        header: COMP_REP_CARBON_INTENSITY_FLD,
        numeric: true,
        toolTip: {
          text: COMP_REP_CARBON_INTENSITY_TOOLTIP
        },
      },
      {
        field: COMP_REP_CI_ALPHA_ID_FLD,
        sortField: COMP_REP_CI_ALPHA_ID_FLD,
        header: COMP_REP_CI_ALPHA_ID_FLD,
      },
    ],
};

export const deferralsSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIMENTARY_COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'requirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'requirementQuantity',
      header: 'reductionRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.0-0',
    },
    {
      field: 'complianceCredits',
      header: 'complianceCreditsAssociated',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
    {
      field: 'remaining',
      header: 'unsatisfiedRequirementsReductions',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
  ],
};

export const deferralsSummaryMainTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIMENTARY_COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'requirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'requirementQuantity',
      header: 'reductionRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.0-0',
    },
    {
      field: 'complianceCredits',
      header: 'complianceCreditsAssociated',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
    {
      field: 'remaining2',
      header: 'unsatisfiedRequirementsReductions',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
  ],
};

export const erpfDeferralsSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIMENTARY_COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'requirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'requirementQuantity',
      header: 'reductionRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.0-0',
    },
    {
      field: 'complianceCredits',
      header: 'erfpCreditsAssociated',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
    {
      field: 'remaining',
      header: 'unsatisfiedRequirementsReductions',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
  ],
};

export const COMP_REP_ERPF_CREDITS_USED_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: COMPLIMENTARY_COMPLIANCE_REPORT_TABLE_PREFIX,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: COMP_REP_CREDIT_GROUP_ERPF_FLD,
      sortField: COMP_REP_CREDIT_GROUP_ERPF_FLD,
      header: COMP_REP_CREDIT_GROUP_ERPF_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditGroupList',
    },
    {
      field: COMP_REP_TYPE_OF_CREDITS_ERPF_FLD,
      sortField: COMP_REP_TYPE_OF_CREDITS_ERPF_FLD,
      header: COMP_REP_TYPE_OF_CREDITS_ERPF_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditClassList',
    },
    {
      field: COMP_REP_QUANTITY_OF_CREDITS_ERPF_FLD,
      sortField: COMP_REP_QUANTITY_OF_CREDITS_ERPF_FLD,
      header: COMP_REP_QUANTITY_OF_CREDITS_ERPF_FLD,
      numeric: true,
    }
  ],
};

export const reductionSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIMENTARY_COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'currentYearRequirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'requirementQuantity',
      header: 'currentYearReductionRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.0-0',
    },
    {
      field: 'complianceCredits',
      header: 'currentYearComplianceCreditsAssociated',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
    {
      field: 'remaining',
      header: 'currentYearUnsatisfiedRequirementsReductions',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
  ],
};

export const volumetricSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIMENTARY_COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'currentYearRequirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'requirementQuantity',
      header: 'volumetricRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.3-3',
    },
    {
      field: 'volume',
      header: 'volumeAssociated',
      numeric: true,
      format: '1.3-3',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'remaining',
      header: 'unsatisfiedRequirements',
      numeric: true,
      format: '1.3-3',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const creditUsedTowardLimitTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIMENTARY_COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'limitType',
      header: 'limitType',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMPLIANCE_REPORT.summaryStep'
    },
    {
      field: 'limit',
      header: 'limit',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditForLimits',
      header: 'creditUsedTowardLimit',
      numeric: true,
      format: '1.0-0',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};