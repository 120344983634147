import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as moment from 'moment';

@Component({
  selector: 'app-formly-datepicker',
  template: `
  <ng-container *ngIf="to.tooltip">
    <fa-icon
      pTooltip="{{ to.tooltip | translate }}"
      tooltipPosition="top"
      tooltipStyleClass="tooltip-min-width"
      [icon]="['fas', 'info-circle']">
    </fa-icon>
  </ng-container>
    <p-calendar
      [appendTo]="to.appendTo"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [id]="id + '_calendar'"
      [inputId]="elementId"
      [inputStyleClass]="'col-12'"
      [maxDate]="maxDate"
      [minDate]="minDate"
      [placeholder]="to.placeholder"
      [styleClass]="'col-12 p-0'"
      (keydown)="keydown($event)"
      (onClose)="onClose($event)"
      (onFocus)="onFocus($event)"
      #calendar
      dateFormat="yy-mm-dd"
    >
    </p-calendar>
  `,
  styleUrls: ['./formly-datepicker.component.scss'],
})
export class FormlyDatepickerComponent extends FieldType implements OnInit {
  private currEl: HTMLElement;
  private lastFocusedEl: HTMLElement;
  private keyboardTriggered = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initDefaultValueSub();
  }

  public get minDate(): Date {
    return this.interpretDate('minDate');
  }

  public get maxDate(): Date {
    return this.interpretDate('maxDate');
  }

  private interpretDate(type: string): Date {
    if (this.to[type]) {
      if (typeof this.to[type] === 'string') {
        if (!isNaN(Date.parse(this.to[type]))) {
          return new Date(this.to[type]);
        }
        const field = this.to[type].split('.').pop();
        return typeof this.model[field] === 'object' ? this.model[field] : new Date(this.model[field]);
      }
      return this.to[type];
    }
  }

  onFocus(event) {
    this.currEl = event.target;
    this.lastFocusedEl = event.relatedTarget;
  }

  onClose(event) {
    if (this.keyboardTriggered) {
      this.keyboardTriggered = false;
      const formElements = [...this.currEl['form'].elements].filter(
        el => el.type !== 'hidden' && !el.className.includes('wcag-visuallyhidden') && el.tagName !== 'FIELDSET',
      );
      const currId = formElements.findIndex(el => el.id === this.currEl.id);
      const prevId = this.lastFocusedEl && this.lastFocusedEl.id ? formElements.findIndex(el => el.id === this.lastFocusedEl.id) : -1;
      const nextEl = formElements[currId + (prevId < currId ? 1 : -1)];
      if (nextEl) {
        nextEl.focus();
      }
    }
    this.to.change && this.to.change(this.field, event);
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  keydown(event) {
    this.keyboardTriggered = true;
  }

  private initDefaultValueSub(): void {
    const { defaultDate } = this.to;

    if (!defaultDate) {
      return;
    }

    const date = typeof defaultDate === 'string' ? defaultDate : moment(defaultDate).format('YYYY-MM-DD');
    this.formControl.setValue(date);
  }
}
