import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';
import { ENTITIES_REVIEW_ACCESS_REQUEST } from '@shared/components/task-details/task-details.const';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { IApp } from '@core/models/app.interfaces';

@Component({
  selector: 'app-entity-history-list-modal',
  templateUrl: './entity-history-list-modal.component.html',
  styleUrls: ['./entity-history-list-modal.component.scss']
})
export class EntityHistoryListModalComponent implements OnInit {
  config = ENTITIES_REVIEW_ACCESS_REQUEST;
  dataList: any[] = [];
  totalElements: number = -1;
  selections = [];
  editMode: boolean;
  header: string;
  public firstItemIndex = 0;
  private pagination = {
    page: 0,
    size: ROWS_PER_PAGE,
  } as IApp.IPagination;

  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<EntityHistoryListModalComponent>,
    private router: Router,
    private helperService: HelperService,
    private submissionService: SubmissionManagementService,
  ) { }

  ngOnInit() {
    this.header = this.data.header ? this.data.header : 'VERIFICATION_MANAGEMENT_MODULE.reportList.vbReviewAccessRequestListTitle';
    this.editMode = this.data.editMode;
    const _columns = [
      ...(this.editMode ? [{checkboxSelect: true, width: '3em'}] : []),
      ...this.config.columns,
      {
        field: 'view',
        header: 'view',
        templateRef: this.viewColTemplate,
      }
    ]

    this.config = {
      ...this.config,
      ...{ columns: _columns }
    };

    this.loadData();
  }

  loadData() {
    const serviceCallback = this.data.serviceCallback ? this.data.serviceCallback : 'getEntitiesByVerificationId';
    this.submissionService[serviceCallback](this.pagination, this.data.entityId)
      .subscribe(result => {
        this.dataList = result || { content: [] };
        this.dataList['content'].forEach(item => item['id'] = item.entityId);
        this.totalElements = this.dataList['content'].length;
      });
  }

  selectionChanged(selections) {
    this.selections = selections;
  }

  view(ev, data) {
    this.close();

    if(data.entityType && data.entityType === 'ANNUAL_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/credit-creation-reports/report/${ data.entityId }`));
    } else if ((data.entityName && data.entityName === 'COMPLIANCE_REPORT') || (data.entityType && data.entityType === 'COMPLIANCE_REPORT') ) {
      this.router.navigate(this.helperService.getTranslatedPath(`/compliance-report/report/${ data.entityId }`));
    } else if (data.entityType && data.entityType === 'ADJUSTMENT_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/credit-adjustment-reports/report/${ data.entityId }`));
    } else if (data.entityType && data.entityType === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/complimentary-compliance-report/report/${ data.entityId }`));
    } else if (data.entityType && data.entityType === 'CREDIT_BALANCE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/credit-balance/report/${ data.entityId }`));
    } else if (data.entityType && data.entityType === 'COMPLIANCE_CREDIT_REVENUE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/credit-revenue/report/${ data.entityId }`));
    } else {
      this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/wizard/application/${ data.entityId }`));
    }
  }

  approve() {
    const payload = {
      action: 'VB_APPROVE_ENTITY_ACCESS',
      id: this.data.entityId,
      vbAccessProvidedEntities: this.selections
    };
    this.submissionService.processEntityAccess(payload).subscribe(result => {
      this.close(true);
    });
  }

  reject() {
    const payload = { action: 'VB_REJECT_ENTITY_ACCESS', id: this.data.entityId };
    this.submissionService.processEntityAccess(payload).subscribe(result => {
      this.close(true);
    });
  }

  close(valueChange = false): void {
    this.dialogRef.close(valueChange);
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadData();
  }

}
