/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-builder-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../project-description/project-description.component.ngfactory";
import * as i3 from "../project-description/project-description.component";
import * as i4 from "../../../services/form-factory.service";
import * as i5 from "../project-builder.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/router";
import * as i8 from "../project-activity-selector/project-activity-selector.component.ngfactory";
import * as i9 from "../project-activity-selector/project-activity-selector.component";
import * as i10 from "../project-summary/project-summary.component.ngfactory";
import * as i11 from "../project-summary/project-summary.component";
import * as i12 from "@angular/common";
import * as i13 from "../../../pipes/custom-translate.pipe";
import * as i14 from "./project-builder-wrapper.component";
import * as i15 from "../../../services/project.service";
import * as i16 from "../../../services/document-in-progress.service";
import * as i17 from "../../../services/modal.service";
import * as i18 from "../../../../core/store/store.service";
import * as i19 from "../../../services/navigation.service";
var styles_ProjectBuilderWrapperComponent = [i0.styles];
var RenderType_ProjectBuilderWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectBuilderWrapperComponent, data: {} });
export { RenderType_ProjectBuilderWrapperComponent as RenderType_ProjectBuilderWrapperComponent };
function View_ProjectBuilderWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "row mt-3 mr-60"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-3 border-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "inner-panel-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-project-description", [], null, null, null, i2.View_ProjectDescriptionComponent_0, i2.RenderType_ProjectDescriptionComponent)), i1.ɵdid(4, 245760, null, 0, i3.ProjectDescriptionComponent, [i4.FormFactoryService, i5.ProjectBuilderService, i6.TranslateService, i7.ActivatedRoute], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-6 border-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "inner-panel-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-project-activity-selector", [], null, null, null, i8.View_ProjectActivitySelectorComponent_0, i8.RenderType_ProjectActivitySelectorComponent)), i1.ɵdid(8, 114688, null, 0, i9.ProjectActivitySelectorComponent, [i5.ProjectBuilderService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-project-summary", [], null, null, null, i10.View_ProjectSummaryComponent_0, i10.RenderType_ProjectSummaryComponent)), i1.ɵdid(11, 114688, null, 0, i11.ProjectSummaryComponent, [i5.ProjectBuilderService], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 8, 0); _ck(_v, 11, 0); }, null); }
function View_ProjectBuilderWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn ml-2 btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.documentUploadInProgress.inProgress$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("PROJECTS_MODULE.ProjectBuilder.saveProject")); _ck(_v, 3, 0, currVal_1); }); }
function View_ProjectBuilderWrapperComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.documentUploadInProgress.inProgress$)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.ProjectBuilder.submitProject")); _ck(_v, 2, 0, currVal_1); }); }
function View_ProjectBuilderWrapperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn mx-2 btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveAsDraft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_5)), i1.ɵdid(6, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.editMode; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.documentUploadInProgress.inProgress$)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("PROJECTS_MODULE.ProjectBuilder.saveProject")); _ck(_v, 3, 0, currVal_1); }); }
function View_ProjectBuilderWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_4)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.projectBuilderService.disabled; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProjectBuilderWrapperComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn ml-2 btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveAsDraft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.documentUploadInProgress.inProgress$)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.ProjectBuilder.saveProject")); _ck(_v, 2, 0, currVal_1); }); }
function View_ProjectBuilderWrapperComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-primary ml-2"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerAction(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.documentUploadInProgress.inProgress$)); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(("PROJECTS_MODULE.ProjectBuilder." + _v.parent.context.$implicit.workflowAction))); _ck(_v, 2, 0, currVal_1); }); }
function View_ProjectBuilderWrapperComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_8)), i1.ɵdid(2, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["ACTIONS", 2]], null, 0, null, View_ProjectBuilderWrapperComponent_9))], function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.workflowAction === "EDIT") || (_v.context.$implicit.workflowAction === "EDIT_CUST")); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ProjectBuilderWrapperComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_7)), i1.ɵdid(1, 278528, null, 0, i12.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectBuilderService.model.entityActionList; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProjectBuilderWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "activity-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row border-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h2", [["class", "m-0 pb-2"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_1)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "row button-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 9, "div", [["class", "button-background fixed-right mr-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i12.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵpid(131072, i13.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_2)), i1.ɵdid(15, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectBuilderWrapperComponent_3)), i1.ɵdid(17, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["ENTITY_ACTIONS", 2]], null, 0, null, View_ProjectBuilderWrapperComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.viewReady; _ck(_v, 7, 0, currVal_1); var currVal_4 = _co.projectBuilderService.updateProject; _ck(_v, 15, 0, currVal_4); var currVal_5 = !_co.projectBuilderService.model.entityActionList; var currVal_6 = i1.ɵnov(_v, 18); _ck(_v, 17, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(("PROJECTS_MODULE.ProjectBuilder.projectApplicationForm" + _co.projectBuilderService.projectType))); _ck(_v, 4, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform(_co.documentUploadInProgress.inProgress$)); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("PROJECTS_MODULE.ProjectBuilder.back")); _ck(_v, 12, 0, currVal_3); }); }
export function View_ProjectBuilderWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-builder-wrapper", [], null, null, null, View_ProjectBuilderWrapperComponent_0, RenderType_ProjectBuilderWrapperComponent)), i1.ɵdid(1, 245760, null, 0, i14.ProjectBuilderWrapperComponent, [i12.Location, i7.ActivatedRoute, i5.ProjectBuilderService, i15.ProjectService, i16.DocumentUploadInProgressService, i17.ModalService, i18.StoreService, i6.TranslateService, i19.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectBuilderWrapperComponentNgFactory = i1.ɵccf("app-project-builder-wrapper", i14.ProjectBuilderWrapperComponent, View_ProjectBuilderWrapperComponent_Host_0, {}, {}, []);
export { ProjectBuilderWrapperComponentNgFactory as ProjectBuilderWrapperComponentNgFactory };
