import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { takeUntil } from 'rxjs/operators';
import { IApp } from '@core/models/app.interfaces';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-account-registration',
  templateUrl: './account-registration.component.html',
  styleUrls: ['./account-registration.component.scss'],
})
export class AccountRegistrationComponent implements OnInit {

  public type;
  public accountId;

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.type = params['type'];
      this.accountId = params['accountId'];
    });
  }

  get lang(): string {
    return this.translate.currentLang;
  }

}
