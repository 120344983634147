import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IApp } from '@core/models/app.interfaces';
import { IRawFormBase } from '@core/models/raw-form.types';
import { LookupService } from '@core/services/lookup.service';
import EntityTypeEnum = IApp.NSDocument.EntityTypeEnum;
import { getDurationInDaysMonthAndYear } from '@core/utilities/utilities.constants';
import {
  ICreditCreationReport,
  ICreditCreationReportGetAlphNumCI,
} from '@module/credit-creation-reports/credit-creation-reports.types';
import {
  getAlphanumericIDofCI,
  getApprovedCIofLoCIFuel,
} from '@module/credit-creation-reports/routines/create-credit-report.routine';
import {
  CCR_PROJECT_ANTICIPATED_END_DATE,
  CCR_PROJECT_ANTICIPATED_START_DATE,
  CCR_PROJECT_CONTACT_PERSON,
  CCR_PROJECT_CREDITING_START_DATE,
  CCR_PROJECT_DURATION,
  getAnticipatedEndDate,
  getAnticipatedStartDate,
  getCarbonIntensity,
  getEntityByProp,
  getFuelType,
  getProjectCreditingEndDate,
  getProjectDuration,
  getProjectId,
  getProjectName,
  getProjectType,
  getQuantificationMethod,
  getRegulatoryRegime,
} from '@module/credit-creation-reports/routines/project-details-popup.routine';
import { CreditCreationReportsService } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { IDocument } from '@module/registration/registration.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ERP_CO_PROCESSING_PROJECT_FUELS,
  ERP_PROJECT_TYPE,
  ERP_QUANTIFICATION_METHOD_FLD,
} from '@shared/components/steps/constants/erp-application.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { uniqBy } from 'lodash';
import { of, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { takeUntil, tap } from 'rxjs/operators';
import {
  CCR_ALPHANUMERIC_CI_FLD,
  CCR_CI_ID_FLD,
  CCR_CI_LIQUID_CI_FUEL_FLD, CCR_DATE_ONLY_FORMAT, CCR_EDITED_PROJECT_DETAILS
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { IProject } from '@shared/services/project.service.types';
import * as moment from 'moment-timezone';
import { Dialog } from 'primeng';

@Component({
  selector: 'app-project-details-form',
  templateUrl: './project-details-form.component.html',
  styleUrls: ['./project-details-form.component.scss'],
})
export class ProjectDetailsFormComponent implements OnInit, OnDestroy {

  @Input() form = new FormGroup({});
  @Input() masterProject: any;
  @Output() modelIdUpdate = new EventEmitter();

  @ViewChild('dialog', { static: false }) dialog: Dialog;

  public ready = false;

  private _fuels$ = new BehaviorSubject([]);
  public fuels$ = this._fuels$.asObservable();
  private _quantificationMethods$ = new BehaviorSubject([]);
  public quantificationMethods$ = this._quantificationMethods$.asObservable();
  public currentQuantificationMethod: any;

  public alphanumericIDofCI$ = new BehaviorSubject([]);

  documents: IDocument[];
  documentUploadConfig: IApp.IDocumentUploadConfig;
  isDocumentUploadInProgress: boolean;

  report: ICreditCreationReport;

  public registrationReport: any;
  public model: any = {};
  public fields: FormlyFieldConfig[];
  public regulatoryRegimeFields: FormlyFieldConfig[];

  public extraForm = new FormGroup({});
  public extraModel: any = {};
  public extraFields: FormlyFieldConfig[];

  public visible = true;
  private _destroy$ = new Subject<any>();

  public rgHeaders = [
    {
    text: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.regulatoryRegime',
    class: 'col-5 text-center p-2',
    },
    {
    text: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.description',
    class: 'col-5 text-center p-2',
    },
  ];

  public projectId;
  public currentStepObj;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ProjectDetailsFormComponent>,
    private service: CreditCreationReportsService,
    private formFactoryService: FormFactoryService,
    private lookupService: LookupService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.registrationReport = this.service.registrationReportsDetail;
    this.projectId = this.data.projectId;
    this.configure();
  }

  configure() {
    this.loadProjectDetails();
  }

  private loadProjectDetails() {
    let request = this.service.getProjectDetails(this.projectId);
    if (this.data.currentStepObj && this.data.currentStepObj.selectedProject) {
      request = of(this.data.currentStepObj.selectedProject);
    } else if (this.data.currentStepObj && this.data.currentStepObj.additionalDetailsModel[CCR_EDITED_PROJECT_DETAILS]) {
      request = of(this.data.currentStepObj.additionalDetailsModel[CCR_EDITED_PROJECT_DETAILS]);
    }
    request
      .pipe(
        tap(data => this.getQuantificationMethods(data.projectType, {...data.quantificationMethod})),
        takeUntil(this._destroy$))
      .subscribe(data => {
        this.model = data;
        if (this.model.quantificationMethod) {
          this.model.quantificationMethod = this.model.quantificationMethod.id;
        }
        this.extraModel = this.service.currentData;
        this._fuels$.next(this.fuels);
        this.fields = this.formFactoryService.configureForm(this.getFormFields());
        this.regulatoryRegimeFields = this.formFactoryService.configureForm(getRegulatoryRegime(this.formDisabled));
        this.getAlphanumericIDofCI();
        this.documentUploadConfig = this.getDocumentConfig();
        this.setProjectDuration();
        this.ready = true;
        setTimeout(() => {
          if (this.formDisabled) {
            this.form.disable();
            this.extraForm.disable();
          }
        });
      });
  }

  private getFormFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getProjectId(),
          getProjectName(),
          getProjectType(),
          getQuantificationMethod(this._quantificationMethods$),
          getCarbonIntensity(),
          getAnticipatedStartDate(() => this.setProjectDuration()),
          getAnticipatedEndDate(() => this.setProjectDuration()),
          getProjectDuration(),
          getProjectCreditingEndDate(),
        ],
      }];
  }

  private preparePayload(): IProject {
    const payload = {
      ...this.model,
      startDate: this.model.startDate && moment(this.model.startDate).format(CCR_DATE_ONLY_FORMAT),
      endDate: this.model.endDate && moment(this.model.endDate).format(CCR_DATE_ONLY_FORMAT),
      [CCR_PROJECT_CREDITING_START_DATE]: this.model[CCR_PROJECT_CREDITING_START_DATE] && moment(this.model[CCR_PROJECT_CREDITING_START_DATE]).format(CCR_DATE_ONLY_FORMAT),
      [ERP_QUANTIFICATION_METHOD_FLD]: this._quantificationMethods$.getValue().find(q => q.id === this.model[ERP_QUANTIFICATION_METHOD_FLD]),
    };
    return payload;
  }

  public save() {
    this.formFactoryService.markFormGroupDirty(this.form);
    if (this.form.valid) {
      this.dialogRef.close(this.preparePayload());
    }
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  public getAlphanumericIDofCI() {
    if (this._fuels$.getValue() && this._fuels$.getValue().length) {
      const payload: ICreditCreationReportGetAlphNumCI = {
        fuelTypeList: this._fuels$.getValue().map(f => f.id),
        id: this.service.currentData.accountId,
        includeAdditionalCi: true,
        ccr: true
      };
      this.service.getAlphanumericIDofCI(payload)
        .pipe(
          takeUntil(this._destroy$),
          tap(data => {
            this.alphanumericIDofCI$.next(data);
          }),
        )
        .subscribe();
    } else {
      this.alphanumericIDofCI$.next([]);
    }
  }

  public addRegulatoryRegime() {
    const applicationRegulatoryRegimes = [{}, ...this.model.applicationRegulatoryRegimes];
    this.model = {...this.model, applicationRegulatoryRegimes };
  }

  private get fuels(): string[] {
    let fuels = [];
    if (this.registrationReport) {
      const subType = this.registrationReport.accountSubTypeDtoList.find(st => st.code === IApp.NSAccountSubType.StatusEnum.RC);
      const activity = subType.activities.find(act => act.code === IApp.NSActivityCode.StatusEnum.ERP);
      fuels = activity ? uniqBy(activity.facilities.flatMap(f => f.fuelDtoList), 'id') : [];
      if (this.model.projectType === ERP_PROJECT_TYPE.CO_PROCESSING_PROJECT) {
        fuels = fuels.filter(f => ERP_CO_PROCESSING_PROJECT_FUELS.includes(f.code));
      } else {
        fuels = fuels.filter(f => !ERP_CO_PROCESSING_PROJECT_FUELS.includes(f.code));
      }
      if (this.currentQuantificationMethod &&
        this.currentQuantificationMethod.fuelIds &&
        this.currentQuantificationMethod.fuelIds.length) {
        fuels = fuels.filter(f => this.currentQuantificationMethod.fuelIds.includes(f.id));
      }
    }
    return fuels;
  }

  private getQuantificationMethods(projectType: string, quantificationMethod: any) {
    this.lookupService.getERPQuantificationMethods(projectType)
      .pipe(takeUntil(this._destroy$))
      .subscribe(result => {
        if (quantificationMethod.id) {
          if (result && !result.some(item => item.id === quantificationMethod.id)) {
            result.push(quantificationMethod);
          }
        }
        this._quantificationMethods$.next([...result,quantificationMethod]);
      });
  }

  public onAlphanumericIDofCIChange(event) {
    if (this.model[CCR_ALPHANUMERIC_CI_FLD]) {
      const ciId = getEntityByProp(this.alphanumericIDofCI$, this.model[CCR_ALPHANUMERIC_CI_FLD], 'name');
      if (ciId) {
        this.model = {
          ...this.model,
          [CCR_CI_ID_FLD]: ciId.id,
          [CCR_CI_LIQUID_CI_FUEL_FLD]: ciId.fuelValue,
        };
      }
    }
  }

  private setCurrentQuantificationMethod(data): void {
    if (data.event && !data.event.initial) {
      this.currentQuantificationMethod = data.event;
      this._fuels$.next(this.fuels);
    } else {
      if (this.model[ERP_QUANTIFICATION_METHOD_FLD]) {
        this.currentQuantificationMethod = this._quantificationMethods$.getValue().find(q => q.id === this.model[ERP_QUANTIFICATION_METHOD_FLD]);
      }
    }
  }

  documentUploadHandler(documentResult: IDocument[]): void {
    this.documents = documentResult;
    this.isDocumentUploadInProgress = documentResult
      .filter(document => document._uploadStatus)
      .some(document => document._uploadStatus !== 'CLEAN');
  }

  getDocumentConfig = (): IApp.IDocumentUploadConfig => {
    return {
      id: this.model.id,
      documentType: true,
      entityName: EntityTypeEnum.PROJECT_OFFSET,
      disablePaginator: false,
      comment: true,
      statusColumn: true,
      pendingSubmissionStatus: true,
      documentUploadConfigs: [
      ],
      extraUploadDocuments: [],
    };
  }

  private replaceWithTranslatedString(startDate, endDate) {
    const message = getDurationInDaysMonthAndYear(
      startDate,
      endDate);
    const translateObj = {
      years: this.translateService.instant('SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.years'),
      months: this.translateService.instant('SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.months'),
      days: this.translateService.instant('SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.days'),
    };

    return message.replace(/years|months|days/gi, matched => {
      return translateObj[matched];
    });
  }

  private setProjectDuration() {
    if (this.model[CCR_PROJECT_ANTICIPATED_START_DATE] && this.model[CCR_PROJECT_ANTICIPATED_END_DATE]) {
      this.model = {
        ...this.model,
        [CCR_PROJECT_DURATION]: this.replaceWithTranslatedString(
          this.model[CCR_PROJECT_ANTICIPATED_START_DATE],
          this.model[CCR_PROJECT_ANTICIPATED_END_DATE],
        ),
      };
    } else if (this.model[CCR_PROJECT_ANTICIPATED_START_DATE] && !this.model[CCR_PROJECT_ANTICIPATED_END_DATE]) {
      this.model = {
        ...this.model,
        [CCR_PROJECT_DURATION]: 'N/A',
      };
    } else {
      this.model = {
        ...this.model,
        [CCR_PROJECT_DURATION]: null,
      };
    }
  }

  get formDisabled(): boolean {
    return this.data.readOnly;
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  ngOnDestroy() {
    this.service.clearCallMethod();
    this._destroy$.next();
    this._destroy$.complete();
  }

}
