import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FieldsetModule } from "primeng";
import { AddressViewComponent } from "./address-view.component";

@NgModule({
  declarations: [AddressViewComponent],
  imports: [
    FieldsetModule,
    TranslateModule,
    CommonModule
  ],
  exports: [AddressViewComponent]
})
export class AddressViewModule {}
