import { BehaviorSubject } from 'rxjs';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./modal.service";
import * as i3 from "@ngx-translate/core";
export class NotificationsService {
    constructor(http, modalService, translate) {
        this.http = http;
        this.modalService = modalService;
        this.translate = translate;
        this.isShown = new BehaviorSubject(false);
        this.data = new BehaviorSubject(null);
    }
    toggle() {
        this.isShown.next(!this.isShown.getValue());
    }
    showMessage(type, message, metaData = null) {
        this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                }
            ],
            message: message,
            metaData: metaData,
            type: type,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                console.log(result);
            }
        });
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.TranslateService)); }, token: NotificationsService, providedIn: "root" });
