/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-formly/core";
import * as i4 from "./text-area.type.component";
var styles_FormlyFieldTextAreaExtra = [];
var RenderType_FormlyFieldTextAreaExtra = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldTextAreaExtra, data: {} });
export { RenderType_FormlyFieldTextAreaExtra as RenderType_FormlyFieldTextAreaExtra };
function View_FormlyFieldTextAreaExtra_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "label", [["class", "wcag-visuallyhidden"]], [[1, "for", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.elementId; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.elementId; _ck(_v, 1, 0, currVal_1); }); }
export function View_FormlyFieldTextAreaExtra_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyFieldTextAreaExtra_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 7, "textarea", [["class", "form-control"], ["title", ""]], [[8, "cols", 0], [8, "rows", 0], [2, "is-invalid", null], [8, "id", 0], [8, "readOnly", 0], [8, "tabIndex", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (i0.ɵnov(_v, 8).onFocus($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 8).onBlur($event) !== false);
        ad = (pd_5 && ad);
    } if (("change" === en)) {
        var pd_6 = (i0.ɵnov(_v, 8).onChange($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(5, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i0.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i0.ɵdid(8, 933888, null, 0, i3.FormlyAttributes, [i0.Renderer2, i0.ElementRef, i1.DOCUMENT], { field: [0, "field"] }, null), (_l()(), i0.ɵted(-1, null, ["    "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.to.dummyLabel === true); _ck(_v, 1, 0, currVal_0); var currVal_14 = _co.formControl; _ck(_v, 5, 0, currVal_14); var currVal_15 = _co.field; _ck(_v, 8, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.to.cols; var currVal_2 = _co.to.rows; var currVal_3 = _co.showError; var currVal_4 = _co.elementId; var currVal_5 = _co.disabledField; var currVal_6 = _co.tabIndex; var currVal_7 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_8 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_9 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_10 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_11 = i0.ɵnov(_v, 7).ngClassValid; var currVal_12 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_13 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }); }
export function View_FormlyFieldTextAreaExtra_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-field-textarea", [], null, null, null, View_FormlyFieldTextAreaExtra_0, RenderType_FormlyFieldTextAreaExtra)), i0.ɵdid(1, 114688, null, 0, i4.FormlyFieldTextAreaExtra, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldTextAreaExtraNgFactory = i0.ɵccf("formly-field-textarea", i4.FormlyFieldTextAreaExtra, View_FormlyFieldTextAreaExtra_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldTextAreaExtraNgFactory as FormlyFieldTextAreaExtraNgFactory };
