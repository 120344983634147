import { IApp } from '@core/models/app.interfaces';

export class Document implements IApp.IDocument{
    action?: IApp.NSDocument.ActionEnum;
    actionList?: IApp.IAction[];
    awsId?: string;
    comments?: string;
    contentType?: string;
    documentType?: IApp.IDocumentType;
    entityId?: number;
    entityType?: IApp.NSDocument.EntityTypeEnum;
    id?: number;
    label?: string;
    lastModifiedDate?: string;
    name?: string;
    size?: number;
    status?: string;
    isPublic?: boolean;
    entityVersionId?: number;
}

export class  DocumentTypeClass implements IApp.IDocumentType {
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    name?: string;
    version?: number;
  }
