import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { DialogModule } from 'primeng';
import { FormPopupWrapperComponent } from './components/form-popup-wrapper/form-popup-wrapper.component';

@NgModule({
  declarations: [FormPopupWrapperComponent],
  exports: [FormPopupWrapperComponent],
  imports: [CommonModule, DialogModule, FormlyModule, FormsModule, ReactiveFormsModule, SharedPipesModule],
})
export class FormPopupWrapperModule {}
