<div id="reg-rept-actions" *ngIf="actionsList?.length > 0">
    <ng-select
        wcag-label
        [placeholder]="'COMMON.actionsLabel.actions' | translate"
        [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.actions' | translate}"
        [items]="actionsList"
        bindLabel="label"
        bindValue="workflowAction"
        class="ng-dropdown-left"
        [alignRight]="true"
        [(ngModel)]="dummyModel"
        (change)="triggerActionController($event)"
        [appendTo]="appendTo">
    </ng-select>
</div>
