/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user-tasks.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../shared/components/task-details/task-details.component.ngfactory";
import * as i8 from "../../../../shared/components/task-details/task-details.component";
import * as i9 from "../../../../core/store/store.service";
import * as i10 from "../../../../shared/services/tasks.service";
import * as i11 from "../../../../core/services/lookup.service";
import * as i12 from "../../../obligation-management/services/obligation-management.service";
import * as i13 from "../../../../shared/services/modal.service";
import * as i14 from "../../../../shared/services/helper.service";
import * as i15 from "@angular/router";
import * as i16 from "@ng-bootstrap/ng-bootstrap";
import * as i17 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i18 from "@angular/common";
import * as i19 from "./user-tasks.component";
var styles_UserTasksComponent = [i0.styles];
var RenderType_UserTasksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserTasksComponent, data: {} });
export { RenderType_UserTasksComponent as RenderType_UserTasksComponent };
function View_UserTasksComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["(", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tasks.length; _ck(_v, 1, 0, currVal_0); }); }
function View_UserTasksComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ": "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "fa-icon", [["class", "float-right ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(5, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(6, 2)], function (_ck, _v) { var currVal_4 = _ck(_v, 6, 0, "far", (_v.parent.context.$implicit.isRead ? "envelope-open" : "envelope")); _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ACCOUNTS_MODULE.userTasks.actionRequiredLabel")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.taskTypeTranslated; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 5).title; var currVal_3 = i1.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_2, currVal_3); }); }
function View_UserTasksComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-details", [], null, null, null, i7.View_TaskDetailsComponent_0, i7.RenderType_TaskDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i8.TaskDetailsComponent, [i9.StoreService, i3.TranslateService, i10.TasksService, i11.LookupService, i12.ObligationManagementService, i13.ModalService, i14.HelperService, i15.Router], { task: [0, "task"], showAction: [1, "showAction"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_UserTasksComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ngb-panel", [], null, null, null, null, null)), i1.ɵdid(1, 2113536, [[1, 4]], 3, i16.NgbPanel, [], { id: [0, "id"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { headerTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_UserTasksComponent_4)), i1.ɵdid(6, 16384, [[2, 4]], 0, i16.NgbPanelTitle, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(0, null, null, 1, null, View_UserTasksComponent_5)), i1.ɵdid(8, 16384, [[4, 4]], 0, i16.NgbPanelContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 1, 0, currVal_0); }, null); }
function View_UserTasksComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ngb-accordion", [["class", "accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], [[null, "panelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("panelChange" === en)) {
        var pd_0 = (_co.onPanelToggle($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_NgbAccordion_0, i17.RenderType_NgbAccordion)), i1.ɵdid(1, 2146304, [["acc", 4]], 1, i16.NgbAccordion, [i16.NgbAccordionConfig], { closeOtherPanels: [0, "closeOtherPanels"] }, { panelChange: "panelChange" }), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTasksComponent_3)), i1.ɵdid(4, 278528, null, 0, i18.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = true; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.tasks; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
function View_UserTasksComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.custom_messages.noItems")); _ck(_v, 2, 0, currVal_0); }); }
function View_UserTasksComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.custom_messages.pleaseWait")); _ck(_v, 1, 0, currVal_0); }); }
function View_UserTasksComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTasksComponent_7)), i1.ɵdid(1, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["LOADING", 2]], null, 0, null, View_UserTasksComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalTasks === 0); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_UserTasksComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewNumberOfItems($event, _co.totalTasks) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.custom_messages.viewAll")); _ck(_v, 2, 0, currVal_0); }); }
function View_UserTasksComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewNumberOfItems($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.custom_messages.viewLess")); _ck(_v, 1, 0, currVal_0); }); }
function View_UserTasksComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "p", [["class", "text-center mt-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(3, { current: 0, total: 1 }), (_l()(), i1.ɵted(-1, null, ["\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTasksComponent_10)), i1.ɵdid(6, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["ALL_ITEMS", 2]], null, 0, null, View_UserTasksComponent_11))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "ACCOUNTS_MODULE.dashboard.recordsInPanel"; var currVal_1 = _ck(_v, 3, 0, _co.tasks.length, _co.totalTasks); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.tasks.length < _co.totalTasks); var currVal_3 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
export function View_UserTasksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTasksComponent_1)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "tasks"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTasksComponent_2)), i1.ɵdid(7, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_DATA", 2]], null, 0, null, View_UserTasksComponent_6)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UserTasksComponent_9)), i1.ɵdid(10, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tasks; _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.totalTasks > 0); var currVal_3 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = _co.tasks; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ACCOUNTS_MODULE.userTasks.title")); _ck(_v, 1, 0, currVal_0); }); }
export function View_UserTasksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user-tasks", [], null, null, null, View_UserTasksComponent_0, RenderType_UserTasksComponent)), i1.ɵdid(1, 245760, null, 0, i19.UserTasksComponent, [i10.TasksService, i9.StoreService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserTasksComponentNgFactory = i1.ɵccf("app-user-tasks", i19.UserTasksComponent, View_UserTasksComponent_Host_0, {}, {}, []);
export { UserTasksComponentNgFactory as UserTasksComponentNgFactory };
