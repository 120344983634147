import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { fromEvent } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FormService {

  constructor(
    private translateService: TranslateService,
  ) { }

  public parseErrors(form: FormGroup, error: any) {
    if (error.fieldMessages) {
      error.fieldMessages.forEach(fieldError => {
        const fieldControl = form.get(fieldError.field);
        if (fieldControl) {
          fieldControl.markAsDirty();
          fieldControl.setErrors({'server-side': fieldError.message});
        }
      });
    }
  }

  public parseErrorsDirect(form: FormGroup, error: any) {
    if (error.fieldMessages) {
      error.fieldMessages.forEach(fieldError => {
        const fieldControl = form.get(fieldError.field);
        if (fieldControl) {
          fieldControl.markAsDirty();
          fieldControl.setErrors({'direct': fieldError.message});
        }
      });
    }
  }

  public scrollToFirstInvalidControl(form = null, containerEl: HTMLElement) {
    const el = form;
    if (!el) {
      return;
    }

    this.getFirstInvalidControl(el).subscribe(firstInvalidControl => {
        containerEl.scroll({
          top: this.getTopOffset(firstInvalidControl, containerEl),
          left: 0,
          behavior: 'smooth',
        });

        fromEvent(containerEl, 'scroll')
          .pipe(
            debounceTime(100),
            take(1),
          )
          .subscribe(() => firstInvalidControl.focus());
      });
  }

  private getTopOffset(controlEl: HTMLElement, containerEl: HTMLElement = null): number {
    const labelOffset = 50;
    const controlElTop = controlEl.getBoundingClientRect().top;

    if (containerEl) {
      const containerTop = containerEl.getBoundingClientRect().top;
      const absoluteControlElTop = controlElTop + containerEl.scrollTop;
      return absoluteControlElTop - containerTop - labelOffset;
    } else {
      const absoluteControlElTop = controlElTop + window.scrollY;
      return absoluteControlElTop - labelOffset;
    }
  }

  private getFirstInvalidControl(parentEl): Observable<any> {
    const elList = parentEl.nativeElement.querySelectorAll('.ng-invalid:not(form)');
    let _found = false;
    return new Observable<any>(subscriber => {
      for (const el of elList) {
        if (!_found) {
          if (el.offsetParent) {
            _found = true;
            subscriber.next(el);
            subscriber.complete();
          }
        }
      }
    });
  }

}
