<p-dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="true"
  [closable]="false"
  [header] = "'CREDIT_CREATION_REPORT.reportStep.chargingStationAddress' | translate">



  <form [formGroup]="form" #ngForm>
    <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
  </form>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
    <button type="button"
            class="btn btn-primary"
            *ngIf="!isDisabled"
            (click)="save()">
      {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
    </button>
  </div>
</p-dialog>
