<div [class.calendar-div]="calendarOptionsOpened" class="date-range">
  <div class="hotspot-area"
       tabindex="0"
       (keydown)="keyPressed($event, 'toggleCalendar', ['Tab', 'Shift', 'Control', 'Alt'])"
       (click)="toggleCalendar()">
  </div>

  <div class="flex" role="button" aria-label="Toggle" (click)="toggleCalendar()">
    <div class="date-range-label"
         [class.placeholder]="!dateFrom && !dateTo">
      <span>{{dateRangeLabel}}</span>
    </div>
  </div>

  <div class="cursor-pointer"
       role="button"
       aria-label="Toggle"
       [class.opened]="calendarOptionsOpened"
       (click)="toggleCalendar()">
    <div class="arrow"></div>
  </div>

  <div *ngIf="calendarOptionsOpened" class="calendars" cdkTrapFocus>
    <div class="months-pickers">
      <p-calendar (onSelect)="customCalendarDatesChanged()" [(ngModel)]="dateFromUTC.boundDate" [inline]="true"
                  [showTime]="false" [yearNavigator]="true"
                  [locale]="locales[lang]"
                  wcag-label
                  view="date"
                  yearRange="2000:2030">
      </p-calendar>
      <p-calendar (onSelect)="customCalendarDatesChanged()" [(ngModel)]="dateToUTC.boundDate" [inline]="true"
                  [showTime]="false" [yearNavigator]="true"
                  [locale]="locales[lang]"
                  [minDate]="minDate"
                  wcag-label
                  view="date"
                  yearRange="2000:2030">
      </p-calendar>
      <div class="buttons-wrapper">
        <p-selectButton
          (onChange)="onChangedDateOption()" [(ngModel)]="selectedDateOption" [options]="dateOptions">
        </p-selectButton>
      </div>

    </div>
  </div>

</div>
