<div class="mt-3 border-bottom d-flex justify-content-between">
  <h3 class="mb-2">{{ 'PROJECTS_MODULE.projectTasks.title' | translate }}</h3>
</div>

<div class="tasks">
  <ng-container *ngIf="tasks && tasks.length; else NO_TASKS">
    <div class="task mb-2" *ngFor="let task of tasks">
      <button class="btn btn-secondary btn-sm" (click)="doAction(task)">{{ 'COMMON.actionsLabel.view' | translate }}</button>
      <div class="task-desc">
        <p class="m-0" [innerHTML]="task.taskTypeTranslated"></p>
      </div>
    </div>
  </ng-container>
  <ng-template #NO_TASKS>
    <div class="text-center mb-3 mt-3">{{ 'PROJECTS_MODULE.projectTasks.noPendingTask' | translate }}</div>
  </ng-template>
</div>
