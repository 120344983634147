import * as i0 from "@angular/core";
import * as i1 from "../../services/navigation.service";
export class BackbtnserviceService {
    constructor(navigation) {
        this.navigation = navigation;
    }
    backPreviousPage() {
        this.navigation.back();
    }
}
BackbtnserviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BackbtnserviceService_Factory() { return new BackbtnserviceService(i0.ɵɵinject(i1.NavigationService)); }, token: BackbtnserviceService, providedIn: "root" });
