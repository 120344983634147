import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IApp } from '@core/models/app.interfaces';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { takeUntil } from 'rxjs/operators';
import { STEP5_SIGN_AGREEMENT } from '../constant/AgreementCreateCredits.const';

@Component({
  selector: 'app-sign-agreement',
  templateUrl: './sign-agreement.component.html',
  styleUrls: ['./sign-agreement.component.scss'],
})
export class SignAgreementComponent extends BaseStepperStepComponent {

  static key = 'SignAgreementComponent';
  fields: FormlyFieldConfig[];

  confirmation = false;
  suffix: string;
  constructor(
    private translateService: TranslateService,
    public formFactoryService: FormFactoryService,
    public formService: FormService,
    public service: AgreementCreditsService) { super(); }

  ngOnInit() {
    this.suffix = this.service.currentStep.suffix;
    this.model = this.service.currentData;
    const FORM_FIELDS = STEP5_SIGN_AGREEMENT;
    FORM_FIELDS[0].fieldGroup[0].key = `${this.suffix}Declaration`;
    FORM_FIELDS[0].fieldGroup[1].key = `${this.suffix}User`;
    FORM_FIELDS[0].fieldGroup[2].key = `${this.suffix}SignedOn`;
    this.fields = this.formFactoryService.configureForm(STEP5_SIGN_AGREEMENT);
  }

  saveAndProceed(draft = false) {
    this.service.nextStep();
  }

  submit() {
    if (!this.validateForm(this.form, false)) {
      this.markInvalid();
      return;
    }
    this.model.action =  this.model.status === IApp.Agreement.StatusEnum.PENDING_OPA_APPROVAL ? 'REGISTER_OPA_SIGN' : 'SIGN_AGREEMENT';
    this.model.registrationAccountId = this.model.registrationAccount.id;
    const  mapData = {actionLabel : 'SIGN'};
    this.service.triggerBackendAction(this.model, true, mapData);

  }
}
