<p-dialog
  [className]="'eccc'"
  [header]="'COMMON.labels.endingSession' | translate"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '500px'}"
  [minY]="100"
  [dismissableMask]="false"
  [closeOnEscape]="false"
  [maximizable]="false"
  [autoZIndex]="false">

  <ng-container *ngIf="!sessionTerminated; else TERMINATED">
    <p>{{'COMMON.labels.sessionExpireMessage' | translate:{countdown: countdown} }}</p>
  </ng-container>
  <ng-template #TERMINATED>
    <p>{{'COMMON.labels.sessionHasExpire' | translate }}</p>
  </ng-template>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button type="button"
                id="SESSION_END_continueBtn"
                class="btn btn-secondary"
                *ngIf="!sessionTerminated"
                (click)="reset()">{{'COMMON.labels.continue' | translate }}</button>
        <button id="SESSION_END_logoutBtn" *ngIf="!sessionTerminated" type="button" class="btn btn-secondary" (click)="logout()">{{'COMMON.labels.logout' | translate }}</button>
        <button id="SESSION_END_resetBtn" *ngIf="sessionTerminated" type="button" class="btn btn-secondary" (click)="resetSession()">{{'COMMON.labels.close' | translate }}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
