import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { IAccountData, IFacility, IFacilityRequest } from '@module/registration/registration.model';
import { RegistrationService } from '@module/registration/registration.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { RESPOND_TOADMIN_COMMENTS } from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { UserService } from '@shared/services/user.service';
import { STATUS_CALCULATE, STATUS_COMPLETED, STATUS_DRAFT, STATUS_ICON , STATUS_WARNING} from '@shared/shared.const';
import { forkJoin, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-offset-reg-progress',
  templateUrl: './offset-reg-progress.component.html',
  styleUrls: ['./offset-reg-progress.component.scss'],
})
export class OffsetRegProgressComponent implements OnInit, OnDestroy {

  data: any = {
  };
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  public discussions: IApp.IRegistrationDiscussion[];

  statusIcon = STATUS_ICON;
  entityActionList: any[];
  facilities: IFacility[] = [];
  facilityNames: IApp.ILookup[];
  files: IApp.IDocument[] = [];
  accountData = new IAccountData();
  programAdminEmail: string;
  pendingDocumentMetaData: IApp.IPendingDocumentMetaData;
  statusObject: any;
  loadedData: any;
  menuIndex = 0;

  actionMap: IApp.IActionMap = {
    PENDING_KYC: {
      message: 'orgRegProgressMsg',
      editable: true,
      status: 'warning',
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_PROJECT_APPLICATION: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: true,
      status: STATUS_DRAFT,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_CALCULATE,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_REVIEW_ADMIN: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_REVIEW_ADMIN2: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_REVIEW_ADMIN_RECEIVED_CONSENT: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_REVIEW_ADMIN2_RECEIVED_CONSENT: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_PROJECT_TRANSFER: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    ON_HOLD: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    PENDING_KYC_NEED_MORE_INFO: {
      message: 'orgRegProgressMsgNMI',
      editable: true,
      status: 'warning',
      rrStatus: STATUS_COMPLETED,
      caStatus: STATUS_COMPLETED,
      duStatus: STATUS_CALCULATE,
    },
    USER_PENDING_REVIEW_ADMIN: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
    },
    USER_PENDING_REVIEW_ADMIN2: {
      message: 'orgRegProgressMsgPendingAdmin',
      editable: false,
      status: STATUS_COMPLETED,
    },
    USER_PENDING_KYC: {
      message: 'orgRegProgressMsgPendingKyc',
      editable: true,
      status: STATUS_DRAFT,
    },
  };

  enableSubmit: boolean;
  private _destroy$ = new Subject<any>();
  actionDesc: string;
  status: string;
  showComments: boolean;
  subType: string;
  subTypeActivity = '';
  errors = [];
  docAllowedFileTypes: any;
  editable = true;
  pendingFacility: any;
  loaded = false;
  userLoaded = false;
  accountLoaded = false;
  documentUploadConfig: IApp.IDocumentUploadConfig;
  docPresent: boolean[] = [];
  docMissing: boolean;
  UserDocMissing: boolean;
  registrationComplete: boolean;
  registrationId: number;
  userData: IApp.ISaveUser;
  agreementRequired: boolean;
  agreementSkipAllowed: boolean;
  agreementComplete = true;
  missingDocumentList: any;


  isNavbarCollapsed = true;

  menuItems: any[]=[];

  userAccountList:any[]=[];
  associationKycStatus:any[]=[];

  constructor(
    public modalService: ModalService,
    public uploadDocumentService: UploadDocumentService,
    private service: CfrRegistrationService,
    public registrationService: RegistrationService,
    private projectService: ProjectService,
    public userService: UserService,
    public lookupService: LookupService,
    public storeService: StoreService,
    public translate: TranslateService,
    public router: Router,
    public helperService: HelperService,
    private formFactoryService: FormFactoryService,
    private authService: AuthService,

  ) {
  }

  ngOnInit() {
    this.storeService.gerProgramAdminEmail().subscribe(
      prop => {
        this.programAdminEmail = prop.value;
        this.authService.changeProgram(this.storeService.user.programId).subscribe(result => {
          this.getFacilityNames();
        });
      },
    );
  }

  updatePageWith(id) {
    this.menuIndex = id;
    this.processPageData(this.loadedData);
  }

  setTabActive(id) {
    this.menuItems.forEach(item=> {
      item.selected = item.id === id;
    })
  }

  getFiles(facility) {
    this.uploadDocumentService.getDocumentsByEntityIdAndType(
        facility.id, this.documentUploadConfig.entityName)
        .subscribe(
          documents => {
            this.files  = documents.content;
          });
  }

  processPageData(value) {

    this.loadedData = value;
    this.facilityNames = value.userAccountList.map(value => ({
      name: value.legalName,
    }),
    );
    this.facilities = value.userAccountList;
    this.setIndividualAccount();
    this._getDiscussions();

    if (this.facilities.length >= 1) {
      this.status = this.facilities[this.menuIndex].status;
      if (this.status === 'PENDING_KYC_NEED_MORE_INFO') {
        this.fields = this.formFactoryService.configureForm(RESPOND_TOADMIN_COMMENTS);
        this.showComments = true;
      }else{
        this.showComments = false;
      }
      if (this.facilities[this.menuIndex].accountSubTypes === IApp.NSAccountSubType.StatusEnum.VB) {
        this.subType = IApp.NSAccountSubType.StatusEnum.VB;
      } else {
        this.subType = IApp.NSAccountSubType.StatusEnum.PS;
      }

      this.pendingFacility = value;
      this.documentUploadConfig = this.service.getDocumentUploadConfig(this.subType, this.facilities[this.menuIndex].id);

      const config1 = this.documentUploadConfig.documentUploadConfigs[0];
      const config2 = this.documentUploadConfig.documentUploadConfigs[1] ? this.documentUploadConfig.documentUploadConfigs[1] :
        { id: -1, entityName: 'ACCOUNT', documentTypeCode: null };
      const projectsPayload = {
        projectStatusList: ['SUBMITTED', 'ACTIVE', 'UNDER_REVIEW', 'ON_HOLD', 'ACTIVE', 'COMPLETED', 'UNDER_REVIEW_2', 'PENDING_ADMIN_REVIEW'],
        proponentList: [this.facilities[this.menuIndex].id],
      };
      forkJoin([
        this.uploadDocumentService.getDocumentsByEntityIdAndType(config1.id, config1.entityName, config1.documentTypeCode),
        this.uploadDocumentService.getDocumentsByEntityIdAndType(config2.id, config2.entityName, config2.documentTypeCode),
        this.projectService.getProjectApplicationList(null, projectsPayload),
      ]).subscribe(([docResponse1, docResponse2,  agreementResponse]) => {
        const doc1 = docResponse1.totalElements > 0;
        const doc2 = this.documentUploadConfig.documentUploadConfigs[1] ? docResponse2.totalElements > 0 : true;
        this.docMissing = !(doc1 && doc2);
        this.UserDocMissing = this.subType === IApp.NSAccountSubType.StatusEnum.VB ? false : (doc1 || doc2);
        this.files = docResponse1.content;

        this.agreementSkipAllowed = this.facilities[this.menuIndex].masterAccount;
        if (this.storeService.user.program !== 'CM') {
          this.agreementComplete = agreementResponse.content.length > 0;
        }

        this.getActionMessage();
        this.loaded = true;
        this.accountLoaded = true;

      });

    } else if (value.status === 'ACTIVE' &&  this.associationKycStatus.length === 0) {
      this.authService.changeProgram(value.programId).subscribe(result => {
        window.location.href = '/';
      });
    } else {

      this.status = this.associationKycStatus ? `USER_${ this.associationKycStatus[this.menuIndex].userStatus}` :  `USER_${value.status}`;
      let uploadDocumentText = 'uploadDocumentTextUser';
      if (value.associationsList[this.menuIndex].roleId === 1) {
        this.documentUploadConfig =  this.service.getDocumentUploadConfig('MAO_USER', this.service.accountId);
        uploadDocumentText = 'uploadDocumentTextPS';
        this.subType = 'MAOUser';
      } else {
        this.documentUploadConfig = this.service.getDocumentUploadConfig('USER', this.service.accountId);
        this.subType = 'User';
      }

      this.getActionMessage();
      this.userLoaded = true;
      this.pendingDocumentMetaData = {
        sizeProperty: 'max.file.size.allowed.registration.report',
        fileExtension: 'file.extension.allowed.registration.report',
        prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
        title: 'titleUser',
        dataLabel: 'userEmailAddress',
        data: value.email,
        status: this.statusObject.status,
        uploadDocumentText,
        identificationDocuments: this.actionDesc,
        documentUploadConfig: this.documentUploadConfig,
        disabled: !this.editable,
      };
      this.userData = {
        id: value.id,
        email: value.email,
        accountId: this.menuItems[this.menuIndex].accountId,
      };
      this.loaded = true;
    }

    this.translate.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.getActionMessage());
  }

  getFacilityNames() {
    this.lookupService.getPendingFacilityNames().pipe(
      takeUntil(this._destroy$),
    ).subscribe(value => {
      //set up menu tabs
      this.associationKycStatus = value.associationsList.filter(a => a.userStatus === 'PENDING_KYC' || a.userStatus === 'PENDING_REVIEW_ADMIN');
      const pendingAccounts = value.userAccountList.length > 0 ;
      this.userAccountList = pendingAccounts ? value.userAccountList : this.associationKycStatus;
      this.menuItems = [];
      this.userAccountList
        .forEach( (itemIndec, index) => {
        const item = pendingAccounts ? itemIndec : itemIndec.accountDto;
        this.menuItems.push({
          name: item.legalName ? item.legalName : item.acc,
          id: index,
          selected: false,
          accountId: item.id,
          masterAccount: !item.masterAccount,
        });
      })
      if(this.menuItems && this.menuItems.length > 0){
        this.menuItems[0].selected = true;
      }
      //exiting code
      this.menuIndex = 0;
      this.processPageData(value);

    });
  }

  getActionMessage() {
    this.statusObject = this.actionMap[`${this.status}`];
    if (this.statusObject) {
      this.editable = this.statusObject.editable;
      let message = this.translate.instant(`REGISTRATION_PAGE.cfrPendingRegistration.${this.statusObject.message}Offset`);
      this.actionDesc = message.replaceAll(`{{paEmail}}`, this.programAdminEmail);
      this.setEntityActionList(this.statusObject);
    }
  }

  setEntityActionList(statusObject) {
    const duStatus = this.calculateDuStatus(statusObject);
    const rrStatus = this.calculateRrStatus(statusObject);
    this.entityActionList = [];
    this.entityActionList.push({
      workflowAction: 'CREATE_REGISTRATION_REPORT',
      status: rrStatus,
      message: rrStatus === STATUS_COMPLETED ? 'VIEW_REGISTRATION_REPORT' : 'CREATE_REGISTRATION_REPORT',
      code: 'RR',
    });

    const caStatus = this.calculateCaStatus(statusObject);


    if(this.isMasterAccount()) {
      this.entityActionList.push({
      workflowAction: this.subType === IApp.NSAccountSubType.StatusEnum.VB ? 'UPLOAD_IDENTITY_DOCUMENTS' :
        'UPLOAD_AUTHORIZATION_AND_IDENTITY_DOCUMENTS',
      status: duStatus,
      message: duStatus === STATUS_COMPLETED ? 'viewuploadDocs' : 'uploadDocs',
      code: 'DU',
    });

      if (this.storeService.user.program !== 'CM') {
        this.entityActionList.push({
          workflowAction: 'CREATE_AGREEMENT',
          status: caStatus,
          message: caStatus === STATUS_COMPLETED ? 'VIEW_AGREEMENT' : 'createAgreement',
          code: 'CA',
        });
      }
    }
  }

  isMasterAccount(){
    return this.facilities[this.menuIndex] && !this.facilities[this.menuIndex].masterAccount;
  }

  calculateDuStatus(statusObject) {
    if (statusObject.duStatus === STATUS_CALCULATE) {
      return this.docMissing ? (this.UserDocMissing ? STATUS_WARNING : STATUS_DRAFT) : STATUS_COMPLETED;
    }
    return statusObject.duStatus;
  }

  calculateRrStatus(statusObject) {
    if (statusObject.rrStatus === STATUS_CALCULATE) {
      return this.registrationComplete ? STATUS_COMPLETED : STATUS_DRAFT;
    }
    return statusObject.rrStatus;
  }

  /**
   * create agreement status
   * @param statusObject
   * @returns
   */
  calculateCaStatus(statusObject) {
    if (statusObject.caStatus === STATUS_CALCULATE) {
      return this.agreementComplete ? STATUS_COMPLETED : (this.agreementSkipAllowed ? STATUS_WARNING : STATUS_DRAFT)  ;
    }
    return statusObject.caStatus;
  }

  cfrRegistration(code) {
    if (code === 'RR') {
      const accountId = this.facilities[this.menuIndex].id ;//this.loadedData.associationsList[0].accountId;
      if (accountId) {
        let uri = `/offset-registration/${accountId}`;
        if(!this.isMasterAccount()){
          uri = `${uri}/${this.facilities[this.menuIndex].masterAccount.id}`;
        }
        this.router.navigate(this.helperService.getTranslatedPath(uri));
      }
    } else if (code === 'CA') {
      this.router.navigate(this.helperService.getTranslatedPath(`/project-list/${this.facilities[this.menuIndex].id}`), {state: { pendingRegistration: true } });
    } else {
      this.storeService.setHistoryUrl();
      let uri = `/cfr-registration/cfr/${code}${this.subType}/${this.facilities[this.menuIndex].id}`;
      if (this.registrationId) {
        uri = `${uri}/${this.registrationId}`;
      }
      this.router.navigate(this.helperService.getTranslatedPath(uri));
    }
  }

  documentUpload(event) {
    this.router.navigate(this.helperService.getTranslatedPath('/cfr-registration/cfr/DU'));
  }

  submitRegistrationReport() {
    this.formFactoryService.markFormGroupDirty(this.form);

    if (!this.showComments || this.form.valid) {

      const facilityIds = this.facilities[this.menuIndex].id;
      const facilityRequest: IFacilityRequest = {
        docIds: this.files,
        accountIds: [facilityIds],
        userComment: this.data.userComment,
      };

      this.modalService.open(ServiceMessageComponent,
        {
          messages: null,
          message: this.status === 'PENDING_KYC_NEED_MORE_INFO' ? 'resubmitApplicationMessage' : 'submitRegistrationMessage',
          metaDataList: [this.facilities[this.menuIndex].legalName],
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.registrationService.submitKyc(facilityRequest).subscribe(
          () => {
            this.authService.changeProgram(this.storeService.user.programId).subscribe(result => {
              this.editable = false;
              this.showComments = false;
              this.getFacilityNames();
              this._getDiscussions();
            });
          },
          (error: HttpErrorResponse) => {
            this.errors = this.registrationService.handleErrors(error.error);
          },
        );
      }
    });
    }
  }

  cancelRegistrationReport(){
    this.modalService.open(ServiceMessageComponent,
      {
        messages:null,
        message: 'cancelRegistrationMessage',
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed()
      .pipe(
        takeUntil(this._destroy$),
        filter(data => data),
        switchMap(() => this.registrationService.cancelOffsetRegistration({ id: this.loadedData.associationsList[0].accountId })),
        switchMap(() => this.authService.signOut())
      ).subscribe();
  }

  submitKycFiles() {
    if ( this.missingDocumentList.length) {
      this.checkForRequiredDocs(this.missingDocumentList);
    } else {

      this.userService.doAction('/doOffsetKycSubmit', this.userData).subscribe(
        result => {
          this.editable = false;
          this.getFacilityNames();
        },
        (error: HttpErrorResponse) => {
          this.errors = this.registrationService.handleErrors(error.error);
        },
      );
    }
  }

  updateDocumentDetails(docs) {
    const requiredDocs = this.documentUploadConfig.documentUploadConfigs.map(t => t.documentTypeCode);
    const uploadedDocs = docs.map(t => t.documentType.code);
    this.missingDocumentList = requiredDocs.filter(doc => !uploadedDocs.includes(doc));
  }

  private checkForRequiredDocs(missingDocs: any) {
    let charCode = 97;
    const missingDocMsg = missingDocs.reduce((acc, doc, index) => {
      ++charCode;
      return (index < missingDocs.length - 1) ?
        acc + `${this.translate.instant(`COMMON.documentList.${doc}`)}<br>${String.fromCharCode(charCode)}. ` :
        acc + `${this.translate.instant(`COMMON.documentList.${doc}`)}`;
    }, '');
    const metaData = [`${String.fromCharCode(97)}. ${missingDocMsg}`];
    this.modalService.open(ServiceMessageComponent,
      {
        messages: [{
          message: 'mandatoryInformationRequiredForUploadDocs',
          metaData,
        }],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    );
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _getDiscussions() {
    if(this.facilities && this.facilities[this.menuIndex]){
    this.service.getPreviousDiscussions(this.facilities[this.menuIndex].id)
      .subscribe(data => this.discussions = data);
  }
}

  private setIndividualAccount() {
    this.service.individualAccount = !!(this.facilities && this.facilities[this.menuIndex] && this.facilities[this.menuIndex]['accountSubType'].name === 'INDIVIDUAL');
  }
}
