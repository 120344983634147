import { HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { programIdKey } from '@core/store/store.service';
import { Subject } from 'rxjs/internal/Subject';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const TRANSLATION_SOURCE_URI = '/cms-server/cms/getByLanguage';
export const TRANSLATION_S3_SOURCE_URI = '/cms-site';
const REGEXP = new RegExp('^C[0-9]+-');
export class CustomTranslateLoader {
    constructor(http) {
        this.http = http;
        this.contentHeader = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        this.reset$ = new Subject();
    }
    transformTranslationFile(payload, ignoreTranslationPrefix) {
        const flattenObject = (obj, prefix = '') => Object.keys(obj).reduce((acc, k) => {
            const pre = prefix.length ? prefix + '.' : '';
            if (typeof obj[k] === 'object' && k !== 'contentList') {
                Object.assign(acc, flattenObject(obj[k], pre + k));
            }
            else {
                if (k !== 'contentList') {
                    acc[pre + k] = obj[k];
                }
                else {
                    acc[pre.slice(0, -1)] = obj[k];
                }
            }
            return acc;
        }, {});
        const flatResponse = flattenObject(payload.contentResponse);
        Object.keys(flatResponse).forEach(k => {
            Array.isArray(flatResponse[k]) && (flatResponse[k] = flatResponse[k].map(l => ({ [l.name]: l.value ?
                    this.getValue(l.value, ignoreTranslationPrefix) : '' })).reduce((obj, item) => (Object.assign({}, obj, item)), {}));
        });
        return flatResponse;
    }
    get programId() {
        return sessionStorage.getItem(programIdKey) || null;
    }
    getValue(value, ignoreTranslationPrefix) {
        if (ignoreTranslationPrefix) {
            //console.log(value + ' ' + REGEXP.test(value));
            return REGEXP.test(value) ? value.substring(6) : value;
        }
        return value;
    }
    getTranslation(lang) {
        const uiVersion = sessionStorage.getItem('uiVersion');
        const ignoreTranslationPrefix = sessionStorage.getItem('ignoreTranslationPrefix');
        this.reset$.next(true);
        const langPath = this.programId ? `${lang}/${this.programId}` : `${lang}/obps`;
        const langS3Path = this.programId ? `${lang}-${this.programId}` : `${lang}-obps`;
        //let uri = `${ environment.apiUrl }${ TRANSLATION_SOURCE_URI }/${langPath}?t=${uiVersion}`;
        let uri = `${environment.apiUrl}${TRANSLATION_S3_SOURCE_URI}/cms-${langS3Path}.json?t=${uiVersion}`;
        //  uri = `/assets/i18n/${langPath}.json`;  // @Dev team, please uncomment for local development purposes. Do not check-in this change
        console.log('LANG URI: ', uri);
        return this.http.get(uri)
            .pipe(takeUntil(this.reset$), catchError(_ => this.http.get(`/assets/i18n/${langPath}.json`)), map(results => this.transformTranslationFile(results, ignoreTranslationPrefix === 'true')));
    }
}
CustomTranslateLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomTranslateLoader_Factory() { return new CustomTranslateLoader(i0.ɵɵinject(i1.HttpClient)); }, token: CustomTranslateLoader, providedIn: "root" });
