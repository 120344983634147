<p-calendar
    [id]="id"
    [ngModel]="currentValue"
    [inputId]="'date-input'"
    [inputStyleClass]="'col-12'"
    [placeholder]="placeholder | translate"
    [styleClass]="'col-12 p-0'"
    (ngModelChange)="onChange($event)"
    #calendar
    dateFormat="yy-mm-dd">
</p-calendar>
