import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'address-view',
  template: `
    <app-address-view
      [legend]="to.legend | translate"
      [address]="model[to.addressKey]">
    </app-address-view>
  `,
})
export class FormlyAddressViewTypeComponent extends FieldType {

}
