import { Component, OnDestroy, OnInit } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { environment } from '@env/environment';
import { IAccountData, IDocument, IFacility, IFacilityRequest } from '@module/registration/registration.model';
import { KYC_DOWNLOAD_TEMPLATE_URI } from '@shared/components/stepper/stepper.const';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RegistrationService } from '@module/registration/registration.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';

const ALLOWED_FILE_TYPES_PROP = 'doc.allowed.file.types';
const PENDING_REVIEW_ADMIN = 'PENDING_REVIEW_ADMIN';

@Component({
  selector: 'app-pending-registrations',
  templateUrl: './pending-registrations.component.html',
  styleUrls: ['./pending-registrations.component.scss'],
})
export class PendingRegistrationsComponent implements OnInit, OnDestroy {

  type = 'obps';
  facilities: IFacility[] = [];
  facilityNames: IApp.ILookup[];
  files:IApp.IDocument[] = [];
  accountData = new IAccountData();
  documentUploadConfig: IApp.IDocumentUploadConfig = {
    comment: true,
    documentType: false,
    entityName: 'USER',
    documentTypeValue: 2,
  };
  actionMap = {
    'PENDING_KYC' : {
      message: 'uploadKycDocument',
      editable: true
    },
    PENDING_REVIEW_ADMIN: {
      message: 'noActionRequired',
      editable: false
    },
    ACTIVE: {
      message: 'noActionRequiredActive',
      editable: false
    },
    'PENDING_KYC_NEED_MORE_INFO': {
      message: 'needMoreInfoKyc',
      editable: true
    }
  };
  actionDesc: string;
  status: string;
  errors = [];
  docAllowedFileTypes: any;
  programAdminEmail: string;
  editable: boolean =true;
  pendingFacility: any;

  private _destroy$ = new Subject<any>();

  constructor(
    private modalService: ModalService,
    private uploadDocumentService: UploadDocumentService,
    private registrationService: RegistrationService,
    private lookupService: LookupService,
    private storeService: StoreService,
    private translate :TranslateService
  ) { }

  ngOnInit() {
    this.storeService.getProperty(ALLOWED_FILE_TYPES_PROP).subscribe(
      prop => this.docAllowedFileTypes = prop.value,
    );

    this.storeService.gerProgramAdminEmail().subscribe(
      prop => {
        this.programAdminEmail = prop.value,
        this.getFacilityNames();
      }
    );

    this.translate.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() =>  this.getActionMessage());

    this.documentUploadConfig.id = this.storeService.user.id;
  }

  getActionMessage(){
    const statusObject = this.actionMap[this.status];
    if(statusObject){
      this.editable = statusObject.editable;
      let message= this.translate.instant(`REGISTRATION_PAGE.pendingRegistrations.${statusObject.message}`);
      message= message.replace(`{{comments}}`, this.storeService.user.needMoreInformation);
      this.actionDesc = message.replaceAll(`{{paEmail}}`, this.programAdminEmail);
    }
  }
  getFacilityNames() {
    this.lookupService.getPendingFacilityNames().pipe(
      takeUntil(this._destroy$),
    ).subscribe(value => {
      this.facilityNames = value.userAccountList.map(value => ({
        name: value.legalName
      })
      );

      this.facilities = value.userAccountList;
      try {
        this.status = value.associationsList.find((o) => o.accountId === value.userAccountList[0].id).userStatus;
      } catch (err) {
        this.status = value.status;
      }
      this.pendingFacility = value;
      this.getActionMessage();
      this.getFiles(value);
    });
  }

  downloadKYCTemplate() {
    window.open(`${environment.apiUrl}${KYC_DOWNLOAD_TEMPLATE_URI}`, '_blank');
  }

  getFiles(facility) {
    this.uploadDocumentService.getDocumentsByEntityIdAndType(
        facility.id, this.documentUploadConfig.entityName)
        .subscribe(
          documents => {
            this.files  = documents.content;
          });
  }

  openUploadPopup(event) {
    event && event.preventDefault();
    this.modalService.open(UploadDocumentComponent, this.documentUploadConfig).afterClosed().subscribe((result?: any) => {
      if (result) {
        if (!this.files) {
          this.files = [];
        }

        this.uploadDocumentService.checkUploadStatus(result.id, result.signedUrl)
          .pipe(
            takeUntil(this._destroy$),
          )
          .subscribe(
            res => {

              const idx = this.files.findIndex(d => d.id === res.id);
              if (idx > -1) {
                if (res.status === 'CLEAN') {
                  const doc = {...this.files[idx], _uploadStatus: res.status};
                  this.files[idx] = doc;
                } else {
                  this.files.splice(idx, 1);

                  const messages: IDisplayMessages = {
                    messages: [{message: 'infectedDocument'}],
                    type: SereviceMessageType.ERROR,
                  };
                  this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                    .afterClosed()
                    .subscribe();
                }
              }
            },
          );

        this.files.push({...result, _uploadStatus: 'IN_PROGRESS'});
      }
    });
  }

  get filesValidated() {
    if (this.files && this.files.length > 0) {
      return this.files.filter(f => f['_uploadStatus'] === 'IN_PROGRESS').length > 0;
    } else {
      return false;
    }
  }

  submitKycFiles() {
    //if (this.type === 'obps') {
      const facilityIds = this.facilities.map(el => el.id);
      const facilityRequest: IFacilityRequest = {
        docIds: this.files,
        accountIds: facilityIds,
      };
      this.registrationService.submitKyc(facilityRequest).subscribe(
        result => {
          this.editable = false;
          this.getFacilityNames();
        },
        (error: HttpErrorResponse) => {
          this.errors = this.registrationService.handleErrors(error.error);
        },
      );
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent;
    this.files[id][property] = editField;
  }

  download(id: number) {
    this.uploadDocumentService.download(id);
  }

  removeFile(index: number) {

    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: 'deleteDocumentWarningMessage',
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.files.splice(index, 1);
      }
    });

  }

  refreshTable(event) {
    this.getFiles(this.pendingFacility);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
