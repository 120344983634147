<div class='inner' #scrollableEl>
  <div class='container'>
    <div class='row'>
      <h2>{{ title | translate }}</h2>
      <p>{{ detailsTitle | translate: { projects: projectListText } }}</p>

      <div class='form-wrapper' [class.reverse]='reverse'>
        <ng-container *ngIf='source?.additionalFields'>
          <div *ngFor='let project of source.model.subProjects; let i = index'>
            <formly-form [fields]='source.additionalFields[i]' [model]='source.model.subProjects[i]'></formly-form>
          </div>
        </ng-container>

        <formly-form *ngIf='source' [fields]='source.fields' [form]='source.form' [model]='source.model'></formly-form>

        <div class="document-container mt-3" *ngIf="documentUploadConfig">
          <app-documents
            [documentUploadConfig]="documentUploadConfig"
            [showPlaceholders]="true"
            (documentResult)="updateUploadedDocuments($event)"
            [relative]="true">
          </app-documents>
        </div>
      </div>

      <div class='sticky-panel'>
        <div class='float-right'>
          <ng-container *ngIf='source && source.model'>
            <button (click)='back()' class='btn btn-secondary ml-3'>
              {{'PROJECTS_MODULE.ProjectBuilder.back' | translate}}
            </button>

            <ng-container *ngIf='source.model.entityActionList'>
              <ng-container *ngFor='let action of source.model.entityActionList'>
                <button type='button' class='btn btn-primary ml-2' (click)='triggerAction(action)'>
                  {{ 'SUBMISSIONS.actions.' + action.workflowAction | translate }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>

        </div>
      </div>
    </div>
  </div>
</div>
