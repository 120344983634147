<div class="container border m-0">
  <div class="row border-bottom align-items-end">
    <ng-container *ngFor="let header of headers">
      <div [attr.class]="header.class">
        {{header.text | translate}}
      </div>
    </ng-container>
  </div>

  <div class="addStageContainer pt-2">

    <formly-form [form]="form" [model]="model" [fields]="fields">
    </formly-form>

  </div>
  <div class="row p-2 border-bottom" *ngIf="addButtonText">
    <div class="col-12">
        <button id="add" type="button" class="btn btn-primary float-right" (click)="add()" [disabled]="disabled">{{addButtonText | translate}}</button>
    </div>
</div>
