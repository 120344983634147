/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blurb.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/messages/primeng-messages.ngfactory";
import * as i3 from "primeng/messages";
import * as i4 from "primeng/api";
import * as i5 from "@angular/common";
import * as i6 from "./blurb.component";
import * as i7 from "@ngx-translate/core";
var styles_BlurbComponent = [i0.styles];
var RenderType_BlurbComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlurbComponent, data: {} });
export { RenderType_BlurbComponent as RenderType_BlurbComponent };
function View_BlurbComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p-messages", [], null, null, null, i2.View_Messages_0, i2.RenderType_Messages)), i1.ɵdid(2, 1228800, null, 1, i3.Messages, [[2, i4.MessageService], i1.ElementRef], { value: [0, "value"], styleClass: [1, "styleClass"], escape: [2, "escape"] }, null), i1.ɵqud(603979776, 1, { templates: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getMessages(); var currVal_1 = "cats-blurp"; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_BlurbComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlurbComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.getMessages().length > 0) && _co.getMessages()[0].detail) && (_co.getMessages()[0].detail.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BlurbComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blurb", [], null, null, null, View_BlurbComponent_0, RenderType_BlurbComponent)), i1.ɵdid(1, 114688, null, 0, i6.BlurbComponent, [i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlurbComponentNgFactory = i1.ɵccf("app-blurb", i6.BlurbComponent, View_BlurbComponent_Host_0, { message: "message", severity: "severity" }, {}, []);
export { BlurbComponentNgFactory as BlurbComponentNgFactory };
