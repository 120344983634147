import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { EXPAND_COLLAPSE_TYPE, ITableViewConfig, ROWS_PER_PAGE } from '../../models/table-view.model';
import { COLUMN_WIDTH } from '../../../app.const';
import { isEqual } from 'lodash';

export const itemsPerPage = [20, 50, 100, 200];

@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableViewComponent implements OnInit, OnChanges {

  @Input('data') data: any;
  @Input('config') config: ITableViewConfig;
  @Input() firstItemIndex = 0;
  @Input() clearSelection;
  @Input() paginationDisabled = false;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() relative = false;
  @Input() selections: any[];
  @Input() expandFirstRow = false;
  @Input() isEditable = false;
  @Input() highlightRowId: number;
  @Input() tableContainerMinWidth: string = null;
  @Input() disableOneRowSelection = false;
  @Output() paginationChanged = new EventEmitter<IApp.IPagination>();
  @Output() checkboxSelected = new EventEmitter<any>();
  @Output() rowExpand = new EventEmitter<any>();

  @ViewChild('tableContainer', {static: true}) tableContainer: ElementRef;
  @ViewChild('dT', {static: false}) table: Table;

  public gridWidth: 'lgWidth' | 'xlWidth';
  itemsPerPage = itemsPerPage;
  rows = ROWS_PER_PAGE;
  expandedRows = {};
  _scrollLeft = 0;

  lastSort = {
    sort: null,
    dir: null,
  };

  constructor(
    private elem: ElementRef,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.rows = ROWS_PER_PAGE;
    this.init();


  }

  init() {
    setTimeout(() => {
      if (this.config) {
        this._calculateGridColumnWidth();
        if (this.config.paginator && (this.data && !this.data['content'])) {
          throw new Error('Data object is not IPageable type');
        }
        if (this.config.paginator && !this.config.rowsPerPage) {
          throw new Error('Please specify rowsPerPage in table config');
        }
        window.dispatchEvent(new Event('resize'));
        this.paginatorText();

        if (!this.footerTemplate && document.getElementsByClassName('ui-table-scrollable-footer')[0]) {
          document.getElementsByClassName('ui-table-scrollable-footer')[0].remove();
        }
      }
    }, 0);
  }

  isThereSubTable(rowData, col){
    return rowData[col.expandSectionDataRoot] !== null && rowData[col.expandSectionData] !== null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.config && !isEqual(changes.config.currentValue, changes.config.previousValue)) {
      this.init();
    }
    if (changes.clearSelection && changes.clearSelection.currentValue) {
      this.selections = [];
    }
    if (changes.data && this.expandFirstRow && this.data && this.data.content.length > 0) {
      this.expandedRows[this.data.content[0].id] = true;
      this.expandFirstRow = false;
      this.onRowExpand({data: this.data.content[0]});
    }
    if (changes.data && this.data && this.data.content && this.data.content.length === 1 && !this.disableOneRowSelection) {
      this.selections = [this.data.content[0]];
      setTimeout(() => this.selected());
    }
  }

  get tableStyles(): any {
    const styles: any = {};
    if (this.config && this.config.width) {
      styles.width = `${this.config.width}px`;
    } else if (this.gridWidth) {
      styles.width = this.gridWidth === 'xlWidth' ? '2600px' : '1300px';
    } else {
      styles.width = '100%';
    }
    return styles;
  }

  onPaginationChanged($event: any) {
    if (!this.paginationDisabled) {
      let page;
      if (this.rows !== $event.rows) {
        this.rows = $event.rows;
        page = 0;
      } else {
        page = $event.first / ($event.rows || this.config.rowsPerPage || this.itemsPerPage[0]);
      }
      const size = $event.rows || this.config.rowsPerPage;
      let sort;
      let dir;
      if ($event.sortField) {
        sort = $event.sortField;
        dir = $event.sortOrder < 0 ? 'DESC' : 'ASC';
        if (this.lastSort.sort !== sort || this.lastSort.dir !== dir) {
          page = 0;
          this.lastSort = {sort, dir};
        }
      }
      this.scrollToTop();
      this.paginationChanged.emit({sort, dir, page, size} as IApp.IPagination);
    }
  }

  paginatorText() {
    if (this.config.paginator) {
      setTimeout(() => {
        const paginator: HTMLElement = this.elem.nativeElement
          .getElementsByClassName('ui-dropdown-trigger-icon ui-clickable pi pi-chevron-down')[0].parentElement as HTMLElement;
        if (paginator.childElementCount === 1) {
          const random = this.randomId;
          const selectEl = this.elem.nativeElement.getElementsByClassName('ui-dropdown-hidden-select')[0] as HTMLElement;

          if (selectEl) {
            selectEl.firstChild['id'] = 'paginator-select';
            selectEl.firstChild['id'] = `paginator-select-${random}`;
            const label = document.createElement('label');
            label.textContent = `paginator-dropdown-${random}`;
            label.setAttribute('for', `paginator-select-${random}`);
            selectEl.append(label);
          }

          const itemPerPage = document.createElement('div');
          itemPerPage.innerText =  this.translate.instant('COMMON.table.itemsPerPageText');

          this.translate.onLangChange.subscribe(()=> {
            setTimeout(() => {
              itemPerPage.innerText =  this.translate.instant('COMMON.table.itemsPerPageText');
             });
          });

          const label = this.elem.nativeElement.querySelector('.ui-dropdown-label-container > label');
          const labelReplacement = document.createElement('span');
          labelReplacement.className = (label && label.className) ? label.className : '';
          labelReplacement.textContent = (label &&  label.textContent) ? label.textContent : '';
          label && label.replaceWith(labelReplacement);

          itemPerPage.className = 'd-md-none d-lg-block';
          itemPerPage.setAttribute('style', 'width: 111px;margin-left: 30px; color: #696969;');
          paginator.append(itemPerPage);
        }
      }, 100);
    }
  }

  selected(): void {
    this.checkboxSelected.emit(this.selections);
  }

  innerTableData: any[];
  innerTableHeaders: any[];
  expandDataField: string;

  isSimpleTable(){
    return this.config.expandSectionType === EXPAND_COLLAPSE_TYPE.SIMPLE_TABLE;
  }

  setExpandSectionData(root?, data?, rowData?, field?){
    if (!this.config.expandSectionTemplate) {
      this.innerTableData = this.getInnerTableData(root, data, rowData);
      this.innerTableHeaders = this.getInnerTableHeaders(field);
      this.expandDataField = this.getExpandDataField(field);
    }
  }

  getInnerTableData(root?, child?, rowData?){
    if(root !== undefined && root !== null){
      if (!rowData || typeof rowData !== 'object') {
        return child[root];
      } else {
        return rowData[root][child];
      }
    } else {
      if (!child) {
        child = this.expandDataField;
      }
      return rowData[child];
    }
  }

  getInnerTableHeaders(field){
    let innerTableHeaders = this.config.columns.find( element => {
      return field == element.field
     }
    );

    return innerTableHeaders.expandSectionTableHeader.columns;
  }

  getExpandDataField(field) {
    const innerTableHeaders = this.config.columns.find( element => {
      return field === element.field;
    });
    return innerTableHeaders.expandSectionDataRoot;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.resizeTableScrollArea();
    this._calculateGridColumnWidth();
  }

  resizeTableScrollArea() {
    if (this.table && !this.config.paginator) {
      setTimeout(() => {
        const tableEl = this.table.getBlockableElement();
        const tableScrollableBody: Partial<HTMLElement> = tableEl.getElementsByClassName('ui-table-scrollable-body')[0];
        const tableContainerHeight = this.tableContainer.nativeElement.clientHeight;
        if (tableScrollableBody) {
          tableScrollableBody.style.maxHeight = `${tableContainerHeight - 46}px`;
        }
      }, 0);
    }
  }

  adjustTableWidth() {
    if (this.table && this.config.autoWidth) {
      setTimeout(() => {
        this.config.width = this.config.columns.length * COLUMN_WIDTH < window.outerWidth
          ? null : this.config.columns.length * COLUMN_WIDTH;
      });
    }
  }

  scrollToTop() {
    if (this.table) {
      setTimeout(() => {
        const tableEl = this.table.getBlockableElement();
        const tableWrapper: Partial<HTMLElement> = tableEl.getElementsByClassName('ui-table-wrapper')[0];
        tableWrapper.scrollTo({ left: this._scrollLeft, top: 0 });
        this._scrollLeft = 0;
      });
    }
  }

  storeScrollLeft() {
    const tableEl = this.table.getBlockableElement();
    const tableWrapper: Partial<HTMLElement> = tableEl.getElementsByClassName('ui-table-wrapper')[0];
    this._scrollLeft = tableWrapper.scrollLeft;
  }

  checkRowDataExist (rowData, col) {
    if( rowData[col.field] !== null && rowData[col.field] !== undefined ) {
      if(rowData[col.field].length > 0)
        return true;
    }
    else {
      return false;
    }
  }

  getItems(data, type= 'chips') {
    if (typeof data === 'string') {
      if (type === 'strChips') {
        return data.split('#!@!#');
      } else {
        return data.indexOf('COMMA') > -1 ? data.split('COMMA') : data.split(',');
      }
    } else {
      return data;
    }
  }

  private get randomId() {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }

  isNumeric(col) {
    return col.numeric || col.fieldType === 'Numeric';
  }

  onRowExpand($event) {
    this.rowExpand.emit($event);
  }

  public setBaseClasses() {
    return {
      'eccc': !this.config.className,
      [this.config.className]: this.config.className,
    };
  }

  private _calculateGridColumnWidth() {
    if (this.config) {
      const totalWidth = this.config.columns
        .map(col => col.width ?
          (!isNaN(parseInt(col.width.split('px')[0], 10)) ?
            parseInt(col.width.split('px')[0], 10) : COLUMN_WIDTH) :
          COLUMN_WIDTH)
        .reduce((sum, x) => sum + x);

      this.gridWidth = null;
      if (totalWidth > window.innerWidth + 32) {
        this.gridWidth = totalWidth > 1300 ? 'xlWidth' : 'lgWidth';
      }
    }
  }
}
