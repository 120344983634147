import { interval } from 'rxjs';
import { share, skip, tap } from 'rxjs/operators';

export class CATSKeepAlive {

  private _interval: number;
  private _action: any;
  private _subscription$ = null;

  /**
   * Creates scheduler which executed given action (method) every intervalTime seconds
   * @param intervalTime - time interval in seconds
   * @param action - method to be invoked by the timer
   */
  constructor(
    intervalTime: number,
    action: any,
  ) {
    this._interval = intervalTime;
    this._action = action;
  }

  start() {
    this._subscription$ && this.stop();
    this._subscription$ = interval(this._interval * 1000)
      .pipe(
        tap(() => this._action && this._action()),
        share(),
      ).subscribe();
  }

  stop() {
    this._subscription$ && this._subscription$.unsubscribe();
  }

}
