import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const UPLOAD_SUB_PROJECTS = '/offset-service/submissions/subProjectReport/upload';
export const FIND_ACTIVE_CREDITS = '/obps-service/findActiveCredits';
export class ProjectReportService {
    constructor(http) {
        this.http = http;
        this.alternateContactPerson$ = new BehaviorSubject(null);
        this.baseUrl = '/offset-service/submissions';
    }
    get alternateContactPerson() {
        return this.alternateContact || null;
    }
    set alternateContactPerson(value) {
        this.alternateContact = value;
    }
    uploadSubProjects(formData) {
        return this.http.post(`${environment.apiUrl}${UPLOAD_SUB_PROJECTS}`, formData);
    }
    submit$(payload, method = 'submit') {
        return this.http.post(this.getUrl(method), payload);
    }
    save$(payload) {
        return this.http.post(this.getUrl(`save`), payload);
    }
    submitResponse$(payload) {
        return this.http.post(this.getUrl(`submitResponse`), payload);
    }
    submitNoticeOfIntentToCancelProject$(payload) {
        return this.http.post(this.getUrl(`submitNoticeOfIntentionToCancel`), payload);
    }
    saveNotification$(payload) {
        return this.http.post(this.getUrl(`saveNotification`), payload);
    }
    getById$(entityId) {
        return this.http.get(this.getUrl(`getById/${entityId}`));
    }
    getNewSubmissionsId$(payload) {
        return this.http.post(this.getUrl(`getNewSubmissionsId`), payload);
    }
    searchSubmissions$(body) {
        return this.http.post(this.getUrl('searchSubmissions'), body);
    }
    reportWorkflowAction(payload, uri) {
        return this.http.post(this.getUrl(uri), payload);
    }
    findActiveCredits(accountId, uri = FIND_ACTIVE_CREDITS) {
        return this.http.post(`${environment.apiUrl}${uri}/${accountId}`, {});
    }
    getUrl(part) {
        return `${environment.apiUrl}${this.baseUrl}/${part}`;
    }
}
ProjectReportService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProjectReportService_Factory() { return new ProjectReportService(i0.ɵɵinject(i1.HttpClient)); }, token: ProjectReportService, providedIn: "root" });
