/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-description.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../project/components/project-tasks/components/project-tasks/project-tasks.component.ngfactory";
import * as i3 from "../../project/components/project-tasks/components/project-tasks/project-tasks.component";
import * as i4 from "../../../services/tasks.service";
import * as i5 from "../../project/components/project-milestones/components/project-milestones/project-milestones.component.ngfactory";
import * as i6 from "../../project/components/project-milestones/components/project-milestones/project-milestones.component";
import * as i7 from "../../project/components/project-milestones/project-milestone.service";
import * as i8 from "../../../pipes/custom-translate.pipe";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i11 from "@ngx-formly/core";
import * as i12 from "@angular/forms";
import * as i13 from "@angular/common";
import * as i14 from "./project-description.component";
import * as i15 from "../../../services/form-factory.service";
import * as i16 from "../project-builder.service";
import * as i17 from "@angular/router";
var styles_ProjectDescriptionComponent = [i0.styles];
var RenderType_ProjectDescriptionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectDescriptionComponent, data: {} });
export { RenderType_ProjectDescriptionComponent as RenderType_ProjectDescriptionComponent };
function View_ProjectDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-tasks", [], null, null, null, i2.View_ProjectTasksComponent_0, i2.RenderType_ProjectTasksComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProjectTasksComponent, [i4.TasksService], { projectId: [0, "projectId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectBuilderService.model.id; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ProjectDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-milestones", [], null, null, null, i5.View_ProjectMilestonesComponent_0, i5.RenderType_ProjectMilestonesComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProjectMilestonesComponent, [i7.ProjectMilestoneService], { projectId: [0, "projectId"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.projectBuilderService.model.id; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProjectDescriptionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "pt-3 pr-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i8.CustomTranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "formly-form", [], null, null, null, i10.View_FormlyForm_0, i10.RenderType_FormlyForm)), i1.ɵprd(512, null, i11.FormlyFormBuilder, i11.FormlyFormBuilder, [i11.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(7, 966656, null, 0, i11.FormlyForm, [i11.FormlyFormBuilder, i11.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i12.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDescriptionComponent_1)), i1.ɵdid(9, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDescriptionComponent_2)), i1.ɵdid(11, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.projectBuilderService.projectForm; var currVal_2 = _co.projectBuilderService.model; var currVal_3 = _co.fields; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.isProjectActive; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.isProjectActive; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("PROJECTS_MODULE.ProjectBuilder.details")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ProjectDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-description", [], null, null, null, View_ProjectDescriptionComponent_0, RenderType_ProjectDescriptionComponent)), i1.ɵdid(1, 245760, null, 0, i14.ProjectDescriptionComponent, [i15.FormFactoryService, i16.ProjectBuilderService, i9.TranslateService, i17.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectDescriptionComponentNgFactory = i1.ɵccf("app-project-description", i14.ProjectDescriptionComponent, View_ProjectDescriptionComponent_Host_0, {}, {}, []);
export { ProjectDescriptionComponentNgFactory as ProjectDescriptionComponentNgFactory };
