

  <!--<p>{{ 'REGISTRATION_PAGE.cfsRegistrationForm.' + getAccredetationLabelDetails() | translate }}</p>-->

  <form [formGroup]="form">
    <formly-form [form]="form" [model]="data" [fields]="fields"></formly-form>
</form>

 <!--<p-fieldset legend="{{ 'REGISTRATION_PAGE.cfsRegistrationForm.proofOfAcreds' | translate }}">
    <button type="button" class="btn btn-secondary mr-2" (click)="documentUpload()">{{ 'REGISTRATION_PAGE.pendingRegistration.uploadDocs' | translate }}</button>
  </p-fieldset>-->

    <app-documents  [documentUploadConfig]="documentUploadConfig" [documentsPage]="documentsPage"  [disabled] ="disabled && !adminActionAllowed"
    (documentResult)="updateDocumentDetails($event)"  [showPlaceholders]="true">
    </app-documents>



