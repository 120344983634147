/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-table-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../table-filter/table-filter.component.ngfactory";
import * as i3 from "../table-filter/table-filter.component";
import * as i4 from "../../services/modal.service";
import * as i5 from "../../../core/services/lookup.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../core/store/store.service";
import * as i8 from "@angular/router";
import * as i9 from "../../services/helper.service";
import * as i10 from "../../services/upload-document.service";
import * as i11 from "@angular/common";
import * as i12 from "../../controls/generic-view-control/generic-view-control.component.ngfactory";
import * as i13 from "../../controls/generic-view-control/generic-view-control.component";
import * as i14 from "../../services/table-template-controls.service";
import * as i15 from "../../controls/generic-action-control/generic-action-control.component.ngfactory";
import * as i16 from "../../controls/generic-action-control/generic-action-control.component";
import * as i17 from "../../pipes/custom-translate.pipe";
import * as i18 from "../table-view/table-view.component.ngfactory";
import * as i19 from "../table-view/table-view.component";
import * as i20 from "./simple-table-wrapper.component";
import * as i21 from "@angular/common/http";
var styles_SimpleTableWrapperComponent = [i0.styles];
var RenderType_SimpleTableWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleTableWrapperComponent, data: {} });
export { RenderType_SimpleTableWrapperComponent as RenderType_SimpleTableWrapperComponent };
function View_SimpleTableWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-table-filter", [], null, [[null, "filterChanged"], [null, "filterInit"], [null, "filterCleared"], [null, "actionSelected"], [null, "newEntryAdded"], [null, "customButtonAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChanged" === en)) {
        var pd_0 = (_co.onFilterChanged($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterInit" === en)) {
        var pd_1 = (_co.onFilterChanged($event, false) !== false);
        ad = (pd_1 && ad);
    } if (("filterCleared" === en)) {
        var pd_2 = (_co.onFilterCleared() !== false);
        ad = (pd_2 && ad);
    } if (("actionSelected" === en)) {
        var pd_3 = (_co.onCheckboxActionSelected($event) !== false);
        ad = (pd_3 && ad);
    } if (("newEntryAdded" === en)) {
        var pd_4 = (_co.newEntryAdded($event) !== false);
        ad = (pd_4 && ad);
    } if (("customButtonAction" === en)) {
        var pd_5 = (_co.onCustomButtonAction($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i2.View_TableFilterComponent_0, i2.RenderType_TableFilterComponent)), i1.ɵdid(1, 770048, null, 0, i3.TableFilterComponent, [i1.ViewContainerRef, i4.ModalService, i5.LookupService, i6.TranslateService, i7.StoreService, i8.Router, i9.HelperService, i10.UploadDocumentService, i8.ActivatedRoute], { fields: [0, "fields"], config: [1, "config"], filterParams: [2, "filterParams"], wideMode: [3, "wideMode"] }, { actionSelected: "actionSelected", filterChanged: "filterChanged", filterCleared: "filterCleared", filterInit: "filterInit", newEntryAdded: "newEntryAdded", customButtonAction: "customButtonAction" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterFields; var currVal_1 = _co.tableConfig; var currVal_2 = _co.filterParams; var currVal_3 = _co.tableDetails.filterWideMode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SimpleTableWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SimpleTableWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "summary"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SimpleTableWrapperComponent_3)), i1.ɵdid(2, 540672, null, 0, i11.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { $implicit: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.tableData); var currVal_1 = _co.summaryTemplateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SimpleTableWrapperComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-view-control", [], null, null, null, i12.View_GenericViewControlComponent_0, i12.RenderType_GenericViewControlComponent)), i1.ɵdid(1, 245760, null, 0, i13.GenericViewControlComponent, [i8.Router, i9.HelperService, i4.ModalService, i14.TableTemplateControls], { rawData: [0, "rawData"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SimpleTableWrapperComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-action-control", [], null, [[null, "selectedAction"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedAction" === en)) {
        var pd_0 = (_co.onActionSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_GenericActionControlComponent_0, i15.RenderType_GenericActionControlComponent)), i1.ɵdid(1, 245760, null, 0, i16.GenericActionControlComponent, [i8.Router, i9.HelperService, i4.ModalService, i14.TableTemplateControls], { rawData: [0, "rawData"], appendTo: [1, "appendTo"] }, { selectedAction: "selectedAction" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = ".ui-table-wrapper table"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SimpleTableWrapperComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEditPopUp(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i17.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.EDIT")); _ck(_v, 2, 0, currVal_0); }); }
export function View_SimpleTableWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewColTemplate: 0 }), i1.ɵqud(402653184, 2, { actionTemplate: 0 }), i1.ɵqud(402653184, 3, { editActionTemplate: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "filters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleTableWrapperComponent_1)), i1.ɵdid(5, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleTableWrapperComponent_2)), i1.ɵdid(7, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], [null, "checkboxSelected"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } if (("checkboxSelected" === en)) {
        var pd_2 = (_co.onRowSelect($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i18.View_TableViewComponent_0, i18.RenderType_TableViewComponent)), i1.ɵdid(10, 638976, null, 0, i19.TableViewComponent, [i1.ElementRef, i6.TranslateService], { data: [0, "data"], config: [1, "config"], firstItemIndex: [2, "firstItemIndex"], selections: [3, "selections"] }, { paginationChanged: "paginationChanged", checkboxSelected: "checkboxSelected" }), (_l()(), i1.ɵand(0, [[1, 2], ["viewColTemplate", 2]], null, 0, null, View_SimpleTableWrapperComponent_4)), (_l()(), i1.ɵand(0, [[2, 2], ["actionTemplate", 2]], null, 0, null, View_SimpleTableWrapperComponent_5)), (_l()(), i1.ɵand(0, [[3, 2], ["editActionTemplate", 2]], null, 0, null, View_SimpleTableWrapperComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tableData && _co.filterFields); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.summaryTemplateRef; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.tableData; var currVal_3 = _co.tableConfig; var currVal_4 = _co.firstItemIndex; var currVal_5 = _co.selections; _ck(_v, 10, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_SimpleTableWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-simple-table-wrapper", [], null, null, null, View_SimpleTableWrapperComponent_0, RenderType_SimpleTableWrapperComponent)), i1.ɵdid(1, 245760, null, 0, i20.SimpleTableWrapperComponent, [i21.HttpClient, i7.StoreService, i5.LookupService, i4.ModalService, i8.ActivatedRoute, i6.TranslateService, i1.ViewContainerRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SimpleTableWrapperComponentNgFactory = i1.ɵccf("app-simple-table-wrapper", i20.SimpleTableWrapperComponent, View_SimpleTableWrapperComponent_Host_0, { tableDetails: "tableDetails", filterParams: "filterParams", initialFilterState: "initialFilterState", selections: "selections", refreshData: "refreshData" }, { exportTableData: "exportTableData", actionSelected: "actionSelected", selectedCheckboxes: "selectedCheckboxes", customButtonAction: "customButtonAction" }, []);
export { SimpleTableWrapperComponentNgFactory as SimpleTableWrapperComponentNgFactory };
