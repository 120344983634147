import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IFormPopupWrapperData } from './form-popup-wrapper.types';
import { IRawFormBase } from '@core/models/raw-form.types';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-form-popup-wrapper',
  templateUrl: './form-popup-wrapper.component.html',
  styleUrls: ['./form-popup-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPopupWrapperComponent implements OnInit {
  visible = true;
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  model: any;

  get title(): string {
    return this.data.title;
  }

  get width(): string {
    return this.data.width ? `${this.data.width}px` : '800px';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IFormPopupWrapperData,
    private dialogRef: MatDialogRef<FormPopupWrapperComponent>,
    private formFactorySvc: FormFactoryService,
  ) {}

  ngOnInit(): void {
    this.initFieldsSub();
    this.initModelSub();
    this.initUpdateModelSub();
  }

  changed(): void {
    this.dialogRef.close(null);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    this.formFactorySvc.markFormGroupDirty(this.form);
    if (this.form.valid) {
      this.dialogRef.close(this.form.value);
    }
  }

  private initFieldsSub(): void {
    this.fields = this.getFields(this.data.fields || []);
  }

  private initModelSub(): void {
    this.model = this.data.model;
  }

  private initUpdateModelSub(): void {
    const { updateModel } = this.data;

    if (typeof updateModel !== 'function') {
      return;
    }

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.model = updateModel(this.form);
    });
  }

  private getFields(source: IRawFormBase[]): FormlyFieldConfig[] {
    return this.formFactorySvc.configureForm(source);
  }
}
