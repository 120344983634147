import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IApp } from '@core/models/app.interfaces';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, skip, take, takeUntil } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs/internal/Observable';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { AppInjector } from '@shared/services/app-injector.service';
import { FormService } from '@shared/services/form.service';

@Component({
  selector: 'app-base-stepper-step',
  template: '<div></div>',
})
export class BaseStepperStepComponent implements OnDestroy, AfterViewInit {

  _svc: any;

  public _destroy$ = new Subject<any>();
  private containerEl: any;
  public scrollContainerDir: ElementRef;
  @ViewChild('ngForm', { static: true }) el: ElementRef;
  public pageEl: ElementRef;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  service: any;
  public model: any = {};
  requiredDocs: any;
  serviceMessageComponent: any;
  formService: any;
  wasDirty = false;

  requiredNestedFields = {};

  constructor() {
    const injector = AppInjector.getInjector();
    this.formService = injector.get(FormService);
  }

  ngAfterViewInit() {
    if (this._svc) {
      this.scrollContainerDir = this._svc.scrollEl;
      this._svc.stepValidationRequest$.pipe(skip(1), takeUntil(this._destroy$)).subscribe(() => this.onRequestValidation());
      this._svc.onSaveAndProceed$.pipe(takeUntil(this._destroy$)).subscribe(() => this.saveAndProceed());
      this._svc.onSubmit$.pipe(takeUntil(this._destroy$)).subscribe(() => this.submit());
      this._svc.onSaveAsDraft$.pipe(takeUntil(this._destroy$)).subscribe(() => this.saveAsDraft());
      this._svc.onProcessAction$.pipe(takeUntil(this._destroy$)).subscribe((data) => this.processAction(data));
      this._svc.onError$.pipe(takeUntil(this._destroy$)).subscribe(error => this.handleErrors(error));
      this.wasDirty = this._svc.isDirty;
    }
    setTimeout(() => {
      this.scrollToTop();
      this.focusFirstElement();
    });
  }

  resetModelChangeTrackingIfNeeded(): void {
    if (!this.wasDirty) {
      this.service.initModelChangeTracking();
    }
  }

  markFormAsDirty(form: FormGroup) {
    (Object).values(form.controls).forEach((control: any) => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormAsDirty(control as FormGroup);
      }
    });
  }

  onRequestValidation() {
    this._svc.stepValidationResponse = true;
  }

  validateForm(form: FormGroup, bypassDisabled: boolean = true) {
    if (bypassDisabled && (this._svc.disabled || (this.service && this.service.disabled))) {
      this._svc.stepValidationResponse = true;
      return true;
    }
    form.markAllAsTouched();
    this.markFormAsDirty(form);
    this._svc.stepValidationResponse = form.valid;
    if (!form.valid) {
      this.scrollToFirstInvalidControl();
    }
    return form.valid;
  }

  markInvalid() {
    this._svc.stepValidationResponse = false;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  getTitle() {
    const vb = this._svc.code === IApp.NSAccountSubType.StatusEnum.VB;
    if (this._svc._stepCode === 'RRSC') {
      return "SCOrganizationDetails";
    } else if (vb) {
      let title = 'vbRegRepSubmTitle';
      const subtype = this._svc.getAccountSubtype(IApp.NSAccountSubType.StatusEnum.VB);
      if (subtype) {
        if (subtype.activities && subtype.activities[0].code) {
          title = `${subtype.activities[0].code}ReportSubmission`;
        }
      }
      return title;
    }
    return "RegistrationReportSubmission";
  }

  private scrollToFirstInvalidControl(form = null) {
    const el = form || this.el;
    if (!el) {
      return;
    }

    this.getFirstInvalidControl(el).subscribe(firstInvalidControl => {
      if (this._svc.scrollEl) {
        this.containerEl = this._svc.scrollEl.nativeElement;
        this.containerEl.scroll({
          top: this.getTopOffset(firstInvalidControl),
          left: 0,
          behavior: 'smooth',
        });

        fromEvent(this.containerEl, 'scroll')
          .pipe(
            debounceTime(100),
            take(1),
          )
          .subscribe(() => firstInvalidControl.focus());
      }
    })
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    const controlElTop = controlEl.getBoundingClientRect().top;

    if (this.scrollContainerDir) {
      const containerTop = this.containerEl.getBoundingClientRect().top;
      const absoluteControlElTop = controlElTop + this.containerEl.scrollTop;

      return absoluteControlElTop - containerTop - labelOffset;
    } else {
      const absoluteControlElTop = controlElTop + window.scrollY;

      return absoluteControlElTop - labelOffset;
    }
  }

  public scrollToBottom() {
    if (this._svc && this._svc.scrollEl) {
      this.containerEl = this._svc.scrollEl.nativeElement;
      if (this.containerEl.children[0]) {
        this.containerEl.scroll({
          top: this.containerEl.children[0].offsetHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    }
  }

  public scrollToTop() {
    if (this._svc && this._svc.scrollEl) {
      this.containerEl = this._svc.scrollEl.nativeElement;
      this.containerEl.scroll({
        top: 0,
        left: 0,
      });
    }
  }

  private getFirstInvalidControl(parentEl): Observable<any> {
    const elList = parentEl.nativeElement.querySelectorAll('.ng-invalid:not(form)');
    let _found = false;
    return new Observable<any>(subscriber => {
      for (const el of elList) {
        if (!_found) {
          if (el.offsetParent) {
            _found = true;
            subscriber.next(el);
            subscriber.complete();
          }
        }
      }
    });
  }

  saveAndProceed() {
    return;
  }

  submit() {
    return;
  }

  saveAsDraft() {
    return;
  }

  handleErrors(error) {
    console.log(error);
    this.formService.parseErrors(this.form, error.error);
  }

  processAction(type){
    return;
  }

  get inputData() {
    return this.service.inputData;
  }

  set inputData(data) {
    this.service.inputData = data;
  }

  public checkForDisableState() {

    return !this._svc.disabled && (this.service.isDirty || !this.model.status) && (!this.model.status || (
     this.model.status === 'IN_PROCESS' ||
     this.model.status === 'PROPOSED' ||
     this.model.status === 'NEED_MORE_INFORMATION' ||
     this.model.status === 'PENDING_AGREEMENT_UPLOAD')
     );
  }

  public get isDisabled(): boolean {
    return this.service.disabled;
  }

  getDocumenUploadConfig(data, entityName, REQUIRED_DOCUMENT_TYPES, model,  statusColumn = false) {
    if (this['inputData'] && this['inputData'].documentType) {
      this.requiredDocs = data.filter(d => REQUIRED_DOCUMENT_TYPES[this['inputData'].documentType].includes(d.name));
    } else {
       this.requiredDocs = data.filter(d => REQUIRED_DOCUMENT_TYPES[model.formType].includes(d.name));
    }
    const documentTypeList = this.requiredDocs.map(d => (
      {
        id: model.id,
        entityName,
        documentTypeValue: d.id,
        documentTypeCode: d.name,
      }));

    return {
      documentType: true,
      id: this.model.id,
      title: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.addedDocuments',
      showPlaceholders: true,
      entityName,
      comment: true,
      entityDetail: this.service.inputData.legalName,
      statusColumn,
      pendingSubmissionStatus: true,
      disablePaginator: false,
      documentUploadConfigs: documentTypeList,
      //  documentTypeCodes,
      historicData: this.service.inputData.history,
      documents: this.service.currentData.documents,
    };
  }

  submitDocuments(uploadedDocumentTypeIds, message:string = 'mandatoryInformationMustBeProvidedException') {
    if (!this.requiredDocs.map(d => d.id).every(elem => uploadedDocumentTypeIds.indexOf(elem) > -1)) {
      this.serviceMessageComponent.showMessage(SereviceMessageType.ERROR, message);
    } else {
      this.service.currentData = this.model;
      this.model.legalName = this.service.inputData.legalName;
      this.service.submitDocuments(this.model);
    }
  }

  public focusFirstElement() {
    setTimeout(() => {
      if (this.pageEl) {
        const focusable = this.pageEl.nativeElement.querySelectorAll('button, [href], input:not(.wcag-visuallyhidden):not([tabindex="-1"]), select, [tabindex]:not([tabindex="-1"])');
        if (focusable.length) {
          focusable[0].focus();
        }
      }
    });
  }

}
