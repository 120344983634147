import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
 selector: 'formly-text-only-field',
 template: `
    <span [innerHTML]="currValue"></span>
    <input type="hidden" [value]="currValue" class="wcag-visuallyhidden" [id]="elementId"/>
  `,
})
export class FormlyFieldTextOnlyField extends FieldType implements OnInit {

  public translatePrefix;
  public type;

  constructor(
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.translatePrefix = this.to.translatePrefix ? `${ this.to.translatePrefix }.` : '';
    this.type = this.to.type;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  get currValue() {
    if (this.to.translate) {
      const translatedValue = this.model[this.field.key] ? this.translateService.instant(`${this.translatePrefix}${this.model[this.field.key]}`) : '';
      return translatedValue !== `${this.translatePrefix}${this.model[this.field.key]}` ? translatedValue : this.model[this.field.key];
    } else {
      return this.model && this.model[this.field.key];
    }
  }

}
