import { Component, OnDestroy, OnInit } from '@angular/core';
import { TasksService } from '@shared/services/tasks.service';
import { IApp } from '@core/models/app.interfaces';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_NUM_OF_ITEMS = 5;

@Component({
  selector: 'app-user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss'],
})
export class UserTasksComponent implements OnInit, OnDestroy {

  private pagination = {
    page: 0,
    size: DEFAULT_NUM_OF_ITEMS,
    sort: 'createdOn',
    dir: 'DESC',
  } as IApp.IPagination;
  private _destroy$ = new Subject<any>();

  public tasks: IApp.ITask[];
  public totalTasks: number = -1;
  labelPrefix: string;

  constructor(
    private taskService: TasksService,
    private store: StoreService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.labelPrefix = this.store.user.getProgramPrefix();
    this.loadTasks();
    this.translateService.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.loadTasks());

    this.taskService.valueChanges$
      .pipe(
        skip(1),
        takeUntil(this._destroy$))
      .subscribe(() => this.loadTasks());
  }

  loadTasks() {
    this.taskService.getAll(this.pagination)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(
        data => {
          this.tasks = data.content;
          this.totalTasks = data.totalElements;
          console.log(data);
        },
      );
  }

  public onPanelToggle(event) {
    const taskId = parseInt(event.panelId, 10);
    const taskIndex = this.tasks.findIndex(t => t.id === taskId);
    const task = this.tasks[taskIndex];
    if (event.nextState && !task.isRead) {
      this.taskService.markAsRead(taskId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(t => this.tasks[taskIndex].isRead = true);
    }
  }

  viewNumberOfItems(event, numOfItems = null) {
    event.preventDefault();
    this.pagination.size = numOfItems ? numOfItems : DEFAULT_NUM_OF_ITEMS;
    this.loadTasks();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
