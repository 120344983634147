/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./program-switcher.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../pipes/custom-translate.pipe";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./program-switcher.component";
import * as i7 from "../../../core/services/auth.service";
import * as i8 from "../../../core/store/store.service";
import * as i9 from "../../services/helper.service";
import * as i10 from "@angular/router";
var styles_ProgramSwitcherComponent = [i0.styles];
var RenderType_ProgramSwitcherComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgramSwitcherComponent, data: {} });
export { RenderType_ProgramSwitcherComponent as RenderType_ProgramSwitcherComponent };
function View_ProgramSwitcherComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchProgram(_v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[4, 4]], 0, i2.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).disabled; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(("COMMON.programTypeList." + _v.context.$implicit.name))); _ck(_v, 2, 0, currVal_1); }); }
function View_ProgramSwitcherComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["programDropdown", 1]], null, 17, "li", [["class", "nav-item"], ["ngbDropdown", ""], ["placement", "bottom-right"]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 1720320, null, 3, i2.NgbDropdown, [i1.ChangeDetectorRef, i2.NgbDropdownConfig, i5.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i2.ɵm]], { placement: [0, "placement"] }, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 7, "span", [["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["id", "programDropdown"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 6).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "programDropdown"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 14).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 16384, [[1, 4]], 1, i2.NgbDropdownMenu, [i2.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramSwitcherComponent_2)), i1.ɵdid(17, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-right"; _ck(_v, 1, 0, currVal_1); var currVal_8 = _co.programs; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("APP_HEADER.headerSection.selectedProgram")); _ck(_v, 8, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform(("COMMON.programTypeList." + _co.program))); _ck(_v, 11, 0, currVal_4); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 14).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 14).placement; _ck(_v, 13, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_ProgramSwitcherComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProgramSwitcherComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.programs == null) ? null : _co.programs.length) > 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProgramSwitcherComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-program-switcher", [], null, null, null, View_ProgramSwitcherComponent_0, RenderType_ProgramSwitcherComponent)), i1.ɵdid(1, 245760, null, 0, i6.ProgramSwitcherComponent, [i7.AuthService, i8.StoreService, i9.HelperService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgramSwitcherComponentNgFactory = i1.ɵccf("app-program-switcher", i6.ProgramSwitcherComponent, View_ProgramSwitcherComponent_Host_0, {}, {}, []);
export { ProgramSwitcherComponentNgFactory as ProgramSwitcherComponentNgFactory };
