import { IProjectDiscussionFilter, IProjectDiscussionSort } from './project-discussion.types';

export const PROJECT_DISCUSSION_FILTER_ALL: IProjectDiscussionFilter = {
  label: 'PROJECTS_MODULE.DISCUSSION.discussionFilterAll',
  value: null,
};

export const PROJECT_DISCUSSION_SORT_ASC: IProjectDiscussionSort = {
  label: 'PROJECTS_MODULE.DISCUSSION.discussionSortAscending',
  value: 'ASC',
};
export const PROJECT_DISCUSSION_SORT_DESC: IProjectDiscussionSort = {
  label: 'PROJECTS_MODULE.DISCUSSION.discussionSortDescending',
  value: 'DESC',
};

export const PROJECT_DISCUSSION_SORT = [
  PROJECT_DISCUSSION_SORT_ASC,
  PROJECT_DISCUSSION_SORT_DESC,
];
