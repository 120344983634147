<h2>{{ 'ACCOUNTS_MODULE.accountList.reportDetails' | translate }}</h2>
<div class="table-area">
  <app-table-view
    [config]="tableConfig"
    [data]="regDetailsList"
    (paginationChanged)="onPaginationChanged($event)">
  </app-table-view>
</div>

<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewAccount($event, data)"
          (keyup)="viewAccount($event, data)">
      {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #actionsTemplate let-data>
  <app-reg-rept-actions [actionsList]="data.entityActionList" [accountId]="accountId"
  [data]="data" [appendTo]="'app-reg-rept-list'"
  (actionSuccessfull)="refreshData($event)" [accountStatus]="accountStatus"></app-reg-rept-actions>
</ng-template>
