<p-dialog
  (visibleChange)="changed()"
  [(visible)]="visible"
  [autoZIndex]="false"
  [className]="'eccc'"
  [header]="title | translate"
  [maximizable]="false"
  [minY]="100"
  [modal]="true"
  [responsive]="true"
  [style]="{ width: width }"
>
  <form [formGroup]="form">
    <formly-form [fields]="fields" [form]="form" [model]="model"></formly-form>
  </form>
  <div class="d-flex justify-content-end mt-2">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{ 'PROJECTS_MODULE.ProjectBuilder.close' | translate }}</button>
    <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!data.disabled">
      {{ 'PROJECTS_MODULE.ProjectBuilder.save' | translate }}
    </button>
  </div>
</p-dialog>
