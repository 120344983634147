import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { IProjectMilestone } from '@shared/components/project/components/project-milestones/project-milestone.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const PROJECT_MILESTONES_URL = '/offset-service/project/projectDeadlineReport?sort=deadLineDate&dir=ASC&size=10';

@Injectable()
export class ProjectMilestoneService {

  constructor(
    private http: HttpClient,
  ) { }

  public getMilestones(projectId): Observable<IProjectMilestone[]> {
    const payload = {projectIdList: [projectId]};
    return this.http.post<IApp.IReportList>(`${ environment.apiUrl }${ PROJECT_MILESTONES_URL }`, payload)
      .pipe(map(results => results.content));
  }
}
