

<div id="sign-agreement">
    <h2>{{'AGREEMENT_CREDITS.Step_6.' +suffix  | translate }}</h2>

    <p>{{'AGREEMENT_CREDITS.Step_6.message' | translate }}</p>

    <div class="container mt-5">
 
   <form [formGroup]="form" #ngForm>
      <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
    </form>
    </div>


    
</div>