import { CfsOrgRoleComponent } from '@shared/components/steps/cfr-registration/cfs-org-role/cfs-org-role.component';
import { CfsOrgAccRegComponent } from '@shared/components/steps/cfr-registration/cfs-org-acc-reg/cfs-org-acc-reg.component';
import { CfsSecureSignInComponent } from '@shared/components/steps/cfr-registration/cfs-secure-sign-in/cfs-secure-sign-in.component';
import { CfsVbAccRegComponent } from '@shared/components/steps/cfr-registration/cfs-vb-acc-reg/cfs-vb-acc-reg.component';

export const CFS_REGISTRATION_STEP_TYPE = {
    CFS_ORG_ROLE: 'CfsOrgRoleComponent',
    CFS_ORG_ACC_REG: 'CfsOrgAccRegComponent',
    CFS_SECURE_SIGN_IN: 'CfsSecureSignInComponent',
    CFS_VB_ACC_REG: 'CfsVbAccRegComponent',
};

export const CfsRegistration = {
    page_title: 'REGISTRATION_PAGE.stepperSection.cfsRegistrationOrgRoleTile',

    progress_bar_config: [
        {
            id: 1,
            name: 'SELECT_ORG_ROLE',
            completed: false,
            hidden: false,
        },
        {
            id: 2,
            name: 'REGISTRATION_INFO',
            completed: false,
            hidden: false,
        },
        {
            id: 3,
            name: 'SECURE_SIGN_IN',
            completed: false,
            hidden: false,
            preventNavigation: true,
        },
    ],
    steps: [
        {
            id: 1,
            type: CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ROLE,
            step_title: 'REGISTRATION_PAGE.stepperSection.step1Title',
            page_sub_title: 'REGISTRATION_PAGE.stepperSection.cfsRegistrationOrgRoleSubTile',
            before_next: false,
            next: false,
            back: true,
            updateStep: true,
            inputData: null,
        },
        {
            id: 2,
            type: CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG,
            step_title: 'REGISTRATION_PAGE.stepperSection.step2',
            page_sub_title: 'REGISTRATION_PAGE.stepperSection.cfsOrgAccRegSubTitle',
            before_next: true,
            next: false,
            back: true,
            updateStep: true,
            inputData: null,
        },
        {
            id: 2,
            type: CFS_REGISTRATION_STEP_TYPE.CFS_VB_ACC_REG,
            step_title: 'REGISTRATION_PAGE.stepperSection.cfsVBOrgAccRegPageTitle',
            page_sub_title: 'REGISTRATION_PAGE.stepperSection.cfsOrgAccRegSubTitle',
            before_next: true,
            next: false,
            back: true,
            updateStep: true,
            inputData: null,
        },
        {
            id: 3,
            type: CFS_REGISTRATION_STEP_TYPE.CFS_SECURE_SIGN_IN,
            before_next: false,
            next: false,
            back: false,
            inputData: null,
        },
    ],
};
