import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ServiceMessageComponent } from "@core/components/service-message/service-message.component";
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from "@core/constants/serviceMessage.const";
import { IApp } from "@core/models/app.interfaces";
import { environment } from "@env/environment";
import { BaseStepperService } from "@shared/components/base-stepper/base-stepper.service";
import { ModalService } from "@shared/services/modal.service";
import { UploadDocumentService } from "@shared/services/upload-document.service";
import { BehaviorSubject, from, Observable } from "rxjs";
import { switchMap, filter, tap } from "rxjs/operators";

export const ACCOUNT_DETAILS = '/account-service/account/detail/';
export const TRANSFEREE_DETAILS = '/account-service/agreementTuc/searchTransferee/';
export const SAVE_AGREEMENT = '/account-service/agreementTuc/save';
export const SAVE_AND_PROCEED_AGREEMENT = '/account-service/agreementTuc/saveAndProceed';
export const UPDATE_AGREEMENT_STATUS = '/account-service/agreementTuc/updateStatus';
export const LOAD_AGREEMENT_BY_ID = '/account-service/agreementTuc/load';
export const GET_REGISTRATION_REPORT = '/account-service/cfrAccount/getRegistrationReport/';
export const VALIDATE_AGREEMENT_REQUEST = '/account-service/agreementTuc/validate/';

export const AGREEMENT_TUC_DATA_KEY = 'agreement_tuc_data';
export const AGREEMENT_TUC_CURRENT_STEP_KEY = 'agreement_tuc_step';

const STEPS_TRANSLATION_PREFIX = 'SUBMISSION_MANAGEMENT_MODULE.AGREEMENT_TO_CREDIT.';


@Injectable({
  providedIn: 'root',
})
export class AgreementToTransferCreditService extends BaseStepperService {

  public translationPrefix = STEPS_TRANSLATION_PREFIX;
  public stepperTitle = 'SUBMISSION_MANAGEMENT_MODULE.AGREEMENT_TO_CREDIT.pageTitleCreateForm';
  public carbonIntensityIds$ = new BehaviorSubject<IApp.ILookup[]>([]);
  public registrationReportId;
  public footerButtonConfig:any = {};
  public fuelList = [];

  constructor(
    private http: HttpClient,
    private uploadDocumentService: UploadDocumentService,
    private modalService: ModalService
  ) {
    super();
  }

  public getAccountByID(id) {
    return this.http.get<any>(`${ environment.apiUrl}${ACCOUNT_DETAILS}${id}`).pipe(
      tap(data => {
        this.currentData.transferorAccount = {...data, accountId: data.id};
      })
    );
  }

  public getTransfereeDetails(id) {
    return this.http.get<any>(`${ environment.apiUrl}${TRANSFEREE_DETAILS}${id}`).pipe(
      tap(data => {
        this.currentData.transfereeAccount = {...data, accountId: data.id};
      })
    );
  }

  public save(payload) {
    payload = {...payload, action: 'SAVE'};
    return this.http.post<any>(`${ environment.apiUrl}${SAVE_AND_PROCEED_AGREEMENT}`, payload);
  }

  public saveDraft(payload) {
    payload = {...payload, action: 'SAVE'};
    return this.http.post<any>(`${ environment.apiUrl}${SAVE_AGREEMENT}`, payload);
  }

  public updateStatus(payload) {
    return this.http.post<any>(`${ environment.apiUrl}${UPDATE_AGREEMENT_STATUS}`, payload);
  }

  public submit() {
    return this.http.post<any>(`${ environment.apiUrl}${SAVE_AGREEMENT}`, {...this.currentData, action: 'SUBMIT'});
  }

  public getAgreementByID(id) {
    return this.http.get<any>(`${ environment.apiUrl}${LOAD_AGREEMENT_BY_ID}/${id}`);
  }

  public getRegistrationReport(accountId) {
    return this.http.get<any>(`${ environment.apiUrl}${GET_REGISTRATION_REPORT}${accountId}`);
  }

  public  storeCurrentData() {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(AGREEMENT_TUC_DATA_KEY, data);
    const currentStep = JSON.stringify(this.currentStep);
    sessionStorage.setItem(AGREEMENT_TUC_CURRENT_STEP_KEY, currentStep);
  }

  public checkForStoredData() {
    const currentData = sessionStorage.getItem(AGREEMENT_TUC_DATA_KEY);
    const currentStep = sessionStorage.getItem(AGREEMENT_TUC_CURRENT_STEP_KEY);
    if (currentData && currentStep) {
      this.currentData = JSON.parse(currentData);
      this.currentStep = JSON.parse(currentStep);
      sessionStorage.removeItem(AGREEMENT_TUC_DATA_KEY);
      sessionStorage.removeItem(AGREEMENT_TUC_CURRENT_STEP_KEY);
      return true;
    }
    return false;
  }

  public checkIfSaveRequired() {
    return !(this.currentData.transferorConsent || this.currentData.transfereeConsent);
  }

  public downloadAgreement(id, signed?:boolean) {
    let uri = `${ environment.serviceUrl}/account-service/agreementTuc/download/${id}` ;
    if (signed) {
      uri = uri + '?signed=true'
    }
    this.uploadDocumentService.downloadDocument(uri);
  }

  public openWarningPopUp(message: string, metaDataList = []): Observable<any> {
    return this.modalService.open(
        ServiceMessageComponent,
        {
            messages: null,
            message,
            metaDataList,
            type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
    ).afterClosed()
  }

  public validateAgreementRequest(accountId) {
    return this.http.get(`${ environment.apiUrl}${VALIDATE_AGREEMENT_REQUEST}${accountId}`).pipe(
      switchMap(result => {
        if (result) {
          return from([true]);
        } else {
          return this.showErrorMessage('applicationNotAllowedForActivity');
        }
      }),
      filter(result => !!result)
    );
  }

  public showErrorMessage(message: string) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            },
          ],
          message: message,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }
}
