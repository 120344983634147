import { NavigationEnd } from "@angular/router";
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class PreviousLocationService {
    constructor(router) {
        this.router = router;
        this._previousLocation = [];
        this.locations = {};
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event) => {
            if (this._previousLocation[length - 1] !== event.url) {
                this._previousLocation.push(event.url);
            }
            if (this._previousLocation.length > 2) {
                this._previousLocation.splice(0, 1);
            }
        });
    }
    get previousLocation() {
        return this._previousLocation[0];
    }
}
PreviousLocationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PreviousLocationService_Factory() { return new PreviousLocationService(i0.ɵɵinject(i1.Router)); }, token: PreviousLocationService, providedIn: "root" });
