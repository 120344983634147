/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-list-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../project-list/project-list.component.ngfactory";
import * as i3 from "../project-list/project-list.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../services/project.service";
import * as i6 from "../../../services/table-template-controls.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../services/notifications.service";
import * as i9 from "../../../../core/services/lookup.service";
import * as i10 from "../../../services/helper.service";
import * as i11 from "../../../services/modal.service";
import * as i12 from "../../../../core/store/store.service";
import * as i13 from "./project-list-wrapper.component";
var styles_ProjectListWrapperComponent = [i0.styles];
var RenderType_ProjectListWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectListWrapperComponent, data: {} });
export { RenderType_ProjectListWrapperComponent as RenderType_ProjectListWrapperComponent };
export function View_ProjectListWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-project-list", [], null, null, null, i2.View_ProjectListComponent_0, i2.RenderType_ProjectListComponent)), i1.ɵdid(2, 245760, null, 0, i3.ProjectListComponent, [i4.ActivatedRoute, i5.ProjectService, i6.TableTemplateControls, i7.TranslateService, i8.NotificationsService, i9.LookupService, i10.HelperService, i11.ModalService, i4.Router, i12.StoreService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ProjectListWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-list-wrapper", [], null, null, null, View_ProjectListWrapperComponent_0, RenderType_ProjectListWrapperComponent)), i1.ɵdid(1, 49152, null, 0, i13.ProjectListWrapperComponent, [], null, null)], null, null); }
var ProjectListWrapperComponentNgFactory = i1.ɵccf("app-project-list-wrapper", i13.ProjectListWrapperComponent, View_ProjectListWrapperComponent_Host_0, {}, {}, []);
export { ProjectListWrapperComponentNgFactory as ProjectListWrapperComponentNgFactory };
