/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-discussion-chat-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./project-discussion-chat-header.component";
var styles_ProjectDiscussionChatHeaderComponent = [i0.styles];
var RenderType_ProjectDiscussionChatHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectDiscussionChatHeaderComponent, data: {} });
export { RenderType_ProjectDiscussionChatHeaderComponent as RenderType_ProjectDiscussionChatHeaderComponent };
export function View_ProjectDiscussionChatHeaderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "header d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵted(5, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), ((_co.entry == null) ? null : _co.entry.commentedOn), "dd MMM y (HH:mm)")); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.entry == null) ? null : _co.entry.commentedByUser); _ck(_v, 5, 0, currVal_1); }); }
export function View_ProjectDiscussionChatHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-discussion-chat-header", [], null, null, null, View_ProjectDiscussionChatHeaderComponent_0, RenderType_ProjectDiscussionChatHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProjectDiscussionChatHeaderComponent, [], null, null)], null, null); }
var ProjectDiscussionChatHeaderComponentNgFactory = i1.ɵccf("app-project-discussion-chat-header", i3.ProjectDiscussionChatHeaderComponent, View_ProjectDiscussionChatHeaderComponent_Host_0, { entry: "entry" }, {}, []);
export { ProjectDiscussionChatHeaderComponentNgFactory as ProjectDiscussionChatHeaderComponentNgFactory };
