
    <label *ngIf="to.dummyLabel === true" [attr.for]="elementId" class="wcag-visuallyhidden">
      <span [innerHTML]="elementId"></span>
    </label>
    <textarea [formControl]="formControl" [cols]="to.cols"
      [rows]="to.rows" class="form-control" [class.is-invalid]="showError"
      [id]="elementId"
      [readOnly]="disabledField"
      [formlyAttributes]="field"
      [tabIndex]="tabIndex"
      title="">
    </textarea>
  