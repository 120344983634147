import { Component, OnInit, Inject } from '@angular/core';
import { CREDIT_REVENUE_REPORT_PERIOD_FLD, getCreditRevenueReportPeriod } from '../credit-revenue-report.const';
import { getOrganizationName } from '@module/credit-creation-reports/routines/create-credit-popup.routine';
import { IRawFormBase } from '@core/models/raw-form.types';
import { Router} from '@angular/router';

import { Subject} from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { ICreditCreationReportPayload } from '@module/credit-creation-reports/credit-creation-reports.types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CreateCreditBalanceReportComponent } from '@module/credit-balance/components/create-credit-balance-report/create-credit-balance-report.component';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { CreditBalanceReportService } from '@module/credit-balance/credit-balance-report.service';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';

import { takeUntil} from 'rxjs/operators'
import { CreditRevenueReportService } from '../credit-revenue-report.service';
@Component({
  selector: 'app-create-credit-revenue-report',
  templateUrl: './create-credit-revenue-report.component.html',
  styleUrls: ['./create-credit-revenue-report.component.scss']
})
export class CreateCreditRevenueReportComponent implements OnInit {
  private _destroy$ = new Subject<any>();

  visible = true;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  data: ICreditCreationReportPayload = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<CreateCreditBalanceReportComponent>,
    private formFactoryService: FormFactoryService,
    private service: CreditRevenueReportService,
    private store: StoreService,
    private router: Router,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.fields = this.formFactoryService.configureForm(this.getFormFields());
  }

  get payload(): ICreditCreationReportPayload {
    return {
      accountId: this.hideOrgDropdown ? this.store.user.accountId : this.data.accountId,
      compliancePeriod: +this.data[CREDIT_REVENUE_REPORT_PERIOD_FLD],
    };
  }

  create(): void {
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    if (this.form.valid) {
      this.service.createCreditRevenueReport(this.payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(resp => {
          this.close();
          this.router.navigate(this.helperService.getTranslatedPath('/credit-revenue/report'), {state: {data: resp}});
        });
    }
  }

  getFormFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getOrganizationName(null, this.hideOrgDropdown),
          getCreditRevenueReportPeriod(),
        ],
      }];
  }

  private get hideOrgDropdown(): boolean {
    const isCustomer = this.store.user.hasPermission('MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER');
    if (this.store.user.associationsList.length === 1 && isCustomer) {
      this.data.accountId = this.store.user.associationsList[0].accountId;
      return true;
    } else {
      return false;
    }
  }

  close() {
    this.visible = false;
    this._destroy$.next();
    this._destroy$.complete();
    this.dialogRef.close();
  }

}
