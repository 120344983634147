import { IApp } from '@core/models/app.interfaces';


export interface ITableFilterActionModel{
  filterListsModel?: IFilterModel[];
  actionModel?: IActionModel[];
  searchBox?: boolean;
  searchButtons?: boolean;
  dateRange?: boolean;
  datePicker?: boolean;
  addNewRow?: IAddNewRow;
  openNewPageForNewRow?: IOpenNewPageForNewRow;
  adminAction?: boolean;
  adminActions?: IActionModel[];
  customButtons?: ICustomButton[];
  options?: {
    searchButtons?: {
      clearButtonOnly?: boolean;
    }
    dateRange?: {
      placeholder?: string;
      startDateField?: string;
      endDateField?: string;
    },
    datePicker?: {
      dateField?: string;
      format?: string;
    },
  };
  dummy?: boolean;
}

export interface IDateRangeFields {
  startDate?: string;
  endDate?: string;
}

export interface IDateFieldConfig {
  dateField?: string;
  format?: string;
}

export interface ICustomButton {
  label?: string;
  ignoreLayout?: boolean;
}

export interface IOpenNewPageForNewRow {
  button?:boolean;
  label?:string;
  permission?: string;
  uri?:string;
}

export interface IAddNewRow {
  button?:boolean;
  label?:string;
  permission?: string;
}

export interface IFilterModel {
  placeholder?: string;
  itemsType?: string;
  multiple?: boolean;
  selectableGroup?: boolean;
  closeOnSelect?: boolean;
  ngModel?: string;
  groupBy?: string;
  flattenResponse?: boolean;
  programs?: string[];
  dropdownFullWidth?: boolean;
}

export interface IActionModel {
  data_id?:string;
  id?: string;
  placeholder?: string;
  options?: IActionOptionsModel[];
  className?: string;
  position?:number;
}

export interface IActionOptionsModel {
  id?: any;
  labelFormatted?:string;
  label?: string;
  uri?: string;
  type?: ActionOptionType;
  confirmationRequired?: boolean;
  default?: boolean;
  additionalParameter?: any;
  typeNotRequired?: boolean;
  typeValue?: string;
}

export interface ITableFilterSortedField {
  fieldType: string;
  index?: number;
  last?: boolean;
  data?: any;
  options?: any;
}

export enum ActionOptionType{
  APPROVE, REJECT,
  URL,
  FILEUPLOAD,
  DOWNLOAD,
  SERVICE,
  EXPORT,
  OBPS_SET_ISSUANCES,
  BULK_APPROVE,
  BULK_REJECT
}

export class FilterFieldsLists{
  accounts?: IApp.IAccountData[];
  accountTypes?: IApp.ILookup[];
  accountSubTypeNames?: IApp.ILookup[];
  facilityStatus?: IApp.ILookup[];
  cfrFacilityStatus?: IApp.ILookup[];
  facilityNames?: IApp.ILookup[];
  crossFacilityNames?: IApp.ILookup[];
  crossAccountIds?: IApp.ILookup[];
  accountIds?: IApp.ILookup[];
  responsiblePersons?: IApp.ILookup[];
  facilityCertificateNos?: IApp.ILookup[];
  obligationStatus?: IApp.ILookup[];
  moreActionsList?: IApp.ILookup[];
  unitStatus? : IApp.ILookup[];
  ruUnitStatus? : IApp.ILookup[];
  compliancePeriod?: IApp.ILookup[];
  resultCompliancePeriods?: IApp.ILookup[];
  versions?: IApp.ILookup[];
  obpsResortType?: IApp.ILookup[];
  versionStatus?: IApp.ILookup[];
  paymentStatus?: IApp.ILookup[];
  transactionType?: IApp.ILookup[];
  obligationType?: IApp.ILookup[];
  compensationStatus?: IApp.ILookup[];
  paymentRefundModes?: IApp.ILookup[];
  paymentRefundStatus?: IApp.ILookup[];
  documentStatusList?: IApp.ILookup[];
  remittanceTypes?: IApp.ILookup[];
  taskTypeList?: IApp.ILookup[];
  workflowApprovalTypes?: IApp.ILookup[];
  assignedToList?: IApp.ILookup[];
  entityTypes?: IApp.ILookup[];
  provinces?:IApp.ILookup[];
  submissionType?:IApp.ILookup[];
  submissionsType?:IApp.ILookup[];
  reportingPeriod?:IApp.ILookup[];
  typeCode?: IApp.ILookup[];
  assignedVB?: IApp.ILookup[];
  regActivityTypes?: IApp.ILookup[];
  cfrCompliancePeriod? : IApp.ILookup[];
  submissionReportStatus? : IApp.ILookup[];
  areaOfExpertise? : IApp.ILookup[];
  allAreaOfExpertise?: IApp.ILookup[];
  quantificationMethods ?: IApp.ILookup[];
  feedstockTypes ?: IApp.ILookup[];
  fuelTypes ?: IApp.ILookup[];
  facilitiesList ?: IApp.ILookup[];
  taskFamilyList?: IApp.ILookup[];
  creditGroupist?: IApp.ILookup[];
  creditClassList?: IApp.ILookup[];
  unitType?: any;
  publicUnitStatus?: IApp.ILookup[];
  compliancePeriodList?: IApp.ILookup[];
  ccrCompliancePeriod?: IApp.ILookup[];
  vbLegalName?: IApp.ILookup[];
  vbCountry?: IApp.ILookup[];
  vbProvinces?: IApp.ILookup[];
  cfrComplianceReportCompliancePeriod?: IApp.ILookup[];
}
