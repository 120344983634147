import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { CurrentUser } from '@core/models/user.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { UnitManagementService } from '@module/unit-management/services/unit-management.service';
import { unitManagementProjectTransferTableConfig } from '@module/unit-management/unit-management.const';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BackbtnserviceService } from '@shared/components/back-btn/backbtnservice.service';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { FormService } from '@shared/services/form.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { ProjectService } from '@shared/services/project.service';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProjTransApproveDTO, ProjTransNeedMoreInfoDTO, ProjTransRejectDTO, ProjTransReSubmitDTO, ProjTransSaveDTO, ProjTransSubmitDTO } from './projec-transfer-models';
import { ProjectTransferService } from './project-transfer.service';

import { get } from 'lodash';

export const SELECT_UNIT = {
  tableListApiUri: '/obps-service/unit/findAll?size=20',
  tableHeaderType: 'ACCOUNT_HISTORY',
  tableFilter: null,
  hasViewTemple: true,
  hasActionTemple: false,
};
@Component({
  selector: 'app-project-transfer-request',
  templateUrl: './project-transfer-request.component.html',
  styleUrls: ['./project-transfer-request.component.scss'],
})
export class ProjectTransferRequestComponent implements OnInit {

  constructor(

    private unitManagementService: UnitManagementService,
    private translateService: TranslateService,
    private projectService: ProjectService,
    private modalService: ModalService,
    private projectTransferService: ProjectTransferService,
    private activatedRoute: ActivatedRoute,
    private backbtnserviceService: BackbtnserviceService,
    private accountManagementService: AccountManagementService,
    private store: StoreService,
    public documentUploadInProgress: DocumentUploadInProgressService,
    private router: Router,
    private helperService: HelperService,
    private formService: FormService,
    private navigation: NavigationService,
    private lookupService: LookupService,
    private notificationService: NotificationsService) { }

  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;

  tableDetails = SELECT_UNIT;
  private _destroy$ = new Subject<any>();
  user: CurrentUser = this.store.user;
  projectId: string;
  projectIdMissing: boolean;
  formType = '';
  projectTransferId: any;
  status = '';
  statusName = '';
  entityActionList: any[] = [];
  subtitle = '';
  maintitle = 'field_0';
  projectAccountData: any = {};

  model: any = {};
  form = new FormGroup({});
  formFields: FormlyFieldConfig[];

  documentsPage: IApp.IDocumentsPage;
  documentUploadConfig: IApp.IDocumentUploadConfig = {
    id: null,
    comment: true,
    documentType: true,
    entityName: null,
    title: 'PROJECT_CANCELLATION.actionLabels.uploadDocument',
    disablePaginator: false,
    documentUploadConfigs : [],
    pendingSubmissionStatus: true,
    extraUploadDocuments : null,
  };
  loaded = false;
  project_type: any = null;
  additionalInfoRequested = false;
  discussions: any[];
  disableDocUpload = true;

  curProjDtls: any = {};

  curProjDtls_INIT: any = {};

  newApplicantDetails: any = {};

  projDtls: any = {};

  sub_stnd_projc: any = {};

  search_dst: any = {}; // Search Destination

  search_dst_2: any = {}; // Search Destination

  reason_proj_trans: any = {}; // Reasons for project Transfer

  reason_proj_trans_b: any = {}; // Reasons for project Transfer

  dst_acc: any = {}; // Destination Account Name

  dst_acc2: any = {}; // Destination Account Name

  chbx1: any = {};

  chbx2: any = {};

  search_dst_agg_proj: any = {};
  // Search Destination Aggregated Project

  dst_agg_proj_dtls: any = {}; // Destination Aggregated Project Name:

  dst_proj_dtls_1: any = {};

  dst_proj_dtls_2: any = {};

  // Admin Reviews
  admin_review: any = {};

  // Previous Discussions
  prev_discussion: any = {};

  // Search Project
  search_project: any = {};

  /* Form type */
  /*****************************************/
  mockup1: any[] = [];

  mockup2: any[] = [];

  mockup3: any[] = [];

  mockup4: any[] = [];

  mockup5: any[] = [];

  mockup5_b: any[] = [];

  mockup6: any[] = [];

  mockup8: any[] = [];

  mockup8_b: any[] = [];

  search_input_field_state: any;

    adminPermission: boolean = this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS);

  destAccountIdForProjecTransferInit: number;
  activitylog = false;

  projectNameID: string;

  transferId: number;

  missingDocumentList: any;

  executedAction: string;
  displayUnits = false;

  tableConfig = unitManagementProjectTransferTableConfig;
  dataList: any;

  unitList: any[] = [];

  attestation:any = {};
  aggregatedAttestation:any = {};

  private _langChanged = false;

  ngOnInit() {

    this.setFormFields();
    this.model = { type: '' };
    this.evalUrlParams();
    this.loadForm();
    this.translateService.onLangChange.pipe(
      takeUntil(this._destroy$)).subscribe(() => {
        if (this.model['projectId']) {
          this.search_input_field_state = this.model['projectId'];
        } else {
          this.search_input_field_state = this.model['accountIdSearch'];
        }
        this.setFormFields();
        this._langChanged = true;
        this.loadForm();
      });
    this.callTableConfig();
  }

  callTableConfig() {

    this.tableConfig = {
      translationPrefix: 'UNITS_MODULE.unitList',
      paginator: true,
      rowsPerPage: 20,
      columns: [
        {
          checkboxSelect: true,
          width: '3em',
        },
        {
          field: 'facilityName',
          header: 'accountName',
          sortField: 'facilityName',
        },
        {
          field: 'quantity',
          header: 'quantity',
          sortField: 'quantity',
        },
        {
          field: 'serialNumber',
          header: 'serialNumber',
          sortField: 'serialNumber',
        },
        {
          field: 'status',
          header: 'status',
          translate: true,
          translationPrefix: 'COMMON.statusList',
          sortField: 'status',
        },
        {
          header: 'details',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.viewColTemplate,
        },
      ],
    };

  }

  viewUnitDetails(event, unit: IApp.IUnitData) {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    this.unitManagementService.viewUnitDetails(unit.unitClassCode , unit);
   }

  loadForm() {
    switch (this.formType) {
      case 'TRANSFER_AGGREGATED_PROJECT':
      case 'TRANSFER_PROJECT':
      case 'REQUEST_TRANSFER_PROJECT':
      case 'TRANSFER_PROJECT_SUB':
        this.formFields = this.mockup1; // mock up 2 is loaded if in mock up 1 is selected
        this.subtitle = 'field_1';
        this.getDocumentConfig();
        this.disableDocUpload = false;
        this.entityActionList = [
          {workflowAction: 'TRANSFER_PROJECT', enabled: false, id: null},
        ];

        if (this.formType === 'TRANSFER_AGGREGATED_PROJECT') {
          this.model.type = 'MASTER';
          this.subtitle = 'field_1c';
        }

        if (this.formType === 'TRANSFER_PROJECT_SUB') {
          this.maintitle = 'field_0_c';
          this.model.type = 'SUB';
        }
        break;

      case 'REVIEW_PROJECT_TRANSFER_REQUEST':
      case 'VIEW_PROJECT_TRANSFER_REQUEST':
        this.subtitle = 'field_1b';
        let formFields;
        if (this.formType === 'REVIEW_PROJECT_TRANSFER_REQUEST') {
          formFields = this.mockup3;
          this.additionalInfoRequested = true;
        }
        if (this.formType  === 'VIEW_PROJECT_TRANSFER_REQUEST') {
          formFields = this.mockup4; // add amin review here as well

        }
        this.getProjectTransferDetail(formFields);
        this.disableReasonProjectTransferAndCheckbox();

        break;

      case 'VIEW_PROJECT_TRANSFER_REQUEST_PREV':
        this.subtitle = 'field_1b';
        this.getPrevProjectTransferDetail(this.mockup4);
        this.disableReasonProjectTransferAndCheckbox();

        break;

      case 'RESUBMIT':
        this.subtitle = 'field_1';
        this.getProjectTransferDetail(this.mockup5);
        this.disableReasonProjectTransferAndCheckbox();
        this.additionalInfoRequested = true;
        this.disableDocUpload = false;
        break;

      case 'REVIEW_PROJECT_TRANSFER_REQUEST_TRANSFEREE':
      case 'REVIEW_PROJECT_TRANSFER_REQUEST_CUST':
        this.subtitle = 'field_1b';
        this.getProjectTransferDetail(this.mockup6);
        this.additionalInfoRequested = true;
        this.disableReasonProjectTransferAndCheckbox();
        // this.formFields = this.mockup6;
        break;

      case 'PROJECT_TRANSFER_INIT':
        this.maintitle = 'field_0_b';
        this.subtitle = 'field_1d';
        this.formFields = this.mockup8;
        this.model['accountIdSearch'] = null;
        this.entityActionList = [
          {workflowAction: 'SUB_RE_CUR_PROJ_COMP', enabled: false, id: null},
        ];
        this.disableDocUpload = false;

        // this.getAccountDetails(this.activatedRoute.snapshot.params['id']);
        this.getDocumentConfig();
        break;

      case 'VIEW_PROJECT_TRANSFER_INIT':
        this.maintitle = 'field_0_b';
        this.subtitle = 'field_1d';
        this.formFields = this.mockup8_b;
        this.model['accountIdSearch'] = null;
        // this.getDocumentConfig();
        break;

      case 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST':
        this.maintitle = 'field_0_b';
        this.model['accountIdSearch'] = null;

        if (this.activitylog) {
          this.getPrevProjectTransferDetail(this.mockup8_b);
        } else {
          this.getProjectTransferDetail(this.mockup8_b);
        }

        // this.getDocumentConfig();
        break;

      default:
        alert('Error processing');
        this.back();
    }
    if (this.search_input_field_state) {
      this.model['accountIdSearch'] = this.search_input_field_state;
      this.search_input_field_state = null;
    }
  }

  disableReasonProjectTransferAndCheckbox() {
    this.reason_proj_trans.fieldGroup[0].templateOptions.disabled = true;
    this.reason_proj_trans.fieldGroup[0].templateOptions.required = false;
    this.chbx1.fieldGroup[0].templateOptions.disabled = true;
    this.chbx2.fieldGroup[0].templateOptions.disabled = true;
  }

  extraEntityActions: any [] =[];
  evalUrlParams() {
    this.activatedRoute.params.subscribe(params => {
      this.formType = params.type;

      if(history.state.extraActions) {
        //here
        this.projectService.getByPendingProjectTransferRequestId(history.state.data.pendingTransferRequestId).subscribe(resp=> {
          this.extraEntityActions = resp.entityActionList;
        });
      }

      this.activitylog = history.state.activityLog;
      if (params.id && params.type !== 'PROJECT_TRANSFER_INIT'  && params.type !== 'VIEW_PROJECT_TRANSFER_INIT') {

        this.projectId = params.id;

        if (params.type === 'VIEW_PROJECT_TRANSFER_REQUEST_PREV') {
           try {
          this.getProjectDetail(history.state.data.projectId);
           } catch (err) {
            this.projectIdMissing = true;
          }
         } else {
          this.getProjectDetail(params.id);
         }

      }
      if (params.id && params.type === 'PROJECT_TRANSFER_INIT') {
       this.destAccountIdForProjecTransferInit = params.id;
      }

      if (params.id && params.type === 'VIEW_PROJECT_TRANSFER_INIT') {
        this.projectId = params.id;
        this.getProjectDetail(params.id);
        this.getAccountDetails(history.state.data.transferDestAccountId);
        this._setDocumentConfig(history.state.data.pendingTransferRequestId);
      }

      if (params.id && params.type === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {

        if (this.activitylog) {

        } else {
          this.projectId = params.id;
          this.getProjectTransferDetail(params.id);
        }
        // this.getAccountDetails(history.state.data.transferDestAccountId);
      }

    });
  }

  private _setDocumentConfig(id: number) {
    // this.model.id = id;

    let entityDocName: IApp.NSDocument.EntityTypeEnum;
    entityDocName = 'PROJECT_TRANSFER_REQUEST';
    let docUploadConfig: any = [];
    const extraDocs: any = [];

    if (this.checkSameAccountGroup() && this.model.transferType !== 'SUB') {
    this.projectTransferId = id;
    this.documentUploadConfig = {
      id,
      comment: true,
      documentType: true,
      entityName: entityDocName,
      title: 'PROJECT_CANCELLATION.actionLabels.uploadDocument',
      disablePaginator: false,
      documentUploadConfigs : [],
      pendingSubmissionStatus: true,
      extraUploadDocuments : extraDocs,
    };
    this.loaded = true;

    //if true hide O345Include historical notifications and regulatory reports associated to the project in the transfer
//O337Include active credits associated to the project in the transfer
    return;
    }

    if ( !(this.formType === 'PROJECT_TRANSFER_INIT') && !(this.formType === 'VIEW_PROJECT_TRANSFER_INIT')  && !(this.formType === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST')) {
      if (this.formType === 'REVIEW_PROJECT_TRANSFER_REQUEST' && !this.adminPermission) {
        docUploadConfig = [];
      } else {
        docUploadConfig.push({
          documentTypeValue: 53,
          documentTypeCode: 'WRITTEN_CONSENT_TO_TRANSFER_BY_TRANSFEROR',
          id,
          entityName: 'PROJECT_TRANSFER_REQUEST',
        });
      }
    }

    if (this.formType === 'PROJECT_TRANSFER_INIT') {
      extraDocs.push({
        documentTypeValue: 66,
        documentTypeCode: 'MISCELLANEOUS',
        id,
        entityName: 'PROJECT_TRANSFER_REQUEST',
      });
    }

    if (this.formType === 'REVIEW_PROJECT_TRANSFER_REQUEST_TRANSFEREE') {
      this.disableDocUpload = false;
      docUploadConfig = [{
        documentTypeValue: 54,
        documentTypeCode: 'ATTESTATION_CONSENT_FROM_TRANSFEREE',
        id,
        entityName: 'PROJECT_TRANSFER_REQUEST',
      }];

      extraDocs.push({
        documentTypeValue: 67,
        documentTypeCode: 'DECLARATION_PROJECT_TRANSFER_MEMBER_PARTIES',
        id,
        entityName: 'PROJECT_TRANSFER_REQUEST',
      });
    }


    docUploadConfig = [...docUploadConfig];
    this.projectTransferId = id;
    this.documentUploadConfig = {
      id,
      comment: true,
      documentType: true,
      entityName: entityDocName,
      title: 'PROJECT_CANCELLATION.actionLabels.uploadDocument',
      disablePaginator: false,
      documentUploadConfigs : [...docUploadConfig, ...this.documentUploadConfig.documentUploadConfigs],
      pendingSubmissionStatus: true,
      extraUploadDocuments : extraDocs,
    };
    this.loaded = true;

    this.documentUploadConfig.documentUploadConfigs = [ ...new Set (this.documentUploadConfig.documentUploadConfigs.map(a => JSON.stringify(a)))].map(a => JSON.parse(a));

    this.updateDocumentDetails([]);
  }

  checkSameAccountGroup() {
    const masterToSub = this.model['srcAccountId'] ?
     `${this.model['srcAccountId']}` === `${this.model['destinationMasterAccountId']}` ||
     `${this.model['srcAccountId']}` === `${this.model['destMasterAccountId']}` : false;
    const subToMaster = this.model['masterAccountId'] ?
     `${this.model['masterAccountId']}` === `${this.model['destinationAccountId']}` ||
     `${this.model['masterAccountId']}` === `${this.model['destAccountId']}` : false;
    const subToSub = this.model['masterAccountId'] ?
     `${this.model['masterAccountId']}` === `${this.model['destinationMasterAccountId']}` ||
     `${this.model['masterAccountId']}` === `${this.model['destMasterAccountId']}` : false;
    const sameAccount = (+this.model.srcAccountId) === (+this.model.destinationAccountId);
    this.model.sameAccountGroup = masterToSub || subToMaster || subToSub || sameAccount;
    return this.model.sameAccountGroup;
  }

  getAccountDetails(id) {
    this.accountManagementService.getAccountDetails(id).pipe(
      takeUntil(this._destroy$),
    )
    .subscribe(response => {
      if ( !(this.formType === 'PROJECT_TRANSFER_INIT') && !(this.formType === 'VIEW_PROJECT_TRANSFER_INIT')  && !(this.formType === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST'))  {
        this.model['legalName'] = response.legalName;
      }

      this.model['destAccountName'] = response.legalName;
      this.model['accountId'] = response.id;
      this.model['destAccountId'] = response.id;

      this.model['legalNameNewApplicantDetails'] = response.legalName;
      this.model['aoNewApplicantDetails'] = response.authorizedOfficial.fullName;

      this.model['organizationAddressNewApplicationDetails'] = `${this.getStringValue(response.address.streetNumber, false)}
    ${this.getStringValue(response.address.streetName)}${this.getStringValue(response.address.city)}
    ${this.getStringValue(response.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response.address.poBox)}
    ${this.getStringValue(response.address.postalCode)}
    ${this.getStringValue(response.address.country, false, 'COMMON.countryList.')}` ;

      this.model = {... this.model};
    });
  }

  getProjectAccount() {
    this.projectService.getProjectDetailsByProjectId({projectProjectId: this.model['accountIdSearch']}).pipe(
      takeUntil(this._destroy$),
    )
    .subscribe(response => {

      if (this.formType === 'PROJECT_TRANSFER_INIT') {
        this.model['legalName'] = response.account.legalName;
        this.model['destAccountId'] = this.destAccountIdForProjecTransferInit;
      }
      this.model['name'] = response.name;
      this.model['name2'] = response.name2;
      this.model['id'] = response.id;
      this.model['type'] = response.type;
      this.model['masterProjectName'] = response.masterProjectName;
      this.model['projectId'] = response.projectId;
      this.model['srcAccountId'] = response.accountId;
      this.model['masterAccountId'] = response.masterAccountId;
      this.model['accountIdSearch'] = response.id;
      this.model['transferType'] = response.type;
      this.model['authorizedOfficialName'] = response.account.authorizedOfficial.name;
      this.model['organizationAddress'] = `${this.getStringValue(response['account'].address.streetNumber, false)}
      ${this.getStringValue(response['account'].address.streetName)}${this.getStringValue(response['account'].address.city)}
      ${this.getStringValue(response['account'].address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response['account'].address.poBox)}
      ${this.getStringValue(response['account'].address.postalCode)}
      ${this.getStringValue(response['account'].address.country, false, 'COMMON.countryList.')}` ;

      this.showSuccessMessage('found');
    });
  }

  async getPrevProjectTransferDetail(formFields) {
      let payload = this.projectId;
      if (this.activitylog) {

      payload = history.state.data.id;
          }
      const response = await this.projectTransferService.getPrevProjectTransferDetailsPromise(payload);
      if (response.previousDiscussions !== null) {
            response.previousDiscussions.unshift(response.currentDiscussion);
            if (this.formType  === 'VIEW_PROJECT_TRANSFER_REQUEST_PREV') {
            this.additionalInfoRequested = true;
            }
          }
      this.discussions = response.previousDiscussions;
      this.status = response.status;
      this.statusName = response.statusName;
      this.projectTransferId = response.id;
      this.entityActionList = response.entityActionList;

      this.model['comment'] = response.comment;
      this.model['discloseComment'] = response.discloseComment;
      this.model['adminRemark'] = response.adminRemark;
      this.model['discloseAdminRemark'] = response.discloseAdminRemark;
      this.model['destAccountId'] = response.destAccountId;
      this.model['reasonForTransfer'] = response.reasonForTransfer;
      this.model['includeCredits'] = response.includeCredits;
      this.model['includeNotifications'] = response.includeNotifications;
      this.model['destAccountName'] = response.destAccountName;

      if (this.model.type === 'SUB') {
            this.maintitle = 'field_0_c';
          }

      if (this.formType === 'VIEW_PROJECT_TRANSFER_REQUEST_PREV') {

            if (this.projectIdMissing) {
              this.getProjectDetail(response.projectId);
            }
            this.model['legalName'] = response.srcAccount.legalName;
            this.model['accountId'] = response.srcAccount.id;
            this.model['srcAccountId'] = response.srcAccount.id;
            this.model['organizationAddress'] =  `${this.getStringValue(response.srcAccount.address.streetNumber, false)}
            ${this.getStringValue(response.srcAccount.address.streetName)}${this.getStringValue(response.srcAccount.address.city)}
            ${this.getStringValue(response.srcAccount.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response.srcAccount.address.poBox)}
            ${this.getStringValue(response.srcAccount.address.postalCode)}
            ${this.getStringValue(response.srcAccount.address.country, false, 'COMMON.countryList.')}` ;

            this.model['authorizedOfficialName'] = response.srcAccount.authorizedOfficial.name;
            this.model['bussinessTelephone'] = response.srcAccount.authorizedOfficial.businessTelephone;
            this.model['email'] = response.srcAccount.authorizedOfficial.email;

        this.model['tardeFR'] = response.destAccount.frenchTradeName;
        this.model['tardeEN'] = response.destAccount.englishTradeName;
        if (response.destAccount && response.destAccount.address) {
          this.model['destinationCivicAddress'] = `
          ${this.getStringValue(response.destAccount.address.streetNumber, false)}
          ${this.getStringValue(response.destAccount.address.streetName)}
          ${this.getStringValue(response.destAccount.address.city)}
          ${this.getStringValue(response.destAccount.address.province, true, 'COMMON.jurisdictionsList.')}
          ${this.getStringValue(response.destAccount.address.poBox)}
          ${this.getStringValue(response.destAccount.address.postalCode)}
          ${this.getStringValue(response.destAccount.address.country, false, 'COMMON.countryList.')}`;
        } else {
          this.model['destinationCivicAddress'] = '';
        }

        this.model['destinationAuthOffialName'] = response.destAccount.authorizedOfficial.name;
        this.model['destinationAuthOffialTitle'] = response.destAccount.authorizedOfficial.title

        if (response.destAccount.authorizedOfficial.postalAddress) {
          this.model['destinationAuthOffialAddress'] = `
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.streetNumber, false)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.streetName)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.city)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.province, true, 'COMMON.jurisdictionsList.')}
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.poBox)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.postalCode)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.country, false, 'COMMON.countryList.')}`;
        } else {
          this.model['destinationAuthOffialAddress'] = '';
        }

        if (response.destAccount.authorizedOfficial.civicAddress) {
          this.model['destinationAuthOffialAddressPostal'] = `
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.streetNumber, false)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.streetName)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.city)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.province, true, 'COMMON.jurisdictionsList.')}
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.poBox)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.postalCode)}
          ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.country, false, 'COMMON.countryList.')}`;
        } else {
          this.model['destinationAuthOffialAddressPostal'] = '';
        }


        this.model['destinationAuthOffialTelephone'] = response.destAccount.authorizedOfficial.businessTelephone;
        this.model['destinationAuthOffialEmail'] = response.destAccount.authorizedOfficial.email;
        this.model['registeredCharityNumber'] = response.destAccount.registeredCharityNumber
        this.model['cRBNumber'] = response.destAccount.registrationNumber;
        this.model['destinationDunsNumber'] = response.destAccount.dunsNumber;

          }

      if (this.activitylog) {
            // update this section once anjana updates this api to get new application details section for REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST
            this.model['legalNameNewApplicantDetails'] = response.applicantAccount.legalName;
            this.model['aoNewApplicantDetails'] = response.applicantAccount.authorizedOfficial.name;

            this.model['organizationAddressNewApplicationDetails'] = `${this.getStringValue(response.applicantAccount.address.streetNumber, false)}
            ${this.getStringValue(response.applicantAccount.address.streetName)}${this.getStringValue(response.applicantAccount.address.city)}
            ${this.getStringValue(response.applicantAccount.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response.applicantAccount.address.poBox)}
            ${this.getStringValue(response.applicantAccount.address.postalCode)}
            ${this.getStringValue(response.applicantAccount.address.country, false, 'COMMON.countryList.')}` ;
      }

      this.model.destMasterAccountId = response.destMasterAccountId;
      this.model.masterAccountId = this.model.masterAccountId ? this.model.masterAccountId : response.srcMasterAccountId;

      this._setDocumentConfig(this.projectTransferId);
      this.formFields = formFields;
  }

  async getProjectTransferDetail(formFields) {
    const response = await this.projectTransferService.getProjectTransferDetailsPromise(this.projectId);
    if (response.previousDiscussions !== null) {
      response.previousDiscussions.unshift(response.currentDiscussion);
      if (this.formType  === 'VIEW_PROJECT_TRANSFER_REQUEST') {
      this.additionalInfoRequested = true;
      }
    }
    this.discussions = response.previousDiscussions;
    if (this.formType !== 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {
      this.status = response.status;
      this.statusName = response.statusName;
    } else {

      // update this section once anjana updates this api to get new application details section for REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST
      this.model['legalNameNewApplicantDetails'] = response.applicantAccount.legalName;
      this.model['aoNewApplicantDetails'] = response.applicantAccount.authorizedOfficial.name;

      this.model['organizationAddressNewApplicationDetails'] = `${this.getStringValue(response.applicantAccount.address.streetNumber, false)}
      ${this.getStringValue(response.applicantAccount.address.streetName)}${this.getStringValue(response.applicantAccount.address.city)}
      ${this.getStringValue(response.applicantAccount.address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response.applicantAccount.address.poBox)}
      ${this.getStringValue(response.applicantAccount.address.postalCode)}
      ${this.getStringValue(response.applicantAccount.address.country, false, 'COMMON.countryList.')}` ;
    }
    this.projectTransferId = response.id;
    this.entityActionList = response.entityActionList;

    this.entityActionList.forEach(item => {
      if (item.workflowAction === 'RESUBMIT' ||
      item.workflowAction === 'SUBMIT' ||
      item.workflowAction === 'NEED_MORE_INFO' ||
      item.workflowAction === 'ADDITIONAL_INFO_REQUESTED_FROM_PARTICIPANT') {
        this.disableDocUpload = false;
      }
    });

    this.model['discloseComment'] = response.discloseComment;
    this.model['destAccountId'] = response.destAccountId;
    this.model['reasonForTransfer'] = response.reasonForTransfer;
    this.model['includeCredits'] = response.includeCredits;
    this.model['includeNotifications'] = response.includeNotifications;
    this.model['destAccountName'] = response.destAccountName;
    this.model['destProjectId'] = response.destProjectId;
    this.model['destProjectProjectId'] = response.destProjectProjectId;
    this.model['destProjectName'] = response.destProjectName;
    this.model['userComment'] = response.userComment;


    this.model['tardeFR'] = response.destAccount.frenchTradeName;
    this.model['tardeEN'] = response.destAccount.englishTradeName;
    if (response.destAccount && response.destAccount.address) {
      this.model['destinationCivicAddress'] = `
      ${this.getStringValue(response.destAccount.address.streetNumber, false)}
      ${this.getStringValue(response.destAccount.address.streetName)}
      ${this.getStringValue(response.destAccount.address.city)}
      ${this.getStringValue(response.destAccount.address.province, true, 'COMMON.jurisdictionsList.')}
      ${this.getStringValue(response.destAccount.address.poBox)}
      ${this.getStringValue(response.destAccount.address.postalCode)}
      ${this.getStringValue(response.destAccount.address.country, false, 'COMMON.countryList.')}` ;
    } else {
      this.model['destinationCivicAddress'] = '';
    }

    this.model['destinationAuthOffialName'] = response.destAccount.authorizedOfficial.name;
    this.model['destinationAuthOffialTitle'] = response.destAccount.authorizedOfficial.title

    if (response.destAccount.authorizedOfficial.postalAddress) {
      this.model['destinationAuthOffialAddress'] = `
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.streetNumber, false)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.streetName)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.city)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.province, true, 'COMMON.jurisdictionsList.')}
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.poBox)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.postalCode)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.postalAddress.country, false, 'COMMON.countryList.')}` ;
    } else {
      this.model['destinationAuthOffialAddress'] = '';
    }

    if (response.destAccount.authorizedOfficial.civicAddress) {
      this.model['destinationAuthOffialAddressPostal'] = `
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.streetNumber, false)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.streetName)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.city)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.province, true, 'COMMON.jurisdictionsList.')}
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.poBox)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.postalCode)}
      ${this.getStringValue(response.destAccount.authorizedOfficial.civicAddress.country, false, 'COMMON.countryList.')}` ;
    } else {
      this.model['destinationAuthOffialAddressPostal'] = '';
    }


    this.model['destinationAuthOffialTelephone'] = response.destAccount.authorizedOfficial.businessTelephone;
    this.model['destinationAuthOffialEmail'] = response.destAccount.authorizedOfficial.email;
    this.model['registeredCharityNumber'] = response.destAccount.registeredCharityNumber
    this.model['cRBNumber'] = response.destAccount.registrationNumber;
    this.model['destinationDunsNumber'] = response.destAccount.dunsNumber;





    this.model['comment'] = response.comment;
    this.model['adminRemark'] = response.adminRemark;
    this.model['discloseAdminRemark'] = response.discloseAdminRemark;

    this.model.destMasterAccountId = response.destMasterAccountId;
    this.model.masterAccountId = this.model.masterAccountId ? this.model.masterAccountId : response.srcMasterAccountId;
    this.model.srcProjectType = response.srcProjectType;

    this._setDocumentConfig(this.projectTransferId);

    if (this.formType === 'REVIEW_PROJECT_TRANSFER_REQUEST_TRANSFEREE' &&
      (this.isACCEPTPROJECTTRANSFER(response.entityActionList)) && !this.model.sameAccountGroup
    ) {
      if (response.srcProjectType === 'NORMAL') {
        formFields.push(this.attestation);
      } else {
        formFields.push(this.aggregatedAttestation);
      }
    }

    this.formFields = formFields;
  }

  isACCEPTPROJECTTRANSFER(data) {

    return data.find( item => {
      if (item.workflowAction === 'ACCEPT_PROJECT_TRANSFER') {
      return true;
      }
    });
  }

  hasAPPROVEEntityList(data) {

    return data.find( item => {
      if (item.workflowAction === 'APPROVED') {
      return true;
      }
    });
  }

  async getProjectDetail(id) {
    const response = await this.projectTransferService.getProjectDetailsPromise(id);

    this.projectNameID = `${response.name}, ${response.projectId}. `;

    this.project_type = response['type'];

    if (this.formType !== 'VIEW_PROJECT_TRANSFER_REQUEST_PREV') {
      this.model['accountId'] = response.accountId;
      this.model['srcAccountId'] = response.accountId;
      this.model['masterAccountId'] = response.masterAccountId;
      this.model['legalName'] = response['account'].legalName;
      this.model['authorizedOfficialName'] = response['account'].authorizedOfficial.name;
      this.model['bussinessTelephone'] = response['account'].authorizedOfficial.businessTelephone;
      this.model['email'] = response['account'].authorizedOfficial.email;
      this.model['organizationAddress'] = `${this.getStringValue(response['account'].address.streetNumber, false)}
      ${this.getStringValue(response['account'].address.streetName)}${this.getStringValue(response['account'].address.city)}
      ${this.getStringValue(response['account'].address.province, true, 'COMMON.jurisdictionsList.')}${this.getStringValue(response['account'].address.poBox)}
      ${this.getStringValue(response['account'].address.postalCode)}
      ${this.getStringValue(response['account'].address.country, false, 'COMMON.countryList.')}` ;
    //  this.entityActionList = response.entityActionList
    }

    this.model['name'] = response.name;
    this.model['name2'] = response.name2;
    this.model['projectId'] = response.projectId;
    this.model['id'] = response.id;
    this.model['type'] = response.type;
    this.model['masterProjectName'] = response.masterProjectName;

    if (this.model.type === 'MASTER' && this.formType !== 'TRANSFER_AGGREGATED_PROJECT') {
      this.subtitle = 'field_1b';
    }

    if (this.formType === 'VIEW_PROJECT_TRANSFER_REQUEST') {
      this.subtitle = null;
    }

    if (this.formType === 'VIEW_PROJECT_TRANSFER_INIT') {
      this.subtitle = response.name + ', ' + response.id;
    }
  }

  getStringValue(value, comma= true, translationPrefix = null) {
    let response = '';
    if (value) {
      response = value;
      if (translationPrefix) {
        response = this.translateService.instant(`${translationPrefix}${value}`);
      }
      if (comma) {
        response = `${response}, `;
      } else {
        response = `${response} `;
      }
    }
    return response;
  }

  getDocumentConfig() {
    if (this._langChanged) {
      this._langChanged = false;
      return;
    }

    this.projectService.getNewTransferRequestId().subscribe((transferId: any) => {
      this.transferId = transferId.id;
      this._setDocumentConfig(transferId.id);
    });
  }


  // this.formFields = this.mockup1; // mock up 2 is loaded if in mock up 1 is selected
  //       this.subtitle = 'field_1';
  //       this.getDocumentConfig();
  //       this.disableDocUpload = false;
  //       this.entityActionList = [
  //         {workflowAction: 'TRANSFER_PROJECT', enabled: false, id: null},
  //       ];

  checkProjectType(a, b, c) {

    let label = this.translateService.instant('PROJECTS_MODULE.transferrequest.' + a);

    switch (this.model.type) {
      case'NORMAL':

      label = this.translateService.instant('PROJECTS_MODULE.transferrequest.' + a);
      break;
      case'SUB':
      label = this.translateService.instant('PROJECTS_MODULE.transferrequest.' + b);
      break;
      case'MASTER':
      label = this.translateService.instant('PROJECTS_MODULE.transferrequest.' + c);
      break;
    }

    return label;
  }

  viewProject() {
    this.projectService.redirectToProjectScreen(
      null,
      this.model.id,
      history.state.data ? history.state.data : this.model,
    );
  }

  evalProjectTypeRadioBtnCtrl() {
    this._setDocumentConfig(this.transferId);
    if (this.model['transferType'] === 'SUB') {
      this.formFields = this.mockup2;
    } else if (this.model['transferType'] === 'NORMAL') {
      this.formFields = this.mockup1;
      this.form.removeControl('destinationProjectName');
    }
  }

  submitToApi() {
    const payload = new ProjTransSubmitDTO();

    payload.projectId = this.model.id;
    payload.id = this.projectTransferId;
    payload.srcAccountId = this.model.srcAccountId;
    payload.destAccountId =  Number(this.model.destAccountId);
    payload.destProjectId  =  this.model.destProjectId;
    payload.reasonForTransfer = this.model.reasonForTransfer;
    payload.transferType = this.model.transferType;
    payload.includeNotifications = this.model.includeNotifications === undefined ? false : this.model.includeNotifications;
    payload.includeCredits = this.model.includeCredits === undefined ? false : this.model.includeCredits;
    payload.unitIds = this.unitList;
    payload['submissionId'] = history.state? history.state.submissionId: null;

    this.projectTransferService.submit(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
      this.navigation.back();
    });
  }

  pendingSubmit() {
    const payload = new ProjTransSubmitDTO();

    payload.projectId = this.model.id;
    payload.id = this.projectTransferId;
    payload.srcAccountId = this.model.srcAccountId;
    payload.destAccountId = Number(this.model.destAccountId);
    payload.reasonForTransfer = this.model.reasonForTransfer;
    payload.transferType = this.model.transferType;
    payload.includeNotifications = this.model.includeNotifications === undefined ? false : this.model.includeNotifications;
    payload.includeCredits = this.model.includeCredits === undefined ? false : this.model.includeCredits;

    this.projectTransferService.pendingSubmit(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
      this.navigation.back();
    });
  }

  needmoreinfo(action) {

    const payload = new ProjTransNeedMoreInfoDTO();

    payload.id = this.projectTransferId;
    payload.adminRemark = this.model.adminRemark;
    payload.discloseAdminRemark = this.model.discloseAdminRemark;
    payload.action = action;

    const popUpForm = { ...{
      translationPrefix: 'COMMON.custom_messages',
      columns: [ {
      field: 'comment',
      header: 'comment',
      sortField: 'respond',
      width: '200px',
      editable: false,
      fieldType: 'textarea',
      inputType: 'string',
      editRowField: true,
      className: 'col-12',
      },
    ],
    }, editService: '/offset-service/projectTransferRequest/needMoreInfo' };
    this.openCommentPopUp(popUpForm, payload);

    /*this.projectTransferService.needmoreinfo(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.backbtnserviceService.backPreviousPage();
    });*/
  }

  resubmit(action) {
    const payload = new ProjTransReSubmitDTO();

    payload.id = this.projectTransferId;
    payload.userComment = this.model.userComment;
    payload.action = action;
    payload['submissionId'] = history.state? history.state.submissionId: null;

    this.projectTransferService.resubmit(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
      this.navigation.back();
    }, error => {

      // this.showErrorMessage('notfound');

    });
  }

  reject(action) {
    const payload = new ProjTransRejectDTO();

    payload.id = this.projectTransferId;
    payload.adminRemark = this.model.adminRemark;
    payload.discloseAdminRemark = this.model.discloseAdminRemark;
    payload.action = action;

    this.projectTransferService.reject(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
      this.navigation.back();
    } , (error: HttpErrorResponse) => {
      this.formService.parseErrors(this.form, error.error);
    });
  }

  reject2(action) {
    const payload = new ProjTransRejectDTO();

    payload.id = this.projectTransferId;
    payload.adminRemark = this.model.adminRemark;
    payload.discloseAdminRemark = this.model.discloseAdminRemark;
    payload.action = action;

    this.projectTransferService.reject2(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.navigation.back();
    }, (error: HttpErrorResponse) => {
      this.formService.parseErrors(this.form, error.error);
    });
  }

  accpetTransfer() {
    const attestationControl = this.form.get('attestation');
    const payload = new ProjTransRejectDTO();

    payload.id = this.projectTransferId;
    payload.attestation = attestationControl && attestationControl.value;

    this.projectTransferService.accpetTransfer(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.navigation.back();
    });

  }

  rejectTransfer() {
    const payload = new ProjTransRejectDTO();

    payload.id = this.projectTransferId;

    this.projectTransferService.rejectTransfer(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.navigation.back();
    });
  }

  approve(action) {
    const payload = new ProjTransApproveDTO();

    payload.id = this.projectTransferId;
    payload.adminRemark = this.model.adminRemark;
    payload.discloseAdminRemark = this.model.discloseAdminRemark;
    payload.action = action;

    this.projectTransferService.approve(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.navigation.back();
    });
  }

  approve2(action) {
    const payload = new ProjTransApproveDTO();

    payload.id = this.projectTransferId;
    payload.action = action;

    this.projectTransferService.approve2(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.navigation.back();
    });
  }

  cancelProjecTranser() {
    const payload = new ProjTransApproveDTO();

    payload.id = this.projectTransferId;
    payload.adminRemark = this.model.adminRemark;
    payload.discloseAdminRemark = this.model.discloseAdminRemark;

    this.projectTransferService.cancelProjecTranser(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.backbtnserviceService.backPreviousPage();
    });
  }

  rejectionApprove() {
    const payload = new ProjTransRejectDTO();

    payload.id = this.projectTransferId;

    this.projectTransferService.rejectionApprove(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
       this.navigation.back();
    });
  }

  save() {
    const payload = new ProjTransSaveDTO();

    payload.id = this.projectTransferId;
    payload.adminRemark = this.model.adminRemark;
    payload.discloseAdminRemark = this.model.discloseAdminRemark;
    payload.userComment = this.model.userComment;

    this.projectTransferService.save(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {

    });
  }

  getDestinationAcount() {

    if (this.model.type !== 'SUB') {
      if ( Number(this.model.destinationAccountId) === this.model.accountId ) {
        this.showErrorMessage('cantbesameacc');
        return;
      }
    }

    let payload = {};
    let showProjectMsg = false;

    if (this.model.destinationAccountId !== null || this.model.destinationAccountId) {

      payload = {
        ... payload,
        accountId: this.model.destinationAccountId,
      };
    }

    if (this.model.destinationProjectId) {
      payload = {
        ... payload,
        projectProjectId: this.model.destinationProjectId,
      };
      showProjectMsg = true;
    }

    this.projectTransferService.searchAccountProject(payload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(r => {
      if (r === null) {
        this.model.destAccountId = null;
        this.model.destinationAccountName = '';
        this.model.destProjectName = '';
        this.model.destinationProjectName = '';
        // this.showErrorMessage('notfound');
      } else {
        this.model.destAccountId = this.model.destinationAccountId;
        this.model.destinationMasterAccountId = r.masterAccountId;
        this.model.destProjectId = r.id;
        this.model.destinationAccountName = r.account.legalName;
        this.model.destinationProjectName = r.name;
        if(this.translateService.currentLang === 'fr' ){
          this.model.destinationProjectName =r.name2
        }
        this.model.destinationLegalName = r.legalName;
        this.model.destinationCivicAddress = `
        ${this.getStringValue(get(r.account, 'address.streetNumber', null), false)}
        ${this.getStringValue(get(r.account, 'address.streetName', null))}
        ${this.getStringValue(get(r.account, 'address.city', null))}
        ${this.getStringValue(get(r.account, 'address.province', null), true, 'COMMON.jurisdictionsList.')}
        ${this.getStringValue(get(r.account, 'address.poBox', null))}
        ${this.getStringValue(get(r.account, 'address.postalCode', null))}
        ${this.getStringValue(get(r.account, 'address.country', null), false, 'COMMON.countryList.')}` ;

        this.model.destinationAuthOffialName = r.account.authorizedOfficial.name;
        this.model.destinationAuthOffialTitle = r.account.authorizedOfficial.title;

        this.model['destinationAuthOffialAddress'] = `
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.streetNumber', null), false)}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.streetName', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.city', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.province', null), true, 'COMMON.jurisdictionsList.')}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.poBox', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.postalCode', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'postalAddress.country', null), false, 'COMMON.countryList.')}` ;

        this.model.destinationAuthOffialTelephone = r.account.authorizedOfficial.businessTelephone;
        this.model.destinationAuthOffialEmail = r.account.authorizedOfficial.email;
        this.model.registeredCharityNumber = r.account.registeredCharityNumber;
        this.model.cRBNumber = r.account.registrationNumber;
        this.model.destinationDunsNumber = r.account.dunsNumber;

        this.model['destinationAuthOffialAddressPostal'] = `
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.streetNumber', null), false)}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.streetName', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.city', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.province', null), true, 'COMMON.jurisdictionsList.')}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.poBox', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.postalCode', null))}
        ${this.getStringValue(get(r.account.authorizedOfficial, 'civicAddress.country', null), false, 'COMMON.countryList.')}` ;

        this.model['tardeEN'] = r['account'].englishTradeName;
        this.model['tardeFR'] = r['account'].frenchTradeName;

        this._setDocumentConfig(this.projectTransferId);
        this.showSuccessMessage('found');
      }

      this.model = { ... this.model};

    }, error => {
      if(this.model.transferType === 'NORMAL'){
       this.showErrorMessage('AccountNotFound');
      }
      else if(this.model.transferType === 'SUB'){
        this.showErrorMessage('notfound');
      }
      this.model.destAccountId = '';
      this.model.destProjectName = '';
      this.model.destinationAccountName = '';
      this.model.destinationProjectName = '';
    });

  }

  showSuccessMessage(message: string) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      type: SereviceMessageType.SUCCESS,
    };

    this.openPopUpMessage(messages);
  }

  showErrorMessage(message: string) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      type: SereviceMessageType.ERROR,
    };

    this.openPopUpMessage(messages);
  }

  openPopUpMessage(messages) {
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe((result?: boolean) => {
        if (result) {

        }
       });
  }

  warningMsgBack() {
    this.modalService.open(ServiceMessageComponent, {
      message: 'warningTransferBack',
      metaDataList: null,
      type: SereviceMessageType.WARNING,
    }, true, DEFAULT_DIALOG_CONFIG)
    .afterClosed()
    .subscribe((result?: boolean) => {
      if (result) {
        if (this.formType === 'VIEW_PROJECT_TRANSFER_INIT' || this.formType === 'VIEW_PROJECT_TRANSFER_REQUEST_PREV' || this.formType === 'PROJECT_TRANSFER_INIT') {
          this.navigation.back();
        } else {
          this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/project-list`));
        }
      }
    });
  }

  private markFormGroupDirty(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsDirty();

      if (control.controls) {
        this.markFormGroupDirty(control);
      }
    });
  }

  private checkForRequiredDocs(missingDocs) {
    if (missingDocs.length) {
      let charCode = 97;
      const missingDocMsg = missingDocs.reduce((acc, doc, index) => {
        ++charCode;
        return (index < missingDocs.length - 1) ?
          acc + `${this.translateService.instant(`COMMON.documentList.${doc}`)}<br>${String.fromCharCode(charCode)}. ` :
          acc + `${this.translateService.instant(`COMMON.documentList.${doc}`)}`;
      }, '');
      const metaData = [`${String.fromCharCode(97)}. ${missingDocMsg}`];
      this.modalService.open(ServiceMessageComponent,
        {
          messages: [{
            message: 'mandatoryInformationRequiredForUploadDocs',
            metaData,
          }],
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      ).afterClosed();

      return true;

    }

    return false;
  }

  updateDocumentDetails(docs) {
    const requiredDocs = this.documentUploadConfig.documentUploadConfigs.map(t => t.documentTypeCode);
    const uploadedDocs = docs.map(t => t.documentType.code);
    this.missingDocumentList = requiredDocs.filter(doc => !uploadedDocs.includes(doc));
  }

  back() {
    if (this.form.touched) {
      this.warningMsgBack();
    } else {

      if (this.formType === 'VIEW_PROJECT_TRANSFER_INIT' || this.formType === 'VIEW_PROJECT_TRANSFER_REQUEST_PREV' || this.formType === 'PROJECT_TRANSFER_INIT') {
        this.navigation.back();
      } else {
        this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/project-list`));
      }
    }
  }

  exeAction(action) {
    let warningObj: any = {};
    let showWarning = true;
    if (action !== 'REJECT_PROJECT_TRANSFER') {
      this.markFormGroupDirty(this.form);
      if (!this.form.valid) {
        return;
      }
    }

    this.executedAction = action;
    switch (action) {
      case 'SUB_RE_CUR_PROJ_COMP': /**/
        if (this.checkForRequiredDocs(this.missingDocumentList)) { return; } else {
          warningObj = {
            message: 'resubadminWarn',
            metaDataList: [this.model.name],
            type: SereviceMessageType.WARNING,
          };
        }
        break;

      case 'REQUEST_TRANSFER_PROJECT': /**/
      case 'TRANSFER_PROJECT':
          if (this.checkForRequiredDocs(this.missingDocumentList)) { return; } else {
            warningObj = {
              message: 'warningTransfer',
              metaDataList: [this.model.name],
              type: SereviceMessageType.WARNING,
            };
          }
          break;

      case 'NEED_MORE_INFO': /**/
      case 'ADDITIONAL_INFO_REQUESTED_FROM_PARTICIPANT':
      case 'NEED_MORE_INFO_ACCEPTED_BY_TRANSFEREE':
        warningObj = {
          message: 'moreInfoWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'RESUBMIT': /**/
      case 'RESUBMIT_ACCEPTED_BY_TRANSFEREE':
        if (this.checkForRequiredDocs(this.missingDocumentList)) { return; } else {
          warningObj = {
            message: 'resubadminWarn',
            metaDataList: [this.model.name],
            type: SereviceMessageType.WARNING,
          };
        }
        break;

      case 'REJECT': /**/
      case 'REJECT_ACCEPTED_BY_TRANSFEREE':
        warningObj = {
          message: 'rejectWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'APPROVE': /**/
      case 'APPROVE_ACCEPTED_BY_TRANSFEREE':
        warningObj = {
          message: 'approveWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'APPROVE_SECOND': /**/
      case 'APPROVE_ACCEPTED_BY_TRANSFEREE_SECOND':
        warningObj = {
          message: 'approveWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'REJECT_SECOND':
      case 'REJECTED_REVIEW_2':
      case 'REJECT_REJECTION': /**/
      case 'REJECT_ACCEPTED_BY_TRANSFEREE_SECOND':
        warningObj = {
          message: 'rejectWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'APPROVE_REJECTION': /**/
        warningObj = {
          message: 'approveRejWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'SAVE': /**/
        warningObj = {
          message: 'saveWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'ACCEPT_PROJECT_TRANSFER': /**/
      if (this.checkForRequiredDocs(this.missingDocumentList)) { return; } else {
        showWarning = false;
        this.lookupService.validatePerformAction(`${this.model.destAccountId}?acceptProjectId=${this.model.id}`).subscribe(t => {
          if (!t) {
            this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnPendingProjectTransferAccount');
          } else {
            warningObj = {
                message: 'acceptWarn',
                metaDataList: [this.model.name],
                type: SereviceMessageType.WARNING,
              };
            this.warningMessageBefore(action, warningObj);
            }
          });
        }
      break;
      case 'REJECT_PROJECT_TRANSFER': /**/
      showWarning = false;
      this.lookupService.validatePerformAction(this.model.destAccountId).subscribe(t => {
        if (!t) {
          this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnPendingProjectTransferAccount');
        } else {
          warningObj = {
          message: 'rejectWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
          this.warningMessageBefore(action, warningObj);
        }
      });
      break;

      case 'AGREE': /**/
        warningObj = {
          message: 'agreeWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

      case 'DO_NOT_AGREE': /**/
        warningObj = {
          message: 'dissagreeWarn',
          metaDataList: [this.model.name],
          type: SereviceMessageType.WARNING,
        };
        break;

        case 'CANCEL_PROJECT_TRANSFER':
          warningObj = {
            message: 'cancelProjTransReq',
            metaDataList: [this.model.name],
            type: SereviceMessageType.WARNING,
          };
          break;
    }

    if (showWarning) {
      this.warningMessageBefore(action, warningObj);
    }

  }

  warningMessageBefore(action, warnMsg) {

    if (this.formType === 'PROJECT_TRANSFER_INIT') {
      warnMsg = {
        message: 'warningTransfer2',
        metaDataList: [this.model.name],
        type: SereviceMessageType.WARNING,
      };
    }

    this.modalService.open(ServiceMessageComponent, warnMsg, true, DEFAULT_DIALOG_CONFIG)
    .afterClosed()
    .subscribe((result?: boolean) => {
      if (result) {
        switch (action) {
          case 'SUB_RE_CUR_PROJ_COMP':
            this.pendingSubmit();
            break;
          case 'REQUEST_TRANSFER_PROJECT':
          case 'TRANSFER_PROJECT':
            this.submitToApi();
            break;
          case 'NEED_MORE_INFO':
          case 'ADDITIONAL_INFO_REQUESTED_FROM_PARTICIPANT':
          case 'NEED_MORE_INFO_ACCEPTED_BY_TRANSFEREE':
            this.needmoreinfo(action);
            break;
          case 'RESUBMIT':
          case 'RESUBMIT_ACCEPTED_BY_TRANSFEREE':
            this.resubmit(action);
            break;
          case 'REJECT':
          case 'REJECT_ACCEPTED_BY_TRANSFEREE':
            this.reject(action);
            break;
          case 'APPROVE':
          case 'APPROVE_ACCEPTED_BY_TRANSFEREE':
            this.approve(action);
            break;
          case 'APPROVE_SECOND':
          case 'APPROVE_ACCEPTED_BY_TRANSFEREE_SECOND':
            this.approve2(action);
            break;
          case 'REJECT_SECOND':
          case 'REJECTED_REVIEW_2':
          case 'REJECT_REJECTION':
          case 'REJECT_ACCEPTED_BY_TRANSFEREE_SECOND':
            this.reject2(action);
            break;
          case 'APPROVE_REJECTION':
            this.rejectionApprove();
            break;
          case 'SAVE':
            this.save();
            break;
          case 'ACCEPT_PROJECT_TRANSFER':
            this.accpetTransfer();
            break;
          case 'REJECT_PROJECT_TRANSFER':
            this.rejectTransfer();
            break;
          case 'AGREE':
            this.accpetTransfer();
            break;
          case 'DO_NOT_AGREE':
            this.rejectTransfer();
            break;

            case 'CANCEL_PROJECT_TRANSFER':
              this.cancelProjecTranser();
              break;
        }
      }
    });
  }

  openCommentPopUp(form, defaultValue) {
    const defaultValues = defaultValue;
    this.modalService.open(TableNewRowComponent, {
      form, defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT,
      editTitle: 'needMoreInfo',
    } as TableNewRow).afterClosed().subscribe(data => {
      if (data) { this.navigation.back(); }
    });
  }
  getCreditsOfProject(accountId, projectId) {
    // **************
    this.projectTransferService.getUnits({facilityNameList: [accountId], projectIdList: [projectId], unitStatusList: ['ACTIVE', 'PENDING_PROJECT_TRANSFER_UNITS']}).subscribe(res => {
      this.dataList = res;
    });
  }
  selectionChanged(checkboxList) {

    this.unitList = checkboxList.map(element => element.id);

  }

  hideCheckbox() {
    if (this.model.type === 'SUB') {
      return true;
    }

    if ( this.checkSameAccountGroup() ) {
      return true
    }

    return false;
  }

  private setFormFields() {
    this.curProjDtls = {
      wrappers: ['app-formly-fieldset'],
      templateOptions: {
      label: 'PROJECTS_MODULE.transferrequest.field_3',
      },
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: "readonly-input",
              wrappers: ["form-field"],
              key: 'legalName',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_4',
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'accountId',
              templateOptions: {
                readOnly: true,
                label:  'PROJECTS_MODULE.transferrequest.field_8',
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-12',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'organizationAddress',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_5',
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'authorizedOfficialName',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_6',
              },
            },
            {
              className: 'col-6',
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'bussinessTelephone',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_7',
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'email',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_9',
              },
            },
          ],
        },
      ],
    };

    this.curProjDtls_INIT = {
      wrappers: ['app-formly-fieldset'],
      templateOptions: {
      label: 'PROJECTS_MODULE.transferrequest.field_3',
      },
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'legalName',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_4',
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'authorizedOfficialName',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_6',
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-12',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'organizationAddress',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_5',
              },
            },
          ],
        },
      ],
    };

    this.newApplicantDetails = {
      wrappers: ['app-formly-fieldset'],
      templateOptions: {
      label: 'PROJECTS_MODULE.transferrequest.field_3_b',
      },
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'legalNameNewApplicantDetails',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_4',
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'aoNewApplicantDetails',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_6',
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-12',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'organizationAddressNewApplicationDetails',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_5',
              },
            },
          ],
        },
      ],
    };

    this.projDtls = {
      wrappers: ['app-formly-fieldset'],
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'name',
              expressionProperties: {
                'templateOptions.label': (model: any) => {
                  return this.checkProjectType('field_11', 'field_11b', 'field_11c');
                },
              },
              hideExpression: () => this.translateService.currentLang !== 'en'
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'name2',
              expressionProperties: {
                'templateOptions.label': (model: any) => {
                  return this.checkProjectType('field_11', 'field_11b', 'field_11c');
                },
              },
              hideExpression: () => this.translateService.currentLang !== 'fr'
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'projectId',
              expressionProperties: {
                'templateOptions.label': (model: any) => {
                  return this.checkProjectType('field_14', 'field_14b', 'field_14c');
                },
              },
            },
          ],
        },

        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'type',
              templateOptions: {
                translate: true,
                translatePrefix: `COMMON.projectApplicationTypesForm`,
                translateOptions: true,
              },
              expressionProperties: {
                'templateOptions.label': (model: any) => {
                  return this.checkProjectType('field_12', 'field_12b', 'field_12c');
                },
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'masterProjectName',
              templateOptions: {
                label:  'PROJECTS_MODULE.transferrequest.field_15',
              },
              hideExpression: 'model.type !== \'SUB\'',
            },
          ],
        },
      ],
      expressionProperties: {
        'templateOptions.label': (model: any) => {
          return this.checkProjectType('field_10', 'field_10b', 'field_10c');
        },
      },
    };

    this.sub_stnd_projc = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          type: 'radio',
          className: 'col-12',
          key: 'transferType',
          defaultValue: 'NORMAL',
          hideExpression: '!model.type || model.type === \'MASTER\'',
          templateOptions: {
            options: [
              { value: this.translateService.instant('PROJECTS_MODULE.transferrequest.field_31'), key: 'NORMAL' },
              { value: this.translateService.instant('PROJECTS_MODULE.transferrequest.field_32'), key: 'SUB' },
            ],
            formCheck: 'inline',
            change: event => {
              this.evalProjectTypeRadioBtnCtrl();
            },
          },
        },
      ],
    };

    this.search_dst = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-5',
          type: 'input',
          wrappers: ['form-field'],
          key: 'destinationAccountId',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.field_33',
            placeholder: 'PROJECTS_MODULE.transferrequest.field_33b',
          },
        },
        {
          className: 'col-1',
          type: 'button',
          wrappers: ['form-field'],
          templateOptions: {

            className: "btn btn-primary",
            onClick: $event => {
              this.getDestinationAcount();
            },
          },
          expressionProperties: {
            'templateOptions.disabled': (model: any) => {
              // access to the main model can be through `this.model` or `formState` or `model
              return model.destinationAccountId === undefined;
            },
            'templateOptions.text': (model: any) => {
              // access to the main model can be through `this.model` or `formState` or `model
              return this.translateService.instant("PROJECTS_MODULE.transferrequest.field_34");
            },
          },
        },
        {
          className: 'col-1',
          type: 'button',
          wrappers: ['form-field'],
          templateOptions: {
            className: "btn btn-secondary",
            onClick: $event => {
              this.model = {
                ...this.model,
                destinationAccountId: '',
                destinationAccountName: '',
              };
            },
          },
          expressionProperties: {
            'templateOptions.text': (model: any) => {
              // access to the main model can be through `this.model` or `formState` or `model
              return this.translateService.instant("PROJECTS_MODULE.transferrequest.field_35");
            },
          },
        },
      ],
    };

    this.search_dst_2 = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-4',
          type: 'input',
          wrappers: ['form-field'],
          key: 'destinationAccountId',
          id: 'destinationAccountId',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.field_33d',
            placeholder: 'PROJECTS_MODULE.transferrequest.field_33b',
          },
        },
        {
          className: 'col-4',
          type: 'input',
          wrappers: ['form-field'],
          key: 'destinationProjectId',
          templateOptions: {
           // placeholder: this.checkProjectType('enterProjectId', 'enterAggregatedProjectId', ''),
          },
          expressionProperties: {
            'templateOptions.placeholder': (model: any) => {
              return this.checkProjectType('enterProjectId', 'enterAggregatedProjectId', '');
            },
          },
        },
        {
          className: 'col-2',
          type: 'button',
          wrappers: ['form-field'],
          templateOptions: {
            text:  this.translateService.instant('PROJECTS_MODULE.transferrequest.field_34'),
            className: 'btn btn-primary',
            onClick: $event => {
              this.getDestinationAcount();
            },
          },
          expressionProperties: {
            'templateOptions.disabled': (model: any) => {
              return (model.destinationAccountId === undefined || model.destinationProjectId === undefined) || (model.destinationAccountId === '' || model.destinationProjectId === '');
            },
          },
        },
        {
          className: 'col-2',
          type: 'button',
          wrappers: ['form-field'],
          templateOptions: {
            text:  this.translateService.instant('PROJECTS_MODULE.transferrequest.field_35'),
            className: 'btn btn-secondary',
            onClick: $event => {
              this.model = {
                ...this.model,
                destinationAccountId: '',
                destinationProjectId: '',
                destinationProjectName: '',
                destinationAccountName: '',

              };
            },
          },
        },
      ],
    };

    this.reason_proj_trans = {
      fieldGroupClassName: 'mt-3 row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'textarea',
          key: 'reasonForTransfer',
          templateOptions: {
            rows: 4,
            required: true,
            disabled: false,
          },
          expressionProperties: {
            'templateOptions.label': (model: any) => {
              return this.checkProjectType('field_19', 'field_19b', 'field_19c');
            },
          },
        },
      ],
    };

    this.reason_proj_trans_b = {
      fieldGroupClassName: 'mt-3 row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'textarea',
          key: 'reasonForTransfer',
          templateOptions: {

            rows: 4,
            required: true,
            disabled: true,
          },
          expressionProperties: {
            'templateOptions.label': (model: any) => {
              return this.checkProjectType('field_19', 'field_19b', 'field_19c');
            },
          },
        },
      ],
    };

    this.dst_acc = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAccountName',
          templateOptions: {
            label: "PROJECTS_MODULE.transferrequest.field_36",
            required: false,
          },
        },
        {
          className: 'col-6',
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'tardeFR',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.frTrade',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'tardeEN',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.enTrade',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationCivicAddress',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.orgAddress',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialName',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authOfficial',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialTitle',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.jobTitle',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialAddress',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authAddress',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialAddressPostal',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authAddressPostal',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialTelephone',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.telephone',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialEmail',
          templateOptions: {
            label:  'PROJECTS_MODULE.transferrequest.email',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'registeredCharityNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.charityNum',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'cRBNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.craBnNum',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationDunsNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.destinationDunsNumber',
            required: false,
          },
        },
      ],
    };

    this.dst_acc2 = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAccountName',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.field_36',
            required: true,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationProjectName',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.field_18',
            required: true,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'tardeFR',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.frTrade',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'tardeEN',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.enTrade',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationCivicAddress',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.orgAddress',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialName',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authOfficial',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialTitle',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.jobTitle',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialAddress',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authAddress',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialAddressPostal',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authAddressPostal',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialTelephone',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.telephone',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialEmail',
          templateOptions: {
            label:  'PROJECTS_MODULE.transferrequest.email',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'registeredCharityNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.charityNum',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'cRBNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.craBnNum',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationDunsNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.destinationDunsNumber',
            required: false,
          },
        },
      ],
    };

    this.chbx1 = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'checkbox',
          wrappers: ['form-field'],
          key: 'includeNotifications',
          templateOptions: {
            disabled: false,
          },
          expressionProperties: {
            'templateOptions.label': (model: any) => {
              return this.checkProjectType('field_20', 'field_20b', 'field_20c');
            },
          },
          hideExpression: "model.type === 'SUB' || model.sameAccountGroup",
        },
      ],
    };

    this.chbx2 = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-12',
          type: 'checkbox',
          wrappers: ['form-field'],
          key: 'includeCredits',
          templateOptions: {
            disabled: false,
          },
          expressionProperties: {
            'templateOptions.onChange': (model: any) => {
              this.displayUnits = this.model.includeCredits;
              return;
            },
            'templateOptions.label': (model: any) => {
              return this.checkProjectType('field_21', 'field_21b', 'field_21c');
            },
          },
          hideExpression: "model.type === 'SUB' || model.sameAccountGroup",
          // this.hideCheckbox()
        },
      ],
    };

    this.search_dst_agg_proj = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          type: 'input',
          wrappers: ['form-field'],
          key: 'certificateNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.field_37',
          },
        },
        {
          className: 'col-1',
          type: 'button',
          wrappers: ['form-field'],
          key: 'accountName',
          templateOptions: {
            text:  this.translateService.instant('PROJECTS_MODULE.transferrequest.field_34'),
            className: 'btn btn-primary',
          },
        },
        {
          className: 'col-1',
          type: 'button',
          wrappers: ['form-field'],
          key: 'accountName',
          templateOptions: {
            text:  this.translateService.instant('PROJECTS_MODULE.transferrequest.field_35'),
            className: 'btn btn-secondary',
            onClick: $event => {
              this.model = {
                ...this.model,
                accountName: '',
                destinationProjectId: '',
              };
            },
          },
        },
      ],
    };

    this.dst_agg_proj_dtls = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'certificateNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.field_18',
          },
        },
        {
          className: 'col-6',
        },
      ],
    };

    this.dst_proj_dtls_1 = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destAccountName',
          templateOptions: {
            readOnly: true,
            label: 'PROJECTS_MODULE.transferrequest.field_36',
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destAccountId',
          templateOptions: {
            readOnly: true,
            label:  'PROJECTS_MODULE.transferrequest.field_38',
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'tardeFR',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.frTrade',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'tardeEN',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.enTrade',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationCivicAddress',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.orgAddress',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialName',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authOfficial',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialTitle',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.jobTitle',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialAddress',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authAddress',
            required: false,
          },
        },
        {
          className: 'col-12',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialAddressPostal',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.authAddressPostal',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialTelephone',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.telephone',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationAuthOffialEmail',
          templateOptions: {
            label:  'PROJECTS_MODULE.transferrequest.email',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'registeredCharityNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.charityNum',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'cRBNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.craBnNum',
            required: false,
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destinationDunsNumber',
          templateOptions: {
            label: 'PROJECTS_MODULE.transferrequest.destinationDunsNumber',
            required: false,
          },
        },
      ],
    };

    this.dst_proj_dtls_2 = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destProjectName',
          templateOptions: {
            readOnly: true,
            label: 'PROJECTS_MODULE.transferrequest.field_39',
          },
          hideExpression: (model: any) => {
            return !this.model.destProjectId;
          },
        },
        {
          className: 'col-6',
          type: 'readonly-input',
          wrappers: ['form-field'],
          key: 'destProjectProjectId',
          templateOptions: {
            readOnly: true,
            label:  'PROJECTS_MODULE.transferrequest.field_43',
          },
          hideExpression: (model: any) => {
            return !this.model.destProjectId;
          },
        },
      ],
    };

    // Admin Reviews
    this.admin_review = {
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-12',
              type: 'textarea',
              wrappers: ['form-field'],
              key: 'adminRemark',
              templateOptions: {
                rows: 4,
                label: 'PROJECTS_MODULE.transferrequest.field_23',
                require: true, // 10/25
              },
              expressionProperties: {
                'templateOptions.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                  if (this.status === 'REJECTED') {
                    return true;
                  } else {
                    console.log(this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS));
                    if (this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                },
              },
              hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                // if admin
                if (this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
                  return false; // show to the admin
                } else {

                  // participante
                  if (this.model.discloseAdminRemark) { // do wshow it
                    return false;
                  } else {
                    return true;
                  }
                }
              },
            },
            {
              className: 'col-6',
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'checkbox',
              wrappers: ['form-field'],
              key: 'discloseAdminRemark',
              templateOptions: {
                label: 'PROJECTS_MODULE.transferrequest.field_24',
                disabled: !this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS),
              },
              expressionProperties: {
                'templateOptions.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                  if (this.status === 'REJECTED') {
                    return true;
                  } else {
                    if (this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
                      return false;
                    }
                  }
                },
              },
              hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {
                // if admin
                if (this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
                  return false; // show to the admin
                } else {

                  return true;
                }
              },
            },
          ],
        },
      ],
    };

    // Previous Discussions
    this.prev_discussion = {
      wrappers: ['app-formly-fieldset'],
      templateOptions: {
      label: 'PROJECTS_MODULE.transferrequest.field_53',
      },
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'legalNamesdfasd',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_54',
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'legalNamsdfse',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_56',
              },
            },
          ],
        },
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'legalNamesfsd',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_55',
              },
            },
            {
              className: 'col-6',
              type: 'readonly-input',
              wrappers: ['form-field'],
              key: 'legalNamsdfsdfe',
              templateOptions: {
                readOnly: true,
                label: 'PROJECTS_MODULE.transferrequest.field_56',
              },
            },
          ],
        },
      ],
    };

    // Search Project
    this.search_project = {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-5',
          type: 'input',
          wrappers: ['form-field'],
          key: 'accountIdSearch',
          templateOptions: {
            required: true,
            label: 'PROJECTS_MODULE.transferrequest.field_61',
            placeholder: 'PROJECTS_MODULE.transferrequest.enterProjectId',
          },
        },
        {
          className: 'col-2',
          type: 'button',
          wrappers: ['form-field'],
          templateOptions: {
            // text:  this.translateService.instant('PROJECTS_MODULE.transferrequest.field_34'),
            className: 'btn btn-primary',
            onClick: $event => {
              this.getProjectAccount();
            },
          },
          expressionProperties: {
            'templateOptions.text': (model: any) => {
              // access to the main model can be through `this.model` or `formState` or `model
              return this.translateService.instant('PROJECTS_MODULE.transferrequest.field_34');
            },
          },

        },
        {
          className: 'col-2',
          type: 'button',
          wrappers: ['form-field'],
          key: 'accountName',
          templateOptions: {
            className: 'btn btn-secondary',
            onClick: $event => {
              const  reasonForTransfer = this.model['reasonForTransfer'];
              this.form.reset();
              this.form.patchValue({ reasonForTransfer});
            },
          },
          expressionProperties: {
            'templateOptions.text': (model: any) => {
              // access to the main model can be through `this.model` or `formState` or `model
              return this.translateService.instant('PROJECTS_MODULE.transferrequest.field_35');
            },
          },
        },
      ],
    };

    this.attestation = {
      wrappers: ['simple-border'],
      templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.attestation',
      },
      class: 'pl-2 mt-3',
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              wrappers: ['form-field'],
              key: 'attestation.attnProponentIsAuthorized',
              id: 'attnProponentIsAuthorized',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.proponentHasAuthorization',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            },
            {
              wrappers: ['form-field'],
              key: 'attestation.attnExclusiveEntitlementToReductions',
              id: 'attnExclusiveEntitlementToReductions',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.proponentIsInCompliance',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            },
            {
              wrappers: ['form-field'],
              key: 'attestation.attnOldRegNotCancelledVoluntaryReversal',
              id: 'attnOldRegNotCancelledVoluntaryReversal',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.notGuiltyUnderSection380',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            }
          ]

        }
      ]
    };

    this.aggregatedAttestation = {
      wrappers: ['simple-border'],
      templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.attestation',
      },
      class: 'pl-2 mt-3',
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          fieldGroup: [
            {
              wrappers: ['form-field'],
              key: 'attestation.attnProponentIsAuthorized',
              id: 'attnProponentIsAuthorized',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.proponentAuthorizedByEachPersonAggregated',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            },
            {
              wrappers: ['form-field'],
              key: 'attestation.attnExclusiveEntitlementToReductions',
              id: 'attnExclusiveEntitlementToReductions',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.proponentExclusioveEntitlementAggregated',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            },
            {
              wrappers: ['form-field'],
              key: 'attestation.attnOldRegNotCancelledVoluntaryReversal',
              id: 'attnOldRegNotCancelledVoluntaryReversal',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.proponentHasAuthorizationAggregated',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            },
            {
              wrappers: ['form-field'],
              key: 'attestation.attnNotFailedToComplyWithSectionOfAct',
              id: 'attnNotFailedToComplyWithSectionOfAct',
              type: 'checkbox',
              className: 'col-12',
              templateOptions: {
                required: true,
                requiredTrue: true,
                skipFirstChange: true,
                label: 'PROJECTS_MODULE.transferrequest.notGuiltyUnderSection380Aggregated',
              },
              validators: {
                validation: [Validators.requiredTrue],
              }
            },
          ]

        }
      ]
    };

    /* Form type */
    /*****************************************/
    this.mockup1 = [
      // Current project proponetn details
      this.curProjDtls,

      // Project details
      this.projDtls,

      // Destination Project Proponents Details
      {
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: "PROJECTS_MODULE.transferrequest.field_16",
        },
        fieldGroup: [
          // radio buttons
          this.sub_stnd_projc,

          // Search Destination
          this.search_dst,

          // Destination Account Name
          this.dst_acc,
        ],
      },

      // Reasons for project Transfer
      this.reason_proj_trans,


    ];

    this.mockup2 = [
      // Current project proponetn details
      this.curProjDtls,

      // Project details
      this.projDtls,
      // Destination Project Proponents Details
        // Search Destination Aggregated Project
        // Destination Aggregated Project Name:
      {
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16',
        },
        fieldGroup: [
          // radio
          this.sub_stnd_projc,

          // Search Destination
          this.search_dst_2,

          // Destination Account Name
          this.dst_acc2,

          // Search Destination Aggregated Project
          // this.search_dst_agg_proj,

          // Destination Aggregated Project Name:
          // this.dst_agg_proj_dtls
        ],
      },

      // Reasons for project Transfer
      this.reason_proj_trans,


    ];

    this.mockup3 = [
     // Current project proponetn details
     this.curProjDtls,

      // Project details
      this.projDtls,

      // Destination Project Proponents Details
      {
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16',
        },
        fieldGroup: [
          this.dst_proj_dtls_1,
          this.dst_proj_dtls_2,
        ],
      },

      // Reasons for project Transfer
      this.reason_proj_trans,



      // Admin Reviews - if admin view only
      this.admin_review,
    ];

    this.mockup4 = [
      // Current project proponetn details
      this.curProjDtls,

      // Project details
      this.projDtls,

      // Destination Project Proponents Details
      {
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16',
        },
        fieldGroup: [
          this.dst_proj_dtls_1,
          this.dst_proj_dtls_2,
        ],
      },

      // Reasons for project Transfer
      this.reason_proj_trans,



       // Admin Reviews - if admin view only
       this.admin_review,
    ];

    this.mockup5 = [
      // Current project proponetn details
      this.curProjDtls,

      // Project details
      this.projDtls,

      // Destination Project Proponents Details
      {
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16',
        },
        fieldGroup: [
          this.dst_proj_dtls_1,
          this.dst_proj_dtls_2,
        ],
      },

      // Reasons for project Transfer
      this.reason_proj_trans,


    ];

    this.mockup5_b = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'textarea',
            wrappers: ['form-field'],
            key: 'userComment',
            templateOptions: {
              rows: 4,
              label: 'PROJECTS_MODULE.transferrequest.field_51',
              disabled: this.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS),
              required: true,
            },
            hideExpression: (model: any, formState: any, field: FormlyFieldConfig) => {

              return (this.status === 'PENDING_REVIEW_ADMIN');
            },
          },
        ],
      },
    ];

    this.mockup6 = [
      // Current project proponetn details
      this.curProjDtls,

      // Project details
      this.projDtls,

      // Destination Project Proponents Details
      {
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16',
        },
        fieldGroup: [
          this.dst_proj_dtls_1,
          this.dst_proj_dtls_2,
        ],
      },

       // Reasons for project Transfer
       this.reason_proj_trans,



      // Admin Reviews
      this.admin_review,
    ];

    this.mockup8 = [
      this.search_project,
      // project proponetn details
      this.projDtls,
      // Destination Project Proponents Details
      // radio buttons
      // Search Destination
      // Destination Account Name
      // Current project proponetn details
      this.curProjDtls_INIT,
      /*{
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16'
        },
        fieldGroup: [
          this.dst_proj_dtls_1,
        ],
      },*/

       // Reasons for project Transfer
      // this.reason_proj_trans,


    ];

    this.mockup8_b = [

      this.projDtls,

      this.newApplicantDetails,
      // Destination Project Proponents Details
      // radio buttons
      // Search Destination
      // Destination Account Name
      // Current project proponetn details
      this.curProjDtls_INIT,
      /*{
        wrappers: ['app-formly-fieldset'],
        templateOptions: {
        label: 'PROJECTS_MODULE.transferrequest.field_16'
        },
        fieldGroup: [
          this.dst_proj_dtls_1,
        ],
      },*/

       // Reasons for project Transfer
      // this.reason_proj_trans_b,


    ];

  }
}
