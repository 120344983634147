
    <style>
      .inline {
        display: flex;
        flex-wrap: wrap;
      }
      .inline .p-field-checkbox {
        display: flex;
        flex: 0 0 20%;
        padding-right: 10px;
        padding-bottom: 15px;
      }
      .inline .p-field-checkbox label {
        right: 0;
        left: 20px;
        margin-top: 1px;
      }
    </style>
    <label *ngIf="to.dummyLabel" class="wcag-visuallyhidden" [for]="itemLabelPrefix + elementId">Label</label>
    <input type="text" class="wcag-visuallyhidden" [id]="itemLabelPrefix + elementId" tabindex="-1">
    <div [class.inline]="inline">
      <fieldset>
        <legend class="wcag-visuallyhidden">{{ itemLabelPrefix + elementId }}</legend>
      <div class="p-field-checkbox"
           *ngFor="let item of items">
        <p-checkbox [name]="elementId + '_checkbox_group'"
                    [value]="item.id"
                    [disabled]="item.disabled"
                    [formControl]="formControl"
                    [class.inline-checkbox]="inline"
                    [inputId]="itemLabelPrefix + elementId + '_item_' + item.id"
                    [label]="item.name"
                    (onChange)="change(field, $event)">
        </p-checkbox>
          <ng-container *ngIf="item.value">
            <fa-icon
              pTooltip="{{ item.value }}"
              tooltipPosition="top"
              [escape]="false"
              [icon]="['fas', 'info-circle']">
            </fa-icon>
          </ng-container>
      </div>
      </fieldset>
    </div>
  