import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel } from '@core/models/filter.model';
import { environment } from '@env/environment';
import {
  CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY,
  CREDIT_BALANCE_REPORT_DATA_KEY,
} from '@module/credit-balance/credit-balance-report.const';
import {
} from '@module/credit-balance/credit-balance-report.service';
import {
  MATERIAL_BALANCE_REPORT_PREFIX, MATERIAL_BALANCE_REPORT_STEP_PREFIX,
  MATERIAL_BALANCE_STEP_COMPONENT_MAP,
} from '@module/material-balance-report/material-balance-consts/material-balance.const';
import { ICreateMaterialBalanceReportPayload } from '@module/material-balance-report/material-balance-report.types';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { ModalService } from '@shared/services/modal.service';
import { Observable, Subject } from 'rxjs';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { HelperService } from '@shared/services/helper.service';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

export const GET_MB_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuel';
export const GET_MB_COMPLIANCE_YEAR = '/cfr-service/materialBalanceReport/getMBRComplianceYearList';
export const GET_MB_REPORT_BY_ID = '/cfr-service/materialBalanceReport/';
export const GET_MB_CI_IDS = '/cfr-service/materialBalanceReport/getMBRCIIdList';
export const GET_MB_FUEL_LIST = '/cfr-service/materialBalanceReport/getMBRFuelList';
export const GET_MB_TABLE_DATA = '/cfr-service/materialBalanceReport/pageSearchByFilter';
export const CREATE_MB_REPORT = '/cfr-service/materialBalanceReport/create';
export const SAVE_MB_REPORT = '/cfr-service/materialBalanceReport/save';
export const PROPOSE_MB_REPORT = '/cfr-service/materialBalanceReport/propose';
export const DISCARD_MB_REPORT = '/cfr-service/materialBalanceReport/discard';
export const GET_RESUBMIT_REPORT = '/cfr-service/materialBalanceReport/reportToResubmit/';
export const MB_DOWNLOAD_REPORT = '/cfr-service/materialBalanceReport/download/report/';
export const MB_SEARCH_ORG = '/account-service/cfrAccount/cfrDetailForMBR';
export const GET_RECONCILIATION_DATA = '/cfr-service/materialBalanceReport/viewReconciliationPageSearchByFilter';
@Injectable({
  providedIn: 'root',
})
export class MaterialBalanceReportService extends BaseStepperService {

  public report: any;
  public updateSteps$ = new Subject();
  public registrationReportsDetail: any;
  public OrgRegistrationReportsDetails: any;
  public translationPrefix = `${MATERIAL_BALANCE_REPORT_PREFIX}${MATERIAL_BALANCE_REPORT_STEP_PREFIX}`;
  public currentStepInvalid = false;

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private helperService: HelperService,
    private router: Router,
    ) {
    super();
   }

  createMBReport(payload: ICreateMaterialBalanceReportPayload): any {
    return this.http.post<any>(`${environment.apiUrl}${CREATE_MB_REPORT}`, payload);
  }

  public getMBCompliancePeriod() {
    return this.http.get<any>(`${environment.apiUrl}${GET_MB_COMPLIANCE_YEAR}`);
  }

  public getMBFuelTypes(payload) {
    return this.http.post<any>(`${environment.apiUrl}${GET_MB_FUEL_LIST}`, payload);
  }

  public getMBApprovedCIIds(payload) {
    return this.http.post<any>(`${environment.apiUrl}${GET_MB_CI_IDS}`, payload);
  }

  public getTableData(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<any>(`${environment.apiUrl}${ GET_MB_TABLE_DATA }`, filterState, { params });
  }

  public getReportById(reportId: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_MB_REPORT_BY_ID }${ reportId }`);
  }

  public getReportByIdToReSubmit(reportId: number) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_RESUBMIT_REPORT }${ reportId }`);
  }

  public getFuelEnergyDensity(fuelId: number): Observable<IApp.ILookup[]> {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${ GET_MB_ENERGY_DENSITY_URI }/${ fuelId }`);
  }

  public saveReport(payload): Observable<any>  {
    return this.http.post(`${ environment.apiUrl }${ SAVE_MB_REPORT }`, {...payload, action: 'SAVE', saveAndProceed: false});
  }

  public saveAndProceed(payload): Observable<any>  {
    return this.http.post(`${ environment.apiUrl }${ SAVE_MB_REPORT }`, {...payload, action: 'SAVE', saveAndProceed: true});
  }

  public discard(payload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${DISCARD_MB_REPORT}`, payload);
  }

  public submitReport(payload): Observable<any>  {
    return this.http.post(`${ environment.apiUrl }${ SAVE_MB_REPORT }`, {...payload, saveAndProceed: false});
  }

  public proposeReport(payload): Observable<any> {
    return this.http.post(`${ environment.apiUrl }${ PROPOSE_MB_REPORT }`, payload);
  }

  public getViewReconciliationData(pagination: IApp.IPagination, reportId: number): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const filterState = {
      materialBalanceReportId: reportId
    };
    return this.http.post<any>(`${environment.apiUrl}${ GET_RECONCILIATION_DATA }`, filterState, { params });
  }

  storeCurrentData(): void {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(CREDIT_BALANCE_REPORT_DATA_KEY, data);
    const step = JSON.stringify({...this.currentStep});
    sessionStorage.setItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY, step);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(CREDIT_BALANCE_REPORT_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(CREDIT_BALANCE_REPORT_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  restoreLastStep(): any {
    const currentStep = sessionStorage.getItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
    if (currentStep) {
      sessionStorage.removeItem(CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY);
      return JSON.parse(currentStep);
    } else {
      return null;
    }
  }

  public setReportSteps(omitStep = false) {
    const steps = this.currentData.associatedSteps.map((step, index) => {
      return this.getStepContent(step, index);
    });

    this.steps = [...steps];
    return [...steps];
  }

  private getStepContent(step, index) {
    const component = MATERIAL_BALANCE_STEP_COMPONENT_MAP[step.code];
    const _step = {
      ...step,
      name: `.${ step.code }_Name`,
      completed: false,
      id: index + 1,
      component,
    };
    _step.id = index + 1;
    return _step;
  }

  public openWarningPopUp(message: string, metaDataList = []): Observable<any> {
    return this.modalService.open(
      ServiceMessageComponent,
      {
        messages: null,
        message,
        metaDataList,
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed();
  }

  public showError(message, transPrefix) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      transPrefix,
      type: SereviceMessageType.ERROR,
    };
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe();
  }

  public downloadDocument(documentId) {
    const uri = environment.serviceUrl + MB_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
        (response: any) => downloadBlob(response),
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  triggerAction(metadata: any, redirect = false) {
    const action = metadata.action;
    const payload = metadata.data;

    if (['VIEW', 'EDIT', 'SUBMIT_TO_ECCC_VERIFICATION_REPORT', 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT'].includes(action.workflowAction)) {
      this.router.navigate(this.helperService.getTranslatedPath(`/material-balance/report/${payload.id}`));
    } else if (action.workflowAction === 'RESUBMIT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/material-balance/report/${payload.id}/RESUBMIT`));
    } else if (action.workflowAction === 'DOWNLOAD_MATERIAL_BALANCE_REPORT') {
      this.downloadDocument(payload.id);
    } else if (action.workflowAction === 'VIEW_VERIFICATION_DETAILS') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/${payload.id}`));
    } else if (action.workflowAction === 'VIEW_VERIFICATION_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
    }
  }

  getOrganizationDetail(id = null, accountId) {
    const payload = {
      id,
      accountId,
      discriminatorType: 'REGISTRATION_ACCOUNT',
    };
    return this.http.post(`${ environment.apiUrl}${MB_SEARCH_ORG}`, payload).
    pipe(tap(response => this.OrgRegistrationReportsDetails = response));
  }
  
}
