import { SOURCE_DATA_TYPE } from "@shared/components/stepper/stepper.const";
import { IRawForm, IRawFormBase } from '@core/models/raw-form.types';
import * as moment from 'moment-timezone';
import { DATE_FORMAT } from '../../../../app.const';

export const ERP_APPLICATION = {
  FACILITY_OR_EQUIPMENT: 'FacilitieOrEquipmentsComponent',
  PROJECT_DESCRIPTION: 'ProjectDescriptionComponent',
  CONTACT_PERSON_INFO: 'ContactPersonInfoComponent',
  UPLOAD_DOCUMNETS: 'PathwayInformationComponent'
};

export const ERP_PROJECT_TYPE = {
  CO_PROCESSING_PROJECT: 'CPP',
  GENERIC_PROJECT: 'GSP',
};

export const ERP_TEST_METHODS = {
  DUMMY_METHOD_1: 'dummyMethod1',
  DUMMY_METHOD_2: 'dummyMethod2',
  OTHER: 'other',
};

export const ERP_TEST_METHODS_LIST = [
  { id: ERP_TEST_METHODS.DUMMY_METHOD_1, name: ERP_TEST_METHODS.DUMMY_METHOD_1 },
  { id: ERP_TEST_METHODS.DUMMY_METHOD_2, name: ERP_TEST_METHODS.DUMMY_METHOD_2 },
  { id: ERP_TEST_METHODS.OTHER, name: ERP_TEST_METHODS.OTHER },
];

export const ERP_NO_CREDITING_END_DATE_IDS = [4];

export const ERP_CO_PROCESSING_PROJECT_FUELS = ['CLCIG', 'CLCID', 'CLCIJ', 'CLCILFO', 'CLCIHFO', 'CLCIP', 'OCLIF'];

export const ERP_PROJECT_NAME_FLD = 'projectName';
export const ERP_PROJECT_TYPE_FLD = 'projectType';
export const ERP_APPROVALS_FLD = 'applyForApprovalType';
export const ERP_CARBON_INTENSITY_FLD = 'carbonIntensity';
export const ERP_PROJECT_START_DATE = 'startDate';
export const ERP_PROJECT_END_DATE = 'endDate';
export const ERP_PREFERRED_CREDITING_START_DATE_FLD = 'creditingPeriodStartDate';
export const ERP_PREFERRED_CREDITING_END_DATE_FLD = 'creditingPeriodEndDate';
export const ERP_ESTIMATED_ANNUAL_CREDITS_FLD = 'carbonIntensityFuel';
export const ERP_QUANTIFICATION_METHOD_FLD = 'quantificationMethod';
export const ERP_TEST_METHOD_FLD = 'approvalTestMethodIds';
export const ERP_TEST_METHOD_OTHER_FLD = 'otherApprovalTestMethod';
export const ERP_FUELS_FLD = 'fuelIds';
export const ERP_CREDITING_PERIOD_FLD = 'creditingPeriod';
export const REF_CI = 'referenceCarbonIntensityOfFuel';

export const ADMIN_ENABLED_FIELDS = [
  ERP_PREFERRED_CREDITING_START_DATE_FLD,
  ERP_PREFERRED_CREDITING_END_DATE_FLD,
];

export const APPLICATION_CO2E_EMISSION_REDUCTION = {
  page_title: 'Application for Recognition of CO2e Emmision Reduction Project',

  steps: [
    {
      id: 1,
      name: 'FACILITY_OR_EQUIPMENT',
      completed: false,
      component: ERP_APPLICATION.FACILITY_OR_EQUIPMENT,
    },
    {
      id: 2,
      name: 'PROJECT_DESCRIPTION',
      completed: false,
      component: ERP_APPLICATION.PROJECT_DESCRIPTION,
    },
    {
      id: 3,
      name: 'ERP_UPLOAD_DOCUMENTS',
      component: ERP_APPLICATION.UPLOAD_DOCUMNETS,
      inputData: {
        documentType: 'erp',
      },
    },
    {
      id: 4,
      name: 'CONTACT_PERSON_INFO',
      component: ERP_APPLICATION.CONTACT_PERSON_INFO,
      inputData: {
        step: '100',
      },
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },

};

export const ERP_APPLICATION_PROJECT_DESCRIPTION_FORM: IRawFormBase[] = [
  {
    groupFields: false,
      fieldGroup: [
        {
          type: 'input',
          className: 'col-sm-12 col-md-6',
          key: ERP_PROJECT_NAME_FLD,
          id: ERP_PROJECT_NAME_FLD,
          label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.projectName',
          required: false,
        },
        {
          type: 'dummy',
        },
        {
          type: 'dict-select',
          className: 'col-sm-12 col-md-6',
          key: ERP_PROJECT_TYPE_FLD,
          source: SOURCE_DATA_TYPE.ERP_PROJECT_TYPE,
          label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.projectTypeDropdown',
          disabled: false,
          required: true,
          id: ERP_PROJECT_TYPE_FLD,
          translatePrefix: 'COMMON.erpProjectTypeList',
          placeholder: 'selectItem',
        },
        {
          type: 'dict-select',
          className: 'col-sm-12 col-md-6',
          key: ERP_QUANTIFICATION_METHOD_FLD,
          label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.quantificationMethodDropdown',
          disabled: false,
          required: true,
          id: ERP_QUANTIFICATION_METHOD_FLD,
          translatePrefix: 'COMMON.erpProjectTypeList',
          ignoreTranslateOptions: true,
          placeholder: 'selectItem',
        },
      ],
  },
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'radio',
        key: ERP_APPROVALS_FLD,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.approvalsLabel',
        dummyInputForLabel: true,
        className: 'col-sm-12 inline',
        required: true,
        options: [
          {key: true, value: 'COMMON.actionsLabel.YES'},
          {key: false, value: 'COMMON.actionsLabel.NO'},
        ],
        hideExpression: `model.${ ERP_PROJECT_TYPE_FLD } !== '${ ERP_PROJECT_TYPE.CO_PROCESSING_PROJECT }'`,
      },
    ],
  },
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'multi-checkbox',
        className: 'mt-4 col-sm-12 col-md-6',
        key: ERP_TEST_METHOD_FLD,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.testMethodsDropdown',
        disabled: false,
        id: ERP_TEST_METHOD_FLD,
        translateOptions: false,
        placeholder: 'selectItem',
        requiredExpression: `model.${ ERP_PROJECT_TYPE_FLD } === '${ ERP_PROJECT_TYPE.CO_PROCESSING_PROJECT }' && model.${ ERP_APPROVALS_FLD }`,
        hideExpression: `model.${ ERP_PROJECT_TYPE_FLD } !== '${ ERP_PROJECT_TYPE.CO_PROCESSING_PROJECT }' || model.${ ERP_APPROVALS_FLD } === false`,
        skipSort: true,
      },
      {
        type: 'input',
        key: ERP_TEST_METHOD_OTHER_FLD,
        className: 'mt-4 col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.otherTestMethod',
        disabled: false,
        required: true,
      },
    ],
  },
  {
    groupFields: false,
    fieldGroup: [
        {
          key: ERP_PROJECT_START_DATE,
          type: 'app-formly-datepicker',
          className: 'col-sm-12 col-md-6',
          label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.projectStartDate',
          required: true,
        },
        {
          key: ERP_PROJECT_END_DATE,
          type: 'app-formly-datepicker',
          className:'col-sm-12 col-md-6',
          label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.projectEstimatedEndDate',
          required: false,
          expressionProperties: { 'templateOptions.minDate': `model.${ ERP_PROJECT_START_DATE }` },
        },
        {
          type:'input',
          key: 'projectDuration',
          label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.projectDuration',
          disabled: true,
          required: false,
        },
    ],
  },
  {
    groupFields: false,
    fieldGroup: [
      {
        key: ERP_PREFERRED_CREDITING_START_DATE_FLD,
        id: ERP_PREFERRED_CREDITING_START_DATE_FLD,
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.preferredCreditingStartDate',
        required: false,
        templateOptions: {
          minDate: moment().local().toDate(),
          maxDate: moment().local().add(18, 'month').toDate(),
        },
      },
      {
        key: ERP_PREFERRED_CREDITING_END_DATE_FLD,
        id: ERP_PREFERRED_CREDITING_END_DATE_FLD,
        type: 'app-formly-datepicker',
        className: 'col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.creditingPeriodEndDate',
        required: true,
        templateOptions: {
          minDate: moment().local().toDate(),
        },
      },
      {
        key: 'dummy',
        id: 'dummy',
        type: 'input',
        className: 'col-sm-12 col-md-6',
        placeholder: 'N/A',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.creditingPeriodEndDate',
        required: false,
        disabled: true,
      },
      {
        type: 'input',
        key: ERP_CREDITING_PERIOD_FLD,
        id: ERP_CREDITING_PERIOD_FLD,
        className: 'col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.creditingPeriod',
        disabled: true,
      },
      {
        type: 'input',
        key: ERP_ESTIMATED_ANNUAL_CREDITS_FLD,
        id: ERP_ESTIMATED_ANNUAL_CREDITS_FLD,
        className: 'col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.estimatedAnnualCredits',
        disabled: false,
        required: true,
        allowNegativeNumbers: false,
        validators: ['positiveNumber'],
        customTemplateOptions: {
          type: 'formatted-number',
        },
      },
    ],
  },
];

export const ERP_APPLICATION_PROJECT_DESCRIPTION_REGULATORY_REGIME: IRawForm[] = [
  {
    arrayField: true,
    key: 'applicationRegulatoryRegimes',
    type: 'form-array',
    removeText: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.remove',
    formRowClass: 'col-sm-12 col-md-10',
    buttonClass: 'col-sm-12 col-md-2',
    removeButtons: true,
    disabled: false,
    fieldGroup: [
      {
        key: 'entityName',
        type: 'dict-select',
        className: 'col-sm-12 col-md-6',
        required: false,
        source: SOURCE_DATA_TYPE.REGULATORY_REGIME,
        translatePrefix: 'COMMON.regulatoryRegimeList',
        placeholder: 'selectItem',
        clearable: true,
       },
      {
        type: 'input',
        key: 'name',
        className: 'col-sm-12 col-md-6',
        label: 'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.description',
        dummyLabel: true,
        disabled: false,
        required: false,
      },
    ],
  },
];


