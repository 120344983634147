/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cfs-secure-sign-in.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../account-selection/account-selection.component.ngfactory";
import * as i3 from "../../../account-selection/account-selection.component";
import * as i4 from "../../../../../core/store/store.service";
import * as i5 from "./cfs-secure-sign-in.component";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/router";
var styles_CfsSecureSignInComponent = [i0.styles];
var RenderType_CfsSecureSignInComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CfsSecureSignInComponent, data: {} });
export { RenderType_CfsSecureSignInComponent as RenderType_CfsSecureSignInComponent };
export function View_CfsSecureSignInComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-account-selection", [], null, null, null, i2.View_AccountSelectionComponent_0, i2.RenderType_AccountSelectionComponent)), i1.ɵdid(2, 114688, null, 0, i3.AccountSelectionComponent, [i4.StoreService], { header: [0, "header"], thankyoumessage: [1, "thankyoumessage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTitle; var currVal_1 = _co.message; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CfsSecureSignInComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cfs-secure-sign-in", [], null, null, null, View_CfsSecureSignInComponent_0, RenderType_CfsSecureSignInComponent)), i1.ɵdid(1, 245760, null, 0, i5.CfsSecureSignInComponent, [i6.TranslateService, i4.StoreService, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CfsSecureSignInComponentNgFactory = i1.ɵccf("app-cfs-secure-sign-in", i5.CfsSecureSignInComponent, View_CfsSecureSignInComponent_Host_0, { inputData: "inputData", executeLastEvent: "executeLastEvent", onSaveAsDraft: "onSaveAsDraft", onSubmit: "onSubmit", onSaveAndProceed: "onSaveAndProceed" }, { stepComplete: "stepComplete" }, []);
export { CfsSecureSignInComponentNgFactory as CfsSecureSignInComponentNgFactory };
