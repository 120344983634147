<div class="filters" *ngIf="showFilters">
  <app-table-filter
    *ngIf="propertyLoaded"
    [fields]="accountListFilters"
    (actionSelected)="actionSelectedController($event)"
    (filterInit)="onFilterChanged($event, false)"
    (filterChanged)="onFilterChanged($event)">
  </app-table-filter>
</div>

<div class="table-area">
  <app-table-view
    [data]="accounts"
    [config]="tableConfig"
    [firstItemIndex]="firstItemIndex"
    (paginationChanged)="onPaginationChanged($event)">
  </app-table-view>
</div>

<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewAccount($event, data)"
          (keyup)="viewAccount($event, data)">
      {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #actionTemplate let-data>
  <div>
    <app-account-actions
      [account_id]="data?.id"
      [accountData]="data"
      [actionsList]="data.entityActionList"
      [actionService]="actionService"
      (actionSuccessfull)="refreshData($event)"
    >
    </app-account-actions>
  </div>
</ng-template>
