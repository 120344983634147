import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
export const ACCOUNT_MGMT_GET_ACCOUNT = '/account-service/account';
export const ACCOUNT_DETAILS = '/account-service/account/detail';
// Account Actions
export const ACTIVITY_LOG_SEARCH = '/registry-server/activityLog/latestTransactions';
export const ACCOUNT_SEARCH_USERS = '/account-service/searchUser';
// OBPS Service
export const OBPS_CREDIT_SUMMARY = '/obps-service/unit/creditSummaryByAccountId';
export const OBPS_PROGRAM_DEADLINE = '/obps-service/properties/getAccountDeadlines';
export const OBPS_OBLIGATION_MGMT = '/registry-server/obligation/search';
export const OBPS_OBLIGATION_SUMMARY = '/registry-server/obligation/consolidatedAccountObligations';
export const UPDATE_OBPS_NFMP = '/account-service/account/updateObpsNonFacilityAccount';
export const UPDATE_CM = '/account-service/ompAccount/save';
export const GET_AUTHORIZED_OFFICIAL = '/account-service/private/user/findAuthorizedOfficialByAccountId';
export const GET_ALL_ROLES = '/account-service/private/user/roleList';
export const OBLIGATION_MANAGEMENT = '/registry-server/obligation/search';
export const UPCOMING_DEADLINES = '/registry-server/obligation/upcomingDeadlines';
export const LOOKUP_ALL_USER_ROLES_LIST = '/account-service/private/user/roleList';
export const LOOKUP_ALL_CREATE_USER_ROLES = '/account-service/private/user/roles';
export const WIRETRANSFER_DETAILS = '/obps-service/transaction/getWireTransferDetails';
export const DIRECT_DEPOSIT_DETAILS = '/obps-service/transaction/getDirectDepositDetails';
export const REGISTRATION_REPORT_DETAILS = '/account-service/cfrAccount/registration/pageSearch';
export const DOCUMENT_UPLOAD_REGISTRATION_VB = '/account-service/registrationDocument';
export const SUBMIT_ASSOCIATION = '/account-service/private/user/associationSubmit';
export const ACTIVITY_DETAIL_LIST = '/account-service/cfrAccount/activityDetails/pageSearch';
export const CHECK_APPROVED_CI = '/cfr-service/application/checkUserHasApprovedCisByAccountId/';
export class AccountManagementService extends AbstractPaginationService {
    constructor(http, translateService) {
        super();
        this.http = http;
        this.translateService = translateService;
        this.currentAccountId$$ = new BehaviorSubject(null);
        this.currentAccountDetails$$ = new BehaviorSubject(null);
    }
    getAuthorizedOfficial(accountId) {
        return this.http.get(`${environment.apiUrl}${GET_AUTHORIZED_OFFICIAL}/${accountId}`);
    }
    getAll(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${ACCOUNT_MGMT_GET_ACCOUNT}/pageSearch`, filterState, { params });
    }
    get(accountId) {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_MGMT_GET_ACCOUNT}/${accountId}`);
    }
    getLatestTransactions(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${ACTIVITY_LOG_SEARCH}`, filterState, { params });
    }
    getAccountDetails(accountId, forceReload = false) {
        const currentAccountId = this.currentAccountId$$.getValue();
        if (currentAccountId !== null && currentAccountId !== accountId) {
            this.currentAccountDetails$$.next(null);
        }
        if (forceReload || accountId !== currentAccountId) {
            this.currentAccountId$$.next(accountId);
            return this.http.get(`${environment.apiUrl}${ACCOUNT_DETAILS}/${accountId}`)
                .pipe(tap(details => this.currentAccountDetails$$.next(details)));
        }
        else {
            return this.currentAccountDetails$$.asObservable();
        }
    }
    getOBPSCreditSummary(accountId) {
        return this.http.get(`${environment.apiUrl}${OBPS_CREDIT_SUMMARY}/${accountId}`);
    }
    getOBPSObligationSummary(accountId) {
        return this.http.get(`${environment.apiUrl}${OBPS_OBLIGATION_SUMMARY}/${accountId}`);
    }
    getOBPSAccountObligations(accountId) {
        const filterState = {
            facilityNameList: [accountId],
        };
        let params = new HttpParams();
        params = this.addPagination(params, null);
        return this.http.post(`${environment.apiUrl}${OBPS_OBLIGATION_MGMT}`, filterState, { params });
    }
    searchUsersByAccountId(accountId) {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_SEARCH_USERS}/${accountId}`);
    }
    getRegistrationDetails(pagination, filterState = {}) {
        let params = new HttpParams();
        filterState.discriminatorType = 'REGISTRATION_ACCOUNT';
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${REGISTRATION_REPORT_DETAILS}`, filterState, { params });
    }
    getActivitiesDetailList(pagination, filterState = {}) {
        let params = new HttpParams();
        filterState.discriminatorType = 'REGISTRATION_ACCOUNT';
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${ACTIVITY_DETAIL_LIST}`, filterState, { params });
    }
    updateOBPS_NFMP(request, quiet = false) {
        return this.http.post(`${environment.apiUrl}${UPDATE_OBPS_NFMP}${quiet ? '?quiet=1' : ''}`, request);
    }
    updateCM(request) {
        return this.http.post(`${environment.apiUrl}${UPDATE_CM}`, request);
    }
    formatCreditSummary(creditSummary) {
        creditSummary.totalIssuedStr = this.formatCreditCount(creditSummary.totalIssued);
        creditSummary.currentHoldingsStr = this.formatCreditCount(creditSummary.currentHoldings);
        creditSummary.totalVolunatryCancellationsStr = this.formatCreditCount(creditSummary.totalVolunatryCancellations);
        creditSummary.totalPendingOutgoingUnitsStr = this.formatCreditCount(creditSummary.totalPendingOutgoingUnits);
        creditSummary.totalPendingIncomingUnitsStr = this.formatCreditCount(creditSummary.totalPendingIncomingUnits);
        creditSummary.totalActiveSurplusCreditsStr = this.formatCreditCount(creditSummary.totalActiveSurplusCredits);
        creditSummary.totalOffsetCreditIssuedStr = this.formatCreditCount(creditSummary.totalOffsetCreditIssued);
        creditSummary.totalActiveOffsetCreditsStr = this.formatCreditCount(creditSummary.totalActiveOffsetCredits);
    }
    formatObligationSummary(creditSummary) {
        creditSummary.outstandingObligationStr = this.formatObligationCount(creditSummary.outstandingObligation);
        creditSummary.compensationProvidedToDateStr = this.formatObligationCount(creditSummary.compensationProvidedToDate);
    }
    formatCreditCount(creditCount) {
        if (creditCount != null) { // } && creditCount >= 0) {
            return `${creditCount.toLocaleString()}  ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
        }
        return `0  ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
    }
    formatObligationCount(creditCount) {
        if (creditCount != null) { // } && creditCount >= 0) {
            return `${creditCount.toLocaleString()} ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
        }
        return `0  ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
    }
    getUpcomingCompensationDeadlines(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${UPCOMING_DEADLINES}`, filterState, { params });
    }
    getWireTransferDetails(id = null, facilityCertificateNumber) {
        const body = { id, facilityCertificateNumber };
        return this.http.post(`${environment.apiUrl}${WIRETRANSFER_DETAILS}`, body);
    }
    getDirectDepositDetails() {
        return this.http.get(`${environment.apiUrl}${DIRECT_DEPOSIT_DETAILS}`);
    }
    getAllRoles() {
        return this.http.get(`${environment.apiUrl}${GET_ALL_ROLES}`);
    }
    getAllViewableRoles() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ALL_USER_ROLES_LIST}`);
    }
    getAllCreateRoles() {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ALL_CREATE_USER_ROLES}`);
    }
    addAssociation(payload) {
        return this.http.post(`${environment.apiUrl}${SUBMIT_ASSOCIATION}`, payload);
    }
    getAccountProjects(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.serviceUrl}/offset-service/dashboard/project/pageSearchByFilterForSummary?`, filterState, { params });
    }
    checkUserHasApprovedCI(accountId) {
        return this.http.get(`${environment.apiUrl}${CHECK_APPROVED_CI}${accountId}`);
    }
}
AccountManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountManagementService_Factory() { return new AccountManagementService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService)); }, token: AccountManagementService, providedIn: "root" });
