import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from '@core/components/forgot-password/forgot-password.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@shared/components/ng-select/ng-select.module';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalService } from '@shared/services/modal.service';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from '../shared/shared.module';
import { AppWrapperComponent } from './components/app-wrapper/app-wrapper.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { ForgotUsernameComponent } from './components/forgot-username/forgot-username.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { LoginComponent } from './components/login/login.component';
import { RequiredInformationComponent } from './components/required-information/required-information.component';
import { ServiceMessageComponent } from './components/service-message/service-message.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { RequestInterceptorService } from './interceptors/request-interceptor.service';
import { RespondInterceptorService } from './interceptors/respond-interceptor.service';
import { FacilityAccountChooserComponent } from './components/facility-account-chooser/facility-account-chooser.component';
import { TooltipModule } from 'primeng';
import { ChooseProgramComponent } from './components/choose-program/choose-program.component';


@NgModule({
  declarations: [
    LoginComponent,
    AppWrapperComponent,
    ForgotPasswordComponent,
    LandingPageComponent,
    TermsConditionsComponent,
    ServiceMessageComponent,
    ComingSoonComponent,
    RequiredInformationComponent,
    ForgotUsernameComponent,
    FacilityAccountChooserComponent,
    ChooseProgramComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    RouterModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ToastModule,
    DialogModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    NgSelectModule,
    LocalizeRouterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
    TooltipModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RespondInterceptorService,
      multi: true,
    },
    MessageService,
    ModalService,
  ],
  entryComponents: [
    TermsConditionsComponent,
    ServiceMessageComponent,
    ComingSoonComponent,
    RequiredInformationComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CoreModule {

}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
