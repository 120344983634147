import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IUserData } from '@core/models/user.model';
import { IApp } from '@core/models/app.interfaces';
import { Subject } from 'rxjs';
import { TermsConditionsService } from '@core/services/terms-conditions.service';
import { takeUntil } from 'rxjs/operators';
import { UserTandC } from '@core/models/termsAndConditions.model';
import { convertUnixTimeToDate } from '@core/utilities/utilities.constants';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from "@shared/services/helper.service";
import { LookupService } from '@core/services/lookup.service';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent {

  private _destroy$ = new Subject<any>();
  public visible = true;
  tcContent: any;
  public currentUser: any;
  title = 'termsAndConditions';


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TermsConditionsComponent>,
    private router: Router,
    private store: StoreService,
    private authService: AuthService,
    private translate: TranslateService,
    private termsConditionsService: TermsConditionsService,
    private helperService: HelperService,
    private lookupService: LookupService,
    private sanitizer: DomSanitizer,
    private userService: UserService,
  ) {
    //this.tcContent = this.data !==null ? data.tandcContents[0] : null;
    this.store.user$.subscribe(
      currentUser =>  this.currentUser = currentUser,
    );
    this.dialogRef.disableClose = true;
    let request = this.termsConditionsService.getTandCUnacceptedByUserId(this.data.currentUser.id);
    if (this.data.readOnly) {
      if (this.data.privacy) {
        this.dialogRef.disableClose = false;
        this.title = 'privacyPolicy';
        request = this.termsConditionsService.getPrivacyPolicy();
      } else{
        this.dialogRef.disableClose = false;
        request = this.termsConditionsService.getTandAcceptedByUser();
      }
    }

    const parseTcContent: any = response => {
      return Array.isArray(response) ? response[0] : response
    };

    request.pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      response => {
        if(this.translate.currentLang === 'en')
          this.tcContent = parseTcContent(response).contentEn !== null ? parseTcContent(response).contentEn : '';
        else
          this.tcContent = parseTcContent(response).contentFr !== null ? parseTcContent(response).contentFr : '';

          this.tcContent = this.tcContent.replaceAll("&lt;", "<");
          this.tcContent = this.tcContent.replaceAll("&gt;", ">");

          console.log(this.tcContent);
          // var parser = new DOMParser();
          // var doc = parser.parseFromString(this.tcContent, 'text/html');
          // this.tcContent = doc.body;
          // this.tcContent = this.sanitizer.bypassSecurityTrustHtml(this.tcContent);
          // document.getElementById("tcContent").innerHTML = this.tcContent;
          // console.log("adfasdf");
      },
      err => {
      }
  );
   }

  accept(): void {
   // let now = convertUnixTimeToDate(new Date().toString()).toString();
    let request = new UserTandC();

    //request.accepted = now;
    request.userId = this.data.currentUser.id;
    request.tandcId = this.data.currentUser.tandcContents[0].id;

    this.termsConditionsService.acceptTermsAndConditions( request as  IApp.IUserTandc )
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          this.store.removeAcceptedTc(request.tandcId);
          this.store.setTcAccept('true');
          this.dialogRef.close();
        },
        err => {
        }
    );
  }

  get readOnlyView(): boolean {
    return this.data.readOnly || false;
  }

  close() {
    this.dialogRef.close();
  }

  signOut() {
    this.authService.signOut().subscribe(
      response => {
        this.store.removeTcAccept();
        this.dialogRef.close();
      },
      err => {
        // TODO remove this part after backend update
        this.store.destroy();
        this.lookupService.clearCache();
        this.userService.clearCache();
        this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
      },
    );
  }

  get lang(): string {
    return this.translate.currentLang;
  }

  openURL(url){
    let temp: string = '';
    if (!/^http[s]?:\/\//.test(url)) {
      temp += 'http://';
    }

    temp += url;
    window.open(temp, '_blank');
  }

}
