export const RAW_FORM_TYPE_AGREEMENT_FACILITY_RADIO = 'agreement-facility-radio';
export const RAW_FORM_TYPE_APP_FORMLY_DATEPICKER = 'app-formly-datepicker';
export const RAW_FORM_TYPE_BUTTON = 'button';
export const RAW_FORM_TYPE_CHECKBOX = 'checkbox';
export const RAW_FORM_TYPE_CHIPS = 'chips';
export const RAW_FORM_TYPE_COI_CHECKLIST_CHECKBOX_TYPE = 'coi-checklist-checkbox-type';
export const RAW_FORM_TYPE_DICT_SELECT = 'dict-select';
export const RAW_FORM_TYPE_DUMMY = 'dummy';
export const RAW_FORM_TYPE_FORM_ARRAY = 'form-array';
export const RAW_FORM_TYPE_INPUT_SWITCH = 'input-switch';
export const RAW_FORM_TYPE_INPUT = 'input';
export const RAW_FORM_TYPE_MULTI_CHECKBOX = 'multi-checkbox';
export const RAW_FORM_TYPE_RADIO_PAUL = 'radio-paul';
export const RAW_FORM_TYPE_RADIO = 'radio';
export const RAW_FORM_TYPE_READONLY_INPUT = 'readonly-input';
/** use this to show simple labels or to embed some data */
export const RAW_FORM_TYPE_TEMPLATE = 'template';
export const RAW_FORM_TYPE_DYNAMIC_TEMPLATE = 'dynamic-template';
export const RAW_FORM_TYPE_TEXTAREA = 'textarea';
export const RAW_FORM_TYPE_BLURB = 'blurb';
export const RAW_FORM_TYPE_EDITABLE_TABLE = 'editable-table';
export const RAW_FORM_TYPE_TABLE = 'table';
export const RAW_FORM_TYPE_ADDRESS_VIEW = 'address-view';
export const RAW_FORM_TYPE_KEY_VALUE_PAIRS = 'key-value-pairs';
export const RAW_FORM_TYPE_DOCUMENTS = 'documents';

export const RAW_FORM_INPUT_TYPE_STRING = 'string';
export const RAW_FORM_INPUT_TYPE_NUMBER = 'number';
export const RAW_FORM_INPUT_TYPE_FORMATTED_NUMBER = 'formatted-number';
export const RAW_FORM_INPUT_TYPE_INTEGER_NUMBER = 'integer-number';
export const RAW_FORM_INPUT_TYPE_FILE = 'file';

export const RAW_FORM_PERMISSION_VIEW = 'view';
export const RAW_FORM_PERMISSION_EDIT = 'edit';
export const RAW_FORM_TEXT_ONLY_FIELD = 'text-only-field';

export const rawFormEditableTableControlsMode = {
  custom: 'CUSTOM',
  predefined: 'PREDEFINED',
  all: 'ALL',
} as const;
