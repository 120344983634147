<div id="gen-agreement">
    <div class="d-md-flex justify-content-center pt-5 align-content-stretch">
        <div class="col-sm-12 col-md-9 col-xl-6 mb-5 d-inline-block">
            <h2 *ngIf="!existInMarkit && !rcaSign">{{'AGREEMENT_CREDITS.Step_5.headerMessage' | translate }}</h2>
            <h2 *ngIf="existInMarkit">{{'AGREEMENT_CREDITS.Step_5.ifOPAExist' | translate }}</h2>
            <h2 *ngIf="rcaSign">{{'AGREEMENT_CREDITS.Step_5.rcaSignHeaderMessage' | translate }}</h2>

            <div class="d-md-flex justify-content-center mt-2" *ngIf="!existInMarkit">
                <div class="card mb-2 mx-auto w-100 card-override">
                    <div class="card-body">
                      <div class="row">
                          <div class="col-12 mt-4 mb-4" style="text-align: center;">
                            <button class="btn btn-secondary mr-2" (click)="download()">{{'AGREEMENT_CREDITS.Step_5.downloadAgreement' | translate }}</button>
                          </div>
                          <div class="col-12  mb-4" style="text-align: center;">
                            <button class="btn btn-secondary mr-2" (click)="print()">{{'AGREEMENT_CREDITS.Step_5.printAgreement' | translate }}</button>
                          </div>
                      </div>
                    </div>
                </div>
            </div>

            <div class="footer">
              <button class="btn btn-secondary ml-2 float-right" type="button"  (click)="close()">
                {{'COMMON.wizard.close' | translate}}
              </button>
            </div>
        </div>
    </div>
</div>
