export const VIEW_PROJECT_PUBLIC_VISIBILITY = 'VIEW_PROJECT_PUBLIC_VISIBILITY';
export const EDIT_PROJECT_PUBLIC_VISIBILITY = 'EDIT_PROJECT_PUBLIC_VISIBILITY';
export const VIEW_DOCUMENT_PUBLIC_VISIBILITY = 'VIEW_DOCUMENT_PUBLIC_VISIBILITY';
export const EDIT_DOCUMENT_PUBLIC_VISIBILITY = 'EDIT_DOCUMENT_PUBLIC_VISIBILITY';

export type TPermission =
  | typeof VIEW_PROJECT_PUBLIC_VISIBILITY
  | typeof EDIT_PROJECT_PUBLIC_VISIBILITY
  | typeof VIEW_DOCUMENT_PUBLIC_VISIBILITY
  | typeof EDIT_DOCUMENT_PUBLIC_VISIBILITY;
