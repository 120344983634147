<p-dialog
  [(visible)]="visible"
  [autoZIndex]="false"
  [className]="'eccc'"
  [closable]="false"
  [header]="'PROJECTS_MODULE.createReportNotification.title' | translate"
  [maximizable]="false"
  [minY]="100"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
>
  <div class="container">
    <form [formGroup]="form" >
      <formly-form [form]="form" [model]="data" [fields]="fields">
      </formly-form>
    </form>
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button
          (click)="submit()"
          [disabled]="submitDisabled"
          class="btn btn-primary"
          type="button"
        >
          {{'PROJECTS_MODULE.createReportNotification.' + submitLabel | translate}}
        </button>
        <button
          (click)="close()"
          class="btn btn-secondary"
          type="button"
        >
          {{'COMMON.actionsLabel.CLOSE' | translate}}
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
