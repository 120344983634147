import { RegistrationService } from '@module/registration/registration.service'
import { FormlyFieldConfig } from '@ngx-formly/core';
import { REGISTRATION_TYPE, INITIAL_REGISTRATION_ENTITY_SECTION, INITIAL_REGISTRATION_ADDRESS_SECTION, INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION, OBPS_REGISTRATION_RESPONSABLE_PERSON_SECTION, OBPS_REGISTRATION_ADDRESS_SECTION, OBPS_REGISTRATION_ENTITY_SECTION, SOURCE_DATA_TYPE, REGISTRATION_FIELDS, INITIAL_REGISTRATION_ENTITY_SECTION_INDIVIDUAL, INITIAL_REGISTRATION_ADDRESS_SECTION_INDIVIDUAL, INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION_INDIVIDUAL, NFMP_REGISTRATION_ADDRESS_SECTION_ORG, NFMP_REGISTRATION_ENTITY_SECTION_ORG, NFMP_REGISTRATION_ENTITY_SECTION_IND, NFMP_REGISTRATION_ADDRESS_SECTION_IND } from '@shared/components/stepper/stepper.const';
import { LookupService } from '@core/services/lookup.service';
import { OMPAccountType } from '@core/models/account.model';
import { OMP_ACCOUNT_TYPE } from '@shared/components/omp-account-types/ompAccountTypePurpose.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { AppInjector } from '@shared/services/app-injector.service';

export class RegistrationForm {

    public registrationForm :FormlyFieldConfig[];
    readonly requiredFields:any[];
    readonly disabledFields:any[]
    readonly personsCount:number;
    private ompAccountTypePurpose:OMPAccountType;
    private formFactoryService: FormFactoryService

    constructor(
        private registrationService: RegistrationService,
        registrationType: REGISTRATION_TYPE,
        private lookupService: LookupService,
        fieldsRequired: any[],
        fieldsDisabled:any[],
        personsCount:number,
        accountTypePurpose : OMPAccountType ){

        this.personsCount = personsCount;
        this.requiredFields = fieldsRequired;
        this.disabledFields = fieldsDisabled;
        this.ompAccountTypePurpose = accountTypePurpose;

        const injector = AppInjector.getInjector();
        this.formFactoryService = injector.get(FormFactoryService);

        switch(registrationType){
            case REGISTRATION_TYPE.INITIAL:
                this.createInitialRegistrationForm();
            break;

          case REGISTRATION_TYPE.CM:
            this.createNFMPRegistrationForm();
            break;

            case REGISTRATION_TYPE.NFMP:
                this.createNFMPRegistrationForm();
            break;

            case REGISTRATION_TYPE.OBPS:
                this.createOBPSRegistrationForm();
            break;
        }
    }

    getRegistrationForm(){
        return this.registrationForm;
    }

    private createInitialRegistrationForm(){

        this.registrationForm = [];

        if(this.ompAccountTypePurpose.accountSubType.name === OMP_ACCOUNT_TYPE.ORGANIZATION){

            this.configureForm(INITIAL_REGISTRATION_ENTITY_SECTION);
            this.configureForm(INITIAL_REGISTRATION_ADDRESS_SECTION);
            this.configureForm(INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION);
        }

        if(this.ompAccountTypePurpose.accountSubType.name === OMP_ACCOUNT_TYPE.INDIVIDUAL){

            this.configureForm(INITIAL_REGISTRATION_ENTITY_SECTION_INDIVIDUAL);
            this.configureForm(INITIAL_REGISTRATION_ADDRESS_SECTION_INDIVIDUAL);
            this.configureForm(INITIAL_REGISTRATION_AUTHORIZED_OFFICIAL_SECTION_INDIVIDUAL);
        }
    }

    private createNFMPRegistrationForm(){
      this.registrationForm = this.formFactoryService
        .getCMAccountRegistrationForm(this.ompAccountTypePurpose.accountSubType.name);
    }

    private createOBPSRegistrationForm(){
        this.registrationForm = [];

        this.configureForm(OBPS_REGISTRATION_ENTITY_SECTION);
        this.configureForm(OBPS_REGISTRATION_ADDRESS_SECTION);
        this.configureForm(OBPS_REGISTRATION_RESPONSABLE_PERSON_SECTION);
    }

    private configureForm(entityFields){

        if(entityFields.groupFields){

            if(entityFields.nestedPropertyKey)
                this.nestedPropertyGroupInFieldSet(entityFields);
            else
                this.groupInFieldSet(entityFields);
        } else{
            this.groupInRow(entityFields);
        }

    }

    private groupInRow(entityFields){
        this.registrationForm.push(
            {
                fieldGroupClassName: 'row',
                fieldGroup:this.createFieldGroup(entityFields)
            }
        );
    }

    private groupInFieldSet(entityFields){
        this.registrationForm.push(
            {
                fieldGroupClassName: 'row',
                key: entityFields.key,
                wrappers:['app-formly-fieldset'],
                templateOptions: {
                    label: entityFields.label,
                    className: entityFields.className,
                    translatePrefix: entityFields.translatePrefix,
                 },
                fieldGroup: this.createFieldGroup(entityFields)
            }
        );
    }

    private nestedPropertyGroupInFieldSet(entityFields){
        for(var index=0; index< this. personsCount ; index++){
            this.registrationForm.push(
                {
                    fieldGroupClassName: 'row',
                    wrappers:['app-formly-fieldset'],
                    templateOptions: {
                        label: entityFields.label,
                        translatePrefix: entityFields.translatePrefix,
                    },
                    fieldGroup: this.createFieldGroup(entityFields,index)
                }
            );
        }
    }

    private createFieldGroup(entityFields, nestedPropertyIndex?){

        let fieldRowTemp : FormlyFieldConfig[]=[];

        entityFields.fieldGroup.forEach( (entry) => {
            if(entityFields.nestedPropertyKey){
                entry = this.getNestedPropertyKey(entry, nestedPropertyIndex);
            }
            fieldRowTemp.push( this.createFieldByType(entityFields.nestedPropertyKey, entry) );
        })

        return fieldRowTemp;
    }

    private createFieldByType(nestedPropertyKey, field){
        switch(field.type){
            case 'input':
                return this.createInputTextField(nestedPropertyKey, field);
            break;
            case 'dict-select':
                return this.createDropdownField(field);
            break;
        }
    }

    private createInputTextField(nestedPropertyKey, field){
        let key = nestedPropertyKey ? field.nestedKey : field.key;
        return {
            className: 'col-6',
            type: field.type,
            key: nestedPropertyKey ? field.nestedKey : field.key ,
            templateOptions: {
                label: field.label,
                required: this.checkIfRequired(key),
                disabled: this.checkIfDisabled(field, key ),
                translatePrefix: field.translatePrefix,
                translateOptions: true,
            }
        }
    }

    private checkIfDisabled(field,key){

        if(this.disabledFields === null)
            return field.disabled;

        let fieldFound = this.disabledFields.find(element=> { return element === key } );

        return fieldFound !== undefined ? true : field.disabled;
    }

    private checkIfRequired(key){
        if(this.requiredFields[0] === REGISTRATION_FIELDS.ALL)
            return true

        if(this.requiredFields.length === 1){
            return this.requiredFields[0] === key;
        }else{
            return this.requiredFields.find(el => el === key ) !== undefined ? true : false;
        }
    }

    private getNestedPropertyKey(field, index?){
        field.nestedKey = field.parentKey + '[' + index + '].' + field.key;
        return field;
    }

    private createDropdownField(field) {
        return {
            className: 'col-6',
            type: 'dict-select',
            wrappers: ['form-field'],
            key: field.key,
            defaultValue: field.defaultValue,
            templateOptions: {
                source: this.getSourceForDropdown(field.source),
                label: field.label,
                dataProvided: field.dataProvided,
                items: field.items,
                translatePrefix: field.translatePrefix,
                translateOptions: true,
                placeholder: 'selectItem',
                required: this.checkIfRequired(field.key),
                disabled: field.disabled,
            },
        };
    }

    private getSourceForDropdown(dataType){
        switch(dataType){
            case SOURCE_DATA_TYPE.BUSSINES_STRUCTURE:
                return this.registrationService.getBusinessStructure()
            break;
            case SOURCE_DATA_TYPE.COUNTRY:
                return this.lookupService.getCountries();
            break;
            case SOURCE_DATA_TYPE.PROVINCE:
                return this.lookupService.getJurisdictions();
            break;
        }
    }
}
