import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@shared/services/helper.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MOPGuard implements CanActivate {

  constructor(
    private store: StoreService,
    private router: Router,
    private translate: TranslateService,
    private helperService: HelperService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const permission = next.data.permission as string;
    const user = this.store.user;
    if (user.hasPermission(permission)) {
      return of(true);
    } else {
      const initialPath = this.store.user.menuItems[0].path;
      this.router.navigate(this.helperService.getTranslatedPath(`/${initialPath}`));
      return of(false);
    }
  }
}
