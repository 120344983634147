import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'formly-form-array',
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
      <div [attr.class]="to.formRowClass">
        <formly-field [field]="field"></formly-field>
      </div>
      <ng-container *ngIf="to.removeButtons">
        <div [attr.class]="to.buttonClass">
          <button class="btn btn-secondary" type="button" (click)="remove(i)" [disabled]="to.disabled">{{to.removeText | translate}}</button>
        </div>
      </ng-container>
    </div>
  `,
})
export class FormlyFormArrayTypeComponent extends FieldArrayType {}
