<p-dialog
  [className]="'eccc'"
  [header]="'ADMIN_MODULE.menuList.' + data.title | translate"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '600px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <div class="col-lg-12" [innerHTML]="content"></div>


  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="TNC_close" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' |
          translate}}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
