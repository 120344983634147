<div class="inner">

  <div class="container-fluid wrapper d-flex flex-md-column h-100">

    <div class="row border-bottom flex-grow-0">
      <div class="col-12">
        <h2 class="m-0 pb-2"> {{'PROJECTS_MODULE.ProjectBuilder.subProjectApplicationForm' | translate}} </h2>
      </div>
    </div>

    <div class="row flex-fill overflow-hidden pt-4">

      <div class="h-100 overflow-auto"
           [ngClass]="masterProject.status === 'ACTIVE' && model.status && model.status !== 'IN_PROCESS' ? 'col-8' : 'col-12'">
        <div *ngIf="viewReady">
          <app-sub-project-form
            [popUpView]="false"
            [model]="model"
            [form]="form"
            [masterProject]="masterProject"
            (modelIdUpdate)="updateModelId($event)">
          </app-sub-project-form>
          <ng-container *ngIf="masterProject.status === 'ACTIVE' && model.isAddedSeparately">
            <div class="pl-2 mt-5 border-bottom">
              <h3>{{'PROJECTS_MODULE.ProjectBuilder.requiredDocument' | translate}}</h3>
            </div>
            <div class="pl-4 mt-3">
              <app-sub-project-documents
                [model]="model"
                *ngIf="model.id"
                [masterProject]="masterProject"
                ></app-sub-project-documents>
            </div>
          </ng-container>
        </div>

      </div>

      <div class="col-4 h-100 overflow-auto" *ngIf="masterProject.status === 'ACTIVE' && model.status && model.status !== 'IN_PROCESS'">
        <app-project-discussion [project]="model"></app-project-discussion>
      </div>
    </div>


    <div class="row button-panel d-flex justify-content-end flex-grow-0">
      <div class="button-background">
        <button type="button" class="btn btn-secondary" (click)="back()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.back' | translate}}</button>
        <ng-container *ngIf="!model.entityActionList; else ENTITY_ACTIONS">
          <ng-container *ngIf="!projectBuilderService.disabled">
            <button type="button" class="btn ml-2 btn-primary" (click)="save()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}</button>
            <button type="button" class="btn btn-primary ml-2"
              [disabled]="documentUploadInProgress.inProgress$ |async"
              (click)="submit()" >{{'PROJECTS_MODULE.ProjectBuilder.submitProject' | translate}}
            </button>
          </ng-container>
        </ng-container>

        <ng-template #ENTITY_ACTIONS>
          <ng-container *ngFor="let action of model.entityActionList">
            <button type="button" class="btn ml-2 btn-primary"
              (click)="save()"
              [disabled]="documentUploadInProgress.inProgress$ |async"
              *ngIf="action.workflowAction==='EDIT' || action.workflowAction==='EDIT_CUST'; else ACTIONS">
              {{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}
            </button>
            <ng-template #ACTIONS>
              <button type="button" class="btn btn-primary ml-2" (click)="triggerAction(action)" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.'+action.workflowAction | translate}}</button>
            </ng-template>
          </ng-container>
        </ng-template>

        <ng-container *ngIf="formDisabled">
          <button type="button" class="btn ml-2 btn-primary"
                  *hasPermission="'EDIT_PROJECT_PUBLIC_VISIBILITY'"
                  (click)="save()">
              {{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}
          </button>
        </ng-container>

      </div>
    </div>
  </div>
</div>
