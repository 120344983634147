import { EnvironmentalIntegrityAccountFilter } from '@core/models/filter-types.model';

export const ENVIRONMENT_INTEGRITY_ACCOUNT = {
  tableListApiUri: '/offset-service/eia/pageSearchByFilter',
  tableHeaderType: 'ENVIRONMENTAL_INTEGRITY_ACCOUNT',
  tableFilter: EnvironmentalIntegrityAccountFilter,
  hasViewTemple: true,
  hasActionTemple: false,
  // title : '.totalEIAHoldings',
  needsTableData: true,
  checkbox: true

};

