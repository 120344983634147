/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i3 from "primeng/dialog";
import * as i4 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i5 from "primeng/api";
import * as i6 from "../../pipes/custom-translate.pipe";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./custom-popup.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../core/store/store.service";
var styles_CustomPopupComponent = [i0.styles];
var RenderType_CustomPopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomPopupComponent, data: {} });
export { RenderType_CustomPopupComponent as RenderType_CustomPopupComponent };
export function View_CustomPopupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "p-dialog", [], [[8, "className", 0]], [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Dialog_0, i2.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i3.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpod(4, { width: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), (_l()(), i1.ɵeld(8, 0, null, 2, 6, "p-footer", [], null, null, null, i4.View_Footer_0, i4.RenderType_Footer)), i1.ɵdid(9, 49152, [[2, 4]], 0, i5.Footer, [], null, null), (_l()(), i1.ɵeld(10, 0, null, 0, 4, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["id", "closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i6.CustomTranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 500; var currVal_7 = false; var currVal_8 = _co.visible; var currVal_9 = _ck(_v, 4, 0, "500px"); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "eccc"; _ck(_v, 0, 0, currVal_0); var currVal_10 = _co.message; _ck(_v, 6, 0, currVal_10); var currVal_11 = _co.translatedMessage; _ck(_v, 7, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 13, 0, currVal_12); }); }
export function View_CustomPopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-popup", [], null, null, null, View_CustomPopupComponent_0, RenderType_CustomPopupComponent)), i1.ɵdid(1, 114688, null, 0, i8.CustomPopupComponent, [i9.MAT_DIALOG_DATA, i9.MatDialogRef, i7.TranslateService, i10.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPopupComponentNgFactory = i1.ɵccf("app-custom-popup", i8.CustomPopupComponent, View_CustomPopupComponent_Host_0, {}, {}, []);
export { CustomPopupComponentNgFactory as CustomPopupComponentNgFactory };
