<p-dialog
[className]="'eccc'"
[closable]="false"
[header]="linkData.header | translate"
[(visible)]="visible"
[modal]="true"
[responsive]="true"
[style]="{width: '600px'}"
[minY]="100"
[maximizable]="false"
[autoZIndex]="false">

<div class="container p-4">
  <p>{{linkData.linkDescription | translate}} <span *ngIf = "linkData.linktext" class = "link" (click)="executeAction()">{{linkData.linktext | translate}}</span> <span *ngIf="linkData.additionalDescription ">{{linkData.additionalDescription | translate}}</span></p>
</div>



<p-footer>
  <div class="footer-buttons">
    <div class="float-right">
      <button id="closebtn" type="button" class="btn btn-secondary" (click)="close(false)">{{'COMMON.actionsLabel.CLOSE'
        | translate}}</button>
      <button id="actionbtn" type="button" class="btn btn-secondary" (click)="executeAction()" *ngIf="linkData.actionButtonText">{{linkData.actionButtonText | translate}}</button>
    </div>
  </div>
</p-footer>
</p-dialog>
