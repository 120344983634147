/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sub-project-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../table-view/table-view.component.ngfactory";
import * as i3 from "../../table-view/table-view.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../pipes/custom-translate.pipe";
import * as i6 from "@angular/common";
import * as i7 from "../../../controls/generic-action-control/generic-action-control.component.ngfactory";
import * as i8 from "../../../controls/generic-action-control/generic-action-control.component";
import * as i9 from "@angular/router";
import * as i10 from "../../../services/helper.service";
import * as i11 from "../../../services/modal.service";
import * as i12 from "../../../services/table-template-controls.service";
import * as i13 from "./sub-project-list.component";
import * as i14 from "../../../../modules/agreement-credits/agreement-credits.service";
import * as i15 from "../../../services/project.service";
import * as i16 from "../../../../core/services/lookup.service";
import * as i17 from "../../../../core/store/store.service";
var styles_SubProjectListComponent = [i0.styles];
var RenderType_SubProjectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubProjectListComponent, data: {} });
export { RenderType_SubProjectListComponent as RenderType_SubProjectListComponent };
function View_SubProjectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-view", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TableViewComponent_0, i2.RenderType_TableViewComponent)), i1.ɵdid(1, 638976, null, 0, i3.TableViewComponent, [i1.ElementRef, i4.TranslateService], { data: [0, "data"], config: [1, "config"], relative: [2, "relative"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.tableConfig; var currVal_2 = _co.relative; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SubProjectListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.view($event, _v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.view($event, _v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i5.CustomTranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 3, 0, currVal_0); }); }
function View_SubProjectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubProjectListComponent_3)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showView(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }, null); }
function View_SubProjectListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-generic-action-control", [], null, null, null, i7.View_GenericActionControlComponent_0, i7.RenderType_GenericActionControlComponent)), i1.ɵdid(1, 245760, null, 0, i8.GenericActionControlComponent, [i9.Router, i10.HelperService, i11.ModalService, i12.TableTemplateControls], { rawData: [0, "rawData"], appendTo: [1, "appendTo"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = "app-project-list .table-container"; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SubProjectListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { actionTemplate: 0 }), i1.ɵqud(402653184, 2, { viewColTemplate: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubProjectListComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["viewColTemplate", 2]], null, 0, null, View_SubProjectListComponent_2)), (_l()(), i1.ɵand(0, [[1, 2], ["actionTemplate", 2]], null, 0, null, View_SubProjectListComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SubProjectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sub-project-list", [], null, null, null, View_SubProjectListComponent_0, RenderType_SubProjectListComponent)), i1.ɵdid(1, 245760, null, 0, i13.SubProjectListComponent, [i14.AgreementCreditsService, i15.ProjectService, i12.TableTemplateControls, i4.TranslateService, i16.LookupService, i10.HelperService, i11.ModalService, i6.Location, i17.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubProjectListComponentNgFactory = i1.ɵccf("app-sub-project-list", i13.SubProjectListComponent, View_SubProjectListComponent_Host_0, { data: "data", tableConfigName: "tableConfigName", relative: "relative" }, {}, []);
export { SubProjectListComponentNgFactory as SubProjectListComponentNgFactory };
