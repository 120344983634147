import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { accountExportExcel, accountExportPdf, accountListTableFilters,
  newAccountAction, newSubAccountAction } from '@core/models/filter-types.model';
import { FilterModel } from '@core/models/filter.model';
import { CurrentUser } from '@core/models/user.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
//import { accountListTableConfig } from '@module/account-management/account-management.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { CfrAccountActionsService } from '@module/account-management/services/cfr-account-actions.service';
import { OBPSAccountActionsService } from '@module/account-management/services/obps-account-actions.service';
import { OffsetAccountActionsService } from '@module/account-management/services/offset-account-actions.service';
import { CMAccountActionsService } from '@module/account-management/services/cm-account-actions.service';
import { TranslateService } from '@ngx-translate/core';
import { TableFilterComponent } from '@shared/components/table-filter/table-filter.component';
import { ActionOptionType } from '@shared/models/table-filter.models';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { HelperService } from "@shared/services/helper.service";
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-accounts-list',
  templateUrl: './accounts-list.component.html',
  styleUrls: ['./accounts-list.component.scss'],
})
export class AccountsListComponent implements OnInit, OnDestroy {

  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild(TableFilterComponent, { static: false }) tableFilterComponent: TableFilterComponent;

  @Input() showFilters = true;
  @Input() masterAccountId: number;

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();

  public firstItemIndex = 0;
  public filterState: FilterModel = {};
  private pagination = {
    page: 0,
    size: ROWS_PER_PAGE,
  } as IApp.IPagination;

  tableConfig: ITableViewConfig;
  accounts: IApp.IAccountPage;
  accountListTableConfig: ITableViewConfig;
  user: CurrentUser;
  accountListFilters = accountListTableFilters;
  createAccountEnabled = false;
  propertyLoaded = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private lookupService: LookupService,
    private accountManagementService: AccountManagementService,
    private helperService: HelperService,
    private store: StoreService,
    private oBPSAccountActionsService: OBPSAccountActionsService,
    private cFRAccountActionsService: CfrAccountActionsService,
    private oFFSETAccountActionsService: OffsetAccountActionsService,
    private cMAccountActionsService: CMAccountActionsService,
  ) { }

  ngOnInit() {
   this.user = this.store.user;
   this.store.getProperty('nfmp.enabled.date')
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        property => {
          if (property.value  && property.value.toLowerCase() === 'true') {
            this.createAccountEnabled = true;
          }
          if(this.user.isOBPS()){
            this.createAccountEnabled = false;
          }
          if (this.user.isOffset()) {
            this.createAccountEnabled = true;
          }

          if(this.user.isCFR()){
            this.createAccountEnabled = false;
          }
          this.setActions();

          this.lookupService.getTableConfigurations('ACCOUNT').subscribe(
      data => {
        this.accountListTableConfig = data;
        this.accountListFilters.filterListsModel = data.filters;

        const { columns, ...config } = this.accountListTableConfig;

        const _columns: any = [
          ...this.accountListTableConfig.columns,
          {
            header: 'view',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          }];

        if ( this.user.hasOneOfThePermission(['APPROVE_ACCOUNT', 'ACCOUNT_DUAL_CONTROL_APPROVAL'])) {
          _columns.push({
            header: 'actions',
            width: '155px',
            templateRef: this.actionTemplate,
            className: 'overflow-visible',
          });
        }

        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };

        this.propertyLoaded = true;

      });
    });

   this.translate.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.loadAccounts(this.pagination, this.filterState));
  }

  setActions() {
    const options = [];
    if (this.user.hasPermission('ACCOUNT_WRITE') && this.createAccountEnabled) {
      if (this.user.isOffset()) {
        options.push(newSubAccountAction);
      } else {
        options.push(newAccountAction);
        options.push(newSubAccountAction);
      }

    }
    options.push(accountExportPdf);
    options.push(accountExportExcel);
    this.accountListFilters.actionModel[0].options = options;
  }

  private loadAccounts(pagination: IApp.IPagination, filterState: FilterModel = {}) {
    this._stopPrevious$.next();
    this.masterAccountId && (filterState = {...filterState, masterAccountId: this.masterAccountId});
    this.accountManagementService.getAll(pagination, filterState)
      .pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(
        data => this.accounts = data,
      );
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadAccounts(this.pagination, this.filterState);
  }

  onFilterChanged(filterData: FilterModel, loadData = true) {
    this.firstItemIndex = 0;
    this.pagination.page = this.firstItemIndex;
    this.filterState = filterData;
    loadData && this.loadAccounts(this.pagination, this.filterState);
  }

  viewAccount(event, account) {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    this.store.setHistoryUrl();
    if(this.user.isOffset() && this.masterAccountId ){
      this.router.navigate(this.helperService.getTranslatedPath(`/offset-registration/${account.id}/${account.masterAccountId}`));
    } else {
      this.router.navigate(this.helperService.getTranslatedPath(`/account-management/account-summary-user/${account.id}`));
    }
   }

  refreshData(event) {
    this.tableFilterComponent.searchServiceManager();
  }

  get lang(): string {
    return this.translate.currentLang;
  }

  actionSelectedController(event) {
    switch (event.type) {
      case ActionOptionType.URL:
        // this.router.navigateByUrl(this.lang + event.uri);
        this.router.navigate(this.helperService.getTranslatedPath(event.uri));
        break;
    }

  }

  get actionService() {
    return this[this.user.getServiceClass('AccountActionsService')];
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
