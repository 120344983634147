import { Component, OnInit } from '@angular/core';
import { FormlyFieldInput } from '@ngx-formly/bootstrap';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-dummy',
 template: '&nbsp;',
})
export class FormlyFieldDummy extends FieldType {

}
