/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rca-org-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i6 from "@ngx-formly/core";
import * as i7 from "@angular/forms";
import * as i8 from "./rca-org-details.component";
import * as i9 from "../../../../shared/services/form-factory.service";
import * as i10 from "../../../../shared/services/form.service";
import * as i11 from "../../agreement-credits.service";
import * as i12 from "../../../cfr-registration/services/cfr-registration.service";
var styles_RcaOrgDetailsComponent = [i0.styles];
var RenderType_RcaOrgDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RcaOrgDetailsComponent, data: {} });
export { RenderType_RcaOrgDetailsComponent as RenderType_RcaOrgDetailsComponent };
function View_RcaOrgDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_3.pleaseReviewInfoRCA")); _ck(_v, 1, 0, currVal_0); }); }
function View_RcaOrgDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_3.pleaseEnterInfo")); _ck(_v, 1, 0, currVal_0); }); }
export function View_RcaOrgDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["id", "other-party-org-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_RcaOrgDetailsComponent_1)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["enterInfo", 2]], null, 0, null, View_RcaOrgDetailsComponent_2)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "formly-form", [], null, null, null, i5.View_FormlyForm_0, i5.RenderType_FormlyForm)), i1.ɵprd(512, null, i6.FormlyFormBuilder, i6.FormlyFormBuilder, [i6.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(10, 966656, null, 0, i6.FormlyForm, [i6.FormlyFormBuilder, i6.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i7.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.existInMarkit; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = _co.formGroup; var currVal_4 = _co.model; var currVal_5 = _co.fields; _ck(_v, 10, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("AGREEMENT_CREDITS.Step.main_title")); _ck(_v, 3, 0, currVal_0); }); }
export function View_RcaOrgDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-rca-org-details", [], null, null, null, View_RcaOrgDetailsComponent_0, RenderType_RcaOrgDetailsComponent)), i1.ɵdid(1, 4440064, null, 0, i8.RcaOrgDetailsComponent, [i3.TranslateService, i9.FormFactoryService, i10.FormService, i11.AgreementCreditsService, i12.CfrRegistrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RcaOrgDetailsComponentNgFactory = i1.ɵccf("app-rca-org-details", i8.RcaOrgDetailsComponent, View_RcaOrgDetailsComponent_Host_0, {}, {}, []);
export { RcaOrgDetailsComponentNgFactory as RcaOrgDetailsComponentNgFactory };
