/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./replacing-mao.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i5 from "@ngx-formly/core";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./replacing-mao.component";
import * as i9 from "../../../../shared/services/navigation.service";
import * as i10 from "../../../../shared/services/form-factory.service";
import * as i11 from "../../../cfr-registration/services/cfr-registration.service";
import * as i12 from "../../../../shared/services/form.service";
import * as i13 from "../../../../core/store/store.service";
import * as i14 from "../../../../shared/services/modal.service";
import * as i15 from "@angular/router";
var styles_ReplacingMaoComponent = [i0.styles];
var RenderType_ReplacingMaoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReplacingMaoComponent, data: {} });
export { RenderType_ReplacingMaoComponent as RenderType_ReplacingMaoComponent };
function View_ReplacingMaoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ACCOUNTS_MODULE.accountSummary.submitRequestToAdmin")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReplacingMaoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.replace() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.APPROVE")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReplacingMaoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.reject() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.REJECT")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ReplacingMaoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "d-md-flex justify-content-center pt-5 align-content-stretch"], ["id", "replacing-mao"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "col-sm-12 col-md-9 col-xl-6 mb-5 d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 18, "div", [["class", "d-md-flex justify-content-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 17, "div", [["class", "card mb-2 mx-auto w-100 card-override"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 2, "formly-form", [], null, null, null, i4.View_FormlyForm_0, i4.RenderType_FormlyForm)), i1.ɵprd(512, null, i5.FormlyFormBuilder, i5.FormlyFormBuilder, [i5.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(13, 966656, null, 0, i5.FormlyForm, [i5.FormlyFormBuilder, i5.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i6.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReplacingMaoComponent_1)), i1.ɵdid(19, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReplacingMaoComponent_2)), i1.ɵdid(21, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReplacingMaoComponent_3)), i1.ɵdid(23, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.formGroup; var currVal_3 = _co.model; var currVal_4 = _co.fields; _ck(_v, 13, 0, currVal_2, currVal_3, currVal_4); var currVal_6 = _co.submission; _ck(_v, 19, 0, currVal_6); var currVal_7 = _co.editable; _ck(_v, 21, 0, currVal_7); var currVal_8 = _co.editable; _ck(_v, 23, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("ACCOUNTS_MODULE.accountSummary.reqForReplacingMAOTitle")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("ACCOUNTS_MODULE.accountSummary.reqForReplacingMAOMessage")); _ck(_v, 9, 0, currVal_1); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("ACCOUNTS_MODULE.accountSummary.close")); _ck(_v, 16, 0, currVal_5); }); }
export function View_ReplacingMaoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-replacing-mao", [], null, null, null, View_ReplacingMaoComponent_0, RenderType_ReplacingMaoComponent)), i1.ɵdid(1, 114688, null, 0, i8.ReplacingMaoComponent, [i9.NavigationService, i10.FormFactoryService, i3.TranslateService, i11.CfrRegistrationService, i12.FormService, i13.StoreService, i14.ModalService, i15.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReplacingMaoComponentNgFactory = i1.ɵccf("app-replacing-mao", i8.ReplacingMaoComponent, View_ReplacingMaoComponent_Host_0, {}, {}, []);
export { ReplacingMaoComponentNgFactory as ReplacingMaoComponentNgFactory };
