import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../store/store.service";
const PUBLIC_OFFSET_ENABLED = 'enable.public.offset';
export class PublicViewEnabledGuard {
    constructor(router, store) {
        this.router = router;
        this.store = store;
    }
    canActivate(next, state) {
        return this.canBeActivated(next);
    }
    canBeActivated(next) {
        return this.store.getProperty(PUBLIC_OFFSET_ENABLED)
            .pipe(switchMap(prop => of(prop.value && prop.value.toLowerCase() === 'true')), tap(value => !value && this.router.navigate([''])));
    }
}
PublicViewEnabledGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PublicViewEnabledGuard_Factory() { return new PublicViewEnabledGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.StoreService)); }, token: PublicViewEnabledGuard, providedIn: "root" });
