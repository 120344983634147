import { Component, Inject, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ProgramManagementService } from '@module/program-management/services/program-management.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { enLocales, frLocales } from '@shared/components/date-range/date-range.locales';
import { TranslateService } from '@ngx-translate/core';
import { convertToYYYYMMDD } from '@core/utilities/utilities.constants';

const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TIMEZONE = 'America/New_York';
const UTC_TIMEZONE = 'UTC';

@Component({
  selector: 'app-set-issuances-dates',
  templateUrl: './set-issuances-dates.component.html',
  styleUrls: ['./set-issuances-dates.component.scss']
})
export class SetIssuancesDatesComponent {
  private _destroy$ = new Subject<any>();
  public openUploadPopup = true;
  value: Date;
  minDate: Date;
  diff: number;
  public locales = {
    en: enLocales,
    fr: frLocales,
  };
  lang: string;
  scheduledTime:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SetIssuancesDatesComponent>,
    private programManagementService: ProgramManagementService,
    private translate: TranslateService,
  ) {
    this.lang = this.translate.currentLang;
    this.value = new Date(moment().local().tz(TIMEZONE).format('YYYY-MM-DD HH:mm:ss'));
    this.minDate = new Date(moment().local().tz(TIMEZONE).format('YYYY-MM-DD HH:mm:ss'));
 //   const utcTime: Date = new Date(moment().local().tz(UTC_TIMEZONE).format('YYYY-MM-DD HH:mm:ss'));
    //this.diff = utcTime.getTime() - this.value.getTime();

    this.programManagementService.getScheduledTime().pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      data => {
        this.scheduledTime = data;
        this.value = new Date(data);
      }
    );
  }

  onSelectDate() {
    const utcDate = new Date(this.value.getTime() );//+ this.diff);
    let newDate = moment(utcDate).format(DATE_FORMAT).toString();
    this.programManagementService.setIssuanceDate(newDate).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      data => {
        this.dialogRef.close();
      }
    );
  }

  close(): void {
    this.dialogRef.close();
  }

}
