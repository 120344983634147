/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-username.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/header/header.component.ngfactory";
import * as i3 from "../../../shared/components/header/header.component";
import * as i4 from "../../store/store.service";
import * as i5 from "../../services/auth.service";
import * as i6 from "@angular/router";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../../../shared/services/helper.service";
import * as i9 from "../../../shared/services/document-in-progress.service";
import * as i10 from "../../../shared/services/modal.service";
import * as i11 from "../../services/lookup.service";
import * as i12 from "../../../shared/services/user.service";
import * as i13 from "@angular/platform-browser";
import * as i14 from "../../../shared/services/previous-location.service";
import * as i15 from "../../../shared/components/retrieve-username/retrieve-username.component.ngfactory";
import * as i16 from "../../../shared/components/retrieve-username/retrieve-username.component";
import * as i17 from "../../../shared/components/footer/footer.component.ngfactory";
import * as i18 from "../../../shared/components/footer/footer.component";
import * as i19 from "../../../modules/registration/registration.service";
import * as i20 from "./forgot-username.component";
var styles_ForgotUsernameComponent = [i0.styles];
var RenderType_ForgotUsernameComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotUsernameComponent, data: {} });
export { RenderType_ForgotUsernameComponent as RenderType_ForgotUsernameComponent };
export function View_ForgotUsernameComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i3.HeaderComponent, [i4.StoreService, i5.AuthService, i6.Router, i7.TranslateService, i8.HelperService, i9.DocumentUploadInProgressService, i10.ModalService, i11.LookupService, i12.UserService, i13.Title, i14.PreviousLocationService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "content"], ["id", "main-content"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "row justify-content-center align-items-center"], ["style", "height: 80vh"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-sm-12 col-md-6 col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-retrieve-username", [], null, null, null, i15.View_RetrieveUsernameComponent_0, i15.RenderType_RetrieveUsernameComponent)), i1.ɵdid(7, 114688, null, 0, i16.RetrieveUsernameComponent, [i5.AuthService], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-footer", [], null, null, null, i17.View_FooterComponent_0, i17.RenderType_FooterComponent)), i1.ɵdid(9, 114688, null, 0, i18.FooterComponent, [i4.StoreService, i10.ModalService, i7.TranslateService, i6.Router, i8.HelperService, i19.RegistrationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 7, 0); _ck(_v, 9, 0); }, null); }
export function View_ForgotUsernameComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-username", [], null, null, null, View_ForgotUsernameComponent_0, RenderType_ForgotUsernameComponent)), i1.ɵdid(1, 114688, null, 0, i20.ForgotUsernameComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotUsernameComponentNgFactory = i1.ɵccf("app-forgot-username", i20.ForgotUsernameComponent, View_ForgotUsernameComponent_Host_0, {}, {}, []);
export { ForgotUsernameComponentNgFactory as ForgotUsernameComponentNgFactory };
