/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./other-party-org-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i5 from "@ngx-formly/core";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/common";
import * as i8 from "./other-party-org-details.component";
import * as i9 from "../../../../shared/services/form-factory.service";
import * as i10 from "../../../../shared/services/form.service";
import * as i11 from "../../agreement-credits.service";
import * as i12 from "../../../submission-management/services/submission-management.service";
var styles_OtherPartyOrgDetailsComponent = [i0.styles];
var RenderType_OtherPartyOrgDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OtherPartyOrgDetailsComponent, data: {} });
export { RenderType_OtherPartyOrgDetailsComponent as RenderType_OtherPartyOrgDetailsComponent };
function View_OtherPartyOrgDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_3.pleaseReviewInfo")); _ck(_v, 1, 0, currVal_0); }); }
function View_OtherPartyOrgDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_3.pleaseEnterInfo")); _ck(_v, 1, 0, currVal_0); }); }
export function View_OtherPartyOrgDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["id", "other-party-org-details"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "formly-form", [], null, null, null, i4.View_FormlyForm_0, i4.RenderType_FormlyForm)), i1.ɵprd(512, null, i5.FormlyFormBuilder, i5.FormlyFormBuilder, [i5.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(7, 966656, null, 0, i5.FormlyForm, [i5.FormlyFormBuilder, i5.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i6.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OtherPartyOrgDetailsComponent_1)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["enterInfo", 2]], null, 0, null, View_OtherPartyOrgDetailsComponent_2)), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["ngForm", 1]], null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i6.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(13, 540672, null, 0, i6.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.FormGroupDirective]), i1.ɵdid(15, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "formly-form", [], null, null, null, i4.View_FormlyForm_0, i4.RenderType_FormlyForm)), i1.ɵprd(512, null, i5.FormlyFormBuilder, i5.FormlyFormBuilder, [i5.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(18, 966656, null, 0, i5.FormlyForm, [i5.FormlyFormBuilder, i5.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i6.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.prevFormGroup; var currVal_2 = _co.model; var currVal_3 = _co.prevFields; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.existInMarkit; var currVal_5 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_4, currVal_5); var currVal_13 = _co.formGroup; _ck(_v, 13, 0, currVal_13); var currVal_14 = _co.formGroup; var currVal_15 = _co.model; var currVal_16 = _co.fields; _ck(_v, 18, 0, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("AGREEMENT_CREDITS.Step.main_title")); _ck(_v, 3, 0, currVal_0); var currVal_6 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_7 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_8 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_9 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_10 = i1.ɵnov(_v, 15).ngClassValid; var currVal_11 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_12 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); }); }
export function View_OtherPartyOrgDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-other-party-org-details", [], null, null, null, View_OtherPartyOrgDetailsComponent_0, RenderType_OtherPartyOrgDetailsComponent)), i1.ɵdid(1, 4440064, null, 0, i8.OtherPartyOrgDetailsComponent, [i3.TranslateService, i9.FormFactoryService, i10.FormService, i11.AgreementCreditsService, i12.SubmissionManagementService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OtherPartyOrgDetailsComponentNgFactory = i1.ɵccf("app-other-party-org-details", i8.OtherPartyOrgDetailsComponent, View_OtherPartyOrgDetailsComponent_Host_0, {}, {}, []);
export { OtherPartyOrgDetailsComponentNgFactory as OtherPartyOrgDetailsComponentNgFactory };
