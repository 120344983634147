import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { TLocation, ILocationDecimal, ILocationDegree } from '@core/models/location.model';
import { ProjectDiscussionService } from '@shared/components/project-discussion/service/project-discussion.service';
import { IProject } from '@shared/services/project.service.types';
import { kml } from '@tmcw/togeojson';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-project-discussion-map',
  templateUrl: './project-discussion-map.component.html',
  styleUrls: ['./project-discussion-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiscussionMapComponent implements OnInit {
  @Input() project: IProject;
  @ViewChild('map', { static: false }) map: google.maps.Map;

  apiLoaded: Observable<boolean>;
  center: google.maps.LatLngLiteral | google.maps.LatLng;
  zoom = 8;

  constructor(private httpClient: HttpClient, private projectDiscussionSvc: ProjectDiscussionService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.apiLoaded = this.initGoogleMapsSub();
    this.initKmlSub();
    this.initCenterSub();
  }

  private initGoogleMapsSub(): Observable<boolean> {
    // we must prevent multiple loading of the google maps api
    if (typeof google === 'object' && typeof google.maps === 'object') {
      return of(true);
    }

    return this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyDOBLSVAzfRdWIFOEcCtC5DMBn7Z-CBl60', 'callback').pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }

  private initKmlSub(): void {
    this.projectDiscussionSvc
      .getKml$(this.project.id)
      .pipe(take(1))
      .subscribe(data => {
        const geoJson = kml(new DOMParser().parseFromString(data, 'text/xml'));
        if (this.map) {
          this.map.data.addGeoJson(geoJson);
          this.zoomMap();
        }
      });
  }

  private zoomMap(): void {
    const bounds = new google.maps.LatLngBounds();

    this.map.data.forEach(feature => {
      const geometry = feature.getGeometry();

      if (geometry) {
        this.processPoints(geometry, bounds.extend, bounds);
      }
    });
    this.map.fitBounds(bounds);
  }

  private processPoints(geometry: google.maps.LatLng | google.maps.Data.Geometry, callback: any, thisArg: google.maps.LatLngBounds) {
    if (geometry instanceof google.maps.LatLng) {
      callback.call(thisArg, geometry);
    } else if (geometry instanceof google.maps.Data.Point) {
      callback.call(thisArg, geometry.get());
    } else {
      // @ts-ignore
      geometry.getArray().forEach(g => {
        this.processPoints(g, callback, thisArg);
      });
    }
  }

  private initCenterSub(): void {
    let lat: number;
    let lng: number;

    let location = this.parseLocation(this.project.activity.location);

    lat = location.lat;
    lng = location.lng;

    if (typeof lat !== 'number' || typeof lng !== 'number') {
      location = this.parseSubProjects();
      lat = location.lat;
      lng = location.lng;
    }
    this.center = { lat, lng };
    this.cd.detectChanges();
  }

  private parseSubProjects(): google.maps.LatLngLiteral {
    if (this.project.subProjects && this.project.subProjects.length) {
      for (const sub of this.project.subProjects) {
        const location = this.parseLocation(sub.activity.location);
        const { lat, lng } = location;
        if (typeof lat === 'number' && typeof lng === 'number') {
          return { lat, lng };
        }
      }
    }

    // default to 45°25′17″ N. 75°40′46″ W - Ottawa, Canada
    return { lat: 45.424722, lng: -75.695 };
  }

  private parseLocation(location: TLocation): google.maps.LatLngLiteral {
    const { type } = location;

    let lat: number = null;
    let lng: number = null;

    if (type === 'DECIMAL') {
      const { latitudeDecimal, longitudeDecimal } = location as ILocationDecimal;
      lat = latitudeDecimal;
      lng = longitudeDecimal;
    }

    if (type === 'DEGREE') {
      const { latitudeDegree, latitudeMinute, latitudeSecond, longitudeDegree, longitudeMinute, longitudeSecond } =
        location as ILocationDegree;
      lat = this.degreesToFloat(latitudeDegree, latitudeMinute, latitudeSecond);
      lng = this.degreesToFloat(longitudeDegree, longitudeMinute, longitudeSecond);
    }

    return { lat, lng };
  }

  private degreesToFloat(degree: number, minute: number, second: number): number {
    return parseFloat(`${degree}.${minute}${second}`);
  }
}
