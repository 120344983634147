/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./base-stepper-step.component";
var styles_BaseStepperStepComponent = [];
var RenderType_BaseStepperStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaseStepperStepComponent, data: {} });
export { RenderType_BaseStepperStepComponent as RenderType_BaseStepperStepComponent };
export function View_BaseStepperStepComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { el: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_BaseStepperStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-base-stepper-step", [], null, null, null, View_BaseStepperStepComponent_0, RenderType_BaseStepperStepComponent)), i0.ɵdid(1, 4374528, null, 0, i1.BaseStepperStepComponent, [], null, null)], null, null); }
var BaseStepperStepComponentNgFactory = i0.ɵccf("app-base-stepper-step", i1.BaseStepperStepComponent, View_BaseStepperStepComponent_Host_0, {}, {}, []);
export { BaseStepperStepComponentNgFactory as BaseStepperStepComponentNgFactory };
