<p-dialog
  [className]="'eccc'"
  [header]="title"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [focusTrap]="false"
  [style]="{width: '900px'}"
  [minY]="100"
  [maximizable]="false"
  [transitionOptions]="'0ms'"
  [autoZIndex]="false">

  <div class="container">
    <form [formGroup]="addNewRowForm" (ngSubmit)="addNewRowForm.valid && save(addNewRowForm)">
    <formly-form [model]="newEntry" [fields]="fields" [options]="options" [form]="addNewRowForm">
      <div class="dialog-footer">
        <div class="footer-buttons">

          <div class="float-right">
            <button id="TABLE_NEW_ROW_saveBtn"  *ngIf= "!viewOnly"  type="submit" class="btn btn-primary"> {{data.saveBtnLabel| translate }}</button>
          </div>
          <div class="float-right pr-2">
            <ng-container *ngIf="data.closeBtnLabel; else CANCEL">
              <button id="TABLE_NEW_ROW_cancelBtn" type="button" *ngIf= "!viewOnly"  class="btn btn-secondary" (click)="cancel()"> {{   data.closeBtnLabel | translate }}</button>
            </ng-container>
            <ng-template #CANCEL>
              <button id="TABLE_NEW_ROW_cancelBtn" type="button" *ngIf= "!viewOnly"  class="btn btn-secondary" (click)="cancel()"> {{   'COMMON.labels.cancel' | translate }}</button>
              <button id="TABLE_NEW_ROW_cancelBtn "type="button" *ngIf= "viewOnly"  class="btn btn-secondary" (click)="cancel()"> {{   'COMMON.labels.close' | translate }}</button>
            </ng-template>
          </div>
          <div class="float-right"  *ngFor="let button of data?.buttons; let i = index">

            <div class="float-right pr-2">
              <button type="button"
                      [id]="button.id"
                      class="btn btn-primary"
                      (click)="executeAction(form, button.actionUri,button.label)">{{ button.label | translate }}
              </button>
              </div>
             </div>
        </div>
      </div>
    </formly-form>
    </form>
  </div>
</p-dialog>
