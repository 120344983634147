<p-dialog
  [className]="'eccc'"
  [header]="'PROGRAM_MANAGEMENT.result_report_modal.title' | translate"
  [closable]="false"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '700px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <h2>{{'PROGRAM_MANAGEMENT.result_report_modal.approvalResult' | translate}}</h2>
  <p>{{'PROGRAM_MANAGEMENT.result_report_modal.issueCreditsMessage' | translate}} {{data.issued}}</p>
  <p>{{'PROGRAM_MANAGEMENT.result_report_modal.revokeCreditMessage' | translate}} {{data.revokeIssued}}</p>
  <p>{{'PROGRAM_MANAGEMENT.result_report_modal.assignedCreditMessage' | translate}} {{data.assigned}}</p>
  <p>{{'PROGRAM_MANAGEMENT.result_report_modal.revokeObligationMessage' | translate}} {{data.revokeAssigned}}</p>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="REPORT_RESULT_apporveBtn" type="button" *ngIf="data.reviewAllowed" class="btn btn-primary" (click)="approve()">{{'COMMON.actionsLabel.APPROVE' | translate}}</button>
        <button id="REPORT_RESULT_closeBtn" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
    </div>
    </div>
  </p-footer>

</p-dialog>
