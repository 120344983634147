import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { replaceParam } from '@core/utilities/utilities.constants';
import { environment } from '@env/environment';
import {
  IProjectReportSubmission,
  IProjectReportSubmissionPayload
} from '@module/project-report/service/project-report.service.types';
import { CreateReportNotificationModalComponent } from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.component';
import {
  notificationType,
  reportType,
} from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.constants';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import {
  PROJECT_ACCOUNT_ACTIVE_STATUSES,
  PROJECT_ACCOUNT_INACTIVE_ACTIONS,
  PROJECT_ACTIONS
} from '@shared/components/project/project.constants';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { PROJECT_CANCEL_ACTIONS, PROJECT_TRANSFER_ACTIONS, SECONDARY_ACTIONS } from '@shared/shared.const';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { last } from 'lodash';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';
import { IProject, IProjectApplication, IProjectApplicationContent } from './project.service.types';

export const AGREEMENT_OPA_LIST = '/account-service/agreement/getOpaList';
export const SAVE_OPA_ACCOUNT = '/account-service/agreement/saveOpaAccount';
export const UPDATE_STATUS = '/account-service/agreement/updateStatus';
export const PROJECT_LIST = '/offset-service/project/pageSearchByFilter?';
export const PROJECT_DETAILS = '/offset-service/project/getById';
export const PROJECT_DETAILS_BY_PROJECT_ID = '/offset-service/project/searchProject';
export const PROJECT_SUBMIT_CANCELLATION = '/offset-service/projectAction/submitCancellation';
export const PROJECT_SAVE_CANCELLATION = '/offset-service/projectAction/saveCancellation';
export const PROJECT_REVIEW_CANCELLATION = '/offset-service/projectAction/reviewCancellation';
export const PROJECT_REVIEW_2_CANCELLATION = '/offset-service/projectAction/review2Cancellation';
export const PROJECT_SEARCH_CANCEL_HISTORY = '/offset-service/projectAction/searchCancellationHistory';
export const GET_NEW_CANCELLATION_REQUEST_ID = '/offset-service/projectAction/getNewCancellationRequestId';
export const GET_NEW_TRANSFER_REQUEST_ID = '/offset-service/projectTransferRequest/getNewProjectTransferRequestId';
export const GET_BY_PENDING_PROJECT_TRANSFER_REQUEST_ID = '/offset-service/projectTransferRequest/get/';
export const PROJECT_GET_CANCELLATION_DETAILS = '/offset-service/projectAction/get';
export const NOTES_SEARCH = '/offset-service/notes/search';
export const AGREEMENT_LOAD = '/account-service/agreement/load';
export const DOWNLOAD_PDF = '/account-service/agreement/download';

export const OFFSET_PROJECT = '/offset-service/project/';
export const PROJECT_OFFSET_RECEIVER = '/offset-service/project/consent';
export const SAVE_PROJECT = '/offset-service/project/save';
export const SUBMIT_PROJECT = '/offset-service/project/submit';
export const GET_NEW_PROJECT_AND_ACTIVITYIDS = '/offset-service/project/getNewProjectAndActivityIds';
export const PROJECT_ACCOUNT_SEARCH = '/offset-service/project/search';
export const SUBMIT_PROJECT_ADDITIONS = '/offset-service/project/getByAdditionToAggregationProjectId';

export const UPLOAD_KML = '/account-service/document/uploadKmlFile';
export const DOWNLOAD_KML = '/account-service/document/downloadKml/';
export const GET_KML_INFO = '/account-service/document/getKml/';
export const DELETE_KML = '/account-service/document/doDeleteLocationFile';
export const UPLOAD_SUB_PROJECTS = '/offset-service/project/subProject/upload';

export const VALIDATE_TRANSFER_AGG_REQ = '/offset-service/projectTransferRequest/validateTransferAggregatedProject/';
const STEPS_TRANSLATION_PREFIX = 'AGREEMENT_CREDITS.wizard.';

export const PROJECT_DETAILS_URL_REGEX = /^\/[a-z]{2}\/project\/offset\/\d*\/\d*.*/;
export const DISCARD = '/offset-service/projectTransferRequest/discard';

export const PUBLIC_PROJECT_DETAILS = '/offset-service/public/project/getById';
export const PUBLIC_PROJECT_LIST = '/offset-service/public/project/pageSearchByFilter?';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const PROJECT_SUBMISSIONS = '/offset-service/submissions/searchSubmissions';

export const GET_PROJECT_BY_ACCOUNT_PROJECT = '/offset-service/project/getByProjectIdAccountId/';

export const DEFAULT_SUBMISSIONS_PAYLOAD = {
  projectIdList: [],
  submissionsTypeCode: reportType.projectReport,
  last: false,
};

@Injectable({
  providedIn: 'root',
})
export class ProjectService extends BaseStepperService {

  public translationPrefix = STEPS_TRANSLATION_PREFIX;
  // private _destroy$ = new Subject<any>();

  private _refreshData$ = new Subject<any>();
  public refreshData$ = this._refreshData$.asObservable();

  private _onProjectUpdate$ = new Subject<any>();
  public onProjectUpdate$ = this._refreshData$.asObservable();

  private _urlBeforeProjectPage: string;
  private currentTabUrl: string;

  constructor(private http: HttpClient,
              private router: Router,
              private modalService: ModalService,
              private helperService: HelperService,
              public translateService: TranslateService,
              private store: StoreService,
              private notificationService: NotificationsService,
   ) {
    super();
    this.currentTabUrl = this.router.url;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
       this.currentTabUrl = this.router.url;
       if (!PROJECT_DETAILS_URL_REGEX.test(event.url)) {
        this._urlBeforeProjectPage = event.url;
      }
    });
  }

  private createNewSubmission(data, url) {
    const currentTab = last((url).split('/'));
    if (currentTab === 'project-list') {
      data = { ...data, projectTab: true };
      this.modalService.open(CreateReportNotificationModalComponent, data);
    } else {
      this.notificationService.showMessage(SereviceMessageType.ERROR, 'TransactionNotPermittedOnPendingProjectTransferAccount');
    }
  }

  public get isPublicView(): boolean {
    return !this.store.getSession();
  }

  public getProjectApplicationList(pagination: IApp.IPagination, filterState: any = {}): Observable<IProjectApplication> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const uri = `${environment.apiUrl}${this.isPublicView ? PUBLIC_PROJECT_LIST : PROJECT_LIST}`;
    return this.http.post<any>(uri, filterState, { params });
  }

  public redirectToProjectScreen(reportId: any, entityId: number = null, project?: any, tabName: string = null, edit: boolean = false) {
    if (this.isPublicView) {
      this.router.navigate(this.helperService.getTranslatedPath(`/public/project/${entityId}`), { state: { data: project } });
    } else {
      let uri = entityId ? `${entityId}` : '';
      if (project) {
        if (project.type === 'MASTER') {
          uri = 'aggregated';
        } else if (project.type === 'MASTER_RECEIVER') {
          uri = 'MASTER_RECEIVER';
        } else {
          uri = 'offset';
        }
        // uri = project.type === 'MASTER' ? 'aggregated' : 'offset';
        uri += `/${project.accountId}/${project.id}`;
      }

      const tab = tabName ? '/' + tabName : '';
      const reDirectToSubTab = !!tabName;

      if (project.status === 'IN_PROCESS' || (reportId && edit)) {
        this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/${uri}`), {state: {data: project, edit, reportId,}});
      } else {
        this.router.navigate(this.helperService.getTranslatedPath(`/project/offset/${project.accountId}/${entityId}${tab}`), {state: {data: project, edit, reportId, reDirectToSubTab}});
      }
    }
  }

  public redirecToUrlBeforeProjectPage() {
    if (this._urlBeforeProjectPage) {
      this.router.navigate(this.helperService.getTranslatedPath(this._urlBeforeProjectPage));
    } else {
      this.redirecToProjectList();
    }
  }

  public redirecToProjectList() {
    this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/project-list`));
  }

  public redirectToSubProject(masterProjectId, entityId, project?: any) {
    this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/sub/${masterProjectId}/${entityId}`), { state: { data: project }});
  }

  public redirectToTransferProject(masterProjectId, entityId, project?: any) {
    this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${masterProjectId}/VIEW_PROJECT_TRANSFER_INIT`), { state: { data: project, formType: '' } });
  }

  saveProject(payload): Observable<any> {
    this.configureProjectDates(payload);
    return this.http.post<any>(`${ environment.serviceUrl }${SAVE_PROJECT}`, payload);
  }

  submitProject(payload): Observable<any> {
    this.configureProjectDates(payload);
    return this.http.post<any>(`${ environment.serviceUrl }${SUBMIT_PROJECT}`, payload);
  }

  consentProjectsTransfer(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJECT_OFFSET_RECEIVER}`, payload);
  }

  getNewProjectAndActivityIds(): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${GET_NEW_PROJECT_AND_ACTIVITYIDS}`);
  }

  searchProjectAccount(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${PROJECT_ACCOUNT_SEARCH}`, payload);
  }

  public getProjectDetails(projectId: number, queryParams: any = {}, reportId?: number): Observable<IProject> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach(key => params = params.append(key, queryParams[key]));
    let uri = `${environment.apiUrl}${PROJECT_DETAILS}/${projectId}`;
    if (reportId) {
      uri = `${uri}/${reportId}`;
    }
    return this.http.get<any>(uri, { params });
  }

  public getProjectDetailsByProjectId(data: any): Observable<IProject> {
    return this.http.post<any>(`${environment.apiUrl}${PROJECT_DETAILS_BY_PROJECT_ID}`, data);
  }

  public submitProjectCancellationRequest(payload): Observable<any> {
    return this.http.post(`${environment.apiUrl}${PROJECT_SUBMIT_CANCELLATION}`, payload);
  }

  public getCancellationDetails(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}${PROJECT_GET_CANCELLATION_DETAILS}/${id}`);
  }

  public saveCancellation(options): Observable<any> {
    return this.http.post(`${environment.apiUrl}${PROJECT_SAVE_CANCELLATION}`, options);
  }

  public reviewCancellationAction(action, payload = {}): Observable<any> {
    const options = { action, ...payload };
    return this.http.post(`${environment.apiUrl}${PROJECT_REVIEW_CANCELLATION}`, options);
  }

  public review2CancellationAction(action, payload = {}): Observable<any> {
    const options = { action, ...payload };
    return this.http.post(`${environment.apiUrl}${PROJECT_REVIEW_2_CANCELLATION}`, options);
  }

  public getCancellationHistory(id): Observable<any> {
    return this.http.post(`${environment.apiUrl}${PROJECT_SEARCH_CANCEL_HISTORY}`, { projectId: id });
  }

  public getCancellationNotes(id, dir = 'DESC'): Observable<any> {
    let params = new HttpParams();
    params = params.append('dir', dir);

    return this.http.post(
      `${environment.apiUrl}${NOTES_SEARCH}`,
      { entityId: id, entityType: 'PROJECT_CANCELLATION' },
      { params });
  }

  public getNewCancellationRequestId() {
    return this.http.get(`${environment.apiUrl}${GET_NEW_CANCELLATION_REQUEST_ID}`);
  }

  public getNewTransferRequestId() {
    return this.http.get(`${environment.apiUrl}${GET_NEW_TRANSFER_REQUEST_ID}`);
  }

  public valTransAggReq(id) {
    return this.http.get(`${environment.apiUrl}${VALIDATE_TRANSFER_AGG_REQ}${id}`);
  }

  public getByPendingProjectTransferRequestId(id) {
    return this.http.get<any>(`${environment.apiUrl}${GET_BY_PENDING_PROJECT_TRANSFER_REQUEST_ID}${id}`);
  }

  triggerActionController(metadata: any, back= false) {

    const value = metadata.action;
    const mapData = PROJECT_ACTIONS[value.workflowAction] || {};
    const payload = metadata.data;

    if (PROJECT_CANCEL_ACTIONS.includes(value.workflowAction)) {
      this.router.navigate(this.helperService.getTranslatedPath(`/program-cancellation/${payload.id}`));
    } else if (value.workflowAction === 'REVIEW_CANCELLATION_REQUEST' || value.workflowAction === 'VIEW_CANCELLATION_REQUEST') {
      this.router.navigate(this.helperService.getTranslatedPath(`/program-cancellation/${payload.id}/review/${payload.cancellationRequestId}`));
    } else if (value.workflowAction === 'SUBMIT_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT' ||
               value.workflowAction === 'REVIEW_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT') {
      this.submitProjectAdditions(payload, mapData);
    } else if (value.workflowAction === 'CREATE_NEW_SUBMISSION') {
      this.createNewSubmission(metadata.data, this.currentTabUrl);

    } else if (value.workflowAction === 'VIEW_HISTORY') {

      this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/history/${payload.id}` )  );
    } else if (SECONDARY_ACTIONS.includes(value.workflowAction)) {
      if (payload.type === 'SUB') {
        this.redirectToSubProject(payload.masterProjectId, payload.id);
      } else {
        this.redirectToProjectScreen(null, payload.id, payload);
      }
    } else if (mapData.uiUri) {
      let actionRoute = mapData.uiUri;
      mapData.params.forEach(param => {
        actionRoute = replaceParam(actionRoute, param, payload[param]);
      });
      this.router.navigate(this.helperService.getTranslatedPath(actionRoute),
      );
    } else if (PROJECT_TRANSFER_ACTIONS.includes(value.workflowAction)) {

      if (value.workflowAction === 'TRANSFER_AGGREGATED_PROJECT') {

        this.valTransAggReq(payload.id).subscribe(response => {
          if (response) {

            this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/${value.workflowAction}`), { state: { data: payload, formType: value.workflowAction } });
          }
        }, error => {

        });
      } else {
        this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/${value.workflowAction}`), { state: { data: payload, formType: value.workflowAction } });
      }
    } else if (value.workflowAction === 'VIEW_PREVIOUS_PROJECT_TRANSFER_REQUEST') {
      this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request-list/${payload.id}`), { state: { data: payload, formType: value.workflowAction } });
    }  else if (value.workflowAction === 'VIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {
      const type = payload.projectTransferStatus === 'RECEIVED_CONSENT' ? 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST' : 'VIEW_PROJECT_TRANSFER_INIT';

      let apiCallForAction = false;
      if (type === 'VIEW_PROJECT_TRANSFER_INIT') {
         apiCallForAction = true;
      }

      this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/${type}`), { state: { data: payload, formType: '', extraActions: apiCallForAction  } });
    } else if (value.workflowAction === 'REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST') {
      this.router.navigate(this.helperService.getTranslatedPath(`/project-transfer-request/${payload.id}/REVIEW_CREATE_ACCOUNT_FOR_PROJECT_TRANSFER_REQUEST`), { state: { data: payload, formType: '' } });
    } else if (value.workflowAction === 'DISCARD_PROJECT_TRANSFER') {
      this.discard(payload, back, mapData);
    } else if (value.workflowAction === 'VIEW_PREVIOUS_CANCELLATION_REQUEST') {
      this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/project-cancellation-history/${payload.id}`), { state: { data: payload, formType: value.workflowAction } });
    } else if (['ADMIN_APPROVE_CONSENT', 'ADMIN_REJECT_CONSENT', 'ADMIN_APPROVE_CONSENT_SECOND', 'ADMIN_REJECT_CONSENT_SECOND'].includes(value.workflowAction)) {
      this.store.resetHistoryUrl();
      if (payload.status === 'TRANSFERRED') {
        this.viewTransferedProject(payload.type, payload.id);
      } else {
        this.redirectToProjectScreen(null, payload.id, payload);
      }
    } else if (value.workflowAction === 'NOTICE_OF_INTENTION_TO_CANCEL_A_PROJECT_REGISTRATION') {
      this.router.navigate(this.helperService.getTranslatedPath(`/project-notification/${notificationType.notificationOfCancelProjectRegistration}/${payload.accountId}/${payload.id}`), { state: { data: payload, formType: value.workflowAction } });
    } else if (value.workflowAction === 'REVIEW_NOTICE_OF_INTENT_TO_CANCEL_A_PROJECT_REGISTRATION' || 
      value.workflowAction === 'VIEW_NOTICE_OF_INTENT_TO_CANCEL_A_PROJECT_REGISTRATION'
    ) {
      // project-notification/:notificationType/:accountId/:projectId/:entityId
      this.router.navigate(this.helperService.getTranslatedPath(`/project-notification/${notificationType.notificationOfCancelProjectRegistration}/${payload.accountId}/${payload.id}/${payload.notIntCancelProjRegEntityId}`), { state: { data: payload, formType: value.workflowAction } });
    } else {
      payload.action = value.workflowAction;
      this.triggerBackendAction(payload, back, mapData);
    }
  }

  triggerBackendAction(payload, back , mapData = null) {
    const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
    const actionLabel =  mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
    console.log(payload.action);
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: confirmationMessage,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
       // payload.name,
        payload.accountName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {

        this.modalService.closeAllMessageDialogs();

        this.projectWorkflowAction(payload, mapData.uri).pipe(
          takeUntil(this._destroy$),
        ).subscribe(response => {
          this._onProjectUpdate$.next(true);
          if (back) {
           // const rcaSign = payload.action  === 'SIGN_AGREEMENT';
          //  if (rcaSign) {
          //    this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/generate/${payload.id}/rca`));
          //  } else {
         //  this._location.back();
          //  }
          } else {
            this._refreshData$.next(true);
          }
        });
      }
     });
  }

  public discardApi(data: any): Observable<IProject> {
    return this.http.post<any>(`${environment.apiUrl}${DISCARD}`, data);
  }

  discard(payload, back , mapData = null) {
    let request = {id: payload.pendingTransferRequestId};
    const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
    const actionLabel =  mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
    console.log(payload.action);
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: confirmationMessage,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
       // payload.name,
        payload.accountName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {

        this.modalService.closeAllMessageDialogs();

        this.discardApi(request).pipe(
          takeUntil(this._destroy$),
        ).subscribe(response => {
          if (back) {
           // const rcaSign = payload.action  === 'SIGN_AGREEMENT';
          //  if (rcaSign) {
          //    this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/generate/${payload.id}/rca`));
          //  } else {
         //  this._location.back();
          //  }
          } else {
            this._refreshData$.next(true);
          }
        });
      }
     });
  }

  submitProjectAdditions(payload, mapData = null) {
    const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
    const actionLabel =  mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: confirmationMessage,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
        payload.accountName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.modalService.closeAllMessageDialogs();
        const id = payload[mapData.id];
        this.http.get<any>(`${ environment.apiUrl }${ SUBMIT_PROJECT_ADDITIONS }/${ id }`)
          .pipe(takeUntil(this._destroy$))
          .subscribe(response => {
            this.router.navigate(this.helperService.getTranslatedPath(`/project-builder/sub/${ response.id }`), { state: { masterProject: response } });
        });
      }
     });
  }

  projectWorkflowAction(payload, uri) {
    return this.http.post<any>(`${ environment.apiUrl }${OFFSET_PROJECT}${uri}`, payload);
  }

  uploadDocument(formData) {
    return this.http.post<any>(`${ environment.apiUrl }${UPLOAD_KML}`, formData);
  }

  deleteKmlDocument(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${DELETE_KML}`, payload);
  }

  uploadSubProjects(formData) {
    return this.http.post<any>(`${ environment.apiUrl }${UPLOAD_SUB_PROJECTS}`, formData);
  }

  downloadKML(projectId) {
    return this.http.get(`${ environment.apiUrl }${DOWNLOAD_KML}${projectId}`, {responseType: 'blob' as 'json', observe: 'response'});
  }

  getKmlInfo(projectId) {
    return this.http.get(`${ environment.apiUrl }${GET_KML_INFO}${projectId}`);
  }

  private configureProjectDates(payload) {
    if (payload.activity.municipality) {
      payload.municipality = payload.activity.municipality;
    }
    payload.activity.activityStartDate && (payload.activity.activityStartDate = moment(payload.activity.activityStartDate).format(DATE_FORMAT).toString());
    payload.activity.creditingPeriodStartDate && (payload.activity.creditingPeriodStartDate = moment(payload.activity.creditingPeriodStartDate).format(DATE_FORMAT).toString());
    payload.activity.creditingPeriodEndDate && (payload.activity.creditingPeriodEndDate = moment(payload.activity.creditingPeriodEndDate).format(DATE_FORMAT).toString());
    if (payload.subProjects && payload.subProjects.length) {
      payload.subProjects.forEach(subProject => {
        subProject.activity.activityStartDate && (subProject.activity.activityStartDate = moment(subProject.activity.activityStartDate).format(DATE_FORMAT).toString());
        subProject.activity.creditingPeriodStartDate && (subProject.activity.creditingPeriodStartDate = moment(subProject.activity.creditingPeriodStartDate).format(DATE_FORMAT).toString());
        subProject.activity.creditingPeriodEndDate && (subProject.activity.creditingPeriodEndDate = moment(subProject.activity.creditingPeriodEndDate).format(DATE_FORMAT).toString());
      });
    }
  }

  getProjectReports$(payload: IProjectReportSubmissionPayload): Observable<IProjectReportSubmission[]> {
    const url = `${environment.apiUrl}${PROJECT_SUBMISSIONS}`;
    return this.http.post<IProjectReportSubmission[]>(url, payload);
  }

  viewTransferedProject(projectType, projectId) {
    let params = new HttpParams();
    params = params.append('size', '10');
    const sub$ = this.http.post(`${ environment.apiUrl }${OFFSET_PROJECT}projectHistory?pageSearch`, {id: projectId}, {params} )
    .subscribe((data:any) => {
      sub$.unsubscribe();
      const project = { ...data.content[0], historic: true };
      if (projectType === 'SUB') {
        this.redirectToSubProject(project.masterProjectId, project.id, project);
      } else {
        this.redirectToProjectScreen(null, project.id, project);
      }
    })
  }

  filterActiveActions(project: IProjectApplicationContent | IProject, actionListProp: string = 'entityActionList'): IProjectApplicationContent | IProject {
    const accountStatus = (project as IProjectApplicationContent).accountStatus || (project as IProject).account.status;
    const accountActive = PROJECT_ACCOUNT_ACTIVE_STATUSES.includes(accountStatus);
    let actions = project[actionListProp];
    const isAdmin = this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS);
    if (!accountActive) {
      if(!isAdmin) {
        actions = actions.filter(entity => PROJECT_ACCOUNT_INACTIVE_ACTIONS.includes(entity.workflowAction));
      } else {
        actions = actions.filter(action => ![...PROJECT_TRANSFER_ACTIONS, ...PROJECT_CANCEL_ACTIONS].includes(action.workflowAction.toString()));
      }
    }
    return {...project, [actionListProp]: actions};
  }

  public searchProject(projectId, accountId) {
    return this.http.get<any>(`${environment.apiUrl}${GET_PROJECT_BY_ACCOUNT_PROJECT}${projectId}/${accountId}`);
  }
}
