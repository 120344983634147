import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UnitManagementService } from '@module/unit-management/services/unit-management.service';
import { IApp } from '@core/models/app.interfaces';
import { UnitFilterModel } from '@core/models/filter.model';
import { unitManagementTableConfig } from '@module/unit-management/unit-management.const';
import { ModalService } from '@shared/services/modal.service';
import { UnitDetailsComponent } from '@module/project-report/components/unit-details/unit-details.component';
import { filterUniqueById } from '@module/project-report/routines/common.routine';
import { AUTO_WIDTH_FOR_COLUMNS } from '@shared/models/table-view.model';
import { LookupService } from '@core/services/lookup.service';
import { ENVIRONMENT_INTEGRITY_ACCOUNT } from '@module/environmental-integrity-account/components/environmental-integrity-account/constant/environmental-integrity-account.const';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select-units',
  templateUrl: './select-units.component.html',
  styleUrls: ['./select-units.component.scss']
})
export class SelectUnitsComponent implements OnInit {
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  @ViewChild('enterRemittanceQuantity', { static: true }) public enterRemittanceQuantity: TemplateRef<any>;

  tableConfig = unitManagementTableConfig;
  units: IApp.IUnitPage;
  selectedUnits: IApp.IUnitData[] = [];
  updatedUnits: IApp.IUnitData[] = [];
  selectedUnitIds: number[] = [];
  visible = true;
  disabled = true;

  private pagination = {
    page: 0,
    size: 20,
  } as IApp.IPagination;
  public firstItemIndex = 0;
  public filterState: UnitFilterModel = {
    facilityNameList: [this.inputData.accountId],
    unitStatusList: this.inputData.unitStatusList,
    unitIdNotIn: this.inputData.unitIdNotIn,
    projectIdList: this.inputData.projectIdList,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<SelectUnitsComponent>,
    private modalService: ModalService,
    private unitManagementService: UnitManagementService,
    private lookupService: LookupService,) {

  }

  ngOnInit() {
    this._tableConstructor();
    this._loadData(this.pagination, this.filterState);
  }

  private _loadData(pagination: IApp.IPagination, filterState: UnitFilterModel = {}) {
    const isEIA: boolean = this.inputData['isEIA'] ? this.inputData['isEIA'] : false;
    this.inputData['unitStatusList'] && (this.filterState['unitStatusList'] = this.inputData['unitStatusList']);

    if (this.inputData['projectIdList']) {
      this.filterState['projectIdList'] = this.inputData['projectIdList'];
    }

    if (this.inputData['transitionTypeList']) {
      this.filterState['transitionTypeList'] = this.inputData['transitionTypeList'];
    }

    if (isEIA) {
      delete filterState['unitIdNotIn'];
      delete filterState['projectIdList'];
      delete filterState['facilityNameList'];
      delete filterState['transitionTypeList'];
    }

    if (this.inputData['isGhg']) {
      delete filterState['unitIdNotIn'];
    }

    const uri = isEIA ? ENVIRONMENT_INTEGRITY_ACCOUNT.tableListApiUri : '';

    this.unitManagementService
      .getAll(pagination, filterState, uri)
      .subscribe(data => {
        this.units = data;
        this.disabled = !this.haveSelectedUnits();
      });
  }

  haveSelectedUnits() {
    const selectedUnitWithQty = this.inputData['selectedUnitWithQty'] || [];
    const availableUnits = this.units.content;
    let selectedUnitsAvailable: boolean = false; // will be used to enable or disable save button
    selectedUnitWithQty.forEach(selectedUnit =>
      availableUnits.forEach(availableUnit => {
        if (availableUnit.id === selectedUnit.unitId) {
          this.onRemiQtyChange(selectedUnit.qty, availableUnit); // updating the updatedUnits array for already selected units
          selectedUnitsAvailable = true;
        }
      })
    );

    return selectedUnitsAvailable;
  }

  private _tableConstructor() {
    this.lookupService.getTableConfigurations('UNIT').subscribe(
      data => {
        const { columns, ...config } = data;

        const _columns = [
          {
            checkboxSelect: true,
            width: '3em',
          },
          ...data.columns,
          {
            header: 'enterRemiQty',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.enterRemittanceQuantity,
          },
          {
            header: 'details',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          },
        ];

        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };
      });
  }

  getSelectedUnitQty(data) {
    const selectedUnitWithQty = this.inputData['selectedUnitWithQty'] || [];
    const unit = selectedUnitWithQty.find(unit => unit.unitId === data.id);
    return unit ? unit.qty : null;
  }

  onRemiQtyChange(value, data, rowIndex?) {
    setTimeout(() => {
      const idx = this.updatedUnits.findIndex(item => item.id === data.id);
      if (idx === -1) {
        const unit = { ...data };
        unit['quantity'] = parseInt(value, 10);
        unit.account = null;
        unit.project = null;
        unit.projectType = null;
        this.updatedUnits.push(unit);
      } else {
        if (!value) {
          this.updatedUnits.splice(idx, 1);
        } else {
          this.updatedUnits[idx]['quantity'] = parseInt(value, 10);
        }
      }

      this.inputData.selections = [...this.updatedUnits];
      this.disabled = false;

      if (this.updatedUnits) { // disabling the save button if none units were selected
        this.updatedUnits = this.updatedUnits.filter(unit => !isNaN(unit.quantity));
        this.disabled = (this.updatedUnits.length === 0) ? true : false;
      }
    })
  }

  viewUnitDetails(unit: IApp.IUnitData) {
    this.modalService
      .open(UnitDetailsComponent, {
        title: 'UNITS_MODULE.creditTransferForm.unitsLabel',
        unit,
      });
  }

  private openModal(message: string, metaData: string[] = [], type = SereviceMessageType.ERROR): Observable<any> {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message,
              metaData,
            },
          ],
          type,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  onRowSelect(data: IApp.IUnitData[]) {
    this.selectedUnits = data;
    this.disabled = false;
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    if ((this.pagination.page === pagination.page) && !pagination.sort && !pagination.dir) return; // added to avoid duplicate calls when a popup opens
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this._loadData(this.pagination, this.filterState);
  }

  areSelectedUnitsQtyUnchanged() { // will return true if selectedUnitQty is unchanged
    let selectedUnitWithQty = this.inputData['selectedUnitWithQty'] || [];
    if (selectedUnitWithQty.length === this.updatedUnits.length) {
      const areSelectedUnitsQtyUnchanged = this.updatedUnits.every(updatedUnit =>
        selectedUnitWithQty.some(selectedUnit =>
          updatedUnit.id === selectedUnit.unitId && updatedUnit.quantity === selectedUnit.qty
        )
      );
      return areSelectedUnitsQtyUnchanged;
    }
    return false;
  }

  save() {
    this.selectedUnitIds = filterUniqueById(this.selectedUnits, this.updatedUnits)
      .map(data => data.id);

    if (!this.updatedUnits || !this.updatedUnits.length) {
      this.close();
      return;
    }

    if (this.areSelectedUnitsQtyUnchanged()) { // there is not change in selected units
      this.close();
      return;
    }

    let exceedingMaximumQtyErrorFlag = false;
    let userEnteredNegativeValue = false;
    this.updatedUnits.forEach(updatedUnit => {
      const unit = this.units.content.find(u => u.id === updatedUnit.id);
      if (unit && (updatedUnit.quantity > unit.quantity)) {
        exceedingMaximumQtyErrorFlag = true;
      }

      if(unit && (updatedUnit.quantity < 0)){
        userEnteredNegativeValue = true;
      }
    });

    if (userEnteredNegativeValue) {
      const sub$ = this.openModal('valueCannotBeLessThanZero').subscribe(() => sub$.unsubscribe());
      return;
    }


    if (exceedingMaximumQtyErrorFlag) {
      const sub$ = this.openModal('quantityExceedsMaxErrorMessage').subscribe(() => sub$.unsubscribe());
      return;
    }

    if (this.inputData['isGhg']) {
      this.updatedUnits = this.updatedUnits.map((unit) => {
        // not required in payload
        delete unit.creditCreationReportDetailDto;
        delete unit.creditActionDto;
        delete unit.project;
        delete unit['submission'];
        return unit;
      });

      this.unitManagementService.updateUnitData({ creditBlockList: this.updatedUnits })
        .subscribe((result: any) => {
          this.visible = false;
          let alteredResult = result.map((unit) => {
            (unit.creditCreationReportDetailDto) && delete unit.creditCreationReportDetailDto;
            (unit.submission) && delete unit.submission;
            return unit
          });
          this.dialogRef.close(alteredResult);
        }, () => this.close());
    } else {
      this.unitManagementService.updateUnitData({ creditBlockList: this.updatedUnits })
        .subscribe((result: any) => {
          this.visible = false;
          this.dialogRef.close(result);
        }, () => this.close());
    }
  }

  close() {
    this.visible = false;
    this.dialogRef.close();
  }
}
