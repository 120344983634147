/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-discussion-chat-controls.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./project-discussion-chat-controls.component";
import * as i6 from "../../../../../../core/store/store.service";
import * as i7 from "../../../../../services/modal.service";
import * as i8 from "../../../service/project-discussion.service";
var styles_ProjectDiscussionChatControlsComponent = [i0.styles];
var RenderType_ProjectDiscussionChatControlsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectDiscussionChatControlsComponent, data: {} });
export { RenderType_ProjectDiscussionChatControlsComponent as RenderType_ProjectDiscussionChatControlsComponent };
function View_ProjectDiscussionChatControlsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onReply($event, _co.entry) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PROJECTS_MODULE.DISCUSSION.discussionReply")); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectDiscussionChatControlsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "ml-3"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUpdate($event, _co.entry) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PROJECTS_MODULE.DISCUSSION.discussionUpdate")); _ck(_v, 1, 0, currVal_0); }); }
export function View_ProjectDiscussionChatControlsComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wrapper d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDiscussionChatControlsComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDiscussionChatControlsComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.updateOnly; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.canUpdate(_co.entry); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProjectDiscussionChatControlsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-discussion-chat-controls", [], null, null, null, View_ProjectDiscussionChatControlsComponent_0, RenderType_ProjectDiscussionChatControlsComponent)), i1.ɵdid(1, 114688, null, 0, i5.ProjectDiscussionChatControlsComponent, [i6.StoreService, i7.ModalService, i8.ProjectDiscussionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectDiscussionChatControlsComponentNgFactory = i1.ɵccf("app-project-discussion-chat-controls", i5.ProjectDiscussionChatControlsComponent, View_ProjectDiscussionChatControlsComponent_Host_0, { entry: "entry", project: "project", updateOnly: "updateOnly" }, {}, []);
export { ProjectDiscussionChatControlsComponentNgFactory as ProjectDiscussionChatControlsComponentNgFactory };
