import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { CREDIT_REVENUE_REPORT_DETAILS_PREFIX } from '../credit-revenue-report.const';
import { BehaviorSubject } from 'rxjs';
import { CreditRevenueReportService } from '../credit-revenue-report.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-revenue-details',
  templateUrl: './add-revenue-details.component.html',
  styleUrls: ['./add-revenue-details.component.scss']
})
export class AddRevenueDetailsComponent implements OnInit {

  public model: any = {};
  public fields: FormlyFieldConfig[];
  public form = new FormGroup({});
  public activities$ = new BehaviorSubject([]);
  public visible = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddRevenueDetailsComponent>,
    private formFactoryService: FormFactoryService,
    private service: CreditRevenueReportService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.model = this.data.model,
    this.getActivities();
    this.setFields();
  }

  public save() {
    this.formFactoryService.markFormAsDirty(this.form);
    if (!this.form.valid) {
      return;
    }
    const activity = this.activities$.getValue().find(activity => activity.id === this.model.activityId);
    this.model = {
      ...this.model,
      activity,
      activityCode: activity.code,
      activityName: activity.name,
    };
    this.formFactoryService.markFormAsDirty(this.form);
    this.dialogRef.close(this.model);
  }

  public close(): void {
    this.dialogRef.close(false);
  }

  setFields() {
    this.fields = this.formFactoryService.configureForm([{
      groupFields: false,
      fieldGroup: [
        {
          type: 'input',
          className: 'col-6',
          key: 'amountOfRevUsedByRegisteredCreator',
          label: this.service.getTranslatedLabel(CREDIT_REVENUE_REPORT_DETAILS_PREFIX, 'amountOfRevUsedByRegisteredCreator', this.service.currentData.compliancePeriod),
          id: 'amountOfRevUsedByRegisteredCreator',
          required: true,
          validators: ['positiveOrZeroNumber'],
          customTemplateOptions: {
            type: 'formatted-number',
            mask: 'separator.2',
            allowNegativeNumbers: false,
            onChange: ()=> this.validateRevenueAmount()
          },
          icon: {
            text: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.amountOfRevTooltip`,
          }
        },
        {
          type: 'dict-select',
          key: 'activityId',
          className: 'col-6',
          source: this.activities$,
          label: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.activity`,
          ignoreTranslateOptions: true,
          placeholder: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.activityPlaceholder`,
          required: true,
          id: `activity`,
          appendTo: 'self',
          icon: {
            text: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.activityTooltip`,
          }
        },
        {
          className: 'col-12',
          type: 'textarea',
          key: 'activityDescription',
          label: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.activityDescription`,
          disabled: false,
          required: true,
          id: 'activityDescription',
          validators: ['validateEmptyString'],
          icon: {
            text: `${CREDIT_REVENUE_REPORT_DETAILS_PREFIX}.activityDescriptionTooltip`,
          }
        },
      ]
    }]);

    if (this.data.disabled) {
      setTimeout(() => {
        this.form.disable();
      })
    }
  }


  private getActivities() {
    this.service.getCRRActivities().subscribe(result => {
      this.activities$.next(result);
    })
  }

  private validateRevenueAmount() {
    const control = this.form.get('amountOfRevUsedByRegisteredCreator');
    if (control && control.value && +control.value > this.data.currentYearRemRevenue) {
      setTimeout(() => {
        control.markAsTouched();
        control.markAsDirty();
        control.setErrors({ direct:  `${this.translateService.instant('COMMON.messageSection.valueCannotbeGreaterThanAvailableQuantity')}`});

      })
    }

  }

}
