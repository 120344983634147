<p-dialog
  [(visible)]="visible"
  [closable]="false"
  [header]="data.mode | translate"
  [minY]="100"
  [style]="{width: '600px'}"
  className="eccc"
>
  <div class="row">
    <div class="col-12 mb-1">
      {{'PROJECTS_MODULE.DISCUSSION.discussionComment' | translate}}
    </div>
    <div class="col-12">
      <textarea class="form-control rounded-0" rows="10" [(ngModel)]="comment"></textarea>
    </div>
  </div>

  <p-footer>
    <div class="d-flex justify-content-end">
      <button
        (click)="close()"
        class="btn btn-secondary"
        type="button"
      >
        {{'PROJECTS_MODULE.DISCUSSION.discussionCommentCancel' | translate}}
      </button>
      <button
        [disabled]="!comment.length"
        (click)="submit()"
        class="btn btn-primary"
        type="button"
      >
        {{'PROJECTS_MODULE.DISCUSSION.discussionCommentSubmit' | translate}}
      </button>
    </div>
  </p-footer>
</p-dialog>