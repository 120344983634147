<div class="card">
  <div class="card-body">
    <h1>{{ title | translate }}</h1>
    <hr />

    <section class="alert alert-danger alert-danger-banner text-center"
             *ngIf="authErrors.length > 0">
        <span *ngFor="let errItem of authErrors" class="alert-link">{{ errItem }}</span>
    </section>

    <form #forgotPasswordForm="ngForm"
          autocomplete="off"
          (ngSubmit)="forgotPasswordForm.form.valid && sendRequest(forgotPasswordForm)"
          novalidate>

      <ng-container *ngIf="status && status.deliveryMedium; else FIRST_STEP">
        <div class="form-group">
          <label for="resetNewPassword">{{ 'LOGIN_PAGE.forgotPasswordSection.newPassword'| translate }}</label>
          <input type="password" [(ngModel)]="resetPasswordPayload.newPassword" class="form-control"
            name="newPassword" id="resetNewPassword" #newPassword="ngModel" passwordComplexityValidator required
            [ngClass]="{ 'is-invalid': forgotPasswordForm.submitted && newPassword.invalid }">

          <small *ngIf="forgotPasswordForm.submitted && newPassword.invalid"
            id="reset-password-error-newpassword-required" class="invalid-feedback">
            <span *ngIf="newPassword.errors.required; else COMPLEXITY">{{ 'LOGIN_PAGE.forgotPasswordSection.provideValidPassword'| translate }}</span>
            <ng-template #COMPLEXITY>
              <span *ngIf="newPassword.errors.passwordComplexityValidator">{{ 'COMMON.messageSection.PasswordTooSimple'| translate }}</span>
            </ng-template>
          </small>

        </div>
        <div class="form-group">
          <label for="resetConfirmPassword">{{ 'LOGIN_PAGE.loginSection.confirmPassword'| translate  }}</label>
          <input type="password"
                 [(ngModel)]="resetPasswordPayload.password"
                 class="form-control"
                 #password="ngModel"
                 name="password"
                 [ngClass]="{ 'is-invalid': forgotPasswordForm.submitted && password.invalid }"
                 id="resetConfirmPassword"
                 passwordComplexityValidator
                 required>

          <small *ngIf="password.invalid"
                   id="login-error-new-password-required"
                   class="invalid-feedback">
            <span *ngIf="password.errors.required; else COMPLEXITY">{{ 'LOGIN_PAGE.loginSection.providePasswordError'| translate }}</span>
            <ng-template #COMPLEXITY>
              <span *ngIf="password.errors.passwordComplexityValidator">{{ 'COMMON.messageSection.PasswordTooSimple'| translate }}</span>
            </ng-template>
          </small>

        </div>
        <h2>  {{ 'LOGIN_PAGE.forgotPasswordSection.confirmationCode'| translate }}</h2>
        <p> {{ codeSentMessage }}</p>
        <div class="form-group">
          <app-confirmation-code
            [(ngModel)]="resetPasswordPayload.confirmationCode"
            name="confirmationCode"
            #confirmationCode="ngModel"
            [isInvalid]="forgotPasswordForm.submitted && confirmationCode.invalid">
          </app-confirmation-code>

          <small *ngIf="forgotPasswordForm.submitted && confirmationCode.invalid"
                 style="display: block"
                   id="reset-password-error-confirmationCode-required"
                   class="invalid-feedback">
              {{ 'LOGIN_PAGE.forgotPasswordSection.provideConfirmationCode'| translate }}
          </small>
          <button type="submit"
                  id="loginSignInBtn"
                  class="btn btn-primary">
             {{ 'LOGIN_PAGE.forgotPasswordSection.submit'| translate }}
          </button>

        </div>
      </ng-container>
      <ng-template #FIRST_STEP>
        <p *ngIf="standalone">To change the current password please reset your password and follow the instructions.</p>
        <div class="form-group">
          <ng-container *ngIf="!standalone">
            <label for="resetUsername">{{ 'LOGIN_PAGE.forgotPasswordSection.username'| translate }}</label>
            <input type="text"
                   [(ngModel)]="resetPasswordPayload.username"
                   class="form-control"
                   name="username"
                   id="resetUsername"
                   #username="ngModel"
                   [ngClass]="{ 'is-invalid': forgotPasswordForm.submitted && username.invalid }"
                   required>
            <small *ngIf="forgotPasswordForm.submitted && username.invalid"
                   id="reset-password-error-username-required"
                   class="invalid-feedback">
              {{ 'LOGIN_PAGE.forgotPasswordSection.provideUsernameError'| translate }}
            </small>
          </ng-container>
          <br/>

          <button type="submit"
                  id="loginSignInBtn"
                  class="btn btn-primary">
             {{ 'LOGIN_PAGE.forgotPasswordSection.requestPasswordReset'| translate }}
          </button>

        </div>
      </ng-template>



      <div class="form-group" *ngIf="!standalone">
        <hr/>
        <a href= "" [appRouterLink]="['welcome' | localize]"> {{ 'LOGIN_PAGE.forgotPasswordSection.backToLogin'| translate }}</a>
      </div>
    </form>
  </div>
</div>

