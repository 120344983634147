import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BackbtnserviceService } from '@shared/components/back-btn/backbtnservice.service';
import { HelperService } from '@shared/services/helper.service';
import { TableTemplateControls } from '@shared/services/table-template-controls.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const PROJECT_TRANSFER_LIST = {
  tableListApiUri: '/offset-service/projectTransferRequest/pageSearchByFilter',
  tableHeaderType: 'PROJECT_TRANSFER_REQUEST',
  tableFilter: null,
  hasViewTemple: true,
  hasActionTemple: false,
  hasEditActionTemple: false,
  projectTypeManagement:true
}

@Component({
  selector: 'app-project-transfer-list',
  templateUrl: './project-transfer-list.component.html',
  styleUrls: ['./project-transfer-list.component.scss']
})
export class ProjectTransferListComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();
  tableDetails = PROJECT_TRANSFER_LIST;

  actionFields: FormlyFieldConfig[];
  actionBtnForm = new FormGroup({});

  actionBtnMockup3 = []

  projectId:number;
  filter: any;

  constructor(private translateService: TranslateService,
    private backbtnserviceService: BackbtnserviceService,
    private tableTemplateControls: TableTemplateControls,
    private router: Router,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.actionFields = this.setBackButton();

    this.activatedRoute.params.subscribe(params => {
      if(params.id) {
        this.projectId = params.id;
        this.filter = {"projectId": this.projectId}
      }
    });

    this.tableTemplateControls._action.subscribe(metadata => {
      if (metadata.action.workflowAction === 'VIEW') {
        this.router.navigate(
          this.helperService.getTranslatedPath(`/project-transfer-request/${metadata.data.id}/VIEW_PROJECT_TRANSFER_REQUEST_PREV`),
          { state: { data: metadata.data, formType: 'VIEW_PROJECT_TRANSFER_REQUEST_PREV' } });
      }
    });

    this.translateService.onLangChange
    .pipe(takeUntil(this._destroy$))
    .subscribe(() => {
      this.actionFields = this.setBackButton();
    })
  }

  setBackButton() {
    return [
      {
        fieldGroupClassName: "mt-4 mr-1 row justify-content-end",
        fieldGroup: [
          {
            className: "col",
            type: "button",
            wrappers: ["form-field"],
            templateOptions: {
              text: this.translateService.instant("PROJECTS_MODULE.transferrequest.field_44"),
              className: "btn btn-secondary",
              onClick: ($event) => {
                this.backbtnserviceService.backPreviousPage();
              }
            }
          },
        ]
      },
    ]
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
