/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dummy.component";
var styles_FormlyFieldDummy = [];
var RenderType_FormlyFieldDummy = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldDummy, data: {} });
export { RenderType_FormlyFieldDummy as RenderType_FormlyFieldDummy };
export function View_FormlyFieldDummy_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\u00A0"]))], null, null); }
export function View_FormlyFieldDummy_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-dummy", [], null, null, null, View_FormlyFieldDummy_0, RenderType_FormlyFieldDummy)), i0.ɵdid(1, 49152, null, 0, i1.FormlyFieldDummy, [], null, null)], null, null); }
var FormlyFieldDummyNgFactory = i0.ɵccf("formly-dummy", i1.FormlyFieldDummy, View_FormlyFieldDummy_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldDummyNgFactory as FormlyFieldDummyNgFactory };
