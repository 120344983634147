<div id="table-filter">

    <p-fieldset legend="{{ 'COMMON.filters.filtersAndTools' | translate}}" [toggleable]="true">

        <div class="container"
             [class.wide-mode]="wideMode">

            <div class="row">

                <div class="col-12">

                    <div class="row">

                      <ng-container *ngFor="let field of sortedFieldsList">

                      <div *ngIf="field.fieldType === 'dummy'"
                        [class.col-4]="true"
                        [class.pl-1]="true"
                        class="mb-3"
                      >
                      </div>
                        <ng-container *ngIf="field.fieldType === 'customButtons' ">
                          <div
                            *ngFor="let button of field.data; let i = index"
                            [class.col-3]="apply3cols(button)"
                            [class.col-4]="apply4cols(button)"
                            [class.col-5]="apply5cols(button)"
                            [class.col-ignore-layout]="button.ignoreLayout"
                            [class.ml-3]="button.ignoreLayout"
                            [class.pl-1]="i === 0"
                            class="mb-3"
                          >
                            <button
                              (click)="customButton(button.label)"
                              class="btn btn-secondary"
                              [class.break-word]="!button.ignoreLayout"
                              id="custom-btn"
                              type="button"
                            >
                              {{'COMMON.filters.' + button.label  | translate}}
                            </button>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'filterListModel'">
                          <div class="mb-3"
                               [class.col-3]="!wideMode"
                               [class.col-2]="wideMode"
                               [class.grow-last]="field.last">
                            <ng-container *ngIf="field.data.itemsType !== 'facilityNames'; else FACILITY_NAMES_SELECTOR">
                              <ng-select [placeholder]=" field.data.placeholder | translate"
                                         wcag-label
                                         [items]="filterFields[field.data.itemsType]"
                                         [multiple]="field.data.multiple"
                                         [dropdownFullWidth]="field.data.dropdownFullWidth"
                                         [selectableGroup]="field.data.selectableGroup"
                                         [closeOnSelect]="field.data.closeOnSelect"
                                         [groupBy]="field.data.groupBy"
                                         [clearSearchOnAdd]="true"
                                         bindLabel="name" bindValue="id"
                                         [(ngModel)]="filterRequest[field.data.ngModel]"
                                         (ngModelChange)="selectionChange(field.data.ngModel)"
                                         [notFoundText]="'COMMON.languageList.notFoundText' | translate"
                                         [virtualScroll]="!field.data.dropdownFullWidth"
                                         [inputAttrs]="{
                                            'aria-label': field.data.placeholder | translate,
                                            'cy-id' : field.data.itemsType
                                            }"
                                          [subscript]="true"
                                         (change)="searchServiceManager()">

                                      <ng-template ng-optgroup-tmp let-item="item">
                                        <div class="ng-option-label" title="{{item?.name}}">{{item?.name}}</div>
                                      </ng-template>

                                      <ng-template ng-label-tmp let-item="item">
                                        {{ item?.name }}
                                      </ng-template>

                                      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                          <div class="ng-value" *ngFor="let item of items">
                                              <span class="ng-value-label" [innerHTML]="item?.name"></span>
                                              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                          </div>
                                      </ng-template>

                              </ng-select>
                            </ng-container>
                            <ng-template #FACILITY_NAMES_SELECTOR>
                                <ng-select [placeholder]=" field.data.placeholder | translate"
                                           wcag-label
                                           [items]="filterFields[field.data.itemsType]"
                                           [multiple]="field.data.multiple"
                                           [selectableGroup]="true"
                                           [closeOnSelect]="field.data.closeOnSelect"
                                           [clearSearchOnAdd]="true"
                                           bindLabel="name"
                                           bindValue="id"
                                           [(ngModel)]="filterRequest[field.data.ngModel]"
                                           [notFoundText]="'COMMON.languageList.notFoundText' | translate"
                                           [virtualScroll]="true"
                                           [inputAttrs]="{
                                              'aria-label': field.data.placeholder | translate,
                                              'cy-id' : field.data.itemsType
                                              }"
                                              [subscript]="true"
                                           (change)="updateFacilityList($event)">


                                  <ng-template ng-optgroup-tmp let-item="item">
                                    <div class="ng-option-label" title="{{item?.name}}">{{item?.name}}</div>
                                  </ng-template>

                                  <ng-template ng-label-tmp let-item="item">
                                    {{ item?.name }}
                                  </ng-template>

                                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                    <div class="ng-value" *ngFor="let item of items">
                                        <span class="ng-value-label" [innerHTML]="item?.name"></span>
                                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                    </div>
                                  </ng-template>

                                </ng-select>
                              </ng-template>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'dateRange'">
                          <div class="col-3"
                               [class.grow-last]="field.last">
                            <app-date-range [dateFrom]="filterRequest[dateRangeFields.startDate]"
                                            [dateTo]="filterRequest[dateRangeFields.endDate]"
                                            [placeholder]="field.options?.placeholder"
                                            (datesChanged)="onDateRangeChanged($event)">
                            </app-date-range>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'datePicker'">
                          <div class="col-3">
                            <app-datepicker
                              [value]="filterRequest[dateFieldConfig.dateField]"
                              (dateChanged)="onDateChange($event)">
                            </app-datepicker>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'searchBox'">
                          <div class="mb-3"
                               [class.col-xl-7]="!wideMode"
                               [class.col-lg-8]="!wideMode"
                               [class.col-md-10]="!wideMode"
                               [class.col-sm-12]="!wideMode"
                               [class.col-5]="wideMode"
                               [class.grow-last]="field.last">
                               <div class="row">
                                 <div class="col-7 float-left">
                                     <label for="search" class="wcag-visuallyhidden">{{'COMMON.filters.filterSearch' | translate}}</label>
                                     <input #searchTextBox
                                         [(ngModel)]="filterRequest.freeText"
                                         type="text"
                                         class="form-control mr-2 float-left"
                                         id="search"
                                         placeholder="{{'COMMON.filters.filterSearch' | translate}}"
                                         (keyup)="onSearchFieldKeyUp($event)">
                                 </div>
                                 <div class="col-5 float-left text-nowrap">
                                     <button id="query" type="button" class="btn btn-secondary mr-2"
                                         (click)="searchServiceManager()">{{'COMMON.filters.filterSearch' | translate}}</button>
                                     <button type="button" id="filter-clear-btn" class="btn btn-secondary mr-2"
                                         (click)="clearSearch()">{{'COMMON.filters.clear' | translate}}</button>
                                 </div>
                               </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'searchButtons'">
                          <div class="col-3 mb-3 p-0"
                               [class.grow-last]="field.last">
                            <div class="col-5 float-left text-nowrap">
                                <button id="query"
                                        type="button"
                                        class="btn btn-secondary mr-2"
                                        *ngIf="!field.options || field.options?.clearButtonOnly === false"
                                        (click)="searchServiceManager()">{{'COMMON.filters.filterSearch' | translate}}
                                </button>
                                <button type="button" id="filter-clear-btn" class="btn btn-secondary mr-2"
                                    (click)="clearSearch()">{{'COMMON.filters.clear' | translate}}</button>
                            </div>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'addNewRow'">
                          <div class="ml-3 pl-3 pr-3 mb-3"
                               *ngIf="user.hasPermission(fields.addNewRow.permission)"
                               [class.grow-last]="field.last">
                            <button class="btn btn-sm" id='addNewRow' style="background-color:transparent;"
                            (click)="addRow()">
                                <fa-icon [icon]="['far', 'plus-square']"></fa-icon>
                                {{ fields.addNewRow.label | translate }}
                            </button>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'openNewPageForNewRow'">
                          <div class="col-3 mb-3"
                               *ngIf="user.hasPermission(fields.openNewPageForNewRow.permission)"
                               [class.grow-last]="field.last">
                            <button class="btn btn-sm" id='openNewPageForNewRow' style="background-color:transparent;"
                            (click)="openNewPageForNewRow(fields.openNewPageForNewRow.uri)">
                                <fa-icon [icon]="['far', 'plus-square']"></fa-icon>
                                {{ fields.openNewPageForNewRow.label | translate }}
                            </button>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'actionModel'">
                          <div class="{{field.data.className || 'col-3'}} mb-3" >
                            <ng-select
                              wcag-label
                              [id]="getDropdownId(field.index, field.data)"
                              [placeholder]=" field.data.placeholder | translate"
                              [items]="field.data.options" [multiple]="false"
                              bindLabel="labelFormatted"
                              [clearable]="false"
                              [clearSearchOnAdd]="true"
                              [notFoundText]="'COMMON.custom_messages.noItems' | translate"
                              [(ngModel)]="actionSelectedDummyModel[field.data.id]"
                              [inputAttrs]="{'aria-label': field.data.placeholder | translate}"
                              [subscript]="true"
                              (change)="onChangeAction($event)">
                            </ng-select>
                          </div>
                        </ng-container>

                        <ng-container *ngIf="field.fieldType === 'adminActions'">
                          <div class="col-3 mb-3">
                            <ng-select
                              wcag-label
                              [id]="getDropdownId(field.index, field.data)"
                              [placeholder]=" field.data.placeholder | translate"
                              [items]="field.data.options" [multiple]="false"
                              bindLabel="labelFormatted"
                              [clearable]="false"
                              [clearSearchOnAdd]="true"
                              [notFoundText]="'COMMON.custom_messages.noItems' | translate"
                              [(ngModel)]="actionSelectedDummyModel[field.data.id]"
                              [inputAttrs]="{'aria-label': field.data.placeholder | translate}"
                              [subscript]="true"
                              (change)="onAdminActionSelected($event)">
                            </ng-select>
                          </div>
                        </ng-container>






                      </ng-container>


                    </div>

                </div>

            </div>

        </div>

    </p-fieldset>

</div>
