import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PreviousLocationService {
  private _previousLocation = [];
  public locations = {}
  constructor(private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      if(this._previousLocation[length-1] !== event.url) {
        this._previousLocation.push(event.url);
      }
      if (this._previousLocation.length>2) {
        this._previousLocation.splice(0,1);
      }
    });
  }

  get previousLocation() {
    return this._previousLocation[0];
  }
}
