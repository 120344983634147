
<div class="panel general-information">
    <div class="row justify-content-between">
        <div class="col-3">
          <h2>{{ 'ACCOUNTS_MODULE.accountSummary.generalInformationTitle' | translate }}</h2>
        </div>
        <div class="col-2">
          <div *ngIf="account" class="row">
            <div class="col-8 col-sm-6">
            </div>
            <div class="col-8 col-sm-6">
            </div>
          </div>
        </div>
  <form [formGroup]="form" id="details-form" >
    <formly-form [model]="account" [fields]="fields" [options]="options" [form]="form"></formly-form>
  </form>
</div>
