import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalizeRouterModule } from "@gilsdav/ngx-translate-router";
import { AccountManagementRoutingModule } from '@module/account-management/account-management-routing.module';
import { AccountPageComponent } from '@module/account-management/containers/account-page/account-page.component';
import { AgreementCreditsModule } from '@module/agreement-credits/agreement-credits.module';
import { NgbAccordionModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { AddAdditionalUsersComponent } from '@shared/components/add-additional-users/add-additional-users.component';
import { NgSelectModule } from '@shared/components/ng-select/ng-select.module';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { SharedFormlyModule } from 'app/shared-formly/shared-formly.module';
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { SharedModule } from '../../shared/shared.module';
import { AccountApprovalComponent } from './components/account-approval/account-approval.component';
import { AccountHistoryViewComponent } from './components/account-history-view/account-history-view.component';
import { AccountHistoryComponent } from './components/account-history/account-history.component';
import { AccountRegistrationComponent } from './components/account-registration/account-registration.component';
import { AccountSummaryComponent } from './components/account-summary/account-summary.component';
import { AccountsListComponent } from './components/accounts-list/accounts-list.component';
import { AgreeementAccSumComponent } from './components/agreeement-acc-sum/agreeement-acc-sum.component';
import { AssignedUsersComponent } from './components/assigned-users/assigned-users.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CompensationObligationHistoryComponent } from './components/compensation-obligation-history/compensation-obligation-history.component';
import { EnhancedUserDashboardComponent } from './components/enhanced-user-dashboard/enhanced-user-dashboard.component';
import { EnhancedUserSidebarComponent } from './components/enhanced-user-sidebar/enhanced-user-sidebar.component';
import { ManageAccountComponent } from './components/manage-account/manage-account.component';
import { RegReptListComponent } from './components/reg-rept-list/reg-rept-list.component';
import { ReplacingMaoComponent } from './components/replacing-mao/replacing-mao.component';
import { ReqRegCancellationComponent } from './components/req-reg-cancellation/req-reg-cancellation.component';
import { SubContractingOrganizationComponent } from './components/sub-contracting-organization/sub-contracting-organization.component';
import { TopTransactionsComponent } from './components/top-transactions/top-transactions.component';
import { UpcomingDeadlinesComponent } from './components/upcoming-deadlines/upcoming-deadlines.component';
import { UserTasksComponent } from './components/user-tasks/user-tasks.component';
import { AccountActionsComponent } from './controls/account-actions/account-actions.component';
import { AccountSummaryProjectsComponent } from './components/account-summary-projects/account-summary-projects.component';
import { AccountActivitiesListComponent } from './components/account-activities-list/account-activities-list.component';

@NgModule({
  declarations: [
    AccountPageComponent,
    AccountsListComponent,
    AccountSummaryComponent,
    CompensationObligationHistoryComponent,
    AssignedUsersComponent,
    AccountActionsComponent,
    EnhancedUserDashboardComponent,
    EnhancedUserSidebarComponent,
    TopTransactionsComponent,
    ManageAccountComponent,
    AccountRegistrationComponent,
    ChangePasswordComponent,
    UserTasksComponent,
    UpcomingDeadlinesComponent,
    AccountApprovalComponent,
    RegReptListComponent,
    ReplacingMaoComponent,
    SubContractingOrganizationComponent,
    ReqRegCancellationComponent,
    AgreeementAccSumComponent,
    AccountHistoryComponent,
    AccountHistoryViewComponent,
    AccountSummaryProjectsComponent,
    AccountActivitiesListComponent,
  ],
  imports: [
    CommonModule,
    AccountManagementRoutingModule,
    TableModule,
    TranslateModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyBootstrapModule,
    AccordionModule,
    FontAwesomeModule,
    NgbDropdownModule,
    DialogModule,
    FormlyModule.forRoot(),
    SharedFormlyModule,
    LocalizeRouterModule,
    NgbAccordionModule,
    MatProgressSpinnerModule,
    AgreementCreditsModule,
    SharedPipesModule,
  ],
  providers: [
    MessageService,
  ],
  entryComponents: [
    EnhancedUserSidebarComponent, AgreeementAccSumComponent,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class AccountManagementModule {
}
