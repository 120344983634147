
  <ng-container *ngIf="to.tooltip">
    <fa-icon
      pTooltip="{{ to.tooltip | translate }}"
      tooltipPosition="top"
      [escape]="false"
      tooltipStyleClass="tooltip-min-width"
      [icon]="['fas', 'info-circle']">
    </fa-icon>
  </ng-container>
    <div>
      <fieldset>
        <legend class="wcag-visuallyhidden">{{ itemLabelPrefix + elementId }}</legend>
      <div *ngFor="let option of to.options | formlyCustomSelectOptions:field | async; let i = index;"
        [ngClass]="{
          'form-check': to.formCheck.indexOf('custom') === -1,
          'form-check-inline': to.formCheck === 'inline',
          'custom-control custom-radio': to.formCheck.indexOf('custom') === 0,
          'custom-control-inline': to.formCheck === 'custom-inline'
        }"
      >

      <div *ngIf="option.data">
          <div class="row">
            <div class="col-12" *ngFor="let info of option.data">
              {{info.label}}
            </div>
          </div>
        </div>

        <ng-container *ngIf="option.disabled; else NORMAL">
          <input type="radio"
          [id]="itemLabelPrefix + elementId + '_' + i + (to.disabled ? '_ro' : '')"
          [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
          [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
          [name]="field.name || id"
          [class.is-invalid]="showError"
          [attr.value]="option.value"
          [attr.disabled]="true"
          [value]="option.value"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [tabIndex]="tabIndex">
        </ng-container>
        <ng-template #NORMAL>
          <input type="radio"
            [id]="itemLabelPrefix + elementId + '_' + i + (to.disabled ? '_ro' : '')"
            [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
            [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
            [name]="field.name || id"
            [class.is-invalid]="showError"
            [attr.value]="option.value"
            [value]="option.value"
            [formControl]="formControl"
            [formlyAttributes]="field"
            [tabIndex]="tabIndex">
        </ng-template>
        <label
          [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
          [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
          [for]="itemLabelPrefix + elementId + '_' + i + (to.disabled ? '_ro' : '')">
          <span [innerHTML]="option.label | translate"></span>
          <ng-container *ngIf="option.tooltip">
            <fa-icon
              pTooltip="{{ option.tooltip | translate }}"
              tooltipPosition="top"
              [tooltipStyleClass]="getTooltipClass(i)"
              [icon]="['fas', 'info-circle']">
            </fa-icon>
          </ng-container>
        </label>

        <div *ngIf="isData(option)">
          <div class="row">
            <div class="col-12" *ngFor="let info of option.data">
             <span [innerHTML]="info.label | translate"></span>
            </div>
          </div>
        </div>

      </div>
      </fieldset>
    </div>
  