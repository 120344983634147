

  <style>
      .card {
        box-shodow:none;
      }
    </style>

    <div *ngFor="let field of field.fieldGroup; let i = index" class="row p-2">
        <ngb-accordion
            #acc="ngbAccordion"
            [closeOthers]="true"
            (panelChange)="onPanelToggle($event)">
            <ngb-panel>
            <ng-template ngbPanelTitle>
                <fa-icon class="float-left  mr-2"  [icon]="['fas', openPanel ? 'chevron-down' : 'chevron-right']"></fa-icon>
                <span>Facility</span>                
            </ng-template>
            <ng-template ngbPanelContent>
                <formly-field class="col" [field]="field"></formly-field>
            </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
  