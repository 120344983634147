import {
  ActionOptionType,
  IActionModel,
  IAddNewRow,
  IOpenNewPageForNewRow,
  ITableFilterActionModel,
} from '@shared/models/table-filter.models';
import { ActionSequence } from 'protractor';
import { IApp } from './app.interfaces';

export const accountsModuletranslationPrefix = 'ACCOUNTS_MODULE.accountList.';
export const adminModuleEnvIntAccTranslationPrefix = 'ADMIN_MODULE.environmentalIntegrityAccount.';
export const activityLogstranslationPrefix = 'ACTIVITY_LOGS_MODULE.logsList.';
export const commonFilterTranslationPrefix = 'COMMON.filters.';
export const commonActionsLabelTranslationPrefix = 'COMMON.actionsLabel.';
export const unitActionTranslationPrefix = '';
export const obligationAdminActionsTranslationPrefix = 'OBLIGATION_MGMT_MODULE.adminActions.';
export const PERMISSION_SECURITY_CONFIGURATION = 'SUPER_SECURITY_CONFIGURATION';
export const PERMISSION_ADMIN_SECURITY_CONFIGURATION = 'ADMIN_SECURITY_CONFIGURATION';
export const PERMISSION_SET_DEADLINE_DATE = 'SET_DEADLINE_DATE';
export const SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX = 'SUBMISSION_MANAGEMENT_MODULE.submissionManagementList.';
export const ADMIN_MODULE_PROTOCOL_MANAGEMENT = 'ADMIN_MODULE.offsetProtocolManagement.';
export const UNIT_CLASS_LIST = 'COMMON.unitClassList.'

export const newAccountAction = {
  id: 'newAccount',
  label: commonActionsLabelTranslationPrefix + 'newAccount',
  uri: '/account-management/mop-register-account/new-account-mop',
  type: ActionOptionType.URL,
  permission: 'ACCOUNT_WRITE',
};

export const newSubAccountAction = {
  id: 'newSubAccount',
  label: commonActionsLabelTranslationPrefix + 'newSubAccount',
  uri: '/account-management/mop-register-account/new-sub-account-mop',
  type: ActionOptionType.URL,
  permission: 'ACCOUNT_WRITE',
};

export const accountExportPdf = {
  id: 'exportPdf',
  label: commonActionsLabelTranslationPrefix + 'exportPdf',
  uri: 'Account',
  type: ActionOptionType.EXPORT,
};

export const accountExportExcel = {
  id: 'exportExcel',
  label: commonActionsLabelTranslationPrefix + 'exportExcel',
  uri: 'Account',
  type: ActionOptionType.EXPORT,
};

export const accountListTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    /**  {
      placeholder: accountsModuletranslationPrefix + 'facilityNameText',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList'
    },

    {
      placeholder: accountsModuletranslationPrefix + 'facilityCertificateNumberText',
      itemsType: 'facilityCertificateNos',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityCertificateList'
    },
     {
      placeholder: accountsModuletranslationPrefix + 'responsiblePersonsText',
      itemsType: 'responsiblePersons',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'responsiblePersonsList'
    },
     {
      placeholder: accountsModuletranslationPrefix + 'facilityStatusText',
      itemsType: 'facilityStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityStatusList'
    }**/
  ],

  actionModel: [
    {
      data_id: 'tools',
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: [],
    },
  ],
};

export const transactionTypeAction = {
  placeholder: 'COMMON.filters.activityType',
  itemsType: 'transactionType',
  multiple: true,
  selectableGroup: true,
  closeOnSelect: false,
  ngModel: 'transitionTypeList',
};

export const activityLogsTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: true,

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          uri: 'ACTIVITY_LOG',
          type: ActionOptionType.EXPORT,
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          uri: 'ACTIVITY_LOG',
          type: ActionOptionType.EXPORT,
        },
      ],
    },
  ],
};

export const facilityObpsResultTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: false,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'resultCompliancePeriods',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriods',
    },

    {
      placeholder: commonFilterTranslationPrefix + 'versions',
      itemsType: 'versions',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'versionNumbers',
    },

    {
      placeholder: commonFilterTranslationPrefix + 'versionStatus',
      itemsType: 'versionStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'versionStatus',
    },

    {
      placeholder: commonFilterTranslationPrefix + 'obpsResortType',
      itemsType: 'obpsResortType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'resultTypes',
    },
  ],

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'actions',
      options: [
        {
          id: ActionOptionType.APPROVE,
          label: commonActionsLabelTranslationPrefix + 'APPROVE',
          type: ActionOptionType.APPROVE,
          confirmationRequired: true,
        },
        {
          label: commonActionsLabelTranslationPrefix + 'upload',
          type: ActionOptionType.FILEUPLOAD,
          uri: IApp.DocumentUploadTo.UPLOAD_OBPS_RESULT_REPORT_SERVICE,
        },
        {
          label: commonActionsLabelTranslationPrefix + 'setIssuances',
          type: ActionOptionType.OBPS_SET_ISSUANCES,
        },
      ],
    },

    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          uri: 'FACILITY_OBPS_RESULT',
          type: ActionOptionType.EXPORT,
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          uri: 'FACILITY_OBPS_RESULT',
          type: ActionOptionType.EXPORT,
        },
        {
          id: 'downloadTemplate',
          label: commonActionsLabelTranslationPrefix + 'downloadTemplate',
          uri: '/obps-service/credits/download',
          type: ActionOptionType.DOWNLOAD,
        },
      ],
    },
  ],
};

export const openNewPageForNewRow: IOpenNewPageForNewRow = {
  button: true,
  label: `${commonActionsLabelTranslationPrefix}addNew`,
  permission: PERMISSION_SECURITY_CONFIGURATION,
  uri: '/admin/new-terms-conditions',
};

export const addNewAdmin: IAddNewRow = {
  button: true,
  label: `${commonActionsLabelTranslationPrefix}addNew`,
  permission: PERMISSION_SECURITY_CONFIGURATION,
};

export const addNewProgramProperty: IAddNewRow = {
  button: true,
  label: `${commonActionsLabelTranslationPrefix}addNew`,
  permission: PERMISSION_SET_DEADLINE_DATE,
};

export const adminProgramPropertiesTableFilters: ITableFilterActionModel = {
  addNewRow: addNewProgramProperty,
  searchBox: true,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('PROGRAM_PROPERTY'),
    },
  ],
};

export function getExportOptions(uri: string) {
  return [
    {
      id: 'exportPdf',
      label: commonActionsLabelTranslationPrefix + 'exportPdf',
      uri,
      type: ActionOptionType.EXPORT,
      additionalParameter: {}
    },
    {
      id: 'exportExcel',
      label: commonActionsLabelTranslationPrefix + 'exportExcel',
      uri,
      type: ActionOptionType.EXPORT,
      additionalParameter: {}
    },
  ];
}

export const adminFacilitiesProgramPropertiesTableFilters: ITableFilterActionModel = {
  addNewRow: addNewProgramProperty,
  searchBox: true,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('FACILITY_PROGRAM_PROPERTY'),
    },
  ],
};

export const adminEnergyDensityTableFilters: ITableFilterActionModel = {
  addNewRow: addNewProgramProperty,
  searchBox: true,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('ENERGY_DENSITY'),
    },
  ],
};

export const adminSystemPropertiesTableFilters: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: `${commonActionsLabelTranslationPrefix}tools`,
      options: getExportOptions('SYSTEM_PROPERTY'),
    },
  ],
};

export const uploadAdminDocumentAction = {
  id: 'upload',
  label: commonActionsLabelTranslationPrefix + 'upload',
  type: ActionOptionType.FILEUPLOAD,
  uri: IApp.DocumentUploadTo.DOCUMENT_UPLOAD_ENTITY,
};

export const sendFacilityReceivedEmails = {
  id: 'sendfacilityReceivedEmails',
  label: commonActionsLabelTranslationPrefix + 'sendfacilityReceivedEmails',
  type: ActionOptionType.SERVICE,
  uri: '/account-service/account/sendFacilityReceivedEmails',
};

export const adminProgramWorkflowConfigTableFilters: ITableFilterActionModel = {
  // addNewRow: addNewAdmin,
  filterListsModel: [
    {
      placeholder: 'ADMIN_MODULE.termsAndConditions.approvalType',
      itemsType: 'workflowApprovalTypes',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'approvalType',
      dropdownFullWidth: true,
    },
  ],
  searchButtons: true,
  options: {
    searchButtons: {
      clearButtonOnly: true,
    },
  },
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('PROGRAM_WORKFLOW_CONFIG'),
    },
  ],
};

export const offsetConfigTableFilters: ITableFilterActionModel = {
  addNewRow: {
    button: true,
    label: `${commonActionsLabelTranslationPrefix}addNew`,
    permission: PERMISSION_SECURITY_CONFIGURATION,
  },
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('OFFSET_PROTOCOL_CONFIG'),
    },
  ],
};

export const adminTermsAndConditionsFilters: ITableFilterActionModel = {
  openNewPageForNewRow,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('TERMS_AND_CONDITIONS'),
    },
  ],
};

export const adminPrivacyPolicyFilters: ITableFilterActionModel = {
  openNewPageForNewRow,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('PRIVACY_POLICY'),
    },
  ],
};

export const adminQuantificationMethodFilters: ITableFilterActionModel = {
  addNewRow: addNewProgramProperty,
  searchBox: true,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('QUANTIFICATION_METHOD'),
    },
  ],
};

export const craPaymentsTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: false,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'paymentStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'paymentStatusList',
    },
  ],

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'actions',
      options: [
        {
          label: commonActionsLabelTranslationPrefix + 'uploadPayment',
          type: ActionOptionType.FILEUPLOAD,
          uri: IApp.DocumentUploadTo.UPLOAD_CRA_PAYMENT_REPORT_SERVICE,
        },
        {
          label: commonActionsLabelTranslationPrefix + 'uploadReversal',
          type: ActionOptionType.FILEUPLOAD,
          uri: IApp.DocumentUploadTo.UPLOAD_CRA_REVERSE_REPORT_SERVICE,
        },

      ],
    },

    {
      placeholder: commonActionsLabelTranslationPrefix +'tools',
      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          uri: 'CRA_PAYMENT',
          type: ActionOptionType.EXPORT,
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          uri: 'CRA_PAYMENT',
          type: ActionOptionType.EXPORT,
        },
        {
          id: 'downloadTemplate',
          label: commonActionsLabelTranslationPrefix + 'downloadPaymentTemplate',
          uri: '/obps-service/transaction/download',
          type: ActionOptionType.DOWNLOAD,
        },
        {
          id: 'downloadTemplate',
          label: commonActionsLabelTranslationPrefix + 'downloadReversalTemplate',
          uri: '/obps-service/transaction/downloadReversalTemplate',
          type: ActionOptionType.DOWNLOAD,
        },
      ],
    },
  ],
};

export const facilityNameAction = {
  placeholder: accountsModuletranslationPrefix + 'accountNameText',
  itemsType: 'facilityNames',
  multiple: true,
  selectableGroup: true,
  closeOnSelect: false,
  ngModel: 'facilityNameList',
};

export const unitsTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: false,

  filterListsModel: [
    facilityNameAction,

    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },

    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'unitStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'unitStatusList',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'actions',
      data_id: 'action',
      options: [
        {
          id: '',
          label: unitActionTranslationPrefix + 'creditTransfers',
          type: ActionOptionType.SERVICE,
          uri: '/credit-transfer',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'creditRevocation',
          type: ActionOptionType.SERVICE,
          uri: '/credit-revocation',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'creditRetirement',
          type: ActionOptionType.SERVICE,
          uri: '/credit-retirement',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'creditSuspension',
          type: ActionOptionType.SERVICE,
          uri: '/credit-suspension',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'voluntaryCancellation',
          type: ActionOptionType.SERVICE,
          uri: '/credit-cancellation',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'surplusCreditRemittance',
          type: ActionOptionType.SERVICE,
          uri: '/credit-remittance',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'recognizedUnitRemittance',
          type: ActionOptionType.SERVICE,
          uri: '/ru-credit-remittance',
        },
        {
          id: '',
          label: unitActionTranslationPrefix + 'issueSurplusCredit',
          type: ActionOptionType.SERVICE,
          uri: '/issue-credit',
        },
      ],
    },

    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      data_id: 'tools',
      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'Unit',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'Unit',
        },
      ],
    },
  ],
};

export const newUserAction = {
  id: 'createNewUser',
  label: commonActionsLabelTranslationPrefix + 'createNewUser',
  uri: '/add-user',
  type: ActionOptionType.SERVICE,
};

export const userExportPdf = {
  id: 'exportPdf',
  label: commonActionsLabelTranslationPrefix + 'exportPdf', // commonActionsLabelTranslationPrefix + "exportPdf",
  type: ActionOptionType.EXPORT,
  uri: 'User',
};

export const userExportExcel = {
  id: 'exportExcel',
  label: commonActionsLabelTranslationPrefix + 'exportExcel', // commonActionsLabelTranslationPrefix + "exportExcel",
  type: ActionOptionType.EXPORT,
  uri: 'User',
};

export const orphanUserExportExcel = {
  id: 'exportOrphanUser',
  label: commonActionsLabelTranslationPrefix + 'exportOrphanUser', // commonActionsLabelTranslationPrefix + "exportExcel",
  type: ActionOptionType.EXPORT,
  uri: 'ORPHAN_USER',
};

export const userTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: false,

  filterListsModel: [
    {
      placeholder: 'COMMON.filters.userRole',
      itemsType: 'rolesList',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'rolesList',
    },
    {
      placeholder: 'COMMON.filters.userStatus',
      itemsType: 'statusList',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'statusList',
    },
  ],

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'actions',
      options: [],
    },
  ],
};

export const reportTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: true,

  filterListsModel: [],
};

export const oblitationMgntTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'OBLIGATION_MANAGEMENT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'OBLIGATION_MANAGEMENT',
        },
      ],
    },
  ],
  adminActions: [],
};

export const recognizedUnitsTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'ruUnitStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'ruUnitStatusList',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'RECOGNIZED_UNITS',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'RECOGNIZED_UNITS',
        },
      ],
    },
  ],
};

export const offsetRecognizedUnitsTableFilters: ITableFilterActionModel = {
  searchBox: true,
  dateRange: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'ruUnitStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'ruUnitStatusList',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'RECOGNIZED_UNITS',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'RECOGNIZED_UNITS',
        },
      ],
    },
  ],
};

export const refundRequestTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'refundMode',
      itemsType: 'paymentRefundModes',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'paymentRefundModes',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'refundStatus',
      itemsType: 'paymentRefundStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'paymentRefundStatus',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'actions',
      data_id: 'action',
      options: [
        {
          label: commonActionsLabelTranslationPrefix + 'uploadRefund',
          type: ActionOptionType.FILEUPLOAD,
          uri: IApp.DocumentUploadTo.UPLOAD_CASH_PAYMENT_REFUND,
        },
      ],
    },

    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PAYMENT_REFUND',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PAYMENT_REFUND',
        },
        {
          id: 'downloadTemplate',
          label: commonActionsLabelTranslationPrefix + 'downloadTemplateRefund',
          uri: IApp.DocumentDownloadFrom.DOWNLOAD_PAYMENT_REFUND_TEMPLATE,
          type: ActionOptionType.DOWNLOAD,
        },
      ],
    },
  ],
};

export const refundRequestActionsOptions: any = [
  {
    id: '',
    label: commonActionsLabelTranslationPrefix + 'bulkApprove',
    type: ActionOptionType.BULK_APPROVE,
    uri: '/payment-refund/doBulkAction',
  },
  {
    id: '',
    label: commonActionsLabelTranslationPrefix + 'bulkReject',
    type: ActionOptionType.BULK_REJECT,
    uri: '/payment-refund/doBulkAction',
  },
];

export const bulletinBoardBidsTableFilter: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: UNIT_CLASS_LIST + 'complianceYear',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'unitType',
      itemsType: 'unitType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'unitTypeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'projectJurisdiction',
      itemsType: 'projectNameJurisdiction',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'jurisdictionNameList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_LIST_BID',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_LIST_BID',
        },
      ],
    },
  ],

  customButtons: [
    {
      label: 'enterBid',
    },
  ],
};

export const bulletinBoardOfferTableFilter: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: UNIT_CLASS_LIST + 'complianceYear',
      itemsType: 'compliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'unitType',
      itemsType: 'unitType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'unitTypeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'projectJurisdiction',
      itemsType: 'projectJurisdiction',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'jurisdictionList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_LIST_OFFER',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_LIST_OFFER',
        },
      ],
    },
  ],
};

export const UploadObpsResultsFilter: ITableFilterActionModel = {
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'documentStatusList',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'documentStatusList',
    },
  ],
  searchButtons: true,
  options: {
    searchButtons: {
      clearButtonOnly: true,
    },
  },
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'actions',
      options: [
        {
          label: commonActionsLabelTranslationPrefix + 'upload',
          type: ActionOptionType.FILEUPLOAD,
          uri: IApp.DocumentUploadTo.OBPS_UPLOAD_RESULTS,
        },
      ],
    },

    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'UPLOAD_OBPS_RESULTS',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'UPLOAD_OBPS_RESULTS',
        },
      ],
    },
  ],
};

export const TaskFilter: ITableFilterActionModel = {
  searchBox: true,
  dateRange: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'taskFamily',
      itemsType: 'taskFamilyList',
      multiple: false,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'taskFamily',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'taskType',
      itemsType: 'taskTypeList',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'taskTypeList',
    },

    {
      placeholder: commonFilterTranslationPrefix + 'assignedTo',
      itemsType: 'assignedToList',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'assignedToList',
    },

    {
      placeholder: commonFilterTranslationPrefix + 'facilityCertificateNumberText',
      itemsType: 'facilityCertificateNos',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityCertificateList',
      programs: ['OBPS'],
    },

    {
      placeholder: commonFilterTranslationPrefix + 'responsiblePersons',
      itemsType: 'responsiblePersons',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'responsiblePersonsList',
      programs: ['OBPS'],
    },
  ],

  options: {
    dateRange: {
      startDateField: 'createdOnStartDate',
      endDateField: 'createdOnEndDate',
    },
  },

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'TODO_LIST',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'TODO_LIST',
        },
      ],
    },
  ],
};

export const ManageFacilitiesFilter: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'type',
      itemsType: 'entityTypes',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'entityTypes',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'legalName',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList',
    },
    /**
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'status',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'status'
    },
    */
    {
      placeholder: commonFilterTranslationPrefix + 'province',
      itemsType: 'provinces',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'provinces',
    },
  ],

  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'FACILITY',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'FACILITY',
        },
      ],
    },
  ],
};

export const submissionMgntTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'legalName',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'submissionType',
      itemsType: 'submissionType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'submissionTypeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'regActivityType',
      itemsType: 'regActivityTypes',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'regActivityType',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'assignedVB',
      itemsType: 'assignedVB',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'assignedVBList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'cfrCompliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'submissionReportStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'statusList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'quantificationMethod',
      itemsType: 'quantificationMethods',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'quantificationMethod',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'SUBMISSION_MANAGEMENT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'SUBMISSION_MANAGEMENT',
        },
      ],
    },
  ],
};

export const verificationMgntTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'submissionId',
      itemsType: 'vbSubmissionId',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'submissionIdList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'submissionType',
      itemsType: 'vbSubmissionType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'submissionTypeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'cfrCompliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'vbStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'statusList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'VERIFICATION_MANAGEMENT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'VERIFICATION_MANAGEMENT',
        },
      ],
    },
  ],
};

export const validatorVerificationBodyTableFilers: ITableFilterActionModel = {
  searchBox: false,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'verificationName',
      itemsType: 'vbLegalName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'facilityNumberList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'technicalScope',
      itemsType: 'technicalScope',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'areaOfExpertiseCode',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'country',
      itemsType: 'vbCountry',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'countryCodeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'vbProvince',
      itemsType: 'vbProvinces',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'province',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('VB_SELECTION'),
    },
  ],
};

export const AgreementTableFilter: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'cfrCompliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'submissionReportStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'statusList',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'AGREEMENT_DETAIL',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'AGREEMENT_DETAIL',
        },
      ],
    },
  ],
  customButtons: [
    {
      label: 'createNewAgreement',
    },
  ],
};

export const CoiTableFilter: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [ ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'MANAGE_COI',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'MANAGE_COI',
        },
      ],
    },
  ],
};

export const CarbonIntensityTableFilter: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'legalName',
      itemsType: 'facilityNames',
      multiple: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'facilities',
      itemsType: 'CIFacilitiesList',
      multiple: true,
      flattenResponse: true,
      closeOnSelect: false,
      ngModel: 'facilitiesNameList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'fuelType',
      itemsType: 'allFuels',
      multiple: true,
      closeOnSelect: false,
      ngModel: 'fuelTypeList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'feedstockType',
      itemsType: 'feedstockTypes',
      multiple: true,
      closeOnSelect: false,
      ngModel: 'feedstockTypeList',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'CARBON_INTENSITY_SEARCH',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'CARBON_INTENSITY_SEARCH',
        },
      ],
    },
  ],
};

export const selectUserTableTLFilters: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'areaOfSpecialization',
      itemsType: 'vbAreaOfExpertise',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'professionalQualifications',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('VB_TEAM_LEAD_SEARCH'),
      position: 1,
    },
  ],
  customButtons: [
    {
      label: 'addNewUser',
    }
  ]
};

export const selectUserTableIRFilters: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'areaOfSpecialization',
      itemsType: 'vbAreaOfExpertise',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'professionalQualifications',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('VB_TEAM_MEMBER_SEARCH'),
      position: 1
    },
  ],
  customButtons: [
    {
      label: 'addNewTeamMember',
    }
  ]
};

export const selectVBTeamMembersFilters: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'areaOfSpecialization',
      itemsType: 'vbAreaOfExpertise',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: true,
      ngModel: 'professionalQualifications',
    },
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: [{
        id: 'exportPdf',
        label: commonActionsLabelTranslationPrefix + 'exportPdf',
        type: ActionOptionType.EXPORT,
        uri: 'VB_TEAM_MEMBER_SEARCH',
      },
      {
        id: 'exportExcel',
        label: commonActionsLabelTranslationPrefix + 'exportExcel',
        type: ActionOptionType.EXPORT,
        uri: 'VB_TEAM_MEMBER_SEARCH',
      }],
      position: 1
    },
  ],
  customButtons: [
    {
      label: 'addNewTeamMember',
    }
  ]
};

export const facilityOrEquipmentTableFilters: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'name',
      itemsType: 'name',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'name',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'streetNumber',
      itemsType: 'streetNumber',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'streetNumber',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'streetName',
      itemsType: 'streetName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'streetName',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'city',
      itemsType: 'city',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'city',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'province',
      itemsType: 'provinces',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'provinces',
    },
  ],
  actionModel: [],
};

export const AccountHistoryFilter: ITableFilterActionModel = {
  searchBox: false,
  filterListsModel: [
    {
      placeholder: accountsModuletranslationPrefix + 'action',
      itemsType: 'action',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'actionPerformedByList',
    },
    {
      placeholder: accountsModuletranslationPrefix + 'type',
      itemsType: 'type',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'transitionTypeList',
    },
  ],
  searchButtons: true,
  options: {
    searchButtons: {
      clearButtonOnly: true,
    },
  },
  actionModel: [
    {
      placeholder: accountsModuletranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'ACCOUNT_HISTORY',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'ACCOUNT_HISTORY',
        },
      ],
    },
  ],
};

export const ProjectSubmissionTableFilter: ITableFilterActionModel = {
  searchBox: true,
  dateRange: true,
  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      className: 'col-2',
      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'SUBMISSIONS',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'SUBMISSIONS',
        },
      ],
    },
  ],
  customButtons: [
    {
      label: 'createNewProjectSubmission',
    },
  ],
  options: {
    dateRange: {
      placeholder: 'SUBMISSIONS.submissionList.reportingPeriod',
    },
  },
};

export const ProjectApplicationTableFilter: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_APPLICATION',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_APPLICATION',
        },
      ],
    },
  ],
  customButtons: [
    {
      label: 'createNewAProjectApplication',
      ignoreLayout: true,
    },
    {
      label: 'existingProjectTransfer',
      ignoreLayout: true,
    },
  ],
};

export const ProtocolManagementTableFilter: ITableFilterActionModel = {
  searchBox: false,

  filterListsModel: [
    // {
    //   placeholder: ADMIN_MODULE_PROTOCOL_MANAGEMENT + 'offsetProtocolStatus',
    //   itemsType: 'offsetProtocolStatus',
    //   multiple: true,
    //   selectableGroup: true,
    //   closeOnSelect: false,
    //   ngModel: 'status',
    // }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'GHG_OFFSET_PROTOCOL_CONFIG',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'GHG_OFFSET_PROTOCOL_CONFIG',
        },
      ],
    },
  ],
  addNewRow: {
    button: true,
    label: `${commonActionsLabelTranslationPrefix}addNew`,
    permission: PERMISSION_SECURITY_CONFIGURATION,
  },
};

export const ProjectHistoryFilter: ITableFilterActionModel = {
  searchBox: false,
  filterListsModel: [
    {
      placeholder: SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX + 'action',
      itemsType: 'action',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'actionPerformedByList',
    },
    {
      placeholder: SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX + 'type',
      itemsType: 'type',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'transitionTypeList',
    },
  ],
  searchButtons: true,
  options: {
    searchButtons: {
      clearButtonOnly: true,
    },
  },
  actionModel: [
    {
      placeholder: SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_HISTORY',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_HISTORY',
        },
      ],
    },
  ],
};

export const ProjectTypeManagementTableFilter: ITableFilterActionModel = {
  searchBox: false,

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_TYPE_CONFIG',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_TYPE_CONFIG',
        },
      ],
    },
  ],
  addNewRow: {
    button: true,
    label: `${commonActionsLabelTranslationPrefix}addNew`,
    permission: PERMISSION_SECURITY_CONFIGURATION,
  },
};

export const ProjectCancellationHistoryFilter: ITableFilterActionModel = {
  searchBox: false,
  filterListsModel: [],
  searchButtons: false,

  actionModel: [
    {
      placeholder: ADMIN_MODULE_PROTOCOL_MANAGEMENT + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_CANCELLATION_HISTORY',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'PROJECT_CANCELLATION_HISTORY',
        },
      ],
    },
  ],
};

export const cancelAction = {
  id: 'voluntaryCancellation',
  label: 'UNITS_MODULE.actionList.creditRevocation',
  type: ActionOptionType.SERVICE,
  uri: 'credit-cancellation',
};

export const EnvironmentalIntegrityAccountFilter: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: adminModuleEnvIntAccTranslationPrefix + 'projectName',
      itemsType: 'projectName',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'projectIdList',
    },
    {
      placeholder: adminModuleEnvIntAccTranslationPrefix + 'offsetProtocol',
      itemsType: 'offsetProtocol',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'offsetProtocolList',
    },
    {
      placeholder: adminModuleEnvIntAccTranslationPrefix + 'unitVintageYear',
      itemsType: 'unitVintage',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
  ],
  options: {
    searchButtons: {
      clearButtonOnly: false,
    },
  },
  actionModel: [
    {
      placeholder: adminModuleEnvIntAccTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'ENVIRONMENTAL_INTEGRITY_ACCOUNT',
        },
        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'ENVIRONMENTAL_INTEGRITY_ACCOUNT',
        },
      ],
    },
    {
      placeholder: adminModuleEnvIntAccTranslationPrefix + 'actions',
      options: [],
    },
  ],
};

export const SUBMISSIONS_HISTORY_FILTERS: ITableFilterActionModel = {
  searchBox: false,
  filterListsModel: [
    {
      placeholder: SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX + 'action',
      itemsType: 'submissionsAction',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'actionPerformedByList',
    },
    {
      placeholder: SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX + 'type',
      itemsType: 'submissionsType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'transitionTypeList',
    },
  ],
  searchButtons: true,
  options: {
    searchButtons: {
      clearButtonOnly: true,
    },
  },
  actionModel: [
    {
      placeholder: SUBMISSION_MANAGEMENT_MODULE_TRANSLATION_PREFIX + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'SUBMISSIONS_HISTORY',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'SUBMISSIONS_HISTORY',
        },
      ],
    },
  ],
};

export const AccountSummaryProjectsTableFilter: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'OFFSET_DASHBOARD_PROJECTS',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'OFFSET_DASHBOARD_PROJECTS',
        },
      ],
    },
  ],
};

export const CreditCreationReportsFilter: ITableFilterActionModel = {
  dateRange: true,
  searchBox: true,

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_CREATION_REPORT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_CREATION_REPORT',
        },
      ],
    },
  ],
  options: {
    dateRange: {
      placeholder: 'CREDIT_CREATION_REPORT.reportList.submittedOn',
    },
  },
};

export const adminErfpPriceManagementTableFilters: ITableFilterActionModel = {
  addNewRow: addNewProgramProperty,
  searchBox: false,
  filterListsModel: ([] = []),
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',
      options: getExportOptions('ERFP_PRICE_MANAGEMENT'),
    },
  ],
};

export const noticeOfCOIMgntTableFilters: ITableFilterActionModel = {
  searchBox: true,

  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'legalName',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'submissionType',
      itemsType: 'vbSubmissionType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'submissionTypes',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'cfrCompliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'coiNoticeStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'statusList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'MANAGE_COI_NOTICE',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'MANAGE_COI_NOTICE',
        },
      ],
    },
  ],
};

export const verificationReportTableFilters: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: commonFilterTranslationPrefix + 'legalName',
      itemsType: 'facilityNames',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'facilityNameList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'submissionType',
      itemsType: 'vbSubmissionType',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'submissionTypes',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'compliancePeriod',
      itemsType: 'cfrCompliancePeriod',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'compliancePeriodList',
    },
    {
      placeholder: commonFilterTranslationPrefix + 'status',
      itemsType: 'verificationReportStatus',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'statusList',
    }
  ],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'VERIFICATION_REPORT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'VERIFICATION_REPORT',
        },
      ],
    },
  ],
};

export const noticeOfSuspensionTableFilters: ITableFilterActionModel = {
  filterListsModel:
    [
      {
        placeholder: `${commonFilterTranslationPrefix}province`,
        itemsType: 'provinces',
        multiple: true,
        selectableGroup: true,
        closeOnSelect: false,
        ngModel: 'provinces',
      },
      {
        placeholder: `${commonFilterTranslationPrefix}creditGroup`,
        itemsType: 'creditGroup',
        multiple: true,
        selectableGroup: true,
        closeOnSelect: false,
        ngModel: 'creditGroupList',
      }
    ],
  actionModel: [],
};


export const CCMFilters: ITableFilterActionModel = {
  searchBox: false,
  searchButtons: true,
  options: {
    searchButtons: {
      clearButtonOnly: true,
    },
  },

  filterListsModel: [],
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: '',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: '',
        },
      ],
    },
  ],
};

export const CCMCreditsFilters = (additionalParameter, uri): ITableFilterActionModel => {
  return {
    searchBox: false,

    filterListsModel: [],
    actionModel: [
      {
        placeholder: commonActionsLabelTranslationPrefix + 'tools',

        options: [
          {
            id: 'exportPdf',
            label: commonActionsLabelTranslationPrefix + 'exportPdf',
            type: ActionOptionType.EXPORT,
            uri: uri,
            additionalParameter: additionalParameter
          },

          {
            id: 'exportExcel',
            label: commonActionsLabelTranslationPrefix + 'exportExcel',
            type: ActionOptionType.EXPORT,
            uri: uri,
            additionalParameter: additionalParameter
          },
        ],
      },
    ],
  };
};
