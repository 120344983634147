/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./change-password.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/reset-password/reset-password.component.ngfactory";
import * as i3 from "../../../../shared/components/reset-password/reset-password.component";
import * as i4 from "../../../../core/services/auth.service";
import * as i5 from "@angular/router";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../shared/services/helper.service";
import * as i8 from "@angular/common";
import * as i9 from "./change-password.component";
import * as i10 from "../../../../core/store/store.service";
var styles_ChangePasswordComponent = [i0.styles];
var RenderType_ChangePasswordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChangePasswordComponent, data: {} });
export { RenderType_ChangePasswordComponent as RenderType_ChangePasswordComponent };
function View_ChangePasswordComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reset-password", [], null, null, null, i2.View_ResetPasswordComponent_0, i2.RenderType_ResetPasswordComponent)), i1.ɵdid(1, 114688, null, 0, i3.ResetPasswordComponent, [i4.AuthService, i5.Router, i6.TranslateService, i5.ActivatedRoute, i7.HelperService], { username: [0, "username"], standalone: [1, "standalone"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentUser == null) ? null : _co.currentUser.userName); var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ChangePasswordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "row justify-content-center align-items-center mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-12 col-md-6 col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChangePasswordComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentUser; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ChangePasswordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-change-password", [], null, null, null, View_ChangePasswordComponent_0, RenderType_ChangePasswordComponent)), i1.ɵdid(1, 114688, null, 0, i9.ChangePasswordComponent, [i7.HelperService, i10.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChangePasswordComponentNgFactory = i1.ɵccf("app-change-password", i9.ChangePasswordComponent, View_ChangePasswordComponent_Host_0, {}, {}, []);
export { ChangePasswordComponentNgFactory as ChangePasswordComponentNgFactory };
