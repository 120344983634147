/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-discussion-chat-replies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../project-discussion-chat-reply/project-discussion-chat-reply.component.ngfactory";
import * as i5 from "../project-discussion-chat-reply/project-discussion-chat-reply.component";
import * as i6 from "@angular/common";
import * as i7 from "./project-discussion-chat-replies.component";
var styles_ProjectDiscussionChatRepliesComponent = [i0.styles];
var RenderType_ProjectDiscussionChatRepliesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectDiscussionChatRepliesComponent, data: {} });
export { RenderType_ProjectDiscussionChatRepliesComponent as RenderType_ProjectDiscussionChatRepliesComponent };
function View_ProjectDiscussionChatRepliesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onExpandReplies($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, [" ", " ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.collapsedReplies.length; var currVal_1 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform("PROJECTS_MODULE.DISCUSSION.discussionPreviousReplies")); _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_ProjectDiscussionChatRepliesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-project-discussion-chat-reply", [], null, null, null, i4.View_ProjectDiscussionChatReplyComponent_0, i4.RenderType_ProjectDiscussionChatReplyComponent)), i1.ɵdid(3, 49152, null, 0, i5.ProjectDiscussionChatReplyComponent, [], { reply: [0, "reply"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ProjectDiscussionChatRepliesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDiscussionChatRepliesComponent_1)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectDiscussionChatRepliesComponent_2)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCollapsed && _co.collapsedReplies.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.visibleReplies; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProjectDiscussionChatRepliesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-discussion-chat-replies", [], null, null, null, View_ProjectDiscussionChatRepliesComponent_0, RenderType_ProjectDiscussionChatRepliesComponent)), i1.ɵdid(1, 573440, null, 0, i7.ProjectDiscussionChatRepliesComponent, [], null, null)], null, null); }
var ProjectDiscussionChatRepliesComponentNgFactory = i1.ɵccf("app-project-discussion-chat-replies", i7.ProjectDiscussionChatRepliesComponent, View_ProjectDiscussionChatRepliesComponent_Host_0, { replies: "replies" }, {}, []);
export { ProjectDiscussionChatRepliesComponentNgFactory as ProjectDiscussionChatRepliesComponentNgFactory };
