import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SessionData } from '@core/models/auth.model';
import { IUserData } from '@core/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from "@shared/services/helper.service";
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { IApp } from '@core/models/app.interfaces';
import { ModalService } from '@shared/services/modal.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { LookupService } from '@core/services/lookup.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '@shared/services/user.service';
import { Observable } from 'rxjs/internal/Observable';
import { Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PreviousLocationService } from '@shared/services/previous-location.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();

  @ViewChild(NgbDropdown, {static: false}) public dropdown: NgbDropdown;

  public session: SessionData;
  public isNavbarCollapsed = true;
  public currentUser: IUserData;
  private _SAMLProviders: IApp.IAuthSAMLProvider[] = null;

  constructor(
    private store: StoreService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private helperService: HelperService,
    private documentUploadInProgress: DocumentUploadInProgressService,
    private modalService: ModalService,
    private lookupService: LookupService,
    private userService: UserService,
    private titleService: Title,
    private previousLocationService: PreviousLocationService
  ) {
    this.store.user$.subscribe(
      currentUser =>  this.currentUser = currentUser,
    );
  }

  ngOnInit() {
    this.session = this.store.getSession();
    this.authService.SAMLProviders
    .subscribe(
      data => this._SAMLProviders = data,
    );

    this.translate.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
      this.setDocTitle(this.translate.instant('COMMON.titles.ecccAppTabTitle'));
    });
  }

  get lang(): string {
    return this.translate.currentLang;
  }

  get loginEnabled(): Observable<boolean> {
    return this.store.loginAvailable$;
  }

  changeLang(event, lang: string): void {
    event && event.preventDefault();
    this.authService.changeLang(lang, true)
      .subscribe();
  }

  setDocTitle(title: string) {
    console.log('current title:::::' + this.titleService.getTitle());
    this.titleService.setTitle(title);
 }

  skipToMain(event) {
    if (event && event.key === 'Enter') {
      event && event.preventDefault();
      document.getElementById('main-content').focus();
    }
  }

  signOut(event, accessToken) {
    event && event.preventDefault();
    if (!this.documentUploadInProgress.isInProgress(true)) {
      this.authService.signOut(true, accessToken).subscribe(
        response => {
        },
        err => {
          // TODO remove this part after backend update
          this.store.destroy();
          this.lookupService.clearCache();
          this.userService.clearCache();
          this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
        },
      );
    }
  }

  migrateAccountAction() {
    this.modalService.open(
      ServiceMessageComponent,
      {
        messages: null,
        message: 'migrateAccountWarning',
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.router.navigate(this.helperService.getTranslatedPath('/register/select-new-login'));
        }
      });
  }

  gotoMyProfile() {
    this.router.navigate(this.helperService.getTranslatedPath(`/user/list/${this.currentUser.id}`));
  }

  toggleMenu() {
    this.dropdown.toggle();
  }

  public get isOffsetPublicPage(): boolean {
    return this.store.isOffsetPublicPage;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
