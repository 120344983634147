import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const NFMP_DATE = '/account-service/property/getNfmpDate';
export class ComingsoonService {
    constructor(http) {
        this.http = http;
    }
    getNFMPDate() {
        return this.http.get(`${environment.apiUrl}${NFMP_DATE}`);
    }
}
ComingsoonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ComingsoonService_Factory() { return new ComingsoonService(i0.ɵɵinject(i1.HttpClient)); }, token: ComingsoonService, providedIn: "root" });
