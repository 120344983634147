import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

const TRANS_PREFIX = 'COMMON.messageSection.';

export class TranslateExtension {
  constructor(
    private translateService: TranslateService,
  ) {

  }

  prePopulate(field: FormlyFieldConfig) {
    const to = field.templateOptions || {};
    if (to._translated || to.translate === false) {
      return;
    }

    to._translated = true;
    if (to.label) {
      !to._label && (to._label = to.label);
      field.expressionProperties = {
        ...(field.expressionProperties || {}),
        'templateOptions.label': () => this.translateService.instant(to._label).replace(/'/g, "\'"),
      };
    }
    if (to.placeholder) {
      !to._placeholder && (to._placeholder = to.placeholder);
      field.expressionProperties = {
        ...(field.expressionProperties || {}),
        'templateOptions.placeholder': () => this.translateService.instant(to._placeholder).replace(/'/g, "\'"),
      };
    }
  }
}

export function registerTranslateExtension(translate: TranslateService) {
  return {
    extensions: [{
      name: 'translate',
      extension: new TranslateExtension(translate),
    }],
    validationMessages: [
      { name: 'server-side', message: (err, field) => translate.instant(`${ TRANS_PREFIX }${ err }`) },
      { name: 'direct', message: (err, field) =>  err   },
      { name: 'required', message: (err, field) => translate.instant(`${ TRANS_PREFIX }fieldRequiredException`)},
      { name: 'minlength', message: (err, field) => translate.instant(`${ TRANS_PREFIX }fieldTooShortException`, {length: err.requiredLength})},
      { name: 'maxlength', message: (err, field) => translate.instant(`${ TRANS_PREFIX }fieldTooLongException`, {length: err.requiredLength})},
      { name: 'min', message: (err, field) => translate.instant(`${ TRANS_PREFIX }fieldBelowMinException`, {length: err.min})},
      { name: 'max', message: (err, field) => translate.instant(`${ TRANS_PREFIX }fieldAboveMaxException`, {length: err.max})},
      { name: 'invalidPostalCodeException', message: () => translate.instant(`${ TRANS_PREFIX }invalidPostalCodeException`)},
    ],
  };
}
