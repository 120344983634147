<form class="form-inline">
  <div class="form-group">
    <ng-container *ngFor="let digit of c; let i = index">
      <label class="wcag-visuallyhidden" [for]="'confirmationCodeDigit' + i">digit</label>
      <input [id]="'confirmationCodeDigit' + i"
             class="form-control form-digit"
             type="text"
             [maxLength]="1"
             (keydown)="onKeyDown($event)"
             (keyup)="onKeyUp($event, i)"
             (focus)="onFocus($event)"
             [ngModel]="output[i]"
             [ngClass]="{ 'is-invalid': !output[i] && isInvalid }"
             [ngModelOptions]="{standalone: true}">
      </ng-container>
  </div>
</form>
