import { IApp } from '@core/models/app.interfaces';
import { AccountData } from '@core/models/account.model';

export interface IRegistrationStep {
     id: number;
     subId?: number;
     name: string;
     completed: boolean;
     hidden?: boolean;
     enabledSubStepsProp?: string;
     enabledSubSteps?: boolean;
     subSteps?: IRegistrationStep[];
     preventNavigation?: boolean;
     skipTranslate?: boolean;
}

export interface IRegistrationSteps {
  [index: string]: IRegistrationStep[];
}

export interface IKYCFile {
  filename: string;
  name: string;
  timestamp: string;
  comments: string;
}

export class AccountSubType implements IApp.IAccountSubType{
  id?:number;
  name?:string;
}

export class RegistrationType implements IApp.IRegistrationType{
  id?:number;
  name?:string;

}

export class IAccount implements IApp.IAccount{
  accountType?: IApp.IAccountType;
  additionalLegalName?: string;
  address?: IAddress;
  authorizedAccountRepresenttive?: IApp.IUser;
  businessStructure?: IApp.IBusinessStructure;
  certificateNumber?: string;
  createdBy?: number;
  creationDate?: any;
  deleted?: boolean;
  externalId?: number;
  facilityId?: number;
  id?: number;
  jurisdiction?: string;
  lastModifiedBy?: number;
  lastModifiedDate?: any;
  legalName?: string;
  masterAccount?: IApp.IAccount;
  naics?: string;
  persons?: IApp.IPerson[];
  phone?: string;
  registrationNumber?: string;
  registrationType?: IApp.IRegistrationType;
  status?: IApp.NSAccount.StatusEnum;
  termsAndConditions?: boolean;
  transitionType?: IApp.NSAccount.TransitionTypeEnum;
  userType?: IApp.NSAccount.UserTypeEnum;
  version?: number;
  accountSubType?:AccountSubType;
  authorizedOfficial: any;
}

// TODO TBD
export class IAccountData {
  id?: number;
  externalId?: number;
  legalName?: string;
  additionalLegalName?: string;
  addressId?: number;
  businessStructure?: IApp.IBusinessStructure;
  charitableFirm?: boolean;
  registrationNumber?: string;
  jurisdiction?: string;
  phone?: string;
  naics?: string;
  authorizedAccountRepresentativeId?: number;
  termsAndConditions?: boolean;
  facilityId?: number;
  certificateNumber?: string;
  address?: IAddress;
  sectorCode?: string;
  authorizedOfficial?: IApp.IUser;
  documents: IDocument[];
  programType?:string;
  masterAccount:AccountData;
  accountSubType?:AccountSubType;
  registrationType?:RegistrationType;
  displayAoProfilePublicView?: boolean;
}

export class IBusinessStructure {
    createdBy?: number;
    creationDate?: string;
    deleted?: boolean;
    id?: number;
    lastModifiedBy?: number;
    lastModifiedDate?: string;
    name?: string;
    version?: number;
}

export interface IAuthorizedOfficial{
  name: string;
  email: string;
}

export interface IAddress{
  streetNumber?: any;
  streetName?: any;
  city?: any;
  poBox?: any;
  country? :	string;
  createdBy? :	number;
  creationDate? :	string;
  deleted? :	boolean;
  id? :	number;
  lastModifiedBy? :	number;
  lastModifiedDate? :	string;
  latitude? :	number;
  longitude? :	number;
  municipality? :	string;
  postalCode? :	string;
  province? :	string;
  streetAddress? :	string;
  version? :	number;
}

export interface IFacility {
  id: number;
  accountId?: number;
  legalName: string;
  certificateNumber: string;
  authorizedAccountRepresentativeId: number;
  status?: string;
  accountSubTypes?: string;
  accountSubTypeActivity?: string;
  masterAccount?: any;
}

export interface IFacilityRequest {
  certificateNumbers?: string[];
  docIds: IApp.IDocument[];
  accountIds?: number[];
  subType?: string;
  userComment?: string;
}

export interface IOkResponse {
  message: string;
  type: string;
  code: string;
  entity: any;
}

export interface IDocument {
  id: number;
  comments: string;
  name?: string;
  _uploadStatus?: string;
  lastModifiedDateStr?: string;
  documentType?: any;
  type?: string;
}

