import {
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: '[wcag-dummy-link-target]',
})
export class WCAGDummyLinkTargetDirective implements OnInit, OnDestroy {

  sub: any;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {
    this.sub = this.translate.onLangChange.subscribe(
      () => {
        this.removePrevious();
        setTimeout(() => this.update());
      },
    );
  }

  ngOnInit() {
    setTimeout(() => this.update());
  }

  private update() {
    const el = this.elRef.nativeElement;
    const href = el.getAttribute('href');
    this.createDummyTarget(href);
  }

  private createDummyTarget(href) {
    if (href && href !== '') {
      const target = href.replace('#', '');
      const existing = document.getElementById(target);
      if (!existing) {
        const dummyTarget = document.createElement('p');
        dummyTarget.className = 'wcag-visuallyhidden dummy-link';
        dummyTarget.id = target
        dummyTarget.setAttribute('tabindex', '-1')
        this.renderer.appendChild(document.body, dummyTarget);
      }
    }
  }

  private removePrevious() {
    const old = document.querySelectorAll('.dummy-link');
    old.forEach(o => o.remove());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
