<p-dialog
  id="prev-selected"
  [className]="'eccc'"
  [header]="'ACCOUNTS_MODULE.uploadDocument.ci_pathway_report_upload_from_previous' | translate"
  [closable]="false"
  [(visible)]="openUploadPopup"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <div class="container">
    <app-table-view
      [data]="tableDataList"
      [config]="tableConfig"
      [firstItemIndex]="firstItemIndex"
      (paginationChanged)="onPaginationChanged($event)"
      [selections]="selectedRows"
      (checkboxSelected)="getSelections($event)"
    >
    </app-table-view>
  </div>

  <ng-template #accountDocumentActions let-data>
  <ng-container >
    <div>
      <app-document-actions [document]="data" 
      [documentUploadConfig]="documentUploadConfig"
      [appendTo]="''">
      </app-document-actions></div>
    <ng-template #UPLOADING>
      <span>{{ 'COMMON.messageSection.documentUploading' | translate }}</span>
    </ng-template>
  </ng-container>
</ng-template>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button type="button" class="btn btn-secondary" (click)="close()">
          {{'COMMON.actionsLabel.CLOSE' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
          {{'COMMON.actionsLabel.SAVE' | translate}}
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>