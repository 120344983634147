import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from 'util';
import { positiveOrZeroNumber } from '@shared/formly/validators';
import { round } from '@core/utilities/utilities.constants';

@UntilDestroy()
@Component({
  selector: 'formly-key-value-pair',
  template: `
    <div [formGroup]="subFormGroup" class="mb-3">
    <p-table [ngClass]="'eccc'" [value]="dummy">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let header of to.keyValueConfig.headers"
              [innerHTML]="translationPrefix + header | translate"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr *ngFor="let prop of to.keyValueConfig.keys">
          <td>
            <span *ngIf="prop.required" class="wcag-visuallyhidden">Required field, </span>
            <span [innerHTML]="translationPrefix + prop.key | translate"></span>
            <span *ngIf="prop.required" aria-hidden="true">*</span>:
          </td>
          <td>
            <ng-container *ngIf="prop.type === 'number'; else NORMAL">
              <label class="wcag-visuallyhidden" [for]="prop.key">{{ prop.key }}</label>
              <input
                [allowNegativeNumbers]="prop.allowNegativeNumbers"
                [class.is-invalid]="isInvalid(prop.key) || isLessThanZero(prop.key)"
                [formlyAttributes]="field"
                [formControlName]="prop.key"
                maxlength="19"
                [id]="prop.key"
                [mask]="prop.mask || 'separator.0'"
                [required]="prop.required"
                [thousandSeparator]="commaSeparator"
                [ngModel]="_model[prop.key]"
                (ngModelChange)="updateModel($event, prop)"
                class="form-control"
                type="text"
              />
              <div class="invalid-feedback" *ngIf="isInvalid(prop.key); else CHECK_NEGATIVE_VALUE">
                <span [innerHTML]="'COMMON.messageSection.fieldRequiredException' | translate"></span>
              </div>
              <ng-template #CHECK_NEGATIVE_VALUE>
                <div class="invalid-feedback" *ngIf="isLessThanZero(prop.key)">
                  <span [innerHTML]="'COMMON.messageSection.valueCannotBeLessThanZero' | translate"></span>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #NORMAL>
              <label class="wcag-visuallyhidden" [for]="prop.key">{{ prop.key }}</label>
              <input
                class="form-control"
                [formControl]="form[controlId][prop.key]"
                [class.is-invalid]="isInvalid(prop.key)"
                [required]="prop.required"
                [formControlName]="prop.key"
                (ngModelChange)="updateModel($event, prop)"
              />
              <div class="invalid-feedback" *ngIf="isInvalid(prop.key)">
                <span [innerHTML]="'COMMON.messageSection.fieldRequiredException' | translate"></span>
              </div>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-container *ngIf="to.keyValueConfig.summary">
      <ng-template pTemplate="footer">
        <tr>
          <td><span [innerHTML]="translationPrefix + to.keyValueConfig.summary.label | translate"></span></td>
          <td>
            <label class="wcag-visuallyhidden" [for]="'summary_' + to.keyValueConfig.summary.key">{{ 'summary_' + to.keyValueConfig.summary.key }}</label>
            <input
                wcag-label
                [formlyAttributes]="field"
                [disabled]="true"
                [id]="'summary_' + to.keyValueConfig.summary.key"
                [ngModelOptions]="{standalone: true}"
                [mask]="to.keyValueConfig.summary.mask || 'separator.0'"
                [thousandSeparator]="commaSeparator"
                [ngModel]="model[to.keyValueConfig.summary.key]"
                class="form-control"
                type="text"
              />
          </td>
        </tr>
      </ng-template>
      </ng-container>
    </p-table>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormlyKeyValuePairs),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormlyKeyValuePairs),
      multi: true,
    },
  ],
})
// tslint:disable-next-line:component-class-suffix
export class FormlyKeyValuePairs extends FieldType implements OnInit, ControlValueAccessor, Validator {
  dummy = [{}];
  _model = {};
  ready = false;

  public get controlId(): string {
    return this.to.controlId;
  }

  isInvalid(key: string) {
    return this.subFormGroup.controls[key].errors &&
      this.subFormGroup.controls[key].dirty &&
      this.subFormGroup.controls[key].errors.required;
  }

  isLessThanZero(key: string) {
    return this.subFormGroup.controls[key].errors &&
      this.subFormGroup.controls[key].dirty &&
      this.subFormGroup.controls[key].errors.positiveOrZeroNumber;
  }

  constructor(
    private translateSvc: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.addControl(this.controlId, this.subFormGroup);
    if (!this.model[this.controlId]) {
      this.model[this.controlId] = {};
    } else {
      this._model = {...this.model[this.controlId]};
      Object.keys(this._model).forEach(key => this._model[key] = this._model[key].toFixed());
      this._model = {...this.model[this.controlId]};
      this.calculateSummary();
    }
    setTimeout(() => this.subFormGroup.markAsPristine());

    this.subFormGroup.statusChanges.subscribe(() => {
      this.onChange(this.subFormGroup.value);
      this.onTouched();
    });
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value);
    } else {
      this.form.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get subFormGroup(): FormGroup {
    if (this.form.get(this.controlId)) {
      return this.form.get(this.controlId) as FormGroup;
    } else {
      const subForm: FormGroup = new FormGroup({});
      if (this.to.keyValueConfig && this.to.keyValueConfig.keys.length) {
        this.to.keyValueConfig.keys.forEach(prop => {
          const control = new FormControl('');
          if (prop.required) {
            control.setValidators([Validators.required, positiveOrZeroNumber]);
          }
          subForm.addControl(prop.key, control);
        });
      }
      return subForm as FormGroup;
    }
  }

  updateModel(val, prop) {
    this._model[prop.key] = prop.type === 'number' && val ? Number(val) : val;
    Object.keys(this._model).forEach(key => {
      if (isNullOrUndefined(this._model[key])) {
        delete this._model[key];
      }
    });
    this.field.model[this.controlId] = this._model;
    if (this.to.keyValueConfig.summary) {
      this.calculateSummary();
    }
    if (this.to.change) {
      this.to.change(this.field, {
        keys: this._model,
        summary: this.to.keyValueConfig.summary ? this.field.model[this.to.keyValueConfig.summary.key] : null,
      });
    }
  }

  private calculateSummary() {
    this.field.model[this.to.keyValueConfig.summary.key] = round(Object.keys(this._model).reduce((sum, key) => sum + Number(this._model[key]), 0), 5);
  }

  get commaSeparator() {
    return this.translateSvc.currentLang === 'en' ? ',' : ' ';
  }

  get translationPrefix(): string {
    return `${ this.to.translatePrefix }.` || '';
  }

  validate() {
    return this.subFormGroup.valid ? null : { invalid: true };
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.subFormGroup.statusChanges.subscribe(fn);
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};

}
