import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[disableOnUpload]',
})
export class DisableOnUploadDirective implements OnInit, OnDestroy {

  @Input('appRouterLink') routerLink: string;
  sub: any;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private documentUploadInProgress: DocumentUploadInProgressService,
  ) { }

  ngOnInit() {
    this.sub = this.documentUploadInProgress.inProgress$
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe(
        state => this.element.nativeElement.disabled = state,
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
