import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IProjectDiscussionCommentContent } from '@shared/components/project-discussion/service/project-discussion.service.types';

@Component({
  selector: 'app-project-discussion-chat-replies',
  templateUrl: './project-discussion-chat-replies.component.html',
  styleUrls: ['./project-discussion-chat-replies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiscussionChatRepliesComponent implements OnChanges {
  @Input() replies: IProjectDiscussionCommentContent[] = [];

  visibleReplies: IProjectDiscussionCommentContent[];
  collapsedReplies: IProjectDiscussionCommentContent[];
  isCollapsed = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.replies) {
      this.normalizeReplies(this.replies);
    }
  }

  onExpandReplies(event: Event): void {
    event.preventDefault();
    this.isCollapsed = false;
    this.visibleReplies = this.replies;
  }

  private normalizeReplies(replies: IProjectDiscussionCommentContent[]): void {
    const tmp = [...replies];
    this.visibleReplies = tmp.splice(-2, 2);
    this.collapsedReplies = tmp;
  }
}
