<div
  class="wrapper d-flex justify-content-end"
>
  <a
    *ngIf="!updateOnly"
    (click)="onReply($event, entry)"
    href="#"
  >
    {{'PROJECTS_MODULE.DISCUSSION.discussionReply' | translate}}
  </a>
  <a
    *ngIf="canUpdate(entry)"
    (click)="onUpdate($event, entry)"
    class="ml-3"
    href="#"
  >
    {{'PROJECTS_MODULE.DISCUSSION.discussionUpdate' | translate}}
  </a>
</div>
