import { LookupService } from '@core/services/lookup.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AdminManagementService } from '@module/admin/services/admin-management.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { FormService } from '@shared/services/form.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TablenewrowService } from '@shared/services/tablenewrow.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { convertUnixTimeToDate } from '@core/utilities/utilities.constants';
import { ModalService } from '@shared/services/modal.service';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';

const DATE_FORMAT = 'YYYY-MM-DD';
const COMPONENT_ID = 'TABLE_NEW_ROW';

@Component({
  selector: 'app-table-new-row',
  templateUrl: './table-new-row.component.html',
  styleUrls: ['./table-new-row.component.scss'],
})
export class TableNewRowComponent implements OnInit {

  public visible = true;

  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  title: string;
  newEntry = {} ;

  addNewRowForm = new FormGroup({});
  viewOnly = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TableNewRow,
    private dialogRef: MatDialogRef<TableNewRowComponent>,
    private adminService: AdminManagementService,
    private formService: FormService,
    private tablenewrowService: TablenewrowService,
    private lookupService: LookupService,
    private translateService: TranslateService,
    private modalService: ModalService,

  ) {
   }

  ngOnInit() {
    this.form['id'] = COMPONENT_ID;
    if(this.data.editTitle) {
      this.data.editTitle = `${this.data.form.translationPrefix}.${ this.data.editTitle}`;
    }else{
      this.data.editTitle = `ADMIN_MODULE.termsAndConditions.editEntry`;
    }

    if(!this.data.saveBtnLabel) {
      this.data.saveBtnLabel = 'COMMON.labels.save';
    }
    const titleValue = this.data.actionType === TABLE_NEW_ROW_ACTION.NEW ? `ADMIN_MODULE.termsAndConditions.addNew`: this.data.editTitle;
    this.title = this.translateService.instant(titleValue)
    this.viewOnly = this.data.actionType === TABLE_NEW_ROW_ACTION.VIEW;
    this.createForm();
    if(this.viewOnly){
      setTimeout(() => this.addNewRowForm.disable());
    }


   /** if(this.data.validate){
      let payload = {
        id: this.data.defaultValues.id
      };

      this.tablenewrowService.updateEntry(payload,this.data.form.validateService).subscribe(
        data => {
          this.visible = false; // not sure if this is even needed considering the dialog ref will get destroyed
          this.dialogRef.close(true);
        },
        (error: HttpErrorResponse) => {
          this.formService.parseErrorsDirect(this.addNewRowForm, error.error);
        },
      );
    } */
  }

  createForm() {
    const fieldRow: FormlyFieldConfig[] = [];

    this.data.form.columns.forEach(field => {

     if (this.data.actionType === TABLE_NEW_ROW_ACTION.NEW  ) {

        if (field.newRowField) {
          // tslint:disable-next-line: max-line-length

          fieldRow.push( this.createField(field.id,field.fieldType, field.invalidMessage,field.inputType, field.required,
            this.getFormFieldKey(field), field.header, field.defaultValue, field.source, field.placeholder, field.className,field.toolTip,
            {items: field.items, bindValue: field.bindValue, bindLabel: field.bindLabel},
            field.translationPrefix, field.onChange, field.hideExpression, field.readonly, field.customTemplateOptions, field.multiple, field.validators) );
        }
      }

      if (this.data.actionType  === TABLE_NEW_ROW_ACTION.EDIT ||  this.viewOnly) {

        if (field.editRowField) {

          let defaultValue;
          var key = this.getFormFieldKey(field);
          if (field.fieldType === 'app-formly-datepicker') {
            if(this.data.defaultValues[key]){
             defaultValue = new Date(convertUnixTimeToDate(this.data.defaultValues[key]));
            }
          } else {
            defaultValue = this.data.defaultValues[key];
          }

          // tslint:disable-next-line: max-line-length
          fieldRow.push( this.createField(field.id,field.fieldType,field.invalidMessage, field.inputType,  field.required,
            key, field.header, defaultValue, field.source, field.placeholder, field.className, field.toolTip,
            {items: field.items, bindValue: field.bindValue, bindLabel: field.bindLabel},
            field.translationPrefix, field.onChange, field.hideExpression, field.readonly, field.customTemplateOptions, field.multiple, field.validators) );
        }
      }

    });

    this.fields.push(
      {
        fieldGroupClassName: 'row',
        fieldGroup: fieldRow,
      },
    );
  }

  getFormFieldKey(field){
    var fieldKey = field.formField? field.formField: field.field;
    return fieldKey;
  }
  // tslint:disable-next-line: max-line-length
  createField(id?,fieldType?,invalidMessage?, inputType?, required?, key?, label?, defaultValue?, source?,
              placeholder?, className?, toolTip?,{items= {}, bindLabel = '', bindValue = ''} = {},
              translationPrefix?, onChange?, hideExpression?, readonly = false, customTemplateOptions = {},
              multiple = null, validators?) {
    return  {
      className: className ? className: 'col-6',
      type: fieldType,
      key,
      defaultValue,
      hideExpression,
      wrappers: ['form-field'],
      templateOptions: {
        required: required === undefined ? true : required ,
        label:  this.data.form.translationPrefix + '.' + label,
        type: inputType === 'number' ? 'formatted-number' : inputType,
        defaultDate: defaultValue,
        source: source ? typeof this.lookupService[source] === 'function' ? this.lookupService[source]() : this.lookupService[source] : null,
        placeholder,
        readonly,
        translatePrefix: translationPrefix,
        translateOptions: translationPrefix ? true:false,
        ignoreDropdownAppend: true,
        appendTo: 'body',
        rows: 4,
        toolTip: toolTip,
        multiple: multiple,
        change: onChange ? (field, $event) => {
          onChange.callback && $event && this.lookupService[onChange.callback]($event.id);
          if (onChange.clearField && onChange.clearField.length > 0 && (!$event || !$event.initial)) {
            onChange.clearField.forEach(fieldName => {
              field.model[fieldName] = null;
              field.form.controls[fieldName].reset();
            });
          }

          if (onChange.additionalField && onChange.additionalField.length > 0 && !$event.initial) {
            onChange.additionalField.forEach(fieldName => {
              field.model[fieldName.formField] = $event[fieldName.lookupfield];
              field.form.controls[fieldName.formField].patchValue($event[fieldName.lookupfield]);
            });
          }

        } : (field, $event) => {
           if(inputType === 'number'){
               this.validateQuantity(field, invalidMessage);
          }
        },
        keypress: (field, event) => {
          if(inputType === 'number' && isNaN(parseInt(event.key, 10))) {
            event.preventDefault();
          }
        },
        items,
        bindLabel,
        bindValue,
        attributes:{
          'cy-id': (id !== undefined ? id : key )
      },
        dataProvided: !(Object.keys(items).length === 0 && items.constructor === Object),
        customTemplateOptions,
        ...customTemplateOptions
      },
      validators
    } as FormlyFieldConfig;
  }

  //Sahil please move to new save
  saveDeprecated(form: FormGroup) {
    const service = this.data.form.editService;
    this.adminService[service](this.newEntry, this.data.defaultValues).subscribe(
      data => {
        this.visible = false; // not sure if this is even needed considering the dialog ref will get destroyed
        this.dialogRef.close(this.newEntry);
      },
      (error: HttpErrorResponse) => {
        this.formService.parseErrors(form, error.error);
      },
    );
  }

  validateQuantity(field,  message){
    if (isNaN(field.formControl.value) || field.formControl.value % 1 != 0
                        || field.formControl.value < 0) {
                        field.formControl.setErrors({ 'server-side':
                        this.translateService.instant(message) });
                      }
  }

  save(form: FormGroup){
    this.executeAction(form,this.data.form.editService, 'SAVE');
  }

  executeAction(form: FormGroup, service_uri: string, label:string){
    //use update function on the html of table-new-row as well instead of save at the form
    const dataToBeSaved = this.newEntry;
    Object.keys(this.newEntry).forEach(function (key, index) {
      var dateValue = dataToBeSaved[key];
      if (dateValue instanceof Date) {
        let formattedDate = moment(dateValue).format(DATE_FORMAT).toString();
        dataToBeSaved[key] = formattedDate;
      }
    });

    if (this.data.defaultValues) {
      for (const key in this.data.defaultValues) {
        if (!this.newEntry.hasOwnProperty(key)) {
          this.newEntry[key] = this.data.defaultValues[key];
        }
      }
    }

    if(this.data.showWarning) {

      this.modalService.open(ServiceMessageComponent, this.data.showWarning, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe((result?: boolean) => {
        if (result) {
          this.tablenewrowService.updateEntry(this.newEntry,service_uri).subscribe(
            data => {
              this.visible = false; // not sure if this is even needed considering the dialog ref will get destroyed
              this.dialogRef.close(true);
            },
            (error: HttpErrorResponse) => {
              this.formService.parseErrors(form, error.error);
            },
          );
        }
      });
    }
    else {
      this.tablenewrowService.updateEntry(this.newEntry,service_uri).subscribe(
        data => {
          this.visible = false; // not sure if this is even needed considering the dialog ref will get destroyed
          this.dialogRef.close(true);
        },
        (error: HttpErrorResponse) => {
          this.formService.parseErrors(form, error.error);
        },
      );
    }

  }

  cancel() {
    this.visible = false; // not sure if this is even needed considering the dialog ref will get destroyed
    this.dialogRef.close(false);
  }

}
