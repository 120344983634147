import { LOCALES } from '../../app.const';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "@gilsdav/ngx-translate-router";
export class HelperService {
    constructor(translateService, localizeService) {
        this.translateService = translateService;
        this.localizeService = localizeService;
    }
    getTranslatedPath(path) {
        const fragments = path.split('/');
        if (LOCALES.includes(fragments[1])) {
            fragments.splice(1, 1);
        }
        return fragments.map(fragment => fragment === '' ? `/${this.translateService.currentLang}` : this.localizeService.translateRoute(fragment));
    }
    hasTranslation(key) {
        const translation = this.translateService.instant(key);
        return translation !== key && translation !== '';
    }
}
HelperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelperService_Factory() { return new HelperService(i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.LocalizeRouterService)); }, token: HelperService, providedIn: "root" });
