import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-retrieve-username',
  templateUrl: './retrieve-username.component.html',
  styleUrls: ['./retrieve-username.component.scss']
})
export class RetrieveUsernameComponent implements OnInit {

  private _destroy$ = new Subject<any>();
  email: string;
  message: string

  constructor( private authService: AuthService) { }

  ngOnInit() {
  }

  sendRequest(form){
    this.authService.getUserName(this.email).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      () => this.onComplete(form),
      () => this.onComplete(form),
    );
  }

  private onComplete(form): void {
    this.email = null;
    this.message = 'Your username has been send to your email';
    form.resetForm();
  }

}
