import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

const PUBLIC_OFFSET_ENABLED = 'enable.public.offset';

@Injectable({
  providedIn: 'root',
})
export class PublicViewEnabledGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: StoreService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canBeActivated(next);
  }

  private canBeActivated(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.getProperty(PUBLIC_OFFSET_ENABLED)
      .pipe(
        switchMap(prop => of(prop.value && prop.value.toLowerCase() === 'true')),
        tap(value => !value && this.router.navigate([''])),
      );
  }

}
