import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { interval, Observable, Subject } from 'rxjs';
import { share, switchMap, takeUntil } from 'rxjs/operators';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./document-in-progress.service";
import * as i3 from "@angular/common";
import * as i4 from "./modal.service";
import * as i5 from "@ngx-translate/core";
export const GET_ALL = '/account-service/document/getDocuments';
export const DOCUMENT_BASE = '/account-service/document';
export const DOCUMENT_DOWNLOAD = '/account-service/document';
export const DOCUMENT_UPLOAD_STATUS = '/account-service/document/uploadStatus';
export const REPORT_58_DOWNLOAD = '/obps-service/remittanceAction/downloadSection58Pdf';
export const REGISTRATION_REPORT = '/account-service/cfrAccount/registration/download';
export const DOWNLOAD_REFUND_REQUEST_PREVIEW = '/obps-service/payment-refund/downloadPaymentRefundSubmittedForm/';
export const DOCUMENT_LATEST_DOWNLOAD = '/account-service/document/find';
export const GET_DOCUMENTS_FOR_ACCOUNT_APPROVAL = '/account-service/document/getDocumentsForAccountApproval/';
export const UPDATE_VISIBILITY = '/updateVisibility';
const CHECK_DOCUMENT_INTERVAL = 5000;
export class UploadDocumentService extends AbstractPaginationService {
    constructor(http, documentUploadInProgress, location, modalService, translateService) {
        super();
        this.http = http;
        this.documentUploadInProgress = documentUploadInProgress;
        this.location = location;
        this.modalService = modalService;
        this.translateService = translateService;
        this.documentUploadInProgress.init();
    }
    upload(uri, formData, baseUrl = environment.serviceUrl) {
        return this.http.post(`${baseUrl}${uri}`, formData);
    }
    getDocumentsForAccountApproval(accountId, all) {
        //   return this.http.get<IApp.PendingAccountDocuments>(`http://localhost:8084/api${ GET_DOCUMENTS_FOR_ACCOUNT_APPROVAL }${accountId}/${all}`);
        return this.http.get(`${environment.apiUrl}${GET_DOCUMENTS_FOR_ACCOUNT_APPROVAL}${accountId}/${all}`);
    }
    getDocumentsByEntityIdAndType(entityId, entityType, documentTypeCode = null) {
        const pagination = {
            page: 0,
            size: ROWS_PER_PAGE,
        };
        return this.getAll(pagination, entityId, entityType, documentTypeCode, GET_ALL, null);
    }
    getAll(pagination, entityId, entityType, documentTypeCode = null, uri = GET_ALL, metaData = null, documentTypeCodes = null, supportingDocumentFilter = [], entityVersionId = 0) {
        let params = new HttpParams();
        !pagination.sort && (pagination.sort = 'timestamp');
        !pagination.dir && (pagination.dir = 'DESC');
        params = this.addPagination(params, pagination);
        const filterState = {};
        filterState.id = entityId;
        filterState.entityName = entityType;
        filterState.documentTypeCode = documentTypeCode;
        filterState.documentTypeCodes = documentTypeCodes;
        if (this.location.getState()['viewEntityHistory']) {
            filterState.entityVersionId = entityVersionId;
        }
        if (metaData) {
            filterState.metaData = metaData;
        }
        if (!uri) {
            uri = GET_ALL;
        }
        if (supportingDocumentFilter && supportingDocumentFilter.length) {
            filterState.supportingDocumentFilter = supportingDocumentFilter;
        }
        return this.http.post(`${environment.apiUrl}${uri}`, filterState, { params });
    }
    getFileNameFromHeaderOrDefult(response) {
        let filename = 'file';
        const disposition = response.headers.get('content-disposition');
        if (disposition) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }
    downloadDocument(uri, fileName = null) {
        this.http.get(uri, { observe: 'response', responseType: 'blob' }).subscribe((response) => {
            const dataType = response.body.type;
            const binaryData = [];
            const filename = fileName ? fileName : this.getFileNameFromHeaderOrDefult(response);
            binaryData.push(response.body);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            if (filename) {
                downloadLink.setAttribute('download', filename);
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();
            downloadLink.remove();
        }, () => {
            // repeat request to get JSON response
            this.http.get(uri, { observe: 'response' }).subscribe();
        });
    }
    viewDocument(uri, fileName) {
        this.http.get(uri, { observe: 'response', responseType: 'blob' }).subscribe((response) => {
            const dataType = 'application/pdf';
            const binaryData = [];
            binaryData.push(response.body);
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
            downloadLink.target = '_blank';
            // window.open(downloadLink.href, "_blank");
            downloadLink.click();
        }, () => {
            // repeat request to get JSON response
            this.http.get(uri, { observe: 'response' }).subscribe();
        });
    }
    downloadRequestJsonPdf(id, name) {
        const uri = `${environment.serviceUrl}${REPORT_58_DOWNLOAD}/${id}`;
        this.downloadDocument(uri, `${name}.pdf`);
    }
    downloadRegistrationReport(path, name) {
        const uri = `${environment.serviceUrl}${REGISTRATION_REPORT}/${path}`;
        this.downloadDocument(uri, `${name}.pdf`);
    }
    download(id) {
        const uri = `${environment.serviceUrl}${DOCUMENT_DOWNLOAD}/${id}`;
        this.downloadDocument(uri, null);
    }
    downloadLatestDocument(code) {
        const uri = `${environment.serviceUrl}${DOCUMENT_LATEST_DOWNLOAD}/${code}`;
        this.downloadDocument(uri, null);
    }
    downloadRefundReuest(id) {
        this.downloadDocument(`${environment.serviceUrl}${DOWNLOAD_REFUND_REQUEST_PREVIEW}${id}`, undefined);
    }
    viewRefundReuestById(id) {
        this.viewDocument(`${environment.serviceUrl}${DOWNLOAD_REFUND_REQUEST_PREVIEW}${id}?isId=true`, undefined);
    }
    viewRefundRequest(id) {
        this.viewDocument(`${environment.serviceUrl}${DOWNLOAD_REFUND_REQUEST_PREVIEW}${id}`, undefined);
    }
    print(id, name) {
        const uri = `${environment.serviceUrl}${REPORT_58_DOWNLOAD}/${id}`;
        this.printDocument(uri, name);
    }
    updateVisibility(id, isPublic) {
        const uri = `${environment.apiUrl}${DOCUMENT_BASE}${UPDATE_VISIBILITY}?quiet=1`;
        const payload = { id, isPublic };
        return this.http.post(uri, payload);
    }
    printDocument(uri, name) {
        this.http.get(uri, { observe: 'response', responseType: 'blob' }).subscribe((response) => {
            const dataType = response.body.type;
            const binaryData = [];
            const filename = name + '.pdf';
            binaryData.push(response.body);
            const blob = new Blob(binaryData, { type: 'application/pdf' });
            const blobUrl = window.URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.setAttribute('src', blobUrl);
            iframe.onload = () => {
                setTimeout(() => {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1000);
            };
            document.body.appendChild(iframe);
        });
    }
    checkUploadStatus(id, signedUrl) {
        const uri = signedUrl ? signedUrl : `${environment.apiUrl}${DOCUMENT_UPLOAD_STATUS}/${id}`;
        this.documentUploadInProgress.set(uri, id);
        return new Observable(observer => {
            const ready = new Subject();
            interval(CHECK_DOCUMENT_INTERVAL).pipe(takeUntil(ready), switchMap(() => this.http.get(uri, { responseType: 'xml' })), share()).subscribe((response) => {
                let status = '';
                if (response.indexOf('CLEAN') > -1) {
                    status = 'CLEAN';
                }
                else if (response.indexOf('INFECTED') > -1) {
                    status = 'INFECTED';
                }
                if ((['INFECTED', 'CLEAN'].includes(status))) {
                    this.documentUploadInProgress.unset(uri);
                    ready.next();
                    observer.next({
                        id,
                        status,
                    });
                    observer.complete();
                }
            }, (error) => {
                let status = '';
                if (error.error && error.error.indexOf('DeleteMarker') > -1) {
                    status = 'DELETED';
                }
                if ((['DELETED'].includes(status))) {
                    this.documentUploadInProgress.unset(uri);
                    ready.next();
                    observer.next({
                        id,
                        status,
                    });
                    observer.complete();
                }
            });
        });
    }
    checkMandatoryDocuments(config, documents, optionalDocsWithPlaceholderCodes = []) {
        if (config) {
            const requiredDocIds = config.documentUploadConfigs.map(d => d.documentTypeCode).filter(code => !optionalDocsWithPlaceholderCodes.includes(code));
            const numOfRequiredDocs = config.noOfRequiredDocs || requiredDocIds.length;
            const missingDocs = documents.filter(doc => requiredDocIds.includes(doc.type) && doc._placeholder);
            const diffMissingDocs = requiredDocIds.length - missingDocs.length;
            if (diffMissingDocs < numOfRequiredDocs) {
                this.requiredDocsNotUploaded(missingDocs);
            }
            return diffMissingDocs >= numOfRequiredDocs;
        }
        else {
            return true;
        }
    }
    requiredDocsNotUploaded(missingDocs) {
        let missingDocMsg = '';
        missingDocs.forEach(doc => missingDocMsg += `<li>${this.translateService.instant('COMMON.documentList.' + doc.type)}</li>`);
        const metaData = [`<ol type="a">${missingDocMsg}</ol>`];
        this.modalService.open(ServiceMessageComponent, {
            messages: [
                {
                    message: 'mandatoryInformationRequiredForUploadDocs',
                    metaData,
                },
            ],
            type: "ERROR" /* ERROR */,
        }, true, DEFAULT_DIALOG_CONFIG);
    }
}
UploadDocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadDocumentService_Factory() { return new UploadDocumentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DocumentUploadInProgressService), i0.ɵɵinject(i3.Location), i0.ɵɵinject(i4.ModalService), i0.ɵɵinject(i5.TranslateService)); }, token: UploadDocumentService, providedIn: "root" });
