import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { APPROVE_CANCELLATION, APPROVE_CANCELLATION2, CfrRegistrationService, LOAD_CANCELLATION,
REJECT_CANCELLATION,  REJECT_CANCELLATION2, SUBMIT_REGISTRATION_CANCELLATION } from '@module/cfr-registration/services/cfr-registration.service';
import { PERMISSION_APPROVE_CFR_ACCOUNT, PERMISSION_ACCOUNT_DUAL_CONTROL_APPROVAL } from '@module/submission-management/components/cancel-application/cancel-application.component';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ACCEPT_CANCELLATION,
  ACCEPT_CANCELLATION_VB,
  CARBON_INTENSITY_CONTRIBUTOR,
  FOREIGN_SUPPLIER,
  PRIMARY_SUPPLIER,
  REGISTERED_CREATOR,
  REQUEST_REGISTRATION_CANCELLATION_REMARKS
} from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { getLabel, getTable } from '@core/utilities/raw-form.utils';
import { LookupService } from '@core/services/lookup.service';
import { UnitManagementService } from '@module/unit-management/services/unit-management.service';
import { NavigationService } from '@shared/services/navigation.service';
import { PERMISSION_ADD_REMARKS,PERMISSION_DISCLOSE_REMARKS,PERMISSION_VIEW_DISCLOSE_REMARKS } from 'app/app.const';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { TASK_STATE } from '@module/unit-management/unit-management.const';

const TABLE_NAME = 'UNIT_PENDING_REGISTRATION_CANCELLATION';

interface CancellationRequestModel {
  accountSubTypes: string;
  orgRoles: string;
  registrationAccountStatus: string; //TODO: wanna switch to enum
  accountSubTypeDtoList: {code: IApp.NSAccountSubType.StatusEnum}[];
  primarySupplier: boolean;
  registeredCreator: boolean;
  foreignSupplier: boolean;
  carbonIntensityContributor: boolean;
  discloseInfo: any; // Can only assume it's boolean
  reviewUnit: any;
  legalName: any;
  acceptCancellation: boolean;
  accountId: number;
}

@Component({
  selector: 'app-req-reg-cancellation',
  templateUrl: './req-reg-cancellation.component.html',
  styleUrls: ['./req-reg-cancellation.component.scss'],
})
export class ReqRegCancellationComponent implements OnInit {

  @ViewChild('viewColTemplate', {static: true}) public viewColTemplate: TemplateRef<any>;
  @ViewChild('unitsTableFooterTemplate', {static: true}) public unitsTableFooterTemplate: TemplateRef<any>;

  private _destroy$ = new Subject<any>();
  formGroup = new FormGroup({});
  model: CancellationRequestModel = {} as any;
  fields: FormlyFieldConfig[];
  isVb: boolean;
  tableConfig: ITableViewConfig;
  units: any;
  state: any;

  apiURL = new Map([
    ['SUBMIT', SUBMIT_REGISTRATION_CANCELLATION ],
    ['APPROVE', APPROVE_CANCELLATION],
    ['REJECT', REJECT_CANCELLATION],
    ['APPROVE2', APPROVE_CANCELLATION2],
    ['REJECT2', REJECT_CANCELLATION2],
    ['LOAD', LOAD_CANCELLATION ],
  ]);

  type: string;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private store: StoreService,
    public translate: TranslateService,
    private modalService: ModalService,
    private helperService: HelperService,
    private formFactoryService: FormFactoryService,
    public cfrRegistrationService: CfrRegistrationService,
    private lookupService: LookupService,
    private unitManagementService: UnitManagementService,
  ) { }

  ngOnInit() {
    this.state = window.history.state && window.history.state.data ? window.history.state : this.storedTaskState;
    if (this.state.data) {
      this.model = this.state.data.rawData;
      this.type = this.state.data.actionType;
      this.isVb = this.model.accountSubTypes === 'VB';

      if ( this.isViewType() ) {
        this.registrationCancellation('LOAD');
      } else {
        this.createForm(this.setGlobalFields());
        setTimeout(() => this.formGroup.enable());
      }
    } else {
      this.close();
    }
  }

  get storedTaskState(): any {
    const content = sessionStorage.getItem(TASK_STATE);
    sessionStorage.removeItem(TASK_STATE);
    return content ? JSON.parse(content) : null;
  }

  isViewType() {
    return this.type === 'VIEW_CANCELLATION' ||
    this.type === 'APPROVE_CANCELLATION' ||
    this.type === 'REJECT_CANCELLATION' ||
    this.type === 'APPROVE_CANCELLATION2' ||
    this.type === 'REJECT_CANCELLATION2';
  }

  showApproveReject() {
    return this.model.registrationAccountStatus === 'PENDING_REGISTRATION_CANCELLATION'
    && this.store.user.hasPermission(PERMISSION_APPROVE_CFR_ACCOUNT) ;
  }

  showApproveReject2() {
    return this.model.registrationAccountStatus === 'PENDING_REGISTRATION_CANCELLATION2'
    && this.store.user.hasPermission(PERMISSION_ACCOUNT_DUAL_CONTROL_APPROVAL) ;
  }

  createForm(fields) {
    this.fields = this.formFactoryService.configureForm(
      [
        {
            groupFields: false,
            fieldGroup: fields,
        },
      ],
    );
  }

  setGlobalFields() {

    const fields = [];

    type StatusWithoutFB = Exclude<IApp.NSAccountSubType.StatusEnum, IApp.NSAccountSubType.StatusEnum.VB>;
    const roleMap: {[key in StatusWithoutFB]: {present: boolean, selected: boolean}} = {
      [IApp.NSAccountSubType.StatusEnum.PS] : {present: false, selected: false},
      [IApp.NSAccountSubType.StatusEnum.RC] : {present: false, selected: false},
      [IApp.NSAccountSubType.StatusEnum.FS] : {present: false, selected: false},
      [IApp.NSAccountSubType.StatusEnum.CIC] : {present: false, selected: false},
    };

    if (this.model.accountSubTypes) {
      this.model.accountSubTypes.split(',').forEach(element => {
        if (roleMap[element]) {
          roleMap[element] = {present: true, selected: false};
        }
      });
    }

    if (this.model.accountSubTypeDtoList) {
      this.model.orgRoles = '';
      this.model.accountSubTypeDtoList.forEach(element => {
        if (roleMap[element.code]) {
          roleMap[element.code] = {present: true, selected: true};
          this.appendOrgRoleCon(element.code);
        }
      });
    }

    if (roleMap[ IApp.NSAccountSubType.StatusEnum.PS].present) {
      fields.push(PRIMARY_SUPPLIER);
      this.model.primarySupplier = roleMap[ IApp.NSAccountSubType.StatusEnum.PS].selected;
    }

    if (roleMap[ IApp.NSAccountSubType.StatusEnum.RC].present) {
      fields.push(REGISTERED_CREATOR);
      this.model.registeredCreator = roleMap[ IApp.NSAccountSubType.StatusEnum.RC].selected;
    }

    if (roleMap[ IApp.NSAccountSubType.StatusEnum.FS].present) {
      fields.push(FOREIGN_SUPPLIER);
      this.model.foreignSupplier = roleMap[ IApp.NSAccountSubType.StatusEnum.FS].selected;
    }

    if (roleMap[ IApp.NSAccountSubType.StatusEnum.CIC].present) {
      fields.push(CARBON_INTENSITY_CONTRIBUTOR);
      this.model.carbonIntensityContributor = roleMap[ IApp.NSAccountSubType.StatusEnum.CIC].selected;
    }

    if(this.isVb){
      fields.push(ACCEPT_CANCELLATION_VB);
    }else{
      fields.push(ACCEPT_CANCELLATION);
    }
    return fields;
  }

  setAdminFields(fields, showUnitsTable = false) {
    fields.forEach(field => {
      field['disabled'] = true;
    });

    if (showUnitsTable) {
      fields.push(
        ...[
          getLabel({
            label: 'ACCOUNTS_MODULE.reqRegCancellation.unitsTableDesc',
            className: 'col-12',
          }),
          getTable({
            id: '_dummy',
            className: 'col-12 mb-3',
            customTemplateOptions: {
              config: this.tableConfig,
              initialData: this.units.content,
              footerTemplate: this.unitsTableFooterTemplate,
              updateData: new Subject<any[]>(),
            },
          }),
      ]);
    }

    // admin remark
    if(this.store.user.hasPermission(PERMISSION_ADD_REMARKS)){
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].disabled = false;
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].hideExpression = false;
    }else if(this.store.user.hasPermission(PERMISSION_VIEW_DISCLOSE_REMARKS) && this.model.discloseInfo){
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].disabled = true;
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].hideExpression = false;
    }else{
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[0].hideExpression = true;
    }


    // disclose
    if(this.store.user.hasPermission(PERMISSION_DISCLOSE_REMARKS)){
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[1].disabled = false;
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[1].hideExpression = false;
    }else {
      REQUEST_REGISTRATION_CANCELLATION_REMARKS[1].hideExpression = true;
    }

    REQUEST_REGISTRATION_CANCELLATION_REMARKS.forEach(field => {
      fields.push(field);
    });
    return fields;
  }

  registrationCancellation(type) {
    if (type === 'SUBMIT') {
      // vanilla can be enhanced
      this.buildOrgRole();
      this.doAction(type);
    } else if (type === 'LOAD') {

      this.lookupService.getTableConfigurations(TABLE_NAME)
        .pipe(
          takeUntil(this._destroy$),
          tap(config => this.setTableConfiguration(config)),
          switchMap(() => this.cfrRegistrationService.registrationCancellation(this.model, this.apiURL.get(type))),
        ).subscribe(data => {
          this.model = data;
          this.model.acceptCancellation = true;
          if (this.model.reviewUnit) {
            this.cfrRegistrationService.getPendingRegistrationCancellationUnits(this.model.accountId)
              .pipe(
                takeUntil(this._destroy$),
                tap(units => this.units = units),
              )
              .subscribe(units => {
                const unitsAvailable = units.content.length;
                this.createForm(this.setAdminFields(this.setGlobalFields(), unitsAvailable));
              });
          } else {
            this.createForm(this.setAdminFields(this.setGlobalFields()));
          }
      });
    } else {
        this.doAction(type);

  }

  }

  setTableConfiguration(tableConfig: ITableViewConfig): void {
    this.tableConfig = tableConfig;
    const { columns, ...config } = this.tableConfig;

    const _columns: any = [
       ...this.tableConfig.columns,
       {
        header: 'view',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.viewColTemplate,
      },
    ];

    this.tableConfig = {
      ...config,
      paginator: false,
      ...{ columns: _columns },
    };
  }

  isValidForSubmitting() {
    const roleIsSelected = this.model.primarySupplier ||
      this.model.registeredCreator ||
      this.model.foreignSupplier ||
      this.model.carbonIntensityContributor ||
      this.isVb;
    return this.model.acceptCancellation && roleIsSelected;
  }

  doAction(type) {
    this.formFactoryService.markFormGroupDirty(this.formGroup);
    if (this.formGroup.valid) {
      this.modalService
      .open(
        ServiceMessageComponent,
        {
          message: this._getConfirmationMessage(type),
          metaDataList: [
            this.translate.instant(`COMMON.actionsLabel.${type}`),
            this.model.orgRoles,
            this.model.legalName],
          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.cfrRegistrationService.registrationCancellation(this.model, this.apiURL.get(type)).pipe(
            takeUntil(this._destroy$),
          ).subscribe(data => {
            this.router.navigate(this.helperService.getTranslatedPath(`/account-management/summary-admin/${this.model.accountId}`));
          });
        }
      });
    }
  }

  buildOrgRole() {
    this.model.accountSubTypeDtoList = [];
    this.model.orgRoles ='';
    if (this.model.primarySupplier ) {
      this.appendOrgRole( IApp.NSAccountSubType.StatusEnum.PS);
    }
    if (this.model.registeredCreator) {
      this.appendOrgRole( IApp.NSAccountSubType.StatusEnum.RC);
    }
    if (this.model.foreignSupplier ) {
      this.appendOrgRole( IApp.NSAccountSubType.StatusEnum.FS);
    }
    if (this.model.carbonIntensityContributor ) {
      this.appendOrgRole( IApp.NSAccountSubType.StatusEnum.CIC);
    }
  }

  appendOrgRoleCon(type: IApp.NSAccountSubType.StatusEnum) {
    if ( this.model.orgRoles.length === 0 ) {
      this.model.orgRoles =  this.translate.instant(`COMMON.accountSubTypeList.${type}`);
      } else {
      this.model.orgRoles = this.model.orgRoles + ',' + this.translate.instant(`COMMON.accountSubTypeList.${type}`);
    }
  }

  appendOrgRole(type: IApp.NSAccountSubType.StatusEnum) {
    if ( this.model.accountSubTypeDtoList.length === 0 ) {
      this.model.accountSubTypeDtoList = [{
        code: type,
      }];
    } else {
     this.model.accountSubTypeDtoList.push({
        code: type,
      });
    }
    this.appendOrgRoleCon(type);
  }

  viewUnitDetails(event, unit: IApp.IUnitData) {
    if (event.key && event.key !== 'Enter') {
      return;
    }
    sessionStorage.setItem(TASK_STATE, JSON.stringify(this.state));
    this.unitManagementService.viewUnitDetails(unit.unitClassCode , unit, false, 'ACTIVE');
   }

  close() {
    this.navigationService.back();
  }

  private _getConfirmationMessage(action) {
    if (action === 'REJECT') {
      return 'confirmationMessageRejectRegistrationCancellation';
    }

    return this.isVb ? 'confirmationMessageRegistrationCancellationVb' :
      'confirmationMessageRegistrationCancellation';
  }

}
