import { environment } from '@env/environment';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IFacility, IRegistrationSteps, IOkResponse, IFacilityRequest, IDocument, IAccountData } from '@module/registration/registration.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../core/services/lookup.service";
export const ACCOUNT = '/account-service/account';
export const DOCUMENT = '/account-service/document';
export const DOCUMENT_DOWNLOAD = '/account-service/document';
export const DICT_JURISDICTIONS_URI = '/account-service/lookup/jurisdictions';
export const SUBMIT_OBPS_FACILITY_ACCOUNT = '/submitObpsFacilityAccount';
export const SUBMIT_KYC = '/submitKyc';
export const SUBMIT_NON_OBPS_FACILITY_ACCOUNT = '/submitObpsNonFacilityAccount';
export const SAVE_NON_OBPS_FACILITY_ACCOUNT = '/saveObpsNonFacilityAccount';
export const SUBMIT_OMP_ACCOUNT = '/account-service/ompAccount/submit';
export const SAVE_OMP_ACCOUNT = '/account-service/ompAccount/save';
export const CERTIFICATE_NOS = '/searchByCertificateNos';
export const PROPERTY = '/account-service/property/get?key=';
export const TRANSLATION_PREFIX = 'REGISTRATION_PAGE.stepperSection.';
export const ACCOUNT_SUB_TYPE = '/account-service/lookup/accountSubTypes';
export const REGISTRATION_TYPE = '/account-service/lookup/registrationTypes';
export const SEARCH_ACCOUNT_BY_TOKEN = '/account-service/account/searchByAccessToken';
export const SUBMIT_CFR_ACCOUNT = '/account-service/account/submitCFRAccount';
export const CFR_ACCOUNT_DETAILS = '/account-service/cfrAccount/details';
export const SAVE_OFFSET_ACCOUNT = '/account-service/offsetAccount/save';
export const OFFSET_ACCOUNT_DETAIL = '/account-service/offsetAccount/loadById/';
export const CANCEL_ACCOUNT_ACCOUNT = '/account-service/offsetAccount/cancel';
export const KEY_OTHER_FUEL_CHAR_LENGTH = 'registration.report.other.fuel.char.length';
export class RegistrationService {
    constructor(http, translateService, lookupService) {
        this.http = http;
        this.translateService = translateService;
        this.lookupService = lookupService;
        this.registrationType = new Subject();
        this._files$ = new BehaviorSubject([]);
        this.files$ = this._files$.asObservable();
        this.prepareSteps();
    }
    prepareSteps() {
        this.registrationSteps = {
            default: [
                {
                    id: 1,
                    name: TRANSLATION_PREFIX + 'programType',
                    completed: false,
                    hidden: true,
                },
                {
                    id: 2,
                    name: TRANSLATION_PREFIX + 'intendedUse',
                    completed: false,
                },
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'selectAccountType',
                    completed: false,
                },
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'registrationSubmitted',
                    completed: false,
                    preventNavigation: true,
                },
            ],
            obps: [
                {
                    id: 1,
                    name: TRANSLATION_PREFIX + 'programType',
                    completed: false,
                    hidden: true,
                },
                {
                    id: 2,
                    name: TRANSLATION_PREFIX + 'intendedUse',
                    completed: false,
                },
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'enterFacilityInformation',
                    completed: false,
                },
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'registrationSubmitted',
                    completed: false,
                    preventNavigation: true,
                },
            ],
            cm: [
                {
                    id: 1,
                    name: TRANSLATION_PREFIX + 'programType',
                    completed: false,
                    hidden: true,
                },
                {
                    id: 2,
                    name: TRANSLATION_PREFIX + 'intendedUse',
                    completed: false,
                },
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'selectAccountTypeAndPurpose',
                    completed: false,
                },
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
                    completed: false,
                },
                {
                    id: 5,
                    name: TRANSLATION_PREFIX + 'registrationSubmitted',
                    completed: false,
                    preventNavigation: true,
                },
            ],
            offset: [
                {
                    id: 1,
                    name: TRANSLATION_PREFIX + 'programType',
                    completed: false,
                    hidden: true,
                },
                {
                    id: 2,
                    name: TRANSLATION_PREFIX + 'intendedUse',
                    completed: false,
                },
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
                    completed: false,
                },
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'registrationSubmitted',
                    completed: false,
                    preventNavigation: true,
                },
            ]
        };
        this.newAccountSteps = {
            cm: [
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'selectAccountTypeAndPurpose',
                    completed: false,
                },
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'enterFacilityInformation',
                    completed: false,
                },
                {
                    id: 5,
                    name: TRANSLATION_PREFIX + 'complete',
                    completed: false,
                },
            ],
            cmHiddenSteps: [
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'enterFacilityInformation',
                    completed: false,
                },
            ],
            offset: [
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
                    completed: false,
                },
                {
                    id: 4,
                    name: TRANSLATION_PREFIX + 'complete',
                    completed: false,
                },
            ],
            offsetHiddenSteps: [
                {
                    id: 3,
                    name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
                    completed: false,
                }
            ]
        };
    }
    getFiles() {
        return this.files$;
    }
    set files(files) {
        this._files$.next(files);
    }
    upload(file) {
        const formData = new FormData();
        const url = `${environment.apiUrl}${DOCUMENT}`;
        //const mData = JSON.stringify({comments: 'abc'});
        formData.append('file', file);
        // formData.append('document', mData);
        return this.http.post(url, formData);
    }
    checkFacility(facilityRequest) {
        const url = `${environment.apiUrl}${ACCOUNT}${CERTIFICATE_NOS}`;
        return this.http.post(url, facilityRequest);
    }
    submitObpsFacilityAccount(payload) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT}${SUBMIT_OBPS_FACILITY_ACCOUNT}`, payload).pipe(tap(response => {
        }));
    }
    submitCfrAccount(payload) {
        payload.accountType = { id: 2 }; //PARTICIPANT
        return this.http.post(`${environment.apiUrl}${SUBMIT_CFR_ACCOUNT}`, payload).pipe(tap(response => {
        }));
    }
    submitVBRegistration(payload) {
        payload.subType = 'VB';
        payload.accountType = { id: 5 }; //VB
        return this.http.post(`${environment.apiUrl}${SUBMIT_CFR_ACCOUNT}`, payload).pipe(tap(response => {
        }));
    }
    submitKyc(payload) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT}${SUBMIT_KYC}`, payload).pipe(tap(response => {
        }));
    }
    submitObpsNonFacilityAccount(payload) {
        payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
        return this.http.post(`${environment.apiUrl}${ACCOUNT}${SUBMIT_NON_OBPS_FACILITY_ACCOUNT}`, payload);
    }
    submitOMPAccount(payload, uri) {
        payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
        return this.http.post(`${environment.apiUrl}${uri ? uri : SUBMIT_OMP_ACCOUNT}`, payload);
    }
    saveOMPAccount(payload) {
        return this.http.post(`${environment.apiUrl}${SAVE_OMP_ACCOUNT}`, payload);
    }
    saveObpsNonFacilityAccount(payload) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT}${SAVE_NON_OBPS_FACILITY_ACCOUNT}`, payload).pipe(tap(response => {
        }));
    }
    getJurisdictionsDict() {
        return this.http.get(`${environment.apiUrl}${DICT_JURISDICTIONS_URI}`);
    }
    getBusinessStructure() {
        return this.lookupService.getBusinessStructure();
    }
    handleErrors(errors) {
        const errorResponse = [];
        return errors.messages.map(err => {
            const errMsg = this.translateService.instant(`COMMON.messageSection.${err}`);
            errorResponse.push(errMsg);
            return errorResponse;
        });
    }
    getAccountSubtypes() {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_SUB_TYPE}`);
    }
    getAccountSubtypeById(id) {
        return this.http.get(`${environment.apiUrl}${ACCOUNT_SUB_TYPE}/${id}`);
    }
    getRegistrationType() {
        return this.http.get(`${environment.apiUrl}${REGISTRATION_TYPE}`);
    }
    getFacilityByToken(token) {
        return this.http.get(`${environment.apiUrl}${SEARCH_ACCOUNT_BY_TOKEN}/${token}`);
    }
    getCFRAccountDetails(token) {
        return this.http.get(`${environment.apiUrl}${CFR_ACCOUNT_DETAILS}/${token}`);
    }
    log(message) {
    }
    submitOffsetAccount(payload, uri) {
        payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
        return this.http.post(`${environment.apiUrl}${uri}`, payload);
    }
    saveOffsetAccount(payload) {
        payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
        return this.http.post(`${environment.apiUrl}${SAVE_OFFSET_ACCOUNT}`, payload);
    }
    getOffsetAccountById(id) {
        return this.http.get(`${environment.apiUrl}${OFFSET_ACCOUNT_DETAIL}${id}`);
    }
    cancelOffsetRegistration(payload) {
        return this.http.post(`${environment.apiUrl}${CANCEL_ACCOUNT_ACCOUNT}`, payload);
    }
    getProperty(propertyName) {
        return this.http.get(`${environment.apiUrl}${PROPERTY}${propertyName}`);
    }
    getConfigProperty$(key) {
        const result = sessionStorage.getItem(key);
        if (result) {
            return of(JSON.parse(result));
        }
        else {
            return this.getProperty(key).pipe(tap(data => this.setConfigProperty(key, data)));
        }
    }
    setConfigProperty(key, value) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
    resetConfigProperty(key) {
        sessionStorage.removeItem(key);
    }
}
RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TranslateService), i0.ɵɵinject(i3.LookupService)); }, token: RegistrationService, providedIn: "root" });
