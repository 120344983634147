/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-formly/core";
import * as i4 from "./chips.type.component";
import * as i5 from "@ngx-translate/core";
var styles_FormlyFieldChips = [];
var RenderType_FormlyFieldChips = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldChips, data: {} });
export { RenderType_FormlyFieldChips as RenderType_FormlyFieldChips };
function View_FormlyFieldChips_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "badge badge-primary badge-tag simple-tag"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close / Delete"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }); }
export function View_FormlyFieldChips_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["class", "wcag-visuallyhidden"], ["type", "hidden"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "mb-2"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FormlyFieldChips_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "input-group mb-3 col-6"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 6, "input", [["class", "form-control"], ["type", "text"]], [[2, "ng-invalid", null], [8, "placeholder", 0], [1, "maxLength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "focus"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 8)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 8)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (i0.ɵnov(_v, 13).onFocus($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 13).onBlur($event) !== false);
        ad = (pd_5 && ad);
    } if (("change" === en)) {
        var pd_6 = (i0.ɵnov(_v, 13).onChange($event) !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.newItem = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(10, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(12, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i0.ɵdid(13, 933888, null, 0, i3.FormlyAttributes, [i0.Renderer2, i0.ElementRef, i1.DOCUMENT], { field: [0, "field"] }, null), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "button", [["aria-label", "Add item"], ["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(16, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.list; _ck(_v, 4, 0, currVal_2); var currVal_13 = _co.newItem; _ck(_v, 10, 0, currVal_13); var currVal_14 = _co.field; _ck(_v, 13, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.elementId + "_chips"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.elementId; _ck(_v, 1, 0, currVal_1); var currVal_3 = _co.formControl.invalid; var currVal_4 = _co.placeholder; var currVal_5 = (_co.maxLength ? _co.maxLength : null); var currVal_6 = i0.ɵnov(_v, 12).ngClassUntouched; var currVal_7 = i0.ɵnov(_v, 12).ngClassTouched; var currVal_8 = i0.ɵnov(_v, 12).ngClassPristine; var currVal_9 = i0.ɵnov(_v, 12).ngClassDirty; var currVal_10 = i0.ɵnov(_v, 12).ngClassValid; var currVal_11 = i0.ɵnov(_v, 12).ngClassInvalid; var currVal_12 = i0.ɵnov(_v, 12).ngClassPending; _ck(_v, 7, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_15 = _co.buttonText; _ck(_v, 16, 0, currVal_15); }); }
export function View_FormlyFieldChips_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-field-chips", [], null, null, null, View_FormlyFieldChips_0, RenderType_FormlyFieldChips)), i0.ɵdid(1, 114688, null, 0, i4.FormlyFieldChips, [i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldChipsNgFactory = i0.ɵccf("formly-field-chips", i4.FormlyFieldChips, View_FormlyFieldChips_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldChipsNgFactory as FormlyFieldChipsNgFactory };
