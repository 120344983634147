declare var require: any;
import { isNull, mergeWith} from 'lodash';
var moment = require('moment-timezone');

export const convertUnixTimeToDate = (unixTimeStamp: string): Date => {
    return moment(unixTimeStamp).tz('UTC').format('DD MMMM YYYY')
}

export const convertToDDMMYYYY = (date: string):Date => {
    return moment(date).format('DD-MM-YYYY')
}

export const convertToYYYYMMDD = (date: any):Date => {
    return moment(date).format('YYYY-MM-DD')
}

export const date2str = (date: Date, format = 'YYYY-MM-DD'): string => {
  return moment(date).tz('UTC').format(format);
};

export const isTodayAfterEffectiveDate= (effectiveDate: string): Date => {
    return moment(new Date()).isAfter(effectiveDate);
}

export const getFullYear = (dateStr: string): number => {
    return parseInt(dateStr.substring(0, 4));
}

export const getNestedProp = (obj, path) => path.split('.').reduce((p, c) => {
  if (p) {
    if (p[c] === 0) {
      return '0';
    } else {
      return p[c] || null;
    }
  } else {
    return null;
  }
}, obj);

export const setNestedProp = (obj, path, value) => {
  let schema = obj;
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!schema[elem]) { schema[elem] = {}; }
    schema = schema[elem];
  }
  schema[pList[len - 1]] = value;
}

export const getDurationInDaysMonthAndYear = (startDate: Date, endDate: Date): string => {
  const diff = moment(endDate).diff(moment(startDate));
  const years = moment.duration(diff).years();
  const months = moment.duration(diff).months();
  const days = moment.duration(diff).days();
  return (years > 0 ? `${years} years ` : '') + (months > 0 ? `${months} months ` : '') + (days >= 0 ? `${days} days` : '');
};

export function assignAnnotations(target, ...sources) {
  if (sources.length === 0) return target;
  const source = sources.shift();
  const merge = Object.keys(source).reduce((acc, key) => {
    if (Array.isArray(source[key]) && Array.isArray(target[key])) {
      acc[key] = [...target[key], ...source[key]];
    } else if (typeof source[key] === 'object' && typeof target[key] === 'object') {
      acc[key] = { ...target[key], ...source[key] };
    } else if (key === 'template') {
      acc[key] = `${target[key] || ''} ${source[key]}`;
    } else {
      acc[key] = source[key];
    }
    return acc;
  }, {});
  return assignAnnotations({...target, ...merge}, ...sources);
}

export const firstUpper = (value: string) => {
  const lowercase = value.toLowerCase();
  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

export const replaceParam = (body, param, value) => {
  const iterLiteral = '\\$\\{' + param + '\\}';
  const re = new RegExp(iterLiteral, 'g');
  return body.replace(re, value);
};

export const getAllParams = (body: string) => {
  // @ts-ignore
  return [...body.matchAll(/\${(.*?)}/g)].map(a => a[1]);
};

export const removeNulls = (obj: object) => {
  Object.keys(obj).forEach(key => obj[key] === null && delete(obj[key]));
  return obj;
};

export const removeNullsIfEmptyList = (objSource: object, objTarget) => {
  const nullKeys = [];
  Object.keys(objSource).forEach(key => objSource[key] === null && nullKeys.push(key));
  Object.keys(objTarget).forEach(key => (nullKeys.includes(key) && objTarget[key] !== null) && (objSource[key] = objTarget[key]));
  return objSource;
};

export const buildQuerySelector = (el: HTMLElement) => {
  let qs = `${ el.tagName }${ el.id ? '#' + el.id : '' }`;
  qs = `${ qs }${ el.className ? '.' + el.className.replace(' ', '.') : ''}`;
  return qs;
};

export const getPrecision = (num: string): number => {
  return !isNaN(+num) && num.split('.')[1].length;
};

export const validateDecimalPlace = (value: string | number, minDigits: number) => {
  if (typeof value === 'number') {
    value = value.toString();
  }
  const decimals = value.toString().split('.')[1];
  if (decimals && decimals.length) {
    return decimals.length >= minDigits;
  } else {
    return false;
  }
};

export const randomStr = (): string => {
  return `${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)}`;
};

export const getRandomID = (): string => {
  return `wcag-${randomStr}`;
};

export const parseTemplate = (field, obj: any) => {
  const params = getAllParams(field.template);
  let template = field.template;
  params.forEach(param => template = replaceParam(template, param, getNestedProp(obj, param)));
  return template;
};

export const getFileNameFromHeaderOrDefault = response => {
  let filename = 'file';
  const disposition = response.headers.get('content-disposition');
  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

export const downloadBlob = (response: any, fileName: string = null) => {
  const dataType = response.body.type;
  const binaryData = [];
  const filename = fileName ? fileName : getFileNameFromHeaderOrDefault(response);
  binaryData.push(response.body);
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
  if (filename) {
    downloadLink.setAttribute('download', filename);
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove();
};

export const addInformationTooltip = (dataColumns: any, fieldName: string, message: string) => {
  dataColumns.map( col => {
    if (col.field === fieldName ) {
      col['source'] = 'getOffsetProtocolStatuses';
      col.toolTip =  {text : message};
    }
  });
};

export const mergeObjectsSkipNulls = (obj1: any, obj2: any) => {
  return mergeWith({}, obj1, obj2, (o, s) => isNull(s) ? o : s);
};

export const validateMaxDecimalPlace = (value: string | number, maxDigits: number) => {
  if (typeof value === 'number') {
    value = value.toString();
  }
  const decimals = value.toString().split('.')[1];
  if (decimals && decimals.length) {
    return decimals.length <= maxDigits;
  } else {
    return true;
  }
};

export const round = (number: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};
