<p-dialog
[className]="'eccc'"
[closable]="false"
[header]="'PROJECTS_MODULE.createApplication.title' | translate"
[(visible)]="visible"
[modal]="true"
[responsive]="true"
[style]="{width: '600px'}"
[minY]="100"
[maximizable]="false"
[autoZIndex]="false">

<div class="container">

    <form [formGroup]="form" >
        <formly-form [form]="form" [model]="data" [fields]="fields">
        </formly-form>
    </form>
    <div *ngIf="data.submissionType && (data.submissionType!== 'CI_APPLICATION' || data.activityType)">
      <ng-container *ngIf="data.activityType; else submissionType">
        {{'COMMON.submissionTypeList.'+data.activityType+'_ExplanatoryText' | translate}}
      </ng-container>
      <ng-template #submissionType>
        <!-- Circumstances under which application would be required... -->
      </ng-template>
    </div>

</div>


<p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="colsebtn" type="button" class="btn btn-primary" (click)="createApplication()">
            {{'PROJECTS_MODULE.createApplication.createApplication' | translate}}
        </button> <button id="colsebtn" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
      </div>
    </div>
  </p-footer>
</p-dialog>
