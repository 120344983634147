import { commonActionsLabelTranslationPrefix } from '@core/models/filter-types.model';
import { ActionOptionType, ITableFilterActionModel } from '@shared/models/table-filter.models';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';

export const COMPLIANCE_REPORT_ID_KEY = 'COMP_REP_ID';
export const COMPLIANCE_REPORT_DATA_KEY = 'COMP_REP_DATA';
export const COMPLIANCE_REPORT_CURRENT_STEP_KEY = 'COMP_REP_STEP_KEY';

export const COMPLIANCE_REPORT_PREFIX = 'COMPLIANCE_REPORT';
export const COMPLIANCE_REPORT_STEP_PREFIX = '.reportStep';
export const COMPLIANCE_REPORT_DETAILS_PREFIX = '.details';
export const COMPLIANCE_REPORT_TABLE_PREFIX = `${ COMPLIANCE_REPORT_PREFIX }.table`;
export const COMPLIANCE_REPORT_CREATE_POPUP = '.createReport';
export const COMPLIANCE_REPORT_TRANSLATION_PREFIX = 'COMPLIANCE_REPORT.';
export const COMPLIANCE_REPORT_INSUFFICIENT_CREDIT_PREFIX = 'insufficientCredits.';

export const complianceRepApplyPrefix = (value: string, subPrefix: string = ''): string => `${COMPLIANCE_REPORT_PREFIX}${subPrefix}.${value}`;

export const COMPLIANCE_REPORT_PERIOD_FLD = 'compliancePeriod';
export const COMPLIANCE_REPORT_CONFIRM_FLD = 'agreeForLowCiExport';
export const COMPLIANCE_REPORT_DISCLAIMER_LABEL = 'disclaimer';

export const COMPLIANCE_REPORT_STEP_COMPONENTS = {
  COMPLIANCE_REPORT_STEP: 'ComplianceReportStepComponent',
  COMPLIANCE_REPORT_DOCUMENTS: 'ComplianceReportDocumentsComponent',
  COMPLIANCE_REPORT_CONTACT_PERSON: 'ComplianceReportContactPersonComponent',
  COMPLIANCE_REPORT_SUMMARY: 'ComplianceReportSummaryComponent',
  COMPLIANCE_REPORT_EXPORT_LOWCI_FUELS: 'ComplianceReportExportLowciFuelsComponent',
  COMPLIANCE_REPORT_DEFERRALS: 'CreditsTransferTowardsDeferralsComponent',
};

export const COMPLIANCE_REPORT_NUMBER_MASK = 'separator.5';
export const COMPLIANCE_REPORT_REDUCTION_NUMBER_MASK = 'separator.0';
export const COMPLIANCE_REPORT_AMOUNT_MASK = 'separator.2';

export const COMPLIANCE_REPORT_LIST_TABLE_FILTER: ITableFilterActionModel = {
  filterListsModel: [],
  searchBox: true,
  searchButtons: false,
  dateRange: true,
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'COMPLIANCE_MANAGEMENT_REPORT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'COMPLIANCE_MANAGEMENT_REPORT',
        },
      ],
    },
  ],
  options: {
    dateRange: {
      placeholder: 'COMPLIANCE_REPORT.table.submittedOn',
    },
  },
};

export const COMPLIANCE_REPORT_LIST_TABLE_CONFIG = {
  tableHeaderType: 'COMPLIANCE_MANAGEMENT_REPORT',
  tableFilter: COMPLIANCE_REPORT_LIST_TABLE_FILTER,
  filtersFromConfig: true,
  hasViewTemple: true,
  hasActionTemple: true,
  adminFilters: ['facilityNames'],
};

// STEP table row data
export const COMP_REP_INVOLVED_IN_ACTIVITIES = 'involvedInActivities';
export const COMP_REP_ENGAGE_IN_ACTIVITIES = 'engageInActivities';
export const COMP_REP_FACILITY_FLD = 'facility';
export const COMP_REP_ERFP_LABEL = 'erfpName';
export const COMP_REP_ERFP_FLD = 'erf.id';
export const COMP_REP_CONTRIBUTION_AMOUNT_FLD = 'contributionAmount';
export const COMP_REP_PROVISIONAL_COMP_CREDIT_FLD = 'numberOfCompCreditsProvisionallyCreated';
export const COMP_REP_ERFP_PRICE_MAPPING_FLD = 'erfPriceMapping.cadConversion';
export const COMP_REP_ERFP_PRICE_MAPPING_LABEL = 'erfPriceMapping';
export const COMP_REP_ERFP_PRICE_MAPPING_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.erfPriceMappingTooltip`;
export const COMP_REP_FACILITY_EQUIPMENT_FLD = 'facilityEquipment';
export const COMP_REP_FACILITY_FUEL_LIST_CODE_FLD = 'fuelListCode';
export const COMP_REP_PROVINCE_FLD = 'province';
export const COMP_REP_ALPHANUMERIC_CI_FLD = 'alphanumericId';
export const COMP_REP_ALPHANUMERIC_CI_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.alphanumericIdTooltip`;
export const COMP_REP_CI_ID = 'ciID';
export const COMP_REP_SEARCH_BUTTON = 'search';
export const COMP_REP_CLEAR_BUTTON = 'clear';
export const COMP_REP_CI_ID_FLD = 'ciApplicationId';
export const COMP_REP_FACILITY_ID_FLD = 'facility.id';
export const COMP_REP_FACILITY_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.facilityTooltip`;
export const COMP_REP_PROVINCE_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.provinceTooltip`;
export const COMP_REP_ERFP_NAMES_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.erfpNameTooltip`;
export const COMP_REP_CONTRIBUTION_AMOUNT_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.contributionAmountTooltip`;
export const COMP_REP_PROVISIONAL_COMP_CREDIT_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.numberOfCompCreditsProvisionallyCreatedTooltip`;
export const COMP_REP_FUEL_FLD = 'fuel';
export const COMP_REP_FUEL_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.fuelTooltip`;
export const COMP_REP_FUEL_ID_FLD = 'fuel.id';
export const COMP_REP_FUEL_NAME_EN_FLD = 'fuelName';
export const COMP_REP_FUEL_NAME_FR_FLD = 'fuelNameFr';
export const COMP_REP_FUEL_QUANTITY_FLD = 'quantityOfFuel';
export const COMP_REP_FUEL_QUANTITY_BLEND_FLD = 'quantityOfFuelPartOfBlend';
export const COMP_REP_FUEL_QUANTITY_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.quantityOfFuelTooltip`;
export const COMP_REP_TRANSPORT_METHOD_FLD = 'methodUsedToTransportFuels';
export const COMP_REP_TRANSPORT_METHOD_OTHER_FLD = 'otherTransportMethod';
export const COMP_REP_FUEL_BLEND_DETAILS_FLD = 'fuelBlendDetailIsText';
export const COMP_REP_FUEL_BLEND_DETAILS_TEXT_FLD = 'fuelBlendDetailAsText';
export const COMP_REP_BLEND_FUEL_FLD = 'fuelIsBlend';
export const COMP_REP_BLEND_FUEL_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.fuelIsBlendTooltip`;
export const COMP_REP_BLEND_FUEL_TEXT_FLD = 'blendFuelText';
export const COMP_REP_COMMENT_FLD = 'additionalComments';
export const COMP_REP_SUBTRACTIONS_POOL_FLD = 'subtractionsPool';
export const COMP_REP_EXEMPTIONS_FLD = 'exemptions';
export const COMP_REP_ENTER_EXEMPTED_QUANTITY_LABEL = 'enterExemptedQuantity';
export const COMP_REP_SUBTRACTIONS_POOL_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.subtractionsPoolTooltip`;
export const COMP_REP_SUBTRACTIONS_POOL_IMPORT_TOOLTIP = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.subtractionsPoolImportTooltip`;
export const COMP_REP_TOTAL_SUBTRACTIONS_FROM_POOL_FLD = 'totalSubtractionsFromPool';
export const COMP_REP_TOTAL_EXEMPTED_QUANTITY_FLD = 'totalExemptedQuantity';
export const COMP_REP_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD = 'volumetricSubjectToRequirement';
export const COMP_REP_VOLUME_SUBJECT_TO_REDUCTION_REQUIREMENT_FLD = 'volumeSubjectToReductionRequirement';
export const COMP_REP_REFERENCE_CARBON_INTENSITY_FLD = 'referencedCarbonIntensity';

export const COMP_REP_RATIONAL_FOR_CHANGE_FLD = 'rationalForChange';
export const COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_FLD = 'calculatedVolumetricSubjectToRequirement';
export const COMP_REP_CALCULATED_VOLUMETRIC_SUBJECT_TO_REQUIREMENT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.calculatedVolumetricSubjectToRequirementTooltip`;
export const COMP_REP_OVERRIDE_CALCULATED_VALUE_FLD = 'overrideCalculatedValue';
export const COMP_REP_VOLUMETRIC_REQUIREMENT_FLD = 'volumetricRequirement';
export const COMP_REP_VOLUMETRIC_REQUIREMENT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.volumetricRequirementTooltip`;
export const COMP_REP_ENERGY_DENSITY_FLD = 'energyDensity';

export const COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_FLD = 'calculatedReductionRequirement';
export const COMP_REP_CALCULATED_REDUCTION_REQUIREMENT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.calculatedReductionRequirementTooltip`;
export const COMP_REP_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD = 'overrideReductionRequirement';
export const COMP_REP_REDUCTION_REQUIREMENT_FLD = 'reductionRequirement';
export const COMP_REP_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD = 'rationalForChangeForReduction';

export const COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD = 'calculatedTotalReductionRequirement';
export const COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.calculatedTotalReductionRequirementTooltip`;
export const COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD = 'overrideTotalReductionRequirement';
export const COMP_REP_TOTAL_REDUCTION_REQUIREMENT_FLD = 'totalReductionRequirement';
export const COMP_REP_TOTAL_RATIONAL_FOR_CHANGE_REDUCTION_REQUIREMENT_FLD = 'rationalForChangeInReductionReq';

export const COMP_REP_STEP_5_DOC_LABEL = 'step_5_doc_Label';
export const COMP_REP_STEP_5_DOC_TOOLTIP = 'step_5_doc_tooltip';

export const COMP_REP_MAX_NUM_OF_COMPLIANCE_CREDITS_CREATED_BY_CONTRIBUTOR_FLD = 'maxNumberOfComplianceCreditsCreatedByContributor';
export const COMP_REP_MAX_NUM_OF_COMPLIANCE_CREDITS_CREATED_FOR_ERP_FLD = 'maxNumberOfComplianceCreditsCreatedForErp';
export const COMP_REP_MAX_NUM_OF_COMPLIANCE_CREDITS_GASEOUS_FLD = 'maxNumberOfComplianceCreditsGaseous';
export const COMP_REP_NUMBER_OF_COMPLIANCE_CREDITS_SCHEDULE_18_FLD = 'erfCreditsToBeUsedForReductionRequirement';
export const COMP_REP_NUMBER_OF_COMPLIANCE_CREDITS_SCHEDULE_18_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.erfCreditsToBeUsedForReductionRequirementTooltip`;
export const COMP_REP_STEP_7_SECTION_1_TITLE = 'step_7_section_1_title';
export const COMP_REP_STEP_7_SECTION_2_TITLE = 'step_7_section_2_title';
export const COMP_REP_STEP_7_SECTION_3_TITLE = 'step_7_section_3_title';
export const COMP_REP_STEP_7_OUTSTANDING_VOLUMETRIC_REQUIREMENT_SECTION_TITLE = 'step_7_outstanding_volumetric_requirement_section_title';
export const COMP_REP_STEP_7_SECTION_4_TITLE = 'step_7_section_4_title';
export const COMP_REP_STEP_7_SECTION_1_OVERRIDE_TITLE = 'step_7_section_1_override_title';
export const COMP_REP_STEP_7_TABLE_TITLE = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.step_7_table_title`;
export const COMP_REP_STEP_6_TABLE_TITLE = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }.step_6_table_title`;
export const COMP_REP_STEP_7_TABLE_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.step_7_table_titleTooltip`;

export const COMP_REP_STEP_REQUIRED_UNITS_FLD = 'requiredUnits';
export const COMP_REP_QUANTITY_TO_SATISFY = 'quantityToSatisfy';
export const COMP_REP_QUANTITY_TO_CANCEL = 'quantityToCancel';
export const COMP_REP_COMPLIANCE_CREDITS_GASOLINE_REPLACEMENT_FLD = 'complianceCreditsGasolineReplacement';
export const COMP_REP_COMPLIANCE_CREDITS_GASOLINE_REPLACEMENT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.complianceCreditsGasolineReplacementTooltip`;
export const COMP_REP_COMPLIANCE_CREDITS_DIESEL_REPLACEMENT_FLD = 'complianceCreditsDieselReplacement';
export const COMP_REP_COMPLIANCE_CREDITS_DIESEL_REPLACEMENT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.complianceCreditsDieselReplacementTooltip`;
export const COMP_REP_CREDIT_USED_FOR_GASEOUS_COMPLIANCE_CREDIT_FLD = 'creditUsedForGasiousCompliaceCredit';
export const COMP_REP_CREDIT_USED_FOR_GASEOUS_COMPLIANCE_CREDIT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.creditUsedForGasiousCompliaceCreditTooltip`;
export const COMP_REP_CREDIT_USED_FOR_LIQUID_COMPLIANCE_CREDIT_FLD = 'creditUsedForLiquidCompliaceCredit';
export const COMP_REP_CREDIT_USED_FOR_LIQUID_COMPLIANCE_CREDIT_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.creditUsedForLiquidCompliaceCreditTooltip`;
export const COMP_REP_OUTSTANDING_REDUCTION_REQUIREMENT_FLD = 'outstandingReductionRequirement';
export const COMP_REP_OUTSTANDING_GASOLINE_REDUCTION_REQUIREMENT_FLD = 'outstandingGasolineReductionRequirement';
export const COMP_REP_OUTSTANDING_DIESEL_REDUCTION_REQUIREMENT_FLD = 'outstandingDiselReductionRequirement';
export const COMP_REP_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'outstandingGasolineVolumetricRequirement';
export const COMP_REP_OVERRIDDEN_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'finalOverriddenOutstandingGasolineVolumetricRequirement';
export const COMP_REP_OVERRIDE_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'overrideFinalOutstandingGasolineVolumetricRequirement';
export const COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'rationalForChangeInFinalOutstandingGasolineVolumetricReq';
export const COMP_REP_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'outstandingDieselVolumetricRequirement';
export const COMP_REP_OVERRIDDEN_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'finalOverriddenOutstandingDiselVolumetricRequirement';
export const COMP_REP_OVERRIDE_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'overrideFinalOutstandingDiselVolumetricRequirement';
export const COMP_REP_OVERRIDE_RATIONAL_OUTSTANDING_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'rationalForChangeInFinalOutstandingDiselVolumetricReq';
export const COMP_REP_CREDIT_USED_FOR_GENERIC_QM_FLD = 'creditUsedForGenericQm';
export const COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_FLD = 'overrideFinalOutstandingReductionRequirement';
export const COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQUIREMENT_INPUT_FLD = 'finalOverriddenOutstandingReductionRequirement';
export const COMP_REP_RATIONAL_CHANGE_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_COMMENT_FLD = 'rationalForChangeInFinalOutstandingReductionReq';
export const COMP_REP_OVERRIDDEN_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'finalOverriddenGasolineVolumetricRequirement';
export const COMP_REP_OVERRIDE_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'overrideFinalGasolineVolumetricRequirement';
export const COMP_REP_OVERRIDE_RATIONAL_GASOLINE_VOLUMETRIC_REQUIREMENT_FLD = 'rationalForChangeInFinalGasolineVolumetricReq';
export const COMP_REP_OVERRIDDEN_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'finalOverriddenDiselVolumetricRequirement';
export const COMP_REP_OVERRIDE_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'overrideFinalDiselVolumetricRequirement';
export const COMP_REP_OVERRIDE_RATIONAL_DIESEL_VOLUMETRIC_REQUIREMENT_FLD = 'rationalForChangeInFinalDiselVolumetricReq';

export const COMP_REP_CHECKBOX_STEP_6_FLD = 'agreeForLowCiExport';
export const COMP_REP_FUEL_QUANTITY_STEP_6_FLD = 'quantityStep6';
export const COMP_REP_FUEL_QUANTITY_STEP_6B_FLD = 'quantityStep6b';
export const COMP_REP_FUEL_QUANTITY_STEP_6_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.quantityStep6Tooltip`;
export const COMP_REP_FUEL_QUANTITY_STEP_6B_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.quantityStep6Tooltip`;
export const COMP_REP_COMPLIANCE_CREDIT_ACCOUNT_FLD = 'typeOfCredit';
export const COMP_REP_CREDITS_TO_BE_CANCELLED_FLD = 'complianceCreditsToBeCancelled';
export const COMP_REP_CREDITS_TO_BE_CANCELLED_TOOLTIP = `${COMPLIANCE_REPORT_PREFIX}${COMPLIANCE_REPORT_STEP_PREFIX}.complianceCreditsToBeCancelledTooltip`;

export const COMP_REP_TOTAL_CREDITS_TO_BE_CANCELLED_FLD = 'totalCreditsToCancelForExportStep';
export const COMP_REP_TOTAL_CREDITS_SELECTED_FOR_CANCELLATION_FLD = 'totalCreditsSelectedForCancellation';
export const COMP_REP_TOTAL_ERFP = 'totalERFP';
export const COMP_REP_TOTAL_AVAILABLE_CREDITS = 'totalAvailableCredits';
export const TOTAL_CREDITS_TO_CANCEL_APPLIED_FOR_EXPORT_STEP = 'totalCreditsToCancelAppliedForExportStep';

export const COMP_REP_ORGANIZATION_NAME_FLD = 'accountId';

export const COMP_REP_STEP_6_CREDITS_HIDE_EXPRESSION = `!model.${ COMP_REP_TOTAL_CREDITS_TO_BE_CANCELLED_FLD } || (model.${ COMP_REP_TOTAL_CREDITS_TO_BE_CANCELLED_FLD } && model.${ COMP_REP_TOTAL_CREDITS_TO_BE_CANCELLED_FLD } <= 0)`;
export const COMP_ERFP_DATE_OF_CONTRIBUTION = 'contributionDate';
// Deferrals Step
export const COMP_REP_OUTSTANDING_GASOLINE_REDUCTION_REQ_DEF_FLD = 'calcOutstandingGasolineReductionRequirement';
export const COMP_REP_OUTSTANDING_DIESEL_REDUCTION_REQ_DEF_FLD = 'calcOutstandingDieselReductionRequirement';
export const COMP_REP_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD = 'calOutstandingReductionRequirement';
export const COMP_REP_INITIAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD = 'initialOutstandingReductionRequirement';
export const COMP_REP_NUMBER_OF_COMPLIANCE_CREDITS_SCHEDULE_18_DEF_FLD = 'erfpUsedForDeferal';
export const COMP_REP_FINAL_OVERRIDEN_OUTSTANDING_REDUCTION_REQ_DEF_INPUT_FLD = 'overrideOutstandingReductionRequirement';
export const COMP_REP_OVERRIDEN_FINAL_OUTSTANDING_REDUCTION_REQUIREMENT_DEF_FLD = 'override';
export const COMP_REP_INITIAL_RED_REQ = 'initialReductionRequirement';

export const STEP_2_CALC_SOURCES = {
  G: ['STEP-1_1', 'STEP-1_2'],
  D: ['STEP-1_3', 'STEP-1_4'],
};

export const STEP_3_CALC_SOURCES = ['STEP-3_1', 'STEP-3_2'];

export const STEP_2_VOLUMETRIC_REQUIREMENT_FACTOR = {
  G: 0.05,
  D: 0.02,
};

export const COMP_REP_BASELINE_CI = {
  G: 95,
  D: 93,
};

export const COMP_REP_CONTACT_PERSON_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'postalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  sameAsOrganization: {
      hide: false,
      changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true,
  },
  hideLatLong: true,
};

export const COMP_REP_CONTACT_PERSON_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: 'REGISTRATION_PAGE.cfsRegistrationForm.',
  key: 'civicAddress',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: true,
  sameAsPostal: {
      hide: false,
      changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
  },
  hideLatLong: true,
}

export const volumetricSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'requirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMPLIANCE_REPORT.summaryStep'
    },
    {
      field: 'requirementQuantity',
      header: 'volumetricRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.3-3',
    },
    {
      field: 'volume',
      header: 'volumeAssociated',
      numeric: true,
      format: '1.3-3',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'remaining',
      header: 'unsatisfiedRequirements',
      numeric: true,
      format: '1.3-3',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const reductionSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'requirementType',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMPLIANCE_REPORT.summaryStep'
    },
    {
      field: 'requirementQuantity',
      header: 'reductionRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.0-0',
    },
    {
      field: 'complianceCredits',
      header: 'complianceCreditsAssociated',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
    {
      field: 'remaining',
      header: 'unsatisfiedRequirementsReductions',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
  ],
};

export const deferralsSummaryTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'requirementType',
      header: 'reductionRequirement',
      width: AUTO_WIDTH_FOR_COLUMNS,
      // translate: true,
      // translationPrefix: 'COMPLIANCE_REPORT.summaryStep'
    },
    {
      field: 'requirementQuantity',
      header: 'reductionQuantity',
      width: AUTO_WIDTH_FOR_COLUMNS,
      numeric: true,
      format: '1.0-0',
    },
    {
      field: 'complianceCredits',
      header: 'complianceCreditsAssociatedDeferral',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
    {
      field: 'remaining2',
      header: 'unsatisfiedRequirementsReductionsDeferral',
      numeric: true,
      width: AUTO_WIDTH_FOR_COLUMNS,
      format: '1.0-0',
    },
  ],
};

export const creditUsedTowardLimitTableConfig: ITableViewConfig = {
  translationPrefix: 'COMPLIANCE_REPORT.summaryStep',
  paginator: false,
  columns: [
    {
      field: 'limitType',
      header: 'limitType',
      width: AUTO_WIDTH_FOR_COLUMNS,
      translate: true,
      translationPrefix: 'COMPLIANCE_REPORT.summaryStep'
    },
    {
      field: 'limit',
      header: 'limit',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
    {
      field: 'creditForLimits',
      header: 'creditUsedTowardLimit',
      numeric: true,
      format: '1.0-0',
      width: AUTO_WIDTH_FOR_COLUMNS,
    },
  ],
};

export const COMP_REP_SERIAL_NUMBER_FLD = 'serialNumber';
export const COMP_REP_TYPE_OF_CREDITS_FLD = 'creditClass';
export const COMP_REP_CREDIT_TYPE_CODE_FLD = 'creditTypeCode';
export const COMP_REP_CREDIT_CLASS_HEADER = 'creditClassHeader';
export const COMP_REP_CREDIT_GROUP_FLD = 'creditGroupName';
export const COMP_REP_QUANTITY_OF_CREDITS_FLD = 'updatedQuantity';
export const COMP_REP_QUANTITY_FLD = 'quantity';
export const COMP_REP_QUANTITY_HEADER = 'quantityHeader';
export const COMP_REP_COMPLIANCE_YEAR = 'complianceYear';
export const COMP_REP_UNIT_STATUS_FLD = 'status';
export const COMP_REP_UNIT_STATUS_HEADER = 'unitStatus';
export const COMP_REP_NUM_CREDITS_TO_CANCEL = 'numCompCreditsTOCancel';
export const COMP_REP_ASSOCIATED_VOLUME_FLD = 'associatedVolume';
export const COMP_REP_ASSOCIATED_VOLUME_TOOLTIP = `${ COMPLIANCE_REPORT_TABLE_PREFIX }.associatedVolumeTooltip`;
export const COMP_REP_CARBON_INTENSITY_FLD = 'carbonIntensityFuel';
export const COMP_REP_CARBON_INTENSITY_VALUE = 'finalCi';
export const COMP_REP_CARBON_INTENSITY_TOOLTIP = `${ COMPLIANCE_REPORT_TABLE_PREFIX }.carbonIntensityTooltip`;
export const COMP_REP_QUANTITY_TO_SATISFY_TOOLTIP = `${ COMPLIANCE_REPORT_TABLE_PREFIX }.quantityToSatisfyTooltip`;
export const COMP_REP_SERIAL_NUMBER_TOOLTIP = `${ COMPLIANCE_REPORT_TABLE_PREFIX }.serialNumberTooltip`;
export const COMP_REP_CREDITS_USED_TOWARD_REQUIREMENTS_TABLE_ID = 'creditsUsedTowardRequirementsTable';
export const COMP_REP_CREDITS_USED_TOWARD_REQUIREMENTS_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: COMPLIANCE_REPORT_TABLE_PREFIX,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: COMP_REP_SERIAL_NUMBER_FLD,
      sortField: COMP_REP_SERIAL_NUMBER_FLD,
      header: COMP_REP_SERIAL_NUMBER_FLD,
      toolTip: {
        text: COMP_REP_SERIAL_NUMBER_TOOLTIP,
      },
    },
    {
      field: COMP_REP_CREDIT_TYPE_CODE_FLD,
      sortField: COMP_REP_TYPE_OF_CREDITS_FLD,
      header: COMP_REP_TYPE_OF_CREDITS_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditClassList',
    },
    {
      field: COMP_REP_CREDIT_GROUP_FLD,
      sortField: COMP_REP_CREDIT_GROUP_FLD,
      header: COMP_REP_CREDIT_GROUP_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditGroupList',
    },
    {
      field: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      sortField: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      header: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      numeric: true,
    },
    {
      field: COMP_REP_ASSOCIATED_VOLUME_FLD,
      sortField: COMP_REP_ASSOCIATED_VOLUME_FLD,
      header: COMP_REP_ASSOCIATED_VOLUME_FLD,
      numeric: true,
      toolTip: {
        text: COMP_REP_ASSOCIATED_VOLUME_TOOLTIP,
      },
    },
    {
      field: COMP_REP_CARBON_INTENSITY_VALUE,
      sortField: COMP_REP_CARBON_INTENSITY_FLD,
      header: COMP_REP_CARBON_INTENSITY_FLD,
      numeric: true,
      toolTip: {
        text: COMP_REP_CARBON_INTENSITY_TOOLTIP
      },
    },
  ],
};

export const COMP_REP_CREDITS_USED_TOWARD_REQUIREMENTS_DEFERRAL_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: COMPLIANCE_REPORT_TABLE_PREFIX,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: COMP_REP_SERIAL_NUMBER_FLD,
      sortField: COMP_REP_SERIAL_NUMBER_FLD,
      header: COMP_REP_SERIAL_NUMBER_FLD,
      toolTip: {
        text: COMP_REP_SERIAL_NUMBER_TOOLTIP,
      },
    },
    {
      field: COMP_REP_CREDIT_TYPE_CODE_FLD,
      sortField: COMP_REP_TYPE_OF_CREDITS_FLD,
      header: COMP_REP_TYPE_OF_CREDITS_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditClassList',
    },
    {
      field: COMP_REP_CREDIT_GROUP_FLD,
      sortField: COMP_REP_CREDIT_GROUP_FLD,
      header: COMP_REP_CREDIT_GROUP_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditGroupList',
    },
    {
      field: COMP_REP_QUANTITY_FLD,
      sortField: COMP_REP_QUANTITY_FLD,
      header: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      numeric: true,
    },
    {
      field: COMP_REP_ASSOCIATED_VOLUME_FLD,
      sortField: COMP_REP_ASSOCIATED_VOLUME_FLD,
      header: COMP_REP_ASSOCIATED_VOLUME_FLD,
      numeric: true,
      toolTip: {
        text: COMP_REP_ASSOCIATED_VOLUME_TOOLTIP,
      },
    },
    {
      field: COMP_REP_CARBON_INTENSITY_VALUE,
      sortField: COMP_REP_CARBON_INTENSITY_FLD,
      header: COMP_REP_CARBON_INTENSITY_FLD,
      numeric: true,
      toolTip: {
        text: COMP_REP_CARBON_INTENSITY_TOOLTIP
      },
    },
  ],
};

export const COMP_REP_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_ID = 'creditsBlocksToBeCancelledTable';
export const COMP_REP_CREDITS_BLOCKS_TO_BE_CANCELLED_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: COMPLIANCE_REPORT_TABLE_PREFIX,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: COMP_REP_SERIAL_NUMBER_FLD,
      sortField: COMP_REP_SERIAL_NUMBER_FLD,
      header: COMP_REP_SERIAL_NUMBER_FLD,
    },
    {
      field: COMP_REP_CREDIT_TYPE_CODE_FLD,
      sortField: COMP_REP_TYPE_OF_CREDITS_FLD,
      header: COMP_REP_TYPE_OF_CREDITS_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditClassList',
    },
    {
      field: COMP_REP_CREDIT_GROUP_FLD,
      sortField: COMP_REP_CREDIT_GROUP_FLD,
      header: COMP_REP_CREDIT_GROUP_FLD,
      translate: true,
      translationPrefix: 'COMMON.creditGroupList',
    },
    {
      field: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      sortField: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      header: COMP_REP_QUANTITY_OF_CREDITS_FLD,
      numeric: true,
    },
    {
      field: COMP_REP_ASSOCIATED_VOLUME_FLD,
      sortField: COMP_REP_ASSOCIATED_VOLUME_FLD,
      header: COMP_REP_ASSOCIATED_VOLUME_FLD,
      numeric: true,
      toolTip: {
        text: COMP_REP_ASSOCIATED_VOLUME_TOOLTIP,
      },
    },
    {
      field: COMP_REP_CARBON_INTENSITY_VALUE,
      sortField: COMP_REP_CARBON_INTENSITY_VALUE,
      header: COMP_REP_CARBON_INTENSITY_FLD,
      numeric: true,
      toolTip: {
        text: COMP_REP_CARBON_INTENSITY_TOOLTIP
      },
    },
  ],
};

export const COMP_REP_CREDIT_CLASS = {
  LIQUID: ['Liquid Compliance Credits'],
  GASEOUS: ['Gaseous Compliance Credits'],
  LIQCC: 'LIQCC',
  GASCC: 'GASCC',
};

export const COMP_REP_STEP_6_SECTIONS = ['EXPORTED', 'ACQUIRED'];

export const COMP_REP_INSUFFICIENT_CREDITS_TO_BE_CANCELLED_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: COMPLIANCE_REPORT_TABLE_PREFIX,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
      checkboxDisabled: false
    },
    {
      field: COMP_REP_TYPE_OF_CREDITS_FLD,
      sortField: COMP_REP_TYPE_OF_CREDITS_FLD,
      header: COMP_REP_CREDIT_CLASS_HEADER,
    },
    {
      field: COMP_REP_QUANTITY_FLD,
      sortField: COMP_REP_QUANTITY_FLD,
      header: COMP_REP_QUANTITY_HEADER,
      numeric: true,
    },
    {
      field: COMP_REP_SERIAL_NUMBER_FLD,
      sortField: COMP_REP_SERIAL_NUMBER_FLD,
      header: COMP_REP_SERIAL_NUMBER_FLD,
    },
    {
      field: COMP_REP_COMPLIANCE_YEAR,
      sortField: COMP_REP_COMPLIANCE_YEAR,
      header: COMP_REP_COMPLIANCE_YEAR,
    },
    {
      field: COMP_REP_UNIT_STATUS_FLD,
      sortField: COMP_REP_UNIT_STATUS_FLD,
      header: COMP_REP_UNIT_STATUS_HEADER,
      translate: true,
      translationPrefix: 'COMMON.statusList',
    },
  ],
};

export const STEP_1_DECIMAL_POINTS_FIELDS = ['quantityOfFuel', 'volumetricSubjectToRequirement', 'totalSubtractionsFromPool'];
