import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { CiPathwayReportService } from '@module/ci-pathway-report/ci-pathway-report.service';
import { Subject } from 'rxjs';
import { IRawFormBase } from '@core/models/raw-form.types';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from '@core/store/store.service';

@Component({
    selector: 'app-ci-pathway-action-model',
    templateUrl: './ci-pathway-action-model.component.html',
})
export class CiPathwayActionModalComponent implements OnInit, OnDestroy {
    form = new FormGroup({});
    fields: FormlyFieldConfig[];
    model: any = {
        carbonIntensityStatus: null,
        ciInvalidReason: null,
        adminRemark: ''
    };
    title: string;

    private ciInavlidReasons$ = new Subject<any>();
    private carbonIntensityStatus$ = new Subject<any>();
    private destroy$ = new Subject<void>();
    private ciPathwaryModalFormConfig: IRawFormBase[] = [{
        groupFields: false,
        fieldGroup: [
            {
                type: 'dict-select',
                key: 'carbonIntensityStatus',
                label: 'TASK_TO_DO.carbonIntensityPathwayReportModal.carbonIntensityStatus',
                disabled: false,
                translatePrefix: 'TASK_TO_DO.carbonIntensityPathwayReportModal',
                required: false,
                clearable: true,
                placeholder: 'selectItem',
            },
            {
                type: 'dict-select',
                key: 'ciInvalidReason',
                label: 'TASK_TO_DO.carbonIntensityPathwayReportModal.reason',
                disabled: false,
                requiredExpression: 'model.carbonIntensityStatus === "INVALID"',
                placeholder: 'TASK_TO_DO.carbonIntensityPathwayReportModal.selectItem',
                hideExpression: 'model.carbonIntensityStatus!== "INVALID"',
                translatePrefix: 'CARBON_INTENSITY_MODULE.invalidReasons',
                ignorePlaceholderTranslatePrefix: true
            },
            {
                type: 'textarea',
                className: 'col-12',
                key: 'adminRemark',
                label: 'TASK_TO_DO.adminTakeActionModal.adminRemark',
                required: false,
                validators: ['validateEmptyString']
            },
        ],
    }];
    private payload: any = null;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<CiPathwayActionModalComponent>,
        private formFactoryService: FormFactoryService,
        private ciPathwayReportService: CiPathwayReportService,
        private store: StoreService
    ) { }

    ngOnInit() {
        this.title = this.data.title;
        this.initializeForm();
        this.getDropdownLists();
        (this.data.model && this.data.model.id) && this.getDataForExistingCiPathwayReport(this.data.model.id);
    }

    initializeForm() {
        this.ciPathwaryModalFormConfig.forEach(formBase => {
            if (this.data && this.data.model && this.data.model.isNeedMoreInfo) {
                formBase.fieldGroup = formBase.fieldGroup.filter(field => {
                    // Exclude fields based on the condition
                    return !['ciInvalidReason', 'carbonIntensityStatus'].includes(field.key);
                });
            } else {
                formBase.fieldGroup.forEach(field => {
                    switch (field.key) {
                        case 'ciInvalidReason': field['source'] = this.ciInavlidReasons$;
                            break;
                        case 'carbonIntensityStatus': field['source'] = this.carbonIntensityStatus$;
                            break;
                    }
                });
            }
        });
        this.fields = this.formFactoryService.configureForm(this.ciPathwaryModalFormConfig);
    }

    getDataForExistingCiPathwayReport(id: number) {
        const api = this.data.model.isForCIApplication ? this.ciPathwayReportService.getExistingCiDetail(id) : this.ciPathwayReportService.getExistingPathwayReportDetail(id);
        api.pipe(takeUntil(this.destroy$)).subscribe(response => {
            if (response) {
                this.payload = response;
            }
        });
    }

    getDropdownLists() {
        this.ciPathwayReportService.getCIInvalidReasons().pipe(takeUntil(this.destroy$)).subscribe((res: Array<any>) => {
            this.ciInavlidReasons$.next(res.map(data => {
                data.id = data.code;
                data.name = data.code;
                return data;
            }));
        });

        this.ciPathwayReportService.getCiStatusList().pipe(takeUntil(this.destroy$)).subscribe((res: Array<any>) => {
            this.carbonIntensityStatus$.next([...res]);
        });
    }

    validateForm() {
        this.form.markAllAsTouched();
        this.formFactoryService.markFormAsDirty(this.form);
        return this.form.valid;
    }

    customActionHandler() {
        // For Need More Information Comment is mandatory
        this.fields[0].fieldGroup.forEach(field => {
            field['required'] = false;
            if (this.form.get(field.key)) {
                this.form.get(field.key).clearValidators();
                this.form.get(field.key).updateValueAndValidity();
            }
            if (field.key === 'adminRemark') {
                field['required'] = true;
                if (this.form.get(field.key)) {
                    this.form.get(field.key).setValidators(Validators.required);
                    this.form.get(field.key).updateValueAndValidity();
                }
            }
        });

        if (this.validateForm()) {
            this.payload['action'] = 'NEED_MORE_INFO';
            this.updateCommonPayload();
            this.submitResponse();
        }
    }

    updateCommonPayload() {
        if (this.data.model.isNeedMoreInfo) {
            this.payload['comment'] = this.form.value.adminRemark;
        } else {
            this.payload['ciInvalidReason'] = this.form.value.ciInvalidReason;
            this.payload['carbonIntensityStatus'] = this.form.value.carbonIntensityStatus;
            this.payload['adminRemark'] = this.form.value.adminRemark;
        }
    }

    submitResponse() {
        if (this.data && this.data.model) {
            let api = this.data.model.isNeedMoreInfo ? this.ciPathwayReportService.needMoreInfoSubmissionCiPathway(this.payload) : this.ciPathwayReportService.reviewCiPathway(this.payload);
            if (this.data.model.isForCIApplication) {
                const isAdmin = this.store.user.isAdmin;
                api = isAdmin ? this.ciPathwayReportService.reviewCIApplication(this.payload) : this.ciPathwayReportService.reviewCIApplicationForUser(this.payload);
            }
            api.pipe(takeUntil(this.destroy$)).subscribe(res => {
                if (res) {
                    this.dialogRef.close(true);
                }
            })
        }
    }

    additionalActionHandler() {
        if (this.validateForm()) {
            this.dialogRef.close({
                model: this.model,
                additionalAction: true
            });
        }
    }

    save() {
        // For Mark Task as Resolved CI Status is mandatory
        this.fields[0].fieldGroup.forEach(field => {
            field['required'] = false;
            if (this.form.get(field.key)) {
                this.form.get(field.key).clearValidators();
                this.form.get(field.key).updateValueAndValidity();
            }
            if (field.key === 'carbonIntensityStatus') {
                field['required'] = true;
                if (this.form.get(field.key)) {
                    this.form.get(field.key).setValidators(Validators.required);
                    this.form.get(field.key).updateValueAndValidity();
                }
            }
        });

        if (this.validateForm()) {
            this.payload['action'] = 'RESOLVED';
            this.updateCommonPayload();
            this.submitResponse();
        }
    }

    close(): void {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
