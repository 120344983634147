import { Component, OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'formly-documents',
  template: `
    <ng-container *ngIf="documentUploadConfig && !field.hide">
      <app-documents
        [documentUploadConfig]="documentUploadConfig"
        (documentResult)="documentUploadHandler($event)"
        [disabled]="fieldDisabled"
        [showPlaceholders]="true">
      </app-documents>
    </ng-container>
  `,
})
// tslint:disable-next-line:component-class-suffix
export class FormlyDocumentsType extends FieldType implements OnInit {

  public documents: IApp.IDocument[];
  public isDocumentUploadInProgress = false;
  public documentUploadDisabled = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  get fieldDisabled() {
    return this.documentUploadDisabled || this.form.disabled;
  }

  documentUploadHandler(documentResult: IApp.IDocument[]): void {
    this.documents = documentResult;
    this.isDocumentUploadInProgress = documentResult
      .filter(document => document._uploadStatus)
      .some(document => document._uploadStatus !== 'CLEAN');
    if (typeof this.to.onDocumentsChange === 'function') {
      this.to.onDocumentsChange(this.documents);
    }
  }

  private _hasDocumentConfigs() {
    return this.documentUploadConfig &&
      this.documentUploadConfig.documentUploadConfigs;
  }

  public get documentUploadConfig(): IApp.IDocumentUploadConfig {
    return this.to.documentUploadConfig;
  }

}
