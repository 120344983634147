import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { LookupService } from '@core/services/lookup.service';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { ProjectBuilderService } from '../project-builder.service';

@Component({
  selector: 'app-project-activity-summary-card',
  templateUrl: './project-activity-summary-card.component.html',
  styleUrls: ['./project-activity-summary-card.component.scss']
})
export class ProjectActivitySummaryCardComponent implements OnInit, OnDestroy {

  @Input() activity: any;

  /**Icons */
  public clone = faClone;
  public pencil = faPencilAlt;
  public trash = faTrash
  /**Icons */

  public title: string;

  private languageChangeSub$: Subscription;
  constructor(
    private lookupService: LookupService,
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
    private projectBuilderService: ProjectBuilderService
  ) { }

  ngOnInit() {
    this.setCardData();
    this.projectBuilderService.subscriptions$.add(
      this.formFactoryService.dictCallback.subscribe(field => {
        if (field.key === 'offsetProtocol.id' || field.key === 'provinceId') {
          this.setCardData();
        }
      })
    );
    this.languageChangeSub$ = this.translateService.onLangChange.subscribe(() => this.setCardData());
  }

  private setCardTitle() {
    if (this.translateService.currentLang === 'en') {
      this.title = this.activity.activity.activityType.en;
    } else {
      this.title = this.activity.activity.activityType.fr;
    }
  }

  private setCardData() {
    combineLatest(
      this.lookupService.getProvinceByCountry('CA'),
      this.lookupService.getOffsetProtocols()
    ).subscribe(([provinces, protocols]) => {
      this.activity = this.projectBuilderService.model;
      const province = provinces.find(province => province.id === this.activity.activity.provinceId).name;
      this.activity.provinceNames = this.translateService.instant(`COMMON.jurisdictionsList.${province}`);
      this.activity.protocolName = protocols.find(protocol => protocol.id === this.activity.activity.offsetProtocol.id).name;
      this.setCardTitle();
    });
  }

  ngOnDestroy() {
    if (this.languageChangeSub$) {
      this.languageChangeSub$.unsubscribe();
    }
  }


}
