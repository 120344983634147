import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { getNestedProp, setNestedProp } from '@core/utilities/utilities.constants';
import { IRegistrationStep } from '@module/registration/registration.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { AppInjector } from '@shared/services/app-injector.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { omit, cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';

const DEBUG_DIRTY_DATASET = false;

@Injectable({
  providedIn: 'root',
})
export class BaseStepperService extends AbstractPaginationService implements OnDestroy {

  public _currentStep$ = new BehaviorSubject(null);
  public currentStep$ = this._currentStep$.asObservable();
  public _registrationSteps: any; //IRegistrationStep[];
  public _registrationStepsTranslated: any;
  public _steps: IRegistrationStep[];
  public _code: string;
  public _orgRole: string;
  public _stepCode: string;
  public _accountId: number;
  public _id: number;
  public _disabled: boolean;
  public stepValidationRequest$ = new BehaviorSubject<boolean>(null);
  public stepValidationResponse$ = new BehaviorSubject<boolean>(null);
  public scrollContainerDir: ElementRef;
  public nonNullObjects = [];
  public extraSteps: any;
  public translationPrefix;
  public inputData: any;
  public lastSavedStep: number;

  public onSaveAndProceed$ = new Subject<boolean>();
  public onSubmit$ = new Subject<boolean>();
  public onSaveAsDraft$ = new Subject<boolean>();
  public onProcessAction$ = new Subject<any>();
  public onError$ = new Subject<any>();

  public translateService: any;

  private _initialData: any;
  private _wasModified = false;
  public fieldsToOmit: string[] = [];
  public adminCanUpdate = new BehaviorSubject(false);
  public prevStepData: any = null;
  private _wasDirty = false;

  public _destroy$ = new Subject<any>();
  private _yearsToExclude: any;
  private _carUnderSectionEligibleYear: any;
  private _carUnderSectionStepsAvailability: any;

  constructor() {
    super();
    this.resetStepper();
    const injector = AppInjector.getInjector();
    if(injector){
       this.translateService = injector.get(TranslateService);
       this.translateService.onLangChange.pipe(
        takeUntil(this._destroy$)
      ).subscribe((event: LangChangeEvent) => {
          this.translateSteps();
        });
    }
  }

  public set wasModified(value: boolean) {
    this._wasModified = value;
  }

  public get wasModified(): boolean {
    return this._wasModified;
  }

  get scrollEl() {
    return this.scrollContainerDir;
  }

  set scrollEl(el) {
    this.scrollContainerDir = el;
  }

  public resetStepper() {
    this._initialData = null;
    this._currentStep$.next({ id: 1, data: {} });
    this.prevStepData = null;
  }

  public init(registrationSteps: any, formContainer: ElementRef) {
    this._registrationSteps = registrationSteps;
    this.translateSteps();
    this.hasSteps && (this.currentStep = this.currentStep);
    this.scrollContainerDir = formContainer;
  }

  public get currentStep(): any {
    return this._currentStep$.getValue();
  }

  public set currentStep(value: any) {
    let stepSrc = value ? this.getStepById(value.id, value.subId, false) : {};
    if (stepSrc && stepSrc.skipMainStep && !value.subId && stepSrc.subSteps && stepSrc.subSteps.length) {
      stepSrc = this.getStepById(value.id, 1, false);
      value.subId = 1;
    }
    this._currentStep$.next({...stepSrc, ...value});
  }

  public get disabled(): boolean {
    return this._disabled;
  }

  public set disabled(value: boolean) {
    this._disabled = value;
  }

  public set yearsToExclude(value) {
     this._yearsToExclude = value;
  }

  public get yearsToExclude (){
    return this._yearsToExclude;
  }

  public set carUnderSectionEligibleYear(value){
    this._carUnderSectionEligibleYear =value;
  }

  public get carUnderSectionEligibleYear(){
    return this._carUnderSectionEligibleYear;
  }

  public set carUnderSectionStepsAvailability(value){
    this._carUnderSectionStepsAvailability =value;
  }

  public get carUnderSectionStepsAvailability(){
    return this._carUnderSectionStepsAvailability;
  }

  public disable() {
    this._disabled = true;
  }

  public enable() {
    this._disabled = false;
  }

  public set steps(data) {
    this._registrationSteps = data;
    this.translateSteps();
  }

  public translateSteps() {
    if (!this._registrationSteps || this._registrationSteps.length === 0) {
      return [];
    }
    this._registrationStepsTranslated = [
      ...this._registrationSteps.map(step => {
        const _step = {
            ...step,
            name: step.skipTranslate ? step.name : this.translateService.instant(`${ this.translationPrefix }${ step.name }`),
          };
        if (step.subSteps) {
          _step.subSteps = step.subSteps.map(subStep => ({
              ...subStep,
              name: subStep.skipTranslate ? subStep.name : this.translateService.instant(`${ this.translationPrefix }${ subStep.name }`),
            }),
          );
        }
        return _step;
      }),
    ];
  }

  public get steps() {
    if (!this._registrationStepsTranslated || this._registrationStepsTranslated.length === 0) {
      return [];
    }

    const steps = [
      ...this._registrationStepsTranslated.map(step => {
        const _step = {
          ...step,
          enabledSubSteps: step.enabledSubStepsProp
            ? this.currentData[step.enabledSubStepsProp]
            : step.enabledSubSteps,
        };
        return _step;
      }),
    ];

    if (JSON.stringify(this._steps) !== JSON.stringify(steps)) {
      this._steps = JSON.parse(JSON.stringify(steps));
    }

    return this.setExtraSteps(this._steps);
  }

  private setExtraSteps(steps) {
    if (this.extraSteps && this.currentData) {
      Object.keys(this.extraSteps).forEach(key => {
        if (this.currentData[key]) {
          const extraStep = {
            ...this.extraSteps[key],
            id: steps.length + 1,
            name: this.translateService.instant(`${ this.translationPrefix }${ this.extraSteps[key].name }`),
            extra: true,
          };
          steps = [...steps, extraStep];
        }
      });
    }
    return steps;
  }

  public set stepValidationResponse(valid: boolean) {
    this.stepValidationResponse$.next(valid);
  }

  public get stepComponent() {
    const regStep = this.getStepById(
      this.currentStep.id,
      this.currentStep.subId,
    );
    return regStep.component;
  }

  private get hasSteps() {
    return this._registrationSteps && this._registrationSteps.length > 0;
  }

  private getStepById(id: number, subId: number = null, stepOnly = false): any {
    const step = this.steps.find(s => s.id === id);
    if (step) {
      return subId && step.subSteps
        ? stepOnly
          ? step
          : step.subSteps.find(ss => ss.subId === subId)
        : step;
    } else {
      return null;
    }
  }

  public nextStep(data: any = null) {
    const currentStepId = this.currentStep.id;
    let currentSubStepId = this.currentStep.subId || 0;
    let nextStep = this.currentStep;
    const step = this.getStepById(currentStepId, currentSubStepId, true);

    if (step.subSteps && step.subSteps.length > 0 && step.enabledSubSteps) {
      const subSteps = step.subSteps.filter(ss => !ss.hidden);
      const _currentIdx = subSteps.findIndex(ss => ss.id === this.currentStep.id && ss.subId === this.currentStep.subId);
      const nextSubStep = subSteps[_currentIdx + 1];
      if (nextSubStep) {
        nextStep = {
          ...nextStep,
          subId: nextSubStep.subId,
          data: data ? data : nextStep.data,
        };
      } else {
        currentSubStepId = null;
      }
    } else {
      currentSubStepId = null;
    }

    let _nextStep;
    if (currentSubStepId === null) {
      _nextStep = this.getStepById(currentStepId + 1);
      if (_nextStep && _nextStep.skipMainStep && _nextStep.subSteps && _nextStep.subSteps.length) {
        _nextStep = this.getStepById(currentStepId + 1, 1);
      }
      if (!nextStep) {
        return;
      } else {
        nextStep = { ..._nextStep, data: data ? data : nextStep.data };
      }
    }
    const nextStepSrc = this.getStepById(nextStep.id, nextStep.subId, false);
    if (nextStepSrc.inputData) {
      this.inputData = {...this.inputData, ...nextStepSrc.inputData};
    }
    this.currentStep = {...nextStep, ...nextStepSrc};
    this.updateStepNum();
    this.prevStepData = cloneDeep(this.currentStep.data);
  }

  private updateStepNum() {
    let step;
    if (this.currentData.step) {
      step = parseFloat(this.currentData.step);
      const current = parseFloat(this.stepNum);
      if (current > step) {
        this.currentData.step = this.stepNum;
      }
    } else {
      this.currentData.step = '1';
    }
  }

  public get stepNum(): string {
    return `${ this.currentStep.id }${ this.currentStep.subId ? '.' + this.currentStep.subId : ''}`;
  }

  public getMaxStep(): number {
    return this.currentData.step && parseFloat(this.currentData.step);
  }

  public prevStep(data: any = null) {
    const currentStepId = this.currentStep.id;
    let currentSubStepId = this.currentStep.subId;
    let prevStep = this.currentStep;
    const step = this.getStepById(currentStepId, currentSubStepId, true);

    let prevSubStep = null;
    if (currentSubStepId) {
      const subSteps = step.subSteps.filter(ss => !ss.hidden);
      const _currentIdx = subSteps.findIndex(ss => ss.id === currentStepId && ss.subId === currentSubStepId);
      prevSubStep = subSteps[_currentIdx - 1];
      if (prevSubStep) {
        currentSubStepId = prevSubStep.subId;
        prevStep = {
          ...prevStep,
          subId: prevSubStep.subId,
          data: data ? data : prevStep.data,
        };
      }
    }

    let _prevStep;
    let skipResetSubId = false;
    if (currentSubStepId === 0 && !prevSubStep) {
      _prevStep = this.getStepById(currentStepId);
      if (_prevStep && _prevStep.skipMainStep && _prevStep.subSteps && _prevStep.subSteps.length) {
        const prevMainStep = this.getStepById(currentStepId - 1);
        if (prevMainStep.skipMainStep && prevMainStep.subSteps && prevMainStep.subSteps.length) {
          _prevStep = this.getStepById(currentStepId - 1, prevMainStep.subSteps.length);
          prevStep = { ...prevStep, ..._prevStep };
        } else {
          _prevStep = prevMainStep;
          prevStep = { ...prevStep, ..._prevStep, subId: null };
        }
      }else{
        prevStep = { ...prevStep, ..._prevStep, subId: null };
      }
    } else if (!prevSubStep) {
      const _preStepId = currentSubStepId === 1 ? currentStepId : currentStepId - 1;
      _prevStep = this.getStepById(_preStepId);
      if (_prevStep && _prevStep.skipMainStep && _prevStep.subSteps && _prevStep.subSteps.length) {
        _prevStep = this.getStepById(currentStepId - 1, _prevStep.subSteps.length);
        skipResetSubId = true;
      }
      if (!_prevStep) {
        return;
      }
      if (
        _prevStep.subSteps &&
        _prevStep.subSteps.length > 0 &&
        _prevStep.enabledSubSteps
      ) {
        prevStep = {
          id: _prevStep.id,
          subId: currentSubStepId === 1 ? null : Math.max(..._prevStep.subSteps.map(s => s.subId)),
          data: data ? data : prevStep.data,
        };
      } else {
        prevStep = {
          id: _prevStep.id,
          subId: skipResetSubId ? _prevStep.subId : null,
          data: data ? data : prevStep.data,
        };
      }
    }
    prevStep = {
      ...prevStep,
      data: data ? data : prevStep.data,
    };
    const prevStepSrc = this.getStepById(prevStep.id, prevStep.subId, false);
    if (prevStepSrc.inputData) {
      this.inputData = {...this.inputData, ...prevStepSrc.inputData};
    }
    this.currentStep = {...prevStep, ...prevStepSrc};
  }

  private ensureObjectsNotNull(data: any) {
    data === null && (data = {});
    if (this.nonNullObjects && this.nonNullObjects.length > 0) {
      this.nonNullObjects.forEach(key => {
        if (getNestedProp(data, key) === null) {
          setNestedProp(data, key, {});
        }
      });
    }
    return data;
  }

  public set currentData(data: any) {
    this.currentStep = {...this.currentStep, data: this.ensureObjectsNotNull(data)};
    this.updateStepNum();
  }

  public get currentData(): any {
    return this.currentStep.data;
  }

  public get isLastStep() {
    if (this.currentStep.subId) {
      if (!this.getStepById(this.currentStep.id, this.currentStep.subId + 1)) {
        return this.getStepById(this.currentStep.id + 1) === null;
      } else {
        return false;
      }
    } else {
      const currentStep = this.getStepById(this.currentStep.id);
      if (currentStep &&
        currentStep.subSteps &&
        (currentStep.enabledSubSteps === undefined ||
          currentStep.enabledSubSteps)
      ) {
        return false;
      } else {
        return this.getStepById(this.currentStep.id + 1) === null;
      }
    }
  }

  public get subStepsChooserSelected() {
    const currentStep = this.getStepById(this.currentStep.id);
    if (currentStep.subSteps) {
      return currentStep.enabledSubSteps !== undefined;
    } else {
      return true;
    }
  }

  public validateData() {
    this.stepValidationRequest$.next(true);
    return this.stepValidationResponse$;
  }

  disableFormIfApplicable(form) {
    if (this.disabled) {
      setTimeout(() => form.disable());
    }
  }

  initModelChangeTracking(wasModified = false): void {
    this._wasModified = wasModified;
    this._wasDirty = false;
    this._initialData = JSON.parse(JSON.stringify(this.currentData));
    this.prevStepData = cloneDeep(this.currentStep.data);
  }

  public get isDirty(): boolean {
    return this.checkIsDirty();
  }

  public checkIsDirty() {
    if (this._initialData) {
      const fieldsToOmit = [...this.fieldsToOmit, 'step', '_organizationAddress'];
      const initialData = omit(this._initialData, fieldsToOmit);
      const currentData = omit(this.currentData, fieldsToOmit);
      const isDirty = JSON.stringify(initialData) !== JSON.stringify(currentData);
      if (DEBUG_DIRTY_DATASET) {
        console.log(initialData);
        console.log(currentData);
        console.log('IS DIRTY: ', isDirty);
      }
      if (this._wasDirty !== isDirty) {
        this._wasDirty = isDirty;
        setTimeout(() => {
          this._wasModified = isDirty;
        });
      }
      return isDirty;
    } else {
      return true;
    }
  }

  public get prevData(): any {
    return cloneDeep(this.prevStepData || this.currentData);
  }

  public preventDblClick(event: PointerEvent) {
    if (event) {
      const el = event.currentTarget as HTMLElement;
      el.setAttribute('disabled', 'disabled');
      setTimeout(() => {
        el.removeAttribute('disabled');
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
