<div class="row mt-2">
  <div class="col-lg-8">
    <ng-container *ngFor="let field of fieldList">
      <ng-container *ngIf="task[field]">
        <div class="row mb-2">
          <div class="col-lg-6 text-right overflow-hidden">
           <span [innerHTML]="'TASK_TO_DO.task.' + field | translate"></span>
            :</div>
          <div class="col-lg-6" [innerHTML]="task[field]"></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>


  <div class="row mt-2 float-right">
    <div class="col" *ngIf="!showAction">
      <button class="btn btn-secondary btm-sm float-right ml-2"

              *ngFor="let action of task.entityActionList"
              (click)="triggerActionController(action)">
        {{action.label}}
      </button>
    </div>
  </div>


<div class="row mt-2">
  <div class="col-lg-4" *ngIf="showAction">
    <button class="btn btn-secondary btm-sm float-right ml-2"
            *ngFor="let action of task.entityActionList"
            (click)="doAction(action.workflowAction)">
        {{action.label}}
    </button>
  </div>
</div>
