<p-dialog
  #dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="true"
  [closable]="false"
  [header] = "'CREDIT_CREATION_REPORT.projectDetails.title' | translate"
  (onShow)="scrollToTop()">

  <ng-container *ngIf="ready">

    <form [formGroup]="form">
      <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
    </form>

    <p class="font-weight-bold">{{ 'CREDIT_CREATION_REPORT.projectDetails.regulatoryRegimeLabel' | translate }}</p>

    <div class="row">
      <div class="col-12">
        <app-form-array-table
          [headers]="rgHeaders"
          [form]="form"
          [model]="model"
          [disabled]="formDisabled"
          [fields]="regulatoryRegimeFields"
          (addItem)="addRegulatoryRegime()"
          [addButtonText]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.addMore'">
        </app-form-array-table>
      </div>
    </div>

    <form [formGroup]="extraForm">
      <formly-form [form]="extraForm" [model]="model" [fields]="extraFields"></formly-form>
    </form>

    <p class="font-weight-bold">{{ 'CREDIT_CREATION_REPORT.projectDetails.documentsLabel' | translate }}</p>
    <div *ngIf="documentUploadConfig" class="form-group mt-4">
      <app-documents
        [documentUploadConfig]="documentUploadConfig"
        [readOnly]="formDisabled"
        (documentResult)="documentUploadHandler($event)"
        [showPlaceholders]="true">
      </app-documents>
    </div>

    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
      <button type="button"
              *ngIf="!formDisabled"
              class="btn btn-primary"
              (click)="save()">
        {{'CREDIT_CREATION_REPORT.reportStep.save' | translate}}
      </button>
    </div>
  </ng-container>
</p-dialog>
