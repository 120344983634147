<h2>{{ 'ACCOUNTS_MODULE.userTasks.title' | translate }}
  <span *ngIf="tasks">({{ tasks.length }})</span>
</h2>
<div class="tasks">
  <ngb-accordion
    #acc="ngbAccordion"
    *ngIf="totalTasks > 0; else NO_DATA"
    [closeOthers]="true"
    (panelChange)="onPanelToggle($event)">
    <ngb-panel *ngFor="let task of tasks" [id]="task.id">
      <ng-template ngbPanelTitle>
        <span >{{ 'ACCOUNTS_MODULE.userTasks.actionRequiredLabel' | translate }}: </span>
        <span [innerHTML]="task.taskTypeTranslated"></span>
        <fa-icon class="float-right"  [icon]="['far', task.isRead ? 'envelope-open' : 'envelope']"></fa-icon>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-task-details [task]="task" [showAction]="true"></app-task-details>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <ng-template #NO_DATA>
    <ng-container *ngIf="totalTasks === 0; else LOADING">
      <div>{{ 'COMMON.custom_messages.noItems' | translate }}</div>
    </ng-container>
    <ng-template #LOADING>
      <div>{{ 'COMMON.custom_messages.pleaseWait' | translate }}</div>
    </ng-template>
  </ng-template>
</div>

<p class="text-center mt-4" *ngIf="tasks">
  <span
   [translate]="'ACCOUNTS_MODULE.dashboard.recordsInPanel'"
   [translateParams]="{
      current: tasks.length,
      total: totalTasks
   }"></span>&nbsp;

  <ng-container *ngIf="tasks.length < totalTasks; else ALL_ITEMS">
    <a href="#" (click)="viewNumberOfItems($event, totalTasks)">{{ 'COMMON.custom_messages.viewAll' | translate }}</a>
  </ng-container>
  <ng-template #ALL_ITEMS>
    <a href="#" (click)="viewNumberOfItems($event)">{{ 'COMMON.custom_messages.viewLess' | translate }}</a>
  </ng-template>

</p>
