import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@shared/components/ng-select/ng-select.module';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { ButtonModule } from 'primeng';
import { ProjectDiscussionAddCommentComponent } from '../project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.component';
import { ProjectDiscussionAddCommentModule } from '../project-discussion-add-comment/project-discussion-add-comment.module';
import { ProjectDiscussionControlsComponent } from './components/project-discussion-controls/project-discussion-controls.component';

@NgModule({
  declarations: [
    ProjectDiscussionControlsComponent,
  ],
  exports: [
    ProjectDiscussionControlsComponent,
  ],
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    ProjectDiscussionAddCommentModule,
    SharedPipesModule,
  ],
  entryComponents: [
    ProjectDiscussionAddCommentComponent,
  ],
})
export class ProjectDiscussionControlsModule { }
