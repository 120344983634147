/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../pipes/custom-translate.pipe";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../program-switcher/program-switcher.component.ngfactory";
import * as i7 from "../program-switcher/program-switcher.component";
import * as i8 from "../../../core/services/auth.service";
import * as i9 from "../../../core/store/store.service";
import * as i10 from "../../services/helper.service";
import * as i11 from "@angular/router";
import * as i12 from "@gilsdav/ngx-translate-router";
import * as i13 from "../../directives/wcag-dummy-link-target.directive";
import * as i14 from "./header.component";
import * as i15 from "../../services/document-in-progress.service";
import * as i16 from "../../services/modal.service";
import * as i17 from "../../../core/services/lookup.service";
import * as i18 from "../../services/user.service";
import * as i19 from "@angular/platform-browser";
import * as i20 from "../../services/previous-location.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang($event, "fr") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fran\u00E7ais"]))], null, null); }
function View_HeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang($event, "en") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["English"]))], null, null); }
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, [["userDropdown", 1]], null, 22, "li", [["class", "nav-item"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(7, 1720320, [[1, 4]], 3, i3.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i2.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i3.ɵm]], null, null), i1.ɵqud(603979776, 2, { _menu: 0 }), i1.ɵqud(603979776, 3, { _menuElement: 0 }), i1.ɵqud(603979776, 4, { _anchor: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 6, "span", [["aria-haspopup", "true"], ["class", "dropdown-toggle"], ["id", "userDropdown"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 12).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[4, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), (_l()(), i1.ɵeld(18, 0, [[3, 0]], null, 10, "div", [["aria-labelledby", "userDropdown"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, [[2, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i1.ɵqud(603979776, 5, { menuItems: 1 }), (_l()(), i1.ɵeld(21, 0, null, null, 3, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.gotoMyProfile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 16384, [[5, 4]], 0, i3.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(23, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(25, 0, null, null, 3, "button", [["class", "dropdown-item"], ["ngbDropdownItem", ""]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.migrateAccountAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(26, 16384, [[5, 4]], 0, i3.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵted(27, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(29, 0, null, null, 1, "app-program-switcher", [], null, null, null, i6.View_ProgramSwitcherComponent_0, i6.RenderType_ProgramSwitcherComponent)), i1.ɵdid(30, 245760, null, 0, i7.ProgramSwitcherComponent, [i8.AuthService, i9.StoreService, i10.HelperService, i11.Router], null, null), (_l()(), i1.ɵeld(31, 0, null, null, 3, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "a", [["class", "nav-link"], ["href", "#"], ["id", "logout"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signOut($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(33, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.lang === "en"); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.lang === "fr"); _ck(_v, 5, 0, currVal_1); _ck(_v, 30, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 7).isOpen(); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 12).dropdown.isOpen(); _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("APP_HEADER.headerSection.welcome")); _ck(_v, 14, 0, currVal_4); var currVal_5 = ((_co.currentUser == null) ? null : _co.currentUser.fullName); _ck(_v, 17, 0, currVal_5); var currVal_6 = true; var currVal_7 = i1.ɵnov(_v, 19).dropdown.isOpen(); var currVal_8 = i1.ɵnov(_v, 19).placement; _ck(_v, 18, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = i1.ɵnov(_v, 22).disabled; _ck(_v, 21, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("APP_HEADER.headerSection.myProfileBtn")); _ck(_v, 23, 0, currVal_10); var currVal_11 = i1.ɵnov(_v, 26).disabled; _ck(_v, 25, 0, currVal_11); var currVal_12 = i1.ɵunv(_v, 27, 0, i1.ɵnov(_v, 28).transform("APP_HEADER.headerSection.migrateAccountBtn")); _ck(_v, 27, 0, currVal_12); var currVal_13 = i1.ɵunv(_v, 33, 0, i1.ɵnov(_v, 34).transform("APP_HEADER.headerSection.signout")); _ck(_v, 33, 0, currVal_13); }); }
function View_HeaderComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "nav-link"], ["href", "#"], ["wcag-dummy-link-target", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i12.LocalizeRouterPipe, [i12.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(4, 1), i1.ɵdid(5, 212992, null, 0, i13.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i5.TranslateService], null, null), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("/"))); _ck(_v, 2, 0, currVal_2); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("APP_HEADER.headerSection.home")); _ck(_v, 6, 0, currVal_3); }); }
function View_HeaderComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "a", [["class", "nav-link"], ["id", "login"], ["wcag-dummy-link-target", ""]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i11.RouterLinkWithHref, [i11.Router, i11.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i12.LocalizeRouterPipe, [i12.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(4, 1), i1.ɵdid(5, 212992, null, 0, i13.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i5.TranslateService], null, null), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("/register/chooser-page"))); _ck(_v, 2, 0, currVal_2); _ck(_v, 5, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("APP_HEADER.headerSection.login")); _ck(_v, 6, 0, currVal_3); }); }
function View_HeaderComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang($event, "fr") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fran\u00E7ais"]))], null, null); }
function View_HeaderComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "nav-link"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLang($event, "en") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["English"]))], null, null); }
function View_HeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HeaderComponent_6)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 4, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_7)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_8)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isOffsetPublicPage; _ck(_v, 1, 0, currVal_0); var currVal_1 = (i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.loginEnabled)) && !_co.isOffsetPublicPage); _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.lang === "en"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.lang === "fr"); _ck(_v, 9, 0, currVal_3); }, null); }
function View_HeaderComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "small", [["class", "last-login float-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("APP_HEADER.headerSection.lastSuccessfulLogin")); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.currentUser.validLoginDateStr; _ck(_v, 3, 0, currVal_1); }); }
function View_HeaderComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("APP_HEADER.headerSection.registryName")); _ck(_v, 2, 0, currVal_0); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { dropdown: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "nav", [["class", "navbar navbar-expand-md navbar-light bg-light"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.ɵm, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [["class", "navbar-brand"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "app-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "skip-main"], ["href", "#"]], null, [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.skipToMain($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["aria-controls", "navbarsDefault"], ["aria-expanded", "false"], ["aria-label", "Toggle navigation"], ["class", "navbar-toggler hidden-sm-up"], ["data-target", "#navbarsDefault"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isNavbarCollapsed = !_co.isNavbarCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [["class", "navbar-toggler-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "collapse navbar-collapse justify-content-end"], ["id", "navbarsDefault"]], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i3.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "ul", [["class", "navbar-nav"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NOT_LOGGED_IN", 2]], null, 0, null, View_HeaderComponent_4)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_9)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_10)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isNavbarCollapsed; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.session; var currVal_5 = i1.ɵnov(_v, 16); _ck(_v, 15, 0, currVal_4, currVal_5); var currVal_6 = (_co.session && _co.currentUser); _ck(_v, 18, 0, currVal_6); var currVal_7 = _co.session; _ck(_v, 20, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("APP_HEADER.headerSection.skipToMainContent")); _ck(_v, 6, 0, currVal_0); var currVal_1 = true; var currVal_2 = !i1.ɵnov(_v, 11).collapsed; _ck(_v, 10, 0, currVal_1, currVal_2); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 245760, null, 0, i14.HeaderComponent, [i9.StoreService, i8.AuthService, i11.Router, i5.TranslateService, i10.HelperService, i15.DocumentUploadInProgressService, i16.ModalService, i17.LookupService, i18.UserService, i19.Title, i20.PreviousLocationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i14.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
