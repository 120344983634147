<section>
  <div>
    <h2 class="title" [innerHTML]="'COMMON.submissionTypeList.APPLICATION_' + data.type | translate"></h2>
    <h2 [innerHTML]="data.legalName"></h2>  <!--Organization name: -->
    <h2 *ngIf= "instrumentIdText" class="mb-5" [innerHTML]="instrumentIdText"></h2>
  </div>
  <div *ngIf="hasPermission">
    <button class="btn btn-secondary float-right" (click)="goToRegistrationReport()">  {{ 'COMMON.actionsLabel.VIEW_REGISTRATION_REPORT' | translate }}</button>
  </div>
</section>
