import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';

export const IGNORE_LOADING_INDICATOR_URIS = [
  '/api/account-service/document/uploadStatus',
  '/api/account-service/lookup/standards',
];

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  private requests: any[] = [];

  constructor(
    public loaderService: LoaderService,
  ) {
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let skipMessage = false;
    if (req.url) {
      IGNORE_LOADING_INDICATOR_URIS.forEach(uri => {
        if (req.url.indexOf(uri) > -1) {
          skipMessage = true;
        }
      });
    }

    if (!skipMessage) {
      this.requests.push(req);
      this.loaderService.show();
    }

    return new Observable(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });

      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });

  }
}
