import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { AuthService } from '@core/services/auth.service';
import { HelperService } from '@shared/services/helper.service';
import { IUserData } from '@core/models/user.model';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { UserService } from '@shared/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { LookupService } from '@core/services/lookup.service';

const COMPONENT_ID = 'REQUIRED_INFORMATION';

@Component({
  selector: 'app-required-information',
  templateUrl: './required-information.component.html',
  styleUrls: ['./required-information.component.scss']
})
export class RequiredInformationComponent implements OnInit {

  private _destroy$ = new Subject<any>();
  public visible = true;

  infoForm = new FormGroup({});
  fields: FormlyFieldConfig[]= [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          className: 'col-11',
          type: 'input',
          key: 'phoneNumber',
          templateOptions: {
            label: 'ACCOUNTS_MODULE.addUser.phoneLabel',
            required: true,
            placeholder: 'COMMON.messageSection.phonePlaceholder',
          },
        },
      ],
    },
  ]; ;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IUserData,
    private dialogRef: MatDialogRef<any>,
    private router: Router,
    private store: StoreService,
    private authService: AuthService,
    private helperService: HelperService ,
    private userService: UserService,
    private lookupService: LookupService,
  ) {}

  ngOnInit() {
    this.infoForm['id'] = COMPONENT_ID;
    this.dialogRef.disableClose = true;
  }

  continue(){
    this.userService.saveUser(this.data).pipe(
      takeUntil(this._destroy$)
    ).subscribe(
      response => {
        this.dialogRef.close();
      },
      err => {

      },
    );

  }

  signOut() {
    this.authService.signOut().pipe(
      takeUntil(this._destroy$)
    ).subscribe(
      response => {
        this.dialogRef.close(true);
      },
      err => {
        // TODO remove this part after backend update
        this.store.destroy();
        this.lookupService.clearCache();
        this.userService.clearCache();
        this.router.navigate(this.helperService.getTranslatedPath('/welcome'));
      },
    );
  }

}
