import { ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { IApp } from '@core/models/app.interfaces';


export const BUTTON_PRIMARY = "btn btn-primary mr-2";
export const BUTTON_SECONDARY = "btn btn-secondary mr-2";
export const COMPONENT_ID = 'UNIT_MANAGEMENT';
export const TRANSLATION_PREFIX_COOMON = 'COMMON.actionsLabel.';
export const PERMISSION_PROPOSE_TRANSACTION = 'PROPOSE_TRANSACTION';

export const TASK_STATE = 'taskState';

export const NOE_CREDIT_STATUSES = [
  'UNIT_ON_HOLD',
  'TEMPORARY_ON_HOLD',
  'CCM_ON_HOLD'
];

export const NOE_STATUS_FORM_NAME = {
  UNIT_ON_HOLD: 'creditOnHoldForm',
  TEMPORARY_ON_HOLD: 'creditTempOnHoldForm',
};

export const unitManagementTableConfig: ITableViewConfig = {
  translationPrefix: 'UNITS_MODULE.unitList',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      checkboxSelect:true,
      width: '3em',
    },
    {
      field: 'facilityName',
      header: 'accountName',
      sortField: 'facilityName',
    },
    {
      field: 'complianceYear',
      header: 'vintageYear',
      sortField: 'complianceYear',
    },
    {
      field: 'quantity',
      header: 'quantity',
      sortField: 'quantity',
      numeric:true,
    },
    {
      field: 'unitClassCode',
      header: 'unitType',
      translate: true,
      translationPrefix: "COMMON.unitClassList",
      sortField: 'unitClassCode',
    },
    {
      field: 'serialNumber',
      header: 'serialNumber',
      sortField: 'serialNumber',
    },
    {
      field: 'status',
      header: 'status',
      translate: true,
      translationPrefix: "COMMON.statusList",
      sortField: 'status',
    }
  ],
};

export const unitManagementProjectTransferTableConfig: ITableViewConfig = {
  translationPrefix: 'UNITS_MODULE.unitList',
  paginator: true,
  rowsPerPage: ROWS_PER_PAGE,
  columns: [
    {
      checkboxSelect:true,
      width: '3em',
    },
    {
      field: 'facilityName',
      header: 'accountName',
      sortField: 'facilityName',
    },
    {
      field: 'quantity',
      header: 'quantity',
      sortField: 'quantity',
    },
    {
      field: 'vintage',
      header: 'vintage',
      translate: true,
      translationPrefix: "COMMON.unitClassList",
      sortField: 'vintage',
    },
    {
      field: 'serialNumber',
      header: 'serialNumber',
      sortField: 'serialNumber',
    },
    {
      field: 'status',
      header: 'status',
      translate: true,
      translationPrefix: "COMMON.statusList",
      sortField: 'status',
    }
  ],
};

const markAsEligible = {
  label : `${TRANSLATION_PREFIX_COOMON}MARK_AS_ELIGIBLE`,
  actionUri : '/doRuEligible',
  id: COMPONENT_ID + '_eligibleBtn',
  showOnNew: false,
  workflowAction:'MARK_AS_ELIGIBLE',
};
const markAsIneligible ={
  label : `${TRANSLATION_PREFIX_COOMON}MARK_AS_INELIGIBLE`,
  actionUri : '/doRuIneligible',
  id: COMPONENT_ID + '_ineligibleBtn',
  showOnNew: false,
  workflowAction:'MARK_AS_INELIGIBLE',
};

const edit ={
  label : 'UNIT_MGMT_MODULE.recogCreditRemittance.updateDetailsBtn',
  actionUri : '/updateReconizedUnit',
  showOnNew: false,
  id: COMPONENT_ID + '_updateDetailsBtn',
};

const markAsRemitted= {
  label : `${TRANSLATION_PREFIX_COOMON}MARK_AS_REMITTED`,
  actionUri : '/doEligibletoRemit',
  id: COMPONENT_ID + '_remitBtn',
  showOnNew: false,
  workflowAction:'MARK_AS_REMITTED',
};

export const refundActionButtonMap :  any ={
  //'MARK_AS_INELIGIBLE' :markAsIneligible,
  //'MARK_AS_REMITTED':markAsRemitted,
  'EDIT':{
    label : 'UNIT_MGMT_MODULE.recogCreditRemittance.updateDetailsBtn',
    actionUri : '/refundReconizedUnit',
    showOnNew: false,
    id: COMPONENT_ID + '_refundBtn',
  }
}

export const actionButtonMap :  any ={
  'MARK_AS_ELIGIBLE' :markAsEligible,
  'MARK_AS_INELIGIBLE' :markAsIneligible,
  'MARK_AS_REMITTED':markAsRemitted,
  'MARK_AS_INVALID' : {
    label : `${TRANSLATION_PREFIX_COOMON}MARK_AS_INVALID`,
    actionUri : '/doRuInvalid',
    id: COMPONENT_ID + '_invalidBtn',
    showOnNew: false,
    workflowAction:'MARK_AS_INVALID',
  },
  'APPROVE' : {
    label : `${TRANSLATION_PREFIX_COOMON}APPROVE`,
    actionUri : '/doCreditRemitApprove',
    id: COMPONENT_ID + '_approveBtn',
    showOnNew: false,
    workflowAction:'APPROVE',
    configAction: 'REMIT_RECOGNIZED_UNITS'
  },
  'REJECT' : {
    label :`${TRANSLATION_PREFIX_COOMON}REJECT`,
    actionUri : '/doCreditRemitReject',
    id: COMPONENT_ID + '_rejectBtn',
    showOnNew: false,
    workflowAction:'REJECT',
    configAction: 'REMIT_RECOGNIZED_UNITS'
  },
  'SUBMIT' : {
    label : `${TRANSLATION_PREFIX_COOMON}SUBMIT`,
    actionUri : '/doCreditRemitApprove',
    id: COMPONENT_ID + '_submitBtn',
    showOnNew: false,
    workflowAction:'APPROVE',
    configAction: 'REMIT_RECOGNIZED_UNITS'
  },
  'CANCEL' : {
    label :`${TRANSLATION_PREFIX_COOMON}CANCEL`,
    actionUri : '/doCreditRemitReject',
    id: COMPONENT_ID + '_cancelBtn',
    showOnNew: false,
    workflowAction:'REJECT',
    configAction: 'REMIT_RECOGNIZED_UNITS'
  },
  'APPROVE2' : {
    label : `${TRANSLATION_PREFIX_COOMON}APPROVE`,
    actionUri : '/doCreditRemitApproveSecond',
    id: COMPONENT_ID + '_approveBtn',
    showOnNew: false,
    workflowAction:'APPROVE',
    configAction: 'REMIT_RECOGNIZED_UNITS'
  },
  'REJECT2' : {
    label :`${TRANSLATION_PREFIX_COOMON}REJECT`,
    actionUri : '/doCreditRemitRejectSecond',
    id: COMPONENT_ID + '_rejectBtn',
    showOnNew: false,
    workflowAction:'REJECT',
    configAction: 'REMIT_RECOGNIZED_UNITS'
  },
  'RESUBMIT' :{
    label :`${TRANSLATION_PREFIX_COOMON}RESUBMIT`,
    actionUri : '/doRemitResubmit',
    id: COMPONENT_ID + '_resubmitBtn',
    showOnNew: false,
    workflowAction:'RESUBMIT',
  },
  'EDIT':edit
 }
