

    <fa-icon
          *ngIf="to.toolTip"
          pTooltip="{{to.toolTip.text | translate}}"
          tooltipStyleClass="tooltip-min-width"
          tooltipPosition="{{to.toolTip?.tooltipPosition ? to.toolTip?.tooltipPosition : 'top'}}"
          [icon]="to.icon?.iconProp ? to.toolTip.iconProp : ['fas', 'info-circle']">
    </fa-icon>

    <input type="text"
           *ngIf="isFreeText"
           [formControl]="formControl"
           [id]="elementId"
           class="form-control"
           [disable-with-validation]="disabledField"
           [disabled]="isFormDisabled"
           [formlyAttributes]="field"
           [placeholder]="''"
           [class.is-invalid]="showError">

    <input type="hidden"
           *ngIf="!isFreeText"
           [id]="elementId"
           tabindex="-1"
           class="wcag-visuallyhidden">

    <ng-select
        wcag-label
        *ngIf="!isFreeText"
        [id]="elementId + '_dropdown'"
        [items]="items"
        [placeholder]="placeholder | translate"
        [inputAttrs]="inputAttrs"
        [formControl]="formControl"
        [bindLabel]="to.bindLabel || 'name'"
        [bindValue]="to.bindValue || 'id'"
        [clearable]="clearable"
        [groupBy]="getGroupBy()"
        [selectableGroup]="true"
        [class.custom-disabled]="isDisabled"
        [disabled]="isFormDisabled"
        [dropdownPosition]="dropdownPosition"
        [searchable]="to.searchable"
        [loading]="isLoading"
        [tabIndex]="tabIndex"
        [notFoundText]="notFoundText | translate"
        [loadingText]="loadingText | translate"
        [appendTo]="appendTo"
        (change)="to.change && to.change(field, $event)"
        [multiple]="to.multiple"
        [virtualScroll]="virtualScroll"
        [closeOnSelect]="!to.multiple"
        (focus)="onFocus($event)">

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span *ngIf="item" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
        </ng-template>

        <ng-template ng-label-tmp let-item="item" let-index="index">
          <span *ngIf="item" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
        </ng-template>

        <ng-template ng-optgroup-tmp let-item="item">
          <span class="ng-option-label" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <ng-container *ngIf="items && items.length > 0">
            <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
          </ng-container>
        </ng-template>


</ng-select>


  