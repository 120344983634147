import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { Observable, Subject } from 'rxjs';
import { UserService } from "@shared/services/user.service";
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { ModalService } from '@shared/services/modal.service';
import { SereviceMessageType, DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { BaseActionsComponent } from '@shared/components/base-actions/base-actions.component';
import { StoreService } from '@core/store/store.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss']
})
export class UserActionsComponent extends BaseActionsComponent implements OnInit {

  @Input() user: IApp.IUser;
  @Input() actionsList: IApp.IEntityAction[];
  @Input() appendTo: string;
  @Output() actionSuccessfull = new EventEmitter<boolean>();

  constructor(
    private userService: UserService,
    private modalService: ModalService,
    private storeService: StoreService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.actionsList) {
      this.actionsList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`));
    }
  }

  executeAction(value: IApp.IEntityAction): void {
    var userPayload: IApp.ISaveUser = {};
    userPayload.id = this.user.id;
    const action = value.workflowAction.charAt(0).toUpperCase() + value.workflowAction.slice(1).toLowerCase();
    const prefix = this.storeService.isProgram('OFFSET') ? 'Offset' : '';
    this.userService.doAction(`/do${ prefix }${ action }`, userPayload).pipe(
      takeUntil(this._destroy$),
    ).subscribe(value => {
      this.actionSuccessfull.emit(true);
    });
  }


  triggerActionController(value: IApp.IEntityAction): void {
    this.resetSelection();
    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: 'confirmationMessage',
        metaData: value.label,
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.executeAction(value);
      }
      this.resetSelection();
    });

  }

}
