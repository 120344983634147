import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ReplaceMAO } from '@core/models/account.model';
import { StoreService } from '@core/store/store.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { REQUEST_REPLACING_MAO } from '@shared/components/stepper/stepper.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { ModalService } from '@shared/services/modal.service';
import { NavigationService } from '@shared/services/navigation.service';

const PERMISSION_APPROVE_ACCOUNT = 'APPROVE_ACCOUNT'

@Component({
  selector: 'app-replacing-mao',
  templateUrl: './replacing-mao.component.html',
  styleUrls: ['./replacing-mao.component.scss'],
})
export class ReplacingMaoComponent implements OnInit {

  formGroup = new FormGroup({});
  model = new ReplaceMAO('MAO_REPLACE_REQUEST');
  fields: FormlyFieldConfig[];
  editable = true;
  submission = false;
  constructor(
    private navigationService: NavigationService,
    private formFactoryService: FormFactoryService,
    public translate: TranslateService,
    public cfrRegistrationService: CfrRegistrationService,
    private formService: FormService,
    private store: StoreService,
    private modalService: ModalService,
    private activatedRoute :ActivatedRoute) { }

  ngOnInit() {
    const state = window.history.state;
    if (state.data) {
      this.model.accountId = Number(state.data.accountId);
      this.model.registrationAcountId = Number(state.data.id);
      this.model.legalName = state.data.legalName;
      this.submission  = true;
      this.editable = false;
      this.fields = this.formFactoryService.configureForm(REQUEST_REPLACING_MAO);
    } else if (state.maoReplaceRequestDto) {
      this.setData(state.maoReplaceRequestDto);
    } else {
       this.activatedRoute.params.subscribe(params => {
         if(params.id){

          this.cfrRegistrationService.loadReplaceMAORequest(params.id).subscribe(result =>{
            this.setData(result);
          });
         }else{
          this.close();
         }
       });
    }

  }

  setData(maoReplaceRequestDto) {
    this.model = maoReplaceRequestDto;
    if (this.store.user.hasPermission(PERMISSION_APPROVE_ACCOUNT) && this.model.status === 'SUBMITTED') {
      this.editable = true;
    } else {
      this.editable = false;
    }
    setTimeout(() => this.formGroup.disable());
    this.fields = this.formFactoryService.configureForm(REQUEST_REPLACING_MAO);
  }
  close() {
    this.navigationService.back();
  }

  submit() {
    this.cfrRegistrationService.submitReplaceMAO(this.model).subscribe( response => {
      this.close();
    },
    (error: HttpErrorResponse) => {
      this.formService.parseErrors(this.formGroup, error.error);
    });
  }

  replace() {
      const message = 'replaceAOConfirmationMessage';
      this.modalService
        .open(
          ServiceMessageComponent,
          {
            message,
            type: SereviceMessageType.WARNING,
          },
          true,
          DEFAULT_DIALOG_CONFIG,
        )
        .afterClosed()
        .subscribe((result?: any) => {
          if (result) {
            this.cfrRegistrationService.approveReplaceMAORequest(this.model).subscribe( response => {
              this.close();
            });
          }
        });
    }

    reject() {
      const message = 'replaceAORejectionConfirmationMessage';
      this.modalService
        .open(
          ServiceMessageComponent,
          {
            message,
            type: SereviceMessageType.WARNING,
          },
          true,
          DEFAULT_DIALOG_CONFIG,
        )
        .afterClosed()
        .subscribe((result?: any) => {
          if (result) {
            this.cfrRegistrationService.rejectReplaceMAORequest(this.model).subscribe( response => {
              this.close();
            });
          }
        });
    }

  }
