/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contact-support-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i3 from "primeng/dialog";
import * as i4 from "../../pipes/custom-translate.pipe";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i7 from "primeng/api";
import * as i8 from "./contact-support-modal.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../core/store/store.service";
var styles_ContactSupportModalComponent = [i0.styles];
var RenderType_ContactSupportModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactSupportModalComponent, data: {} });
export { RenderType_ContactSupportModalComponent as RenderType_ContactSupportModalComponent };
export function View_ContactSupportModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "p-dialog", [], [[8, "className", 0]], [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Dialog_0, i2.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i3.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(5, { width: 0 }), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, 2, 6, "p-footer", [], null, null, null, i6.View_Footer_0, i6.RenderType_Footer)), i1.ɵdid(10, 49152, [[2, 4]], 0, i7.Footer, [], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 4, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["id", "CONTACT_SUPPORT_closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 4).transform("COMMON.labels.contactSupport")); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = _co.visible; var currVal_9 = _ck(_v, 5, 0, "500px"); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "eccc"; _ck(_v, 0, 0, currVal_0); var currVal_10 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(("COMMON.actionsLabel.contactMessage" + _co.labelPrefix))); _ck(_v, 7, 0, currVal_10); var currVal_11 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 14, 0, currVal_11); }); }
export function View_ContactSupportModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contact-support-modal", [], null, null, null, View_ContactSupportModalComponent_0, RenderType_ContactSupportModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.ContactSupportModalComponent, [i9.MAT_DIALOG_DATA, i9.MatDialogRef, i5.TranslateService, i10.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactSupportModalComponentNgFactory = i1.ɵccf("app-contact-support-modal", i8.ContactSupportModalComponent, View_ContactSupportModalComponent_Host_0, {}, {}, []);
export { ContactSupportModalComponentNgFactory as ContactSupportModalComponentNgFactory };
