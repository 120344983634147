
<p-table [columns]="tableHeaders" [value]="tableData" [ngClass]="'eccc'">
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns">
              <ng-container *ngIf="col.translate; else NO_TRANSLATION">
                <span [innerHTML]="col.header | translate"></span>
              </ng-container>
              <ng-template #NO_TRANSLATION>
                <span [innerHTML]="col.header"></span>
              </ng-template>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
            <td *ngFor="let col of columns">
              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">
                <ng-container *ngIf="col.valueTranslationPrefix; else NO_TRANSLATION">
                  {{ col.valueTranslationPrefix + rowData[col.field] | translate }}
                </ng-container>
                <ng-template #NO_TRANSLATION>
                  {{ rowData[col.field] }}
                </ng-template>
              </ng-container>
              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="col.templateRef; context: {$implicit: rowData}"></ng-container>
              </ng-template>
            </td>
        </tr>
    </ng-template>
</p-table>
