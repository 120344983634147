<div id="account-document-actions">
    <ng-select
        wcag-label
        #actionDropdown
        [placeholder]="placeholderLabel | translate"
        [inputAttrs]="{'aria-label': placeholderLabel | translate}"
        [items]="actionList"
        bindLabel="label"
        bindValue="label"
        class="ng-dropdown-left"
        [(ngModel)]="dummyModel"
        [appendTo]="appendTo"
        (change)="triggerActionController($event)">
    </ng-select>
</div>
