import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/services/modal.service";
export class RequestInterceptorService {
    constructor(modalService) {
        this.modalService = modalService;
    }
    intercept(req, next) {
        return next.handle(req);
    }
}
RequestInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RequestInterceptorService_Factory() { return new RequestInterceptorService(i0.ɵɵinject(i1.ModalService)); }, token: RequestInterceptorService, providedIn: "root" });
