import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LookupService } from '@core/services/lookup.service';
import { NavigationService } from '@shared/services/navigation.service';
import { AccountSummaryProjectsTableFilter } from '@core/models/filter-types.model';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel } from '@core/models/filter.model';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { FILTER_COLUMNS, PROJECT_DETAILS, SUB_PROJECT_TABLE_CONFIG } from './account-summary-projects.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';

import { cloneDeep, isEqual } from 'lodash';

@Component({
  selector: 'app-account-summary-projects',
  templateUrl: './account-summary-projects.component.html',
  styleUrls: ['./account-summary-projects.component.scss']
})
export class AccountSummaryProjectsComponent implements OnInit {
  public showDetails = false;
  public showTableDetails = false;
  public filterFields = AccountSummaryProjectsTableFilter;
  public tableConfig: ITableViewConfig;
  public tableData: IApp.ITableList;
  public pagination = {
    page: 0,
    size: 20,
    dir: undefined,
    sort: undefined
  } as IApp.IPagination;
  public firstItemIndex = 0;
  public filterState: FilterModel = {};

  public projectDetails = PROJECT_DETAILS;
  public selectedProject;
  public highlightRowId: number;

  private accountId;


  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;

  constructor(
    private navigation: NavigationService,
    private lookupService: LookupService,
    private accountManagementService: AccountManagementService
  ) { }

  ngOnInit() {
    this.accountId = history.state.accountId;
    this.setTableConfigs();
  }

  public viewProject(event, data) {
    if (!data) {
      return;
    }
    this.highlightRowId = data.id;
    this.selectedProject = data;
    this.showDetails = true;
    setTimeout(() => {
      this.showTableDetails = true;
    },300);
  }

  public close() {
    this.showDetails = false;
    this.showTableDetails = false;
  }

  public onFilterChanged(filterData: any) {
    if (!filterData.facilityNameList || !filterData.facilityNameList.length && this.accountId) {
      filterData.facilityNameList = [this.accountId];
    }
    if (isEqual(filterData, this.filterState)) {
      return;
    }
    this.firstItemIndex = 0;
    this.filterState = {...filterData};
    this.loadData(this.pagination, this.filterState);
  }

  public onPaginationChanged(pagination: IApp.IPagination) {
    if (isEqual(pagination, this.pagination)) {
      return;
    }
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadData(this.pagination, this.filterState);
  }

  public loadData(pagination: IApp.IPagination, filterState: FilterModel = {}) {
    this.showDetails = false;
    this.showTableDetails = false;
    this.accountManagementService.getAccountProjects(pagination, filterState).subscribe(data => {
      this.tableData = data;
      this.viewProject(null,data.content[0]);
    })
  }

  public back() {
    this.navigation.back();
  }

  private setTableConfigs() {
    this.lookupService.getTableConfigurations('OFFSET_DASHBOARD_PROJECTS').subscribe(
      data => {

        this.tableConfig = data;

        const { columns, ...config } = this.tableConfig;

        const _columns: any = [
          ...this.tableConfig.columns.filter(column => FILTER_COLUMNS.includes(column.field)),
          {
            header: 'details',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          }
        ];

        this.filterFields = { ...this.filterFields, filterListsModel: data.filters };
        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };
      });
  }

}
