import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'formly-table',
  template: `
    <app-table-view
      [config]="to.config"
      [data]="data"
      [relative]="true"
      [selections]="selection"
      [isEditable]="to.isEditable"
      [tableContainerMinWidth]="to.tableContainerMinWidth"
      [footerTemplate]="to.footerTemplate"
      [disableOneRowSelection]="to.disableOneRowSelection"
      (checkboxSelected)="onRowSelected($event)"
      (paginationChanged)="onPaginationChanged($event)"
    >
    </app-table-view>
  `,
})
export class FormlyTable extends FieldType implements OnInit {

  private currentSelection: any[] = [];
  selection = [];

  get data() {
    const control = this.form.get(this.controlId);
    if (control && control.value && control.value !== null) {
      return this.to.config.paginator ? control.value : [...control.value];
    }
    return this.to.config.paginator ? {content: []} : [];
  }

  set data(val: any) {
    this.onTableUpdate(val);
    if (this.form.get(this.controlId)) {
      this.form.get(this.controlId).setValue(val);
    }
  }

  private get controlId(): string {
    return this.to.controlId;
  }

  private entryId = 0;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.initApplyInitialDataSub();
    this.initUpdateDataSub();
    this.initRefreshSelectedSub();
    this.disableTableIfApplicable();
    if (this.to.selections) {
      this.selection = [...this.to.selections];
    }
  }

  public onRowSelected(data: any, background = false): void {
    const { onRowSelected } = this.to;

    if (typeof onRowSelected === 'function') {
      this.currentSelection = data;
      onRowSelected(data, background);
    }
  }

  public onPaginationChanged($event): void {
    const { onPaginationChanged } = this.to;

    if (typeof onPaginationChanged === 'function') {
      onPaginationChanged($event);
    }
  }

  private disableTableIfApplicable(): void {
    if (this.to.disabled) {
      this.to.config.selectDisabled = true;
    }
  }

  private initApplyInitialDataSub(): void {
    const { initialData } = this.to;
    const source = initialData instanceof Array ? initialData : [];
    this.applyData(source);
  }

  private initRefreshSelectedSub(): void {
    const requestSelected: Subject<any[]> = this.to.requestSelected;

    if (!requestSelected) {
      return;
    }

    requestSelected.pipe(untilDestroyed(this)).subscribe(value => {
      this.currentSelection = value;
      this.selection = [...value];
      this.onRowSelected(this.currentSelection, true);
    });
  }

  private initUpdateDataSub(): void {
    const updateData: Subject<any[]> = this.to.updateData;

    if (!updateData) {
      return;
    }

    updateData.pipe(untilDestroyed(this)).subscribe(value => {
      this.applyData(value);
    });
  }

  private applyData(data: any[]): void {
    if (this.to.config.paginator) {
      this.data = data;
    } else {
      this.data = data.map(entry => {
        this.entryId++;
        return {
          ...entry,
          id: entry.id ? entry.id : this.entryId,
        };
      });
    }
    this.cd.detectChanges();
  }

  private onTableUpdate(value: any): void {
    const { onTableUpdate } = this.to;

    if (typeof onTableUpdate === 'function') {
      onTableUpdate(value);
    }
  }
}
