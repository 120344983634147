
<div class="account-title">
    <a href="#"
       [appRouterLink]="'/account-management/summary-user' | localize"
       class="arrow-back"
       wcag-dummy-link-target>
      <fa-icon class="float-left back-icon"  [icon]="['fas', 'chevron-left']"></fa-icon>
      <span style="display: none">back</span>
    </a>
</div>

<app-stepper
  [type]="type"
  [accountId]="accountId"
  [inApp]="true"
></app-stepper>
