import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { loginRoute, newOBPSRegistrationRoute } from '@core/constants/registration-route-types.const';
import { AuthService } from '@core/services/auth.service';
import { StoreService } from '@core/store/store.service';
import { programIdKey } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { ContactSupportModalComponent } from '@shared/components/contact-support-modal/contact-support-modal.component';
import { CfsRegistration } from '@shared/components/steps/constants/registration-types.const';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { forkJoin, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { take, takeUntil } from 'rxjs/operators';

const PROD_HOSTNAME = 'marchescarbone-carbonmarkets.canada.ca';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {

  actionsList: any[] = [];
  private _destroy$ = new Subject<any>();
  showCfr: boolean;
  showOffset: boolean;
  loaded: boolean;

  constructor(
    private modalService: ModalService,
    private helperService: HelperService,
    private translate: TranslateService,
    private store: StoreService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    forkJoin([
      this.store.getProperty('cfs.enabled.date'),
      this.store.getProperty('enable.offset')
      ]).subscribe(([property, offsetProp]) => {
          this.showCfr =  this.checkPrpertyEnabled(property);
          this.showOffset =  this.checkPrpertyEnabled(offsetProp);
          this.loaded = true;
        },
        () => this.loaded = true,
      );
    this.actionsList = [
      { id: 'HOME', name: 'Home' },
      { id: 'LOGIN', name: 'Login' },
    ];
  }

  get showButtons(): Observable<boolean> {
    return this.store.loginAvailable$;
  }

  get registrationRoute() {
    return this.helperService.getTranslatedPath(newOBPSRegistrationRoute);
  }

  get loginRoute() {
    return this.helperService.getTranslatedPath(loginRoute);
  }

  get aboutUsRoute() {
    return this.helperService.getTranslatedPath('/learn-more');
  }

  checkPrpertyEnabled(property){
    return property.value && property.value.toLowerCase() === 'true';
  }

  contactUs(event: Event, labelPrefix = '') {
    if (event) {
      event.preventDefault();
    }
    this.modalService.open(ContactSupportModalComponent, labelPrefix);
  }

  openRegistrationObps(event) {
    event && event.preventDefault();
    sessionStorage.setItem(programIdKey, 'obps');
    this.authService.changeLang(this.translate.currentLang, true)
      .subscribe(() => this.router.navigate(this.helperService.getTranslatedPath(newOBPSRegistrationRoute)));
  }

  openRegistrationCfs(event) {
    event && event.preventDefault();
    sessionStorage.setItem(programIdKey, 'cfr');
    this.authService.changeLang(this.translate.currentLang, true)
      .subscribe(() =>
        this.router.navigate(
          this.helperService.getTranslatedPath('/register/wizard/cfr-registration-steps'), { state: { data: CfsRegistration } },
        ));
  }

  openLearnOBPS() {
    if (this.lang === 'en') {
      window.open(
        'https://www.canada.ca/en/environment-climate-change/services/climate-change/pricing-pollution-how-it-will-work/output-based-pricing-system.html',
        '_blank',
      );
    } else {
      window.open(
        'https://www.canada.ca/fr/environnement-changement-climatique/services/changements-climatiques/fonctionnement-tarification-pollution/systeme-tarification-fonde-rendement.html',
        '_blank',
      );
    }
    return false;
  }

  get lang(): string {
    return this.translate.currentLang;
  }

  get isNonProd(): boolean {
    return location.hostname !== PROD_HOSTNAME;
  }
}
