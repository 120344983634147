import { IRawFormBase } from '@core/models/raw-form.types';
import { SOURCE_DATA_TYPE, VB_ORG_INFO } from '@shared/components/stepper/stepper.const';

export const VERIFICATION_VALIDATION_BODY_TYPE_YEAR: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        label: 'Submission Type',
        type: 'input',
        className: 'col-6 p-2',
        key: 'submissionType',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionType',
      },
      {
        label: 'Compliance Period',
        type: 'input',
        className: 'col-6 p-2',
        key: 'compliancePeriod',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'compliancePeriod',
        hideExpression: `!model.compliancePeriod`
      },
    ],
  },
];

export const VERIFICATION_VALIDATION_BODY_COMMENTS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        label: 'Comments',
        className: 'col-12 m-2 p-4',
        type: 'textarea',
        key: 'comment',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        required: false,
        id: 'comment',
        templateOptions: {
          rows: 4
        },
      },
    ],
  },
];

export const TEAM_LEAD_SELECTION_FORM: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.organizationName',
        className: 'col-6 p-2',
        type: 'input',
        key: 'organizationName',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'organizationName'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.compliancePeriod',
        className: 'col-6 p-2',
        type: 'input',
        key: 'compliancePeriod',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'compliancePeriod',
        hideExpression: `!model.compliancePeriod`
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionType',
        className: 'col-6 p-2',
        type: 'input',
        key: 'submissionType',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionType'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionId',
        className: 'col-6 p-2',
        type: 'input',
        key: 'submissionId',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionId',
        hideExpression: `!model.submissionId`
      }
    ],
  },
];

export const INDEPENDENT_REVIEWER_SELECTION_FORM: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.organizationName',
        className: 'col-6 p-2',
        type: 'input',
        key: 'organizationName',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'organizationName'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.compliancePeriod',
        className: 'col-6 p-2',
        type: 'input',
        key: 'compliancePeriod',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'compliancePeriod',
        hideExpression: `!model.compliancePeriod`
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionType',
        className: 'col-6 p-2',
        type: 'input',
        key: 'submissionType',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionType'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionId',
        className: 'col-6 p-2',
        type: 'input',
        key: 'submissionId',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionId',
        hideExpression: `!model.submissionId`
      }
    ],
  },
];

export const OUTSOURCED_VERIFICATION_BODY: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.organizationName',
        className: 'col-6 p-2',
        type: 'input',
        key: 'organizationName',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'organizationName'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.compliancePeriod',
        className: 'col-6 p-2',
        type: 'input',
        key: 'compliancePeriod',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'compliancePeriod'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionType',
        className: 'col-6 p-2',
        type: 'input',
        key: 'submissionType',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionType'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionId',
        className: 'col-6 p-2',
        type: 'input',
        key: 'submissionId',
        source: SOURCE_DATA_TYPE.VERIFICATION_BODY,
        disabled: true,
        required: true,
        id: 'submissionId'
      }
    ],
  }
];

export const OVB_ASSIGNMENT_DETAILS: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'textarea',
        className: 'col-12',
        key: 'assignedComponentDetails',
        id: 'assignedComponentDetails',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.assignedComponentDetails',
        required: true,
      },
      {
        type: 'input',
        key: 'totalAssignmentUndertakenPercentage',
        id: 'totalAssignmentUndertakenPercentage',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.totalAssignmentUndertakenPercentage',
        required: true,
        customTemplateOptions: {
          type: 'number',
          hideArrows: true
        },
        min: 1,
        max: 100
      },
    ]
  }
];

export const VB_PROOF_ACCREDITATION: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        type: 'dict-select',
        key: 'name',
        source: SOURCE_DATA_TYPE.ACCREDITATION_BODY,
        placeholder: 'COMMON.roleList.selectItem',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.accreditationBody',
        translate: false,
        disabled: false,
        required: true,
        id: 'accreditationBody',
        sort: false,
        reloadSourceOnLangChange: true,
      },
    ],
  },

  {
    groupFields: true,
    // key: 'accreditationBody',
    label: 'periodValidityCreds',
    translatePrefix: 'REGISTRATION_PAGE.cfsRegistrationForm',
    fieldGroup: [
      {
        type: 'app-formly-datepicker',
        key: 'fromDate',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.from',
        disabled: false,
        required: true,
        expressionProperties: { 'templateOptions.maxDate': 'model.toDate' },
        id: 'accreditationBodyFrom',
      },
      {
        type: 'app-formly-datepicker',
        key: 'toDate',
        label: 'REGISTRATION_PAGE.cfsRegistrationForm.to',
        disabled: false,
        required: true,
        expressionProperties: { 'templateOptions.minDate': 'model.fromDate' },
        id: 'accreditationBodyTo',
      },
    ],
  },
];

export const TEAM_MEMBERS_SELECTION_FORM: IRawFormBase[] = [
  {
    groupFields: false,
    fieldGroup: [
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.organizationName',
        className: 'col-6 p-2',
        type: 'input',
        key: 'legalName',
        disabled: true,
        required: true,
        id: 'organizationName'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.compliancePeriod',
        className: 'col-6 p-2',
        type: 'input',
        key: 'compliancePeriod',
        disabled: true,
        required: true,
        id: 'compliancePeriod'
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionType',
        className: 'col-6 p-2',
        type: 'input',
        key: 'entityType',
        disabled: true,
        required: true,
        id: 'submissionType',
      },
      {
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationVerification.submissionId',
        className: 'col-6 p-2',
        type: 'input',
        key: 'applicationId',
        disabled: true,
        required: true,
        id: 'submissionId',
        hideExpression: "!model.applicationId"
      }
    ],
  },
];
