import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ObligationMgmtFilterModel } from '@core/models/filter.model';
import { IApp } from '@core/models/app.interfaces';
import { Subject } from 'rxjs';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { takeUntil } from 'rxjs/operators';
import { upcomingCompensationDeadlinesTableConfig } from '@module/account-management/account-management.const';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_NUM_OF_ITEMS = 5;

@Component({
  selector: 'app-upcoming-deadlines',
  templateUrl: './upcoming-deadlines.component.html',
  styleUrls: ['./upcoming-deadlines.component.scss'],
})
export class UpcomingDeadlinesComponent implements OnInit, OnDestroy {

  @ViewChild('deadlineTypeColTemplate', { static: true }) public deadlineTypeColTemplate: TemplateRef<any>;

  upcomingCompensationDeadlinesTableConfig = upcomingCompensationDeadlinesTableConfig;
  upcomingCompensationDeadlinesFullList: IApp.IObligation[];
  upcomingCompensationDeadlines: IApp.IObligation[];
  totalElements: number = -1;

  private _destroy$ = new Subject<any>();

  periods: any;
  currentPeriod: any;

  pagination = {
      page: 0,
      size: DEFAULT_NUM_OF_ITEMS,
  } as IApp.IPagination;

  filterState: ObligationMgmtFilterModel = {

  };

  constructor(
    private accountManagementService: AccountManagementService,
    private translateService: TranslateService,
  ) {
    this.periods = this.setPeriods();
    this.currentPeriod = this.periods[1];
    this.translateService.onLangChange
    .pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => {
      this.periods = this.setPeriods();
      this.currentPeriod = this.periods[1];
    })
  }



  ngOnInit() {
   // this.upcomingCompensationDeadlinesTableConfig.columns[4].templateRef = this.deadlineTypeColTemplate;
    this.loadData(true);
  }

  loadData(reload = false) {
    this.filterState.dateRange = this.currentPeriod.range;
    if (reload) {
      this.accountManagementService.getUpcomingCompensationDeadlines(this.pagination, this.filterState)
        .pipe(
          takeUntil(this._destroy$),
        ).subscribe(
        data => {
          this.totalElements = data.content.length;
          this.upcomingCompensationDeadlinesFullList = data.content;
          this.upcomingCompensationDeadlines = this.upcomingCompensationDeadlinesFullList.slice(0, this.pagination.size);
        },
      );
    } else {
      this.upcomingCompensationDeadlines = this.upcomingCompensationDeadlinesFullList.slice(0, this.pagination.size);
    }
  }

  viewNumberOfItems(event, numOfItems = null) {
    event.preventDefault();
    this.pagination.size = numOfItems ? numOfItems : DEFAULT_NUM_OF_ITEMS;
    this.loadData();
  }

  changePeriod(period) {
    this.currentPeriod = period;
    this.loadData(true);
  }

  private setPeriods() {
    return [
      {
        id: 0,
        range:10,
        name: this.translateService.instant('ACCOUNTS_MODULE.upcomingDeadlines.next10Days'),
      },
      {
        id: 1,
        range:90,
        name: this.translateService.instant('ACCOUNTS_MODULE.upcomingDeadlines.next3Months'),
      },
      {
        id: 2,
        range:365,
        name: this.translateService.instant('ACCOUNTS_MODULE.upcomingDeadlines.nextYear'),
      },
    ];
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}

