import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { PROJECT_APPLICATION_TYPES, PROJECT_ID_KEY, PROJECT_TYPES } from '../consts/project-builder.const';

import { ProjectBuilderService } from '../project-builder.service';

import { cloneDeep } from 'lodash';
import { isNull } from 'util';
import { FormGroup } from '@angular/forms';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@shared/services/navigation.service';

export const PROJECT_BUILDER_FORM = 'PROJECT_BUILDER_FORM';

@Component({
  selector: 'app-project-builder-wrapper',
  templateUrl: './project-builder-wrapper.component.html',
  styleUrls: ['./project-builder-wrapper.component.scss']
})
export class ProjectBuilderWrapperComponent implements OnInit, OnDestroy {

  public viewReady = false;
  public projectId: string;
  public accountId: string;

  public apiCalled = false;
  public editMode = false;

  private _destroy$ = new Subject<any>();

  constructor(
    private location: Location,
    private activatedRoute: ActivatedRoute,
    public projectBuilderService: ProjectBuilderService,
    private projectService: ProjectService,
    public documentUploadInProgress: DocumentUploadInProgressService,
    private modalService: ModalService,
    private store: StoreService,
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) {
    this.projectBuilderService.subscriptions$ = new Subscription();
    this.projectBuilderService.projectForm = new FormGroup({});
   }

  ngOnInit() {
    document.body.classList.add('wide-body');
    this.accountId = this.activatedRoute.snapshot.params['accountId'];
    this.projectId = this.activatedRoute.snapshot.params['id'];
    this.projectBuilderService.projectType = this.activatedRoute.snapshot.params['type'];
    this.projectBuilderService.showAddToSummary = true;
    this.projectBuilderService.model.type = PROJECT_APPLICATION_TYPES[this.projectBuilderService.projectType];
    const stateData = history.state.data;
    if (stateData && stateData.historic) {
      this.projectBuilderService.historicData = true;
      this.projectBuilderService.showAddToSummary = false;
      this.setProjectBuilderModel(stateData);
      this.viewReady = true;
    } else if (this.projectId) {
      this.processUpdateProjectScenarioForReports();
      this.updateModel();
    } else {
      this.getAccountDetails();
    }
    this.projectBuilderService.changeActivityView.pipe(takeUntil(this._destroy$)).subscribe((value) => this.editMode = value);
  }

  public back() {
    if (!this.projectBuilderService.historicData &&
        this.projectBuilderService.projectForm.dirty && this.projectBuilderService.projectForm.touched) {
      const sub$ = this.projectBuilderService.openConfirmationModal(null, null, 'migrateAccountWarning').subscribe(result => {
        if (result) {
          this.redirectToPreviousLocation();
        }
        sub$.unsubscribe();
      })
    } else {
      this.redirectToPreviousLocation();
    }
  }

  public saveAsDraft() {
    const projectNameField = this.translateService.currentLang === 'en' ? 'name' : 'name2';
    if (!this.projectBuilderService.model[projectNameField] ||
        !this.projectBuilderService.model.activity.activityType) {
      const dialogRef = this.modalService.open(ServiceMessageComponent,
        {
          messages: [
            ...(!this.projectBuilderService.model.name ? [{
              message: 'projectNameRequired',
            }]:[]),
            ...(!this.projectBuilderService.model.activity.activityType ? [{
              message: 'projectTypeRequired',
            }]:[])
          ],
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      ).afterClosed().subscribe(() => {
        dialogRef.unsubscribe();
      });
    } else {
      this.apiCalled = false;
      const sub$ = this.projectBuilderService.validateIncorrectValuesRes$.pipe(
        filter((result: boolean) => {
          if (result && !this.apiCalled) {
            this.apiCalled = true;
            return true;
          } else {
            return false;
          }
        }),
        switchMap(() => {
          const payload = cloneDeep(this.projectBuilderService.model);
          delete payload.activity.location.file;
          return this.projectService.saveProject(payload);
        }),
        )
        .subscribe(data => {
          this.setProjectBuilderModel(data.entity);
          this.projectBuilderService.projectForm.reset(this.projectBuilderService.model);
          sub$.unsubscribe();
        });
        this.projectBuilderService.validateIncorrectValuesReq$.next();
    }
  }

  public submit() {
    this.apiCalled = false;
    const sub$ = combineLatest(
      this.projectBuilderService.validateActivityFormsRes$,
      this.projectBuilderService.validateDescriptionFormsRes$,
      this.projectBuilderService.validateRequiredDocsRes$)
      .pipe(
        takeUntil(this._destroy$),
        filter(([activityForm, descriptionForm, requiredDocs]) => {
          if (activityForm && descriptionForm && requiredDocs && !this.apiCalled) {
            this.apiCalled = true;
            return true;
          } else {
            return false;
          }
        }),
        switchMap(() => {
          const payload = cloneDeep(this.projectBuilderService.model);
          delete payload.activity.location.file;
          return this.projectBuilderService.updateProject ?
            this.projectService.saveProject(payload) : this.projectService.submitProject(payload);
        })
        )
        .subscribe(() => {
          sub$.unsubscribe();
          this.redirectToPreviousLocation();
        });
    this.projectBuilderService.validateFormsReq$.next();
  }

  public triggerAction(action) {
    let payload: any = {
      id: this.projectBuilderService.model.id,
      action: action.workflowAction
    };

    if (action.workflowAction === 'SUBMIT' || action.workflowAction === 'RESUBMIT') {
      this.submit();
      return;
    } else if (action.workflowAction === 'DISCARD') {
      const sub$ = this.projectBuilderService.openConfirmationModal(action.workflowAction)
        .pipe(
          takeUntil(this._destroy$),
          filter(result => result),
          switchMap(() => this.projectService.projectWorkflowAction(payload, 'discard'))
        )
        .subscribe(() => {
          sub$.unsubscribe();
          this.redirectToPreviousLocation();
        });
    }
  }

  private getAccountDetails() {
    const payload = {
      accountId: this.accountId,
    };
    this.projectService.searchProjectAccount(payload).subscribe(result => {
      this.projectBuilderService.model.id = result.id;
      this.projectBuilderService.model.accountId = this.accountId;
      this.projectBuilderService.model.account = result.account;
      this.projectBuilderService.model.activity = {id: result.projectActivityId};
      this.projectBuilderService.model = {...this.checkCreditingPeriodDates(this.projectBuilderService.model)};
      this.projectBuilderService.addPermission(result.entityActionList);
      this.viewReady = true;
    });
  }

  private updateModel() {
    let queryParams = {};
    if (this.projectBuilderService.reportId) {
      queryParams = { actionListRequired: true }
    }
    this.projectService.getProjectDetails(+this.projectId, queryParams, this.projectBuilderService.reportId).subscribe(result => {
      this.setProjectBuilderModel(result);
      if(this.projectBuilderService.updateProject){
        sessionStorage.setItem(PROJECT_ID_KEY, this.projectBuilderService.model.submissionsEditedProjectId);
      }
    });
  }

  private setProjectBuilderModel(result) {
    this.projectBuilderService.model = result;
    this.projectBuilderService.model.discardSubProjects = [];
    this.projectBuilderService.model.subProjects = result.subProjects || [];
    this.projectBuilderService.model.activity.isPublic = result.activity.isPublic;
    this.projectBuilderService.model.activity.municipality = result.municipality;
    this.projectBuilderService.isProjectPublicExistingValue = this.projectBuilderService.model.activity.isPublic;
    this.projectBuilderService.setSubprojectPreviouslyRegistered();
    this.projectBuilderService.addPermission(result.entityActionList);
    // if ( this.projectBuilderService.projectType === PROJECT_TYPES.AGGREGATED) {
    //   this.projectBuilderService.setAggregatedEmmissionsDetails();
    // }
    this.projectBuilderService.model = this.checkCreditingPeriodDates(this.projectBuilderService.model);
    this.viewReady = true;
    setTimeout(() => this.projectBuilderService.selectedCard = result.activity.activityType);
  }

  private checkCreditingPeriodDates(model) {
    return {...model,
     activity: {...model.activity,
        _creditingPeriodEndDateVisible: !!model.activity.creditingPeriodEndDate,
        _creditingPeriodStartDateVisible: !!model.activity.creditingPeriodStartDate,
     },
    };
  }

  private redirectToPreviousLocation() {
    // this.store.back();
    this.navigationService.back();
  }

  private processUpdateProjectScenarioForReports(){
    if (history.state.edit) {
      this.projectBuilderService.updateProject = true;
      this.projectBuilderService.reportId = history.state.reportId;
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('wide-body');
    this.projectBuilderService.clearData();
    this._destroy$.next();
    this._destroy$.complete();
    this.projectBuilderService.validateDescriptionFormsRes$.next(false);
    this.projectBuilderService.validateActivityFormsRes$.next(false);
    this.projectBuilderService.validateRequiredDocsRes$.next(false);
  }

}
