import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-fieldset',
  template: `
      <p-fieldset [id]="elementId" [styleClass]="className">
      <p-header>
        <span [innerHTML]="label | translate"></span>{{number}}{{starChar}}
        <fa-icon
         *ngIf="to.icon"
          pTooltip="{{to.icon?.text | translate}}"
          tooltipStyleClass="tooltip-min-width"
          [escape]="false"
          tooltipPosition="top"
          [icon]="['fas', 'info-circle']">
        </fa-icon>  
      </p-header>
        <ng-container #fieldComponent></ng-container>
      </p-fieldset>
`,
  styleUrls: ['./formly-fieldset.component.scss'],
})
export class FormlyFieldsetComponent extends FieldWrapper implements OnInit{

  translatePrefix: string;
  className: string;
  starChar

  constructor() {
    super();
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      if(this.field.wrappers.length >= 0 )
        return this.field.id

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;

    } else {
      return this.field.id;
    }
  }

  ngOnInit() {
    this.translatePrefix = this.to.translatePrefix ? `${ this.to.translatePrefix }.` : '';
    this.className = this.to.className || '';
  }

  get label() {
    const starChar = this.to.label.charAt(this.to.label.length-1);
    let label = this.to.label;
    if (starChar === '*') {
      this.starChar = starChar;
      label = this.to.label.slice(0,this.to.label.length-1);
    }
    return `${ this.translatePrefix }${ label }`;
  }
  get number() {
    if (this.to.includeNumber) {
      return this.field.parent.key ? ' ' + (parseInt(this.field.parent.key, 10) + 1) : '';
    } else {
      return '';
    }
  }

}
