import { Directive, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { truncate } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
const MAX_LENGTH = 150;
const WIDTH = 400;

@Directive({
  selector: '[truncate-value]',
})
export class TruncateValueDirective implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();

  observer: any;
  el: any;
  separator: ' ';
  popup: HTMLElement;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.el = this.elRef.nativeElement;

    this.translateService.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        setTimeout(() => this.truncateValue(true));

      });

    this.observer = new MutationObserver(mutations => {
      mutations.forEach(() => {
        this.truncateValue();
      });
    });

    const config = { attributes: true, childList: true, characterData: true };
    this.observer.observe(this.elRef.nativeElement, config);

  }

  truncateValue(removePrevious = false): void {
    const content = this.el.innerHTML;
    if (removePrevious) {
      this.removePopups();
    }
    if (content.length > MAX_LENGTH) {
      this.createPopup(this.el.parentElement, content);
      this.el.innerHTML = truncate(content, {
        length: 50,
        separator: this.separator,
        omission: '...',
      });
      this.el.parentElement.style.position = 'relative';

      this.zone.runOutsideAngular(() => {
        this.renderer.listen(this.el.parentElement, 'mouseenter', (event: MouseEvent) => this.handleMouseOver(event));
        this.renderer.listen(this.el.parentElement, 'mouseleave', (event: MouseEvent) => this.handleMouseLeaveParent(event));
      });
    }

    this.observer.disconnect();
  }

  private createPopup(el: any, content: string): void {
    this.popup = document.createElement('div');
    this.popup.innerHTML = content;
    this.popup.className = 'truncate-popup';
    this.popup.style.maxWidth = `${ WIDTH }px`;
    this.renderer.appendChild(el, this.popup);
  }

  private removePopups(): void {
    Array.from(this.el.parentElement.getElementsByClassName('truncate-popup')).forEach((p: HTMLElement) => p.remove());
  }

  handleMouseOver(event: Event): void {
    document.querySelectorAll('.truncate-popup.visible').forEach(el => el.remove());
    const target = event.currentTarget as HTMLElement;
    const popupEl = target.getElementsByClassName('truncate-popup')[0] as HTMLElement;
    const rect = target.children[0].getBoundingClientRect() as DOMRect;
    const wWidth = window.innerWidth;

    if (popupEl) {
      const clonedPopupEl = document.body.appendChild(popupEl.cloneNode(true)) as HTMLElement;

      if (rect.x + WIDTH >= wWidth) {
        clonedPopupEl.style.top = `${rect.y}px`;
        clonedPopupEl.style.left = `${rect.x - (clonedPopupEl.offsetWidth - (wWidth - rect.x) + 20)}px`;
      } else {
        clonedPopupEl.style.top = `${rect.y}px`;
        clonedPopupEl.style.left = `${rect.x}px`;
      }

      this.renderer.listen(clonedPopupEl, 'mouseout', (e: MouseEvent) => this.handleMouseLeave(e));
      this.renderer.addClass(clonedPopupEl, 'visible');
    }
  }

  handleMouseLeave(event): void {
    const popupEl = event.currentTarget as HTMLElement;
    this.renderer.removeClass(popupEl, 'visible');
    popupEl.remove();
  }

  handleMouseLeaveParent(event): void {
    if (event && event.relatedTarget && !event.relatedTarget.classList.contains('truncate-popup')) {
      document.querySelectorAll('.truncate-popup.visible').forEach(el => el.remove());
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.observer.disconnect();
  }

}
