/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/table-view/table-view.component.ngfactory";
import * as i2 from "../../components/table-view/table-view.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./formly-table-view";
var styles_FormlyTable = [];
var RenderType_FormlyTable = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyTable, data: {} });
export { RenderType_FormlyTable as RenderType_FormlyTable };
export function View_FormlyTable_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-view", [], null, [[null, "checkboxSelected"], [null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("checkboxSelected" === en)) {
        var pd_1 = (_co.onRowSelected($event) !== false);
        ad = (pd_1 && ad);
    } if (("paginationChanged" === en)) {
        var pd_2 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_TableViewComponent_0, i1.RenderType_TableViewComponent)), i0.ɵdid(1, 638976, null, 0, i2.TableViewComponent, [i0.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"], footerTemplate: [2, "footerTemplate"], relative: [3, "relative"], selections: [4, "selections"], isEditable: [5, "isEditable"], tableContainerMinWidth: [6, "tableContainerMinWidth"], disableOneRowSelection: [7, "disableOneRowSelection"] }, { paginationChanged: "paginationChanged", checkboxSelected: "checkboxSelected" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.to.config; var currVal_2 = _co.to.footerTemplate; var currVal_3 = true; var currVal_4 = _co.selection; var currVal_5 = _co.to.isEditable; var currVal_6 = _co.to.tableContainerMinWidth; var currVal_7 = _co.to.disableOneRowSelection; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_FormlyTable_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-table", [], null, null, null, View_FormlyTable_0, RenderType_FormlyTable)), i0.ɵdid(1, 114688, null, 0, i4.FormlyTable, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyTableNgFactory = i0.ɵccf("formly-table", i4.FormlyTable, View_FormlyTable_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyTableNgFactory as FormlyTableNgFactory };
