import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { IApp } from '@core/models/app.interfaces';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { AppInjector } from '@shared/services/app-injector.service';

@Component({
  selector: 'app-base-actions',
  template: '<div></div>',
})
export class BaseActionsComponent implements OnDestroy {

  dummyModel;
  _destroy$ = new Subject<any>();
  @Input() actionsList: IApp.IEntityAction[];
  translateService: any;

  constructor() {
    const injector = AppInjector.getInjector();
    this.translateService = injector.get(TranslateService);
    this.translateService.onLangChange
      .pipe(
        takeUntil(this._destroy$),
      ).subscribe((event: LangChangeEvent) => {
        this.setActions();
      });
  }

  resetSelection() {
    setTimeout(() => {
      this.dummyModel = null;
      (document.activeElement as HTMLElement).blur();
      }, 0,
    );
  }

  setActions() {
    if (this.actionsList) {
      this.actionsList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`));
      this.actionsList = [...this.actionsList];
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
