export const CCR_PREFIX = 'CREDIT_CREATION_REPORT';
export const CCR_STEP_PREFIX = '.reportStep';
export const CCR_ACTIVITY_PREFIX = '.reportActivity';
export const CCR_CREATE_POPUP = '.createReport';
export const CCR_CHARGING_STATION_ADDRESS_POPUP = '.chargingStationAddress';
export const CCR_TYPE_PREFIX = 'CREDIT_CREATION_REPORT.reportTypes';

export const CCR_ORGANIZATION_NAME_FLD = 'accountId';
export const CCR_TYPE_FLD = 'reportType';
export const CCR_ACTIVITY_CODE_FLD = 'activityCode';
export const CCR_COMPLIANCE_PERIOD_FLD = 'compliancePeriod';
export const CAR_COMPLIANCE_PERIOD_FLD = 'carCompliancePeriod';
export const CCR_QUARTER_FLD = 'quarterCode';
export const CCR_FACILITY_NAME_FLD = 'facilityName';

// STEP table row data
export const CCR_FACILITY_FLD = 'facility';
export const CCR_FACILITY_EQUIPMENT = 'facilityEquipment';
export const CCR_PROVINCE_FLD = 'province';
export const CCR_FACILITY_ID_FLD = 'facility.id';
export const CCR_FUEL_FLD = 'fuel';
export const CCR_FUEL_ID_FLD = 'fuel.id';
export const CCR_CI_ID_FLD = 'ciApplicationId';
export const CCR_ALPHANUMERIC_CI_FLD = 'alphanumericId';
export const CCR_CI_ID = 'ciID';
export const CCR_CI_LIQUID_CI_FUEL_FLD = 'approvedCliValue';
export const CCR_CI_LIQUID_CI_FUEL_ID = 'approvedCliId';
export const CCR_CI_INSTRUMENT_ID = 'instrumentId';
export const CCR_CI_LIQUID_CI_FUEL_HYDROGEN_LABEL = 'approvedCliValueHydrogen';
export const CCR_CI_LIQUID_CI_FUEL_GAS_LABEL = 'approvedCliValueGas';
export const CCR_CI_LIQUID_CI_FUEL_BIOGAS_LABEL = 'approvedCliValueBiogas';
export const CCR_FEEDSTOCK_NAME_FLD = 'feedstockName';
export const CCR_FEEDSTOCK_REGION_FLD = 'feedstockRegion';
export const CCR_LO_CI_FUEL_ENERGY_DENSITY_FLD = 'energyDensity';
export const CCR_REFERENCE_CI_OF_ELECTRICITY_CODE_FLD = 'referenceCiOfElectricityCode';
export const CCR_LO_CI_FUEL_ENERGY_DENSITY_HYDROGEN_LABEL = 'energyDensityHydrogen';
export const CCR_LO_CI_FUEL_ENERGY_DENSITY_GAS_LABEL = 'energyDensityGas';
export const CCR_LO_CI_FUEL_QUANTITY_FLD = 'complianceCreditQuantity';
export const CCR_LO_CI_FUEL_QUANTITY_ = 'complianceCreditQuantity';
export const CCR_LO_CI_FUEL_QUANTITY_GAS_LABEL = 'complianceCreditQuantityGas';
export const CCR_FUEL_REPLACEMENT_FLD = 'fuelReplacementCode';
export const CCR_FUEL_REPLACEMENT_TYPE = 'fuelReplacementType';
export const CCR_CREDITS_TYPE = 'creditType';
export const CCR_CREDITS_TYPE_ID = 'creditTypeId';
export const CCR_CREDITS_TYPE_FLD = 'typeOfCredit';
export const CCR_CREDITS_TYPE_OBJ = 'creditType.id';
export const CCR_LIQUID_COMPLIANCE_CREDITS_FLD = 'calculatedComplianceCredits';
export const CCR_UPDATED_COMPLIANCE_CREDITS_FLD = 'updatedComplianceCredits';
export const CCR_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL = 'gaseousComplianceCreditsCreated';
export const CCR_OVERRIDE_CREDITS_NUMBER_FLD = 'overrideComplianceCredits';
export const CCR_COMPLIANCE_CREDIT_CREATED_VALUE = 'complianceCreditsCreated';
export const CCR_UPDATED_COMPLIANCE_CREDIT_VALUE = 'updatedComplianceCreditsCreated';
export const CCR_REASON_FOR_OVERRIDING_FLD = 'reason';
export const CCR_SEARCH_BUTTON = 'search';
export const CCR_CLEAR_BUTTON = 'clear';
export const CCR_VIEW_PROJECT_INFO = 'viewProjectInfo';
export const CCR_VIEW_CONTACT_PERSON_INFO = 'viewContactPerson';
export const CCR_NAME_OF_QUARTER = 'nameOfQuarter';
export const CCR_FUELING_STATION_REGISTERED = 'fuelingStationRegisteredInCats';
export const CCR_FACILITY_DIFFERENT_FROM_BIOGAS ='facilityDifferentFromBiogas';
export const CCR_ELECTRICITY_FACILITY_NAME ='electricityFacilityName';
export const CCR_ELECTRICITY_FACILITY_CIVIC_ADDRESSS ='civicAddressOfElectricityFacility';
export const CCR_ORGNIZATION_ID = 'searchedOrganizationId';
export const CCR_ORGNIZATION_ID_PLACEHOLDER = 'searchedOrganizationIdPlaceholder';
export const CCR_STATION_ID = 'searchedStationId';
export const CCR_STATION_ID_PLACEHOLDER = 'searchedStationIdPlaceholder';
export const CCR_FUELING_STATION_OWNER_NAME = 'fuelingStation.accountName';
export const CCR_FUELING_STATION_OWNER_NAME_LABEL = 'fuelingStationOwnerName';
export const CCR_FUELING_STATION_NAME = 'fuelingStation.name';
export const CCR_FUELING_STATION_NAME_LABEL = 'fuelingStationName';
export const CCR_FACILITY_CIVIC_ADDRESS = 'facilityCivicAddress';
export const CCR_FACILITY_FUELING_STATION_CIVIC_ADDRESS = 'facilityFulingStationCivicAddress';
export const CCR_FACILITY_FUEL_CIVIC_ADDRESS = 'facilityFuelCivicAddress';
export const CCR_POSTAL_ADDRESS = 'postalAddress';
export const CCR_CIVIC_ADDRESS = 'civicAddress';
export const CCR_FUELING_STATION_POSTAL_ADDRESS = 'fuelingStationPostalAddress';
export const CCR_FUELING_STATION_CIVIC_ADDRESS = 'fuelingStationCivicAddress';
export const CCR_FUELING_STATION_POSTAL_ADDRESS_FLD = 'fuelingStation.postalAddress';
export const CCR_FUELING_STATION_CIVIC_ADDRESS__FLD = 'fuelingStation.civicAddress';
export const CCR_FACILITY_CIVIC_ADDRESS_FLD = 'facility.civicAddress';
export const CCR_FOREIGN_SUPPLIER_NAME = 'nameOfForeginSupplier';
export const CCR_FOREIGN_SUPPLIER_CIVIC_ADDRESS = 'civicAddressOfForeignSupplier';
export const CCR_FACILITY_EQUIPMENT_CIVIC_ADDRESS = 'facilityEquipmentAddress';
export const CCR_TRANSFEREE = 'transfereeName';
export const CCR_CARBON_INTENSITY_OF_FUEL = 'carbonIntensityOfFuel';
export const CCR_NUMBER_OF_CREDITS_TO_BE_TRANSFERED = 'numberOfCreditsToBeTransfered';
export const CCR_ELECTRICAL_ENERGY_OUTPUT = 'electricalEnergyOutput';
export const CCR_HEAT_ENERGY_PRODUCED = 'heatEnergyProduced';
export const CCR_ELECTRICAL_ENERGY_PRODUCED = 'electricalEnergyProduced';
export const CCR_REFERENCE_CI_OF_ELECTRICITY = 'referenceCiOfElectricity';
export const CCR_CI_OF_ELECTRICITY_PRODUCED = 'carbonIntensityElecProducedUsingBiogas';
export const CCR_SECTION_VALIDATION_EQUATION = 'sectionValidationEquation';
export const CCR_CI_OF_ELECTRICITY_ASSOCIATED_WITH_QUANTITY_FLD = 'carbonIntensityOfElectricity';
export const CCR_SECTION_FLD = 'section';
export const CCR_TOTAL_QTY_BIOGAS = 'totalQuantityOfBiogas';


export const CCR_TOTAL_CREDITS_TO_BE_ISSUED_FLD = 'totalCreditsToBeIssued';
export const CCR_TOTAL_FINAL_CREDITS_ISSUED_FLD = 'finalCreditsIssued';
export const CCR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED = 'totalLiquidGasolineCreditsCreated';
export const CCR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED = 'totalLiquidDiselCreditsCreated';
export const CCR_TOTAL_LIQUID_OTHER_CREDITS_CREATED = 'totalLiquidOtherCreditsCreated';
export const CCR_TOTAL_GASEOUS_CREDITS_CREATED = 'totalGaseousCreditsCreated';
export const CCR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED = 'totalLiquidCreditsCreated';

export const CCR_TOTAL_LIQUID_GASOLINE_CREDITS_TO_BE_TRANSFERED = 'totalLiquidGasolineCreditsToBeTransfered';
export const CCR_TOTAL_LIQUID_DIESEL_CREDITS_TO_BE_TRANSFERED = 'totalLiquidDieselCreditsToBeTransfered';
export const CCR_TOTAL_LIQUID_OTHER_CREDITS_TO_BE_TRANSFERED = 'totalLiquidOtherCreditsToBeTransfered';
export const CCR_TOTAL_LIQUID_CREDITS_TO_BE_TRANSFERED = 'totalLiquidCreditsToBeTransfered';
export const CCR_TOTAL_GASEOUS_CREDITS_TO_BE_TRANSFERED = 'totalGaseousCreditsToBeTransfered';

export const CCR_SUMMARY_CREDITS_TO_DEPOSIT_FLD = 'creditsToDepositInAccount';
export const CCR_SUMMARY_CREDITS_TO_DEPOSIT_IN_OTHER_ACCOUNT_FLD = 'creditsToDepositInOtherAccount';
export const CCR_SUMMARY_FINAL_CREDITS_TO_DEPOSIT = 'finalCreditsToDepositInAccount';

export const CCR_EQUIPMENT_SERIAL_NO_FLD = 'serialNumberOfEquipement';
export const CCR_PROJECT_ID_FLD = 'erpProjectId';
export const CCR_CONTACT_PERSON_NAME_FLD = 'contactPerson';
export const CCR_INVOLVED_IN_ACTIVITIES = 'involvedInActivitiesForComplianceCredits';

export const CCR_ANNUAL_COMPLIANCE_CREDIT_CREATED_VALUE_FLD = 'complianceCreditsCreated';
export const CCR_ANNUAL_COMPLIANCE_CREDIT_CREATED_VALUE = 'annualComplianceCreditsCreated';
export const CCR_ANNUAL_ENTER_CREDIT_INFO_LABEL = 'enterCreditInfoLabel';
export const CCR_ANNUAL_CO2_LIQUID_CREDITS = '_liquidCredits';
export const CCR_ANNUAL_CO2_GASEOUS_CREDITS = '_gaseousCredits';
export const CCR_ANNUAL_CO2_LIQUID_QUANTITY = '_liquidQuantity';
export const CCR_ANNUAL_CO2_GASEOUS_QUANTITY = '_gaseousQuantity';
export const CCR_ANNUAL_CORRESPONDING_VOLUME = 'annualCorrespondingVolumeOfLowCIFuel';

export const CCR_FUELING_STATION_FLD = 'fuelingStation';
export const CCR_FUELING_STATION_HYDROGEN_FLD = 'fuelingStationHydrogen';
export const CCR_FUELING_STATION_FUEL_FLD = 'fuelingStationFuel';
export const CCR_FUELING_STATION_RENEWABLE_FUEL_FLD = 'fuelingStationRenewableFuel';
export const CCR_QUANTITY_VEHICLE_FUEL_FLD = 'quantityOfFuelMeasured';
export const CCR_QUANTITY_RENEWABLE_VEHICLE_FUEL_FLD = 'quantityOfFuelSupplied';
export const CCR_QUANTITY_FUEL_SUPPLIED_TO_VEHICLE_FLD = 'complianceCreditQuantity';
export const CCR_ANNUAL_LIQUID_COMPLIANCE_CREDITS_GAS_LABEL = 'gaseousAnnualComplianceCreditsCreated';
export const CCR_EDITED_PROJECT_DETAILS = 'erpEditedProject';
export const CCR_FUEL_PURCHASE_PERSON_NAME = 'fuelSellerName';
export const CCR_VOLUME_RENEWABLE_VEHICLE_FUEL = 'quantityOfFuelSupplied';
export const CCR_ENERGY_EFFICIENCY_RATIO = 'energyEfficiencyRatio';
export const CCR_DEFAULT_ENERGY_EFFICIENCY_RATIO_FLD = 'defaultEnergyEfficiencyRatio';
export const CCR_ELECTRICITY_SUPPLIED_TO_PROVINCE_FLD = 'complianceCreditQuantity';
export const CCR_ELECTRICITY_SUPPLIED_TO_PROVINCE = 'electricitySuppliedToProvince';
export const CCR_ELECTRICITY_SUPPLIED_TO_VEHICLES = 'electricitySuppliedToVehicles';
export const CCR_PROVINCE_TERRITORY = 'provinceTerritory';
export const CCR_CHARGING_STATION_FLD = 'chargingStation';
export const CCR_CHARGING_STATION_LAT_FLD = 'chargingStation.civicAddress.latitude';
export const CCR_CHARGING_STATION_LON_FLD = 'chargingStation.civicAddress.longitude';
export const CCR_CHARGING_STATION_ID_FLD = 'chargingStationId';
export const CCR_CHARGING_STATION_NAME_FLD = 'chargingStationName';
export const CCR_ACCESSIBILITY_TO_MARINE_VESSELS = 'chAccesbilityToMarineVessels';
export const CCR_PHYSICAL_ADDRESS_OF_LOCATION = 'physicalAddressOfLocation';
export const CCR_VIEW_ADDRESS = 'viewAddressDetails';
export const CCR_EDIT_ADDRESS = 'editAddressDetails';
export const CCR_CHARGING_STATION_PHYSICAL_ADDRESS_FLD = 'chargingStation.physicalAddress';
export const CCR_TOTAL_NUMBER_OF_LICOMP_ELECTRICITY = 'totalNumberOfLiquidAnnualStep5';
export const CCR_QUANTITY_OF_HYDROGEN = 'quantityOfHydrogen';
export const CCR_HYDROGEN_ENERGY_EFFICIENCY_RATIO_LABEL = 'energyEfficiencyRatioHydrogen';

export const CCR_MANUAL_ENERGY_DENSITY = 'manualEnergyDensity';
export const CCR_MANUAL_ENERGY_DENSITY_VALUE = 'manualEnergyDensityValue';
export const CCR_AGREEMENT_IDENTIFIER = 'agreementToCreditId';

export const CCR_OPINION_TYPE = 'opinionType';
export const CCR_CONCLUSION_COMMENT = 'conclusionComment';
export const CCR_VERIFICATION_BODY = 'vbName';

export const CCR_MATERIAL_CHANGE = 'materialChange';
export const CCR_VOLUNTARY_SUBMISSION = 'voluntarilySubmission';


export const CCR_SUMMARY_FIELDS = {
  'STEP-1': [
    CCR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED,
    CCR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED,
    CCR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED,
  ],
  'STEP-2': [CCR_TOTAL_GASEOUS_CREDITS_CREATED],
  'STEP-3': [CCR_TOTAL_GASEOUS_CREDITS_CREATED],
  'STEP-4': [
    CCR_TOTAL_LIQUID_GASOLINE_CREDITS_CREATED,
    CCR_TOTAL_LIQUID_DIESEL_CREDITS_CREATED,
    CCR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED,
  ],
  'STEP-5': [CCR_TOTAL_GASEOUS_CREDITS_CREATED],
  'STEP-6': [CCR_TOTAL_TOTAL_LIQUID_CREDITS_CREATED],
  'STEP-7': [],
  'STEP-8': [CCR_TOTAL_GASEOUS_CREDITS_CREATED]
};

export const CCR_DEFAULT_ENERGY_EFFICIENCY_RATIO = 2.5;
export const CCR_DEFAULT_HYDROGEN_ENERGY_EFFICIENCY_RATIO = 1.5;

export const CCR_YES_NO_ITEMS = [
  { id: 'yes', name: 'prYes', value: true },
  { id: 'no', name: 'prNo', value: false },
];

export const CCR_FUEL_TYPE = {
  LIQUID: 'LIQUID',
  GAS: 'GAS',
};

export const CCR_DATE_ONLY_FORMAT = 'YYYY-MM-DD';

export const CCRApplyPrefix = (value: string, subPrefix: string = ''): string => `${CCR_PREFIX}${subPrefix}.${value}`;

export const CCR_DOCUMENTS_ENTITY_ANNUAL = 'CREDIT_CREATION_REPORT_ANNUAL';
export const CCR_DOCUMENTS_ENTITY_ANNUAL_STEP1 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP1';
export const CCR_DOCUMENTS_ENTITY_ANNUAL_STEP3 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP3';
export const CCR_DOCUMENTS_ENTITY_ANNUAL_STEP4 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP4';
export const CCR_DOCUMENTS_ENTITY_ANNUAL_STEP5 = 'CREDIT_CREATION_REPORT_ANNUAL_STEP5';

export const CCR_POSTAL_ADDRESS_FORM_SECTION = {
  prefix: `${ CCR_PREFIX }${ CCR_CHARGING_STATION_ADDRESS_POPUP }.`,
  key: 'postalAddress',
  changeExpr: 'this.cloneField($event, field, \'civicAddress\', \'civicAddress.sameAsPostalAddress\')',
  provinceStateLabel: 'CREDIT_CREATION_REPORT.reportStep.provinceTerritory',
  sameAsOrganization: {
      hide: false,
      changeExpr: null,
  },
  hidePoBox: false,
  sameAsPostal: {
      hide: true,
  },
  hideLatLong: true,
  optional: true,
  disableCountryField: true,
};

export const CCR_CIVIC_ADDRESS_FORM_SECTION = {
  prefix: `${ CCR_PREFIX }${ CCR_CHARGING_STATION_ADDRESS_POPUP }.`,
  key: 'civicAddress',
  provinceStateLabel: 'CREDIT_CREATION_REPORT.reportStep.provinceTerritory',
  sameAsOrganization: {
      hide: true,
  },
  hidePoBox: true,
  sameAsPostal: {
      hide: false,
      changeExpr: 'this.disableForm($event, field, null, \'postalAddress\');  this.cloneFormGroup($event, field, \'postalAddress\', \'civicAddress\')',
  },
  hideLatLong: true,
  optional: true,
  disableCountryField: true,
};

export const CREDIT_VOLUME_MAP = {
  _liquidCredits: '_liquidQuantity',
  _gaseousCredits: '_gaseousQuantity'
};
