/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./step-header.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../shared/services/helper.service";
import * as i7 from "../../agreement-credits.service";
import * as i8 from "../../../../core/store/store.service";
var styles_StepHeaderComponent = [i0.styles];
var RenderType_StepHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepHeaderComponent, data: {} });
export { RenderType_StepHeaderComponent as RenderType_StepHeaderComponent };
export function View_StepHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "overflow-auto"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "float-left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ": ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-secondary float-right"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToRegistrationReport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("AGREEMENT_CREDITS.Step.registeredCreator")); var currVal_1 = _co.data.legalName; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("AGREEMENT_CREDITS.Step.viewRegistrationReport")); _ck(_v, 6, 0, currVal_2); }); }
export function View_StepHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-step-header", [], null, null, null, View_StepHeaderComponent_0, RenderType_StepHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i4.StepHeaderComponent, [i5.Router, i6.HelperService, i7.AgreementCreditsService, i8.StoreService], null, null)], null, null); }
var StepHeaderComponentNgFactory = i1.ɵccf("app-step-header", i4.StepHeaderComponent, View_StepHeaderComponent_Host_0, { data: "data" }, {}, []);
export { StepHeaderComponentNgFactory as StepHeaderComponentNgFactory };
