<div class="inner" *ngIf="viewReady">
  <div class="stepper-section">
    <app-progress-bar
      [steps]="registrationSteps"
      [current]="currentStep.id"
      [currentSubStep]="currentStep.subId"
      [maxStep]="maxStep"
      (changeSubStep)="onStepChange($event)">
    </app-progress-bar>
  </div>
  <div class="content-section">
    <div class="scrollable-area" #formContainer>
      <div class="content">
     <!--   <app-step-title [data]="applicationData" [type]="applicationType" [parentComponent]="this"></app-step-title>-->
        <ng-template appStepAnchor></ng-template>
      </div>
    </div>
    <div class="footer">

       <ng-container *ngFor="let entityAction of entityActions">
          <button *ngIf= "entityAction.enabled" class="btn btn-primary ml-2 float-right"
                type="button"
                disableOnUpload
                (click)="triggerActionController(entityAction)">
                {{ 'COMMON.actionsLabel.' + entityAction.workflowAction | translate }}
          </button>
          <button *ngIf= "isLastStep && !entityAction.enabled" class="btn btn-primary ml-2 float-right"
                type="button"
                disableOnUpload
                (click)="triggerActionController(entityAction)">
                {{ 'COMMON.actionsLabel.' + entityAction.workflowAction | translate }}
          </button>
        </ng-container>

      <button class="btn btn-primary ml-2 float-right"
              *ngIf="(currentStep.showSubmit  && !footerButtonConfig.showSubmit )|| (isLastStep && footerButtonConfig.showSubmit)"
              type="button"
              disableOnUpload
              (click)="submit()">
              {{ service.translationPrefix + submitButtonPrefix | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right"
              *ngIf="!isLastStep && subStepsChooserSelected"
              type="button"
              disableOnUpload
              (click)="nextStep()">
        {{'COMMON.wizard.nextStepButton' | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right"
              *ngIf="!isFirstStep && !currentStep.onlyClose"
              type="button"
              disableOnUpload
              (click)="prevStep()">
              {{'COMMON.wizard.backToPreviousStepButton' | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right"
              *ngIf="footerButtonConfig.showSave && !currentStep.onlyClose && !service.hideSaveAsDraft"
              type="button"
              [disabled]="!service.isDirty"
              disableOnUpload
              (click)="saveAsDraft()">
        {{'COMMON.wizard.SaveasDraft' | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right" type="button"  disableOnUpload (click)="close()">
        {{'COMMON.wizard.close' | translate}}
      </button>
    </div>
  </div>
</div>
