import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { REGISTRATION_TYPE } from '@shared/components/stepper/stepper.const';
import { ComingsoonService } from '@core/services/comingsoon.service';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent{

  private _destroy$ = new Subject<any>();
  //eventually this will be displayed by a message
  //also check the momentjs function isTodayAfterEffectiveDate might help
  //if we turn this automatic
  public message: string = ' OMP (Other Market Participant) registration will be available after February 15, 2021';
  public visible = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ComingSoonComponent>,
    private comingsoonService: ComingsoonService,
    private translateService: TranslateService
  ) {

    switch(data.registration){
      case REGISTRATION_TYPE.CFS:
        this.message = this.translateService.instant('REGISTRATION_PAGE.stepperSection.cfsAvailabilityMessage');
        this.message = this.message+ ' ' + data.value;
        break;
      
      case REGISTRATION_TYPE.NFMP:
        this.message = this.translateService.instant('REGISTRATION_PAGE.stepperSection.nfmpAvailabilityMessage');
        this.message = this.message + ' ' + data.value;
        break;
        
      case REGISTRATION_TYPE.PUBLIC_BODY_GOVERNMENT:
          this.message = this.translateService.instant('REGISTRATION_PAGE.stepperSection.publicGovernment');
          break;
    }
   }

  close(){
    this.dialogRef.close(true);
  }
}
