import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { ActivityLogFilterModel } from '@core/models/filter.model';
import { CurrentUser } from '@core/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { LoaderService } from '@core/services/loader.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { TranslateService } from '@ngx-translate/core';
import { ActionPanelService } from '@shared/services/action-panel.service';
import { HelperService } from '@shared/services/helper.service';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-enhanced-user-dashboard',
  templateUrl: './enhanced-user-dashboard.component.html',
  styleUrls: ['./enhanced-user-dashboard.component.scss'],
})
export class EnhancedUserDashboardComponent implements OnInit, OnDestroy {

  @ViewChild('facilityNameNgSelect', {static: true}) facilityNameNgSelect;

  public isLoading = false;

  private _destroy$ = new Subject<any>();
  private _stopPrevious$ = new Subject<any>();

  public facilityNames: IApp.ILookup[];
  public years = {};
  public nfmp: boolean;
  public labelPrefix: string;
  public currentUser: CurrentUser;
  public isParticipant: boolean;

  public accountData: IApp.IAccountData;
  public creditSummary: IApp.ICreditSummary = {};
  public obligationSummary: IApp.IAccountObligation = {};
  public topTransactions: IApp.IActivityLog[];
  private accountId: any;

  constructor(
    private lookupService: LookupService,
    private actionPanelService: ActionPanelService,
    private accountManagementService: AccountManagementService,
    private store: StoreService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.currentUser = this.store.user;
    this.labelPrefix = this.store.user.getProgramPrefix();
    this.nfmp = this.store.user.isNonObpsAccount;

    if (this.currentUser.hasPermission('MENU_REGISTRATION_PENDING')) {
      this.authService.changeProgram(this.currentUser.programId)
        .pipe(takeUntil(this._destroy$))
        .subscribe(() => {
          if (this.store.user.menuItems.length !== this.currentUser.menuItems.length) {
            this.router.navigateByUrl('/');
          }
        });
    }

    this.actionPanelService.broadcastAccountId$
      .pipe(
        filter(d => d !== null),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe(
        accountId => {
          this.getAccount(accountId);
          this.accountId = accountId;
        },
      );

    this.translateService.onLangChange
       .pipe(takeUntil(this._destroy$))
       .subscribe(() => this.getAccount(this.accountId));
  }

  getAccount(account: any) {
    this.isLoading = true;
    this.accountData = this.actionPanelService.account;
    this._stopPrevious$.next();
    const pagination = {
      page: 0,
      size: 5,
    } as IApp.IPagination;

    const filterState: ActivityLogFilterModel = {
      facilityNameList: [account.id ? account.id : account],
    };

    if (this.store.user.isOBPS()) {
      combineLatest([
        this.accountManagementService.getOBPSCreditSummary(account.id ? account.id : account),
        this.accountManagementService.getOBPSObligationSummary(account.id ? account.id : account),
        this.accountManagementService.getLatestTransactions(pagination, filterState),
      ]).pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(([creditSummary, obligationSummary, latestTransactions]) => {
        this.creditSummary = creditSummary;
        this.accountManagementService.formatCreditSummary(this.creditSummary);
        this.obligationSummary = obligationSummary;
        this.accountManagementService.formatObligationSummary(this.obligationSummary);
        this.topTransactions = latestTransactions.content;
        setTimeout(() => this.isLoading = false);
      });
    } else if (this.store.user.isCFR()) {
      combineLatest([
        this.accountManagementService.getOBPSCreditSummary(account.id ? account.id : account),
        this.accountManagementService.getAccountDetails(account.id ? account.id : account),
        this.accountManagementService.getLatestTransactions(pagination, filterState),
      ]).pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(([creditSummary, accountResponse, latestTransactions]) => {
        this.isParticipant = accountResponse.accountTypeName === 'PARTICIPANT';
        this.creditSummary = creditSummary;
        this.accountManagementService.formatCreditSummary(this.creditSummary);
        this.obligationSummary = {};
        this.accountManagementService.formatObligationSummary(this.obligationSummary);
        this.topTransactions = latestTransactions.content;
        setTimeout(() => this.isLoading = false);
      });
    }  else if (this.store.user.isOffset() || this.store.user.isCM()) {
      combineLatest([
        this.accountManagementService.getOBPSCreditSummary(account.id ? account.id : account),
        this.accountManagementService.getOBPSObligationSummary(account.id ? account.id : account),
        this.accountManagementService.getLatestTransactions(pagination, filterState),
      ]).pipe(
        takeUntil(this._destroy$),
        takeUntil(this._stopPrevious$),
      ).subscribe(([creditSummary, obligationSummary, latestTransactions]) => {
        this.topTransactions = latestTransactions.content;
        this.creditSummary = creditSummary;
        this.accountManagementService.formatCreditSummary(this.creditSummary);
        this.obligationSummary = obligationSummary;
        this.accountManagementService.formatObligationSummary(this.obligationSummary);
        setTimeout(() => this.isLoading = false);
      });
    } else {
      this.isLoading = false;
    }
  }

  viewProjects() {
    this.router.navigate(this.helperService.getTranslatedPath(
      '/account-management/summary-projects'),
      {state: {accountId: this.accountId}},
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  format(count: number) {
    return this.accountManagementService.formatCreditCount(count);
  }

}
