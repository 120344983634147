import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { Observable } from 'rxjs';

export const PROJECT_DETAILS = '/offset-service/project/getById';
export const PROJECT_TRANS_REQ = '/offset-service/projectTransferRequest/getById';
export const GETPROJECTTRANSFER = '/offset-service/projectTransferRequest/get';
export const PROJ_TRANS_REQ_SUBMIT = '/offset-service/projectTransferRequest/submit';
export const PROJ_TRANS_REQ_SUBMIT_PENDING = '/offset-service/projectTransferRequest/pendingSubmit';
export const PROJ_TRANS_REQ_NEED_INFO = '/offset-service/projectTransferRequest/needMoreInfo';
export const PROJ_TRANS_REQ_RE_SUBMIT = '/offset-service/projectTransferRequest/resubmit';
export const PROJ_TRANS_REQ_REJECT = '/offset-service/projectTransferRequest/reject';
export const PROJ_TRANS_REQ_APPROVE = '/offset-service/projectTransferRequest/approve';

export const PROJ_TRANS_REQ_CANCEL = '/offset-service/projectTransferRequest/cancel';

export const PROJ_TRANS_REQ_APPROVE_2 = '/offset-service/projectTransferRequest/approve2';
export const PROJ_TRANS_REQ_REJECT_2 = '/offset-service/projectTransferRequest/rejection/reject';
export const PROJ_TRANS_REQ_REJECTION_APPROVE_2 = '/offset-service/projectTransferRequest/rejection/approve';
export const PROJ_TRANS_REQ_SAVE = '/offset-service/projectTransferRequest/save';
export const PROJ_REJECT_TRANSFER = '/offset-service/projectTransferRequest/rejectTransfer';
export const PROJ_ACCEPT_TRANSFER = '/offset-service/projectTransferRequest/accept';
export const GET_PENDING_TRANSFER_PROJECT = '/offset-service/project/getPendingTransferProject';
export const SEND_PROPONENT_CONSENT = '/offset-service/project/sendConsent';

export const SEACH_DST_ACCOUNT = '/account-service/offsetAccount/loadById/';

export const SEARCH_ACC_PROJ = '/offset-service/project/searchProject';
export const GETUNITS = '/obps-service/unit/findAll?size=20';
@Injectable({
  providedIn: 'root',
})
export class ProjectTransferService extends BaseStepperService {

  constructor(private http: HttpClient) {
    super();
  }

  public getUnits(req): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${GETUNITS}`, req );
  }

  public getProjectDetailsPromise(projectId: string) {
    return this.http.get<any>(`${environment.apiUrl}${PROJECT_DETAILS}/${projectId}`).toPromise();
  }

  public getProjectTransferDetailsPromise(projectId: string) {
    return this.http.get<any>(`${environment.apiUrl}${PROJECT_TRANS_REQ}/${projectId}`).toPromise();
  }

  public getPrevProjectTransferDetailsPromise(projectId: string) {
    return this.http.get<any>(`${environment.apiUrl}${GETPROJECTTRANSFER}/${projectId}`).toPromise();
  }

  public getPendingTransferProjects(projectId: string, projectPid: string, srcAccountId: string) {
    return this.http.get<any>(`${environment.apiUrl}${GET_PENDING_TRANSFER_PROJECT}/${projectId}/${projectPid}/${srcAccountId}`).toPromise();
  }

  public getDestinationAccount(id: any): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${SEACH_DST_ACCOUNT}${id}`);
  }

  submit(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_SUBMIT}`, payload);
  }

  pendingSubmit(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_SUBMIT_PENDING}`, payload);
  }

  needmoreinfo(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_NEED_INFO}`, payload);
  }

  resubmit(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_RE_SUBMIT}`, payload);
  }

  reject(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_REJECT}`, payload);
  }

  approve(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_APPROVE}`, payload);
  }

  cancelProjecTranser(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_CANCEL}`, payload);
  }

  approve2(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_APPROVE_2}`, payload);
  }

  reject2(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_REJECT_2}`, payload);
  }

  rejectionApprove(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_REJECTION_APPROVE_2}`, payload);
  }

  save(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_TRANS_REQ_SAVE}`, payload);
  }

  rejectTransfer(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_REJECT_TRANSFER}`, payload);
  }

  accpetTransfer(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${PROJ_ACCEPT_TRANSFER}`, payload);
  }

  sendConsent(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${SEND_PROPONENT_CONSENT}`, payload);
  }

  searchAccountProject(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${SEARCH_ACC_PROJ}`, payload);
  }
}
