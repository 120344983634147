import { Component, OnDestroy, OnInit , Input} from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { compensationObligationTableConfig } from '@module/account-management/account-management.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ICompensationObligation = IApp.ICompensationObligation;

@Component({
  selector: 'app-compensation-obligation-history',
  templateUrl: './compensation-obligation-history.component.html',
  styleUrls: ['./compensation-obligation-history.component.scss'],
})
export class CompensationObligationHistoryComponent implements OnInit, OnDestroy {

  @Input() accountId:number;
  compensationObligationTableConfig = compensationObligationTableConfig;
  data: any;
  private _destroy$ = new Subject<any>();

  constructor(
    private accountManagementService: AccountManagementService,
  ) { }

  ngOnInit() {
  
    this.accountManagementService.getOBPSAccountObligations(this.accountId).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      data => {
        this.data = data.content;
      },
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
