/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-agreement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/upload-pending-documents/upload-pending-documents.component.ngfactory";
import * as i3 from "../../../../shared/components/upload-pending-documents/upload-pending-documents.component";
import * as i4 from "../../../../core/store/store.service";
import * as i5 from "@angular/common";
import * as i6 from "./upload-agreement.component";
import * as i7 from "../../agreement-credits.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "../../../../core/services/lookup.service";
import * as i10 from "../../../../shared/services/notifications.service";
var styles_UploadAgreementComponent = [i0.styles];
var RenderType_UploadAgreementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadAgreementComponent, data: {} });
export { RenderType_UploadAgreementComponent as RenderType_UploadAgreementComponent };
function View_UploadAgreementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-upload-pending-documents", [], null, [[null, "documentResult"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("documentResult" === en)) {
        var pd_0 = (_co.updateDocumentDetails($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UploadPendingDocumentsComponent_0, i2.RenderType_UploadPendingDocumentsComponent)), i1.ɵdid(2, 114688, null, 0, i3.UploadPendingDocumentsComponent, [i4.StoreService], { pendingDocumentMetaData: [0, "pendingDocumentMetaData"] }, { documentResult: "documentResult" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pendingDocumentMetaData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UploadAgreementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadAgreementComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UploadAgreementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-agreement", [], null, null, null, View_UploadAgreementComponent_0, RenderType_UploadAgreementComponent)), i1.ɵdid(1, 4440064, null, 0, i6.UploadAgreementComponent, [i7.AgreementCreditsService, i4.StoreService, i8.TranslateService, i9.LookupService, i10.NotificationsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadAgreementComponentNgFactory = i1.ɵccf("app-upload-agreement", i6.UploadAgreementComponent, View_UploadAgreementComponent_Host_0, {}, {}, []);
export { UploadAgreementComponentNgFactory as UploadAgreementComponentNgFactory };
