import { FormGroup } from '@angular/forms';
import { IApp } from '@core/models/app.interfaces';
import { IRawFormBase } from '@core/models/raw-form.types';
import { StoreService } from '@core/store/store.service';
import {
  ENTITY_TYPE,
  notificationType,
} from '@module/submission-management/components/create-report-notification-modal/create-report-notification-modal.constants';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { IProject } from '@shared/services/project.service.types';

import { randomStr } from '@core/utilities/utilities.constants';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import * as moment from 'moment';

export const DATE_FORMAT = 'YYYY-MM-DD';

export class TransferAggregationRequestRoutine {

  fields: FormlyFieldConfig[];
  additionalFields: FormlyFieldConfig[][] = [];
  model: any = {};
  form = new FormGroup({});

  constructor(
    private formFactorySvc: FormFactoryService,
    private project: IProject,
    private accountId: string,
    private store: StoreService,
    private translateService: TranslateService,
  ) {
    this.configure(project);
  }

  configure(entity?: any): void {
    console.log(entity);
    this.setModel(entity);
    const fields = [
      {
        label: 'PROJECTS_MODULE.transferrequest.newApplicantDetailsTitle',
        groupFields: true,
        fieldGroup: [
          {
            className: 'col-6',
            type: 'input',
            key: 'destAccount.legalName',
            label: `PROJECTS_MODULE.transferrequest.accountOrganizationName`,
            required: false,
            id: 'destAccountLegalName',
            disabledExpression: 'true',
          },
          {
            className: 'col-6',
            type: 'input',
            key: 'destAccount.authorizedAccountRepresenttiveName',
            label: `PROJECTS_MODULE.transferrequest.accountOfficialName`,
            required: false,
            id: 'destAccountOfficialName',
            disabledExpression: 'true',
          },
          {
            className: 'col-12',
            type: 'input',
            key: 'destAccount.organizationAddressCompiled',
            label: `PROJECTS_MODULE.transferrequest.accountOfficialName`,
            required: false,
            id: 'destAccountAddress',
            disabledExpression: 'true',
          },
        ],
      },
      {
        label: 'PROJECTS_MODULE.transferrequest.currentProponentDetails',
        groupFields: true,
        fieldGroup: [
          {
            className: 'col-6',
            type: 'input',
            key: 'account.legalName',
            label: `PROJECTS_MODULE.transferrequest.accountOrganizationName`,
            required: false,
            id: 'proponentAccountLegalName',
            disabledExpression: 'true',
          },
          {
            className: 'col-6',
            type: 'input',
            key: 'account.authorizedAccountRepresenttiveName',
            label: `PROJECTS_MODULE.transferrequest.accountOfficialName`,
            required: false,
            id: 'proponentAccountOfficialName',
            disabledExpression: 'true',
          },
          {
            className: 'col-12',
            type: 'input',
            key: 'account.organizationAddressCompiled',
            label: `PROJECTS_MODULE.transferrequest.accountOfficialName`,
            required: false,
            id: 'proponentAccountAddress',
            disabledExpression: 'true',
          },
        ],
      },
    ] as IRawFormBase[];

    this.fields = this.formFactorySvc.configureForm(fields);
    this.setAdditionalFields();

    // this.disableFormIfApplicable();
  }

  setAdditionalFields() {

    if (this.project.subProjects && this.project.subProjects.length) {

      for (const subProject of this.project.subProjects) {
        const fields = [] as IRawFormBase[];

        fields.push(
          {
            label: 'PROJECTS_MODULE.transferrequest.projectDetails',
            groupFields: true,
            fieldGroup: [
              {
                key: 'name',
                type: 'input',
                className: 'col-6',
                label: 'PROJECTS_MODULE.transferrequest.projectName',
                id: `projectName-${randomStr()}`,
                disabled: true,
              },
              {
                key: 'projectId',
                type: 'input',
                className: 'col-6',
                label: 'PROJECTS_MODULE.transferrequest.projectId',
                id: `projectId-${randomStr()}`,
                disabled: true,
              },
              {
                key: 'type',
                type: 'input',
                className: 'col-6',
                label: 'PROJECTS_MODULE.transferrequest.projectType',
                id: `projectType-${randomStr()}`,
                disabled: true,
              },
            ],
          });
        this.additionalFields.push(this.formFactorySvc.configureForm(fields));
      }
    }
  }

  public setModel(entity?: any): void {
    if (entity) {
      this.model = {
        ...entity,
      };
    } else {
      this.model = {
        notStartDateDto: {
          subProjectNotificationDtoList: [],
        },
      };
    }
    this.model.notStartDateDto = {
      currentActivityStartDate: this.project.activity.activityStartDate,
      currentCreditingPeriodStartDate: this.project.activity.creditingPeriodStartDate,
      currentCreditingPeriodEndDate: this.project.activity.creditingPeriodEndDate,
      ...this.model.notStartDateDto,
      projectId: this.project.id,
    };

    this.model = {
      ...this.model,
      approveAction: this.store.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS),
      projectType: this.project.type,
      adminPermission: !!(this.model.entityActionList && this.model.entityActionList.some(action => action.workflowAction === 'EDIT')),
      _creditingPeriodStartDateVisible: !!this.model.notStartDateDto.updatedCreditingPeriodStartDate,
      _creditingPeriodEndDateVisible: !!this.model.notStartDateDto.updatedCreditingPeriodEndDate,
      inProcess: !!(!this.model.status || this.model.status === 'IN_PROCESS'),
    };

    if (this.model.approveAction && this.model.inProcess) {
      this.model.adminPermission = true;
    }

    // this.showDiscussion = !this.model.inProcess;

    if (this.project.subProjects && this.project.subProjects.length) {
      this.model.notStartDateDto.subProjectNotificationDtoList = this.project.subProjects.map((subProject, index) => {
        const title = subProject.name + (subProject.projectId ? ` ( ${subProject.projectId} )` : '');
        return {
          currentActivityStartDate: subProject.activity.activityStartDate,
          currentCreditingPeriodStartDate: subProject.activity.creditingPeriodStartDate,
          currentCreditingPeriodEndDate: subProject.activity.creditingPeriodEndDate,
          ...(this.model.notStartDateDto.subProjectNotificationDtoList && this.model.notStartDateDto.subProjectNotificationDtoList[index]),
          title: `<strong>${title}</strong>`,
          projectId: subProject.id,
          approveAction: this.model.approveAction,
          status: this.model.status,
          adminPermission: this.model.adminPermission,
          disabled: this.model.disabled,
          _creditingPeriodStartDateVisible: !!(this.model.notStartDateDto.subProjectNotificationDtoList &&
            this.model.notStartDateDto.subProjectNotificationDtoList[index] &&
            this.model.notStartDateDto.subProjectNotificationDtoList[index].updatedCreditingPeriodStartDate),
          _creditingPeriodEndDateVisible: !!(this.model.notStartDateDto.subProjectNotificationDtoList &&
            this.model.notStartDateDto.subProjectNotificationDtoList[index] &&
            this.model.notStartDateDto.subProjectNotificationDtoList[index].updatedCreditingPeriodEndDate),
        };
      });
    }

    // this.entity = this.model;
    // this.setDisabled(this.store.user);
  }

  // getDocumentConfig(): IApp.IDocumentUploadConfig {
  //   return {
  //     id: this.newSubmissionId || this.model.id,
  //     documentType: true,
  //     entityName: 'SUBMISSIONS_OFFSET_NOT_START_DATE',
  //     disablePaginator: false,
  //     comment: true,
  //     statusColumn: true,
  //     pendingSubmissionStatus: true,
  //     entityVersionId: this.entityVersionId,
  //     documentUploadConfigs: [
  //       {
  //         documentTypeValue: 89,
  //         documentTypeCode: 'NEW_PROJECT_START_DATE_JUSTIFICATION',
  //         id: this.newSubmissionId || this.model.id,
  //         entityName: 'SUBMISSIONS_OFFSET_NOT_START_DATE',
  //         entityVersionId: this.entityVersionId,
  //       },
  //     ],
  //     extraUploadDocuments: [],
  //   };
  // }

  upateAggregatedStartDate(field, event) {
    const startDates = this.model.notStartDateDto.subProjectNotificationDtoList.map(subProject => {
      return subProject.newActivityStartDate ? moment(subProject.newActivityStartDate) : moment(subProject.currentActivityStartDate);
    }).filter(data => data);
    this.model.notStartDateDto.newActivityStartDate = moment.min(startDates).toDate();
    this.form.get('notStartDateDto.newActivityStartDate').setValue(this.model.notStartDateDto.newActivityStartDate);
  }

  disableFormIfApplicable() {
    const editParticipant = (this.model.entityActionList && this.model.entityActionList.some(action => action.workflowAction === 'EDIT_CUST' || action.workflowAction === 'RESUBMIT') || !this.model.status);
    if (!editParticipant || this.model.historic) {
      this.model.formDisabled = true;
      setTimeout(() => this.form.disable());
    }
  }

  private configureDateFields(payload) {
    payload.notStartDateDto.newActivityStartDate && (payload.notStartDateDto.newActivityStartDate = moment(payload.notStartDateDto.newActivityStartDate).format(DATE_FORMAT).toString());
    payload.notStartDateDto.updatedCreditingPeriodStartDate && (payload.notStartDateDto.updatedCreditingPeriodStartDate = moment(payload.notStartDateDto.updatedCreditingPeriodStartDate).format(DATE_FORMAT).toString());
    payload.notStartDateDto.updatedCreditingPeriodEndDate && (payload.notStartDateDto.updatedCreditingPeriodEndDate = moment(payload.notStartDateDto.updatedCreditingPeriodEndDate).format(DATE_FORMAT).toString());
    if (payload.notStartDateDto.subProjectNotificationDtoList) {
      payload.notStartDateDto.subProjectNotificationDtoList.forEach(subProject => {
        subProject.newActivityStartDate && (subProject.newActivityStartDate = moment(subProject.newActivityStartDate).format(DATE_FORMAT).toString());
        subProject.updatedCreditingPeriodStartDate && (subProject.updatedCreditingPeriodStartDate = moment(subProject.updatedCreditingPeriodStartDate).format(DATE_FORMAT).toString());
        subProject.updatedCreditingPeriodEndDate && (subProject.updatedCreditingPeriodEndDate = moment(subProject.updatedCreditingPeriodEndDate).format(DATE_FORMAT).toString());
      });
    }
  }
}
