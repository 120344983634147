import { Component, ElementRef, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { LookupService } from '@core/services/lookup.service';
import { environment } from '@env/environment';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { INCOMING_PROJECT_FIELDS } from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';

@Component({
  selector: 'app-transfer-project-to-aggregation',
  templateUrl: './transfer-project-to-aggregation.component.html',
  styleUrls: ['./transfer-project-to-aggregation.component.scss'],
})
export class TransferProjectToAggregationComponent implements OnInit, OnDestroy {

  public form = new FormGroup({});
  public fields: FormlyFieldConfig[];
  public model: any = {};
  public tableConfig: ITableViewConfig;
  public tableData = {
    content: [],
  };
  public downloadSelected = null;

  public firstItemIndex = 0;
  public taskList = [{id: 1, name: 'Download Template'}];

  private accountId;
  private _destroy$ = new Subject<any>();

  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  @ViewChild('viewColTemplate', { static: true }) public viewColTemplate: TemplateRef<any>;
  @ViewChild('UPLOAD', {static: false}) uploadContainer: ElementRef<any>;

  constructor(
    private formFactoryService: FormFactoryService,
    private lookupService: LookupService,
    private service: ProjectService,
    public projectBuilderService: ProjectBuilderService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private renderer: Renderer2,
    public documentUploadInProgress: DocumentUploadInProgressService,
    private uploadDocumentService: UploadDocumentService,
  ) { }

  ngOnInit() {
    this.accountId = this.projectBuilderService.model.subProjects && this.projectBuilderService.model.subProjects.length ? this.projectBuilderService.model.subProjects[0].accountId : null;
    this.setTableData();
    this.setFields();
    this.getTableConfig();

    this.projectBuilderService.subProjectAdded$
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.projectBuilderService.model.subProjects.forEach(subProject => {
          if (!subProject.masterProjectId) {
            subProject.masterProjectId = this.projectBuilderService.model.id;
          }
        });
      });
  }

  public onActionSelected(event) {
    const sub$ = this.projectBuilderService.openConfirmationModal('discard', this.projectBuilderService.model.account.legalName, 'confirmationMessageSubProject').subscribe(result => {
      if (!result) {
        sub$.unsubscribe();
        return;
      }

      const projectId = event.data.projectId;
      const { content } = this.tableData;
      const discardedIndex = content.findIndex(project => project.projectId === projectId);

      if (discardedIndex !== -1) {
        content.splice(discardedIndex, 1);
      }

      if (event.data.version >= 0) {
        if (!this.projectBuilderService.model.discardSubProjects) {
          this.projectBuilderService.model.discardSubProjects = [];
        }
        this.projectBuilderService.model.discardSubProjects.push(event.data.id);
      }

      this.tableData = { ...this.tableData };

      const discardedSubProjectIndex = this.projectBuilderService.model.subProjects.findIndex(project => project.projectId === projectId); // temp Fix, TODO: need to refactor with CATS-12922
      if (discardedSubProjectIndex !== -1) {
        this.projectBuilderService.model.subProjects.splice(discardedSubProjectIndex, 1);
        this.projectBuilderService.model.subProjects = [...this.projectBuilderService.model.subProjects];
      }

      const projectTransfers = this.projectBuilderService.model.projectTransfers;
      const projectIndex = projectTransfers.findIndex(project => project.projectId === event.data.id);
      if (projectIndex !== -1) {
        projectTransfers.splice(projectIndex, 1);
      }

      this.updateMaster();

      if (!content.length) {
        this.accountId = null;
      }

      sub$.unsubscribe();
    });
  }

  public viewProject(event, data) {
    this.projectBuilderService.openProjectTransferDetailsModal(data);
  }

  public onPaginationChanged(event) {
  }

  private updateMaster() {
    this.projectBuilderService.model.noOfSubProjects =  this.projectBuilderService.model.subProjects.length;
    this.projectBuilderService.setAggregatedStartDate();
    this.projectBuilderService.setAggregatedEmmissionsDetails();
    this.projectBuilderService.setSubprojectPreviouslyRegistered();
    this.projectBuilderService.model = { ...this.projectBuilderService.model };
  }

  private setFields() {
    INCOMING_PROJECT_FIELDS[0].fieldGroup[2].customTemplateOptions = {
      onClick: () => this.searchProject(),
    };
    INCOMING_PROJECT_FIELDS[0].fieldGroup[3].customTemplateOptions = {
      onClick: () => this.clear(),
    };
    this.fields = this.formFactoryService.configureForm(INCOMING_PROJECT_FIELDS as any);
  }

  private getTableConfig() {
    this.lookupService.getTableConfigurations('ADD_PROJECT').subscribe(
      data => {

        this.tableConfig = data;

        const { columns, ...config } = this.tableConfig;

        const _columns: any = [
           ...this.tableConfig.columns,
          {
            header: 'details',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.viewColTemplate,
          }, {
            header: 'actions',
            width: AUTO_WIDTH_FOR_COLUMNS,
            templateRef: this.actionTemplate,
          },
        ];
        this.tableConfig = {
          ...config,
          ...{ columns: _columns },
        };
      });
  }

  private searchProject() {
    this.formFactoryService.markFormGroupDirty(this.form);
    if (!this.form.valid) {
      return;
    }

    if (!this.accountId) {
      this.accountId = this.model.accountId;
    } else if (this.model.accountId.toString() !== this.accountId.toString()) {
      this.showError('accountIdCannotBeDifferent');
      return;
    }

    if (!Number(this.accountId)) {
      this.accountId = null;
      this.showError('invalidInputType');
      return;
    }

    this.service.searchProject(this.model.projectProjectId, this.model.accountId).subscribe(result => {
      const project = result;
      project.isSubMasterReceiver = true;

      if (!this.projectBuilderService.model.projectTransfers) {
        this.projectBuilderService.model.projectTransfers = [];
      }

      if (!project || this.projectBuilderService.model.subProjects.some(subProject => subProject.id === project.id)) {
        return;
      }
      if (!this.projectBuilderService.model.subProjects || !this.projectBuilderService.model.subProjects.length) {
        this.projectBuilderService.model.subProjects = [project];
        this.projectBuilderService.model.projectTransfers = [this.getTrnsferDetails(project)];
      } else {
        this.projectBuilderService.model.subProjects.push(project);
        this.projectBuilderService.model.projectTransfers.push(this.getTrnsferDetails(project));
      }
      this.setTableData();
      this.updateMaster();
      this.form.reset();
    }, err => {
      if (!this.projectBuilderService.model.subProjects || !this.projectBuilderService.model.subProjects.length) {
        this.accountId = null;
      }
    });
  }

  private setTableData() {
    this.tableData = {
      content: this.projectBuilderService.model.subProjects.filter(project => project.isSubMasterReceiver).map(subProject => ({...subProject, entityActionList: [{workflowAction: 'REMOVE'}]})),
    };
  }

  private clear() {
    this.form.reset();
  }

  private showError(message: string) {
    const sub$ = this.modalService.open(ServiceMessageComponent,
      {
        messages: [{
          message,
        }],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe(() => {
      sub$.unsubscribe();
    });
  }

  private getTrnsferDetails(project) {
    return {
      projectId: project.id,
      srcAccountId: this.model.accountId,
      destAccountId: this.projectBuilderService.model.accountId,
      transferType: project.type,
      srcProjectId: project.masterProjectId,
      destProjectId: this.projectBuilderService.model.id,
      includeNotifications: false,
      includeCredits: false,
    };
  }

  public addSubProject() {
    if (!this.projectBuilderService.disabled) {
      this.projectBuilderService.addSubProject();
    }
  }

  public uploadSubProjects() {
    if (!this.projectBuilderService.disabled) {
      const projectNameField = this.translateService.currentLang === 'en' ? 'name' : 'name2';
      if (!this.projectBuilderService.model[projectNameField]) {
        this.displayNoSubProjectError('addNameAndDescriptionToUploadSubProjects');
      } else {
        this.projectBuilderService.uploadSubProjects(this.uploadContainer, this.renderer);
      }
    }
  }

  private displayNoSubProjectError(message = 'atLeastTwoSubProjectRequired') {
    const dialogRef = this.modalService.open(ServiceMessageComponent,
      {
        messages: [
          {
            message,
          },
        ],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe(() => {
      dialogRef.unsubscribe();
    });
  }

  public downloadTemplate(ev) {
    const uri = this.translateService.currentLang === 'en' ? '/account-service/document/downloadTemplate/Projects.csv' : '/account-service/document/downloadTemplate/Projets.csv';
    this.uploadDocumentService.downloadDocument(`${ environment.serviceUrl }${uri}`);
    setTimeout(() => {
        this.downloadSelected = null;
        (document.activeElement as HTMLElement).blur();
      }, 0,
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
