<div
  [ngClass]="selected ? 'selected-card': 'card'"
  (click)="selectCard()"
  (keypress)="selectCard()"
  tabindex="0"
  role="button">
  <div class="d-flex justify-content-between">
    <span><strong>{{title}}</strong></span>
    <span *ngIf="selected"><fa-icon [icon]="checkIcon"></fa-icon></span>
  </div>
  <div class="mt-2">
    {{description}}
  </div>

</div>
