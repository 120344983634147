import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MOPGuard } from '@core/guards/mop.guard';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { AccountSummaryComponent } from '@module/account-management/components/account-summary/account-summary.component';
import { AccountsListComponent } from '@module/account-management/components/accounts-list/accounts-list.component';
import { EnhancedUserDashboardComponent } from '@module/account-management/components/enhanced-user-dashboard/enhanced-user-dashboard.component';
import { EnhancedUserSidebarComponent } from '@module/account-management/components/enhanced-user-sidebar/enhanced-user-sidebar.component';
import { ManageAccountComponent } from '@module/account-management/components/manage-account/manage-account.component';
import { AccountPageComponent } from '@module/account-management/containers/account-page/account-page.component';
import { AddAdditionalUsersComponent } from "@shared/components/add-additional-users/add-additional-users.component";
import { AccountRegistrationComponent } from './components/account-registration/account-registration.component';
import { ChangePasswordComponent } from "@module/account-management/components/change-password/change-password.component";
import { UserDetailsCanDeactivateGuard } from "@core/guards/documentUploadCheckCanDeactivate.guard";
import { AccountApprovalComponent } from './components/account-approval/account-approval.component';
import { ReplacingMaoComponent } from './components/replacing-mao/replacing-mao.component';
import { AuthenticatedGuard } from '@core/guards/authenticated.guard';
import { ReqRegCancellationComponent } from './components/req-reg-cancellation/req-reg-cancellation.component';
import { AccountHistoryComponent } from './components/account-history/account-history.component';
import { AccountHistoryViewComponent } from './components/account-history-view/account-history-view.component';
import { AccountSummaryProjectsComponent } from './components/account-summary-projects/account-summary-projects.component';

const routes: Routes = [
  {
    path: '',
    component: AccountPageComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'summary-admin',
      },
      {
        // MOP section
        path: 'summary-admin',
        canActivate: [MOPGuard],
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission : 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' },
        component: AccountsListComponent,
      },
      {
        path: 'replace-mao',
        //canActivate: [MOPGuard],
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle'},
        component: ReplacingMaoComponent,
      },
      {
        path: 'replace-mao/:id',
        //canActivate: [MOPGuard],
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle'},
        component: ReplacingMaoComponent,
      },
      {
        path: 'request-registration-cancellation',
       // canActivate: [MOPGuard],
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle' },
        component: ReqRegCancellationComponent,
      },
      {
        // MOP section
        path: 'approve-account/:id',
        canActivate: [MOPGuard],
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission : 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' },
        component: AccountApprovalComponent,
      },
      {
        // MOP section
        path: 'approve-account/:id/:userId',
        canActivate: [MOPGuard],
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission : 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' },
        component: AccountApprovalComponent,
      },
      {
        // MOP section
        path: 'summary-admin/:id',
        canActivate: [MOPGuard],
        canDeactivate: ['CanDeactivateAccountSummaryComponent'],
        component: AccountSummaryComponent,
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission : 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' },
      },
      {
        path: 'account-details/:id',
        canDeactivate: ['CanDeactivateAccountSummaryComponent'],
        component: AccountSummaryComponent,
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle', permission : 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_ADMIN' },
      },
      {
        // Enhanced User section
        path: 'summary-user',
        component: EnhancedUserDashboardComponent,
        data: {
          title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
          actionPanel: true,
          actionPanelComponent: EnhancedUserSidebarComponent,
        },
      },
      {
        // Account Summary Project section
        path: 'summary-projects',
        component: AccountSummaryProjectsComponent,
      },
      {
        // MOP registration
        path: 'mop-register-account/:type',
        component: AccountRegistrationComponent,
        data: {
          title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
          actionPanel: false
        },
      },
      {
        // Enhanced User section
        path: 'register-account/:type',
        component: AccountRegistrationComponent,
        data: {
          title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
          actionPanel: true,
          actionPanelComponent: EnhancedUserSidebarComponent,
        },
      },
      {
        // Enhanced User section
        path: 'account-summary-user/:id',
        component: AccountSummaryComponent,
        data: {title: 'ACCOUNTS_MODULE.accountList.pageTitle' },
      },
      {
        // Enhanced User section
        path: 'register-account/:type/:id/:offset',
        component: AccountRegistrationComponent,
        data: {
          title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
          actionPanel: true,
          actionPanelComponent: EnhancedUserSidebarComponent,
        },
      },
      {
        path: 'manage-account',
        component: ManageAccountComponent,
        data: {
          title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
          actionPanel: true,
          actionPanelComponent: EnhancedUserSidebarComponent,
        },
      },
      {
        path: 'add-users',
        component: AddAdditionalUsersComponent,
        canDeactivate: ['CanDeactivateAddAdditionalUsersComponent'],
        data: {
          actionPanel: true,
          actionPanelComponent: EnhancedUserSidebarComponent,
        },
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          title: 'ACCOUNTS_MODULE.enhancedUser.pageTitle',
          actionPanel: true,
          actionPanelComponent: EnhancedUserSidebarComponent,
        },
      },
      {
        path: 'history/:accountId',
        component: AccountHistoryComponent,
        data: {
          title: 'ACCOUNTS_MODULE.pageTitle',
        },
      },
      {
        path: 'history/view/:id',
        component: AccountHistoryViewComponent,
      }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: 'CanDeactivateAccountSummaryComponent',
      useFactory: () => new UserDetailsCanDeactivateGuard<AccountSummaryComponent>(),
    },
    {
      provide: 'CanDeactivateAddAdditionalUsersComponent',
      useFactory: () => new UserDetailsCanDeactivateGuard<AddAdditionalUsersComponent>(),
    },
  ],
})
export class AccountManagementRoutingModule {
}
