import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { Agreements } from '@core/models/account.model';
import { StoreService } from '@core/store/store.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { STEP1_PARTY_AGREEMENT_FORM } from '@module/agreement-credits/components/constant/AgreementCreateCredits.const';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { AGREEMENT_CREATE_CREDITS_STEPS } from '@shared/components/steps/constants/agreement-create-credits';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep} from 'lodash';

@Component({
  selector: 'app-select-party',
  templateUrl: './select-party.component.html',
  styleUrls: ['./select-party.component.scss'],
})
export class SelectPartyComponent extends BaseStepperStepComponent {

  static key = 'SelectPartyComponent';

  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  isOpa: boolean;
  headerDetails: any;
  complianceYear: number;

  constructor(
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
    public formService: FormService,
    private storeService: StoreService,
    public service: AgreementCreditsService,
    private notificationsService : NotificationsService) { super(); }

  ngOnInit() {
    if (this.service.currentData.id) {
      this.model = this.service.currentData;
      this.isOpa = this.model.opaDetail && !this.model.opaDetail.editable &&
        this.model.opaDetail.id === this.storeService.user.associationsList[0].accountId;
      this.complianceYear = this.model.complianceYear;
      this.model.registrationAccountId = this.model.registrationAccount.id;

      const payload = {
        id: this.service.currentData.id,
        complianceYear: this.model.complianceYear,
      };
      this.service.getAgreementOpaList(this.getRegistrationAccountId(), payload).pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        response => {
          this.inputData = response;

          /*  Need to be handled in a better way,
          ** Handler for empty response for above api call in case of submitted agreements
          */
          if ((!response || !response.length) && this.model.status && !["IN_PROCESS", "PENDING_AGREEMENT_UPLOAD"].includes(this.model.status)) {
            this.inputData = [
              {
                opa: this.model.opaName,
                uniqueFacilities: [
                  {
                    facilityId: this.model.facilityList[0].facilityId,
                    name: this.model.facilityList[0].name,
                    opa: this.model.opaName
                  }
                ]
              }
            ];
          }

          this.onInit();
          this.service.disableFormIfApplicable(this.form);
        });
    } else {
      if (this.service.currentData) {
        this.model = this.service.currentData;
      } else {
        this.model = new Agreements();
        this.service.currentData = this.model;
      }
      this.inputData = this.service.inputData;
      this.model.registrationAccountId = this.inputData[0].uniqueFacilities[0].accountId;
      this.model.registrationReportAccountId = this.inputData[0].uniqueFacilities[0].registrationAccountId;
      this.onInit();
    }

  }

  onInit() {
    const formConfig = Object.create(STEP1_PARTY_AGREEMENT_FORM);
    formConfig[2].fieldGroup[0].options = [];

    this.inputData.forEach((facility, index) => {

      const uniquefacilities = facility.uniqueFacilities.map(element => ({
        id: element.facilityId,
        name: element.name,
        opa: element.opa,
      }
      ));
      facility.uniquefacilities = uniquefacilities;

      formConfig[2].fieldGroup[0].options.push(
        {
          value: uniquefacilities,
          key: this.model.opaNameCurrent && facility.opa === this.model.opaNameCurrent ? this.model.opaName : facility.opa, //for submitted agreements if opa gets changed
          opa: this.model.opaNameCurrent && facility.opa === this.model.opaNameCurrent ? this.model.opaName : facility.opa, //for submitted agreements if opa gets changed
          uniqueFacilities: facility.uniqueFacilities,
        },
      );
    });
    console.log(formConfig[2].fieldGroup[0].options);
    this.fields = this.formFactoryService.configureForm(formConfig);
    const inputData = Array.isArray(this.inputData) ? this.inputData[0] : this.inputData;
    this.headerDetails = inputData ? {
      type: inputData.uniqueFacilities[0].activity,
      legalName: inputData.uniqueFacilities[0].legalName || this.model.registrationAccount.legalName,
      subType: inputData.uniqueFacilities[0].subAccountType,
      accountId: inputData.uniqueFacilities[0].accountId || this.model.registrationAccountId,
      registrationAccountId: inputData.uniqueFacilities[0].registrationAccountId || this.model.registrationReportAccountId,
    } : null;

    // TODO: creates nested subscriptions on EVERY field change
    this.formFactoryService.dictCallback
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => this.callbackFunction(data),
      );
  }

  callbackFunction(fieldData): void {
    if (fieldData.key === 'complianceYear' && this.model.complianceYear && this.complianceYear !== this.model.complianceYear) {
      this.complianceYear = this.model.complianceYear;
      const payload = {
        complianceYear: this.model.complianceYear,
      };
      this.service.getAgreementOpaList(this.getRegistrationAccountId(), payload).pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        response => {
          this.inputData = response;
          this.onInit();
          this.service.disableFormIfApplicable(this.form);
        });
    }
  }

  getRegistrationAccountId() {
    return this.model.registrationAccountId ? this.model.registrationAccountId : this.model.registrationAccount.id;
  }

  saveAsDraft() {
    this.saveAndProceed(true);
  }

  saveAndProceed(draft = false) {

    const opa = this.getSelectedOpa();
    if (!this.validateForm(this.form) || !opa) {
      this.markInvalid();
      const inputData = Array.isArray(this.inputData) ? this.inputData[0] : this.inputData;
      if(!inputData && !opa){
        this.notificationsService.showMessage(SereviceMessageType.ERROR, 'FacilitiesAlreadySubmitted');
      }
      return;
    }


    if (this.checkForDisableState()) {

      this.model.facilityList = opa.uniquefacilities;

      if (!this.model.opaAccount || !this.model.opaAccount.id) {
        this.model.opaAccount = null;
      }
      if (!this.model.opaDetail || !this.model.opaDetail.id) {
        this.model.opaDetail = null;
      }
      this.service.saveOpaAccount(this.model, draft).pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          console.log(data);

          this.model.id = data.entity.id;
          this.service.currentData = data.entity;
          this.service.prevStepData = cloneDeep(this.service.currentData);
          this.service.initModelChangeTracking();
          this.reviewSteps(this.service.currentData);
          if (!draft) {
            this.service.inputData = opa;
            this.service.nextStep();
          }
        },
        (error: HttpErrorResponse) => {
          this.formService.parseErrors(this.form, error.error);
        },
      );
    } else {
      this.service.inputData = opa;
      this.service.nextStep();
    }
  }

  getSelectedOpa() {
    return this.inputData.find(opa => opa.opa === this.model.opaName || opa.opa === this.model.opaNameCurrent);
  }

  reviewSteps(data) {
    const steps = AGREEMENT_CREATE_CREDITS_STEPS;
    if (data.opaDetail && !data.opaDetail.editable) {
      steps.steps[2] = { ...steps.steps[2], name: 'REVIEW_OPA' };
      steps.steps[3] = { ...steps.steps[3], name: 'REVIEW_OPA_AO_DETAILS' };
    } else {
      steps.steps[2] = { ...steps.steps[2], name: 'ENTER_OPA' };
      steps.steps[3] = { ...steps.steps[3], name: 'ENTER_OPA_AO_DETAILS' };
    }
    this.service.steps = steps.steps;
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
