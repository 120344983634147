import { Component, OnInit } from '@angular/core';
import { ProjectBuilderService } from '../project-builder.service';

@Component({
  selector: 'app-project-activity-selector',
  templateUrl: './project-activity-selector.component.html',
  styleUrls: ['./project-activity-selector.component.scss']
})
export class ProjectActivitySelectorComponent implements OnInit {

  public editMode: boolean;
  public inProcess = true;

  constructor(private projectBuilderService: ProjectBuilderService) { }

  ngOnInit() {
    this.projectBuilderService.changeActivityView.subscribe((value) => this.editMode = value);
    this.inProcess = !this.projectBuilderService.model.status || this.projectBuilderService.model.status === 'IN_PROCESS';
  }

  showActivityList() {
    this.editMode = false;
    this.projectBuilderService.changeActivityView.next(this.editMode);
  }

}
