import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { StoreService } from '@core/store/store.service';
import { IApp } from '@core/models/app.interfaces';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { forkJoin, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementService } from '@module/users-management/services/user-management.service';
import { takeUntil } from 'rxjs/operators';
import { RegistrationForm } from '@shared/models/registration-form.model';
import { REGISTRATION_TYPE } from '@shared/components/stepper/stepper.const';
import { LookupService } from '@core/services/lookup.service';
import { accountSummaryNFMPRequiredFields } from '@core/constants/registration-required-fields.const';
import { OMPAccountType } from '@core/models/account.model';
export const COMPONENT_ID = 'ACCOUNT_SUMMARY';
import { RegistrationService } from '@module/registration/registration.service';
import { HelperService } from '@shared/services/helper.service';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-account-history-view',
  templateUrl: './account-history-view.component.html',
  styleUrls: ['./account-history-view.component.scss'],
})
export class AccountHistoryViewComponent implements OnInit {
  private _destroy$ = new Subject<any>();
  isEditEnabled: boolean = false;
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  account: IApp.IAccountData = {};
  accountId: number;
  // isRC: boolean;
  fields: FormlyFieldConfig[];
  accountStatus: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private storeService: StoreService,
    private accountManagementService: AccountManagementService,
    private _location: Location,
    private translateService: TranslateService,
    private userManagementService: UserManagementService,
    private registrationService: RegistrationService,
    private lookupService: LookupService,
    private helperService: HelperService,
    private navigation: NavigationService,
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.accountId = params.id;
      } else {
        this.navigation.back();
      }
    });
    this.form.disable();
  }

  ngOnInit() {

    if (!history.state.data) {
      this.router.navigate(
        this.helperService.getTranslatedPath('/account-management')
      );
    }

    this.getAccountDetails();
    this.form['id'] = COMPONENT_ID;
  }

  // editGeneralInformation() {
  //   this.isEditEnabled = !this.isEditEnabled;
  //   this.form.disabled ? this.form.enable() : this.form.disable();
  //   if (this.form.disabled === true) {
  //     this.options.resetModel();
  //   }
  // }

  getAccountDetails() {
    this.account = history.state.data;
    // this.isRC =
    //   this.account.accountTypeName === "PARTICIPANT" &&
    //   this.account.accountSubTypeNames !== "FS";

    // if (this.account.entityActionList) {
    //   this.account.entityActionList.forEach(
    //     (option) =>
    //       (option.label = this.translateService.instant(
    //         `COMMON.actionsLabel.${option.workflowAction}`
    //       ))
    //   );
    // }

    // this.getAuthorizedOfficialByAccountId();
    this.account.jurisdiction = this.translateService.instant(
      `COMMON.jurisdictionsList.${this.account.jurisdiction}`
    );
    this.createForm();

    // forkJoin([
    //   this.accountManagementService.getAccountDetails(this.accountId),
    //   this.accountManagementService.getAll({}, { accountId: this.accountId }),
    // ]).subscribe(
    //   ([data, regResponse]) => {
    //     this.account = data;
    //     console.log("not good", data);

    //     this.isRC =
    //       this.account.accountTypeName === "PARTICIPANT" &&
    //       this.account.accountSubTypeNames !== "FS";
    //     // this.account.address.country = this.account.address.country ? this.account.address.country : 'CA';
    //     //if (this.account.accountTypeName === 'VB') {
    //     if (this.account.entityActionList) {
    //       this.account.entityActionList.forEach(
    //         (option) =>
    //           (option.label = this.translateService.instant(
    //             `COMMON.actionsLabel.${option.workflowAction}`
    //           ))
    //       );
    //     }
    //     this.getAuthorizedOfficialByAccountId();
    //     this.account.jurisdiction = this.translateService.instant(
    //       `COMMON.jurisdictionsList.${this.account.jurisdiction}`
    //     );
    //     this.createForm();

    //   },
    //   (err) => {
    //     this.router.navigate(
    //       this.helperService.getTranslatedPath("/account-management")
    //     );
    //   }
    // );
  }

  createForm() {

    let ompAccountTypePurpose = new OMPAccountType();
    ompAccountTypePurpose.accountSubType = this.account.accountSubType;
    let registrationForm = new RegistrationForm(
      this.registrationService,
      REGISTRATION_TYPE.NFMP,
      this.lookupService,
      accountSummaryNFMPRequiredFields,
      null,
      null,
      ompAccountTypePurpose
    );
    this.fields = registrationForm.getRegistrationForm();
  }

  // getAuthorizedOfficialByAccountId() {
  //   const filterState = { facilityNameList: [this.accountId] };
  //   this.userManagementService
  //     .getAll(null, filterState)

  //     .pipe(takeUntil(this._destroy$))
  //     .subscribe((data) => {
  //       this.account.users = data.content.filter(
  //         (user) => user.associationsList.length > 0
  //       );
  //       this.account.users.map((user) => {
  //         if (user.id === this.account.authorizedUserId && this.isOFFSET()) {
  //           user.email =
  //             user.email +
  //             `<br/> (${this.translateService.instant(
  //               "ACCOUNTS_MODULE.accountSummary.authorizedOfficial"
  //             )})`;
  //         }
  //       });
  //     });
  // }

  isOFFSET() {
    return this.storeService.user.program === 'OFFSET';
  }
}
