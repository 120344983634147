import { ActivityFuelTypeComponent } from '@module/submission-management/components/application-ci-pathway/activity-fuel-type/activity-fuel-type.component';
import { PathwayInformationComponent } from '@module/submission-management/components/application-ci-pathway/pathway-information/pathway-information.component';

export const APPLICATION_CI_PATHWAY_COMPONENTS = {
  ACTIVITY_FUEL_TYPE: 'ActivityFuelTypeComponent',
  PATHWAY_INFORMATION: 'PathwayInformationComponent',
};

export const APPLICATION_CI_PATHWAY = {
  page_title: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.mainStepTitle',

  steps: [
    {
      id: 1,
      name: 'ACTIVITY_FUEL_TYPE',
      completed: false,
      component: APPLICATION_CI_PATHWAY_COMPONENTS.ACTIVITY_FUEL_TYPE,
    },
    {
      id: 2,
      name: 'PATHWAY_INFORMATION',
      completed: false,
      component: APPLICATION_CI_PATHWAY_COMPONENTS.PATHWAY_INFORMATION,
    },
  ],

  buttons: {
    showSave: true,
    showSubmit: true,
    submitPrefix: 'submitRegistrationReportButton',
  },
};

// todo change to form-factory format but there's need to change the logic
// export const APPLICATION_CI_PATHWAY_ACTIVITY_FUEL_TYPE = [
//   {
//     groupFields: false,
//     className: 'col-12',
//     fieldGroup: [
//       {
//         type: 'dict-select',
//         className: 'col-6',
//         key: 'fuel',
//         source: SOURCE_DATA_TYPE.FUELS,
//         dataType: 'PRODFF/LIQUID',
//         label: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.activityDropdown',
//         disabled: false,
//         required: true,
//         id: 'fuelSubjApplication',
//       },
//       {
//         type: 'dict-select',
//         className: 'col-6',
//         key: 'fuel',
//         source: SOURCE_DATA_TYPE.FUELS,
//         dataType: 'PRODFF/LIQUID',
//         label: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.fuelDropdown',
//         disabled: false,
//         required: true,
//         id: 'fuelSubjApplication',
//       },
//       {
//         key: 'radio3',
//         type: 'radio',
//         className: 'col-12',
//         label: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.radioOpt1',
//         required: true,
//         inline: true,
//         options: [
//           {value: 'New', key: 'New'},
//           {value: 'Modified', key: 'Modified'},
//         ],
//       },
//     ],
//   },
// ];


export const APPLICATION_CI_PATHWAY_ACTIVITY_FUEL_TYPE = [
  {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        className: 'col-6',
        type: 'dict-select',
        wrappers: ['form-field'],
        key: 'activityId',
        templateOptions: {
          label: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.activityDropdown',
          translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway',
          required: true,
          source: 'getActivities',
          placeholder: 'selectItem',
          change: (field, $event) => {
            // this.creditRemittanceData = {
            //   ...field.model,
            //   certificateNumber: $event ? $event.certificateNumber : '',
            // };
            // if ($event) {
            //   this.unitService.getObligation($event.id).subscribe(data => {
            //     this.obligations = data;
            //   });
            // }
            console.log($event);
          },
        },
      },
      {
        className: 'col-6',
        type: 'dict-select',
        wrappers: ['form-field'],
        key: 'fuelId',
        templateOptions: {
          label: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.fuelDropdown',
          translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway',
          required: true,
          source: 'getActivities',
          placeholder: 'selectItem',
          change: (field, $event) => {
            // this.creditRemittanceData = {
            //   ...field.model,
            //   certificateNumber: $event ? $event.certificateNumber : '',
            // };
            // if ($event) {
            //   this.unitService.getObligation($event.id).subscribe(data => {
            //     this.obligations = data;
            //   });
            // }
            console.log($event);
          },
        },
      },
      {
        type: 'radio',
        key: 'submissionType',
        className: 'col-6 inline',
        templateOptions: {
          required: true,
          options: [
            {value: 'New', key: 'New'},
            {value: 'Modified', key: 'Modified'},
          ],
          label: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway.radioOpt1',
          translatePrefix: 'SUBMISSION_MANAGEMENT_MODULE.applicationCIPathway',
        },
      },
    ],
  },
];
