/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i2 from "primeng/dialog";
import * as i3 from "../../../../../pipes/custom-translate.pipe";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i7 from "primeng/api";
import * as i8 from "./project-discussion-add-comment.component";
import * as i9 from "@angular/material/dialog";
import * as i10 from "../../../service/project-discussion.service";
var styles_ProjectDiscussionAddCommentComponent = [];
var RenderType_ProjectDiscussionAddCommentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectDiscussionAddCommentComponent, data: {} });
export { RenderType_ProjectDiscussionAddCommentComponent as RenderType_ProjectDiscussionAddCommentComponent };
export function View_ProjectDiscussionAddCommentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 25, "p-dialog", [["className", "eccc"]], null, [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Dialog_0, i1.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i2.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone, i0.ChangeDetectorRef], { header: [0, "header"], closable: [1, "closable"], minY: [2, "minY"], visible: [3, "visible"], style: [4, "style"] }, { visibleChange: "visibleChange" }), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), i0.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), i0.ɵpod(5, { width: 0 }), (_l()(), i0.ɵeld(6, 0, null, 1, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [["class", "col-12 mb-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), i0.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(10, 0, null, null, 6, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 5, "textarea", [["class", "form-control rounded-0"], ["rows", "10"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.comment = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(12, 16384, null, 0, i5.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i0.ɵdid(14, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i0.ɵdid(16, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i0.ɵeld(17, 0, null, 2, 8, "p-footer", [], null, null, null, i6.View_Footer_0, i6.RenderType_Footer)), i0.ɵdid(18, 49152, [[2, 4]], 0, i7.Footer, [], null, null), (_l()(), i0.ɵeld(19, 0, null, 0, 6, "div", [["class", "d-flex justify-content-end"]], null, null, null, null, null)), (_l()(), i0.ɵeld(20, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(21, null, [" ", " "])), i0.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(23, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(24, null, [" ", " "])), i0.ɵpid(131072, i3.CustomTranslatePipe, [i4.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 4).transform(_co.data.mode)); var currVal_1 = false; var currVal_2 = 100; var currVal_3 = _co.visible; var currVal_4 = _ck(_v, 5, 0, "600px"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_13 = _co.comment; _ck(_v, 14, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("PROJECTS_MODULE.DISCUSSION.discussionComment")); _ck(_v, 8, 0, currVal_5); var currVal_6 = i0.ɵnov(_v, 16).ngClassUntouched; var currVal_7 = i0.ɵnov(_v, 16).ngClassTouched; var currVal_8 = i0.ɵnov(_v, 16).ngClassPristine; var currVal_9 = i0.ɵnov(_v, 16).ngClassDirty; var currVal_10 = i0.ɵnov(_v, 16).ngClassValid; var currVal_11 = i0.ɵnov(_v, 16).ngClassInvalid; var currVal_12 = i0.ɵnov(_v, 16).ngClassPending; _ck(_v, 11, 0, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12); var currVal_14 = i0.ɵunv(_v, 21, 0, i0.ɵnov(_v, 22).transform("PROJECTS_MODULE.DISCUSSION.discussionCommentCancel")); _ck(_v, 21, 0, currVal_14); var currVal_15 = !_co.comment.length; _ck(_v, 23, 0, currVal_15); var currVal_16 = i0.ɵunv(_v, 24, 0, i0.ɵnov(_v, 25).transform("PROJECTS_MODULE.DISCUSSION.discussionCommentSubmit")); _ck(_v, 24, 0, currVal_16); }); }
export function View_ProjectDiscussionAddCommentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-discussion-add-comment", [], null, null, null, View_ProjectDiscussionAddCommentComponent_0, RenderType_ProjectDiscussionAddCommentComponent)), i0.ɵdid(1, 49152, null, 0, i8.ProjectDiscussionAddCommentComponent, [i9.MatDialogRef, i9.MAT_DIALOG_DATA, i10.ProjectDiscussionService], null, null)], null, null); }
var ProjectDiscussionAddCommentComponentNgFactory = i0.ɵccf("app-project-discussion-add-comment", i8.ProjectDiscussionAddCommentComponent, View_ProjectDiscussionAddCommentComponent_Host_0, {}, {}, []);
export { ProjectDiscussionAddCommentComponentNgFactory as ProjectDiscussionAddCommentComponentNgFactory };
