import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { Account } from '@core/models/account.model';
import { IApp } from '@core/models/app.interfaces';
import { accountRejectConfig, accountRespondConfig } from '@module/account-management/account-management.const';
import { BaseActionsComponent } from '@shared/components/base-actions/base-actions.component';
import { TableNewRowComponent } from '@shared/components/table-new-row/table-new-row.component';
import { TABLE_NEW_ROW_ACTION, TableNewRow } from '@shared/models/table-new-row.model';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { takeUntil } from 'rxjs/operators';
import { ACCOUNT_ACTION_CONFIRMATION_CONFIG, ACCOUNT_ACTION_EDIT_SERVICE, ACCOUNT_CLOSUER_CONFIRMATION_CONFIG, ACCOUNT_NOTES_CONFIG } from './account-actions.const';

const ACTION_POSTFIX = 'Account';

@Component({
  selector: 'app-account-actions',
  templateUrl: './account-actions.component.html',
  styleUrls: ['./account-actions.component.scss'],
})
export class AccountActionsComponent extends BaseActionsComponent implements OnInit, OnChanges {

  @Input() actionsList: IApp.IEntityAction[];
  @Input() account_id: number;
  @Input() primaryAccountId: number;
  @Input() actionService: any;
  @Input() appendTo = 'body';
  @Input() accountData: any;
  @Output() actionSuccessfull = new EventEmitter<boolean>();
  account: any;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private modalService: ModalService,
  ) {
    super();
  }

  ngOnInit() {

    this.setActionsList();
  }

  ngOnChanges() {
    this.setActionsList();
  }

  triggerActionController(value: IApp.IEntityAction): void {

    this.account = new Account();
    this.account.id = this.account_id;
    let respondModel: IApp.ICreditActionData = {};

    if (this.primaryAccountId) {
      this.account.accountId = this.primaryAccountId;
      respondModel.accountId = this.primaryAccountId;
    }

    this.resetSelection();
    if (value.workflowAction === 'VIEW') {
      this.actionService.view(this.account);
    } else if (value.workflowAction === 'VIEW_NMI_COMMENTS') {
      respondModel = this.accountData;
      if (this.accountData.status === 'PENDING_KYC_NEED_MORE_INFO') {
        respondModel.userComment = null;
      }
      this.openNMIEditPopUp(this.getAccountNMIConfig(false), respondModel, TABLE_NEW_ROW_ACTION.VIEW);
    } else if (value.workflowAction === 'SUBMIT_RESPONSE_FOR_NMI') {
      respondModel = {};
      respondModel.userComment = null;
      respondModel.comment = this.accountData.comment;
      respondModel.commentTimestampStr = this.accountData.commentTimestampStr;
      respondModel.accountIds = [this.accountData.id];
      this.openNMIEditPopUp(this.getAccountNMIConfig(true), respondModel, TABLE_NEW_ROW_ACTION.EDIT);
    } else if (value.workflowAction === 'NEED_MORE_INFO') {
      respondModel.id = this.account_id;
      this.openEditPopUp(accountRespondConfig, respondModel, 'comment');
    } else if (value.workflowAction === 'REJECT' && !this.actionService.showConfirmationMessageForRejection()) {
      respondModel.id = this.account_id;
      this.openEditPopUp(accountRejectConfig, respondModel);
    } else if (value.workflowAction === 'ADD_NOTE') {
      this.openNotesPopUp();
    } else if (value.workflowAction === 'VIEW_HISTORY') {
      this.router.navigate(this.helperService.getTranslatedPath(`/account-management/history/${this.account.id}`));
    } else if (value.workflowAction === 'ON_HOLD') {
      const popUpForm = { ...ACCOUNT_ACTION_CONFIRMATION_CONFIG, editService: ACCOUNT_ACTION_EDIT_SERVICE.ON_HOLD };
      this.openCommentPopUp(popUpForm);
    } else if (value.workflowAction === 'RELEASE_HOLD') {
      const popUpForm = { ...ACCOUNT_ACTION_CONFIRMATION_CONFIG, editService: ACCOUNT_ACTION_EDIT_SERVICE.RELEASE_HOLD };
      this.openCommentPopUp(popUpForm);
    } else if (value.workflowAction === 'REOPEN') {
      const popUpForm = { ...ACCOUNT_ACTION_CONFIRMATION_CONFIG, editService: ACCOUNT_ACTION_EDIT_SERVICE.REOPEN };
      this.openCommentPopUp(popUpForm);
    } else if (value.workflowAction === 'CLOSE') {
      this.openCommentPopUp(ACCOUNT_CLOSUER_CONFIRMATION_CONFIG);
    } else if (value.workflowAction === 'LOCK') {
      const popUpForm = { ...ACCOUNT_ACTION_CONFIRMATION_CONFIG, editService: ACCOUNT_ACTION_EDIT_SERVICE.LOCK };
      this.openCommentPopUp(popUpForm);
    } else if (value.workflowAction === 'UNLOCK') {
      const popUpForm = { ...ACCOUNT_ACTION_CONFIRMATION_CONFIG, editService: ACCOUNT_ACTION_EDIT_SERVICE.UNLOCK };
      this.openCommentPopUp(popUpForm);
    } else if (value.workflowAction === 'DISCARD') {
      const popUpForm = { ...ACCOUNT_ACTION_CONFIRMATION_CONFIG, editService: ACCOUNT_ACTION_EDIT_SERVICE.DISCARD };
      this.openCommentPopUp(popUpForm, value);
    } else {

      if(value.workflowAction === 'APPROVE_KYC_DOCS') {
        value.workflowAction = 'APPROVE';
      }
      let approval = value.workflowAction.indexOf('APPROVE') > -1;
      if(approval && this.accountData.masterAccountId) {
        value.workflowAction = this.accountData.status === 'PENDING_REVIEW_ADMIN2' ? 'APPROVE2SUB' : 'APPROVESUB';
        approval = false;
      } else {
        approval = approval && !this.actionService.showConfirmationMessageForApproval();
      }
      if (approval) {
        this.executeAction(value);
      } else {
        this.modalService.open(ServiceMessageComponent,
          {
            messages: null,
            message: this.actionService.getConfirmationMsg(value.workflowAction),
            metaDataList: [value.label, this.accountData.legalName],
            type: SereviceMessageType.WARNING,
          },
          true,
          DEFAULT_DIALOG_CONFIG,
        ).afterClosed().subscribe((result?: any) => {
          if (result) {
            this.executeAction(value);
          }
          this.resetSelection();
        });
      }
    }
  }

  public executeAction(value: IApp.IEntityAction): void {

    const actionMethod = `${value.workflowAction.toCamelCase()}${ACTION_POSTFIX}`;
    if (typeof this.actionService[actionMethod] !== 'function') {
      console.error(`No method ${actionMethod} defined in target service.`);
      return;
    }
    this.actionService[actionMethod](this.account).pipe(
      takeUntil(this._destroy$),
    ).subscribe(() => {
      // CATS-2483
      if (value.workflowAction === 'DISCARD') {
        this.router.navigate(this.helperService.getTranslatedPath(''));
      }
      this.actionSuccessfull.emit(true);
    });
  }

  openEditPopUp(tableConfig: ITableViewConfig, respondModel: any, editTitle = 'rejectionDetails') {
    this.modalService.open(TableNewRowComponent, {
      form: tableConfig,
      defaultValues: respondModel,
      actionType: TABLE_NEW_ROW_ACTION.EDIT,
      saveBtnLabel: 'COMMON.actionsLabel.SUBMIT',
      editTitle,
    } as TableNewRow).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.actionSuccessfull.emit(true);
      }
    });
  }

  openNMIEditPopUp(tableConfig: ITableViewConfig, respondModel: any, actionType: any) {

    this.modalService.open(TableNewRowComponent, {
      form: tableConfig,
      editTitle: 'comment',
      defaultValues: respondModel,
      actionType,
    } as TableNewRow).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.actionSuccessfull.emit(true);
      }
    });
  }

  getAccountNMIConfig(editable: boolean) {
    return {
      translationPrefix: 'ACCOUNTS_MODULE.accountList',
      paginator: true,
      editService: '/account-service/account/submitKycAdmin',
      columns: [
        {
          field: 'commentTimestampStr',
          header: 'timestamp',
          sortField: 'comment',
          width: '200px',
          editable: false,
          readonly: true,
          fieldType: 'input',
          inputType: 'string',
          editRowField: true,
          newRowField: true,
          required: false,
          className: 'col-12',
        }, {
          field: 'comment',
          header: 'adminComments',
          sortField: 'comment',
          width: '200px',
          editable: false,
          readonly: true,
          fieldType: 'textarea',
          inputType: 'string',
          editRowField: true,
          newRowField: true,
          required: false,
          className: 'col-12',
        },
        {
          field: 'userCommentTimestampStr',
          header: 'timestamp',
          sortField: 'comment',
          width: '200px',
          editable: false,
          readonly: true,
          fieldType: 'input',
          inputType: 'string',
          editRowField: true,
          newRowField: true,
          required: false,
          className: 'col-12',
        }, {
          field: 'userComment',
          header: 'regulateeResponse',
          sortField: 'userComment',
          width: '200px',
          editable,
          readonly: !editable,
          fieldType: 'textarea',
          inputType: 'string',
          editRowField: true,
          newRowField: true,
          required: false,
          className: 'col-12',
        },
      ],
    };
  }

  private setActionsList() {
    if (this.actionsList) {
      this.actionsList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`));
      this.actionsList.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  private openNotesPopUp() {
    const defaultValues = {
      id: this.account_id,
      notes: this.accountData.notes,
    };
    this.modalService.open(TableNewRowComponent, {
      form: ACCOUNT_NOTES_CONFIG,
      defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT,
      editTitle: 'adminNote',
    } as TableNewRow).afterClosed().subscribe(data => {
      this.actionSuccessfull.emit(true);
    });
  }

  private openCommentPopUp(form, value?) {
    const defaultValues = {
      id: this.account_id,
    };
    this.modalService.open(TableNewRowComponent, {
      form, defaultValues,
      actionType: TABLE_NEW_ROW_ACTION.EDIT,
    } as TableNewRow).afterClosed().subscribe(data => {
      this.actionSuccessfull.emit(true);
    });
  }
}
