/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gen-agreement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./gen-agreement.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../shared/services/form.service";
import * as i8 from "../../agreement-credits.service";
import * as i9 from "../../../../core/store/store.service";
import * as i10 from "../../../../shared/services/upload-document.service";
var styles_GenAgreementComponent = [i0.styles];
var RenderType_GenAgreementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GenAgreementComponent, data: {} });
export { RenderType_GenAgreementComponent as RenderType_GenAgreementComponent };
function View_GenAgreementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_5.headerMessage")); _ck(_v, 1, 0, currVal_0); }); }
function View_GenAgreementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_5.ifOPAExist")); _ck(_v, 1, 0, currVal_0); }); }
function View_GenAgreementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("AGREEMENT_CREDITS.Step_5.rcaSignHeaderMessage")); _ck(_v, 1, 0, currVal_0); }); }
function View_GenAgreementComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "d-md-flex justify-content-center mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card mb-2 mx-auto w-100 card-override"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-12 mt-4 mb-4"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.download() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "col-12  mb-4"], ["style", "text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.print() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("AGREEMENT_CREDITS.Step_5.downloadAgreement")); _ck(_v, 6, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("AGREEMENT_CREDITS.Step_5.printAgreement")); _ck(_v, 10, 0, currVal_1); }); }
export function View_GenAgreementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["id", "gen-agreement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "d-md-flex justify-content-center pt-5 align-content-stretch"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "col-sm-12 col-md-9 col-xl-6 mb-5 d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenAgreementComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenAgreementComponent_2)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenAgreementComponent_3)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GenAgreementComponent_4)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-secondary ml-2 float-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.existInMarkit && !_co.rcaSign); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.existInMarkit; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.rcaSign; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.existInMarkit; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("COMMON.wizard.close")); _ck(_v, 14, 0, currVal_4); }); }
export function View_GenAgreementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gen-agreement", [], null, null, null, View_GenAgreementComponent_0, RenderType_GenAgreementComponent)), i1.ɵdid(1, 4440064, null, 0, i5.GenAgreementComponent, [i3.TranslateService, i6.ActivatedRoute, i7.FormService, i8.AgreementCreditsService, i9.StoreService, i4.Location, i10.UploadDocumentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GenAgreementComponentNgFactory = i1.ɵccf("app-gen-agreement", i5.GenAgreementComponent, View_GenAgreementComponent_Host_0, {}, {}, []);
export { GenAgreementComponentNgFactory as GenAgreementComponentNgFactory };
