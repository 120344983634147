/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sub-project-documents.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../documents/documents.component.ngfactory";
import * as i3 from "../../documents/documents.component";
import * as i4 from "../../../services/modal.service";
import * as i5 from "../../../services/upload-document.service";
import * as i6 from "../../../../core/store/store.service";
import * as i7 from "../../../services/document-in-progress.service";
import * as i8 from "@ngx-translate/core";
import * as i9 from "@angular/common";
import * as i10 from "./sub-project-documents.component";
import * as i11 from "../project-builder.service";
import * as i12 from "../../../services/form-factory.service";
var styles_SubProjectDocumentsComponent = [i0.styles];
var RenderType_SubProjectDocumentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubProjectDocumentsComponent, data: {} });
export { RenderType_SubProjectDocumentsComponent as RenderType_SubProjectDocumentsComponent };
function View_SubProjectDocumentsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "document-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-documents", [], null, [[null, "documentResult"], ["window", "beforeunload"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:beforeunload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onBeforeUnload($event) !== false);
        ad = (pd_0 && ad);
    } if (("documentResult" === en)) {
        var pd_1 = (_co.updateUploadedDocuments($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DocumentsComponent_0, i2.RenderType_DocumentsComponent)), i1.ɵdid(2, 770048, null, 0, i3.DocumentsComponent, [i4.ModalService, i5.UploadDocumentService, i6.StoreService, i7.DocumentUploadInProgressService, i8.TranslateService], { documentsPage: [0, "documentsPage"], documentUploadConfig: [1, "documentUploadConfig"], disabled: [2, "disabled"], showPlaceholders: [3, "showPlaceholders"], relative: [4, "relative"] }, { documentResult: "documentResult" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documentsPage; var currVal_1 = _co.documentUploadConfig; var currVal_2 = _co.model.disabled; var currVal_3 = true; var currVal_4 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SubProjectDocumentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubProjectDocumentsComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.model.id; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SubProjectDocumentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sub-project-documents", [], null, null, null, View_SubProjectDocumentsComponent_0, RenderType_SubProjectDocumentsComponent)), i1.ɵdid(1, 245760, null, 0, i10.SubProjectDocumentsComponent, [i11.ProjectBuilderService, i12.FormFactoryService, i6.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubProjectDocumentsComponentNgFactory = i1.ɵccf("app-sub-project-documents", i10.SubProjectDocumentsComponent, View_SubProjectDocumentsComponent_Host_0, { model: "model", masterProject: "masterProject" }, { validateDocuments: "validateDocuments" }, []);
export { SubProjectDocumentsComponentNgFactory as SubProjectDocumentsComponentNgFactory };
