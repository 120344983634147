import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const OBPS_SERVICE = '/obps-service';
export const CREDITS_SEARCH_FACILITY = '/credits/searchFacility';
export const TRANSACTION_GET_CRA_PAYMENT_RESULTS = '/transaction/getCraPaymentResults';
export const APPROVE_RESULT_LIST = '/credits/approveResultList';
export const REJECT_RESULT_LIST = '/credits/rejectResultList';
export const TRANSACTION_MANUAL_REFUND = '/transaction/manualRefund';
export const SAVE_PAYMENT = '/transaction/save';
export const VALIDATE_PAYMENT = '/transaction/validateManualReviewRecord';
export const SET_ISSUANCE_DATE = '/unit/activate/scheduler/startScheduler';
export const FIND_RESULT_IMPACT = '/credits/findResultImpact/';
export const UPLOAD_OBPS_RESULTS_TABLE = '/obps-service/fileUpload/obpsResultsUploadSearchFilter';
export const REJECT_OBPS_RESULT_UPLOAD = '/obps-service/fileUpload/rejectObpsResultsUpload';
export const APPROVE_OBPS_RESULTS_UPLOAD = '/obps-service/fileUpload/approveObpsResultsUpload';
export const RESUBMIT_OPBPS_RESULTS = '/obps-service/fileUpload/reSubmitObpsResultsUpload';
export const OBPS_RESULTS_VIEW = '/obps-service/fileUpload/viewObpsResultsUpload';
export const GET_SCHEDULED_TIME = '/obps-service/unit/activate/scheduler/getScheduledTime';
export class ProgramManagementService extends AbstractPaginationService {
    constructor(http) {
        super();
        this.http = http;
    }
    getScheduledTime() {
        return this.http.get(`${environment.apiUrl}${GET_SCHEDULED_TIME}`, { responseType: 'text' });
    }
    getCreditsSearchFacility(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${OBPS_SERVICE}${CREDITS_SEARCH_FACILITY}?pageSearch`, filterState, { params });
    }
    getCraPaymentResults(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${OBPS_SERVICE}${TRANSACTION_GET_CRA_PAYMENT_RESULTS}?pageSearch`, filterState, { params });
    }
    approveResultList(request) {
        return this.http.post(`${environment.apiUrl}${OBPS_SERVICE}${APPROVE_RESULT_LIST}`, request);
    }
    rejectResultList(request) {
        return this.http.post(`${environment.apiUrl}${OBPS_SERVICE}${REJECT_RESULT_LIST}`, request);
    }
    transactionManualRefund(request) {
        return this.http.post(`${environment.apiUrl}${OBPS_SERVICE}${TRANSACTION_MANUAL_REFUND}`, request);
    }
    savePayment(request) {
        return this.http.post(`${environment.apiUrl}${OBPS_SERVICE}${SAVE_PAYMENT}`, request);
    }
    setIssuanceDate(request) {
        let params = new HttpParams().set('startScheduler', request);
        return this.http.get(`${environment.apiUrl}${OBPS_SERVICE}${SET_ISSUANCE_DATE}`, { params: params });
    }
    findResultImpact(id) {
        return this.http.get(`${environment.apiUrl}${OBPS_SERVICE}${FIND_RESULT_IMPACT}${id}`);
        //  return this.http.get<IApp.IResultDetail>(`http://localhost:8088/api${ OBPS_SERVICE }${ FIND_RESULT_IMPACT }${id}`);
    }
    getUploadOBPSResultTable(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${UPLOAD_OBPS_RESULTS_TABLE}`, filterState, { params });
    }
    rejectObpsResultsUpload(id) {
        return this.http.post(`${environment.apiUrl}${REJECT_OBPS_RESULT_UPLOAD}?id=` + id, {});
    }
    approveObpsResultsUpload(id) {
        return this.http.post(`${environment.apiUrl}${APPROVE_OBPS_RESULTS_UPLOAD}?id=` + id, {});
    }
    reSubmitObpsResultsUpload(id) {
        return this.http.post(`${environment.apiUrl}${RESUBMIT_OPBPS_RESULTS}?id=` + id, {});
    }
    getViewObpsResultsUpload(id) {
        let params = new HttpParams().set('id', id);
        return this.http.post(`${environment.apiUrl}${OBPS_RESULTS_VIEW}?id=` + id, {});
    }
}
ProgramManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProgramManagementService_Factory() { return new ProgramManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: ProgramManagementService, providedIn: "root" });
