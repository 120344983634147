import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export const ADMIN_MGMT_FACILITIES = '/obps-service/properties/getAllFacility';
export const ADMIN_MGMT_PROGRAMS = '/obps-service/properties/getAllProgram';
export const ADMIN_SYSTEM_PROPERTIES = '/account-service/property/getAllProperties';
export const ADMIN_WORKFLOW_CONFIG = '/account-service/standard/getAllProgramWorkflowConfigs';
export const ADMIN_UPDATE_SYSTEM_PROPERTIES = '/account-service/property/update';
export const ADMIN_UPDATE_PROGRAM_PROPERTIES = '/obps-service/properties/saveProgram';
export const ADMIN_WORKFLOW_STANDARDS = '/account-service/lookup/standards';
export const ADMIN_WORKFLOW_APPROVAL_TYPES = '/account-service/standard/getAllStandardApprovalTypes';
export const ADMIN_UPDATE_WORKFLOW_CONFIG = '/account-service/standard/saveProgramWorkflowConfig';
export const ADMIN_OFFSET_PROTOCOL = '/account-service/offsetProtocol/getAll';
export const ADMIN_OFFSET_PROTOCOL_VERSION = '/account-service/offsetProtocolVersion/getAll';
export const ADMIN_UPDATE_FACILITY_PROGRAM_PROPERTIES = '/obps-service/properties/saveFacilityProgramProperty';
export const ADMIN_OFFSET_PROTOCOL_CONFIG = '';
export class AdminManagementService extends AbstractPaginationService {
    constructor(http) {
        super();
        this.http = http;
    }
    getFacilities(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.apiUrl}${ADMIN_MGMT_FACILITIES}`, filterState, { params });
    }
    getPrograms(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.apiUrl}${ADMIN_MGMT_PROGRAMS}`, filterState, { params });
    }
    getSystemProperties(pagination, filterState = {}, url) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.apiUrl}${url}`, filterState, { params });
    }
    getStandards(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        const response1 = this.http.post(`${environment.apiUrl}${ADMIN_WORKFLOW_CONFIG}`, filterState, { params });
        const response2 = this.http.get(`${environment.apiUrl}${ADMIN_WORKFLOW_STANDARDS}`);
        const response3 = this.http.get(`${environment.apiUrl}${ADMIN_WORKFLOW_APPROVAL_TYPES}`);
        return forkJoin([response1, response2, response3]);
    }
    sendEmails(uri) {
        return this.http.post(`${environment.apiUrl}${uri}`, {});
    }
    updateSystemProperty(updatedRow, { id, description, key, deleted }) {
        updatedRow = Object.assign({}, updatedRow, { id, description, key, deleted });
        const params = updatedRow;
        return this.http.post(`${environment.apiUrl}${ADMIN_UPDATE_SYSTEM_PROPERTIES}`, params);
    }
    updateProgramProperty(updatedRow, { id = false, submissionDateBenchMark = false, complianceYear = false } = {}) {
        if (id) {
            updatedRow = Object.assign({}, updatedRow, { id, submissionDateBenchMark, complianceYear });
        }
        const params = updatedRow;
        return this.http.post(`${environment.apiUrl}${ADMIN_UPDATE_PROGRAM_PROPERTIES}`, params);
    }
    updateFacilityProgramProperty(updatedRow, { id = false, submissionDateBenchMark = false, complianceYear = false } = {}) {
        if (id) {
            updatedRow = Object.assign({}, updatedRow, { id, submissionDateBenchMark, complianceYear });
        }
        updatedRow = Object.assign({}, updatedRow, { account: { id: updatedRow.account } });
        const params = updatedRow;
        return this.http.post(`${environment.apiUrl}${ADMIN_UPDATE_FACILITY_PROGRAM_PROPERTIES}`, params);
    }
    updateWorkFlowProperty(updatedRow) {
        const params = updatedRow;
        return this.http.post(`${environment.apiUrl}${ADMIN_UPDATE_WORKFLOW_CONFIG}`, params);
    }
    getOffsetProtocol(pagination) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.get(`${environment.apiUrl}${ADMIN_OFFSET_PROTOCOL}`, { params });
    }
    getAllOffsetProtocol(pagination) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${ADMIN_OFFSET_PROTOCOL}?pageSearch&type=RU`, { offsetType: 'RU' }, { params });
    }
    getOffsetProtocolVersion(pagination) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.get(`${environment.apiUrl}${ADMIN_OFFSET_PROTOCOL_VERSION}`, { params });
    }
    getProtocolManagement(pagination) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.get(`${environment.apiUrl}${ADMIN_OFFSET_PROTOCOL_CONFIG}`, { params });
    }
}
AdminManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminManagementService_Factory() { return new AdminManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: AdminManagementService, providedIn: "root" });
