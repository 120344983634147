import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { DialogModule } from 'primeng';
import { ProjectDiscussionAddCommentComponent } from './components/project-discussion-add-comment/project-discussion-add-comment.component';

@NgModule({
  declarations: [
    ProjectDiscussionAddCommentComponent,
  ],
  exports: [
    ProjectDiscussionAddCommentComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    SharedPipesModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ProjectDiscussionAddCommentModule { }
