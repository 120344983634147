<nav class="navbar navbar-expand-md navbar-light bg-light">
  <a class="navbar-brand">
    <div class="app-logo"></div>
  </a>
  <a class="skip-main"
     href="#"
     (keydown)="skipToMain($event)">
    {{ 'APP_HEADER.headerSection.skipToMainContent' | translate }}
  </a>
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse justify-content-end" id="navbarsDefault">
<div>
      <ul class="navbar-nav">
        <ng-container *ngIf="session; else NOT_LOGGED_IN">
          <li class="nav-item">
            <a *ngIf="lang === 'en'" class="nav-link" href="#" (click)="changeLang($event, 'fr')">Français</a>
            <a *ngIf="lang === 'fr'" class="nav-link" href="#" (click)="changeLang($event, 'en')">English</a>
          </li>
          <li class="nav-item" ngbDropdown  #userDropdown>
            <span id="userDropdown"
                  ngbDropdownToggle>
              {{ 'APP_HEADER.headerSection.welcome' | translate }} <strong>{{ currentUser?.fullName }}</strong></span>
            <div ngbDropdownMenu aria-labelledby="userDropdown">
              <button ngbDropdownItem (click)="gotoMyProfile()">{{ 'APP_HEADER.headerSection.myProfileBtn' | translate }}</button>
              <button ngbDropdownItem (click)="migrateAccountAction()">{{ 'APP_HEADER.headerSection.migrateAccountBtn' | translate }}</button>
            </div>
          </li>
          <app-program-switcher></app-program-switcher>
          <li class="nav-item">
            <a  id="logout" class="nav-link" (click)="signOut($event)" href="#">{{ 'APP_HEADER.headerSection.signout' | translate }}</a>
          </li>
        </ng-container>
        <ng-template #NOT_LOGGED_IN>
          <li *ngIf="!isOffsetPublicPage" class="nav-item">
            <a class="nav-link" wcag-dummy-link-target href="#" [routerLink]="['/' | localize]">{{ 'APP_HEADER.headerSection.home' | translate }}</a>
          </li>
          <li class="nav-item" *ngIf="(loginEnabled | async) && !isOffsetPublicPage">
            <a id="login"  class="nav-link" wcag-dummy-link-target [routerLink]="['/register/chooser-page' | localize]">{{ 'APP_HEADER.headerSection.login' | translate }}</a>
          </li>
          <li class="nav-item">
            <a *ngIf="lang === 'en'" class="nav-link" href="#" (click)="changeLang($event, 'fr')">Français</a>
            <a *ngIf="lang === 'fr'" class="nav-link" href="#" (click)="changeLang($event, 'en')">English</a>
          </li>
        </ng-template>
      </ul>
      <small class="last-login float-right" *ngIf="session && currentUser">{{ 'APP_HEADER.headerSection.lastSuccessfulLogin' | translate }}
        <!--{{ currentUser.validLoginDate | date:'medium'}}-->
        {{currentUser.validLoginDateStr}}
      </small>
    </div>
  </div>
</nav>
<div class="sub-header" *ngIf="session">
  <h1>{{ 'APP_HEADER.headerSection.registryName' | translate }}</h1>
</div>
