<div *ngIf="loaded">

<h2>{{ 'ACCOUNTS_MODULE.approveAccounts.approveAccounts' | translate }}:</h2>
<p>{{ 'ACCOUNTS_MODULE.approveAccounts.reviewText' | translate }}</p>

<div class="panel mt-4" >
    <h3>{{ 'ACCOUNTS_MODULE.approveAccounts.identificationDocuments' | translate }}:
        <span style="float: right;">{{ 'ACCOUNTS_MODULE.approveAccounts.mainAuthorizedOfficial' | translate }}:
          {{account.authorizedUserName}}</span>
    </h3>
    <app-documents [hidden]="true"
    (refreshTableData)="updateDocumentDetails($event)" [documentUploadConfig]="userDocumentUploadConfig">
    </app-documents>
</div>

<div class="panel mt-4" *ngIf="!replace">
    <h3>{{ 'ACCOUNTS_MODULE.approveAccounts.authorizationDocuments' | translate }}:
        <span style="float: right;">{{ 'ACCOUNTS_MODULE.approveAccounts.accountName' | translate }}:
            {{account.legalName}}</span></h3>
    <app-documents  [hidden]="true"
    (refreshTableData)="updateDocumentDetails($event)" [documentUploadConfig]="documentUploadConfig">
    </app-documents>
</div>


<div class="panel mt-4" *ngIf="replace">
    <h3>{{ 'ACCOUNTS_MODULE.approveAccounts.authorizationDocuments' | translate }}:
        <span style="float: right;">{{ 'ACCOUNTS_MODULE.approveAccounts.accountName' | translate }}:
            {{account.legalName}}</span></h3>
    <app-documents  [hidden]="true"
    (refreshTableData)="updateDocumentDetails($event)" [documentUploadConfig]="replaceUserDocumentUploadConfig">
    </app-documents>
</div>

<div class="panel mt-4" *ngIf="isOffset">
    <h3>{{ 'PROJECTS_MODULE.tableDetails.title' | translate }}:</h3>
    <app-project-list
      [showTableDetails]="false"
      [registrationAccountId]="id"
      [showFilters]="false"
      (projectListData)="fetchProjectData($event)" >
    </app-project-list>
</div>

<div class="panel mt-4" *ngIf="isCFR && isVBAccount">
  <app-reg-rept-list [accountId]="account.id" [accountStatus]="account.status"></app-reg-rept-list>
</div>

<div class="sticky-action-button sticky-down">
  <div class="footer-buttons">

      <div class="float-right">
          <button class="btn btn-secondary mr-2" type="button" (click)="returnPreviousLocation($event)">
              {{ 'COMMON.actionsLabel.CLOSE' | translate }}
          </button>

        <ng-container *ngIf="!account.enableSendConsent && !enableSendConsent; else consentTemplate">
          <ng-container *ngIf="!replace && account.projectTransferRequestDto && account.status === 'PENDING_REVIEW_ADMIN' ; else approveTemplate">
            <button
              class="btn btn-secondary mr-2"  type="button"
              (click)="approve('confirmationMessageAccountKycDocproject')">{{ 'COMMON.actionsLabel.APPROVE_KYC_DOCS' | translate }}</button>
          </ng-container>
          <ng-template #approveTemplate>
            <button  *ngIf="!replace"
                     class="btn btn-secondary mr-2"  type="button"
                     (click)="approve('confirmationMessageAccount')">{{ 'COMMON.actionsLabel.APPROVE' | translate }}</button>
          </ng-template>

          <button class="btn btn-primary mr-2" type="button" *ngIf="replace"
                  (click)="approveReplaceUser()">{{ 'COMMON.actionsLabel.APPROVE' | translate }}</button>
        </ng-container>
        <ng-template #consentTemplate>
          <button class="btn btn-primary mr-2" type="button" (click)="sendToProponentForConsent()">{{ 'COMMON.actionsLabel.SEND_CURRENT_PROPONENT_CONSENT' | translate }}</button>
        </ng-template>
      </div>

  </div>
</div>
</div>
