<p-fieldset>
  <div class="wrapper">
    <div class="box-1">
      <ng-container #fieldComponent></ng-container>
    </div>
    <div class="box-2">
      <ng-container *ngFor="let info of to.data">
        <div class="inner-wrapper">
          <div class="label-box">{{info.label}}:</div>
          <div class="value-box" *ngIf="!info.value.address; else address">{{info.value}}</div>
          <ng-template #address>
            <div class="address-box">
              <div class="mr-2 postal"><app-address-view [legend]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.postalAddress'" [address]="info.value.postalAddress"></app-address-view></div>
              <div class="civic"><app-address-view [legend]="'SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.civicAddress'" [address]="info.value.civicAddress"></app-address-view></div>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</p-fieldset>


