import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IRawFormBase, IRawFormInput } from '@core/models/raw-form.types';
import { getInput } from '@core/utilities/raw-form.utils';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import {
  MAILING_ADDRESS_FORM_SECTION,
  PHYSICAL_ADDRESS_FORM_SECTION,
} from '@shared/components/offset-account-type/offset-account-type.const';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ACM_EMAIL_ADDRESS, ACM_NAME, ACM_TELEPHONE_NUMBER, ACM_TITLE } from './alternate-contact-modal.constants';
import { IAlternateContactModal } from './alternate-contact-modal.types';

@Component({
  selector: 'app-alternate-contact-modal',
  templateUrl: './alternate-contact-modal.component.html',
  styleUrls: ['./alternate-contact-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlternateContactModalComponent implements OnInit {
  visible = true;
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  data = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: IAlternateContactModal,
    private dialogRef: MatDialogRef<AlternateContactModalComponent>,
    private formFactorySvc: FormFactoryService,
    private translateSvc: TranslateService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initGetFieldsSub();
    this.data = this.inputData;
  }

  applyPrefix(value: string): string {
    return `SUBMISSIONS.alternateContact.${value}`;
  }

  submit(): void {
    this.visible = false;
    this.dialogRef.close(this.form.value);
  }

  close(): void {
    this.visible = false;
    this.dialogRef.close();
  }

  private initGetFieldsSub(): void {
    this.fields = this.formFactorySvc.configureForm(this.getFields());
    const address = this.getAddressFields();
    this.fields.splice(2, 0, address);
    this.cd.detectChanges();
  }

  private getFields(): IRawFormBase[] {
    return [
      {
        fieldGroup: [
          this.getDefaultInput(ACM_NAME),
          this.getDefaultInput(ACM_TITLE),
          this.getDefaultInput(ACM_TELEPHONE_NUMBER),
          this.getDefaultInput(ACM_EMAIL_ADDRESS),
        ],
      },
    ];
  }

  private getAddressFields(): FormlyFieldConfig {
    const sameAsOrganization = { ...PHYSICAL_ADDRESS_FORM_SECTION.sameAsOrganization, hide: true };
    const postal = { ...PHYSICAL_ADDRESS_FORM_SECTION, sameAsOrganization };
    const APRaddress = this.formFactorySvc.configurFormPostWithCivicAddress(postal, MAILING_ADDRESS_FORM_SECTION)[0];
    APRaddress.wrappers = ['app-formly-fieldset'];
    APRaddress.templateOptions = { label: this.translateSvc.instant('REGISTRATION_PAGE.offsetRegistrationForm.APRAddress') };
    return APRaddress;
  }

  private getDefaultInput(id: string): IRawFormInput {
    return getInput({
      id,
      className: 'col-12',
      label: this.applyPrefix(id),
    });
  }
}
