import { Component, OnInit } from '@angular/core';
import { ProjectBuilderService } from '../project-builder.service';

@Component({
  selector: 'app-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss']
})
export class ProjectSummaryComponent implements OnInit {

  public activities$;

  constructor(
    private projectBuilderService: ProjectBuilderService
  ) { }

  ngOnInit() {
    this.activities$ = this.projectBuilderService.getActivities();
  }

}
