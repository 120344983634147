import { IRawForm } from '@core/models/raw-form.types';
import { getDictSelect } from '@core/utilities/raw-form.utils';
import {
    COMPLIMENTARY_COMPLIANCE_REPORT_ORGANIZATION_NAME_FLD,
    complimentaryComplianceReportApplyPrefix,
    COMPLIMENTARY_COMPLIANCE_REPORT_CREATE_POPUP,
    COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX,
    COMPLIMENTARY_COMPLIANCE_REPORT_PERIOD_FLD,
} from '@module/complimentary-compliance-report/complimentary-compliance-report.const';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';

export const getOrganizationName = (onChange?: () => void, hideExpression?): IRawForm => {
    return getDictSelect({
      id: COMPLIMENTARY_COMPLIANCE_REPORT_ORGANIZATION_NAME_FLD,
      className: 'col-6',
      source: SOURCE_DATA_TYPE.ACCOUNT_NAMES,
      label: complimentaryComplianceReportApplyPrefix(COMPLIMENTARY_COMPLIANCE_REPORT_ORGANIZATION_NAME_FLD, COMPLIMENTARY_COMPLIANCE_REPORT_CREATE_POPUP),
      onChange,
      disabled: hideExpression,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      translatePrefix: COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX + COMPLIMENTARY_COMPLIANCE_REPORT_CREATE_POPUP,
      skipInitialBlur: true,
      hideExpression,
    });
};

export const getComplianceReportPeriod = (onChange?: () => void): IRawForm => {
    return getDictSelect({
      id: COMPLIMENTARY_COMPLIANCE_REPORT_PERIOD_FLD,
      className: 'col-6',
      source: SOURCE_DATA_TYPE.COMPLIMENTARY_COMPLIANCE_REPORT_COMPLIANCE_YEARS,
      label: complimentaryComplianceReportApplyPrefix(COMPLIMENTARY_COMPLIANCE_REPORT_PERIOD_FLD, COMPLIMENTARY_COMPLIANCE_REPORT_CREATE_POPUP),
      onChange,
      required: true,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      skipInitialBlur: true,
      translatePrefix: COMPLIMENTARY_COMPLIANCE_REPORT_PREFIX + COMPLIMENTARY_COMPLIANCE_REPORT_CREATE_POPUP,
    });
  };