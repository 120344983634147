import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { SUB_PROJECT_FORM } from '../consts/sub-projects.const';
import * as moment from 'moment';
import { delay, filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NEED_MORE_INFO_FORM, PROJECT_APPLICATION_TYPES, PROJECT_TYPES } from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';
import { ProjectService } from '@shared/services/project.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { EDIT_PROJECT_PUBLIC_VISIBILITY } from '@core/models/permission.const';
import { isNull } from 'util';

import { get, cloneDeep } from 'lodash'

@Component({
  selector: 'app-sub-project-form',
  templateUrl: './sub-project-form.component.html',
  styleUrls: ['./sub-project-form.component.scss']
})
export class SubProjectFormComponent implements OnInit, OnDestroy, OnChanges {

  @Input() popUpView = true;
  @Input() model: any = {
    type: PROJECT_APPLICATION_TYPES[PROJECT_TYPES.SUB],
    activity: {
      location: {}
    },
  };
  @Input() form = new FormGroup({});
  @Input() masterProject: any

  @Output() modelIdUpdate = new EventEmitter();

  public fields: FormlyFieldConfig[];

  public needMoreInfoFields: FormlyFieldConfig[];

  public visible = true;

  private _destroy$ = new Subject<any>();
  private minProjectStartDate;
  private _mapFileId = 'mapFileSubProject';
  private _downloadLinkId = 'downloadLinkSubProject';

  constructor(
    private formFactoryService: FormFactoryService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<SubProjectFormComponent>,
    private translateService: TranslateService,
    private projectBuilderService: ProjectBuilderService,
    private projectService: ProjectService,
    private lookupService: LookupService,
    private storeService: StoreService,
  ) { }

  ngOnInit() {
    this.storeService.getOffsetMinStartDate().subscribe(
      prop => {
      this.minProjectStartDate = moment(prop.value);
    });
    this.model = {
      ...this.model,
      ...this.data.model,
      adminPermisson: this.projectBuilderService.model.adminPermisson,
      editParticipant: this.projectBuilderService.model.editParticipant,
      disabled: this.projectBuilderService.disabled,
      editPublicVisibility: this.projectBuilderService.hasEditProjectPublicVisibilityAction(),
    };
    if (!isNull(this.projectBuilderService.model.activity.indigenousLedBusiness) && !this.model.id) {
      this.model.activity.indigenousLedBusiness = JSON.parse(
        JSON.stringify(this.projectBuilderService.model.activity.indigenousLedBusiness),
      );
    }
    if (!this.model.id) {
      const payload: any = {
        accountId: this.model.accountId,
      };
      this.projectService.searchProjectAccount(payload)
        .subscribe(result => {
        this.model.id = result.id;
        this.modelIdUpdate.emit(this.model.id);
      });
    } else {
      this.projectBuilderService.renderDownloadKmlControl(this.model, this._mapFileId, this._downloadLinkId);
    }
    this.model = this.checkCreditingPeriodDates(this.model);
    this.setFields();
    this.handleBrowseButton();
    this.disableFormIfApplicable();
    this.translateService.onLangChange.pipe(takeUntil(this._destroy$)).subscribe(() => {
      this.setFields();
      this.disableFormIfApplicable();
    });
    this.checkForInvalidDate();
    this.validateLatLong();
    this.formFactoryService.inputCallback.subscribe(field => {
      if (field.key === 'activity.estimatedScenarioGhg') {
        this.projectBuilderService.validateGHGReductions(this.form, this.model);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      this.model = this.checkCreditingPeriodDates(this.model);
    }
  }

  public changed(ev) {
    this.dialogRef.close(false);
  }

  public close() {
    this.dialogRef.close(false);
  }

  public save(updateVisibility = false) {
    if (updateVisibility) {
      const payload = this.createPayload();
      this.saveProject(payload);
      this.dialogRef.close(this.model);
    } else {
      this.formFactoryService.markFormGroupDirty(this.form);
      if (this.form.valid) {
        this.dialogRef.close(this.model);
      }
    }
  }

  private checkForInvalidDate() {
    this.formFactoryService.onDateCheangeCallback
    .pipe(takeUntil(this._destroy$))
    .subscribe(field => {
      const projectStartDate = get(this.masterProject,'activity.activityStartDate', null)
      const control = this.form.get('activity.activityStartDate');
      if (this.model.activity.activityStartDate && control) {
        if (moment(this.model.activity.activityStartDate).isBefore( this.minProjectStartDate)) {
          control.markAsDirty();
          control.setErrors({ 'direct': `${this.translateService.instant('COMMON.messageSection.subProjectStartDateCannotBeLessThenJan2017')}
          ${this.minProjectStartDate.format('MMM D, YYYY')}`});
        } else if (projectStartDate && moment(this.model.activity.activityStartDate).isBefore(projectStartDate)) {
          control.markAsDirty();
          control.setErrors({ 'direct': `${this.translateService.instant('COMMON.messageSection.subProjectStartDateCannotBeLessThenMaster')}
          ( ${moment(projectStartDate).format('MMM D, YYYY')} )`});
        }
      }
    })
  }

  private setFields() {
    const subProjectForm = cloneDeep(SUB_PROJECT_FORM);
    subProjectForm[3].fieldGroup[0]['options'] =  subProjectForm[3].fieldGroup[0]['options'].map(option => {
      return {
        key: option.key,
        value: option.value,
        tooltip: option.tooltip,
        tooltipClass: option.tooltipClass
      }
    });
    subProjectForm[4].fieldGroup[0].label = this.translateService.instant(subProjectForm[4].fieldGroup[0].label);
    subProjectForm[4].fieldGroup[1].label = this.translateService.instant(subProjectForm[4].fieldGroup[1].label);


    const currentLang = this.translateService.currentLang;
    if (currentLang === 'fr') {
      const engDescription = subProjectForm[7].fieldGroup[0];
      subProjectForm[7].fieldGroup[0] = {
        ...subProjectForm[7].fieldGroup[1],
        required: true,
        expressionProperties: null
      };
      subProjectForm[7].fieldGroup[1] = {
        ...engDescription,
        required: false,
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        }
      }

      const engName = subProjectForm[0].fieldGroup[0];
      subProjectForm[0].fieldGroup[0] = {
        ...subProjectForm[0].fieldGroup[1],
        expressionProperties: null,
        required: true
      }
      subProjectForm[0].fieldGroup[1] = {
        ...engName,
        required: false,
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        }
      }
    }

    this.fields = this.formFactoryService.configureForm(subProjectForm);
    if(this.projectBuilderService.hasApproveAction(this.model.discloseComment)) {
      const needMoreInfoFields = cloneDeep(NEED_MORE_INFO_FORM);
      if (this.popUpView || !this.model.isAddedSeparately) {
        needMoreInfoFields[0].fieldGroup = needMoreInfoFields[0].fieldGroup.slice(0,1);
      }
      this.needMoreInfoFields = this.formFactoryService.configureForm(needMoreInfoFields);
    }
  }

  private handleBrowseButton() {
    this.formFactoryService.buttonCallback.pipe(
      delay(100),
      takeUntil(this._destroy$)
    ).subscribe(result => {
      if (result.field.subProject) {
        const doc = document.getElementById(this._mapFileId);
        if (doc) {
          doc.click();
        }
      }
    });

    this.formFactoryService.fileUpload
    .pipe(takeUntil(this._destroy$))
    .subscribe(({file,field}) => {
      this.projectBuilderService.renderKmlFileUpload(this.model, file, field, this._mapFileId, this._downloadLinkId);
    });
  }

  private disableFormIfApplicable() {
    if (this.projectBuilderService.disabled) {
      setTimeout(() => {
        this.form.disable();
        this.formFactoryService.enableFieldByPermission(this.form, 'activity.isPublic', [EDIT_PROJECT_PUBLIC_VISIBILITY]);
      });
    }
  }

  private validateLatLong() {
    this.formFactoryService.inputCallback
    .pipe(
      takeUntil(this._destroy$),
      filter(field => field.key === 'activity.location.longitudeDecimal' || field.key === 'activity.location.latitudeDecimal')
      )
    .subscribe(field => {
      const control = this.form.get(field.key) as FormControl;
      this.projectBuilderService.validateDecimalLatLong(control, control.value);
    })
  }

  private checkCreditingPeriodDates(model) {
    return {...model,
     activity: {...model.activity,
        _creditingPeriodEndDateVisible: !!model.activity.creditingPeriodEndDate,
        _creditingPeriodStartDateVisible: !!model.activity.creditingPeriodStartDate,
     },
    };
  }

  get formDisabled(): boolean {
    return this.projectBuilderService.disabled;
  }

  private createPayload() {
    const payload = {...this.model, ...this.form.getRawValue()};
    payload.activity.indigenousLedBusiness = JSON.parse(JSON.stringify(this.model.activity.indigenousLedBusiness));
    payload.activity.activityType = this.model.activity.activityType;
    payload.activity.id = this.model.activity.id;
    payload.isPublic = this.model.activity.isPublic;
    delete payload.activity.location.file;
    return payload;
  }

  private saveProject(payload) {
    const valid = this.projectBuilderService.validateIncorrectValues(this.form, true);
    if (valid) {
      this.projectService.saveProject(payload)
      .subscribe(result => {
        this.model = result.entity;
        this.model.activity.isPublic = this.model.isPublic;
      });
    }
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
