import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ActionPanelService {
    constructor() {
        this.broadcastAccountId$ = new BehaviorSubject(null);
        this.broadcastSubAccountSelected$ = new BehaviorSubject(null);
        this.withActionPanel$ = new BehaviorSubject(false);
        this.panelVisible$ = new BehaviorSubject(true);
    }
    broadcastAccountId(id) {
        this.broadcastAccountId$.next(id);
    }
    broadcastSubAccountSelected(value) {
        this.broadcastSubAccountSelected$.next(value);
    }
    get accountId() {
        return this.broadcastAccountId$.getValue();
    }
    get subAccountSelected() {
        return this.broadcastSubAccountSelected$.getValue();
    }
    get account() {
        return this._account;
    }
    set account(data) {
        this._account = data;
    }
    init(event) {
        this.withActionPanel$.next(event.snapshot.data.actionPanel || false);
    }
    toggle() {
        this.panelVisible$.next(!this.panelVisible$.getValue());
    }
}
ActionPanelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActionPanelService_Factory() { return new ActionPanelService(); }, token: ActionPanelService, providedIn: "root" });
