/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-checkbox-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/address-view/address-view.component.ngfactory";
import * as i3 from "../../../shared/components/address-view/address-view.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../node_modules/primeng/fieldset/primeng-fieldset.ngfactory";
import * as i7 from "primeng/fieldset";
import * as i8 from "./data-checkbox-wrapper.component";
var styles_DataCheckboxWrapperComponent = [i0.styles];
var RenderType_DataCheckboxWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DataCheckboxWrapperComponent, data: {} });
export { RenderType_DataCheckboxWrapperComponent as RenderType_DataCheckboxWrapperComponent };
function View_DataCheckboxWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "value-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; _ck(_v, 1, 0, currVal_0); }); }
function View_DataCheckboxWrapperComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "address-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mr-2 postal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-address-view", [], null, null, null, i2.View_AddressViewComponent_0, i2.RenderType_AddressViewComponent)), i1.ɵdid(3, 638976, null, 0, i3.AddressViewComponent, [i4.TranslateService], { legend: [0, "legend"], address: [1, "address"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "civic"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-address-view", [], null, null, null, i2.View_AddressViewComponent_0, i2.RenderType_AddressViewComponent)), i1.ɵdid(6, 638976, null, 0, i3.AddressViewComponent, [i4.TranslateService], { legend: [0, "legend"], address: [1, "address"] }, null)], function (_ck, _v) { var currVal_0 = "SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.postalAddress"; var currVal_1 = _v.parent.context.$implicit.value.postalAddress; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "SUBMISSION_MANAGEMENT_MODULE.ERPApplicationForm.civicAddress"; var currVal_3 = _v.parent.context.$implicit.value.civicAddress; _ck(_v, 6, 0, currVal_2, currVal_3); }, null); }
function View_DataCheckboxWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "inner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "label-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ":"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataCheckboxWrapperComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["address", 2]], null, 0, null, View_DataCheckboxWrapperComponent_3))], function (_ck, _v) { var currVal_1 = !_v.context.$implicit.value.address; var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_0); }); }
export function View_DataCheckboxWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fieldComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 7, "p-fieldset", [], null, null, null, i6.View_Fieldset_0, i6.RenderType_Fieldset)), i1.ɵdid(2, 49152, null, 0, i7.Fieldset, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(3, 0, null, 1, 5, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "box-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, [[1, 3], ["fieldComponent", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "box-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataCheckboxWrapperComponent_1)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.data; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_DataCheckboxWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-data-checkbox-wrapper", [], null, null, null, View_DataCheckboxWrapperComponent_0, RenderType_DataCheckboxWrapperComponent)), i1.ɵdid(1, 49152, null, 0, i8.DataCheckboxWrapperComponent, [], null, null)], null, null); }
var DataCheckboxWrapperComponentNgFactory = i1.ɵccf("app-data-checkbox-wrapper", i8.DataCheckboxWrapperComponent, View_DataCheckboxWrapperComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { DataCheckboxWrapperComponentNgFactory as DataCheckboxWrapperComponentNgFactory };
