import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IAlternateContactPerson,
  IAlternateContactPersonData,
  IGetNewSubmissionsIdResponse,
  IProjectReportSubmission,
  IProjectReportSubmitPayload,
  IRNSubmitResponse,
  ISearchSubmissions,
} from './project-report.service.types';

export const UPLOAD_SUB_PROJECTS = '/offset-service/submissions/subProjectReport/upload';
export const FIND_ACTIVE_CREDITS = '/obps-service/findActiveCredits';

@Injectable({ providedIn: 'root' })
export class ProjectReportService {
  alternateContactPerson$ = new BehaviorSubject<IAlternateContactPersonData>(null);

  get alternateContactPerson(): IAlternateContactPerson {
    return this.alternateContact || null;
  }

  set alternateContactPerson(value: IAlternateContactPerson) {
    this.alternateContact = value;
  }

  private readonly baseUrl = '/offset-service/submissions';

  private alternateContact: IAlternateContactPerson;

  constructor(private http: HttpClient) {}



  uploadSubProjects(formData) {
    return this.http.post<any>(`${ environment.apiUrl }${UPLOAD_SUB_PROJECTS}`, formData);
  }

  submit$(payload: IProjectReportSubmitPayload, method = 'submit'): Observable<IRNSubmitResponse> {
    return this.http.post<IRNSubmitResponse>(this.getUrl(method), payload);
  }

  save$(payload: IProjectReportSubmitPayload): Observable<IProjectReportSubmission> {
    return this.http.post<IProjectReportSubmission>(this.getUrl(`save`), payload);
  }

  submitResponse$(payload: IProjectReportSubmitPayload): Observable<IRNSubmitResponse> {
    return this.http.post<IRNSubmitResponse>(this.getUrl(`submitResponse`), payload);
  }

  submitNoticeOfIntentToCancelProject$(payload: IProjectReportSubmitPayload): Observable<IRNSubmitResponse> {
    return this.http.post<IRNSubmitResponse>(this.getUrl(`submitNoticeOfIntentionToCancel`), payload);
  }

  saveNotification$(payload: IProjectReportSubmitPayload): Observable<IProjectReportSubmission> {
    return this.http.post<IProjectReportSubmission>(this.getUrl(`saveNotification`), payload);
  }

  getById$(entityId: number): Observable<any> {
    return this.http.get<any>(this.getUrl(`getById/${entityId}`));
  }

  getNewSubmissionsId$(payload: any): Observable<IGetNewSubmissionsIdResponse> {
    return this.http.post<IGetNewSubmissionsIdResponse>(this.getUrl(`getNewSubmissionsId`),  payload);
  }

  searchSubmissions$(body: ISearchSubmissions): Observable<IProjectReportSubmission[]> {
    return this.http.post<IProjectReportSubmission[]>(this.getUrl('searchSubmissions'), body);
  }

  reportWorkflowAction(payload, uri: string): Observable<any> {
    return this.http.post<any>(this.getUrl(uri), payload);
  }

  findActiveCredits(accountId, uri: string = FIND_ACTIVE_CREDITS): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${uri}/${accountId}`, {});
  }

  private getUrl(part: string): string {
    return `${environment.apiUrl}${this.baseUrl}/${part}`;
  }
}
