import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { StoreService } from '@core/store/store.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UnauthenticatedGuard implements CanActivate {
  constructor(
    private store: StoreService,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private localizeService: LocalizeRouterService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canBeActivated(next);
  }

  private canBeActivated(next: ActivatedRouteSnapshot): Observable<boolean> {
    const session = this.store.getSession();
    if (session) {
      // check token expiration TODO
      this.router.navigate(['']);
      return of(false);
    } else {
      return of(true);
    }
  }

}
