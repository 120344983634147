import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';
import { APPLICATION_ID_KEY } from '@module/submission-management/const/submission-management.const';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { StoreService } from '@core/store/store.service';

@Component({
  selector: 'app-step-title',
  templateUrl: './step-title.component.html',
  styleUrls: ['./step-title.component.scss'],
})
export class StepTitleComponent implements OnInit {

  @Input() data: any;
  @Input() type: string;
  @Input() parentComponent;
  instrumentIdText: string;
  hasPermission: boolean;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private translateService: TranslateService,
    private submissionManagementService: SubmissionManagementService,
    private store: StoreService
  ) { }

  ngOnInit() {
    console.log(this.data);
    this.hasPermission = this.store.user.hasPermissionForAccount('VIEW_REGISTRATION_REPORT', this.data.accountId);
    if(this.data.instrumentId){
      const label = `COMMON.submissionTypeList.${this.data.type}_ID`;
      this.instrumentIdText = `${this.translateService.instant(label)}: ${this.data.instrumentId}`;
    }
  }

  goToRegistrationReport() {
    const subType = 'PS';
    sessionStorage.setItem(APPLICATION_ID_KEY, JSON.stringify(this.data.id));
    this.submissionManagementService.storeCurrentData();
    this.store.storeCurrentUrl();
    this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/RR${subType}/${this.data.accountId}`));
  }

}
