
<div id="select-party">

    <h2>{{'AGREEMENT_CREDITS.Step.main_title' | translate }}</h2>

    <app-step-header *ngIf="headerDetails && !isOpa" [data]="headerDetails"></app-step-header>

    <h2>{{'AGREEMENT_CREDITS.Step_1.selectPartyAgreement' | translate }}</h2>

    <form [formGroup]="form" #ngForm>
      <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
    </form>
   


</div>