import { HttpParams } from '@angular/common/http';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { replaceParam } from '@core/utilities/utilities.constants';
import { environment } from '@env/environment';
import { AGREEMENT_ACTIONS, AGREEMENT_DATA_KEY, AGREEMENT_INPUT_DATA_KEY } from '@module/agreement-credits/components/constant/AgreementCreateCredits.const';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { AGREEMENT_CREATE_CREDITS_STEPS } from '@shared/components/steps/constants/agreement-create-credits';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../../shared/services/modal.service";
import * as i4 from "../../shared/services/helper.service";
import * as i5 from "../../shared/services/upload-document.service";
import * as i6 from "../../shared/services/navigation.service";
import * as i7 from "../../core/store/store.service";
export const AGREEMENT_OPA_LIST = '/account-service/agreement/getOpaList';
export const SAVE_OPA_ACCOUNT = '/account-service/agreement/saveOpaAccount';
export const UPDATE_STATUS = '/account-service/agreement/updateStatus';
export const AGREEMENT_LIST = '/account-service/agreement/pageSearch?';
export const AGREEMENT_LOAD = '/account-service/agreement/load';
export const DOWNLOAD_PDF = '/account-service/agreement/download';
const STEPS_TRANSLATION_PREFIX = 'AGREEMENT_CREDITS.wizard.';
export class AgreementCreditsService extends BaseStepperService {
    constructor(http, router, modalService, helperService, uploadDocumentService, navigationService, store) {
        super();
        this.http = http;
        this.router = router;
        this.modalService = modalService;
        this.helperService = helperService;
        this.uploadDocumentService = uploadDocumentService;
        this.navigationService = navigationService;
        this.store = store;
        this.translationPrefix = STEPS_TRANSLATION_PREFIX;
        // private _destroy$ = new Subject<any>();
        this._stopPrevious$ = new Subject();
        this._refreshData$ = new Subject();
        this.refreshData$ = this._refreshData$.asObservable();
        this.hideSaveAsDraft = false;
    }
    getAgreementList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${AGREEMENT_LIST}`, filterState, { params });
    }
    getAgreementOpaList(account, pay = null) {
        const payload = pay ? pay : {};
        payload.registrationAccountId = account;
        return this.http.post(`${environment.apiUrl}${AGREEMENT_OPA_LIST}`, payload);
    }
    saveOpaAccount(payload, draft = false) {
        const uri = draft ? '' : 'AndProceed';
        if (payload.registrationAccount) {
            payload.registrationAccountId = payload.registrationAccount.id;
        }
        return this.http.post(`${environment.apiUrl}${SAVE_OPA_ACCOUNT}${uri}`, payload);
    }
    updateStatus(payload) {
        const request = {
            id: payload.id,
            action: payload.action,
        };
        return this.http.post(`${environment.apiUrl}${UPDATE_STATUS}`, request);
    }
    submitDocuments(payload) {
        this.triggerBackendAction(payload, true, { actionLabel: 'SUBMIT' });
    }
    getAgreementById(id) {
        return this.http.get(`${environment.apiUrl}${AGREEMENT_LOAD}/${id}`);
    }
    opaExists() {
        return this.currentData.catsAccountOpa && this.currentData.catsAccountOpa.id;
    }
    saveHistoryAndRedirectToAgreementScreen(response, entityId = null, currentData = null) {
        this.store.setHistoryUrl();
        this.redirectToAgreementScreen(response, entityId, currentData);
    }
    redirectToAgreementScreen(response, entityId = null, currentData = null) {
        const uri = entityId ? `agreement/${entityId}` : 'create-agreement';
        const data = Object.assign({}, AGREEMENT_CREATE_CREDITS_STEPS, { inputData: response, currentData });
        this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/wizard/${uri}`), { state: { data } });
    }
    triggerActionController(metadata, back = false) {
        const value = metadata.action;
        const mapData = AGREEMENT_ACTIONS[value.workflowAction] || {};
        const payload = metadata.data;
        if (value.workflowAction.indexOf('DOWNLOAD') > -1) {
            const signed = value.workflowAction.indexOf('_SIGNED_') > -1;
            this.uploadDocumentService.downloadDocument(`${environment.apiUrl}${DOWNLOAD_PDF}/${payload.id}${signed ? '?signed=true' : ''}`);
        }
        else if (value.workflowAction === 'CANCEL_PROJECT') {
            this.router.navigate(this.helperService.getTranslatedPath(`/program-cancellation/${payload.id}`));
        }
        else if (mapData.uiUri) {
            let actionRoute = mapData.uiUri;
            mapData.params.forEach(param => {
                actionRoute = replaceParam(actionRoute, param, payload[param]);
            });
            this.router.navigate(this.helperService.getTranslatedPath(actionRoute));
        }
        else {
            payload.action = value.workflowAction;
            this.triggerBackendAction(payload, back, mapData);
        }
    }
    triggerBackendAction(payload, back, mapData = null) {
        const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageAgreement';
        const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
        console.log(payload.action);
        this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message: confirmationMessage,
            metaDataList: [
                this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
                payload.registrationAccount.legalName,
            ],
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                this.modalService.closeAllMessageDialogs();
                this.updateStatus(payload)
                    .pipe(takeUntil(this._destroy$))
                    .subscribe(response => {
                    if (back) {
                        const rcaSign = payload.action === 'SIGN_AGREEMENT';
                        if (rcaSign) {
                            this.router.navigate(this.helperService.getTranslatedPath(`/agreement-credits/generate/${payload.id}/rca`));
                        }
                        else {
                            this.navigationService.back();
                        }
                    }
                    else {
                        this._refreshData$.next(true);
                    }
                });
            }
            // this.resetSelection();
        });
    }
    getContactPersonInfo(key) {
        return [
            {
                groupFields: false,
                fieldGroup: [
                    {
                        type: 'input',
                        className: 'col-6',
                        key: `${key}.title`,
                        label: 'AGREEMENT_CREDITS.Step_4.title',
                        disabled: false,
                        required: false,
                        id: `${key}.title`,
                    },
                    {
                        type: 'input',
                        className: 'col-6',
                        key: `${key}.name`,
                        label: 'AGREEMENT_CREDITS.Step_4.name',
                        disabled: false,
                        required: true,
                        id: `${key}.name`,
                    },
                    {
                        type: 'input',
                        className: 'col-6',
                        key: `${key}.businessTelephone`,
                        label: 'AGREEMENT_CREDITS.Step_4.telNumber',
                        disabled: false,
                        required: true,
                        id: `${key}.businessTelephone`,
                    },
                    {
                        type: 'input',
                        className: 'col-6',
                        key: `${key}.email`,
                        label: 'AGREEMENT_CREDITS.Step_4.email',
                        disabled: false,
                        required: true,
                        id: `${key}.email`,
                    },
                ],
            },
        ];
    }
    storeCurrentData() {
        const data = JSON.stringify(this.currentData);
        const inputData = JSON.stringify(this.inputData);
        sessionStorage.setItem(AGREEMENT_DATA_KEY, data);
        sessionStorage.setItem(AGREEMENT_INPUT_DATA_KEY, inputData);
    }
    restoreCurrentData(skipRemove = false) {
        const data = sessionStorage.getItem(AGREEMENT_DATA_KEY);
        const inputData = sessionStorage.getItem(AGREEMENT_INPUT_DATA_KEY);
        if (data || inputData) {
            if (!skipRemove) {
                sessionStorage.removeItem(AGREEMENT_DATA_KEY);
                sessionStorage.removeItem(AGREEMENT_INPUT_DATA_KEY);
            }
            return { currentData: JSON.parse(data), inputData: JSON.parse(inputData) };
        }
        else {
            return null;
        }
    }
}
AgreementCreditsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgreementCreditsService_Factory() { return new AgreementCreditsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.UploadDocumentService), i0.ɵɵinject(i6.NavigationService), i0.ɵɵinject(i7.StoreService)); }, token: AgreementCreditsService, providedIn: "root" });
