import { Component, OnInit } from '@angular/core';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { TranslateService } from '@ngx-translate/core';
import { Step } from '@shared/components/steps/models/step';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { UploadDocumentService , DOCUMENT_DOWNLOAD} from '@shared/services/upload-document.service';
import { takeUntil } from 'rxjs/operators';
import { environment } from '@env/environment';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { StoreService } from '@core/store/store.service';

const DOWNLOAD_PDF = '/account-service/agreement/download'

@Component({
  selector: 'app-gen-agreement',
  templateUrl: './gen-agreement.component.html',
  styleUrls: ['./gen-agreement.component.scss']
})
export class GenAgreementComponent extends BaseStepperStepComponent {

  static key = 'GenAgreementComponent';

  existInMarkit: boolean;
  rcaSign:boolean;
  uri: string;

  constructor(
    private translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    public formService: FormService,
    public service: AgreementCreditsService,
    private store:StoreService,
    private _location: Location,
    private uploadDocumentService: UploadDocumentService) { super(); }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.existInMarkit = params.existInMarkit === 'true';
      this.rcaSign =  params.existInMarkit === 'rca';
      this.uri = params.existInMarkit === 'false' ? `${environment.serviceUrl}${DOWNLOAD_PDF}/${params.id}` : `${environment.serviceUrl}${DOWNLOAD_PDF}/${params.id}?signed=true`;
     });
  }

  download() {
    this.uploadDocumentService.downloadDocument(this.uri);
  }

  print() {
    this.uploadDocumentService.printDocument(
      this.uri,
      this.translateService.instant("AGREEMENT_CREDITS.Step_5.printAgreement")
    );
  }

  close() {
    this.store.back();
  }
  saveAndProceed() {
    this.service.nextStep();
  }

}
