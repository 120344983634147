<!-- class="active" aria-current="true" -->
<nav aria-label="progress mb-1" class="step-indicator">
    <ul class="steps">
        <ng-container *ngFor="let step of stepperSteps">
          <li *ngIf="!step.hidden"
              [class.complete]="step.completed"
              [class.active]="current === step.id"
              [class.visited]="step.id <= maxStep"
              [attr.data-id]="step.id"
              fit-to-label
              [class.has-substeps]="step.subSteps && step.subSteps.length > 0 && step.enabledSubSteps">
            <a (click)="goToStep($event, step.id) " href="#" ><span [innerHTML]="step.name"></span></a>
            <span *ngIf="step.completed" class="sr-only">{{'COMMON.labels.completed' | translate }}</span>
            <span *ngIf="!step.completed" class="sr-only">{{'COMMON.labels.notcompleted' | translate }}</span>
            <ng-container *ngIf="step.subSteps && step.subSteps.length > 0 && step.enabledSubSteps">
            <ul class="sub-steps">
              <ng-container *ngFor="let subStep of step.subSteps">
                <li *ngIf="!subStep.hidden"
                    class="sub-step"
                    fit-to-label
                    [class.complete]="subStep.completed"
                    [class.visited]="getSubStep(subStep) <= maxStep"
                    [class.active]="currentSubStep === subStep.subId">
                  <a (click)="goToStep($event, subStep.id, subStep.subId)" href="#"><span [innerHTML]="subStep.name"></span></a>
                  <span *ngIf="subStep.completed" class="sr-only">{{'COMMON.labels.completed' | translate }}</span>
                  <span *ngIf="!subStep.completed" class="sr-only">{{'COMMON.labels.notcompleted' | translate }}</span>
                </li>
              </ng-container>
            </ul>
          </ng-container>
          </li>
        </ng-container>
     </ul>
</nav>
