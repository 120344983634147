<div class="filters">
  <app-table-filter
  *ngIf="tableData && filterFields"
    [fields]="filterFields"
    [config]="tableConfig"
    [filterParams]="filterParams"
    [wideMode]="tableDetails.filterWideMode"
    (filterChanged)="onFilterChanged($event)"
    (filterInit)="onFilterChanged($event, false)"
    (filterCleared)="onFilterCleared()"
    (actionSelected)="onCheckboxActionSelected($event)"
    (newEntryAdded)="newEntryAdded($event)"
    (customButtonAction)="onCustomButtonAction($event)"
  >
  </app-table-filter>
</div>

<div class="summary" *ngIf="summaryTemplateRef">
  <ng-container *ngTemplateOutlet="summaryTemplateRef; context: {$implicit: tableData}"></ng-container>
</div>

<div class="table-area">
  <app-table-view
    [data]="tableData"
    [config]="tableConfig"
    [firstItemIndex]="firstItemIndex"
    (paginationChanged)="onPaginationChanged($event)"
    (checkboxSelected)="onRowSelect($event)"
    [selections]="selections"
  >
  </app-table-view>
</div>

<ng-template #viewColTemplate let-data>
  <app-generic-view-control [rawData]="data"></app-generic-view-control>
</ng-template>

<ng-template #actionTemplate let-data>
  <app-generic-action-control
    [rawData]="data"
    [appendTo]="'.ui-table-wrapper table'"
    (selectedAction)="onActionSelected($event)">
  </app-generic-action-control>
</ng-template>

<ng-template #editActionTemplate let-rowData>
  <div>
      <button class="btn btn-primary" type="button" (click)="openEditPopUp(rowData)">{{'COMMON.actionsLabel.EDIT' | translate }}</button>
  </div>
</ng-template>

