import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import { SereviceMessageType } from '@core/constants/serviceMessage.const';
import { AuthService } from '@core/services/auth.service';
import { StoreService } from '@core/store/store.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@shared/services/helper.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { Observable, of } from 'rxjs';

const USER_LIST_URLS = ['/user/list/', '/utilisateur/liste'];

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild {

  constructor(
    private store: StoreService,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private localizeService: LocalizeRouterService,
    private helperService: HelperService,
    public serviceMessageComponent: NotificationsService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (['/en', '/fr'].includes(state.url)) {
      if (this.store.user.menuItems) {
        const initialPath = this.store.user.menuItems[0].path;
        this.router.navigate(this.helperService.getTranslatedPath(`/${initialPath}`));
        return of(false);
      } else {
        this.router.navigate([this.localizeService.translateRoute('/welcome')]);
        return of(false);
      }
    } else {
      return this.canBeActivated(next, state.url);
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canBeActivated(next, state.url);
  }

  private canBeActivated(next: ActivatedRouteSnapshot, stateUrl: string): Observable<boolean> {
    const session = this.store.getSession();
    if (!session) {
      this.router.navigate([this.localizeService.translateRoute('/welcome')]);
      return of(false);
    } else {
      if ( this.checkUserdetailPresent() || USER_LIST_URLS.map(url => this.localizeService.translateRoute(url)).some(a => stateUrl.includes(a as string))) {
        this.authService.resetInactivity();
        return of(typeof session !== 'undefined');
      } else {
        if (this.store.getTcAccept() === 'loaded') {
          this.serviceMessageComponent.showMessage(SereviceMessageType.ERROR, 'pleaseCompleteUserProfile');
        } else if (this.store.getTcAccept() === 'true') {
          this.store.setTcAccept('loaded');
        }
        this.router.navigate(this.helperService.getTranslatedPath(`/user/list/${this.store.user.id}`));
        return of(typeof session !== 'undefined');
      }
    }
  }

  private checkUserdetailPresent() {
    const userDetailsPresent = this.store.user.businessTelephone; // && this.store.user.title;
    if (this.store.user.isCFR()) {
      if (this.store.user.hasRole('ACCOUNT_HOLDER_ENHANCED_USER') && this.store.user.associationsList[0].accountDto &&
      this.store.user.associationsList[0].accountDto.status !== 'ACTIVE') {
        return true;
      }
      return userDetailsPresent &&  this.store.user.civicAddress;
    }
    return true;
  }
}
