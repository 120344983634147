<p-dialog
[className]="'eccc'"
[closable]="false"
[header]="'REGISTRATION_PAGE.cfrPendingRegistration.createAgreement' | translate"
[(visible)]="visible"
[modal]="true"
[responsive]="true"
[style]="{width: '600px'}"
[minY]="100"
[maximizable]="false"
[autoZIndex]="false">

<div *ngIf="inputData.showNoFacilityFound" class="container p-4">
  <p>{{'AGREEMENT_CREDITS.Step.facilityDoesntExist' | translate}}</p>

  <p class="mt-4"><a  [routerLink]="" (click)="clickRegistrationReport(inputData.data)">{{'AGREEMENT_CREDITS.Step.viewRegistrationReport' | translate}}</a></p>

</div>



<p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button  *ngIf="!inputData.showNoFacilityFound" id="colsebtn" type="button" class="btn btn-primary" (click)="createAgreement()">
            {{'REGISTRATION_PAGE.cfrPendingRegistration.createAgreement' | translate}}
        </button> <button id="colsebtn" type="button" class="btn btn-secondary" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
      </div>
    </div>
  </p-footer>
</p-dialog>