import {
  APPLICATION_CI_PATHWAY,
} from '@module/submission-management/const/application-ci-pathway.const';
import { APPLICATION_CI_PRODUCTION_OF_LOW_CI_FUELS ,
  APPLICATION_CI_CHARGING_STATIONS,
  APPLICATION_CI_FUELING_STATIONS
} from '@shared/components/steps/constants/application-approval-carbon-intensity.const';
import { APPLICATION_CO2E_EMISSION_REDUCTION } from '@shared/components/steps/constants/erp-application.const';
import { APPLICATION_CO_PROCESSED_LOW_CI_FUEL } from '@shared/components/steps/constants/application-co-processed-low-ci-fuel.const';
import { ERP_CANCELLATION } from '@module/emissions-reduction-projects/emissions-reduction-projects.const';
import { IEntityActionList } from '@core/models/action.model';

export const PERMISSION_PROPOSE_TRANSACTION = 'PROPOSE_TRANSACTION';
export const CUSTOMIZED_SUBMISSION_TYPES = [
  'REGISTRATION_REPORT',
  'AGREEMENT_TO_CREATE_CREDITS',
  'AGREEMENT_TO_TRANSFER_CREDIT_UPON_CREATION',
  'NOTICE_OF_SUSPENSION',
];
export const NOS_IN_PROGRESS_RESERVED_ACTIONS: IEntityActionList[] = [
  { workflowAction: 'DISCARD', id: null, enabled: false },
  { workflowAction: 'EDIT', id: null, enabled: false },
];

export const APP_TYPE = {
  CI: 'ci',
  ERP: 'erp',
};

export const REVIEW = '/review';
export const REVIEW2 = '/review2';
export const PROVIDE_INFORMATION = 'PROVIDE_INFORMATION';
export const NEED_MORE_INFORMATION = 'NEED_MORE_INFORMATION';

export const CANCELLATION ={
  action: 'CANCEL',
  uiUri: '/submission-management/cancel-application',
  uri: '/reviewCancellation',
  type: 'CI_PATHWAY_CANCELLATION',
  id: 'cancellationId',
  status: 'cancellationStatus',
  prefix: 'cancel'
};

export const REVIEW_RELEASE ={
  action: 'SUBMIT',
  uiUri: '/submission-management/release-application',
  type: 'CI_PATHWAY_RELEASE',
  uri: '/reviewRelease',
  id: 'releaseId',
  status: 'releaseStatus',
  prefix: 'release'
};

export const RELEASE ={
  action: 'RELEASE',
  uiUri: '/submission-management/release-application',
  type: 'CI_PATHWAY_RELEASE',
  uri: '/reviewRelease',
 // id: 'releaseId',
 // status: 'releaseStatus',
  prefix: 'release'
};

export const SUBMISSION_MANAGEMENT_ACTIONS = {
  SAVE_ADMIN_REMARKS:{
    action: 'SAVE_ADMIN_REMARKS',
    uri: REVIEW,
    actionLabel: 'SAVE_ADMIN_REMARKS2',
  },
  SAVE_ADMIN_REMARKS2:{
    action: 'SAVE_ADMIN_REMARKS2',
    uri: REVIEW,
    actionLabel: 'SAVE_ADMIN_REMARKS2',
  },
  SAVE_COMMENT:{
    action: 'SAVE_COMMENT',
    uri: '/resubmit',
    actionLabel: 'SAVE_ADMIN_REMARKS2',
  },
  APPROVE: {
    action: 'APPROVE',
    uri: REVIEW,
  },
  REJECT: {
    action: 'REJECT',
    uri: REVIEW,
  },
  APPROVE2: {
    action: 'APPROVE',
    uri: REVIEW2,
  },
  RETURN_TO_REVIEWER: {
    action: 'REJECT',
    uri: REVIEW2,
    actionLabel: 'RETURN_TO_REVIEWER',
  },
  UNDER_REVIEW:{
    action: 'NEED_MORE_INFO',
    uri: REVIEW,
    actionLabel: 'requestMoreInformation',
  },
  NEED_MORE_INFORMATION:{
    action: 'NEED_MORE_INFO',
    uri: REVIEW,
    actionLabel: 'requestMoreInformation',
  },
  PROVIDE_INFORMATION:{
    action: 'PROVIDE_INFORMATION',
    uri: '/resubmit',
    actionLabel: 'PROVIDE_INFORMATION',
  },
  DISCARD: {
    action: 'DISCARD',
    uri: '/discard',
    confirmationMessage: 'confirmationMessageApplicationDiscard'
  },
  REVIEW: {
    action: 'NEED_REVIEW',
    uri: REVIEW,
    actionLabel: 'startTheReviewFor',
  },
  APPROVE_START_OF_VERIFICATION_PROCESS:{
    action: 'APPROVE_START_OF_VERIFICATION_PROCESS',
    uri: '/approveStartOfVerification',
    actionLabel: 'approveStartOfVerification',
  },
  SUBMIT_ECCC:{
    action: 'SUBMIT_ECCC',
    uri: '/	submitToEccc',
    actionLabel: 'SUBMIT_ECCC',
  },
  REQUEST_CANCELLATION: {
    action: 'SUBMIT',
    uiUri: '/submission-management/cancel-application',
    type: 'CI_PATHWAY_CANCELLATION',
    uri: '/cancel',
    id: null,
    status: null,
    prefix: 'cancel',
    actionLabel: 'requestCancellationFor',
  },
  VIEW_CANCELLATION: CANCELLATION,
  VIEW_CANCELLATION_ADMIN: CANCELLATION,
  REVIEW_REQUEST_CANCELLATION: CANCELLATION,
  REQUEST_CANCELLATION_ADMIN: CANCELLATION,
  REJECT_REQUEST_CANCELLATION: CANCELLATION,
  REQUEST_RELEASE: {
    action: 'SUBMIT',
    uiUri: '/submission-management/release-application',
    type: 'CI_PATHWAY_RELEASE',
    uri: '/release',
    id: null,
    status: null,
    prefix: 'release'
  },
  RELEASE,
  VIEW_RELEASE: REVIEW_RELEASE,
  REVIEW_RELEASE,
  REJECT_RELEASE: REVIEW_RELEASE,
  REJECT_REQUEST_RELEASE: REVIEW_RELEASE,
  REVIEW_REQUEST_RELEASE: REVIEW_RELEASE,
  REQUEST_ERP_CANCELLATION_ADMIN: ERP_CANCELLATION,
  DOWNLOAD_APPLICATION_PDF: {
    id: 'id',
    actionLabel: 'DOWNLOAD_APPLICATION_PDF',
  },
};

export const applicationTypeConfig = {
  APPLICATION_CI_PATHWAY,
  APPLICATION_CI_PRODUCTION_OF_LOW_CI_FUELS,
  APPLICATION_CI_CHARGING_STATIONS,
  APPLICATION_CI_FUELING_STATIONS,
  APPLICATION_CO2E_EMISSION_REDUCTION,
  APPLICATION_CO_PROCESSED_LOW_CI_FUEL,
};

export const APPLICATION_ID_KEY = 'CI_ApplicationId';
export const APPLICATION_DATA_KEY = 'CI_ApplicationData';
export const APPLICATION_CURRENT_STEP_KEY = 'CI_ApplicationCurrentStep';

export const MAIN_MODULE_PATH = '/submission-management';

export const IRRELEVANT_APPLICATION_FIELDS = [
  'accountSubTypeNames',
  'civicAddress',
  'entityActionList',
  'fullJson',
  'postalAddress',
  'legalName',
  'organizationName',
  'persons',
  'documents',
  'recentApplicationId',
  'creditingPeriod',
  'projectDuration',
  'total',
  'contactPerson._organizationAddress',
  'contactPerson.contactPersonSame',
];
export const MENU_SUBMISSION_MANAGEMENT = 'MENU_SUBMISSION_MANAGEMENT';
