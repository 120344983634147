

  <div class="row mb-5">
    <div class="ml-3 mr-0" style="margin: auto;">
    
        <div [ngClass]="{
        'form-check': to.formCheck.indexOf('custom') === -1,
        'form-check-inline': to.formCheck === 'inline',
        'custom-control custom-checkbox': to.formCheck.indexOf('custom') === 0,
        'custom-control-inline': to.formCheck === 'custom-inline'
        }">
            <input type="checkbox"
                #checkboxEl
                [class.is-invalid]="showError"
                [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
                [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
                [indeterminate]="to.indeterminate && formControl.value === null"
                [formControl]="formControl"
                [id]="elementId"
                [formlyAttributes]="field">
                <label [for]="elementId"
                [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
                [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
            >
                {{ to.label }}
                <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
            </label>
        </div>

    </div>
    <div class="col">
        {{to.data.label | translate}}
    </div>
  </div>
 