import { HttpParams } from '@angular/common/http';
import { ComponentFactoryResolver } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { NON_NULL_OBJECTS, VB_NON_NULL_OBJECTS } from '@module/cfr-registration/cfr-registration.const';
import { APPLICATION_DATA_KEY, APPLICATION_ID_KEY } from '@module/submission-management/const/submission-management.const';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { ADDRESS_FIELDS, SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { PERMISSION_DISCLOSE_COMMENTS } from 'app/app.const';
import { from, throwError } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ExecuteLinkActionModalComponent } from '../components/execute-link-action-modal/execute-link-action-modal.component';
import { RECENT_ENTITY_ID, RECENT_STEP, RECENT_STEP_CODE } from '../containers/main-page/main-page.component';
import { AGREEMENT_DATA_KEY } from '@module/agreement-credits/components/constant/AgreementCreateCredits.const';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../core/store/store.service";
import * as i3 from "../../../shared/services/modal.service";
import * as i4 from "../../../shared/services/helper.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "../../../shared/services/upload-document.service";
export const SAVE_REGISTRATION = '/account-service/cfrAccount/registerCfrAccount';
export const GET_REGISTRATION = '/account-service/account/detail/';
export const GET_REGISTRATION_CFR = '/account-service/cfrAccount/cfrDetail';
export const SEARCH_FACLITY = '/account-service/account/searchFacility/';
export const GET_REGISTRATION_REPORT = '/account-service/cfrAccount/registration/pageSearch';
export const GET_ORGANIZATION_DETAILS = '/account-service/account/getFacility/';
export const SUB_ORGANIZATION_DETAILS = '/account-service/cfrAccount/getSubOrganizations';
export const CHECK_AGREEMENT_SUBMITTED = '/account-service/agreement/checkStatusExistByRegistrationAccountId/';
export const CHECK_AGREEMENT_APPROVED = '/account-service/agreement/checkStatusExistByRegistrationAccountIdOnApproval/';
export const LOOKUP_ACTIVITY_BY_ACCOUNT_SUB_TYPE = '/account-service/lookup/getActivityByAccountSubType/';
export const REPLACE_MAO = '/account-service/cfrAccount/requestReplaceMAO';
export const LOAD_MAO = '/account-service/cfrAccount/maoReplaceRequest/find';
export const APPROVE_REPLACE_MAO = '/account-service/cfrAccount/approveReplaceMAO';
export const REJECT_REPLACE_MAO = '/account-service/cfrAccount/rejectReplaceMAO';
export const REJECT_REPLACE_MAO_REQUEST = '/account-service/cfrAccount/reject/requestReplaceMAO';
export const APPROVE_REPLACE_MAO_REQUEST = '/account-service/cfrAccount/approve/requestReplaceMAO';
export const APPROVE_REGISTRATION_REPORT = '/account-service/cfrAccount/appproveRegistration';
export const REJECT_REGISTRATION_REPORT = '/account-service/cfrAccount/rejectRegistration';
export const SEARCH_OPA_BY_ID = '/account-service/cfrAccount/searchOpaById/';
export const DISCARD_REGISTRATION_REPORT = '/account-service/cfrAccount/discardRegistration';
const STEPS_TRANSLATION_PREFIX = 'CFR_REGISTRATION.registrationSteps.';
export const SUBMIT_REGISTRATION_CANCELLATION = '/account-service/cfrAccount/submitRegistrationCancellation';
export const APPROVE_CANCELLATION = '/account-service/cfrAccount/approveRegistrationCancellation';
export const REJECT_CANCELLATION = '/account-service/cfrAccount/rejectRegistrationCancellation';
export const LOAD_CANCELLATION = '/account-service/cfrAccount/laodRegistrationCancellation';
export const APPROVE_CANCELLATION2 = '/account-service/cfrAccount/approveRegistrationCancellation2';
export const REJECT_CANCELLATION2 = '/account-service/cfrAccount/rejectRegistrationCancellation2';
export const PREVIOUS_DISCUSSION = '/account-service/account/getDiscussions';
export const REGISTRATION_REPORT_DOCUMENT_UPLAOD = '/account-service/registrationDocument';
export const AC = 'http://localhost:8084/api';
export const PROCESS_IDENTITY_VERIFICATION_DOCS = '/account-service/cfrAccount/processIdentityVerificationDocument';
export const PENDING_REGISTRATION_CANCELLATION_UNITS = '/obps-service/unit/pendingRegistrationCancellation';
const accountTypeFieldMap = {
    PS: 'primarySupplier',
    RC: 'registeredCreator',
    FS: 'foreignSupplier',
};
export class CfrRegistrationService extends BaseStepperService {
    constructor(http, storeService, resolver, modalService, helperService, router, _location, uploadDocumentService) {
        super();
        this.http = http;
        this.storeService = storeService;
        this.resolver = resolver;
        this.modalService = modalService;
        this.helperService = helperService;
        this.router = router;
        this._location = _location;
        this.uploadDocumentService = uploadDocumentService;
        this.translationPrefix = STEPS_TRANSLATION_PREFIX;
        this.individualAccount = false;
        this.userId = null;
        this._initialType = {};
        this.formData = {};
        this.ovbAccreditationValid = true;
        this.nonNullObjects = NON_NULL_OBJECTS;
    }
    get code() {
        return this._code;
    }
    set code(value) {
        this._code = value;
    }
    get orgRole() {
        return this._orgRole;
    }
    set orgRole(value) {
        this._orgRole = value;
    }
    get stepCode() {
        return this._stepCode;
    }
    set stepCode(value) {
        this._stepCode = value;
        if (this._stepCode === 'RRVB') {
            this.nonNullObjects = VB_NON_NULL_OBJECTS;
        }
    }
    get accountId() {
        return this._accountId;
    }
    set accountId(value) {
        this._accountId = value;
    }
    get id() {
        return this._id;
    }
    set id(value) {
        this._id = value;
    }
    sameAsOrganizationAddress() {
        console.log('sameAsOrganizationAddress');
    }
    getAccountSubtype(code) {
        return this.currentData.accountSubTypeDtoList ? this.currentData.accountSubTypeDtoList.find(o => o.code === code) : null;
    }
    getElementIndex(entityList, code) {
        return entityList ? entityList.findIndex(o => o.code === code) : -1;
    }
    getElement(entityList, code) {
        return entityList ? entityList.find(o => o.code === code) : null;
    }
    getAddressFromConfig(addressConfig) {
        const label = addressConfig.label ? addressConfig.label : `${addressConfig.prefix}${addressConfig.key}`;
        const index = addressConfig.index ? `${addressConfig.index}_` : '';
        const fieldset = {
            groupFields: true,
            key: addressConfig.key,
            label,
            className: `${addressConfig.hidden ? 'hidden' : 'col-6 col-6-expandable'}`,
            changeExpr: addressConfig.changeExpr,
            hidden: addressConfig.hidden,
            fieldGroup: [
                {
                    type: 'checkbox',
                    key: ADDRESS_FIELDS.sameAsOrganizationAddress,
                    hideExpression: addressConfig.sameAsOrganization.hide,
                    changeExpr: addressConfig.sameAsOrganization.changeExpr,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.sameAsOrganizationAddress',
                    disabled: addressConfig.disabled === false || false,
                    required: false,
                    className: 'col-12',
                    id: `${index}${label}sameAsOrganizationAddress`,
                },
                {
                    type: 'checkbox',
                    key: ADDRESS_FIELDS.sameAsPostalAddress,
                    hideExpression: addressConfig.sameAsPostal.hide,
                    changeExpr: addressConfig.sameAsPostal.changeExpr,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.sameAsPostalAddress',
                    disabled: addressConfig.disabled === false || false,
                    required: false,
                    className: 'col-12',
                    id: `${index}${label}sameAsPostalAddress`,
                },
                {
                    type: 'dict-select',
                    key: ADDRESS_FIELDS.country,
                    source: addressConfig.countrySrc || SOURCE_DATA_TYPE.COUNTRY,
                    dataType: addressConfig.countryDataType,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.county',
                    disabled: addressConfig.disabled === false || addressConfig.disableCountryField || false,
                    formDisabled: addressConfig.formDisabled || false,
                    required: addressConfig.optional !== true,
                    sort: false,
                    defaultValue: !addressConfig.ignoreDefaultCountry ? 'CA' : '',
                    skipItems: addressConfig.skipCountries,
                    translatePrefix: 'COMMON.countryList',
                    id: `${index}country`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                    mapMissingFn: addressConfig.mapMissingFn,
                    onDataLoad: addressConfig.onCountryListLoad,
                },
                {
                    type: 'dummy',
                },
                {
                    type: 'input',
                    key: ADDRESS_FIELDS.streetNumber,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetNum',
                    required: addressConfig.optional !== true,
                    disabled: addressConfig.disabled === false || false,
                    formDisabled: addressConfig.formDisabled || false,
                    id: `${index}${label}streetNumber`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                },
                {
                    type: 'input',
                    key: ADDRESS_FIELDS.streetName,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.streetName',
                    disabled: addressConfig.disabled === false || false,
                    formDisabled: addressConfig.formDisabled || false,
                    required: addressConfig.optional !== true,
                    id: `${index}${label}streetName`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                },
                {
                    type: 'input',
                    key: ADDRESS_FIELDS.city,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.cityTown',
                    disabled: addressConfig.disabled === false || false,
                    formDisabled: addressConfig.formDisabled || false,
                    required: addressConfig.optional !== true,
                    id: `${index}${label}city`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                },
                {
                    type: 'dict-select',
                    key: ADDRESS_FIELDS.province,
                    source: SOURCE_DATA_TYPE.ALL_PROVINCE,
                    translatePrefix: 'COMMON.jurisdictionsList',
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.provState',
                    disabled: addressConfig.disabled === false || false,
                    formDisabled: addressConfig.formDisabled || false,
                    skipItems: addressConfig.disableCountryField && ['OC'],
                    filter: { field: 'country', prop: 'code' },
                    required: addressConfig.optional !== true,
                    id: `${index}${label}province`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                },
                {
                    type: 'input',
                    key: ADDRESS_FIELDS.poBox,
                    hideExpression: addressConfig.hidePoBox,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.poBox',
                    disabled: addressConfig.disabled === false || false,
                    formDisabled: addressConfig.formDisabled || false,
                    required: addressConfig.optional !== true && addressConfig.optionalPoBox !== true,
                    className: 'col-6',
                    id: `${index}${label}poBox`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                },
                {
                    type: 'input',
                    key: ADDRESS_FIELDS.postalCode,
                    label: 'REGISTRATION_PAGE.cfsRegistrationForm.postalCode',
                    disabled: addressConfig.disabled === false || false,
                    formDisabled: addressConfig.formDisabled || false,
                    requiredExpression: !addressConfig.optional && "model && ['CA', 'US'].includes(model.country)",
                    id: `${index}${label}postalCode`,
                    ignoreLocalFormDisabled: addressConfig.formReadOnly,
                },
            ],
        };
        if (!addressConfig.hideLatLong) {
            fieldset.fieldGroup.push({
                type: 'input',
                key: ADDRESS_FIELDS.latitude,
                hideExpression: addressConfig.hideLatLong,
                label: 'REGISTRATION_PAGE.nfmpAccountForm.latitude',
                disabled: addressConfig.disabled === false || false,
                formDisabled: addressConfig.formDisabled || false,
                required: true,
                id: `${index}${label}latitude`,
                validators: ['number', 'lonlatDecimal'],
                icon: { text: 'COMMON.messageSection.decimalLatLongTooltip' },
                max: 90,
                min: -90,
                modelOptions: {
                    updateOn: 'blur',
                },
            }, {
                type: 'input',
                key: ADDRESS_FIELDS.longitude,
                label: 'REGISTRATION_PAGE.nfmpAccountForm.longitude',
                hideExpression: addressConfig.hideLatLong,
                disabled: addressConfig.disabled === false || false,
                formDisabled: addressConfig.formDisabled || false,
                required: true,
                id: `${index}${label}longitude`,
                validators: ['number', 'lonlatDecimal'],
                icon: { text: 'COMMON.messageSection.decimalLatLongTooltip' },
                max: 180,
                min: -180,
                modelOptions: {
                    updateOn: 'blur',
                },
            });
        }
        return fieldset;
    }
    addressCallbackFunction(fieldData, addressPrefix, data, civicAddress, postalAddress, form) {
        console.log(fieldData);
        if (fieldData.id === `${addressPrefix}civicAddress${ADDRESS_FIELDS.sameAsPostalAddress}`) {
            if (data.civicAddress.sameAsPostalAddress) {
                const postalAddress = this.currentData.postalAddress;
                postalAddress[ADDRESS_FIELDS.sameAsPostalAddress] = true;
                const key = civicAddress.key;
                civicAddress.fieldGroup.forEach(element => {
                    console.log(element.key);
                    if (!element.hideExpression && element.key != 'sameAsPostalAddress') {
                        form.get(`${key}.${element.key}`).setValue(postalAddress[element.key]);
                        // form.get(`${key}.${element.key}`).setValue();
                        form.get(`${key}.${element.key}`).disable();
                    }
                });
            }
        }
        else if (fieldData.id === `${addressPrefix}postalAddress${ADDRESS_FIELDS.sameAsOrganizationAddress}`) {
            if (data.postalAddress.sameAsOrganizationAddress) {
                const orgPostalAddress = this.currentData.postalAddress;
                orgPostalAddress[ADDRESS_FIELDS.sameAsOrganizationAddress] = true;
                const key = postalAddress.key;
                postalAddress.fieldGroup.forEach(element => {
                    if (!element.hideExpression) {
                        form.get(`${key}.${element.key}`).setValue(orgPostalAddress[element.key]);
                        form.get(`${key}.${element.key}`).disable();
                    }
                });
            }
        }
    }
    isNewAccount() {
        return !this.id;
    }
    loadReplaceMAORequest(id) {
        return this.http.get(`${environment.apiUrl}${LOAD_MAO}/${id}`);
    }
    submitReplaceMAO(payload) {
        return this.http.post(`${environment.apiUrl}${REPLACE_MAO}`, payload);
    }
    approveReplaceMAORequest(payload) {
        return this.http.post(`${environment.apiUrl}${APPROVE_REPLACE_MAO_REQUEST}`, payload);
    }
    rejectReplaceMAORequest(payload) {
        return this.http.post(`${environment.apiUrl}${REJECT_REPLACE_MAO_REQUEST}`, payload);
    }
    approveReplaceMAO(payload) {
        return this.http.post(`${environment.apiUrl}${APPROVE_REPLACE_MAO}`, payload);
    }
    rejectReplaceMAO(payload) {
        return this.http.post(`${environment.apiUrl}${REJECT_REPLACE_MAO}`, payload);
    }
    processRegistrationReport(uri, payload) {
        return this.http.post(`${environment.apiUrl}${uri}`, payload);
    }
    searchOpaById(accountId) {
        return this.http.get(`${environment.apiUrl}${SEARCH_OPA_BY_ID}${accountId}`);
    }
    getDocumentTypeList(code, accountId) {
        let documentTypeList;
        /**if(code === IApp.NSAccountSubType.StatusEnum.VB){
          documentTypeList =[{
            documentTypeValue: 2,
            documentTypeCode: 'KYC_DOCUMENT',
            id: this.storeService.user.id,
            entityName: 'USER'
          }];
        }else**/
        if (code === 'USER') {
            documentTypeList = [
                {
                    documentTypeValue: 12,
                    documentTypeCode: 'AUTHORIZATION_DOCUMENT',
                    id: this.storeService.user.id,
                    entityName: 'USER',
                },
            ];
        }
        else if (code === 'MAO_USER') {
            documentTypeList = [
                {
                    documentTypeValue: 12,
                    documentTypeCode: 'AUTHORIZATION_DOCUMENT',
                    id: this.storeService.user.id,
                    entityName: 'USER',
                },
                {
                    documentTypeValue: 2,
                    documentTypeCode: 'KYC_DOCUMENT',
                    id: this.storeService.user.id,
                    entityName: 'USER',
                },
            ];
        }
        else if (this.individualAccount) {
            documentTypeList = [
                {
                    documentTypeValue: 2,
                    documentTypeCode: 'KYC_DOCUMENT',
                    id: this.storeService.user.id,
                    overrideId: accountId,
                    overrideEntityName: 'ACCOUNT',
                    entityName: 'USER',
                },
            ];
        }
        else if (code === IApp.NSAccountSubType.StatusEnum.FS ||
            code === IApp.NSAccountSubType.StatusEnum.CIC) {
            documentTypeList = [
                {
                    documentTypeValue: 12,
                    documentTypeCode: 'AUTHORIZATION_DOCUMENT',
                    id: accountId,
                    entityName: 'ACCOUNT',
                }
            ];
        }
        else {
            documentTypeList = [
                {
                    documentTypeValue: 12,
                    documentTypeCode: 'AUTHORIZATION_DOCUMENT',
                    id: accountId,
                    entityName: 'ACCOUNT',
                },
                {
                    documentTypeValue: 2,
                    documentTypeCode: 'KYC_DOCUMENT',
                    id: this.userId || this.storeService.user.id,
                    entityName: 'USER',
                },
            ];
        }
        return documentTypeList;
    }
    getExtraDocumentTypeList(code, accountId) {
        let documentTypeList;
        if (code === 'USER') {
            // do nothing
        }
        else if (code === 'MAO_USER') {
            // do nothing
        }
        else if (this.individualAccount) {
            documentTypeList = [
                {
                    documentTypeValue: 12,
                    documentTypeCode: 'AUTHORIZATION_DOCUMENT',
                    id: accountId,
                    entityName: 'ACCOUNT',
                },
            ];
        }
        else if (code === IApp.NSAccountSubType.StatusEnum.FS) {
            documentTypeList = [
                {
                    documentTypeValue: 2,
                    documentTypeCode: 'KYC_DOCUMENT',
                    id: this.userId || this.storeService.user.id,
                    entityName: 'USER'
                },
            ];
        }
        else {
            /**  documentTypeList =[{
              documentTypeValue:42,
              documentTypeCode: 'LIST_OF_BENEFICIAL_OWNERS',
              id: accountId,
              entityName: 'ACCOUNT'
            },{
              documentTypeValue: 41,
              documentTypeCode: 'ARTICLES_OF_INCORPORATION',
              id: accountId,
              entityName: 'ACCOUNT'
            }]; */
        }
        return documentTypeList;
    }
    getDocumentUploadConfig(code, accountId) {
        let documentUploadConfig = {
            documentType: true,
            entityName: 'USER',
            guidanceTextEntity: 'REGISTRATION',
            // id: this.storeService.user.id,
            comment: true,
            disablePaginator: false,
            pendingSubmissionStatus: true,
            uri: REGISTRATION_REPORT_DOCUMENT_UPLAOD,
        };
        documentUploadConfig.documentUploadConfigs = this.getDocumentTypeList(code, accountId);
        if (this.storeService.user.isOffset()) {
            documentUploadConfig.extraUploadDocuments = this.getExtraDocumentTypeList(code, accountId);
        }
        return documentUploadConfig;
    }
    getOffsetRegistrationDetails(userId) {
        return this.http.get(`${environment.apiUrl}${GET_REGISTRATION}${userId}`);
    }
    getRegistrationDetails(payload) {
        return this.http.post(`${environment.apiUrl}${GET_REGISTRATION_CFR}`, payload).pipe(map(data => {
            const subStepActivators = {};
            for (const key of Object.keys(accountTypeFieldMap)) {
                subStepActivators[accountTypeFieldMap[key]] = this.hasAccountSubType(key);
            }
            return Object.assign({}, data, subStepActivators);
        }));
    }
    checkAgreementSubmitted(id) {
        return this.http.get(`${environment.apiUrl}${CHECK_AGREEMENT_SUBMITTED}${id}`);
    }
    checkAgreementApproved(id) {
        return this.http.get(`${environment.apiUrl}${CHECK_AGREEMENT_APPROVED}${id}`);
    }
    getOrganizationEntityDetails(id) {
        return this.http.get(`${environment.apiUrl}${GET_ORGANIZATION_DETAILS}${id}`).pipe(tap(response => { }));
    }
    searchFacility(path) {
        return this.http.get(`${environment.apiUrl}${SEARCH_FACLITY}${path}`);
    }
    save(action, draft = false, update = false) {
        if (this.currentData.discriminatorType === 'SUB_ORGANIZATION_ACCOUNT') {
            this.currentData.authorizedOfficial = null;
        }
        const data = {
            accountSubTypes: '',
            accountSubTypeDtoList: [],
        };
        const ps = this.checkNotCancellation('PS', data);
        const rc = this.checkNotCancellation('RC', data);
        const fs = this.checkNotCancellation('FS', data);
        const cic = this.checkNotCancellation('CIC', data);
        if ((ps && rc && fs && cic) || this.currentData.status !== 'ACTIVE') {
            if (action === 'SUBMIT') {
                let agreement = false;
                let activityMissing = true;
                this.currentData.accountSubTypeDtoList.map(sybType => {
                    if (sybType.selected) {
                        activityMissing = false;
                        sybType.activities.map(act => {
                            if (act.facilities) {
                                act.facilities.map(f => {
                                    if (f.agreementRequired) {
                                        agreement = true;
                                    }
                                });
                            }
                        });
                    }
                });
                if (activityMissing) {
                    this.showMessage('atLeast1OrganizationRoleShouldBeSelected');
                    return;
                }
                return this.modalService
                    .open(ServiceMessageComponent, {
                    messages: null,
                    message: agreement ? 'confirmationMessageRegReportAgreement' : update && this.participationRequested() && this.currentData.registrationAccountStatus !== 'NEED_MORE_INFO' ? 'UPDATE_REGISTRATION_REPORT' : 'confirmationMessageRegReport',
                    metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${action}`), this.currentData.legalName],
                    type: "WARNING" /* WARNING */,
                }, true, DEFAULT_DIALOG_CONFIG)
                    .afterClosed()
                    .pipe(filter(result => !!result), switchMap(() => this.displayWarningMsgForRoleChange()), filter(result => !!result), switchMap(() => this.http.post(`${environment.apiUrl}${SAVE_REGISTRATION}`, Object.assign({}, this.currentData, { action, updateRegistration: update, currentStep: this.stepNum }))));
            }
            else {
                const uri = draft ? 'AndProceed' : '';
                return this.http
                    .post(`${environment.apiUrl}${SAVE_REGISTRATION}${uri}`, Object.assign({}, this.currentData, { action, currentStep: this.stepNum }))
                    .pipe(tap(response => { }));
            }
        }
        else {
            const roles = data.accountSubTypeDtoList.map(t => t.code).join(',');
            this.showCancellationMessage('registrationCancellationMessage', data, [
                this.translateService.instant(`COMMON.accountSubTypeList.${roles}`),
                data.accountSubTypeDtoList.length === 1 ? '' : 's',
            ]);
        }
    }
    lookupActivityByAccountSubType(accountSubTypeId) {
        return this.http.get(`${environment.apiUrl}${LOOKUP_ACTIVITY_BY_ACCOUNT_SUB_TYPE}${accountSubTypeId}`);
    }
    returnPreviousLocation(event) {
        event && event.preventDefault();
        let applicationId = sessionStorage.getItem(APPLICATION_ID_KEY);
        if (applicationId) {
            applicationId = JSON.parse(applicationId);
            sessionStorage.removeItem(APPLICATION_ID_KEY);
            this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/wizard/application${applicationId ? '/' + applicationId : ''}`));
        }
        else {
            sessionStorage.removeItem(APPLICATION_DATA_KEY);
            const prevPage = this.storeService.lastVisitedUrl || this.storeService.getHistoryUrl();
            if (prevPage) {
                this.router.navigate(this.helperService.getTranslatedPath(prevPage));
            }
            else {
                this._location.back();
            }
        }
    }
    checkNotCancellation(code, data) {
        const inputPs = this._initialType[code];
        const newPs = this.getElement(this.currentData.accountSubTypeDtoList, code);
        if (inputPs && newPs && newPs.selected) {
            data.accountSubTypes = data.accountSubTypes + ',' + code;
            return true;
        }
        else if (!inputPs) {
            return true;
        }
        else {
            data.accountSubTypeDtoList.push(newPs);
            return false;
        }
    }
    setInitialTypes() {
        this.setInitialSelectedType('PS');
        this.setInitialSelectedType('RC');
        this.setInitialSelectedType('FS');
        this.setInitialSelectedType('CIC');
    }
    setInitialSelectedType(code) {
        if (this.activeAccountSubTypeCodes && this.activeAccountSubTypeCodes.length) {
            this._initialType[`${code}`] = !!this.activeAccountSubTypeCodes.includes(code);
            return;
        }
        const ps = this.getElement(this.currentData.accountSubTypeDtoList, code);
        this._initialType[`${code}`] = ps && ps.selected;
        if (ps && ps.selected) {
            this.cleanUpFacilityFuelIds(code);
        }
    }
    cleanUpFacilityFuelIds(code) {
        const psCode = this.currentData.accountSubTypeDtoList.findIndex(r => r.code === code);
        const roleData = this.currentData.accountSubTypeDtoList[psCode];
        this.currentData.accountSubTypeDtoList[psCode] = Object.assign({}, roleData, { activities: this.currentData.accountSubTypeDtoList[psCode]
                .activities.map(act => (Object.assign({}, act, { facilities: act.facilities.map(f => (Object.assign({}, f, { fuelDtoList: f.fuelDtoList.map(fuel => ({
                        id: fuel.id,
                        name: fuel.name,
                    })) }))) }))) });
    }
    getRegistrationReportDetails(payload) {
        return this.http.post(`${environment.apiUrl}${GET_REGISTRATION_REPORT}`, payload).pipe(tap(response => { }));
    }
    getSubOrganizationDetails(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        filterState.discriminatorType = 'SUB_ORGANIZATION_ACCOUNT';
        // tslint:disable-next-line: max-line-length
        return this.http.post(`${environment.apiUrl}${GET_REGISTRATION_REPORT}`, filterState, { params });
    }
    showMessage(message, metaData) {
        this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                Object.assign({ message: message }, (metaData && { metaData })),
            ],
            message: message,
            type: "ERROR" /* ERROR */,
            metaDataList: metaData
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                console.log(result);
            }
        });
    }
    showCancellationMessage(message, inputData, metaDataList) {
        this.modalService
            .open(ServiceMessageComponent, {
            messages: [
                {
                    message: message,
                },
            ],
            message: message,
            metaDataList: metaDataList,
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe((result) => {
            if (result) {
                const data = Object.assign({}, this.currentData, inputData);
                this.router.navigate(this.helperService.getTranslatedPath('/request-registration-cancellation'), {
                    state: { data: { rawData: data, actionType: 'CANCEL_REGISTRATION' } },
                });
            }
        });
    }
    registrationCancellation(payload, uri) {
        return this.http.post(`${environment.apiUrl}${uri}`, payload);
    }
    hasAccountSubType(code) {
        return this.getElementIndex(this.currentData.accountSubTypeDtoList, code) > -1;
    }
    get isAppStoredData() {
        return !!sessionStorage.getItem(APPLICATION_DATA_KEY);
    }
    get isAgreementStoredData() {
        return !!sessionStorage.getItem(AGREEMENT_DATA_KEY);
    }
    getPreviousDiscussions(accountId = null) {
        if (accountId) {
            return this.http.get(`${environment.apiUrl}${PREVIOUS_DISCUSSION}/${accountId}`);
        }
    }
    checkIfIdentityDocRequired(accountSubTypeDtoList = this.currentData.accountSubTypeDtoList) {
        if (!accountSubTypeDtoList || !accountSubTypeDtoList.length) {
            return true;
        }
        const orgRole = accountSubTypeDtoList.filter(dto => dto.selected).map(dto => dto.code);
        return orgRole.some(code => [
            IApp.NSAccountSubType.StatusEnum.RC,
            IApp.NSAccountSubType.StatusEnum.PS,
            IApp.NSAccountSubType.StatusEnum.VB,
        ].includes(code));
    }
    displayWarningMsgForRoleChange() {
        return this.getIdentityVerificationDoc().pipe(switchMap((identityDoc) => {
            if (this.checkIfIdentityDocRequired() && identityDoc.totalElements === 0 && this.currentData.accountSubTypes && this.participationRequested()) {
                const postfix = 'Role';
                let msg = postfix;
                if (this.currentData.accountSubTypes.includes(IApp.NSAccountSubType.StatusEnum.PS)) {
                    msg = 'PS' + msg;
                }
                if (this.currentData.accountSubTypes.includes(IApp.NSAccountSubType.StatusEnum.RC)) {
                    msg = 'RC' + msg;
                }
                if (this.currentData.status === 'PENDING_REVIEW_ADMIN') {
                    this.openLinkDialog();
                    return throwError('error');
                }
                if (msg !== postfix) {
                    return this.openConfirmationModal(msg);
                }
            }
            return from([true]);
        }));
    }
    setEntityActionListForAdditionalActions() {
        if (this.stepCode === 'DUPS') {
            if (this.currentData.registrationAccountStatus === 'PENDING_ADDITIONAL_DOCUMENTATION_REVIEW' && this.storeService.user.hasPermission(PERMISSION_DISCLOSE_COMMENTS)) {
                this.currentData.entityActionList = [
                    ...this.currentData.entityActionList,
                    { workflowAction: "APPROVE_IDENTITY_DOC" },
                    { workflowAction: "REJECT_IDENTITY_DOC" }
                ];
            }
            else if (this.currentData.registrationAccountStatus === 'PENDING_ADDITIONAL_DOCUMENTATION') {
                this.currentData.entityActionList = [
                    ...this.currentData.entityActionList,
                    { workflowAction: "SUBMIT_IDENTITY_DOC" }
                ];
            }
        }
    }
    doAction(action) {
        let msg = '';
        let requestAction;
        switch (action.workflowAction) {
            case 'APPROVE_IDENTITY_DOC':
                msg = 'APPROVE_IDENTITY_DOC';
                requestAction = 'PENDING_ADDITIONAL_DOCUMENTATION_REVIEW_APPROVED';
                break;
            case 'REJECT_IDENTITY_DOC':
                msg = 'REJECT_IDENTITY_DOC';
                requestAction = 'PENDING_ADDITIONAL_DOCUMENTATION_REVIEW_REJECTED';
                break;
            case 'SUBMIT_IDENTITY_DOC':
                msg = 'SUBMIT_IDENTITY_DOC';
                requestAction = 'UPLOAD_ADDITIONAL_DOCUMENTATION';
                break;
        }
        const sub$ = this.getIdentityVerificationDoc().pipe(switchMap(identityDoc => {
            if (identityDoc.totalElements === 0 && requestAction === 'UPLOAD_ADDITIONAL_DOCUMENTATION') {
                this.showMessage('IDENTITY_VERIFICATION_DOC_REQUIRED');
                return throwError('err');
            }
            return this.openConfirmationModal(msg);
        }), filter(result => !!result), switchMap(() => this.processIdentityVerificationDocument(requestAction))).subscribe((result) => {
            sub$.unsubscribe();
            this.resetStepper();
            this.storeService.back();
        });
    }
    openConfirmationModal(msg) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message: msg,
            type: "WARNING" /* WARNING */,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
    closeServiceMessageDialogs() {
        this.modalService.closeServiceMessageDialogs();
    }
    participationRequested() {
        return this.currentData.status && !['PENDING_KYC', 'NOT_SUBMITTED'].includes(this.currentData.status);
    }
    processIdentityVerificationDocument(action) {
        return this.http.post(`${environment.apiUrl}${PROCESS_IDENTITY_VERIFICATION_DOCS}`, { id: this.currentData.id, accountId: this.currentData.accountId, action });
    }
    getIdentityVerificationDoc() {
        const pagination = {
            page: 0,
            size: ROWS_PER_PAGE,
        };
        return this.uploadDocumentService.getAll(pagination, this.userId, 'USER', 'KYC_DOCUMENT');
    }
    openLinkDialog() {
        const sub$ = this.modalService
            .open(ExecuteLinkActionModalComponent, {
            url: `/cfr-registration/cfr/DUPS/${this.currentData.accountId}`,
            actionButtonText: `COMMON.actionsLabel.uploadDocument`,
            linkDescription: `COMMON.messageSection.MISSING_CRITERIA_FOR_ROLE_CHANGE`,
            additionalDescription: null,
            header: `COMMON.messageSection.IDENTITY_VERIFICATION_DOC_REQUIRED`
        })
            .afterClosed()
            .pipe(filter(result => !!result), switchMap(() => this.save('SAVE', true))).subscribe((result) => {
            sub$.unsubscribe();
            sessionStorage.setItem(RECENT_ENTITY_ID, JSON.stringify(result.entity));
            sessionStorage.setItem(RECENT_STEP, JSON.stringify(this.currentStep));
            sessionStorage.setItem(RECENT_STEP_CODE, this.stepCode);
            this.storeService.resetHistoryUrl();
            this.router.navigate(this.helperService.getTranslatedPath(`/cfr-registration/cfr/DUPS/${this.currentData.accountId}`));
        });
    }
    getPendingRegistrationCancellationUnits(accountId) {
        const payload = {
            freeText: null,
            facilityNameList: [accountId],
        };
        return this.http.post(`${environment.apiUrl}${PENDING_REGISTRATION_CANCELLATION_UNITS}`, payload);
    }
    proposedRegistrationUpdateHandler(payload, uri) {
        return this.http.post(`${environment.apiUrl}${uri}`, payload);
    }
}
CfrRegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CfrRegistrationService_Factory() { return new CfrRegistrationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StoreService), i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i3.ModalService), i0.ɵɵinject(i4.HelperService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.Location), i0.ɵɵinject(i7.UploadDocumentService)); }, token: CfrRegistrationService, providedIn: "root" });
