import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { Observable } from 'rxjs';

const PUBLIC_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJDQVRTIiwibmFtZSI6IkFOT05ZTU9VUy1VU0VSLU9GRlNFVCIsImlhdCI6MTY0MTk5NjM3NH0.VIp7y2P5L1k4VolE_8eOwGD9sz5Mxbr9Wk92bz3PjhY';
const authorizationKey = 'Authorization';
const authorizationBackupKey = 'authorizationbackup';
const publicRequestKey = 'public-request';
const PUBLIC_URLS = ['public-registry', 'registre-public'];

@Injectable()
export class PublicViewInterceptor implements HttpInterceptor {

  constructor(
      private store: StoreService,
      private router: Router,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (PUBLIC_URLS.every(url => !this.router.url.split('/').includes(url))) {
      return next.handle(request);
    }
    const headers = {};
    const isUnauthorized = !this.store || !this.store.getSession();
    const apiCall = request.url.indexOf('/api') > -1 || request.url.indexOf('/service') > -1;
    const keepAliveCall = request.url.indexOf('/standards') > -1;
    if (isUnauthorized && apiCall && !keepAliveCall) {
      headers[authorizationKey] = `Bearer ${ PUBLIC_TOKEN }`;
      headers[authorizationBackupKey] = `Bearer ${ PUBLIC_TOKEN }`;
      headers[publicRequestKey] = 'true';
    }
    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }

}
