import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';

@Component({
  selector: 'app-admin-take-action-modal',
  templateUrl: './admin-take-action-modal.component.html',
})
export class AdminTakeActionModalComponent implements OnInit {
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  model: any;
  title: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AdminTakeActionModalComponent>,
    private formFactoryService: FormFactoryService,
  ) { }

  ngOnInit() {
    this.model = this.data.model;
    this.title = this.data.title;
    this.fields = this.formFactoryService.configureForm(this.data.formConfig);
  }

  validateForm() {
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    return this.form.valid;
  }

  customActionHandler() {
    this.handleRequiredFields('customActionMandatoryFields');
    if (this.validateForm()) {
      this.dialogRef.close({
        model: this.model,
        customAction: true
      });
    }
  }

  additionalActionHandler() {
    if (this.validateForm()) {
      this.dialogRef.close({
        model: this.model,
        additionalAction: true
      });
    }
  }

  save() {
    this.handleRequiredFields('primaryActionMandatoryFields');
    if (this.validateForm()) {
      this.dialogRef.close({
        model: this.model
      });
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  handleRequiredFields(actionKey) {
    if (this.data[actionKey]) {
      this.fields[0].fieldGroup.forEach(field => {
        if (this.data[actionKey].includes(field.key)) {
          field['required'] = true;
          if(this.form.get(field.key)){
            this.form.get(field.key).setValidators(Validators.required);
            this.form.get(field.key).updateValueAndValidity();
          }
        } else {
          if (!field.expressionProperties['templateOptions.required']) {
            field['required'] = false;
            if(this.form.get(field.key)){
              this.form.get(field.key).clearValidators();
              this.form.get(field.key).updateValueAndValidity();
            }
          }
        }
    });
    }
  }

}
