
    <app-table-view
      [config]="to.config"
      [data]="data"
      [relative]="true"
      [selections]="selection"
      [isEditable]="to.isEditable"
      [tableContainerMinWidth]="to.tableContainerMinWidth"
      [footerTemplate]="to.footerTemplate"
      [disableOneRowSelection]="to.disableOneRowSelection"
      (checkboxSelected)="onRowSelected($event)"
      (paginationChanged)="onPaginationChanged($event)"
    >
    </app-table-view>
  