var FormlyKeyValuePairs_1;
import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, Validator, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { isNullOrUndefined } from 'util';
import { positiveOrZeroNumber } from '@shared/formly/validators';
import { round } from '@core/utilities/utilities.constants';
let FormlyKeyValuePairs = FormlyKeyValuePairs_1 = 
// tslint:disable-next-line:component-class-suffix
class FormlyKeyValuePairs extends FieldType {
    constructor(translateSvc) {
        super();
        this.translateSvc = translateSvc;
        this.dummy = [{}];
        this._model = {};
        this.ready = false;
        this.onChange = (value) => { };
        this.onTouched = () => { };
    }
    get controlId() {
        return this.to.controlId;
    }
    isInvalid(key) {
        return this.subFormGroup.controls[key].errors &&
            this.subFormGroup.controls[key].dirty &&
            this.subFormGroup.controls[key].errors.required;
    }
    isLessThanZero(key) {
        return this.subFormGroup.controls[key].errors &&
            this.subFormGroup.controls[key].dirty &&
            this.subFormGroup.controls[key].errors.positiveOrZeroNumber;
    }
    ngOnInit() {
        this.form.addControl(this.controlId, this.subFormGroup);
        if (!this.model[this.controlId]) {
            this.model[this.controlId] = {};
        }
        else {
            this._model = Object.assign({}, this.model[this.controlId]);
            Object.keys(this._model).forEach(key => this._model[key] = this._model[key].toFixed());
            this._model = Object.assign({}, this.model[this.controlId]);
            this.calculateSummary();
        }
        setTimeout(() => this.subFormGroup.markAsPristine());
        this.subFormGroup.statusChanges.subscribe(() => {
            this.onChange(this.subFormGroup.value);
            this.onTouched();
        });
    }
    writeValue(value) {
        if (value) {
            this.form.setValue(value);
        }
        else {
            this.form.reset();
        }
    }
    registerOnChange(fn) {
        this.onChange = fn;
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    get subFormGroup() {
        if (this.form.get(this.controlId)) {
            return this.form.get(this.controlId);
        }
        else {
            const subForm = new FormGroup({});
            if (this.to.keyValueConfig && this.to.keyValueConfig.keys.length) {
                this.to.keyValueConfig.keys.forEach(prop => {
                    const control = new FormControl('');
                    if (prop.required) {
                        control.setValidators([Validators.required, positiveOrZeroNumber]);
                    }
                    subForm.addControl(prop.key, control);
                });
            }
            return subForm;
        }
    }
    updateModel(val, prop) {
        this._model[prop.key] = prop.type === 'number' && val ? Number(val) : val;
        Object.keys(this._model).forEach(key => {
            if (isNullOrUndefined(this._model[key])) {
                delete this._model[key];
            }
        });
        this.field.model[this.controlId] = this._model;
        if (this.to.keyValueConfig.summary) {
            this.calculateSummary();
        }
        if (this.to.change) {
            this.to.change(this.field, {
                keys: this._model,
                summary: this.to.keyValueConfig.summary ? this.field.model[this.to.keyValueConfig.summary.key] : null,
            });
        }
    }
    calculateSummary() {
        this.field.model[this.to.keyValueConfig.summary.key] = round(Object.keys(this._model).reduce((sum, key) => sum + Number(this._model[key]), 0), 5);
    }
    get commaSeparator() {
        return this.translateSvc.currentLang === 'en' ? ',' : ' ';
    }
    get translationPrefix() {
        return `${this.to.translatePrefix}.` || '';
    }
    validate() {
        return this.subFormGroup.valid ? null : { invalid: true };
    }
    registerOnValidatorChange(fn) {
        this.subFormGroup.statusChanges.subscribe(fn);
    }
};
FormlyKeyValuePairs = FormlyKeyValuePairs_1 = tslib_1.__decorate([
    UntilDestroy()
], FormlyKeyValuePairs);
export { FormlyKeyValuePairs };
