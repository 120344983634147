import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'agreement-facility-radio',
  template: `

    <div class="ui-g" style="width:500px;margin-bottom:10px">

        <div *ngFor="let option of to.options | formlyCustomSelectOptions:field | async; let i = index;"
        style="margin-top: 20px;"    
        [ngClass]="{
            'ui-g-12  form-check': to.formCheck.indexOf('custom') === -1,
            'ui-g-12  form-check-inline': to.formCheck === 'inline',
            'ui-g-12  custom-control custom-radio': to.formCheck.indexOf('custom') === 0,
            'ui-g-12  custom-control-inline': to.formCheck === 'custom-inline'
            }"
        >       

            <input type="radio"
                [id]="elementId + '_' + i"
                [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
                [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
                [name]="field.name || id"
                [class.is-invalid]="showError"
                [attr.value]="option.value"
                [value]="option.value"
                [formControl]="formControl"
                [formlyAttributes]="field"
            >
            <label
                [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
                [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
                [for]="elementId + '_' + i"
            >
                <span>{{'AGREEMENT_CREDITS.Step_1.mainRadioLabel' | translate }}</span>
                {{ getOpaName(i) }}<span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>        
            </label>

            <div style="margin-left: 5rem;" *ngFor="let uniquefacility of getUniqueFacilities(i)">
              <span [class.is-invalid]="showError">
                {{ uniquefacility.accountName }} ({{'AGREEMENT_CREDITS.Step_1.facilityID' | translate }}: {{ uniquefacility.facilityId }})
              </span>  
            </div>

        </div>
    
    </div>
  `,
})
export class AgreementFacilityRadio extends FieldType implements OnInit {
  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'custom', // 'custom' | 'custom-inline' | 'stacked' | 'inline'
    },
  };

  rawOptions: any;

  ngOnInit() {
    
    this.rawOptions = this.to.options;
  }


  constructor(){
    super();
  }

  getOpaName(index) {
    return this.rawOptions[index].opa;
  }

  getUniqueFacilities(index){
    return this.rawOptions[index].uniqueFacilities;
  }

  isData(option) {
    return option.data;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }
}
