import { Injector } from '@angular/core';
import * as i0 from "@angular/core";
export class AppInjector {
    static setInjector(injector) {
        AppInjector.injector = injector;
    }
    static getInjector() {
        return AppInjector.injector;
    }
}
AppInjector.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInjector_Factory() { return new AppInjector(); }, token: AppInjector, providedIn: "root" });
