import {
  RAW_FORM_INPUT_TYPE_STRING,
  RAW_FORM_TYPE_APP_FORMLY_DATEPICKER,
  RAW_FORM_TYPE_BLURB,
  RAW_FORM_TYPE_BUTTON,
  RAW_FORM_TYPE_CHECKBOX,
  RAW_FORM_TYPE_DICT_SELECT,
  RAW_FORM_TYPE_DUMMY,
  RAW_FORM_TYPE_DYNAMIC_TEMPLATE,
  RAW_FORM_TYPE_EDITABLE_TABLE,
  RAW_FORM_TYPE_INPUT,
  RAW_FORM_TYPE_KEY_VALUE_PAIRS,
  RAW_FORM_TYPE_RADIO,
  RAW_FORM_TYPE_TABLE,
  RAW_FORM_TYPE_TEMPLATE,
  RAW_FORM_TYPE_TEXTAREA,
  RAW_FORM_TYPE_DOCUMENTS,
  RAW_FORM_TYPE_READONLY_INPUT,
} from '@core/models/raw-form.constants';
import {
  IRawForm,
  IRawFormAppFormlyDatepicker,
  IRawFormBlurb,
  IRawFormButton,
  IRawFormCheckbox,
  IRawFormDictSelect,
  IRawFormDocuments,
  IRawFormDummy,
  IRawFormDynamicTemplate,
  IRawFormEditableTable,
  IRawFormInput,
  IRawFormKeyValuePairs,
  IRawFormRadio,
  IRawFormTable,
  IRawFormTemplate,
} from '@core/models/raw-form.types';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const getField = (key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig => {
  for (let i = 0, len = fields.length; i < len; i++) {
    const f = fields[i];
    if (f.key === key) {
      return f;
    }

    if (f.fieldGroup && !f.key) {
      const cf = getField(key, f.fieldGroup);
      if (cf) {
        return cf;
      }
    }
  }
};

const defaultValue = <T, R>(value: T, defaultValue: R): T | R => (typeof value === typeof defaultValue ? value : defaultValue);

const applyIdKeyLabel = <T extends IRawForm>(field: T): T => {
  const { id, key, label } = field;
  return {
    ...field,
    id: id || '',
    key: key || id || '',
    label: label || '',
  };
};

export const getLabel = (field: Partial<IRawFormTemplate> = {}): IRawFormTemplate => {
  const { parse, translate } = field;
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_TEMPLATE,
    parse: defaultValue(parse, true),
    translate: defaultValue(translate, true),
  });
};

export const getDynamicLabel = (field: Partial<IRawFormDynamicTemplate> = {}): IRawFormDynamicTemplate => {
  const { parse, translate } = field;
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_DYNAMIC_TEMPLATE,
    parse: defaultValue(parse, true),
    translate: defaultValue(translate, true),
  });
};

export const getDictSelect = (field: Partial<IRawFormDictSelect> = {}): IRawFormDictSelect => {
  const { required } = field;
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_DICT_SELECT,
    required: defaultValue(required, true),
  });
};

export const getInput = (field: Partial<IRawFormInput> = {}): IRawFormInput => {
  const { type, placeholder, className, required, translate, min, max } = field;
  return applyIdKeyLabel({
    ...field,
    type: type || RAW_FORM_TYPE_INPUT,
    placeholder: placeholder || '',
    className: className || 'col-2',
    translate,
    min: min || null,
    max: max || null,
    required: defaultValue(required, true),
  });
};


export const getReadOnly = (field: Partial<IRawFormInput> = {}): IRawFormInput => {
  const { type, placeholder, className, required } = field;
  return applyIdKeyLabel({
    ...field,
    type: type || RAW_FORM_TYPE_READONLY_INPUT,
    placeholder: placeholder || '',
    className: className || 'col-2',
    required: defaultValue(required, true),
  });
};


export const getTextArea = (field: Partial<IRawFormInput> = {}): IRawFormInput => {
  const { type, placeholder, className, required } = field;
  return applyIdKeyLabel({
    ...field,
    type: type || RAW_FORM_TYPE_TEXTAREA,
    placeholder: placeholder || '',
    className: className || 'col-2',
    required: defaultValue(required, true),
  });
};

export const getAppFormlyDatepicker = (field: Partial<IRawFormAppFormlyDatepicker> = {}): IRawFormAppFormlyDatepicker => {
  const { placeholder, inputType, required } = field;
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_APP_FORMLY_DATEPICKER,
    placeholder: placeholder || '',
    inputType: inputType || RAW_FORM_INPUT_TYPE_STRING,
    required: defaultValue(required, true),
  });
};

export const getRadio = (field: Partial<IRawFormRadio> = {}): IRawFormRadio => {
  const { required } = field;
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_RADIO,
    required: defaultValue(required, true),
  });
};

export const getButton = (field: Partial<IRawFormButton> = {}): IRawFormButton => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_BUTTON,
  });
};

export const getCheckbox = (field: Partial<IRawFormCheckbox> = {}): IRawFormCheckbox => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_CHECKBOX,
  });
};

export const getDummy = (field: Partial<IRawFormDummy> = {}): IRawFormDummy => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_DUMMY,
  });
};

export const getBlurb = (field: Partial<IRawFormBlurb> = {}): IRawFormBlurb => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_BLURB,
  });
};

export const getEditableTable = (field: Partial<IRawFormEditableTable>): IRawFormEditableTable => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_EDITABLE_TABLE,
  });
};

export const getTable = (field: Partial<IRawFormTable>): IRawFormTable => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_TABLE,
  });
};

export const getKeyValuePairs = (field: Partial<IRawFormKeyValuePairs>): IRawFormKeyValuePairs => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_KEY_VALUE_PAIRS,
  });
};

export const getDocuments = (field: Partial<IRawFormDocuments>): IRawFormDocuments => {
  return applyIdKeyLabel({
    ...field,
    type: RAW_FORM_TYPE_DOCUMENTS,
  });
};
