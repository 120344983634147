/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./text-only-type.component";
var styles_FormlyTextOnly = [];
var RenderType_FormlyTextOnly = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyTextOnly, data: {} });
export { RenderType_FormlyTextOnly as RenderType_FormlyTextOnly };
export function View_FormlyTextOnly_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "input-group"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.elementId; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.to.textData; _ck(_v, 2, 0, currVal_1); }); }
export function View_FormlyTextOnly_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-text-only-file", [], null, null, null, View_FormlyTextOnly_0, RenderType_FormlyTextOnly)), i0.ɵdid(1, 49152, null, 0, i1.FormlyTextOnly, [], null, null)], null, null); }
var FormlyTextOnlyNgFactory = i0.ɵccf("formly-text-only-file", i1.FormlyTextOnly, View_FormlyTextOnly_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyTextOnlyNgFactory as FormlyTextOnlyNgFactory };
