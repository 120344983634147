import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBell, faCaretSquareLeft, faClone, faEnvelope, faEnvelopeOpen, faLifeRing, faPlusSquare, } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle, faMinusCircle, faQuestionCircle, faTimesCircle, } from '@fortawesome/free-solid-svg-icons';
import { FieldType } from '@ngx-formly/core';
import { registerTranslateExtension } from './formly/formly-extensions/translate.extension';
export function showError(f) {
    return (f.formControl &&
        f.formControl.invalid &&
        (f.formControl.dirty || (f.options.parentForm && f.options.parentForm.submitted) || (f.field.validation && f.field.validation.show)));
}
const ɵ0 = {}, ɵ1 = [], ɵ2 = registerTranslateExtension;
export class SharedModule {
    constructor(library) {
        library.addIcons(faBell, faLifeRing, faExclamationCircle, faCheckCircle, faTimesCircle, faPlusSquare, faCaretSquareLeft, faChevronLeft, faChevronRight, faChevronDown, faChevronUp, faExclamationTriangle, faEnvelope, faEnvelopeOpen, faCircle, faMinusCircle, faChevronDown, faChevronUp, faQuestionCircle, faInfoCircle, faClone);
    }
}
export { ɵ0, ɵ1, ɵ2 };
