/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./offset-account-type.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i7 from "@ngx-formly/core";
import * as i8 from "./offset-account-type.component";
import * as i9 from "../../../modules/account-management/services/account-management.service";
import * as i10 from "../../services/form-factory.service";
import * as i11 from "../../../modules/registration/registration.service";
import * as i12 from "../../../core/store/store.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../core/services/lookup.service";
import * as i15 from "../../services/form.service";
import * as i16 from "../../../core/services/auth.service";
import * as i17 from "../../services/modal.service";
import * as i18 from "../../services/navigation.service";
var styles_OffsetAccountTypeComponent = [i0.styles];
var RenderType_OffsetAccountTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OffsetAccountTypeComponent, data: {} });
export { RenderType_OffsetAccountTypeComponent as RenderType_OffsetAccountTypeComponent };
function View_OffsetAccountTypeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_OffsetAccountTypeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REGISTRATION_PAGE.offsetRegistrationForm.save")); _ck(_v, 1, 0, currVal_0); }); }
function View_OffsetAccountTypeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "max-width mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OffsetAccountTypeComponent_2)), i1.ɵdid(5, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "mt-2 button-panel"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OffsetAccountTypeComponent_3)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, [" ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 4); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.showSave; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("REGISTRATION_PAGE.offsetRegistrationForm.close")); _ck(_v, 10, 0, currVal_2); }); }
function View_OffsetAccountTypeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_OffsetAccountTypeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "offset-stp2-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("COMMON.actionsLabel.SUBMIT")); _ck(_v, 1, 0, currVal_0); }); }
function View_OffsetAccountTypeComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["id", "offset-stp2-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REGISTRATION_PAGE.offsetRegistrationForm.nextStepButton")); _ck(_v, 1, 0, currVal_0); }); }
function View_OffsetAccountTypeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OffsetAccountTypeComponent_5)), i1.ɵdid(1, 540672, null, 0, i4.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.previousStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OffsetAccountTypeComponent_6)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["nextStepLbl", 2]], null, 0, null, View_OffsetAccountTypeComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 4); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.onestep; var currVal_3 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("REGISTRATION_PAGE.stepperSection.back")); _ck(_v, 4, 0, currVal_1); }); }
function View_OffsetAccountTypeComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCM ? i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REGISTRATION_PAGE.accountTypeSection.cmAccountRegistrationTitleSUBACC")) : i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("REGISTRATION_PAGE.accountTypeSection.offsetAccountRegistrationTitleSUBACC"))); _ck(_v, 1, 0, currVal_0); }); }
function View_OffsetAccountTypeComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isCM ? i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("REGISTRATION_PAGE.accountTypeSection.nfmpAccountRegistration")) : i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("REGISTRATION_PAGE.accountTypeSection.offsetAccountRegistrationTitle"))); _ck(_v, 1, 0, currVal_0); }); }
function View_OffsetAccountTypeComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["id", "offset-accunt-type"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OffsetAccountTypeComponent_9)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["titleSubacc", 2]], null, 0, null, View_OffsetAccountTypeComponent_10)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, [[1, 0], ["ngForm", 1]], null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 13).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 13).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i5.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(13, 540672, null, 0, i5.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.FormGroupDirective]), i1.ɵdid(15, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 2, "formly-form", [], null, null, null, i6.View_FormlyForm_0, i6.RenderType_FormlyForm)), i1.ɵprd(512, null, i7.FormlyFormBuilder, i7.FormlyFormBuilder, [i7.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(18, 966656, null, 0, i7.FormlyForm, [i7.FormlyFormBuilder, i7.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i5.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onestep; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_10 = _co.formGroup; _ck(_v, 13, 0, currVal_10); var currVal_11 = _co.formGroup; var currVal_12 = _co.model; var currVal_13 = _co.fields; _ck(_v, 18, 0, currVal_11, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("REGISTRATION_PAGE.accountTypeSection.offsetAccRegSubTitle")); _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 15).ngClassValid; var currVal_8 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_OffsetAccountTypeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { formEl: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OffsetAccountTypeComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["new", 2]], null, 0, null, View_OffsetAccountTypeComponent_4)), (_l()(), i1.ɵand(0, [["form", 2]], null, 0, null, View_OffsetAccountTypeComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accountId; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_OffsetAccountTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-offset-account-type", [], null, null, null, View_OffsetAccountTypeComponent_0, RenderType_OffsetAccountTypeComponent)), i1.ɵdid(1, 245760, null, 0, i8.OffsetAccountTypeComponent, [i9.AccountManagementService, i10.FormFactoryService, i3.TranslateService, i11.RegistrationService, i12.StoreService, i13.ActivatedRoute, i14.LookupService, i15.FormService, i16.AuthService, i17.ModalService, i18.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OffsetAccountTypeComponentNgFactory = i1.ɵccf("app-offset-account-type", i8.OffsetAccountTypeComponent, View_OffsetAccountTypeComponent_Host_0, { model: "model", registrationType: "registrationType", masterAccountData: "masterAccountData", onestep: "onestep" }, { back: "back", next: "next" }, []);
export { OffsetAccountTypeComponentNgFactory as OffsetAccountTypeComponentNgFactory };
