import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { buildQuerySelector } from '@core/utilities/utilities.constants';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@shared/components/ng-select/ng-select.component';
import { cloneDeep } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'formly-field-dictionary-select',
  template: `

    <fa-icon
          *ngIf="to.toolTip"
          pTooltip="{{to.toolTip.text | translate}}"
          tooltipStyleClass="tooltip-min-width"
          tooltipPosition="{{to.toolTip?.tooltipPosition ? to.toolTip?.tooltipPosition : 'top'}}"
          [icon]="to.icon?.iconProp ? to.toolTip.iconProp : ['fas', 'info-circle']">
    </fa-icon>

    <input type="text"
           *ngIf="isFreeText"
           [formControl]="formControl"
           [id]="elementId"
           class="form-control"
           [disable-with-validation]="disabledField"
           [disabled]="isFormDisabled"
           [formlyAttributes]="field"
           [placeholder]="''"
           [class.is-invalid]="showError">

    <input type="hidden"
           *ngIf="!isFreeText"
           [id]="elementId"
           tabindex="-1"
           class="wcag-visuallyhidden">

    <ng-select
        wcag-label
        *ngIf="!isFreeText"
        [id]="elementId + '_dropdown'"
        [items]="items"
        [placeholder]="placeholder | translate"
        [inputAttrs]="inputAttrs"
        [formControl]="formControl"
        [bindLabel]="to.bindLabel || 'name'"
        [bindValue]="to.bindValue || 'id'"
        [clearable]="clearable"
        [groupBy]="getGroupBy()"
        [selectableGroup]="true"
        [class.custom-disabled]="isDisabled"
        [disabled]="isFormDisabled"
        [dropdownPosition]="dropdownPosition"
        [searchable]="to.searchable"
        [loading]="isLoading"
        [tabIndex]="tabIndex"
        [notFoundText]="notFoundText | translate"
        [loadingText]="loadingText | translate"
        [appendTo]="appendTo"
        (change)="to.change && to.change(field, $event)"
        [multiple]="to.multiple"
        [virtualScroll]="virtualScroll"
        [closeOnSelect]="!to.multiple"
        (focus)="onFocus($event)">

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <span *ngIf="item" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
        </ng-template>

        <ng-template ng-label-tmp let-item="item" let-index="index">
          <span *ngIf="item" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
        </ng-template>

        <ng-template ng-optgroup-tmp let-item="item">
          <span class="ng-option-label" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
        </ng-template>

        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
          <ng-container *ngIf="items && items.length > 0">
            <div class="ng-value" *ngFor="let item of items">
              <span class="ng-value-label" [innerHTML]="item && item[to.bindLabel || 'name']"></span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>
          </ng-container>
        </ng-template>


</ng-select>


  `,
})
export class FormlyFieldDictionarySelect extends FieldType implements OnInit, OnDestroy {
  onDestroy$ = new Subject<void>();
  isLoading = false;
  allItems = [];
  items = [];
  source: Observable<IApp.IDict[]>;
  fluxSource: Observable<IApp.IFluxDict>;
  dataProvided: boolean;
  clearable: boolean;
  virtualScroll = true;
  public notFoundText: string;
  public placeholder: string;
  public loadingText = 'COMMON.actionsLabel.loading';

  public translatePrefix;
  public appendTo = 'body';

  public sortItems = true;
  public inputAttrs = {};
  public selectedValue;

  disabledField = false;
  localFormDisabled = false;
  multiple: boolean;
  dropdownPosition = 'auto';

  public isFreeText = false;

  private unchangedData: any[];

  @ViewChild(NgSelectComponent, {static: false}) ngSelectComponent: NgSelectComponent;

  constructor(
    private elRef: ElementRef,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    if (this.to.appendTo === 'self') {
      this.to.appendTo = `#${ this.elementId }_dropdown`;
      this.dropdownPosition = 'bottom';
    }
    if (this.to.searchable === undefined) {
      this.to.searchable = true;
    }
    this.formControl.valueChanges
      .pipe(
        filter(val => val === null),
        takeUntil(this.onDestroy$),
      ).subscribe(val => {
        if (this.field.defaultValue) {
          this.formControl.setValue(this.field.defaultValue);
        }
      });

    this.appendTo = this.to.appendTo || 'body';
    this.getScrollableParent();
    this.localFormDisabled = this.to.formDisabled || this.to.disabled;
    if (this.form.enabled) {
      const formControlDisableFn = this.formControl.disable;
      const formControlEnableFn = this.formControl.enable;
      this.formControl.disable = opts => {
        if (this.to.ignoreLocalFormDisabled) {
          formControlDisableFn.apply(this.formControl, arguments);
        }
        this.disabledField = true;
      };

      this.formControl.enable = opts => {
        if (this.to.ignoreLocalFormDisabled) {
          formControlEnableFn.apply(this.formControl, arguments);
        }
        this.disabledField = false;
      };
    }

    if (this.to.virtualScroll !== undefined) {
      this.virtualScroll = this.to.virtualScroll;
    }

    if (this.to.sort === false) {
      this.sortItems = this.to.sort;
    }

    if (this.to.reload$) {
      this.to.reload$
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => this.reloadData());
    }

    this.translatePrefix = this.to.translatePrefix ? `${ this.to.translatePrefix }.` : '';
    this.source = this.to.source;
    this.fluxSource = this.to.fluxSource;
    this.dataProvided = this.to.dataProvided;
    this.clearable = 'clearable' in this.to ? this.to.clearable : true;
    if (this.fluxSource) {
      this.loadFluxData();
    } else if (this.dataProvided) {
      this.loadProvidedData();
    } else {
      this.loadData();
    }
    this.notFoundText = this.translatePrefix ? `${ this.translatePrefix }notFoundText` :
        `ADMIN_MODULE.termsAndConditions.notFoundText`;
    const placeholderText = !this.to.ignorePlaceholderTranslatePrefix ? `${ this.translatePrefix }${ this.to.placeholder }` : this.to.placeholder;
    this.to.placeholder && (this.placeholder = placeholderText);
    this.to.loadingText && (this.loadingText = this.to.loadingText);

    if (!this.to.multiple && !this.multiple) {
      this.formControl.valueChanges
        .pipe(
          takeUntil(this.onDestroy$),
          filter(() => !this.isFreeText),
        )
        .subscribe(val => {
          this.clearUnlisted();
        });
    }

    if (this.to.filter) {
      this.form.get(this.to.filter.field).valueChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(val => {
          this.checkIsFreeText();
          this.filterItems();
        });
      setTimeout(() => {
        this.checkIsFreeText();
        this.filterItems();
      });
    }

    this.setInputAttrs();
    this.resetDataOnLanguageChange();
  }

  private reloadData() {
    if (this.fluxSource) {
      this.loadFluxData();
    } else if (this.to.dataProvided) {
      this.loadProvidedData();
    } else {
      this.loadData();
    }
  }

  filterItems(): void {
    if (this.to.filter) {
      const val = this.form.get(this.to.filter.field) && this.form.get(this.to.filter.field).value;
      if (val) {
        this.items = [...this.filterFn(this.to.filter.prop, val)];
        this.clearUnlisted();
      }
    } else {
      this.prePopulate();
    }
  }

  filterFn(prop, val) {
    let items = this.allItems.filter(i => i[prop] === val);
    if (items.length === 0) { items = this.allItems.filter(i => i[prop] === null); }
    return items;
  }

  get disabled() {
    return this.to.disabled;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.field.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }

  isFormDisabled(): boolean {
    return this.form.disabled === true || this.localFormDisabled;
  }

  private loadData() {
    this.isLoading = true;
    this.source
      .pipe(
        takeUntil(this.onDestroy$),
        filter(d => d !== null),
        map((data: IApp.IDict[]) => data.filter(el => this.to.skipItems ? !this.to.skipItems.includes(el.id) : true)),
        map(data => {
          if (!this.to.mapMissingFn || typeof this.to.mapMissingFn !== 'function') {
            return data;
          }
          const val = this.form.get(this.key) && this.form.get(this.key).value;
          return (!data.find(i => i.id === val)) ? [...data, this.to.mapMissingFn(val)] : data;
        }),
        tap(data => {
          if (this.to.onDataLoad && typeof this.to.onDataLoad === 'function') {
            this.to.onDataLoad(data);
          }
        }),
        tap(data => {
          this.unchangedData = data;
        }),
        map((data: IApp.IDict[]) => {
          return this.translateData(data);
        }),
      )
      .subscribe(data => {
        this.setData(data);
      },
        err => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        });
  }

  private loadFluxData() {
    this.isLoading = true;
    this.fluxSource
      .pipe(
        takeUntil(this.onDestroy$),
        tap(data => {
          this.unchangedData = data.roleDtos;
        }),
        map((data: IApp.IFluxDict) => {
         return this.translateData(this.unchangedData);
        }),
      )
      .subscribe(data => {
        this.allItems = data;
        this.items = data;
        if (this.sortItems) {
        this.items.sort((a, b) => a[this.to.bindLabel || 'name'].localeCompare(b[this.to.bindLabel || 'name']));
      } this.filterItems();
        this.filterItems();
        this.isLoading = false;
    });
  }

  private loadProvidedData() {
    if (this.to.translateOptions && this.to.translatePrefix) {
      let data = cloneDeep(this.to.items);
      data = data.map(el => ({...el, name: this.translateService.instant(`${this.translatePrefix}${el[this.to.bindLabel || 'name']}`) }));
      this.allItems = data;
      this.items = data;
    } else {
      this.allItems = this.to.items;
      this.items = this.to.items;
    }
  }

  get label() {
    let label = '';
    if (this.to.required) {
      label = label + 'Required field, ';
    }
    label += this.to.placeholder === 'selectItem' ? this.to.label : this.placeholder;
    return label;
  }

  prePopulate() {
    setTimeout(() => {
      const preventAutoPopulate = (typeof this.to.preventAutoPopulate === 'function') ? this.to.preventAutoPopulate() : this.to.preventAutoPopulate;
      if (!preventAutoPopulate && this.ngSelectComponent && this.ngSelectComponent.itemsList.items.length === 1 && this.field.key !== 'opaAgreementId') {
        this.ngSelectComponent.select(this.ngSelectComponent.itemsList.items[0]);
      }
      if (!this.to.skipInitialBlur) {
        this.ngSelectComponent && this.ngSelectComponent.blur();
      }
    });
  }

  get isDisabled() {
    return this.disabledField;
  }

  getScrollableParent() {
    if (this.to.ignoreDropdownAppend || this.isFreeText || !this.ngSelectComponent) {
      return;
    }

    setTimeout(() => {
      let a = this.ngSelectComponent.element;
      const els = [];
      while (a) {
        els.unshift(a);
        a = a.parentNode as HTMLElement;
      }
      const revEls = els.reverse();
      const scrollableElIdx = revEls.findIndex(e => e.scrollHeight !== undefined && e.clientHeight !== undefined && e.clientHeight > 0 && e.scrollHeight > e.clientHeight + 1);
      if (scrollableElIdx >= 1) {
        const el = revEls[scrollableElIdx - 1];
        if (el.tagName !== 'APP-BASE-STEPPER') {
          el.style.position = 'relative';
          el.style.display = el.style.display || 'block';
          const _el = buildQuerySelector(el);
          this.appendTo = _el || 'body';
        }
      }
    });
  }

  clearUnlisted() {
    setTimeout(() => {
      if (!this.isFreeText) {
        const val = this.field.model && this.field.model[this.field.key];
        const tmp = this.items as any;
        const ids = tmp.map(i => ([i[this.to.bindValue || 'id'], ...this.to.groupBy && i[this.to.groupBy] ? i[this.to.groupBy].map(j => j[this.to.bindValue || 'id']) : []])).flat();
        if (val && this.items && this.items.length > 0 && !ids.includes(val)) {
          this.ngSelectComponent.handleClearClick();
        }
        this.prePopulate();
      }
    });
  }

  setInputAttrs() {
    const attrs = { 'aria-label': this.label };
    this.inputAttrs = attrs;
  }

  public get tabIndex(): number {
    return this.isDisabled || this.field.hide ? -1 : 0;
  }

  onFocus(event) {
    this.isDisabled && event && event.preventDefault();
  }

  getGroupBy(): string {
    if (this.to.groupBy && typeof this.to.groupBy === 'function') {
      return this.to.groupBy();
    }

    return this.to.groupBy;
  }

  private translateData(data) {
    if (this.to.sortByLang) {
      const d = cloneDeep(data);
      const langIndex = d.findIndex(el => el.lang === this.translateService.currentLang.toUpperCase());
      const firstItem = langIndex > -1 ? d.splice(langIndex, 1) : [];
      data = [...firstItem, ...d];
    }
    if (this.to.translateOptions && data) {
      const translated = data.map(el => ({
        ...el,
        name: this.translateService.instant(`${this.translatePrefix}${el[this.to.bindLabel || 'name']}`),
      }));
      if (this.sortItems) {
        return translated.sort((a, b) => a.name.localeCompare(b.name));
      } else if (this.field.key === 'country') {
        const topItems = translated.splice(0, 2);
        return [...topItems, ...translated.sort((a, b) => a.name.localeCompare(b.name))];
      } else {
        return translated;
      }
    } else {
      return data;
    }
  }

  private resetDataOnLanguageChange() {
    this.translateService.onLangChange
    .pipe(takeUntil(this.onDestroy$))
    .subscribe(() => {
      if (this.dataProvided) {
        this.loadProvidedData();
      } else {
        if (this.to.reloadSourceOnLangChange) {
          this.loadData();
        } else {
          const data = this.translateData(this.unchangedData);
          data && this.setData(data);
        }
      }
    });
  }

  private setData(data) {
    this.allItems = data;
    this.items = data;
    if (this.field.key === 'accountId') {
          this.to.change && this.to.change(this.field, { id: this.model[this.field.key],
             certificateNumber: this.model['certificateNumber'],
            initial: true });
        } else {
          setTimeout(() => {
            this.model && this.to.change && this.to.change(this.field,
              {
                id: this.model[this.field.key],
                selectedItems: this.ngSelectComponent.selectedValues,
                initial: true,
              });
          });
        }
    this.filterItems();
    // if (!this.items.length && !this.isDisabled && !this.to.validateEmpty) {
    //   this.ngSelectComponent && this.ngSelectComponent.handleClearClick();
    //   this.formControl.setErrors(null);
    // }
    this.isLoading = false;
  }

  checkIsFreeText(): void {
    this.isFreeText = this.to.freeTextExpr ? Function( `return ${this.to.freeTextExpr}`).bind(this)() : false;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
