import { Injectable } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { ModalService } from '@shared/services/modal.service';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_STORAGE_KEY = 'docsInProgress';

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadInProgressService {

  private data: IApp.IDocumentInProgress[] = [];
  private _inProgress$ = new BehaviorSubject<boolean>(false);
  public inProgress$ = this._inProgress$.asObservable();

  constructor(
    private modalService: ModalService,
  ) {
    this.load();
  }

  public init() {
    this.data = [];
    this.save();
  }

  private save() {
    sessionStorage.setItem(DEFAULT_STORAGE_KEY, JSON.stringify(this.data));
  }

  private load() {
    this.data = JSON.parse(sessionStorage.getItem(DEFAULT_STORAGE_KEY) || '[]');
  }

  public set(uri, id) {
    this.load();
    this.data.push({uri, id});
    this.updateState();
    this.save();
  }

  public unset(uri) {
    this.load();
    const idx = this.data.findIndex(d => d.uri === uri);
    if (idx > -1) {
      this.data.splice(idx, 1);
    }
    this.updateState();
    this.save();
  }

  public get() {
    this.load();
    return this.data;
  }

  private showWarning() {
    const messages: IDisplayMessages = {
      messages: [{message: 'documentsInProgress'}],
      type: SereviceMessageType.WARNING_ONLY,
    };
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe();
  }

  private updateState() {
    const inProgress = this.data.length > 0;
    this._inProgress$.next(inProgress);
  }

  public isInProgress(showMessage = false): boolean {
    this.load();
    const inProgress = this.data.length > 0;
    if (inProgress) {
      setTimeout(() => {
        if (showMessage) {
          this.showWarning();
        }
      });
    }
    return inProgress;
  }
}
