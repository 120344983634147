import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

const CODE_LENGTH = 6;

@Component({
  selector: 'app-confirmation-code',
  templateUrl: './confirmation-code.component.html',
  styleUrls: ['./confirmation-code.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmationCodeComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmationCodeComponent),
      multi: true,
    },
  ],
})
export class ConfirmationCodeComponent implements OnInit, ControlValueAccessor {

  @Input('isInvalid') isInvalid: boolean;

  c = [];
  output = [];
  value: string;
  propagateChange: any = () => {};

  onChange: any = () => {};
  onTouched: any = () => {};
  validateFn: any = () => {};

  validate(control: AbstractControl) {
    const isNotValid = this.output.length < CODE_LENGTH;
    return isNotValid && {
      invalid: true,
    };
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    } else {
      this.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  constructor() {
    this.c = new Array(CODE_LENGTH).fill(null);
  }

  reset() {
    this.value = null;
    this.output = [];
  }

  ngOnInit() {
  }

  onKeyUp(event, i) {
    if (event.key === 'Backspace') {
      this.output.splice(i, 1);

      this.value = this.output.join('');
      this.propagateChange(this.value);

      if (i > 0) {
        const prev = event.srcElement.previousElementSibling;
        prev.focus();
      }
    } else if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
      const value = event.target.value[0];
      this.output[i] = value;

      this.value = this.output.join('');
      this.propagateChange(this.value);

      if (i < CODE_LENGTH - 1) {
        const next = event.srcElement.nextElementSibling;
        if (value) {
          next.focus();
        }
      }
    } else {
      event.preventDefault();
    }
  }

  onFocus(event) {
    event.srcElement.select();
  }

  onKeyDown(event) {
    if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab'].includes(event.key)
      || (event.key !== 'Tab' && event.shiftKey)) {
      event.preventDefault();
    }
  }

}
