import { Injectable } from '@angular/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { LOCALES } from '../../app.const';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(private translateService: TranslateService, private localizeService: LocalizeRouterService) {}

  getTranslatedPath(path: string): (string | any)[] {
    const fragments = path.split('/');
    if (LOCALES.includes(fragments[1])) {
      fragments.splice(1, 1);
    }
    return fragments.map(fragment =>
      fragment === '' ? `/${this.translateService.currentLang}` : this.localizeService.translateRoute(fragment),
    );
  }

  hasTranslation(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }

}
