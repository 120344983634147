import { Component } from "@angular/core";
import { FieldArrayType, FieldType } from "@ngx-formly/core";

@Component({
  selector: "coi-checklist-radio-type",
  template: `

  <style>
  .subset {
    padding-left: 5rem !important;
  }
  </style>
  <div class="card mb-5">
    <div class="row">
      <div class="col-6 p-3"  [ngClass]="{'subset' : to.data.isSubset}"> 
        {{to.data.label | translate}}
      </div>
      <div class="col-6 p-3 pl-5">
        <div *ngFor="let option of to.options | formlyCustomSelectOptions:field | async; let i = index;"
          [ngClass]="{
            'form-check': to.formCheck.indexOf('custom') === -1,
            'form-check-inline': to.formCheck === 'inline',
            'custom-control custom-radio': to.formCheck.indexOf('custom') === 0,
            'custom-control-inline': to.formCheck === 'custom-inline'
          }"
        >
          <input type="radio"
            [id]="elementId + '_' + i"
            [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
            [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
            [name]="field.name || id"
            [class.is-invalid]="showError"
            [attr.value]="option.value"
            [value]="option.value"
            [formControl]="formControl"
            [formlyAttributes]="field"
          >

          <label
            [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
            [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
            [for]="elementId + '_' + i">
              {{ option.label }}
          </label>

        </div>
      </div>
    </div>
  </div>
 `,
})
export class CoiChecklistradioType extends FieldType {
  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'custom-inline', // 'custom' | 'custom-inline' | 'stacked' | 'inline'
    },
  };

  isData(option) {
    return option.data;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }
}
