<div class="app-panel">
  <app-blurb *ngIf="isMAO" [message]="maoLabel"></app-blurb>

    <h2 class="float-left">
        <a href="#"
           class="arrow-back"
           wcag-dummy-link-target
           role="button"
           aria-label="Back"
           (click)="returnPreviousLocation($event)"
           (keypress)="returnPreviousLocation($event)">
          <fa-icon class="float-left back-icon"  [icon]="['fas', 'chevron-left']"></fa-icon>
          <span style="display: none">back</span>
        </a>
        {{ 'ACCOUNTS_MODULE.addUser.userDetailsText' | translate }}</h2>
    <form [formGroup]="newUserForm" #ngForm>
      <formly-form
        [form]="newUserForm"
        [model]="userData"
        [fields]="newUserFields">
      </formly-form>
    </form>
</div>

<div class="panel" style="min-height: 400px;"  *ngIf = "loaded">
  <h2>{{ label | translate }}</h2>
  <app-linked-accounts
    *ngIf="userData && userData.associationsList"
    [readonly]="!formEnabled"
    [userId]="userData.id"
    [items]="userData.associationsList"
    [canDeleteAccount]="!userData.userCreateAllowed"
    [program]="program"
    [relative]="true"
    (listChanged)="onListChanged($event)"
    (accountTypes)="onAccountTypesChange($event)"
    (hasAdminRole)="hasAdminRole($event)">
  </app-linked-accounts>
</div>

<form [formGroup]="newUserForm" #ngForm>
  <formly-form
    [form]="newUserForm"
    [model]="userData"
    [fields]="cfrFormFields">
  </formly-form>
</form>


<div class="panel" style="min-height: 400px;" *ngIf = "!selfUpdate">
  <app-documents [documentsPage]="documentsPage" [disabled]="documentsDisabled"
   [documentUploadConfig]="documentUploadConfig" (documentResult)="updateDocumentDetails($event)">
  </app-documents
  >
</div>



<div class="sticky-action-button sticky-down">
  <div class="row " *ngIf="formEnabled || enableSubmit">
    <div class="col-12">
      <button class="btn btn-primary ml-2 float-right" *ngIf="enableSubmit"
              disableOnUpload
              (click)= "saveUser(newUserForm)">
        {{ submitButtonLabel | translate }}</button>



      <div *ngFor="let entityAction of userData.entityActionList">

        <button  class="btn btn-primary ml-2 float-right"
              type="button"
              (click)="triggerActionController(entityAction)">
              {{ 'COMMON.actionsLabel.' + entityAction.workflowAction | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
