import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TableTemplateActionControl } from '@core/models/tableTemplateControls.model';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class TableTemplateControls {

    private action = new Subject<TableTemplateActionControl>();

    constructor(
      private translateService: TranslateService,
    ) { }
    
    nextAction(data){
        this.action.next(data);
    }

    get _action() {
        return this.action;
    }
  }
  