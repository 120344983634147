import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getAllParams, replaceParam } from '@core/utilities/utilities.constants';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'formly-parsed-template',
  template: `<div>
    <div class="d-inline" [ngClass]="to.class" [innerHtml]="template" [attr.id]="to.id"></div>&nbsp;
    <fa-icon
      *ngIf="to.icon"
      pTooltip="{{to.icon?.text | translate}}"
      tooltipPosition="{{to.icon?.tooltipPosition ? to.icon?.tooltipPosition : 'top'}}"
      [icon]="to.icon?.iconProp ? to.icon.iconProp : ['fas', 'info-circle']">
    </fa-icon>
  </div>`,
})
export class CustomFormlyTemplateType extends FieldType {

  private innerHtml = { content: null, template: null };

  constructor(
    private translateService: TranslateService,
    private sanitizer: DomSanitizer) {
    super();
  }

  private getFieldValue(prop) {
    return this.field.model[prop] || '';
  }

  get template() {
    if (this.to.template) {
      let template = this.to.template;
      if (this.to.translate) {
        template = this.translateService.instant(this.to.template);
      }

      const params = getAllParams(template);

      params.forEach(param => {
        console.log(param, '=>', this.form.get(param));
        template = replaceParam(template, param, this.getFieldValue(param));
      });

      this.innerHtml = {
        template,
        content: this.to.safeHtml ? this.sanitizer.bypassSecurityTrustHtml(template) : template,
      };
    }

    return this.innerHtml.content;
  }
}
