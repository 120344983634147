
    <div [formGroup]="subFormGroup" class="mb-3">
    <p-table [ngClass]="'eccc'" [value]="dummy">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let header of to.keyValueConfig.headers"
              [innerHTML]="translationPrefix + header | translate"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body">
        <tr *ngFor="let prop of to.keyValueConfig.keys">
          <td>
            <span *ngIf="prop.required" class="wcag-visuallyhidden">Required field, </span>
            <span [innerHTML]="translationPrefix + prop.key | translate"></span>
            <span *ngIf="prop.required" aria-hidden="true">*</span>:
          </td>
          <td>
            <ng-container *ngIf="prop.type === 'number'; else NORMAL">
              <label class="wcag-visuallyhidden" [for]="prop.key">{{ prop.key }}</label>
              <input
                [allowNegativeNumbers]="prop.allowNegativeNumbers"
                [class.is-invalid]="isInvalid(prop.key) || isLessThanZero(prop.key)"
                [formlyAttributes]="field"
                [formControlName]="prop.key"
                maxlength="19"
                [id]="prop.key"
                [mask]="prop.mask || 'separator.0'"
                [required]="prop.required"
                [thousandSeparator]="commaSeparator"
                [ngModel]="_model[prop.key]"
                (ngModelChange)="updateModel($event, prop)"
                class="form-control"
                type="text"
              />
              <div class="invalid-feedback" *ngIf="isInvalid(prop.key); else CHECK_NEGATIVE_VALUE">
                <span [innerHTML]="'COMMON.messageSection.fieldRequiredException' | translate"></span>
              </div>
              <ng-template #CHECK_NEGATIVE_VALUE>
                <div class="invalid-feedback" *ngIf="isLessThanZero(prop.key)">
                  <span [innerHTML]="'COMMON.messageSection.valueCannotBeLessThanZero' | translate"></span>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #NORMAL>
              <label class="wcag-visuallyhidden" [for]="prop.key">{{ prop.key }}</label>
              <input
                class="form-control"
                [formControl]="form[controlId][prop.key]"
                [class.is-invalid]="isInvalid(prop.key)"
                [required]="prop.required"
                [formControlName]="prop.key"
                (ngModelChange)="updateModel($event, prop)"
              />
              <div class="invalid-feedback" *ngIf="isInvalid(prop.key)">
                <span [innerHTML]="'COMMON.messageSection.fieldRequiredException' | translate"></span>
              </div>
            </ng-template>
          </td>
        </tr>
      </ng-template>
      <ng-container *ngIf="to.keyValueConfig.summary">
      <ng-template pTemplate="footer">
        <tr>
          <td><span [innerHTML]="translationPrefix + to.keyValueConfig.summary.label | translate"></span></td>
          <td>
            <label class="wcag-visuallyhidden" [for]="'summary_' + to.keyValueConfig.summary.key">{{ 'summary_' + to.keyValueConfig.summary.key }}</label>
            <input
                wcag-label
                [formlyAttributes]="field"
                [disabled]="true"
                [id]="'summary_' + to.keyValueConfig.summary.key"
                [ngModelOptions]="{standalone: true}"
                [mask]="to.keyValueConfig.summary.mask || 'separator.0'"
                [thousandSeparator]="commaSeparator"
                [ngModel]="model[to.keyValueConfig.summary.key]"
                class="form-control"
                type="text"
              />
          </td>
        </tr>
      </ng-template>
      </ng-container>
    </p-table>
    </div>
  