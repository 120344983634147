import { getPrecision, validateDecimalPlace, validateMaxDecimalPlace } from '@core/utilities/utilities.constants';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const CUSTOM_VALIDATORS = {
  phone: {
    expression: c => /^\+[1-9]{1}[0-9]{3,14}$/.test(c.value),
    message: 'COMMON.messageSection.InvalidTelephoneFormat',
  },
  lonlatDecimal: {
    expression: c => {
      if (c.value) {
        const test = validateDecimalPlace(c.value, 5);
        if (!test) {
          return test;
        } else {
          if (getPrecision(c.value) > 5) {
            setTimeout(c.setValue((+c.value).toFixed(5), {emitEvent: false}));
          }
          return test;
        }
      } else {
        return true;
      }
    },
    message: 'COMMON.messageSection.accurateToFourDecimals',
  },
  number: {
    expression: c => !isNaN(c.value),
    message: 'COMMON.messageSection.valueMustBeNumber',
  },
  email: {
    expression: c => {
      const validityExpression = new RegExp('^(?!.*(?:\\.-|-\\.))[^@]+@[^\\W_](?:[\\w-]*[^\\W_])?(?:\\.[^\\W_](?:[\\w-]*[^\\W_])?)+$');
      return validityExpression.test(c.value);
    },
    message: 'COMMON.messageSection.InvalidEmailFormatException',
  },
  postalCode: {
    expression: c => /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(c.value),
    message: 'COMMON.messageSection.invalidPostalCodeException',
  },
  positiveNumber: {
    expression: c => !(isNaN(c.value) || +c.value <= 0),
    message: 'COMMON.messageSection.valueMustBePositiveNumber',
  },
  positiveOrZeroNumber: {
    expression: c => !(isNaN(c.value) || +c.value < 0),
    message: 'COMMON.messageSection.valueCannotBeLessThanZero',
  },
  notRequiredEmail: {
    expression: c => {
      if (!c.value) {
        return true;
      }
      const validityExpression = new RegExp('^(?!.*(?:\\.-|-\\.))[^@]+@[^\\W_](?:[\\w-]*[^\\W_])?(?:\\.[^\\W_](?:[\\w-]*[^\\W_])?)+$');
      return validityExpression.test(c.value);
    },
    message: 'COMMON.messageSection.InvalidEmailFormatException',
  },
  onlyNumbers: {
    expression: c =>/^[0-9]*$/.test(c.value),
    message: 'COMMON.messageSection.InvalidInput'
  },
  validateToTwoDecimalPlaces: {
    expression: c => {
      if (c.value) {
        const test = validateMaxDecimalPlace(c.value, 2);
        return test
      } else {
        return true;
      }
    },
    message: 'COMMON.messageSection.accurateToTwoDecimals',
  },
  validateEmptyString: {
    expression: c => {
     return !c.value || c.value.trim();
    },
    message: 'COMMON.messageSection.fieldRequiredException'
  }
};


export const postalCodeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const country = control.parent.get('country');

  return control && control.value && country && country.value === 'CA' &&
    !/^\s*[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]\s*$/.test(control.value) ?
    { invalidPostalCodeException: true } :
    null;
};

export const positiveOrZeroNumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return (isNaN(control.value) || +control.value < 0) ? {positiveOrZeroNumber: true} : null;
};
