import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-data-checkbox-wrapper',
  templateUrl: './data-checkbox-wrapper.component.html',
  styleUrls: ['./data-checkbox-wrapper.component.scss']
})
export class DataCheckboxWrapperComponent extends FieldWrapper{

  constructor() {
    super();
  }

  public executeCallback(event, callback) {
    event.preventDefault();
    callback();
  }


}
