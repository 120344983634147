/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./formly-fieldset.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i3 from "@fortawesome/angular-fontawesome";
import * as i4 from "@angular/platform-browser";
import * as i5 from "primeng/tooltip";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../../node_modules/primeng/fieldset/primeng-fieldset.ngfactory";
import * as i8 from "primeng/fieldset";
import * as i9 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i10 from "primeng/api";
import * as i11 from "@angular/common";
import * as i12 from "./formly-fieldset.component";
var styles_FormlyFieldsetComponent = [i0.styles];
var RenderType_FormlyFieldsetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormlyFieldsetComponent, data: {} });
export { RenderType_FormlyFieldsetComponent as RenderType_FormlyFieldsetComponent };
function View_FormlyFieldsetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "fa-icon", [["class", "ng-fa-icon"], ["tooltipPosition", "top"], ["tooltipStyleClass", "tooltip-min-width"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i2.View_FaIconComponent_0, i2.RenderType_FaIconComponent)), i1.ɵdid(1, 573440, null, 0, i3.FaIconComponent, [i4.DomSanitizer, i3.FaConfig, i3.FaIconLibrary, [2, i3.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(2, 2), i1.ɵdid(3, 4341760, null, 0, i5.Tooltip, [i1.ElementRef, i1.NgZone], { tooltipPosition: [0, "tooltipPosition"], tooltipStyleClass: [1, "tooltipStyleClass"], escape: [2, "escape"], text: [3, "text"] }, null), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "fas", "info-circle"); _ck(_v, 1, 0, currVal_2); var currVal_3 = "top"; var currVal_4 = "tooltip-min-width"; var currVal_5 = false; var currVal_6 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 3, i1.ɵnov(_v, 4).transform(((_co.to.icon == null) ? null : _co.to.icon.text))), ""); _ck(_v, 3, 0, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).title; var currVal_1 = i1.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FormlyFieldsetComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fieldComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 9, "p-fieldset", [], [[8, "id", 0]], null, null, i7.View_Fieldset_0, i7.RenderType_Fieldset)), i1.ɵdid(2, 49152, null, 0, i8.Fieldset, [i1.ElementRef], { styleClass: [0, "styleClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 6, "p-header", [], null, null, null, i9.View_Header_0, i9.RenderType_Header)), i1.ɵdid(4, 49152, null, 0, i10.Header, [], null, null), (_l()(), i1.ɵeld(5, 0, null, 0, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, 0, ["", "", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_FormlyFieldsetComponent_1)), i1.ɵdid(9, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 16777216, [[1, 3], ["fieldComponent", 1]], 1, 0, null, null, null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.className; _ck(_v, 2, 0, currVal_1); var currVal_5 = _co.to.icon; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.elementId; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.label)); _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.number; var currVal_4 = _co.starChar; _ck(_v, 7, 0, currVal_3, currVal_4); }); }
export function View_FormlyFieldsetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-formly-fieldset", [], null, null, null, View_FormlyFieldsetComponent_0, RenderType_FormlyFieldsetComponent)), i1.ɵdid(1, 114688, null, 0, i12.FormlyFieldsetComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldsetComponentNgFactory = i1.ɵccf("app-formly-fieldset", i12.FormlyFieldsetComponent, View_FormlyFieldsetComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldsetComponentNgFactory as FormlyFieldsetComponentNgFactory };
