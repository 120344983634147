import { IApp } from '@core/models/app.interfaces';

export class TermsAndConditionsModel{
    content?: string;
    createdBy?: number;
    createdDate?: any;
    deleted?: boolean;
    effectiveFrom?: string;
    id?: number;
    link?: string;
    version?: number;    
    type?: string;
}

export class UserTandC implements IApp.IUserTandc  {
    accepted?: string;
    deleted?: boolean;
    id?: number;
    tandcId?: number;
    userId?: number;
}

export class TandcSearchFilter implements  IApp.ITandcSearchFilter {
    freeText?: string;
    pagination?: IApp.IPagination;
}

export class TermsAndConditions{
    effectiveFrom?:Date;
    contentEn?:any;
    contentFr?:any;  
    id?: number;
    type?: string;

    constructor(type: string){
        this.id = null;
        this.effectiveFrom = null;
        this.contentEn = '';
        this.contentFr = '';
        this.type = type;
    }
}