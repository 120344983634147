
    <div class="form-group" [class.has-error]="!isValid">
      <div class="input-group">
        <input type="text"
               [id]="elementId"
               class="form-control"
               [(ngModel)]="accountId"
               [class.is-invalid]="!isValid"
               [placeholder]="to.placeholder"
               (blur)="updateAccountId()"
               (keyup)="clearErrors()">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary"
                  type="button"
                  (click)="search()">{{'COMMON.filters.filterSearch' | translate}}</button>
        </div>
        <div *ngIf="accountFound"
             class="invalid-feedback"
             style="color: green"
             role="alert"
             [class.d-block]="accountFound">{{ 'COMMON.filters.accountFound' | translate }}: {{_accountName}}</div>
      </div>
    </div>
  