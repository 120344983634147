import { Component, OnDestroy, OnInit,Input } from '@angular/core';
import { topTransactionsTableConfig } from '@module/account-management/account-management.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IApp } from '@core/models/app.interfaces';
import {ActivityLogFilterModel, FilterModel} from '@core/models/filter.model';
import { StoreService } from '@core/store/store.service';

@Component({ 
  selector: 'app-top-transactions',
  templateUrl: './top-transactions.component.html',
  styleUrls: ['./top-transactions.component.scss'],
})
export class TopTransactionsComponent implements OnInit, OnDestroy {

  topTransactionsTableConfig = topTransactionsTableConfig;
  filterState : ActivityLogFilterModel ={};
  
  @Input() 
  topTransactions: IApp.IActivityLog[];
  labelPrefix: string;
  private _destroy$ = new Subject<any>();
  private pagination = {
    page: 0,
    size: 5,
  } as IApp.IPagination;

  constructor(
    private accountManagementService: AccountManagementService,
    private store: StoreService
  ) { }

  ngOnInit() {
  this.labelPrefix = this.store.user.getProgramPrefix();
   
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
