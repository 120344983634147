/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./text-only-field.component";
import * as i2 from "@ngx-translate/core";
var styles_FormlyFieldTextOnlyField = [];
var RenderType_FormlyFieldTextOnlyField = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyFieldTextOnlyField, data: {} });
export { RenderType_FormlyFieldTextOnlyField as RenderType_FormlyFieldTextOnlyField };
export function View_FormlyFieldTextOnlyField_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "input", [["class", "wcag-visuallyhidden"], ["type", "hidden"]], [[8, "value", 0], [8, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currValue; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.currValue; var currVal_2 = _co.elementId; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_FormlyFieldTextOnlyField_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-text-only-field", [], null, null, null, View_FormlyFieldTextOnlyField_0, RenderType_FormlyFieldTextOnlyField)), i0.ɵdid(1, 114688, null, 0, i1.FormlyFieldTextOnlyField, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormlyFieldTextOnlyFieldNgFactory = i0.ɵccf("formly-text-only-field", i1.FormlyFieldTextOnlyField, View_FormlyFieldTextOnlyField_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyFieldTextOnlyFieldNgFactory as FormlyFieldTextOnlyFieldNgFactory };
