import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import * as moment from 'moment';
import { DETAILS_FORM } from '../consts/project-builder.const';
import { ProjectBuilderService } from '../project-builder.service';
import { PROJECT_DESCRIPTION_PREFIX } from './project-description.constants';
import { cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-project-description',
  templateUrl: './project-description.component.html',
  styleUrls: ['./project-description.component.scss'],
})
export class ProjectDescriptionComponent implements OnInit, OnDestroy {
  readonly projectDescriptionPrefix = PROJECT_DESCRIPTION_PREFIX;

  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  projectUpdate = false;

  private _destroy$ = new Subject<any>();

  constructor(
    private formFactoryService: FormFactoryService,
    public projectBuilderService: ProjectBuilderService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.checkProjectUpdate();
    this.setFields();
    this.validateFormOnSubmit();
    this.checkDisabledForm();
    this.translateService.onLangChange.pipe(
      takeUntil(this._destroy$)
    ).subscribe(() => {
      this.setFields();
      this.checkDisabledForm();
      setTimeout(() => {
        this.projectBuilderService.updateControlsValueAndValidity();
      });
    });
  }

  validateFormOnSubmit(): void {
    this.projectBuilderService.validateFormsReq$.pipe(untilDestroyed(this)).subscribe(() => {
      this.projectBuilderService.updateControlsValueAndValidity();
      this.formFactoryService.markFormGroupDirty(this.projectBuilderService.projectForm);
      this.projectBuilderService.validateDescriptionFormsRes$.next(this.projectBuilderService.projectForm.valid);
    });
  }

  private setFields(): void {
    const fields = this.getFields();
    this.fields = this.formFactoryService.configureForm(fields);
  }

  private getFields() {
    const fields = cloneDeep(DETAILS_FORM);
    const currentLang = this.translateService.currentLang;
    fields[0].fieldGroup[0].disabled = this.projectUpdate;
    fields[0].fieldGroup[1].disabled = this.projectUpdate;
    fields[0].fieldGroup[6].label = this.getAddressLabel();

    if (currentLang === 'fr') {
      const engDescription = fields[0].fieldGroup[3];
      fields[0].fieldGroup[3] = {
        ...fields[0].fieldGroup[4],
        expressionProperties: null,
        required: true
      }
      fields[0].fieldGroup[4] = {
        ...engDescription,
        required: false,
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        }
      }
      const engName = fields[0].fieldGroup[0];
      fields[0].fieldGroup[0] = {
        ...fields[0].fieldGroup[1],
        expressionProperties: null,
        required: true
      }
      fields[0].fieldGroup[1] = {
        ...engName,
        required: false,
        expressionProperties: {
          'templateOptions.disabled': '(model.disabled && !model.editPublicVisibility)',
        }
      }
    }

    // const projectId = this.activatedRoute.snapshot.params['id'];
    // if (projectId) {
    //   const startDate = this.projectBuilderService.model.activity.creditingPeriodStartDate;
    //   if (typeof startDate === 'string') {
        //const date = new Date(startDate);
        // https://jira.ihsmarkit.com/browse/CATS-4416
        // Date will be calculated as follows: "Crediting Period Start Date + 12 months + 6 months"
        //date.setMonth(date.getMonth() + 18);
        //const label = `${moment(date).format('YYYY-MM-DD')}`;
        //const description = this.translateService.instant(`${PROJECT_DESCRIPTION_PREFIX}.verificationDue`)
        //fields[2].fieldGroup[0].label = `${label} ${description}`;
      // } else {
      //   delete fields[2];
      // }
    // } else {
    //   delete fields[1];
    //   delete fields[2];
    // }
    if (!this.projectBuilderService.model.projectId) {
      delete fields[1];
      delete fields[2];
    }
    return fields;
  }

  private checkDisabledForm() {
    if (this.projectBuilderService.checkForDisabledState()) {
      setTimeout(() => {
        this.projectBuilderService.projectForm.disable();
      });
    }
  }

  private checkProjectUpdate() {
    this.projectUpdate = this.projectBuilderService.updateProject || false;
  }

  private getAddressLabel(): string {
    let data = this.translateService.instant(`${PROJECT_DESCRIPTION_PREFIX}.projectProponentContact`);
    data += `*:`;
    if (this.projectBuilderService.model.account) {
      const authOfficial = this.projectBuilderService.model.account.authorizedOfficial;
      const address = cloneDeep(authOfficial.civicAddress);
      if (address) {
        address.province = address.province && this.translateService.instant(`COMMON.jurisdictionsList.${address.province}`);
        address.country = address.country && this.translateService.instant(`COMMON.countryList.${address.country}`);
        address.city = address.city ? address.city + ',' : '';
        address.poBox = address.poBox ? address.poBox + ' &nbsp' : '';
        data += `<br><div class=\"disabled-text-box\">${address.streetNumber} ${address.streetName}<br>${address.city} ${address.province}<br>${address.poBox}${address.postalCode}<br>${address.country}<br>${authOfficial.businessTelephone}<br>${authOfficial.email}</div>`;
      }
    }
    return data;
  }

  get isProjectActive(): boolean {
    return ['ACTIVE', 'PENDING_CANCELLED'].includes(this.projectBuilderService.model.status)
      && this.projectBuilderService.isProjectAccountActive;
  }

  private updateControlsValueAndValidity() {
    setTimeout(() => {
      if (this.translateService.currentLang === 'en') {
        this.projectBuilderService.projectForm.get('name2').clearValidators();
        this.projectBuilderService.projectForm.get('name2').reset(this.projectBuilderService.model.name2);
        this.projectBuilderService.projectForm.get('name2').updateValueAndValidity();
        this.projectBuilderService.projectForm.get('description2').clearValidators();
        this.projectBuilderService.projectForm.get('description2').reset(this.projectBuilderService.model.description2);
        this.projectBuilderService.projectForm.get('description2').updateValueAndValidity();
      } else {
        this.projectBuilderService.projectForm.get('name').clearValidators();
        this.projectBuilderService.projectForm.get('name').reset(this.projectBuilderService.model.name);
        this.projectBuilderService.projectForm.get('name').updateValueAndValidity();
        this.projectBuilderService.projectForm.get('description').clearValidators();
        this.projectBuilderService.projectForm.get('description').reset(this.projectBuilderService.model.description);
        this.projectBuilderService.projectForm.get('description').updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
