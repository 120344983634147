import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProgramFacilityPropertiesData, IProgramPropertiesData, IProtocolManagementConfigData, ISystemPropertiesData } from '@core/models/admin.model';
import { IApp } from '@core/models/app.interfaces';
import {FilterModel} from '@core/models/filter.model';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { forkJoin, Observable } from 'rxjs';
import { IOffsetConfigData } from './../../../core/models/admin.model';

export const ADMIN_MGMT_FACILITIES = '/obps-service/properties/getAllFacility';
export const ADMIN_MGMT_PROGRAMS = '/obps-service/properties/getAllProgram';
export const ADMIN_SYSTEM_PROPERTIES = '/account-service/property/getAllProperties';
export const ADMIN_WORKFLOW_CONFIG = '/account-service/standard/getAllProgramWorkflowConfigs';
export const ADMIN_UPDATE_SYSTEM_PROPERTIES = '/account-service/property/update';
export const ADMIN_UPDATE_PROGRAM_PROPERTIES = '/obps-service/properties/saveProgram';
export const ADMIN_WORKFLOW_STANDARDS = '/account-service/lookup/standards';
export const ADMIN_WORKFLOW_APPROVAL_TYPES = '/account-service/standard/getAllStandardApprovalTypes';
export const ADMIN_UPDATE_WORKFLOW_CONFIG = '/account-service/standard/saveProgramWorkflowConfig';
export const ADMIN_OFFSET_PROTOCOL = '/account-service/offsetProtocol/getAll';
export const ADMIN_OFFSET_PROTOCOL_VERSION = '/account-service/offsetProtocolVersion/getAll';
export const ADMIN_UPDATE_FACILITY_PROGRAM_PROPERTIES = '/obps-service/properties/saveFacilityProgramProperty';
export const ADMIN_OFFSET_PROTOCOL_CONFIG = '';

@Injectable({
  providedIn: 'root',
})

export class AdminManagementService extends AbstractPaginationService {

  constructor(private http: HttpClient) {
    super();
   }

  getFacilities(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<IProgramFacilityPropertiesData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    // tslint:disable-next-line: max-line-length
    return this.http.post<IProgramFacilityPropertiesData>(`${ environment.apiUrl }${ ADMIN_MGMT_FACILITIES }`, filterState, { params });
  }

  getPrograms(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<IProgramPropertiesData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    // tslint:disable-next-line: max-line-length
    return this.http.post<IProgramPropertiesData>(`${ environment.apiUrl }${ ADMIN_MGMT_PROGRAMS }`, filterState, { params });
  }

  getSystemProperties(pagination: IApp.IPagination, filterState: FilterModel = {}, url: string): Observable<ISystemPropertiesData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    // tslint:disable-next-line: max-line-length
    return this.http.post<ISystemPropertiesData>(`${environment.apiUrl}${url}`, filterState, { params });
  }

  getStandards(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<any[]> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const response1 = this.http.post<ISystemPropertiesData>(`${ environment.apiUrl }${ ADMIN_WORKFLOW_CONFIG }`, filterState, { params });
    const response2 = this.http.get(`${ environment.apiUrl }${ ADMIN_WORKFLOW_STANDARDS }`);
    const response3 = this.http.get(`${ environment.apiUrl }${ ADMIN_WORKFLOW_APPROVAL_TYPES }`);

    return forkJoin([response1, response2, response3]);
  }

  sendEmails(uri: string): Observable<string>{
    return this.http.post<string>(`${ environment.apiUrl }${ uri }`, {});
  }

  updateSystemProperty(updatedRow, {id, description, key, deleted}) {
    updatedRow = {...updatedRow, id, description, key, deleted};
    const params = updatedRow;
    return this.http.post<ISystemPropertiesData>(`${ environment.apiUrl }${ ADMIN_UPDATE_SYSTEM_PROPERTIES }`,  params);
  }

  updateProgramProperty(updatedRow, {id = false, submissionDateBenchMark = false, complianceYear = false} = {}) {
    if (id) {
      updatedRow = {...updatedRow, id, submissionDateBenchMark, complianceYear};
    }

    const params = updatedRow;
    return this.http.post<IProgramPropertiesData>(`${ environment.apiUrl }${ ADMIN_UPDATE_PROGRAM_PROPERTIES }`,  params);
  }

  updateFacilityProgramProperty(updatedRow, {id = false , submissionDateBenchMark = false, complianceYear = false} = {}) {
    if (id) {
      updatedRow = {...updatedRow, id, submissionDateBenchMark, complianceYear};
    }
    updatedRow = {...updatedRow, account: {id: updatedRow.account}};
    const params = updatedRow;
    return this.http.post<IProgramPropertiesData>(`${ environment.apiUrl }${ ADMIN_UPDATE_FACILITY_PROGRAM_PROPERTIES }`,  params);
  }

  updateWorkFlowProperty(updatedRow) {
    const params = updatedRow;
    return this.http.post<IProgramPropertiesData>(`${ environment.apiUrl }${ ADMIN_UPDATE_WORKFLOW_CONFIG }`,  params);
  }

  getOffsetProtocol(pagination: IApp.IPagination): Observable<IOffsetConfigData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.get<IOffsetConfigData>(`${ environment.apiUrl }${ ADMIN_OFFSET_PROTOCOL }`, {params});
  }

  getAllOffsetProtocol(pagination: IApp.IPagination): Observable<IOffsetConfigData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IOffsetConfigData>(`${ environment.apiUrl }${ ADMIN_OFFSET_PROTOCOL }?pageSearch&type=RU`, {offsetType: 'RU'}, { params });
   }

  getOffsetProtocolVersion(pagination: IApp.IPagination): Observable<IOffsetConfigData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.get<IOffsetConfigData>(`${ environment.apiUrl }${ ADMIN_OFFSET_PROTOCOL_VERSION }`, {params});
  }

  getProtocolManagement(pagination: IApp.IPagination): Observable<IOffsetConfigData> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.get<IProtocolManagementConfigData>(`${ environment.apiUrl }${ ADMIN_OFFSET_PROTOCOL_CONFIG }`, {params});
  }

}


 