<ng-container *ngIf="showTableDetails">
  <h2>{{ 'AGREEMENT_CREDITS.tableDetails.title' | translate }}</h2>
  <h2>{{ 'AGREEMENT_CREDITS.tableDetails.subTitle' | translate }}</h2>

  <p>{{ 'AGREEMENT_CREDITS.tableDetails.message' | translate }}</p>
</ng-container>

<div class="filters" *ngIf="showTableDetails">
    <app-table-filter
        [fields]="filterFields"
        (filterChanged)="onFilterChanged($event)"
        (filterInit)="onFilterChanged($event)"
        (customButtonAction)="onCustomButtonAction($event)">
    </app-table-filter>
</div>

<div class="table-area" id="agreement">
  <app-table-view *ngIf="dataList"
    [config]="tableConfig"
    [data]="dataList"
    (paginationChanged)="onPaginationChanged($event)"
   >
  </app-table-view>
</div>

<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewAgreement($event, data)"
          (keyup)="viewAgreement($event, data)">
      {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
    </span>
  </div>
</ng-template>

<ng-template #actionTemplate let-data>
  <app-generic-action-control
    [rawData]="data"
    [appendTo]="'app-agreement-credits-list'">
  </app-generic-action-control>
</ng-template>

<ng-container *ngIf="showTableDetails">
  <div class="float-right">
    <button class="btn btn-secondary mb-5 float-right" type="button" (click)="close()" style="width: 80px;">
      {{ 'COMMON.actionsLabel.CLOSE' | translate }}
    </button>
  </div>
</ng-container>
