import { IApp } from '@core/models/app.interfaces';
import { CarbonIntensityTableFilter } from '@core/models/filter-types.model';

export const ERP_SUBMISSION_LIST_TRANSLATION_PREFIX = 'EMISSIONS_REDUCTION_PROJECT_MODULE.emissionsReductionProjectList.';

import {
  commonActionsLabelTranslationPrefix,
} from '@core/models/filter-types.model';
import { ActionOptionType, ITableFilterActionModel } from '@shared/models/table-filter.models';

export const ERP_CANCEL_PREFIX = 'erpCancel';

export const ERP_SUBMISSION_LIST_TABLE_FILTER: ITableFilterActionModel = {
  searchBox: true,
  filterListsModel: [
    {
      placeholder: ERP_SUBMISSION_LIST_TRANSLATION_PREFIX + 'action',
      itemsType: 'action',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'actionPerformedByList',
    },
    {
      placeholder: ERP_SUBMISSION_LIST_TRANSLATION_PREFIX + 'type',
      itemsType: 'type',
      multiple: true,
      selectableGroup: true,
      closeOnSelect: false,
      ngModel: 'transitionTypeList',
    },
  ],
  searchButtons: false,
  actionModel: [
    {
      placeholder: ERP_SUBMISSION_LIST_TRANSLATION_PREFIX + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'EMISSIONS_REDUCTION_PROJECT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'EMISSIONS_REDUCTION_PROJECT',
        },
      ],
    },
  ],
};

export const ERP_SUBMISSION_LIST_TABLE_DETAILS = {
  tableListApiUri: '/cfr-service/application/erpPageSearch',
  tableHeaderType: 'EMISSIONS_REDUCTION_PROJECT',
  tableFilter: ERP_SUBMISSION_LIST_TABLE_FILTER,
  filtersFromConfig: true,
  hasViewTemple: false,
  hasActionTemple: true,
};

export const REQUEST_ERP_CANCELLATION_ADMIN = {
  action: 'CANCEL',
  uiUri: '/emissions-reduction/cancel-erp',
  uri: '/reviewCancellationCarbonIntensity',
  type: 'ERP_CANCELLATION',
  id: null,
  status: null,
  prefix: ERP_CANCEL_PREFIX,
};

export const REQUEST_CANCELLATION = {
  action: 'SUBMIT',
  uiUri: '/emissions-reduction/cancel-erp',
  type: 'ERP_CANCELLATION',
  uri: '/cancelCarbonIntensity',
  id: null,
  status: null,
  prefix: ERP_CANCEL_PREFIX,
  actionLabel: 'requestCancellationFor',
};

export const ERP_CANCELLATION = {
  action: 'CANCEL',
  uiUri: '/emissions-reduction/cancel-erp',
  uri: '/reviewCancellationCarbonIntensity',
  type: 'ERP_CANCELLATION',
  id: 'cancellationId',
  status: 'cancellationStatus',
  prefix: ERP_CANCEL_PREFIX,
};

export const DOWNLOAD_APPLICATION_PDF = {
  id: 'id',
  type: 'DOWNLOAD',
  actionLabel: 'DOWNLOAD_APPLICATION_PDF',
  endpoint: '/cfr-service/application/download/report',
};

export const ERP_ACTIONS: IApp.ITableAction = {
  VIEW_ERP_APPLICATION: {
    uiUri: '/submission-management/wizard/application/${entityId}',
  },
  VIEW_ERP_PROJECT_INFO: {
    id: 'id',
    customAction: true,
  },
  REQUEST_ERP_CANCELLATION_ADMIN,
  REQUEST_CANCELLATION,
  VIEW_CANCELLATION: ERP_CANCELLATION,
  APPROVE_CANCELLATION: ERP_CANCELLATION,
  REVIEW_REQUEST_CANCELLATION: ERP_CANCELLATION,
  REJECT_REQUEST_CANCELLATION: ERP_CANCELLATION,
  DOWNLOAD_APPLICATION_PDF,
};
