import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-simple-border-wrapper',
  templateUrl: './simple-border-wrapper.component.html',
  styleUrls: ['./simple-border-wrapper.component.scss']
})
export class SimpleBorderWrapperComponent extends FieldWrapper {

  constructor() {
    super()
   }

}
