
<div id="replacing-mao" class="d-md-flex justify-content-center pt-5 align-content-stretch">

    <div class="col-sm-12 col-md-9 col-xl-6 mb-5 d-inline-block">

        <h1>{{'ACCOUNTS_MODULE.accountSummary.reqForReplacingMAOTitle' | translate }}</h1>

        <div class="d-md-flex justify-content-center mt-2">
            <div class="card mb-2 mx-auto w-100 card-override">
                <div class="card-body">
                    <p>{{'ACCOUNTS_MODULE.accountSummary.reqForReplacingMAOMessage' | translate }}</p>
                    
                    <formly-form [form]="formGroup" [model]="model" [fields]="fields"></formly-form>
                </div>

                <div class="card-footer">
                    <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{ 'ACCOUNTS_MODULE.accountSummary.close' | translate }}</button>
                    <button type="button" class="btn btn-secondary mr-2" *ngIf="submission" (click)="submit()">{{ 'ACCOUNTS_MODULE.accountSummary.submitRequestToAdmin' | translate }}</button>
                    <button type="button" class="btn btn-secondary mr-2" *ngIf="editable"  (click)="replace()">{{ 'COMMON.actionsLabel.APPROVE' | translate }}</button>
                    <button type="button" class="btn btn-secondary mr-2" *ngIf="editable"  (click)="reject()">{{ 'COMMON.actionsLabel.REJECT' | translate }}</button>
                </div>

            </div>
        </div>

    </div>

</div>

