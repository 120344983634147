import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./agreement-credits.service";
export class AgreementGuard {
    constructor(service) {
        this.service = service;
    }
    canActivate(next, state) {
        return this.switchToProperPath();
    }
    switchToProperPath() {
        const { currentData, inputData } = this.service.restoreCurrentData();
        if (currentData && inputData) {
            this.service.redirectToAgreementScreen(inputData, currentData.id, currentData);
            return of(true);
        }
        else {
            return of(false);
        }
    }
}
AgreementGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AgreementGuard_Factory() { return new AgreementGuard(i0.ɵɵinject(i1.AgreementCreditsService)); }, token: AgreementGuard, providedIn: "root" });
