import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from '../../../../shared/components/base-page/base-page.component';
import { EnhancedUserDashboardComponent } from '@module/account-management/components/enhanced-user-dashboard/enhanced-user-dashboard.component';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent extends BasePageComponent implements OnInit{

  constructor() {
    super();
  }

  ngOnInit() {
  }


}
