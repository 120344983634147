import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { IRegistrationStep } from '@module/registration/registration.model';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { takeUntil } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

interface ISupStepUpdate {
  id?: number;
  subId?: number;
}

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit, OnChanges, OnDestroy {

  @Input('steps') steps: IRegistrationStep[];
  @Input('current') current = 1;
  @Input('currentSubStep') currentSubStep = null;
  @Input('maxStep') maxStep: number = null;

  // use one of the following outputs depending on usecase - backward compatibility
  @Output() changeStep = new EventEmitter<number>();
  @Output() changeSubStep = new EventEmitter<ISupStepUpdate>();

  private _destroy$ = new Subject<any>();

  stepperSteps: IRegistrationStep[];

  constructor(
    private documentUploadInProgress: DocumentUploadInProgressService,
    private translate: TranslateService,
  ) {
    this.translate.onLangChange
    .pipe(
      takeUntil(this._destroy$),
    )
    .subscribe((event: LangChangeEvent) => {
      this.updateSteps(this.current);
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.steps) {
      this.updateSteps(this.current, this.currentSubStep);
    }
    if (changes.current) {
      this.updateSteps(this.current);
    }
    if (changes.currentSubStep) {
      this.updateSteps(this.current, this.currentSubStep);
    }
  }

  updateSteps(currentStep, currentSubStep = null) {
    const _steps = JSON.parse(JSON.stringify(this.steps));
    _steps.forEach( step => {
      if (step.id >= currentStep ) {
        step.completed = false;
      } else {
        step.completed = true;
        if (step.subSteps && step.subSteps.length > 0) {
          step.subSteps.map(ss => ss.completed = true);
        }
      }

      if (step.id === currentStep && currentSubStep) {
        step.subSteps.map(ss => {
          ss.completed = ss.subId < currentSubStep;
          return ss;
        });
      }
    });
    this.stepperSteps = [..._steps];
  }

  goToStep(event: any, stepNumber: number, subStepNumber: number = null): void {
    event.preventDefault();
    if (!this.enabledNavigation) {
      return;
    }

    const step = this.stepperSteps.find(step => step.id === stepNumber); // Activate first sub step in case main step does not have any view associated.
    if (!subStepNumber && step['skipMainStep'] && step['internalSteps']) {
      subStepNumber = 1;
    }

    if (!this.documentUploadInProgress.isInProgress(true)) {
      if (stepNumber <= this.current && subStepNumber && subStepNumber <= this.currentSubStep) {
        if (stepNumber === this.current && !subStepNumber && this.currentSubStep === null) {
          return;
        }
        this.current = stepNumber;
        if (subStepNumber) {
          this.currentSubStep = subStepNumber;
        }
        this.changeSubStep.emit({id: stepNumber, subId: subStepNumber});
        this.changeStep.emit(stepNumber);
        this.updateSteps(this.current);
      } else if (this.maxStep && subStepNumber ? parseFloat(`${stepNumber}.${subStepNumber}`) <= this.maxStep : stepNumber <= this.maxStep) {
        this.changeSubStep.emit({id: stepNumber, subId: subStepNumber});
      }
    }
  }

  get enabledNavigation() {
    const step = this.stepperSteps.find(s => s.id === this.current);
    return !(step && step.preventNavigation);
  }

  public getSubStep(subStep: any): number {
    return parseFloat(`${ subStep.id }.${ subStep.subId }`);
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
