import { IApp } from '@core/models/app.interfaces';

export const ADDRESS = 'address';
export const EMAIL = 'email';
export const UPLOAD_DOCUMENTS = 'documents';
export const TITLE = 'title';
export const PROFESSIONAL_TITLE = 'professionalTitle';
export const FULL_NAME = 'fullName';
export const BUSINESS_TELEPHONE = 'businessTelephone';
export const LINKED_ACCOUNT_TEXT = 'linkedAccountsText';
export const USER_ROLE = 'userRole';

export const DISPLAY_AO_PROFILE_PUBLIC = 'displayAoProfilePublicView';

export const FIELD_CONFIGURATIONS: IApp.IUserFieldMap = {
  OBPS_NEW: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: true, label: TITLE},
    fullName: {required: true},
    email: {required: true},
    businessTelephone: {required: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {hidden: true},
    uploadDocuments: {},
  },
  OBPS_EXISTING: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: true, label: TITLE},
    fullName: {required: true},
    email: {required: true, disabled: false},
    businessTelephone: {required: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {hidden: true},
    uploadDocuments: {},
  },
  API: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: true, disabled: true, label: TITLE},
    fullName: {required: true, disabled: true},
    email: {required: true, disabled: true},
    businessTelephone: {required: true, disabled: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {hidden: true},
    uploadDocuments: {},
  },
  CFR_NEW: {
    linkedAccountsText: {label: USER_ROLE},
    title: { required: true, disabled: false, label: PROFESSIONAL_TITLE },
    fullName: { required: true},
    email: {required: true},
    businessTelephone: { disabled: true},
    professionalQualification: {hidden: true},
    address: {hidden: true},
    uploadDocuments: { disabled: true},
  },
  CFR_VB_NEW: {
    linkedAccountsText: {label: USER_ROLE},
    title: { required: true, disabled: false, label: PROFESSIONAL_TITLE},
    fullName: { required: true},
    email: {required: true},
    businessTelephone: { disabled: true},
    professionalQualification: {required: true},
    address: {hidden: true},
    uploadDocuments: { disabled: true},
  },
  CFR_VB_EXISTING: {
    linkedAccountsText: {label: USER_ROLE},
    title: {  disabled: true, label: PROFESSIONAL_TITLE},
    fullName: {required: true},
    email: {disabled: false},
    businessTelephone: { required: true},
    faxNumber: {},
    professionalQualification: {required: true},
    address: {
      required: true,
      hidePoBoxCivic : true,
    },
    uploadDocuments: { },
  },
  CFR_EXISTING: {
    linkedAccountsText: {label: USER_ROLE},
    title: {label: PROFESSIONAL_TITLE},
    fullName: {required: true},
    email: {disabled: false},
    businessTelephone: {required: true},
    phoneNumber: {},
    deliveryMedium: {},
    faxNumber: {},
    professionalQualification: {hidden: true},
    address: {required: true,
      hidePoBoxCivic : true,
    },
    uploadDocuments: { },
  },
  OFFSET_NEW: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: false, label: TITLE},
    fullName: {required: true},
    email: {required: true},
    businessTelephone: {required: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {
      hidden: true,
    },
    uploadDocuments: {},
  },
  OFFSET_EXISTING: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: false, label: TITLE},
    fullName: {required: true},
    email: {required: true, disabled: false},
    businessTelephone: {required: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {
      required: true,
      provinceStateLabel: 'REGISTRATION_PAGE.cfsRegistrationForm.provinceTerritotyState',
      postalCodeLabel: 'REGISTRATION_PAGE.cfsRegistrationForm.postalZipForeignCode',
      hidePoBoxCivic : false,
      civicAddressLabel: 'REGISTRATION_PAGE.offsetRegistrationForm.APRmailingAddress',
      postalAddressLabel: 'REGISTRATION_PAGE.offsetRegistrationForm.APRphysicalAddress',
      sameAsPostalLabel: 'REGISTRATION_PAGE.offsetRegistrationForm.sameAsPhysicalAddress',
    },
    uploadDocuments: {},
  },
  CM_NEW: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: true, label: TITLE},
    fullName: {required: true},
    email: {required: true},
    businessTelephone: {required: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {hidden: true},
    uploadDocuments: {},
  },
  CM_EXISTING: {
    linkedAccountsText: {label: LINKED_ACCOUNT_TEXT},
    title: {required: true, label: TITLE},
    fullName: {required: true},
    email: {required: true, disabled: false},
    businessTelephone: {required: true},
    faxNumber: {hidden: true},
    professionalQualification: {hidden: true},
    address: {hidden: true},
    uploadDocuments: {},
  },
};
