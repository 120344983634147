/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i2 from "@fortawesome/angular-fontawesome";
import * as i3 from "@angular/platform-browser";
import * as i4 from "primeng/tooltip";
import * as i5 from "../../pipes/custom-translate.pipe";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./custom-template.type";
var styles_CustomFormlyTemplateType = [];
var RenderType_CustomFormlyTemplateType = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomFormlyTemplateType, data: {} });
export { RenderType_CustomFormlyTemplateType as RenderType_CustomFormlyTemplateType };
function View_CustomFormlyTemplateType_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "fa-icon", [["class", "ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i1.View_FaIconComponent_0, i1.RenderType_FaIconComponent)), i0.ɵdid(1, 573440, null, 0, i2.FaIconComponent, [i3.DomSanitizer, i2.FaConfig, i2.FaIconLibrary, [2, i2.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i0.ɵpad(2, 2), i0.ɵdid(3, 4341760, null, 0, i4.Tooltip, [i0.ElementRef, i0.NgZone], { tooltipPosition: [0, "tooltipPosition"], text: [1, "text"] }, null), i0.ɵpid(131072, i5.CustomTranslatePipe, [i6.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.to.icon == null) ? null : _co.to.icon.iconProp) ? _co.to.icon.iconProp : _ck(_v, 2, 0, "fas", "info-circle")); _ck(_v, 1, 0, currVal_2); var currVal_3 = i0.ɵinlineInterpolate(1, "", (((_co.to.icon == null) ? null : _co.to.icon.tooltipPosition) ? ((_co.to.icon == null) ? null : _co.to.icon.tooltipPosition) : "top"), ""); var currVal_4 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform(((_co.to.icon == null) ? null : _co.to.icon.text))), ""); _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).title; var currVal_1 = i0.ɵnov(_v, 1).renderedIconHTML; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CustomFormlyTemplateType_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "d-inline"]], [[8, "innerHTML", 1], [1, "id", 0]], null, null, null, null)), i0.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\u00A0 "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomFormlyTemplateType_1)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "d-inline"; var currVal_3 = _co.to.class; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.to.icon; _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; var currVal_1 = _co.to.id; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_CustomFormlyTemplateType_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-parsed-template", [], null, null, null, View_CustomFormlyTemplateType_0, RenderType_CustomFormlyTemplateType)), i0.ɵdid(1, 49152, null, 0, i8.CustomFormlyTemplateType, [i6.TranslateService, i3.DomSanitizer], null, null)], null, null); }
var CustomFormlyTemplateTypeNgFactory = i0.ɵccf("formly-parsed-template", i8.CustomFormlyTemplateType, View_CustomFormlyTemplateType_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { CustomFormlyTemplateTypeNgFactory as CustomFormlyTemplateTypeNgFactory };
