import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel, SubmissionFilterModel } from '@core/models/filter.model';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { ProjectReportService } from '@module/project-report/service/project-report.service';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { ProjectService } from '@shared/services/project.service';
import { Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { map, switchMap, tap } from 'rxjs/operators';
import { CREATE_REPORT_NOTIFICATION_MODAL_REPORT } from '../components/create-report-notification-modal/create-report-notification-modal.constants';
import {
  APP_TYPE,
  APPLICATION_CURRENT_STEP_KEY,
  APPLICATION_DATA_KEY,
} from '../const/submission-management.const';
import { ITeamLeadIndependentReviewer } from '../models/team-lead-independent-reviewewr';
import { ITeamMember } from '../models/team-members';
import { IVerificationReview } from '../models/verification-review';
import { ONLY_REGISTRY_ADMIN } from '../../../app.const';
import { FormService } from '@shared/services/form.service';
import { IDisplayMessages } from '@core/models/serviceMessage.model';

export const SUBMISSION_MANAGEMENT = '/account-service/submission/pageSearchByFilter';
export const CI_APPLICATION_SEARCH = '/account-service/application/search';
export const CARBON_INETNSITY_SEARCH = '/cfr-service/application/pageSearch';
export const ERP_SEARCH = '/cfr-service/application/erpPageSearch';
export const CI_APPLICATION_GET = '/account-service/application/get/';
export const SUBMISSION_BY_ID = '/account-service/submission/';

export const CI_APPLICATION_SAVE_PRODUCTION_LOW_FUELS = '/account-service/application/save';
export const CI_APPLICATION_SAVE_AND_PROCEED = ' account-service.url/application/saveAndProceed';
export const PERMISSION_PROPOSE_TRANSACTION = 'PROPOSE_TRANSACTION';
// common methods for all the applications
export const ACCOUNT_SERVICE_URL = '/account-service/';
export const COMMON_SAVE_ACTION = '/save';
export const COMMON_SUBMIT_ACTION = '/submit';
export const COMMON_PROPOSE_ACTION = '/propose';
export const COMMON_REVIEW_ACTION = '/review';
export const COMMON_GET_ACTION = '/get';
export const APPLICATION = 'application';
export const GET_REPLACE_REQUEST = 'cfrAccount/maoReplaceRequest/find/';
export const VERIFICATION_BODY = '/account-service/cfrAccount/verificationBody/pageSearch?';
export const REGISTRATION_REPORT = '/account-service/cfrAccount/cfrDetail';
export const VERIFICATION_REVIEW = '/cfr-service/verification/review';
export const VERIFICATION_UPDATE_REVIEW = '/cfr-service/verification/updateReview';
export const TEAM_LEAD_AND_INDEPENDENT_REVIEWER = '/cfr-service/verification/assignTeamLeadAndIndependentReviewer';
export const CHANGE_TEAM_LEAD_AND_INDEPENDENT_REVIEWER = '/cfr-service/verification/changeTeamLeadAndIndependentReviewer';
export const ASSIGN_TEAM_MEMBER = '/cfr-service/verification/assignTeamMembers';
export const EDIT_TEAM_MEMBER = '/cfr-service/verification/editTeamMembers';
export const GET_ACCESS_PROVIDED_ENTITIES_BY_VERIFICATION_ID = '/cfr-service/verification/get/'
export const GET_ENTITIES_BY_VERIFICATION_ID = '/cfr-service/verification/pageVbEntity?sort=entityId&dir=ASC';
export const PROCESS_ENTITY_ACCESS = '/cfr-service/verification/vbProcessEntityAccess';
export const DOWNLOAD_APPLICATION_PDF = '/cfr-service/application/download/report';
export const GET_CI_REQUEST_CHANGE_DATA_API = '/account-service/lookup/getCarbonIntensityStatusChangeRequest/';

export const LOOKUP_APPLICATION_FACILITIES = 'account-service/lookup/getFacilityListForApplication';

const STEPS_TRANSLATION_PREFIX = 'SUBMISSION_MANAGEMENT_MODULE.wizard.';

@Injectable({
  providedIn: 'root',
})
export class SubmissionManagementService extends BaseStepperService {

  public registrationReportsDetail;
  public registrationReportData;
  private _stepModelCache: any = {};
  private _applicationApprovalModel = new BehaviorSubject({});
  public applicationApprovalModel$ = this._applicationApprovalModel.asObservable();
  public inputData: any;
  public translationPrefix = STEPS_TRANSLATION_PREFIX;
  public selectedIndependentReviewer: ITeamLeadIndependentReviewer = {};
  public selectedTeamLead: any = {};
  public selectedVB: any = {};
  public verificationDisabled: boolean;
  public parentRouteOnTeamLeadConfirmation: string;

  public stepperStepUpdate$ = new Subject();

  public hideSaveAsDraft = false;

  private _refreshData$ = new Subject<any>();
  public refreshData$ = this._refreshData$.asObservable();

  constructor(  private http: HttpClient,
                private storeService: StoreService,
                private router: Router,
                private helperService: HelperService,
                private modalService: ModalService,
                private helperSvc: HelperService,
                private projectReportSvc: ProjectReportService,
                private projectService: ProjectService,
                private formService: FormService
                ) {
      super();
  }

  get applicationApprovalModel(): any {
    return this.currentData;
  }

  set applicationApprovalModel(data) {
    this.currentData = data;
  }

  resetModel() {
    this._applicationApprovalModel.next({});
  }

  // get scrollEl() {
  //   return this.scrollContainerDir;
  // }
  //
  // set scrollEl(el) {
  //   this.scrollContainerDir = el;
  // }

  saveAsDraft(): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${ CI_APPLICATION_SAVE_PRODUCTION_LOW_FUELS }`, this.applicationApprovalModel.data);
  }

  saveAndProceed(): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${ CI_APPLICATION_SAVE_AND_PROCEED }`, this.applicationApprovalModel);
  }

  getReportList(pagination: IApp.IPagination, filterState: SubmissionFilterModel = {}):
   Observable<IApp.ISubmissionManagementPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.ISubmissionManagementPage>(`${ environment.apiUrl }${ SUBMISSION_MANAGEMENT }`, filterState, { params });
  }

  getCarbonIntensityById(id: number) {
    let params = new HttpParams();
    params = this.addPagination(params, {});
    return this.http.post<IApp.ISubmissionManagementPage>(`${ environment.apiUrl }${ CARBON_INETNSITY_SEARCH }`, {id}, { params });
  }

  getApplicationById(id: number, type = APP_TYPE.CI) {
    let params = new HttpParams();
    params = this.addPagination(params, {});
    const uri = type === APP_TYPE.CI ? CARBON_INETNSITY_SEARCH : ERP_SEARCH;
    return this.http.post<IApp.ISubmissionManagementPage>(`${ environment.apiUrl }${ uri }`, {id}, { params });
  }

  getCarbonIntensity(payload = {}) {
    let params = new HttpParams();
    params = this.addPagination(params, {});
    return this.http.post<any>(`${ environment.apiUrl }${ CARBON_INETNSITY_SEARCH }`, payload, { params });
  }

  getCIApplication(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${ CI_APPLICATION_SEARCH }`, payload)
    .pipe(
      tap(response => this.registrationReportData = response),
      tap(response => this.registrationReportsDetail = response ? response.account : null),
    );
  }

  getApplication(id: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ CI_APPLICATION_GET }${ id }`);
  }

  getApplicationBySubmissionReportId(id: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ SUBMISSION_BY_ID }APPLICATION${ id }`);
  }

  getRegistrationReportDetail(id = null, accountId) {
    const payload = {
      id,
      accountId,
      discriminatorType: 'REGISTRATION_ACCOUNT',
    };
    return this.http.post(`${ environment.apiUrl}${REGISTRATION_REPORT}`, payload).
    pipe(tap(response => this.registrationReportsDetail = response));
  }

  getApplicationByType(type: string, id: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ ACCOUNT_SERVICE_URL}${APPLICATION}${ COMMON_GET_ACTION }/${ id }`);
  }

  saveApplication(draft: boolean, payload: any, quiet = false): Observable<any> {
    const type = draft ? '' : 'AndProceed';
    payload.fullJson = null;
    if (parseFloat(this.stepNum) !== parseFloat(payload.step) && parseFloat(payload.step) > this.lastSavedStep) {
      payload.step = this.lastSavedStep.toString();
    }
    if (!this.isDirty && !draft) {
      return of({entity: this.currentData})
        .pipe(tap(() => this.adminCanUpdate.next(false)));
    } else {
      return this.http.post<any>(`${environment.apiUrl}${ACCOUNT_SERVICE_URL}${APPLICATION}${COMMON_SAVE_ACTION}${type}${quiet ? '?quiet=1' : ''}`, payload)
        .pipe(
          tap(() => this.adminCanUpdate.next(false)),
          tap(data => data.entity && data.entity.step && (this.lastSavedStep = data.entity.step)),
        );
    }
  }

  proposeApplication(payload: any): Observable<any> {
    payload.fullJson = null;
    return this.http.post<any>(`${ environment.apiUrl}${ ACCOUNT_SERVICE_URL }${APPLICATION}${ COMMON_PROPOSE_ACTION }`, payload);
  }

  submitApplication(type: any, payload: any, form?) {
    payload.fullJson = null;
    const isTransactionProposer = this.storeService.user.checkIfTransactionProposer(payload.accountId);
    const message = isTransactionProposer ?    'proposeApplicationConfirmation' : 'confirmationMessageApplication';
    let userType = payload.type === 'CI_PATHWAY' ? 'ecccTeam' : 'validationBody';
    let appType = payload.type;
    if (payload.ciMethod === 'DEFAULT') {
      userType = 'verificationBody';
    }
    if (payload.type === 'CI_PRODUCTION_OF_LOW_CI_FUELS'
    || payload.type === 'CI_FUELING_STATIONS'
    || payload.type === 'CI_CHARGING_STATIONS') {
      appType = 'CI_APPLICATION';
    }
    this.modalService
      .open(
        ServiceMessageComponent,
          {
            messages: null,
            message,
            metaDataList: [this.translateService.instant(`COMMON.actionsLabel.submit`),
            this.translateService.instant(`COMMON.submissionTypeList.${appType}`),
            payload.legalName,
            this.translateService.instant(`COMMON.actionsLabel.${userType}`),
          ],
          type,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          this.submit( payload, isTransactionProposer)
           .subscribe(resp => {
            this.returnPreviousLocation();
          }, (error: HttpErrorResponse) => {
            if (form) {
              this.formService.parseErrors(form, error.error);
            }
          });
      }
  });
  }

  returnPreviousLocation() {
    const prevPage = this.storeService.lastVisitedUrl || this.storeService.getHistoryUrl();
    if (prevPage) {
      this.router.navigate(this.helperService.getTranslatedPath(prevPage));
    } else {
      this.storeService.back();
    }
  }

  submit( payload: any, isTransactionProposer: boolean): Observable<any> {
    if (isTransactionProposer) {
      return this.proposeApplication(payload);
    }
    return this.http.post<any>(`${ environment.apiUrl}${ ACCOUNT_SERVICE_URL }${APPLICATION}${ COMMON_SUBMIT_ACTION }`, payload);
  }

  doAction(uri: string, payload: any): Observable<IApp.IApplication> {
    payload.fullJson = null;
    return this.http.post<IApp.IApplication>(`${ environment.apiUrl }${ ACCOUNT_SERVICE_URL }${APPLICATION}${ uri }` , payload);
  }

  getReplaceRequest( id: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ ACCOUNT_SERVICE_URL}${ GET_REPLACE_REQUEST}${ id }`);
  }

  getCiRequestChangeData( id: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_CI_REQUEST_CHANGE_DATA_API}${ id }`);
  }

  getVerificationBodyList(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<IApp.ITableList> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.ITableList>(`${environment.apiUrl}${VERIFICATION_BODY}`, filterState, { params });
  }

  saveVerificationReview(payload: IVerificationReview) {
    return this.http.post<any>(`${environment.apiUrl}${VERIFICATION_REVIEW}`, payload);
  }

  saveTeamLeadAndIndependentReviewer(payload: ITeamLeadIndependentReviewer) {
    if (this.inputData.showReasonForm) {
      return this.http.post<any>(`${environment.apiUrl}${CHANGE_TEAM_LEAD_AND_INDEPENDENT_REVIEWER}`, payload);
    }
    return this.http.post<any>(`${environment.apiUrl}${TEAM_LEAD_AND_INDEPENDENT_REVIEWER}`, payload);
  }
  saveTeamMembers(payload: ITeamMember, status) {
    if (status === 'PENDING_VERIFICATION_TEAM_SELECTION') {
      return this.http.post<any>(`${environment.apiUrl}${ASSIGN_TEAM_MEMBER}`, payload);
    } else {
      return this.http.post<any>(`${environment.apiUrl}${EDIT_TEAM_MEMBER}`, payload);
    }
  }

  storeCurrentData() {
    const data = JSON.stringify({...this.inputData, ...this.currentData});
    sessionStorage.setItem(APPLICATION_DATA_KEY, data);
    const currentStep = JSON.stringify(this.currentStep);
    sessionStorage.setItem(APPLICATION_CURRENT_STEP_KEY, currentStep);
  }

  restoreCurrentData(skipRemove = false): Observable<any> {
    const data = sessionStorage.getItem(APPLICATION_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(APPLICATION_DATA_KEY);
      let restoredData = JSON.parse(data);
      return this.getCIApplication({
        accountId: restoredData.accountId,
        type: restoredData.type,
      }).pipe(
        tap(appData => {
          restoredData = {
            ...restoredData,
            fuelDtoList: appData.fuelDtoList,
          };
        }),
        switchMap(() => of(restoredData)),
      );
    } else {
      return of(null);
    }
  }

  restoreLastStep(): any {
      const currentStep = sessionStorage.getItem(APPLICATION_CURRENT_STEP_KEY);
      if (currentStep) {
        sessionStorage.removeItem(APPLICATION_CURRENT_STEP_KEY);
        return JSON.parse(currentStep);
      } else {
        return null;
      }
  }

  getSubmissionsList$(pagination: IApp.IPagination, filterState: FilterModel = {}, uri: string) {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<any>(`${environment.apiUrl}${uri}`, filterState, { params });
  }

  triggerBackendAction(payload, back, mapData = null) {
    const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;

    switch(actionLabel) {
      case 'DISCARD':
        this._requestConfirmation(payload, mapData, 'discard');
        break;
      case 'VIEW_HISTORY':
        this.router.navigate(this.helperService.getTranslatedPath(`/submission-management/submissions-history/${payload.id}`));
        break;
      case 'VIEW_PROJECT_REPORT':
        this.router.navigate(this.helperService.getTranslatedPath(
          `/project-report/PROJECT_REPORT/${payload.accountId}/${payload.projectId}/${payload.notErrOmiProjectReportId}`));
        break;
      case 'APPROVE':
      case 'REJECT':
      case 'APPROVE_SECOND':
      case 'REJECT_SECOND':
      case 'SUBMIT':
      case 'EDIT_CUST':
      case 'NEED_REVIEW':
      case 'NEED_MORE_INFO':
      case 'RESUBMIT':
        this._redirectToReport(payload);
        break;
      case 'VIEW_PROJECT':
        this.redirectToProjects(payload);
        break;
      default:
        console.log('Unhandled action', actionLabel);
        this._redirectToReport(payload);
        break;
    }
  }

  private _requestConfirmation(payload, mapData, uri = 'review') {
    const confirmationMessage = mapData && mapData.confirmationMessage ? mapData.confirmationMessage : 'confirmationMessageProject';
    const actionLabel = mapData && mapData.actionLabel ? mapData.actionLabel : payload.action;
    const actionPayload: any = {
      id: payload.id,
      action: actionLabel,
      reportTypeCode : payload.reportType,
      type: payload.submissionType.toUpperCase()
    };

    this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: confirmationMessage,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
          payload.accountName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.modalService.closeAllMessageDialogs();
        this.projectReportSvc.reportWorkflowAction(actionPayload, uri).subscribe(() => {
          this._refreshData$.next(true);
        })
      }
    });
  }

  private _redirectToReport(payload) {
    const {accountId, projectId, id, submissionType} = payload;
    const reportType = this.updatedReportTypeForGHG(payload.status, payload.reportType);
    const path = submissionType.toUpperCase() === CREATE_REPORT_NOTIFICATION_MODAL_REPORT ? 'project-report' : 'project-notification';
    const route = ['', path, reportType, accountId, projectId, id].join('/');
      this.router.navigate(this.helperSvc.getTranslatedPath(route));
      return;
  }

  updatedReportTypeForGHG(status, reprotType){
    if(reprotType && reprotType === 'NOT_GHG_REVERSAL' && ['SUSPECTED_UNDER_REVIEW_2','SUSPECTED_APPROVED','SUSPECTED_UNDER_REVIEW'].includes(status)){
      return 'NOT_SUSPECTED_GHG_REVERSAL';          
    }
    return reprotType
  }

  get isAdmin(): boolean {
    return this.storeService.user.hasPermission(ONLY_REGISTRY_ADMIN);
  }

  private redirectToProjects(payload) {
    if (payload.projectType === 'SUB') {
      this.projectService.redirectToSubProject(payload.masterProjectId, payload.projectId);
    } else {
      this.projectService.redirectToProjectScreen(null, payload.projectId, payload);
    }
    return;
  }

  getDownloadApplicationUrl(id: number): string {
    return `${ environment.serviceUrl }${ DOWNLOAD_APPLICATION_PDF}/${ id }`;
  }

  getApplicationFacilities(accountId) {
    return this.http.get<any>(`${ environment.apiUrl }/${ LOOKUP_APPLICATION_FACILITIES }/${ accountId }`);
  }

  getAccessProvidedEntitiesByVerificationId(pagination: IApp.IPagination, id: number): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.get<any>(`${ environment.apiUrl }${ GET_ACCESS_PROVIDED_ENTITIES_BY_VERIFICATION_ID }${ id }`, { params })
      .pipe(map(response => {
        response.content = response.vbAccessProvidedEntities;
        return response;
      }));
  }

  getEntitiesByVerificationId(pagination: IApp.IPagination, id: string): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<any>(`${ environment.apiUrl }${ GET_ENTITIES_BY_VERIFICATION_ID }`, { verificationId: id }, { params });
  }

  processEntityAccess(payload: {id: number, action: string}): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${ PROCESS_ENTITY_ACCESS }`, payload);
  }

  showError(message) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      type: SereviceMessageType.ERROR,
    };
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe();
  }
}
