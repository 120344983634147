import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel, ObligationMgmtFilterModel, SubOrganizationFilterModel } from '@core/models/filter.model';
import { ActivityLogFilterModel } from '@core/models/filter.model';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export const ACCOUNT_MGMT_GET_ACCOUNT = '/account-service/account';
export const ACCOUNT_DETAILS = '/account-service/account/detail';

// Account Actions
export const ACTIVITY_LOG_SEARCH = '/registry-server/activityLog/latestTransactions';
export const ACCOUNT_SEARCH_USERS = '/account-service/searchUser';
// OBPS Service
export const OBPS_CREDIT_SUMMARY = '/obps-service/unit/creditSummaryByAccountId';
export const OBPS_PROGRAM_DEADLINE = '/obps-service/properties/getAccountDeadlines';
export const OBPS_OBLIGATION_MGMT = '/registry-server/obligation/search';
export const OBPS_OBLIGATION_SUMMARY = '/registry-server/obligation/consolidatedAccountObligations';

export const UPDATE_OBPS_NFMP = '/account-service/account/updateObpsNonFacilityAccount';
export const UPDATE_CM = '/account-service/ompAccount/save';
export const GET_AUTHORIZED_OFFICIAL = '/account-service/private/user/findAuthorizedOfficialByAccountId';
export const GET_ALL_ROLES = '/account-service/private/user/roleList';
export const OBLIGATION_MANAGEMENT = '/registry-server/obligation/search';
export const UPCOMING_DEADLINES = '/registry-server/obligation/upcomingDeadlines';
export const LOOKUP_ALL_USER_ROLES_LIST = '/account-service/private/user/roleList';
export const LOOKUP_ALL_CREATE_USER_ROLES = '/account-service/private/user/roles';

export const WIRETRANSFER_DETAILS = '/obps-service/transaction/getWireTransferDetails';
export const DIRECT_DEPOSIT_DETAILS = '/obps-service/transaction/getDirectDepositDetails';

export const REGISTRATION_REPORT_DETAILS = '/account-service/cfrAccount/registration/pageSearch';
export const DOCUMENT_UPLOAD_REGISTRATION_VB = '/account-service/registrationDocument';

export const SUBMIT_ASSOCIATION = '/account-service/private/user/associationSubmit';

export const ACTIVITY_DETAIL_LIST = '/account-service/cfrAccount/activityDetails/pageSearch';

export const CHECK_APPROVED_CI = '/cfr-service/application/checkUserHasApprovedCisByAccountId/'
@Injectable({
  providedIn: 'root',
})
export class AccountManagementService extends AbstractPaginationService {

  private currentAccountId$$ = new BehaviorSubject<number | null>(null);
  private currentAccountDetails$$ = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    super();
  }

  getAuthorizedOfficial(accountId: number): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_AUTHORIZED_OFFICIAL }/${ accountId }`);
  }

  getAll(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<IApp.IAccountPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IAccountPage>(`${ environment.apiUrl }${ ACCOUNT_MGMT_GET_ACCOUNT }/pageSearch`, filterState, { params });
  }

  get(accountId: number): Observable<IApp.IAccountData> {
    return this.http.get<IApp.IAccountData>(`${ environment.apiUrl }${ ACCOUNT_MGMT_GET_ACCOUNT }/${ accountId }`);
  }

  getLatestTransactions(pagination: IApp.IPagination, filterState: ActivityLogFilterModel = {}): Observable<IApp.IActivityLogPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IActivityLogPage>(`${ environment.apiUrl }${ ACTIVITY_LOG_SEARCH }`, filterState, { params });
  }

  getAccountDetails(accountId: number, forceReload = false): Observable<any> { // changed to any done with Project transfer client change dtos and form objs
    const currentAccountId = this.currentAccountId$$.getValue();
    if (currentAccountId !== null && currentAccountId !== accountId) {
      this.currentAccountDetails$$.next(null);
    }
    if (forceReload || accountId !== currentAccountId) {
      this.currentAccountId$$.next(accountId);
      return this.http.get<any>(`${ environment.apiUrl }${ ACCOUNT_DETAILS }/${ accountId }`)
        .pipe(
          tap(details => this.currentAccountDetails$$.next(details)),
        );
    } else {
      return this.currentAccountDetails$$.asObservable();
    }
  }

  getOBPSCreditSummary(accountId: number): Observable<IApp.ICreditSummary> {
    return this.http.get<IApp.ICreditSummary>(`${ environment.apiUrl }${ OBPS_CREDIT_SUMMARY }/${ accountId }`);
  }

  getOBPSObligationSummary(accountId: number): Observable<IApp.IAccountObligation> {
    return this.http.get<IApp.IAccountObligation>(`${ environment.apiUrl }${ OBPS_OBLIGATION_SUMMARY }/${ accountId }`);
  }

  getOBPSAccountObligations(accountId: number): Observable<IApp.IObligationManagementPage> {
    const filterState = {
      facilityNameList : [accountId],
    };
    let params = new HttpParams();
    params = this.addPagination(params, null);
    return this.http.post<IApp.IObligationManagementPage>(`${ environment.apiUrl }${ OBPS_OBLIGATION_MGMT }`, filterState, { params });
  }

  searchUsersByAccountId(accountId: number): Observable<IApp.IUser[]> {
    return this.http.get<IApp.IUser[]>(`${ environment.apiUrl }${ ACCOUNT_SEARCH_USERS }/${ accountId }`);
  }

  getRegistrationDetails(pagination: IApp.IPagination, filterState: SubOrganizationFilterModel = {}): Observable<any> {
    let params = new HttpParams();
    filterState.discriminatorType = 'REGISTRATION_ACCOUNT';
    params = this.addPagination(params, pagination);
    return this.http.post<any>(`${ environment.apiUrl }${ REGISTRATION_REPORT_DETAILS }`, filterState, { params });
  }

  getActivitiesDetailList(pagination: IApp.IPagination, filterState: SubOrganizationFilterModel = {}): Observable<any> {
    let params = new HttpParams();
    filterState.discriminatorType = 'REGISTRATION_ACCOUNT';
    params = this.addPagination(params, pagination);
    return this.http.post<any>(`${ environment.apiUrl }${ ACTIVITY_DETAIL_LIST }`, filterState, { params });
  }

  updateOBPS_NFMP(request: IApp.IAccountData, quiet = false): Observable<IApp.OkResponseAccountDto> {
    return this.http.post<IApp.OkResponseAccountDto>(`${ environment.apiUrl }${ UPDATE_OBPS_NFMP }${ quiet ? '?quiet=1' : ''}`, request);
  }

  updateCM(request: IApp.IAccountData): Observable<IApp.OkResponseAccountDto> {
    return this.http.post<IApp.OkResponseAccountDto>(`${ environment.apiUrl }${ UPDATE_CM }`, request);
  }

  formatCreditSummary(creditSummary: IApp.ICreditSummary) {
    creditSummary.totalIssuedStr = this.formatCreditCount(creditSummary.totalIssued);
    creditSummary.currentHoldingsStr = this.formatCreditCount(creditSummary.currentHoldings);
    creditSummary.totalVolunatryCancellationsStr = this.formatCreditCount(creditSummary.totalVolunatryCancellations);
    creditSummary.totalPendingOutgoingUnitsStr = this.formatCreditCount(creditSummary.totalPendingOutgoingUnits);
    creditSummary.totalPendingIncomingUnitsStr = this.formatCreditCount(creditSummary.totalPendingIncomingUnits);
    creditSummary.totalActiveSurplusCreditsStr = this.formatCreditCount(creditSummary.totalActiveSurplusCredits);
    creditSummary.totalOffsetCreditIssuedStr = this.formatCreditCount(creditSummary.totalOffsetCreditIssued);
    creditSummary.totalActiveOffsetCreditsStr = this.formatCreditCount(creditSummary.totalActiveOffsetCredits);
  }

  formatObligationSummary(creditSummary: IApp.IAccountObligation) {
    creditSummary.outstandingObligationStr = this.formatObligationCount(creditSummary.outstandingObligation);
    creditSummary.compensationProvidedToDateStr = this.formatObligationCount(creditSummary.compensationProvidedToDate);
  }

  formatCreditCount(creditCount: number) {
    if (creditCount != null) {// } && creditCount >= 0) {
      return `${creditCount.toLocaleString()}  ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
    }
    return `0  ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
  }

  formatObligationCount(creditCount: number) {
    if (creditCount != null) {// } && creditCount >= 0) {
      return `${creditCount.toLocaleString()} ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
    }
    return `0  ${this.translateService.instant('COMMON.commonSection.tCo2e')}`;
  }

  getUpcomingCompensationDeadlines(
    pagination: IApp.IPagination,
    filterState: ObligationMgmtFilterModel = {},
  ): Observable<IApp.IObligationManagementPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IObligationManagementPage>(`${ environment.apiUrl }${ UPCOMING_DEADLINES }`, filterState, { params });
  }

  getWireTransferDetails(id = null, facilityCertificateNumber): Observable<IApp.IWireTransferDetails> {
    const body = { id, facilityCertificateNumber };
    return this.http.post<IApp.IWireTransferDetails>(`${ environment.apiUrl }${ WIRETRANSFER_DETAILS }`, body);
  }

  getDirectDepositDetails(): Observable<IApp.IWireTransferDetails> {
    return this.http.get<IApp.IWireTransferDetails>(`${ environment.apiUrl }${ DIRECT_DEPOSIT_DETAILS }`);
  }

  getAllRoles(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ GET_ALL_ROLES }`);
  }

  getAllViewableRoles(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ LOOKUP_ALL_USER_ROLES_LIST }`);
  }

  getAllCreateRoles(): Observable<IApp.IFluxDict> {
    return this.http.get<IApp.IFluxDict>(`${ environment.apiUrl }${ LOOKUP_ALL_CREATE_USER_ROLES }`);
  }

  addAssociation(payload): Observable<any> {
    return this.http.post<any>(`${ environment.apiUrl }${ SUBMIT_ASSOCIATION }`, payload);
  }

  getAccountProjects(pagination: IApp.IPagination, filterState: any = {}): Observable<IApp.ITableList> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.ITableList>(
      `${environment.serviceUrl}/offset-service/dashboard/project/pageSearchByFilterForSummary?`,
      filterState,
      { params },
    );
  }

  checkUserHasApprovedCI(accountId) : Observable<any>
  {
    return this.http.get(`${environment.apiUrl}${CHECK_APPROVED_CI}${accountId}`);
  }
}
