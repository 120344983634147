import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'formly-field-readonly-input',
  template: `
    <ng-container *ngIf="currValue !== null && currValue !== undefined; else PLACEHOLDER">
      <span class="form-control wrapped-text" readonly="readonly" [class.auto-size]="autoHeight">
        <ng-container *ngIf="type && type === 'formatted-number'; else REGULAR">{{ currValue | localizedNumber: '1.0-0' }}</ng-container>
        <ng-template #REGULAR>
          <ng-container *ngIf="chips; else NORMAL">
            <span
              class="badge badge-primary badge-tag simple-tag"
              style="margin-top: -2px;"
              [class.mb]="autoHeight"
              *ngFor="let item of chipsList; let i = index"
              >{{ item }}</span
            >
          </ng-container>
          <ng-template #NORMAL>
            <ng-container *ngIf="to.dataPopup; else NO_POPUP">
              <span [innerHTML]="currValue" [pTooltip]="currValue" tooltipPosition="top" tooltipStyleClass="tooltip-min-width"></span>
            </ng-container>
          </ng-template>
          <ng-template #NO_POPUP>
            <span [innerHTML]="currValue"></span>
          </ng-template>
        </ng-template>
      </span>
    </ng-container>
    <ng-template #PLACEHOLDER>
      <span class="form-control hide-overflow" readonly="readonly" style="color: #495057">
        {{ placeholder }}
      </span>
    </ng-template>
    <input type="hidden" [value]="currValue" class="wcag-visuallyhidden" [id]="elementId" />
  `,
  styleUrls: ['./formly-custom-types.scss'],
})
export class FormlyFieldReadOnlyInput extends FieldType implements OnInit {
  public translatePrefix;
  public type;
  public placeholder;
  public chips;
  public autoHeight;

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.translatePrefix = this.to.translatePrefix ? `${this.to.translatePrefix}.` : '';
    this.to.placeholder && (this.placeholder = this.translateService.instant(`${this.to.placeholder}`));
    this.type = this.to.type;
    this.chips = this.to.chips;
    this.autoHeight = this.to.autoHeight;

    this.initDefaultValueSub();
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      const parent = this.field.parent.parent;
      const grandParent = (parent && parent.parent) ? parent.parent : false;
      if (!prefix && parent && grandParent && grandParent.type === 'repeat') {
        const cnt = parent.key;
        prefix = `${grandParent['id']}_${cnt}`;
      }
      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    }
    return this.field.id;
  }

  get currValue() {
    const value = this.model[this.field.key];
    const label = `${this.translatePrefix}${value}`;
    if (this.to.translate) {
      const translatedValue = value ? this.translateService.instant(label) : '';
      return translatedValue !== label ? translatedValue : value;
    }
    return this.model && value;
  }

  get chipsList() {
    const value = this.model[this.field.key];
    return this.model && value && value.split(',');
  }

  private initDefaultValueSub(): void {
    const { defaultValue } = this.to;

    if (typeof defaultValue !== 'string' && typeof defaultValue !== 'number') {
      return;
    }

    const value = typeof defaultValue === 'number' ? defaultValue.toString() : defaultValue;

    this.formControl.setValue(value);
  }
}
