import { HttpParams } from '@angular/common/http';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../shared/services/modal.service";
import * as i3 from "../../core/store/store.service";
export const CCM_LIST_URI = '/cfr-service/creditClearance/pageSearchByFilter';
export const CCM_LIST_USER_URI = '/cfr-service/creditClearance/pageSearchCreditClearance';
export const CCM_GETBY_ID = '/cfr-service/creditClearance/get/';
export const CCM_GETBY_STARTDATE = '/cfr-service/creditClearance/ccmFetchARecord';
export const SETUP_CCM = '/cfr-service/creditClearance/create';
export const SAVE_CCM = '/cfr-service/creditClearance/save';
export const SUBMIT_CCM = '/cfr-service/creditClearance/submit';
export const DISCARD_CCM = '/cfr-service/creditClearance/discard';
export class CreditClearanceMechanismService extends AbstractPaginationService {
    constructor(http, modalService, store) {
        super();
        this.http = http;
        this.modalService = modalService;
        this.store = store;
    }
    setupCCM(payload) {
        return this.http.post(`${environment.serviceUrl}${SETUP_CCM}`, payload);
    }
    save(payload) {
        return this.http.post(`${environment.serviceUrl}${SAVE_CCM}`, payload);
    }
    submit(payload) {
        return this.http.post(`${environment.serviceUrl}${SUBMIT_CCM}`, payload);
    }
    discard(payload) {
        return this.http.post(`${environment.apiUrl}${DISCARD_CCM}`, payload);
    }
    getCCMList(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        const uri = this.store.user.isAdmin ? CCM_LIST_URI : CCM_LIST_USER_URI;
        return this.http.post(`${environment.apiUrl}${uri}`, filterState, { params });
    }
    getCCMByID(id) {
        return this.http.get(`${environment.apiUrl}${CCM_GETBY_ID}${id}`);
    }
    getCCMByStartDate(payload) {
        return this.http.post(`${environment.apiUrl}${CCM_GETBY_STARTDATE}`, payload);
    }
    openConfirmationModal(msg, metaDataList) {
        return this.modalService
            .open(ServiceMessageComponent, {
            messages: null,
            message: msg,
            type: "WARNING" /* WARNING */,
            metaDataList,
        }, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed();
    }
}
CreditClearanceMechanismService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditClearanceMechanismService_Factory() { return new CreditClearanceMechanismService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ModalService), i0.ɵɵinject(i3.StoreService)); }, token: CreditClearanceMechanismService, providedIn: "root" });
