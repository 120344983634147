/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-discussion-chat-reply.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../project-discussion-chat-header/project-discussion-chat-header.component.ngfactory";
import * as i3 from "../project-discussion-chat-header/project-discussion-chat-header.component";
import * as i4 from "../project-discussion-chat-controls/project-discussion-chat-controls.component.ngfactory";
import * as i5 from "../project-discussion-chat-controls/project-discussion-chat-controls.component";
import * as i6 from "../../../../../../core/store/store.service";
import * as i7 from "../../../../../services/modal.service";
import * as i8 from "../../../service/project-discussion.service";
import * as i9 from "./project-discussion-chat-reply.component";
var styles_ProjectDiscussionChatReplyComponent = [i0.styles];
var RenderType_ProjectDiscussionChatReplyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectDiscussionChatReplyComponent, data: {} });
export { RenderType_ProjectDiscussionChatReplyComponent as RenderType_ProjectDiscussionChatReplyComponent };
export function View_ProjectDiscussionChatReplyComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-discussion-chat-header", [], null, null, null, i2.View_ProjectDiscussionChatHeaderComponent_0, i2.RenderType_ProjectDiscussionChatHeaderComponent)), i1.ɵdid(1, 49152, null, 0, i3.ProjectDiscussionChatHeaderComponent, [], { entry: [0, "entry"] }, null), (_l()(), i1.ɵted(2, null, ["\n", "\n"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-project-discussion-chat-controls", [], null, null, null, i4.View_ProjectDiscussionChatControlsComponent_0, i4.RenderType_ProjectDiscussionChatControlsComponent)), i1.ɵdid(4, 114688, null, 0, i5.ProjectDiscussionChatControlsComponent, [i6.StoreService, i7.ModalService, i8.ProjectDiscussionService], { entry: [0, "entry"], project: [1, "project"], updateOnly: [2, "updateOnly"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reply; _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.reply; var currVal_3 = _co.project; var currVal_4 = true; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.reply == null) ? null : _co.reply.comment); _ck(_v, 2, 0, currVal_1); }); }
export function View_ProjectDiscussionChatReplyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-discussion-chat-reply", [], null, null, null, View_ProjectDiscussionChatReplyComponent_0, RenderType_ProjectDiscussionChatReplyComponent)), i1.ɵdid(1, 49152, null, 0, i9.ProjectDiscussionChatReplyComponent, [], null, null)], null, null); }
var ProjectDiscussionChatReplyComponentNgFactory = i1.ɵccf("app-project-discussion-chat-reply", i9.ProjectDiscussionChatReplyComponent, View_ProjectDiscussionChatReplyComponent_Host_0, { project: "project", reply: "reply" }, {}, []);
export { ProjectDiscussionChatReplyComponentNgFactory as ProjectDiscussionChatReplyComponentNgFactory };
