import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
export class TableTemplateControls {
    constructor(translateService) {
        this.translateService = translateService;
        this.action = new Subject();
    }
    nextAction(data) {
        this.action.next(data);
    }
    get _action() {
        return this.action;
    }
}
TableTemplateControls.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TableTemplateControls_Factory() { return new TableTemplateControls(i0.ɵɵinject(i1.TranslateService)); }, token: TableTemplateControls, providedIn: "root" });
