export const CREATE_REPORT_NOTIFICATION_MODAL_REPORT = 'REPORT';
export const CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION = 'NOTIFICATION';

export const CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT = 'createTitle';
export const CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_REPORT = 'createReportTitle';
export const CREATE_REPORT_NOTIFICATION_MODAL_SUBMIT_NOTIFICATION = 'createNotificationTitle';

export const CREATE_REPORT_NOTIFICATION_MODAL_REPORT_TYPE = 'reportTypeId';
export const CREATE_REPORT_NOTIFICATION_MODAL_NOTIFICATION_TYPE = 'notificationType';

export const PR_PREFIX = 'COMMON.languageList';
export const reportType = {
  monitoringReport: 'MONITORING_REPORT',
  projectReport: 'PROJECT_REPORT',
  correctedReport: 'CORRECTED_REPORT',
  ghgReversalReport: 'GHG_REV_REPORT',
} as const;

export const ENTITY_TYPE = {
  REPORT: 'REPORT',
  NOTIFICATION: 'NOTIFICATION',
} as const;

export type TReportType = typeof reportType[keyof typeof reportType];

export const notificationType = {
  notificationOfErrorsOrOmissions: 'NOT_ERR_OMI',
  notificationOfGhgReversal: 'NOT_GHG_REVERSAL',
  notificationOfGhgSuspectedReversal: 'NOT_SUSPECTED_GHG_REVERSAL',
  notificationOfStartDate: 'NOT_START_DATE',
  notificationOfNewProtocolVersion: 'NOT_NEW_PROTOCOL_VERSION',
  notificationThatTheProponentIsWindingUpDissolving: 'NOT_PROP_WIN_DISS',
  notificationOfUpdateToRiskManagementPlan: 'NOT_UPD_RMP',
  notificationOfAuthorizationOfProjectActivities: 'NOT_CARRYOUT_PROJ_ACT',
  notificationOfCancelProjectRegistration: 'NOT_INT_CANCEL_PROJ_REG',
  notProponantChange: 'NOT_CHANGE_PROPONENT_NAME_CIVIC_ADDRESS',
  notLandOwnerChange: 'NOT_CHANGE_LAND_OWNERSHIP',

} as const;

export type TNotificationType = typeof notificationType[keyof typeof notificationType];

export const entityStatus = {
  /** this is draft mode */
  inProcess: 'IN_PROCESS',
  discarded: 'DISCARDED',
  submitted: 'SUBMITTED',
  underReview: 'UNDER_REVIEW',
  onHold: 'ON_HOLD',
  approved: 'APPROVED',
  underReview2: 'UNDER_REVIEW_2',
  rejected: 'REJECTED',
  rejectedReview2: 'REJECTED_REVIEW_2',
} as const;

export type TEntityStatus = typeof entityStatus[keyof typeof entityStatus];

export const transitionType = {
  submissionsSubmitted: 'SUBMISSIONS_SUBMITTED',
  submissionsSaved: 'SUBMISSIONS_SAVED',
  submissionsDiscarded: 'SUBMISSIONS_DISCARDED',
  submissionsUnderReview: 'SUBMISSIONS_UNDER_REVIEW',
  submissionsOnHold: 'SUBMISSIONS_ON_HOLD',
  submissionsApproved: 'SUBMISSIONS_APPROVED',
  submissionsUnderReview2: 'SUBMISSIONS_UNDER_REVIEW_2',
  submissionsRejected: 'SUBMISSIONS_REJECTED',
  submissionsRejectedReview2: 'SUBMISSIONS_REJECTED_REVIEW_2',
};

export type TTransitionType = typeof transitionType[keyof typeof transitionType];
