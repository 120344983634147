import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, Subject } from 'rxjs';
import { PROJECT_DISCUSSION_SORT_ASC } from '../project-discussion.constants';
import { IProjectDiscussionAddComment, IProjectDiscussionCommentContent, IProjectDiscussionGetComments, IProjectDiscussionGetCommentsResponse, IProjectDiscussionReplyComment } from './project-discussion.service.types';

@Injectable()
export class ProjectDiscussionService {
  sort = PROJECT_DISCUSSION_SORT_ASC;
  filter: number[] = [];
  loadComments = new Subject<any>();

  private readonly baseUrl = `${environment.apiUrl}/offset-service/notes`;

  constructor(
    private http: HttpClient,
  ) { }

  onLoadComments(): void {
    this.loadComments.next();
  }

  getComments$(body: IProjectDiscussionGetComments): Observable<IProjectDiscussionGetCommentsResponse> {
    const uri = `${this.baseUrl}/search`;
    let params = new HttpParams();

    params = params.append('dir', this.sort.value);

    if (this.filter.length) {
      body = { ...body, userIds: this.filter };
    }

    return this.http.post<IProjectDiscussionGetCommentsResponse>(uri, body, { params });
  }

  addComment$(body: IProjectDiscussionAddComment): Observable<unknown> {
    const uri = `${this.baseUrl}/save`;
    return this.http.post<unknown>(uri, body);
  }

  updateComment$(body: IProjectDiscussionCommentContent): Observable<unknown> {
    const uri = `${this.baseUrl}/save`;
    return this.http.post<unknown>(uri, body);
  }

  replyComment$(body: IProjectDiscussionReplyComment): Observable<unknown> {
    const uri = `${this.baseUrl}/save`;
    return this.http.post<unknown>(uri, body);
  }

  getKml$(projectId: number): Observable<string> {
    const uri = `${environment.apiUrl}/account-service/document/downloadKml/${projectId}`;
    return this.http.get(uri, { responseType: 'text' });
  }
}
