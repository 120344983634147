/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-activities-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i6 from "@fortawesome/angular-fontawesome";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i9 from "../../../../shared/components/table-view/table-view.component";
import * as i10 from "./account-activities-list.component";
import * as i11 from "../../../../core/services/lookup.service";
import * as i12 from "../../services/account-management.service";
var styles_AccountActivitiesListComponent = [i0.styles];
var RenderType_AccountActivitiesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountActivitiesListComponent, data: {} });
export { RenderType_AccountActivitiesListComponent as RenderType_AccountActivitiesListComponent };
function View_AccountActivitiesListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("COMMON.jurisdictionsList." + _v.parent.context.$implicit.name))); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("REGISTRATION_PAGE.cfsRegistrationForm." + _v.parent.parent.context.$implicit.type))); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 0, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_8)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["FACILITY_NAME", 2]], null, 0, null, View_AccountActivitiesListComponent_9))], function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit.type === "CSH") || (_v.parent.context.$implicit.type === "GPC")) || (_v.parent.context.$implicit.type === "Residential")); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AccountActivitiesListComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_co.translateService.currentLang]; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_13)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.parent.context.$implicit.liquidFuels; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.activitiesIncluded.LILCIF")); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpad(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_ck(_v, 2, 0, _co.translateService.currentLang)]; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_15)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.parent.parent.context.$implicit.gasFuels; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.activitiesIncluded.GASLCIF")); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_12)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_14)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.parent.parent.context.$implicit.liquidFuels == null) ? null : _v.parent.parent.context.$implicit.liquidFuels.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.parent.parent.context.$implicit.gasFuels == null) ? null : _v.parent.parent.context.$implicit.gasFuels.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_AccountActivitiesListComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpad(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_ck(_v, 2, 0, _co.translateService.currentLang)]; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_17)), i1.ɵdid(1, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.fuelDtoList; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AccountActivitiesListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_11)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["FUEL", 2]], null, 0, null, View_AccountActivitiesListComponent_16))], function (_ck, _v) { var currVal_0 = (((_v.parent.context.$implicit.liquidFuels == null) ? null : _v.parent.context.$implicit.liquidFuels.length) || ((_v.parent.context.$implicit.gasFuels == null) ? null : _v.parent.context.$implicit.gasFuels.length)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AccountActivitiesListComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("COMMON.jurisdictionsList." + _v.context.$implicit))); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_19)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.jurisdiction; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AccountActivitiesListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_6)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["FACILITY", 2]], null, 0, null, View_AccountActivitiesListComponent_7)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_10)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_18)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type === "PROVINCE"); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = ((_v.context.$implicit.fuelDtoList == null) ? null : _v.context.$implicit.fuelDtoList.length); _ck(_v, 6, 0, currVal_2); var currVal_3 = ((_v.context.$implicit.jurisdiction == null) ? null : _v.context.$implicit.jurisdiction.length); _ck(_v, 8, 0, currVal_3); }, null); }
function View_AccountActivitiesListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_5)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.facilities; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_co.translateService.currentLang]; _ck(_v, 1, 0, currVal_0); }); }
function View_AccountActivitiesListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " - ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_4)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_2 = _v.context.$implicit.activities; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(("ACCOUNTS_MODULE.registrationList." + _v.context.$implicit.code))); var currVal_1 = i1.ɵunv(_v, 1, 1, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.registrationList.activityInfo")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_AccountActivitiesListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_3)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.accountSubTypeDtoList; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AccountActivitiesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [["class", "expand-collapse"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleColExpand(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "fa-icon", [["class", "float-left back-icon ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i5.View_FaIconComponent_0, i5.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i6.FaIconComponent, [i7.DomSanitizer, i6.FaConfig, i6.FaIconLibrary, [2, i6.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "ml-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Details"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountActivitiesListComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, "fas", (_v.context.$implicit.expanded ? "chevron-down" : "chevron-right")); _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.$implicit.expanded; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).title; var currVal_1 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_AccountActivitiesListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { activityDetailTemplate: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_TableViewComponent_0, i8.RenderType_TableViewComponent)), i1.ɵdid(6, 638976, null, 0, i9.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"] }, { paginationChanged: "paginationChanged" }), (_l()(), i1.ɵand(0, [[1, 2], ["activityDetailTemplate", 2]], null, 0, null, View_AccountActivitiesListComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.activities; var currVal_2 = _co.tableConfig; _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.registrationList.activityDetails")); _ck(_v, 2, 0, currVal_0); }); }
export function View_AccountActivitiesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-activities-list", [], null, null, null, View_AccountActivitiesListComponent_0, RenderType_AccountActivitiesListComponent)), i1.ɵdid(1, 245760, null, 0, i10.AccountActivitiesListComponent, [i11.LookupService, i12.AccountManagementService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountActivitiesListComponentNgFactory = i1.ɵccf("app-account-activities-list", i10.AccountActivitiesListComponent, View_AccountActivitiesListComponent_Host_0, { accountId: "accountId" }, {}, []);
export { AccountActivitiesListComponentNgFactory as AccountActivitiesListComponentNgFactory };
