import { AuthenticatedGuard } from "@core/guards/authenticated.guard";
import { MOPGuard } from "@core/guards/mop.guard";
import { AgreementCreditsListComponent } from "./components/agreement-credits-list/agreement-credits-list.component";
import { MainPageComponent } from "./containers/main-page/main-page.component";
import { SelectPartyComponent } from '@module/agreement-credits/components/select-party/select-party.component';
import { ReviewActivitiesComponent } from '@module/agreement-credits/components/review-activities/review-activities.component';
import { OtherPartyOrgDetailsComponent } from '@module/agreement-credits/components/other-party-org-details/other-party-org-details.component';
import { AuthContactPersonInfoComponent } from '@module/agreement-credits/components/auth-contact-person-info/auth-contact-person-info.component';
import { GenAgreementComponent } from '@module/agreement-credits/components/gen-agreement/gen-agreement.component';
import { AgreementCreditsService } from "./agreement-credits.service";
import { AgreementStepperComponent } from "./containers/agreement-stepper/agreement-stepper.component";
import { SignAgreementComponent } from '@module/agreement-credits/components/sign-agreement/sign-agreement.component';
import { UploadAgreementComponent } from '@module/agreement-credits/components/upload-agreement/upload-agreement.component';
import { RcaOrgDetailsComponent } from '@module/agreement-credits/components/rca-org-details/rca-org-details.component';
import { RcaAuthContactPersonInfoComponent } from '@module/agreement-credits/components/rca-auth-contact-person-info/rca-auth-contact-person-info.component';
import { AgreementGuard } from '@module/agreement-credits/agreement.guard';
const CreateAgreementMap = {
    SelectPartyComponent,
    ReviewActivitiesComponent,
    OtherPartyOrgDetailsComponent,
    AuthContactPersonInfoComponent,
    GenAgreementComponent,
    SignAgreementComponent,
    UploadAgreementComponent,
    RcaOrgDetailsComponent,
    RcaAuthContactPersonInfoComponent,
};
const ɵ0 = { title: 'SUBMISSION_MANAGEMENT_MODULE.pageTitle', permission: 'MENU_SUBMISSION_MANAGEMENT', navbar: true }, ɵ1 = { title: 'SUBMISSION_MANAGEMENT_MODULE.pageTitle', permission: 'MENU_SUBMISSION_MANAGEMENT', navbar: true }, ɵ2 = { title: 'SUBMISSION_MANAGEMENT_MODULE.pageTitle', permission: 'MENU_SUBMISSION_MANAGEMENT', navbar: true }, ɵ3 = { service: AgreementCreditsService, entryComponentsMap: CreateAgreementMap }, ɵ4 = { service: AgreementCreditsService, entryComponentsMap: CreateAgreementMap }, ɵ5 = { service: AgreementCreditsService, entryComponentsMap: CreateAgreementMap };
const routes = [
    {
        path: '',
        component: MainPageComponent,
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list',
            },
            {
                path: 'list',
                component: AgreementCreditsListComponent,
                data: ɵ0,
                canActivate: [MOPGuard],
            },
            {
                path: 'list/:id',
                component: AgreementCreditsListComponent,
                data: ɵ1,
                canActivate: [MOPGuard],
            },
            {
                path: 'generate/:id/:existInMarkit',
                component: GenAgreementComponent,
                data: ɵ2,
                canActivate: [MOPGuard],
            },
        ]
    },
    {
        path: 'wizard',
        canActivate: [AuthenticatedGuard],
        children: [
            {
                path: 'create-agreement',
                component: AgreementStepperComponent,
                data: ɵ3,
            },
            {
                path: 'agreement/:id',
                component: AgreementStepperComponent,
                data: ɵ4,
            },
            {
                path: 'agreement/:type/:id',
                component: AgreementStepperComponent,
                data: ɵ5,
            },
            {
                path: 'agreement',
                component: AgreementStepperComponent,
                canActivate: [AgreementGuard],
            },
        ],
    },
];
export class AgreementCreditRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
