import { Component, Inject, OnInit } from '@angular/core';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import {
  CR_AGGREGATED_SUMMARY_TABLE_CFG, CR_NORMAL_SUMMARY_TABLE_CFG,
} from '@module/project-report/routines/project-report.constants';

@Component({
  selector: 'app-summary-table',
  templateUrl: './summary-table.component.html',
  styleUrls: ['./summary-table.component.scss'],
})
export class SummaryTableComponent implements OnInit {
  tableConfig: ITableViewConfig;
  visible = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<SummaryTableComponent>) {

  }

  ngOnInit() {
    this.tableConfig = this.isAggregated() ? CR_AGGREGATED_SUMMARY_TABLE_CFG : CR_NORMAL_SUMMARY_TABLE_CFG;
  }

  isAggregated() {
    return this.inputData.type === 'MASTER';
  }

  save() {
    this.visible = false;
    this.dialogRef.close(true);
  }

  close() {
    this.visible = false;
    this.dialogRef.close();
  }
}
