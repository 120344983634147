/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./ng-dropdown-panel.component";
import * as i3 from "./ng-dropdown-panel.service";
var styles_NgDropdownPanelComponent = [];
var RenderType_NgDropdownPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NgDropdownPanelComponent, data: {} });
export { RenderType_NgDropdownPanelComponent as RenderType_NgDropdownPanelComponent };
function View_NgDropdownPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ng-dropdown-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { searchTerm: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.filterValue); var currVal_1 = _co.headerTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_NgDropdownPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "ng-dropdown-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { searchTerm: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.filterValue); var currVal_1 = _co.footerTemplate; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_NgDropdownPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { contentElementRef: 0 }), i0.ɵqud(402653184, 2, { scrollElementRef: 0 }), i0.ɵqud(402653184, 3, { paddingElementRef: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NgDropdownPanelComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, [[2, 0], ["scroll", 1]], null, 3, "div", [["class", "ng-dropdown-panel-items scroll-host"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, [[3, 0], ["padding", 1]], null, 0, "div", [], [[2, "total-padding", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["content", 1]], null, 1, "div", [], [[2, "scrollable-content", null]], null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NgDropdownPanelComponent_2)), i0.ɵdid(10, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerTemplate; _ck(_v, 4, 0, currVal_0); var currVal_3 = _co.footerTemplate; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.virtualScroll; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.virtualScroll && _co.items.length); _ck(_v, 7, 0, currVal_2); }); }
export function View_NgDropdownPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-dropdown-panel", [], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_NgDropdownPanelComponent_0, RenderType_NgDropdownPanelComponent)), i0.ɵdid(1, 770048, null, 0, i2.NgDropdownPanelComponent, [i0.Renderer2, i0.NgZone, i3.NgDropdownPanelService, i0.ElementRef, [2, i1.DOCUMENT]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NgDropdownPanelComponentNgFactory = i0.ɵccf("ng-dropdown-panel", i2.NgDropdownPanelComponent, View_NgDropdownPanelComponent_Host_0, { items: "items", markedItem: "markedItem", position: "position", appendTo: "appendTo", bufferAmount: "bufferAmount", virtualScroll: "virtualScroll", headerTemplate: "headerTemplate", footerTemplate: "footerTemplate", filterValue: "filterValue", alignRight: "alignRight", minWidth: "minWidth", dropdownFullWidth: "dropdownFullWidth" }, { update: "update", scroll: "scroll", scrollToEnd: "scrollToEnd", outsideClick: "outsideClick" }, ["*"]);
export { NgDropdownPanelComponentNgFactory as NgDropdownPanelComponentNgFactory };
