import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IRawFormBase } from '@core/models/raw-form.types';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-credit-adjustment-report-view-address',
  templateUrl: './credit-adjustment-report-view-address.component.html',
  styleUrls: ['./credit-adjustment-report-view-address.component.scss'],
})
export class CreditAdjustmentReportViewAddressComponent implements OnInit {

  public visible = true;
  facilityPrefix;
  model: any = {};
  formGroup = new FormGroup({});
  fields: FormlyFieldConfig[];
  tooltip: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CreditAdjustmentReportViewAddressComponent>,
    private formFactoryService: FormFactoryService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    if (this.data && this.data.facility) {
      this.model = this.data.facility;
      this.tooltip = this.data.tooltip;
      if(this.data.facility.type === "PROVINCE"){
        this.model = {...this.data.facility, name: this.translateService.instant(`COMMON.jurisdictionsList.${this.data.facility.name}`)}
      }
      this.facilityPrefix = this.formFactoryService.firstUpper(this.model.type);
    }
    this.fields = this.formFactoryService.configureForm(this.getFacilityDetails());
  }

  getFacilityDetails(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        fieldGroup: [
          {
            type: 'input',
            className: 'col-6',
            key: 'name',
            label: `REGISTRATION_PAGE.${this.facilityPrefix}Form.facilityName`,
            disabled: true,
            required: false,
            id: 'organizationName',
          },
        ],
      },
    ];
  }

  public close(): void {
    this.dialogRef.close(false);
  }

}
