<div class="document-container" *ngIf="model.id">
  <app-documents
    [documentUploadConfig]="documentUploadConfig"
    [documentsPage]="documentsPage"
    [disabled] ="model.disabled"
    [showPlaceholders]="true"
    (documentResult)="updateUploadedDocuments($event)"
    [relative]="true"
    >
  </app-documents>
</div>
