import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-address-view',
  templateUrl: './address-view.component.html',
  styleUrls: ['./address-view.component.scss']
})
export class AddressViewComponent implements OnInit, OnChanges {

  @Input() legend: string;
  @Input() address: any;

  streetNumberAndName: string;
  cityAndProvince: string;
  poBoxAndPostalCode: string;
  constructor(private translateService: TranslateService) { }

  ngOnInit() {
    this.configureAddress();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.configureAddress();
  }

  private configureAddress() {
    if (this.address) {
      this.streetNumberAndName = this.address.streetNumber ? `${this.address.streetNumber}  ${this.address.streetName}` : this.address.streetName;
      this.cityAndProvince = this.address.city ? `${this.address.city}, ${this.address.province}` : this.address.province;
      this.poBoxAndPostalCode = this.address.poBox ? `${this.address.poBox}, ${this.address.postalCode}` : this.address.postalCode;
    }
  }
}
