/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-transfer-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i3 from "primeng/dialog";
import * as i4 from "../../../pipes/custom-translate.pipe";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i8 from "@ngx-formly/core";
import * as i9 from "@angular/common";
import * as i10 from "./project-transfer-details.component";
import * as i11 from "../../../services/form-factory.service";
import * as i12 from "@angular/material/dialog";
import * as i13 from "../project-builder.service";
var styles_ProjectTransferDetailsComponent = [i0.styles];
var RenderType_ProjectTransferDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectTransferDetailsComponent, data: {} });
export { RenderType_ProjectTransferDetailsComponent as RenderType_ProjectTransferDetailsComponent };
function View_ProjectTransferDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 17, "p-dialog", [], [[8, "className", 0], [8, "dragging", 0]], [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } if (("visibleChange" === en)) {
        var pd_1 = (_co.changed($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Dialog_0, i2.RenderType_Dialog)), i1.ɵdid(2, 180224, null, 2, i3.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵeld(7, 0, null, 1, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i6.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(9, 540672, null, 0, i6.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i6.ControlContainer, null, [i6.FormGroupDirective]), i1.ɵdid(11, 16384, null, 0, i6.NgControlStatusGroup, [[4, i6.ControlContainer]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "formly-form", [], null, null, null, i7.View_FormlyForm_0, i7.RenderType_FormlyForm)), i1.ɵprd(512, null, i8.FormlyFormBuilder, i8.FormlyFormBuilder, [i8.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(14, 966656, null, 0, i8.FormlyForm, [i8.FormlyFormBuilder, i8.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i6.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵeld(15, 0, null, 1, 3, "div", [["class", "d-flex justify-content-end mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵpid(131072, i4.CustomTranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform("PROJECTS_MODULE.transferrequest.projectDetails")); var currVal_3 = true; var currVal_4 = false; var currVal_5 = true; var currVal_6 = false; var currVal_7 = 100; var currVal_8 = false; var currVal_9 = _co.visible; var currVal_10 = _ck(_v, 6, 0, "800px"); _ck(_v, 2, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); var currVal_18 = _co.form; _ck(_v, 9, 0, currVal_18); var currVal_19 = _co.form; var currVal_20 = _co.model; var currVal_21 = _co.fields; _ck(_v, 14, 0, currVal_19, currVal_20, currVal_21); }, function (_ck, _v) { var currVal_0 = "eccc"; var currVal_1 = false; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_11 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_12 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_13 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_14 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_15 = i1.ɵnov(_v, 11).ngClassValid; var currVal_16 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_17 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 7, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); var currVal_22 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("PROJECTS_MODULE.ProjectBuilder.close")); _ck(_v, 17, 0, currVal_22); }); }
function View_ProjectTransferDetailsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "formly-form", [], null, null, null, i7.View_FormlyForm_0, i7.RenderType_FormlyForm)), i1.ɵprd(512, null, i8.FormlyFormBuilder, i8.FormlyFormBuilder, [i8.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(2, 966656, null, 0, i8.FormlyForm, [i8.FormlyFormBuilder, i8.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i6.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.model; var currVal_2 = _co.fields; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProjectTransferDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectTransferDetailsComponent_1)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NORMAL_VIEW", 2]], null, 0, null, View_ProjectTransferDetailsComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popUpView; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectTransferDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-transfer-details", [], null, null, null, View_ProjectTransferDetailsComponent_0, RenderType_ProjectTransferDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i10.ProjectTransferDetailsComponent, [i11.FormFactoryService, i12.MAT_DIALOG_DATA, i12.MatDialogRef, i13.ProjectBuilderService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectTransferDetailsComponentNgFactory = i1.ɵccf("app-project-transfer-details", i10.ProjectTransferDetailsComponent, View_ProjectTransferDetailsComponent_Host_0, { popUpView: "popUpView", form: "form", model: "model" }, {}, []);
export { ProjectTransferDetailsComponentNgFactory as ProjectTransferDetailsComponentNgFactory };
