import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { BaseAccountActionsService } from '@module/account-management/services/base-account-actions.service';
import { HelperService } from '@shared/services/helper.service';
import { Observable, of} from 'rxjs';


const ACCOUNT_REJECT2 = '/account-service/account/dualControlReject';


@Injectable({
  providedIn: 'root'
})
export class OffsetAccountActionsService extends BaseAccountActionsService {

  customConfirmations = {
    APPROVE_ALL_LINKED_ACCOUNTS: 'APPROVE_ALL_LINKED_ACCOUNTS',
  };


  constructor(private router: Router,
    private helperService: HelperService) {
    super();
  }

  getConfirmationMsg(key) {
    return this.customConfirmations[key] || 'confirmationMessageAccount';
  }

  approveAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    this.router.navigate(this.helperService.getTranslatedPath(`/account-management/approve-account/${account.id}`));
    return of({});
  }

  reject2Account(account: IApp.IAccount): Observable<IApp.IAccount> {
   return this.http.post<IApp.IAccount>(`${ environment.apiUrl }${ ACCOUNT_REJECT2 }`, account);
  }

  approve2Account(account: IApp.IAccount): Observable<IApp.IAccount> {
    this.router.navigate(this.helperService.getTranslatedPath(`/account-management/approve-account/${account.id}`));
    return this.http.post<IApp.IAccount>(`${ environment.apiUrl }`, account);
  }

  approvesubAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
     return super.approveAccount(account);
  }

  approve2SubAccount(account: IApp.IAccount): Observable<IApp.IAccount> {
    return super.approve2Account(account);
  }


  showConfirmationMessageForApproval(){
    return false;
  }
  showConfirmationMessageForRejection(){
    return false;
  }
}
