import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const EXP_TOKEN_CUSTOM_MSG = 'Invalid/expired token or token not found, please access using valid token.';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
      private router: Router,
      private authService: AuthService,
    ) { }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
      if (
        (err.status === 401 || err.status === 403) &&
        (err.error.description === 'Expired JWT' ||
        (err.error.messages && err.error.messages[0] === EXP_TOKEN_CUSTOM_MSG))
      ) {
        // assuming that session token is already expired
        this.authService.resetSession();
        return of(err.message);
      }
      return throwError(err);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const newRequest = req.clone({ headers: req.headers });
      return next.handle(newRequest)
          .pipe(
            catchError(x => this.handleAuthError(x)),
          );
    }
}
