import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LookupService } from '@core/services/lookup.service';
import { CAR_CI_INSTRUMENT_ID, CAR_CI_LIQUID_CI_FUEL_FLD, CAR_CI_OF_FUEL, CAR_INSTRUMENT_CI_ID, CAR_LIQUID_COMPLIANCE_CREDITS_FLD, CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD, CAR_MANUAL_ENERGY_DENSITY, CAR_MANUAL_ENERGY_DENSITY_VALUE } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';
import { CreditAdjustmentReportService } from '@module/credit-adjustment-report/credit-adjustment-report.service';
import { getAlphanumericIdInput, getCarbonIntensity } from '@module/credit-adjustment-report/routines/credit-adjustment-report.routine';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { LocalPaginationService } from '@shared/services/local-pagination.service';
import { ModalService } from '@shared/services/modal.service';
import { Dialog } from 'primeng';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-credit-adjustment-us-view-details',
  templateUrl: './credit-adjustment-us-view-details.component.html',
  styleUrls: ['./credit-adjustment-us-view-details.component.scss'],
  providers: [LocalPaginationService],

})

export class CreditAdjustmentUsViewDetailsComponent implements OnInit {
  static key = 'CreditAdjustmentUsViewDetailsComponent';
  @ViewChild('dialog', { static: false }) dialog: Dialog;
  public form = new FormGroup({});
  public fields: FormlyFieldConfig[];
  public model :any ={};

  public tableConfig: ITableViewConfig;
  public tableData = {content: []};
  visible = true;
  separatorMask = 'separator.5';
  commaSeparator = ',';

  @ViewChild('CheckboxColTemplate', {static: true}) public CheckboxColTemplate: TemplateRef<any>;
  @ViewChild('inputColTemplate', {static: true}) public inputColTemplate: TemplateRef<any>;
  @ViewChild('allocateVolColTemplate', {static: true}) public allocateVolColTemplate: TemplateRef<any>;
  // @ViewChild('quantityAvailableColTemplate', {static: true}) public quantityAvailableColTemplate: TemplateRef<any>;
  public referenceCarbonIntensity: number;
  public referenceCIOfElectricity$ = new BehaviorSubject([]);

  private usedUnitsMap:any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CreditAdjustmentUsViewDetailsComponent>,
    private service: CreditAdjustmentReportService,
    private translateService: TranslateService,
    private lookupService: LookupService,
    private formfactoryService: FormFactoryService,
    private localPaginationService: LocalPaginationService

  ) { }

  ngOnInit() {
    this.setFields();
    this.setUsedUnitsMap();
    this.geReferenceCarbonIntensity(this.data)
    this.getReferenceCarbonIntensityForElectricity(this.data)
    this.setTableConfig(this.data);
    this.setTableData();
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  setFields(){
    // need to confirm id for these formfields
    this.fields = this.formfactoryService.configureForm([
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getAlphanumericIdInput(),
          getCarbonIntensity('carbonIntensityView',CAR_CI_OF_FUEL)
        ]
      }
     ])
  }



  setTableConfig(data){
    let _columns =[]
    this.lookupService.getUnderSectionTableConfig('CREDIT_ADJUSTMENT_REPORT_SEC_90_DETAILS').subscribe(result=>{
      this.tableConfig = result
      const { columns, ...config } = this.tableConfig
      _columns = [
        ...this.updateColumnsConfiguration(columns,data),
        {
          header: `isOverride_${this.service.currentStep.code}`,
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.CheckboxColTemplate,
        },
        {
          header: 'overriddenValue',
          width: AUTO_WIDTH_FOR_COLUMNS,
          templateRef: this.inputColTemplate,
        },
      ];

      this.tableConfig = {
        ...config,
        ...{ columns: _columns },
      };
    })

  }

  setTableData(){
    console.log('data', this.data)
    this.data.viewDetailsData.forEach(element => {
      element.quarterAndYear = `${element.nameOfQuarter}-${element.year}`
    });

    if(this.data.viewDetailsData.length){
      this.localPaginationService.data = this.data.viewDetailsData
      this.tableData = this.localPaginationService.getPage({ page: 0 });
    }
    this.model ={
      [CAR_INSTRUMENT_CI_ID] : this.data.viewDetailsData[0].referredReportDetail.alphanumericId,
      [CAR_CI_OF_FUEL]: this.data.viewDetailsData[0].referredReportDetail.approvedCliValue
    }

  }

  onVolumneAllocation(data){
    data._quantityAvailableRaw  = data.totalComplianceCreditQuantity - (+data.complianceCreditQuantity + this.usedUnitsMap[data.referredReportDetailId]);
    data._quantityAvailable = Math.max(data._quantityAvailableRaw, 0);

    this.calculatedCredits(data);
  }

  calculatedCredits(data){
    if(data.step.code !== 'STEP-8'){
      //calculation of credits from step 1 to step 7 of CCR
      if (this.referenceCarbonIntensity) {

        const ciLiquidLowCIFuel =  this.data.rowData.ciModel.approvedCarbonIntensityRounded
        const vProducedLiquidLowCIFuel = Number(data.complianceCreditQuantity) || 0;
        const energyDensity = data[CAR_MANUAL_ENERGY_DENSITY] ? data[CAR_MANUAL_ENERGY_DENSITY_VALUE]||0 : data[CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD]||0;
        const calc = (this.referenceCarbonIntensity - ciLiquidLowCIFuel) * vProducedLiquidLowCIFuel * energyDensity * Math.pow(10, -6);
        data[CAR_LIQUID_COMPLIANCE_CREDITS_FLD]= Math.round(calc < 0 ? 0 : calc)

      }
    }
    else{
      // calculatoin of credits for step 8 (electricity/bio gas) of CCR
      const ciLiquidLowCIFuel =  this.data.rowData.ciModel.approvedCarbonIntensityRounded
      const vProducedLiquidLowCIFuel = Number(data.complianceCreditQuantity) || 0;
      const energyDensity = data[CAR_MANUAL_ENERGY_DENSITY] ? data[CAR_MANUAL_ENERGY_DENSITY_VALUE]||0 : data[CAR_LO_CI_FUEL_ENERGY_DENSITY_FLD]||0;
      let carbonIntensityElecProducedUsingBiogas = ciLiquidLowCIFuel * vProducedLiquidLowCIFuel * energyDensity / data.electricalEnergyOutput

      const ciEnergyProduced = Number(carbonIntensityElecProducedUsingBiogas) || 0;
      const electricalEnergyProduced = Number(data.electricalEnergyProduced) || 0;
      let calc =0
      let fixedReferenceCarbonIntensity = this.referenceCarbonIntensity
      let referenceCarbonIntensityForElectricity = this.updateReferenceCIofElectricity(data)


      if(data.section=== 'SECTION_95'){
        calc = (Number(fixedReferenceCarbonIntensity) - ciLiquidLowCIFuel) * vProducedLiquidLowCIFuel * energyDensity * Math.pow(10, -6);
      }
      else{
        calc = (Number(referenceCarbonIntensityForElectricity) - ciEnergyProduced) * electricalEnergyProduced * Math.pow(10, -6);
      }
      data[CAR_LIQUID_COMPLIANCE_CREDITS_FLD] = Math.round(calc < 0 ? 0 : calc)

    }


  }

  geReferenceCarbonIntensity(data){
    this.service.getReferenceCarbonIntensityForFuel(this.service.currentData.complianceYear, data.rowData.ciModel.fuelDto.id)
        .subscribe(refCI => {
          if (!isNaN(refCI)) {
            this.model.referencedCarbonIntensity = refCI;
            this.referenceCarbonIntensity = refCI;
          } else {
            this.service.showErrorMessage('noRefCiFound').subscribe();
          }
        });
  }

  getReferenceCarbonIntensityForElectricity(data){
    if(data.action === 'INPUT'){
    let electricityStep = data.viewDetailsData.filter((rec)=>rec.step.code==="STEP-8")
    if (electricityStep.length && electricityStep.length > 0 && electricityStep[0].facility.civicAddress.province) {
      this.service.getReferencedCarbonIntensityForElectricity(electricityStep[0].facility.civicAddress.province).subscribe(result => {
        this.referenceCIOfElectricity$.next(result);
      });
    }
  }
  }

  private updateReferenceCIofElectricity(data){
    if(data.referenceCiOfElectricityCode && this.referenceCIOfElectricity$.getValue() &&
    this.referenceCIOfElectricity$.getValue().length){
      const refCI = this.referenceCIOfElectricity$.getValue().find(
        item => item.code === data.referenceCiOfElectricityCode,
      );
      if (refCI) {
        return  refCI.value;
      }
    }
  }



  onPaginationChanged(){

  }

  updateColumnsConfiguration(cols,data) {
    if(this.data.rowData.ciSectionCode !== 'DEFAULT_CI'){

      return [...cols]
    }
    else{

      const totalComplianceCreditQuantity = {
        header: 'complianceCreditQuantity',
        width: AUTO_WIDTH_FOR_COLUMNS,
        field: 'totalComplianceCreditQuantity',
        fieldType:'Numeric'
      }
      const allocateQuantity = {
        header: 'allocatedQuantity',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.allocateVolColTemplate,
        fieldType:'Numeric'
      }
      const quantityAvailable = {
        header: 'quantityAvailable',
        width: AUTO_WIDTH_FOR_COLUMNS,
        field: '_quantityAvailable',
        fieldType:'Numeric'
      }
      const index = cols.findIndex((col) => col.field === 'complianceCreditQuantity');
      if (index !== -1) {
        cols.splice(index, 1, totalComplianceCreditQuantity, allocateQuantity, quantityAvailable);
      }


      return [...cols]
    }

    // return cols.map((col)=>{
    //   if(col.field=== 'energyDensity'){
    //     return {
    //       ...col,
    //       field: this.translateService.currentLang === 'en'? "energyDensityEn":'energyDensityFr'
    //     }
    // const index = cols.findIndex((col) => col.field === 'creditsToBeDeposited');

    //   }
    //   else{
    //     return {...col}
    //   }
    // })
  }

  public save(){
    if (this.tableData.content.some(data=> data._quantityAvailableRaw < 0) && this.data.rowData.ciSectionCode === 'DEFAULT_CI') {
      return;
    }
    this.dialogRef.close(this.data);
  }

  public close(){
    // this.visible = false;
    this.dialogRef.close(false);
  }

  get headerText(){
    return this.data.action === 'VIEW' ?
    'CREDIT_ADJUSTMENT_REPORT.reportStep.viewDetailsTitle' : 'CREDIT_ADJUSTMENT_REPORT.reportStep.inputDetailsTitle'
  }

  private setUsedUnitsMap() {
    this.service.currentData.refReportDetails.forEach(refReport => {
      const defaultCIReports = this.service.currentData.reportDetails.filter(report => report.ciSectionCode === 'DEFAULT_CI' && report.referredReportDetailId === refReport.id && report.ciApplicationId !==this.data.rowData.ciModel.ciApplicationId);
      this.usedUnitsMap[refReport.id] = defaultCIReports.reduce((acc, defaultCIReport) => (acc + (+defaultCIReport.complianceCreditQuantity|| 0)), 0);
    });

    this.updateAvailabelUnits();
  }

  private updateAvailabelUnits() {
    this.data.viewDetailsData.forEach(data => {
      data._quantityAvailable = data.totalComplianceCreditQuantity - (+data.complianceCreditQuantity + this.usedUnitsMap[data.referredReportDetailId]);
      data._quantityAvailableRaw = data._quantityAvailable;
    });
  }
}
