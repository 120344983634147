<h2>{{ 'ACCOUNTS_MODULE.registrationList.activityDetails' | translate }}</h2>
<div class="table-area">
  <app-table-view
    [config]="tableConfig"
    [data]="activities"
    (paginationChanged)="onPaginationChanged($event)">
  </app-table-view>
</div>

<ng-template #activityDetailTemplate let-data>
  <div>
    <span (click)="toggleColExpand(data)" class="expand-collapse">
      <fa-icon class="float-left back-icon"  [icon]="['fas', data.expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
      <span class="ml-3">Details</span>
    </span>
    <ng-container *ngIf="data.expanded">
      <ul>
        <li *ngFor = "let accountSubType of data.accountSubTypeDtoList;">
          {{ 'ACCOUNTS_MODULE.registrationList.'+accountSubType.code | translate }} - {{ 'ACCOUNTS_MODULE.registrationList.activityInfo' | translate }}
          <ul>
            <li *ngFor = "let activity of accountSubType.activities;">
              {{activity[translateService.currentLang]}}
              <ul>
                <li *ngFor = "let facility of activity.facilities;">
                  <ng-container *ngIf="facility.type === 'PROVINCE'; else FACILITY">
                    {{'COMMON.jurisdictionsList.' + facility.name | translate}}
                  </ng-container>
                  <ng-template #FACILITY>
                    <ng-container *ngIf="facility.type === 'CSH' || facility.type === 'GPC' || facility.type === 'Residential'; else FACILITY_NAME">
                      {{'REGISTRATION_PAGE.cfsRegistrationForm.' +facility.type | translate}}
                    </ng-container>

                    <ng-template #FACILITY_NAME>
                      {{facility.name}}
                    </ng-template>

                  </ng-template>
                  <ul>
                    <ng-container *ngIf="facility.fuelDtoList?.length">
                      <ng-container *ngIf="facility.liquidFuels?.length || facility.gasFuels?.length; else FUEL">
                        <ng-container *ngIf="facility.liquidFuels?.length">
                          <li>
                            {{'ACCOUNTS_MODULE.activitiesIncluded.LILCIF' | translate}}
                            <ul>
                              <li *ngFor = "let fuel of facility.liquidFuels;">
                                {{fuel[translateService.currentLang]}}
                              </li>
                            </ul>
                          </li>
                        </ng-container>
                        <ng-container *ngIf="facility.gasFuels?.length">
                          <li>
                            {{'ACCOUNTS_MODULE.activitiesIncluded.GASLCIF' | translate}}
                            <ul>
                              <li *ngFor = "let fuel of facility.gasFuels;">
                                {{fuel[[translateService.currentLang]]}}
                              </li>
                            </ul>
                          </li>
                        </ng-container>

                      </ng-container>
                      <ng-template #FUEL>
                        <li *ngFor = "let fuel of facility.fuelDtoList;">
                          {{fuel[[translateService.currentLang]]}}
                        </li>
                      </ng-template>
                    </ng-container>
                    <ng-container *ngIf="facility.jurisdiction?.length">
                      <li *ngFor = "let province of facility.jurisdiction;">
                        {{'COMMON.jurisdictionsList.' + province | translate}}
                      </li>
                    </ng-container>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </ng-container>
  </div>
</ng-template>
