import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IApp } from '@core/models/app.interfaces';
import IDocument = IApp.IDocument;

@Component({
  selector: 'app-credit-creation-additional-details',
  templateUrl: './credit-creation-additional-details.component.html',
  styleUrls: ['./credit-creation-additional-details.component.scss']
})
export class CreditCreationAdditionalDetailsComponent implements OnInit, AfterViewInit {

  public currentStepObj;
  public visible = true;
  public documents: IDocument[];
  public isDocumentUploadInProgress = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CreditCreationAdditionalDetailsComponent>,
  ) { }

  ngOnInit() {
    this.currentStepObj = this.data.currentStepObj;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.currentStepObj.additionalDetailsForm.disable();
    });
  }

  documentUploadHandler(documentResult: IDocument[]): void {
    this.documents = documentResult;
    this.isDocumentUploadInProgress = documentResult
      .filter(document => document._uploadStatus)
      .some(document => document._uploadStatus !== 'CLEAN');
  }

  public close(): void {
    this.dialogRef.close(false);
  }

}
