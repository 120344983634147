<div class="inner">

  <div class="container-fluid wrapper d-flex flex-md-column h-100">

    <div class="row border-bottom flex-grow-0">
      <div class="col-12">
        <h2 class="m-0 pb-2"> {{'PROJECTS_MODULE.ProjectBuilder.subProjectApplicationForm' | translate}} </h2>
      </div>
    </div>

    <div class="row flex-fill overflow-hidden pt-4">

      <div class="h-100 overflow-auto">

        <app-blurb [message]="'COMMON.documentGuidanceTest.AGG_SUB_PROJECT' | translate" class="stick-left"></app-blurb>
        <div class="pl-4 mt-3 mr-2" style="min-height: 250px;">
          <div class="d-flex justify-content-between mb-3">
            <div #UPLOAD>
              <button type="button" class="btn btn-secondary mr-3" (click)="addSubProject()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.addNewSubProject' | translate}}</button>
              <button type="button" class="btn btn-secondary" (click)="uploadSubProjects()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.uploadSubProjects' | translate}}</button>
            </div>
            <div class="tools-selector">
              <ng-select
                wcag-label
                [placeholder]="'COMMON.actionsLabel.tools' | translate"
                [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.tools' | translate}"
                [items]="taskList"
                bindLabel="name"
                bindValue="name"
                class="ng-dropdown-left"
                [appendTo]="'body'"
                [alignRight]="true"
                [(ngModel)]="downloadSelected"
                (change)="downloadTemplate($event)"
                [disabled]="projectBuilderService.disabled">
              </ng-select>
            </div>
          </div>
          <app-sub-projects-table [showStatus]="false"></app-sub-projects-table>
        </div>
        <ng-container *ngIf="viewReady">

          <div class="pl-4 mr-2">
            <div class="app-panel mt-3">
              <formly-form [form]="form" [model]="aggregatedAttnDeclarationModel" [fields]="aggregatedAttnDeclarationFields"></formly-form>

              <ng-container>
                <app-blurb *ngIf='projectBuilderService.hasApproveAction()' [message]="'PROJECTS_MODULE.ProjectForms.adminRemarksGuidanceTest'"></app-blurb>
                <formly-form [form]="form" [model]="needMoreInfoModel" [fields]="needMoreInfoFields"></formly-form>
              </ng-container>
            </div>
          </div>

          <ng-container *ngIf="projectBuilderService.showRequiredDocs">
            <div class="pl-2 mt-5 border-bottom">
              <h3>{{'PROJECTS_MODULE.ProjectBuilder.requiredDocument' | translate}}</h3>
            </div>
            <div class="pl-4 mt-3 mr-2">
              <app-project-documents
                [entityName]="'PROJECTS_IN_AGGREGATION'"
                [entityId]="model.projectsInAggregationId">
              </app-project-documents>
            </div>
          </ng-container>

        </ng-container>

      </div>
    </div>
    <div class="row button-panel d-flex justify-content-end flex-grow-0 pt-3 border-top">
      <div class="button-background">
        <button type="button" class="btn btn-secondary" (click)="back()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.back' | translate}}</button>

        <ng-container *ngIf="!model.entityActionList || !model.entityActionList.length; else ACTION_LIST">
          <button type="button" class="btn ml-2 btn-secondary"
                  (click)="save()"
                  [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}</button>

          <button type="button" class="btn ml-2 btn-primary"
                  (click)="submit()"
                  [disabled]="documentUploadInProgress.inProgress$ |async">
              {{'PROJECTS_MODULE.ProjectBuilder.submit' | translate}}
          </button>
        </ng-container>

        <ng-template #ACTION_LIST>
          <ng-container *ngFor="let action of model.entityActionList">
            <button type="button" class="btn ml-2 btn-primary"
                    (click)="save()"
                    [disabled]="documentUploadInProgress.inProgress$ |async"
                    *ngIf="action.workflowAction==='EDIT' || action.workflowAction==='EDIT_CUST'; else ACTIONS">
                      {{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}
            </button>
            <ng-template #ACTIONS>
              <button type="button" class="btn btn-primary ml-2" (click)="triggerAction(action)" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.'+action.workflowAction | translate}}</button>
            </ng-template>
          </ng-container>
        </ng-template>

      </div>
    </div>

  </div>
</div>





