import { IRawForm } from '@core/models/raw-form.types';
import { getDictSelect, getInput } from '@core/utilities/raw-form.utils';
import { CCR_TYPE } from '@module/credit-creation-reports/const/credit-creation-reports.const';
import {
  CCR_ACTIVITY_CODE_FLD,
  CCR_COMPLIANCE_PERIOD_FLD,
  CCR_CREATE_POPUP,
  CCR_ORGANIZATION_NAME_FLD,
  CCR_PREFIX,
  CCR_QUARTER_FLD,
  CCR_TYPE_FLD,
  CCR_TYPE_PREFIX,
  CCRApplyPrefix,
  CCR_STEP_PREFIX,
  CAR_COMPLIANCE_PERIOD_FLD,
} from '@module/credit-creation-reports/routines/create-credis-reports.constats';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

export const getOrganizationName = (onChange?: () => void, hideExpression?): IRawForm => {
  return getDictSelect({
    id: CCR_ORGANIZATION_NAME_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.ACCOUNT_NAMES,
    label: CCRApplyPrefix(CCR_ORGANIZATION_NAME_FLD, CCR_CREATE_POPUP),
    onChange,
    disabled: hideExpression,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
    skipInitialBlur: true,
    hideExpression,
  });
};

export const getCreditCreationReportType = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CCR_TYPE_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.CCR_TYPE,
    label: CCRApplyPrefix(CCR_TYPE_FLD, CCR_CREATE_POPUP),
    onChange,
    ignoreDropdownAppend: true,
    skipInitialBlur: true,
    translatePrefix: CCR_TYPE_PREFIX,
  });
};

export const getCreditCreationReportActivityType = (source: BehaviorSubject<any>, reload$?: Observable<any>): IRawForm => {
  return {
    type: 'multi-checkbox',
    key: `${CCR_ACTIVITY_CODE_FLD}s`,
    source,
    label: CCRApplyPrefix(CCR_ACTIVITY_CODE_FLD, CCR_CREATE_POPUP),
    required: true,
    className: 'col-12',
    id: CCR_ACTIVITY_CODE_FLD,
    hideExpression: `!model.${CCR_TYPE_FLD}`,
    disabled: `!model.${CCR_TYPE_FLD}`,
    reload$,
  };
};

export const getCreditCreationReportCompliancePeriod = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CCR_COMPLIANCE_PERIOD_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.CCR_COMPLIANCE_YEARS,
    label: CCRApplyPrefix(CCR_COMPLIANCE_PERIOD_FLD, CCR_CREATE_POPUP),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    hideExpression:`model.${CCR_TYPE_FLD} === '${CCR_TYPE.CREDIT_ADJUSTMENT}'`,
    translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
  });
};

export const getCreditAdjustmentReportCompliancePeriod=(onChange?: () => void,): IRawForm =>{
  return getDictSelect({
    id: CAR_COMPLIANCE_PERIOD_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.CAR_COMPLIANCE_YEARS,
    label: CCRApplyPrefix(CCR_COMPLIANCE_PERIOD_FLD, CCR_CREATE_POPUP),
    onChange,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    hideExpression:`model.${CCR_TYPE_FLD} !== '${CCR_TYPE.CREDIT_ADJUSTMENT}'`,
    translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
  });
}

export const getCreditCreationReportQuarters = (onChange?: () => void, disabled?): IRawForm[] => {
  return [
    getDictSelect({
      id: CCR_QUARTER_FLD,
      className: 'col-6',
      source: SOURCE_DATA_TYPE.CCR_QUARTERS,
      label: CCRApplyPrefix(CCR_QUARTER_FLD, CCR_CREATE_POPUP),
      bindValue: 'code',
      onChange,
      ignoreTranslateOptions: true,
      ignoreDropdownAppend: true,
      hideExpression: `model.${CCR_TYPE_FLD} !== '${ CCR_TYPE.QUARTERLY }' || model.${CCR_COMPLIANCE_PERIOD_FLD} === 2022`,
      disabled,
      translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
    }),
    getInput({
      id: `${CCR_QUARTER_FLD}_`,
      className: 'col-6',
      disabled: true,
      placeholder: CCRApplyPrefix('Q3AndQ4', CCR_STEP_PREFIX),
      label: CCRApplyPrefix(CCR_QUARTER_FLD, CCR_CREATE_POPUP),
      hideExpression: `model.${CCR_TYPE_FLD} !== '${ CCR_TYPE.QUARTERLY }' || model.${CCR_COMPLIANCE_PERIOD_FLD} !== 2022`,
      translatePrefix: CCR_PREFIX + CCR_CREATE_POPUP,
    }),
  ];
};

export const activityTypeMap = {
  ANNUAL: ["ERP", "EUFS"],
  QUARTERLY: ["IMPLCI", "BPE", "PRODCI"],
  CREDIT_ADJUSTMENT:["PRODCI","BPE","IMPLCI"]
}
