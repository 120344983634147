import { Component, OnInit } from '@angular/core';
import { NavigationService } from '@shared/services/navigation.service';
import { BackbtnserviceService } from './backbtnservice.service';

@Component({
  selector: 'app-back-btn',
  templateUrl: './back-btn.component.html',
  styleUrls: ['./back-btn.component.scss']
})
export class BackBtnComponent implements OnInit {

  constructor(
    // private backbtnserviceService: BackbtnserviceService,
    private navigation: NavigationService ) { }

  ngOnInit() {
  }

  back(event){
    event && event.preventDefault();
    this.navigation.back();
  }

}
