import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { FilterModel } from '@core/models/filter.model';
import { StoreService } from '@core/store/store.service';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { ModalService } from '@shared/services/modal.service';
import { Observable } from 'rxjs';

export const CCM_LIST_URI = '/cfr-service/creditClearance/pageSearchByFilter';
export const CCM_LIST_USER_URI = '/cfr-service/creditClearance/pageSearchCreditClearance';
export const CCM_GETBY_ID = '/cfr-service/creditClearance/get/';
export const CCM_GETBY_STARTDATE = '/cfr-service/creditClearance/ccmFetchARecord';
export const SETUP_CCM = '/cfr-service/creditClearance/create';
export const SAVE_CCM = '/cfr-service/creditClearance/save';
export const SUBMIT_CCM = '/cfr-service/creditClearance/submit';
export const DISCARD_CCM = '/cfr-service/creditClearance/discard';

@Injectable({
  providedIn: 'root'
})
export class CreditClearanceMechanismService extends AbstractPaginationService {

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private store: StoreService
  ) {
    super();
  }

  public setupCCM(payload): Observable<any> {
    return this.http.post<any>(`${environment.serviceUrl}${SETUP_CCM}`, payload);
  }

  public save(payload): Observable<any> {
    return this.http.post<any>(`${environment.serviceUrl}${SAVE_CCM}`, payload);
  }

  public submit(payload): Observable<any> {
    return this.http.post<any>(`${environment.serviceUrl}${SUBMIT_CCM}`, payload);
  }

  public discard(payload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${DISCARD_CCM}`, payload);
  }

  public getCCMList(pagination: IApp.IPagination, filterState: FilterModel = {}): Observable<any> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);

    const uri = this.store.user.isAdmin ? CCM_LIST_URI: CCM_LIST_USER_URI;

    return this.http.post<any>(`${environment.apiUrl}${ uri }`, filterState, { params });
  }

  public getCCMByID(id): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${CCM_GETBY_ID}${id}`);
  }

  public getCCMByStartDate(payload): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}${CCM_GETBY_STARTDATE}`,payload);
  }


  public openConfirmationModal(msg, metaDataList?) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          metaDataList,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }
}
