/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./required-information.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i3 from "primeng/dialog";
import * as i4 from "../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i5 from "@ngx-formly/core";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i8 from "primeng/api";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./required-information.component";
import * as i11 from "@angular/material/dialog";
import * as i12 from "@angular/router";
import * as i13 from "../../store/store.service";
import * as i14 from "../../services/auth.service";
import * as i15 from "../../../shared/services/helper.service";
import * as i16 from "../../../shared/services/user.service";
import * as i17 from "../../services/lookup.service";
var styles_RequiredInformationComponent = [i0.styles];
var RenderType_RequiredInformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RequiredInformationComponent, data: {} });
export { RenderType_RequiredInformationComponent as RenderType_RequiredInformationComponent };
export function View_RequiredInformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "p-dialog", [], [[8, "className", 0]], [[null, "visibleChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("visibleChange" === en)) {
        var pd_0 = ((_co.visible = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Dialog_0, i2.RenderType_Dialog)), i1.ɵdid(1, 180224, null, 2, i3.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, { visibleChange: "visibleChange" }), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpod(4, { width: 0 }), (_l()(), i1.ɵeld(5, 0, null, 1, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "formly-form", [], null, null, null, i4.View_FormlyForm_0, i4.RenderType_FormlyForm)), i1.ɵprd(512, null, i5.FormlyFormBuilder, i5.FormlyFormBuilder, [i5.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(8, 966656, null, 0, i5.FormlyForm, [i5.FormlyFormBuilder, i5.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i6.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i1.ɵeld(9, 0, null, 2, 10, "p-footer", [], null, null, null, i7.View_Footer_0, i7.RenderType_Footer)), i1.ɵdid(10, 49152, [[2, 4]], 0, i8.Footer, [], null, null), (_l()(), i1.ɵeld(11, 0, null, 0, 8, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["id", "REQUIRED_INFORMATION_continueBtn"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["id", "REQUIRED_INFORMATION_logoutBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.signOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Required Information"; var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = _co.visible; var currVal_9 = _ck(_v, 4, 0, "500px"); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.infoForm; var currVal_11 = _co.data; var currVal_12 = _co.fields; _ck(_v, 8, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = "eccc"; _ck(_v, 0, 0, currVal_0); var currVal_13 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("COMMON.actionsLabel.CONTINUE")); _ck(_v, 14, 0, currVal_13); var currVal_14 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("COMMON.actionsLabel.LOGOUT")); _ck(_v, 18, 0, currVal_14); }); }
export function View_RequiredInformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-required-information", [], null, null, null, View_RequiredInformationComponent_0, RenderType_RequiredInformationComponent)), i1.ɵdid(1, 114688, null, 0, i10.RequiredInformationComponent, [i11.MAT_DIALOG_DATA, i11.MatDialogRef, i12.Router, i13.StoreService, i14.AuthService, i15.HelperService, i16.UserService, i17.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RequiredInformationComponentNgFactory = i1.ɵccf("app-required-information", i10.RequiredInformationComponent, View_RequiredInformationComponent_Host_0, {}, {}, []);
export { RequiredInformationComponentNgFactory as RequiredInformationComponentNgFactory };
