/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accounts-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/components/table-filter/table-filter.component.ngfactory";
import * as i3 from "../../../../shared/components/table-filter/table-filter.component";
import * as i4 from "../../../../shared/services/modal.service";
import * as i5 from "../../../../core/services/lookup.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../../../../core/store/store.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../../shared/services/helper.service";
import * as i10 from "../../../../shared/services/upload-document.service";
import * as i11 from "@angular/common";
import * as i12 from "../../../../shared/pipes/custom-translate.pipe";
import * as i13 from "../../controls/account-actions/account-actions.component.ngfactory";
import * as i14 from "../../controls/account-actions/account-actions.component";
import * as i15 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i16 from "../../../../shared/components/table-view/table-view.component";
import * as i17 from "./accounts-list.component";
import * as i18 from "../../services/account-management.service";
import * as i19 from "../../services/obps-account-actions.service";
import * as i20 from "../../services/cfr-account-actions.service";
import * as i21 from "../../services/offset-account-actions.service";
import * as i22 from "../../services/cm-account-actions.service";
var styles_AccountsListComponent = [i0.styles];
var RenderType_AccountsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountsListComponent, data: {} });
export { RenderType_AccountsListComponent as RenderType_AccountsListComponent };
function View_AccountsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "app-table-filter", [], null, [[null, "actionSelected"], [null, "filterInit"], [null, "filterChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionSelected" === en)) {
        var pd_0 = (_co.actionSelectedController($event) !== false);
        ad = (pd_0 && ad);
    } if (("filterInit" === en)) {
        var pd_1 = (_co.onFilterChanged($event, false) !== false);
        ad = (pd_1 && ad);
    } if (("filterChanged" === en)) {
        var pd_2 = (_co.onFilterChanged($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_TableFilterComponent_0, i2.RenderType_TableFilterComponent)), i1.ɵdid(1, 770048, [[3, 4]], 0, i3.TableFilterComponent, [i1.ViewContainerRef, i4.ModalService, i5.LookupService, i6.TranslateService, i7.StoreService, i8.Router, i9.HelperService, i10.UploadDocumentService, i8.ActivatedRoute], { fields: [0, "fields"] }, { actionSelected: "actionSelected", filterChanged: "filterChanged", filterInit: "filterInit" }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accountListFilters; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AccountsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "filters"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountsListComponent_2)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyLoaded; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AccountsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewAccount($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.viewAccount($event, _v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i12.CustomTranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("ACCOUNTS_MODULE.accountList.view")); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-account-actions", [], null, [[null, "actionSuccessfull"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionSuccessfull" === en)) {
        var pd_0 = (_co.refreshData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_AccountActionsComponent_0, i13.RenderType_AccountActionsComponent)), i1.ɵdid(2, 770048, null, 0, i14.AccountActionsComponent, [i8.Router, i9.HelperService, i4.ModalService], { actionsList: [0, "actionsList"], account_id: [1, "account_id"], actionService: [2, "actionService"], accountData: [3, "accountData"] }, { actionSuccessfull: "actionSuccessfull" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.entityActionList; var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.id); var currVal_2 = _co.actionService; var currVal_3 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AccountsListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { viewColTemplate: 0 }), i1.ɵqud(402653184, 2, { actionTemplate: 0 }), i1.ɵqud(671088640, 3, { tableFilterComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountsListComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-table-view", [], null, [[null, "paginationChanged"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("paginationChanged" === en)) {
        var pd_1 = (_co.onPaginationChanged($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_TableViewComponent_0, i15.RenderType_TableViewComponent)), i1.ɵdid(7, 638976, null, 0, i16.TableViewComponent, [i1.ElementRef, i6.TranslateService], { data: [0, "data"], config: [1, "config"], firstItemIndex: [2, "firstItemIndex"] }, { paginationChanged: "paginationChanged" }), (_l()(), i1.ɵand(0, [[1, 2], ["viewColTemplate", 2]], null, 0, null, View_AccountsListComponent_3)), (_l()(), i1.ɵand(0, [[2, 2], ["actionTemplate", 2]], null, 0, null, View_AccountsListComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showFilters; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.accounts; var currVal_2 = _co.tableConfig; var currVal_3 = _co.firstItemIndex; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AccountsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-accounts-list", [], null, null, null, View_AccountsListComponent_0, RenderType_AccountsListComponent)), i1.ɵdid(1, 245760, null, 0, i17.AccountsListComponent, [i8.Router, i6.TranslateService, i5.LookupService, i18.AccountManagementService, i9.HelperService, i7.StoreService, i19.OBPSAccountActionsService, i20.CfrAccountActionsService, i21.OffsetAccountActionsService, i22.CMAccountActionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountsListComponentNgFactory = i1.ɵccf("app-accounts-list", i17.AccountsListComponent, View_AccountsListComponent_Host_0, { showFilters: "showFilters", masterAccountId: "masterAccountId" }, {}, []);
export { AccountsListComponentNgFactory as AccountsListComponentNgFactory };
