import { Component, OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { LookupService } from '@core/services/lookup.service';
import { ProjectBuilderService } from '../project-builder.service';

@Component({
  selector: 'app-project-activity-list',
  templateUrl: './project-activity-list.component.html',
  styleUrls: ['./project-activity-list.component.scss']
})
export class ProjectActivityListComponent implements OnInit {
  private activityTpes: IApp.ILookup[];
  public items:IApp.ILookup[];
  constructor(
    private projectBuilderService: ProjectBuilderService,
    private lookupService: LookupService
    ) { }

  ngOnInit() {
    this.getActivityTypes();
  }

  public selectCard(cardInfo) {
    this.projectBuilderService.selectedCard = cardInfo;
  }

  private getActivityTypes() {
    this.lookupService.getOffsetProjectActivityType().subscribe(data => {
      this.items = data;
    });
  }

}
