import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CI_PATHWAY_ADD_DETAIL_PREFIX, CI_PATHWAY_REPORT_COMPLIANCE_PERIOD_FLD, CI_PATHWAY_REPORT_DETAILS_PREFIX, CI_PATHWAY_REPORT_LCA2, CI_PATHWAY_REPORT_POPUP_COMPLIANCE_PERIOD_FLD_LABEL, CI_PATHWAY_REPORT_SECTION1, CI_PATHWAY_REPORT_SECTION1A, CI_PATHWAY_REPORT_SECTION1B, CIPathwayApplyPrefix } from '@module/ci-pathway-report/ci-pathway-report.const';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { Dialog } from 'primeng';
import { ViewFacilityDetailsComponent } from '../view-facility-details/view-facility-details.component';
import { ModalService } from '@shared/services/modal.service';
import { IRawFormBase } from '@core/models/raw-form.types';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { LOOKUP_PATHWAY, LookupService } from '@core/services/lookup.service';
import { IApp } from '@core/models/app.interfaces';
import { documentsTableConfig } from '@module/account-management/account-management.const';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { CiPathwayReportService } from '@module/ci-pathway-report/ci-pathway-report.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { BehaviorSubject } from 'rxjs';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { CARApplyPrefix } from '@module/credit-adjustment-report/const/credit-adjustment-report.const';

@Component({
  selector: 'app-add-details-ci-path-report-lca-model',
  templateUrl: './add-details-ci-path-report-lca-model.component.html',
  styleUrls: ['./add-details-ci-path-report-lca-model.component.scss']
})
export class AddDetailsCiPathReportLcaModelComponent extends BaseStepperStepComponent implements OnInit {
  @ViewChild('dialog', { static: false }) dialog: Dialog;
  @ViewChild('actionTemplate', { static: true }) public actionTemplate: TemplateRef<any>;
  public visible = true;
  public currentStepObj;
  tableConfig: ITableViewConfig;
  staticDocumentsTableData;
  lcaFormLeft = new FormGroup({});
  lcaFormRight = new FormGroup({});
  lcaForm1 = new FormGroup({});
  model: any;
  enableFieldForEditLca = false;
  pendingDocumentMetaData: IApp.IPendingDocumentMetaData;
  code: string;
  uploadedDocumentTypeIds: number[] = [];
  documentUploadConfig: IApp.IDocumentUploadConfig;
  documentTypeList: IApp.IDocumentUploadConfig[];
  documentResult1;
  finalDatafromPopUp;
  documentsPage: IApp.IDocumentsPage;
  selectedDocuments;
  disabled = false;
  feedStockAr;
  pathwayList$ = new BehaviorSubject([]);
  pathwayListLeft$ = new BehaviorSubject([]);
  documentConfig: IApp.IDocumentUploadConfig
  lca1FieldList: FormlyFieldConfig[];
  lca2FieldList: FormlyFieldConfig[];
  lca3FieldListLeft: FormlyFieldConfig[];
  lca3FieldListRight: FormlyFieldConfig[];
  lca4FieldListRight: FormlyFieldConfig[];
  lcaSection5List: FormlyFieldConfig[];
  outcome = [];
  public tableActionItems;
  public firstItemIndex = 0;
  feedStockArrayValue = [];
  feedStockFinalVal;

  CI_PATHWAY_REPORT_LCA3L: IRawFormBase[] = [
    {
      groupFields: false,
      fieldGroup: [
        {
          type: 'input',
          className: 'col-12',
          key: 'ciApplicationDto.instrumentId',
          label: CIPathwayApplyPrefix('approvedAlphanumeric', CI_PATHWAY_ADD_DETAIL_PREFIX),
          required: true,
          disabled: true,
          id: 'approvedAlphanumericLCA',
        },
        {
          type: 'input',
          className: 'col-12',
          key: 'ciApplicationDto.carbonIntensityFuel',
          label: CIPathwayApplyPrefix('approvedLCACI', CI_PATHWAY_ADD_DETAIL_PREFIX),
          required: true,
          disabled: true,
          id: 'carbonIntensityFuel',
          customTemplateOptions: {
            type: 'formatted-number',
            mask: 'separator.2',
            allowNegativeNumbers: false,
          },
        },
        {
          type: 'input',
          className: 'col-12',
          key: 'ciApplicationDto.additionalCarbonIntensityFuel',
          label: CIPathwayApplyPrefix('approvedAdditionalLCACI', CI_PATHWAY_ADD_DETAIL_PREFIX),
          disabled: true,
          id: 'additionalCarbonIntensityFuel',
          customTemplateOptions: {
            type: 'formatted-number',
            mask: 'separator.2',
            allowNegativeNumbers: false,
          },
        },
        {
          type: 'input',
          className: 'col-12',
          key: 'approvedCarbonIntensity',
          label: CIPathwayApplyPrefix('approvedRoundedLCACI', CI_PATHWAY_ADD_DETAIL_PREFIX),
          required: true,
          disabled: true,
          id: 'approvedCarbonIntensity',
        },
        {
          type: 'dict-select',
          className: 'col-12',
          key: 'ciApplicationDto.feedStock.id',
          id: 'feedStockLCA',
          hideExpression: 'model.ciApplicationDto.type === "CI_CHARGING_STATIONS" || model.ciApplicationDto.type === "CI_FUELING_STATIONS"',
          disabled: true,
          required: true,
          translatePrefix: 'COMMON.feedstockList',
          items: [],
        //  bindValue: 'id',
          dataProvided: true,
         // source: SOURCE_DATA_TYPE.FEED_STOCK,
          label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_1',
          placeholder: 'selectItem',
        },
        {
          key: 'ciApplicationDto.pathwayType',
          type: 'radio',
          className: 'col-12',
          name: 'approvedPathwayType',
          label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOpt3',
          apiOptions: true,
          dummyInputForLabel: true,
          apiService: LOOKUP_PATHWAY,
          id: 'pathwayType',
          disabled: true
        },
        {
          type: 'dict-select',
          className: 'col-12 pathwayId',
          key: 'ciApplicationDto.pathwayId',
          source: this.pathwayListLeft$,
          //dataProvided: true,
          label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.dropDown3',
          disabled: true,
          //preventAutoPopulate: true,
          id: 'pathwayId',
          requiredExpression: '!(!model.ciApplicationDto.pathwayType || model.ciApplicationDto.pathwayType === "EXISTING")',
          placeholder: 'COMMON.submissionTypeList.selectItem',
        },
  
      ],
    },
  ];


  lcaSection3: IRawFormBase[] = [{
    groupFields: false,
    fieldGroup: [
      {
        type: 'checkbox',
        className: 'col-12',
        key: 'ciEdited',
        label: CIPathwayApplyPrefix('checkboxToEditCIValues', CI_PATHWAY_ADD_DETAIL_PREFIX),
        disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
        //requiredTrue: true,
        id: `ciEdited`,
        change: (field, $event) => this.enablingFields($event),
      },
      {
        type: 'input',
        className: 'col-12',
        key: 'instrumentId',
        label: CIPathwayApplyPrefix('approvedAlphanumeric', CI_PATHWAY_ADD_DETAIL_PREFIX),
        // disabledExpression: () => !this.fieldsEditable(),
        disabled: true,
        id: 'instrumentId',
      },
    ],

  }];
  lcaSection4: IRawFormBase[] = [{
    // groupFields: false,
    fieldGroup: [
      {
        type: 'input',
        className: 'col-12',
        key: 'actualCarbonIntensity',
        label: CIPathwayApplyPrefix('approvedRoundedLCACI', CI_PATHWAY_ADD_DETAIL_PREFIX),
        required: true,
        id: 'actualCarbonIntensity',
        disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
        //placeholder: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.ciValuePlaceholder',
        customTemplateOptions: {
          type: 'formatted-number',
          // mask: 'separator.2',
          allowNegativeNumbers: false,
        },
        validators: ['positiveOrZeroNumber'],
      },
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'feedStock.id',
        id: 'feedStock',
        hideExpression: 'model.ciApplicationDto.type === "CI_CHARGING_STATIONS" || model.ciApplicationDto.type === "CI_FUELING_STATIONS"',
        required: true,
        translatePrefix: 'COMMON.feedstockList',
        items: [],
        dataProvided: true,
       // bindValue: 'name',
       // source: SOURCE_DATA_TYPE.FEED_STOCK,
        label: 'SUBMISSION_MANAGEMENT_MODULE.ci_info.field_1',
        placeholder: 'selectItem',
        disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
      },
      {
        key: 'pathwayType',
        type: 'radio',
        className: 'col-12',
        name: 'pathwayType',
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.radioOpt3',
        apiOptions: true,
        dummyInputForLabel: true,
        apiService: LOOKUP_PATHWAY,
        id: 'type',
        expressionProperties: {
         // 'templateOptions.disabled': '((model.status === null) || (model.status === "IN_PROCESS"))? false: true',
        },
        onChange: (field, event) => { this.enableOrDisablePathwayList(event) }
      },
      {
        type: 'dict-select',
        className: 'col-12',
        key: 'pathwayIdList',
        multiple: true,
        source: this.pathwayList$,
        label: 'SUBMISSION_MANAGEMENT_MODULE.applicationApprovalForm.dropDown3',
        id: 'pathwayIdList',
        requiredExpression: '!(!model.pathwayType || model.pathwayType === "EXISTING")',
        placeholder: 'COMMON.submissionTypeList.selectItem',
        ignoreDropdownAppend: true,
        disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") || (model.status === "PROPOSED"))? false: true',
      },
    ],

  }];

  section5: IRawFormBase[] = [
    {
      groupFields: false,
      fieldGroup: [
        {
          className: "rationable",
          type: 'textarea',
          key: 'rationable',
          id: 'rationable',
          label: CIPathwayApplyPrefix('differenceBtwnApprovedActual', CI_PATHWAY_ADD_DETAIL_PREFIX),
          requiredExpression: () => this.checkActualAndApprovedCI(),
          translate: true,
          disabledExpression: '((model.status === null) || (model.status === "IN_PROCESS") ||  (model.status === "PROPOSED"))? false: true',
          hideExpression: () => !this.fieldsEditable() && !this.model.ciEdited,
          templateOptions: {
            rows: 4,
          }
        }]
    }
  ]




  constructor(@Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<AddDetailsCiPathReportLcaModelComponent>,
    public _el: ElementRef,
    private formFactoryService: FormFactoryService,
    private modalService: ModalService,
    public service: CiPathwayReportService,
    public uploadDocumentService: UploadDocumentService,
    private lookupService: LookupService,
    private translateService: TranslateService,) {
    super();
    this.pageEl = _el;
  }

  ngOnInit() {
    this.lookupService.getFeedStock().subscribe(res => {
      if (res) {
        this.feedStockAr = res;
      }
    })
    this.model = this.data.currentStepObj;
    if(this.model && this.model.feedStock &&  this.model.feedStock.name)
      this.feedStockFinalVal = this.model.feedStock.name;
    if (this.model && this.model.pathwayList && this.model.pathwayList.length && this.model.ciApplicationDto.pathwayId)
    {
      this.pathwayListLeft$.next(this.model.pathwayList);
      if(this.model.pathwayType === "NEW")
        this.pathwayList$.next(this.model.pathwayList);
      if(!this.model.ciEdited)
      this.model.pathwayIdList = [this.model.ciApplicationDto.pathwayId];
    }
    // else
    // {
    //   this.model.pathwayIdList = null;
    // }
    if(this.model.pathwayType === "NEW")
      this.pathwayList$.next(this.model.pathwayList);
    if (((this.model.status === null) || (this.model.status === "IN_PROCESS") || (this.model.status === "PROPOSED")) ? false : true) {
      this.disabled = true;
    }
    console.log("Lca model", this.model);
    if(this.model.ciApplicationDto.type === "CI_CHARGING_STATIONS")
      this.lca1FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_SECTION1A);
    else if(this.model.ciApplicationDto.type === "CI_FUELING_STATIONS")
      this.lca1FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_SECTION1B);
    else 
      this.lca1FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_SECTION1);
    this.lca2FieldList = this.formFactoryService.configureForm(CI_PATHWAY_REPORT_LCA2);
    if(this.model && this.model.ciApplicationDto && this.model.ciApplicationDto.feedStock)
    {
      this.feedStockArrayValue = [this.model.ciApplicationDto.feedStock];
      this.CI_PATHWAY_REPORT_LCA3L[0].fieldGroup[4]['items'] = this.feedStockArrayValue;
    }
    this.lca3FieldListLeft = this.formFactoryService.configureForm(this.CI_PATHWAY_REPORT_LCA3L);
    this.lca3FieldListRight = this.formFactoryService.configureForm(this.lcaSection3);
    this.lca4FieldListRight = this.formFactoryService.configureForm(this.lcaSection4);
    this.lcaSection5List = this.formFactoryService.configureForm(this.section5);
    this.lcaForm1.disable();
    this.documentsPage = {
     // totalElements: 1,
      totalPages: 1,
      last: true,
      first: true,
      size: 20,
      number: 1,
      numberOfElements: 1,
      sort: null,
      content: []
    }
    this.alreadyUploadedDocs();
  //  this.documentUploadConfig = this.getDocumentConfig();
    this.configStaticDocumentsTable();
    this.setTableActionItems();
    setTimeout(() => {
      if((this.model.status === null) || (this.model.status === "IN_PROCESS") || (this.model.status === "PROPOSED")){
        this.lcaForm1.get('pathwayType').enable();
        if(!this.model.ciEdited)
        {
        this.lcaSection4[0].fieldGroup[1]['items'] = this.feedStockArrayValue;
        this.lca4FieldListRight = this.formFactoryService.configureForm(this.lcaSection4);
        }
      }
      else
      {
        this.lcaForm1.get('pathwayType').disable();
        if(this.model.feedStock)
        {
        let feedStockArrayValue11 = [this.model.feedStock];
        this.lcaSection4[0].fieldGroup[1]['items'] = feedStockArrayValue11;
        this.lca4FieldListRight = this.formFactoryService.configureForm(this.lcaSection4);
        }
      }

      if (this.model.pathwayType === 'EXISTING')
        this.lcaForm1.get('pathwayIdList').disable()

    })
  }

  isElectricity()
  {
    if(this.model.ciApplicationDto.type === "CI_CHARGING_STATIONS")
      return true;
    else
      return false;
  }

  isCompressed()
  {
    if(this.model.ciApplicationDto.type === "CI_FUELING_STATIONS")
        return true;
    else
      return false;
  }


  checkActualAndApprovedCI(){
    this.model.actualCarbonIntensity = +this.model.actualCarbonIntensity
    if (this.model.actualCarbonIntensity === this.model.approvedCarbonIntensity)
    {
      return false;
    }
    else
    {
    return true;
    }
  }


  updateDocumentDetails(documentResult) {
    if (documentResult.length) {
      // this.documentResult1 = documentResult[0];
      // this.selectedDocuments = documentResult[1];
      this.finalDatafromPopUp = documentResult;
      const filteredData = documentResult.filter(item => item.selectFromPrevious === true)
      this.selectedDocuments = filteredData;
      this.outcome = documentResult;
    }
    // if (this.model && this.model.prevDocument) {
    //   const idsToRemove = new Set(this.model.prevDocument.map(doc => doc.id));
    //   this.documentsPage.content = this.documentsPage.content.filter(doc => !idsToRemove.has(doc.id));
    //   this.documentsPage = {
    //     ...this.documentsPage,
    //     content: [...this.documentsPage.content, ...this.model.prevDocument]
    //   };
    //   this.documentsPage = { ...this.documentsPage }
    //     this.outcome = this.model.prevDocument
    // }

  }

  reportstatus()
  {
    if(this.model && this.model.status)
    {
      if(this.model.status === null || this.model.status === "IN_PROCESS" || this.model.status === "PROPOSED")
        return false;
      else 
        return true;
    }
  }

  getDocumentConfig(arr): IApp.IDocumentUploadConfig {
    const entityName = 'CI_PATHWAY_REPORT';


    this.documentConfig = {
      id: this.model.id,
      documentType: true,
      entityName,
      title: 'REGISTRATION_PAGE.applicationCIPathway.',
      disablePaginator: false,
      comment: true,
      statusColumn: true,
      pendingSubmissionStatus: true,
      // entityVersionId: this.entityVersionId,
      documentUploadConfigs: arr.content.filter((item, index, self) => {
           return item.documentType.code !== 'OTHER' && 
               self.findIndex(i => i.documentType.code === item.documentType.code) === index;
      }).
      map(item=>({      
          id: this.model.id,
          documentTypeValue: item.documentType.id,
          documentTypeCode: item.documentType.code,
          entityName,
          noOfRequiredDocs: 1
      })),
      // documentUploadConfigs: [{
      //   id: this.model.id,
      //   documentTypeValue: 17,
      //   documentTypeCode: 'DIAGRAM_SHOWING_PROCESS_FLOW_TO_PRODUCE_FUEL',
      //   entityName,
      //   noOfRequiredDocs: 1
      // },
      // {
      //   id: this.model.id,
      //   documentTypeValue: 18,
      //   documentTypeCode: 'DESCRIPTION_OF_ANY_OUTPUT_OTHER_THAN_FUEL_PRODUCED',
      //   entityName,
      //   noOfRequiredDocs: 1
      // },
      // {
      //   id: this.model.id,
      //   documentTypeValue: 19,
      //   documentTypeCode: 'CIPATHWAY_COPY_LCA_FUEL_MODEL_INPUT_DATA_RESULTS',
      //   entityName,
      //   noOfRequiredDocs: 1
      // },
      // {
      //   id: this.model.id,
      //   documentTypeValue: 20,
      //   documentTypeCode: 'CIPATHWAY_MEASUREMENT_CALCULATION_LCA_MODEL_INPUT_DATA_SUPPORTING_DOC_METHODOLOGY',
      //   entityName,
      //   noOfRequiredDocs: 1
      // }
      // ],
      extraUploadDocuments: [],
      ...(this.service.currentData.resubmitFirstTime && {
        secondaryEntityId: this.service.currentData.parentId,
        secondaryEntityName: entityName
      })
    };
    return this.documentConfig;
  }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  public close(): void {
    if (this.lcaFormRight.touched || this.lcaForm1.touched) {
      const sub$ = this.service.openConfirmationModal('confirmOnClose').subscribe(result => {
        sub$.unsubscribe();
        if (result) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  public save() {
    let previouslyUploadedDocuments = [];
    const uniqueDocuments = new Set();
    this.formFactoryService.markFormGroupDirty(this.lcaForm1);
    this.formFactoryService.markFormGroupDirty(this.lcaFormRight);
    // if (this.documentResult1 && this.documentResult1.length)
    //   this.model.prevDocument = this.documentResult1;
    if (this.finalDatafromPopUp && this.finalDatafromPopUp.length)
      this.model.prevDocument = this.finalDatafromPopUp;
    if (this.selectedDocuments && this.selectedDocuments.length)
      this.model.selectedDocs = this.selectedDocuments;
    this.service.currentData = { ...this.service.currentData, prevDoc: this.documentResult1, selectedDocs: this.selectedDocuments };
    if (this.selectedDocuments && this.selectedDocuments.length) {
      for (let obj of this.selectedDocuments) {
        if (!uniqueDocuments.has(obj.id)) {
          uniqueDocuments.add(obj.id);
          previouslyUploadedDocuments.push({
            currentEntityId: this.model.id,
            previousEntityId: obj.entityId,
            documentId: obj.id
          });
        }
      }
      this.model.previouslyUploadedDocuments = previouslyUploadedDocuments;
    }
    if(this.model.feedStock && this.model.feedStock.name && (this.enableFieldForEditLca
       || this.model.feedStock.name!== this.model.ciApplicationDto.feedStock.name
      || this.model.feedStock.name !== this.feedStockFinalVal))
    {
    this.feedStockAr = this.feedStockAr.filter(item => item.id === this.model.feedStock.id);
    if(!this.feedStockAr.length)
      this.model.feedStock.name = this.feedStockArrayValue[0].name
    else
    this.model.feedStock.name = this.feedStockAr[0].name;
    }
    if (this.lcaForm1.valid && this.lcaFormRight.valid)
    {  
      const existingTypes = this.outcome
        .filter(doc => !doc._placeholder) 
        .map(doc => doc.documentType ? doc.documentType.code : doc.type);
      const requiredTypes = this.documentUploadConfig.documentUploadConfigs.map(doc => doc.documentTypeCode);
      const missingTypes = requiredTypes.filter(type => !existingTypes.includes(type));
      const missingDocuments = this.documentUploadConfig.documentUploadConfigs.filter(doc => missingTypes.includes(doc.documentTypeCode));
      if (missingDocuments.length) {
        this.checkForRequiredDocs(missingDocuments);
        return;
    }
      this.dialogRef.close(this.model);
    }
  }

  private checkForRequiredDocs(missingDocs: any) {
    let charCode = 97;
    let missingDocMsg = missingDocs.reduce((acc, doc, index) => {
      ++charCode;
      return index < missingDocs.length - 1
        ? acc + `${this.translateService.instant(`COMMON.documentList.${doc.documentTypeCode}`)}<br>${String.fromCharCode(charCode)}. `
        : acc + `${this.translateService.instant(`COMMON.documentList.${doc.documentTypeCode}`)}`;
    }, '');
    const metaData = [`${String.fromCharCode(97)}. ${missingDocMsg}`];
    this.modalService.open(
      ServiceMessageComponent,
      {
        messages: [
          {
            message: 'mandatoryInformationRequiredForUploadDocs',
            metaData,
          },
        ],
        type: SereviceMessageType.ERROR,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    );
  }

  public viewFacilityDetails(event) {
    event && event.preventDefault();
    const data = this.service.currentData.ciApplicationDto;
    let entityData = (data.applicationOrgEntities && data.applicationOrgEntities.length) && data.applicationOrgEntities[0].entity;
    if(!entityData.name || (entityData.name !== this.model.facilityName)){
      entityData = (data.applicationOrgEntities && data.applicationOrgEntities.length) && data.applicationOrgEntities[0];
    }
    this.modalService.open(ViewFacilityDetailsComponent, {
      facility: entityData,
      tooltip: CARApplyPrefix('step1_tooltip', '.tooltip')
    }, true).afterClosed();
  }

  configStaticDocumentsTable() {
   // documentsTableConfig.paginator = this.documentUploadConfig.disablePaginator ? false : documentsTableConfig.paginator;

    const { columns, ...config } = documentsTableConfig;
    const _columns = [
      {
        field: 'name',
        header: 'name',
        width: AUTO_WIDTH_FOR_COLUMNS,
        sortField: 'name',
      },
      //...documentsTableConfig.columns,
      {
        field: 'type',
        header: 'type',
        width: AUTO_WIDTH_FOR_COLUMNS,
        translate: true,
        translationPrefix: 'COMMON.documentList',
        sortField: 'type'
      },
      {
        field: 'comments',
        header: 'comments',
        width: AUTO_WIDTH_FOR_COLUMNS,
        sortField: 'comments',
      },
      {
        field: 'status',
        header: 'status',
        width: AUTO_WIDTH_FOR_COLUMNS,
        translationPrefix: 'COMMON.statusList',
        translate: true,
        sortField: 'status'
      },
      {
        field: 'lastModifiedDateStr',
        header: 'timestamp',
        width: AUTO_WIDTH_FOR_COLUMNS,
        sortField: 'lastModifiedDateStr',
      },
      {
        header: 'actions',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.actionTemplate,
      }
    ];

    

    this.tableConfig = {
      ...config,
      paginator: false,
      ...{ columns: _columns },
    };
  }

  private setTableActionItems() {
    this.tableActionItems = [
      {
        id: 'DOWNLOAD',
        name: this.translateService.instant('CARBON_INTENSITY_PATHWAY_REPORT.reportDetails.DOWNLOAD'),
      },
    ];
  }

  public async triggerTableAction(event, rowIndex, rowData) {
    setTimeout(() => {
      rowData.dummyModel = null;
    });
    if (!event) {
      return;
    }
    switch (event.id) {
      case 'DOWNLOAD':
          this.uploadDocumentService.download(rowData.id);

        }
  };

  enablingFields(event) {
    if (event.target.checked) {
      this.enableFieldForEditLca = true;
      if(this.model.status === null || this.model.status === "IN_PROCESS" || this.model.status === "PROPOSED"){
        this.lcaForm1.enable();
        this.lcaSection4[0].fieldGroup[1]['dataProvided'] = false;
        this.lcaSection4[0].fieldGroup[1]['source'] = SOURCE_DATA_TYPE.FEED_STOCK;
        this.lca4FieldListRight = this.formFactoryService.configureForm(this.lcaSection4); 
         this.lcaForm1.controls['feedStock'].setValue({id: this.model.feedStock.id})   
      }
      this.model.ciEdited = true;
      if (this.model.pathwayType === 'EXISTING')
        this.lcaForm1.get('pathwayIdList').disable();

    }
    else {
      this.enableFieldForEditLca = false;
      this.lcaForm1.disable();
      this.model.ciEdited = false;
      this.lcaForm1.controls['actualCarbonIntensity'].setValue(this.model.approvedCarbonIntensity);
      this.lcaForm1.controls['pathwayType'].setValue(this.model.ciApplicationDto.pathwayType);
      if(this.model.ciApplicationDto.pathwayId)
      this.lcaForm1.controls['pathwayIdList'].setValue([this.model.ciApplicationDto.pathwayId]);
      if(this.model.ciApplicationDto && this.model.ciApplicationDto.feedStock && this.model.ciApplicationDto.feedStock.id)
      {
       // const feedStockIdObj = { id: this.model.ciApplicationDto.feedStock.id }  
       this.lcaSection4[0].fieldGroup[1]['dataProvided'] = true;
       this.lcaSection4[0].fieldGroup[1]['items'] = this.feedStockArrayValue;
       this.lca4FieldListRight = this.formFactoryService.configureForm(this.lcaSection4);
       setTimeout(() => {
        this.lcaForm1.disable();
       }) 
      }


    }
  }

  fieldsEditable() {
    return this.enableFieldForEditLca;
  }

  alreadyUploadedDocs() {
    const entityType = 'APPLICATION';
    const metaData = [{
      "metaData": {"REPORT_TYPE":"CI_PATHWAY_REPORT"}
      }];

    const pagination = {
      page: 0,
      size: ROWS_PER_PAGE,
    } as IApp.IPagination;
    const entityId = this.model.ciApplicationDto.id
    this.uploadDocumentService.getAll(pagination, entityId, entityType, undefined, undefined, null, null, metaData ).
      subscribe(response => {
        this.staticDocumentsTableData = response.content;
        this.documentUploadConfig = this.getDocumentConfig(response);
      })
  }

  enableOrDisablePathwayList(data) {
    if (data.target.defaultValue === 'EXISTING')
    {
      this.lcaForm1.get('pathwayIdList').disable();
      this.lcaForm1.controls['pathwayIdList'].setValue(null);  
    }
    else
    {
      this.pathwayList$.next(this.model.pathwayList);
      this.lcaForm1.get('pathwayIdList').enable();
      if(this.model.ciApplicationDto.pathwayId)
      this.lcaForm1.controls['pathwayIdList'].setValue([this.model.ciApplicationDto.pathwayId]);
    }
  }



}
