import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import {FieldsetModule} from 'primeng/fieldset';
import {CalendarModule} from 'primeng/calendar';
import { FormlyFieldsetComponent } from './wrappers/formly-fieldset/formly-fieldset.component';
import { FormlyDatepickerComponent } from './types/formly-datepicker/formly-datepicker.component';
import { FormlyWrapperFormField } from './wrappers/form-field-wrapper/form-field-wrapper.component';
import { FormlyCustomValidationMessage } from '@shared/formly/formly-custom-types/formly-custom-validation-message.component';
import { DataCheckboxWrapperComponent } from './wrappers/data-checkbox-wrapper/data-checkbox-wrapper.component';
import { AddressViewModule } from '@shared/components/address-view/address-view.module';
import { TranslateModule } from '@ngx-translate/core';
import { SimpleBorderWrapperComponent } from './wrappers/simple-border-wrapper/simple-border-wrapper.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TooltipModule } from 'primeng';

@NgModule({
  declarations: [
    FormlyFieldsetComponent,
    FormlyDatepickerComponent,
    FormlyWrapperFormField,
    FormlyCustomValidationMessage,
    DataCheckboxWrapperComponent,
    SimpleBorderWrapperComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FieldsetModule,
    CalendarModule,
    FormlyBootstrapModule,
    FormsModule,
    FormlyModule.forRoot({
      wrappers: [
        {name: 'app-formly-fieldset', component: FormlyFieldsetComponent},
        {name: 'form-field', component: FormlyWrapperFormField},
        {name: 'data-checkbox', component: DataCheckboxWrapperComponent},
        {name: 'simple-border', component: SimpleBorderWrapperComponent}
      ],
      types: [
        {name: 'app-formly-datepicker', component: FormlyDatepickerComponent, wrappers: ['form-field']}
      ]
    }),
    AddressViewModule,
    TranslateModule,
    FontAwesomeModule,
    TooltipModule,
  ]
})
export class SharedFormlyModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faInfoCircle);
  }
}
