<div class="activity-inner">
  <div class="row border-bottom">
    <div class="col-12">
      <h2 class="m-0 pb-2"> {{'PROJECTS_MODULE.ProjectBuilder.projectApplicationForm'+projectBuilderService.projectType | translate}} </h2>
    </div>
  </div>
  <div class="row mt-3 mr-60" *ngIf="viewReady">
    <div class="col-3 border-right">
      <div class="inner-panel-1">
      <app-project-description></app-project-description>
    </div>
    </div>
    <div class="col-6 border-right">
      <div class="inner-panel-2">
        <app-project-activity-selector></app-project-activity-selector>
      </div>
    </div>
    <div class="col-3">
      <app-project-summary></app-project-summary>
    </div>
  </div>
</div>
<div class="row button-panel">
  <div class="button-background fixed-right mr-4">
    <button type="button" class="btn btn-secondary" (click)="back()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.back' | translate}}</button>

    <ng-container *ngIf="projectBuilderService.updateProject">
      <button type="button" class="btn ml-2 btn-primary"
        (click)="submit()"
        [disabled]="documentUploadInProgress.inProgress$ |async">
        {{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}
      </button>
    </ng-container>

    <ng-container *ngIf="!projectBuilderService.model.entityActionList; else ENTITY_ACTIONS">
      <ng-container *ngIf="!projectBuilderService.disabled">
        <button type="button" class="btn mx-2 btn-primary" (click)="saveAsDraft()"
          [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.saveProject' |
          translate}}</button>
        <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="editMode"
          [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.submitProject' | translate}}
        </button>
      </ng-container>
    </ng-container>

    <ng-template #ENTITY_ACTIONS>
      <ng-container *ngFor="let action of projectBuilderService.model.entityActionList">
        <button type="button" class="btn ml-2 btn-primary"
          (click)="saveAsDraft()"
          [disabled]="documentUploadInProgress.inProgress$ |async"
          *ngIf="action.workflowAction==='EDIT' || action.workflowAction==='EDIT_CUST'; else ACTIONS">
          {{'PROJECTS_MODULE.ProjectBuilder.saveProject' | translate}}
        </button>
        <ng-template #ACTIONS>
          <button type="button" class="btn btn-primary ml-2" (click)="triggerAction(action)" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.'+action.workflowAction | translate}}</button>
        </ng-template>
      </ng-container>
    </ng-template>
  </div>
</div>
