import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { StoreService } from '@core/store/store.service';
import { downloadBlob } from '@core/utilities/utilities.constants';
import { environment } from '@env/environment';
import {
  COMPLIANCE_REPORT_CURRENT_STEP_KEY,
  COMPLIANCE_REPORT_DATA_KEY,
  COMPLIANCE_REPORT_PREFIX,
  COMPLIANCE_REPORT_STEP_COMPONENTS,
  COMPLIANCE_REPORT_STEP_PREFIX,
  COMP_REP_CREDIT_CLASS,
  COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD,
  COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD,
} from '@module/compliance-report/compliance-report.const';
import {
  IComplianceReport,
  IComplianceReportGetAlphNumCI,
  ICreateComplianceReportPayload,
} from '@module/compliance-report/compliance-report.types';
import { CCR_APPROVED_CARBONINTENSITIES } from '@module/credit-creation-reports/services/credit-creation-reports.service';
import { BaseStepperService } from '@shared/components/base-stepper/base-stepper.service';
import { HelperService } from '@shared/services/helper.service';
import { ModalService } from '@shared/services/modal.service';
import { sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap, tap } from 'rxjs/operators';

export const CREATE_COMPLIANCE_REPORT = '/cfr-service/complianceReport/getNewComplianceReport';
export const GET_REPORT_BY_ID = '/cfr-service/complianceReport/getReportById/';
export const GET_REPORT_BY_ID_TO_RESUBMIT = '/cfr-service/complianceReport/getReportToResubmit/';
export const COMP_REP_FACILITIES_URI = '/cfr-service/complianceReport/getFacilities';
export const COMP_REP_SAVE_REPORT = '/cfr-service/complianceReport/saveComplianceReport';
export const COMP_REP_SAVE_AND_PROCEED = '/cfr-service/complianceReport/saveAndProceedComplianceReport';
export const COMP_REP_SUBMIT_REPORT = '/cfr-service/complianceReport/submitComplianceReport';
export const COMP_REP_PROPOSE_REPORT = '/cfr-service/complianceReport/proposeComplianceReport';
export const COMP_REP_RE_SUBMIT_REPORT = '/cfr-service/complianceReport/reSubmitComplianceReport';
export const GET_FACILITY_DETAILS = '/account-service/cfrAccount/account/getFacilityDetail/';
export const COMP_REP_FUEL_ENERGY_DENSITY_URI = '/account-service/energyDensity/getForFuelCode';
export const COMP_REP_FUEL_CI_LIMIT = '/cfr-service/complianceReport/getFuelCiLimit';
export const COMP_REP_SUMMARY = '/cfr-service/complianceReport/getReportSummary/';
export const COMP_REP_DOC_UPLOAD_ID = '/cfr-service/complianceReport/getDocumentIdForReference';
export const COMP_REP_CREDIT_BLOCKS = '/obps-service/unit/findAll';
export const COMP_REP_CARBON_INTENSITY_IDS_LOOKUP_URI = '/cfr-service/application/lookupComplianceReportCarbonIntensityIDs';
export const COMP_REP_CARBON_INTENSITY_IDS_SEARCH_URI = '/cfr-service/application/lookupCarbonIntensityIDs';
export const COMP_REP_UPDATE_STATUS = '/cfr-service/complianceReport/updateStatus';
export const COMP_REP_DISCARDS = '/cfr-service/complianceReport/discard';
export const COMPLIMENTARY_COMP_REP_DISCARDS = '/cfr-service/complimentryComplianceReport/discard';
export const COMP_REP_DOWNLOAD_REPORT = '/cfr-service/complianceReport/download/report/';
export const COMPLIMENTARY_REP_DOWNLOAD_REPORT = '/cfr-service/complimentryComplianceReport/download/report/';
export const COMP_REP_FUELS_FOR_SUBTYPE = '/cfr-service/complianceReport/getFuelsForSubType';
export const COMP_REP_REFERENCE_CARBON_INTENSITY_FOR_FUEL = '/cfr-service/creditCreationReport/getReferencedCarbonIntensityForFuel';
export const COMP_REP_CREDIT_TYPE_REPLACEMENT_URI = '/cfr-service/creditCreationReport/getCreditTypeForFuelType';

export const COMP_REP_INSUFFICIENT_CREDIT_SUBMISSION = '/cfr-service/complianceReport/insufficientCreditSubmission';
export const COMP_REP_CANCEL_EXCESS_CREDITS = '/cfr-service/complianceReport/cancelExcessCredits';
export const COMP_REP_SEND_NOTICE = '/cfr-service/complianceReport/sendNoticeForCreditUpdate';

export const COMP_REP_GET_ACTIVE_CREDITS = '/obps-service/findActiveCredits';
export const COMP_GET_TOTAL_ACTIVE_CREDITS = '/cfr-service/lookup/getAvailiableCredits';
@Injectable({
  providedIn: 'root',
})
export class ComplianceReportService extends BaseStepperService {

  public translationPrefix = `${ COMPLIANCE_REPORT_PREFIX }${ COMPLIANCE_REPORT_STEP_PREFIX }`;
  public registrationReportsDetail: any;
  public report: IComplianceReport;

  private _refreshData$ = new Subject<any>();
  public refreshData$ = this._refreshData$.asObservable();

  public updateSummary$ = new Subject();

  stepRoutineMap: any = {};

  public currentStepInvalid = false;

  public gasolineCILimit;
  public dieselCILimit;

  public totalActiveCredts = 0;
  public totalGenericCredits = 0;
  public totalGaseousCredits = 0;
  private totalLiquidCredits = 0;
  public creditsTypeCount = [
    {
      code: 'GQM',
      dataId: this.totalGenericCredits
    },
    {
      code: 'GASCC',
      dataId: this.totalGaseousCredits
    },
    {
      code: 'LIQUID',
      dataId: this.totalLiquidCredits
    }
  ];

  constructor(
    private http: HttpClient,
    private modalService: ModalService,
    private router: Router,
    private helperService: HelperService,
    private store: StoreService,
  ) {
    super();
  }

  createComplianceReport(payload: ICreateComplianceReportPayload): any {
    return this.http.post<any>(`${ environment.apiUrl }${ CREATE_COMPLIANCE_REPORT }`, payload);
  }

  getReportById(reportId) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID }${ reportId }`);
  }

  getReportByIdToReSubmit(reportId) {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_REPORT_BY_ID_TO_RESUBMIT }${ reportId }`);
  }

  storeCurrentData(): void {
    const data = JSON.stringify({...this.currentData});
    sessionStorage.setItem(COMPLIANCE_REPORT_DATA_KEY, data);
  }

  restoreCurrentData(skipRemove = false): any {
    const data = sessionStorage.getItem(COMPLIANCE_REPORT_DATA_KEY);
    if (data) {
      !skipRemove && sessionStorage.removeItem(COMPLIANCE_REPORT_DATA_KEY);
      return JSON.parse(data);
    } else {
      return null;
    }
  }

  restoreLastStep(): any {
      const currentStep = sessionStorage.getItem(COMPLIANCE_REPORT_CURRENT_STEP_KEY);
      if (currentStep) {
        sessionStorage.removeItem(COMPLIANCE_REPORT_CURRENT_STEP_KEY);
        return JSON.parse(currentStep);
      } else {
        return null;
      }
  }

  saveReport(payload): Observable<any>  {
    payload.credits = this.creditsTypeCount;
    return this.http.post(`${ environment.apiUrl }${ COMP_REP_SAVE_REPORT }`, {...payload, action: 'SAVE'})
      .pipe(tap(response => this.report = response.entity));
  }

  saveAndProceed(payload): Observable<any>  {
    payload.credits = this.creditsTypeCount;
    return this.http.post(`${ environment.apiUrl }${ COMP_REP_SAVE_AND_PROCEED }`, {...payload, action: 'SAVE'});
  }

  submitReport(payload, propose = false): Observable<any> {
    payload.credits = this.creditsTypeCount;
    const uri = propose ? COMP_REP_PROPOSE_REPORT : COMP_REP_SUBMIT_REPORT;
    return this.http.post(`${ environment.apiUrl }${ uri }`, {...payload, action: 'SUBMIT'});
  }

  reSubmitReport(payload, propose = false): Observable<any> {
    payload.credits = this.creditsTypeCount;
    const uri = propose ? COMP_REP_PROPOSE_REPORT : COMP_REP_RE_SUBMIT_REPORT;
    return this.http.post(`${ environment.apiUrl }${ uri }`, {...payload, action: 'RESUBMIT'});
  }

  getFacilityDetails(facilityId): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ GET_FACILITY_DETAILS }${facilityId}`);
  }

  private getStepContent(step, index, subStepIndex = null) {
    let component =  COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_STEP;
    if (step.code === 'STEP-6') {
      component = COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_EXPORT_LOWCI_FUELS;
    } else if (step.code === 'STEP-8') {
      component = COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_DOCUMENTS;
    } else if (step.code === 'STEP-9') {
      component = COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_CONTACT_PERSON;
    } else if (step.code === 'STEP-10') {
      component = COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_SUMMARY;
    } else if (step.code === 'STEP-11') {
      component = COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_DEFERRALS;
    }
    const _step = {
        ...step,
        name: `.${ step.code }_title`,
        completed: false,
        id: index + 1,
        component,
      };
    _step.id = index + 1;
    if (subStepIndex !== null) {
      _step.subId = subStepIndex + 1;
    }
    return _step;
  }

  private getSubStepContent(step, index, subStepIndex = null) {
    const component =  COMPLIANCE_REPORT_STEP_COMPONENTS.COMPLIANCE_REPORT_STEP;
    const _step = {
      ...step,
      name: `.${step.code}_title`,
      stepType: `${ step.associatedActivityStepCode }_${ step.fuelCodeList }`,
      completed: false,
      id: index + 1,
      component,
    };
    _step.id = index + 1;
    if (subStepIndex !== null) {
      _step.subId = subStepIndex + 1;
    }
    return _step;
  }

  public setReportSteps() {
    const steps = this.currentData.associatedSteps.map((step, index) => {

      const currentStepIdentifier = step.code.split('-')[1].trim();

      const subSteps = [];
      if (step.internalSteps && step.internalSteps.length) {
        sortBy(step.internalSteps, ['sequence']).map((subStep, j) => {
          subSteps.push(this.getSubStepContent(subStep, index, j));
        });
      }
      const _step = this.getStepContent(step, index);
      if (subSteps.length) {
        _step.subSteps = subSteps;
        _step.skipMainStep = true;
        _step.enabledSubSteps = true;
      }
      return _step;
    });

    this.steps = [...steps];
    return [...steps];
  }

  public getStepName(val): string {
    const arr = val.split(': ');
    arr.shift();
    return arr.join(': ');
  }

  getFacilities(payload: any) {
    return this.http.post(`${ environment.apiUrl }${ COMP_REP_FACILITIES_URI }`, payload);
  }

  getFuelEnergyDensity(fuelCode: string): Observable<IApp.ILookup[]> {
    return this.http.get<IApp.ILookup[]>(`${ environment.apiUrl }${ COMP_REP_FUEL_ENERGY_DENSITY_URI }/${ fuelCode }`);
  }

  getFuelCiLimit(complianceYear: number, fuelCode: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ COMP_REP_FUEL_CI_LIMIT }/${ complianceYear }/${ fuelCode }`);
  }

  public openConfirmationModal(msg, metaDataList?) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: msg,
          type: SereviceMessageType.WARNING,
          metaDataList,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public showErrorMessage(message: string, metaDataList = []) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            { message },
          ],
          message,
          metaDataList,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed();
  }

  public getReportSummary(id) {
    return this.http.get<any>(`${environment.apiUrl}${COMP_REP_SUMMARY}${id}`);
  }

  public getDocUploadID() {
    return this.http.get<any>(`${environment.apiUrl}${COMP_REP_DOC_UPLOAD_ID}`);
  }

  public getCreditBlocks(pagination: IApp.IPagination, complianceYear?, accountId = this.currentData.accountId): Observable<any> {

    const unitStatusList = ['ACTIVE'];
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    const filterState = {
      freeText: null, unitStatusList,
      ccr: true,
      beforeComplianceYear: true,
      compliancePeriodList:[this.currentData.complianceYear],
      issuanceYear: this.currentData.complianceYear,
      facilityNameList: [accountId]
     };
    if (complianceYear) {
      filterState.compliancePeriodList = [complianceYear];
      filterState.issuanceYear = complianceYear;
    } else {
      filterState.compliancePeriodList = [this.currentData.complianceYear];
      filterState.issuanceYear = this.currentData.complianceYear;
    }
    //const filterState = {freeText: null, unitStatusList: []};
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_CREDIT_BLOCKS }`, filterState, { params });
  }

  getExtraTemplates(path: string): Observable<any> {
    return this.http.get(path, { responseType: 'text' });
  }

  getAlphanumericIDofCI(payload: IComplianceReportGetAlphNumCI, search = false): Observable<IApp.ILookup[]> {
    const uri = search ? COMP_REP_CARBON_INTENSITY_IDS_SEARCH_URI : COMP_REP_CARBON_INTENSITY_IDS_LOOKUP_URI;
    return this.http.post<IApp.ILookup[]>(`${ environment.apiUrl }${ uri }`, payload);
  }

  getReferenceCarbonIntensityForFuel(year: number, fuelId: number): Observable<number> {
    return this.http.get(`${ environment.apiUrl }${ COMP_REP_REFERENCE_CARBON_INTENSITY_FOR_FUEL }/${ year }/${ fuelId }`)
      .pipe(map((response: IApp.ILookup) => Number(response.value)));
  }

  triggerAction(metadata: any, redirect = false) {
    const action = metadata.action;
    const payload = metadata.data;

    if (['VIEW', 'EDIT', 'SUBMIT_TO_ECCC_VERIFICATION_REPORT', 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT'].includes(action.workflowAction) && payload.type === 'COMPLIANCE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/compliance-report/report/${ payload.id }`));
    } else if (['VIEW', 'EDIT', 'SUBMIT_TO_ECCC_VERIFICATION_REPORT', 'RESUBMIT_TO_ECCC_VERIFICATION_REPORT'].includes(action.workflowAction) && payload.type === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/complimentary-compliance-report/report/${ payload.id }`));
    } else if (action.workflowAction === 'RESUBMIT' && payload.type === 'COMPLIANCE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/compliance-report/report/${ payload.id }/RESUBMIT`));
    } else if (action.workflowAction === 'RESUBMIT' && payload.type === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/complimentary-compliance-report/report/${ payload.id }/RESUBMIT`));
    } else if (action.workflowAction === 'DOWNLOAD_COMPLIANCE_REPORT') {
      this.downloadDocument(payload.id);
    } else if (action.workflowAction === 'DOWNLOAD_COMPLIMENTRY_COMPLIANCE_REPORT') {
      this.downloadCompDocument(payload.id);
    } else if (action.workflowAction === 'VIEW_VERIFICATION_DETAILS') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/${payload.id}`));
    } else if (action.workflowAction === 'VIEW_VERIFICATION_REPORT') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/report-summary/report-submission/${payload.verificationReportId}`));
    } else {
      this.executeAction(payload, action, redirect);
    }
  }

  public updateComplianceReport(payload: any): Observable<IComplianceReport> {
    let uri = payload.action === 'DISCARD' ? COMP_REP_DISCARDS : COMP_REP_UPDATE_STATUS;
    if (payload.action === 'DISCARD' && payload.type === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
       uri = COMPLIMENTARY_COMP_REP_DISCARDS;
    }
    return this.http.post<any>(`${environment.apiUrl}${uri}`, payload);
  }

  executeAction(payload , action, redirect) {
    const request = {id: payload.id, action: action.workflowAction, type: payload.type};
    const actionLabel = action.workflowAction;

    if (action.workflowAction === 'ASSIGN_VB') {
      this.router.navigate(this.helperService.getTranslatedPath(`/verification/manage-verification/select-verification-body/ASSIGN_VB/${payload.verificationBodyId}`));
      return;
    }
    let message = 'confirmationMessageComplianceReport';
    if (payload.type === 'COMPLIMENTRY_COMPLIANCE_REPORT') {
      message = 'confirmationMessageComplimentaryComplianceReport';
    }
    const sub$ = this.modalService.open(ServiceMessageComponent,
      {
        messages: null,
        message: message,
        metaDataList: [this.translateService.instant(`COMMON.actionsLabel.${actionLabel}`).toLowerCase(),
          payload.legalName,
        ],
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      sub$.unsubscribe();
      if (result) {
        this.modalService.closeAllMessageDialogs();
        if (action.workflowAction === 'SUBMIT') {
          this.getReportById(payload.id)
            .pipe(switchMap((report: IComplianceReport) => this.submitReport(report)))
            .subscribe(() => this._refreshData$.next(true));
        } else {
          this.updateComplianceReport(request).subscribe(response => {
          if (!redirect) {
            this._refreshData$.next(true);
          } else {
            this.resetStepper();
            this.store.back();
          }
        });
      }
    }});
  }

  public downloadDocument(documentId) {
    const uri = environment.serviceUrl + COMP_REP_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
        (response: any) => downloadBlob(response),
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  public downloadCompDocument(documentId) {
    const uri = environment.serviceUrl + COMPLIMENTARY_REP_DOWNLOAD_REPORT + documentId;
    this.http.get(uri, {observe: 'response', responseType: 'blob' as 'json'})
      .subscribe(
        (response: any) => downloadBlob(response),
        () => this.http.get(uri, {observe: 'response'}).subscribe(),
      );
  }

  getFuelsForSubType(subType: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ COMP_REP_FUELS_FOR_SUBTYPE }/${ subType }`);
  }

  public submitInsufficientCredits(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_INSUFFICIENT_CREDIT_SUBMISSION }`, payload);
  }

  public cancelExcessCredits(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_CANCEL_EXCESS_CREDITS }`, payload);
  }

  getActiveCredits(accountId): Observable<any> {
    const payload = {freeText: null, unitStatusList: ['ACTIVE'], ccr: true, beforeComplianceYear: true, compliancePeriodList:[this.currentData.complianceYear], issuanceYear: this.currentData.complianceYear};
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_GET_ACTIVE_CREDITS }/${ accountId }`, payload);
  }
  getCreditType(type: string): Observable<IApp.ILookup> {
    return this.http.get<IApp.ILookup>(`${ environment.apiUrl }${ COMP_REP_CREDIT_TYPE_REPLACEMENT_URI }/${ type }`);
  }

  get docEntityType(): IApp.NSDocument.EntityTypeEnum {
    return `COMPLIANCE_REPORT_${ this.currentStep.code.replace('-', '_') }` as IApp.NSDocument.EntityTypeEnum;
  }

  public sendNotice(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ COMP_REP_SEND_NOTICE }`, payload);
  }

  public getApprovedCarbonIntensities(complianceYear, fuelId) {
    let uri = `${CCR_APPROVED_CARBONINTENSITIES}${complianceYear}/${fuelId}/12`;

    return this.http.get(`${ environment.apiUrl }${uri}`);
  }

  getTotalActiveCreditsInfo(accountId, complianceYear) {
    return this.http.get<any>(`${environment.apiUrl}${COMP_GET_TOTAL_ACTIVE_CREDITS}/${accountId}/${complianceYear}/ACTIVE`);
  }

  private getTotalActiveCredits(accountId, complianceYear) {
    this.getTotalActiveCreditsInfo(accountId, complianceYear).subscribe(result => {
      if (result && result.length) {
        this.totalActiveCredts = result.reduce((acc,unit) => acc+unit.id,0);
        const genericUnits = result.filter((unit) => unit.creditGroup && unit.creditGroup.code === 'GQM');
        this.totalGenericCredits = genericUnits.reduce((acc,unit) => acc+unit.id,0);
        const gaseousCredits = result.filter((unit) => unit.creditType && unit.creditType.code === COMP_REP_CREDIT_CLASS.GASCC);
        this.totalGaseousCredits = gaseousCredits.reduce((acc,unit) => acc+unit.id,0);
        const liquidUnits = result.filter((unit) => unit.creditType && unit.creditType.code === 'LIQCC');
        this.totalLiquidCredits = liquidUnits.reduce((acc,unit) => acc+unit.id,0);
        this.creditsTypeCount[0].dataId= this.totalGenericCredits;
        this.creditsTypeCount[1].dataId = this.totalGaseousCredits;
        this.creditsTypeCount[2].dataId = this.totalLiquidCredits;
      }
    });
  }

  calculateTenPercentLimitonTotalRequirement(deferrals) {
    let tenPercentLimit = 0;
    let deferralsTotalReductionReq = 0;
    deferrals.forEach((deferral) => {
      if (deferral.outstandingGasolineReductionRequirement) {
        deferralsTotalReductionReq += deferral.outstandingGasolineReductionRequirement;
      }
      if (deferral.outstandingDieselReductionRequirement) {
        deferralsTotalReductionReq += deferral.outstandingDieselReductionRequirement;
      }
    })
    const totalReductionRequirementOverride = this.currentData[COMP_REP_TOTAL_OVERRIDE_CALCULATED_REDUCTION_REQUIREMENT_VALUE_FLD];
    if (!totalReductionRequirementOverride) {
      tenPercentLimit = Math.round((this.currentData[COMP_REP_TOTAL_CALCULATED_REDUCTION_REQUIREMENT_FLD] + deferralsTotalReductionReq) * 0.1);
    } else {
      tenPercentLimit = Math.round((this.currentData.totalReductionRequirement + deferralsTotalReductionReq) * 0.1);
    }
    return tenPercentLimit;
  }

  resetCredits () {
    this.totalActiveCredts = 0;
    this.totalGenericCredits = 0;
    this.totalGaseousCredits = 0;
  }

}
