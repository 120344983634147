<p-dialog
  [className]="'eccc'"
  [closable]="false"
  [header]="inputData.title | translate"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '800px'}"
  [minY]="500"
  [maximizable]="false"
  [autoZIndex]="false">

  <div class="container">
    <app-table-view
      [data]="inputData.summary"
      [config]="tableConfig"
    >
    </app-table-view>
  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button type="button" class="btn btn-secondary" (click)="close()">
          {{'COMMON.actionsLabel.CLOSE' | translate}}
        </button>
        <button type="button" class="btn btn-primary" (click)="save()">
          {{'COMMON.actionsLabel.SAVE' | translate}}
        </button>
      </div>
    </div>
  </p-footer>
</p-dialog>
