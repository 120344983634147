/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sign-agreement.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/forms";
import * as i5 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i6 from "@ngx-formly/core";
import * as i7 from "./sign-agreement.component";
import * as i8 from "../../../../shared/services/form-factory.service";
import * as i9 from "../../../../shared/services/form.service";
import * as i10 from "../../agreement-credits.service";
var styles_SignAgreementComponent = [i0.styles];
var RenderType_SignAgreementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SignAgreementComponent, data: {} });
export { RenderType_SignAgreementComponent as RenderType_SignAgreementComponent };
export function View_SignAgreementComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["id", "sign-agreement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "container mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, [[1, 0], ["ngForm", 1]], null, 7, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i4.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(11, 540672, null, 0, i4.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.ControlContainer, null, [i4.FormGroupDirective]), i1.ɵdid(13, 16384, null, 0, i4.NgControlStatusGroup, [[4, i4.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "formly-form", [], null, null, null, i5.View_FormlyForm_0, i5.RenderType_FormlyForm)), i1.ɵprd(512, null, i6.FormlyFormBuilder, i6.FormlyFormBuilder, [i6.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(16, 966656, null, 0, i6.FormlyForm, [i6.FormlyFormBuilder, i6.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i4.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.form; _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.form; var currVal_11 = _co.model; var currVal_12 = _co.fields; _ck(_v, 16, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("AGREEMENT_CREDITS.Step_6." + _co.suffix))); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("AGREEMENT_CREDITS.Step_6.message")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 13).ngClassValid; var currVal_7 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_SignAgreementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sign-agreement", [], null, null, null, View_SignAgreementComponent_0, RenderType_SignAgreementComponent)), i1.ɵdid(1, 4440064, null, 0, i7.SignAgreementComponent, [i3.TranslateService, i8.FormFactoryService, i9.FormService, i10.AgreementCreditsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignAgreementComponentNgFactory = i1.ɵccf("app-sign-agreement", i7.SignAgreementComponent, View_SignAgreementComponent_Host_0, {}, {}, []);
export { SignAgreementComponentNgFactory as SignAgreementComponentNgFactory };
