<div class="form-group w-300 mr-2" *ngIf="show && facilityNames?.length > 2">
  <label for="facility-account-chooser">{{ 'COMMON.facilityAccountChooser.selectAccountLabel' | translate }}</label>
  <ng-select
    #facilityNameNgSelect
    [labelForId]="'facility-account-chooser'"
    [placeholder]="'ACCOUNTS_MODULE.dashboard.facilityNameText' | translate"
    [inputAttrs]="{'aria-label': 'ACCOUNTS_MODULE.dashboard.facilityNameText' | translate}"
    class="ng-dropdown-left"
    [items]="facilityNames"
    [clearable]="false"
    bindLabel="name"
    bindValue="id"
    [selectableGroup]="true"
    [multiple]="true"
    [markFirst]="true"
    [(ngModel)]="selectedItems"
    [virtualScroll]="true"
    (add)="onAdd($event)"
    (remove)="onRemove($event)">

    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <ng-container *ngIf="items.length === 1; else MULTIPLE_SELECTED">
        <div class="ng-value">
          <ng-container *ngIf="items[0].id === -1; else OTHER_SELECTION">
            <span class="ng-value-label">{{ 'COMMON.facilityAccountChooser.allAccountsText' | translate }}</span>
          </ng-container>
          <ng-template #OTHER_SELECTION>
            <span class="ng-value-label">{{items[0].name}}</span>
            <span class="ng-value-icon right" (click)="clear(items[0])" aria-hidden="true">×</span>
          </ng-template>
        </div>
      </ng-container>
      <ng-template #MULTIPLE_SELECTED>
        <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label"
                  [pTooltip]="getSelectedAccountNames(items)"
                  tooltipPosition="bottom">{{ items.length }} {{ 'COMMON.facilityAccountChooser.accountsSelectedText' | translate }}...</span>
        </div>
      </ng-template>
    </ng-template>

    <ng-template ng-optgroup-tmp let-item="item">
      <div class="ng-option-label" title="{{item?.name}}">{{item?.name}}</div>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      {{ item.name }}
    </ng-template>

  </ng-select>
</div>
