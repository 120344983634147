import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { AppInjector } from '@shared/services/app-injector.service';
import { DocumentUploadInProgressService } from '@shared/services/document-in-progress.service';
import { Observable } from 'rxjs';

@Injectable()
export class UserDetailsCanDeactivateGuard<T> implements CanDeactivate<T> {

  documentUploadInProgress: DocumentUploadInProgressService;
  AAG_INFO_VISIBLE = 'AAG_info_visible';

  constructor(
  ) {
    const injector = AppInjector.getInjector();
    this.documentUploadInProgress = injector.get(DocumentUploadInProgressService);
  }

  canDeactivate(component: T): Observable<boolean> | boolean {
    this.enableAAGInfo();
    return !this.documentUploadInProgress.isInProgress(true);
  }

  enableAAGInfo() {
    sessionStorage.setItem(this.AAG_INFO_VISIBLE, 'true');
  }
}
