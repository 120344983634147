<div id="omp-account-types">

    <h1>{{ 'REGISTRATION_PAGE.accountTypeSection.ompAccountType' | translate }}</h1>

    <div class="d-md-flex mt-2">
        <div class="card mb-2 ">
            <div class="card-body">
                <div class="p-grid mt-5 mb-5 ">
                    <div class="p-col-12 mt-3" *ngFor="let accountType of accoutTypes" >
                      <fieldset>
                        <legend class="wcag-visuallyhidden">{{ 'REGISTRATION_PAGE.accountTypeSection.ompAccountType' | translate }}</legend>
                          <p-radioButton
                                *ngIf="hideOption(accountType.id)"
                                name="group1"
                                [value]="accountType"
                                label="{{ 'REGISTRATION_PAGE.accountTypeSection.' + accountType.name | translate }}"
                                [(ngModel)]="selectionAccountType"
                                wcag-label
                            ></p-radioButton>
                      </fieldset>
                    </div>
                </div>
                <div class="p-grid mb-5 ">
                    <p-fieldset
                      *ngIf="selectionAccountType"
                      legend="{{ 'REGISTRATION_PAGE.accountTypeSection.purposeOfAccount' | translate }}">
                      <app-blurb [message]="'REGISTRATION_PAGE.accountTypeSection.accountOpeningReason'"></app-blurb>
                        <div class="p-grid">
                            <div class="p-col-12 m-3" *ngFor="let type of registrationType" >
                              <fieldset>
                                <legend class="wcag-visuallyhidden">{{ 'REGISTRATION_PAGE.accountTypeSection.purposeOfAccount' | translate }}</legend>
                                  <p-radioButton
                                      name="group2"
                                      [value]=" type "
                                      label="{{ 'REGISTRATION_PAGE.accountTypeSection.'+type.name | translate }}"
                                      [(ngModel)]="selectionAccountRegistraionType"
                                      wcag-label
                                  ></p-radioButton>
                              </fieldset>
                            </div>
                        </div>
                    </p-fieldset>
                </div>
            </div>
            <div class="card-footer">
                <button *ngIf="!isSubaccount && showBackButton" class="btn btn-secondary mr-2" (click)="backStep()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>
                <button  id="nfmp-stp2-btn"  class="btn btn-primary" (click)="nextStep()"  [disabled]="onlyIfNoselection()">{{ 'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
            </div>
        </div>
    </div>

</div>
