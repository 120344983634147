import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { chunk, orderBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_PAGE_SIZE = 20;

@Injectable()
export class LocalPaginationService {

  private _data = new BehaviorSubject<any>([]);
  private _dataChunks = [];
  private _pagination = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
  } as IApp.IPagination;

  constructor() { }

  set data(val: any) {
    this._data.next(val);
    this.getChunks();
  }

  get data(): any {
    return this._data.getValue();
  }

  private getChunks(data: any = null): void {
    this._dataChunks = chunk(data || this._data.getValue(), this.pagination.size);
  }

  private get pagination(): IApp.IPagination {
    return {
      ...this._pagination,
      size: this.pageSize,
    };
  }

  private get totalElements(): number {
    return this._data.getValue().length;
  }

  private get totalPages(): number {
    return this._dataChunks.length;
  }

  private get isLast(): boolean {
    return this._pagination.page === this._dataChunks.length - 1;
  }

  private get isFirst(): boolean {
    return this._pagination.page === 0;
  }

  private getDataPage(page = null): any {
    const _page = page || this.pagination.page;
    return this._dataChunks[_page] || [];
  }

  private sort(pagination: IApp.IPagination): void {
    if (!pagination) {
      return;
    }
    if (pagination.sort !== this.pagination.sort || pagination.dir !== this.pagination.dir) {
      const sorted = orderBy(this._data.getValue(), [pagination.sort], [pagination.dir.toLowerCase() || 'asc']);
      this.data = sorted;
      this.getChunks(sorted);
      pagination.page = 0;
    }
    this._pagination = pagination;
    this.getChunks();
  }

  private get pageSize(): number {
    return this._pagination.size || DEFAULT_PAGE_SIZE;
  }

  public getLastPage(): IApp.IPageableData {
    return this.getPage({...this.pagination, page: this.totalPages - 1 });
  }

  public get lastPageFirstItemIndex(): any {
    return (this.totalPages - 1) * this.pageSize;
  }

  public getPage(pagination: IApp.IPagination = null): IApp.IPageableData {
    this.sort(pagination);
    const content =  this.getDataPage();
    return {
      content,
      totalElements: this.totalElements,
      totalPages: this.totalPages,
      last: this.isLast,
      first: this.isFirst,
      size: this.pageSize,
      number: this.pageSize,
      numberOfElements: content.length,
    };
  }
}
