<ng-container *ngIf="useLayout; else PUBLIC_VIEW_LAYOUT">
  <div class="inner">
  <div class="wizard-inner">
  <div class="stepper-section">
    <app-progress-bar
      *ngIf="progressBar"
      [steps]="progressBar"
      [current]="currentStep"
      (changeStep)="goToStep($event)">
    </app-progress-bar>
  </div>
  <div class="content-section">
    <div class="scrollable-area" #formContainer>
      <div class="content">
        <app-step-title *ngIf="applicationData" [data]="applicationData" [type]="applicationType"></app-step-title>
        <ng-template appStepAnchor></ng-template>
      </div>
    </div>
    <div class="footer">
      <button *ngIf="isLastStep" type="button" class="btn btn-primary ml-2 float-right" (click)="submit()">
        {{ 'COMMON.wizard.' +submitButtonLabel  | translate }}
      </button>
      <button *ngIf="showBeforeNextButton && !isLastStep" type="button" class="btn btn-primary ml-2 float-right" (click)="beforeNextStep()">{{ 'REGISTRATION_PAGE.nfmpAccountForm.nextStepButton' | translate }}</button>
      <button *ngIf="showNextButton && !isLastStep" type="button" class="btn btn-secondary ml-2 float-right" (click)="nextStep()">{{ 'REGISTRATION_PAGE.nfmpAccountForm.nextStepButton' | translate }}</button>
      <button *ngIf="showBackButton" type="button" class="btn btn-secondary ml-2 float-right" (click)="back()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>
      <button *ngIf="showCloseButton" type="button" class="btn btn-secondary ml-2 float-right" (click)="saveAsDraft()">{{'COMMON.wizard.SaveasDraft' | translate}}</button>
      <button *ngIf="showSaveAsDraftButton" type="button" class="btn btn-secondary ml-2 float-right" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
    </div>
  </div>
  </div>
  </div>


</ng-container>


<ng-template #PUBLIC_VIEW_LAYOUT>
  <div class="d-md-flex justify-content-center pt-5 align-content-stretch">

      <div class="col-sm-12 col-md-3 col-xl-2 mb-5">
          <app-progress-bar *ngIf="progressBar"
            [steps]="progressBar"
            [current]="currentStep"
            (changeStep)="goToStep($event)">
          </app-progress-bar>
      </div>

      <div class="col-sm-12 col-md-9 col-xl-8 mb-5 d-inline-block">

          <h1 *ngIf="page_title" [class.wcag-visuallyhidden]="!page_title || !step_title">{{ step_title ? (step_title | translate) : 'Registration' }}</h1>
          <h2 class="mt-4 mb-4" [class.wcag-visuallyhidden]="!page_sub_title"
          [innerHTML]= "page_sub_title ? (page_sub_title | translate) : 'Registration step'"
          >{{ page_sub_title ? (page_sub_title | translate) : 'Registration step' }}:</h2>


          <div class="d-md-flex justify-content-center mt-2">
              <div class="card mb-2 mx-auto w-100 card-override">
                  <div class="card-body">
                    <ng-template appStepAnchor></ng-template>
                  </div>

                  <div class="card-footer">
                      <button *ngIf="showCloseButton" type="button" class="btn btn-secondary mr-2" (click)="saveAsDraft()">{{'COMMON.wizard.SaveasDraft' | translate}}</button>
                      <button *ngIf="showBackButton" type="button" class="btn btn-secondary mr-2" (click)="back()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>
                      <button *ngIf="showNextButton" type="button" class="btn btn-secondary mr-2" (click)="nextStep()">{{ 'REGISTRATION_PAGE.nfmpAccountForm.nextStepButton' | translate }}</button>
                      <button *ngIf="showBeforeNextButton" type="button" class="btn btn-primary mr-2" (click)="beforeNextStep()">{{ 'REGISTRATION_PAGE.nfmpAccountForm.nextStepButton' | translate }}</button>
                      <button *ngIf="showSaveAsDraftButton" type="button" class="btn btn-secondary mr-2" (click)="close()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
                  </div>

              </div>
          </div>

      </div>
  </div>
</ng-template>
