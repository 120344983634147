<ng-container *ngIf="!isOffsetPublicPage">
  <p>{{ 'APP_FOOTER.footerSection.dateRevised' | translate}}: <strong>{{ revisedDate | async }}</strong></p>
</ng-container>
<p>
  <a href="#" *ngIf="isLoggedIn" (click)="contactUs($event)" wcag-dummy-link-target>{{ 'APP_FOOTER.footerSection.reportProblemLink' | translate}}</a>
  <a href="#" *ngIf="isLoggedIn" (click)="openTnC($event)" wcag-dummy-link-target class="ml-4">{{ 'APP_FOOTER.footerSection.terms' | translate}}</a>
  <a href="#" *ngIf="isLoggedIn" (click)="openPrivacy($event)" wcag-dummy-link-target class="ml-4">{{ 'APP_FOOTER.footerSection.privacy' | translate}}</a>
  <a href="#" *ngIf="!isLoggedIn && registrationType" (click)="openRegistrationPrivacy($event)" wcag-dummy-link-target class="ml-4">{{ 'APP_FOOTER.footerSection.privacy' | translate}}</a>
  <a href="#" *ngIf="publicPage" (click)="openPublicPagePrivacy($event)" wcag-dummy-link-target class="ml-4">{{ 'APP_FOOTER.footerSection.privacy' | translate}}</a>
  <a href="#" *ngIf="publicPage" (click)="openPublicPageDisclaimer($event)" wcag-dummy-link-target class="ml-4">{{ 'APP_FOOTER.footerSection.disclaimer' | translate}}</a>
  <a href="#" (click)="openPopup($event, !isOffsetPublicPage ? 'aboutCATSTitle' : 'supportedBrowsersTitle', 'supportedBrowsers')" wcag-dummy-link-target class="ml-4">
    <ng-container *ngIf="!isOffsetPublicPage; else OFFSET_PUBLIC_PAGE">{{ 'APP_FOOTER.footerSection.aboutCATSLink' | translate}}</ng-container>
    <ng-template #OFFSET_PUBLIC_PAGE>{{ 'APP_FOOTER.footerSection.supportedBrowsersLink' | translate }}</ng-template>
  </a>

</p>
<div class="ca-logo"></div>
