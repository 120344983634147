import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';
import { TermsConditionsComponent } from '@core/components/terms-conditions/terms-conditions.component';
import { MODAL_TYPES } from '@core/constants/modals.const';
import { IUserData } from '@core/models/user.model';
import { LoaderService } from '@core/services/loader.service';
import { StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@shared/services/modal.service';
import { Dialog } from 'primeng';
import { DomHandler } from 'primeng/dom';
import { filter } from 'rxjs/operators';
import { LOCALES } from './app.const';
import { camelCase } from 'lodash';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  title = 'eccc-app-ui';
  public currentUser: any;

  constructor(
    private router: Router,
    private store: StoreService,
    private modalService: ModalService,
    private translate: TranslateService,
    private titleService: Title,
    private loaderService: LoaderService,
    private navigation: NavigationService,
  ) {
    translate.addLangs(LOCALES);

    this.store.user$.subscribe(
      currentUser =>  {
          this.currentUser = currentUser;
          this.store.checkTCAcceptance();
      },
    );

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe(events => {
      events[0] && (this.store.lastVisitedUrl = events[0]['url']);
      // this will close all pop ups whenever we load a new component thru redirect
      this.modalService.closeAllMessageDialogs();

      if ( this.store.getTcAccept() === 'false' && this.modalService.isTCDialogIsNotOpen()) {
        this.modalService.open(TermsConditionsComponent, { currentUser: this.currentUser as IUserData }, false, {id: MODAL_TYPES.TERMS_AND_CONDITIONS});
      }
    });

    this.router.events
      .subscribe((event: RouterEvent) => {
        if (event instanceof RouteConfigLoadStart) {
          this.loaderService.show();
        } else if (event instanceof RouteConfigLoadEnd) {
          this.loaderService.hide();
        }
      });

    this.setDocTitle(this.translate.instant('COMMON.titles.ecccAppTabTitle'));

  }

  setDocTitle(title: string) {
    console.log('current title:::::' + this.titleService.getTitle());
    this.titleService.setTitle(title);
 }

}

const _translateServiceInstantOrig = TranslateService.prototype.instant;
Object.assign(TranslateService.prototype, {
  instant(key: string, interpolateParams) {
    if (typeof key === 'string') {
      const last = key.split('.').pop().toLowerCase();
      if (last === 'null') {
        return '';
      } else {
        return _translateServiceInstantOrig.call(this, key, interpolateParams);
      }
    }
  },
});

/**
 * Fix for PrimeNG dialog in version 9.0.0
 */
const _marDialogRefOrig = Dialog.prototype.disableModality;
Object.assign(Dialog.prototype, {
  disableModality() {
     if (this.wrapper) {
       if (this.dismissableMask) {
         this.unbindMaskClickListener();
       }
       if (this.modal) {
         DomHandler.removeClass(document.body, 'ui-overflow-hidden');
       }
     }
  },
});

Object.assign(String.prototype, {
  toCamelCase() {
    return camelCase(this);
  },
});
