import { REGISTRATION_FIELDS } from '@shared/components/stepper/stepper.const'

export const newRegistrationRequiredFields = [
    REGISTRATION_FIELDS.legalName,

    REGISTRATION_FIELDS.individualName,

    REGISTRATION_FIELDS.organizationBusEmail,
    REGISTRATION_FIELDS.organizationBusPhone,

    REGISTRATION_FIELDS.facilityId,
    REGISTRATION_FIELDS.businessStructure,
    REGISTRATION_FIELDS.registrationNumber,
    REGISTRATION_FIELDS.certificateNumber,
    REGISTRATION_FIELDS.jurisdiction,
    REGISTRATION_FIELDS.registrationType,
    REGISTRATION_FIELDS.naics,
    REGISTRATION_FIELDS.address,
    REGISTRATION_FIELDS.streetAddress,
    REGISTRATION_FIELDS.municipality,
    REGISTRATION_FIELDS.province,
    REGISTRATION_FIELDS.country,
    REGISTRATION_FIELDS.postalCode,
    REGISTRATION_FIELDS.name,
    REGISTRATION_FIELDS.title,
  //  REGISTRATION_FIELDS.telephone,
    REGISTRATION_FIELDS.email,
    REGISTRATION_FIELDS.businessTelephone,
    REGISTRATION_FIELDS.organizationId,
    REGISTRATION_FIELDS.responsibleName,
    REGISTRATION_FIELDS.businessNumber,
    REGISTRATION_FIELDS.persons,
    REGISTRATION_FIELDS.sapCustomerNumber
]

export const newAccountRequiredFields = [
    REGISTRATION_FIELDS.legalName,

    REGISTRATION_FIELDS.individualName,

    REGISTRATION_FIELDS.organizationBusEmail,
    REGISTRATION_FIELDS.organizationBusPhone,

    REGISTRATION_FIELDS.facilityId,
    REGISTRATION_FIELDS.businessStructure,
    REGISTRATION_FIELDS.registrationNumber,
    REGISTRATION_FIELDS.certificateNumber,
    REGISTRATION_FIELDS.jurisdiction,
    REGISTRATION_FIELDS.registrationType,
    REGISTRATION_FIELDS.naics,
    REGISTRATION_FIELDS.address,
    REGISTRATION_FIELDS.streetAddress,
    REGISTRATION_FIELDS.municipality,
    REGISTRATION_FIELDS.province,
    REGISTRATION_FIELDS.country,
    REGISTRATION_FIELDS.postalCode,
    REGISTRATION_FIELDS.name,
    REGISTRATION_FIELDS.title,
    REGISTRATION_FIELDS.email,
    REGISTRATION_FIELDS.businessTelephone,
    REGISTRATION_FIELDS.organizationId,
    REGISTRATION_FIELDS.responsibleName,
    REGISTRATION_FIELDS.businessNumber,
    REGISTRATION_FIELDS.persons,
    REGISTRATION_FIELDS.sapCustomerNumber
]

export const newSubAccountRequiredFields = [
    REGISTRATION_FIELDS.legalName
]

export const accountSummaryOBPSRequiredFields = [
    REGISTRATION_FIELDS.legalName,
    REGISTRATION_FIELDS.facilityId,
    REGISTRATION_FIELDS.certificateNumber,
    REGISTRATION_FIELDS.jurisdiction,
    REGISTRATION_FIELDS.registrationNumber,
    REGISTRATION_FIELDS.naics,
    REGISTRATION_FIELDS.sapCustomerNumber,

   // REGISTRATION_FIELDS.streetAddress,
   // REGISTRATION_FIELDS.municipality,
   // REGISTRATION_FIELDS.province,
   // REGISTRATION_FIELDS.country,
   // REGISTRATION_FIELDS.postalCode,
    
    REGISTRATION_FIELDS.organizationId,
    REGISTRATION_FIELDS.responsibleName,
    REGISTRATION_FIELDS.businessNumber,
]

export const accountSummaryNFMPRequiredFields =[
    REGISTRATION_FIELDS.ALL
]

export const newAccountDisabledFieldsIfEnhancedUser = [
    REGISTRATION_FIELDS.name,
    REGISTRATION_FIELDS.title,
    REGISTRATION_FIELDS.email,
    REGISTRATION_FIELDS.businessTelephone
]

export const newSubAcocuntRequiredFieldsbyMop=[
    REGISTRATION_FIELDS.legalName,
    REGISTRATION_FIELDS.name,
    REGISTRATION_FIELDS.email,
    REGISTRATION_FIELDS.businessTelephone,
    REGISTRATION_FIELDS.title,
]

export const newSubAccountDisabledFieldsByMop = [
    REGISTRATION_FIELDS.name,
    REGISTRATION_FIELDS.title,
    REGISTRATION_FIELDS.email,
    REGISTRATION_FIELDS.businessTelephone
]