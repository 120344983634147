import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ComingSoonComponent } from '@core/components/coming-soon/coming-soon.component';
import { Step } from '@shared/components/steps/models/step';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@shared/services/modal.service';
import { CfsRegistration, CFS_REGISTRATION_STEP_TYPE } from '../../constants/registration-types.const';


@Component({
  selector: 'app-cfs-org-role',
  templateUrl: './cfs-org-role.component.html',
  styleUrls: ['./cfs-org-role.component.scss']
})
export class CfsOrgRoleComponent extends Step implements OnInit {

  static key = 'CfsOrgRoleComponent';

  stepConfiguration: any;
  selectedCfsOpt:any;

  constructor(private translate: TranslateService, private modalService: ModalService) { super(); }

  ngOnInit() {
    this.stepConfiguration = Object.create(CfsRegistration.steps);
  }

  onSelection(selectedCfsOpt){

    let lastTitle: string;

    if (selectedCfsOpt === CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG) {

      this.stepConfiguration.forEach((step,index)=>{

        if(step.type === CFS_REGISTRATION_STEP_TYPE.CFS_VB_ACC_REG) {
          this.stepConfiguration.splice(index,1);
        }

        if(step.type === CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG) {
          this.stepConfiguration[this.stepConfiguration.length-1].page_sub_title = step.page_sub_title;
        }
    
      });

     this.stepComplete.emit(this.stepConfiguration);

    } else if (selectedCfsOpt === CFS_REGISTRATION_STEP_TYPE.CFS_VB_ACC_REG) {

      this.stepConfiguration.forEach((step,index)=>{

        if(step.type === CFS_REGISTRATION_STEP_TYPE.CFS_ORG_ACC_REG) {
          this.stepConfiguration.splice(index,1);
        }

        if(step.type === CFS_REGISTRATION_STEP_TYPE.CFS_VB_ACC_REG) {
          this.stepConfiguration[this.stepConfiguration.length-1].page_sub_title = step.page_sub_title;
        }
     });

     this.stepComplete.emit(this.stepConfiguration);

    }else{

    this.modalService.open(ComingSoonComponent, {registration: 'cfs'} ).afterClosed().subscribe((closed?: any) => {
      if (closed) {
        return;
      }
    });
  }
  }

}
