import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { IApp } from '@core/models/app.interfaces';

export const NFMP_DATE = '/account-service/property/getNfmpDate';

@Injectable({
  providedIn: 'root'
})
export class ComingsoonService {

  constructor( private http: HttpClient) { }

  public getNFMPDate(): Observable<IApp.IProperty> {
    return this.http.get<IApp.IProperty>(`${ environment.apiUrl }${ NFMP_DATE }`);
  }
}
