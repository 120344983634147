
<div class="inner">
  <div class="stepper-section">
    <app-progress-bar
      [steps]="registrationSteps"
      [current]="currentStep.id"
      [currentSubStep]="currentStep.subId"
      [maxStep]="maxStep"
      (changeSubStep)="onStepChange($event)">
    </app-progress-bar>
  </div>
  <div class="content-section">
    <div class="scrollable-area" #formContainer>
      <div class="content">
        <ng-template appStepAnchor></ng-template>
      </div>
    </div>
    <div class="footer">

      <ng-container *ngFor="let entityAction of entityActions">
        <button class="btn btn-primary ml-2 float-right"  *ngIf="!footerButtonConfig.hideActions"
              type="button"
              disableOnUpload
              [disabled]="footerButtonConfig.disableActions"
              (click)="triggerActionController(entityAction)">
              {{ 'COMMON.actionsLabel.' + entityAction.workflowAction | translate }}
        </button>
      </ng-container>

      <button class="btn btn-primary ml-2 float-right"
              *ngIf="footerButtonConfig.showUpdate"
              type="button"
              disableOnUpload
              [disabled]="!wasModified && !inProgress"
              (click)="update()">
              {{'COMMON.wizard.' +footerButtonConfig.updatePrefix | translate}}
      </button>
      <button class="btn btn-primary ml-2 float-right"
              *ngIf="isLastStep && footerButtonConfig.showSubmit"
              type="button"
              disableOnUpload
              [disabled]="!submitButtonEnabled"
              (click)="submit()">
              {{'COMMON.wizard.' +footerButtonConfig.submitPrefix | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right"
              *ngIf="!isLastStep && subStepsChooserSelected"
              type="button"
              [disabled]="(isLoading | async)  || (uploadInProgress$ | async)"
              disableOnUpload
              (click)="nextStep()">
        {{'COMMON.wizard.nextStepButton' | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right"
              *ngIf="!isFirstStep"
              type="button"
              disableOnUpload
              (click)="prevStep()">
              {{'COMMON.wizard.backToPreviousStepButton' | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right"
              *ngIf="footerButtonConfig.showSave"
              type="button"
              [disabled]="!isDirty || (isLoading | async) || (uploadInProgress$ | async)"
              disableOnUpload
              (click)="saveAsDraft()">
        {{'COMMON.wizard.SaveasDraft' | translate}}
      </button>
      <button class="btn btn-secondary ml-2 float-right" type="button" disableOnUpload (click)="close()">
        {{'COMMON.wizard.' + footerButtonConfig.closePrefix | translate}}
      </button>
    </div>
  </div>
</div>
