/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assigned-users.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../shared/controls/user-actions/user-actions.component.ngfactory";
import * as i5 from "../../../../shared/controls/user-actions/user-actions.component";
import * as i6 from "../../../../shared/services/user.service";
import * as i7 from "../../../../shared/services/modal.service";
import * as i8 from "../../../../core/store/store.service";
import * as i9 from "@angular/common";
import * as i10 from "../../../../shared/components/linked-accounts/linked-accounts.component.ngfactory";
import * as i11 from "../../../../shared/components/linked-accounts/linked-accounts.component";
import * as i12 from "../../../../core/services/lookup.service";
import * as i13 from "../../../users-management/services/user-management.service";
import * as i14 from "../../../../core/services/auth.service";
import * as i15 from "../../../../shared/services/notifications.service";
import * as i16 from "../../services/account-management.service";
import * as i17 from "../../../../shared/services/navigation.service";
import * as i18 from "../../../../shared/pipes/dict.pipe";
import * as i19 from "../../../../shared/components/table-view/table-view.component.ngfactory";
import * as i20 from "../../../../shared/components/table-view/table-view.component";
import * as i21 from "./assigned-users.component";
import * as i22 from "@angular/router";
import * as i23 from "../../../../shared/services/helper.service";
var styles_AssignedUsersComponent = [i0.styles];
var RenderType_AssignedUsersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssignedUsersComponent, data: {} });
export { RenderType_AssignedUsersComponent as RenderType_AssignedUsersComponent };
function View_AssignedUsersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.viewUser($event, _v.context.$implicit.id) !== false);
        ad = (pd_0 && ad);
    } if (("keyup" === en)) {
        var pd_1 = (_co.viewUser($event, _v.context.$implicit.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("USERS_MODULE.usersList.view")); _ck(_v, 2, 0, currVal_0); }); }
function View_AssignedUsersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-user-actions", [], null, [[null, "actionSuccessfull"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("actionSuccessfull" === en)) {
        var pd_0 = (_co.refreshData($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_UserActionsComponent_0, i4.RenderType_UserActionsComponent)), i1.ɵdid(2, 245760, null, 0, i5.UserActionsComponent, [i6.UserService, i7.ModalService, i8.StoreService], { actionsList: [0, "actionsList"], user: [1, "user"], appendTo: [2, "appendTo"] }, { actionSuccessfull: "actionSuccessfull" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.entityActionList; var currVal_1 = _v.context.$implicit; var currVal_2 = ".ui-table-scrollable-view"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AssignedUsersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform((_co.roleTranslationPrefix + i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.parent.context.$implicit.associationsList[0].roleId, _co.roles))))); _ck(_v, 1, 0, currVal_0); }); }
function View_AssignedUsersComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["..."]))], null, null); }
function View_AssignedUsersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_AssignedUsersComponent_5)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵppd(3, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignedUsersComponent_6)), i1.ɵdid(5, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.associationsList[0].roleId, _co.roles)); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.associationsList.length > 1); _ck(_v, 5, 0, currVal_1); }, null); }
function View_AssignedUsersComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_AssignedUsersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignedUsersComponent_4)), i1.ɵdid(1, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_ASSOC_LIST", 2]], null, 0, null, View_AssignedUsersComponent_7))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.associationsList && (_v.context.$implicit.associationsList.length > 0)); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_AssignedUsersComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "position: relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-linked-accounts", [], null, null, null, i10.View_LinkedAccountsComponent_0, i10.RenderType_LinkedAccountsComponent)), i1.ɵdid(2, 638976, null, 0, i11.LinkedAccountsComponent, [i12.LookupService, i7.ModalService, i8.StoreService, i6.UserService, i3.TranslateService, i13.UserManagementService, i14.AuthService, i15.NotificationsService, i16.AccountManagementService, i17.NavigationService], { items: [0, "items"], readonly: [1, "readonly"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.associationsList; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AssignedUsersComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i18.DictPipe, []), i1.ɵqud(402653184, 1, { accountUserActions: 0 }), i1.ɵqud(402653184, 2, { viewColTemplate: 0 }), i1.ɵqud(402653184, 3, { expandUserRowTemplate: 0 }), i1.ɵqud(402653184, 4, { roleColumnTemplate: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "table-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-table-view", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onWindowResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i19.View_TableViewComponent_0, i19.RenderType_TableViewComponent)), i1.ɵdid(10, 638976, null, 0, i20.TableViewComponent, [i1.ElementRef, i3.TranslateService], { data: [0, "data"], config: [1, "config"] }, null), (_l()(), i1.ɵand(0, [[2, 2], ["viewColTemplate", 2]], null, 0, null, View_AssignedUsersComponent_1)), (_l()(), i1.ɵand(0, [[1, 2], ["accountUserActions", 2]], null, 0, null, View_AssignedUsersComponent_2)), (_l()(), i1.ɵand(0, [[4, 2], ["roleColumnTemplate", 2]], null, 0, null, View_AssignedUsersComponent_3)), (_l()(), i1.ɵand(0, [[3, 2], ["expandUserRowTemplate", 2]], null, 0, null, View_AssignedUsersComponent_8))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userList; var currVal_2 = _co.tableConfig; _ck(_v, 10, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("ACCOUNTS_MODULE.assignedUsers.title")); _ck(_v, 6, 0, currVal_0); }); }
export function View_AssignedUsersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-assigned-users", [], null, null, null, View_AssignedUsersComponent_0, RenderType_AssignedUsersComponent)), i1.ɵdid(1, 114688, null, 0, i21.AssignedUsersComponent, [i8.StoreService, i16.AccountManagementService, i22.Router, i23.HelperService, i6.UserService, i12.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssignedUsersComponentNgFactory = i1.ɵccf("app-assigned-users", i21.AssignedUsersComponent, View_AssignedUsersComponent_Host_0, { accountId: "accountId", userList: "userList" }, { actionSuccesfull: "actionSuccesfull" }, []);
export { AssignedUsersComponentNgFactory as AssignedUsersComponentNgFactory };
