import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StoreService } from '@core/store/store.service';
import { ProjectDiscussionAddCommentComponent } from '@shared/components/project-discussion/project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.component';
import { ProjectDiscussionAddComment } from '@shared/components/project-discussion/project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.constants';
import { IProjectDiscussionAddCommentComponent } from '@shared/components/project-discussion/project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.types';
import { ProjectDiscussionService } from '@shared/components/project-discussion/service/project-discussion.service';
import { IProjectDiscussionCommentContent } from '@shared/components/project-discussion/service/project-discussion.service.types';
import { ModalService } from '@shared/services/modal.service';
import { IProject } from '@shared/services/project.service.types';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-project-discussion-chat-controls',
  templateUrl: './project-discussion-chat-controls.component.html',
  styleUrls: ['./project-discussion-chat-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiscussionChatControlsComponent implements OnInit {
  @Input() entry: IProjectDiscussionCommentContent;
  @Input() project: IProject;
  @Input() updateOnly = false;

  isAdmin: boolean;

  constructor(
    private store: StoreService,
    private modalSvc: ModalService,
    private projectDiscussionSvc: ProjectDiscussionService,
  ) { }

  ngOnInit() {
    this.isAdmin = !this.store.user.checkIfCFRNonAdmin();
  }

  onReply(event: Event, entry: IProjectDiscussionCommentContent): void {
    event.preventDefault();
    const data = this.getData(entry, ProjectDiscussionAddComment.Reply);
    this.showDialog(data);
  }

  onUpdate(event: Event, entry: IProjectDiscussionCommentContent): void {
    event.preventDefault();
    const data = this.getData(entry, ProjectDiscussionAddComment.Update);
    this.showDialog(data);
  }

  canUpdate(entry: IProjectDiscussionCommentContent): boolean {
    return this.isAdmin && entry.commentedByUserId === this.store.user.id;
  }

  private getData(entry: IProjectDiscussionCommentContent, mode: ProjectDiscussionAddComment): IProjectDiscussionAddCommentComponent {
    return {
      project: this.project,
      mode,
      entry,
    };
  }

  private showDialog(data: IProjectDiscussionAddCommentComponent): void {
    this.modalSvc.open(ProjectDiscussionAddCommentComponent, data).afterClosed().pipe(
      take(1),
    ).subscribe(result => {
      if (result === true) {
        this.projectDiscussionSvc.onLoadComments();
      }
    });
  }
}
