import { IApp } from '@core/models/app.interfaces';
import { AuthorizedOfficialComponent } from '@module/cfr-registration/components/authorized-official/authorized-official.component';
import { BiogasProductionComponent } from '@module/cfr-registration/components/biogas-production/biogas-production.component';
import { EmissionReductionProjectComponent } from '@module/cfr-registration/components/emission-reduction-project/emission-reduction-project.component';
import { EndUserFuelSwitchingComponent } from '@module/cfr-registration/components/end-user-fuel-switching/end-user-fuel-switching.component';
import { ForeignSupplierComponent } from '@module/cfr-registration/components/foreign-supplier/foreign-supplier.component';
import { FossilFuelImportComponent } from '@module/cfr-registration/components/fossil-fuel-import/fossil-fuel-import.component';
import { FossilFuelProductionComponent } from '@module/cfr-registration/components/fossil-fuel-production/fossil-fuel-production.component';
import { LowCiFuelImportComponent } from '@module/cfr-registration/components/low-ci-fuel-import/low-ci-fuel-import.component';
import { LowCiFuelProductionFsComponent } from '@module/cfr-registration/components/low-ci-fuel-production-fs/low-ci-fuel-production-fs.component';
import { LowCiFuelProductionRcComponent } from '@module/cfr-registration/components/low-ci-fuel-production-rc/low-ci-fuel-production-rc.component';
import { PrimarySupplierComponent } from '@module/cfr-registration/components/primary-supplier/primary-supplier.component';
import { RegisteredCreatorComponent } from '@module/cfr-registration/components/registered-creator/registered-creator.component';
import { Step1Component } from '@module/cfr-registration/components/step1/step1.component';
import { UploadRegistrationDocumentsComponent } from '@module/cfr-registration/components/upload-registration-documents/upload-registration-documents.component';
import { VbAreaExpertiseComponent } from '@module/cfr-registration/components/vb-area-expertise/vb-area-expertise.component';
import { VbOrgClassificationComponent } from '@module/cfr-registration/components/vb-org-classification/vb-org-classification.component';
import { VbAccreditationDetailsComponent } from '@module/cfr-registration/components/vb-accreditation-details/vb-accreditation-details.component';
import { CicLowCiFuelProductionComponent } from '@module/cfr-registration/components/cic-low-ci-fuel-production/cic-low-ci-fuel-production.component';
import { CarbonIntensityContributorComponent } from '@module/cfr-registration/components/carbon-intensity-contributor/carbon-intensity-contributor.component';

export const CFR_REGISTRATION_COMPONENTS = {
  STEP1: 'Step1Component',
  AUTH_OFFICIAL: 'AuthorizedOfficialComponent',
  PRIMARY_SUPPLIER: 'PrimarySupplierComponent',
  FOSSIL_FUEL_PROD: 'FossilFuelProductionComponent',
  FOSSIL_FUEL_IMPORT: 'FossilFuelImportComponent',
  REGISTERED_CREATOR: 'RegisteredCreatorComponent',
  EMISSION_REDUCTION_PROJECT: 'EmissionReductionProjectComponent',
  LOW_CI_FUEL_PROD_RC: 'LowCiFuelProductionRcComponent',
  BIOGAS_PROD: 'BiogasProductionComponent',
  LOW_CI_FUEL_IMPORT: 'LowCiFuelImportComponent',
  END_USER_FUEL_SWITCHING: 'EndUserFuelSwitchingComponent',
  FOREIGN_SUPPLIER: 'ForeignSupplierComponent',
  LOW_CI_FUEL_PROD_FS: 'LowCiFuelProductionFsComponent',
  VB_ORG_CLASSIFICATION: 'VbOrgClassificationComponent',
  VB_AREA_EXP: 'VbAreaExpertiseComponent',
  VB_ACC_DET: 'VbAccreditationDetailsComponent',
  UPLOAD_REG_DOCS: 'UploadRegistrationDocumentsComponent',
  CARBON_INTENSITY_CONTRIBUTOR: 'CarbonIntensityContributorComponent',
  CIC_LOW_CI_FUEL_PROD: 'CicLowCiFuelProductionComponent',
};

export const CFR_PROPOSE_RR_ACTIONS = {
  'APPROVE_PROPOSED': '/account-service/cfrAccount/approveProposedRegistrationUpdate',
  'REJECT_PROPOSED': '/account-service/cfrAccount/rejectProposedRegistrationUpdate'
};

export const REGISTRATION_REPORT_ROLES = {
   PS: 'primarySupplier',
   RC: 'registeredCreator',
   VB: 'accreditationBody',
   FS: 'foreignSupplier',
   CIC: 'carbonIntensityContributor',
};

export const FACILITIES_WITHOUT_AGREEMENT = [
  IApp.NSActivityCode.StatusEnum.PRODFF,
  IApp.NSActivityCode.StatusEnum.PLCIMIE,
];

export const REGISTRATION_STEPS = {
  RRPS: [
    {
      id: 1,
      name: 'orgInformation',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.STEP1,
    },
    {
      id: 2,
      name: 'authorizedOfficialContactPersonInfo',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.AUTH_OFFICIAL,
    },
    {
      id: 3,
      name: REGISTRATION_REPORT_ROLES.PS,
      completed: false,
      enabledSubStepsProp: REGISTRATION_REPORT_ROLES.PS,
      component: CFR_REGISTRATION_COMPONENTS.PRIMARY_SUPPLIER,
      subSteps: [
        {
          id: 3,
          subId: 1,
          name: 'fossilFuelProduction',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.FOSSIL_FUEL_PROD,
        },
        {
          id: 3,
          subId: 2,
          name: 'fossilFuelImport',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.FOSSIL_FUEL_IMPORT,
        },
      ],
    },
    {
      id: 4,
      name: REGISTRATION_REPORT_ROLES.RC,
      completed: false,
      enabledSubStepsProp: REGISTRATION_REPORT_ROLES.RC,
      component: CFR_REGISTRATION_COMPONENTS.REGISTERED_CREATOR,
      subSteps: [
        {
          id: 4,
          subId: 1,
          name: 'emissionsReduction',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.EMISSION_REDUCTION_PROJECT,
        },
        {
          id: 4,
          subId: 2,
          name: 'lowCIFuelProduction',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.LOW_CI_FUEL_PROD_RC,
        },
        {
          id: 4,
          subId: 3,
          name: 'biogasProduction',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.BIOGAS_PROD,
        },
        {
          id: 4,
          subId: 4,
          name: 'lowCIFuelImport',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.LOW_CI_FUEL_IMPORT,
        },
        {
          id: 4,
          subId: 5,
          name: 'endUserFuelSwitching',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.END_USER_FUEL_SWITCHING,
        },
      ],
    },
    {
      id: 5,
      name: REGISTRATION_REPORT_ROLES.FS,
      completed: false,
      enabledSubStepsProp: REGISTRATION_REPORT_ROLES.FS,
      component: CFR_REGISTRATION_COMPONENTS.FOREIGN_SUPPLIER,
      subSteps: [
        {
          id: 5,
          subId: 1,
          name: 'lowCIFuelProduction',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.LOW_CI_FUEL_PROD_FS,
        },
      ],
    },
    {
      id: 6,
      name: REGISTRATION_REPORT_ROLES.CIC,
      completed: false,
      enabledSubStepsProp: REGISTRATION_REPORT_ROLES.CIC,
      component: CFR_REGISTRATION_COMPONENTS.CARBON_INTENSITY_CONTRIBUTOR,
      subSteps: [
        {
          id: 6,
          subId: 1,
          name: 'CIClowCIFuelProduction',
          completed: false,
          component: CFR_REGISTRATION_COMPONENTS.CIC_LOW_CI_FUEL_PROD,
        },
      ],
    },
  ],
  RRVB: [
    {
      id: 1,
      name: 'orgInformation',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.STEP1,
    },
    {
      id: 2,
      name: 'aoe',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.VB_AREA_EXP,
    },
    {
      id: 3,
      name: 'proofOfAccreditation',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.VB_ACC_DET,
    },
    {
      id: 4,
      name: 'authorizedOfficialInfo',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.AUTH_OFFICIAL,
    },
  ],
  RRSC: [
    {
      id: 1,
      name: 'subContractingOrg',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.STEP1,
    },
    {
      id: 2,
      name: 'aoe',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.VB_AREA_EXP,
    },
  ],
  DUVB: [
    {
      id: 1,
      name: 'uploadRegDocs',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.UPLOAD_REG_DOCS,
      disableMessageOnClose: true,
    },
  ],
  DUPS: [
    {
      id: 1,
      name: 'uploadRegDocs',
      completed: false,
      component: CFR_REGISTRATION_COMPONENTS.UPLOAD_REG_DOCS,
      disableMessageOnClose: true,
    },
  ],
};

export const FOOTER_BUTTONS = {
  RRPS: {
    showSave: true,
    showSubmit: true,
    submitPrefix : 'submitRegistrationReportButton',
  },
  RRVB: {
    showSave: true,
    showSubmit: true,
    submitPrefix : 'submitRegistrationReportButton',
  },
  RRSC: {
    showSave: true,
    showSubmit: true,
    submitPrefix : 'submitButton',
  },
  DUPS: {
    showSave: false,
    showSubmit: false,
    hideActions: false,
    closePrefix: 'saveAndClose',
  },
  DUVB: {
    showSave: false,
    showSubmit: false,
    hideActions: true,
    closePrefix: 'saveAndClose',
  },
};

export const ACTION_LIST = {
  APPROVE_IDENTITY_DOC: {
    executeCallback: true,
  },
  REJECT_IDENTITY_DOC: {
    executeCallback: true,
  },
  SUBMIT_IDENTITY_DOC: {
    executeCallback: true,
  },
};

export const PERMISSION_ADD_SUB_ORGANIZATION_USER = 'REVIEW_EEC_PAYMENT_FILE';
export const NON_NULL_OBJECTS = [
  'postalAddress',
  'civicAddress',
  'authorizedOfficial.postalAddress',
  'authorizedOfficial.civicAddress',
  'contactPerson.postalAddress',
  'contactPerson.civicAddress',
];

export const VB_NON_NULL_OBJECTS = [
  'postalAddress',
  'civicAddress',
  'authorizedOfficial.postalAddress',
  'authorizedOfficial.civicAddress',
];

export const  postalAddress = {
  streetNumber: '36',
  streetName: 'Haslemere Avenue',
  city: 'surrey',
  province: 'Nova Scotia',
  poBox: null,
  postalCode: 'G1S 3E4',
  country: 'CA',
  sameAsPostalAddress: false,
};

export const  aoPostalAddress = {
  businessTelephone: 'Haslemere',
  city: 'surrey',
  country: 'CA',
  email: '36',
  postalCode: 'G1S 3E4',
  province: 'Nova Scotia',
  sameAsPostalAddress: false,
  streetName: 'Haslemere Avenue',
  streetNumber: '36',
  telephone: 'Mitcham',
  poBox: null,
};

export const  civicAddress = {
  streetNumber: '36',
  streetName: 'Haslemere Avenue',
  city: 'surrey',
  province: 'Nova Scotia',
  poBox: null,
  postalCode: 'G1S 3E4',
  country: 'CA',
  sameAsPostalAddress: false,
};

export const authorizedOfficial = {
  telephone: '+447918184267',
  fullName: 'Papul Das',
  title: 'Ms',
  faxNumber: 'fax',
  legalName: 'papul',
  email: 'pdcfr12feb@markit.com',
  businessTelephone: '+447918184268',
  postalAddress: aoPostalAddress,
  civicAddress,
  professionalQualificationIds: [1, 5],
};

export const contactPerson = {
  telephone: '+447918184267',
  fullName: 'Papul Das cp',
  title: 'Ms',
  faxNumber: 'fax',
  legalName: 'papul',
  email: 'pdcfr12febcp@markit.com',
  businessTelephone: '+447918184268',
  postalAddress: aoPostalAddress,
  civicAddress,
  professionalQualificationIds: [1, 5],
};

export const primarySupplier = {
  code: 'PS',
  activities : [{
    code: 'PRODFF',
    facilities: [{
      civicAddress,
      postalAddress,
      facilityType: 1,
      type: 'FACILITY',
      fuelList: [2, 1],
      name: 'Facility 1',
    }],
  }, {
    code: 'IMPFF',
    facilities: [{
      type: 'PROVINCE',
      facilityType: 1,
      fuelList: [2, 1],
      name: 'BC',
    }],
  }],
};

export const registeredCreator = {
  code: 'RC',
  activities : [{
      code: 'ERP',
      facilities: [{
        civicAddress,
        postalAddress,
        facilityName: 'Facility Biogas RC 1',
        facilityType: 1,
        agreementRequired: true,
        type: 'Facility',
        description: ' Description',
        name: 'Facility ERP 1',
      },
      {
        civicAddress,
        postalAddress,
        facilityName: 'Facility Biogas RC 1',
        facilityType: 1,
        agreementRequired: true,
        type: 'Equipment',
        description: ' Description',
        name: 'Equipment ERP 1',
      }],
    },
    {
      code: 'PRODCI',
      facilities: [{
      civicAddress,
      postalAddress,
      facilityType: 1,
      agreementRequired: true,
      type: 'FACILITY',
      fuelList: [14, 17, 18],
      name: 'Facility LOw CI RC 1',
    }],
  }, {
    code: 'BPE',
    facilities: [{
      civicAddress,
      postalAddress,
      facilityType: 1,
      agreementRequired: true,
      type: 'FACILITY',
      description: 'Equipment Description',
      name: 'Facility Biogas RC 1',
    }],
  }, {
    code: 'IMPLCI',
    facilities: [{
      type: 'PROVINCE',
      facilityType: 1,
      fuelList: [ 14, 12, 17, 18],
      otherFuelList: ['Low Fuel1' , 'Low fuel 2'],
      name: 'BC',
    }],
  }, {
    code: 'EUFS',
    facilities: [{
      civicAddress,
      postalAddress,
      facilityType: 1,
      type: 'NonResidential',
      name: 'NonResidential ERP 1',
    },
    {
      civicAddress,
      postalAddress,
      facilityType: 1,
      type: 'FuelingStation',
      name: 'FuelingStation RC 1',
      fuelList: [ 14, 12, 17, 18],
    }, {
      jurisdiction: ['NB', 'NL'],
      type: 'Residential',
      facilityType: 1,
      name: 'Residential RC 1',
    }],
  }],
};

export const foreignSupplier = {
  code: 'FS',
  activities : [{
    code: 'PRODCI',
    facilities: [{
      civicAddress,
      postalAddress,
      facilityName: 'Facility 1',
      facilityType: 1,
      type: 'FACILITY',
      fuelList: [11, 13, 17, 18],
      name: 'Facility FS 1',
    }],
  }],
};

export const account = {
  id: 2871028,
  legalName: 'PD CFR Account 12 Feb',
  orgBusinessEmail: 'french trade',
  orgBusinessPhone: 'english trade',
  organizationId: 'Org12',
  registrationNumber: 'CRABN',
  frenchTradeName: 'french trade',
  englishTradeName: 'english trade',
  postalAddress,
  civicAddress,
  authorizedOfficial,
  contactPerson,
  accountSubTypeDtoList: [primarySupplier, registeredCreator, foreignSupplier],
  primarySupplier: true,
  registeredCreator: true,
  foreignSupplier: true,
};

export const accreditationBody = {
  code: 'VB', // fs,ps,
  activities : [{
    code: 'VFB, VLB, VFLB',
    facilities: [{
      code: 'abcode_1',
      fromDate: '2021-02-10',
      toDate: '2021-02-25',
      type: 'ACCREDITATION_BODY',
    }],
  }],
};

export const vbaccount = {
  id: 123,
  legalName: 'PD CFR Account',
  orgBusinessEmail: 'french trade',
  orgBusinessPhone: 'english trade',
  organizationId: 'Orngg12',
  registrationNumber: 'CRABN',
  frenchTradeName: 'french trade',
  englishTradeName: 'english trade',
  postalAddress,
  civicAddress,
  authorizedOfficial,
  areaOfExpertiseIds: [11, 2],
  accountSubTypeDtoList: [accreditationBody],
};
