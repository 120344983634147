import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';

@Injectable({
  providedIn: 'root',
})
export class AgreementGuard implements CanActivate {

  constructor(
    private service: AgreementCreditsService,
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.switchToProperPath();
  }

  private switchToProperPath(): Observable<boolean> {
    const { currentData, inputData } = this.service.restoreCurrentData();
    if (currentData && inputData) {
      this.service.redirectToAgreementScreen(inputData, currentData.id, currentData);
      return of(true);
    } else {
      return of(false);
    }
  }

}
