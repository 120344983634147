import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
let FormPopupWrapperComponent = class FormPopupWrapperComponent {
    constructor(data, dialogRef, formFactorySvc) {
        this.data = data;
        this.dialogRef = dialogRef;
        this.formFactorySvc = formFactorySvc;
        this.visible = true;
        this.form = new FormGroup({});
    }
    get title() {
        return this.data.title;
    }
    get width() {
        return this.data.width ? `${this.data.width}px` : '800px';
    }
    ngOnInit() {
        this.initFieldsSub();
        this.initModelSub();
        this.initUpdateModelSub();
    }
    changed() {
        this.dialogRef.close(null);
    }
    close() {
        this.dialogRef.close(null);
    }
    save() {
        this.formFactorySvc.markFormGroupDirty(this.form);
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        }
    }
    initFieldsSub() {
        this.fields = this.getFields(this.data.fields || []);
    }
    initModelSub() {
        this.model = this.data.model;
    }
    initUpdateModelSub() {
        const { updateModel } = this.data;
        if (typeof updateModel !== 'function') {
            return;
        }
        this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
            this.model = updateModel(this.form);
        });
    }
    getFields(source) {
        return this.formFactorySvc.configureForm(source);
    }
};
FormPopupWrapperComponent = tslib_1.__decorate([
    UntilDestroy()
], FormPopupWrapperComponent);
export { FormPopupWrapperComponent };
