<p-dialog
  [className]="'eccc'"
  [header]="title | translate"
  [closable]="false"
  [visible]="true"
  [modal]="true"
  [responsive]="true"
  [style]="{width: width}"
  [minY]="500"
  [maximizable]="false"
  [autoZIndex]="false">
  <ng-container [ngTemplateOutlet]="template | templateRef"
                [ngTemplateOutletContext]="{ $implicit: data}">
  </ng-container>
  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="closeBtn" type="button" class="btn btn-secondary" (click)="closeDialog()">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
