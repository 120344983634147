import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import {UserFilterModel} from '@core/models/filter.model';
import { IUsersPage } from '@core/models/user.model';
import { environment } from '@env/environment';
import { ACCOUNT_MGMT_GET_ACCOUNT } from '@module/account-management/services/account-management.service';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { Observable } from 'rxjs';

//export const USER_MGMT_GET_USER = 'http://localhost:8084/api/account-service/user';
export const USER_MGMT_GET_USER = '/account-service/private/user';
export const USER_MGMT_SEARCH_PRIVATE_USERS = '/account-service/private/user/newpageSearch';
export const USER_LOGIN_DETAILS = '/account-service/user/loginDetails';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService extends AbstractPaginationService {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  getAll(pagination: IApp.IPagination, filterState: UserFilterModel = {}): Observable<IUsersPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IUsersPage>(`${ environment.apiUrl }${ USER_MGMT_SEARCH_PRIVATE_USERS }`, filterState, { params });
  }

  get(userId: number): Observable<IApp.IUser> {
    return this.http.get<IApp.IUser>(`${ environment.apiUrl }${ USER_MGMT_GET_USER }/${ userId }`);
  }

  getUserLogin(user: IApp.IUser): Observable<IApp.IUser>{
    return this.http.post<IApp.IUser>(`${ environment.apiUrl }${ USER_LOGIN_DETAILS }`, user);
  }
}
