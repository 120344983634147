import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { SubmissionManagementService } from '@module/submission-management/services/submission-management.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { CIVIC_ADDRESS_FORM_SECTION, POSTAL_ADDRESS_FORM_SECTION, SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { Step } from '@shared/components/steps/models/step';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { FormService } from '@shared/services/form.service';
import { takeUntil } from 'rxjs/operators';
import { STEP3_OTHER_PARTY_ORG_DETAILS, PREVIOUS_AGREEMENT_DETAILS } from '../constant/AgreementCreateCredits.const';
import { IRawFormInput } from '@core/models/raw-form.types';
import { cloneDeep} from 'lodash';
@Component({
  selector: 'app-other-party-org-details',
  templateUrl: './other-party-org-details.component.html',
  styleUrls: ['./other-party-org-details.component.scss'],
})
export class OtherPartyOrgDetailsComponent extends BaseStepperStepComponent {

  static key = 'OtherPartyOrgDetailsComponent';

  formGroup = new FormGroup({});
  fields: FormlyFieldConfig[];
  prevFormGroup = new FormGroup({});
  prevFields: FormlyFieldConfig[];
  model: any;

  existInMarkit: boolean;

  constructor(
    private translateService: TranslateService,
    private formFactoryService: FormFactoryService,
    public formService: FormService,
    public service: AgreementCreditsService,
    private submissionManagementService: SubmissionManagementService) { super(); }

  ngOnInit() {
    this.model = this.service.currentData;
    const PREVIOUS_FORM_FIELDS = PREVIOUS_AGREEMENT_DETAILS;
    (PREVIOUS_FORM_FIELDS[0].fieldGroup[1] as IRawFormInput).dataType = this.model.registrationAccount.id;
    this.prevFields = this.formFactoryService.configureForm(PREVIOUS_FORM_FIELDS);

    const FORM_FIELDS = STEP3_OTHER_PARTY_ORG_DETAILS;
    this.fields = this.formFactoryService.configureForm(FORM_FIELDS);

    if (this.model.opaDetail && this.model.opaDetail.editable) {
      this.existInMarkit = false;
    } else {
      this.existInMarkit = true;
      setTimeout(() => this.formGroup.disable());
    }

    POSTAL_ADDRESS_FORM_SECTION.key = 'opaDetail.postalAddress';
    CIVIC_ADDRESS_FORM_SECTION.key = 'opaDetail.civicAddress';
    CIVIC_ADDRESS_FORM_SECTION.sameAsPostal.changeExpr = 'this.disableForm($event, field);  this.cloneFormGroup($event, field, \'opaDetail.postalAddress\', \'opaDetail.civicAddress\')',
      POSTAL_ADDRESS_FORM_SECTION.sameAsOrganization.hide = true;
    this.fields.push(this.formFactoryService.configurFormPostWithCivicAddress(POSTAL_ADDRESS_FORM_SECTION, CIVIC_ADDRESS_FORM_SECTION)[0]);
    this.service.disableFormIfApplicable(this.formGroup);
    setTimeout(() => {
        this.formGroup.get('opaDetail.legalName').disable();
    });
    this.formFactoryService.dictCallback
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        data => this.callbackFunction(data),
      );
  }

  callbackFunction(fieldData): void {
    if (!this.existInMarkit  && fieldData.key === `opaAgreementId` && this.checkForDisableState() ) {
      if(this.model.opaAgreementId){
      this.service.getAgreementById(this.model.opaAgreementId).subscribe(
        result => {
          console.log(result.opaAccount);
          //this.model.opaDetail = result.opaAccount;
          setTimeout(() => {
            this.formGroup.disable();
            this.prevFormGroup.get('opaAgreementId').enable();
            this.formGroup.patchValue({ opaDetail: result.opaAccount });
            this.model.opaDetail.id = result.opaAccount.id;
            console.log(this.model.opaDetail);
            this.model.opaDetail.editable = true;
          });
        },
        (error: HttpErrorResponse) => {
          console.log(error);
        },
      );
    }else{
      setTimeout(() => {
            if(!fieldData.event || !fieldData.event.initial){
            this.formGroup.enable();
            this.model.opaDetail = {
              legalName :  this.model.opaName,
              editable: true,
            };
            this.formGroup.reset({ opaDetail: this.model.opaDetail });
            this.formGroup.get('opaDetail.legalName').disable();
            }
          });
    }
    }
  }


  saveAsDraft() {
    this.saveAndProceed(true);
  }

  saveAndProceed(draft: boolean = false) {

    if (!this.validateForm(this.formGroup) && this.formGroup.status !== 'DISABLED') {
      this.markInvalid();
      return;
    }
    if (this.checkForDisableState()) {

      this.model.opaAccount = null;
      this.service.saveOpaAccount(this.model, draft).pipe(
        takeUntil(this._destroy$),
      ).subscribe(
        data => {
          console.log(data);
          this.service.currentData = data.entity;
          this.service.prevStepData = cloneDeep(this.service.currentData);
          this.service.initModelChangeTracking();
          if (!draft) {
            this.service.nextStep();
          }
        },
         (error: HttpErrorResponse) => {
            this.formService.parseErrors(this.formGroup.controls.opaDetail as FormGroup, error.error);
            setTimeout(() => this.validateForm(this.formGroup));
          },

      );
    } else {
      this.service.nextStep();
    }
  }

}
