import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();
  url: string;

  constructor(
    private translate: TranslateService,
    private router: Router,
  ) {
    this.translate.onLangChange
    .pipe(
      takeUntil(this._destroy$),
    )
    .subscribe((event: LangChangeEvent) => {
      setTimeout(() => {
        this.url = this.router.url.split('#')[0];
      });
    });
  }

  ngOnInit() {
    this.url = this.router.url.split('#')[0];
  }

  get lang(): string {
    return this.translate.currentLang;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
