<div class="mt-3 border-bottom d-flex justify-content-between">
  <h3 class="mb-2">{{ 'PROJECTS_MODULE.projectMilestones.title' | translate }}</h3>
</div>

<div class="d-flex mt-3">
  <label class="wcag-visuallyhidden" for="mode">{{ 'PROJECTS_MODULE.projectMilestones.showCompleted' | translate }}</label>
  <p-inputSwitch
    [inputId]="'mode'"
    (onChange)="toggle($event)">
  </p-inputSwitch>
  <label class="ml-2" >{{ 'PROJECTS_MODULE.projectMilestones.showCompleted' | translate }}</label>
</div>

<div class="milestones mt-3 mb-3">
  <ng-container *ngIf="milestones && milestones.length; else NO_MILESTONES">
    <div class="milestone" *ngFor="let milestone of milestones">
      <div class="milestone-date">{{ showCompleted ? milestone.completionDate : milestone.deadLineDateStr }}</div>
       <small>{{ 'COMMON.submissionTypeList.' + milestone?.deadLineType | translate }}</small>
    </div>
  </ng-container>
  <ng-template #NO_MILESTONES>
    <div class="text-center mb-3 mt-3">{{ 'PROJECTS_MODULE.projectMilestones.noUpcomingMilestones' | translate }}</div>
  </ng-template>
</div>
