import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDocument } from '@module/registration/registration.model';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';

//Document Actions
export const DOCUMENT_DO_ACTION = '/account-service/document/do';
export const CRA_PAYMENT_APPROVE = '/obps-service/transaction/approvePayment';
export const CRA_PAYMENT_REJECT = '/obps-service/transaction/rejectPayment';
export const CRA_PAYMENT_SAVE = '/obps-service/transaction/save';

export const DOWNLOAD = '/account-service/cfrAccount/registration/download';

@Injectable({
  providedIn: 'root'
})
export class ControlActionsService {

  constructor(private http: HttpClient) { }

  getFileNameFromHeaderOrDefult(response){
    const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
    if(response.headers.get('content-disposition') !== null){
      return regex.exec(response.headers.get('content-disposition'))[1];
    }

    return "file";
  }

  download(payload: any) {
    let fileName: string; 
    this.http.get(DOWNLOAD, payload).subscribe(
      (response: any) => {
          const dataType = response.body.type;
          const binaryData = [];
          const filename = fileName ? fileName : this.getFileNameFromHeaderOrDefult(response);
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
          if (filename) {
            downloadLink.setAttribute('download', filename);
          }
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          
      },
    () => {
        // repeat request to get JSON response
        this.http.get(DOWNLOAD, {observe: 'response'}).subscribe();
      },
  )
  }

  documentDoAction(payload, action : string): Observable<IDocument> {
    return this.http.post<IDocument>(`${ environment.apiUrl }${DOCUMENT_DO_ACTION}${action}`, payload).pipe(
      tap(response => {
      }),
    );
  }
  
  craPaymentApprove(request): Observable<any> { //for now is any until we know the right obj to map here
    return this.http.post<any>(`${ environment.apiUrl }${CRA_PAYMENT_APPROVE}`, request).pipe(
      tap(response => {
      }),
    );
  }

  craPaymentReject(request): Observable<any> { //for now is any until we know the right obj to map here
    return this.http.post<any>(`${ environment.apiUrl }${CRA_PAYMENT_REJECT}`, request).pipe(
      tap(response => {
      }),
    );
  }

  craPaymentSave(request): Observable<any> { //for now is any until we know the right obj to map here
    return this.http.post<any>(`${ environment.apiUrl }${CRA_PAYMENT_SAVE}`, request).pipe(
      tap(response => {
      }),
    );
  }


}
