/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/blurb/blurb.component.ngfactory";
import * as i2 from "../../components/blurb/blurb.component";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./formly-blurb.type";
var styles_FormlyBlurb = [];
var RenderType_FormlyBlurb = i0.ɵcrt({ encapsulation: 2, styles: styles_FormlyBlurb, data: {} });
export { RenderType_FormlyBlurb as RenderType_FormlyBlurb };
export function View_FormlyBlurb_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-blurb", [], null, null, null, i1.View_BlurbComponent_0, i1.RenderType_BlurbComponent)), i0.ɵdid(1, 114688, null, 0, i2.BlurbComponent, [i3.TranslateService], { message: [0, "message"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.label; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FormlyBlurb_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-blurb", [], null, null, null, View_FormlyBlurb_0, RenderType_FormlyBlurb)), i0.ɵdid(1, 49152, null, 0, i4.FormlyBlurb, [], null, null)], null, null); }
var FormlyBlurbNgFactory = i0.ɵccf("formly-blurb", i4.FormlyBlurb, View_FormlyBlurb_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { FormlyBlurbNgFactory as FormlyBlurbNgFactory };
