import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getAllParams, getNestedProp, replaceParam } from '@core/utilities/utilities.constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'formly-dynamic-template',
  template: `<div [innerHtml]="template" [attr.id]="to.id"></div>`,
})
export class DynamicFormlyTemplateType extends FieldType implements OnInit {
  template: any;
  private innerHtml = { content: null, template: null };

  constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    this.parseTemplate(this.to.template);
    this.initContentSub();
  }

  private getFieldValue(prop: string): string {
    return getNestedProp(this.field.model, prop) || '';
  }

  private parseTemplate(template: any): void {
    if (template) {
      let source = template;
      if (this.to.translate) {
        source = this.translateService.instant(template);
      }

      const params = getAllParams(source);

      params.forEach(param => {
        console.log(param, '=>', this.form.get(param));
        source = replaceParam(source, param, this.getFieldValue(param));
      });

      this.innerHtml = {
        template: source,
        content: this.to.safeHtml ? this.sanitizer.bypassSecurityTrustHtml(source) : source,
      };
    }

    this.template = this.innerHtml.content;
  }

  private initContentSub(): void {
    const updateContent: Observable<any> = this.to.updateContent;

    if (!updateContent) {
      return;
    }

    updateContent.pipe(untilDestroyed(this)).subscribe(value => {
      this.parseTemplate(value);
    });
  }
}
