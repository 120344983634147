import { MatDialogConfig } from '@angular/material/dialog';
import { MODAL_TYPES } from './modals.const';

export const TRANS_PREFIX = 'COMMON.messageSection.';

export const DEFAULT_DIALOG_CONFIG: MatDialogConfig = {
  hasBackdrop: true,
  panelClass: 'error-message-dialog',
  position: {
    top: '0',
  }
};

export const enum SereviceMessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    WARNING = 'WARNING',
    WARNING_ONLY = 'WARNING_ONLY',
}

const ERROR_PROPERTIES = {
    class: 'alert-danger',
    icon: 'times-circle',
};

const SUCCESS_PROPERTIES = {
    class: 'alert-success',
    icon: 'check-circle',
};

const WARNING_PROPERTIES = {
    class: 'alert-warning',
    icon: 'exclamation-circle',
};

export const SERVICE_MESSAGE = {
    ERROR : ERROR_PROPERTIES,
    SUCCESS : SUCCESS_PROPERTIES,
    WARNING : WARNING_PROPERTIES,
    WARNING_ONLY: WARNING_PROPERTIES,
};

export const IGNORE_ERROR_URIS = [
  '/api/account-service/lookup/findAccount/',
  '/api/auth-server/auth/samlLogin',
  '/api/auth-server/auth/samlProviders',
  '/service/obps-service/fileUpload/obpsResults',
  '/service/obps-service/transaction/upload',
  '/service/obps-service/transaction/uploadReversal',
  '/service/obps-service/credits/upload',
  '/service/obps-service/payment-refund/uploadCashPaymentRefund',
  '/api/auth-server/auth/SignOut',
  '/api/account-service/property/get',
  '/account-service/document/entity',
  '/service/account-service/registrationDocument',
  '/service/account-service/document',
  '/api/account-service/cfrAccount/searchOpaById',
   '/api/offset-service/project/searchProject'
];

export const GENERIC_EXCEPTION_MSG = 'GenericException';

export const ORGANIZATION_NAME_REQUIRED_TYPES = [
  'NoticeOfSuspensionSavedSuccessfully',
  'NoticeOfErrorAdminRemarksSavedSuccessfully',
  'NoticeOfErrorNeedMoreInfoSuccessfully',
  'NoticeOfErrorSavedSuccessfully',
];
