import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AppInjector } from '@shared/services/app-injector.service';
import * as i0 from "@angular/core";
export const ACCOUNT_APPROVE = '/account-service/account/approve';
export const ACCOUNT_REJECT = '/account-service/account/reject';
export const ACCOUNT_CLOSE = '/account-service/account/close';
export const ACCOUNT_OPEN = '/account-service/account/reOpen';
export const ACCOUNT_DISCARD = '/account-service/account/discard';
export const ACCOUNT_LOCK = '/account-service/account/lock';
export const ACCOUNT_UNLOCK = '/account-service/account/unlock';
export const ACCOUNT_APPROVE_ALL_LINKED_ACCOUNTS = '/account-service/account/approveAllLinkedAccounts';
const ACCOUNT_APPROVE2 = '/account-service/account/dualControlApprove';
export class BaseAccountActionsService {
    constructor() {
        this.customConfirmations = {};
        const injector = AppInjector.getInjector();
        this.http = injector.get(HttpClient);
    }
    getConfirmationMsg(key) {
        return this.customConfirmations[key] || 'confirmationMessage';
    }
    approveAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_APPROVE}`, account);
    }
    approve2Account(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_APPROVE2}`, account);
    }
    approveAllLinkedAccountsAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_APPROVE_ALL_LINKED_ACCOUNTS}`, account);
    }
    lockAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_LOCK}`, account);
    }
    unlockAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_UNLOCK}`, account);
    }
    closeAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_CLOSE}`, account);
    }
    openAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_OPEN}`, account);
    }
    discardAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_DISCARD}`, account);
    }
    rejectAccount(account) {
        return this.http.post(`${environment.apiUrl}${ACCOUNT_REJECT}`, account);
    }
    showConfirmationMessageForApproval() {
        return true;
    }
    showConfirmationMessageForRejection() {
        return true;
    }
    view(account) {
    }
}
BaseAccountActionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseAccountActionsService_Factory() { return new BaseAccountActionsService(); }, token: BaseAccountActionsService, providedIn: "root" });
