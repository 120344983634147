<!-- <section  class="action-panel" [class.visible]="actionPanelVisible | async">
<section class="action-panel visible" >
  <app-enhanced-user-sidebar></app-enhanced-user-sidebar>
  <i class="fas toggle-icon fa-chevron-left" (click)="toggleActionPanel()"></i>
  <i class="fas toggle-icon fa-chevron-right" (click)="toggleActionPanel()"></i>
</section> -->


<div class="account-title mb-3">

  <app-blurb [message]="'COMMON.documentGuidanceTest.ENHANCED_USER_DASHBOARD'" class="stick-left"></app-blurb>

  </div>

  <div class="panel mt-4">
    <app-user-tasks></app-user-tasks>
  </div>

  <div class="panel mt-4" *ngIf="(currentUser.isOBPS() && !nfmp)">
    <app-upcoming-deadlines></app-upcoming-deadlines>
  </div>
  <div class="row" *ngIf="currentUser.isOBPS() || isParticipant || currentUser.isOffset()">

    <div class="col-sm-12 mt-3"  *ngIf="!nfmp">

        <div class="card h-100">

          <div class="card-body">

            <div class="card-title float-left">
              <h2>{{'ACCOUNTS_MODULE.dashboard.accountSummaryTitle' | translate}}
                <span *ngIf="accountData">- {{ accountData.legalName }}
                  <ng-container *ngIf="currentUser.isOffset()">
                    <span *ngIf="!accountData.masterAccountId; else SUB">({{ 'ACCOUNTS_MODULE.dashboard.masterAccount' | translate }})</span>
                    <ng-template #SUB>
                      <span>({{ 'ACCOUNTS_MODULE.dashboard.subAccount' | translate }})</span>
                    </ng-template>
                  </ng-container>
                </span>
              </h2>
              <!-- <span *ngIf="isParticipant">{{ 'ACCOUNTS_MODULE.dashboard.fuelTypeLiquid' | translate }}</span> -->
            </div>

            <div class="row mt-4 clear-both" *ngIf="isParticipant">
              <div class="col-md-6 col-lg-4">
                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{ 'ACCOUNTS_MODULE.dashboard.fuelTypeLiquid' | translate }}</div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.complianceCredits' | translate}}</div>
                  <div class="col-lg-6 text-muted"  [innerHTML]="creditSummary.currentHoldingsStr"></div>
                </div>
              </div>
            </div>

           <div class="row justify-content-md-center mt-4 clear-both" *ngIf="!isParticipant">

              <div class="col-md-6 col-lg-4">

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.currentHoldings' | translate}}</div>
                  <div class="col-lg-6" [innerHTML]="creditSummary.currentHoldingsStr"></div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.totalIssued' | translate}}</div>
                  <div class="col-lg-6" [innerHTML]="creditSummary.totalIssuedStr"></div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.totalVolunatryCancellations' | translate}}</div>
                  <div class="col-lg-6" [innerHTML]="creditSummary.totalVolunatryCancellationsStr"></div>
              </div>

              </div>

              <div class="col-md-6 col-lg-4">
                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.totalPendingOutgoingUnits' | translate}}</div>
                  <div class="col-lg-6" [innerHTML]="creditSummary.totalPendingOutgoingUnitsStr"></div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.totalPendingIncomingUnits' | translate}}</div>
                  <div class="col-lg-6" [innerHTML]="creditSummary.totalPendingIncomingUnitsStr"></div>
                </div>

              </div>

              <div class="col-md-6 col-lg-4" *ngIf="!currentUser.isOffset()">
                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.totalObligationsPaid' | translate}}</div>
                  <div class="col-lg-6"  [innerHTML]="obligationSummary.compensationProvidedToDateStr"></div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.currentOutstandingObligations' | translate}}</div>
                  <div class="col-lg-6"  [innerHTML]="obligationSummary.outstandingObligationStr"></div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4" *ngIf="currentUser.isOffset()">
                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.numberOfProjects' | translate}}</div>
                  <div class="col-lg-6">{{creditSummary.totalProjects? creditSummary.totalProjects: 0}}</div>
                </div>

                <div class="row mb-2">
                  <div class="col-lg-6 text-right text-muted overflow-hidden">{{'ACCOUNTS_MODULE.dashboard.projectDetails' | translate}}</div>
                  <div class="col-lg-6">
                    <span class="link" role="button"
                    (click)="viewProjects()">
                {{ 'ACCOUNTS_MODULE.dashboard.view' | translate}}
              </span></div>
                </div>
              </div>

            </div>
            <div *ngIf="currentUser.isOffset() && accountData && !accountData.masterAccountId" class="note">
              {{ 'ACCOUNTS_MODULE.dashboard.masterAccountNotes' | translate }}
            </div>

          </div>
        </div>

    </div>

  </div>


  <div class="panel mt-4">
    <app-top-transactions [topTransactions] = "topTransactions"></app-top-transactions>
  </div>

<div class="loader" *ngIf="isLoading">
  <mat-spinner diameter="40"></mat-spinner>
</div>

