
    <ng-container *ngIf="currValue !== null && currValue !== undefined; else PLACEHOLDER">
      <span class="form-control wrapped-text" readonly="readonly" [class.auto-size]="autoHeight">
        <ng-container *ngIf="type && type === 'formatted-number'; else REGULAR">{{ currValue | localizedNumber: '1.0-0' }}</ng-container>
        <ng-template #REGULAR>
          <ng-container *ngIf="chips; else NORMAL">
            <span
              class="badge badge-primary badge-tag simple-tag"
              style="margin-top: -2px;"
              [class.mb]="autoHeight"
              *ngFor="let item of chipsList; let i = index"
              >{{ item }}</span
            >
          </ng-container>
          <ng-template #NORMAL>
            <ng-container *ngIf="to.dataPopup; else NO_POPUP">
              <span [innerHTML]="currValue" [pTooltip]="currValue" tooltipPosition="top" tooltipStyleClass="tooltip-min-width"></span>
            </ng-container>
          </ng-template>
          <ng-template #NO_POPUP>
            <span [innerHTML]="currValue"></span>
          </ng-template>
        </ng-template>
      </span>
    </ng-container>
    <ng-template #PLACEHOLDER>
      <span class="form-control hide-overflow" readonly="readonly" style="color: #495057">
        {{ placeholder }}
      </span>
    </ng-template>
    <input type="hidden" [value]="currValue" class="wcag-visuallyhidden" [id]="elementId" />
  