import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { FieldType } from '@ngx-formly/core';
let FormlyDocumentsType = 
// tslint:disable-next-line:component-class-suffix
class FormlyDocumentsType extends FieldType {
    constructor() {
        super();
        this.isDocumentUploadInProgress = false;
        this.documentUploadDisabled = false;
    }
    ngOnInit() {
    }
    get fieldDisabled() {
        return this.documentUploadDisabled || this.form.disabled;
    }
    documentUploadHandler(documentResult) {
        this.documents = documentResult;
        this.isDocumentUploadInProgress = documentResult
            .filter(document => document._uploadStatus)
            .some(document => document._uploadStatus !== 'CLEAN');
        if (typeof this.to.onDocumentsChange === 'function') {
            this.to.onDocumentsChange(this.documents);
        }
    }
    _hasDocumentConfigs() {
        return this.documentUploadConfig &&
            this.documentUploadConfig.documentUploadConfigs;
    }
    get documentUploadConfig() {
        return this.to.documentUploadConfig;
    }
};
FormlyDocumentsType = tslib_1.__decorate([
    UntilDestroy()
], FormlyDocumentsType);
export { FormlyDocumentsType };
