import { TemplateRef } from '@angular/core';
import { IRawForm } from '@core/models/raw-form.types';
import { getLabel } from '@core/utilities/raw-form.utils';
import {
  COMPLIANCE_REPORT_LIST_TABLE_FILTER,
} from '@module/compliance-report/compliance-report.const';
import { commonActionsLabelTranslationPrefix } from '@module/reports/components/reports/reports.const';
import { ActionOptionType, ITableFilterActionModel } from '@shared/models/table-filter.models';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewColumn, ITableViewConfig } from '@shared/models/table-view.model';

export const CREDIT_BALANCE_REPORT_ID_KEY = 'CB_REP_ID';
export const CREDIT_BALANCE_REPORT_DATA_KEY = 'CB_REP_DATA';
export const CREDIT_BALANCE_REPORT_CURRENT_STEP_KEY = 'CB_REP_STEP_KEY';

export const CREDIT_BALANCE_REPORT_PREFIX = 'CREDIT_BALANCE';
export const CREDIT_BALANCE_REPORT_STEP_PREFIX = '.reportStep';
export const CREDIT_BALANCE_REPORT_CREATE_POPUP = '.createReport';
export const CREDIT_BALANCE_REPORT_PERIOD_FLD = 'creditBalancePeriod';

export const creditBalanceRepApplyPrefix = (value: string, subPrefix: string = ''): string => `${CREDIT_BALANCE_REPORT_PREFIX}${subPrefix}.${value}`;

export const CB_REPORT_LIST_TABLE_FILTER: ITableFilterActionModel = {
  filterListsModel: [],
  searchBox: true,
  searchButtons: false,
  dateRange: true,
  actionModel: [
    {
      placeholder: commonActionsLabelTranslationPrefix + 'tools',

      options: [
        {
          id: 'exportPdf',
          label: commonActionsLabelTranslationPrefix + 'exportPdf',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_BALANCE_REPORT',
        },

        {
          id: 'exportExcel',
          label: commonActionsLabelTranslationPrefix + 'exportExcel',
          type: ActionOptionType.EXPORT,
          uri: 'CREDIT_BALANCE_REPORT',
        },
      ],
    },
  ],
  options: {
    dateRange: {
      placeholder: 'COMPLIANCE_REPORT.table.submittedOn',
    },
  },
};

export const CREDIT_BALANCE_REPORT_LIST_TABLE_CONFIG = {
  tableHeaderType: 'CREDIT_BALANCE_REPORT',
  tableFilter: CB_REPORT_LIST_TABLE_FILTER,
  filtersFromConfig: true,
  hasViewTemple: true,
  hasActionTemple: true,
  adminFilters: ['facilityNames'],
};

export const CB_CREDITS_TO_CLEARANCE = 'creditGroup';
export const CB_CREDITS_TO_CLEARANCE_DATA = 'creditGroupData';

export const CB_TOTAL_RECEIVED_CCM_FLD = 'totalReceivedCcm';
export const CB_TOTAL_RECEIVED_CCM_LOCAL_FLD = 'totalReceivedCcmLocalFld';
export const CB_CCM_PREFIX = 'CREDIT_BALANCE.submissionForm';

export const CB_CCM_TABLE_CONFIG = (templateRef: TemplateRef<any>): ITableViewConfig => {
  return {
    translationPrefix: CB_CCM_PREFIX,
    paginator: false,
    hasTotalRecord: true,
    columns: [
      {
        field: 'creditBalanceGroupCode',
        header: 'creditBalanceGroupCode',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: false,
        translate: true,
        inlineTooltip: true,
        translationPrefix: `${CREDIT_BALANCE_REPORT_PREFIX}.creditGroup`,
        ignoreTruncate: true
      },
      {
        field: 'totalCreditSubmitted',
        header: 'totalCreditSubmitted',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalCreditSubmittedTooltip`
        }
      },
      {
        field: 'totalOutgoingTransfer',
        header: 'totalOutgoingTransfer',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalOutgoingTransferTooltip`
        }
      },
      {
        field: 'totalIncomingTransfer',
        header: 'totalIncomingTransfer',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalIncomingTransferTooltip`
        }
      },
      {
        field: 'totalImmediateOutgoingTransfer',
        header: 'totalImmediateOutgoingTransfer',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalImmediateOutgoingTransferTooltip`
        }
      },
      {
        field: 'totalImmediateIncomingTransfer',
        header: 'totalImmediateIncomingTransfer',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalImmediateIncomingTransferTooltip`
        }
      },
      {
        field: 'totalCreditIssued',
        header: 'totalCreditIssued',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalCreditIssuedTooltip`
        }
      },
      {
        // field: 'totalOffered',
        header: 'totalOffered',
        width: AUTO_WIDTH_FOR_COLUMNS,
        // numeric: true,
        templateRef,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalOfferedTooltip`
        }
      },
      {
        field: 'totalReceivedCcm',
        header: 'totalReceivedCcm',
        width: AUTO_WIDTH_FOR_COLUMNS,
        numeric: true,
        inlineTooltip: true,
        toolTip: {
          text: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList.totalReceivedCcmTooltip`
        }
      },
    ],
  };
};

export const getStaticText = (label: string, className: string = 'col-12', hideExpression: string = null,): IRawForm => {
  return getLabel({
    label,
    className,
    hideExpression,
  });
};

export const REVIEW_CREDITS_TOTAL_COLUMNS = [
  'totalCreditSubmitted',
  'totalOutgoingTransfer',
  'totalIncomingTransfer',
  'totalImmediateOutgoingTransfer',
  'totalImmediateIncomingTransfer',
  'totalCreditIssued',
  'totalOffered',
  'totalReceivedCcm'
];

export const CREDIT_BALANCE_STEP_COMPONENT_MAP = {
  "STEP-1": 'ReviewCreditInfoComponent',
  "STEP-2": 'SelectPledgedCreditsComponent',
  "STEP-3": 'CreditBalanceContactPersonComponent'
};

export const CB_PLEDGED_CREDITS_UNIT_TABLE_CONFIG: ITableViewConfig = {
  translationPrefix: `${CREDIT_BALANCE_REPORT_PREFIX}.reportList`,
  paginator: true,
  rowsPerPage: 10,
  columns: [
    {
      checkboxSelect: true,
      width: '3em',
    },
    {
      field: 'complianceYear',
      sortField: 'complianceYear',
      header: 'complianceYear',
    },
    {
      field: 'creditTypeCode',
      sortField: 'unitType',
      header: 'unitType',
      translate: true,
      translationPrefix: 'COMMON.creditClassList',
    },
    {
      field: 'creditGroupName',
      sortField: 'creditGroupName',
      header: 'creditGroupName',
      translate: true,
      translationPrefix: 'COMMON.creditGroupList',
    },
    {
      field: 'serialNumber',
      sortField: 'serialNumber',
      header: 'serialNumber',
    },
    {
      field: 'quantity',
      sortField: 'quantity',
      header: 'quantity',
      numeric: true
    },
    {
      field: 'status',
      sortField: 'status',
      header: 'status',
      translate: true,
      translationPrefix: 'COMMON.statusList'
      // toolTip: {
      //   text: 'tooltip CB'
      // },
    }
  ]
};

export const FIELDS_TO_OMIT = ['reportCreditDetails', 'reportDetails'];


