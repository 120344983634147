import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@shared/pipes/shared-pipes.module';
import { ProjectDiscussionAddCommentComponent } from '../project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.component';
import { ProjectDiscussionAddCommentModule } from '../project-discussion-add-comment/project-discussion-add-comment.module';
import { ProjectDiscussionChatControlsComponent } from './components/project-discussion-chat-controls/project-discussion-chat-controls.component';
import { ProjectDiscussionChatHeaderComponent } from './components/project-discussion-chat-header/project-discussion-chat-header.component';
import { ProjectDiscussionChatRepliesComponent } from './components/project-discussion-chat-replies/project-discussion-chat-replies.component';
import { ProjectDiscussionChatReplyComponent } from './components/project-discussion-chat-reply/project-discussion-chat-reply.component';
import { ProjectDiscussionChatComponent } from './components/project-discussion-chat/project-discussion-chat.component';

@NgModule({
  declarations: [
    ProjectDiscussionChatComponent,
    ProjectDiscussionChatHeaderComponent,
    ProjectDiscussionChatRepliesComponent,
    ProjectDiscussionChatReplyComponent,
    ProjectDiscussionChatControlsComponent,
  ],
  exports: [
    ProjectDiscussionChatComponent,
    ProjectDiscussionChatHeaderComponent,
    ProjectDiscussionChatRepliesComponent,
    ProjectDiscussionChatReplyComponent,
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    ProjectDiscussionAddCommentModule,
  ],
  entryComponents: [
    ProjectDiscussionAddCommentComponent,
  ],
})
export class ProjectDiscussionChatModule { }
