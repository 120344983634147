import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../store/store.service";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../shared/services/helper.service";
export class MOPGuard {
    constructor(store, router, translate, helperService) {
        this.store = store;
        this.router = router;
        this.translate = translate;
        this.helperService = helperService;
    }
    canActivate(next, state) {
        const permission = next.data.permission;
        const user = this.store.user;
        if (user.hasPermission(permission)) {
            return of(true);
        }
        else {
            const initialPath = this.store.user.menuItems[0].path;
            this.router.navigate(this.helperService.getTranslatedPath(`/${initialPath}`));
            return of(false);
        }
    }
}
MOPGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MOPGuard_Factory() { return new MOPGuard(i0.ɵɵinject(i1.StoreService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.TranslateService), i0.ɵɵinject(i4.HelperService)); }, token: MOPGuard, providedIn: "root" });
