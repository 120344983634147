import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
//export const USER_MGMT_GET_USER = 'http://localhost:8084/api/account-service/user';
export const USER_MGMT_GET_USER = '/account-service/private/user';
export const USER_MGMT_SEARCH_PRIVATE_USERS = '/account-service/private/user/newpageSearch';
export const USER_LOGIN_DETAILS = '/account-service/user/loginDetails';
export class UserManagementService extends AbstractPaginationService {
    constructor(http) {
        super();
        this.http = http;
    }
    getAll(pagination, filterState = {}) {
        let params = new HttpParams();
        params = this.addPagination(params, pagination);
        return this.http.post(`${environment.apiUrl}${USER_MGMT_SEARCH_PRIVATE_USERS}`, filterState, { params });
    }
    get(userId) {
        return this.http.get(`${environment.apiUrl}${USER_MGMT_GET_USER}/${userId}`);
    }
    getUserLogin(user) {
        return this.http.post(`${environment.apiUrl}${USER_LOGIN_DETAILS}`, user);
    }
}
UserManagementService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserManagementService_Factory() { return new UserManagementService(i0.ɵɵinject(i1.HttpClient)); }, token: UserManagementService, providedIn: "root" });
