import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-result-report-modal',
  templateUrl: './result-report-modal.component.html',
  styleUrls: ['./result-report-modal.component.scss']
})
export class ResultReportModalComponent {

  public visible = true;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ResultReportModalComponent>,
  ) { }

  close(): void {
    this.dialogRef.close();
  }

  approve(): void {
    this.dialogRef.close({type: 0});
  }

  reject(): void {
    this.dialogRef.close({type: 1});
  }

}
