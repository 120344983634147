import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProjectMilestoneService } from '@shared/components/project/components/project-milestones/project-milestone.service';
import { InputSwitchModule } from 'primeng';
import { ProjectMilestonesComponent } from './components/project-milestones/project-milestones.component';

@NgModule({
  declarations: [ProjectMilestonesComponent],
  exports: [
    ProjectMilestonesComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InputSwitchModule,
  ],
  providers: [
    ProjectMilestoneService,
  ],
})
export class ProjectMilestonesModule { }
