import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-template-popup',
  templateUrl: './template-popup.component.html',
  styleUrls: ['./template-popup.component.scss'],

})
export class TemplatePopupComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TemplatePopupComponent>,
  ) {  }

  ngOnInit() {
  }

  get template(): string {
    return this.data && this.data.template;
  }

  get title(): string {
    return this.data && this.data.title;
  }

  get width(): string {
    return this.data && this.data.width || '500px';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
