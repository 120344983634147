
    <div [id]="elementId + '_chips'">
      <input type="hidden" [id]="elementId" class="wcag-visuallyhidden">
      <div class="mb-2">
        <ng-container *ngFor="let item of list; let i = index">
          <div class="badge badge-primary badge-tag simple-tag">
          {{ item }}
            <button type="button" class="close" aria-label="Close / Delete" (click)="remove(i)">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </ng-container>
      </div>

      <div class="row">
        <div class="input-group mb-3 col-6">
          <input type="text"
                 class="form-control"
                 [(ngModel)]="newItem"
                 [class.ng-invalid]="formControl.invalid"
                 [formlyAttributes]="field"
                 [placeholder]="placeholder"
                 [attr.maxLength]="maxLength ? maxLength : null">
            <div class="input-group-append">
              <button class="btn btn-secondary" type="button" (click)="addItem()" aria-label="Add item">{{ buttonText }}</button>
            </div>
        </div>
      </div>
    </div>
  