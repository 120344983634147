/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-activity-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../project-activity-form/project-activity-form.component.ngfactory";
import * as i5 from "../project-activity-form/project-activity-form.component";
import * as i6 from "../../../services/form-factory.service";
import * as i7 from "../../../services/modal.service";
import * as i8 from "../project-builder.service";
import * as i9 from "../../../services/document-in-progress.service";
import * as i10 from "../../../services/upload-document.service";
import * as i11 from "../../../services/project.service";
import * as i12 from "../../../services/form.service";
import * as i13 from "../../../../core/store/store.service";
import * as i14 from "../project-activity-list/project-activity-list.component.ngfactory";
import * as i15 from "../project-activity-list/project-activity-list.component";
import * as i16 from "../../../../core/services/lookup.service";
import * as i17 from "@angular/common";
import * as i18 from "./project-activity-selector.component";
var styles_ProjectActivitySelectorComponent = [i0.styles];
var RenderType_ProjectActivitySelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectActivitySelectorComponent, data: {} });
export { RenderType_ProjectActivitySelectorComponent as RenderType_ProjectActivitySelectorComponent };
function View_ProjectActivitySelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showActivityList() !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.showActivityList() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("PROJECTS_MODULE.ProjectBuilder.changeType")); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectActivitySelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-project-activity-form", [], null, null, null, i4.View_ProjectActivityFormComponent_0, i4.RenderType_ProjectActivityFormComponent)), i1.ɵdid(2, 4440064, null, 0, i5.ProjectActivityFormComponent, [i6.FormFactoryService, i7.ModalService, i8.ProjectBuilderService, i3.TranslateService, i9.DocumentUploadInProgressService, i10.UploadDocumentService, i11.ProjectService, i1.Renderer2, i12.FormService, i13.StoreService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_ProjectActivitySelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "pl-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-project-activity-list", [], null, null, null, i14.View_ProjectActivityListComponent_0, i14.RenderType_ProjectActivityListComponent)), i1.ɵdid(2, 114688, null, 0, i15.ProjectActivityListComponent, [i8.ProjectBuilderService, i16.LookupService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ProjectActivitySelectorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "pt-3 pr-4 pl-4 pb-5 activity-panel"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "pl-2 mt-3 border-bottom d-flex justify-content-between"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActivitySelectorComponent_1)), i1.ɵdid(7, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActivitySelectorComponent_2)), i1.ɵdid(9, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["listView", 2]], null, 0, null, View_ProjectActivitySelectorComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.editMode && _co.inProcess); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.editMode; var currVal_3 = i1.ɵnov(_v, 10); _ck(_v, 9, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("PROJECTS_MODULE.ProjectBuilder.projectType")); _ck(_v, 4, 0, currVal_0); }); }
export function View_ProjectActivitySelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-activity-selector", [], null, null, null, View_ProjectActivitySelectorComponent_0, RenderType_ProjectActivitySelectorComponent)), i1.ɵdid(1, 114688, null, 0, i18.ProjectActivitySelectorComponent, [i8.ProjectBuilderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectActivitySelectorComponentNgFactory = i1.ɵccf("app-project-activity-selector", i18.ProjectActivitySelectorComponent, View_ProjectActivitySelectorComponent_Host_0, {}, {}, []);
export { ProjectActivitySelectorComponentNgFactory as ProjectActivitySelectorComponentNgFactory };
