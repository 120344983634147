import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { IApp } from '@core/models/app.interfaces';
import { Observable } from 'rxjs';
import { TermsAndConditions } from '@core/models/termsAndConditions.model';

export const TANDC_ACCEPT = '/account-service/tandc/accept';
export const TANDC_SAVE = '/account-service/tandc/save';
export const TANDC_UNACCEPTED_BY_USER_ID = '/account-service/tandc/unacceptedByUser/';
export const TANDC_ACCEPTED_BY_USER = '/account-service/tandc/acceptedByUser';
export const PRIVACY_POLICY = '/account-service/tandc/privacyPolicy';
export const REGISTRATION_PRIVACY_POLICY = '/account-service/lookup/privacyPolicy';

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService {

  constructor(private http: HttpClient) { }

  acceptTermsAndConditions(userTAndC ): Observable<IApp.IUserTandc>{
    return this.http.post<IApp.IUserTandc>(`${ environment.apiUrl }${ TANDC_ACCEPT }`, userTAndC);
  }

  saveTandC(newTandC): Observable<TermsAndConditions>{
    return this.http.post<TermsAndConditions>(`${ environment.apiUrl }${ TANDC_SAVE }`, newTandC);
  }

  getTandCUnacceptedByUserId(id): Observable<any>{
    return this.http.get<any>(`${ environment.apiUrl }${ TANDC_UNACCEPTED_BY_USER_ID }${id}`);
  }

  getTandAcceptedByUser(): Observable<any>{
    return this.http.get<any>(`${ environment.apiUrl }${ TANDC_ACCEPTED_BY_USER }`);
  }

  getPrivacyPolicy(): Observable<any>{
    return this.http.get<any>(`${ environment.apiUrl }${ PRIVACY_POLICY }`);
  }

  getRegistrationPrivacyPolicy(programId): Observable<any>{
    let params = new HttpParams();
    params = params.append('programId', `${programId}`);
    return this.http.get<any>(`${ environment.apiUrl }${ REGISTRATION_PRIVACY_POLICY }`, {params});
  }
}
