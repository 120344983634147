import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { programIdKey,ignoreTranslationPrefix } from '@core/store/store.service';
import { Subject } from 'rxjs/internal/Subject';

export const TRANSLATION_SOURCE_URI = '/cms-server/cms/getByLanguage';
export const TRANSLATION_S3_SOURCE_URI = '/cms-site';
const REGEXP = new RegExp('^C[0-9]+-');
     
@Injectable({
  providedIn: 'root',
})
export class CustomTranslateLoader implements TranslateLoader  {
    contentHeader = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    private reset$ = new Subject();

    constructor(
      private http: HttpClient,
    ) {}

    transformTranslationFile(payload, ignoreTranslationPrefix) {
      const flattenObject = (obj, prefix = '') =>
      Object.keys(obj).reduce((acc, k) => {
          const pre = prefix.length ? prefix + '.' : '';
          if (typeof obj[k] === 'object' && k !== 'contentList') {
            Object.assign(acc, flattenObject(obj[k], pre + k));
          } else {
            if (k !== 'contentList') {
              acc[pre + k] = obj[k];
            } else {
              acc[pre.slice(0, -1)] = obj[k];
            }
          }
          return acc;
      }, {});

      const flatResponse = flattenObject(payload.contentResponse);
      Object.keys(flatResponse).forEach(k => {
        Array.isArray(flatResponse[k]) && (flatResponse[k] = flatResponse[k].map(l => ({ [l.name]: l.value ? 
          this.getValue(l.value, ignoreTranslationPrefix) : ''})).reduce((obj, item) => ({...obj, ...item}), {}));
      });
      return flatResponse;
    }

    get programId(): string {
      return sessionStorage.getItem(programIdKey) || null;
    }

    getValue(value, ignoreTranslationPrefix){
      if(ignoreTranslationPrefix) {
        //console.log(value + ' ' + REGEXP.test(value));
        return REGEXP.test(value) ? value.substring(6): value;
      }
      return value;
    }

    getTranslation(lang: string): Observable<any> {
      const uiVersion = sessionStorage.getItem('uiVersion') ;
      const ignoreTranslationPrefix = sessionStorage.getItem('ignoreTranslationPrefix') ;
      
      this.reset$.next(true);
      const langPath = this.programId ? `${lang}/${this.programId}` : `${lang}/obps`;
      const langS3Path = this.programId ? `${lang}-${this.programId}` : `${lang}-obps`;

      //let uri = `${ environment.apiUrl }${ TRANSLATION_SOURCE_URI }/${langPath}?t=${uiVersion}`;
      let uri = `${ environment.apiUrl }${ TRANSLATION_S3_SOURCE_URI }/cms-${langS3Path}.json?t=${uiVersion}`;
    //  uri = `/assets/i18n/${langPath}.json`;  // @Dev team, please uncomment for local development purposes. Do not check-in this change

    console.log('LANG URI: ', uri);
      return this.http.get(uri)
       .pipe(
          takeUntil(this.reset$),
          catchError(_ => this.http.get(`/assets/i18n/${langPath}.json`)),
          map(results => this.transformTranslationFile(results, ignoreTranslationPrefix  === 'true')),
        );
    }
}
