import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IApp } from '@core/models/app.interfaces';
import { SessionData } from '@core/models/auth.model';
import { CurrentUser } from '@core/models/user.model';
import { environment } from '@env/environment';
import { HelperService } from '@shared/services/helper.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { map } from 'rxjs/operators';
import { ACCOUNT_TYPE_IDS, BUSINESS_STRUCTURE_IDS_BY_PROGRAM } from '@shared/components/stepper/stepper.const';

const userDataKey = 'userData';
const sessionDataKey = 'sessionData';
const lastVisitedUrlKey = 'last_visited_url';
const obligationId = 'obligationId';
const tcAccepted = 'tcAccepted';
const uiVersion = 'uiVersion';
export const ignoreTranslationPrefix = 'ignoreTranslationPrefix';
export const PROPERTY = '/account-service/property/get?key=';
const REGISTRY_SERVER_PROPERTY = '/registry-server/property/get?key=';
const accessKey = 'accessKey';
const historyUrl = 'historyUrl';
const accountStorageKey = 'global_account';
export const programIdKey = 'program_id';
export const defaultAccountSelected = -1;
const DISPLAY_ACTION_BUTTONS_PROP = 'display.action.buttons';
export const ENABLE_VERIFICATION_FLOW = 'enable.verification.flow';
const SESSION_INACTIVITY_TIME_PROP = 'session.inactive.validity.time';
const CFS_ENABLED_DATE_PROP = 'cfs.enabled.date';
const UI_DATE_REVISED_PROP = 'ui.date.revised';
const ENABLE_OFFSET_PROP = 'enable.offset';
const NEW_NOE_KEY = 'cached_new_notice_of_error';
const AAG_INFO_VISIBLE = 'AAG_info_visible';
export const ENABLE_VERIFICATION_FLOW_CI_APP_START_DATE = 'enable.verification.flow.ci.application.start.date';
export const TRANSLATION_PREFIX_IGNORE_PROPERTY = 'application.ignoreTranslationPrefix';

@Injectable({
  providedIn: 'root',
})
export class StoreService {

  private readonly _user$ = new BehaviorSubject<CurrentUser>(null);
  readonly user$ = this._user$.asObservable();
  private readonly _session$ = new BehaviorSubject<SessionData>(null);

  private _revisedDate$ = new BehaviorSubject<any>(null);
  readonly revisedDate$ = this._revisedDate$.asObservable();

  private _ignoreTranslationPrefix$ = new BehaviorSubject<any>(null);
  readonly ignoreTranslationPrefix$ = this._ignoreTranslationPrefix$.asObservable();

  private readonly _accountFacilities$ = new BehaviorSubject<number[]>([defaultAccountSelected]);
  readonly accountFacilities$ = this._accountFacilities$.asObservable();

  private readonly _loginAvailable$ = new BehaviorSubject<boolean>(false);
  readonly loginAvailable$ = this._loginAvailable$.asObservable();

  private readonly _accountSubTypes$ = new BehaviorSubject<any>(null);
  readonly accountSubTypes$ = this._accountSubTypes$.asObservable();

  constructor(
    private http: HttpClient,
    private router: Router,
    private _location: Location,
    private helperService: HelperService,
    private navigatioService: NavigationService,
  ) {
    this.getActionButtonsState();
    // Get userData and sessionData from sessionStorage if available
    this._user$.next(this.load(userDataKey));
    this._session$.next(this.load(sessionDataKey));
    this.getRevisedDate().subscribe(
      data => {
        this._revisedDate$.next(data.value);
        sessionStorage.setItem(uiVersion, data.component );
      },
    );

    this.getIgnoreTranslationPrefix().subscribe(
      data => {
        this._ignoreTranslationPrefix$.next(data.value);
        sessionStorage.setItem(ignoreTranslationPrefix, data.value);
      },
    );

    const selectedAccounts = sessionStorage.getItem(accountStorageKey);
    try {
      if (selectedAccounts) {
        this._accountFacilities$.next(JSON.parse(selectedAccounts));
      }
    } catch (e) {
      this.accountFacilities = [defaultAccountSelected];
    }
  }

  setTcAccept(value: string) {
    sessionStorage.setItem(tcAccepted, value );
  }

  getTcAccept() {
    return sessionStorage.getItem(tcAccepted);
  }

  setAccessKey(value: string) {
    sessionStorage.setItem(accessKey, value );
  }

  removeAcceptedTc(id) {
    const user = this.user;
    user.tandcContents = user.tandcContents.filter(el => el.id !== id);
    this.user = user;
  }

  getAccessKey() {
    const key =  sessionStorage.getItem(accessKey);
    return key === 'null' ? null : key;
  }

  setHistoryUrl() {
    sessionStorage.setItem(historyUrl, this.router.url );
  }

  resetHistoryUrl() {
    sessionStorage.removeItem(historyUrl);
  }

  getHistoryUrl() {
    const key =  sessionStorage.getItem(historyUrl);
    return key === 'null' ? null : key;
  }

  back() {
    const url = this.getHistoryUrl();
    if (url) {
      this.resetHistoryUrl();
      this.router.navigate(this.helperService.getTranslatedPath(url));
    } else {
      this.navigatioService.back();
    }
  }

  removeTcAccept() {
    sessionStorage.removeItem(tcAccepted);
  }

  set user(user: CurrentUser) {
    this._user$.next(user);
    if (user) {
      this.save(userDataKey, user);
      if (user.program) {
        sessionStorage.setItem(programIdKey, user.program.toLowerCase());
      }
    }
  }

  get user() {
    return new CurrentUser(this._user$.getValue());
  }

  checkTCAcceptance() {
    const user = this.user;
    if (user && user.tandcContents && user.tandcContents.length > 0) {
      this.setTcAccept('false');
    } else {
      this.setTcAccept('true');
    }
  }

  public get currentProgram(): string {
    return this.user.program;
  }

  public isProgram(code): boolean {
    return this.currentProgram.toLowerCase() === code.toLowerCase();
  }

  set session(session: SessionData) {
    this._session$.next(session);
    if (session) {
      this.save(sessionDataKey, session);
    }
  }

  getSession() {
    return this._session$.getValue();
  }

  private save(itemKey, value) {
    sessionStorage.setItem(itemKey, JSON.stringify(value));
  }

  private load(itemKey) {
    const userData = sessionStorage.getItem(itemKey);
    if (userData) {
      try {
        return JSON.parse(userData);
      } catch {
        return null;
      }
    }
  }

  getActionButtonsState() {
    this.getProperty(DISPLAY_ACTION_BUTTONS_PROP).subscribe(
      data => {
        this._loginAvailable$.next(data.value === 'true');
      },
    );
  }


  getIgnoreTranslationPrefix(): Observable<IApp.Property> {
    return this.getProperty(TRANSLATION_PREFIX_IGNORE_PROPERTY);
  }

  getRevisedDate(): Observable<IApp.Property> {
    return this.getProperty('ui.date.revised');
  }

  public gerProgramAdminEmail(): Observable<IApp.Property> {
    return this.getProperty('email.group.programAdmin');
  }

  public getAllowedDocTypes(): Observable<IApp.Property> {
    return this.getProperty('doc.allowed.file.types');
 }

  public getOffsetMinStartDate(): Observable<IApp.Property> {
    return this.getProperty('project.offset.startDate.minimumValue');
  }

  public getProperty(propertyName: string): Observable<IApp.Property> {
    let propertyUrl =  PROPERTY;
    if (propertyName === SESSION_INACTIVITY_TIME_PROP ||  propertyName === DISPLAY_ACTION_BUTTONS_PROP
      || propertyName === CFS_ENABLED_DATE_PROP ||  propertyName === UI_DATE_REVISED_PROP ||
      propertyName === ENABLE_OFFSET_PROP || propertyName === ENABLE_VERIFICATION_FLOW) {
      propertyUrl =  REGISTRY_SERVER_PROPERTY;
    }

    return this.http.get<IApp.Property>(`${environment.apiUrl}${propertyUrl}${propertyName}`)
      .pipe(
        map(data => {
          if (propertyName === ENABLE_VERIFICATION_FLOW) {
            data.value = data.value ? data.value.toLowerCase() : data.value ;
          }
          return data;
        }),
      );
  }

  public set accountFacilities(ids: number[] | null) {
    if (ids) {
      sessionStorage.setItem(accountStorageKey, JSON.stringify(ids));
    } else {
      sessionStorage.removeItem(accountStorageKey);
    }
    this._accountFacilities$.next(ids);
  }

  public get accountFacilities(): number[] {
    return this._accountFacilities$.getValue() || [-1];
  }

  set lastVisitedUrl(url: string) {
    sessionStorage.setItem(lastVisitedUrlKey, JSON.stringify(url));
  }

  get lastVisitedUrl(): string {
    const url = sessionStorage.getItem(lastVisitedUrlKey);
    return url && JSON.parse(url);
  }

  set obligationId(url: string) {
    sessionStorage.setItem(obligationId, url);
  }

  get obligationId(): string {
    return sessionStorage.getItem(obligationId);
  }

  public clearLastVisitedUrl(): void {
    sessionStorage.removeItem(lastVisitedUrlKey);
  }

  storeObligationId(id): void {
    this.obligationId = id;
  }

  public clearObligationId(): void {
    sessionStorage.removeItem(obligationId);
  }

  storeCurrentUrl(): void {
    this.lastVisitedUrl = window.location.pathname;
  }

  get isOffsetPublicPage(): boolean {
    return this.router.url.includes('public');
  }

  set activeNOE(data: {formType: string, id: number}) {
    sessionStorage.setItem(NEW_NOE_KEY, JSON.stringify(data));
  }

  get activeNOE() {
    const result = sessionStorage.getItem(NEW_NOE_KEY);
    return result && JSON.parse(result);
  }

  public clearActiveNOE() {
    sessionStorage.removeItem(NEW_NOE_KEY);
  }

  destroy() {
    sessionStorage.clear();
    this.accountFacilities = null;
    this.user = null;
    this.session = null;
  }

  getAccountTypeIdByProgramType() {
    return this.user.isCM() ? ACCOUNT_TYPE_IDS.CM : ACCOUNT_TYPE_IDS.OFFSET;
  }

  getBusinessStructureIdByProgramType() {
    return this.user.isCM() ? BUSINESS_STRUCTURE_IDS_BY_PROGRAM.CM : BUSINESS_STRUCTURE_IDS_BY_PROGRAM.OFFSET;
  }

  setAAGInfoVisibility(visibility): void {
    sessionStorage.setItem(AAG_INFO_VISIBLE, visibility);
  }

  getAAGInfoVisibility(): string {
    return sessionStorage.getItem(AAG_INFO_VISIBLE);
  }

  setCOIDataObject(key, object): void {
    sessionStorage.setItem(key, object);
  }

  getCOIDataObject(key) {
    return sessionStorage.getItem(key);
  }

  removeCOIDataObject(key) {
    sessionStorage.removeItem(key);
  }

  setAccountSubTypes(subTypes) {
    this._accountSubTypes$.next(subTypes);
  }
}
