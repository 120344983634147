import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';

const DEFAULT_HEIGHT = 60;
const PADDING_BOTTOM = 20;

@Directive({
  selector: '[fit-to-label]',
})
export class FitToLabelDirective implements OnInit, AfterViewInit {

  observer: any;
  el: any;

  constructor(
    private elRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.el = this.elRef.nativeElement;
    this.updateHeight();
  }

  ngAfterViewInit(): void {
    this.updateHeight();
  }

  @HostListener('window:resize', ['$event'])
  updateHeight() {
    const labelEl = this.el.querySelector('a span');
    const labelAnchorEl = this.el.querySelector('a');
    const labelHeight = labelEl.offsetHeight;
    const labelSubStepEl = this.el.querySelector('.sub-steps');
    if (labelEl.offsetHeight > DEFAULT_HEIGHT) {
      labelAnchorEl.style.height = `${ labelHeight }px`;
      this.el.setAttribute('style', `height: ${ labelHeight + PADDING_BOTTOM }px !important`);
      if (labelSubStepEl) {
        labelSubStepEl.style.marginTop = `${ labelHeight + 20 }px`;
      }
    }
  }

}
