import { IApp } from './app.interfaces';

export class Pgination implements IApp.IPagination{
    page: number;
    size: number;
    sort: string;
    dir: string;

    constructor(page,size){
        this.page = page;
        this.size = size;
    }
}
