import { Component, OnInit, Inject, ViewChild, } from '@angular/core';
import { LookupService } from '@core/services/lookup.service';
import { ITableViewConfig } from '@shared/models/table-view.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IApp } from '@core/models/app.interfaces';
import { cloneDeep, isEqual } from 'lodash';
import { MaterialBalanceReportService } from '../material-balance-report.service';
import { Dialog } from 'primeng';

@Component({
  selector: 'app-view-reconciliation-details',
  templateUrl: './view-reconciliation-details.component.html',
  styleUrls: ['./view-reconciliation-details.component.scss']
})
export class ViewReconciliationDetailsComponent implements OnInit {

  @ViewChild('dialog', { static: false }) dialog: Dialog;

  public tableConfig: ITableViewConfig;
  public tableData;
  public viewReady = false;
  public visible = true;
  public formHeader = 'VIEW_RECONCILIATION.table.tableHeader';
  private pagination = {
    page: 0,
    size: 20,
    sort: undefined,
    dir: undefined,
  } as IApp.IPagination;
  public firstItemIndex = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<ViewReconciliationDetailsComponent>,
    private lookupService: LookupService,
    private materialBalanceReportService: MaterialBalanceReportService,
  ) { }

  scrollToTop(): void {
    setTimeout(() => {
      this.dialog.el.nativeElement.querySelector('.ui-dialog-content').scrollTo(0, 0);
    }, 500);
  }

  ngOnInit() {
    this.getTableConfig();
  }

  private getTableConfig() {
    this.lookupService.getTableConfigurations('VIEW_RECONCILIATION').subscribe(data => {
      this.tableConfig = data;
      this.viewReady = true;
      this.loadData(this.pagination);
    });
  }

  private loadData(pagination: IApp.IPagination) {
    this.materialBalanceReportService.getViewReconciliationData(pagination, this.data.reportId).subscribe(result => {
      this.tableData = result;
    });
  }

  public onPaginationChanged(pagination: IApp.IPagination) {
    if (isEqual(this.pagination, pagination)) {
      return;
    }
    this.pagination = cloneDeep(pagination);
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    this.loadData(this.pagination);
  }

  public close() {
    this.dialogRef.close();
  }

}
