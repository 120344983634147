/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./project-activity-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../project-activity-card/project-activity-card.component.ngfactory";
import * as i3 from "../project-activity-card/project-activity-card.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./project-activity-list.component";
import * as i7 from "../project-builder.service";
import * as i8 from "../../../../core/services/lookup.service";
var styles_ProjectActivityListComponent = [i0.styles];
var RenderType_ProjectActivityListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProjectActivityListComponent, data: {} });
export { RenderType_ProjectActivityListComponent as RenderType_ProjectActivityListComponent };
function View_ProjectActivityListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-project-activity-card", [], null, [[null, "selectionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectionChange" === en)) {
        var pd_0 = (_co.selectCard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_ProjectActivityCardComponent_0, i2.RenderType_ProjectActivityCardComponent)), i1.ɵdid(2, 245760, null, 0, i3.ProjectActivityCardComponent, [i4.TranslateService], { cardInfo: [0, "cardInfo"] }, { selectionChange: "selectionChange" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProjectActivityListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProjectActivityListComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProjectActivityListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-project-activity-list", [], null, null, null, View_ProjectActivityListComponent_0, RenderType_ProjectActivityListComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProjectActivityListComponent, [i7.ProjectBuilderService, i8.LookupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectActivityListComponentNgFactory = i1.ɵccf("app-project-activity-list", i6.ProjectActivityListComponent, View_ProjectActivityListComponent_Host_0, {}, {}, []);
export { ProjectActivityListComponentNgFactory as ProjectActivityListComponentNgFactory };
