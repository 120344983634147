import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appRouterLink]',
})
export class RouterLinkDirective implements OnInit, OnDestroy {

  @Input('appRouterLink') routerLink: string;
  unsubscribe;

  constructor(
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.unsubscribe = this.renderer.listen(this.element.nativeElement, 'click', event => {
      event.preventDefault();
      event.stopPropagation();
      this.router.navigateByUrl(`/${this.translate.currentLang}/${this.routerLink}`);
    });
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

}
