import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StoreService } from '@core/store/store.service';
import { HelperService } from '@shared/services/helper.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirstTabRedirectGuard implements CanActivate {

  constructor(
    private store: StoreService,
    private router: Router,
    private helperService: HelperService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const initialPath = this.store.user.menuItems[0].path;
    this.router.navigate(this.helperService.getTranslatedPath(`/${initialPath}`));
    return of(false);
  }
}
