import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-button',
  template: `
    <div>
      <button type="button" [ngClass]="'btn btn-' + to.btnType + ' ' + to.className" (click)="onClick($event)" [disabled]="to.disabled" [tabIndex]="tabIndex">
        {{ to.text | translate }}
      </button>
    </div>
  `,
})
export class FormlyFieldButton extends FieldType {

  constructor() {
    super();
  }

  onClick($event): void {
    $event.preventDefault();
    if (this.to.onClick) {
      this.to.onClick($event, this.form);
    }
  }

  public get tabIndex(): number {
    return this.to.tabindex || this.field.hide ? -1 : 0;
  }

}
