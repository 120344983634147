import { ITableViewConfig, ROWS_PER_PAGE} from '@shared/models/table-view.model';

export const FORM_TITLE = 'UNITS_MODULE.creditTransferForm.pageTitle';

export const URI_SUBMIT_TRANSFER = '/doCreditTransfer';
export const URI_PROPOSE_TRANSFER = '/doCreditTransferPropose';
export const URI_APPROVE_TRANSFER_ADMIN = '/doCreditTransferAdminApprove';
export const URI_REJECT_TRANSFER_ADMIN = '/doCreditTransferAdminReject';
export const URI_APPROVE_TRANSFER_BUYER = '/doCreditTransferBuyerApprove';
export const URI_REJECT_TRANSFER_BUYER = '/doCreditTransferBuyerReject';
export const URI_CANCEL_TRANSFER = '/doCreditTransferCancel';
export const URI_APPROVE_TRANSFER_PROPOSE = '/doCreditTransferProposeApprove';
export const URI_REJECT_TRANSFER_PROPOSE = '/doCreditTransferProposeReject';
export const URI_APPROVE_TRANSFER_SECOND = '/doCreditTransferAdminSecondApprove';
export const URI_REJECT_TRANSFER_SECOND = '/doCreditTransferAdminSecondReject';
export const URI_RESUBMIT_TRANSFER = '/doCreditTransferResubmit';

export const CFR_CALCULATED_CI_VOLUME_FLD = 'calculatedVolumeOfLowCiFuel';
export const CFR_CARBON_INTENSITY_ID_FLD = 'carbonIntensityId';
export const CFR_CREDIT_QTY_AVAILABLE = 'totalCreditQuantityAvailible';

export const SEARCHABLE_ACCOUNT_ID_VALID = '_searchableAccountIdValid';

export const creditTransferConfig: ITableViewConfig = {
    translationPrefix: 'UNITS_MODULE.creditTransferForm',
    paginator: true,
    rowsPerPage: ROWS_PER_PAGE,
    editService:'/creditAction/doCreditSuspensionRespond',
    columns: [],
  };

export const CREDIT_TRANSFER_ACCOUNT_DETAILS = [
  {
    type: 'readonly-input',
    wrappers: ['form-field'],
    key: 'facilityName',
    templateOptions: {
      label: 'UNITS_MODULE.creditTransferForm.sourceLabel',
      required: true,
    },
  },
];

export const CFR_CREDIT_TRANSFER_SOURCE_AGENT = [
  {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'fullName',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.agentName',
        },
      },
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'title',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.agentTitle',
        },
      },
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'businessTelephone',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.agentPhone',
        },
      },
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'email',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.email',
        },
      },
    ],
  },
];

export const CFR_LEGAL_NAME = {
  type: 'readonly-input',
  wrappers: ['form-field'],
  key: 'legalName',
  className: 'col-6',
  templateOptions: {
    label: 'UNITS_MODULE.creditTransferForm.legalName',
  },
};

export const CFR_CREDIT_TRANSFER_ACCOUNT_DETAILS = [
  {
    fieldGroupClassName: 'row',
    fieldGroup: [
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'accountId',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.organizationId',
        },
      },
      CFR_LEGAL_NAME,
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'englishTradeName',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.englishTradeName',
        },
      },
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'frenchTradeName',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.frenchTradeName',
        },
      },
      {
        type: 'readonly-input',
        wrappers: ['form-field'],
        key: 'registrationNumber',
        className: 'col-6',
        templateOptions: {
          label: 'UNITS_MODULE.creditTransferForm.businessNumber',
        },
      },
    ],
  },
  {
    fieldGroupClassName: 'row col-12',
    fieldGroup: [
      {
        template: 'UNITS_MODULE.creditTransferForm.sourceAccountOrgAddress',
        className: 'h5 col-12 p-0',
      },
      {
        type: 'address-view',
        wrappers: ['form-field'],
        key: 'postalAddress',
        className: 'col-6',
        templateOptions: {
          legend: 'UNITS_MODULE.creditTransferForm.postalAddress',
          addressKey: 'postalAddress',
        },
      },
      {
        type: 'address-view',
        wrappers: ['form-field'],
        key: 'civicAddress',
        className: 'col-6',
        templateOptions: {
          legend: 'UNITS_MODULE.creditTransferForm.civicAddress',
          addressKey: 'civicAddress',
        },
      },
    ]
  },
  {
    key: 'authorizedOfficial',
    fieldGroupClassName: 'row col-12',
    fieldGroup: [
      {
        template: 'UNITS_MODULE.creditTransferForm.sourceAgentDetailsLabel',
        className: 'h5 col-12 p-0',
      },
      ...CFR_CREDIT_TRANSFER_SOURCE_AGENT
    ]
  },
  {
    key: 'authorizedOfficial',
    fieldGroupClassName: 'row col-12',
    fieldGroup: [
      {
        template: 'UNITS_MODULE.creditTransferForm.agentAddressLabel',
        className: 'h5 col-12 p-0',
      },
      {
        type: 'address-view',
        wrappers: ['form-field'],
        key: 'postalAddress',
        className: 'col-6',
        templateOptions: {
          legend: 'UNITS_MODULE.creditTransferForm.postalAddress',
          addressKey: 'postalAddress',
        },
      },
      {
        type: 'address-view',
        wrappers: ['form-field'],
        key: 'civicAddress',
        className: 'col-6',
        templateOptions: {
          legend: 'UNITS_MODULE.creditTransferForm.civicAddress',
          addressKey: 'civicAddress',
        },
      },
    ]
  },
];

