/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cfs-vb-acc-reg.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i3 from "@ngx-formly/core";
import * as i4 from "@angular/forms";
import * as i5 from "./cfs-vb-acc-reg.component";
import * as i6 from "../../../../services/form-factory.service";
import * as i7 from "../../../../services/form.service";
import * as i8 from "../../../../../core/store/store.service";
import * as i9 from "../../../../../modules/registration/registration.service";
import * as i10 from "../../../../services/modal.service";
var styles_CfsVbAccRegComponent = [i0.styles];
var RenderType_CfsVbAccRegComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CfsVbAccRegComponent, data: {} });
export { RenderType_CfsVbAccRegComponent as RenderType_CfsVbAccRegComponent };
export function View_CfsVbAccRegComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { el: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["id", "cfs-vb-acc-reg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "formly-form", [], null, null, null, i2.View_FormlyForm_0, i2.RenderType_FormlyForm)), i1.ɵprd(512, null, i3.FormlyFormBuilder, i3.FormlyFormBuilder, [i3.FormlyConfig, i1.ComponentFactoryResolver, i1.Injector]), i1.ɵdid(4, 966656, null, 0, i3.FormlyForm, [i3.FormlyFormBuilder, i3.FormlyConfig, i1.ChangeDetectorRef, [8, null], [2, i4.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formGroup; var currVal_1 = _co.model; var currVal_2 = _co.fields; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CfsVbAccRegComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cfs-vb-acc-reg", [], null, null, null, View_CfsVbAccRegComponent_0, RenderType_CfsVbAccRegComponent)), i1.ɵdid(1, 245760, null, 0, i5.CfsVbAccRegComponent, [i6.FormFactoryService, i7.FormService, i8.StoreService, i9.RegistrationService, i10.ModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CfsVbAccRegComponentNgFactory = i1.ɵccf("app-cfs-vb-acc-reg", i5.CfsVbAccRegComponent, View_CfsVbAccRegComponent_Host_0, { inputData: "inputData", executeLastEvent: "executeLastEvent", onSaveAsDraft: "onSaveAsDraft", onSubmit: "onSubmit", onSaveAndProceed: "onSaveAndProceed" }, { stepComplete: "stepComplete" }, []);
export { CfsVbAccRegComponentNgFactory as CfsVbAccRegComponentNgFactory };
