export const LOCALES = ['en', 'fr'];
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const TIMEZONE = 'America/New_York';
export const UTC_TIMEZONE = 'UTC';
export const COLUMN_WIDTH = 130;

export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';


export const PERMISSION_DISCLOSE_COMMENTS = 'CREDIT_DISCLOSE_REMARKS';
export const PERMISSION_PROPOSE_TRANSACTION = 'PROPOSE_TRANSACTION';
export const PERMISSION_CREATE_APPLICATION = 'CREATE_APPLICATION';
export const PERMISSION_SUBMIT_APPLICATION = 'SUBMIT_APPLICATION';
export const PERMISSION_ADD_REMARKS = 'ADD_REMARKS';
export const PERMISSION_DISCLOSE_REMARKS = 'DISCLOSE_REMARKS';
export const PERMISSION_VIEW_DISCLOSE_REMARKS = 'VIEW_DISCLOSE_REMARKS';
export const PERMISSION_SECURITY_CONFIGURATION = 'SUPER_SECURITY_CONFIGURATION';
export const PERMISSION_ADMIN_SECURITY_CONFIGURATION = 'ADMIN_SECURITY_CONFIGURATION';
export const MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER = 'MENU_ACCOUNT_MANAGEMENT_SUMMARY_USER';
export const ONLY_REGISTRY_ADMIN = 'ONLY_REGISTRY_ADMIN';
