
      <p-fieldset [id]="elementId" [styleClass]="className">
      <p-header>
        <span [innerHTML]="label | translate"></span>{{number}}{{starChar}}
        <fa-icon
         *ngIf="to.icon"
          pTooltip="{{to.icon?.text | translate}}"
          tooltipStyleClass="tooltip-min-width"
          [escape]="false"
          tooltipPosition="top"
          [icon]="['fas', 'info-circle']">
        </fa-icon>  
      </p-header>
        <ng-container #fieldComponent></ng-container>
      </p-fieldset>
