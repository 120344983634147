/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./template-popup.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/template-ref.pipe";
import * as i3 from "../../../core/utilities/template-registry";
import * as i4 from "../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i5 from "primeng/dialog";
import * as i6 from "../../pipes/custom-translate.pipe";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../node_modules/primeng/api/primeng-api.ngfactory";
import * as i10 from "primeng/api";
import * as i11 from "./template-popup.component";
import * as i12 from "@angular/material/dialog";
var styles_TemplatePopupComponent = [i0.styles];
var RenderType_TemplatePopupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplatePopupComponent, data: {} });
export { RenderType_TemplatePopupComponent as RenderType_TemplatePopupComponent };
export function View_TemplatePopupComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TemplateRefPipe, [i3.TemplateRegistry]), (_l()(), i1.ɵeld(1, 0, null, null, 16, "p-dialog", [], [[8, "className", 0]], null, null, i4.View_Dialog_0, i4.RenderType_Dialog)), i1.ɵdid(2, 180224, null, 2, i5.Dialog, [i1.ElementRef, i1.Renderer2, i1.NgZone, i1.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, null), i1.ɵqud(603979776, 1, { headerFacet: 1 }), i1.ɵqud(603979776, 2, { footerFacet: 1 }), i1.ɵpid(131072, i6.CustomTranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), i1.ɵpod(6, { width: 0 }), (_l()(), i1.ɵeld(7, 16777216, null, 1, 3, null, null, null, null, null, null, null)), i1.ɵdid(8, 540672, null, 0, i8.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(9, { $implicit: 0 }), i1.ɵppd(10, 1), (_l()(), i1.ɵeld(11, 0, null, 2, 6, "p-footer", [], null, null, null, i9.View_Footer_0, i9.RenderType_Footer)), i1.ɵdid(12, 49152, [[2, 4]], 0, i10.Footer, [], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 4, "div", [["class", "footer-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "float-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-secondary"], ["id", "closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i6.CustomTranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 5).transform(_co.title)); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 500; var currVal_7 = false; var currVal_8 = true; var currVal_9 = _ck(_v, 6, 0, _co.width); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _ck(_v, 9, 0, _co.data); var currVal_11 = i1.ɵunv(_v, 8, 1, _ck(_v, 10, 0, i1.ɵnov(_v, 0), _co.template)); _ck(_v, 8, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = "eccc"; _ck(_v, 1, 0, currVal_0); var currVal_12 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("COMMON.actionsLabel.CLOSE")); _ck(_v, 16, 0, currVal_12); }); }
export function View_TemplatePopupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-template-popup", [], null, null, null, View_TemplatePopupComponent_0, RenderType_TemplatePopupComponent)), i1.ɵdid(1, 114688, null, 0, i11.TemplatePopupComponent, [i12.MAT_DIALOG_DATA, i12.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplatePopupComponentNgFactory = i1.ɵccf("app-template-popup", i11.TemplatePopupComponent, View_TemplatePopupComponent_Host_0, {}, {}, []);
export { TemplatePopupComponentNgFactory as TemplatePopupComponentNgFactory };
