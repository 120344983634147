import { of } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../store/store.service";
import * as i2 from "@angular/router";
import * as i3 from "../../shared/services/helper.service";
export class FirstTabRedirectGuard {
    constructor(store, router, helperService) {
        this.store = store;
        this.router = router;
        this.helperService = helperService;
    }
    canActivate(next, state) {
        const initialPath = this.store.user.menuItems[0].path;
        this.router.navigate(this.helperService.getTranslatedPath(`/${initialPath}`));
        return of(false);
    }
}
FirstTabRedirectGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirstTabRedirectGuard_Factory() { return new FirstTabRedirectGuard(i0.ɵɵinject(i1.StoreService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HelperService)); }, token: FirstTabRedirectGuard, providedIn: "root" });
