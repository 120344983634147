/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/custom-translate.pipe";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../../../node_modules/primeng/dialog/primeng-dialog.ngfactory";
import * as i4 from "primeng/dialog";
import * as i5 from "../../../../../../node_modules/@ngx-formly/core/ngx-formly-core.ngfactory";
import * as i6 from "@ngx-formly/core";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "./ci-pathway-action-model.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../shared/services/form-factory.service";
import * as i12 from "../../ci-pathway-report.service";
import * as i13 from "../../../../core/store/store.service";
var styles_CiPathwayActionModalComponent = [];
var RenderType_CiPathwayActionModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CiPathwayActionModalComponent, data: {} });
export { RenderType_CiPathwayActionModalComponent as RenderType_CiPathwayActionModalComponent };
function View_CiPathwayActionModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.customActionHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.data["customActionBtn"])); _ck(_v, 1, 0, currVal_0); }); }
function View_CiPathwayActionModalComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.additionalActionHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.data["additionalActionBtn"])); _ck(_v, 1, 0, currVal_0); }); }
export function View_CiPathwayActionModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 19, "p-dialog", [], [[8, "className", 0]], null, null, i3.View_Dialog_0, i3.RenderType_Dialog)), i0.ɵdid(1, 180224, null, 2, i4.Dialog, [i0.ElementRef, i0.Renderer2, i0.NgZone, i0.ChangeDetectorRef], { header: [0, "header"], modal: [1, "modal"], closable: [2, "closable"], responsive: [3, "responsive"], autoZIndex: [4, "autoZIndex"], minY: [5, "minY"], maximizable: [6, "maximizable"], visible: [7, "visible"], style: [8, "style"] }, null), i0.ɵqud(603979776, 1, { headerFacet: 1 }), i0.ɵqud(603979776, 2, { footerFacet: 1 }), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), i0.ɵpod(5, { width: 0 }), (_l()(), i0.ɵeld(6, 0, null, 1, 2, "formly-form", [], null, null, null, i5.View_FormlyForm_0, i5.RenderType_FormlyForm)), i0.ɵprd(512, null, i6.FormlyFormBuilder, i6.FormlyFormBuilder, [i6.FormlyConfig, i0.ComponentFactoryResolver, i0.Injector]), i0.ɵdid(8, 966656, null, 0, i6.FormlyForm, [i6.FormlyFormBuilder, i6.FormlyConfig, i0.ChangeDetectorRef, [8, null], [2, i7.FormGroupDirective]], { form: [0, "form"], model: [1, "model"], fields: [2, "fields"] }, null), (_l()(), i0.ɵeld(9, 0, null, 1, 10, "div", [["class", "d-flex justify-content-end mt-2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-2"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(11, null, [" ", " "])), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CiPathwayActionModalComponent_1)), i0.ɵdid(14, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CiPathwayActionModalComponent_2)), i0.ɵdid(16, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(18, null, [" ", " "])), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 4).transform(_co.title)); var currVal_2 = true; var currVal_3 = false; var currVal_4 = true; var currVal_5 = false; var currVal_6 = 100; var currVal_7 = false; var currVal_8 = true; var currVal_9 = _ck(_v, 5, 0, "820px"); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = _co.form; var currVal_11 = _co.model; var currVal_12 = _co.fields; _ck(_v, 8, 0, currVal_10, currVal_11, currVal_12); var currVal_14 = _co.data["customActionBtn"]; _ck(_v, 14, 0, currVal_14); var currVal_15 = _co.data["additionalActionBtn"]; _ck(_v, 16, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = "eccc"; _ck(_v, 0, 0, currVal_0); var currVal_13 = i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("TASK_TO_DO.adminTakeActionModal.close")); _ck(_v, 11, 0, currVal_13); var currVal_16 = i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform((_co.data["primaryActionBtn"] ? _co.data["primaryActionBtn"] : "TASK_TO_DO.adminTakeActionModal.markTaskAsCompleted"))); _ck(_v, 18, 0, currVal_16); }); }
export function View_CiPathwayActionModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-ci-pathway-action-model", [], null, null, null, View_CiPathwayActionModalComponent_0, RenderType_CiPathwayActionModalComponent)), i0.ɵdid(1, 245760, null, 0, i9.CiPathwayActionModalComponent, [i10.MAT_DIALOG_DATA, i10.MatDialogRef, i11.FormFactoryService, i12.CiPathwayReportService, i13.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CiPathwayActionModalComponentNgFactory = i0.ɵccf("app-ci-pathway-action-model", i9.CiPathwayActionModalComponent, View_CiPathwayActionModalComponent_Host_0, {}, {}, []);
export { CiPathwayActionModalComponentNgFactory as CiPathwayActionModalComponentNgFactory };
