
    <input type="hidden" [id]="elementId" class="wcag-visuallyhidden">
    <ng-select
            wcag-label
            [items]="items"
            [id]="elementId + '_dropdown'"
            [placeholder]="placeholder"
            [inputAttrs]="{'aria-label': label}"
            [formControl]="formControl"
            [multiple]="multiple"
            [closeOnSelect]="false"
            [bindLabel]="to.bindLabel || 'name'"
            [bindValue]="to.bindValue || 'id'"
            [loading]="isLoading"
            [notFoundText]="notFoundText"
            (add)="onAdd($event)">
    </ng-select>
  