import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CoreModule } from '@core/core.module';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { RespondInterceptorService } from '@core/interceptors/respond-interceptor.service';
import { LoaderInterceptor } from '@core/interceptors/loader.interceptor';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { CustomTranslateLoader } from '@core/services/translation-loader.service';
import { AccountManagementModule } from '@module/account-management/account-management.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationsService } from './shared/services/notifications.service';
import { SharedModule } from './shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { PublicViewInterceptor } from '@core/interceptors/public-view.interceptor';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AccountManagementModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [
    NotificationsService,
    CookieService,
    Title,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PublicViewInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

registerLocaleData(localeEn, 'en');
registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
