import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { AuthService } from '@core/services/auth.service';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { linkedAccountsTableConfig } from '@module/account-management/account-management.const';
import { AccountManagementService } from '@module/account-management/services/account-management.service';
import { UserManagementService } from '@module/users-management/services/user-management.service';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { userDetailsPrefix } from '@shared/components/add-additional-users/add-additional-users.component';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig } from '@shared/models/table-view.model';
import { ModalService } from '@shared/services/modal.service';
import { NotificationsService } from '@shared/services/notifications.service';
import { UserService } from '@shared/services/user.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { NavigationService } from '@shared/services/navigation.service';

const PERMISSION_REQUEST_NEW_USERS = 'REQUEST_NEW_USERS';
const ACCOUNT_TYPE_FIELD = 'accountTypeName';

const DEFAULT_LINKED_ACCOUNT = {
  roleId: 2,
  programId: null,
  accountId: null,
  userStatus: 'ACTIVE',
};

const ADMIN_PRIORITY_ID = 3;

@Component({
  selector: 'app-linked-accounts',
  templateUrl: './linked-accounts.component.html',
  styleUrls: ['./linked-accounts.component.scss'],
})
export class LinkedAccountsComponent implements OnInit, OnChanges {

  @Input() userId: number;
  @Input() items: IApp.ILinkedAccount[];
  @Input() formEnabled: boolean;
  @Input() readonly = false;
  @Input() program;
  @Input() relative = false;
  @Output() listChanged = new EventEmitter<IApp.ILinkedAccountUpdate>();
  @Output() accountTypes = new EventEmitter<string[]>();
  @Output() hasAdminRole = new EventEmitter<boolean>();

  @ViewChild('linkedAccountsActions', {static: true}) public linkedAccountsActions: TemplateRef<any>;
  @ViewChild('roleField', {static: true}) public roleFieldTmpl: TemplateRef<any>;
  @ViewChild('accountField', {static: true}) public accountFieldTmpl: TemplateRef<any>;
  @ViewChild('accountTypeField', {static: true}) public accountTypeFieldTmpl: TemplateRef<any>;
  @ViewChild('programField', {static: true}) public programFieldTmpl: TemplateRef<any>;
  @ViewChild('projectFieldTmpl', {static: true}) public projectFieldTmpl: TemplateRef<any>;

  tableConfig: ITableViewConfig;

  private _destroy$ = new Subject<any>();
  private _facilityNames: IApp.ILookup[] = [];
  public facilityNames: IApp.ILookup[] = [];

  public dummy: IApp.IEntityAction;

  public linkedAccount: IApp.ILinkedAccount = DEFAULT_LINKED_ACCOUNT;

  public roles: IApp.IDict[] = [];
  public viewRoles: IApp.IDict[] = [];
  public programs: IApp.IDict[] = [];
  public accounts: IApp.ILookup[] = [];

  public associationInEdit = null;
  roleTranslationPrefix: string;

  options: FormlyFormOptions = {};
  linkedAccountsForm = new FormGroup({});
  linkedAccountsFields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          fieldGroupClassName: 'row',
          className: 'col-sm-9',
          fieldGroup: [
            {
              className: 'col-sm-4',
              type: 'dict-select',
              wrappers: ['form-field'],
              key: 'programId',
              id: userDetailsPrefix.concat('programLabelCombobox'),
              templateOptions: {
                required: true,
                clearable: false,
                label: 'ACCOUNTS_MODULE.linkedAccounts.associatedProgram',
                translatePrefix: 'COMMON.programTypeList',
                translateOptions: true,
                placeholder: 'selectItem',
                source: this.userService.getProgramDict(),
              },
            },
            {
              className: 'col-sm-4',
              type: 'dict-select',
              wrappers: ['form-field'],
              key: 'roleId',
              id: userDetailsPrefix.concat('roleCombobox'),
              templateOptions: {
                required: true,
                clearable: false,
                label: 'ACCOUNTS_MODULE.linkedAccounts.role',
                translatePrefix: 'COMMON.roleList',
                translateOptions: true,
                placeholder: 'selectItem',
                fluxSource: this.userService.getRoleDict(),
                sort: true,
                change: (field, selection: IApp.ILookup) => {
                  if (!this.roles || !this.roles['roleDtos']) {
                    return false;
                  }
                  if (selection) {
                    const role = this.getRoleById(selection.id);
                    if (role.dataId <= 3) {
                      field.form.controls.accountId.reset();
                    } else {
                      field.form.controls.accountId.reset(this.linkedAccount.accountId);
                    }
                  } else {
                    field.form.controls.roleId.reset();
                  }
                },
              },
            },
            {
              className: 'col-sm-4',
              type: 'dict-select',
              wrappers: ['form-field'],
              key: 'accountId',
              id: userDetailsPrefix.concat('roleCombobox'),
              templateOptions: {
                clearable: false,
                translatePrefix: 'COMMON.roleList',
                label: 'ACCOUNTS_MODULE.linkedAccounts.account',
                placeholder: 'selectItem',
                source: this.getAllowedFacilityNames(),
                preventAutoPopulate: () => {
                  const role = this.getRoleById(this.linkedAccount.roleId);
                  return role && role.name.includes('ADMIN');
                },
                change: (field, selection: IApp.ILookup) => {
                  const selectionId = selection.id || selection.masterAccountId;

                  if (this.program === 'OFFSET' && selectionId) {
                    field.form.get('accountLevel').setValue(selection.accountLevel);
                    this.linkedAccount.masterAccountId = selection.masterAccountId;
                    this.getProjectNamesForAccounts([selectionId]).subscribe(
                      data => {
                        if (data && data[selectionId]) {
                          field.form.get('projectNames').setValue(data[selectionId]);
                        } else {
                          field.form.get('projectNames').setValue(null);
                        }
                      },
                    );
                  }
                },
              },
              expressionProperties: {
                'templateOptions.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
                  if (!this.roles || !this.roles['roleDtos']) {
                    return false;
                  }
                  const role = this.roles['roleDtos'].find(r => r.id === model.roleId);
                  return role ? role.dataId <= 3 : false;
                },
              },
            },
            {
              className: 'col-sm-12',
              type: 'readonly-input',
              key: 'projectNames',
              hideExpression: () => this.program !== 'OFFSET',
              templateOptions: {
                label: 'ACCOUNTS_MODULE.linkedAccounts.projectNames',
                chips: true,
                autoHeight: true,
              },
            },
            {
              className: 'col-sm-12 hidden',
              type: 'readonly-input',
              key: 'accountLevel',
              templateOptions: {
                label: 'accountLevel',
              },
            },
          ],
        },
        {
          className: 'col-sm-3',
          type: 'button',
          hideExpression: () => this.inEdit,
          templateOptions: {
            text: `ACCOUNTS_MODULE.linkedAccounts.addAssociationBtn${this.store.user.program.toLowerCase()}`,
            btnType: 'primary',
            className: 'w-100',
            onClick: ($event, form) => this.editAssociation(form),
          },
          expressionProperties: {
            'templateOptions.disabled': (model: any, formState: any, field: FormlyFieldConfig) => {
              if (!this.roles || !this.roles['roleDtos']) {
                return false;
              }
              const role = this.roles['roleDtos'].find(r => r.id === model.roleId);
              const notAdminRole = role && role.dataId > 3;
              return notAdminRole && !model.accountId;
            },
          },
        },
        {
          fieldGroupClassName: 'row',
          className: 'col-sm-3',
          hideExpression: () => !this.inEdit,
          fieldGroup: [
            {
              className: 'col-sm-6',
              type: 'button',
              templateOptions: {
                text: this.translateService.instant(`ACCOUNTS_MODULE.linkedAccounts.updateAssociationBtn`),
                btnType: 'primary',
                className: 'w-100',
                onClick: ($event, form) => this.editAssociation(form),
              },
            },
            {
              className: 'col-sm-6',
              type: 'button',
              templateOptions: {
                text: this.translateService.instant('COMMON.actionsLabel.CANCEL'),
                btnType: 'primary',
                className: 'w-100',
                onClick: ($event, form) => this.cancelForm(),
              },
            },
          ],
        },
      ],
    },
  ];

  actionsList: IApp.IEntityAction[];

  constructor(
    private lookupService: LookupService,
    private modalService: ModalService,
    private store: StoreService,
    private userService: UserService,
    private translateService: TranslateService,
    private userManagementService: UserManagementService,
    private authService: AuthService,
    private notification: NotificationsService,
    private accountManagementService: AccountManagementService,
    private navigationService: NavigationService,
  ) { }

  ngOnInit() {

    this.getRoles();
    this.getViewableRoles();
    this.getPrograms();
    this.getAccounts();

    const roleTranslationPrefix =  `COMMON.${this.store.user.program.toLowerCase()}RoleList`;
    this.linkedAccountsFields[0].fieldGroup[0].fieldGroup[1].templateOptions.translatePrefix = roleTranslationPrefix;
    this.roleTranslationPrefix = `${roleTranslationPrefix}.`;

    const { columns, ...config } = linkedAccountsTableConfig;
    const _columns = [
      ...linkedAccountsTableConfig.columns,
    ];

    _columns[0].templateRef = this.roleFieldTmpl;
    _columns[1].templateRef = this.accountFieldTmpl;
    _columns[2].templateRef = this.accountTypeFieldTmpl;
    _columns[3].templateRef = this.programFieldTmpl;

    if (!this.readonly) {
      _columns.push({
        header: 'actions',
        width: '100px',
        className: 'overflow-visible',
        templateRef: this.linkedAccountsActions,
      });
    }

    if (this.readonly) {
      _columns.splice(2, 2);
    }

    if (this.program === 'OFFSET') {
      _columns.splice(2, 0, {
        field: 'projectNames',
        header: 'projectNames',
        width: '100px',
        templateRef: this.projectFieldTmpl,
      });
      _columns.splice(2, 0, {
        field: 'accountLevel',
        header: 'accountLevel',
        width: '100px',
      });
    }

    this.tableConfig = {
      ...config,
      ...{columns: _columns},
    };

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items && this.items.length > 0) {
      this.items = [...this.items.map(item => ({...item, actionList: this.getActionList(item)}))];
      this.mapProjectNamesForOffsetAccounts();
    }
  }

  private mapProjectNamesForOffsetAccounts() {
    if (this.program === 'OFFSET') {
      const accountIds = this.items.filter(a => !a.projectNames && a.accountId !== null).map(a => a.accountId);
      if (accountIds && accountIds.length > 0) {
        this.getProjectNamesForAccounts(accountIds)
          .pipe(takeUntil(this._destroy$))
          .subscribe(
            data => this.items = [
              ...this.items.map(item => ({
                ...item,
                projectNames: item.projectNames ? item.projectNames : data[item.accountId] ? data[item.accountId] : null,
              })),
            ],
          );
      }
    }
  }

  getUser() {
    this.userService.get(this.userId)
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(
        user => {
          this.items = [...user.associationsList];
          this.hasAdminRole.emit(this.checkAdminRole());
        },
      );
  }

  getRoles() {
    this.accountManagementService.getAllRoles()
      .pipe(
        takeUntil(this._destroy$),
        filter(d => d !== null),
      ).subscribe(
        data => {
          this.roles = data;
          if (this.userId && !this.items) {
            this.getUser();
          } else {
            this.hasAdminRole.emit(this.checkAdminRole());
          }
      },
    );
  }

  getViewableRoles() {
    this.accountManagementService.getAllViewableRoles()
      .pipe(
        takeUntil(this._destroy$),
        filter(d => d !== null),
      ).subscribe(
        data => this.viewRoles = data,
    );
  }

  getAccounts() {
    this.lookupService.getFacilityNames(false,  true)
      .pipe(
        takeUntil(this._destroy$),
        filter(d => d !== null),
      ).subscribe(
        data => this.accounts = data,
    );
  }

  getAllowedFacilityNames() {
    return this.lookupService.getFacilityNames()
      .pipe(
        takeUntil(this._destroy$),
        filter(d => d !== null),
        map(d => d.filter(account => this.store.user.hasPermissionForBothAccount(PERMISSION_REQUEST_NEW_USERS, account.id, account.masterAccountId))),
      );
  }

  getPrograms() {
    this.userService.getProgramDict()
      .pipe(
        takeUntil(this._destroy$),
        filter(d => d !== null),
      ).subscribe(
        data => {
          this.programs = data;
          this.linkedAccount = this.defaultLinkedAccountData;
        },
    );
  }

  getActionList(data) {
    let actionsList = [];
    if (data.entityActionList && data.entityActionList.length > 0) {
      data.entityActionList.forEach(option => option.label = this.translateService.instant(`COMMON.actionsLabel.${option.workflowAction}`));
    }

    actionsList = [
      {
        label: this.translateService.instant('COMMON.actionsLabel.EDIT'),
        workflowAction: 'EDIT',
      }];

    if (!this.isCurrentUser) {
        actionsList.push({
        label: this.translateService.instant('COMMON.actionsLabel.DISCARD'),
        workflowAction: 'DISCARDED',
      });
      }

    if (!data.isNew) {
      if (data.userStatus === 'DEACTIVE' || data.userStatus === 'DISCARDED') {
        actionsList.unshift(
          {
            label: this.translateService.instant('COMMON.actionsLabel.ACTIVE'),
            workflowAction: 'ACTIVE',
          },
        );
      } else if (!this.isCurrentUser) {
        actionsList.unshift(
          {
            label: this.translateService.instant('COMMON.actionsLabel.DEACTIVE'),
            workflowAction: 'DEACTIVE',
          },
        );
      }
    }
    return data.entityActionList ? [...actionsList, ...data.entityActionList] : actionsList;
  }

  private findAssociationId(data) {
    return this.items.findIndex(item =>
        item.accountId === data.accountId &&
        item.programId === data.programId &&
        item.roleId === data.roleId);
  }

  private findAssociationAccountId(data) {
    return this.items.findIndex(item =>
        item.accountId === data.accountId &&
        item.programId === data.programId );
  }

  onActionSelected(selectedAction, data) {
    const itemId = this.findAssociationId(data);
    let dialogMessage = 'confirmationMessage';
    if (selectedAction.workflowAction !== 'EDIT') {
      const items = this.items;

      if (!data.id) {
        if (selectedAction.workflowAction === 'DISCARDED') {
          items.splice(itemId, 1);
        } else if (['DEACTIVE','ACTIVE'].includes(selectedAction.workflowAction)) {
          items[itemId].userStatus = selectedAction.workflowAction;
        }
        this.listChanged.emit({ items: [...items], refresh: true });
        this.hasAdminRole.emit(this.checkAdminRole());
        this.dummy = null;
        return;
      }

      const reqData = {
        id: data.id,
        userStatus: selectedAction.workflowAction,
      };
      if (selectedAction.workflowAction === 'DISCARDED') {
        dialogMessage = 'confirmationMessageRemoveAssociation';
      }
      this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: null,
          message: dialogMessage,
          metaData: this.translateService.instant(selectedAction.label),

          type: SereviceMessageType.WARNING,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
      .subscribe((result?: any) => {
        if (result) {
          let request = this.userService.setAssociationAction(reqData);
          if (['APPROVE', 'REJECT'].includes(selectedAction.workflowAction)) {
            request = this.userService.setAssociationApprovalAction(selectedAction.workflowAction, data.id);
          }
          request.subscribe(
            response => {

              if (response.entity && ['DISCARDED', 'DISABLED'].includes(response.entity.userStatus)) {
                this.navigationService.back();
                return;
              }

              const isNewItem = items[itemId].isNew;
              items[itemId] = {
                ...items[itemId],
                isNew: isNewItem,
                userStatus: selectedAction.workflowAction,
                actionList: this.getActionList({...items[itemId], userStatus: selectedAction.workflowAction}),
              };

              this.items = [...items];
              this.listChanged.emit({ items: this.items, refresh: true });
              this.hasAdminRole.emit(this.checkAdminRole());
              this.checkUserAssociations();
            },
          );
        }
      });


    } else {
      this.linkedAccount = {...data};
      this.associationInEdit = itemId;
    }
    setTimeout(() => {
      this.dummy = null;
      (document.activeElement as HTMLElement).blur();
    });
  }

  checkUserAssociations() {
    if (this.isCurrentUser && this.items.filter(a => a.userStatus !== 'DEACTIVE').length === 0) {
      this.authService.signOut().subscribe(
        () => {},
        () => this.authService.resetSession(),
      );
    }
  }

  editAssociation(form): void {
    if (!form.invalid) {
       const existingItemId = this.findAssociationAccountId(this.linkedAccount);
       if (existingItemId > -1 && existingItemId !== this.associationInEdit) {
         this.showError('linkedAccountAlreadyExists');
         return;
       } else if (this.linkedAccount.roleId === 1 && this.linkedAccount.masterAccountId) {
         this.showError('noPrincipalUserForSubAccount');
         return;
       } else if (this.linkedAccount && this.accounts.findIndex(item =>
        item.id === this.linkedAccount.accountId &&
        item.status === 'LOCKED' ) > -1 ) {
        this.showError('TransactionNotPermittedOnLockedAccount');
        return;
       }

       if (this.store.user.isCFR()  ) {
          if (this.items.length === 1 && !this.inEdit) {
          this.showError('only1AssociationAllowedForCFR');
          return;
        } else if (this.linkedAccount.accountId) {
          this.userService.getRoleByAccount(this.linkedAccount.accountId).subscribe(rList => {
            const role = rList.roleDtos.find(r => r.id === this.linkedAccount.roleId);
            if (!role) {
              this.notification.showMessage(SereviceMessageType.ERROR, 'roleNotAllowedForThisAccountType',
                this.translateService.instant(`COMMON.accountTypeList.${this.accounts.find(t => t.id ===
                  this.linkedAccount.accountId).accountTypeName}`));
             } else {
              this.editAssociationAfterVal();
            }
         });
        } else {
          this.editAssociationAfterVal();
        }
      } else {
        this.editAssociationAfterVal();
      }
    }
  }

  addNewAssociation(): Observable<any> {
    return new Observable<any>(subscriber => {
      if (this.isExistingUser) {
        this.accountManagementService.addAssociation({
          accountId: this.linkedAccount.accountId,
          programId: this.linkedAccount.programId,
          roleId: this.linkedAccount.roleId,
          userId: this.userId,
          status: 'ACTIVE',
        }).subscribe(data => {
          subscriber.next(data);
          subscriber.complete();
        });
      } else {
        subscriber.next(true);
        subscriber.complete();
      }
    });
  }

  editAssociationAfterVal() {

    const itemSetup = (refresh = true) => {
      this.listChanged.emit({ items: this.items, refresh });
      this.hasAdminRole.emit(this.checkAdminRole());
      const accountTypes = this.accounts.filter(a => a.id === this.linkedAccount.accountId).map(a => a[ACCOUNT_TYPE_FIELD]);
      this.accountTypes.emit(accountTypes);
      this.linkedAccount = {...this.defaultLinkedAccountData};
      this.associationInEdit = null;
      setTimeout(() => this.options.resetModel());
    };

    if (this.program === 'OFFSET') {
      const selectedAccount = this.accounts.find(t => t.id === this.linkedAccount.accountId);
      if (selectedAccount) {
        this.linkedAccount.accountLevel = selectedAccount.accountLevel;
      }
    }

    if (this.inEdit) {
      const items = this.items;
      items[this.associationInEdit] = this.linkedAccount;
      this.items = [...items];
      itemSetup(false);
    } else {
      this.addNewAssociation().subscribe(data => {
        if (data) {
          this.items = [{
            ...data,
            ...this.linkedAccount,
            status: 'ACTIVE',
            userStatus: this.linkedAccount.userStatus,
            actionList: this.getActionList(this.linkedAccount),
          }, ...this.items];
        } else {
          this.items = [{
            ...this.linkedAccount,
            isNew: true,
            status: 'ACTIVE',
            userStatus: this.linkedAccount.userStatus,
            actionList: this.getActionList(this.linkedAccount),
          }, ...this.items];
        }
        itemSetup();
      });
    }
  }

  cancelForm() {
    this.linkedAccount = this.defaultLinkedAccountData;
    this.associationInEdit = null;
    setTimeout(() => this.options.resetModel());
  }

  public get inEdit(): boolean {
    return this.associationInEdit !== null;
  }

  get defaultLinkedAccountData() {
    if (this.programs.length === 1) {
      return {...DEFAULT_LINKED_ACCOUNT, programId: this.programs[0].id};
    } else {
      return DEFAULT_LINKED_ACCOUNT;
    }
  }

  public getSortedActions(actionList) {
    return  actionList ? actionList.sort((a, b) => a.label.localeCompare(b.label)) : actionList;
  }

  private showError(message) {
    const messages: IDisplayMessages = {
      messages: [{message}],
      type: SereviceMessageType.ERROR,
    };
    this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe();
  }

  private get isCurrentUser(): boolean {
    return this.store.user.id === this.userId;
  }

  private checkAdminRole(): boolean {
     const userRoles = Array.from(new Set(this.items.map(a => a.roleId)));
     const roleMap = userRoles.map(ur => this.roles['roleDtos'].find(r => r.id === ur).dataId);
     return roleMap.find(el => el <= ADMIN_PRIORITY_ID) !== undefined;
  }

  private get isExistingUser(): boolean {
    return !!this.userId;
  }

  private getProjectNamesForAccounts(accountIds: number[]) {
    return this.lookupService.getProjectNamesByFilter(accountIds);
  }

  private getRoleById(id: number): IApp.IDict {
    return this.roles['roleDtos'] && this.roles['roleDtos'].find(r => r.id === id);
  }
}
