import { OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
export class FormlyFieldTextAreaExtra extends FieldType {
    constructor() {
        super(...arguments);
        this.disabledField = false;
        this.defaultOptions = {
            templateOptions: {
                cols: 1,
                rows: 1,
            },
        };
    }
    ngOnInit() {
        this.disabledField = this.to.disabled;
        this.initDefaultValueSub();
    }
    get elementId() {
        if (this.field.id && this.field.id.includes('formly_')) {
            let prefix = this.form['id'];
            // check if in repeat section
            if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
                const cnt = this.field.parent.parent.key;
                prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
            }
            if (!prefix && this.field.parent.parent && this.field.parent.parent.type === 'form-array') {
                prefix = this.field.parent['id'];
            }
            return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
        }
        else {
            return this.field.id;
        }
    }
    initDefaultValueSub() {
        const { defaultValue } = this.to;
        if (typeof defaultValue !== 'string' && typeof defaultValue !== 'number') {
            return;
        }
        const value = typeof defaultValue === 'number' ? defaultValue.toString() : defaultValue;
        this.formControl.setValue(value);
    }
    get tabIndex() {
        return this.to.tabindex || this.field.hide ? -1 : 0;
    }
}
