<div class="table-container"
     [class.relative]="relative"
     [style.minWidth]="tableContainerMinWidth"
     #tableContainer>

  <ng-container *ngIf=" !config?.paginator; else WITH_PAGINATOR_ROW_EXPANSION">

    <p-table [ngClass]="setBaseClasses()"
             [class.relative]="relative"
             [value]="data"
             [resizableColumns]="true"
             [scrollable]="!relative"
             scrollHeight="100%"
             *ngIf="config?.columns"
             [tableStyle]="tableStyles"
             (onSort)="storeScrollLeft()"
             [dataKey]="config.dataKey || 'id'"
             (onRowExpand)="onRowExpand($event)"
             [(selection)]="selections"
             #dT>
      <ng-template pTemplate="colgroup" let-columns>

      <colgroup>
        <col *ngFor="let col of config.columns"
             [class.checkbox-col]="col.checkboxSelect"
             [style.width]="col.expandIcon ? '40px' : (col.width || 'auto')">
      </colgroup>

      </ng-template>

      <ng-template pTemplate="header">

        <ng-container *ngIf="config.extraHeader">
          <tr class="extra" *ngFor="let header of config.extraHeader; let i = index">
            <ng-container *ngFor="let th of header; let j = index">
                <th class="text-center"
                [attr.rowspan]="th.rowspan || ''"
                [attr.colspan]="th.colspan || ''">
                  <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                    <span [innerHTML]="config.translationPrefix + '.' + th.header | translate"></span>
                    <span class="wcag-visuallyhidden">Dummy</span>
                  </ng-container>
                  <ng-template #NO_TRANSLATION>
                    <span [innerHTML]="th.header"></span>
                    <span class="wcag-visuallyhidden">Dummy</span>
                  </ng-template>
                </th>
            </ng-container>
          </tr>
        </ng-container>
        <tr>

          <th pResizableColumn *ngFor="let col of config.columns; let i = index"
          [pSortableColumn]="col.sortField"
          [style.width]="col.expandIcon ? '40px' : (col.width || 'auto')" [style.vertical-align]="col.inlineTooltip ? 'bottom' : 'inherit'">

            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">
              <ng-container *ngIf="!config.singleSelect;">
                <p-tableHeaderCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled" wcag-label (click)="selected()"></p-tableHeaderCheckbox>
              </ng-container>
              <span class="wcag-visuallyhidden">Dummy</span>
            </ng-container>

            <ng-template #HEADER_FIELD>
              <ng-container *ngIf="!col.inlineTooltip; else INLINE_TOOLTIP">
                <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                  <span [innerHTML]="config.translationPrefix + '.' + col.header | translate"
                        [class.with-toolTip]="col.toolTip"></span>
                  <span class="wcag-visuallyhidden">Dummy</span>
                </ng-container>

                <ng-template #NO_TRANSLATION>
                  <span [innerHTML]="col.header"
                        [class.with-toolTip]="col.toolTip"></span>
                  <span class="wcag-visuallyhidden">Dummy</span>
                </ng-template>

                <fa-icon
                  *ngIf="col.toolTip"
                  class ="{{col.toolTip.class? col.toolTip.class: 'toolTip'}}"
                  pTooltip="{{col.toolTip.text | translate}}"
                  tooltipPosition="{{col.toolTip?.tooltipPosition ? col.toolTip?.tooltipPosition : 'top'}}"
                  [escape]="false"
                  tooltipStyleClass="tooltip-min-width"
                  [icon]="col.icon?.iconProp ? col.toolTip.iconProp : ['fas', 'info-circle']">
                </fa-icon>
              </ng-container>

              <ng-template #INLINE_TOOLTIP>
                <span> {{config.translationPrefix + '.' + col.header | translate}}
                  <fa-icon
                    *ngIf="col.toolTip"
                    pTooltip="{{col.toolTip.text | translate}}"
                    tooltipPosition="{{col.toolTip?.tooltipPosition ? col.toolTip?.tooltipPosition : 'top'}}"
                    [escape]="false"
                    tooltipStyleClass="tooltip-min-width"
                    [icon]="col.icon?.iconProp ? col.toolTip.iconProp : ['fas', 'info-circle']">
                  </fa-icon>
                </span>
              </ng-template>

              <p-sortIcon id="{{col.field}}" [field]="col.sortField" *ngIf="col.sortField"></p-sortIcon>

            </ng-template>

          </th>
        </tr>

      </ng-template>

      <ng-template *ngIf="isEditable" pTemplate="body" let-rowData let-columns="tableColumns" let-rowIndex="rowIndex" let-expanded="expanded" >

        <!-- WCAG table layout issue -->
        <tr class="wcag-visuallyhidden">
          <th *ngFor="let col of config.columns; let i = index" class="wcag-visuallyhidden">
            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">&nbsp;</ng-container>
            <ng-template #HEADER_FIELD>
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header | translate"></span>
              </ng-container>
              <ng-template #NO_TRANSLATION>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-template>
            </ng-template>
            <span class="wcag-visuallyhidden">Dummy</span>
          </th>
        </tr>
        <!-- end of WCAG table layout issue -->

        <tr #rE >

          <td pEditableColumn *ngFor="let col of config.columns; let i = index"
              [ngClass]="col.className">

            <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
              <ng-container *ngIf="config.singleSelect; else MULTIPLE_SELECT">
                <p-tableRadioButton wcag-label [disabled]="col.checkboxDisabled || config.selectDisabled" [value]="rowData"></p-tableRadioButton>
              </ng-container>
              <ng-template #MULTIPLE_SELECT>
                <p-tableCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled || rowData.disabled" wcag-label [value]="rowData"></p-tableCheckbox>
              </ng-template>
            </ng-container>

            <ng-template #ROW_FIELD>

              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">

                <ng-container *ngIf="col.translate; else DO_NOT_TRANSLATE">
                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">

                    <ng-container *ngIf="col.expandIcon && (rowData[col.expandSectionData] || config.expandSectionTemplate); else DATA_EXPANDER">
                      <a wcag-dummy-link-target href="#" [pRowToggler]="rowData" *ngIf="!col.expandSectionData || (col.expandSectionData && rowData[col.expandSectionData] && rowData[col.expandSectionData].length > (col.expandSectionAllRecords ? 0 : 1))">
                        <span class="wcag-visuallyhidden">Open details</span>
                        <fa-icon class="float-left back-icon"  [icon]="['fas', expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
                      </a>
                    </ng-container>
                    <ng-template #DATA_EXPANDER>
                      <a href="#"
                         wcag-dummy-link-target
                         style="color: #012640"
                         *ngIf="rowData[col.expandSectionData]"
                         [pRowToggler]="rowData" (click)="setExpandSectionData(col.expandSectionData, rowData, null, col.field)">
                        {{ (col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate  }}
                      </a>
                    </ng-template>
                    <span class="wcag-visuallyhidden">Dummy</span>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY 1-->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                      <ng-container *ngIf="rowData[col.field] != null && rowData[col.field].length > 0; else IS_NULL">
                        <span truncate-value [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate"></span>
                      </ng-container>
                      <ng-template #IS_NULL></ng-template>
                    </ng-container>
                    <ng-template #CHIPS>
                      <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)"><span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text "
                                                                                    [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + chip | translate"></span><br></span>
                    </ng-template>
                  </ng-template>


                </ng-container>

                <ng-template #DO_NOT_TRANSLATE>

                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">

                    <ng-container *ngIf="col.expandIcon && (rowData[col.expandSectionData] || config.expandSectionTemplate); else DATA_EXPANDER">
                      <a href="#" [pRowToggler]="rowData"
                         wcag-dummy-link-target
                         *ngIf="!col.expandSectionData || (col.expandSectionData && rowData[col.expandSectionData] && rowData[col.expandSectionData].length > (col.expandSectionAllRecords ? 0 : 1))"
                         (click)="setExpandSectionData(null, col.expandSectionData, rowData, col.field)">
                        <span class="wcag-visuallyhidden">Open details</span>
                        <fa-icon class="float-left back-icon"  [icon]="['fas', expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
                      </a>
                    </ng-container>
                    <ng-template #DATA_EXPANDER>
                      <a href="#"
                         wcag-dummy-link-target
                         style="color: #012640"
                         *ngIf="rowData[col.expandSectionData]"
                         [pRowToggler]="rowData" (click)="setExpandSectionData(col.expandSectionData, rowData, col.field)">
                        <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                          <ng-container *ngIf="!isNumeric(col); else NUMERIC">
                            <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                              <ng-container *ngIf="rowData[col.field] !== null && rowData[col.field].length > 0; else IS_NULL">
                                <span [innerHTML] =" rowData[col.field]"></span>
                              </ng-container>
                              <ng-template #IS_NULL></ng-template>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </a>
                    </ng-template>
                    <span class="wcag-visuallyhidden">Dummy</span>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY 2 -->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                      <ng-container *ngIf="!isNumeric(col); else NUMERIC">
                        <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                          <ng-container *ngIf="rowData[col.field] !== null; else IS_NULL">
                            <p-cellEditor>
                              <ng-template pTemplate="output">
                                <span truncate-value [innerHTML]="rowData[col.field]"></span>
                              </ng-template>

                              <ng-template pTemplate="input">
                                <input pInputText [(ngModel)]="rowData[col.field]" type="text" maxlength="50" class="form-control" />
                              </ng-template>
                            </p-cellEditor>
                          </ng-container>
                          <ng-template #IS_NULL></ng-template>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </ng-template>

                <ng-template #NUMERIC>
                  {{ rowData[col.field] | localizedNumber: (col.format || '1.')}}
                </ng-template>

                <ng-template #DATETIME_CONTENT>
                  {{ rowData[col.field] | date: 'yyyy-MM-dd'}}
                </ng-template>

                <ng-template #CHIPS>
                  <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)">
                    <span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text"  [innerHTML] = "chip"></span> <br></span>
                </ng-template>

              </ng-container>

              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="col.templateRef; context: {$implicit: rowData, columnIdx:col, rowIndex: rowIndex, index: i}"></ng-container>
              </ng-template>


            </ng-template>

          </td>
      </ng-template>

      <ng-template *ngIf="!isEditable" pTemplate="body" let-rowData let-columns="tableColumns" let-rowIndex="rowIndex" let-expanded="expanded" >

        <!-- WCAG table layout issue -->
        <tr class="wcag-visuallyhidden">
          <th *ngFor="let col of config.columns; let i = index" class="wcag-visuallyhidden">
            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">&nbsp;</ng-container>
            <ng-template #HEADER_FIELD>
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header | translate"></span>
              </ng-container>
              <ng-template #NO_TRANSLATION>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-template>
            </ng-template>
            <span class="wcag-visuallyhidden">Dummy</span>
          </th>
        </tr>
        <!-- end of WCAG table layout issue -->

        <tr #rE [ngClass]="{'highlightSelected': (highlightRowId>=0 && highlightRowId === rowData.id),
        'highlightRowData': rowData.highlightRowData}">

          <td *ngFor="let col of config.columns; let i = index"
              [ngClass]="col.className">

            <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
              <ng-container *ngIf="config.singleSelect; else MULTIPLE_SELECT">
                <p-tableRadioButton wcag-label [disabled]="col.checkboxDisabled || config.selectDisabled" [value]="rowData"></p-tableRadioButton>
              </ng-container>
              <ng-template #MULTIPLE_SELECT>
                <p-tableCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled || rowData.disabled" wcag-label *ngIf="!rowData.isTotalRecord" [value]="rowData" (click)="selected()"></p-tableCheckbox>
              </ng-template>
            </ng-container>

            <ng-template #ROW_FIELD>

              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">

                <ng-container *ngIf="col.translate; else DO_NOT_TRANSLATE">
                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">

                    <ng-container *ngIf="col.expandIcon && (rowData[col.expandSectionData] || config.expandSectionTemplate); else DATA_EXPANDER">
                      <a wcag-dummy-link-target href="#" [pRowToggler]="rowData" *ngIf="!col.expandSectionData || (col.expandSectionData && rowData[col.expandSectionData] && rowData[col.expandSectionData].length > (col.expandSectionAllRecords ? 0 : 1))">
                        <span class="wcag-visuallyhidden">Open details</span>
                        <fa-icon class="float-left back-icon"  [icon]="['fas', expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
				              </a>
                    </ng-container>
                    <ng-template #DATA_EXPANDER>
                      <a href="#"
                         wcag-dummy-link-target
                        style="color: #012640"
                        *ngIf="rowData[col.expandSectionData]"
                        [pRowToggler]="rowData" (click)="setExpandSectionData(col.expandSectionData, rowData, null, col.field)">
                      {{ (col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate  }}
                      </a>
                    </ng-template>
                    <span class="wcag-visuallyhidden">Dummy</span>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY 1-->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                      <ng-container *ngIf="rowData[col.field] != null && rowData[col.field].length > 0; else IS_NULL">
                        <ng-container *ngIf="!col.ignoreTruncate; else NO_TRUNCATE">
                          <span truncate-value [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate"></span>
                        </ng-container>
                        <ng-template #NO_TRUNCATE>
                          <span [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate"></span>
                        </ng-template>
                      </ng-container>
                      <ng-template #IS_NULL></ng-template>
                    </ng-container>
                    <ng-template #CHIPS>
                      <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)"><span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text "
                        [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + chip | translate"></span><br></span>
                    </ng-template>
                  </ng-template>


                </ng-container>

                <ng-template #DO_NOT_TRANSLATE>

                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">

                    <ng-container *ngIf="col.expandIcon && (rowData[col.expandSectionData] || config.expandSectionTemplate); else DATA_EXPANDER">
                      <a href="#" [pRowToggler]="rowData"
                         wcag-dummy-link-target
                         *ngIf="!col.expandSectionData || (col.expandSectionData && rowData[col.expandSectionData] && rowData[col.expandSectionData].length > (col.expandSectionAllRecords ? 0 : 1))"
                         (click)="setExpandSectionData(null, col.expandSectionData, rowData, col.field)">
					              <span class="wcag-visuallyhidden">Open details</span>
                        <fa-icon class="float-left back-icon"  [icon]="['fas', expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
				              </a>
                    </ng-container>
                    <ng-template #DATA_EXPANDER>
                      <a href="#"
                         wcag-dummy-link-target
                         style="color: #012640"
                        *ngIf="rowData[col.expandSectionData]"
                       [pRowToggler]="rowData" (click)="setExpandSectionData(col.expandSectionData, rowData, col.field)">
                        <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                          <ng-container *ngIf="!isNumeric(col); else NUMERIC">
                            <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                              <ng-container *ngIf="rowData[col.field] !== null && rowData[col.field].length > 0; else IS_NULL">
                                <span [innerHTML] =" rowData[col.field]"></span>
                              </ng-container>
                              <ng-template #IS_NULL></ng-template>
                            </ng-container>
                          </ng-container>
                        </ng-container>
                      </a>
                    </ng-template>
                    <span class="wcag-visuallyhidden">Dummy</span>
                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY 2 -->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                    <ng-container *ngIf="!isNumeric(col); else NUMERIC">
                      <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                        <ng-container *ngIf="rowData[col.field] !== null; else IS_NULL">
                          <span truncate-value [innerHTML] =" rowData[col.field]"></span>
                        </ng-container>
                        <ng-template #IS_NULL></ng-template>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  </ng-template>






<!--                  <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">-->
<!--                    <ng-container *ngIf="!col.numeric; else NUMERIC">-->
<!--                      <ng-container *ngIf="rowData[col.field] !== null; else IS_NULL">-->
<!--                        <span [innerHTML] =" rowData[col.field]"></span>-->
<!--                      </ng-container>-->
<!--                      <ng-template #IS_NULL></ng-template>-->
<!--                    </ng-container>-->
<!--                  </ng-container>-->

                </ng-template>

                <ng-template #NUMERIC>
                  <span *ngIf="!rowData.isTotalRecord">{{ rowData[col.field] | localizedNumber: (col.format || '1.')}}</span>
                  <span *ngIf="rowData.isTotalRecord"><b>{{ rowData[col.field] | localizedNumber: (col.format || '1.')}}</b></span>

                </ng-template>

                <ng-template #DATETIME_CONTENT>
                  {{ rowData[col.field] | date: 'yyyy-MM-dd'}}
                </ng-template>

                <ng-template #CHIPS>
                  <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)">
                    <span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text"  [innerHTML] = "chip"></span> <br></span>
                </ng-template>

              </ng-container>

              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="col.templateRef; context: {$implicit: rowData, columnIdx:col, rowIndex: rowIndex, index: i}"></ng-container>
              </ng-template>


            </ng-template>

          </td>
      </ng-template>

      <ng-template pTemplate="footer" *ngIf="footerTemplate">
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-rowData let-columns="tableColumns">
        <tr>
            <td [attr.colspan]="config.columns.length">
              <ng-container *ngIf="config.expandSectionTemplate else NO_TEMPLATE_EXPANDER">
                <ng-container *ngTemplateOutlet="config.expandSectionTemplate; context: {$implicit: rowData}"></ng-container>
              </ng-container>
              <ng-template #NO_TEMPLATE_EXPANDER>
                <div class="ui-g ui-fluid" style="font-size:16px;">
                    <div class="ui-g-12 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-12">

                              <ng-container *ngIf="isSimpleTable()">

                                <app-table-simple
                                  [tableHeaders]="innerTableHeaders"
                                  [tableData]="getInnerTableData(null, null, rowData)">
                                </app-table-simple>

                              </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
              </ng-template>
            </td>
        </tr>
      </ng-template>

    </p-table>

  </ng-container>

  <ng-template #WITH_PAGINATOR>
<!-- if id at datakey doesn't work exchange for this {{config.dataKey}} and check config file of table header-->
    <p-table [ngClass]="setBaseClasses()"
             [class.pagebale]="config.paginator"
             (onLazyLoad)="onPaginationChanged($event)"
             (onSort)="storeScrollLeft()"
             [lazy]="true"
             [paginator]="true"
             [value]="data?.content"
             [totalRecords]="data?.totalElements"
             [rowsPerPageOptions]="itemsPerPage"
             [rows]="rows"
             [first]="firstItemIndex"
             [resizableColumns]="true"
             [(selection)]="selections"
             [selectionMode]="config.singleSelect ? 'single' : 'multiple'"
             [tableStyle]="tableStyles"
             [dataKey]="config.dataKey || 'id'"
             (onRowExpand)="onRowExpand($event)"
             #dT>
      <ng-template pTemplate="colgroup" let-columns>

      <colgroup>
        <col *ngFor="let col of config.columns"
             [class.checkbox-col]="col.checkboxSelect"
             [style.width]="col.expandIcon ? '40px' : (col.width || 'auto')">
      </colgroup>

      </ng-template>

      <ng-template pTemplate="header">

        <ng-container *ngIf="config.extraHeader">
          <tr class="extra" *ngFor="let header of config.extraHeader; let i = index">
            <th *ngFor="let th of header; let j = index"
                class="text-center"
                [attr.rowspan]="th.rowspan || ''"
                [attr.colspan]="th.colspan || ''">
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + th.header | translate"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-container>
              <ng-template #NO_TRANSLATION>
                <span [innerHTML]="th.header"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-template>
            </th>
          </tr>
        </ng-container>

        <tr>

          <th pResizableColumn *ngFor="let col of config.columns; let i = index" [pSortableColumn]="col.sortField">

            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">
              <ng-container *ngIf="!config.singleSelect">
                <p-tableHeaderCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled" wcag-label (click)="selected()"></p-tableHeaderCheckbox>
              </ng-container>
              <span class="wcag-visuallyhidden">Dummy</span>
            </ng-container>

            <ng-template #HEADER_FIELD>

              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header | translate"
                      [class.with-toolTip]="col.toolTip"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-container>

              <ng-template #NO_TRANSLATION>
                <span [innerHTML]="col.header"
                      [class.with-toolTip]="col.toolTip"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-template>

              <fa-icon
                *ngIf="col.toolTip"
                class ="toolTip"
                pTooltip="{{col.toolTip.text | translate}}"
                tooltipPosition="{{col.toolTip?.tooltipPosition ? col.toolTip?.tooltipPosition : 'top'}}"
                [icon]="col.icon?.iconProp ? col.toolTip.iconProp : ['fas', 'info-circle']">
              </fa-icon>

              <p-sortIcon id="{{col.field}}" [field]="col.sortField" *ngIf="col.sortField"></p-sortIcon>

            </ng-template>

          </th>

        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="tableColumns" let-rowIndex="rowIndex">

        <tr #rE  >

          <td *ngFor="let col of config.columns; let i = index"
              [ngClass]="col.className">

            <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
              <ng-container *ngIf="config.singleSelect; else MULTIPLE_SELECT">
                <p-tableRadioButton wcag-label [disabled]="col.checkboxDisabled || config.selectDisabled" [value]="rowData" (click)="selected()"></p-tableRadioButton>
              </ng-container>
              <ng-template #MULTIPLE_SELECT>
                <p-tableCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled || rowData.disabled" wcag-label [value]="rowData" (click)="selected()"></p-tableCheckbox>
              </ng-template>
            </ng-container>

            <ng-template #ROW_FIELD>

              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">

                <ng-container *ngIf="col.translate; else DO_NOT_TRANSLATE">
                  <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                    <ng-container *ngIf="rowData[col.field] !== null; else IS_NULL">
                      {{ (col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate }}
                    </ng-container>
                    <ng-template #IS_NULL></ng-template>
                  </ng-container>
                  <ng-template #CHIPS>
                    <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)"><span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text"
                      [innerHTML] = "(col.translationPrefix || config.translationPrefix) + '.' + chip | translate "> </span><br></span>
                  </ng-template>
                </ng-container>

                <ng-template #DO_NOT_TRANSLATE>

                  <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">
                    <ng-container *ngIf="!isNumeric(col); else NUMERIC">
                      <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                        <ng-container *ngIf="rowData[col.field] !== null; else IS_NULL">
                          {{ rowData[col.field] }}
                        </ng-container>
                        <ng-template #IS_NULL></ng-template>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <ng-template #NUMERIC>
                    {{ rowData[col.field] | localizedNumber: (col.format || '1.')}}
                  </ng-template>

                  <ng-template #DATETIME_CONTENT>
                    {{ rowData[col.field] | date: 'yyyy-MM-dd'}}
                  </ng-template>

                  <ng-template #CHIPS>
                    <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)">
                      <span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text "  [innerHTML] = "chip"></span><br></span>
                  </ng-template>

                </ng-template>

              </ng-container>

              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="col.templateRef; context:  {$implicit: rowData, columnIdx:col, rowIndex: rowIndex, index: i}"></ng-container>
              </ng-template>

            </ng-template>

          </td>
      </ng-template>

    </p-table>

  </ng-template>

  <ng-template #WITH_PAGINATOR_ROW_EXPANSION>

    <p-table
      [ngClass]="setBaseClasses()"
      [class.pagebale]="config.paginator"
      (onLazyLoad)="onPaginationChanged($event)"
      (onSort)="storeScrollLeft()"
      [lazy]="true"
      [paginator]="true"
      [value]="data?.content"
      [totalRecords]="data?.totalElements"
      [rowsPerPageOptions]="itemsPerPage"
      [rows]="rows"
      [first]="firstItemIndex"
      [expandedRowKeys]="expandedRows"
      [resizableColumns]="true"
      [(selection)]="selections"
      [selectionMode]="config.singleSelect ? 'single' : 'multiple'"
      [tableStyle]="tableStyles"
      [dataKey]="config.dataKey || 'id'"
      (onRowExpand)="onRowExpand($event)"
      #dT
    >

      <ng-template pTemplate="colgroup" let-columns>

        <colgroup>
          <col *ngFor="let col of config.columns"
               [class.checkbox-col]="col.checkboxSelect"
               [style.width]="col.expandIcon ? '40px' : (col.width || 'auto')">
        </colgroup>

      </ng-template>

      <ng-template pTemplate="header">

        <ng-container *ngIf="config.extraHeader">
          <tr class="extra" *ngFor="let header of config.extraHeader; let i = index">
            <th *ngFor="let th of header; let j = index"
                class="text-center"
                [attr.rowspan]="th.rowspan || ''"
                [attr.colspan]="th.colspan || ''">
              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + th.header | translate"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-container>
              <ng-template #NO_TRANSLATION>
                <span [innerHTML]="th.header"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-template>
            </th>
          </tr>
        </ng-container>

        <tr>

          <th pResizableColumn *ngFor="let col of config.columns; let i = index" [pSortableColumn]="col.sortField"
              [style.width]="col.expandIcon ? '40px' : (col.width || 'auto')">

            <ng-container *ngIf="col.checkboxSelect; else HEADER_FIELD">
              <ng-container *ngIf="!config.singleSelect">
                <p-tableHeaderCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled" wcag-label (click)="selected()"></p-tableHeaderCheckbox>
              </ng-container>
              <span class="wcag-visuallyhidden">Dummy</span>
            </ng-container>

            <ng-template #HEADER_FIELD>

              <ng-container *ngIf="config.translationPrefix; else NO_TRANSLATION">
                <span [innerHTML]="config.translationPrefix + '.' + col.header | translate"
                      [class.with-toolTip]="col.toolTip"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-container>

              <ng-template #NO_TRANSLATION>
                <span [innerHTML]="col.header" *ngIf="col.header"
                      [class.with-toolTip]="col.toolTip"></span>
                <span class="wcag-visuallyhidden">Dummy</span>
              </ng-template>

              <fa-icon
                *ngIf="col.toolTip"
                class ="toolTip"
                pTooltip="{{col.toolTip.text | translate}}"
                [escape]="false"
                tooltipPosition="{{col.toolTip?.tooltipPosition ? col.toolTip?.tooltipPosition : 'top'}}"
                [icon]="col.icon?.iconProp ? col.toolTip.iconProp : ['fas', 'info-circle']">
              </fa-icon>

              <p-sortIcon id="{{col.field}}" [field]="col.sortField" *ngIf="col.sortField"></p-sortIcon>

            </ng-template>

          </th>

        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="tableColumns" let-expanded="expanded" let-rowIndex="rowIndex">

        <tr #rE  [ngClass]="{'highlightSelected': (highlightRowId>=0 && highlightRowId === rowData.id)}">

          <td *ngFor="let col of config.columns; let i = index" [ngClass]="col.className">

            <!-- CHECKBOX ROW SELECTION -->
            <ng-container *ngIf="col.checkboxSelect; else ROW_FIELD">
              <ng-container *ngIf="config.singleSelect; else MULTIPLE_SELECT">
                <p-tableRadioButton wcag-label [disabled]="col.checkboxDisabled || config.selectDisabled" [value]="rowData" (click)="selected()"></p-tableRadioButton>
              </ng-container>
              <ng-template #MULTIPLE_SELECT>
                <p-tableCheckbox [disabled]="col.checkboxDisabled || config.selectDisabled || rowData.disabled" wcag-label [value]="rowData" (click)="selected()"></p-tableCheckbox>
              </ng-template>
            </ng-container>

            <!-- TABLE FIELDS -->
            <ng-template #ROW_FIELD>

              <!-- REGULAR FIELD -->
              <ng-container *ngIf="!col.templateRef; else CUSTOM_CONTENT">

                <!-- FIELD WITH TRANSLATION -->
                <ng-container *ngIf="col.translate; else DO_NOT_TRANSLATE">
                  <!-- FIELD WITH EXPAND COLLAPSE -->
                  <ng-container *ngIf="col.expandCollapse; else REGULAR_DISPLAY">

                    <ng-container *ngIf="col.expandIcon; else EXPANDED_DATA">

                      <a wcag-dummy-link-target href="#" [pRowToggler]="rowData" *ngIf="!col.expandSectionData || (col.expandSectionData && rowData[col.expandSectionData] && rowData[col.expandSectionData].length > (col.expandSectionAllRecords ? 0 : 1))">
                        <span class="wcag-visuallyhidden">Open details</span>
                        <fa-icon class="float-left back-icon"  [icon]="['fas', expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
				              </a>

                    </ng-container>

                    <ng-template #EXPANDED_DATA>

                      <a href="#"
                         wcag-dummy-link-target
                         style="color: #012640"
                         *ngIf="rowData[col.expandSectionData]"
                         [pRowToggler]="rowData" (click)="setExpandSectionData(col.expandSectionData, rowData, col.field)">
                        {{ (col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate  }}
                      </a>
                    </ng-template>
                    <span class="wcag-visuallyhidden">Dummy</span>

                  </ng-container>

                  <!-- FIELD PURELY REGULAR DISPLAY 3-->
                  <ng-template #REGULAR_DISPLAY>
                    <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                      <ng-container *ngIf="checkRowDataExist(rowData,col)">
                        <ng-container *ngIf="col.ignoreTruncate; else TRUNCATE">
                          <span [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate"></span>
                        </ng-container>
                        <ng-template #TRUNCATE>
                          <span truncate-value [innerHTML]="(col.translationPrefix || config.translationPrefix) + '.' + rowData[col.field] | translate"></span>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                    <ng-template #CHIPS>
                      <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)" ><span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text "
                        [innerHTML] = "(col.translationPrefix || config.translationPrefix) + '.' + chip | translate"></span> <br/></span>
                    </ng-template>
                  </ng-template>

                </ng-container>

                <!-- FIELD WITH-OUT TRANSLATION -->
                <ng-template #DO_NOT_TRANSLATE>

                  <!-- FIELD STRING TYPE -->
                  <ng-container *ngIf="!col.dateTime; else DATETIME_CONTENT">

                    <!-- FIELD WITH EXPAND COLLAPSE -->

                    <ng-container *ngIf="col.expandIcon; else EXPANDED_DATA">
                      <a wcag-dummy-link-target href="#" [pRowToggler]="rowData" *ngIf="!col.expandSectionData || (col.expandSectionData && rowData[col.expandSectionData] && rowData[col.expandSectionData].length > (col.expandSectionAllRecords ? 0 : 1))">
                        <span class="wcag-visuallyhidden">Open details</span>
                        <fa-icon class="float-left back-icon"  [icon]="['fas', expanded ? 'chevron-down' : 'chevron-right']"></fa-icon>
				              </a>
                    </ng-container>

                    <ng-template #EXPANDED_DATA>

                      <ng-container *ngIf="col.expandCollapse && col.field; else REGULAR_DISPLAY">
                        <a href="#"
                           wcag-dummy-link-target
                           style="color: #012640"
                           *ngIf="isThereSubTable(rowData, col) && rowData[col.field]; else NO_EXPAND_COLLAPSE"
                           [pRowToggler]="rowData" (click)="setExpandSectionData(col.expandSectionDataRoot, col.expandSectionData, rowData, col.field)">
                          {{ rowData[col.field] }}
                        </a>

                        <ng-template #NO_EXPAND_COLLAPSE>
                          {{ rowData[col.field] }}
                        </ng-template>


                      </ng-container>

                    <!-- FIELD PURELY REGULAR DISPLAY 4-->
                    <ng-template #REGULAR_DISPLAY>
                      <ng-container *ngIf="!isNumeric(col); else NUMERIC">
                        <ng-container *ngIf="!['chips', 'strChips'].includes(col.fieldType); else CHIPS">
                          <ng-container *ngIf="!col.isHTML; else ISHTML">
                            <span truncate-value [innerHTML]="rowData[col.field]"></span>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-template>

                    </ng-template>
                  </ng-container>

                  <ng-template #ISHTML>
                    <span [innerHTML]="rowData[col.field]">
                    </span>
                  </ng-template>

                  <ng-template #NUMERIC>
                    <span *ngIf="!col.displayBlankForNumeric">{{ rowData[col.field] | localizedNumber: (col.format || '1.')}}</span>
                    <span *ngIf="col.displayBlankForNumeric &&  rowData[col.field] !== null">{{ rowData[col.field] | localizedNumber: (col.format || '1.')}}</span>
                    <span *ngIf="col.displayBlankForNumeric &&  rowData[col.field] === null">{{ rowData[col.field] }}</span>
                  </ng-template>

                  <!-- FIELD DATETIME TYPE -->
                  <ng-template #DATETIME_CONTENT>
                    {{ rowData[col.field] | date: 'yyyy-MM-dd HH:mm:ss Z'}}
                  </ng-template>

                  <ng-template #CHIPS>
                    <span *ngFor="let chip of getItems(rowData[col.field], col.fieldType)"><span class="badge badge-primary badge badge-primary badge-tag simple-tag wrap-text "
                      [innerHTML] ="chip"></span><br></span>
                  </ng-template>

                </ng-template>

              </ng-container>

              <!-- CUSTOM FIELD -->
              <ng-template #CUSTOM_CONTENT>
                <ng-container *ngTemplateOutlet="col.templateRef; context:  {$implicit: rowData, columnIdx:col, rowIndex: rowIndex, index: i}"></ng-container>
              </ng-template>

            </ng-template>

          </td>

      </ng-template>

      <ng-template pTemplate="rowexpansion" let-rowData let-col let-columns="tableColumns">
        <tr>
            <td [attr.colspan]="config.columns.length">
              <ng-container *ngIf="config.expandSectionTemplate else NO_TEMPLATE_EXPANDER">
                <ng-container *ngTemplateOutlet="config.expandSectionTemplate; context: {$implicit: rowData}"></ng-container>
              </ng-container>
              <ng-template #NO_TEMPLATE_EXPANDER>
                <div class="ui-g ui-fluid" style="font-size:16px;">
                    <div class="ui-g-12 ui-md-12">
                        <div class="ui-g">
                            <div class="ui-g-12">

                              <ng-container *ngIf="isSimpleTable()">

                                <app-table-simple
                                  [tableHeaders]="innerTableHeaders"
                                  [tableData]="innerTableData"
                                >
                                </app-table-simple>

                              </ng-container>

                            </div>
                        </div>
                    </div>
                </div>
              </ng-template>
            </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="paginatorright" let-state>
        <span *ngIf="state.totalRecords"
              [translate]="'COMMON.table.totalRecords'"
              [translateParams]="{
                total: state.totalRecords
              }">
        </span>
      </ng-template>

    </p-table>

  </ng-template>

</div>

<ng-template #TABLE_CONTENT>

</ng-template>
