import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import {
  DEFAULT_DIALOG_CONFIG,
  IGNORE_ERROR_URIS,
  ORGANIZATION_NAME_REQUIRED_TYPES,
  SereviceMessageType
} from '@core/constants/serviceMessage.const';
import { IDisplayMessages, IMessages } from '@core/models/serviceMessage.model';
import { ModalService } from '@shared/services/modal.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RespondInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private modalService: ModalService) {
  }

  private isQuiet(event): boolean {
    const url = new URL(event.url);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.has('quiet');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {

        if (event instanceof HttpResponse && event.status == 200 && event.body && event.body.message) {
          if (event.body.message !== 'SUCCESS' && event.body.message !== 'MFATokenMissing'
          && event.body.message.indexOf('SAMLFirst') == -1) {

          const messages: IDisplayMessages = {
              messages: [{
                message: event.body.message,
                ...this._addMetaDataIfNeeded(event.body),
              }],
              type: SereviceMessageType.SUCCESS,
            };

          !this.isQuiet(event) && this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
              .afterClosed()
              .subscribe((result?: boolean) => { });
          }
        }
      }, (err: any) => {

        let skipMessage = false;

        if (err.url) {
          IGNORE_ERROR_URIS.forEach(uri => {
            if (err.url.indexOf(uri) > -1) {
              skipMessage = true;
            }
          });
        }
        if (!skipMessage) {
          if (err.error && err.error.messages && err.error.messages.length > 0) {

            const messageList: IMessages[] = err.error.messages.map(message => ({
              message,
            }));

            const messages: IDisplayMessages = {
              messages: messageList,
              type: SereviceMessageType.ERROR,
            };

            this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
              .afterClosed()
              .subscribe((result?: boolean) => {
              });
          } else if (err.error && err.error.dynamicFieldMessages && err.error.dynamicFieldMessages.length > 0) {

            const messages: IDisplayMessages = {
              messages: err.error.dynamicFieldMessages,
              type: SereviceMessageType.ERROR,
            };

            this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
              .afterClosed()
              .subscribe((result?: boolean) => {
              });
          }else if (err.error && err.error.code === 401) {

            const messageList: IMessages[] = [{message :'accessDenied'}];

            const messages: IDisplayMessages = {
              messages: messageList,
              type: SereviceMessageType.ERROR,
            };

            this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
              .afterClosed()
              .subscribe((result?: boolean) => {
              });
          }
        }

      }),
    );
  }

  private _addMetaDataIfNeeded(data) {
    if (ORGANIZATION_NAME_REQUIRED_TYPES.includes(data.message)) {
      return { metaData: [data.entity.organizationName] };
    }

    return {};
  }
}
