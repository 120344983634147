export enum projectRoutes {
  Issuance = 'issuance',
  ProjectDetails = 'project-details',
  ProjectDocuments = 'project-documents',
  SubProjects = 'sub-projects',
}

export const REVIEW = {

  uri: 'review',
};

export const REVIEW2 = {
  uri: 'review2',
};

export const PROJECT_ACTIONS = {
  NEED_REVIEW: REVIEW,
  REJECT: REVIEW,
  APPROVE: REVIEW,
  APPROVE_SECOND:  REVIEW2,
  REJECT_SECOND: REVIEW2,
  REJECT_REJECTION:  {
    uri: 'review2',
    actionLabel: 'rejectTheRejectionOf',
  },
  APPROVE_REJECTION: {
    uri: 'review2',
   actionLabel: 'approveTheRejectionOf',
  },
  DISCARD: {
    confirmationMessage: 'confirmationMessageProjectDiscard',
    uri: 'discard',
  },
  SUBMIT_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT: {
    uri: 'getByAdditionToAggregationProjectId',
    id: 'projectsInAggregationId',
    actionLabel: 'SUBMIT_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT',
    confirmationMessage: 'confirmationSubmissionOfAddition',
  },
  REVIEW_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT: {
    uri: 'getByAdditionToAggregationProjectId',
    id: 'projectsInAggregationId',
    actionLabel: 'REVIEW_PROJECT_ADDITIONS_TO_AGGREGATED_PROJECT',
    confirmationMessage: 'confirmationSubmissionOfAddition',
  },
};

export const PROJECT_ACCOUNT_ACTIVE_STATUSES = ['ACTIVE', 'LOCKED', 'CLOSED'];
export const PROJECT_ACCOUNT_INACTIVE_ACTIONS = ['VIEW_HISTORY'];

export const ATTESTATION_FIELDS = [
  'confidentialBusiness',
  'attnProponentIsAuthorized',
  'attnExclusiveEntitlementToReductions',
  'attnOldRegNotCancelledVoluntaryReversal',
  'attnNotFailedToComplyWithSectionOfAct',
  'attnNotConvictedOfOffenceUnderCriminalCode',
  'attnEligibilityCriteriaOfProtocolAreMet',
  'attnProjectNotRegCreditForGhgReductions',
  'attnFreeFromConflictOfInterestRegulations',
];

export const PROJECT_ADMIN_REMARKS_FIELDS = [
  'adminRemark',
  'discloseComment',
];
