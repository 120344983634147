import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgSelectComponent } from "@shared/components/ng-select/ng-select.component";
import { ProjectDiscussionAddCommentComponent } from "@shared/components/project-discussion/project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.component";
import { ProjectDiscussionAddComment } from "@shared/components/project-discussion/project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.constants";
import { IProjectDiscussionAddCommentComponent } from "@shared/components/project-discussion/project-discussion-add-comment/components/project-discussion-add-comment/project-discussion-add-comment.types";
import {
  PROJECT_DISCUSSION_FILTER_ALL,
  PROJECT_DISCUSSION_SORT,
  PROJECT_DISCUSSION_SORT_ASC,
} from "@shared/components/project-discussion/project-discussion.constants";
import {
  IProjectDiscussionFilter,
  IProjectDiscussionSort,
} from "@shared/components/project-discussion/project-discussion.types";
import { ProjectDiscussionService } from "@shared/components/project-discussion/service/project-discussion.service";
import { IProjectDiscussionGetCommentsResponse } from "@shared/components/project-discussion/service/project-discussion.service.types";
import { ModalService } from "@shared/services/modal.service";
import { IProject } from "@shared/services/project.service.types";
import { take } from "rxjs/operators";

@Component({
  selector: "app-project-discussion-controls",
  templateUrl: "./project-discussion-controls.component.html",
  styleUrls: ["./project-discussion-controls.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiscussionControlsComponent implements OnChanges {
  @Input() comments: IProjectDiscussionGetCommentsResponse;
  @Input() project: IProject;
  @ViewChild("filterBy", { static: true }) filterBy: NgSelectComponent;

  filterByItems = [PROJECT_DISCUSSION_FILTER_ALL];
  filterByModel = [PROJECT_DISCUSSION_FILTER_ALL];

  sortByItems = PROJECT_DISCUSSION_SORT;
  sortByModel = PROJECT_DISCUSSION_SORT_ASC;

  constructor(
    private modalSvc: ModalService,
    private projectDiscussionSvc: ProjectDiscussionService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.comments && changes.comments.currentValue) {
      this.updateFilterBy();
    }
  }

  onAdd(item: IProjectDiscussionFilter): void {
    const [first] = this.filterByModel;

    if (first) {
      if (
        (item.value === null && first.value !== null) ||
        (item.value !== null && first.value === null)
      ) {
        this.processItem(item);
      }
    }

    this.projectDiscussionSvc.filter = this.filterByModel
      .filter((entry) => entry.value !== null)
      .map((entry) => entry.value);
    this.projectDiscussionSvc.onLoadComments();
  }

  onRemove(): void {
    if (this.filterByModel.length === 0) {
      this.selectItem(PROJECT_DISCUSSION_FILTER_ALL);
    }
    this.projectDiscussionSvc.filter = [];
    this.projectDiscussionSvc.onLoadComments();
  }

  onSortBy(value: IProjectDiscussionSort): void {
    this.projectDiscussionSvc.sort = value;
    this.projectDiscussionSvc.onLoadComments();
  }

  onAddComment(): void {
    const data: IProjectDiscussionAddCommentComponent = {
      project: this.project,
      mode: ProjectDiscussionAddComment.Comment,
    };
    this.modalSvc
      .open(ProjectDiscussionAddCommentComponent, data)
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result === true) {
          this.projectDiscussionSvc.onLoadComments();
        }
      });
  }

  private updateFilterBy(): void {
    const { idNameList } = this.comments;

    if (!idNameList) {
      return;
    }

    const values: IProjectDiscussionFilter[] = Object.entries(idNameList).map(
      (entry) => {
        const [value, label] = entry;
        return { label, value: value as any };
      }
    );

    this.filterByItems = [PROJECT_DISCUSSION_FILTER_ALL].concat(values);
  }

  private processItem(item: IProjectDiscussionFilter): void {
    this.filterBy.handleClearClick();
    setTimeout(() => {
      this.selectItem(item);
      this.projectDiscussionSvc.filter = this.filterByModel.map(
        (entry) => entry.value
      );
    });
  }

  private selectItem(item: IProjectDiscussionFilter): void {
    const option = this.filterBy.itemsList.items.find(
      (opt) => opt.value["value"] === item.value
    );
    this.filterBy.select(option);
  }
}
