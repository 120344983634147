<div class="inner">
  <div id="request-registration-cancellation" class="d-md-flex justify-content-center pt-5 align-content-stretch">

      <div class="col-sm-12 col-md-9 col-xl-6 mb-5 d-inline-block">

          <h1>{{'ACCOUNTS_MODULE.reqRegCancellation.title' | translate }}</h1>

          <div class="d-md-flex justify-content-center mt-2">
              <div class="card mb-2 mx-auto w-100 card-override">
                  <div class="card-body">
                      <div *ngIf="!isVb">
                      <p>{{'ACCOUNTS_MODULE.reqRegCancellation.message1' | translate }}</p>
                      <p>{{'ACCOUNTS_MODULE.reqRegCancellation.message2' | translate }}</p>      </div>
                      <formly-form [form]="formGroup" [model]="model" [fields]="fields"></formly-form>
                  </div>

                  <div class="card-footer">
                      <button type="button" class="btn btn-secondary mr-2" *ngIf="!isViewType()" (click)="registrationCancellation('SUBMIT')" [disabled]="!isValidForSubmitting()">{{ 'ACCOUNTS_MODULE.reqRegCancellation.submit' | translate }}</button>
                      <button type="button" class="btn btn-secondary mr-2" *ngIf="showApproveReject()" (click)="registrationCancellation('APPROVE')">{{ 'ACCOUNTS_MODULE.reqRegCancellation.approve' | translate }}</button>
                      <button type="button" class="btn btn-secondary mr-2" *ngIf="showApproveReject()" (click)="registrationCancellation('REJECT')">{{ 'ACCOUNTS_MODULE.reqRegCancellation.reject' | translate }}</button>
                      <button type="button" class="btn btn-secondary mr-2" *ngIf="showApproveReject2()" (click)="registrationCancellation('APPROVE2')">{{ 'ACCOUNTS_MODULE.reqRegCancellation.approve' | translate }}</button>
                      <button type="button" class="btn btn-secondary mr-2" *ngIf="showApproveReject2()" (click)="registrationCancellation('REJECT2')">{{ 'ACCOUNTS_MODULE.reqRegCancellation.reject' | translate }}</button>
                     <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{ 'ACCOUNTS_MODULE.reqRegCancellation.close' | translate }}</button>
                  </div>

              </div>
          </div>

      </div>

  </div>
</div>

<ng-template #viewColTemplate let-data>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewUnitDetails($event, data)"
          (keyup)="viewUnitDetails($event, data)">
      {{ 'COMMON.actionsLabel.view' | translate}}
    </span>
  </div>
</ng-template>

<ng-template #unitsTableFooterTemplate>
  <tr>
    <td><span [innerHTML]="'ACCOUNTS_MODULE.reqRegCancellation.total' | translate"></span></td>
    <td>{{ units?.pageStats?.total | localizedNumber: '1.0-0' }}</td>
    <td colspan="4"></td>
  </tr>
</ng-template>
