import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from '@shared/services/modal.service';
import { IApp } from '@core/models/app.interfaces';
import { OMPAccountType, AccountRegistrationType } from '@core/models/account.model';
import { OMP_ACCOUNT_TYPE } from './ompAccountTypePurpose.const';
import { RegistrationService } from '@module/registration/registration.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';
import { AccountSubType } from '@module/registration/registration.model';

@Component({
  selector: 'app-omp-account-types',
  templateUrl: './omp-account-types.component.html',
  styleUrls: ['./omp-account-types.component.scss']
})
export class OmpAccountTypesComponent implements OnInit {

  private _destroy$ = new Subject<any>();

  accoutTypes:AccountSubType[];
  registrationType:AccountRegistrationType[];

  @Input() isSubaccount: boolean;
  @Input() showBackButton = true;
  @Output() back = new EventEmitter();
  @Output() next= new EventEmitter<IApp.OMPAccountType>();

  organization = OMP_ACCOUNT_TYPE.ORGANIZATION;
  individual = OMP_ACCOUNT_TYPE.INDIVIDUAL;

  selectionAccountType = new AccountSubType();
  selectionAccountRegistraionType = new AccountRegistrationType();

  ompAccountType = new OMPAccountType();

  constructor(
    private registrationService: RegistrationService,
    private modalService: ModalService,) { }

  ngOnInit() {
    this.getAccountTypes();
    this.getRegistrationTypes();
  }

  hideOption(accountTypeID){
    if( this.isSubaccount && accountTypeID === 2)
      return false;

    return true;
  }

  getAccountTypes(){
    this.registrationService.getAccountSubtypeById(4).pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      data => {
       this.accoutTypes = data;
      },
      err => {
      }
    );
  }

  getRegistrationTypes(){
    this.registrationService.getRegistrationType().pipe(
      takeUntil(this._destroy$),
    ).subscribe(
      data => {
       this.registrationType =data;
      },
      err => {
      }
    );
  }

  backStep(){
    this.back.emit();
  }

  nextStep(){
    this.ompAccountType.accountRegistrationType = this.selectionAccountRegistraionType;
    this.ompAccountType.accountSubType = this.selectionAccountType
    this.next.emit(this.ompAccountType);
  }

  onlyIfNoselection(){
    return this.selectionAccountType.id === undefined || this.selectionAccountRegistraionType.id === undefined;
  }

}
