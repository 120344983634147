<li *ngIf="programs?.length > 1"
    class="nav-item"
    ngbDropdown
    placement="bottom-right"
    #programDropdown>
  <span id="programDropdown"
        ngbDropdownToggle>
    {{ 'APP_HEADER.headerSection.selectedProgram' | translate }} <strong>{{ 'COMMON.programTypeList.' + program | translate }}</strong></span>
  <div ngbDropdownMenu aria-labelledby="programDropdown">
    <button *ngFor="let program of programs" ngbDropdownItem (click)="switchProgram(program.id)">{{ 'COMMON.programTypeList.' + program.name | translate }}</button>
  </div>
</li>

