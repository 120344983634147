export const STATUS_COMPLETED = 'completed';
export const STATUS_DRAFT = 'draft';
export const STATUS_CALCULATE= 'calculate';
export const STATUS_WARNING = 'warning';

export const STATUS_ICON = {
  completed : {
    style: {'color': 'green', 'font-size': '2rem'},
    icon: ['fas', 'check-circle'],
  },
  draft : {
    style: {'color': 'gray', 'font-size': '2rem'},
    icon: ['fas', 'circle'],
  },
  incomplete : {
    style: {'color': 'red', 'font-size': '2rem'},
    icon: ['fas', 'minus-circle'],
  },
  warning : {
    style: {'color': 'orange', 'font-size': '2rem'},
    icon: ['fas', 'exclamation-circle'],
  },
};

export const SUB_ORGANIZATION_ACCOUNT = 'SUB_ORGANIZATION_ACCOUNT';
export const REGISTRATION_ACCOUNT = 'REGISTRATION_ACCOUNT'

export const ACTIVE_PROJECT_TRANSFER_ACTIONS = [
  'TRANSFER_PROJECT',
  'TRANSFER_PROJECT_SUB',
  'TRANSFER_AGGREGATED_PROJECT',
  'REVIEW_PROJECT_TRANSFER_REQUEST_CUST',
  'REQUEST_TRANSFER_PROJECT',
  'REVIEW_PROJECT_TRANSFER_REQUEST',
  'REVIEW_PROJECT_TRANSFER_REQUEST_TRANSFEREE',
];


export const PROJECT_TRANSFER_ACTIONS = [
  'VIEW_PROJECT_TRANSFER_REQUEST',
  ...ACTIVE_PROJECT_TRANSFER_ACTIONS
];

export const PROJECT_CANCEL_ACTIONS = [
  'CANCEL_PROJECT',
  'CANCEL_PROJECT_SUB',
  'REQUEST_CANCELLATION',
  'REQUEST_CANCELLATION_SUB'
];

export const SECONDARY_ACTIONS = [
  'REJECT_SECOND',
  'APPROVE_SECOND',
  'REJECT_REJECTION',
  'APPROVE_REJECTION',
  'NEED_MORE_INFO',
  'REJECT',
  'APPROVE',
  'EDIT',
  'SUBMIT',
  'RESUBMIT',
  'EDIT_CUST'
];

export const ACTIVE_ACCOUNT_ACTIONS = [
  'REVIEW_CANCELLATION_REQUEST',
  ...PROJECT_CANCEL_ACTIONS,
  ...ACTIVE_PROJECT_TRANSFER_ACTIONS
];

export const OTHER_AREA_OF_EXPERTISE_ID = 24;
