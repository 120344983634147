import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { StoreService } from '@core/store/store.service';

@Directive({
  // tslint:disable-next-line:directive-selector
    selector: '[hasPermission]',
})
export class HasPermissionDirective implements OnInit {

    private permissions;
    private elseTemplateRef: TemplateRef<any> | null = null;

    constructor(private element: ElementRef,
                private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private storeSvc: StoreService) {
    }

    ngOnInit() {
        this.updateView();
    }

    @Input()
    set hasPermission(val) {
        this.permissions = Array.isArray(val) ? val : [val];
        this.updateView();
    }

    @Input()
    set hasPermissionElse(templateRef: TemplateRef<any> | null) {
        assertTemplate('hasPermissionElse', templateRef);
        this.elseTemplateRef = templateRef;
        this.updateView();
    }

    private updateView() {
        this.viewContainer.clear();
        if (this.userHasPermission) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            if (this.elseTemplateRef) {
                this.viewContainer.createEmbeddedView(this.elseTemplateRef);
            }
        }
    }

    private get userHasPermission(): boolean {
      return this.storeSvc.user.hasOneOfThePermission(this.permissions);
    }
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
    const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
    if (!isTemplateRefOrNull) {
        throw new Error(`${property} must be a TemplateRef, but received '${JSON.stringify(templateRef)}'.`);
    }
}
