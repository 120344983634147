import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { ActionPanelService } from '../../services/action-panel.service';
import { AppInjector } from '../../services/app-injector.service';

@Component({
  selector: 'app-base-page',
  template: '<div></div>',
})
export class BasePageComponent implements OnDestroy {

  public _destroy$ = new Subject<any>();
  public actionPanelVisible = new BehaviorSubject<boolean>(null);
  public withActionPanel = false;

  router;
  actionPanelService;
  activatedRoute;
  sidebarComponent;

  constructor() {

    const injector = AppInjector.getInjector();
    this.router = injector.get(Router);
    this.actionPanelService = injector.get(ActionPanelService);
    this.activatedRoute = injector.get(ActivatedRoute);

    this.actionPanelService.withActionPanel$
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(
        state => {
          this.withActionPanel = state;
        },
      );

    this.actionPanelService.panelVisible$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this._destroy$),
      )
      .subscribe(
        state => this.actionPanelVisible.next(state),
      );

    this.router.events
      .pipe(
        takeUntil(this._destroy$),
      )
      .subscribe(event => {
      if (event instanceof NavigationEnd) {
        const firstChild = this.activatedRoute.snapshot.firstChild.firstChild.firstChild.firstChild.firstChild;
        const routeData = firstChild ? firstChild.data : null;

        if (routeData && routeData.actionPanelComponent) {
          this.sidebarComponent = routeData.actionPanelComponent;
        }

        const value = routeData ? routeData.actionPanel : false;
        this.actionPanelService.withActionPanel$.next(value);
      }
    });

  }

  toggleActionPanel() {
    this.actionPanelService.toggle();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
