import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit, AfterViewInit {

  // @Input() header: string;
  // @Input() visible: boolean;

  // @Output() visibleChange = new EventEmitter();
  public form;
  public model;
  public fields;
  public visible = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<FormModalComponent>
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.data.disabled) {
      this.data.form.disable();
    }
  }

  changed(event) {
    // this.visibleChange.emit(event);
    this.dialogRef.close(true);
  }

  close(): void {
    this.visible = false;
    this.dialogRef.close(false);
  }

}
