import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import * as moment from 'moment';

const DEFAULT_PLACEHOLDER = 'COMMON.calendar.selectDate';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent {

  @Input() set value(val: string) {
    if (val === null) {
      this.currentValue = null;
    }
    const d = moment(val);
    if (d.isValid()) {
      this.currentValue = d.toDate();
    }
  }
  @Input() placeholder = DEFAULT_PLACEHOLDER;
  @Input() appendTo;
  @Input() id;
  @Output() dateChanged = new EventEmitter();

  public currentValue: Date;

  constructor(
    private cd: ChangeDetectorRef,
  ) { }

  onChange(event) {
    this.dateChanged.emit(event);
    this.cd.detectChanges();
  }

}
