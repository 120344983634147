import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { Subject, pipe } from 'rxjs';
import {  takeUntil } from 'rxjs/operators';
import { CreditClearanceMechanismService } from '../credit-clearance-mechanism.service';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';
import { COMPLIANCE_REPORT_PERIOD_FLD, getComplianceReportPeriod } from '../credit-clearance-mechanism.const';
import { IRawFormBase } from '@core/models/raw-form.types';

@Component({
  selector: 'app-new-credit-clearance-mechanism',
  templateUrl: './new-credit-clearance-mechanism.component.html',
  styleUrls: ['./new-credit-clearance-mechanism.component.scss']
})
export class NewCreditClearanceMechanismComponent implements OnInit {

  private _destroy$ = new Subject<any>();

  visible = true;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  model = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    private dialogRef: MatDialogRef<NewCreditClearanceMechanismComponent>,
    private formFactoryService: FormFactoryService,
    private creditClearanceMechanismService: CreditClearanceMechanismService,
    private router: Router,
    private helperService: HelperService,
  ) { }

  ngOnInit() {
    this.fields = this.formFactoryService.configureForm(this.getFormFields());
  }

  get payload() {
    return {
      complianceYear: this.model[COMPLIANCE_REPORT_PERIOD_FLD],
    };
  }

  create(): void {
    this.form.markAllAsTouched();
    this.formFactoryService.markFormAsDirty(this.form);
    if (this.form.valid) {
      this.close();
      this.creditClearanceMechanismService.setupCCM(this.payload)
        .pipe(takeUntil(this._destroy$))
        .subscribe(resp => {
          this.close();
          this.router.navigate(this.helperService.getTranslatedPath('/credit-clearance/mechanism/details'), {state: {data: resp}});
        });
    }
  }

  getFormFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        className: 'col-12',
        fieldGroup: [
          getComplianceReportPeriod(),
        ],
      }];
  }

  close() {
    this.visible = false;
    this._destroy$.next();
    this._destroy$.complete();
    this.dialogRef.close();
  }

}
