import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { environment } from '@env/environment';
import { AbstractPaginationService } from '@shared/services/abstract-pagination-service';
import { Observable } from 'rxjs';
import { ObligationMgmtFilterModel, RefundRequestFilterModel } from '@core/models/filter.model';
import { Router } from "@angular/router";
import { HelperService } from "@shared/services/helper.service";
import { SubmitRefundPayment, ValudateDetailsToProceed } from '@core/models/submitPaymentRefund';
import { MarkAsCompleted, CancelPaymentRefund } from '@core/models/PaymentRefunds';
import { IOkResponse } from '@module/registration/registration.model';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { StoreService } from '@core/store/store.service';
import { ModalService } from '@shared/services/modal.service';

export const OBLIGATION_MANAGEMENT = '/registry-server/obligation/search';
export const CREDIT_SERVICE = '/obps-service';
export const OBLIGATION_DETAILS = '/registry-server/obligation/getObligationDetail';
export const ASSESSMENT_RESULT_REPORT = '/obps-service/credits/assessmentResultReports';
export const REFUND_REQUEST = '/obps-service/payment-refund/search';
export const DEADLINE_BY_OBLIGATION_ID = '/obps-service/properties/getByObligationId/';
export const PAYMENT_REFUND_OBLIGATION_BY_ID = '/obps-service/payment-refund/obligation';
export const VALIDATE_DETAILS = '/obps-service/payment-refund/validateAndGetDetailToProceed';
export const PAYMENT_BY_ID = '/obps-service/transaction/getPaymentById/';
export const SUBMIT_PAYMENT_REFUND = '/obps-service/payment-refund/submitPaymentRefund';
export const OBLIGATION_TOWARD_ID_DETAILS = '/payment-refund/id/';

export const COMPLETE_PAYMENT_REFUND = '/obps-service/payment-refund/completePaymentRefund';
export const CANCEL_PAYMENT_REFUND = '/obps-service/payment-refund/cancelPaymentRefund';

export const MARK_PAYMENT_REFUND_IN_PROGRESS ='/obps-service/payment-refund/markPaymentRefundInProgress';
export const DOWNLOAD_REFUND_REQUEST = '/obps-service/payment-refund/downloadPaymentRefundTemplate/';
export const DOWNLOAD_REFUND_REQUEST_PREVIEW = '/obps-service/payment-refund/downloadPaymentRefundSubmittedForm/'
export const BULK_ACTION = '/obps-service/payment-refund/doBulkAction';

export const COMPLETE_CASH_PAYMENT_REFUND ='/obps-service/payment-refund/completeCashPaymentRefund';
export const REJECT_PAYMENT_REFUND ='/obps-service/payment-refund/rejectPaymentRefund';
export const APPROVE_PAYMENT_REFUND = '/obps-service/payment-refund/approvePaymentRefund';
const EEC_PERCENTAGE_IGNORE = [181, 178];

@Injectable({
  providedIn: 'root',
})
export class ObligationManagementService extends AbstractPaginationService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private helperService: HelperService,
    private store: StoreService,
    private modalService: ModalService
  ) {
    super();
  }

  getObligationtowardsDetailsById(id: number): Observable<MarkAsCompleted> {
    const url = `${ environment.apiUrl }${CREDIT_SERVICE}${ OBLIGATION_TOWARD_ID_DETAILS }${ id }`;
    return this.http.post<MarkAsCompleted>(url, {});
  }

  doAction( uri:string, payload: any): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ CREDIT_SERVICE }${ uri }`, payload);
  }

  getObligationMgntList(pagination: IApp.IPagination, filterState: ObligationMgmtFilterModel = {}): Observable<IApp.IObligationManagementPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    let path = `${ environment.apiUrl }${ OBLIGATION_MANAGEMENT }`;
    if(this.store.obligationId != null && this.store.obligationId !== "undefined"){
      path =  `${path}?obligationId=${this.store.obligationId}`;
      this.store.clearObligationId();
    }
    return this.http.post<IApp.IObligationManagementPage>(path, filterState, { params });
  }

  getRefundRequestLIst(pagination: IApp.IPagination, filterState: RefundRequestFilterModel = {}): Observable<IApp.IRefundRequestPage> {
    let params = new HttpParams();
    params = this.addPagination(params, pagination);
    return this.http.post<IApp.IRefundRequestPage>(`${ environment.apiUrl }${ REFUND_REQUEST }`, filterState, { params });
  }

  getObligationDetails(obligationId: number): Observable<IApp.IObligation> {
    const url = `${ environment.apiUrl }${ OBLIGATION_DETAILS }/${ obligationId }`;
    return this.http.get<IApp.IObligation>(url);
  }

  getAssessmentResultReportData(obligationId: number): Observable<IApp.IAssessmentResultReport[]> {
    const url = `${ environment.apiUrl }${ ASSESSMENT_RESULT_REPORT }/${ obligationId }`;
    return this.http.get<IApp.IAssessmentResultReport[]>(url);
  }

  openAvailableUnitCredits(codeType: string,obligationData: IApp.IObligation ) {
    this.router.navigate(
      this.helperService.getTranslatedPath(
        `/obligation-management/remittance/${codeType}/${obligationData.obligationId}`
      ),
      { state: { data: obligationData } }
    );
  }

  getPaymentDeadline(obligationId: number): Observable<any>{
    const url = `${ environment.apiUrl }${ DEADLINE_BY_OBLIGATION_ID }${ obligationId }`;
    return this.http.get<any>(url);
  }

  getPaymentById(id: number): Observable<IApp.IPaymentConfirmation> {
    return this.http.get<IApp.IPaymentConfirmation>(`${ environment.apiUrl }${ PAYMENT_BY_ID }${id}`);
   // return this.http.get<IApp.IPaymentConfirmation>(`${ environment.cra }${ PAYMENT_BY_ID }${id}`);
  }

  getPaymentRefundObligationById(id: number){
    return this.http.post<IApp.RefundMode>(`${ environment.apiUrl }${ PAYMENT_REFUND_OBLIGATION_BY_ID }/${ id }`, {});
  }

  validateAndGetDetailToProceed(payload: ValudateDetailsToProceed): Observable<ValudateDetailsToProceed>{
    return this.http.post<ValudateDetailsToProceed>(`${ environment.apiUrl }${ VALIDATE_DETAILS }`, payload);
  }

  submitPaymentRefund(payload: SubmitRefundPayment): Observable<SubmitRefundPayment>{
    return this.http.post<SubmitRefundPayment>(`${ environment.apiUrl }${ SUBMIT_PAYMENT_REFUND }`, payload);
  }

  completePaymentRefund(payload: MarkAsCompleted): Observable<MarkAsCompleted>{
    return this.http.post<MarkAsCompleted>(`${ environment.apiUrl }${ COMPLETE_PAYMENT_REFUND }`, payload);
  }

  cancelPaymentRefund(payload: CancelPaymentRefund): Observable<CancelPaymentRefund>{
    return this.http.post<CancelPaymentRefund>(`${ environment.apiUrl }${ CANCEL_PAYMENT_REFUND }`, payload);
  }

  bulkAction(payload): Observable<any>{
    return this.http.post<any>(`${ environment.apiUrl }${ BULK_ACTION }`, payload);
  }

  completeCashPaymentRefund(payload): Observable<any>{
    return this.http.post<any>(`${ environment.apiUrl }${ COMPLETE_CASH_PAYMENT_REFUND }`, payload);
  }

  rejectPaymentRefund(payload): Observable<any>{
    return this.http.post<any>(`${ environment.apiUrl }${ REJECT_PAYMENT_REFUND }`, payload);
  }

  approvePaymentRefund(payload): Observable<any>{
    return this.http.post<any>(`${ environment.apiUrl }${ APPROVE_PAYMENT_REFUND }`, payload);
  }

  openExcessEmissioncharge(obligationData: IApp.IObligation ) {
    this.router.navigate(
      this.helperService.getTranslatedPath(
        `/obligation-management/eec/${obligationData.obligationId}`
      ),
      { state: { data: obligationData } }
    );
  }

  openMarkRefundAsComplete(obligationData: IApp.IObligation ) {
    this.router.navigate(
      this.helperService.getTranslatedPath(
        `/obligation-management/mark-refund-as-complete`
      ),
      { state: { data: obligationData } }
    );
  }

  openRefundRequestForm(obligationData: IApp.IObligation ){
    this.router.navigate(
      this.helperService.getTranslatedPath(
        `/obligation-management/refunds-requested-form/${obligationData.obligationId}`
      ),
      { state: { data: obligationData } }
    );
  }

  markPaymentRefundInProgress(id: number){
    return this.http.post<any>(`${ environment.apiUrl }${ MARK_PAYMENT_REFUND_IN_PROGRESS }`, { id });
  }

  show25Check(data){
    let show = true;
    EEC_PERCENTAGE_IGNORE.forEach(el => {
      if(data.versionProvision.indexOf(el.toString()) > -1){
        show = false;
      }
    });
    return show;
  }

  public showErrorMessage(message: string) {
    return this.modalService
      .open(
        ServiceMessageComponent,
        {
          messages: [
            {
              message: message,
            },
          ],
          message: message,
          type: SereviceMessageType.ERROR,
        },
        true,
        DEFAULT_DIALOG_CONFIG,
      )
      .afterClosed()
  }

}
