<div id="account-actions" *ngIf ="actionsList?.length > 0">
    <ng-select
        wcag-label
        [placeholder]="'COMMON.actionsLabel.actions' | translate"
        [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.actions' | translate}"
        [items]="actionsList"
        bindLabel="label"
        bindValue="label"
        class="ng-dropdown-left "
        [(ngModel)]="dummyModel"
        [appendTo]="appendTo"
        [alignRight]="true"
        (change)="triggerActionController($event)"
        >
    </ng-select>
</div>
