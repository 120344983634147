<p-dialog
  [className]="'eccc'"
  [(visible)]="visible"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '1000px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false"
  [closable]="false"
  [header] = "'CREDIT_CREATION_REPORT.reportStep.additionalDetailsTitle' | translate">
  <form [formGroup]="currentStepObj.additionalDetailsForm" *ngIf ="currentStepObj && currentStepObj.additionalDetailsForm">
    <formly-form [form]="currentStepObj.additionalDetailsForm" [model]="currentStepObj.additionalDetailsModel" [fields]="currentStepObj.additionalDetailsFields"></formly-form>
  </form>

  <div *ngIf="currentStepObj.detailSectionDocumentUploadConfig" class="form-group mt-4">
    <app-documents
      [documentUploadConfig]="currentStepObj.detailSectionDocumentUploadConfig"
      (documentResult)="documentUploadHandler($event)"
      [showPlaceholders]="true">
    </app-documents>
  </div>

  <div class="d-flex justify-content-end mt-2">
    <button type="button" class="btn btn-secondary mr-2" (click)="close()">{{'CREDIT_CREATION_REPORT.reportStep.close' | translate}}</button>
  </div>
</p-dialog>
