import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { LookupService } from '@core/services/lookup.service';
import { defaultAccountSelected, StoreService } from '@core/store/store.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

const DEFAULT_SELECTION = defaultAccountSelected;

@Component({
  selector: 'app-facility-account-chooser',
  templateUrl: './facility-account-chooser.component.html',
  styleUrls: ['./facility-account-chooser.component.scss'],
})
export class FacilityAccountChooserComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<any>();
  public facilityNames: IApp.ILookup[];
  @ViewChild('facilityNameNgSelect', {static: false}) facilityNameNgSelect;

  selectedItems: any;
  show: boolean;

  constructor(
    private storeService: StoreService,
    private lookupService: LookupService,
    private translateService: TranslateService,
  ) {
    this.translateService.onLangChange
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        const facilityNames = this.facilityNames;
        facilityNames[0].name = this.translateService.instant('COMMON.facilityAccountChooser.allAccountsText');
        this.facilityNames = [...facilityNames];
      });
  }

  ngOnInit() {
    this.getFacilityNames();
    this.storeService.accountFacilities$
      .pipe(
        skip(1),
        takeUntil(this._destroy$),
      )
      .subscribe(
        () => this.getFacilityNames(),
      );
  }

  getFacilityNames() {
    this.show = !this.storeService.user.checkIfCFRNonAdmin();
    this.lookupService.getFacilityNames(true).pipe(
      takeUntil(this._destroy$),
    ).subscribe(value => {
      this.facilityNames = [
        {
          id: -1,
          name: this.translateService.instant('COMMON.facilityAccountChooser.allAccountsText'),
        }, ...value];
      setTimeout(() => {
        this.selectedItems = this.storeService.accountFacilities;
      }, 100);
    });
  }

  onAdd(item) {
    const processItem = i => {
      this.facilityNameNgSelect.handleClearClick();
      setTimeout(() => {
        const option = this.facilityNameNgSelect.itemsList.items.find(opt => opt.value['id'] === i.id);
        this.facilityNameNgSelect.select(option);
        this.storeService.accountFacilities = this.selectedItems.flat();
      });
    };

    if (item.id === DEFAULT_SELECTION) {
      if (this.selectedItems[0] && this.selectedItems[0] !== DEFAULT_SELECTION) {
        processItem(item);
      }
    } else {
      const firstItem = this.selectedItems[0];
      if (firstItem && firstItem === DEFAULT_SELECTION) {
        processItem(item);
      }
    }
    this.storeService.accountFacilities = this.selectedItems.flat();
  }

  onRemove(item) {
    if (this.selectedItems.length === 0) {
      const option = this.facilityNameNgSelect.itemsList.items.find(opt => opt.value['id'] === DEFAULT_SELECTION);
      this.facilityNameNgSelect.select(option);
    }
    this.storeService.accountFacilities = this.selectedItems.flat();
  }

  getSelectedAccountNames(items) {
    return items.map(i => i.name).join(', ');
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
