<h1 class="wcag-visuallyhidden">Registration</h1>
<h2 class="wcag-visuallyhidden">Registration</h2>
<h3 *ngIf="header">{{ header | translate }}</h3>
<h3 *ngIf="!header" class="wcag-visuallyhidden">Registration</h3>

<h3 *ngIf="sub_header">{{ sub_header | translate }}</h3>

<div *ngIf="!session">
<div class="mt-5">
  <p [innerHTML]="message | translate">
  <!-- <p [innerHTML]="'REGISTRATION_PAGE.authAccountSelection.Gccfdescription' | translate"> -->
</div>
<div class="row mt-3">
  <div class="col-9">
  </div>
  <div class="col-3">
    <button type="button"
            class="btn btn-secondary w-100"
            [routerLink]="['/register/chooser-page' | localize]">
      {{ 'REGISTRATION_PAGE.authAccountSelection.continue' | translate }}
    </button>
  </div>
</div>
</div>

<div *ngIf="session">
  <div class="mt-5">
    <p [innerHTML]="'REGISTRATION_PAGE.authAccountSelection.thankyouText' | translate">
  </div>
  <div class="row mt-3">
    <div class="col-4">
    </div>
    <div class="col-4">
      <button type="button"
              class="btn btn-primary"
              [routerLink]="['/welcome' | localize]">
        {{ 'REGISTRATION_PAGE.authAccountSelection.close' | translate }}
      </button>
    </div>
    <div class="col-4">
    </div>
  </div>
  </div>

