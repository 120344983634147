import { getPrecision, validateDecimalPlace, validateMaxDecimalPlace } from '@core/utilities/utilities.constants';
const ɵ0 = c => /^\+[1-9]{1}[0-9]{3,14}$/.test(c.value), ɵ1 = c => {
    if (c.value) {
        const test = validateDecimalPlace(c.value, 5);
        if (!test) {
            return test;
        }
        else {
            if (getPrecision(c.value) > 5) {
                setTimeout(c.setValue((+c.value).toFixed(5), { emitEvent: false }));
            }
            return test;
        }
    }
    else {
        return true;
    }
}, ɵ2 = c => !isNaN(c.value), ɵ3 = c => {
    const validityExpression = new RegExp('^(?!.*(?:\\.-|-\\.))[^@]+@[^\\W_](?:[\\w-]*[^\\W_])?(?:\\.[^\\W_](?:[\\w-]*[^\\W_])?)+$');
    return validityExpression.test(c.value);
}, ɵ4 = c => /^[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]$/.test(c.value), ɵ5 = c => !(isNaN(c.value) || +c.value <= 0), ɵ6 = c => !(isNaN(c.value) || +c.value < 0), ɵ7 = c => {
    if (!c.value) {
        return true;
    }
    const validityExpression = new RegExp('^(?!.*(?:\\.-|-\\.))[^@]+@[^\\W_](?:[\\w-]*[^\\W_])?(?:\\.[^\\W_](?:[\\w-]*[^\\W_])?)+$');
    return validityExpression.test(c.value);
}, ɵ8 = c => /^[0-9]*$/.test(c.value), ɵ9 = c => {
    if (c.value) {
        const test = validateMaxDecimalPlace(c.value, 2);
        return test;
    }
    else {
        return true;
    }
}, ɵ10 = c => {
    return !c.value || c.value.trim();
};
export const CUSTOM_VALIDATORS = {
    phone: {
        expression: ɵ0,
        message: 'COMMON.messageSection.InvalidTelephoneFormat',
    },
    lonlatDecimal: {
        expression: ɵ1,
        message: 'COMMON.messageSection.accurateToFourDecimals',
    },
    number: {
        expression: ɵ2,
        message: 'COMMON.messageSection.valueMustBeNumber',
    },
    email: {
        expression: ɵ3,
        message: 'COMMON.messageSection.InvalidEmailFormatException',
    },
    postalCode: {
        expression: ɵ4,
        message: 'COMMON.messageSection.invalidPostalCodeException',
    },
    positiveNumber: {
        expression: ɵ5,
        message: 'COMMON.messageSection.valueMustBePositiveNumber',
    },
    positiveOrZeroNumber: {
        expression: ɵ6,
        message: 'COMMON.messageSection.valueCannotBeLessThanZero',
    },
    notRequiredEmail: {
        expression: ɵ7,
        message: 'COMMON.messageSection.InvalidEmailFormatException',
    },
    onlyNumbers: {
        expression: ɵ8,
        message: 'COMMON.messageSection.InvalidInput'
    },
    validateToTwoDecimalPlaces: {
        expression: ɵ9,
        message: 'COMMON.messageSection.accurateToTwoDecimals',
    },
    validateEmptyString: {
        expression: ɵ10,
        message: 'COMMON.messageSection.fieldRequiredException'
    }
};
export const postalCodeValidator = (control) => {
    const country = control.parent.get('country');
    return control && control.value && country && country.value === 'CA' &&
        !/^\s*[A-Za-z][0-9][A-Za-z] ?[0-9][A-Za-z][0-9]\s*$/.test(control.value) ?
        { invalidPostalCodeException: true } :
        null;
};
export const positiveOrZeroNumber = (control) => {
    return (isNaN(control.value) || +control.value < 0) ? { positiveOrZeroNumber: true } : null;
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
