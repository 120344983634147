
export class ProjTransSubmitDTO {
  projectId?:number;
  srcAccountId?:number;
  destAccountId?:number;
  destProjectId?:any;
  reasonForTransfer?:string;
  transferType?:string;
  includeNotifications?:boolean;
  includeCredits?:boolean;
  id?:any;
  unitIds?:any[];
}

export class ProjTransNeedMoreInfoDTO {
  id?:number;
  adminRemark?:any;
  discloseAdminRemark?:any;
  action?: string;
}

export class ProjTransReSubmitDTO {
  id?:number;
  userComment?:string;
  action?:string;
}

export class ProjTransRejectDTO {
  id?:number;
  adminRemark?:any;
  discloseAdminRemark?:any;
  attestation?: any;
  action?: string;
}

export class ProjTransApproveDTO {
  id?:number;
  adminRemark?:any;
  discloseAdminRemark?:any;
  action?: string;
}

export class ProjTransSaveDTO {
  id?:number;
  adminRemark?:any;
  discloseAdminRemark?:any;
  userComment?:string;
}

