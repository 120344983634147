<h2 class="action-panel-title"><span class="wcag-visuallyhidden">Sidebar</span>&nbsp;</h2>

<div class="form-group mb-3 ml-3 mr-3 mt-3">

  <ng-select
    wcag-label
    #facilityNameNgSelect
    [placeholder]="'ACCOUNTS_MODULE.dashboard.facilityNameText' | translate"
    [inputAttrs]="{'aria-label': 'ACCOUNTS_MODULE.dashboard.facilityNameText' | translate}"
    [items]="facilityNames"
    [clearable]="false"
    bindLabel="name"
    bindValue="id"
    [groupBy]="groupByField"
    [selectableGroup]="true"
    [(ngModel)]="test"
    (change)="getAccount($event)"
    [markFirst]="true">

    <ng-template ng-option-tmp let-item="item">
      <div title="{{item.name}}">{{item.name}}</div>
    </ng-template>

    <ng-template ng-optgroup-tmp let-item="item">
      <div class="ng-option-label" title="{{item.name}}">{{item.name}}</div>
    </ng-template>

    <ng-template ng-label-tmp let-item="item">
      {{ item?.name }}
    </ng-template>

  </ng-select>
</div>

<h2 class="action-panel-title">Actions</h2>

<ul class="nav flex-column nav-action-panel">
  <li class="nav-link" *ngIf="accountId">
      <a wcag-dummy-link-target
         [routerLink]="getTranslatedPath('/account-management/account-summary-user/' + accountId)">{{ 'USERS_MODULE.usersList.viewAccount' | translate}}</a>
  </li>
  <li class="nav-link"  *ngIf="subAccountCreateAllowed()">
    <a wcag-dummy-link-target *ngIf="!isSubAccountDisabled"
    (click)="redirectToSubAccount(accountId)">{{ 'USERS_MODULE.usersList.requestNewSubAccount' | translate}}</a>
  </li>
  <li class="nav-link" *ngIf="userCreateAllowed() && accountId">
    <a wcag-dummy-link-target href="#" routerLinkActive="active"
    [routerLink]="getTranslatedPath('/account-management/add-users' )">{{ 'USERS_MODULE.usersList.addAdditionalUserButton' | translate}}</a>
  </li>

  <li class="nav-link">
    <a href="#" (click)="showUserGuide($event)">{{ 'USERS_MODULE.usersList.viewUserGuide' | translate}}</a>
  </li>

  <li class="nav-link">
    <a href="#" (click)="contactUs($event)">{{'LOGIN_PAGE.loginSection.contactSupport' | translate}}</a>
  </li>
</ul>
