/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./confirmation-code.component";
var styles_ConfirmationCodeComponent = [i0.styles];
var RenderType_ConfirmationCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationCodeComponent, data: {} });
export { RenderType_ConfirmationCodeComponent as RenderType_ConfirmationCodeComponent };
function View_ConfirmationCodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "label", [["class", "wcag-visuallyhidden"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["digit"])), (_l()(), i1.ɵeld(3, 0, null, null, 9, "input", [["class", "form-control form-digit"], ["type", "text"]], [[8, "id", 0], [8, "maxLength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keydown"], [null, "keyup"], [null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keydown" === en)) {
        var pd_4 = (_co.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup" === en)) {
        var pd_5 = (_co.onKeyUp($event, _v.context.index) !== false);
        ad = (pd_5 && ad);
    } if (("focus" === en)) {
        var pd_6 = (_co.onFocus($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "is-invalid": 0 }), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[2, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, null), i1.ɵpod(10, { standalone: 0 }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(12, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_10 = "form-control form-digit"; var currVal_11 = _ck(_v, 6, 0, (!_co.output[_v.context.index] && _co.isInvalid)); _ck(_v, 5, 0, currVal_10, currVal_11); var currVal_12 = _co.output[_v.context.index]; var currVal_13 = _ck(_v, 10, 0, true); _ck(_v, 9, 0, currVal_12, currVal_13); }, function (_ck, _v) { var currVal_0 = ("confirmationCodeDigit" + _v.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = ("confirmationCodeDigit" + _v.context.index); var currVal_2 = 1; var currVal_3 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 12).ngClassValid; var currVal_8 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_ConfirmationCodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "form", [["class", "form-inline"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, null, 0, i3.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i1.ɵdid(4, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfirmationCodeComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.c; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ConfirmationCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-confirmation-code", [], null, null, null, View_ConfirmationCodeComponent_0, RenderType_ConfirmationCodeComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.ConfirmationCodeComponent]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.ConfirmationCodeComponent]), i1.ɵdid(3, 114688, null, 0, i4.ConfirmationCodeComponent, [], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ConfirmationCodeComponentNgFactory = i1.ɵccf("app-confirmation-code", i4.ConfirmationCodeComponent, View_ConfirmationCodeComponent_Host_0, { isInvalid: "isInvalid" }, {}, []);
export { ConfirmationCodeComponentNgFactory as ConfirmationCodeComponentNgFactory };
