<div class="pl-2 border-bottom">
  <h3>{{'PROJECTS_MODULE.ProjectBuilder.subProjectDetails' | translate}}</h3>
</div>
<div class="pl-4 my-2">
  <p>{{'PROJECTS_MODULE.ProjectBuilder.subProjectDetailsNote' | translate}}</p>
  <p>{{'PROJECTS_MODULE.ProjectBuilder.subProjectTransferLabel' | translate}}</p>
  <form [formGroup]="form" #ngForm>
    <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
  </form>

  <div class="table-area">
    <app-table-view
      [data]="tableData"
      [config]="tableConfig"
      [firstItemIndex]="firstItemIndex"
      (paginationChanged)="onPaginationChanged($event)"
      [relative]="true"
    >
    </app-table-view>
  </div>
  <div class="mt-4">
    <div class="d-flex justify-content-between mb-3">
      <div #UPLOAD>
        <button type="button" class="btn btn-secondary mr-3" (click)="addSubProject()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.addNewSubProject' | translate}}</button>
        <button type="button" class="btn btn-secondary" (click)="uploadSubProjects()" [disabled]="documentUploadInProgress.inProgress$ |async">{{'PROJECTS_MODULE.ProjectBuilder.uploadSubProjects' | translate}}</button>
      </div>
      <div class="tools-selector">
        <ng-select
          wcag-label
          [placeholder]="'COMMON.actionsLabel.tools' | translate"
          [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.tools' | translate}"
          [items]="taskList"
          bindLabel="name"
          bindValue="name"
          class="ng-dropdown-left"
          [(ngModel)]="downloadSelected"
          (change)="downloadTemplate($event)"
          [disabled]="projectBuilderService.disabled">
        </ng-select>
      </div>
    </div>
    <app-sub-projects-table [showStatus]="false"></app-sub-projects-table>
  </div>
</div>

<ng-template #viewColTemplate let-data>
  <ng-container>
  <div>
    <span tabindex="0" class="link" role="button"
          (click)="viewProject($event, data)"
          (keyup)="viewProject($event, data)">
      {{ 'ACCOUNTS_MODULE.accountList.view' | translate }}
    </span>
  </div>
</ng-container>
</ng-template>

<ng-template #actionTemplate let-data>
  <app-generic-action-control
    [rawData]="data"
    [appendTo]="'body'"
    (selectedAction)="onActionSelected($event)">
  </app-generic-action-control>
</ng-template>

