import { Component } from "@angular/core";
import { FieldArrayType, FieldType } from "@ngx-formly/core";

@Component({
  selector: "coi-checklist-checkbox-type",
  template: `

  <div class="row mb-5">
    <div class="ml-3 mr-0" style="margin: auto;">
    
        <div [ngClass]="{
        'form-check': to.formCheck.indexOf('custom') === -1,
        'form-check-inline': to.formCheck === 'inline',
        'custom-control custom-checkbox': to.formCheck.indexOf('custom') === 0,
        'custom-control-inline': to.formCheck === 'custom-inline'
        }">
            <input type="checkbox"
                #checkboxEl
                [class.is-invalid]="showError"
                [class.form-check-input]="to.formCheck.indexOf('custom') === -1"
                [class.custom-control-input]="to.formCheck.indexOf('custom') === 0"
                [indeterminate]="to.indeterminate && formControl.value === null"
                [formControl]="formControl"
                [id]="elementId"
                [formlyAttributes]="field">
                <label [for]="elementId"
                [class.form-check-label]="to.formCheck.indexOf('custom') === -1"
                [class.custom-control-label]="to.formCheck.indexOf('custom') === 0"
            >
                {{ to.label }}
                <span *ngIf="to.required && to.hideRequiredMarker !== true">*</span>
            </label>
        </div>

    </div>
    <div class="col">
        {{to.data.label | translate}}
    </div>
  </div>
 `,
})
export class CoiChecklistCheckBoxType extends FieldType {
  defaultOptions = {
    templateOptions: {
      options: [],
      formCheck: 'custom-inline', // 'custom' | 'custom-inline' | 'stacked' | 'inline'
    },
  };

  isData(option) {
    return option.data;
  }

  get elementId(): string {
    if (this.field.id && this.field.id.includes('formly_')) {
      let prefix = this.form['id'];

      // check if in repeat section
      if (!prefix && this.field.parent.parent && this.field.parent.parent.parent && this.field.parent.parent.parent.type === 'repeat') {
        const cnt = this.field.parent.parent.key;
        prefix = `${this.field.parent.parent.parent['id']}_${cnt}`;
      }

      return `${prefix ? prefix + '_' : ''}${this.key.replace('.', '_')}`;
    } else {
      return this.field.id;
    }
  }
}
