/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./back-btn.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/wcag-dummy-link-target.directive";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./back-btn.component";
import * as i8 from "../../services/navigation.service";
var styles_BackBtnComponent = [i0.styles];
var RenderType_BackBtnComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BackBtnComponent, data: {} });
export { RenderType_BackBtnComponent as RenderType_BackBtnComponent };
export function View_BackBtnComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["aria-label", "Back"], ["class", "arrow-back"], ["href", "#"], ["role", "button"], ["wcag-dummy-link-target", ""]], null, [[null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back($event) !== false);
        ad = (pd_0 && ad);
    } if (("keypress" === en)) {
        var pd_1 = (_co.back($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i2.WCAGDummyLinkTargetDirective, [i1.ElementRef, i1.Renderer2, i3.TranslateService], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "fa-icon", [["class", "float-left back-icon ng-fa-icon"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(3, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["style", "display: none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["back"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_2 = _ck(_v, 4, 0, "fas", "chevron-left"); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).title; var currVal_1 = i1.ɵnov(_v, 3).renderedIconHTML; _ck(_v, 2, 0, currVal_0, currVal_1); }); }
export function View_BackBtnComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-back-btn", [], null, null, null, View_BackBtnComponent_0, RenderType_BackBtnComponent)), i1.ɵdid(1, 114688, null, 0, i7.BackBtnComponent, [i8.NavigationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackBtnComponentNgFactory = i1.ɵccf("app-back-btn", i7.BackBtnComponent, View_BackBtnComponent_Host_0, {}, {}, []);
export { BackBtnComponentNgFactory as BackBtnComponentNgFactory };
