
<div class="d-md-flex justify-content-center pt-5 align-content-stretch"
     *ngIf="!(translationLoading | async)"
     [ngClass]="{ 'h-100 overflow-scroll': isMasterAccountRegistration() }" >

  <div class="col-sm-12 mb-5 " *ngIf="showStepper"
       [class.col-xl-2]="inApp"
       [class.col-xl-3]="!inApp">
    <app-progress-bar
      [steps]="registrationSteps"
      [current]="currentStep"
      (changeStep)=updateStep($event)>
    </app-progress-bar>
  </div>

  <div class="col-sm-12 col-md-9 mb-5 d-inline-block"
       [class.col-xl-10]="inApp"
       [class.col-xl-6]="!inApp">

    <ng-container *ngIf="currentStep === 1">

      <h1>{{ 'REGISTRATION_PAGE.accountTypeSection.cats' | translate }}</h1>
      <h2 class="mt-5 mb-5">{{ 'REGISTRATION_PAGE.stepperSection.programTypeSelection' | translate }}</h2>

      <div class="d-md-flex justify-content-center mt-2">
        <div class="card mb-2 mx-auto w-100" style="max-width: 400px;">
          <div class="card-body">
              <h3 class="card-title h5">{{ 'REGISTRATION_PAGE.accountTypeSection.obps' | translate }}</h3>
          </div>
          <div class="card-footer">
              <button id="obps-stp1-btn" class="btn btn-primary" (click)="nextStepIntendUsage('obps')">{{ 'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
          </div>
        </div>

        <div class="card mb-2 mx-auto w-100" style="max-width: 400px;">
          <div class="card-body">
              <h3 class="card-title h5">{{ 'REGISTRATION_PAGE.stepperSection.cleanFuelSystem' | translate }}</h3>
          </div>
          <div class="card-footer">
              <button id="cfs-stp1-btn" class="btn btn-primary" (click)="nextStepIntendUsage('cfs')">{{ 'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
          </div>
        </div>
      </div>

    </ng-container>

    <ng-container *ngIf="currentStep === 2">

      <h1>{{ 'REGISTRATION_PAGE.accountTypeSection.cats' | translate }}</h1>
      <h2 class="mt-5">{{ 'REGISTRATION_PAGE.accountTypeSection.pleaseSelectAccountType' | translate }}</h2>

      <div class="justify-content-center mt-2">
       <!--  <div class="card mb-2 mx-auto">
          <div class="card-body">
              <h3 class="card-title h5">{{ 'REGISTRATION_PAGE.accountTypeSection.obpsAccount' | translate }}</h3>
              <p class="card-text"  [innerHTML]= "'REGISTRATION_PAGE.accountTypeSection.obpsDescrption' | translate"></p>
          </div>
          <div class="card-footer">
              <button id="obps-stp2-btn" class="btn btn-primary" (click)="nextStep('obps')">{{ 'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
          </div>
        </div> -->

        <!-- <div class="card mb-2 mx-auto" *ngIf="createNFMPAccountEnabled">
          <div class="card-body">
              <h3 class="card-title h5">{{ 'REGISTRATION_PAGE.accountTypeSection.nonFacilityMarketParticipants' | translate }}</h3>
              <p class="card-text">{{ 'REGISTRATION_PAGE.accountTypeSection.nfpmDescription' | translate }}</p>
          </div>
          <div class="card-footer">
              <button  id="nfmp-stp2-btn"  class="btn btn-primary" (click)="nextStep('cm')">{{ 'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
          </div>
        </div> -->

        <!-- <div class="card mb-2 mx-auto" *ngIf="createOffsetccountEnabled">
          <div class="card-body">
              <h3 class="card-title h5">{{ 'REGISTRATION_PAGE.accountTypeSection.federalOffsetAccount' | translate }}</h3>
              <p class="card-text">{{ 'REGISTRATION_PAGE.accountTypeSection.offsetAccountDescription' | translate }}</p>
          </div>
          <div class="card-footer">
              <button  id="nfmp-stp2-btn"  class="btn btn-primary" (click)="nextStep('offset')">{{ 'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
          </div>
        </div> -->

        <p-card>
          <div class="container cfs-container-opt mb-2">
            <div class="row">

              <div class="col-lg">
                <h3>{{ 'REGISTRATION_PAGE.accountTypeSection.obpsAccount' | translate }}</h3>
                <p [innerHTML]= "'REGISTRATION_PAGE.accountTypeSection.obpsDescrption' | translate"></p>
              </div>

              <div class="col-2">
                <button type="button" id="obps-stp2-btn" class="btn btn-secondary cfs-select-btn" (click)="nextStep('obps')">
                  {{'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
              </div>

            </div>
          </div>
        </p-card>

        <p-card *ngIf="createNFMPAccountEnabled">
          <div class="container cfs-container-opt mb-2">
            <div class="row">

              <div class="col-lg">
                <h3>{{ 'REGISTRATION_PAGE.accountTypeSection.nonFacilityMarketParticipants' | translate }}</h3>
                <p [innerHTML]="'REGISTRATION_PAGE.accountTypeSection.nfpmDescription' | translate"></p>
              </div>

              <div class="col-2">
                <button type="button" id="nfmp-stp2-btn" class="btn btn-secondary cfs-select-btn" (click)="nextStep('cm')">
                  {{'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
              </div>

            </div>
          </div>
        </p-card>

        <p-card *ngIf="createOffsetccountEnabled">
          <div class="container cfs-container-opt mb-2">
            <div class="row">

              <div class="col-lg">
                <h3>{{ 'REGISTRATION_PAGE.accountTypeSection.federalOffsetAccount' | translate }}</h3>
                <p [innerHTML]= "'REGISTRATION_PAGE.accountTypeSection.offsetAccountDescription' | translate"></p>
              </div>

              <div class="col-2">
                <button type="button" id="offset-stp2-btn" class="btn btn-secondary cfs-select-btn" (click)="nextStep('offset')">
                  {{'REGISTRATION_PAGE.accountTypeSection.select' | translate }}</button>
              </div>

            </div>
          </div>
        </p-card>
      </div>
<!--<h4 class="mt-4">{{ 'REGISTRATION_PAGE.accountTypeSection.knowMore' | translate }}</h4>-->
      <button class="btn btn-secondary mr-2" (click)="backStep()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>
    </ng-container>

    <ng-container *ngIf="currentStep === 3 && type === 'cm'">
      <app-omp-account-types
        (back)="backStep()"
        (next)="nextStep($event)"
        [isSubaccount]="isRegistrationSubAccount() ||  isRegistrationSubAccountByMop()"
        [showBackButton]="isNewRegistration()">
      </app-omp-account-types>
    </ng-container>

    <ng-container *ngIf="currentStep === 4 && type === 'cm' && registrationType === cmNewSubAccount && isLoaded">
      <h1 *ngIf="header">{{ header | translate }}</h1>
      <h1 *ngIf="!header" class="wcag-visuallyhidden">Registration</h1>
      <div class="h5" [innerHTML]="subHeader | translate"></div>
      <app-offset-account-type
        [onestep]="onestep"
        [model]="accountData"
        [masterAccountData]="masterAccountData"
        [registrationType]= "registrationType"
        (back)="backStep()"
        (next)="nextStep($event)"
      ></app-offset-account-type>
    </ng-container>

    <ng-container *ngIf="currentStep === 4 && type === 'cm' && registrationType !== cmNewSubAccount">
      <h1 *ngIf="header">{{ header | translate }}</h1>
      <h1 *ngIf="!header" class="wcag-visuallyhidden">Registration</h1>
      <div class="h5" [innerHTML]="subHeader | translate"></div>
      <div>
        <form [formGroup]="cmForm" (ngSubmit)="submitObpsNonFacilityAccount(cmForm)">
          <formly-form [form]="cmForm" [model]="accountData" [fields]="accountFields">
            <div class="col-12 p-0 mt-4">
              <button type="button" *ngIf="isNewRegistration()" class="btn btn-secondary mr-2" (click)="backStep()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>
              <button id="nfmp-stp3-btn" type="submit" class="btn btn-primary" [disabled]="!cmForm.valid">{{ 'REGISTRATION_PAGE.nfmpAccountForm.nextStepButton' | translate }}</button>
            </div>
          </formly-form>
        </form>
      </div>
    </ng-container>

    <ng-container *ngIf="currentStep === 3 && type === 'obps'">

      <h1 *ngIf="header">{{ header | translate}}</h1>
      <h1 *ngIf="!header" class="wcag-visuallyhidden">Registration</h1>
      <h2 class="mt-4 mb-4">{{ 'REGISTRATION_PAGE.enterFacilitySection.enterFacilityNumber' | translate }}</h2>

      <form [formGroup]="obpsForm">

        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-8 mb-2 p-0">
              <formly-form [model]="facilityModel" [fields]="formFields" [options]="options" [form]="obpsForm">
              </formly-form>
            </div>
            <div class="col-sm-12 col-md-4 mb-2 pl-sm-0 pl-md-2">
              <button class="btn btn-primary" id="button-addon" (click)="addFacility( obpsForm)">
                {{ 'REGISTRATION_PAGE.enterFacilitySection.addFacility' | translate }}
              </button>
            </div>
          </div>
        </div>

        <ng-container *ngIf="facilities.length > 0">
          <h1 class="mt-5">{{ 'REGISTRATION_PAGE.enterFacilitySection.addedFacilities' | translate }}</h1>
          <p-table class="eccc" [value]="facilities">
            <ng-template pTemplate="header">
              <tr>
                <th [pSortableColumn]="'certificateNumber'">{{ 'REGISTRATION_PAGE.enterFacilitySection.facilityCertificateNumber' | translate }}
                  <p-sortIcon [field]="'certificateNumber'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'legalName'">{{ 'REGISTRATION_PAGE.enterFacilitySection.facilityName' | translate }}
                  <p-sortIcon [field]="'legalName'"></p-sortIcon>
                </th>
                <th>{{ 'REGISTRATION_PAGE.enterFacilitySection.actions' | translate }}</th>
              </tr>
            </ng-template>
              <ng-template pTemplate="body" let-facility>
              <tr >
                <td>{{facility.certificateNumber}}</td>
                <td>{{facility.legalName}}</td>
                <td>
                  <button class="btn btn-outline-secondary" (click)="removeFacility(i)" title="Remove File">
                    <span>{{'REGISTRATION_PAGE.enterFacilitySection.delete' | translate}}</span>
                  </button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>

        <div class="col-12 p-0">
          <br/>
          <button type="button" class="btn btn-secondary mr-2" (click)="backStep()">{{ 'REGISTRATION_PAGE.stepperSection.back' | translate }}</button>
          <button type="button" id="obps-stp3-btn" class="btn btn-primary" [disabled]="facilities.length === 0" (click)="submitFacilities(obpsForm)">{{ 'REGISTRATION_PAGE.enterFacilitySection.submit' | translate }}</button>
        </div>

      </form>

    </ng-container>

    <ng-container *ngIf="currentStep === 3 && type === 'offset' && isLoaded">
      <app-offset-account-type
        [onestep]="onestep"
        [model]="accountData"
        [masterAccountData]="masterAccountData"
        [registrationType]= "registrationType"
        (back)="backStep()"
        (next)="nextStep('offset')"
      ></app-offset-account-type>
    </ng-container>

    <ng-container *ngIf="(currentStep == 5 || (currentStep === 4 && (type === 'obps'))) ">
      <app-account-selection [header]="header"></app-account-selection>
    </ng-container>

    <ng-container *ngIf="currentStep === 4 &&  type === 'offset'">
      <app-account-selection [header]="header" [thankyoumessage]="'REGISTRATION_PAGE.offsetRegistrationForm.placeHolderText'" ></app-account-selection>
    </ng-container>
<!--   <ng-container *ngIf="currentStep === 5">
      <h1 *ngIf="header">{{ header | translate }}</h1>
      <h1 *ngIf="!header" class="wcag-visuallyhidden">Registration</h1>

      <p>{{ 'REGISTRATION_PAGE.completeSection.confirmationMessage' | translate }}
      </p>


      <div class="col-12">
        <button id="stp5-btn" class="btn btn-outline-secondary" type="submit" (click)="closeForm()">{{ 'REGISTRATION_PAGE.completeSection.close' | translate }}</button>
      </div>
    </ng-container>-->

  </div>

</div>

