<p-dialog
  id="upload-popup"
  [className]="'eccc'"
  [header]="'COMMON.actionsLabel.uploadDocument' | translate"
  [closable]="false"
  [(visible)]="openUploadPopup"
  [modal]="true"
  [responsive]="true"
  [style]="{width: '500px'}"
  [minY]="100"
  [maximizable]="false"
  [autoZIndex]="false">

  <div>
    <div>
        <div class="form-group">
          <label for="input-file">{{'COMMON.actionsLabel.document' | translate}}</label>
          <div class="form-group">
            <div class="input-group input-file" name="Fichier1">
              <span class="input-group-btn">
                <button
                        id="select-document"
                        class="btn btn-primary btn-upload"
                        (click)="selectFiles($event)"
                        [disabled]="errorMessage"
                        (keypress)="selectFiles($event)">{{'COMMON.actionsLabel.BROWSE' | translate}}</button>
                <label class="wcag-visuallyhidden" for="file">{{ 'COMMON.actionsLabel.chooseAFfile' | translate }}</label>
                <input #fileInput data-cy="file-input" (change)="addFile($event)" id="file" class="hidden" type="file" tabindex="-1">
              </span>
              <input tabindex="-1" id="input-file" type="text" class="form-control" [placeholder]="'COMMON.actionsLabel.chooseAFfile' | translate" value="{{file?.name}}" readonly  [disabled]="errorMessage"/>
            </div>
          </div>
        </div>
    </div>

    <div *ngIf="data.documentType">
      <form [formGroup]="form">
        <formly-form [form]="form" [model]="documentProps" [fields]="fields"></formly-form>
      </form>

     <!-- <div class="form-group" [formGroup]="form">
        <label for="document-type" [innerHTML]="'COMMON.actionsLabel.DOCUMENTTYPE' | translate"></label>
        <input type="hidden" id="document-type" class="wcag-visuallyhidden"/>
        <ng-select
          wcag-label
          placeholder="{{'COMMON.actionsLabel.DOCUMENTTYPE' | translate}}"
          [inputAttrs]="{'aria-label': 'COMMON.actionsLabel.DOCUMENTTYPE' | translate}"
          [items]="documentTypeList"
          bindLabel="name"
          bindValue="id"
          [appendTo]="'body'"
          (change)="documentTypeSelected($event)">
        </ng-select>
      </div> -->
    </div>

    <div *ngIf="data.comment">
      <div class="form-group">
        <label for="exampleFormControlTextarea1">{{'COMMON.actionsLabel.COMMENT' | translate}}</label>
        <textarea id="upload-document-comment" (change)="documentCommentsEntered($event)" class="form-control rounded-0" id="exampleFormControlTextarea1" rows="10" [disabled]="errorMessage"></textarea>
      </div>
    </div>

  </div>

  <p-footer>
    <div class="footer-buttons">
      <div class="float-right">
        <button id="UPLOAD_DOCUMENT_closeBtn" type="button" class="btn btn-secondary" (click)="close()" [disabled]="errorMessage">{{'COMMON.actionsLabel.CLOSE' | translate}}</button>
        <button id="submit-upload-document" type="button" class="btn btn-primary" (click)="submit()" [disabled]="errorMessage">{{'COMMON.actionsLabel.upload' | translate}}</button>
      </div>
    </div>
  </p-footer>

</p-dialog>
