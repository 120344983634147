import { IRawForm } from '@core/models/raw-form.types';
import { SOURCE_DATA_TYPE } from '@shared/components/stepper/stepper.const';
import { getDictSelect } from '@core/utilities/raw-form.utils';
import {
  CREDIT_BALANCE_REPORT_CREATE_POPUP,
  CREDIT_BALANCE_REPORT_PERIOD_FLD,
  CREDIT_BALANCE_REPORT_PREFIX, creditBalanceRepApplyPrefix
} from '@module/credit-balance/credit-balance-report.const';

export const getCreditBalanceReportPeriod = (onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: CREDIT_BALANCE_REPORT_PERIOD_FLD,
    className: 'col-6',
    source: SOURCE_DATA_TYPE.CREDIT_BALANCE_REPORT_COMPLIANCE_YEARS,
    label: creditBalanceRepApplyPrefix(CREDIT_BALANCE_REPORT_PERIOD_FLD, CREDIT_BALANCE_REPORT_CREATE_POPUP),
    onChange,
    required: true,
    ignoreTranslateOptions: true,
    ignoreDropdownAppend: true,
    placeholder: CREDIT_BALANCE_REPORT_PERIOD_FLD,
    skipInitialBlur: true,
    translatePrefix: CREDIT_BALANCE_REPORT_PREFIX + CREDIT_BALANCE_REPORT_CREATE_POPUP,
  });
};
