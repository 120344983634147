import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG } from '@core/constants/serviceMessage.const';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./modal.service";
const DEFAULT_STORAGE_KEY = 'docsInProgress';
export class DocumentUploadInProgressService {
    constructor(modalService) {
        this.modalService = modalService;
        this.data = [];
        this._inProgress$ = new BehaviorSubject(false);
        this.inProgress$ = this._inProgress$.asObservable();
        this.load();
    }
    init() {
        this.data = [];
        this.save();
    }
    save() {
        sessionStorage.setItem(DEFAULT_STORAGE_KEY, JSON.stringify(this.data));
    }
    load() {
        this.data = JSON.parse(sessionStorage.getItem(DEFAULT_STORAGE_KEY) || '[]');
    }
    set(uri, id) {
        this.load();
        this.data.push({ uri, id });
        this.updateState();
        this.save();
    }
    unset(uri) {
        this.load();
        const idx = this.data.findIndex(d => d.uri === uri);
        if (idx > -1) {
            this.data.splice(idx, 1);
        }
        this.updateState();
        this.save();
    }
    get() {
        this.load();
        return this.data;
    }
    showWarning() {
        const messages = {
            messages: [{ message: 'documentsInProgress' }],
            type: "WARNING_ONLY" /* WARNING_ONLY */,
        };
        this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
            .afterClosed()
            .subscribe();
    }
    updateState() {
        const inProgress = this.data.length > 0;
        this._inProgress$.next(inProgress);
    }
    isInProgress(showMessage = false) {
        this.load();
        const inProgress = this.data.length > 0;
        if (inProgress) {
            setTimeout(() => {
                if (showMessage) {
                    this.showWarning();
                }
            });
        }
        return inProgress;
    }
}
DocumentUploadInProgressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentUploadInProgressService_Factory() { return new DocumentUploadInProgressService(i0.ɵɵinject(i1.ModalService)); }, token: DocumentUploadInProgressService, providedIn: "root" });
