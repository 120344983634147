import { AccountHistoryFilter } from '@core/models/filter-types.model';

export const ACCOUNT_HISTORY_ADMIN_TABLE_DETAILS = {
  tableListApiUri: '/account-service/account/accountHistory',
  tableHeaderType: 'ACCOUNT_HISTORY',
  tableFilter: AccountHistoryFilter,
  hasViewTemple: true,
  hasActionTemple: false,
  title : '.accountHistory',

};

export const ACCOUNT_HISTORY_USER_TABLE_DETAILS = {
  tableListApiUri: '/account-service/userAccount/accountHistory',
  tableHeaderType: 'ACCOUNT_HISTORY',
  tableFilter: AccountHistoryFilter,
  hasViewTemple: true,
  hasActionTemple: false,
  title : '.accountHistory'
};

