import { AbstractControl, FormGroup } from '@angular/forms';
import { RAW_FORM_TYPE_READONLY_INPUT } from '@core/models/raw-form.constants';
import {
  IRawForm,
  IRawFormAppFormlyDatepicker,
  IRawFormDictSelectItem,
  IRawFormInput,
  IRawFormRadio
} from '@core/models/raw-form.types';
import { getAppFormlyDatepicker, getCheckbox, getDictSelect, getField, getInput, getLabel, getRadio } from '@core/utilities/raw-form.utils';
import { IPRBaseModel } from '@module/project-report/routines/project-report.types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ITableViewConfig, ROWS_PER_PAGE } from '@shared/models/table-view.model';
import { IActivity } from '@shared/services/project.service.types';
import { Subject } from 'rxjs';
import { EIA_DEPOSIT_PERCENTAGE, PERCENTAGE_CREDIT_FOREGONE } from './common.routine.constants';
import {
  PR_CHANGES_ID,
  PR_CONFIDENTIAL_BUSINESS_INFO_INCLUDED,
  PR_EIA_DEPOSIT,
  PR_FOREGONE_CREDITS,
  PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
  PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS,
  PR_PREFIX,
  PR_REPORTING_PERIOD_END_DATE,
  PR_REPORTING_PERIOD_START_DATE,
  PR_REQUESTING_CREDITS,
  PR_TCO2E,
  PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
  PR_YES_NO_ITEMS,
  PRApplyPrefix,
} from './project-report.constants';

export const getRequiredLabel = (): IRawForm => {
  return getLabel({
    label: PRApplyPrefix('pleaseEnterTheBelowRequiredInformation'),
    className: 'col-12 mb-3 h5',
  });
};

export const GHGIds = [
  PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
  PR_EIA_DEPOSIT,
  PR_FOREGONE_CREDITS,
  PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS,
  PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
];

export const CREDITIds = ['quantity'];

export const normalizeDateUtc = (defaultValue: string): Date => {
  const now = new Date();
  if (defaultValue) {
    const date = new Date(defaultValue);
    now.setFullYear(date.getUTCFullYear());
    now.setMonth(date.getUTCMonth());
    now.setDate(date.getUTCDate());
  }
  return now;
};

export const getReportingPeriodStartDate = (defaultValue: string, readOnly = false): IRawFormAppFormlyDatepicker | IRawFormInput => {
  const label = PRApplyPrefix(PR_REPORTING_PERIOD_START_DATE);
  const defaultDate = defaultValue;
  const className = 'col-6';

  if (readOnly) {
    return getInput({
      type: RAW_FORM_TYPE_READONLY_INPUT,
      id: PR_REPORTING_PERIOD_START_DATE,
      className,
      defaultValue,
      label,
    });
  }

  return getAppFormlyDatepicker({
    id: PR_REPORTING_PERIOD_START_DATE,
    className,
    label,
    placeholder: label,
    templateOptions: {
      defaultDate,
    },
  });
};

export const getReportingPeriodEndDate = (
  minDateSource: string,
  onChange?: () => void,
  defaultValue?: string,
): IRawFormAppFormlyDatepicker => {
  const value = PRApplyPrefix(PR_REPORTING_PERIOD_END_DATE);
  const minDate = new Date(minDateSource);
  const maxDate = new Date(minDate);
  maxDate.setFullYear(minDate.getFullYear() + 6);
  const defaultDate = defaultValue;
  return getAppFormlyDatepicker({
    id: PR_REPORTING_PERIOD_END_DATE,
    label: value,
    onChange,
    placeholder: value,
    templateOptions: {
       minDate,
     // maxDate,
      defaultDate,
    },
  });
};

export const getAgreement = (id: string, defaultValue = null, disabled = false, required = true): IRawForm => {
  return getCheckbox({
    id,
    className: 'col-12',
    defaultValue,
    disabled,
    label: PRApplyPrefix(id),
    required,
    requiredTrue: required
  });
};

export const getPopUpConfig = (field: string): ITableViewConfig => {
  return {
    translationPrefix: 'COMMON.custom_messages',
    paginator: true,
    rowsPerPage: ROWS_PER_PAGE,
    editService: '/offset-service/submissions/review',
    columns: [
      {
        field: 'id',
        header: 'id',
        sortField: 'id',
        width: '200px',
        editable: false,
        fieldType: 'readonly-input',
        inputType: 'number',
        editRowField: false,
      },
      {
        field: 'action',
        header: 'action',
        sortField: 'action',
        width: '200px',
        editable: false,
        fieldType: 'readonly-input',
        inputType: 'number',
        editRowField: false,
      },
      {
        field,
        header: 'comment',
        sortField: 'comment',
        width: '200px',
        editable: false,
        fieldType: 'textarea',
        inputType: 'string',
        editRowField: true,
        className: 'col-12',
      },
    ],
  };
};

export const getTotalNetQuantityGhgReductionsGeneratedByTheProject = (): IRawFormInput => {
  return getInput({
    type: RAW_FORM_TYPE_READONLY_INPUT,
    id: PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT,
    label: PRApplyPrefix(PR_TOTAL_NET_QUANTITY_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT),
    placeholder: PR_TCO2E,
  });
};

export const calcTotalField = (model: IPRBaseModel, activity: IActivity, key: string): string => {
  let source = model[PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT] || 0;
  source = typeof source === 'string' ? parseInt(source) : source;
  if (isNaN(source)) {
    source = 0;
  }
  const percent = activity[key] || 0;
  return Math.floor((source * percent) / 100).toString();
};

export const getTotal = (model: IPRBaseModel, source: string[]): string => {
  const [first, ...rest] = Object.entries(model)
    .filter(([key]) => !!source.find(entry => entry === key))
    .map(([, value]) => parseInt(value) || 0);
  let total = rest.reduce((memo, val) => {
    return val > 0 ? memo - val : memo + val;
  }, first);

  if (isNaN(total) || total < 0) {
    total = 0;
  }

  return total.toString();
};

export const calcTotals = (model: IPRBaseModel, target: string, source: string[]): IPRBaseModel => {
  const value = model[target];
  const total = getTotal(model, source);

  if (total !== value) {
    return { ...model, [target]: total };
  }

  return model;
};

export const calcTotalQuantityResult = (form: FormGroup, activity: IActivity,
                                        totalCreditsToBeIssued: number): IPRBaseModel => {
  const total = GHGIds.slice(-1)[0];
  const fields = GHGIds.slice(0, GHGIds.length - 1);

  const model: IPRBaseModel = form.value;

  let diff = +model[PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT];

  diff = +model[PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT] +
   +model[PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS];

  const foregoneCredits = calcForegoneCredits(diff, activity, PERCENTAGE_CREDIT_FOREGONE);
  model[PR_FOREGONE_CREDITS] = +foregoneCredits > 0 ? foregoneCredits : '0';
  model[PR_EIA_DEPOSIT] = calcEIADeposite(model, activity);

  return calcTotals(model, total, fields);
};

export const calcForegoneCredits= (source: any, activity: IActivity, key: string): string => {
 // let source = model[PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT] || 0;
  source = typeof source === 'string' ? parseInt(source) : source;
  if (isNaN(source)) {
    source = 0;
  }
  const percent = activity[key] || 0;
  return Math.floor((source * percent) / 100).toString();
};

export const calcEIADeposite = (model, activity) => {
  const source =  ((+model[PR_GHG_REDUCTIONS_GENERATED_BY_THE_PROJECT]) - (+model[PR_FOREGONE_CREDITS]) + (+model[PR_NET_INCREASE_OF_GHGS_CARRIED_OVER_FROM_PREVIOUS_YEARS]));
  const percent = activity[EIA_DEPOSIT_PERCENTAGE] || 0;
  const eiaDeposit =  Math.floor((source * percent) / 100).toString();
  return +eiaDeposit > 0 ?eiaDeposit :'0';
};

export const getControls = (form: FormGroup, keys: string[]): AbstractControl[] => {
  return keys.map(key => form.get(key));
};

export const updateDynamicLabel = (key: string, targetFields: FormlyFieldConfig[], value: string): void => {
  const label = getField(key, targetFields);
  if (!label) {
    return;
  }
  const updateContent: Subject<any> = label.templateOptions.updateContent;
  if (updateContent) {
    updateContent.next(value);
  }
};

export const getTotalsResult = <T>(data: T[]): { [K in keyof T]: T[K] } => {
  const fields = GHGIds;
  const source = {};
  fields.forEach(field => {
    source[field] = 0;
  });
  const totals = data.reduce((memo, val) => {
    fields.forEach(field => {
      memo[field] = memo[field] + val[field] * 1;
    });
    return memo;
  }, source);
  return totals as { [K in keyof T]: T[K] };
};

export const getChangeUpdate = (): IRawForm => {
  return getLabel({
    label: PRApplyPrefix('changeUpdate'),
    className: 'col-6 mb-3 h5 d-flex align-items-end',
  });
};

export const getChanges = (defaultValue = false): IRawFormRadio => {
  return getRadio({
    id: PR_CHANGES_ID,
    label: PRApplyPrefix(PR_CHANGES_ID),
    dummyInputForLabel: true,
    className: 'col-6 inline mb-3',
    inline: true,
    defaultValue,
    options: [
      { value: PRApplyPrefix('prYes'), key: true },
      { value: PRApplyPrefix('prNo'), key: false },
    ],
  });
};

export const getRequestingCredits = (requestingCredits = null, disabled = false, onChange?: () => void): IRawForm => {
  return getDictSelect({
    id: PR_REQUESTING_CREDITS,
    bindValue: 'value',
    className: 'col-12',
    dataProvided: true,
    defaultValue: requestingCredits,
    disabled,
    items: PR_YES_NO_ITEMS,
    label: PRApplyPrefix(PR_REQUESTING_CREDITS),
    onChange,
    translateOptions: true,
    translatePrefix: PR_PREFIX,
  });
};

export const getConfidentialBusinessInfoIncluded = (confidentialBusinessInfoIncluded = false, disabled = false, onChange?: ()=> void): IRawForm => {
  return getDictSelect({
    id: PR_CONFIDENTIAL_BUSINESS_INFO_INCLUDED,
    bindValue: 'value',
    className: 'col-12',
    dataProvided: true,
    defaultValue: confidentialBusinessInfoIncluded,
    items: PR_YES_NO_ITEMS,
    label: PRApplyPrefix(PR_CONFIDENTIAL_BUSINESS_INFO_INCLUDED),
    translateOptions: true,
    translatePrefix: PR_PREFIX,
    disabled,
    onChange
  });
};

/**
 * Filter unique records by id
 * @param arr1 - initial state
 * @param arr2 - new state to check against
 */
export const filterUniqueById = (arr1, arr2) => {
  return arr1.filter(selectedEl => {
    return (
      arr2.filter(updatedEl => {
        return selectedEl.id === updatedEl.id;
      }).length === 0
    );
  });
};

export const getSubProjectsItems = (
  subProjects,
  provideId = true,
  name = 'name'
): IRawFormDictSelectItem[] => {
  subProjects = subProjects || [];
  subProjects = subProjects.filter(project => project.status === 'ACTIVE');

  return subProjects.map(subProject => ({
    id: subProject.id,
    name: subProject[name],
    value: provideId ? subProject.id : subProject,
  }));
};

export const getPrimitiveValue = (value: number | string): number | string => {
  return typeof value === 'string' || typeof value === 'number' ? value : 0;
}
