import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy, HostListener, OnChanges, SimpleChanges
} from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { AUTO_WIDTH_FOR_COLUMNS, ITableViewConfig, ROWS_PER_PAGE } from 'app/shared/models/table-view.model';
import { documentsTableConfig } from '@module/account-management/account-management.const';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentComponent } from '@shared/components/upload-document/upload-document.component';
import { GET_ALL, UploadDocumentService } from '@shared/services/upload-document.service';
import { CurrentUser } from '@core/models/user.model';
import { StoreService } from '@core/store/store.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject, forkJoin } from 'rxjs';
import { IDisplayMessages } from '@core/models/serviceMessage.model';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DocumentUploadInProgressService } from "@shared/services/document-in-progress.service";
import { TranslateService } from '@ngx-translate/core';
import { EDIT_DOCUMENT_PUBLIC_VISIBILITY, VIEW_DOCUMENT_PUBLIC_VISIBILITY } from '@core/models/permission.const';
import { isNullOrUndefined } from 'util';
import { CiPathPrevSelectedDocComponent } from '@module/ci-pathway-report/components/ci-path-prev-selected-doc/ci-path-prev-selected-doc.component';
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnChanges,OnDestroy {

  @ViewChild('accountDocumentActions', {static: true}) public accountDocumentActions: TemplateRef<any>;
  @ViewChild('daccountDocumentTimestamp', {static: true}) public daccountDocumentTimestamp: TemplateRef<any>;
  @ViewChild('accountDocumentType', {static: true}) public accountDocumentType: TemplateRef<any>;
  @ViewChild('documentName', {static: true}) public documentName: TemplateRef<any>;
  @ViewChild('makePublicTmpl', {static: true}) public makePublicTmpl: TemplateRef<any>;

  @Input() documentsPage : IApp.IDocumentsPage;
  @Input() documentUploadConfig : IApp.IDocumentUploadConfig;
  @Input() entityId: number;
  @Input() disabled = false;
  @Input() hidden = false;
  @Input() showPlaceholders = false;
  @Input() relative = true;
  @Input() skipFirstLoad = false;
  @Input() readOnly = false;
  @Input() ciPathwayReport = true;
  guidanceText: string;
  @Output() documentResult = new EventEmitter<IApp.IDocument[]>();

  tableConfig: ITableViewConfig;
  user: CurrentUser;
  private pagination = {
    page: 0,
    size: ROWS_PER_PAGE,
  } as IApp.IPagination;
  public firstItemIndex = 0;
  private _destroy$ = new Subject<any>();
  public makePublicEnabled = false;

  constructor(private modalService: ModalService,
    private uploadDocumentService:UploadDocumentService,
    private store: StoreService,
    private documentUploadInProgress: DocumentUploadInProgressService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.user = this.store.user;
    documentsTableConfig.paginator= this.documentUploadConfig.disablePaginator ? false : documentsTableConfig.paginator;
    const entityNameText = this.documentUploadConfig.guidanceTextEntity?
    this.documentUploadConfig.guidanceTextEntity :this.documentUploadConfig.entityName;
    this.guidanceText = `COMMON.documentGuidanceTest.${entityNameText}`;

    const { columns, ...config } = documentsTableConfig;
    const _columns = [
      {
        templateRef: this.documentName,
        header: 'name',
        width: AUTO_WIDTH_FOR_COLUMNS,
        sortField: 'name',
      },
      ...documentsTableConfig.columns
    ];

    if (this.documentUploadConfig.publicVisibility && this.user.hasPermission(VIEW_DOCUMENT_PUBLIC_VISIBILITY)) {
      _columns.push({
        header: 'makePublic',
        width: AUTO_WIDTH_FOR_COLUMNS,
        templateRef: this.makePublicTmpl,
      });
    }

    if(this.user.hasPermission('DOCUMENT_UPLOAD') || this.user.hasPermission('DOCUMENT_DOWNLOAD')){
      _columns.push({
        header: 'actions',
        width: AUTO_WIDTH_FOR_COLUMNS,
        className: 'overflow-visible',
        templateRef: this.accountDocumentActions,
      });
    }

    if (this.documentUploadConfig.statusColumn === false) {
      _columns.splice(_columns.findIndex(col => col.header === 'status'), 1);
    }

    this.tableConfig = {
      ...config,
      ...{columns: _columns},
    };

    this.makePublicEnabled = !isNullOrUndefined(this.documentUploadConfig.publicVisibilityEnabled) ?
      this.documentUploadConfig.publicVisibilityEnabled :
      this.user.hasPermission(EDIT_DOCUMENT_PUBLIC_VISIBILITY) ||
      false;
    this.checkDocumentsStatus();
   }

   ngOnChanges(changes: SimpleChanges) {
     if (changes.documentUploadConfig && !changes.documentUploadConfig.firstChange) {
       this.refreshTable({});
     }
   }


  @Output() refreshTableData = new EventEmitter<boolean>();
  refreshTable(event) {
   // this.documentUploadConfig.id = event.document.id;
   if (this.documentUploadConfig.secondaryEntityId) {
      this.loadDocumentsWithSecondaryEntity(this.pagination);
   } else if (this.documentUploadConfig.id && this.documentUploadConfig.entityName) {
      this.uploadDocumentService.getAll(
        this.pagination,
        this.documentUploadConfig.id,
        this.documentUploadConfig.entityName,
        this.documentUploadConfig.documentTypeCode,
        this.documentUploadConfig.listUri,
        this.documentUploadConfig.metaData,
        this.documentUploadConfig.documentTypeCodes,
        this.documentUploadConfig.supportingDocumentFilter,
        this.documentUploadConfig.entityVersionId
      )
        .subscribe(
          result => {
            this.documentsPage = result;
            this.showPlaceholderDocuments();
            this.updateCurrentUploadsInProgress();
          });
      this.refreshTableData.emit(event);
    } else {
      if ((event.action === 'DELETE' || event.action === 'DELETE_NOT_SUBMITTED') && event.document) {
        const docId = this.documentsPage.content.findIndex(d => d.id === event.document.id);
        this.documentsPage.content.splice(docId, 1);
        this.documentResult.emit(this.documentsPage.content);
        this.loadDocuments(this.pagination);
      }else {
        this.loadDocumentsFromMultipleSource(this.pagination);
        this.refreshTableData.emit(event);
      }
    }
  }

  private setDocumentPlaceholders() {
    if (!this.documentsPage) {
      this.documentsPage = {
        content: [],
      };
    }
    const currentDocTypes = Array.from(new Set(this.documentsPage.content.map(d => d.documentType.code)));
    const missingTypes = this.documentUploadConfig.documentUploadConfigs.filter(dt => !currentDocTypes.includes(dt.documentTypeCode));
    missingTypes.forEach(docType => {
      this.documentsPage.content.push({
        _placeholder: true,
        type: docType.documentTypeCode,
        documentType: docType,
      });
    });
  }

  openUploadPopup(event, preselectedDocumentType = null){
    event && event.preventDefault();
    if (this.disabled) {
      return;
    }
    const documentUploadConfig = preselectedDocumentType ? {...this.documentUploadConfig, preselectedDocumentType} : this.documentUploadConfig;
    this.modalService.open(UploadDocumentComponent, documentUploadConfig).afterClosed().subscribe((result?: any) => {
      if(result){
        if(!this.documentsPage){
          this.documentsPage ={
            totalElements: 1,
            totalPages: 1,
            last: true,
            first: true,
            size: 1,
            number: 1,
            numberOfElements: 1,
            sort:null
          };
        }
        if(!this.documentsPage.content){
          this.documentsPage.content = [];
        }

        this.uploadDocumentService.checkUploadStatus(result.id, result.signedUrl)
          .pipe(
            takeUntil(this._destroy$),
          )
          .subscribe(
            res => {

              const idx = this.documentsPage.content.findIndex(d => d.id === res.id);
              if (idx > -1) {
                if (res.status === 'CLEAN') {
                  const doc = {...this.documentsPage.content[idx], _uploadStatus: 'CLEAN'};
                  this.documentsPage.content[idx] = doc;
                  this.loadDocuments(this.pagination);
                } else {
                  this.documentsPage.content.splice(idx, 1);
                  const messages: IDisplayMessages = {
                    messages: [{message: 'infectedDocument'}],
                    type: SereviceMessageType.ERROR,
                  };
                  this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                    .afterClosed()
                    .subscribe();
                }
                this.documentResult.emit(this.documentsPage.content);
              }

            },
          );

        const documents = this.documentsPage.content;
        const placeholderIdx = documents.findIndex(p => p._placeholder && p.type === result.type);
        if (placeholderIdx > -1) {
          documents.splice(placeholderIdx, 1);
        }

        documents.unshift({...result, _uploadStatus: 'IN_PROGRESS'});
        this.documentsPage = {...this.documentsPage, content: documents};
       // var documentIdList: number[] = [];
       // this.documents.content.map(doc => documentIdList.push(doc.id));
        this.documentResult.emit(this.documentsPage.content);
      }
    });
  }

  openPreviousSelectedPopUp(event){
    this.modalService.open(CiPathPrevSelectedDocComponent, {}, true).afterClosed().subscribe(res => {
      if (res && res.length) {
        let typesInB = new Set(res.map(obj => obj.type));
        this.documentsPage.content = this.documentsPage.content.filter(aObj => !typesInB.has(aObj.type));
        this.documentsPage.content = [...this.documentsPage.content, ...res];
        this.documentsPage = {...this.documentsPage}
        this.documentResult.emit(this.documentsPage.content);
        this.loadDocuments(this.pagination);
      }

    });

  }

  private updateCurrentUploadsInProgress() {
    const currentUploads = this.documentUploadInProgress.get();

    currentUploads.forEach(doc => {
      const idx = this.documentsPage.content.findIndex(d => d.id === doc.id);
      if (idx > -1) {
        const docInProgress = this.documentsPage.content[idx];
        docInProgress._uploadStatus = 'IN_PROGRESS';
        this.documentsPage.content[idx] = docInProgress;
        this.documentResult.emit(this.documentsPage.content);
      }
    });
  }

  onPaginationChanged(pagination: IApp.IPagination) {
    this.pagination = pagination;
    this.firstItemIndex = (this.pagination.page * this.pagination.size);
    if (!this.skipFirstLoad) {
      this.loadDocuments(this.pagination);
    } else if (this.showPlaceholders) {
      this.setDocumentPlaceholders();
    } else {
      this.skipFirstLoad = false;
    }
  }

  private loadDocuments(pagination: IApp.IPagination) {
    if(this.documentUploadConfig.historicData){
       this.loadHistoricDocuments(this.documentUploadConfig.documents);
    } else if (this.documentUploadConfig.secondaryEntityId) {
      this.loadDocumentsWithSecondaryEntity(pagination);
    } else if (this.documentUploadConfig.id &&  this.documentUploadConfig.entityName) {
      this.uploadDocumentService.getAll(
        pagination, this.documentUploadConfig.id,
        this.documentUploadConfig.entityName,
        this.documentUploadConfig.documentTypeCode,
        this.documentUploadConfig.listUri,
        this.documentUploadConfig.metaData,
        this.documentUploadConfig.documentTypeCodes,
        this.documentUploadConfig.supportingDocumentFilter,
        this.documentUploadConfig.entityVersionId
      )
        .pipe(
          takeUntil(this._destroy$),
        ).subscribe(
          data => {

          this.documentsPage = data;
          this.removeDeletedIfDisabled();
          this.showPlaceholderDocuments();
          this.updateCurrentUploadsInProgress();
        }
        );
    }else {
      this.loadDocumentsFromMultipleSource(pagination);
    }
  }

  private removeDeletedIfDisabled() {
    if (this.disabled && this.documentsPage && this.documentsPage.content) {
      this.documentsPage.content.forEach(element => {
        if (element.actionList) {
          element.actionList = element.actionList.filter(el => {
            return el.action !== 'DELETE_NOT_SUBMITTED';
       });
      }
    });
  }
}

  private loadHistoricDocuments(documents) {
    if (documents) {
     const actualDocs  = documents.filter(d => d.id);
     if(actualDocs){
     actualDocs.forEach(element => element.actionList = [ {action: 'DOWNLOAD', enabled: true}]);
     this.documentsPage = {
        totalElements: actualDocs.length,
        totalPages: 1,
        last: false,
        first: true,
        size: 20,
        number: 0,
        sort: null,
        numberOfElements: 20,
        content: actualDocs,
      };
    }
    }
  }

  private loadDocumentsFromMultipleSource(pagination: IApp.IPagination){
    if (this.documentUploadConfig.documentUploadConfigs && this.documentUploadConfig.documentUploadConfigs.length) {
        const documentUploadConfig1 = this.documentUploadConfig.documentUploadConfigs[0];
        const documentUploadConfig2 = this.documentUploadConfig.documentUploadConfigs[1] ? this.documentUploadConfig.documentUploadConfigs[1] :
        {id: this.documentUploadConfig.documentUploadConfigs[0].overrideId? this.documentUploadConfig.documentUploadConfigs[0].overrideId : -1,
        entityName :'ACCOUNT',documentTypeCode:null};
        const documentTypeCodes = [];
        if( this.documentUploadConfig.documentUploadConfigs.length > 2){
          for (let i = 1; i <  this.documentUploadConfig.documentUploadConfigs.length; i++) {
            documentTypeCodes.push(this.documentUploadConfig.documentUploadConfigs[i].documentTypeCode);

         }
        }
        const type = documentUploadConfig1.entityName === 'ACCOUNT' && documentUploadConfig1.documentTypeCode === 'AUTHORIZATION_DOCUMENT' ? null : documentUploadConfig1.documentTypeCode;
        forkJoin([
          this.uploadDocumentService.getAll(pagination, documentUploadConfig1.id,
            documentUploadConfig1.entityName,type,null, documentUploadConfig1.metaData),
          this.uploadDocumentService.getAll(pagination,documentUploadConfig2.id,
            documentUploadConfig2.entityName, documentUploadConfig2.documentTypeCode,null, null, documentTypeCodes)
        ]).subscribe(([data, data2]) => {
          this.documentsPage = data;
          this.documentsPage.numberOfElements = data.numberOfElements + data2.numberOfElements;
          this.documentsPage.totalElements = data.totalElements + data2.totalElements;
          this.documentsPage.content = this.documentsPage.content.concat(data2.content);
          this.showPlaceholderDocuments();
          this.updateCurrentUploadsInProgress();
        });
    }
  }

  showPlaceholderDocuments(){
    if (this.showPlaceholders) {
      this.documentResult.emit(this.documentsPage.content );
      this.setDocumentPlaceholders();
    }
  }

  @HostListener('window:beforeunload', [ '$event' ])
  onBeforeUnload(event) {
    if (this.documentUploadInProgress.isInProgress()) {
      event.preventDefault();
      event.returnValue = 'Documents are still processing';
      return event;
    }
    return true;
  }

  get uploadInProgress() {
    return this.documentUploadInProgress.inProgress$;
  }

  private checkDocumentsStatus() {
    if (!this.documentsPage || !this.documentsPage.content) {
      return;
    }
    const docs = this.documentsPage.content.filter(doc => doc._uploadStatus === 'IN_PROGRESS');
    docs.forEach(document => {
      this.uploadDocumentService.checkUploadStatus(document.id, document.signedUrl)
        .pipe(
          takeUntil(this._destroy$),
        )
        .subscribe(
          res => {
            const idx = this.documentsPage.content.findIndex(d => d.id === res.id);
            if (idx > -1) {
              if (res.status === 'CLEAN') {
                const doc = {...this.documentsPage.content[idx], _uploadStatus: 'CLEAN'};
                this.documentsPage.content[idx] = doc;
              } else {
                this.documentsPage.content.splice(idx, 1);
                const messages: IDisplayMessages = {
                  messages: [{message: 'infectedDocument'}],
                  type: SereviceMessageType.ERROR,
                };
                this.modalService.open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
                  .afterClosed()
                  .subscribe();
              }
              this.documentResult.emit(this.documentsPage.content);
            }
            },
          );
    });
  }

  public updateVisibility(doc: IApp.IDocument): void {
    this.modalService.open(ServiceMessageComponent,
      {
        message: doc.isPublic ? 'markDocumentAsPublic' : 'unmarkDocumentAsPublic',
        type: SereviceMessageType.WARNING,
      },
      true,
      DEFAULT_DIALOG_CONFIG,
    ).afterClosed().subscribe((result?: any) => {
      if (result) {
        this.setDocumentVisibility(doc);
      } else {
        doc.isPublic = !doc.isPublic;
        this.documentResult.emit(this.documentsPage.content);
      }
    });
  }

  private setDocumentVisibility(doc: IApp.IDocument): void {
    doc._uploadStatus = 'IN_PROGRESS';
    this.uploadDocumentService.updateVisibility(doc.id, doc.isPublic)
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        () => doc._uploadStatus = 'CLEAN',
        () => doc._uploadStatus = 'CLEAN',
        () => this.documentResult.emit(this.documentsPage.content),
      );
  }

  private loadDocumentsWithSecondaryEntity(pagination: IApp.IPagination) {
      const sub$ = forkJoin([
        this.uploadDocumentService.getAll(
          pagination,
          this.documentUploadConfig.secondaryEntityId,
          this.documentUploadConfig.secondaryEntityName,
          this.documentUploadConfig.documentTypeCode,
          this.documentUploadConfig.listUri,
          this.documentUploadConfig.metaData,
          this.documentUploadConfig.documentTypeCodes,
          this.documentUploadConfig.supportingDocumentFilter,
          this.documentUploadConfig.entityVersionId
        ),
        this.uploadDocumentService.getAll(
          pagination,
          this.documentUploadConfig.id,
          this.documentUploadConfig.entityName,
          null,
          GET_ALL,
          null,
          null,
          [],
          this.documentUploadConfig.entityVersionId
        )
      ]).subscribe(([data, data2]) => {
        sub$.unsubscribe();
        data.content.forEach(element => {
          element.actionList = [ {action: 'DOWNLOAD', enabled: true}];
          element['makePublicDisabled'] = true;
        });
        this.documentsPage = data;
        this.documentsPage.numberOfElements = data.numberOfElements + data2.numberOfElements;
        this.documentsPage.totalElements = data.totalElements + data2.totalElements;
        this.documentsPage.content = this.documentsPage.content.concat(data2.content);
        this.documentsPage.content = this.documentsPage.content.sort(
            this._compareFn(this._getNormalizedPagination(pagination))
        );
        this.showPlaceholderDocuments();
        this.updateCurrentUploadsInProgress();
      });
  }

  private _getNormalizedPagination(pagination) {
    // fixes mapping from the table config
    if (pagination.sort === 'timestamp') {
      pagination.sort = 'lastModifiedDateStr';
    }

    return pagination;
  }

  private _compareFn(pagination) {
    if (pagination.dir === 'ASC') {
      return (a, b) => (String(a[pagination.sort]).localeCompare(String(b[pagination.sort])));
    }

    return (a, b) => (String(b[pagination.sort]).localeCompare(String(a[pagination.sort])));
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

}
