import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IProjectDiscussionCommentContent } from '@shared/components/project-discussion/service/project-discussion.service.types';
import { IProject } from '@shared/services/project.service.types';

@Component({
  selector: 'app-project-discussion-chat-reply',
  templateUrl: './project-discussion-chat-reply.component.html',
  styleUrls: ['./project-discussion-chat-reply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDiscussionChatReplyComponent {
  @Input() project: IProject;
  @Input() reply: IProjectDiscussionCommentContent;
}
