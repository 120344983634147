/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../pipes/custom-translate.pipe";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./project-discussion-wrapper.component";
var styles_ProjectDiscussionWrapperComponent = [];
var RenderType_ProjectDiscussionWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectDiscussionWrapperComponent, data: {} });
export { RenderType_ProjectDiscussionWrapperComponent as RenderType_ProjectDiscussionWrapperComponent };
export function View_ProjectDiscussionWrapperComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ":"])), i0.ɵpid(131072, i1.CustomTranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [], null, null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform("PROJECTS_MODULE.DISCUSSION.discussionHeader")); _ck(_v, 2, 0, currVal_0); }); }
export function View_ProjectDiscussionWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-discussion-wrapper", [], null, null, null, View_ProjectDiscussionWrapperComponent_0, RenderType_ProjectDiscussionWrapperComponent)), i0.ɵdid(1, 49152, null, 0, i3.ProjectDiscussionWrapperComponent, [], null, null)], null, null); }
var ProjectDiscussionWrapperComponentNgFactory = i0.ɵccf("app-project-discussion-wrapper", i3.ProjectDiscussionWrapperComponent, View_ProjectDiscussionWrapperComponent_Host_0, {}, {}, ["*"]);
export { ProjectDiscussionWrapperComponentNgFactory as ProjectDiscussionWrapperComponentNgFactory };
