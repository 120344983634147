/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./service-message.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../node_modules/@fortawesome/angular-fontawesome/angular-fontawesome.ngfactory";
import * as i5 from "@fortawesome/angular-fontawesome";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./service-message.component";
import * as i8 from "@angular/material/dialog";
var styles_ServiceMessageComponent = [i0.styles];
var RenderType_ServiceMessageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ServiceMessageComponent, data: {} });
export { RenderType_ServiceMessageComponent as RenderType_ServiceMessageComponent };
function View_ServiceMessageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-warning mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.warningResult(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.warningResult(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.YES")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("COMMON.actionsLabel.NO")); _ck(_v, 5, 0, currVal_1); }); }
function View_ServiceMessageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-warning mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.warningResult(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.warningResult(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.CONTINUE")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("COMMON.actionsLabel.CANCEL")); _ck(_v, 5, 0, currVal_1); }); }
function View_ServiceMessageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "btn btn-warning mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.warningResult(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-secondary mr-3"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.warningResult(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("COMMON.actionsLabel.OK")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("COMMON.actionsLabel.CANCEL")); _ck(_v, 5, 0, currVal_1); }); }
function View_ServiceMessageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceMessageComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceMessageComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceMessageComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.messages.yesNoButton; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.messages.continueOkButton; _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.messages.yesNoButton && !_co.messages.continueOkButton); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.messages.message)); _ck(_v, 1, 0, currVal_0); }); }
function View_ServiceMessageComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.messages.message)); _ck(_v, 1, 0, currVal_0); }); }
function View_ServiceMessageComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.format(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_ServiceMessageComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceMessageComponent_8)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages.messages; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ServiceMessageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceMessageComponent_6)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["REGULAR_ERROR", 2]], null, 0, null, View_ServiceMessageComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.messages.message; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ServiceMessageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "alert-icon alert-dismissible fade show"], ["id", "message-service"], ["role", "alert"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["aria-hidden", "true"], ["class", "icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "fa-icon", [["class", "ng-fa-icon"], ["style", "font-size: 40px"]], [[1, "title", 0], [8, "innerHTML", 1]], null, null, i4.View_FaIconComponent_0, i4.RenderType_FaIconComponent)), i1.ɵdid(5, 573440, null, 0, i5.FaIconComponent, [i6.DomSanitizer, i5.FaConfig, i5.FaIconLibrary, [2, i5.FaStackItemSizeDirective]], { icon: [0, "icon"] }, null), i1.ɵpad(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "h3", [["id", "message_type"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ServiceMessageComponent_1)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["IS_ERROR", 2]], null, 0, null, View_ServiceMessageComponent_5)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["color", "link"], ["data-dismiss", "alert"], ["id", "popup-close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert-icon alert-dismissible fade show"; var currVal_1 = _co.getClassByMessageType(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = _ck(_v, 6, 0, "fas", _co.iconByMessageType); _ck(_v, 5, 0, currVal_4); var currVal_6 = _co.isWarning(); var currVal_7 = i1.ɵnov(_v, 13); _ck(_v, 12, 0, currVal_6, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 5).title; var currVal_3 = i1.ɵnov(_v, 5).renderedIconHTML; _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.messageTypeHeader)); _ck(_v, 9, 0, currVal_5); }); }
export function View_ServiceMessageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-service-message", [], null, null, null, View_ServiceMessageComponent_0, RenderType_ServiceMessageComponent)), i1.ɵdid(1, 49152, null, 0, i7.ServiceMessageComponent, [i8.MAT_DIALOG_DATA, i8.MatDialogRef, i2.TranslateService], null, null)], null, null); }
var ServiceMessageComponentNgFactory = i1.ɵccf("app-service-message", i7.ServiceMessageComponent, View_ServiceMessageComponent_Host_0, {}, {}, []);
export { ServiceMessageComponentNgFactory as ServiceMessageComponentNgFactory };
