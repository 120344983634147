
<div id="other-party-org-details">
    <h2>{{'AGREEMENT_CREDITS.Step.main_title' | translate }}</h2>

    <h2 *ngIf="existInMarkit; else enterInfo">{{'AGREEMENT_CREDITS.Step_3.pleaseReviewInfoRCA' | translate }}</h2>
    
    <ng-template #enterInfo>
        <h2>{{'AGREEMENT_CREDITS.Step_3.pleaseEnterInfo' | translate }}</h2>
    </ng-template>
    
    <formly-form [form]="formGroup" [model]="model" [fields]="fields" ></formly-form>

</div>