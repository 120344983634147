<div class="card">
    <div class="card-body">
        <h1>{{ 'LOGIN_PAGE.forgotUserName.forgotUserName'| translate }}</h1>
        <hr/>

        <form
            #forgotUserNameForm="ngForm"
            autocomplete="off"
            (ngSubmit)="forgotUserNameForm.form.valid && sendRequest(forgotUserNameForm)"
            novalidate>

            <div class="form-group">
                <label for="userNameEmail">{{ 'LOGIN_PAGE.forgotUserName.email'| translate }}</label>
                <input type="email" [(ngModel)]="email" class="form-control"
                    name="userNameEmail" id="userNameEmail" #userNameEmail="ngModel" required
                    [ngClass]="{ 'is-invalid': forgotUserNameForm.submitted && userNameEmail.invalid }">

                <small *ngIf="forgotUserNameForm.submitted && userNameEmail.invalid"
                    id="reset-password-error-newpassword-required" class="invalid-feedback">
                    {{ 'LOGIN_PAGE.forgotUserName.provideEmailAddress'| translate }}
                </small>

            </div>

            <div class="form-group">
                <button type="submit" id="submitEmail" class="btn btn-primary">
                    {{ 'LOGIN_PAGE.forgotUserName.submit'| translate }}
                </button>
            </div>

            <div class="form-group">
                <hr/>
                <a href= "" [appRouterLink]="['welcome' | localize]"> {{ 'LOGIN_PAGE.forgotUserName.backToLogin'| translate }}</a>
            </div>
        </form>

    </div>
  </div>

