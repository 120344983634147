/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account-selection.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/custom-translate.pipe";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
import * as i5 from "@gilsdav/ngx-translate-router";
import * as i6 from "@angular/common";
import * as i7 from "./account-selection.component";
import * as i8 from "../../../core/store/store.service";
var styles_AccountSelectionComponent = [i0.styles];
var RenderType_AccountSelectionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountSelectionComponent, data: {} });
export { RenderType_AccountSelectionComponent as RenderType_AccountSelectionComponent };
function View_AccountSelectionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.header)); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountSelectionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registration"]))], null, null); }
function View_AccountSelectionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.sub_header)); _ck(_v, 1, 0, currVal_0); }); }
function View_AccountSelectionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "btn btn-secondary w-100"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i5.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(10, 1), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _ck(_v, 10, 0, i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("/register/chooser-page"))); _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.message)); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("REGISTRATION_PAGE.authAccountSelection.continue")); _ck(_v, 11, 0, currVal_2); }); }
function View_AccountSelectionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "mt-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "row mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["class", "col-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpid(131072, i5.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵpad(10, 1), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵpid(131072, i2.CustomTranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 0, "div", [["class", "col-4"]], null, null, null, null, null))], function (_ck, _v) { var currVal_1 = _ck(_v, 10, 0, i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("/welcome"))); _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("REGISTRATION_PAGE.authAccountSelection.thankyouText")); _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("REGISTRATION_PAGE.authAccountSelection.close")); _ck(_v, 11, 0, currVal_2); }); }
export function View_AccountSelectionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h1", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registration"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "wcag-visuallyhidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registration"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSelectionComponent_1)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSelectionComponent_2)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSelectionComponent_3)), i1.ɵdid(9, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSelectionComponent_4)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountSelectionComponent_5)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.header; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.sub_header; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.session; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.session; _ck(_v, 13, 0, currVal_4); }, null); }
export function View_AccountSelectionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account-selection", [], null, null, null, View_AccountSelectionComponent_0, RenderType_AccountSelectionComponent)), i1.ɵdid(1, 114688, null, 0, i7.AccountSelectionComponent, [i8.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountSelectionComponentNgFactory = i1.ɵccf("app-account-selection", i7.AccountSelectionComponent, View_AccountSelectionComponent_Host_0, { header: "header", thankyoumessage: "thankyoumessage", sub_header: "sub_header" }, {}, []);
export { AccountSelectionComponentNgFactory as AccountSelectionComponentNgFactory };
