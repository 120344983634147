
import { HttpClient  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { IFacility, IKYCFile, IRegistrationSteps, IOkResponse, IFacilityRequest, IDocument, IAccountData} from '@module/registration/registration.model';
import { TranslateService } from '@ngx-translate/core';
import { IApp } from '@core/models/app.interfaces';
import { LookupService } from '@core/services/lookup.service';

export const ACCOUNT =  '/account-service/account';
export const DOCUMENT =  '/account-service/document';
export const DOCUMENT_DOWNLOAD =  '/account-service/document';

export const DICT_JURISDICTIONS_URI = '/account-service/lookup/jurisdictions';
export const SUBMIT_OBPS_FACILITY_ACCOUNT = '/submitObpsFacilityAccount';
export const SUBMIT_KYC = '/submitKyc';
export const SUBMIT_NON_OBPS_FACILITY_ACCOUNT = '/submitObpsNonFacilityAccount';
export const SAVE_NON_OBPS_FACILITY_ACCOUNT = '/saveObpsNonFacilityAccount';
export const SUBMIT_OMP_ACCOUNT = '/account-service/ompAccount/submit';
export const SAVE_OMP_ACCOUNT = '/account-service/ompAccount/save';
export const CERTIFICATE_NOS = '/searchByCertificateNos';
export const PROPERTY = '/account-service/property/get?key=';

export const TRANSLATION_PREFIX = 'REGISTRATION_PAGE.stepperSection.';
export const ACCOUNT_SUB_TYPE = '/account-service/lookup/accountSubTypes';
export const REGISTRATION_TYPE = '/account-service/lookup/registrationTypes';
export const SEARCH_ACCOUNT_BY_TOKEN = '/account-service/account/searchByAccessToken';

export const SUBMIT_CFR_ACCOUNT = '/account-service/account/submitCFRAccount';
export const CFR_ACCOUNT_DETAILS = '/account-service/cfrAccount/details';

export const SAVE_OFFSET_ACCOUNT = '/account-service/offsetAccount/save';
export const OFFSET_ACCOUNT_DETAIL = '/account-service/offsetAccount/loadById/';
export const CANCEL_ACCOUNT_ACCOUNT = '/account-service/offsetAccount/cancel';

export const KEY_OTHER_FUEL_CHAR_LENGTH = 'registration.report.other.fuel.char.length';



@Injectable({
  providedIn: 'root',
})
export class RegistrationService {

  public registrationSteps: IRegistrationSteps;
  public newAccountSteps: IRegistrationSteps;

  public registrationType = new Subject<string>();

  private _files$ = new BehaviorSubject<IDocument[]>([]);
  readonly files$ = this._files$.asObservable();

  constructor(private http: HttpClient,
    private translateService: TranslateService,
    private lookupService: LookupService,
    ) {
    this.prepareSteps();
  }

  prepareSteps() {
    this.registrationSteps = {
      default: [
        {
          id: 1,
          name: TRANSLATION_PREFIX + 'programType',
          completed: false,
          hidden: true,
        },
        {
          id: 2,
          name: TRANSLATION_PREFIX + 'intendedUse',
          completed: false,
        },
        {
          id: 3,
          name: TRANSLATION_PREFIX + 'selectAccountType',
          completed: false,
        },
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'registrationSubmitted',
          completed: false,
          preventNavigation: true,
        },
      ],
      obps: [
        {
          id: 1,
          name: TRANSLATION_PREFIX + 'programType',
          completed: false,
          hidden: true,
        },
        {
          id: 2,
          name: TRANSLATION_PREFIX + 'intendedUse',
          completed: false,
        },
        {
          id: 3,
          name: TRANSLATION_PREFIX + 'enterFacilityInformation',
          completed: false,
        },
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'registrationSubmitted',
          completed: false,
          preventNavigation: true,
        },
      ],
      cm: [
        {
          id: 1,
          name: TRANSLATION_PREFIX + 'programType',
          completed: false,
          hidden: true,
        },
        {
          id: 2,
          name: TRANSLATION_PREFIX + 'intendedUse',
          completed: false,
        },
        {
          id: 3,
          name: TRANSLATION_PREFIX + 'selectAccountTypeAndPurpose',
          completed: false,
        },
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
          completed: false,
        },
        {
          id: 5,
          name: TRANSLATION_PREFIX + 'registrationSubmitted',
          completed: false,
          preventNavigation: true,
        },
      ],
      offset: [
        {
          id: 1,
          name: TRANSLATION_PREFIX + 'programType',
          completed: false,
          hidden: true,
        },
        {
          id: 2,
          name: TRANSLATION_PREFIX + 'intendedUse',
          completed: false,
        },
        {
          id: 3,
          name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
          completed: false,
        },
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'registrationSubmitted',
          completed: false,
          preventNavigation: true,
        },
      ]
    };

    this.newAccountSteps = {
      cm: [
        {
          id: 3,
          name: TRANSLATION_PREFIX + 'selectAccountTypeAndPurpose',
          completed: false,
        },
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'enterFacilityInformation',
          completed: false,
        },
        {
          id: 5,
          name: TRANSLATION_PREFIX + 'complete',
          completed: false,
        },
      ],
      cmHiddenSteps: [
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'enterFacilityInformation',
          completed: false,
        },
      ],
      offset: [
         {
          id: 3,
          name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
          completed: false,
        },
        {
          id: 4,
          name: TRANSLATION_PREFIX + 'complete',
          completed: false,
        },
      ],
      offsetHiddenSteps: [
        {
         id: 3,
         name: TRANSLATION_PREFIX + 'offsetRegistrationInfo',
         completed: false,
       }
     ]
    }
  }

  getFiles(): Observable<IDocument[]> {
    return this.files$;
  }

  set files(files) {
    this._files$.next(files);
  }

  upload(file): Observable<IDocument> {
    const formData = new FormData();
    const url = `${ environment.apiUrl }${DOCUMENT}`;
    //const mData = JSON.stringify({comments: 'abc'});
    formData.append('file', file);
   // formData.append('document', mData);
    return this.http.post<IDocument>(url, formData);
  }

  checkFacility(facilityRequest: IFacilityRequest): Observable<IFacility[]> {
    const url = `${ environment.apiUrl }${ACCOUNT}${ CERTIFICATE_NOS }`;
    return this.http.post<IFacility[]>(url, facilityRequest);
  }

  submitObpsFacilityAccount(payload: IFacilityRequest): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ ACCOUNT }${ SUBMIT_OBPS_FACILITY_ACCOUNT }`, payload).pipe(
      tap(response => {
      }),
    );
  }

  submitCfrAccount( payload: any): Observable<any> {
    payload.accountType = {id: 2}; //PARTICIPANT
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ SUBMIT_CFR_ACCOUNT }`, payload).pipe(
      tap(response => {
      }),
    );
  }

  submitVBRegistration( payload: any): Observable<any> {
     payload.subType ='VB';
     payload.accountType = {id: 5}; //VB
     return this.http.post<IOkResponse>(`${ environment.apiUrl }${ SUBMIT_CFR_ACCOUNT }`, payload).pipe(
      tap(response => {
      }),
    );
  }


  submitKyc(payload: IFacilityRequest): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ ACCOUNT }${ SUBMIT_KYC }`, payload).pipe(
      tap(response => {
      }),
    );
  }

  submitObpsNonFacilityAccount(payload: IAccountData): Observable<IOkResponse> {
    payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ ACCOUNT }${ SUBMIT_NON_OBPS_FACILITY_ACCOUNT }`, payload);
  }

  submitOMPAccount(payload: IAccountData, uri: string): Observable<IOkResponse> {
    payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ uri ? uri : SUBMIT_OMP_ACCOUNT }`, payload);
  }

  saveOMPAccount(payload: IAccountData): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ SAVE_OMP_ACCOUNT }`, payload);
  }

  saveObpsNonFacilityAccount(payload: IAccountData): Observable<IOkResponse> {
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ ACCOUNT }${ SAVE_NON_OBPS_FACILITY_ACCOUNT }`, payload).pipe(
      tap(response => {
      }),
    );
  }

  getJurisdictionsDict(): Observable<IApp.IDict[]> {
    return this.http.get<IApp.IDict[]>(`${ environment.apiUrl }${ DICT_JURISDICTIONS_URI }`);
  }

  getBusinessStructure(){
    return this.lookupService.getBusinessStructure();
  }

  handleErrors(errors: IApp.IError): any[] {
    const errorResponse = [];
    return errors.messages.map(err => {
      const errMsg = this.translateService.instant(`COMMON.messageSection.${ err }`);
      errorResponse.push(errMsg);
      return errorResponse;
    });
  }

  getAccountSubtypes(): Observable<IApp.IAccountSubType[]>{
    return this.http.get<IApp.IAccountSubType[]>(`${ environment.apiUrl }${ACCOUNT_SUB_TYPE}`);
  }

  getAccountSubtypeById(id: number): Observable<IApp.IAccountSubType[]>{
    return this.http.get<IApp.IAccountSubType[]>(`${ environment.apiUrl }${ACCOUNT_SUB_TYPE}/${id}`);
  }

  getRegistrationType(): Observable<IApp.IRegistrationType[]>{
    return this.http.get<IApp.IRegistrationType[]>(`${ environment.apiUrl }${REGISTRATION_TYPE}`);
  }

  getFacilityByToken(token: string): Observable<IApp.IAccountData[]> {
    return this.http.get<IApp.IAccountData[]>(`${ environment.apiUrl }${ SEARCH_ACCOUNT_BY_TOKEN }/${ token }`);
  }

  getCFRAccountDetails(token: string): Observable<any> {
    return this.http.get<any>(`${ environment.apiUrl }${ CFR_ACCOUNT_DETAILS }/${ token }`);
  }

  private log(message: string) {
  }

  submitOffsetAccount(payload, uri: string) {
    payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
    return this.http.post<IOkResponse>(`${ environment.apiUrl }${ uri }`, payload);
  }


  saveOffsetAccount(payload) {
    payload.displayAoProfilePublicView = payload.authorizedOfficial.displayAoProfilePublicView;
    return this.http.post<any>(`${ environment.apiUrl }${ SAVE_OFFSET_ACCOUNT }`, payload);
  }

  getOffsetAccountById(id:number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}${OFFSET_ACCOUNT_DETAIL}${id}`);
  }

  cancelOffsetRegistration(payload) {
    return this.http.post<any>(`${ environment.apiUrl }${ CANCEL_ACCOUNT_ACCOUNT }`, payload);
  }

  getProperty(propertyName: string): Observable<IApp.Property>{
    return this.http.get<IApp.Property>(`${ environment.apiUrl }${ PROPERTY }${propertyName}`);
  }

  getConfigProperty$(key): Observable<any> {
    const result = sessionStorage.getItem(key);
    if (result) {
      return of(JSON.parse(result));
    } else {
      return this.getProperty(key).pipe(tap(data => this.setConfigProperty(key, data)));
    }
  }

  setConfigProperty(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  resetConfigProperty(key) {
    sessionStorage.removeItem(key);
  }
}
