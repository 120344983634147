/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./simple-border-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./simple-border-wrapper.component";
var styles_SimpleBorderWrapperComponent = [i0.styles];
var RenderType_SimpleBorderWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SimpleBorderWrapperComponent, data: {} });
export { RenderType_SimpleBorderWrapperComponent as RenderType_SimpleBorderWrapperComponent };
function View_SimpleBorderWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "border-bottom mb-2"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "pl-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 16777216, [[1, 3], ["fieldComponent", 1]], null, 0, null, null, null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.to.label)); _ck(_v, 1, 0, currVal_0); }); }
function View_SimpleBorderWrapperComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "border-bottom mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "pl-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, [[1, 3], ["fieldComponent", 1]], null, 0, null, null, null, null, null, null, null))], null, null); }
export function View_SimpleBorderWrapperComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { fieldComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SimpleBorderWrapperComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["NO_LABEL", 2]], null, 0, null, View_SimpleBorderWrapperComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.to.label; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SimpleBorderWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-simple-border-wrapper", [], null, null, null, View_SimpleBorderWrapperComponent_0, RenderType_SimpleBorderWrapperComponent)), i1.ɵdid(1, 49152, null, 0, i4.SimpleBorderWrapperComponent, [], null, null)], null, null); }
var SimpleBorderWrapperComponentNgFactory = i1.ɵccf("app-simple-border-wrapper", i4.SimpleBorderWrapperComponent, View_SimpleBorderWrapperComponent_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { SimpleBorderWrapperComponentNgFactory as SimpleBorderWrapperComponentNgFactory };
