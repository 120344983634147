import { UrlSegment, UrlSegmentGroup, Route } from "@angular/router";
import { StoreService } from "@core/store/store.service";
import { AppInjector } from "@shared/services/app-injector.service";
import { TranslateService } from '@ngx-translate/core';

export function cfsMatcher(
  segments: UrlSegment[],
  group: UrlSegmentGroup,
  route: Route,
) {
  const appInjector = AppInjector.getInjector();
  if (appInjector && route.data && segments[0]) {
    const storeService = appInjector.get(StoreService);
    const translate = appInjector.get(TranslateService);
    const isPathMatch = [route.data.path, translate.instant(`ROUTES.routes.${route.data.path}`)].includes(segments[0].path);
    const isUserTypeMatch = storeService.user
      ? storeService.user.program === "CFR"
      : false;

    if (isPathMatch && isUserTypeMatch) {
      return { consumed: [segments[0]] };
    }
  }
  return null;
}
