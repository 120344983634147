import { Component, OnDestroy, OnInit } from '@angular/core';
import { IApp } from '@core/models/app.interfaces';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { AgreementCreditsService } from '@module/agreement-credits/agreement-credits.service';
import { CfrRegistrationService } from '@module/cfr-registration/services/cfr-registration.service';
import { IDocument } from '@module/registration/registration.model';
import { TranslateService } from '@ngx-translate/core';
import { BaseStepperStepComponent } from '@shared/components/base-stepper/base-stepper-step.component';
import { NotificationsService } from '@shared/services/notifications.service';


const PREFIX = 'AGREEMENT_CREDITS.tableDetails.';
const ENTITY_NAME = 'AGREEMENT';
const REQUIRED_DOCUMENT_TYPES = {
  AGREEMENT: ['AGREEMENT_DOCUMENT'],
};
@Component({
  selector: 'app-upload-agreement',
  templateUrl: './upload-agreement.component.html',
  styleUrls: ['./upload-agreement.component.scss'],
})
export class UploadAgreementComponent extends BaseStepperStepComponent implements OnInit, OnDestroy {
  static key = 'UploadAgreementComponent';

  code = '';
  pendingDocumentMetaData: IApp.IPendingDocumentMetaData;
  uploadedDocumentTypeIds: number[] = [];
  loaded = false;

  constructor(public service: AgreementCreditsService,
              private storeService: StoreService,
              private translateService: TranslateService,
              public lookupService: LookupService,
              public serviceMessageComponent: NotificationsService,
  ) {
    super();
  }

  ngOnInit() {
    this.model = this.service.currentData;
    this.service.currentData.formType = ENTITY_NAME;
    this.lookupService.getDocumentTypes(ENTITY_NAME).subscribe(data => {
      this.pendingDocumentMetaData = {
        prefix: PREFIX,
        sizeProperty:'max.file.size.allowed.registration.report',
        fileExtension:'doc.allowed.file.types',  
        title: 'title',
        data: null,
        dataLabel: null,
        status: null,
        uploadDocumentText: `uploadDocumentText${this.code}`,
        identificationDocuments: this.translateService.instant(`${PREFIX}identificationDocuments${this.code}`),
        documentUploadConfig: this.getDocumenUploadConfig(data, 'AGREEMENT', REQUIRED_DOCUMENT_TYPES, this.service.currentData, true),
        disabled: this.service._disabled,
      };
      this.loaded = true;
    });

   
  }

  updateDocumentDetails(documents: IDocument[]) {
    this.uploadedDocumentTypeIds = documents.filter(d => d.id).map(d => d.documentType.id);
    this.model.documents = documents;
  }

  submit() {
    this.service.currentData.action = 'UPLOAD_SIGNED_AGREEMENT';
    this.submitDocuments(this.uploadedDocumentTypeIds, 'uploadAgreementToContinue');
  }

}
