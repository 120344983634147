/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./dynamic-template.type";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/platform-browser";
var styles_DynamicFormlyTemplateType = [];
var RenderType_DynamicFormlyTemplateType = i0.ɵcrt({ encapsulation: 2, styles: styles_DynamicFormlyTemplateType, data: {} });
export { RenderType_DynamicFormlyTemplateType as RenderType_DynamicFormlyTemplateType };
export function View_DynamicFormlyTemplateType_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1], [1, "id", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.template; var currVal_1 = _co.to.id; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_DynamicFormlyTemplateType_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "formly-dynamic-template", [], null, null, null, View_DynamicFormlyTemplateType_0, RenderType_DynamicFormlyTemplateType)), i0.ɵdid(1, 114688, null, 0, i1.DynamicFormlyTemplateType, [i2.TranslateService, i3.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DynamicFormlyTemplateTypeNgFactory = i0.ɵccf("formly-dynamic-template", i1.DynamicFormlyTemplateType, View_DynamicFormlyTemplateType_Host_0, { field: "field", model: "model", form: "form", options: "options" }, {}, []);
export { DynamicFormlyTemplateTypeNgFactory as DynamicFormlyTemplateTypeNgFactory };
