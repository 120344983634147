import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServiceMessageComponent } from '@core/components/service-message/service-message.component';
import { DEFAULT_DIALOG_CONFIG, SereviceMessageType } from '@core/constants/serviceMessage.const';
import { IApp } from '@core/models/app.interfaces';
import { Document, DocumentTypeClass } from '@core/models/document.model';
import { IRawFormBase } from '@core/models/raw-form.types';
import { IDisplayMessages, IMessages } from '@core/models/serviceMessage.model';
import { LookupService } from '@core/services/lookup.service';
import { StoreService } from '@core/store/store.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { FormFactoryService } from '@shared/services/form-factory.service';
import { ModalService } from '@shared/services/modal.service';
import { UploadDocumentService } from '@shared/services/upload-document.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import IDocumentUploadConfig = IApp.IDocumentUploadConfig;
import {
  EDIT_DOCUMENT_PUBLIC_VISIBILITY,
} from '@core/models/permission.const';
import { environment } from '@env/environment';

export const DOCUMENT_UPLOAD_ENTITY = '/account-service/document/entity';
export const DOCUMENT_UPLOAD_REGISTRATION = '/account-service/document';

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.scss'],
})
export class UploadDocumentComponent {
  private _destroy$ = new Subject<any>();
  public openUploadPopup = true;
  documentTypeList: IApp.ILookup[];
  document: Document;
  file: any;
  documentUploadConfig: IApp.IDocumentUploadConfig;
  errorMessage: any;
  form = new FormGroup({});
  fields: FormlyFieldConfig[];
  documentTypes = new BehaviorSubject<IApp.ILookup[]>(null);
  documentProps: IApp.IDocumentProps = {};

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  //@ViewChild(NgSelectComponent, {static: false}) documentTypeSelector: NgSelectComponent;

  constructor(
    //this data obj should be a const type interface registration and accoutn summary so far is calling it
    //sample obj so far as of 12/11 { id: this.account.id, uploadTo:'account', documentType:false, comment : false}
    @Inject(MAT_DIALOG_DATA) public data: IApp.IDocumentUploadConfig,
    private dialogRef: MatDialogRef<UploadDocumentComponent>,
    private lookupService: LookupService,
    private uploadDocumentService: UploadDocumentService,
    private translate: TranslateService,
    private modalService: ModalService,
    private storeService: StoreService,
    private formFactoryService: FormFactoryService,
  ) {
    this.documentUploadConfig = data;
    this.document = new Document();
    this.getDocumentType();
  }

  getDocumentTypes(): Observable<IApp.ILookup[]> {
    return this.documentTypes.pipe(filter(val => val != null));
  }

  private get extraUploadDocuments() {
    let docs = [];
    if (this.documentUploadConfig.extraUploadDocuments) {
      docs = this.documentUploadConfig.extraUploadDocuments.map((option: IDocumentUploadConfig) => ({
        id: option.documentTypeValue,
        name: this.translate.instant(`COMMON.documentList.${option.documentTypeCode}`),
      }));
    }
    return docs;
  }

  getDocumentType() {
    if (this.documentUploadConfig.documentUploadConfigs) {
      var pushOTHERdoctType = true; //do not ever use this , bandaid for missing limb.... ask Papul first
      this.documentUploadConfig.documentUploadConfigs.forEach(doctype => {
        console.log(doctype.replaceOTHERdocType);
        if (doctype.replaceOTHERdocType) {
          pushOTHERdoctType = false;
        }
      });

      this.documentTypeList = this.documentUploadConfig.documentUploadConfigs.map(option => ({
        id: option.documentTypeValue,
        name: this.translate.instant(`COMMON.documentList.${option.documentTypeCode}`),
      }));

      this.documentTypeList = [...this.documentTypeList, ...this.extraUploadDocuments];

      if (pushOTHERdoctType) {
        this.documentTypeList.push({
          id: 1,
          name: this.translate.instant(`COMMON.documentList.OTHER`),
        });
      }

      this.documentTypes.next(this.documentTypeList);
      this.fields = this.formFactoryService.configureForm(this.getFields());
    } else if (this.documentUploadConfig.entityName) {
      this.lookupService
        .getOBPSDocumentType(this.documentUploadConfig.entityName)
        .pipe(takeUntil(this._destroy$))
        .subscribe(value => {
          value
            ? (this.documentTypeList = value.map(option => ({
                ...option,
                name: this.translate.instant(`COMMON.documentList.${option.name}`),
              })))
            : '';

          this.documentTypeList = [...this.documentTypeList, ...this.extraUploadDocuments];

          this.documentTypes.next(this.documentTypeList);
          this.fields = this.formFactoryService.configureForm(this.getFields());
        });
    }
    setTimeout(() => {
      if (this.documentUploadConfig.preselectedDocumentType) {
        const option = this.documentTypeList.find(opt => opt.id === this.documentUploadConfig.preselectedDocumentType['documentTypeValue']);
        this.documentProps = { ...this.documentProps, documentType: option };
      }
    });
  }

  selectFiles(event) {
    event && event.preventDefault();
    this.fileInput.nativeElement.click();
  }

  addFile(event) {
    this.file = event.target.files[0];
  }

  documentTypeSelected(event) {
    this.document.documentType = event;
  }

  documentCommentsEntered(event) {
    this.document.comments = event.target.value;
  }

  submit() {
    const formData = new FormData();

    this.formFactoryService.markFormGroupDirty(this.form);
    if (!this.form.valid) {
      return;
    } else if (this.documentProps.documentType && this.documentProps.documentType.id) {
      this.document.documentType = this.documentProps.documentType;
    }

    if (this.file === undefined) {
      this.showErrorMessage([{ message: 'FILE_NOT_FOUND' }]);
      return;
    }

    formData.append('file', this.file);
    var uri = DOCUMENT_UPLOAD_REGISTRATION;
    let baseUrl = environment.serviceUrl;
    if (this.documentUploadConfig.entityName) {
      this.document.entityType = this.data.entityName;
    }
    if (this.documentUploadConfig.pendingSubmissionStatus) {
      this.document.status = 'PENDING_SUBMISSION';
    }

    if (this.data.uri) {
      uri = this.data.uri;
    } else {
      uri = DOCUMENT_UPLOAD_ENTITY;
    }

    if (this.data.id) {
      this.document.entityId = this.data.id;
    }

    if (this.documentUploadConfig.documentTypeValue) {
      this.document.documentType = new DocumentTypeClass();
      this.document.documentType.id = this.documentUploadConfig.documentTypeValue;
    }

    if (this.enabledPublicVisibility) {
      this.document.isPublic = this.documentProps.isPublic;
    }

    if (this.documentUploadConfig.documentUploadConfigs) {
      const uploadConfig = this.documentUploadConfig.documentUploadConfigs.filter(
        c => c.documentTypeValue === this.document.documentType.id,
      );
      if (uploadConfig && uploadConfig.length > 0) {
        const docUpload = uploadConfig[0];
        this.document.entityId = docUpload.id;
        this.document.entityType = docUpload.entityName;
        this.document.entityVersionId = 0;
        if (docUpload.uri) {
          uri = docUpload.uri;
        }
        if (docUpload.baseUrl) {
          baseUrl = docUpload.baseUrl;
        }
      } else {
        const docUpload = this.documentUploadConfig.documentUploadConfigs[0];
        if (docUpload !== undefined) {
          this.document.entityId = docUpload.overrideId ? docUpload.overrideId : docUpload.id;
          this.document.entityType = docUpload.overrideEntityName ? docUpload.overrideEntityName : docUpload.entityName;
          this.document.entityVersionId = 0;
        }
      }
    }
    formData.append('document', JSON.stringify(this.document));
    this.uploadDocumentService.upload(uri, formData, baseUrl).subscribe(
      data => {
        this.dialogRef.close(data);
        if (this.document.entityType === 'ADMIN') {
          this.showSuccessMessage('documentUploadedSuccessfully');
        }
      },
      err => {
        this.file = undefined;
        this.fileInput.nativeElement.value = '';

        if (err.error && err.error.message) {
          this.showErrorMessage([{ message: err.error.message }]);
        } else if (err.error && err.error.messages.length > 0) {
          this.showErrorMessage(err.error.messages.map(message => ({ message })));
        } else if (err.error && err.error.dynamicFieldMessages && err.error.dynamicFieldMessages.length > 0) {
          this.showErrorMessage(err.error.dynamicFieldMessages);
        }
      },
    );
  }

  showSuccessMessage(message: string) {
    const messages: IDisplayMessages = {
      messages: [{ message: message }],
      type: SereviceMessageType.SUCCESS,
    };

    this.modalService
      .open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
      .afterClosed()
      .subscribe((result?: boolean) => {});
  }

  showErrorMessage(errorMessages: IMessages[]) {
    if (!this.errorMessage) {
      const messages: IDisplayMessages = {
        messages: errorMessages,
        type: SereviceMessageType.ERROR,
      };

      this.errorMessage = this.modalService
        .open(ServiceMessageComponent, messages, true, DEFAULT_DIALOG_CONFIG)
        .afterClosed()
        .subscribe((result?: boolean) => (this.errorMessage = null));
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  get enabledPublicVisibility(): boolean {
    return this.documentUploadConfig.publicVisibility && this.storeService.user.hasPermission(EDIT_DOCUMENT_PUBLIC_VISIBILITY);
  }

  getFields(): IRawFormBase[] {
    return [
      {
        groupFields: false,
        fieldGroup: [
          {
            type: 'dict-select',
            className: 'col-12',
            key: 'documentType.id',
            source: this.documentTypes,
            dataType: 'VB_SCOPE',
            label: 'COMMON.actionsLabel.DOCUMENTTYPE',
            disabled: false,
            required: this.storeService.user.isCFR() || this.storeService.user.isOffset(),
            id: `docType`,
            ignoreDropdownAppend: true,
            virtualScroll: false,
            placeholder: 'COMMON.actionsLabel.DOCUMENTTYPE',
          },
          {
            className: 'col-12',
            type: 'checkbox',
            key: 'isPublic',
            label: 'ACCOUNTS_MODULE.uploadDocument.markPublic',
            hideExpression: !this.enabledPublicVisibility,
            id: 'isPublicUploadForm',
          },
        ],
      },
    ];
  }
}
